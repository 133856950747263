import React from "react";
import Box from "pages/_components/Box";
import I18n from "pages/_components/I18n";
import { string } from "prop-types";

const MovementHeader = ({ currency }) => (
    <Box className="movs-header-cols">
        <Box className="p-5 size-6">
            <I18n id="tableHeader.date" />
        </Box>
        <Box className="p-5 size-6">
            <I18n id="tableHeader.transaction" />
        </Box>
        <Box className="p-5 justify-content-flex-end size-6" display="flex">
            <I18n id="tableHeader.amount" />
        </Box>
        <Box className="p-5 justify-content-flex-end size-6" display="flex">
            <div>
                <I18n id="tableHeader.availableBalance" /> {currency}
            </div>
        </Box>
        <Box className="p-5 justify-content-flex-end size-6" display="flex">
            <div>
                <I18n id="tableHeader.countableBalance" /> {currency}
            </div>
        </Box>
    </Box>
);

MovementHeader.propTypes = {
    currency: string,
};

MovementHeader.defaultProps = {
    currency: "",
};

export default MovementHeader;
