import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import FormattedAmount from "pages/_components/FormattedAmount";
import ConfirmDialog from "pages/_components/modal/ConfirmDialog";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape } from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "react-router-dom/withRouter";
import * as i18n from "util/i18n";

const MyServicesCard = ({
    isDesktop,
    service,
    updateFavorite,
    fetching,
    deleteService,
    linkService,
    isFastRecharge,
    handleEdit,
    isSelectRecharge,
}) => {
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    return (
        <>
            <Link
                aria-label={i18n.get(`servicePayment.category.${service?.category?.idServicePayment}`)}
                to="/formCustom/servicePayment"
                onClick={() => {
                    linkService(service, isSelectRecharge);
                }}>
                <Box
                    display="flex"
                    alignX="between"
                    alignY="center"
                    background="white"
                    borderRadius="default"
                    className="py-5 px-3 px-md-5 "
                    gap={isDesktop ? "5" : "3"}
                    fullWidth
                    fullHeight>
                    <Box display="flex">
                        <Image
                            src={`images/icons/servicesCategories/${service?.category?.logo}.svg`}
                            {...(!isDesktop && { width: "9", height: "9" })}
                        />
                    </Box>

                    <Box display="flex" column className="mr-auto" gap="1">
                        <Box display="flex" alignY="center">
                            {isFastRecharge ? (
                                <FormattedAmount
                                    quantity={service?.amountQuantity}
                                    currency={service?.amountCurrency}
                                    color="secondary"
                                    size="8"
                                    bold
                                />
                            ) : (
                                <>
                                    <Text
                                        color="secondary"
                                        size="6"
                                        bold
                                        labelKey={`servicePayment.category.${service?.category?.idServicePayment}`}
                                    />
                                    <Box display="flex" alignY="center" alignX="flex-start">
                                        <Button
                                            ariaLabel={
                                                service.favorite
                                                    ? "global.favoriteStar.a11y"
                                                    : "global.noFavoriteStar.a11y"
                                            }
                                            image={`images/icons/favoriteStar${service.favorite ? "On" : "Off"}.svg`}
                                            className={`star-btn py-0 ${service.favorite ? "disabled" : "off"}`}
                                            bsStyle="only-icon"
                                            disabled={fetching && !service.favorite}
                                            loading={fetching}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                updateFavorite(service);
                                            }}
                                        />
                                    </Box>
                                </>
                            )}
                        </Box>
                        <Box display="flex">
                            <Text color={isDesktop ? "text" : "heading"} size="6" uppercase>
                                {service.nameBiller}
                            </Text>
                        </Box>
                        <Box display="block" gap="3">
                            {service.alias !== "" && (
                                <>
                                    <Text color="primary" size="6" bold>
                                        {service.alias}
                                    </Text>
                                    <Text size="6"> | </Text>
                                </>
                            )}
                            <Text size="6" color="text">
                                {service.idBill ? service.idBill : service.partyId}
                            </Text>
                        </Box>
                    </Box>

                    <Box display="flex" gap="1">
                        <Box>
                            <Button
                                ariaLabel="global.edit"
                                bsStyle="only-icon"
                                image="images/icons/editPen24.svg"
                                small
                                onClick={(e) => handleEdit(e, service.idPaymentUser)}
                                imageMd
                            />
                        </Box>
                        <Box>
                            <Button
                                ariaLabel="global.delete.a11y"
                                bsStyle="only-icon"
                                image="images/icons/deleteTrash24.svg"
                                small
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowDeleteConfirm(true);
                                }}
                                imageMd
                            />
                        </Box>
                    </Box>
                </Box>
            </Link>
            {/** TODO LABEL */}
            <ConfirmDialog
                showDialog={showDeleteConfirm}
                title={
                    isFastRecharge
                        ? i18n.get("servicePayments.fast.recharge.modal.delete.title")
                        : i18n.get("servicePayments.modal.delete.title")
                }
                description={
                    isFastRecharge
                        ? i18n.get("servicePayments.fast.recharge.modal.delete.description")
                        : i18n.get("servicePayments.modal.delete.description")
                }
                onConfirm={() => {
                    deleteService(service);
                }}
                onCancel={() => {
                    setShowDeleteConfirm(false);
                }}
            />
        </>
    );
};

MyServicesCard.propTypes = {
    isDesktop: bool.isRequired,
    service: shape({}),
    updateFavorite: func.isRequired,
    fetching: bool,
    deleteService: func.isRequired,
    linkService: func.isRequired,
    isFastRecharge: bool,
    handleEdit: func.isRequired,
    isSelectRecharge: bool,
};

MyServicesCard.defaultProps = {
    service: null,
    fetching: false,
    isFastRecharge: false,
    isSelectRecharge: false,
};

export default connect()(withRouter(resizableRoute(MyServicesCard)));
