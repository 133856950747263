import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";

import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";

import Box from "pages/_components/Box";
import Step1Content from "pages/recoveryPassword/_components/Step1Content";
import classNames from "classnames";
import LoginWrapper from "pages/login/_components/LoginWrapper";

class RecoveryPassStep1 extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.recoveryPassCleanUp());
    }

    render() {
        const { isMobile } = this.props;

        return (
            <LoginWrapper>
                <Box
                    display="flex"
                    {...(isMobile && { alignX: "flex-end", component: "main" })}
                    column
                    zIndex="9"
                    fullHeight
                    className={classNames("login-background", { "mt-auto": isMobile })}>
                    <Step1Content {...this.props} />
                </Box>
            </LoginWrapper>
        );
    }
}

RecoveryPassStep1.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
};

export default connect()(RecoveryPassStep1);
