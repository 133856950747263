import React, { useState } from "react";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func } from "prop-types";
import { connect } from "react-redux";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import classNames from "classnames";
import { compose } from "redux";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import SecondFactor from "pages/secondFactor/SecondFactor";
import ExitModal from "pages/_components/modal/ExitModal";
import { push } from "react-router-redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const ChangePasswordConfirm = ({ isSubmitting, isDesktop, setFieldValue, submitForm, dispatch }) => {
    const [canSubmit, setCanSubmit] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);

    const handlePreCancel = () => {
        setShowExitModal(true);
    };

    const handleCancel = () => {
        dispatch(push("/settings/"));
    };

    const formButtonsMobile = (
        <Box className="mt-8 mb-4" display="flex" column fullWidth>
            <Button
                className="mb-4"
                bsStyle="primary"
                label="global.continue"
                loading={isSubmitting}
                type="submit"
                block
                disabled={!canSubmit}
            />
            <Button bsStyle="outline" label="global.cancel" onClick={handlePreCancel} block />
        </Box>
    );

    return (
        <>
            <Notification scopeToShow="changePasswordConfirm" />
            <Head
                title="settings.changePassword"
                backLinkTo="/settings/changePassword"
                textBack="settings.changePassword.return"
            />
            <MainContainer className={isDesktop ? "pt-0" : "background-white"} fullHeight>
                <Form className="full-height">
                    <Box display="flex" column fullWidth fullHeight>
                        <Box
                            background="white"
                            className={classNames("mt-md-0 pt-5 pt-md-8 px-5 pb-md-7", {
                                "box-shadow-small": isDesktop,
                                "mx-n-5 full-height": !isDesktop,
                            })}
                            borderRadius="lg">
                            <Row fullHeight>
                                <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                    <SecondFactor
                                        onChangeToken={(tokenCode) => {
                                            if (setFieldValue) {
                                                setFieldValue("secondFactor", tokenCode);
                                                submitForm();
                                            }
                                        }}
                                        onValidSubmit={(validData) => {
                                            setCanSubmit(validData.submit);
                                        }}
                                        idActivity="preferences.changepassword.confirm.send"
                                        scopeToShow="changePasswordConfirm"
                                        isSubmitting={isSubmitting}
                                    />

                                    {!isDesktop && formButtonsMobile}
                                </Col>
                            </Row>
                        </Box>
                    </Box>
                    {isDesktop && (
                        <Box {...(!isDesktop && { className: "mt-auto" })}>
                            <Row>
                                <Box className="main-container">
                                    <Row
                                        gapX="7"
                                        className={classNames("mt-7 mb-7", {
                                            "d-flex flex-column-reverse": !isDesktop,
                                        })}>
                                        <Col xs={3} xsOffset={3}>
                                            <Button
                                                bsStyle="outline"
                                                label="global.cancel"
                                                onClick={handlePreCancel}
                                                block
                                            />
                                        </Col>
                                        <Col xs={3}>
                                            <Button
                                                bsStyle="primary"
                                                label="global.confirm"
                                                loading={isSubmitting}
                                                type="submit"
                                                block
                                                disabled={!canSubmit}
                                            />
                                        </Col>
                                    </Row>
                                </Box>
                            </Row>
                        </Box>
                    )}
                </Form>
            </MainContainer>
            <ExitModal
                modalShow={showExitModal}
                acceptFunction={() => handleCancel()}
                cancelFunction={() => setShowExitModal(false)}
                headingText={i18n.get("confirm.exit.title")}
                text={i18n.get("confirm.exit.info")}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    isFetching: settingsSelectors.isFetching(state),
});

ChangePasswordConfirm.propTypes = {
    dispatch: func.isRequired,
    isFetching: bool.isRequired,
    isDesktop: bool.isRequired,
    setFieldValue: func.isRequired,
    submitForm: func.isRequired,
    isSubmitting: bool,
};

ChangePasswordConfirm.defaultProps = {
    isSubmitting: false,
};

export default compose(
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            secondFactor: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                secondFactor: Yup.string().required(i18n.get("form.credential.otp.required")),
            }),
        handleSubmit: ({ secondFactor }, formikBag) => {
            formikBag.props.dispatch(settingsActions.changePasswordConfirm(secondFactor, formikBag));
        },
    }),
)(ChangePasswordConfirm);
