import * as API from "middleware/api";

export const listLoans = () => API.executeWithAccessToken("/loans.list");

export const listStatements = (idLoan) => API.executeWithAccessToken("/loans.listStatements", { idLoan });

export const readLoan = (idLoan, familyLoan, typeLoan) =>
    API.executeWithAccessToken("/loans.read", {
        idLoan,
        familyLoan,
        typeLoan,
    });

export const downloadPayment = (idLoan, { ...filters }, fileFormat) =>
    API.executeWithAccessToken("/loans.downloadPayment", {
        idLoan,
        format: fileFormat,
        ...filters,
    });

export const downloadLoanList = (format, typeLoan, groupCode, numOperation) =>
    API.executeWithAccessToken("/loans.download", {
        format,
        typeLoan,
        groupCode,
        numOperation,
    });

export const downloadStatementLoanList = (format, idLoan, groupCode, typeProduct, numOperation, description) =>
    API.executeWithAccessToken("/loans.statement.download", {
        format,
        idLoan,
        groupCode,
        typeProduct,
        numOperation,
        description,
    });
