import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Button from "pages/_components/Button";
import { Col } from "react-bootstrap";
import DateField from "pages/_components/fields/DateField";
import Selector from "pages/_components/fields/formik/Selector";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import * as i18n from "util/i18n";
import * as config from "util/config";
import moment from "moment";
import { func, instanceOf, shape } from "prop-types";
import { calculateDaysDifference } from "util/date";
import FieldError from "pages/_components/fields/FieldError";

const FORM_ID = "accounts.movements.filters";

const MovementsFilter = ({ initialData, minDate, handleFilter, handleCancel }) => {
    const [errorDate, setErrorDates] = useState(false);
    const minDateFrom = minDate;
    const minDateTo = minDate;
    const optionsDate = [
        {
            value: "lastSeven",
            label: i18n.get("accounts.movements.filters.searchBy.lastSeven"),
        },
        {
            value: "lastThirty",
            label: i18n.get("accounts.movements.filters.searchBy.lastThirty"),
        },
        {
            value: "period",
            label: i18n.get("accounts.movements.filters.searchBy.period"),
        },
    ];

    const generateOptionsTransactionType = () => {
        const options = config
            .getArray("accounts.movements.filter.transactionType", ["0", "1", "2", "3"])
            .map((transaction) => ({
                value: transaction,
                label: i18n.get(`accounts.movements.filter.transactionType.${transaction}`),
            }));
        return options;
    };

    const handleChangeSelect = (option, { values }) => {
        if (option !== "period") {
            setErrorDates(false);
        } else {
            const { dateFrom, dateTo } = values;
            const days = calculateDaysDifference(dateFrom, dateTo);
            setErrorDates(days > 30);
        }
    };

    const handleChangeDateFrom = (dateFrom, { values }) => {
        const { dateTo } = values;
        const days = calculateDaysDifference(dateFrom.toDate(), dateTo);
        setErrorDates(days > 30);
    };

    const handleChangeDateTo = (dateTo, { values }) => {
        const { dateFrom } = values;
        const days = calculateDaysDifference(dateFrom, dateTo.toDate());
        setErrorDates(days > 30);
    };

    return (
        <Formik
            initialValues={{
                selectedDate: initialData.selectedDate,
                transactionType: initialData.transactionType,
                dateFrom: initialData.dateFrom,
                dateTo: initialData.dateTo,
            }}
            onSubmit={handleFilter}>
            {(formik) => (
                <Form>
                    <Box column fullWidth className={classNames("mb-5")}>
                        <Row>
                            <Field
                                component={Selector}
                                options={optionsDate}
                                handleChange={(selected) => handleChangeSelect(selected, formik)}
                                idForm={FORM_ID}
                                name="selectedDate"
                                placeholder="Seleccionar"
                            />
                        </Row>
                        {formik.values.selectedDate && formik.values.selectedDate === "period" && (
                            <>
                                <Row
                                    gapY="0"
                                    className={classNames("px-0", {
                                        "d-flex flex-column": !isDesktop,
                                    })}>
                                    <Col xs={6}>
                                        <Field
                                            component={DateField}
                                            endDate={formik.values.dateTo}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="dateFrom"
                                            selectsStart
                                            startDate={formik.values.dateFrom}
                                            handleChange={(dateSelect) => handleChangeDateFrom(dateSelect, formik)}
                                            popperPlacement="bottom"
                                            popperModifiers={{
                                                flip: {
                                                    behavior: ["bottom"], // don't allow it to flip to be above
                                                },
                                            }}
                                            {...(minDateFrom && { minDate: minDateFrom })}
                                            {...(formik.values.dateTo && { maxDate: formik.values.dateTo })}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <Field
                                            component={DateField}
                                            endDate={formik.values.dateTo}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="dateTo"
                                            selectsEnd
                                            startDate={formik.values.dateFrom}
                                            popperPlacement="bottom"
                                            handleChange={(dateSelect) => handleChangeDateTo(dateSelect, formik)}
                                            popperModifiers={{
                                                flip: {
                                                    behavior: ["bottom"], // don't allow it to flip to be above
                                                },
                                            }}
                                            // eslint-disable-next-line no-nested-ternary
                                            {...(formik.values.dateFrom
                                                ? { minDate: formik.values.dateFrom }
                                                : minDateTo
                                                ? { minDate: minDateTo }
                                                : moment().add(-6, "months"))}
                                        />
                                    </Col>
                                </Row>
                                {errorDate && (
                                    <Box className="my-3 filter-content-error">
                                        {" "}
                                        <FieldError error={i18n.get("accounts.movements.filter.dates.message")} />
                                    </Box>
                                )}
                            </>
                        )}

                        <Row>
                            <Field
                                component={Selector}
                                options={generateOptionsTransactionType()}
                                idForm={FORM_ID}
                                name="transactionType"
                                placeholder="Seleccionar"
                            />
                        </Row>
                    </Box>

                    <Box display="flex" column fullWidth className={classNames("mb-5")}>
                        <Row
                            className={classNames("px-0", {
                                "d-flex flex-column-reverse": !isDesktop,
                            })}>
                            <Col xs={6}>
                                <Button
                                    type="button"
                                    label="global.cancel"
                                    bsStyle="outline"
                                    onClick={handleCancel}
                                    block
                                />
                            </Col>
                            <Col xs={6}>
                                <Button
                                    type="submit"
                                    label="product.filters.filter"
                                    bsStyle="primary"
                                    disabled={
                                        !formik.values.selectedDate || !formik.values.transactionType || errorDate
                                    }
                                    block
                                />
                            </Col>
                        </Row>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

MovementsFilter.propTypes = {
    minDate: instanceOf(Date),
    initialData: shape({}).isRequired,
    handleFilter: func.isRequired,
    handleCancel: func.isRequired,
};

MovementsFilter.defaultProps = {
    minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
};

export default MovementsFilter;
