import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { func, arrayOf, objectOf, shape, bool, string } from "prop-types";

import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

class DispatcherConfirm extends Component {
    static propTypes = {
        user: shape({ firstName: string, lastName: string }).isRequired,
        routerActions: shape({
            goBack: func,
        }),
        idActivity: string,
        idTransaction: string,
        actions: objectOf(func).isRequired,
        formActions: objectOf(func).isRequired,
        hasDispatcherEnabled: bool,
        fetching: bool,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
    };

    static defaultProps = {
        routerActions: null,
        idActivity: null,
        idTransaction: null,
        fetching: false,
        hasDispatcherEnabled: false,
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    handleSubmit = (data, formikBag) => {
        const { actions, formActions, idTransaction, idActivity, user, hasDispatcherEnabled } = this.props;
        const { signatureLevel, ...credentials } = data;
        const secondFactor = credentials;
        if (!idTransaction) {
            actions.updateDispatcherRequest(
                {
                    secondFactor,
                    userId: user.idUser,
                    dispatcher: !hasDispatcherEnabled,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    render() {
        const { fetching, user, credentialGroups, hasDispatcherEnabled } = this.props;

        return (
            <Fragment>
                <Head
                    title="administration.users.dispatcher.configure"
                    onBack={this.handleBack}
                    closeLinkTo="/administration/users"
                />

                <MainContainer showLoader={fetching && !Object.keys(user).length}>
                    <div className="above-the-fold">
                        <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                <p className="text-lead">
                                    <I18n id="administration.forms.confirm.credentials" />
                                </p>
                                <div className="transfer-block">
                                    <Heading.DataGroup
                                        containerClassName="transfer-data data-wrapper"
                                        label="administration.signature.user"
                                        data={`${user.firstName} ${user.lastName}`}
                                    />
                                    <div className="transfer-data">
                                        <Container.ColumnBody columnClass="list-wrapper">
                                            <I18n
                                                id={`administration.users.dispatcher.change.${hasDispatcherEnabled}`}
                                            />
                                        </Container.ColumnBody>
                                    </div>
                                </div>
                            </Col>
                        </Container>

                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                            idActivity="administration.users.update"
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default DispatcherConfirm;
