import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { selectors as creditCardRequestSelectors } from "reducers/creditCardRequest";
import { selectors as enrollmentSelectors } from "reducers/enrollment";
import { selectors as loginSelectors } from "reducers/login";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import { selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import { compose } from "redux";
import { shape, string } from "prop-types";

function validCreditCardRequestUrl(path) {
    if (path.startsWith("/products/lifetest")) {
        return true;
    }
    return false;
}

const withExchangeToken = (Component) => {
    const WithExchangeTokenComponent = ({ exchangeTokens, match, ...props }) => {
        const { path } = match;

        if (
            path.indexOf("loginStep") !== -1 &&
            path.indexOf("loginStep1") !== -1 &&
            !exchangeTokens.loginExchangeToken
        ) {
            return <Redirect to="/loginStep1" />;
        }

        if (
            path.indexOf("recoveryPassword/step") !== -1 &&
            path.indexOf("recoveryPassword/step1") !== -1 &&
            !exchangeTokens.recoveryPasswordExchangeToken
        ) {
            return <Redirect to="/recoveryPassword/step1" />;
        }

        if (path.indexOf("/onboarding/step") !== -1 && path.indexOf("/onboarding/step1") !== -1) {
            return <Redirect to="/onboarding/step1" />;
        }
        if (
            (path.indexOf("enrollment/step") !== -1 || path.indexOf("enrollment/associate/step") !== -1) &&
            path !== "/enrollment" &&
            !exchangeTokens.enrollmentExchangeToken &&
            !exchangeTokens.onboardingExchangeToken &&
            !exchangeTokens.loginExchangeToken
        ) {
            return <Redirect to="/enrollment" />;
        }

        if (validCreditCardRequestUrl(path) && !exchangeTokens.creditCardRequestExchangeToken) {
            return <Redirect to="/products/creditCardRequest/showEmailVerification" />;
        }

        return <Component {...props} />;
    };

    WithExchangeTokenComponent.propTypes = {
        exchangeTokens: shape({
            loginExchangeToken: shape({}).isRequired,
            recoveryPasswordExchangeToken: shape({}).isRequired,
            onboardingExchangeToken: shape({}).isRequired,
            enrollmentExchangeToken: shape({}).isRequired,
            creditCardRequestExchangeToken: shape({}).isRequired,
        }).isRequired,
        match: shape({
            path: string.isRequired,
        }).isRequired,
    };

    return WithExchangeTokenComponent;
};

const mapStateToProps = (state) => ({
    exchangeTokens: {
        loginExchangeToken: loginSelectors.getExchangeToken(state),
        recoveryPasswordExchangeToken: recoveryPasswordSelectors.getExchangeToken(state),
        onboardingExchangeToken: onboardingSelectors.getExchangeToken(state),
        enrollmentExchangeToken: enrollmentSelectors.getExchangeToken(state),
        creditCardRequestExchangeToken: creditCardRequestSelectors.getExchangeToken(state),
    },
});

export default compose(connect(mapStateToProps), withExchangeToken);
