import { Field } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import FrequentDestination from "pages/frequentDestination/FrequentDestinations";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import { bool, func, shape, string } from "prop-types";
import { parse } from "query-string";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as freqDestSelectors } from "reducers/frequentDestination/frequentDestination";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import { emailRegex } from "util/form";
import { numberFormat } from "util/number";
import { validationAccount, validationAmount, validationLength } from "util/validationSchemaUtil";
import * as Yup from "yup";
import { PDFTextField, PDFAmountField, PDFProductSelectorField } from "pages/forms/customForms/PDFTicket";
import FormTransition from "../_components/FormTransition";

const ID_FORM = "transfers.thirdParties";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

const TransferThirdPartieForm = (props) => {
    const { mode, dispatch, location, preDataForm } = props;
    const { decimalSeparator, thousandSeparator } = numberFormat();

    useEffect(() => {
        if (mode === "edit" || mode === "view") {
            const formData = {
                amount: null,
                debitAccount: null,
                creditAccount: null,
                creditReference: null,
                emails: null,
                message: null,
            };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
        }
        // eslint-disable-next-line
    }, [dispatch, location]);

    const onModalRowClick = (frequentDestionationOnRow, values, setValues) => {
        setValues({
            ...values,
            creditAccount: frequentDestionationOnRow.freqDestNumber,
        });
    };

    const preData = preDataForm || {
        currencyList: [],
        debitAccountList: [],
    };

    const selectorOptions = {
        options: preData.debitAccountList?.map((acc) => ({
            ...acc,
            balance: { currency: acc.currency, quantity: acc.balance },
            id: acc.idProduct,
            disabled: acc.isDisabled,
        })),
    };

    const renderTicket = (values) => (
        <>
            <PDFAmountField idForm={ID_FORM} name="amount" values={values} />
            <PDFProductSelectorField idForm={ID_FORM} name="debitAccount" values={values} options={selectorOptions} />
            <PDFTextField idForm={ID_FORM} name="creditAccount" values={values} />

            <PDFTextField idForm={ID_FORM} name="creditReference" values={values} />
            <PDFTextField idForm={ID_FORM} name="emails" values={values} />
            <PDFTextField idForm={ID_FORM} name="message" values={values} />
        </>
    );

    const renderFields = (setFieldValue, values, setValues) => {
        const { currentLang, fromBackoffice, transaction, isDesktop } = props;
        const idTransaction = transaction?.idTransaction;
        const debitAccountOrigin = parse(location.search)?.debitAccount;

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        if (!preDataForm || !preDataForm.currencyList || !preDataForm.debitAccountList) {
            return <PageLoading loading />;
        }

        const dataAmount = {
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options: preData.currencyList.map((c) => ({
                id: c.backendId,
                label: c.code,
            })),
        };

        const fieldDebitAccount = (
            <Field
                {...genericProps}
                component={FormFieldsComponents.ProductselectorCustom}
                data={selectorOptions}
                key="debitAccount"
                name="debitAccount"
                renderAs="combo"
                value={debitAccountOrigin || preData?.debitAccountList[0]?.idProduct || ""}
                idField="debitAccount"
            />
        );
        const fieldCreditAccount = (
            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="creditAccount"
                name="creditAccount"
                idField="creditAccount"
                isRequired={false}
                validationRegularExpresion="^[a-zA-Z0-9 áéíóúñ@]*$"
            />
        );
        return (
            <React.Fragment>
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Amount}
                    data={dataAmount}
                    key="amount"
                    name="amount"
                    value={
                        preData.currencyList && preData.currencyList[0]
                            ? { ...preData.currencyList[0], currency: preData.currencyList[0].backendId }
                            : {}
                    }
                    idField="amount"
                />

                {mode === "preview" || mode === "view" ? (
                    <div className="transfer-data data-wrapper">
                        <div className="flex-container">
                            <div className="transfer-data">{fieldDebitAccount}</div>
                            <Image src="images/arrowRight.svg" className="svg-icon" />
                            <div className="transfer-data">{fieldCreditAccount}</div>
                        </div>
                    </div>
                ) : (
                    <>
                        {fieldDebitAccount}
                        <div>
                            {fieldCreditAccount}
                            <FrequentDestination
                                isDesktop={isDesktop}
                                onModalRowClick={(freqDest) => {
                                    onModalRowClick(freqDest, values, setValues);
                                }}
                                isModal
                                typeFilter="account"
                            />
                        </div>
                    </>
                )}

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Text}
                    key="creditReference"
                    name="creditReference"
                    idField="creditReference"
                    isRequired={false}
                    validationRegularExpresion="^[a-zA-Z0-9 áéíóúñ@]*$"
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Emaillist}
                    key="emails"
                    name="emails"
                    idField="emails"
                    isRequired={false}
                    validationRegularExpresion={emailRegex}
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Textarea}
                    key="message"
                    name="message"
                    idField="message"
                    isRequired={false}
                    validationRegularExpresion="^[a-zA-Z0-9 áéíóúñ@]*$"
                />
            </React.Fragment>
        );
    };

    const validationSchema = () =>
        Yup.object().shape({
            creditAccount: validationLength(
                "",
                "forms.transfers.foreign.creditAccount.maxCharacters",
                0,
                250,
                true,
                undefined,
            ),
            debitAccount: validationAccount("transfer.thirdParties.debitAccount.invalid", [
                ...(preDataForm?.debitAccountList || []),
            ]),
            amount: validationAmount(),
            creditReference: validationLength(
                "",
                "forms.transfers.thirdParties.reference.maxCharacters",
                0,
                250,
                false,
                undefined,
            ),
            message: validationLength(
                "",
                "forms.transfers.thirdParties.message.maxCharacters",
                0,
                250,
                false,
                undefined,
            ),
        });

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
        // eslint-disable-next-line react/prop-types
    } = props.preData || {};

    const formProps = {
        title: "forms.transfers.thirdParties.formName",
        metadata: {
            draftsEnabled: true,
            templatesEnabled: true,
            schedulable: true,
            programable: true,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },

        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validationSchema,
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    frequentDestionationOnRow: freqDestSelectors.getFrequentDestionationOnRow(state),
});

TransferThirdPartieForm.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    frequentDestionationOnRow: shape({}),
    isDesktop: bool,
    fromTransaction: bool,
};
TransferThirdPartieForm.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    transaction: {},
    location: {},
    fromTransaction: false,
    frequentDestionationOnRow: {},
    isDesktop: false,
};
export default compose(connect(mapStateToProps), withRouter)(TransferThirdPartieForm);
