import classNames from "classnames";
import { Field } from "formik";
import FieldError from "pages/_components/fields/FieldError";
import ReactSelect from "pages/forms/_components/_fields/Select";
import { arrayOf, bool, func, node, oneOfType, shape, string } from "prop-types";
import React from "react";

class Select extends React.Component {
    static propTypes = {
        idForm: string,
        name: string.isRequired,
        options: arrayOf(
            shape({
                value: string,
                label: oneOfType([node, string]),
            }),
        ).isRequired,
        className: string,
        hideLabel: bool,
        label: oneOfType([node, string]),
        optional: string,
        placeholder: string,
        noResultsText: string,
        onChange: func,
        searchable: bool,
        clearable: bool,
        mode: string,
        noMarginBottom: bool,
    };

    static defaultProps = {
        idForm: "",
        className: "form-group",
        placeholder: "",
        noResultsText: "",
        searchable: false,
        clearable: false,
        onChange: null,
        hideLabel: false,
        label: "",
        optional: "",
        mode: "edit",
        noMarginBottom: false,
    };

    getLabelForValue = (val) => {
        const { options } = this.props;
        return options.find((opt) => opt.value === val)?.label || "  ";
        // return options.map((opt) => {
        //     if (opt.value === val) {
        //         return opt.label;
        //     }
        // });
    };

    render() {
        const {
            idForm,
            name,
            mode,
            optional,
            className,
            hideLabel,
            label,
            onChange,
            noMarginBottom,
            ...selectProps
        } = this.props;
        const idField = `${idForm}.${name}`;

        return (
            <Field name={name}>
                {({ field, form }) => {
                    const hasError = form.touched[field.name] && form.errors[field.name];
                    const controlClassName = classNames(className, {
                        "has-error": hasError,
                        "form-group-margin-bottom": !noMarginBottom,
                    });
                    return (
                        <div className={controlClassName}>
                            {!hideLabel && label && (
                                <div className="form-group-text">
                                    <label className="control-label" id={`label.${idField}`} htmlFor={idField}>
                                        {label}
                                        {optional && <small className="text-optional">{optional}</small>}
                                    </label>
                                </div>
                            )}
                            {mode === "edit" ? (
                                <div className="input-group">
                                    <input type="hidden" id={idField} name={name} />
                                    <ReactSelect
                                        id={`react_select_${idField}`}
                                        inputProps={{ id: idField, name }}
                                        value={typeof field.value === "string" ? field.value : undefined}
                                        {...selectProps}
                                        onChange={(option) => {
                                            if (onChange) {
                                                onChange(option);
                                            } else {
                                                form.setFieldValue(field.name, !option ? "" : option.value);
                                            }
                                        }}
                                    />
                                </div>
                            ) : (
                                <div>{this.getLabelForValue(field.value)}</div>
                            )}
                            {hasError && <FieldError error={form.errors[field.name]} />}
                        </div>
                    );
                }}
            </Field>
        );
    }
}

export default Select;
