import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Notification from "pages/_components/Notification";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { func, shape, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import Text from "pages/_components/Text";
import Row from "pages/_components/Row";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import TextField from "pages/_components/fields/TextField";
import PhoneInput from "pages/_components/fields/PhoneInput";
// import { REGION_ECU } from "constants.js";

const ModifyContacts = ({ idForm, data, oldEmail, oldPhone, handleBack, handleContinue }) => {
    // const [errorMsg, setErrorMsg] = useState(undefined);

    const requiredMessage = i18n.get("global.field.required");

    const preferredCountries = ["ec"];
    const areaCodes = { ve: ["412", "414", "416", "424", "426"] };

    const renderHeader = () => (
        <>
            <Notification scopeToShow="groupForm" />
            <Head onBack={handleBack} title={`${idForm}.title`} textBack="administration.users.returnToDetail" />
        </>
    );

    const handleSubmit = (values, { setFieldError }) => {
        if (!values.mobileNumber || !values.mobileNumber.value) {
            setFieldError("mobileNumber", requiredMessage);
            return;
        }

        handleContinue(values);
    };

    const validateFields = ({ values }) => {
        if (
            values.email &&
            values.mobileNumber &&
            (values.email !== oldEmail ||
                values.mobileNumber.value !== oldPhone.value ||
                values.mobileNumber.prefix !== oldPhone.prefix)
        ) {
            return false;
        }
        return true;
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(requiredMessage)
            .email(i18n.get("recoveryPassword.step2.email.invalid")),
        mobileNumber: Yup.object().required(requiredMessage),
    });

    return (
        <>
            {renderHeader()}
            <MainContainer className="main-container">
                <Formik
                    enableReinitialize
                    initialValues={data}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}>
                    {(formik) => (
                        <Form>
                            <Box borderRadius="lg" background="white" className="box-shadow-small mt-3 p-7">
                                <Box className="py-4">
                                    <Text labelKey={`${idForm}.info`} size="4" bold />

                                    <Row gapX="7">
                                        <Col xs={6}>
                                            <Field
                                                component={TextField}
                                                idForm={idForm}
                                                name="email"
                                                type="text"
                                                pattern="^[a-zA-Z0-9ñ@+._-]*$"
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <Field
                                                component={PhoneInput}
                                                idForm={idForm}
                                                idField={`${idForm}.mobileNumber`}
                                                name="mobileNumber"
                                                // eslint-disable-next-line max-len
                                                mobilePhone={`${formik?.values?.mobileNumber?.prefix} ${formik?.values?.mobileNumber?.value}`}
                                                className="remove-flex"
                                                preferredCountries={preferredCountries}
                                                areaCodes={areaCodes}
                                                enableAreaCodes={preferredCountries}
                                                openUp
                                            />
                                        </Col>
                                    </Row>
                                </Box>
                            </Box>
                            <Row gapX="7" className="mt-7 mb-7">
                                <Col xs={3} xsOffset={3}>
                                    <Button
                                        bsStyle="outline"
                                        label="global.cancel"
                                        btnUppercase={false}
                                        block
                                        type="button"
                                        onClick={handleBack}
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Button
                                        bsStyle="primary"
                                        label="global.modify"
                                        btnUppercase={false}
                                        disabled={validateFields(formik)}
                                        // disabled={!values.accept}
                                        block
                                        type="submit"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </>
    );
};

ModifyContacts.propTypes = {
    idForm: string.isRequired,
    data: shape({}).isRequired,
    // isCreate: bool.isRequired,
    handleBack: func.isRequired,
    handleContinue: func.isRequired,
    oldEmail: string.isRequired,
    oldPhone: shape({}).isRequired,
};

export default ModifyContacts;
