/*
 * DAS middleware to interact with DAS
 */
import getAxiosObject from "./axiosUtils";

const apiAxios = getAxiosObject(window.DAS_URL);

const sendMessageRequest = (params) =>
    apiAxios.post(
        "",
        {
            ...params,
        },
        {
            headers: {
                Authorization: null,
            },
        },
    );

export default sendMessageRequest;
