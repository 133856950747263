export const flattenArray = (array) => [].concat(...array);

export const removeDuplicateItems = (array) => [...new Set(array)];

export const reinsert = (array, from, to) => {
    const clonedArray = [...array];
    const valueToReinsert = clonedArray[from];

    clonedArray.splice(from, 1);
    clonedArray.splice(to, 0, valueToReinsert);

    return clonedArray;
};

export const removeElement = (array, index) => {
    const leftSide = array.slice(0, index);
    const rightSide = array.slice(index + 1, array.length);

    return [...leftSide, ...rightSide];
};

export const intersection = (array1, array2) => array1.filter((x) => array2.includes(x));

export const mapItemsIds = (array, idKey) => ({
    ids: array.map((item) => item[idKey]),
    byId: array.reduce(
        (byId, item) => ({
            ...byId,
            [item[idKey]]: item,
        }),
        {},
    ),
});

export const mapItemsUsersId = (array, idKey) => ({
    ids: array.map((item) => item[idKey]),
    byId: array.reduce(
        (byId, item) => ({
            ...byId,
            [item[idKey]]: { ...item, labelView: `${item.fullName} - ${item.documentType} ${item.documentNumber}` },
        }),
        {},
    ),
});

export const indexOfWithAttr = (array, attr, value) => {
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
};
export const equalsByObjectProperty = (array1, array2, property) => {
    if (!array1 && !array2) {
        return true;
    }
    if (!array1 || !array2) {
        return false;
    }
    const result =
        array1.length === array2.length &&
        array1.filter((x) => array2.filter((y) => x[property] === y[property]).length) &&
        array1.filter((x) => array2.filter((y) => x[property] === y[property]).length).length === array1.length;

    return result;
};

/**
 *
 * @param {*} array : arrayJson to filtered
 * @param {*} arrayFilter : keys to searched
 * @param {*} val : string to filter
 * @returns
 */
export const filterData = (array, arrayFilter, val) => {
    const datos = [];
    array.forEach((el) => {
        arrayFilter.some((elem) => {
            if (!!el[elem] && val !== null) {
                const value = val.toLowerCase();
                const valueOb = el[elem].toLowerCase();
                if (valueOb.includes(value)) {
                    datos.push(el);
                    return true;
                }
            }
            return false;
        });
    });
    return datos;
};
