import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Box from "pages/_components/Box";
import { bool, func, instanceOf, number, shape, string } from "prop-types";
import { actions as accountsActions } from "reducers/accounts";
import { connect } from "react-redux";
import { isDesktop } from "react-device-detect";
import I18n from "pages/_components/I18n";

class Movement extends Component {
    static propTypes = {
        data: shape({
            date: string,
            reference: string,
            reference: string,
            credit: number,
            debit: number,
            balance: number,
            countableBalance: number,
        }).isRequired,
        currentPage: number.isRequired,
        paginatedDataMap: instanceOf(Map).isRequired,
        itemsMap: instanceOf(Map).isRequired,
        filters: shape({}).isRequired,
        hasFiltersApplied: bool.isRequired,
        history: shape({
            push: func.isRequired,
        }).isRequired,
        dispatch: func.isRequired,
    };

    handleMovementClick = (movement) => {
        const { dispatch, currentPage, paginatedDataMap, itemsMap, filters, hasFiltersApplied } = this.props;

        dispatch(accountsActions.setPaginatedInfo(currentPage, paginatedDataMap, itemsMap, filters, hasFiltersApplied));

        dispatch(accountsActions.setSelectedMovement(movement));
    };

    render() {
        const { data, history } = this.props;
        const {
            idAccount,
            date,
            reference,
            transaction,
            credit,
            debit,
            balance,
            countableBalance,
            accountCurrency,
        } = data;
        return (
            <Box
                className="row-account-mov"
                pointer
                onClick={() => {
                    history.push(`/accounts/${idAccount}/movement`);
                    this.handleMovementClick(data);
                }}>
                {isDesktop ? (
                    <>
                        <Box className="p-5" display="flex" alignY="center">
                            <FormattedDate size="6" date={date} />
                        </Box>
                        <Box className="p-5">
                            <p className="text-bold mt-0 mb-2 size-6">{transaction}</p>
                            <span className="size-7">Ref: {reference}</span>
                        </Box>
                        <Box
                            className={`p-5 justify-content-flex-end mov-col-amount-${credit ? "credit" : "debit"}`}
                            display="flex"
                            alignY="center">
                            {credit ? "+" : "-"}
                            <FormattedAmount
                                className="data-amount data-numeric size-6"
                                noCurrency
                                quantity={credit || debit}
                            />
                        </Box>
                        <Box className="p-5 justify-content-flex-end" display="flex" alignY="center">
                            <FormattedAmount
                                className="data-amount data-numeric size-6"
                                noCurrency
                                quantity={balance}
                            />
                        </Box>
                        <Box className="p-5 justify-content-flex-end" display="flex" alignY="center">
                            <FormattedAmount
                                className="data-amount data-numeric size-6"
                                noCurrency
                                quantity={countableBalance}
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        <Box>
                            <Box className="mb-3">
                                <FormattedDate size="6" date={date} />
                            </Box>

                            <Box className="mb-2">
                                <I18n id="tableHeader.availableBalance" /> {accountCurrency}
                            </Box>
                            <Box className="text-bold size-6">{transaction}</Box>
                        </Box>
                        <Box>
                            <Box className="mb-2 text-right size-6">
                                <I18n id="tableHeader.amount" /> {accountCurrency}
                            </Box>
                            <Box display="flex" alignX="flex-end" className="mb-2">
                                {credit ? <span className="data-amount data-numeric size-6 color-success-color">+</span> : <span>-</span>}
                                <FormattedAmount
                                    className={`data-amount data-numeric size-6 ${credit ? "color-success-color" : ""}`}
                                    noCurrency
                                    quantity={credit || debit}
                                />
                            </Box>

                            <Box className="text-right mb-2 size-6">
                                <I18n id="tableHeader.availableBalance" /> {accountCurrency}
                            </Box>
                            <Box display="flex" alignX="flex-end">
                                <FormattedAmount
                                    className="data-amount data-numeric size-6"
                                    noCurrency
                                    quantity={balance}
                                />
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        );
    }
}

export default withRouter(connect()(Movement));
