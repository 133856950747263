import * as API from "middleware/api";

const getPagedDataList = (idActivity, page, nextIndex, filters, idProduct, idFieldName) =>
    API.executeWithAccessToken(`/${idActivity}`, {
        page,
        [idFieldName]: idProduct,
        nextIndex,
        ...filters,
    });

export default getPagedDataList;
