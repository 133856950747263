export const SECOND_FACTOR_CONST = {
    SOFT_HARD_TOKEN: "softHardToken",
    SECURITY_QUESTIONS: "securityQuestions",
    OTP: "otp",
};

export const SECOND_FACTOR_METHOD_CONST = {
    SOFT_TOKEN: "softToken",
    HARD_TOKEN: "hardToken",
    DIGITAL_TOKEN: "digitalToken",
    SMS_EMAIL: "sms-email",
    SMS: "sms",
    EMAIL: "email",
};

export const getSecondFactorFormat = (secondFactorType, value = undefined) => {
    switch (secondFactorType) {
        case SECOND_FACTOR_CONST.SOFT_HARD_TOKEN:
            return `${SECOND_FACTOR_CONST.SOFT_HARD_TOKEN}@${value}`;

        case SECOND_FACTOR_CONST.SECURITY_QUESTIONS:
            return `${SECOND_FACTOR_CONST.SECURITY_QUESTIONS}@${value}`;

        case SECOND_FACTOR_CONST.OTP:
        default:
            return `${SECOND_FACTOR_CONST.OTP}@${value}`;
    }
};

export const replaceParametersMessage = (template, ...params) =>
    template.replace(/#\{(\d+)\}/g, (match, number) =>
        typeof params[number] !== "undefined" ? params[number] : match,
    );
