import classNames from "classnames";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, shape, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { openLink } from "util/browser";
import { deeper, isMobileNativeFunc, svgScaleFactor } from "util/device";
import * as i18nUtils from "util/i18n";

const Image = ({
    alt,
    ariaHiden,
    ariaLabel,
    className,
    color,
    externalHref,
    height,
    href,
    imageMd,
    imageRight,
    imageSm,
    imageXs,
    isMobileNative,
    location,
    src,
    width,
    wrapperClassName,
    wrapperHeight,
    wrapperWidth,
}) => {
    const svgRef = useRef(null);

    const isSVG = /\.svg$/.test(src);

    const svgResize = () => {
        const svgElement = svgRef.current?.container?.firstChild?.firstChild;

        if (svgElement?.nodeName === "svg") {
            const svgSize = svgElement.getBBox();
            svgElement.style.width = Math.round(svgSize.width * deeper.scaleFactor);
        }
    };

    useEffect(() => {
        let timeoutId = null;
        const resizeEvents = () => {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                svgScaleFactor();
                svgResize();
            }, 200);
        };

        if (!height && !width && !imageXs && !imageSm && !imageMd) {
            window.addEventListener("resize", resizeEvents, { passive: true });
        }

        return () => {
            if (!height && !width && !imageXs && !imageSm && !imageMd) {
                window.removeEventListener("resize", resizeEvents);
            }
        };
    }, [height, width, imageMd, imageSm, imageXs]);
    const path = src.replace("images/", "").replace(/^\//, "");
    let fullPath;
    try {
        fullPath = require(`styles/images/${path}`);
    } catch {
        fullPath = require(`styles/images/financialInstitution.svg`);
    }

    fullPath = fullPath.replace(/^\//, "");

    if (!isMobileNative) {
        const pathDepth = location.pathname.split("/").length - 1;
        const pathPrefix = Array(pathDepth).join("../");
        fullPath = pathPrefix + fullPath;
    }

    const [id] = useState(
        () =>
            `id${Math.random()
                .toString(36)
                .substring(2)}`,
    );

    // eslint-disable-next-line no-nested-ternary
    const Component = href ? Link : externalHref ? "a" : "div";

    return isSVG ? (
        <ReactSVG
            ref={svgRef}
            // id={specificID}
            id={`svg-${id}`}
            src={fullPath}
            className={classNames(wrapperClassName, {
                "align-right": imageRight,
                [`svg-color-${color}`]: color,
                [`svg-height-${wrapperHeight}`]: wrapperHeight,
                [`svg-width-${wrapperWidth}`]: wrapperWidth,
            })}
            beforeInjection={(svg) => {
                const classNameInjected = className?.split(" ");

                classNameInjected.forEach((injectedClass) => {
                    if (injectedClass) {
                        svg.classList.add(injectedClass.trim());
                    }
                });

                // Add height and width classes to the svg element
                if (height) {
                    svg.classList.add(`svg-height-${height}`);
                }
                if (width) {
                    svg.classList.add(`svg-width-${width}`);
                }

                // Accessibility
                if (ariaLabel) {
                    svg.setAttribute("role", "img");
                    svg.setAttribute("aria-label", i18nUtils.get(ariaLabel));
                }
                if (ariaHiden) {
                    svg.setAttribute("aria-hidden", "true");
                }

                // Resize the svg element on first render
                if (!height && !width && !imageXs && !imageSm && !imageMd) {
                    try {
                        // Check if width is declared in SVG (For Safari)
                        if (svg.hasAttribute("width")) {
                            const widthScaled = Math.round(svg.width.animVal.value * deeper.scaleFactor);
                            svg.setAttribute("style", `width: ${widthScaled}px`);
                        } else {
                            return undefined;
                        }
                    } catch (e) {
                        return undefined;
                    }
                }
                return null;
            }}
            wrapper="div"
        />
    ) : (
        <Component
            {...(href && { to: href })}
            {...(externalHref && !isMobileNativeFunc() && { href: externalHref, target: "_blank" })}
            {...(externalHref &&
                isMobileNativeFunc() && {
                    onClick: () => {
                        openLink(externalHref).then();
                    },
                })}
            className={classNames(wrapperClassName, {
                "align-right": imageRight,
                [`svg-height-${wrapperHeight}`]: wrapperHeight,
                [`svg-width-${wrapperWidth}`]: wrapperWidth,
            })}>
            <img
                ref={svgRef}
                src={fullPath}
                alt={alt}
                className={classNames({
                    [`img-height-${height}`]: height,
                    [`img-width-${width}`]: width,
                })}
            />
        </Component>
    );
};

Image.propTypes = {
    alt: string,
    ariaHiden: bool,
    ariaLabel: string,
    className: string,
    color: string,
    externalHref: string,
    height: string,
    href: string,
    imageMd: bool,
    imageRight: bool,
    imageSm: bool,
    imageXs: bool,
    isMobileNative: bool,
    location: shape({ pathname: string }),
    src: string.isRequired,
    width: string,
    wrapperClassName: string,
    wrapperHeight: string,
    wrapperWidth: string,
};

Image.defaultProps = {
    alt: "",
    ariaHiden: false,
    ariaLabel: null,
    className: "svg-image",
    color: null,
    externalHref: null,
    height: null,
    href: null,
    imageMd: false,
    imageRight: false,
    imageSm: false,
    imageXs: false,
    isMobileNative: false,
    location: null,
    width: null,
    wrapperClassName: "svg-wrapper",
    wrapperHeight: null,
    wrapperWidth: null,
};

export default resizableRoute(withRouter(Image));
