import React, { useEffect } from "react";
import Notification from "pages/_components/Notification";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { bool, func } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import { actions, selectors } from "reducers/settings";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import { INSERT_SECURITY_QUESTION, UPDATE_SECURITY_QUESTION } from "constants.js";
import WithPermissions from "pages/_components/WithPermissions";

const SecurityQuestions = ({
    goToDesktop,
    goToUpdate,
    goToRegister,
    getSecurityQuestions,
    isFetching,
    enabledUpdate,
    actionNotAllowed,
    hasRegisteredQuestions,
}) => {
    useEffect(() => {
        getSecurityQuestions();
    }, []);

    const handleBack = () => {
        goToDesktop();
    };

    const handleRegister = () => {
        if (hasRegisteredQuestions) {
            if (enabledUpdate) {
                goToUpdate();
            } else {
                actionNotAllowed();
            }
        } else {
            goToRegister();
        }
    };

    // const hasResgistered = registeredQuestions && Object.keys(registeredQuestions).length > 0;

    return (
        <>
            <Notification scopeToShow="securityQuestions" />
            <Head onBack={handleBack} title="securityQuestions.title" />
            <MainContainer
                showLoader={isFetching}
                className={classNames(
                    "main-container border-radius-lg box-shadow-small background-white texture-header",
                    {
                        "p-7": isDesktop,
                        "p-5": !isDesktop,
                    },
                )}>
                <Box
                    borderRadius="lg"
                    fullWidth
                    display="flex"
                    className="px-7 py-6 mb-7 box-shadow-small background-white">
                    <Box display="flex" alignY="center" fullWidth>
                        <Box fullWidth>
                            <Box>
                                <Text component="h3" labelKey="securityQuestions.index.title" bold />
                            </Box>
                            <Box className="mt-4">
                                <Text labelKey="securityQuestions.index.info" color="text-grey-color" light />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" alignY="center" className="width-security ml-3">
                        <Image src="images/icons/security_header.svg" />
                    </Box>
                </Box>

                <Row
                    gapX="7"
                    className={classNames({
                        "row-template-1fr": !isDesktop,
                    })}>
                    <Col xs="4">
                        <WithPermissions
                            permissions={[
                                hasRegisteredQuestions ? UPDATE_SECURITY_QUESTION : INSERT_SECURITY_QUESTION,
                            ]}>
                            <Box
                                borderRadius="lg"
                                flex1
                                pointer
                                className="px-7 py-7 mb-7 box-shadow-small"
                                onClick={() => handleRegister()}>
                                <Box display="flex" alignX="between">
                                    <Box fitWidth className="mb-2">
                                        <Image src="images/icons/icn-security.svg" color="transparent" />
                                    </Box>
                                </Box>
                                <Box className="mt-3">
                                    <Text
                                        bold
                                        labelKey={`securityQuestions.option.${
                                            hasRegisteredQuestions ? "update" : "register"
                                        }.title`}
                                    />
                                </Box>
                                <Box className="mt-3">
                                    <Text
                                        size="6"
                                        labelKey={`securityQuestions.option.${
                                            hasRegisteredQuestions ? "update" : "register"
                                        }.info`}
                                    />
                                </Box>
                            </Box>
                        </WithPermissions>
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};

SecurityQuestions.propTypes = {
    goToDesktop: func.isRequired,
    goToUpdate: func.isRequired,
    goToRegister: func.isRequired,
    getSecurityQuestions: func.isRequired,
    actionNotAllowed: func.isRequired,
    isFetching: bool,
    enabledUpdate: bool,
    hasRegisteredQuestions: bool,
};

SecurityQuestions.defaultProps = {
    isFetching: false,
    enabledUpdate: true,
    hasRegisteredQuestions: false,
};

const mapStateToProps = (state) => ({
    hasRegisteredQuestions: selectors.getHasRegisteredQuestions(state),
    enabledUpdate: selectors.isEnabledUpdate(state),
    isFetching: selectors.isFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
    getSecurityQuestions: () => {
        dispatch(actions.getSecurityQuestions());
    },
    goToDesktop: () => {
        dispatch(routerActions.push("/desktop"));
    },
    goToUpdate: () => {
        dispatch(routerActions.push("/updateQuestions"));
    },
    goToRegister: () => {
        dispatch(routerActions.push("/registerQuestions"));
    },
    actionNotAllowed: () => {
        dispatch(
            notificationActions.showNotification(i18n.get("securityQuestions.action.update.limit.message"), "warning", [
                "securityQuestions",
            ]),
        );
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityQuestions);
