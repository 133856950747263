import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func } from "prop-types";
import React, { useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push, replace } from "react-router-redux";
import { actions as enrollmentActions } from "reducers/enrollment";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";

const Start = (props) => {
    const { dispatch, isDesktop, isActiveCorporate } = props;

    useEffect(() => {
        dispatch(enrollmentActions.clean());
        if (!isActiveCorporate) {
            dispatch(replace("/enrollment/document"));
        }
    }, []);

    const handleGoClick = (isSecondaryUser) => {
        if (isSecondaryUser) {
            dispatch(push("/enrollment/document"));
        } else {
            dispatch(push("/enrollment/invitationCode"));
        }
    };

    return (
        <>
            <Box
                component="header"
                display="flex"
                {...(!isDesktop && { alignY: "center" })}
                className="py-7"
                fullWidth
                column>
                <Text component="h1" color="heading" bold labelKey="enrollment.start.title" />
                <Box display="flex" alignX="center" fullWidth>
                    <Text
                        component="h2"
                        className="m-0"
                        labelKey="enrollment.start.subtitle"
                        size="3"
                        bold
                        color="primary"
                        align="center"
                    />
                </Box>
            </Box>

            <Row>
                <Col xs={12} md={10} mdOffset={1}>
                    <Row>
                        <Col xs={12} md={6}>
                            <Box
                                component="article"
                                display="flex"
                                column
                                alignX="center"
                                alignY="between"
                                className="pt-6 pt-md-9 pb-5 pb-md-9 px-8 px-md-7"
                                background="white"
                                borderRadius="default"
                                fullWidth
                                fullHeight>
                                <Text
                                    component="h3"
                                    className="mt-0 mb-5 mb-md-8"
                                    color="text"
                                    size={isDesktop ? "4" : "3"}
                                    bold
                                    align="center"
                                    labelKey="enrollment.start.corporate"
                                />
                                <Box display="flex" alignY="flex-end" className="mb-4 mb-md-8 access-image-wrapper">
                                    <Image src="images/newUserCorp.svg" />
                                </Box>
                                <Text
                                    component="p"
                                    className="mb-6 mb-md-11 mt-0"
                                    size="6"
                                    align="center"
                                    labelKey="enrollment.start.corporate.description"
                                />
                                <Button
                                    bsStyle="outline"
                                    className="px-11"
                                    label="enrollment.start.select"
                                    onClick={() => handleGoClick(true)}
                                />
                            </Box>
                        </Col>
                        <Col xs={12} md={6}>
                            <Box
                                component="article"
                                display="flex"
                                column
                                alignX="center"
                                alignY="between"
                                className="pt-6 pt-md-9 pb-5 pb-md-9 px-8 px-md-7"
                                background="white"
                                borderRadius="default"
                                fullWidth
                                fullHeight>
                                <Text
                                    component="h3"
                                    className="mt-0 mb-5 mb-md-8"
                                    color="text"
                                    size={isDesktop ? "4" : "3"}
                                    bold
                                    align="center"
                                    labelKey="enrollment.start.natural"
                                />
                                <Box display="flex" alignY="flex-end" className="mb-4 mb-md-8 access-image-wrapper">
                                    <Image src="images/newUser.svg" />
                                </Box>
                                <Text
                                    component="p"
                                    className="mb-6 mb-md-11 mt-0"
                                    size="6"
                                    align="center"
                                    labelKey="enrollment.start.natural.description"
                                />
                                <Button
                                    bsStyle="outline"
                                    className="px-11"
                                    label="enrollment.start.select"
                                    onClick={() => handleGoClick(false)}
                                />
                            </Box>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

Start.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isActiveCorporate: bool.isRequired,
};
const mapStateToProps = (state) => ({
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
});

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(Start));
