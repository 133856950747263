import * as form from "middleware/form";
import * as products from "middleware/products";
import { CreditCardStatus } from "pages/_components/CreditCard/CreditCard";
import { actions as changeStatusProductActions, types } from "reducers/changeStatusProduct";
import { actions as creditCardsActions, selectors as creditCardsSelectors } from "reducers/creditCard";
import { actions as notificationActions } from "reducers/notification";
import { actions as productActions } from "reducers/products";
import { actions as widgetsActions, selectors as widgetsSelectors } from "reducers/widgets";
import { actions as debitCardsActions } from "reducers/debitCards";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18n from "util/i18n";
import { actions as accountsActions } from "reducers/accounts";

const changeStatusProductSagas = [
    takeLatest(types.CHANGE_PRODUCT_STATE_REQUEST, changeProductState),
    takeLatest(types.CREDENTIALS_GROUPS_REQUEST, getcredencialsGroups),
];

function* updateDataWidget(widget, idProduct, newStatus, productionStatusExpected) {
    const productsWidgets = yield select(widgetsSelectors.getWidget, widget);
    if (productsWidgets?.data?.creditCards) {
        const newListCreditCardsWidget = productsWidgets.data.creditCards.map((item) => {
            if (item.idProduct === idProduct) {
                return {
                    ...item,
                    blocked: newStatus !== CreditCardStatus.Actived,
                    statusCode: newStatus,
                    productionStatus: productionStatusExpected,
                };
            }
            return item;
        });
        yield put(widgetsActions.listSuccess(widget, { [widget]: newListCreditCardsWidget }));
    }
}

function* updateListCreditCards(idProduct, newStatus, productionStatusExpected) {
    const creditCards = yield select(creditCardsSelectors.getList);
    if (creditCards) {
        const newListCreditCards = creditCards?.map((item) => {
            const newItem = { ...item };
            if (item.idProduct === idProduct) {
                const blocked = newStatus !== CreditCardStatus.Actived;
                return { ...newItem, blocked, statusCode: newStatus, productionStatus: productionStatusExpected };
            }
            if (item?.additionalCreditCardList !== null && item?.additionalCreditCardList.length > 0) {
                const newAdditional = item.additionalCreditCardList.map((itemAdditional) => {
                    if (itemAdditional.idProduct === idProduct) {
                        const blocked = newStatus !== CreditCardStatus.Actived;
                        return {
                            ...itemAdditional,
                            blocked,
                            statusCode: newStatus,
                            productionStatus: productionStatusExpected,
                        };
                    }
                    return itemAdditional;
                });
                newItem.additionalCreditCardList = newAdditional;
            }
            return newItem;
        });
        yield put(creditCardsActions.listSuccess(newListCreditCards));
    }
}

export default changeStatusProductSagas;

function* changeProductState({ data }) {
    const {
        parametersSubmit,
        credentialsForm,
        formikBag,
        idActivity,
        statusExpected,
        productType,
        actionName,
        paramsNotification,
        productionStatusExpected,
    } = data;
    const response = yield call(products.changeProductStatus, idActivity, parametersSubmit, credentialsForm);

    if (response?.status === 200) {
        if (response.data.code === "COR020W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else if (response.data.code === "COR000I") {
            yield put(changeStatusProductActions.modalHide());
            if (productType === "creditCards") {
                yield call(updateDataWidget, productType, data.idProduct, statusExpected, productionStatusExpected);
                yield call(updateListCreditCards, data.idProduct, statusExpected, productionStatusExpected);
                yield put(productActions.setSelectProduct({ ...data, statusCode: statusExpected }));
            }
            if (productType === "debitCards") {
                yield put(debitCardsActions.listRequest());
            }
            if (productType === "accounts") {
                yield put(accountsActions.listAccounts());
            }
            yield put(
                notificationActions.showNotification(
                    i18n.get(`${productType}.snackbar.${actionName}.messageOk`, null, paramsNotification?.success),
                    "success",
                    ["desktop", "creditcards", "creditCardDetails", "account/details", "debitcards"],
                ),
            );
        } else {
            yield put(changeStatusProductActions.modalHide());
            yield put(
                notificationActions.showNotification(
                    i18n.get(`${productType}.snackbar.${actionName}.messageError`, null, paramsNotification?.error),
                    "error",
                    ["desktop", "creditcards", "creditCardDetails", "account/details", "debitcards"],
                ),
            );
        }
    } else {
        yield put(changeStatusProductActions.modalHide());
        yield put(
            notificationActions.showNotification(response.data.message, "error", [
                "desktop",
                "creditcards",
                "creditCardDetails",
                "account/details",
                "debitcards",
            ]),
        );
    }
    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* getcredencialsGroups({ data }) {
    const { idActivity } = data;
    const response = yield call(form.listCredentialsGroups, "", idActivity);

    if (response && response.status === 200) {
        yield put({
            type: types.CREDENTIALS_GROUPS_SUCCESS,
            credentialsGroups: response.data.data.groups,
        });
    } else {
        yield put(changeStatusProductActions.modalHide());
        yield put(notificationActions.showNotification(response.data.message, "error", ["changeStatusProduct"]));
    }
}
