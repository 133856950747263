import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

// Action types
export const types = {
    CLEAN_UP: "recoveryPassword/CLEAN",

    RECOVERY_PASS_BACK_TO_STEP_1: "recoveryPassword/BACK_TO_STEP_1",
    RECOVERY_PASS_STEP1_REQUEST: "recoveryPassword/RECOVERY_PASS_STEP1_REQUEST",
    RECOVERY_PASS_STEP2_REQUEST: "recoveryPassword/RECOVERY_PASS_STEP2_REQUEST",
    RECOVERY_PASS_STEP3_REQUEST: "recoveryPassword/RECOVERY_PASS_STEP3_REQUEST",
    RECOVERY_PASS_STEP4_REQUEST: "recoveryPassword/RECOVERY_PASS_STEP4_REQUEST",

    RECOVERY_PASS_STEP1_SUCCESS: "recoveryPassword/RECOVERY_PASS_STEP1_SUCCESS",
    RECOVERY_PASS_STEP2_SUCCESS: "recoveryPassword/RECOVERY_PASS_STEP2_SUCCESS",
    RECOVERY_PASS_STEP3_SUCCESS: "recoveryPassword/RECOVERY_PASS_STEP3_SUCCESS",
    RECOVERY_PASS_STEP4_SUCCESS: "recoveryPassword/RECOVERY_PASS_STEP4_SUCCESS",

    RECOVERY_PASS_STEP3_FAILURE_REQUIRE_CAPTCHA: "recoveryPassword/RECOVERY_PASS_STEP3_FAILURE_REQUIRE_CAPTCHA",

    RECOVERY_PASS_FAILURE_REQUIRE_CAPTCHA: "recoveryPassword/RECOVERY_PASS_FAILURE_REQUIRE_CAPTCHA",
    RECOVERY_RESEND_OTP_AUTHENTICATE_CODE_REQUEST: "recoveryPassword/RECOVERY_RESEND_OTP_AUTHENTICATE_CODE_REQUEST",
};

// Initial state
export const INITIAL_STATE = {
    username: null,
    email: null,
    exchangeToken: null,
    showCaptcha: false,

    resetCode: null,
    preData: null,
    contact: "",
    idTransaction: "",
    operationType: "",
    questions: [],
};

// Reducer
const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.CLEAN_UP:
        case types.RECOVERY_PASS_BACK_TO_STEP_1:
        case types.RECOVERY_PASS_STEP4_SUCCESS:
            return { ...INITIAL_STATE };
        case types.RECOVERY_PASS_STEP1_SUCCESS:
            return {
                ...state,
                username: action.username,
                email: action.email,
                showCaptcha: false,
                exchangeToken: action.exchangeToken,
            };
        case types.RECOVERY_PASS_STEP2_SUCCESS:
            return {
                ...state,
                exchangeToken: action.exchangeToken,
                showCaptcha: false,
                idTransaction: action.idTransaction,
            };
        case types.RECOVERY_PASS_STEP3_SUCCESS:
            return { ...state, exchangeToken: action.exchangeToken, showCaptcha: false };
        case types.RECOVERY_PASS_STEP3_FAILURE_REQUIRE_CAPTCHA:
            return { ...state, showCaptcha: true };
        default:
            return state;
    }
};

export default persistReducer(
    {
        key: "recoveryPassword",
        storage: storageSession,
        blacklist: [],
    },
    reducer,
);

// Action creators
export const actions = {
    backToStep1: () => ({
        type: types.RECOVERY_PASS_BACK_TO_STEP_1,
    }),
    recoveryPassStep1: (username, email, formikBag) => ({
        type: types.RECOVERY_PASS_STEP1_REQUEST,
        username,
        email,
        formikBag,
    }),
    recoveryPassStep2: (secondFactor, exchangeToken, formikBag) => ({
        type: types.RECOVERY_PASS_STEP2_REQUEST,
        secondFactor,
        exchangeToken,
        formikBag,
    }),
    recoveryPassStep3: (newPassword, newPasswordRepeat, exchangeToken, formikBag) => ({
        type: types.RECOVERY_PASS_STEP3_REQUEST,
        newPassword,
        newPasswordRepeat,
        exchangeToken,
        formikBag,
    }),
    recoveryPassStep4: () => ({
        type: types.RECOVERY_PASS_STEP4_REQUEST,
    }),
    recoveryPassCleanUp: () => ({
        type: types.CLEAN_UP,
    }),
    recoveryPassResendOtp: () => ({
        type: types.RECOVERY_RESEND_OTP_AUTHENTICATE_CODE_REQUEST,
    }),
};

// Selectors
export const selectors = {
    getPreData: ({ recoveryPassword }) => recoveryPassword.preData,
    getUsername: ({ recoveryPassword }) => recoveryPassword.username,
    getNotificationType: ({ recoveryPassword }) => recoveryPassword.notificationType,
    getExchangeToken: ({ recoveryPassword }) => recoveryPassword.exchangeToken,
    getShowCaptcha: ({ recoveryPassword }) => recoveryPassword.showCaptcha,
    getContact: ({ recoveryPassword }) => recoveryPassword.contact,
    getIdTransaction: ({ recoveryPassword }) => recoveryPassword.idTransaction,
    getEmail: ({ recoveryPassword }) => recoveryPassword.email,
};
