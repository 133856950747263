import classNames from "classnames";
import { format } from "date-fns";
import Box from "pages/_components/Box";
import CircularProgressBar from "pages/_components/CircularProgressBar";
import { Collapse } from "react-bootstrap";
import Image from "pages/_components/Image";
import Info from "pages/_components/Info";
import InfoCard from "pages/_components/InfoCard";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, shape } from "prop-types";
import React, { useState } from "react";
import Col from "react-bootstrap/lib/Col";
import {parseISOAlternative, formatDate }  from "util/date";

import * as i18n from "util/i18n";

const DetailHeadInfo = ({ isDesktop, product }) => {
    const startDate = product.constitutedDate ? format(product.constitutedDate, "DD/MM/YYYY") : null;
    // const lastPaymentDay = product.lastPaymentDay ? format(product.lastPaymentDay, "DD/MM/YYYY") : null;
    const numberPayFees = product.numberOfPaidFees;
    const pendingFees = product.numberOfFees - numberPayFees;
    const [moreDetails, setMoreDetails] = useState(false);
    
    const getNextDueDate = (nextDueDate) => {
        const parsedDate = parseISOAlternative(nextDueDate);
        return formatDate(parsedDate)
    }

    const nextDueDatedFormatted  = getNextDueDate(product.nextDueDate);
    const startDateFormatted = getNextDueDate(product.constitutedDate);

    const moreDetailsHandler = () => {
        setMoreDetails(!moreDetails);
    };

    const getTypeTitle = () => {
        let label = "";
        if (product.groupCode === "FACTORING") {
            label = "loans.num.type.factoring";
        } else if (product.groupCode === "CARTERA") {
            label = "loans.num.type.cartera";
        } else if (product.groupCode === "COMEXT") {
            label = "loans.num.type.comext";
        }
        return label;
    };

    const renderTextProgressBar = () => (
        <>
            <strong>{i18n.get("loans.detail.circular.progress.label.1")}</strong>
            <strong>
                {i18n.get("loans.detail.circular.progress.label.2", null, {
                    PERCENTAGE: product.paidPercentage,
                })}
            </strong>
        </>
    );

    const renderDetailsBase = () => (
        <Box
            component="article"
            display="flex"
            borderRadius="default"
            className={!isDesktop ? "p-5 mb-5 background-white" : "p-7 background-white"}>
            <Row className="px-0">
                <Col xs={12} md={6}>
                    <Box
                        component="div"
                        display="flex"
                        borderRadius="default"
                        className={!isDesktop ? "box-shadow-small" : "p-8 box-shadow-small"}
                        column={!isDesktop}>
                        <Row className="px-0">
                            <Col xs={12} md={4}>
                                <Box display="flex" alignX="center">
                                    <div style={{ width: 130, height: 145 }}>
                                        <CircularProgressBar percentage={product.paidPercentage}>
                                            {renderTextProgressBar()}
                                        </CircularProgressBar>
                                    </div>
                                </Box>
                            </Col>
                            <Col xs={12} md={6}>
                                <Box display="flex" className="ml-4" column={isDesktop}>
                                    <Box component="article" display="flex" borderRadius="default">
                                        <InfoCard
                                            title="loans.payment.fees"
                                            isDesktop={isDesktop}
                                            message={numberPayFees}
                                        />
                                    </Box>
                                    <Box component="article" display="flex" borderRadius="default">
                                        <InfoCard
                                            title="loans.pending.fees"
                                            isDesktop={isDesktop}
                                            message={pendingFees}
                                        />
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </Box>
                </Col>
                <Col xs={12} md={6} className="full-height">
                    <Box
                        component="div"
                        display="flex"
                        borderRadius="default"
                        className={!isDesktop ? "box-shadow-small" : "p-7 mb-5 box-shadow-small"}>
                        <Row className="px-0">
                            <Col>
                                <InfoCard
                                    title="loan.detail.type.loan.label"
                                    isDesktop={isDesktop}
                                    message={product.alias}
                                />
                            </Col>
                            <Col>
                                <Row>
                                    <Col xs={12} md={6} className="full-height">
                                        <InfoCard
                                            title="loan.detail.due.date.label"
                                            isDesktop={isDesktop}
                                            message={nextDueDatedFormatted}
                                        />
                                    </Col>
                                    <Col xs={12} md={6} className="full-height">
                                        <Box fullWidth className={isDesktop ? "text-webkit-right border-left" : ""}>
                                            <Box className="fit-content-width text-left">
                                                <InfoCard
                                                    className={classNames({ "credit-balance-content-end": isDesktop })}
                                                    title={isDesktop ? "loan.detail.amount.balance.label" : "loan.detail.amount.balance.mobile.label"}
                                                    isDesktop={isDesktop}
                                                    currency={product.currency}
                                                    quantity={product.currentBalance}
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Box>
                </Col>
            </Row>
        </Box>
    );

    const renderDetailsComex = () => (
        <Box component="article" display="flex" borderRadius="default" className="p-7 mb-5 box-shadow-small">
            <Row
                gapY={0}
                className={classNames("px-0", {
                    "children-border-right ": isDesktop,
                    "gap-0": !isDesktop,
                })}>
                <Col xs={12} md={6} className="full-height">
                    <InfoCard title="loan.detail.due.date.comex.label" isDesktop={isDesktop} message={nextDueDatedFormatted} />
                </Col>
                <Col xs={12} md={6} className="full-height">
                    <InfoCard
                        className={classNames({ "credit-balance-content-end": isDesktop })}
                        title="loan.detail.amount.balance.comex.label"
                        isDesktop={isDesktop}
                        currency={product.currency}
                        quantity={product.paidAmount}
                    />
                </Col>
            </Row>
        </Box>
    );

    const renderMoreDetailsBase = () => (
        <Box
            className={classNames({
                "px-6": isDesktop,
                "px-5": !isDesktop,
            })}>
            <Box display={isDesktop && "flex"} className={`${isDesktop ? "mt-4" : "mt-5"}`}>
                <Info labelKey="loan.details.official.name" text={product.officialName} flex={!isDesktop} />
                <Info labelKey="loan.details.branch.name" text={product.branchName} flex={!isDesktop} />
                <Info labelKey="loan.details.constituted.date" text={startDate} flex={!isDesktop} />
                <Info labelKey="loan.details.currency.name" text={product.currency} flex={!isDesktop} />
            </Box>
            <Box display={isDesktop && "flex"} className={`${isDesktop ? "mt-7" : "mt-5"}`}>
                <Info labelKey="loan.details.rate.anual" text={`${product.interestRate}%`} flex={!isDesktop} />
                <Info labelKey="loan.details.rate.current" text={`${product.rate}%`} flex={!isDesktop} />
                <div className="info-data full-width"> </div>
                <div className="info-data full-width"> </div>
            </Box>
        </Box>
    );

    const renderMoreDetailsComex = () => (
        <Box
            className={classNames({
                "px-6": isDesktop,
                "px-5": !isDesktop,
            })}>
            <Box display={isDesktop && "flex"} className={`${isDesktop ? "mt-4" : "mt-5"}`}>
                <Info labelKey="loan.details.constituted.date" text={startDateFormatted} flex={!isDesktop} />
                <Info labelKey="loan.details.client.name" text={product.ownerName} flex={!isDesktop} />
                <Info labelKey="loan.details.official.name" text={product.officialName} flex={!isDesktop} />
                <Info labelKey="loan.details.currency.name" text={product.currency} flex={!isDesktop} />
            </Box>
            <Box display={isDesktop && "flex"} className={`${isDesktop ? "mt-7" : "mt-5"}`} alignY="right">
                <Info labelKey="loan.detail.type.loan.label" text={product.alias} flex={!isDesktop} />
                <Info
                    labelKey="loan.detail.amount.original.label"
                    amount={product.totalAmount}
                    currency={product.currency}
                    flex={!isDesktop}
                />
                <div className="info-data full-width"> </div>
                <div className="info-data full-width"> </div>
            </Box>
        </Box>
    );

    const renderMoreDetails = () => (
        <Box display="flex" className={isDesktop ? "py-7 border-radius-default" : "p-5 border-radius-default"}>
            <Box borderRadius="lg" background="background-disabled" fullWidth className="p-4">
                <Box
                    borderRadius="lg"
                    display="flex"
                    className={classNames({
                        "py-4 px-6": isDesktop,
                        "p-5": !isDesktop,
                    })}
                    onClick={moreDetailsHandler}
                    pointer
                    fullWidth>
                    <Box fullWidth>
                        <Text align="left" labelKey="global.moreDetails" bold />
                    </Box>
                    <Box alignY="center">
                        <Image src={`images/util/arrow-${moreDetails ? "up" : "down"}.svg`} />
                    </Box>
                </Box>
                <Collapse in={moreDetails}>
                    {product.groupCode === "CARTERA" || product.groupCode === "FACTORING"
                        ? renderMoreDetailsBase()
                        : renderMoreDetailsComex()}
                </Collapse>
            </Box>
        </Box>
    );

    return (
        <>
            <Box className="detail-head-info pt-0 background-white">
                <Box
                    component="article"
                    display="flex"
                    borderRadius="default"
                    className="p-7"
                    fullWidth={!isDesktop}
                    column={!isDesktop}>
                    <Box display="flex" className={isDesktop ? "mr-4" : "mb-4"} alignY={isDesktop && "center"}>
                        <Image
                            src="images/products/icon_PA.svg"
                            wrapperClassName="item-info-icon item-info-icon color-strong-primary"
                        />
                    </Box>
                    <Box fullWidth className="mb-2">
                        <Text
                            {...(isDesktop && { size: "3" })}
                            {...(!isDesktop && { uppercase: true, size: "6" })}
                            semibold>
                            {`${i18n.get(getTypeTitle())} ${product?.number}`}
                        </Text>
                        <Box className="mt-2">
                            <Text labelKey="loans.title.description" />
                        </Box>
                    </Box>
                </Box>
                {product.groupCode === "CARTERA" || product.groupCode === "FACTORING"
                    ? renderDetailsBase()
                    : renderDetailsComex()}
                {renderMoreDetails()}
            </Box>
        </>
    );
};

DetailHeadInfo.propTypes = {
    product: shape({}).isRequired,
    isDesktop: bool.isRequired,
};

export default DetailHeadInfo;
