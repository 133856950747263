import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, func } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { compose } from "redux";
import EnrollmentWrapper from "../_components/EnrollmentWrapper";

class Finish extends Component {
    goToLogin = () => {
        const { dispatch } = this.props;
        dispatch(push("/loginStep1"));
    };

    content = () => {
        const { isDesktop } = this.props;
        return (
            <>
                <Box>
                    <Col xs={12} md={8} mdOffset={2}>
                        <Box display="flex" column alignX="center" background="white">
                            <Box display="flex" alignX="center" className="mb-8">
                                <Box className="box-image-success">
                                    <Image src="images/util/success.gif" />
                                </Box>
                            </Box>
                            <Box display="flex" alignX="center" fullWidth className="mb-7">
                                <Text
                                    size={isDesktop ? "3" : "2"}
                                    align="center"
                                    bold
                                    labelKey="enrollment.step6.title"
                                />
                            </Box>
                            <Box display="flex" alignX="center" fullWidth className="mb-6">
                                <Text align="center" labelKey="enrollment.step6.subject" />
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} md={8} mdOffset={2} className="align-center">
                        <Button
                            bsStyle="primary"
                            label="enrollment.step6.button.label"
                            onClick={() => {
                                this.goToLogin();
                            }}
                            block
                        />
                    </Col>
                </Box>
            </>
        );
    };

    render() {
        const { isDesktop } = this.props;

        return (
            <EnrollmentWrapper isDesktop={isDesktop}>
                <>{this.content()}</>
            </EnrollmentWrapper>
        );
    }
}

Finish.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
};

export default compose(connect())(Finish);
