import { Field, Form, withFormik } from "formik";
import withTransactionFilterContext from "hoc/withTransactionFilterContext";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Selector from "pages/_components/fields/formik/Selector";
import { bool, func, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "transactions.list.filters.status";

class StatusFilter extends Component {
    static propTypes = {
        selectedOperationType: string.isRequired,
        setFieldValue: func.isRequired,
        fetching: bool.isRequired,
        setStatus: func,
        // setFilterValues: func.isRequired,
    };

    static defaultProps = {
        setStatus: () => {},
    };

    componentDidUpdate(prevProps) {
        if (this.props.selectedOperationType !== prevProps.selectedOperationType) {
            this.props.setFieldValue("operationType", this.props.selectedOperationType);
        }
    }

    render() {
        const { fetching } = this.props;

        const statusOptions = [
            {
                value: "all",
                label: i18n.get("global.all"),
            },
            {
                value: "FINISHED",
                label: i18n.get("transactions.list.filter.searchBy.status.FINISHED"),
            },
            // {
            //     value: "SCHEDULED",
            //     label: i18n.get("transactions.list.filter.searchBy.status.SCHEDULED"),
            // },
            // {
            //     value: "DELETED",
            //     label: i18n.get("transactions.list.filter.searchBy.status.DELETED"),
            // },
            {
                value: "CANCELLED",
                label: i18n.get("transactions.list.filter.searchBy.status.CANCELLED"),
            },
            // {
            //     value: "RETURNED",
            //     label: i18n.get("transactions.list.filter.searchBy.status.RETURNED"),
            // },
            {
                value: "FAILED",
                label: i18n.get("transactions.list.filter.searchBy.status.FAILED"),
            },
        ];

        return (
            <Form>
                <Container className="container--layout items-center" gridClassName="form-content">
                    <Col xs={12} md={4}>
                        <Box className="form-group form-group--select">
                            <Field
                                component={Selector}
                                options={statusOptions}
                                idForm="transactions.list.filters.searchBy"
                                name="status"
                                placeholder=""
                                handleChange={(status) => {
                                    const { setStatus } = this.props;
                                    setStatus([status]);
                                }}
                            />
                        </Box>
                    </Col>

                    <Col sm={12} md={4} className="height-auto">
                        <Box display="flex" alignY="flex-end" fullHeight>
                            <Button bsStyle="primary" label="global.search" block loading={fetching} type="submit" />
                        </Box>
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: (props) => ({
        status: props.filterValues?.status || "all",
    }),
    validationSchema: () =>
        Yup.object().shape({
            status: Yup.string().required(i18n.get(`${FORM_ID}.searchBy.required`)),
        }),
    handleSubmit: ({ ...filters }, formikBag) => {
        const { handleClick } = formikBag.props;
        handleClick(filters);
    },
})(withTransactionFilterContext(StatusFilter));
