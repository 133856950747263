import React from "react";
import { func, number } from "prop-types";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Button from "pages/_components/Button";
import { isDesktop } from "react-device-detect";
import { noop } from "./utils";

const PaginatorButtonsBar = ({ page, totalpages, goToFirstPage, goToPreviousPage, goToNextPage, goToLastPage }) => {
    const disablePrev = page <= 1;
    const disableNext = page === totalpages;

    const handleClick = (onClick) => {
        if (isDesktop) {
            onClick();
        } else {
            // mobile IOS - brebe retraso para evitar clic en detalle
            setTimeout(() => {
                onClick();
            }, 140);
        }
    };

    return (
        <Box className="paginator-bar" display="flex" fullWidth alignX="center">
            <Box>
                <Button
                    className="btn-prev-page"
                    image="images/util/caret-left-double.svg"
                    bsStyle="link"
                    disabled={disablePrev}
                    onClick={() => (disablePrev ? noop : handleClick(goToFirstPage))}
                />
                <Button
                    className="btn-prev-page ml-2"
                    image="images/util/caret-left.svg"
                    bsStyle="link"
                    disabled={disablePrev}
                    onClick={() => (disablePrev ? noop : handleClick(goToPreviousPage))}
                />
            </Box>
            <Box display="flex" alignY="center" className="ml-5 mr-5">
                <Text
                    labelKey="global.pagination.label"
                    className="paginator-text"
                    size="5"
                    page={page}
                    total={totalpages}
                />
            </Box>
            <Box>
                <Button
                    className="btn-next-page"
                    image="images/util/caret-right.svg"
                    bsStyle="link"
                    disabled={disableNext}
                    onClick={() => (disableNext ? noop : handleClick(goToNextPage))}
                />
                <Button
                    className="btn-next-page ml-2"
                    image="images/util/caret-right-double.svg"
                    bsStyle="link"
                    disabled={disableNext}
                    onClick={() => (disableNext ? noop : handleClick(goToLastPage))}
                />
            </Box>
        </Box>
    );
};

PaginatorButtonsBar.propTypes = {
    page: number,
    totalpages: number,
    goToFirstPage: func,
    goToPreviousPage: func,
    goToNextPage: func,
    goToLastPage: func,
};

PaginatorButtonsBar.defaultProps = {
    page: 0,
    totalpages: 0,
    goToFirstPage: () => {},
    goToPreviousPage: () => {},
    goToNextPage: () => {},
    goToLastPage: () => {},
};

export default PaginatorButtonsBar;
