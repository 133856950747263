import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import ShortCutBtn from "pages/_components/ShortCutBtn";
import Text from "pages/_components/Text";
import { bool, func } from "prop-types";
import React, { useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux/actions";
import { actions as wallyActions, selectors as wallySelectors } from "reducers/wally";
import { compose } from "redux";
import * as i18n from "util/i18n";

const OneTouchAccessWally = (props) => {
    const { dispatch, isActiveWaly, fetching } = props;
    const handleClose = () => {
        dispatch(push("/desktop"));
    };

    useEffect(() => {
        dispatch(wallyActions.userWallyRequest());
    }, []);

    useEffect(() => {
        if (isActiveWaly !== null) {
            if (!isActiveWaly) {
                dispatch(push("/enrollmentWally"));
            }
        }
    }, [isActiveWaly]);

    const buttonsArrayOptions = {
        wallyOpcions: [
            {
                image: "images/shortCutBtn/SCBsend.svg",
                label: "wally.home.send",
                href: "/sendWally",
            },
            {
                image: "images/shortCutBtn/SCBrequest.svg",
                label: "wally.home.request",
                href: "/requestWally",
            },
            {
                image: "images/shortCutBtn/SCBmovement.svg",
                label: "wally.home.movement",
                href: "/lastMovementsWally",
            },
            {
                image: "images/shortCutBtn/SCBpending.svg",
                label: "wally.home.pending",
                href: "/pendingWally",
                showNotification: true,
            },
            {
                image: "images/shortCutBtn/SCBsettings.svg",
                label: "wally.home.settings",
                href: "/settingsWally",
            },
        ],
    };

    const buttonsArray = buttonsArrayOptions.wallyOpcions;

    return (
        <PageLoading loading={fetching} classicStyle={false}>
            <Box display="flex" column alignY="flex-start" fullHeight fullWidth>
                <Box
                    component="header"
                    display="flex"
                    alignX="between"
                    background="wally"
                    className="pt-2-safe px-2 pb-2">
                    <Box display="flex" alignX="flex-start" alignY="center" className="p-4">
                        <Image height="9" src="images/icons/MbBolivarianoLogo.svg" />
                    </Box>

                    <Box display="flex" alignX="flex-end" alignY="center" className="p-4">
                        <Button
                            image="images/icons/cross16.svg"
                            className="btn-login py-4"
                            bsStyle="link"
                            onClick={handleClose}
                            inverse
                        />
                    </Box>
                </Box>
                <Box
                    component="main"
                    display="flex"
                    alignY="center"
                    alignX="center"
                    column
                    background="wally"
                    className="py-7"
                    fullHeight
                    fullWidth>
                    <Box display="flex" className="mb-10">
                        <Image src="images/wally-head.svg" height="5" />
                        <Text size="3" color="inverse" bold component="h1" className="ml-2">
                            {i18n.get("wally.home.title")}
                        </Text>
                    </Box>

                    <Box display="flex" alignY="center" className="px-12 mb-auto" fullWidth>
                        <Row gapX="3" gapY="5" className="height-auto px-6">
                            {buttonsArray.map((el) => (
                                <Col key={`wally-btn-${el.label}`} xs={6} className="height-auto">
                                    <Box display="flex" alignX="center" fullWidth fullHeight>
                                        <ShortCutBtn
                                            key={`${el.label}_${el.image}`}
                                            image={el.image}
                                            label={el.label}
                                            href={el.href}
                                            inverse
                                            showNotification={el.showNotification}
                                        />
                                    </Box>
                                </Col>
                            ))}
                        </Row>
                    </Box>
                </Box>
            </Box>
        </PageLoading>
    );
};

const mapStateToProps = (state) => ({
    isActiveWaly: wallySelectors.getIsActiveWally(state),
    fetching: wallySelectors.isFetching(state),
});

OneTouchAccessWally.propTypes = {
    dispatch: func.isRequired,
    isActiveWaly: bool,
    fetching: bool,
};

OneTouchAccessWally.defaultProps = {
    isActiveWaly: null,
    fetching: false,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(OneTouchAccessWally));
