import { string } from "prop-types";
import React from "react";

const FieldHelp = (props) => {
    const { text } = props;

    if (text) {
        // TODO: This component requires proper HTML ;)
        return (
            <div className="form-group-text">
                <span>{text}</span>
            </div>
        );
    }
    return null;
};

FieldHelp.propTypes = {
    text: string,
};

FieldHelp.defaultProps = {
    text: null,
};

export default FieldHelp;
