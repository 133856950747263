import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import GridTable from "pages/_components/GridTable/GridTable";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import withFocus from "pages/_components/withFocus";
import formField from "pages/forms/_components/_fields/_commons/formField";
import { arrayOf, bool, number, shape, string } from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import { compose } from "redux";
import * as numberUtils from "util/number";

const AmountPaymentFinancingPlans = (props) => {
    const {
        quantityTextAlign,
        data: { financingPlans, decimalSeparator, precision, thousandsSeparator, loading },
        isDesktop,
        form,
        boldAmount,
    } = props;

    const isSelectCreditCard = !!form?.values?.creditCard;

    const columnsTeplateDesktop = "1.5fr 1fr 1fr 1fr 1fr";
    const columnsTeplateMobile = "2fr minmax(max-content,1fr) 2.5rem minmax(5.75rem,1fr)";

    const getColumnsTemplate = () => (isDesktop ? columnsTeplateDesktop : columnsTeplateMobile);

    const onAmountChange = (value, id) => {
        const { amountPlans } = form?.values;
        let newAmountPlans;
        const quantity = numberUtils.createDecimalNumber(value, decimalSeparator, precision);

        if (quantity > 0) {
            const tempAmountPlans = amountPlans?.filter((item) => item.id !== id) || [];
            newAmountPlans = [...tempAmountPlans, { id, amount: { quantity: quantity || 0, currency: "USD" } }];
        } else if (!quantity || quantity === 0) {
            const tempAmountPlans = amountPlans?.filter((item) => item.id !== id) || [];
            newAmountPlans = [...tempAmountPlans];
        } else {
            newAmountPlans = amountPlans;
        }

        form.setFieldValue("amountPlans", newAmountPlans);
        const newAmount =
            newAmountPlans?.map((item) => parseFloat(item.amount.quantity)).reduce((a, b) => (a || 0) + (b || 0), 0) ||
            0;
        form.setFieldValue("amount", { quantity: newAmount, currency: "USD" });
    };

    const getValueById = (idFinancingPlan) => {
        const { amountPlans } = form?.values;
        const amount = amountPlans?.find((item) => item.id === idFinancingPlan)?.amount;
        return amount?.quantity || "";
    };

    return (
        <Box component="article" background="white" borderRadius="default" className="pt-6 min-height-10rem mx-n-5">
            <Text
                component="h2"
                size="4"
                bold
                className="ml-5 my-0 pb-5"
                labelKey="creditCard.financing.plans.tableForm.title"
            />

            <GridTable>
                <GridTable.Header>
                    <GridTable.Container
                        columnsTemplate={getColumnsTemplate()}
                        className={isDesktop ? "gapX-9" : "gapX-3"}
                        padding="py-2 px-5 px-md-5">
                        <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                            <Text labelKey="creditCard.financing.plans.header.table.description" />
                        </GridTable.Data>
                        {isDesktop && (
                            <GridTable.Data columnStart={2} alignX="center" inHeader>
                                <Text labelKey="creditCard.financing.plans.header.table.reference" />
                            </GridTable.Data>
                        )}
                        <GridTable.Data columnStart={isDesktop ? 3 : 2} alignX="flex-end" inHeader>
                            {isDesktop ? (
                                <Text labelKey="creditCard.financing.plans.header.table.amountInstallment" />
                            ) : (
                                <Text labelKey="tableHeader.quota" />
                            )}
                        </GridTable.Data>
                        <GridTable.Data
                            columnStart={isDesktop ? 5 : 4}
                            alignX={isDesktop ? "flex-start" : "flex-end"}
                            inHeader>
                            <Text labelKey="creditCard.financing.plans.header.table.amount" />
                        </GridTable.Data>
                    </GridTable.Container>
                </GridTable.Header>

                {loading && (
                    <GridTable.Body>
                        <PageLoading loading />
                    </GridTable.Body>
                )}
                {financingPlans?.length > 0 ? (
                    <GridTable.Body>
                        {isSelectCreditCard &&
                            !loading &&
                            financingPlans?.map((item) => (
                                <GridTable.Container
                                    className="product-data px-5"
                                    columnsTemplate={getColumnsTemplate()}
                                    rows={1}
                                    key={item.referenceNumber}>
                                    <GridTable.Data columnStart={1} alignX="flex-start">
                                        <Box display="flex" column withEllipsis>
                                            <Text
                                                uppercase
                                                {...(!isDesktop && {
                                                    color: "secondary",
                                                    bold: true,
                                                })}
                                                ellipsis>
                                                {item.description}
                                            </Text>
                                            {!isDesktop && <Text>{item.referenceNumber}</Text>}
                                        </Box>
                                    </GridTable.Data>
                                    {isDesktop && (
                                        <GridTable.Data columnStart={2} alignX="center">
                                            <Text>{item.referenceNumber}</Text>
                                        </GridTable.Data>
                                    )}
                                    <GridTable.Data columnStart={isDesktop ? 3 : 2} alignX="flex-end">
                                        {/* <Text>{item.monthlyFee}</Text> */}
                                        <FormattedAmount
                                            size={isDesktop ? "6" : "4"}
                                            color="text"
                                            quantity={item.openInstallmentBalance}
                                            noCurrency
                                        />
                                    </GridTable.Data>
                                    <GridTable.Data columnStart={isDesktop ? 4 : 3} alignX="flex-end" tabIndex="-1">
                                        <Box {...(isDesktop && { className: "mr-8" })}>
                                            <Button
                                                image="images/plusCircle.svg"
                                                bsStyle="link"
                                                {...(!isDesktop && { className: "px-2" })}
                                                onClick={() =>
                                                    onAmountChange(
                                                        typeof item?.openInstallmentBalance === "number"
                                                            ? item.openInstallmentBalance.toFixed(2)
                                                            : item.openInstallmentBalance.toString(),
                                                        item?.id,
                                                    )
                                                }
                                            />
                                        </Box>
                                    </GridTable.Data>
                                    <GridTable.Data
                                        tabIndex="-1"
                                        columnStart={isDesktop ? 5 : 4}
                                        alignX="flex-start"
                                        className="field-small-text">
                                        <NumberFormat
                                            id={`amount${item.referenceNumber}`}
                                            name={`amount${item.referenceNumber}`}
                                            className={classNames("form-control input-border", quantityTextAlign, {
                                                "text-bold": boldAmount,
                                            })}
                                            type="text"
                                            onChange={(event) => onAmountChange(event.target.value, item?.id)}
                                            maxLength="20"
                                            value={getValueById(item.id)}
                                            placeholder="0.00"
                                            thousandSeparator={thousandsSeparator}
                                            decimalSeparator={decimalSeparator}
                                            fixedDecimalScale
                                            autoComplete="off"
                                            inputMode="decimal"
                                        />
                                    </GridTable.Data>
                                </GridTable.Container>
                            ))}
                    </GridTable.Body>
                ) : (
                    <GridTable.Body>
                        <GridTable.Container
                            className="product-data px-5"
                            columnsTemplate={getColumnsTemplate()}
                            rows={1}
                            border="top-background-border-disabled">
                            <GridTable.Data columnWidth={5} alignX="center">
                                {isSelectCreditCard && !loading && (
                                    <Text
                                        align="center"
                                        className="mt-6 mt-md-9"
                                        labelKey="creditCard.financing.plans.table.empty"
                                    />
                                )}
                            </GridTable.Data>
                        </GridTable.Container>
                    </GridTable.Body>
                )}
            </GridTable>
        </Box>
    );
};

AmountPaymentFinancingPlans.propTypes = {
    quantityTextAlign: string,
    boldAmount: bool,
    isDesktop: bool,
    data: shape({
        options: arrayOf(shape({})),
        thousandsSeparator: string.isRequired,
        decimalSeparator: string.isRequired,
        precision: number,
    }).isRequired,
    form: shape({}).isRequired,
};

AmountPaymentFinancingPlans.defaultProps = {
    isDesktop: false,
    quantityTextAlign: "text-left",
    boldAmount: false,
};

export default compose(withFocus, formField())(resizableRoute(AmountPaymentFinancingPlans));
