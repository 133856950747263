import { Field, Form, withFormik } from "formik";
import moment from "moment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import DateField from "pages/_components/fields/DateField";
import Document from "pages/_components/fields/Document";
import Selector from "pages/_components/fields/formik/Selector";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
    actions as updateUserDataActions,
    selectors as updateUserDataSelectors,
} from "reducers/updateUserData/updateUserData.reducer";
import { compose } from "redux";
import * as dateUtils from "util/date";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { useLoadingGlobalProvider } from "providers/LoadingGlobalProvider";

const FORM_ID = "settings.personalDataUpdateStep1";

const PersonalDataUpdateStep1 = ({
    isSubmitting,
    isDesktop,
    exchangeToken,
    preDataStep1,
    dispatch,
    isFetching,
    currentUserData,
    isDisabledStep1,
    setFieldValue,
}) => {
    // Section 1
    const [userFullName, setUserFullName] = useState();
    const [userNationalityId, setNationalityId] = useState();
    const [userNationality, setNationality] = useState();

    // Section 2
    const [birthDate, setBirthDate] = useState();
    const [startDate, setStartDate] = useState();
    const [educationLevelId, setEducationLevelId] = useState();
    const [maritalStatusId, setMaritalStatusId] = useState();
    const [professionOccupationId, setProfessionOccupationId] = useState();

    // Section 3
    const [documentUserValue, setDocumentUserValue] = useState();
    const [issuedDate, setIssuedDate] = useState();
    const [expirationDate, setExpirationDate] = useState();
    const [minExpirationDate, setMinExpirationDate] = useState();

    // Selectors
    const [maritalStatusList, setMaritalStatusList] = useState([{}]);
    const [educationLevelList, setEducationLevelList] = useState([{}]);
    const [professionOccupationList, setProfessionOccupationList] = useState([{}]);
    const [countryList, setCountryList] = useState([]);
    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [idNumberMap1, setIdNumberMap1] = useState([]);
    const [idNumberMap2, setIdNumberMap2] = useState({});

    const { setLoading } = useLoadingGlobalProvider();

    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);

    useEffect(() => {
        dispatch(updateUserDataActions.preFormStep1(exchangeToken));
    }, [dispatch]);

    useEffect(() => {
        if (currentUserData) {
            const { PersonName, Nationality, BirthDt } = currentUserData.PersonData;
            const fullName = PersonName?.FullName;
            setUserFullName(fullName);
            setFieldValue("userFullName", fullName);

            const nationalityId = Nationality;
            setNationalityId(nationalityId);

            const [year, month, day] = BirthDt.split("-");
            const startDateAux = new Date();
            const yearAsNumber = year ? parseInt(year, 2) : "";
            const monthAsNumber = month ? parseInt(month, 2) - 1 : "";
            const dayAsNumber = day ? parseInt(day, 2) : "";
            startDateAux.setFullYear(yearAsNumber, monthAsNumber, dayAsNumber);
            setStartDate(startDateAux);

            const birthDateAsString = moment(BirthDt).format("DD/MM/YYYY");
            setBirthDate(birthDateAsString);
            setFieldValue("birthDate", BirthDt);

            const educLevelId = currentUserData?.EducationLevel;
            setEducationLevelId(educLevelId);
            setFieldValue("educationLevel", educLevelId);

            const maritalStId = currentUserData?.MaritalStat?.Value;
            setMaritalStatusId(maritalStId);
            setFieldValue("maritalStatus", maritalStId);

            const profOccupationId = currentUserData?.Profession?.Value;
            setProfessionOccupationId(profOccupationId);
            setFieldValue("professionOccupation", profOccupationId);
        }
    }, [currentUserData]);

    useEffect(() => {
        if (preDataStep1) {
            setMaritalStatusList(preDataStep1?.civilStatusList);
            setEducationLevelList(preDataStep1?.educationLevelList);
            setProfessionOccupationList(preDataStep1?.professionList);

            if (preDataStep1.idData) {
                const {
                    idCountry,
                    idType,
                    idNumber1,
                    idNumber2,
                    idDocument1,
                    idDocument2,
                    idIssuedDate,
                    idExpirationDate,
                } = preDataStep1.idData.issuedIdent;
                const documentUser = {
                    documentCountry: idCountry,
                    documentType: idType,
                    idNumber1,
                    idNumber2,
                    documentNumber1: idDocument1,
                    documentNumber2: idDocument2,
                };
                setDocumentUserValue(documentUser);
                setFieldValue("documentUser", documentUser);

                const idIssuedDateAsString = moment(idIssuedDate).format("DD/MM/YYYY");
                setIssuedDate(idIssuedDateAsString);
                setFieldValue("issuedDate", idIssuedDate);

                const idExpirationDateAsString = idExpirationDate ? moment(idExpirationDate).format("DD/MM/YYYY") : "";
                setExpirationDate(idExpirationDateAsString);
                if (idExpirationDate) {
                    setFieldValue("expirationDate", idExpirationDate);
                }

                const minExpDate = idExpirationDate ? moment(idExpirationDate) : moment(new Date());
                setMinExpirationDate(minExpDate);
            }

            setCountryList(preDataStep1?.documentData?.countryList || []);
            setDocumentTypeList(preDataStep1?.documentData?.documentTypeList || []);
            setIdNumberMap1(preDataStep1?.documentData?.idNumberMap1 || []);
            setIdNumberMap2(preDataStep1?.documentData?.idNumberMap2 || []);
        }
    }, [preDataStep1]);

    useEffect(() => {
        if (userNationalityId && countryList && countryList.length > 0) {
            const country = countryList.find(({ id }) => userNationalityId === id) || {};
            const countryName = country?.name;
            setNationality(countryName);
            setFieldValue("userNationality", countryName);
        }
    }, [userNationalityId, countryList]);

    const enableStep1 = () => {
        dispatch(updateUserDataActions.enableStep1(!isDisabledStep1));
    };

    const handleChangeBirthDate = (selectedBirthDate) => {
        const selectedBirthDateAsString = moment(selectedBirthDate.toDate()).format("DD/MM/YYYY");
        setBirthDate(selectedBirthDateAsString);
        setFieldValue("birthDate", selectedBirthDate.toDate());
    };

    const handleChangeMaritalStatus = (maritalStId) => {
        setMaritalStatusId(maritalStId);
        setFieldValue("maritalStatus", maritalStId);
    };

    const handleChangeEducationLevel = (edLevelId) => {
        setEducationLevelId(edLevelId);
        setFieldValue("educationLevel", edLevelId);
    };

    const handleChangeProfession = (professionId) => {
        setProfessionOccupationId(professionId);
        setFieldValue("professionOccupation", professionId);
    };

    const handleChangeIssuedDate = (selectedIssuedDate) => {
        const selectedIssuedDateAsString = moment(selectedIssuedDate.toDate()).format("DD/MM/YYYY");
        setIssuedDate(selectedIssuedDateAsString);
        setFieldValue("issuedDate", selectedIssuedDate.toDate());
    };

    const handleChangeExpirationDate = (selectedExpirationDate) => {
        const selectedExpirationDateAsString = moment(selectedExpirationDate.toDate()).format("DD/MM/YYYY");
        setExpirationDate(selectedExpirationDateAsString);
        setFieldValue("expirationDate", selectedExpirationDate.toDate());
    };

    const handleCancel = () => {
        dispatch(updateUserDataActions.enableStep1(false));
        dispatch(push("/desktop"));
    };

    return (
        <>
            <Notification scopeToShow="personalDataUpdate" />
            <Form className="mx-n-5">
                <Box
                    display="flex"
                    column
                    alignX="flex-start"
                    alignY="center"
                    background="component-background"
                    borderRadius="default"
                    className="px-5 px-md-12 py-7 py-md-9 mb-3 mb-md-7">
                    <Box display="inline-block" className="mb-2 mb-md-4">
                        <Text labelKey="settings.personalDataUpdateStep1.name" color="heading" bold addColon />
                        <Text color="heading" uppercase name="userFullName">
                            {userFullName}
                        </Text>
                    </Box>
                    <Box display="inline-block" {...(!isDesktop && { className: "mb-2" })}>
                        <Text labelKey="settings.personalDataUpdateStep1.nationality" color="heading" bold addColon />
                        <Text color="heading" name="userNationality">
                            {userNationality}
                        </Text>
                    </Box>
                    {!isDesktop && (
                        <Box display="inline-block">
                            <Text
                                labelKey="settings.personalDataUpdateStep1.birthDate.label"
                                color="heading"
                                bold
                                addColon
                            />
                            <Text color="heading" name="userNationality">
                                {birthDate}
                            </Text>
                        </Box>
                    )}
                </Box>

                <Box
                    display="flex"
                    column
                    fullWidth
                    background="component-background"
                    className="px-5 px-md-12 pt-7 pb-10 pb-md-11 mb-3 mb-md-7"
                    borderRadius="default">
                    <Box className="mb-7 mb-md-8">
                        <Field
                            component={SwitchField}
                            idForm={FORM_ID}
                            name="modifyDataControl"
                            onChange={enableStep1}
                        />
                    </Box>

                    <Row>
                        {isDesktop && (
                            <Col xs={12} md={6}>
                                <Field
                                    component={DateField}
                                    idForm={FORM_ID}
                                    name="birthDate"
                                    minDate={moment().add(-100, "years")}
                                    maxDate={moment(new Date())}
                                    dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18n.getLang())}
                                    idField="birthDate.date"
                                    value={birthDate}
                                    labelNoMarginTop
                                    startDate={startDate}
                                    disabled={isDisabledStep1}
                                    onChange={handleChangeBirthDate}
                                />
                            </Col>
                        )}
                        <Col xs={6}>
                            <Field
                                idForm={FORM_ID}
                                name="maritalStatus"
                                component={Selector}
                                options={maritalStatusList}
                                value={maritalStatusId}
                                valueKey="value"
                                labelKey="label"
                                labelNoMarginTop
                                disabled={isDisabledStep1}
                                handleChange={handleChangeMaritalStatus}
                            />
                        </Col>
                        <Col xs={6}>
                            <Field
                                idForm={FORM_ID}
                                name="educationLevel"
                                component={Selector}
                                options={educationLevelList}
                                value={educationLevelId}
                                labelNoMarginTop
                                disabled={isDisabledStep1}
                                valueKey="value"
                                labelKey="label"
                                handleChange={handleChangeEducationLevel}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Field
                                idForm={FORM_ID}
                                name="professionOccupation"
                                component={Selector}
                                options={professionOccupationList}
                                value={professionOccupationId}
                                labelNoMarginTop
                                disabled={isDisabledStep1}
                                valueKey="value"
                                labelKey="label"
                                handleChange={handleChangeProfession}
                            />
                        </Col>
                    </Row>
                </Box>
                <Box
                    display="flex"
                    column
                    fullWidth
                    background="component-background"
                    className="px-5 px-md-12 pt-9 pb-10 pb-md-11 mb-5 mb-md-8"
                    borderRadius="default">
                    <Row {...(!isDesktop && { gapY: "0" })}>
                        <Col xs={12} lg={6}>
                            <Field
                                countries={countryList}
                                documentCountryMap={documentTypeList}
                                idNumberMap={idNumberMap1}
                                idNumber2Map={idNumberMap2}
                                value={documentUserValue}
                                component={Document}
                                name="documentUser"
                                reducedColumnGap
                                {...(!isDesktop && { idNumberStyle2: true })}
                                gapX="7"
                                spacingInputs="mb-5 mb-md-7"
                                labelNoMarginTop
                                disabled={isDisabledStep1}
                                disabledAll={isDisabledStep1}
                                setDocumentUserValue={setDocumentUserValue}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <Row gapX="0">
                                <Field
                                    component={DateField}
                                    idForm={FORM_ID}
                                    name="issuedDate"
                                    minDate={moment().add(-30, "years")}
                                    maxDate={moment(new Date())}
                                    dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18n.getLang())}
                                    idField="issuedDate.date"
                                    value={issuedDate}
                                    {...(isDesktop && { labelNoMarginTop: true })}
                                    disabled={isDisabledStep1}
                                    onChange={handleChangeIssuedDate}
                                />
                                <Field
                                    component={DateField}
                                    idForm={FORM_ID}
                                    name="expirationDate"
                                    minDate={minExpirationDate}
                                    maxDate={moment().add(30, "years")}
                                    dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18n.getLang())}
                                    idField="expirationDate.date"
                                    value={expirationDate}
                                    labelNoMarginTop
                                    disabled={isDisabledStep1}
                                    onChange={handleChangeExpirationDate}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Box>
                <Row className="px-5 px-md-0" {...(!isDesktop && { gapY: "3" })}>
                    <Col xs={12} md={4} mdOffset={2}>
                        <Button label="global.cancel" bsStyle="outline" onClick={handleCancel} block />
                    </Col>
                    <Col xs={12} md={4} {...(!isDesktop && { className: "grid-reversed" })}>
                        <Button
                            bsStyle="primary"
                            label="global.continue"
                            loading={isSubmitting || isFetching}
                            type="submit"
                            block
                        />
                    </Col>
                </Row>
            </Form>
            {/* </PageLoading> */}
        </>
    );
};

PersonalDataUpdateStep1.propTypes = {
    isFetching: bool,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
    exchangeToken: string.isRequired,
    preDataStep1: shape({}),
    currentUserData: shape({}).isRequired,
    isDisabledStep1: bool,
    values: shape({}).isRequired,
    setFieldValue: func.isRequired,
};

PersonalDataUpdateStep1.defaultProps = {
    preDataStep1: null,
    isFetching: true,
    isDisabledStep1: true,
};

const mapStateToProps = (state) => ({
    preDataStep1: updateUserDataSelectors.getPreDataStep1(state),
    exchangeToken: updateUserDataSelectors.getExchangeToken(state),
    isFetching: updateUserDataSelectors.isFetching(state),
    currentUserData: updateUserDataSelectors.getCurrentUserData(state),
    isDisabledStep1: updateUserDataSelectors.isDisabledStep1(state),
});

export default compose(
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            birthDate: "",
            maritalStatus: "",
            educationLevel: "",
            professionOccupation: "",
            documentUser: "",
            issuedDate: "",
            expirationDate: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                birthDate: Yup.string().required(i18n.get(`${FORM_ID}.birthDate.required`)),
                maritalStatus: Yup.string().required(i18n.get(`${FORM_ID}.maritalStatus.required`)),
                educationLevel: Yup.string().required(i18n.get(`${FORM_ID}.educationLevel.required`)),
                professionOccupation: Yup.string().required(i18n.get(`${FORM_ID}.professionOccupation.required`)),
                issuedDate: Yup.string().required(i18n.get(`${FORM_ID}.issuedDate.required`)),
                expirationDate: Yup.string()
                    .typeError(i18n.get(`${FORM_ID}.expirationDate.required`))
                    .required(i18n.get(`${FORM_ID}.expirationDate.required`)),
                documentUser: Yup.object().shape({
                    documentCountry: Yup.string().required(i18n.get(`${FORM_ID}.documentUser.required`)),
                    documentType: Yup.string().required(i18n.get(`${FORM_ID}.documentUser.required`)),
                    documentNumber1: Yup.string().required(i18n.get(`${FORM_ID}.documentUser.required`)),
                    documentNumber2: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) => documentCountry === "PA" && documentType === "CIP",
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentUser.required`)),
                    }),
                    idNumber1: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) =>
                            documentCountry === "PA" || (documentCountry === "VE" && documentType === "CED"),
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentUser.required`)),
                    }),
                    idNumber2: Yup.string().when("documentCountry", {
                        is: "PA",
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentUser.required`)),
                    }),
                }),
            }),
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(updateUserDataActions.saveForm(values, 1, formikBag));
        },
    }),
)(PersonalDataUpdateStep1);
