import { pdf } from "@react-pdf/renderer";
import classNames from "classnames";
import { PDFTicket } from "pages/forms/customForms/PDFTicket";
import FormActions from "pages/forms/_components/FormActions";
import TransactionTicket from "pages/forms/_components/TransactionTicket";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { goBack, replace } from "react-router-redux";
import { actions as bankActions } from "reducers/bankSelector";
import { actions as formActions } from "reducers/form";
import { actions as notificationActions } from "reducers/notification";
import { getDisplay, getMobileOS, isAndroidPlatform, isMobileNative, isMobileNativeFunc } from "util/device";
import { downloadPdf } from "util/download";
import * as i18n from "util/i18n";

const generateTicketBlob = async (title, transaction, values, renderTicket, wally) => {
    const Ticket = (
        <PDFTicket title={title} transaction={transaction} values={values} renderTicket={renderTicket} wally={wally} />
    );
    return pdf(Ticket).toBlob();
};

const generateUrlFromBlob = async (blob, title, transaction) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            let dataUrl = reader.result;
            const chunks = dataUrl.split(",");

            if (getMobileOS(getDisplay()) === "iOS") {
                dataUrl = `data:text/pdf:${i18n.get(title)?.replace("/", "-")}_${
                    transaction.idTransaction
                }'.pdf;base64,${chunks[1]}`;
            }
            resolve(dataUrl);
        };
        reader.onerror = (e) => {
            reject(e);
        };
        reader.readAsDataURL(blob);
    });

class TicketRenderCustom extends Component {
    static propTypes = {
        values: shape({}).isRequired,
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        currentLang: string.isRequired,
        metadata: shape({}).isRequired,
        errors: shape({}).isRequired,
        fromBackoffice: bool,
        ticketConfirmation: bool,
        title: string,
        history: shape({ location: shape({ pathname: string }) }).isRequired,
        match: shape({ path: string, url: string, isExact: bool }).isRequired,
        setValues: func.isRequired,
        setErrors: func.isRequired,
        setFieldValue: func.isRequired,
        isMobile: bool,
        isDesktop: bool.isRequired,
        fetching: bool,
        location: shape({ pathname: string }).isRequired,
        id: string,
        childrenTransactions: shape({}),
        parentTransaction: shape({}),
        transaction: shape({
            idTransaction: string,
        }).isRequired,
        idActivity: string.isRequired,
        showSubmit: bool,
        returnBack: bool,
        closeLinkTo: string,
        renderFields: func,
        renderTicket: func,
        headerText: string,
        wally: bool,
    };

    static defaultProps = {
        fromBackoffice: false,
        ticketConfirmation: false,
        title: "",
        isMobile: false,
        fetching: false,
        id: null,
        childrenTransactions: null,
        parentTransaction: null,
        showSubmit: true,
        returnBack: false,
        closeLinkTo: null,
        renderFields: null,
        renderTicket: null,
        headerText: null,
        wally: false,
    };

    componentWillUnmount() {
        const { dispatch, history, match } = this.props;
        const { pathname } = history.location;
        const { url } = match.url;

        if (!pathname.includes(url)) {
            dispatch(formActions.formClosed());
        }
        const data = {};
        if (!pathname.includes("bankSearch")) {
            dispatch(formActions.setData(data));
            dispatch(bankActions.resetSelectedBanks());
        }
    }

    handleClick = async (action) => {
        const {
            dispatch,
            metadata: { idActivity },
            transaction,
            values,
            renderTicket,
            title,
            wally,
        } = this.props;

        const { idTransaction } = transaction;
        switch (action) {
            case "saveDraft":
                dispatch(
                    formActions.saveDraft({
                        idActivityDraft: idActivity,
                        data: values,
                        idTransaction: transaction ? idTransaction : null,
                    }),
                );
                break;
            case "downloadTicketPdf":
                if (renderTicket) {
                    try {
                        const ticketBlob = await generateTicketBlob(title, transaction, values, renderTicket, wally);
                        downloadPdf(`${i18n.get(title)}_${transaction.idTransaction}.pdf`, ticketBlob, false);
                    } catch {
                        dispatch(
                            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                                "transaction/details",
                            ]),
                        );
                    }
                }

                break;
            case "shareTicket":
                if (renderTicket) {
                    try {
                        const ticketBlob = await generateTicketBlob(title, transaction, values, renderTicket);
                        const url = await generateUrlFromBlob(ticketBlob, title, transaction);

                        let options = {
                            files: [url],
                        };

                        if (isMobileNativeFunc() && isAndroidPlatform()) {
                            options = { ...options, message: i18n.get(title), subject: i18n.get(title) };
                        }

                        window.plugins.socialsharing.shareWithOptions(options, null, null);
                    } catch {
                        dispatch(
                            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                                "transaction/details",
                            ]),
                        );
                    }
                }

                break;
            default:
                break;
        }
    };

    handleBack = () => {
        const { dispatch, returnBack, location } = this.props;
        const isTicketRendered = location.pathname.match("/transaction/") != null;
        const isTicketHistoricRendered = location.pathname.match("/transaction/historic/") != null;

        let pathname = isTicketRendered ? "/transactions/list" : "/desktop";
        pathname = isTicketHistoricRendered ? "/transactions/list/historic" : pathname;
        if (returnBack) {
            dispatch(goBack());
        } else {
            dispatch(replace({ pathname, state: { transition: "transition-flow-close" } }));
        }
    };

    renderFieldsSection = () => {
        const { renderFields } = this.props;
        return <Box className={classNames("form-content")}>{renderFields()}</Box>;
    };

    renderFormContent = () => {
        const {
            transaction,
            childrenTransactions,
            parentTransaction,
            fromBackoffice,
            ticketConfirmation,
            isSubmitting,
            isDesktop,
            headerText,
            wally,
            metadata: { draftsEnabled, templatesEnabled, idForm, showSubmit },
        } = this.props;

        const isEditable =
            typeof childrenTransactions === "undefined" ||
            childrenTransactions === null ||
            childrenTransactions.length === 0;

        const actionButtons = !fromBackoffice && (
            <FormActions
                onClick={this.handleClick}
                fetching={isSubmitting}
                mode="view"
                draftsEnabled={draftsEnabled}
                templatesEnabled={templatesEnabled}
                transaction={transaction}
                isEditable={isEditable}
                idForm={idForm}
                isDesktop={isDesktop}
                showSubmit={showSubmit}
            />
        );

        return (
            <TransactionTicket
                headerText={headerText}
                childrenTransactions={childrenTransactions}
                formActions={actionButtons}
                fromBackoffice={fromBackoffice}
                parentTransaction={parentTransaction}
                ticketConfirmation={ticketConfirmation}
                transaction={transaction}
                generalHandleClick={this.handleClick}
                wally={wally}
                handleCancel={this.handleBack}>
                {this.renderFieldsSection()}
            </TransactionTicket>
        );
    };

    renderHeader = () => {
        const { isMobile, title } = this.props;

        return (
            <Head
                hideNavbarInMobile
                accessibilityTextId="administration.forms.transaction.details"
                titleText={title}
                onBack={this.handleBack}
                {...(isMobileNative && {
                    actionButton: () => {
                        this.handleClick("shareTicket");
                    },
                    actionButtonImage: "images/icons/share.svg",
                    actionButtonBsStyle: !isMobile ? "link" : "only-icon",
                    actionButtonImageMd: true,
                })}
            />
        );
    };

    render() {
        const { fetching, currentLang, errors, isMobile } = this.props;
        return (
            <>
                <Notification scopeToShow="ticket" errors={errors} currentLang={currentLang} />
                {!fetching && this.renderHeader()}

                <MainContainer showLoader={fetching}>
                    <div className={isMobile ? "container-fluid " : "container "}>
                        {!fetching && <div className="above-the-fold">{this.renderFormContent()}</div>}
                    </div>
                </MainContainer>
            </>
        );
    }
}

export default TicketRenderCustom;
