import React, { useEffect, useState } from "react";
import { arrayOf, string, shape, number, bool, objectOf, func } from "prop-types";

import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import { filterData } from "util/array";

import Box from "pages/_components/Box";
import PaginatedDataTable from "pages/_components/PaginatedDataTable";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";

const MultiSelectTable = ({
    list,
    options,
    tableFilters,
    columns,
    deletable,
    rowsPerPage,
    populateDataTable,
    deleteButton,
}) => {
    const [dataToFilter, setDataToFilter] = useState([]);
    const [listFiltered, setListFiltered] = useState([]);

    const generateItems = (itemList) =>
        itemList.map((itemId) => {
            const item = options.byId[itemId];
            return item;
        });

    useEffect(() => {
        if (list) {
            const items = generateItems(list);
            setDataToFilter(items);
            setListFiltered(items);
        }
    }, [list]);

    const handleFilterData = (filter) => {
        if (filter !== "") {
            const data = filterData([...dataToFilter], tableFilters, filter);
            setListFiltered(data);
        } else {
            setListFiltered([...dataToFilter]);
        }
    };

    const populateData = (data) => populateDataTable(data, deletable, deleteButton);

    const renderTable = () => {
        if (!dataToFilter.length) {
            return (
                <ul className="list">
                    <li className="list-item">
                        <div className="list-item-inner">
                            <div className="data-wrapper data-wrapper-flex">
                                <I18n id="administration.members.empty" />
                            </div>
                        </div>
                    </li>
                </ul>
            );
        }

        return (
            <Box className="multiselect-table-action mt-6">
                <TextSimpleField
                    name="search"
                    placeholder={i18n.get("administration.advanced.group.create.description.search")}
                    value=""
                    onChange={(e) => handleFilterData(e.target.value)}
                    serarchStyle
                />
                <PaginatedDataTable
                    data={listFiltered}
                    columns={columns}
                    populateRows={populateData}
                    rowsPerPage={rowsPerPage}
                    hasFilterApplied={dataToFilter.length !== listFiltered.length}
                    showNumbersResults={false}
                />
            </Box>
        );
    };

    return <>{renderTable()}</>;
};

MultiSelectTable.propTypes = {
    list: arrayOf(string),
    options: shape({
        ids: arrayOf(string),
        byId: objectOf(
            shape({
                label: string,
                value: string,
            }),
        ),
    }).isRequired,
    rowsPerPage: number,
    deletable: bool,
    tableFilters: arrayOf(string),
    columns: arrayOf(shape({})).isRequired,
    deleteButton: func.isRequired,
    populateDataTable: func.isRequired,
};

MultiSelectTable.defaultProps = {
    list: [],
    tableFilters: [],
    rowsPerPage: 10,
    deletable: true,
};

export default MultiSelectTable;
