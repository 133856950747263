import filesize from "filesize";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { arrayOf, func, number, shape, string } from "prop-types";
import React, { Component } from "react";

class ChatAttachment extends Component {
    static propTypes = {
        attachment: shape({
            idCommunication: number,
            files: arrayOf(
                shape({
                    idFile: number,
                    fileName: string,
                }),
            ),
        }),
        handleDownloadAttachment: func.isRequired,
    };

    static defaultProps = {
        attachment: null,
    };

    render() {
        const { attachment, handleDownloadAttachment } = this.props;

        return attachment.fileList.map((file) => (
            <Box display="flex" alignX="flex-end">
                <Button
                    bsStyle="link"
                    imageMd
                    imageColor="text-disabled-color"
                    className="max-width-full"
                    role="presentation"
                    onClick={handleDownloadAttachment(file.idFile)}>
                    <Box display="flex" alignX="flex-end" alignY="center" fullWidth>
                        <Image src="images/icons/attachment.svg" color="text-color" />

                        <Text color="text" size="6" ellipsis>
                            {file.fileName}
                        </Text>

                        {/* TODO file.size */}
                        <Text color="text-disabled-color" size="6" className="ml-2">
                            ({filesize(file.idFile)})
                        </Text>
                    </Box>
                </Button>
            </Box>
        ));
    }
}
export default ChatAttachment;
