import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { shape, func, bool, node, string, number, instanceOf } from "prop-types";
import { actions, selectors, GET_PAGINATED_DATA_LIST } from "reducers/paginatedTable";
import { loadingSelector } from "reducers/loading";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import PageLoading from "pages/_components/PageLoading";
import classNames from "classnames";
import Button from "pages/_components/Button";
import { Col } from "react-bootstrap";
import NoResults from "pages/_components/NoResultsMessage";
import PaginatorButtonsBar from "../PaginatorButtonsBar";
import Row from "../Row";

const FIRST_PAGE = 1;

const PaginatedTable = React.memo(
    ({
        idActivity,
        filters,
        hasFiltersApplied,
        paginatedData,
        getPagedDataList,
        clearPagedDataList,
        isFetching,
        isDesktop,
        itemProps,
        headerProps,
        itemComponent: ItemComponent,
        headerComponent: HeaderComponent,
        noMoreDataMessage,
        withoutItemsMessage,
        withoutItemsMessageFilter,
        currentPage,
        paginatedDataMapBKP,
        itemsMapBKP,
        autoClearWhenUnmounted,
        name,
        alternateBackgroundColor,
        handleShowFilter,
        handleClearFilter,
        renderDownloadDocs,
        showFilters,
        extraActionMobile,
        extraActionTwo,
    }) => {
        const [paginatedDataMap, setPaginatedDataMap] = useState(paginatedDataMapBKP || new Map());
        const [itemsMap, setItemsMap] = useState(itemsMapBKP || new Map());
        const [itemsToShow, setItemsToShow] = useState([]);
        const [page, setPage] = useState(1);
        const [totalpages, setTotalpages] = useState(1);
        const [showWithoutItemsMessage, setShowWithoutItemsMessage] = useState(false);
        const [paginatedDataValue, setPaginatedDataValue] = useState(undefined);
        const [rPerPage, setRowsPerPage] = useState(0);
        const [totalR, setTotalRows] = useState(0);
        const [currentP, setCurrentPage] = useState(currentPage || 1);
        const [hasError, setHasError] = useState(false);

        useEffect(() => {
            if (autoClearWhenUnmounted) {
                clearPagedDataList();
            }
        }, []);

        useEffect(() => {
            setPage(currentP);
            let items = [];
            if (itemsMapBKP) {
                const pData = paginatedDataMapBKP.get(1);

                const { paginatedList } = pData;
                const { totalPages, totalRows, rowsPerPage } = paginatedList;

                items = itemsMapBKP.get(currentP);

                setPaginatedDataValue({});
                setTotalpages(totalPages);

                setRowsPerPage(rowsPerPage);
                setTotalRows(totalRows);
            } else {
                getPagedDataList(filters, currentP, 0, setHasError);
            }
            setItemsToShow(items);
        }, [filters]);

        const generateKey = () =>
            Math.random()
                .toString(16)
                .slice(2);

        const getItemsComponent = (elementList, pageNumber, paginatedDataMapVal) => {
            let items = [];
            if (elementList && elementList.length > 0) {
                items = elementList.map((data) => {
                    const newProps = {
                        data,
                        hasFiltersApplied,
                        key: generateKey(),
                        currentPage: pageNumber,
                        paginatedDataMap: paginatedDataMapVal,
                        itemsMap,
                        filters,
                        isDesktop,
                        ...itemProps,
                    };
                    return React.createElement(ItemComponent, newProps);
                });
            }
            return items;
        };

        const setNewItemsToShow = (newItems) => {
            setItemsToShow(newItems);
        };

        useEffect(() => {
            const paginatedDataActivity = paginatedData[`${idActivity}.${name}`];
            let pData = paginatedDataActivity;
            if (paginatedDataMapBKP && currentP && itemsMap) {
                pData = paginatedDataMapBKP.get(currentP) || paginatedDataActivity;
            }
            if (pData && paginatedDataActivity) {
                const { paginatedList } = pData;
                if (paginatedList) {
                    const { elementList, currentPage: pageNumber, totalPages, totalRows, rowsPerPage } = paginatedList;

                    if (!elementList || elementList.length === 0) {
                        setNewItemsToShow([]);
                        if (pageNumber === FIRST_PAGE) {
                            setShowWithoutItemsMessage(true);
                        }
                    } else {
                        setTotalpages(totalPages);
                        paginatedDataMap.set(pageNumber, pData);
                        setPaginatedDataMap(paginatedDataMap);
                        const items = getItemsComponent(elementList, pageNumber, paginatedDataMap);
                        itemsMap.set(pageNumber, items);
                        setItemsMap(itemsMap);
                        setNewItemsToShow(items);
                        setShowWithoutItemsMessage(false);
                    }
                    setPage(pageNumber);
                    setRowsPerPage(rowsPerPage);
                    setTotalRows(totalRows);
                }
                setPaginatedDataValue(pData);
            }
        }, [paginatedData, headerProps, itemProps]);

        const fetchMore = (pageFetch) => {
            if (pageFetch <= totalpages) {
                setCurrentPage(pageFetch);
                getPagedDataList(filters, pageFetch, (pageFetch - 1) * rPerPage, setHasError);
            }
        };

        const goToFirstPage = () => {
            setPage(1);
            const prevPageItems = itemsMap.get(1);
            if (prevPageItems) {
                setItemsToShow(prevPageItems);
                setShowWithoutItemsMessage(false);
            } else {
                fetchMore(1);
            }
        };

        const goToPreviousPage = () => {
            if (page > 1) {
                const prev = page - 1;
                const prevPageItems = itemsMap.get(prev);
                if (prevPageItems) {
                    setItemsToShow(prevPageItems);
                    setShowWithoutItemsMessage(false);
                } else {
                    fetchMore(prev);
                }
                setPage(prev);
            }
        };

        const goToNextPage = () => {
            if (page < totalpages) {
                const next = page + 1;
                const nextPageItems = itemsMap.get(next);
                if (nextPageItems) {
                    setNewItemsToShow(nextPageItems);
                    setPage(next);
                } else {
                    setCurrentPage(next);
                    fetchMore(next);
                }
            }
        };

        const fetchLast = (pageFetch, nextIndexFetch) => {
            if (pageFetch <= totalpages) {
                setCurrentPage(pageFetch);
                getPagedDataList(filters, pageFetch, nextIndexFetch, setHasError);
            }
        };

        const goToLastPage = () => {
            const nextPageItems = itemsMap.get(totalpages);
            if (nextPageItems) {
                setNewItemsToShow(nextPageItems);
                setPage(totalpages);
            } else {
                fetchLast(totalpages, (totalpages - 1) * rPerPage);
            }
        };

        const getHeader = () => {
            const newProps = {
                ...headerProps,
            };
            return React.createElement(HeaderComponent, newProps);
        };

        const rows = () => {
            if (totalR < rPerPage) {
                return totalR;
            }
            return page === totalpages ? totalR : rPerPage * page;
        };

        const renderLabelResults = () => (
            <Box fullWidth display="flex" alignY="center">
                <Text labelKey="global.pagination.results" color="text" size="5" rows={rows()} totalRows={totalR} />
            </Box>
        );

        const renderLabelResultsMobile = () => (
            <Box fullWidth display="flex" alignY="center">
                <Row>
                    <Col xs="6">
                        <Box fullWidth fullHeight display="flex" alignY="center">
                            <Text
                                labelKey="global.pagination.results"
                                color="text"
                                size="5"
                                rows={rows()}
                                totalRows={totalR}
                            />
                        </Box>
                    </Col>
                    <Col xs="6">{extraActionMobile && extraActionMobile()}</Col>
                </Row>
            </Box>
        );

        const renderFilters = () => (
            <>
                {hasFiltersApplied && !showWithoutItemsMessage ? (
                    <Button
                        className="btn-clear-filter mr-7"
                        image="images/util/close.svg"
                        bsStyle="link"
                        label="global.clear.filter"
                        onClick={handleClearFilter}
                    />
                ) : (
                    <Button
                        className="btn-filter mr-7"
                        image="images/util/filter.svg"
                        bsStyle="link"
                        label="global.filter"
                        onClick={handleShowFilter}
                    />
                )}
            </>
        );

        return (
            <Box className={`pagination-component pagination-${name}-content`}>
                {paginatedDataValue && (
                    <PageLoading relative loading={isFetching}>
                        <div>
                            <Box fullWidth display="flex" className="pt-4 pb-3">
                                {/* {!showWithoutItemsMessage && ( */}
                                <>
                                    {isDesktop && renderLabelResults()}
                                    <Box fullWidth={!isDesktop} display="flex" alignY="center">
                                        {showFilters && renderFilters()}
                                    </Box>
                                    <Box display="flex" alignY="end">
                                        {renderDownloadDocs && renderDownloadDocs(showWithoutItemsMessage)}
                                    </Box>
                                </>
                                {/* )} */}
                            </Box>

                            {!isDesktop && renderLabelResultsMobile()}

                            {extraActionTwo && extraActionTwo()}

                            {itemsToShow && itemsToShow.length > 0 && (
                                <Box
                                    fullHeight
                                    fullWidth
                                    className={classNames("paginate-table", {
                                        "children-alternate-bg-color": alternateBackgroundColor,
                                    })}>
                                    {isDesktop && getHeader()}
                                    {itemsToShow}
                                </Box>
                            )}
                            {!showWithoutItemsMessage && page >= totalpages && (
                                <Box className="mt-7 mb-7 text-center">{noMoreDataMessage}</Box>
                            )}

                            {showWithoutItemsMessage && (
                                <Box className="mt-7 mb-7 text-center">
                                    {hasFiltersApplied && withoutItemsMessageFilter ? (
                                        <> {withoutItemsMessageFilter}</>
                                    ) : (
                                        <> {withoutItemsMessage}</>
                                    )}
                                </Box>
                            )}
                        </div>
                    </PageLoading>
                )}

                {!showWithoutItemsMessage && paginatedDataValue && (
                    <PaginatorButtonsBar
                        page={page}
                        totalpages={totalpages}
                        goToFirstPage={goToFirstPage}
                        goToPreviousPage={goToPreviousPage}
                        goToNextPage={goToNextPage}
                        goToLastPage={goToLastPage}
                    />
                )}

                {(!paginatedDataValue || hasError) && (
                    <PageLoading relative loading={isFetching}>
                        <Box className="mt-7 mb-7 text-center">
                            <NoResults centerImage={false} />
                        </Box>
                    </PageLoading>
                )}
            </Box>
        );
    },
);

const mapStateToProps = (state) => ({
    paginatedData: selectors.getPaginatedData(state),
    isFetching: loadingSelector(state, [GET_PAGINATED_DATA_LIST]),
});

const mapDispatchToProps = (dispatch, { idActivity, idProduct, idFieldName, notificationScopes, name }) => ({
    getPagedDataList: (filters, page, nextIndex, setHasError) => {
        dispatch(
            actions.getPagedDataList(
                idActivity,
                page,
                nextIndex,
                filters,
                idProduct,
                idFieldName,
                notificationScopes,
                name,
                setHasError,
            ),
        );
    },
    clearPagedDataList: () => {
        dispatch(actions.clearPagedDataList());
    },
});

PaginatedTable.propTypes = {
    filters: shape({}),
    hasFiltersApplied: bool.isRequired,
    paginatedData: shape({}),
    getPagedDataList: func.isRequired,
    clearPagedDataList: func.isRequired,
    isFetching: bool,
    isDesktop: bool,
    itemProps: shape({}).isRequired,
    headerProps: shape({}),
    itemComponent: node.isRequired,
    headerComponent: node,
    noMoreDataMessage: node,
    withoutItemsMessage: node,
    withoutItemsMessageFilter: node,
    currentPage: number.isRequired,
    paginatedDataMapBKP: instanceOf(Map),
    itemsMapBKP: instanceOf(Map),
    autoClearWhenUnmounted: bool,
    idActivity: string.isRequired,
    name: string,
    alternateBackgroundColor: bool,
    renderDownloadDocs: func,
    handleShowFilter: func,
    handleClearFilter: func,
    showFilters: bool,
    extraActionTwo: func,
    extraActionMobile: func,
};

PaginatedTable.defaultProps = {
    filters: [],
    paginatedData: undefined,
    isFetching: false,
    isDesktop: true,
    headerProps: undefined,
    headerComponent: undefined,
    noMoreDataMessage: undefined,
    withoutItemsMessage: undefined,
    withoutItemsMessageFilter: undefined,
    paginatedDataMapBKP: undefined,
    itemsMapBKP: undefined,
    autoClearWhenUnmounted: true,
    name: "",
    alternateBackgroundColor: true,
    renderDownloadDocs: undefined,
    handleShowFilter: () => {},
    handleClearFilter: () => {},
    showFilters: true,
    extraActionTwo: undefined,
    extraActionMobile: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaginatedTable);
