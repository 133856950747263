import React from "react";
import { string, bool } from "prop-types";

import Image from "pages/_components/Image";

function EnvironmentTag({ type, name, active }) {
    return (
        <div className="environment-tag">
            <span className="visually-hidden"> {`${type}, ${name}`} </span>
            <span aria-hidden>{name}</span>
            {active && <Image src="images/dropdown-arrow.svg" />}
        </div>
    );
}

EnvironmentTag.propTypes = {
    name: string.isRequired,
    type: string.isRequired,
    active: bool,
};

EnvironmentTag.defaultProps = {
    active: false,
};

export default EnvironmentTag;
