import React from "react";
import Box from "pages/_components/Box";
import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { isDesktop } from "react-device-detect";
import { isMobileNative } from "util/device";
import LoginWrapper from "./_components/LoginWrapper";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import Button from "pages/_components/Button";

const UserBlockedAdmin = () => {

    const getContent = () => (
        <>
        <Box className="login-blocked-icon">
            <Image src="images/util/error.gif" />
        </Box>
        <Text size={isDesktop ? "3" : "1"} className="mt-8" bold labelKey="login.user.blocked.title" />
        <Text
            className="mt-8 line-height-15 text-center"
            size="6"
            labelKey="login.user.blocked.admin.info"
        />
        <Button
            className="mt-8"
            href={"/"}
            bsStyle="primary"
            label="general.page.userBlockedBank.btn"
            btnUppercase={false}
            block
        />
    </>
    );

    return (
    <>
        {isDesktop ? (
            <>
                <Notification scopeToShow="recoveryUser" />
                <BoxErrorNotification isMobile={false} scopeToShow="externalLayout" />
                <MainContainer shouldHideOnLoad className="pt-0 max-width-full">
                    <Box display="flex" position="relative" zIndex="9" className="login-background">
                        <LoginWrapper transition="transition-drill-in">
                            {getContent()}
                        </LoginWrapper>
                    </Box>
                </MainContainer>
            </>
        ) : (
            <>
                <Box display="flex" column alignX="between" className="scroll" fullHeight>
                    <Head position="relative" {...isMobileNative} />
                    <Box
                        display="flex"
                        alignX="flex-end"
                        component="main"
                        position="relative"
                        zIndex="9"
                        className="login-background mt-auto">
                        <Box display="block" position="absolute" top="n-12" fullWidth>
                            <BoxErrorNotification isMobile scopeToShow="externalLayout" />
                        </Box>
                        <LoginWrapper>
                            {getContent()}
                        </LoginWrapper>
                    </Box>
                </Box>
            </>
        )}
    </>
)};

export default UserBlockedAdmin;
