import React, { Component } from "react";
import Panel from "react-bootstrap/lib/Panel";
import { func, arrayOf, shape, string, oneOf } from "prop-types";
import { Field } from "formik";
import { connect } from "react-redux";

import { permissionsSelectors } from "reducers/administration";
import * as arrayUtils from "util/array";

import SmartGroups from "pages/administration/_components/advancedPermissionsForm/SmartGroups";
import I18n from "pages/_components/I18n";
import MultiSelect from "pages/_components/fields/MultiSelect";

export const PermissionsPanelContext = React.createContext();

class PermissionsPanel extends Component {
    static propTypes = {
        render: func.isRequired,
        products: arrayOf(
            shape({
                label: string,
                value: string,
            }),
        ).isRequired,
        mode: oneOf(["view", "edit"]).isRequired,
    };

    state = {
        option: null,
    };

    handleClick = (option) => {
        this.setState({ option });
    };

    handleBlur = () => {
        this.setState({ option: null });
    };

    removePermission = (form, name, selectedValue) => {
        const { setValues, values } = form;
        const value = values.permissions[name] || [];

        setValues({
            ...values,
            permissions: {
                ...values.permissions,
                [name]: value.filter((activePermission) => activePermission !== selectedValue),
            },
        });
    };

    addPermission = (form, name, selectedValue) => {
        const { setValues, values } = form;
        const value = values.permissions[name] || [];

        setValues({
            ...values,
            permissions: {
                ...values.permissions,
                [name]: [...value, selectedValue],
            },
        });
    };

    renderPanel = () => {
        const { option } = this.state;
        const { mode, products } = this.props;

        if (option) {
            const { permissionList, idItem, label } = option;
            const [permission] = permissionList;
            const productTypes = permission.productTypes.split(",");
            const productOptions = arrayUtils.mapItemsIds(
                products.filter(({ productType }) => productTypes.includes(productType)),
                "value",
            );

            if (mode !== "edit") {
                return (
                    <Panel>
                        <Panel.Heading>
                            <Panel.Title componentClass="h3">{label}</Panel.Title>
                        </Panel.Heading>
                        <Field
                            name={idItem}
                            render={({ form, field }) => {
                                const { values = {} } = form;
                                const { name } = field;
                                const value = values.permissions[name] || [];

                                const { smartGroups, productsAdded } = value.reduce(
                                    (acc, val) => {
                                        if (val.indexOf("ALL") !== -1) {
                                            return { ...acc, smartGroups: [...acc.smartGroups, val] };
                                        }

                                        return { ...acc, productsAdded: [...acc.productsAdded, val] };
                                    },
                                    { smartGroups: [], productsAdded: [] },
                                );

                                return (
                                    <Panel.Body>
                                        {smartGroups.length > 0 && (
                                            <div className="form-group">
                                                <div className="form-group-text">
                                                    <I18n id="administration.permissions.advanced.smart.groups" />
                                                </div>
                                                <ul className="form-group-control-list list">
                                                    {smartGroups.map((smartGroup) => (
                                                        <li key={smartGroup} className="list-item">
                                                            <I18n id={`productType.${smartGroup.split("_")[1]}`} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                        {productsAdded.length > 0 && (
                                            <div className="form-group">
                                                <div className="form-group-text">
                                                    <I18n id="administration.permissions.advanced.products" />
                                                </div>
                                                <ul className="form-group-control-list list">
                                                    {productsAdded.map((productId) => (
                                                        <li key={productId} className="list-item">
                                                            {productOptions.byId[productId].label}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </Panel.Body>
                                );
                            }}
                        />
                    </Panel>
                );
            }

            return (
                <Panel>
                    <Panel.Heading>
                        <Panel.Title componentClass="h3">{label}</Panel.Title>
                    </Panel.Heading>
                    <Field
                        name={idItem}
                        render={({ form, field }) => {
                            const { values } = form;
                            const { name } = field;
                            const value = values.permissions[name] || [];

                            return (
                                <Panel.Body>
                                    <p>
                                        <I18n id="administration.permissions.select.products.types" />
                                    </p>
                                    <SmartGroups
                                        name={name}
                                        productTypes={productTypes}
                                        value={value}
                                        onChange={({ target }) => {
                                            if (value.includes(target.value)) {
                                                this.removePermission(form, name, target.value);
                                            } else {
                                                this.addPermission(form, name, target.value);
                                            }
                                        }}
                                        mode={mode}
                                    />

                                    <div className="separator">
                                        <hr />
                                        <I18n id="global.or.tick" />
                                    </div>

                                    <p>
                                        <I18n id="administration.permissions.select.specific.products" />
                                    </p>
                                    <MultiSelect
                                        name={`permissions.${name}`}
                                        options={productOptions}
                                        placeholder="administration.permissions.products.placeholder"
                                        values={value}
                                        onSelect={(selectedOption) =>
                                            this.addPermission(form, name, selectedOption.value)
                                        }
                                        onDelete={(selectedOption) =>
                                            this.removePermission(form, name, selectedOption.value)
                                        }
                                        mode={mode}>
                                        {(product) => <span className="data-desc">{product.label}</span>}
                                    </MultiSelect>
                                </Panel.Body>
                            );
                        }}
                    />
                </Panel>
            );
        }

        return null;
    };

    render() {
        const { render } = this.props;
        const { option } = this.state;

        return (
            <PermissionsPanelContext.Provider
                value={{
                    onClick: this.handleClick,
                    onBlur: this.handleBlur,
                    activeIdItem: option && option.idItem,
                }}>
                {render(this.renderPanel())}
            </PermissionsPanelContext.Provider>
        );
    }
}

const mapStateToProps = (state) => ({
    products: permissionsSelectors.getMappedProducts(state),
});

export default connect(mapStateToProps)(PermissionsPanel);
