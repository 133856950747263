import classNames from "classnames";
import { node, string } from "prop-types";
import React from "react";

const GridTableBody = ({ children, className, ...props }) => {
    const childrenArray = React.Children.toArray(children);
    const modifiedChildren = childrenArray.map((child, i) => {
        if (React.isValidElement(child) && i === childrenArray.length - 1) {
            return React.cloneElement(child, { isLastElement: true });
        }
        return child;
    });

    return (
        <tbody className={classNames("grid-table-body", className)} {...props}>
            {modifiedChildren}
        </tbody>
    );
};

GridTableBody.propTypes = {
    children: node.isRequired,
    className: string,
};

GridTableBody.defaultProps = {
    className: null,
};

export default GridTableBody;
