import { call, put, takeLatest } from "redux-saga/effects";
import { routerActions } from "react-router-redux";

import { types, actions } from "reducers/bankSelector";
import * as bankSelector from "middleware/bankSelector";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";

const sagas = [takeLatest(types.LOAD_LIST_REQUEST, loadListRequest), takeLatest(types.PRE_REQUEST, preForm)];

export default sagas;

function* loadListRequest({ filters, currentUrl, setSubmitting, navigationAction }) {
    const response = yield call(bankSelector.loadListRequest, filters);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]));
    } else if (!response.data.data.banks.length) {
        yield put(
            notificationActions.showNotification(i18n.get("forms.bankselector.noRecordsFound"), "error", [
                "form",
                "bankFormCustom",
            ]),
        );
    } else {
        yield put(actions.loadListSuccess(response.data.data));
        yield put(routerActions[navigationAction](`${currentUrl}/results`, { shouldLoadForm: false }));
    }

    setSubmitting(false);
}

function* preForm() {
    const { data } = yield call(bankSelector.preRequest);
    if (data.error) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["bankFormCustom"]),
        );
    } else {
        yield put({
            type: types.PRE_REQUEST_SUCCES,
            preData: data.data,
        });
    }
}
