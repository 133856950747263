import classNames from "classnames";
import Statement from "pages/loans/_components/Statement";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import ProductList from "pages/_components/product/List";
import Sticker from "pages/_components/Sticker";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { selectors as loansSelectors } from "reducers/loans";
import { selectors as loginSelectors } from "reducers/login";
import * as configUtil from "util/config";
import * as i18nUtils from "util/i18n";

class Statements extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fees: arrayOf(shape({})).isRequired,
        isDesktop: bool.isRequired,
        isFeePaid: bool.isRequired,
        isFetchingFees: bool.isRequired,
        isTablet: bool.isRequired,
        loan: shape({}),
        paymentPlan: bool,
        noFiltersDataText: string,
    };

    static defaultProps = {
        loan: null,
        paymentPlan: false,
        noFiltersDataText: "loan.detail.statement.noFees",
    };

    state = {
        data: [],
        feeDetail: null,
        pageNumber: 1,
        showFeeDetail: false,
    };

    getFeesByType = (isFeePaid) => {
        const { fees } = this.props;

        return fees.filter((f) => f.paid === isFeePaid);
    };

    fetchMoreFees = () => {
        const { pageNumber } = this.state;
        const { isFeePaid } = this.props;
        const feesPerPage = configUtil.getInteger("loan.detail.statements.feesPerPage");
        this.setState({
            data: this.getFeesByType(isFeePaid).slice(0, (pageNumber + 1) * feesPerPage),
            pageNumber: pageNumber + 1,
        });
    };

    handleClick = () => {
        this.fetchMoreFees();
    };

    handleFeeClick = (fee) => {
        this.setState({ feeDetail: fee });
        this.setState({ showFeeDetail: true });
    };

    handleCloseModal = () => {
        this.setState({ showFeeDetail: false });
    };

    renderItem = (fee, index) => {
        const { isDesktop, isTablet, paymentPlan, isFeePaid } = this.props;
        return (
            <GridTable.Container
                className={classNames("product-data", {
                    "gapX-3": isDesktop,
                    "min-height-10-i border-radius-default": !isDesktop,
                })}
                columnsTemplate={isDesktop ? `repeat(${this.getLastColumnNumber()}, 1fr)` : ""}
                rows={1}
                key={fee.idStatement}
                {...(!isDesktop &&
                    !isTablet && {
                        onClick: () => this.handleFeeClick(fee),
                    })}>
                <Statement
                    isDesktop={isDesktop}
                    isTablet={isTablet}
                    fee={fee}
                    isFeePaid={isFeePaid}
                    index={index + 1}
                    paymentPlan={paymentPlan}
                />
            </GridTable.Container>
        );
    };

    getLastColumnNumber = () => {
        const { isDesktop, isFeePaid } = this.props;
        if (!isDesktop) {
            return "3";
        }
        return "5";
    };

    renderList = (list, renderLoadMore) => {
        const { isDesktop, paymentPlan, isFeePaid } = this.props;

        const isEmpty = list.length === 0;

        const lastColumnNumber = this.getLastColumnNumber();
        return (
            <Container className="container--layout scrollable movements-container">
                <GridTable className="table-default">
                    <GridTable.Header tableHeaderBackground="layout-body-background">
                        <GridTable.Container
                            columnsTemplate={isDesktop ? `repeat(${lastColumnNumber}, 1fr)` : "auto .5fr 1fr 1.5rem"}>
                            {isDesktop && (
                                <>
                                    <GridTable.Data columnStart="1" alignX="start" inHeader>
                                        <Text
                                            size="5"
                                            color="text-disabled-color"
                                            labelKey="loan.detail.statement.header.dueDate"
                                            align="center"
                                        />
                                    </GridTable.Data>
                                    <GridTable.Data columnStart="2" alignX="start" inHeader>
                                        <Text
                                            size="5"
                                            color="text-disabled-color"
                                            labelKey="loan.detail.statement.header.capital"
                                        />
                                    </GridTable.Data>
                                    <GridTable.Data columnStart="3" alignX="start" inHeader>
                                        <Text
                                            size="5"
                                            color="text-disabled-color"
                                            labelKey="loan.detail.statement.header.interest"
                                        />
                                    </GridTable.Data>
                                    <GridTable.Data columnStart="4" alignX="center" inHeader>
                                        <Text
                                            size="5"
                                            color="text-disabled-color"
                                            labelKey="loan.detail.statement.header.status"
                                        />
                                    </GridTable.Data>
                                    <GridTable.Data columnStart="5" alignX="end" inHeader>
                                        <Text
                                            size="5"
                                            color="text-disabled-color"
                                            labelKey="loan.detail.statement.header.total"
                                        />
                                    </GridTable.Data>
                                </>
                            )}
                        </GridTable.Container>
                    </GridTable.Header>
                    <GridTable.Body>
                        {list}
                        {!isEmpty && renderLoadMore()}
                    </GridTable.Body>
                </GridTable>
            </Container>
        );
    };

    renderModal = () => {
        const { isFeePaid, paymentPlan } = this.props;
        const { feeDetail, showFeeDetail } = this.state;
        return (
            <Modal aria-labelledby="modalTitleID" aria-modal="true" show={showFeeDetail} className="drawer">
                <div className="modal-container remove-padding">
                    <Head
                        onBack={this.handleCloseModal}
                        title={isFeePaid ? "loans.statements.details.paid" : "loans.statements.details.to.pay"}
                        hideNavbarInMobile
                    />
                    <MainContainer background="menu-background">
                        <Box
                            background="component-background"
                            borderRadius="default"
                            className="mt-5 pt-0 pb-7 px-5 mb-9">
                            <Box
                                display="flex"
                                alignY="center"
                                className="py-6 mb-8"
                                border="bottom-background-divider-1">
                                <Text size="3" color="heading" bold>
                                    {i18nUtils.get("loan.detail.statement.header.fee")}: {feeDetail?.idFee}
                                </Text>
                                {feeDetail?.expired && paymentPlan && (
                                    <Sticker
                                        status="error"
                                        labelKey="loan.detail.statement.field.expired"
                                        className="ml-3"
                                    />
                                )}
                                {!feeDetail?.expired && paymentPlan && (
                                    <Sticker
                                        status="success"
                                        labelKey="loan.detail.statement.field.valid"
                                        className="ml-3"
                                    />
                                )}
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Text
                                    size="3"
                                    color="heading"
                                    labelKey="loan.detail.statement.header.paidDate"
                                    addColon
                                    bold
                                />

                                <FormattedDate size="3" color="heading" date={feeDetail?.dueDate} />
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Text
                                    size="3"
                                    color="heading"
                                    labelKey="loan.detail.statement.header.capital"
                                    addColon
                                    bold
                                />

                                <FormattedAmount
                                    size="3"
                                    className="data-amount justify-content-end"
                                    quantity={feeDetail?.principalBalance}
                                    noCurrency
                                />
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Text
                                    size="3"
                                    color="heading"
                                    labelKey="loan.detail.statement.header.interest"
                                    addColon
                                    bold
                                />
                                <FormattedAmount
                                    size="3"
                                    className="data-amount justify-content-end"
                                    quantity={feeDetail?.interest}
                                    noCurrency
                                />
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Text
                                    size="3"
                                    color="heading"
                                    labelKey="loan.detail.statement.header.mora"
                                    addColon
                                    bold
                                />
                                <FormattedAmount
                                    size="3"
                                    className="data-amount justify-content-end"
                                    quantity={feeDetail?.penaltyInterest}
                                    noCurrency
                                />
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Text
                                    size="3"
                                    color="heading"
                                    labelKey="loan.detail.statement.header.feci"
                                    addColon
                                    uppercase
                                    bold
                                />
                                <FormattedAmount
                                    size="3"
                                    className="data-amount justify-content-end"
                                    quantity={feeDetail?.feci}
                                    noCurrency
                                />
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Text
                                    size="3"
                                    color="heading"
                                    labelKey="loan.detail.statement.header.amount"
                                    addColon
                                    bold
                                />
                                <FormattedAmount
                                    size="3"
                                    className="data-amount justify-content-end"
                                    quantity={feeDetail?.amount}
                                    noCurrency
                                />
                            </Box>
                        </Box>
                        <Button
                            bsStyle="outline"
                            label="global.back"
                            onClick={this.handleCloseModal}
                            block
                            className="mt-auto"
                        />
                    </MainContainer>
                </div>
            </Modal>
        );
    };

    render() {
        const { isDesktop, isFetchingFees, noFiltersDataText, fees } = this.props;
        const { feeDetail } = this.state;

        return (
            <>
                <ProductList
                    fetching={isFetchingFees}
                    items={fees}
                    renderItem={this.renderItem}
                    loadMoreText="loan.detail.statement.moreFees"
                    noDataText="loan.detail.statement.noFees"
                    noFiltersDataText={noFiltersDataText}>
                    {this.renderList}
                </ProductList>
                {feeDetail && !isDesktop && this.renderModal()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeRegion: loginSelectors.getRegion(state),
    isFetchingFees: loansSelectors.getFetchingFees(state),
    loan: loansSelectors.getSelectedLoan(state),
});

export default connect(mapStateToProps)(Statements);
