import { types } from "reducers/productRequest";
import { call, put, takeLatest } from "redux-saga/effects";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import * as productsMiddleware from "middleware/products";

const sagas = [takeLatest([types.SEND_REQUEST_PRODUCT], sendRequestProduct)];

export default sagas;

function* sendRequestProduct({ product, productType, contactType, message, pathname }) {
    const response = yield call(productsMiddleware.sendRequestProduct, product, productType, contactType, message);

    if (response && response.status === 200) {
        yield put({
            type: types.SEND_REQUEST_PRODUCT_SUCCESS,
        });
        yield put(
            notificationActions.showNotification(i18n.get("productRequest.drawer.success"), "success", [pathname]),
        );
    } else {
        yield put(notificationActions.showNotification(i18n.get("productRequest.drawer.error"), "error", [pathname]));
    }
}
