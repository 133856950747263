import React, { Component } from "react";
import { string, bool, func, shape, number } from "prop-types";

import * as i18nUtils from "util/i18n";

import ListItem from "pages/_components/listItem/ListItem";

class CreditLineWigetItem extends Component {
    static propTypes = {
        productAlias: string,
        label: string,
        productType: string,
        amountLabel: string.isRequired,
        hideAmountLabel: bool,
        showLink: bool,
        rate: string,
        completeFavorites: bool,
        dispatch: func.isRequired,
        showStar: bool,
        total: number,
        availableUSD: shape({ currency: string, quantity: number }),
        availableEUR: shape({ currency: string, quantity: number }),
    };

    static defaultProps = {
        productAlias: null,
        hideAmountLabel: false,
        showLink: true,
        rate: "",
        label: "",
        completeFavorites: false,
        showStar: false,
        total: 0,
        productType: "CreditLine",
        availableUSD: { currency: "USD", quantity: 0 },
        availableEUR: { currency: "EUR", quantity: 0 },
    };

    render() {
        const {
            productAlias,
            label,
            amountLabel = i18nUtils.get("creditLine.availableBalance"),
            hideAmountLabel,
            showLink,
            rate,
            completeFavorites,
            total,
            availableUSD,
            availableEUR,
            ...props
        } = this.props;
        const productTypeTitle = i18nUtils.get("desktop.widgets.creditLines");
        const productName = `${productTypeTitle}: ${total}`;

        return (
            <ListItem
                {...props}
                idProduct={`creditLine${total}`}
                productTypeTitle={productTypeTitle}
                name={productName}
                currency={availableUSD.currency}
                amount={availableUSD.quantity}
                currency2={availableEUR.currency}
                amount2={availableEUR.quantity}
                amountLabel={!hideAmountLabel && amountLabel}
                path={showLink ? `/creditLines` : null}
                rate={rate}
                showProductIcon
                completeFavorites={completeFavorites}
            />
        );
    }
}

export default CreditLineWigetItem;
