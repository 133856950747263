import { crashLogData } from "./crashReport/crashReport.util";

export const ENT000 = "ENT000";

export const validateSerialNumber = (serialNumber) =>
    new Promise((resolve, reject) => {
        window.cordova.plugins.CordovaEntrustPlugin.validateSerialNumber(
            serialNumber,
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            },
        );
    });

export const validateActivateCode = (activateCode) =>
    new Promise((resolve, reject) => {
        window.cordova.plugins.CordovaEntrustPlugin.validateActivateCode(
            activateCode,
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            },
        );
    });

export const hasRegisteredIdentity = () =>
    new Promise((resolve, reject) => {
        window.cordova.plugins.CordovaEntrustPlugin.hasRegisteredIdentity(
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            },
        );
    });
export const registerIdentity = (serialNumber, activateCode) =>
    new Promise((resolve) => {
        window.cordova.plugins.CordovaEntrustPlugin.registerIdentity(
            serialNumber,
            activateCode,
            (response) => {
                resolve(response);
            },
            (error) => {
                crashLogData({ sdkError: error });
                resolve(error);
            },
        );
    });

export const generateOTP = () =>
    new Promise((resolve, reject) => {
        window.cordova.plugins.CordovaEntrustPlugin.generateOTP(
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            },
        );
    });

export const deleteIdentity = () =>
    new Promise((resolve, reject) => {
        window.cordova.plugins.CordovaEntrustPlugin.deleteIdentity(
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            },
        );
    });
