import React, { useEffect, useState } from "react";
import flowRight from "lodash/flowRight";
import withFocus from "pages/_components/withFocus";
import formField from "pages/forms/_components/_fields/_commons/formField";
import { func, shape, string } from "prop-types";
import ModalManualRole from "pages/forms/customForms/ModalManualRole";
import Button from "pages/_components/Button";
import Table from "pages/_components/Table";
import I18n from "pages/_components/I18n";

import FormattedAmount from "pages/_components/FormattedAmount";
import ShowInfoFile from "pages/forms/customForms/_customFields/ShowInfoFile";

const ModalSelectCustom = (props) => {
    const {
        value,
        mode,
        setValue,
        onChangeManual = null,
        uploadType,
        currency,
        dispatch,
        idTransactionTicket,
        optionCurrencyList,
        optionBankList,
        genericProps,
        infoErrorFile,
        onChangeAmount,
    } = props;
    const [showModal, setShowModal] = useState(false);
    const [currencyCustom, setCurrencyCustom] = useState(currency);
    const [amount, setAmount] = useState(0);

    const [page, setPage] = useState(0);
    const [itemPage, setItemPage] = useState([]);
    const NUMBER_PAGE = 10;

    const getDataPagination = () => {
        if (!value || value.length === 0) {
            setItemPage([]);
            return;
        }

        if (value.length <= NUMBER_PAGE) {
            const newValue = [...value];
            setItemPage(newValue);
            return;
        }

        const startIndex = page * NUMBER_PAGE;
        let endIndex = startIndex + NUMBER_PAGE;
        endIndex = endIndex > value.length ? value.length : endIndex;
        const items = value.slice(startIndex, endIndex);
        if (items) {
            const newValue = [...itemPage];
            newValue.push(...items);
            setItemPage(newValue);
        }

        const newPage = page + 1;
        setPage(newPage);
    };

    const calculateAmount = (items) => {
        const itemArray = items || value;
        let amountTotal = 0;
        if (itemArray) {
            const itemsDefault = itemArray || [];
            amountTotal = itemsDefault.reduce((acc, val) => acc + parseFloat(val.creditAmountQuantity), 0);
        }

        setAmount(amountTotal);
    };

    const updateCurrencyItems = (currencyData) => {
        if (value) {
            const valueCurrency = value.map((item) => ({ ...item, creditAmountCurrency: currencyData }));
            setValue(valueCurrency);
        }
    };

    useEffect(() => {
        setItemPage([]);
        setPage(0);
        calculateAmount();
        getDataPagination();
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        if ((onChangeAmount, amount, currencyCustom)) {
            onChangeAmount(amount, currencyCustom);
        }
        // eslint-disable-next-line
    }, [amount, currencyCustom]);

    const onClose = () => {
        setShowModal(false);
    };

    const getCurrencyItems = () => {
        let currencyResult = currencyCustom;
        if (value && value.length > 0) {
            currencyResult = value[0].creditAmountCurrency || currencyCustom;
        }

        return currencyResult;
    };

    const addItem = (item) => {
        if (item) {
            const newValue = [...value, { ...item, creditAmountCurrency: getCurrencyItems() }];
            setValue(newValue);
        }
        setShowModal(false);
    };

    const handleCurrency = (event) => {
        if (event && event.target.value) {
            const currencyData = event.target.value;
            updateCurrencyItems(currencyData);
            setCurrencyCustom(currencyData);
        }
    };

    const deleteItem = (itemRemove) => {
        if (value) {
            const itemsFilter = value.filter((item) => item.id !== itemRemove.id);
            setValue(itemsFilter);
            const itemsFilterMore = itemPage.filter((item) => item.id !== itemRemove.id);
            setItemPage(itemsFilterMore);
            if (onChangeManual) {
                onChangeManual();
            }
        }
    };

    const renderMore = () => {
        const numberPageInt = Math.floor(value.length / NUMBER_PAGE);
        let numberPageResidue = 0;
        if (value.length > NUMBER_PAGE) {
            numberPageResidue = value.length % NUMBER_PAGE;
        }
        const numberPages = numberPageInt + (numberPageResidue > 0 ? 1 : 0);
        const render = value && page + 1 <= numberPages;
        return render;
    };

    const renderItemTable = () => {
        if (!itemPage) {
            return <div />;
        }

        const listItems = itemPage.map((item, index) => (
            <Table.Row
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.creditAccountNumber}-${index}`}>
                <>
                    <div className="table-data">
                        <span className="data-aux">{item.creditAccountNumber}</span>
                    </div>
                    <div className="table-data">
                        <FormattedAmount currency={getCurrencyItems()} quantity={item.creditAmountQuantity} />
                    </div>
                    <div className="table-data">
                        <span className="data-aux">{item.creditAccountName}</span>
                    </div>
                    <div className="table-data">
                        <span className="data-aux">{item.bankIdentifier}</span>
                    </div>
                    <div className="table-data">
                        {mode === "edit" && (
                            <div className="toolbar-item toolbar-item--fixed toolbar-item-right">
                                <Button
                                    className="toolbar-btn view-close"
                                    onClick={() => {
                                        deleteItem(item);
                                    }}
                                    image="images/cross.svg"
                                    bsStyle="link"
                                />
                            </div>
                        )}
                    </div>
                </>
            </Table.Row>
        ));

        return listItems;
    };

    const addItemManual = () => {
        setShowModal(true);
        setItemPage([]);
        setPage(0);
        if (onChangeManual && uploadType && uploadType === "file") {
            onChangeManual();
        }
    };

    const renderTableItemDetails = () => (
        <>
            {" "}
            <Table>
                <Table.Header>
                    <Table.HeaderData align="left">
                        <I18n id="tableHeader.account" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id="tableHeader.amount" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id="tableHeader.description" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id="tableHeader.bank" />
                    </Table.HeaderData>
                    <Table.HeaderData />
                </Table.Header>
                <Table.Body>
                    <>{renderItemTable()}</>
                </Table.Body>
            </Table>
            {renderMore() && (
                <>
                    <div className="text-center no-more-data flex">
                        <Button
                            bsStyle="link"
                            onClick={() => {
                                getDataPagination();
                            }}
                            image="images/show.svg"
                            label="massive.payments.line.more.results"
                            className="btn-small"
                        />
                    </div>
                </>
            )}
        </>
    );

    return (
        <>
            <ModalManualRole
                onClose={onClose}
                addItem={addItem}
                isVisible={showModal}
                optionBankList={optionBankList}
                genericProps={genericProps}
                currency={currencyCustom}
            />
            <ShowInfoFile
                uploadType={uploadType}
                mode={mode}
                value={value}
                onClickNewItem={() => {
                    addItemManual();
                }}
                dispatch={dispatch}
                idTransactionTicket={idTransactionTicket}
                optionCurrencyList={optionCurrencyList}
                currencyCustom={getCurrencyItems()}
                handleCurrency={(e) => {
                    handleCurrency(e);
                }}
                amount={amount}
                lines={value.length || 0}
                infoErrorFile={infoErrorFile}
            />

            {mode !== "view" && value && value.length > 0 && renderTableItemDetails()}
        </>
    );
};

ModalSelectCustom.propTypes = {
    setValue: func,
    value: shape({}),
    mode: string,
    optionCurrencyList: shape([]),
    onChangeManual: func,
    uploadType: string,
    currency: string,
    dispatch: func,
    idTransactionTicket: string,
    optionBankList: shape({}).isRequired,
    genericProps: shape({}),
    infoErrorFile: shape({}),
    onChangeAmount: func,
};
ModalSelectCustom.defaultProps = {
    setValue: () => {},
    value: {},
    mode: "",
    optionCurrencyList: [],
    onChangeManual: () => {},
    uploadType: "",
    currency: "",
    dispatch: () => {},
    idTransactionTicket: "",
    genericProps: {},
    infoErrorFile: {},
    onChangeAmount: () => {},
};
export default flowRight(withFocus, formField())(ModalSelectCustom);
