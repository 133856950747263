import cn from "classnames";
import Text from "pages/_components/Text/Text";
import { bool, number, string } from "prop-types";
import React, { Component } from "react";

class Badge extends Component {
    static propTypes = {
        count: number.isRequired,
        className: string,
        circular: bool,
    };

    static defaultProps = {
        className: undefined,
        circular: false,
    };

    render() {
        const { count, className, circular } = this.props;

        if (count === 0) {
            return null;
        }
        const componentClassName = cn("badge animation", className, { "badge-circular": circular });
        return (
            <div className={componentClassName}>
                <Text size={count > 99 ? "small" : "7"}>{count > 99 ? "+99" : count}</Text>
            </div>
        );
    }
}

export default Badge;
