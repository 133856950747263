import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LOAD_LIST_REQUEST: "bankSelector/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "bankSelector/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "bankSelector/LOAD_LIST_SUCCESS",
    BANK_SELECTED: "bankSelector/BANK_SELECTED",
    RESET_SELECTED_BANK: "bankSelector/RESET_SELECTED_BANK",
    PRE_REQUEST: "bankSelector/PRE_REQUEST",
    PRE_REQUEST_SUCCES: "bankSelector/PRE_REQUEST_SUCCES",
    PRE_REQUEST_FAILURE: "bankSelector/PRE_REQUEST_FAILURE",
    SET_DATA: "bankSelector/SET_DATA",
};

export const INITIAL_STATE = {
    banksResults: [],
    hasMoreResults: false,
    selectedBanks: {},
    fetching: true,
    preData: {},
    data: {},
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_LIST_REQUEST]: (state) => state,
    [types.LOAD_LIST_FAILURE]: (state) => state,
    [types.LOAD_LIST_SUCCESS]: (state, action) => ({
        ...state,
        banksResults: action.data.banks,
        hasMoreResults: action.data.moreResults,
    }),
    [types.BANK_SELECTED]: (state, action) => ({
        ...state,
        selectedBanks: {
            ...state.selectedBanks,
            [action.field]: action.selectedBank,
        },
    }),
    [types.RESET_SELECTED_BANK]: (state) => ({
        ...state,
        selectedBanks: INITIAL_STATE.selectedBanks,
    }),
    [types.PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.PRE_REQUEST_SUCCES]: (state, action) => ({
        ...state,
        fetching: false,
        preData: action.preData,
    }),
    [types.PRE_REQUEST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.SET_DATA]: (state, action) => ({
        ...state,
        data: { ...state.data, ...action.data },
    }),
});

export const actions = {
    loadListRequest: makeActionCreator(
        types.LOAD_LIST_REQUEST,
        "filters",
        "setSubmitting",
        "currentUrl",
        "navigationAction",
    ),
    loadListFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    loadListSuccess: makeActionCreator(types.LOAD_LIST_SUCCESS, "data"),
    bankSelected: makeActionCreator(types.BANK_SELECTED, "selectedBank", "field"),
    resetSelectedBanks: makeActionCreator(types.RESET_SELECTED_BANK),
    preRequest: makeActionCreator(types.PRE_REQUEST),
    setData: makeActionCreator(types.SET_DATA, "data"),
};

export const selectors = {
    getBanks: ({ bankSelector }) => bankSelector.banksResults,
    getHasMoreResults: ({ bankSelector }) => bankSelector.hasMoreResults,
    getSelectedBank: ({ bankSelector }, idField) => bankSelector.selectedBanks[idField],
    getPreData: ({ bankSelector }) => bankSelector.preData,
    getData: ({ bankSelector }) => bankSelector.data,
};
