import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    FINANCES_READ_REQUEST: "finances/FINANCES_READ_REQUEST",
    FINANCES_READ_FAILURE: "finances/FINANCES_READ_FAILURE",
    FINANCES_READ_SUCCESS: "finances/FINANCES_READ_SUCCESS",
};

export const INITIAL_STATE = {
    report: null,
    fetching: true,
};

export default createReducer(INITIAL_STATE, {
    [types.FINANCES_READ_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.FINANCES_READ_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.FINANCES_READ_SUCCESS]: (_, action) => ({
        report: action.report,
        fetching: false,
    }),
});

export const actions = {
    financeReadRequest: makeActionCreator(types.FINANCES_READ_REQUEST),
    financeReadFailure: makeActionCreator(types.FINANCES_READ_FAILURE),
    financeReadSuccess: makeActionCreator(types.FINANCES_READ_SUCCESS, "report"),
};

export const selectors = {
    getReport: ({ finances }) => finances.report,
    isFetching: ({ finances }) => finances.fetching,
};
