import { disable } from "mixpanel-browser";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PaginatedDataTable from "pages/_components/PaginatedDataTable";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import Table from "rc-table";
import React, { Component } from "react";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";

class SignaturesSchemes extends Component {
    static propTypes = {
        actions: shape({
            exportListRequest: func,
            listSignaturesSchemesRequest: func,
            loadMoreRequest: func,
        }).isRequired,
        activeEnvironment: shape({
            administrationScheme: string,
            type: string,
        }).isRequired,
        currentPage: number,
        fetching: bool,
        fetchingMoreSignaturesSchemes: bool,
        hasMoreData: bool.isRequired,
        routerActions: shape({
            push: func,
        }).isRequired,
        signaturesSchemes: arrayOf(
            shape({
                capList: arrayOf(shape({ maximum: number })),
                idSignature: number,
                signatureType: string,
            }),
        ).isRequired,
        totalPages: number.isRequired,
    };

    static defaultProps = {
        currentPage: 1,
        fetching: false,
        fetchingMoreSignaturesSchemes: false,
    };

    state = {
        searchWord: "",
        rowsPerPage: configUtils.getInteger("administration.rowsPerPage"),
    };

    componentDidMount() {
        const { actions } = this.props;

        actions.listSignaturesSchemesRequest();
    }

    getSigners = (administrationScheme, signatureScheme) => {
        const { groupsMap } = signatureScheme;

        return administrationScheme === "medium"
            ? groupsMap.A
            : Object.keys(groupsMap).reduce((result, key) => result + key.toString().repeat(groupsMap[key]), "");
    };

    generateTableColumns = () => {
        const {
            activeEnvironment: { administrationScheme: admScheme },
            routerActions,
        } = this.props;

        const result = [
            {
                key: "signers",
                dataIndex: "signers",
                title: i18n.get("administration.signatures.list.signers"),
                width: 75,
            },
            {
                align: "left",
                key: "amount",
                dataIndex: "amount",
                title: i18n.get("administration.signatures.list.amountPerPeriod"),
                width: 150,
            },
            {
                key: "transactions",
                dataIndex: "transactions",
                title: i18n.get("administration.signatures.list.transactions"),
                width: 250,
            },
            {
                key: "alias",
                dataIndex: "alias",
                title: i18n.get("administration.signatures.list.alias"),
                width: 85,
            },
        ];

        return [
            ...result,
            {
                key: "actions",
                dataIndex: "actions",
                title: "",
                width: 25,
                className: "text-right",
                onCell: (record) => ({
                    onClick: (e) => {
                        if (record.signatureType !== "ADM") {
                            routerActions.push(
                                `/administration/${admScheme}/signaturesSchemes/remove/${record.idSignature}`,
                            );
                        }
                        e.stopPropagation();
                    },
                }),
            },
        ];
    };

    handleClickDownload = (format) => {
        const { actions } = this.props;

        actions.exportListRequest(format);
    };

    handleMoreDataClick = () => {
        const { actions, currentPage } = this.props;

        actions.loadMoreRequest({ pageNumber: currentPage + 1 });
    };

    btnHandlerOnClick = () => {
        const {
            routerActions,
            activeEnvironment: { administrationScheme },
        } = this.props;
        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/create`);
    };

    onRowClick = (record) => {
        const {
            activeEnvironment: { administrationScheme },
            routerActions,
        } = this.props;

        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/${record.idSignature}`);
    };

    populateSignaturesData = (data) => {
        const {
            activeEnvironment: { administrationScheme, type },
        } = this.props;

        return (
            data &&
            data.map((signatureScheme) => {
                const { capList, idSignature, signatureType, signatureAlias } = signatureScheme;

                let result = {
                    idSignature,
                    key: idSignature,
                    signers: this.getSigners(administrationScheme, signatureScheme),
                    alias: signatureAlias || "N/A",
                    signatureType,
                };

                result = { transactions: i18n.get(`signatures.type.${signatureType}`), ...result };
                if (signatureType === "AMOUNT" && capList.length > 0) {
                    result = {
                        amount: (
                            <FormattedAmount
                                className="data-amount"
                                frequencyInSpan={false}
                                currency={configUtils.get("core.masterCurrency")}
                                quantity={
                                    capList[0].maximum === -1
                                        ? configUtils.get(`default_cap_signature_${type}`)
                                        : capList[0].maximum
                                }
                                frequency={i18n.get(
                                    "administration.signatures.create.advanced.topAmountPerPeriod",
                                    null,
                                    {
                                        period: i18n.get(
                                            `administration.signatures.transactions.capFrequency.${capList[0].frequency}`,
                                        ),
                                    },
                                )}
                            />
                        ),
                        ...result,
                    };
                } else {
                    result = { amount: "N/A", ...result };
                }

                return {
                    actions: signatureType !== "ADM" && (
                        <Button bsStyle="link" image="images/icons/deleteTrash.svg" className="btn-circle-bg" />
                    ),
                    disabled: (signatureType === "ADM"),
                    ...result,
                };
            })
        );
    };

    renderPageHeader = () => {
        const {
            activeEnvironment: { administrationScheme },
            signaturesSchemes,
        } = this.props;

        return (
            <>
                <Notification scopeToShow="administrationSignaturesSchemes" />
                {signaturesSchemes.length ? (
                    <Head
                        addLinkTo={`/administration/${administrationScheme}/signaturesSchemes/create`}
                        addLinkToLabel="administration.signatures.new.title"
                        title="administration.signatures.list.title"
                    />
                ) : (
                    <Head title="administration.signatures.list.title" />
                )}
            </>
        );
    };

    renderTableFooter = () => {
        const { totalPages, hasMoreData, fetchingMoreSignaturesSchemes } = this.props;

        return (
            <div>
                {totalPages > 1 &&
                    (hasMoreData ? (
                        <div className="text-center no-more-data" key="noMoresignatures">
                            <Button
                                className="btn btn-link"
                                onClick={this.handleMoreDataClick}
                                loading={fetchingMoreSignaturesSchemes}
                                image="images/show.svg"
                                label="administration.signatures.list.more"
                            />
                        </div>
                    ) : (
                        <div className="text-center no-more-data" key="noMoreSignatures">
                            <p className="text-lead">
                                <I18n id="administration.signatures.list.more.noMoreData" />
                            </p>
                        </div>
                    ))}
            </div>
        );
    };

    renderSignaturesSchemesTable = () => {
        const { totalPages } = this.props;
        return (
            <>
                <Table
                    className="table-default"
                    columns={this.generateTableColumns()}
                    data={this.populateSignaturesData()}
                    rowKey={(record) => record.key}
                    emptyText={i18n.get("administration.signatures.list.noRecords")}
                    onRow={(record) => ({
                        onClick: () => this.onRowClick(record),
                    })}
                    footer={totalPages > 1 ? this.renderTableFooter : null}
                />
            </>
        );
    };

    renderLabelResults = () => {
        const { signaturesSchemes } = this.props;
        if (!signaturesSchemes) {
            return null;
        }
        return (
            <Box fullWidth display="flex" alignY="center" className="mb-5">
                <Text
                    labelKey="global.pagination.results"
                    color="text"
                    size="5"
                    rows={signaturesSchemes.length}
                    totalRows={signaturesSchemes.length}
                />
            </Box>
        );
    };

    filterSignaturesSchemes = () => {
        const { signaturesSchemes } = this.props;
        const { searchWord } = this.state;
        return signaturesSchemes.filter(
            (signature) =>
                signature.signatureAlias?.toLowerCase().includes(searchWord.toLowerCase()) ||
                Object.keys(signature.groupsMap)
                    .reduce((result, key) => result + key.toString().repeat(signature.groupsMap[key]), "")
                    .toLowerCase()
                    .includes(searchWord.toLowerCase()),
        );
    };

    handleChange = (event) => {
        const { value } = event.target;
        this.setState({ searchWord: value });
    };

    render() {
        const { fetching, signaturesSchemes } = this.props;
        const { searchWord, rowsPerPage } = this.state;

        const filteredSignatures = this.filterSignaturesSchemes();

        return (
            <>
                {this.renderPageHeader()}
                <MainContainer
                    className="main-container border-radius-lg p-7 mt-3 box-shadow-small background-white texture-header"
                    showLoader={fetching}>
                    <div className="above-the-fold">
                        <Box
                            fullWidth
                            display="flex"
                            className="px-7 py-4 mb-5 border-radius-lg box-shadow-small background-white">
                            <Box fullWidth>
                                <Box>
                                    <Text component="h3" labelKey="administration.signatures.index.title" bold />
                                </Box>
                                <Box className="mt-5">
                                    <Text labelKey="administration.signatures.index.info" color="text-disabled-color" />
                                </Box>
                            </Box>
                            <Box>
                                <Image src="images/administration/signatures.svg" />
                            </Box>
                        </Box>
                        <TextSimpleField
                            name="search"
                            placeholder={i18n.get("administration.signatures.index.search.placeholder")}
                            value=""
                            onChange={this.handleChange}
                            serarchStyle
                        />
                        <PaginatedDataTable
                            data={filteredSignatures}
                            columns={this.generateTableColumns()}
                            populateRows={this.populateSignaturesData}
                            rowsPerPage={rowsPerPage}
                            onRowClick={this.onRowClick}
                            hasFilterApplied={
                                signaturesSchemes && filteredSignatures.length !== signaturesSchemes.length
                            }
                            messageNoRecords="administration.signatures.list.noRecords"
                        />
                    </div>
                </MainContainer>
            </>
        );
    }
}

export default resizableRoute(SignaturesSchemes);
