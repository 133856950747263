import classNames from "classnames";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import GridTable from "pages/_components/GridTable/GridTable";
import { resizableRoute } from "pages/_components/Resizable";
import Image from "pages/_components/Image";

import { bool, func, shape, string, arrayOf } from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";

const CurrencyModal = ({ isDesktop, list, onClose, show }) => {
    const getColumnsTemplate = () => "1.2fr 1fr 1fr";

    return (
        <Modal
            aria-labelledby="modalTitleID"
            aria-modal="true"
            onHide={() => {
                onClose();
            }}
            show={show}
            className={classNames("drawer")}>
            <div className="modal-container px-0">
                <Modal.Header closeButton className={isDesktop ? "px-9" : "px-5"}>
                    <Text id="modalTitleID" component="h4" labelKey="currency.modal.th.tax" className="modal-title" />
                </Modal.Header>
                <Modal.Body>
                    <Box display="flex" fullWidth className="px-0 px-md-9" flex1>
                        <Box display="flex" column>
                            <Text
                                component="p"
                                size="6"
                                align="center"
                                className={classNames("mb-9 my-0", { "px-5": !isDesktop })}
                                labelKey="currency.modal.text"
                            />

                            <Box display="flex" {...(isDesktop && { className: "mx-n-9" })} scrollable>
                                <GridTable>
                                    <GridTable.Header>
                                        <GridTable.Container
                                            columnsTemplate={getColumnsTemplate()}
                                            className={isDesktop ? "gapX-9" : "gapX-3 min-height-9-i"}>
                                            <GridTable.Data columnStart={1} alignX="flex-start" size="7" inHeader>
                                                <Text labelKey="currency.modal.th.currency" />
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={2} alignX="flex-end" size="7" inHeader>
                                                <Text labelKey="currency.modal.th.buy" />
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={3} alignX="flex-end" size="7" inHeader>
                                                <Text labelKey="currency.modal.th.sale" />
                                            </GridTable.Data>
                                        </GridTable.Container>
                                    </GridTable.Header>
                                    <GridTable.Body>
                                        {list.map((currency) => (
                                            <GridTable.Container
                                                columnsTemplate={getColumnsTemplate()}
                                                className={isDesktop ? "gapX-9" : "gapX-3 min-height-9-i"}>
                                                <GridTable.Data columnStart={1} alignX="flex-start">
                                                    <Box display="flex" alignY="center" fullWidth>
                                                        <Image
                                                            src={`images/currencyFlags/${currency.targetCurrencyCode}.svg`}
                                                            width="5"
                                                            className="mr-3"
                                                        />
                                                        <Text size={isDesktop ? "7" : "6"} className="mr-2">
                                                            {currency.targetCurrencyCode}
                                                        </Text>
                                                        <Text
                                                            size={isDesktop ? "7" : "6"}
                                                            ellipsis
                                                            labelKey={`currency.name.${currency.targetCurrencyCode}`}
                                                        />
                                                    </Box>
                                                </GridTable.Data>
                                                <GridTable.Data columnStart={2} alignX="flex-end">
                                                    <Text size={isDesktop ? "7" : "6"}>{currency.purchase}</Text>
                                                </GridTable.Data>
                                                <GridTable.Data columnStart={3} alignX="flex-end">
                                                    <Text size={isDesktop ? "7" : "6"}>{currency.sale}</Text>
                                                </GridTable.Data>
                                            </GridTable.Container>
                                        ))}
                                    </GridTable.Body>
                                </GridTable>
                            </Box>
                        </Box>
                    </Box>
                </Modal.Body>
            </div>
        </Modal>
    );
};

CurrencyModal.propTypes = {
    children: func,
    isDesktop: bool.isRequired,
    list: arrayOf(shape({})).isRequired,
    onClose: func,
    show: bool,
    title: string,
};

CurrencyModal.defaultProps = {
    children: "",
    onClose: () => {},
    show: false,
    title: "",
};

export default resizableRoute(CurrencyModal);
