import * as form from "middleware/form";
import * as updateUserDataApi from "middleware/updateUserData/updateUserData.middleware";
import { replace } from "react-router-redux";
import { actions as notificationActions } from "reducers/notification";
import {
    actions as updateUserDataActions,
    selectors as updateUserDataSelectors,
    types as updateUserDataTypes,
} from "reducers/updateUserData/updateUserData.reducer";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { adjustIdFieldErrors } from "util/form.js";

function* getCredencialsGroups({ credential }) {
    const { activityId } = credential;
    const response = yield call(form.listCredentialsGroups, "", activityId);
    if (!response?.type || !response?.data?.data) {
        yield put(updateUserDataTypes.GET_CREDENTIAL_GROUPS_FAILURE);
        yield;
        return;
    }

    if (response.type === "W") {
        yield put(updateUserDataTypes.GET_CREDENTIAL_GROUPS_FAILURE);
        yield;
        return;
    }

    const { groups } = response?.data?.data;
    if (!groups) {
        yield put(updateUserDataTypes.GET_CREDENTIAL_GROUPS_FAILURE);
        yield;
        return;
    }

    yield put({
        type: updateUserDataTypes.GET_CREDENTIAL_GROUPS_SUCCESS,
        credentialsGroups: groups,
    });
}

function* updateUserDataPreRequest({ otp, formikBag }) {
    const response = yield call(updateUserDataApi.updateUserDataPreRequest, otp);
    if (!response?.type || !response?.data?.data) {
        yield put(updateUserDataTypes.UPDATE_USER_DATA_PRE_FAILURE);
        yield;
    } else if (response.type === "W") {
        if (response.data.data.otp === "returnCode.COR091W") {
            yield put(updateUserDataTypes.UPDATE_USER_DATA_PRE_FAILURE);
        } else {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            formikBag.setSubmitting(false);
        }
    } else {
        const { currentUserData, _exchangeToken } = response?.data?.data;
        if (!currentUserData || !_exchangeToken) {
            yield put(updateUserDataTypes.UPDATE_USER_DATA_PRE_FAILURE);
            yield;
        } else {
            yield put({
                type: updateUserDataTypes.UPDATE_USER_DATA_PRE_SUCCESS,
                data: { currentUserData, exchangeToken: _exchangeToken },
            });
            yield put(replace("/settings/personalDataUpdateStep1"));
        }
    }
}

function* preFormStep1({ exchangeToken }) {
    const response = yield call(updateUserDataApi.updateUserDataStep1PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        const { documentData, idData, civilStatusList, educationLevelList, professionList } = response.data?.data;
        const preDataStep1 = {
            documentData,
            idData,
            civilStatusList,
            educationLevelList,
            professionList,
        };
        yield put(updateUserDataActions.preFormStep1Success(preDataStep1));
    } else if (response.data?.code === "COR020W") {
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.requestFailure());
    }
}

function* preFormStep2({ exchangeToken }) {
    const response = yield call(updateUserDataApi.updateUserDataStep2PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        const { countryList, provinceList, districtList, jurisdictionList, cityList } = response.data.data;
        const preDataStep2 = {
            countryList,
            provinceList,
            districtList,
            jurisdictionList,
            cityList,
        };
        yield put(updateUserDataActions.preFormStep2Success(preDataStep2));
    } else if (response && response.data?.code === "COR020W") {
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* preFormStep3({ exchangeToken }) {
    const response = yield call(updateUserDataApi.updateUserDataStep3PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        const {
            industryTypeList,
            businessTypeList,
            countryList,
            provinceList,
            districtList,
            jurisdictionList,
            cityList,
            incomeTypeList,
            otherIncomeSourceList,
        } = response.data?.data;
        const preDataStep3 = {
            industryTypeList,
            businessTypeList,
            countryList,
            provinceList,
            districtList,
            jurisdictionList,
            cityList,
            incomeTypeList,
            otherIncomeSourceList,
        };
        yield put(updateUserDataActions.preFormStep3Success(preDataStep3));
    } else if (response.data?.code === "COR020W") {
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* preFormStep4({ exchangeToken }) {
    const response = yield call(updateUserDataApi.updateUserDataStep4PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        // const {  } = response.data?.data;
        const preDataStep4 = {};
        yield put(updateUserDataActions.preFormStep4Success(preDataStep4));
    } else if (response.data?.code === "COR020W") {
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* preFormStep5({ exchangeToken }) {
    const response = yield call(updateUserDataApi.updateUserDataStep5PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        const { countryName } = response.data?.data;
        const preDataStep5 = { countryName };
        yield put(updateUserDataActions.preFormStep5Success(preDataStep5));
    } else if (response.data?.code === "COR020W") {
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* preFormStep6({ exchangeToken }) {
    const response = yield call(updateUserDataApi.updateUserDataStep6PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        const {
            skipStep6,
            mandatoryDocuments,
            additionalDocuments,
            residenceDocsDisclaimer,
            employmentDocsDisclaimer,
        } = response.data?.data;
        const preDataStep6 = {
            skipStep6,
            mandatoryDocuments,
            additionalDocuments,
            residenceDocsDisclaimer,
            employmentDocsDisclaimer,
        };
        yield put(updateUserDataActions.preFormStep6Success(preDataStep6));

        if (skipStep6) {
            yield put(
                updateUserDataActions.saveForm(
                    {
                        skipStep6: true,
                    },
                    6,
                    {
                        props: {
                            exchangeToken,
                        },
                    },
                ),
            );
        }
    } else if (response.data?.code === "COR020W") {
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

const readFileAsDataUrl = (file) => {
    const fileReader = new FileReader();

    return new Promise((resolve) => {
        fileReader.onload = (fileLoadedEvent) => {
            resolve(fileLoadedEvent.target.result);
        };
        fileReader.readAsDataURL(file);
    });
};

function* selectFile({ file, isMandatory, internalIndex }) {
    const result = yield readFileAsDataUrl(file);
    if (isMandatory) {
        const selectedMandatoryAtts = yield select(updateUserDataSelectors.getSelectedMandatoryAttachments);
        const index = selectedMandatoryAtts.map((e) => e.idx).indexOf(internalIndex);
        if (selectedMandatoryAtts.length === 0 || index < 0) {
            selectedMandatoryAtts.push({
                idx: internalIndex,
                name: file.name,
                size: file.size,
                content: result.split(",")[1],
            });
        } else if (index >= 0) {
            selectedMandatoryAtts[index] = {
                idx: internalIndex,
                name: file.name,
                size: file.size,
                content: result.split(",")[1],
            };
        }
        yield put(updateUserDataActions.updateMandatoryAttachments(selectedMandatoryAtts));
    } else {
        const selectedAdditionalAtts = yield select(updateUserDataSelectors.getSelectedAdditionalAttachments);
        const index = selectedAdditionalAtts.map((e) => e.idx).indexOf(internalIndex);
        if (selectedAdditionalAtts.length === 0 || index < 0) {
            selectedAdditionalAtts.push({
                idx: internalIndex,
                name: file.name,
                size: file.size,
                content: result.split(",")[1],
            });
        } else if (index >= 0) {
            selectedAdditionalAtts[index] = {
                idx: internalIndex,
                name: file.name,
                size: file.size,
                content: result.split(",")[1],
            };
        }
        yield put(updateUserDataActions.updateAdditionalAttachments(selectedAdditionalAtts));
    }
}

function* saveForm({ values, step, formikBag }) {
    let activityId;
    if (step === 1) {
        activityId = "settings.userData.update.step1.send";
    } else if (step === 2) {
        activityId = "settings.userData.update.step2.send";
    } else if (step === 3) {
        activityId = "settings.userData.update.step3.send";
    } else if (step === 4) {
        activityId = "settings.userData.update.step4.send";
    } else if (step === 5) {
        activityId = "settings.userData.update.step5.send";
    } else if (step === 6) {
        activityId = "settings.userData.update.step6.send";
    }

    const { exchangeToken } = formikBag?.props;
    const response = yield call(updateUserDataApi.saveUserDataStep, activityId, values, exchangeToken);
    if (response && response.status === 200) {
        yield put(updateUserDataActions.saveFormSuccess());
        if (step === 1) {
            yield put(replace("/settings/personalDataUpdateStep2"));
        } else if (step === 2) {
            yield put(replace("/settings/personalDataUpdateStep3"));
        } else if (step === 3) {
            yield put(replace("/settings/personalDataUpdateStep4"));
        } else if (step === 4) {
            yield put(replace("/settings/personalDataUpdateStep5"));
        } else if (step === 5) {
            yield put(replace("/settings/personalDataUpdateStep6"));
        } else if (step === 6) {
            yield put(replace("/settings/personalDataUpdateStep7"));
        }
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.saveFormFailure());
    }
}

function* getDistrictList({ provinceCode }) {
    const response = yield call(updateUserDataApi.getCatalogListByParentCatalogName, "DISTRITO", provinceCode);

    if (response && response.status === 200 && response.data?.code === "COR000I") {
        const { recordList } = response.data?.data;
        const districtByProvinceList = recordList || {};
        yield put(updateUserDataActions.getDistrictListSuccess(districtByProvinceList));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.getDistrictListFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* getJurisdictionList({ districtCode }) {
    const response = yield call(updateUserDataApi.getCatalogListByParentCatalogName, "CORREGIMIENTO", districtCode);

    if (response && response.status === 200 && response.data?.code === "COR000I") {
        const { recordList } = response.data?.data;
        const jurisdictionList = recordList || {};
        yield put(updateUserDataActions.getJurisdictionListSuccess(jurisdictionList));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.getJurisdictionListFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* getProvinceList({ countryCode }) {
    const response = yield call(updateUserDataApi.getCatalogListByParentCatalogName, "PROVINCIA", countryCode);

    if (response && response.status === 200 && response.data?.code === "COR000I") {
        const { recordList } = response.data?.data;
        const provinceList = recordList || {};
        yield put(updateUserDataActions.getProvinceListSuccess(provinceList));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.getProvinceListFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* getCityList({ stateProvinceCode }) {
    const response = yield call(updateUserDataApi.getCatalogListByParentCatalogName, "CIUDAD", stateProvinceCode);

    if (response && response.status === 200 && response.data?.code === "COR000I") {
        const { recordList } = response.data?.data;
        yield put(updateUserDataActions.getCityListSuccess(recordList));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.getCityListFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

const sagas = [
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_PRE_REQUEST, updateUserDataPreRequest),
    takeLatest(updateUserDataTypes.GET_CREDENTIAL_GROUPS_REQUEST, getCredencialsGroups),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_STEP1_PRE_REQUEST, preFormStep1),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_STEP2_PRE_REQUEST, preFormStep2),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_STEP3_PRE_REQUEST, preFormStep3),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_STEP4_PRE_REQUEST, preFormStep4),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_STEP5_PRE_REQUEST, preFormStep5),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_STEP6_PRE_REQUEST, preFormStep6),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_SAVE_FORM, saveForm),
    takeLatest(updateUserDataTypes.GET_DISTRICT_LIST_REQUEST, getDistrictList),
    takeLatest(updateUserDataTypes.GET_JURISDICTION_LIST_REQUEST, getJurisdictionList),
    takeLatest(updateUserDataTypes.GET_PROVINCE_LIST_REQUEST, getProvinceList),
    takeLatest(updateUserDataTypes.GET_CITY_LIST_REQUEST, getCityList),
    takeLatest(updateUserDataTypes.SELECT_FILE, selectFile),
];

export default sagas;
