import * as session from "middleware/session";
import { push, replace } from "react-router-redux";
import { types as desktopTypes } from "reducers/desktop";
import { selectors as fingerprintSelectors, types as fingerprintTypes } from "reducers/fingerprint";
import { actions as generalConditionActions, generalConditionsTypes } from "reducers/generalConditions";
import { actions as notificationActions } from "reducers/notification";
import { actions, types } from "reducers/session";
import { types as tourTypes } from "reducers/tour";
import { types as secondFactorTypes } from "reducers/secondFactor";
import { selectors as statusSelectors, types as statusTypes } from "reducers/status";
import { selectors as sessionSelectors } from "reducers/session";
import globalTypes from "reducers/types/global";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { isMobileNativeFunc } from "util/device";
import * as i18n from "util/i18n";
import { clearSessionDataRevelock } from "util/revelockMobile/revelockMobile.util";
import * as config from "util/config";
import { fingerprintDevice } from "util/fingerprint";
import { readCreditLineList, readCreditCardList } from "./login";

const sagas = [
    takeLatest(types.LOGOUT_REQUEST, handleLogoutRequest),
    takeLatest(types.LOGOUT_USER_BLOCKED_REQUEST, handleLogoutUserBlockedRequest),
    takeLatest(types.CHANGE_ENVIRONMENT_REQUEST, handleChangeEnvironmentRequest),
    takeLatest(types.EXTEND, extendSession),
    takeLatest(types.EXPIRE, expireSession),
    takeLatest(types.MASK_AMOUNT_UPDATE_REQUEST, updateMaskAmount),
    takeLatest(types.SET_ACCEPT_MODAL_REQUEST, acceptModal),
    takeLatest(types.OPEN_SAT_SERVICES_REQUEST, openSATServices),
    takeLatest(types.LOAD_FP_DATA_REQUEST, loadFingerprintData),
    takeLatest(types.SET_STATE_APP, updateUserData),
    takeLatest(types.LOGOUT_EXTERNAL_REQUEST, handleLogoutExternalRequest),
];

export default sagas;

function* clearSessionRevelock() {
    if (isMobileNativeFunc()) {
        yield call(clearSessionDataRevelock);
    }
}
function* handleLogoutRequest() {
    const hasGoToLoginStep1 = yield select(statusSelectors.hasGoToLogin);
    try {
        yield call(clearSessionRevelock);
        try {
            yield call(session.logout);
        } catch (e) {
            // we do nothing cause it doesn't
            // matter if it failed
        }
        const showAgain = yield select(fingerprintSelectors.isShowAgain);

        if (showAgain) {
            yield put({ type: fingerprintTypes.FINGERPRINT_SHOW_WIDGET });
        }
    } finally {
        if (hasGoToLoginStep1) {
            yield put(replace("/loginStep1"));
        }
        yield put({ type: globalTypes.CLEAN_UP });
    }
}

function* handleLogoutUserBlockedRequest() {
    try {
        yield call(clearSessionRevelock);
        try {
            yield call(session.logout);
        } catch (e) {
            // we do nothing cause it doesn't
            // matter if it failed
        }
    } finally {
        yield put(replace("/userBlocked"));
    }
}

export function* handleChangeEnvironmentRequest({
    idEnvironment,
    rememberEnvironment,
    formikBag,
    isDesktop
}) {
    const response = yield call(session.changeEnvironment, idEnvironment, rememberEnvironment);
    const { code, data } = response.data;
    if (code === "COR020W") {
        const { message } = response.data;
        if (data.cantAccessByRestriction) {
            yield put({ type: types.CHANGE_ENVIRONMENT_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get(data.cantAccessByRestriction), "warning", ["desktop"]),
            );
            if (isDesktop) {
                yield put(push("/desktop")); 
            }
        } else {
            const errorMessage =
                data.idEnvironmentToChange && typeof data.idEnvironmentToChange === "string"
                    ? data.idEnvironmentToChange
                    : message;

            yield put({ type: types.CHANGE_ENVIRONMENT_FAILURE });
            yield put(notificationActions.showNotification(errorMessage, "error", ["settings"]));
            yield put(push("/settings"));
        }
    } else if (code === "API522I") {
        yield put({
            type: generalConditionsTypes.SET_ACTION_DISPATCH,
            submitGeneralCondition: generalConditionActions.executeActionDispatch,
            submitParamGeneralCondition: { idEnvironment, rememberEnvironment, shouldNavigate: true },
        });

        yield put(push("/envGeneralConditions"));
    } else {
        let forms = null;
        if (data.forms) {
            forms = {};

            for (let i = 0; i < data.forms.length; i++) {
                let category = forms[data.forms[i].category];
                if (!category) {
                    category = [];
                    forms[data.forms[i].category] = category;
                }
                category.push(data.forms[i]);
            }
        }
        const environment = {
            permissions: data.permissions,
            forms,
            name: data.activeEnvironmentName,
            type: data.activeEnvironmentType,
            id: data.activeIdEnvironment,
            administrationScheme: data.administrationScheme,
            clients: data.clients,
        };

        const { environments, isAdministrator, enabledAssistant, signatureLevel, configuredToken } = response.data.data;
        yield fork(readCreditLineList);
        yield fork(readCreditCardList);

        yield put({
            type: types.CHANGE_ENVIRONMENT_SUCCESS,
            environment,
            environments,
            isAdministrator,
            signatureLevel,
        });

        yield put({ type: desktopTypes.LOAD_LAYOUT_REQUEST });
        yield put({ type: types.SET_ENABLED_ASSISTANT, enabledAssistant });
        yield put({ type: tourTypes.HIDE });
        yield put({ type: secondFactorTypes.SECOND_FACTOR_SET_CONFIGURED_TOKEN, configuredToken });

        yield put(replace("/desktop"));
        
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* extendSession() {
    yield call(session.extend);
    yield put({ type: types.EXTEND_SUCCESS });
}

function* expireSession({ lastHref }) {
    // dispatching action to save last href in status state
    yield put({ type: statusTypes.SAVE_LAST_HREF, lastHref });
    yield put({ type: types.LOGOUT_REQUEST });

    yield put(notificationActions.showNotification(i18n.get("session.expired"), "error", ["externalLayout"]));
}

function* updateMaskAmount({ idProduct, maskAmount }) {
    // yield put(actions.maskAmountUpdate(maskAmount));
    // yield call(session.updateMaskAmount, productId, maskAmount);

    const response = yield call(session.updateMaskAmount, idProduct, maskAmount);

    if (response && response.status === 200) {
        const {
            data: { productsMaskAmount },
        } = response.data;
        yield put(actions.maskAmountUpdate(productsMaskAmount));
    }
}

function* acceptModal({ modalId }) {
    yield call(session.acceptModal, modalId);
}

export function* openSATServices({ onFinish }) {
    try {
        const response = yield call(session.getBankToken, "OTROS");
        const { data } = response;
        if (data?.code === "COR000I") {
            const { token, seed } = response.data.data;

            const urlSAT = config.get("menu.otherServicesSAT.url");

            if (!urlSAT) {
                yield put({
                    type: types.OPEN_SAT_SERVICES_FAILURE,
                });

                yield put(
                    notificationActions.showNotification(i18n.get("desktop.openSATServices.error"), "error", [
                        "desktop",
                    ]),
                );
                return;
            }

            yield put({
                type: types.OPEN_SAT_SERVICES_SUCCESS,
            });

            let url = urlSAT.replace("%PARAM_TOKEN%", token);
            url = url.replace("%PARAM_SEED%", seed);

            yield call(() => window.open(url, "_blank"));
        } else {
            yield put({
                type: types.OPEN_SAT_SERVICES_FAILURE,
            });
            if (data?.code === "BAK001W") {
                yield put(
                    notificationActions.showNotification(i18n.get("desktop.openSATServices.error"), "error", [
                        "desktop",
                    ]),
                );
            }
        }
    } catch (e) {
        yield put({
            type: types.OPEN_SAT_SERVICES_FAILURE,
        });
        yield put(
            notificationActions.showNotification(i18n.get("desktop.openSATServices.error"), "error", ["desktop"]),
        );
    }
    if (onFinish) {
        onFinish();
    }
}

export function* loadFingerprintData() {
    try {
        let fingerprint = {};
        const seed = config.get("fingerprint.geoip.key");
        if (isMobileNativeFunc()) {
            const dataFp = yield call(fingerprintDevice, seed);
            fingerprint = JSON.parse(dataFp);
        } else {
            fingerprint = yield call(() => window.MPFingerprint.getData(true, true));
            if (fingerprint) {
                const urlGeoIp = config.get("fingerprint.geoip.url");
                const url = urlGeoIp?.replace("%PARAM_SEED%", seed);
                const ipInfo = yield call(() =>
                    fetch(url)
                        .then((response) => response.json())
                        .catch(() => null),
                );
                if (ipInfo) {
                    fingerprint.Geoip = ipInfo;
                }
            }
        }
        yield put({
            type: types.LOAD_FP_DATA_SUCCESS,
            fpDataDevice: fingerprint,
        });
    } catch (e) {
        console.error(e);
        yield put({ type: types.LOAD_FP_DATA_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("desktop.loadDataFP.error"), "error", ["desktop"]));
    }
}

function* updateUserData({ state }) {
    const date = new Date();
    const statusApp = { [state]: date.toISOString() };
    yield call(session.updateUserData, { statusApp });
}

function* handleLogoutExternalRequest() {
    try {
        yield call(clearSessionRevelock);
        try {
            const user = yield select(sessionSelectors.getUser);
            yield call(session.logoutExternal, user?.userId);
        } catch (e) {
            // we do nothing cause it doesn't
            // matter if it failed
        }
    } finally {
        yield put(replace("/loginStep1"));
        yield put({ type: globalTypes.CLEAN_UP });
    }
}
