import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import { arrayOf, bool, shape, string } from "prop-types";
import React, { Children, Component } from "react";
import { connect } from "react-redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import classNames from "classnames";
import { selectors as loginSelectors } from "reducers/login";
import moment from "moment";
import { isDesktop } from "react-device-detect";
import Image from "./Image";

class FooterDesktop extends Component {
    render() {
        const { dashboard, messages, moreOptions, isSubmiting, isLogin, padding } = this.props;
        const currentYear = moment().year();

        if (!messages) {
            return null;
        }

        if (isLogin) {
            return (
                <>
                    <Box {...(isDesktop && { display: "flex" })} fullWidth className="login-footer">
                        <Text
                            className="no-mobile"
                            defaultValue={`Banco Bolivariano ${currentYear} ® Todos los derechos reservados.`}
                        />
                        <Box display="flex">
                            <Image src="images/lucideIcons/phone.svg" className="svg-icon max-height-5 max-width-1" />
                            <Text defaultValue="Atención al cliente&nbsp;&nbsp;" />
                            <Text defaultValue="5-50 50 50&nbsp;" bold />
                            <Text defaultValue="Opción 2" bold />
                        </Box>
                    </Box>
                    {!isDesktop && (
                        <Box fullWidth className={classNames("login-footer", padding)}>
                            <Text
                                size="5"
                                className="mt-3"
                                defaultValue={`Banco Bolivariano ${currentYear} ® Todos los derechos reservados.`}
                            />
                        </Box>
                    )}
                </>
            );
        }
        return dashboard ? (
            <Box fullWidth display="flex" className="app-dashboard-footer">
                <Text
                    className="no-mobile"
                    defaultValue={`Banco Bolivariano ${currentYear} ® Todos los derechos reservados.`}
                />
                <Box display="flex" className="no-mobile">
                    <Image src="images/logos/logo-sat-footer.svg" className="svg-icon" />
                </Box>
            </Box>
        ) : (
            moreOptions && (
                <Box
                    component="footer"
                    className={classNames("app-default-footer", {
                        "pointer-events-none": isSubmiting,
                    })}>
                    <ul className="legal-nav list-inline">
                        {Children.map(moreOptions, (option, idx) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={idx}>{option}</li>
                        ))}
                    </ul>
                </Box>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    messages: i18nSelectors.getMessages(state),
    isSubmiting: loginSelectors.getSubmiting(state),
});

FooterDesktop.propTypes = {
    dashboard: bool,
    moreOptions: arrayOf(shape({})),
    messages: shape({}),
    isSubmiting: bool,
    isLogin: bool,
    padding: string,
};

FooterDesktop.defaultProps = {
    dashboard: false,
    moreOptions: null,
    messages: null,
    isSubmiting: false,
    isLogin: false,
    padding: "px-7",
};

export default connect(mapStateToProps)(FooterDesktop);
