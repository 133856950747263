import * as Yup from "yup";
import * as i18n from "util/i18n";

export const validationSchema = (field) => {
    const validation = {};

    field.forEach((element) => {
        let schema = Yup.string().required(i18n.get(`servicePayment.dinamicForm.validate.required`));
        if (element.fieldType === "Number" || element.fieldType === "Double") {
            schema = Yup.number().required(i18n.get(`servicePayment.dinamicForm.validate.required`));
        }

        Object.keys(element?.restriction).forEach((key) => {
            if (element.restriction[key] !== null) {
                if (key === "maxValue" || key === "maxLength") {
                    schema = schema.max(
                        element.restriction[key],
                        i18n.get(`servicePayment.dinamicForm.validate.maxValue`, null, {
                            NUM: element.restriction[key],
                        }),
                    );
                }
                if (key === "minValue" || key === "minLength") {
                    schema = schema.min(
                        element.restriction[key],
                        i18n.get(`servicePayment.dinamicForm.validate.minValue`, null, {
                            NUM: element.restriction[key],
                        }),
                    );
                }

                // excepciones
                if (
                    (element.fieldType === "Number" || element.fieldType === "Double") &&
                    (key === "maxLength" || key === "minLength")
                ) {
                    schema = Yup.string()
                        .required(i18n.get(`servicePayment.dinamicForm.validate.required`))
                        .min(
                            element.restriction.minLength,
                            i18n.get(`servicePayment.dinamicForm.validate.minLength.num`, null, {
                                NUM: element.restriction.minLength,
                            }),
                        )
                        .max(
                            element.restriction.maxLength,
                            i18n.get(`servicePayment.dinamicForm.validate.maxLength.num`, null, {
                                NUM: element.restriction.maxLength,
                            }),
                        );
                }
            }
            if (element.fieldType === "string" && (key === "maxLength" || key === "minLength")) {
                schema = Yup.string()
                    .required(i18n.get(`servicePayment.dinamicForm.validate.required`))
                    .min(
                        element.restriction.minLength,
                        i18n.get(`servicePayment.dinamicForm.validate.minLength`, null, {
                            NUM: element.restriction.minLength,
                        }),
                    )
                    .max(
                        element.restriction.maxLength,
                        i18n.get(`servicePayment.dinamicForm.validate.maxLength`, null, {
                            NUM: element.restriction.maxLength,
                        }),
                    );
            }
        });
        validation[element.fieldId] = schema;
    });

    return Yup.object({ ...validation });
};

export const initialValues = (field, isFastRecharge) => {
    const values = {
        alias: "",
        saveService: isFastRecharge,
    };

    field.forEach((element) => {
        values[element.fieldId] = "";
    });
    return values;
};

export const initialValuesService = (field, payService) => {
    const values = {
        alias: payService?.alias,
    };
    if (payService) {
        field.forEach((element) => {
            if ((element.serviceField === "BillId" || element.serviceField === "BillRef") && !!payService?.idBill) {
                values[element.fieldId] = payService?.idBill;
            }
            if (element.serviceField === "IssuedIdentType" && !!payService?.issuedIdentType) {
                values[element.fieldId] = payService?.issuedIdentType;
            }
            if (element.serviceField === "PartyId" && !!payService?.partyId) {
                values[element.fieldId] = payService?.partyId;
            }
        });
    }

    return values;
};

export const initialValuesFastRecharge = (field, payService) => {
    const values = {
        alias: payService?.alias,
    };
    const typeAccount = payService?.productType ? payService?.productType : "debitAccount";
    const total = payService?.amountQuantity ? parseFloat(payService?.amountQuantity).toFixed(2) : "";
    if (payService) {
        field.forEach((element) => {
            if ((element.serviceField === "BillId" || element.serviceField === "BillRef") && !!payService?.idBill) {
                values[element.fieldId] = payService?.idBill;
            }
            if (element.serviceField === "IssuedIdentType" && !!payService?.issuedIdentType) {
                values[element.fieldId] = payService?.issuedIdentType;
            }
            if (element.serviceField === "PartyId" && !!payService?.partyId) {
                values[element.fieldId] = payService?.partyId;
            }
        });
    }

    values.typeAccount = typeAccount;
    values[typeAccount] = payService?.idProduct;
    values.SubTotal = { quantity: total, currency: payService?.amountCurrency };

    if (payService?.extraData?.hasTax) {
        const tax = Number(((total * payService.extraData.tax) / 100).toFixed(2));
        values.TotalTax = { currency: payService?.amountCurrency, quantity: tax.toFixed(2) };
    }

    return values;
};

export const listViewService = (field, payService) => {
    const values = [
        {
            fieldId: "alias",
            fieldLabel: "Alias",
            value: payService?.alias,
        },
    ];

    if (payService) {
        field.forEach((element) => {
            if ((element.serviceField === "BillId" || element.serviceField === "BillRef") && !!payService?.idBill) {
                values.push({
                    fieldId: element.fieldId,
                    fieldLabel: element.fieldLabel,
                    value: payService?.idBill,
                });
            }
            if (element.serviceField === "IssuedIdentType" && !!payService?.issuedIdentType) {
                values.push({
                    fieldId: element.fieldId,
                    fieldLabel: element.fieldLabel,
                    value: payService?.issuedIdentType,
                });
            }
            if (element.serviceField === "PartyId" && !!payService?.partyId) {
                values.push({
                    fieldId: element.fieldId,
                    fieldLabel: element.fieldLabel,
                    value: payService?.partyId,
                });
            }
        });
    }

    return values;
};
