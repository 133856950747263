import { ID_ACTIVITY_DOWNLOAD_STATE } from "constants.js";
import { Field, withFormik } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions as productActions, selectors as productSelectors } from "reducers/products";
import { compose } from "redux";
import * as i18n from "util/i18n";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";

const DownloadAccountDetailForm = (props) => {
    const {
        currentLang,
        dispatch,
        handleSubmit,
        isSubmitting,
        stateProductList,
        metadataDownloadStateProduct,
        isDesktop,
        isFeatchingProduct,
        showEmptyList,
    } = props;
    const genericProps = {
        mode: "edit",
        lang: currentLang,
        idActivity: ID_ACTIVITY_DOWNLOAD_STATE,
        dispatch,
    };

    useEffect(() => {
        dispatch(productActions.metadataDownloadStateProduct());
    }, []);

    const getMonthArray = () => {
        if (!metadataDownloadStateProduct || !metadataDownloadStateProduct.monthArray) {
            return [];
        }

        const monthArray = metadataDownloadStateProduct.monthArray.reduce((acc, item) => {
            if (item) {
                acc.push({ id: item.key, value: item.value, label: item.value });
            }
            return acc;
        }, []);

        return [{ id: "0", value: "Todos", label: "Todos" }, ...monthArray];
    };

    const getYearArray = () => {
        if (!metadataDownloadStateProduct || !metadataDownloadStateProduct.yearArray) {
            return [];
        }

        const data = metadataDownloadStateProduct.yearArray.reduce((acc, item) => {
            if (item) {
                acc.push({ id: item.key, value: item.value, label: item.value });
            }
            return acc;
        }, []);

        return data;
    };

    const monthArray = getMonthArray();
    const yearArray = getYearArray();

    return (
        <PageLoading loading={isSubmitting || isFeatchingProduct}>
            <form onSubmit={handleSubmit} className="full-width full-height">
                <Box display="flex" column fullWidth fullHeight>
                    <Box display="flex" gap="5" className="mb-12" wrap fullWidth>
                        <Box flex1>
                            {metadataDownloadStateProduct && (
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Selector}
                                    dataLabelClassName="mt-0"
                                    key="month"
                                    name="month"
                                    idField="month"
                                    labelIdField="react-select-month-input"
                                    optionList={monthArray}
                                    renderAs="combo"
                                    isRequired
                                    alwaysDown
                                    defaultValue={monthArray && monthArray[0] ? [monthArray[0]] : []}
                                />
                            )}
                        </Box>
                        <Box flex1>
                            {metadataDownloadStateProduct && (
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Selector}
                                    dataLabelClassName="mt-0"
                                    key="year"
                                    name="year"
                                    idField="year"
                                    labelIdField="react-select-year-input"
                                    optionList={yearArray}
                                    isRequired
                                    alwaysDown
                                    defaultValue={
                                        yearArray && yearArray[yearArray.length - 1]
                                            ? [yearArray[yearArray.length - 1]]
                                            : []
                                    }
                                    renderAs="combo"
                                />
                            )}
                        </Box>
                        <Box className="mt-auto" {...(!isDesktop && { fullWidth: true })}>
                            <Button
                                {...(isDesktop
                                    ? {
                                          image: "images/search.svg",
                                          ariaLabel: "global.search",
                                          onClick: handleSubmit,
                                      }
                                    : { block: true, label: "global.search" })}
                                type="submit"
                                bsStyle="primary"
                                loading={isSubmitting}
                            />
                        </Box>
                    </Box>

                    <Box display="flex" column className="modal-scrollable-content">
                        {stateProductList &&
                            stateProductList.length > 0 &&
                            stateProductList.map((item) => (
                                <Box
                                    display="flex"
                                    className="p-3 mb-4"
                                    border="inset-background-divider"
                                    borderRadius="default"
                                    background="menu-background"
                                    alignX="between"
                                    fullWidth
                                    flex1>
                                    <Box display="flex" className="ml-3" alignX="flex-start" alignY="center">
                                        <Text>{`${item.labelMonth} ${item.year}`}</Text>
                                    </Box>
                                    <Box display="flex" alignX="flex-end" pullRight>
                                        {item.fileUrl && (
                                            <Button
                                                bsStyle={isDesktop ? "link" : "only-icon"}
                                                externalHref={item.fileUrl}
                                                image="images/icons/download.svg"
                                                className="mt-auto btn-color-secondary"
                                                {...(isDesktop && { label: "forms.product.downloadStatementPDF" })}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            ))}

                        {showEmptyList && (!stateProductList || stateProductList.length <= 0) && (
                            <Box
                                display="flex"
                                className="download-state-item"
                                row
                                alignItems="center"
                                alignX="between"
                                fullWidth
                                flex1>
                                <Box display="flex" className="item-label full-width pr-lg-3" alignY="center">
                                    <Text align="center" className="full-width" wrap>
                                        {i18n.get("forms.product.state.download.emptyList")}
                                    </Text>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </form>
        </PageLoading>
    );
};

DownloadAccountDetailForm.propTypes = {
    currentLang: string,
    dispatch: func,
    handleSubmit: func,
    isSubmitting: bool,
    stateProductList: shape([]).isRequired,
    metadataDownloadStateProduct: shape({}).isRequired,
    isDesktop: bool,
    isFeatchingProduct: bool,
    showEmptyList: bool.isRequired,
};

DownloadAccountDetailForm.defaultProps = {
    currentLang: "",
    dispatch: () => {},
    handleSubmit: () => {},
    isSubmitting: false,
    isDesktop: true,
    isFeatchingProduct: false,
};

const mapStateToProps = (state) => ({
    metadataDownloadStateProduct: productSelectors.getMetadataDownloadStateProduct(state),
    stateProductList: productSelectors.getStateProductList(state),
    isFeatchingProduct: productSelectors.isFeatchingProduct(state) || false,
    showEmptyList: productSelectors.showEmptyList(state) || false,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ month: undefined, year: undefined }),

        validate: (values) => {
            const errors = {};
            if (!values.month || !values.month[0] || !values.month[0].id) {
                errors.month = i18n.get(DEFAULT_REQUIRED);
            }
            if (!values.year || !values.year[0] || !values.year[0].id) {
                errors.year = i18n.get(DEFAULT_REQUIRED);
            }
            return errors;
        },

        handleSubmit: (values, formikBag) => {
            const { month, year } = values;
            const { account, dispatch } = formikBag.props;
            const monthId = month[0]?.id || "";
            const yearId = year[0]?.id || "";

            dispatch(productActions.downloadStateProduct(monthId, yearId, account?.idProduct || "", formikBag));
        },
    }),
)(DownloadAccountDetailForm);
