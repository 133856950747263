import { createReducer, makeActionCreator } from "util/redux";
// Action types
export const types = {
    RESET: "unlockUser/RESET",
    UNLOCK_USER_STEP1_PRE_REQUEST: "unlockUser/UNLOCK_USER_STEP1_PRE_REQUEST",
    UNLOCK_USER_STEP1_PRE_SUCCESS: "unlockUser/UNLOCK_USER_STEP1_PRE_SUCCESS",
    UNLOCK_USER_STEP1_SAVE_FORM: "unlockUser/UNLOCK_USER_STEP1_SAVE_FORM",
    UNLOCK_USER_STEP1_REQUEST: "unlockUser/UNLOCK_USER_STEP1_REQUEST",
    UNLOCK_USER_STEP1_SUCCESS: "unlockUser/UNLOCK_USER_STEP1_SUCCESS",
    UNLOCK_USER_REQUEST_FAILURE: "unlockUser/UNLOCK_USER_STEP1_FAILURE",
    UNLOCK_USER_STEP2_REQUEST: "unlockUser/UNLOCK_USER_STEP2_REQUEST",
    UNLOCK_USER_STEP2_SUCCESS: "unlockUser/UNLOCK_USER_STEP2_SUCCESS",
    RECOVERY_RESEND_OTP_AUTHENTICATE_CODE_REQUEST: "unlockUser/RECOVERY_RESEND_OTP_AUTHENTICATE_CODE_REQUEST",
};

// Initial state
export const INITIAL_STATE = {
    fetching: false,
    preData: {},
    documentCountry: "",
    documentType: "",
    documentNumberToShow: "",
    documentNumber: "",
    mail: "",
    exchangeToken: null,
    showCaptcha: false,
    resetCode: null,
    contact: "",
    idTransaction: "",
    operationType: "",
    questions: [],
    userName: "",
    errorTitle: "",
    errorMessage: "",
};

// Reducer
export default createReducer(INITIAL_STATE, {
    [types.RESET]: () => ({ ...INITIAL_STATE }),
    [types.UNLOCK_USER_STEP1_PRE_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.UNLOCK_USER_STEP1_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preData: action.preData,
    }),
    [types.UNLOCK_USER_STEP1_SAVE_FORM]: (state, action) => ({
        ...state,
        userName: action.userName,
        mail: action.mail,
    }),
    [types.UNLOCK_USER_STEP1_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.UNLOCK_USER_STEP1_SUCCESS]: (state, action) => ({
        ...state,
        username: action.username,
        mail: action.mail,
        showCaptcha: false,
        exchangeToken: action.exchangeToken,
    }),
    [types.UNLOCK_USER_STEP2_SUCCESS]: (state, action) => ({
        ...state,
        exchangeToken: action.exchangeToken,
        showCaptcha: false,
        idTransaction: action.idTransaction,
    }),
    [types.UNLOCK_USER_REQUEST_FAILURE]: (state, action) => ({
        ...state,
        fetching: false,
        errorMessage: action.errorMessage,
        errorTitle: action.errorTitle,
    }),
});

// Action creators
export const actions = {
    saveForm: makeActionCreator(types.UNLOCK_USER_STEP1_SAVE_FORM, "userName", "mail"),
    unlockUserStep1: makeActionCreator(types.UNLOCK_USER_STEP1_REQUEST, "userName", "mail", "formikBag"),
    unlockUserStep2: makeActionCreator(types.UNLOCK_USER_STEP2_REQUEST, "secondFactor", "exchangeToken", "formikBag"),
    requestFailure: makeActionCreator(types.UNLOCK_USER_REQUEST_FAILURE),
    reset: makeActionCreator(types.RESET),
    unlockUserResendOtp: () => ({
        type: types.RECOVERY_RESEND_OTP_AUTHENTICATE_CODE_REQUEST,
    }),
};

// Selectors
export const selectors = {
    getPreData: ({ unlockUser }) => unlockUser.preData,
    getFormData: ({ unlockUser }) => unlockUser.formData,
    getFetching: ({ unlockUser }) => unlockUser.fetching,
    getUsername: ({ unlockUser }) => unlockUser.username,
    getNotificationType: ({ unlockUser }) => unlockUser.notificationType,
    getExchangeToken: ({ unlockUser }) => unlockUser.exchangeToken,
    getShowCaptcha: ({ unlockUser }) => unlockUser.showCaptcha,
    getContact: ({ unlockUser }) => unlockUser.contact,
    getIdTransaction: ({ unlockUser }) => unlockUser.idTransaction,
    getTypeOperation: ({ unlockUser }) => unlockUser.operationType,
    getClientQuestions: ({ unlockUser }) => unlockUser.questions,
    getOtpMethod: ({ unlockUser }) => unlockUser.otpMethod,
    getErrorTitle: ({ unlockUser }) => unlockUser.errorTitle,
    getErrorMessage: ({ unlockUser }) => unlockUser.errorMessage,
    getEmail: ({ unlockUser }) => unlockUser.mail,
};
