import { CORPORATE_GROUP_ENVIRONMENT_TYPE, INSERT_SECURITY_QUESTION, UPDATE_SECURITY_QUESTION } from "constants.js";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import MenuChat from "pages/_components/menu/MenuChat";
import MenuDropdown from "pages/_components/menu/MenuDropdown";
import MenuLink from "pages/_components/menu/MenuLink";
import { bool, func, node, number, object, shape, string } from "prop-types";
import React from "react";
import Nav from "react-bootstrap/lib/Nav";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux/actions";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import { actions as productRequestActions } from "reducers/productRequest";
import { actions as checkActions } from "reducers/checks";
import { actions as productActions, selectors as productSelectors } from "reducers/products";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { get as getMessage } from "util/i18n";
import { actions as statusActions } from "reducers/status";
import WithPermissions from "pages/_components/WithPermissions";
import FeatureFlag from "../../FeatureFlag";
import { actions as paginatedTableActions } from "reducers/paginatedTable";
import { actions as usersActions } from "reducers/administration/users";

class Menu extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {
            synchProducts: false,
            fetchingToken: false,
        };
    }

    buildAdministrationMenu = () => {
        const { isMobile, isAdministrator, activeEnvironment, dispatch } = this.props;

        let admMenu = null;
        if (!isMobile && isAdministrator) {
            admMenu = (
                <MenuDropdown
                    titleKey="administration.menu.name"
                    key="menu.administration"
                    image="images/icons/menu-administration.svg">
                    <MenuLink
                        titleKey="administration.menu.users"
                        key="menu.administration.users"
                        linkTo="/administration/users"
                        onClick={() => dispatch(usersActions.setSelectedIndex(0))}
                        exact
                    />
                    {/* NO BORRAR (IMPLEMENTACION EMPRESAS) */}
                    <MenuLink
                        titleKey="administration.menu.groups"
                        key="menu.administration.groups"
                        linkTo="/administration/groups"
                        exact
                    />
                    <MenuLink
                        titleKey="administration.menu.signatureScheme"
                        key="menu.administration.signatureScheme"
                        linkTo={`/administration/${activeEnvironment.administrationScheme}/signaturesSchemes`}
                        exact
                    />
                    {/* <MenuLink
                        titleKey="administration.restrictions.environment.title"
                        key="menu.administration"
                        linkTo="/administration/restrictions"
                        exact
                    /> */}
                </MenuDropdown>
            );
        }

        return admMenu;
    };

    syncEnvironmentProducts = () => {
        const { dispatch, isfetchingSynchronization } = this.props;

        this.setState({
            synchProducts: true,
        });

        if (!isfetchingSynchronization) {
            dispatch(
                productActions.syncEnviromentProduct(false, () => {
                    this.setState({
                        synchProducts: false,
                    });
                }),
            );
        }
    };

    displayProductRequestModal = () => {
        const { dispatch, onOptionClicked, isMobile } = this.props;
        dispatch(productRequestActions.modalShow());
        if (isMobile) {
            onOptionClicked();
        }
    };

    goToEnvironment = (idEnvironment) => {
        const { dispatch, environments, isDesktop } = this.props;
        if (environments[idEnvironment].allowedToAccess === "true") {
            dispatch(sessionActions.changeEnvironment(idEnvironment, false, null, false));
            dispatch(sessionActions.setMobileMenuClosed());
        } else {
            dispatch(
                notificationActions.showNotification(
                    getMessage("administration.restrictions.unavailableEnvironment"),
                    "error",
                    ["desktop"],
                ),
            );
            dispatch(push("/desktop"));
        }
    };

    openSATServices = () => {
        const { dispatch, isfetchingToken } = this.props;

        if (!isfetchingToken) {
            this.setState({
                fetchingToken: true,
            });
            dispatch(
                sessionActions.openSATServices(() => {
                    this.setState({
                        fetchingToken: false,
                    });
                }),
            );
        }
    };

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(statusActions.showSessionCloseModal());
    };

    render() {
        const {
            activeEnvironment,
            isMobile,
            isMobileNative,
            pendingTransactionsQuantity,
            isfetchingSynchronization,
            isfetchingToken,
            environments,
            onOptionClicked,
        } = this.props;

        const { fetchingToken, synchProducts } = this.state;

        if (!activeEnvironment) {
            return null;
        }

        // ENVIRONMENT
        let environmentMenuDropdown = null;
        const environmentMenuLinks = [];

        Object.values(environments)
            .filter((environment) => parseInt(environment.idEnvironment, 10) !== activeEnvironment.id)
            .forEach((environment) => {
                environmentMenuLinks.push(
                    <MenuLink
                        key={environment.name}
                        childTitleKey={environment.name}
                        onClick={() => this.goToEnvironment(environment.idEnvironment)}
                    />,
                );
            });

        environmentMenuDropdown = (
            <MenuDropdown
                id="menuTransfers"
                key="menu.transfers"
                useChildren={
                    <Box display="flex" alignY="center" className="mr-auto environment-wrapper semibold size-2">
                        {/* <Image
                            src={`images/icons/enviroment-${activeEnvironment.type}.svg`}
                            className="environment-image"
                        /> */}
                        <Text className="menu-dropdown-btn-text menu-env-mobile">{activeEnvironment.name}</Text>
                    </Box>
                }>
                {environmentMenuLinks}
            </MenuDropdown>
        );

        // Otros servicios SAT
        const otherServicesSAT = (
            <Button
                image="images/icons/menu-link.svg"
                label="menu.otherServicesSAT"
                className="btn-menu"
                bsStyle={isfetchingToken && fetchingToken ? "only-icon" : "link"}
                key="menu.otherServicesSAT"
                onClick={this.openSATServices}
                loading={isfetchingToken && fetchingToken}
            />
        );

        // INICIO
        const desktopMenuDropdown = (
            <MenuLink
                titleKey="menu.desktop"
                key="menu.desktop"
                linkTo="/desktop"
                image="images/icons/menu-home.svg"
                exact
                tabIndex="0"
                onClick={onOptionClicked}
            />
        );

        // CUENTAS
        const accounts = (
            <MenuLink
                titleKey="menu.accounts"
                key="menu.accounts"
                linkTo="/accounts"
                image="images/products/icon_tab_CC.svg"
                // exact
                tabIndex="0"
                onClick={onOptionClicked}
            />
        );

        // CHEQUES
        const checks = (
            <MenuLink
                titleKey="menu.checks"
                key="menu.checks"
                linkTo="/checks"
                image="images/icons/transfer.svg"
                // exact
                onClick={() => {
                    const { dispatch } = this.props;
                    dispatch(checkActions.setSearchFilters(null));
                    if (onOptionClicked) {
                        onOptionClicked();
                    }
                }}
            />
        );

        // TARJETAS DE CREDITO
        const creditCards = (
            <MenuLink
                titleKey="menu.creditcards"
                key="menu.creditcards"
                linkTo="/creditCards"
                image="images/products/icon_tab_TC.svg"
                onClick={onOptionClicked}
            />
        );
        // CREDITOS
        const loans = (
            <MenuLink
                titleKey="menu.loans"
                key="menu.loans"
                linkTo="/loans"
                image="images/icons/menu-loans.svg"
                onClick={onOptionClicked}
            />
        );

        // INVERSIONES
        const deposits = (
            <MenuLink
                titleKey="menu.deposits"
                key="menu.deposits"
                linkTo="/deposits"
                image="images/icons/menu-deposits.svg"
                onClick={onOptionClicked}
            />
        );

        // PRODUCTOS
        // let productsMenuDropdown = null;
        const productsMenuLinks = [];

        productsMenuLinks.push(
            <MenuLink
                titleKey="menu.products.myCreditLines"
                key="menu.products.my.credit.lines"
                linkTo="/creditLines"
                exact
                onClick={onOptionClicked}
            />,
        );

        // SINCRONIZACION
        productsMenuLinks.push(
            <Button
                label="menu.synchronization"
                bsStyle="menu-synchronization"
                key="menu.synchronization"
                onClick={this.syncEnvironmentProducts}
                loading={isfetchingSynchronization && synchProducts}
            />,
        );

        // if (productsMenuLinks.length > 0) {
        //     productsMenuDropdown = (
        //         <MenuDropdown titleKey="menu.products" key="menu.products" image="images/icons/menu-products.svg">
        //             {productsMenuLinks}
        //         </MenuDropdown>
        //     );
        // }

        // TRANSFERENCIAS
        // let transferMenuDropdown = null;
        const transferMenuLinks = [];
        // if (activeEnvironment.permissions.accounts) {
        if (activeEnvironment.permissions.transferInternal) {
            transferMenuLinks.push(
                <MenuLink
                    linkTo="/formCustom/transferInternal"
                    key="transferInternal"
                    titleKey="menu.transfers.option"
                    exact
                />,
            );
        } else if (activeEnvironment.permissions.transferLocal) {
            transferMenuLinks.push(
                <MenuLink
                    linkTo="/formCustom/transferLocal"
                    key="transferLocal"
                    titleKey="menu.transfers.option"
                    exact
                />,
            );
        } else if (activeEnvironment.permissions.transferForeign) {
            transferMenuLinks.push(
                <MenuLink
                    linkTo="/formCustom/transferForeign"
                    key="transferForeign"
                    titleKey="menu.transfers.option"
                    exact
                />,
            );
        }
        // }

        if (transferMenuLinks.length <= 0) {
            transferMenuLinks.push(
                <MenuLink
                    linkTo="/formCustom/transferLocal"
                    key="transferLocal"
                    titleKey="menu.transfers.option"
                    exact
                />,
            );
        }
        transferMenuLinks.push(
            <MenuLink
                linkTo="/InternationalTransferTracking"
                key="InternationalTransferTracking"
                titleKey="menu.transfers.tracking"
                exact
            />,
        );

        // transferMenuDropdown = (
        //     <MenuDropdown
        //         id="menuTransfers"
        //         titleKey="menu.transfers"
        //         key="menu.transfers"
        //         image="images/icons/menu-transfers.svg">
        //         {transferMenuLinks}
        //     </MenuDropdown>
        // );

        // PAGOS
        // let paymentMenuDropdown = null;
        const paymentMenuLinks = [];
        // if (activeEnvironment.permissions.accounts) {
        if (
            activeEnvironment.permissions.payCreditCard ||
            activeEnvironment.permissions.payCreditCardOther ||
            activeEnvironment.permissions.payCreditCardThird
        ) {
            if (activeEnvironment.permissions.payCreditCard) {
                paymentMenuLinks.push(
                    <MenuLink
                        titleKey="menu.payments.creditCard"
                        key="menu.payments.creditCard"
                        linkTo="/formCustom/payCreditCardLocal"
                        exact
                    />,
                );
            } else if (activeEnvironment.permissions.payCreditCardThird) {
                paymentMenuLinks.push(
                    <MenuLink
                        titleKey="menu.payments.creditCard"
                        key="menu.payments.creditCard"
                        linkTo="/formCustom/payCreditCardThird"
                        exact
                    />,
                );
            }
        } else {
            paymentMenuLinks.push(
                <MenuLink
                    titleKey="menu.payments.creditCard"
                    key="menu.payments.creditCard"
                    linkTo="/formCustom/payCreditCardLocal"
                    exact
                />,
            );
        }

        paymentMenuLinks.push(
            <MenuLink titleKey="menu.payments.loan" key="menu.payments.loan" linkTo="/formCustom/payLoan" exact />,
        );

        paymentMenuLinks.push(
            <MenuLink
                titleKey="menu.payments.servicePayment"
                key="menu.payments.servicePayment"
                linkTo="/servicePayments"
                exact
            />,
        );

        if (
            activeEnvironment.permissions.rechargeCreditCardLocal ||
            activeEnvironment.permissions.rechargeCreditCardThird
        ) {
            if (activeEnvironment.permissions.rechargeCreditCardLocal) {
                paymentMenuLinks.push(
                    <MenuLink
                        titleKey="menu.reload.creditCard"
                        key="menu.reload.creditCard"
                        linkTo="/formCustom/rechargeCreditCardLocal"
                    />,
                );
            } else if (activeEnvironment.permissions.rechargeCreditCardThird) {
                paymentMenuLinks.push(
                    <MenuLink
                        titleKey="menu.reload.creditCard"
                        key="menu.reload.creditCard"
                        linkTo="/formCustom/rechargeCreditCardThird"
                        exact
                    />,
                );
            }
        } else {
            paymentMenuLinks.push(
                <MenuLink
                    titleKey="menu.reload.creditCard"
                    key="menu.reload.creditCard"
                    linkTo="/formCustom/rechargeCreditCardLocal"
                />,
            );
        }

        // if (paymentMenuLinks.length > 0) {
        //     paymentMenuDropdown = (
        //         <MenuDropdown titleKey="menu.payments" key="menu.payments" image="images/icons/menu-payments.svg">
        //             {paymentMenuLinks}
        //         </MenuDropdown>
        //     );
        // }
        // }

        // TARJETAS DE DEBITO
        // const debitCardMenuLink = (
        //     <MenuLink
        //         titleKey="menu.debitcard"
        //         key="menu.debitCard"
        //         linkTo="/debitCards"
        //         image="images/shortCutBtn/SCBcard.svg"
        //         exact
        //         tabIndex="0"
        //     />
        // );

        // TODO MVP2: MIS FINANZAS
        // const yourFinances = (
        //     <MenuLink
        //         titleKey="menu.yourFinances"
        //         key="menu.yourFinances"
        //         image="images/icons/menu-your-finances.svg"
        //         linkTo="/finances"
        //     />
        // );

        // BENEFICIARY
        // const beneficiaryMenuDropdown = (
        //     <MenuLink
        //         titleKey="menu.directory"
        //         key="menu.directory"
        //         linkTo="/frequentDestination"
        //         image="images/icons/menu-directory.svg"
        //         exact
        //         tabIndex="0"
        //     />
        // );

        // HISTORIAL
        const transactionsMenuDropdown = (
            <MenuLink
                titleKey="menu.transactions"
                key="menu.transactions"
                linkTo="/transactions/list"
                image="images/icons/menu-transactions.svg"
                exact
                tabIndex="0"
                onClick={()=>{
                    const {dispatch} = this.props;
                    dispatch(paginatedTableActions.setKeepLastPage(false));
                    if (onOptionClicked) {
                        onOptionClicked();
                    }
                }}
            />
        );

        // TRANSACCIONES PENDIENTES
        const pendingDispatch = (
            <FeatureFlag id="feature.signatureSchema.dispatchControl">
                <MenuLink
                    titleKey="menu.pendingDispatch"
                    key="menu.pendingDispatch"
                    linkTo="/pendingTransaction/list"
                    image="images/icons/menu-pending-transactions.svg"
                    notificationCount={pendingTransactionsQuantity}
                    notificationCountTextID="menu.pendingDispatch.badge"
                    exact
                    tabIndex="0"
                    onClick={()=>{
                        const {dispatch} = this.props;
                        dispatch(paginatedTableActions.setKeepLastPage(false));
                        if (onOptionClicked) {
                            onOptionClicked();
                        }
                    }}
                />
            </FeatureFlag>
        );

        // SOLICITUDES
        // let requestsMenuDropdown = null;
        const requestsMenuLinks = [];

        // Solicitudes
        // Referencias bancarias
        requestsMenuLinks.push(
            <MenuLink
                titleKey="menu.request.bankReferences"
                key="menu.request.bankReferences"
                linkTo="/formCustom/referenceLetter"
            />,
        );

        // Solicitud productos
        requestsMenuLinks.push(
            <Button
                label="menu.request.productRequest"
                bsStyle="menu-synchronization"
                key="menu.request.productRequest"
                onClick={this.displayProductRequestModal}
            />,
        );
        requestsMenuLinks.push(
            <MenuLink
                titleKey="menu.RequestsAndComplaints"
                key="menu.RequestsAndComplaints"
                linkTo="/requestsAndComplaints"
            />,
        );
        // requestsMenuLinks.push(
        //     <MenuLink
        //         titleKey="menu.request.creditCardActivation"
        //         key="menu.request.creditCardActivation"
        //         linkTo="/websiteunderconstruction"
        //         exact
        //     />,
        // );

        // requestsMenuDropdown = (
        //     <MenuDropdown titleKey="menu.request" key="menu.request" image="images/icons/menu-requests.svg">
        //         {requestsMenuLinks}
        //     </MenuDropdown>
        // );

        // // SEGURIDAD
        // let securityMenuDropdown = null;
        // const securityMenuLinks = [];

        // // TODO: Activacion de Token
        // securityMenuLinks.push(
        //     <MenuLink
        //         titleKey="menu.security.tokenActivation"
        //         key="menu.security.tokenActivation"
        //         linkTo="/websiteunderconstruction"
        //     />,
        // );

        // if (securityMenuLinks.length > 0) {
        //     securityMenuDropdown = (
        //         <MenuDropdown titleKey="menu.security" key="menu.security" image="images/icons/menu-security.svg">
        //             {securityMenuLinks}
        //         </MenuDropdown>
        //     );
        // }

        // GESTION DE TARJETAS
        // const creditCardManagementLinks = null;
        // const creditCardManagement = (
        //     <MenuDropdown
        //         titleKey="menu.creditCardManagement"
        //         key="menu.creditCardManagement"
        //         image="images/icons/menu-credit-card-management.svg"
        //         linkTo="/pendingTransaction/list">
        //         {creditCardManagementLinks}
        //     </MenuDropdown>
        // );

        // ADMINISTRACIÓN (DESKTOP)
        const administrationMenuDropdown = this.buildAdministrationMenu();

        // CERRAR SESIÓN
        const menuLogOut = (
            <MenuLink
                className="cursor-pointer"
                titleKey="global.logout"
                key="global.logout"
                image="images/icons/logOut.svg"
                onClick={this.logOut}
            />
        );

        const settingsMenuDropdown = (
            <MenuDropdown
                className="profile-section"
                titleKey="menu.settings"
                key="menu.settings"
                image="images/icons/user.svg">
                <MenuLink
                    titleKey="settings.index"
                    key="menu.settings"
                    linkTo="/settings"
                    exact
                    onClick={onOptionClicked}
                />
                <WithPermissions permissions={[UPDATE_SECURITY_QUESTION, INSERT_SECURITY_QUESTION]} somePermissions>
                    <MenuLink
                        titleKey="settings.securityQuestions"
                        key="settings.securityQuestions"
                        linkTo="/securityQuestions"
                        exact
                        onClick={onOptionClicked}
                    />
                </WithPermissions>
                <MenuLink
                    titleKey="settings.softToken"
                    key="settings.softToken"
                    linkTo="/settings/authenticatorHandler"
                    exact
                    onClick={onOptionClicked}
                />
                <></>
            </MenuDropdown>
        );

        const chatOption = (
            <Box>
                <MenuChat
                    titleKey="administration.menu.users"
                    key="menu.administration.users"
                    linkTo="/administration/users"
                />
            </Box>
        );

        return (
            <Nav className="nav-menu">
                {isMobile && <FirstLevelItem>{environmentMenuDropdown}</FirstLevelItem>}
                {!isMobile && <FirstLevelItem>{otherServicesSAT}</FirstLevelItem>}
                <FirstLevelItem>{desktopMenuDropdown}</FirstLevelItem>
                <FirstLevelItem>{accounts}</FirstLevelItem>
                <FirstLevelItem>{checks}</FirstLevelItem>
                <FirstLevelItem>{creditCards}</FirstLevelItem>
                <FirstLevelItem>{loans}</FirstLevelItem>
                <FirstLevelItem>{deposits}</FirstLevelItem>
                {/* <FirstLevelItem>{productsMenuDropdown}</FirstLevelItem> */}

                {/* Se ocultan opciones del menu segun el figma */}
                {/* <FirstLevelItem>{transferMenuDropdown}</FirstLevelItem>
                <FirstLevelItem>{paymentMenuDropdown}</FirstLevelItem>
                <FirstLevelItem>{debitCardMenuLink}</FirstLevelItem>
                <FirstLevelItem>{yourFinances}</FirstLevelItem>
                <FirstLevelItem>{beneficiaryMenuDropdown}</FirstLevelItem> */}
                <FirstLevelItem>{transactionsMenuDropdown}</FirstLevelItem>
                {activeEnvironment.type !== CORPORATE_GROUP_ENVIRONMENT_TYPE && (
                    <FirstLevelItem>{pendingDispatch}</FirstLevelItem>
                )}
                {/* <FirstLevelItem>{requestsMenuDropdown}</FirstLevelItem> */}
                {/* <FirstLevelItem>{securityMenuDropdown}</FirstLevelItem>
                <FirstLevelItem>{creditCardManagement}</FirstLevelItem> */}

                {activeEnvironment.type !== "retail" && <FirstLevelItem>{administrationMenuDropdown}</FirstLevelItem>}
                {isMobile && <FirstLevelItem>{settingsMenuDropdown}</FirstLevelItem>}
                {!isMobileNative && activeEnvironment.type === "retail" && chatOption}
                <FirstLevelItem>{menuLogOut}</FirstLevelItem>
            </Nav>
        );
    }
}

Menu.propTypes = {
    isMobile: bool,
    isMobileNative: bool.isRequired,
    dispatch: func.isRequired,
    isAdministrator: bool,
    activeEnvironment: shape({
        permissions: shape({
            accounts: bool,
            deposits: bool,
            creditcards: bool,
            payCreditCard: bool,
            payCreditCardThird: bool,
            payCreditCardOther: bool,
            payLoan: bool,
            payThirdPartiesCreditCard: bool,
            requestTransactionCancellation: bool,
            transferForeign: bool,
            transferInternal: bool,
            transferLocal: bool,
            transferThirdParties: bool,
            transferPeerToPeer: bool,
        }),
        // eslint-disable-next-line react/forbid-prop-types
        forms: object,
        administrationScheme: string,
        type: string,
    }).isRequired,
    pendingTransactionsQuantity: number,
    isfetchingSynchronization: bool.isRequired,
    onOptionClicked: func,
    environments: shape().isRequired,
    isfetchingToken: bool.isRequired,
};

Menu.defaultProps = {
    isMobile: false,
    isAdministrator: false,
    pendingTransactionsQuantity: 0,
    onOptionClicked: null,
};

function FirstLevelItem({ children }) {
    return <>{children}</>;
}

FirstLevelItem.propTypes = {
    children: node,
};
FirstLevelItem.defaultProps = {
    children: null,
};

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    lang: i18nSelectors.getLang(state),
    isAdministrator: sessionSelectors.isAdministrator(state),
    pendingTransactionsQuantity: transactionsSelectors.getPendingTransactionsQuantity(state),
    loggedUser: sessionSelectors.getUser(state),
    isfetchingSynchronization: productSelectors.isfetchingSynchronization(state),
    environments: sessionSelectors.getEnvironments(state),
    isfetchingToken: sessionSelectors.isfetchingToken(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(Menu)));
