/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import Button from "pages/_components/Button";
import CollapsibleInfo from "pages/_components/CollapsibleInfo";
import Dropdown from "pages/_components/Dropdown";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import { bool, func, node, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import { Col, Grid, Navbar } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import * as i18n from "util/i18n";

class ProductHead extends Component {
    static propTypes = {
        /**
         * back link url
         */
        backLinkTo: string,
        /**
         * back function, ignored if backLinkTo is specified
         */
        onBack: func,
        fetchingDownload: bool,
        onClickDownloadPDF: func,
        onClickDownloadXLS: func,
        renderDownload: func,
        handleOptionsClick: func,
        isDesktop: bool.isRequired,
        infoComponent: shape({
            data: node,
        }),
        children: node.isRequired,
        onClickDownloadCSV: func,
        secondaryBtn: shape({ href: string, label: string }),
    };

    static defaultProps = {
        onClickDownloadPDF: null,
        onClickDownloadXLS: null,
        renderDownload: null,
        handleOptionsClick: null,
        infoComponent: null,
        backLinkTo: "",
        fetchingDownload: false,
        onBack: null,
        onClickDownloadCSV: null,
        secondaryBtn: null,
    };

    handleClick = () => {
        const { handleOptionsClick } = this.props;

        if (handleOptionsClick) {
            handleOptionsClick();
        }
        document.activeElement.blur();
    };

    renderMenuOption = () => {
        const { isDesktop, handleOptionsClick } = this.props;

        if (!isDesktop) {
            return (
                handleOptionsClick && (
                    <div className="toolbar-item toolbar-item--fixed toolbar-item-right">
                        <Button
                            className="toolbar-item toolbar-item--fixed toolbar-btn toolbar-btn-right view-options"
                            onClick={this.handleClick}
                            image="images/headerCommandsMobile.svg"
                            label="view more options"
                            block={false}
                            bsStyle="link"
                        />
                    </div>
                )
            );
        }
        return null;
    };

    renderSecondaryBtn = () => {
        const { secondaryBtn } = this.props;

        return (
            <div className="toolbar-item">
                <Button label={secondaryBtn.label} bsStyle="outline" href={secondaryBtn.href} />
            </div>
        );
    };

    renderDownloadDropdown = () => {
        const {
            fetchingDownload,
            onClickDownloadPDF,
            onClickDownloadXLS,
            renderDownload,
            isDesktop,
            onClickDownloadCSV,
        } = this.props;

        if (!isDesktop) {
            return null;
        }
        if (renderDownload) {
            return renderDownload(fetchingDownload);
        }
        return (
            <div className="toolbar-item">
                <Dropdown
                    image="images/download.svg"
                    label="global.download"
                    bsStyle="outline"
                    fetching={fetchingDownload}
                    pullRight>
                    <Button
                        onClick={onClickDownloadPDF}
                        label="accounts.pdfFile"
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                    <Button
                        onClick={onClickDownloadXLS}
                        label="accounts.xlsFile"
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                    <Button
                        onClick={onClickDownloadCSV}
                        label="accounts.csvFile"
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                </Dropdown>
            </div>
        );
    };

    render() {
        const { backLinkTo, children, isDesktop, infoComponent, onBack, secondaryBtn } = this.props;

        const { data } = infoComponent;
        const childrenListRender = children.slice(1);

        return (
            <Fragment>
                <header className="view-header theme-product-detail">
                    <Helmet>
                        <title>{`Banco Bolivariano - ${i18n.get("activities.accounts.read")}`}</title>
                    </Helmet>
                    <Navbar collapseOnSelect fluid>
                        <Navbar.Header>
                            <div className="toolbar toolbar-product-name">
                                {backLinkTo && (
                                    <div className="toolbar-item toolbar-item--fixed toolbar-item-left">
                                        <Link className="btn btn-link toolbar-btn view-back" to={backLinkTo}>
                                            <Image className="svg-icon svg-caret" src="images/arrowLeft.svg" />
                                            <I18n id="global.back" componentProps={{ className: "visually-hidden" }} />
                                        </Link>
                                    </div>
                                )}
                                {onBack && (
                                    <div className="toolbar-item toolbar-item--fixed toolbar-item-left">
                                        <Button
                                            className="btn toolbar-btn view-back"
                                            onClick={onBack}
                                            image="images/arrowLeft.svg"
                                            block={false}
                                            label="global.back"
                                            bsStyle="link"
                                        />
                                    </div>
                                )}

                                {children[0]}
                                {this.renderMenuOption()}
                            </div>
                            {secondaryBtn && this.renderSecondaryBtn()}
                            {this.renderDownloadDropdown()}
                        </Navbar.Header>
                    </Navbar>

                    {isDesktop && infoComponent && (
                        <CollapsibleInfo
                            about={<span className="text-lead">{i18n.get("productHead.moreInfo.text")}</span>}>
                            {data}
                        </CollapsibleInfo>
                    )}
                </header>
                <div className="view-morphing theme-product-detail accounts-heading-detail">
                    <section className="container--layout items-center section-content-heading">
                        <Grid fluid className="m-0">
                            <Row className="justify-content-center">
                                <Col className="col-12">{childrenListRender}</Col>
                            </Row>
                        </Grid>
                    </section>
                </div>
            </Fragment>
        );
    }
}

export default resizableRoute(ProductHead);
