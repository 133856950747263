import React, { Component } from "react";
import { func, string, arrayOf, bool, oneOfType, any } from "prop-types";
import { WithContext as ReactTags } from "react-tag-input";
import classNames from "classnames";

class TagsInput extends Component {
    input = React.createRef();

    static propTypes = {
        placeholder: string,
        customDelimiter: arrayOf(oneOfType([string])),
        idField: string.isRequired,
        validationRegex: oneOfType([string, any]),
        setTagValue: func.isRequired,
        setAllTagValue: func.isRequired,
        removeTagValue: func.isRequired,
        isFocused: bool,
        toggleIsFocused: func,
        allowUnique: bool,
    };

    static defaultProps = {
        customDelimiter: [],
        placeholder: "",
        validationRegex: new RegExp(/[\s\S]*/),
        allowUnique: true,
        isFocused: false,
        toggleIsFocused: undefined,
    };

    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
    }

    handleClick = () => {
        this.input.current.ref.current.textInput.focus();
    };

    handleInputBlur = () => {
        const pendingEmail = this.input.current.ref.current.state.query;
        if (this.validateItem(pendingEmail)) {
            this.handleAddition(pendingEmail);
            this.resetReactTagsInput();
        }
    };

    handleAddition = (pastedText) => {
        const selectedText = typeof pastedText === "string" ? pastedText : pastedText.id;
        const validText = this.validateItem(selectedText);
        const textWithDelimiters = this.hasDelimeters(selectedText);
        if (validText || textWithDelimiters) {
            if (textWithDelimiters) {
                this.managePasteText(selectedText); // Last item of the list will be returned
            } else {
                this.addNewTagItem(selectedText);
            }
        }
    };

    // If more than one element is passed, then it will process them individually
    managePasteText = (textParam) => {
        let separatedItems = textParam;
        const { customDelimiter, allowUnique } = this.props;

        for (let i = 0; i < customDelimiter.length; i++) {
            separatedItems = separatedItems.split(customDelimiter[i]).join("|#|");
        }
        separatedItems = separatedItems.split("|#|");
        let isValidPaste = true;
        const validItems = [];
        for (let j = 0; j < separatedItems.length; j++) {
            const evalItem = separatedItems[j];
            const isValidItem = this.validateItem(evalItem);
            if (isValidItem) {
                if (allowUnique && !this.isDuplicate(evalItem) && !this.isDuplicate(evalItem, validItems)) {
                    validItems.push(evalItem);
                }
            }
            if (!isValidItem && evalItem.length) {
                isValidPaste = false;
                break;
            }
        }
        if (isValidPaste) {
            this.addNewTagItem(validItems, true);
        }
    };

    // Checks if submitted element matches its filter, returns True if element is valid
    validateItem = (textItem) => {
        const { validationRegex } = this.props;
        return validationRegex.test(textItem);
    };

    addNewTagItem = (itemText, isPaste = false) => {
        const { setTagValue, setAllTagValue, allowUnique } = this.props;
        const { items } = this.state;
        const newItem = { id: itemText, text: itemText };

        if (isPaste) {
            itemText.forEach((i) => items.push({ id: i, text: i }));
            setAllTagValue(items);
        } else {
            if (allowUnique && !this.isDuplicate(newItem)) {
                items.push(newItem);
            }
            items.forEach((i) => {
                setTagValue(i);
            });
        }
    };

    // Check on duplicates
    isDuplicate = (newItem, optionalArray = null) => {
        const { items } = this.state;
        let list;
        if (optionalArray) {
            list = optionalArray.filter((val) => val === newItem);
        } else {
            list = items.filter((item) => item.id === newItem.id);
        }
        return list.length > 0;
    };

    // Looks up for custom delimiters
    hasDelimeters = (textParam) => {
        const { customDelimiter } = this.props;
        for (let i = 0; i < customDelimiter.length; i++) {
            if (textParam && textParam.indexOf(customDelimiter[i]) > 0) {
                return true;
            }
        }
        return false;
    };

    handleDelete = (i) => {
        const { removeTagValue } = this.props;
        const { items } = this.state;
        items.splice(i, 1);
        removeTagValue(i);
    };

    handleChange = (tag) => {
        const { customDelimiter, validationRegex } = this.props;
        if (customDelimiter.includes(tag.charAt(tag.length - 1))) {
            const email = tag.slice(0, -1);
            this.handleAddition(email, validationRegex);
            this.resetAndFocusReactTagsInput();
        }
    };

    resetReactTagsInput() {
        this.input.current.ref.current.setState({ query: "" });
        this.input.current.ref.current.textInput.value = "";
    }

    resetAndFocusReactTagsInput() {
        this.input.current.ref.current.setState({ query: "" });
        this.input.current.ref.current.textInput.value = "";
        this.input.current.ref.current.textInput.focus();
    }

    render() {
        const { placeholder, idField, validationRegex, isFocused, toggleIsFocused } = this.props;
        const { items } = this.state;
        return (
            <div
                className={classNames("input-group", { "has-focus": isFocused })}
                onClick={this.handleClick}
                onFocus={toggleIsFocused}
                onBlur={toggleIsFocused}>
                <ReactTags
                    id={idField}
                    ref={this.input}
                    autofocus={false}
                    tags={items}
                    inputFieldPosition="inline"
                    allowDragDrop={false}
                    handleInputChange={this.handleChange}
                    handleDelete={this.handleDelete}
                    handleAddition={this.handleAddition}
                    handleInputBlur={this.handleInputBlur}
                    placeholder={placeholder}
                    validationRegex={validationRegex}
                    classNames={{
                        tags: "tagsClass",
                        tagInput: "tagInputClass",
                        tagInputField: "tagInputFieldClass",
                        selected: "selectedClass",
                        tag: "tagClass",
                        remove: "removeClass",
                        suggestions: "suggestionsClass",
                        activeSuggestion: "activeSuggestionClass",
                    }}
                />
            </div>
        );
    }
}

export default TagsInput;
