import React, { Component } from "react";
import { node, string, bool, func } from "prop-types";
import Grid from "react-bootstrap/lib/Grid";
import Row from "pages/_components/Row";

import ContainerColumn from "pages/_components/container/Column";
import ContainerColumnHeader from "pages/_components/container/ColumnHeader";
import ContainerColumnBody from "pages/_components/container/ColumnBody";

class Container extends Component {
    static propTypes = {
        children: node.isRequired,
        className: string,
        gridClassName: string,
        singleRow: bool,
        containerRef: func,
    };

    static defaultProps = {
        gridClassName: "",
        className: "",
        singleRow: true,
        containerRef: null,
    };

    static Row = Row;

    static Column = ContainerColumn;

    // TODO remove this component
    static ColumnHeader = ContainerColumnHeader;

    // TODO remove this component
    static ColumnBody = ContainerColumnBody;

    render() {
        const { children, className, gridClassName, singleRow, containerRef, ...props } = this.props;

        return (
            <article className={className} ref={containerRef}>
                <Grid className={gridClassName} {...props}>
                    {!singleRow ? children : <Row className="justify-content-center">{children}</Row>}
                </Grid>
            </article>
        );
    }
}

export default Container;
