import { createReducer, makeActionCreator } from "util/redux";

export const GET_PAGINATED_DATA_LIST = "paginatedTable/GET_PAGINATED_DATA_LIST";

export const types = {
    GET_PAGINATED_DATA_LIST_REQUEST: `${GET_PAGINATED_DATA_LIST}_REQUEST`,
    GET_PAGINATED_DATA_LIST_START: `${GET_PAGINATED_DATA_LIST}_START`,
    GET_PAGINATED_DATA_LIST_ERROR: `${GET_PAGINATED_DATA_LIST}_ERROR`,
    GET_PAGINATED_DATA_LIST_SUCCESS: `${GET_PAGINATED_DATA_LIST}_SUCCESS`,
    CLEAR_PAGED_DATA_LIST: `paginatedTable/CLEAR_PAGED_DATA_LIST`,
    SET_KEEP_LAST_PAGE: `paginatedTable/SET_KEEP_LAST_PAGE`,
};

export const INITIAL_STATE = {
    paginatedData: {},
    keepLastPage: false,
};

export default createReducer(INITIAL_STATE, {
    [types.GET_PAGINATED_DATA_LIST_REQUEST]: (state) => state,
    [types.GET_PAGINATED_DATA_LIST_START]: (state) => state,
    [types.GET_PAGINATED_DATA_LIST_ERROR]: (state) => state,
    [types.GET_PAGINATED_DATA_LIST_SUCCESS]: (state, { id, paginatedData }) => ({
        ...state,
        paginatedData: {
            [id]: paginatedData,
        },
    }),
    [types.CLEAR_PAGED_DATA_LIST]: () => ({ ...INITIAL_STATE }),
    [types.SET_KEEP_LAST_PAGE]: (state, { keepLastPage }) => ({
        ...state, keepLastPage
    }),
});

export const actions = {
    getPagedDataList: makeActionCreator(
        types.GET_PAGINATED_DATA_LIST_REQUEST,
        "idActivity",
        "page",
        "nextIndex",
        "filters",
        "idProduct",
        "idFieldName",
        "notificationScopes",
        "name",
        "setHasError"
    ),
    getPagedDataListStart: makeActionCreator(types.GET_PAGINATED_DATA_LIST_START),
    getPagedDataListError: makeActionCreator(types.GET_PAGINATED_DATA_LIST_ERROR),
    getPagedDataListSuccess: makeActionCreator(types.GET_PAGINATED_DATA_LIST_SUCCESS, "id", "paginatedData"),
    clearPagedDataList: makeActionCreator(types.CLEAR_PAGED_DATA_LIST),
    setKeepLastPage: makeActionCreator(types.SET_KEEP_LAST_PAGE, "keepLastPage"),
};

export const selectors = {
    getPaginatedData: ({ paginatedTable }) => paginatedTable.paginatedData,
    getKeepLastPage: ({ paginatedTable }) => paginatedTable.keepLastPage,
};
