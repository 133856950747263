/** */
import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { func, shape } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import { actions as tourActions } from "reducers/tour";
import * as i18nUtils from "util/i18n";

class TourMobile extends Component {
    state = {
        isStep: 1,
    };

    static propTypes = {
        steps: shape({}).isRequired,
        dispatch: func.isRequired,
    };

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.afterChangeHandler = this.afterChangeHandler.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    skip() {
        const { dispatch } = this.props;
        dispatch(tourActions.hide());
        dispatch(tourActions.addSkip());
    }

    begin() {
        const { dispatch } = this.props;
        dispatch(tourActions.hide());
        dispatch(tourActions.finish());
    }

    afterChangeHandler(index) {
        this.setState({ isStep: index + 1 });
    }

    render() {
        const { isStep } = this.state;
        const { steps } = this.props;

        const ArrowLeft = (props) => (
            <Button
                {...props}
                image="images/mobileDashboardTour/chevronTourLeft.svg"
                bsStyle="link"
                imageMd
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
                // eslint-disable-next-line
                onClick={this.previous}
            />
        );
        const ArrowRight = (props) => (
            <Button
                {...props}
                image="images/mobileDashboardTour/chevronTourRight.svg"
                bsStyle="link"
                imageMd
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
                // eslint-disable-next-line
                onClick={this.next}
            />
        );
        const SkipBtn = (props) => (
            <Button
                {...props}
                bsStyle="link"
                label="dashboardTour.mobile.skipBtn"
                imageMd
                onClick={() => {
                    // eslint-disable-next-line
                    this.skip();
                }}
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
            />
        );

        const FinishBtn = (props) => (
            <Button
                {...props}
                bsStyle="link"
                label="dashboardTour.mobile.startBtn"
                imageMd
                onClick={() => {
                    // eslint-disable-next-line
                    this.begin();
                }}
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
            />
        );
        const settings = {
            arrows: true,
            prevArrow: isStep > 1 ? <ArrowLeft /> : <SkipBtn />,
            nextArrow: isStep < 5 ? <ArrowRight /> : <FinishBtn />,
            infinite: true,
            dots: true,
            slidesToShow: 1,
            draggable: true,
            lazyLoad: false,
            swipeToSlide: true,
            slidesToScroll: 1,
            speed: 300,
            afterChange: this.afterChangeHandler,
            responsive: [
                {
                    breakpoint: 1232,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 420,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        return (
            <Box display="flex" column fullHeight className="tour-mobile">
                <Slider
                    ref={(c) => {
                        this.slider = c;
                    }}
                    {...settings}>
                    {steps.map((step) => (
                        <Box
                            key={step.label}
                            label={step.label}
                            onClick={step.onClick}
                            display="flex"
                            alignY="center"
                            fullHeight>
                            <Box className="mb-10">
                                <Image src={step.image} />
                            </Box>
                            {step.title && (
                                <Text
                                    component="h1"
                                    labelKey={step.title}
                                    color="heading"
                                    size="big"
                                    bold
                                    className="mt-0 mb-7 pb-2"
                                />
                            )}
                            <Text
                                component="p"
                                color="heading"
                                labelKey={step.content}
                                className={classNames("m-0", { "pt-11 mt-12": !step.title })}
                            />
                        </Box>
                    ))}
                </Slider>
            </Box>
        );
    }
}

export default connect()(TourMobile);
