import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions as restrictionActions, selectors as restrictionSelectors } from "reducers/administration/restrictions";
import { selectors as sessionSelectors } from "reducers/session";

const RESTRICTION_TYPE_CALENDAR = "Calendar";
const RESTRICTION_TYPE_IP = "IP";

class RestrictionsUserProfile extends Component {
    static propTypes = {
        restrictions: arrayOf(shape({})),
        activeEnvironment: shape({}).isRequired,
        idUser: string.isRequired,
        dispatch: func.isRequired,
        calendarEnabled: bool.isRequired,
        iPEnabled: bool.isRequired,
        isAdminUser: bool.isRequired,
    };

    static defaultProps = {
        restrictions: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(restrictionActions.getRestrictionsAvailability());
    }

    renderNotRestrictionsApplied = () => (
        <Box display="flex" alignX="center" className="py-3" fullWidth>
            <Text size="6" color="heading-color" labelKey="administration.restrictions.noRestrictions" align="center" />
        </Box>
    );

    getCalendarSection = (data) => {
        if (data && data.length > 0) {
            const listDays = data[0].listDays.map((name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase());
            if (data[0].perpetual) {
                return (
                    <Text
                        size="6"
                        color="primary"
                        bold
                        className="m-0"
                        labelKey="administration.restrictions.perpetual.detail"
                    />
                );
            }
            return (
                data.length > 0 && (
                    <Box display="flex" gap="2" column>
                        <Box display="flex">
                            <span>
                                <Text
                                    component="label"
                                    size="6"
                                    color="primary"
                                    bold
                                    className="m-0"
                                    labelKey="administration.restrictions.timeZone.label"
                                    addColon
                                />
                                <Text size="6" color="primary">
                                    {data[0].timeZone}
                                </Text>
                            </span>
                        </Box>
                        <Box display="flex">
                            <span>
                                <Text
                                    component="label"
                                    size="6"
                                    color="primary"
                                    bold
                                    className="m-0"
                                    labelKey="administration.restrictions.restrictions.days"
                                    addColon
                                />
                                <Text size="6" color="primary">
                                    {listDays.join(", ")}
                                </Text>
                            </span>
                        </Box>
                        <Box display="flex">
                            <span>
                                <Text
                                    component="label"
                                    size="6"
                                    color="primary"
                                    bold
                                    className="m-0"
                                    labelKey="administration.restrictions.restrictions.time"
                                    addColon
                                />
                                <Text size="6" color="primary" labelKey="administration.restrictions.from" />
                                <Text size="6" color="primary">
                                    {` ${data[0].startTime} `}
                                </Text>
                                <Text size="6" color="primary" labelKey="administration.restrictions.to" />

                                <Text size="6" color="primary">
                                    {` ${data[0].endTime} `}
                                </Text>
                                <Text size="6" color="primary" labelKey="administration.restrictions.hrs" />
                            </span>
                        </Box>
                    </Box>
                )
            );
        }
        return <></>;
    };

    getIPSection = (ipsData) =>
        ipsData.length > 0 && (
            <div>
                <div>
                    <I18n id="administration.restrictions.IP" /> {ipsData.map((i) => i.ip).join(", ")}
                </div>
            </div>
        );

    renderEnvironmentSection = (restrictions) => {
        const { activeEnvironment, calendarEnabled, iPEnabled } = this.props;
        if (
            !restrictions ||
            restrictions.length === 0 ||
            restrictions.filter((i) => i.idEnvironment && !i.idUser).length === 0 ||
            (!calendarEnabled && !iPEnabled)
        ) {
            return this.renderNotRestrictionsApplied();
        }

        const calendarRestrictions = restrictions.filter(
            (i) => i.idEnvironment && !i.idUser && i.type === RESTRICTION_TYPE_CALENDAR,
        );
        const ipsRestrictions = restrictions.filter(
            (i) => i.idEnvironment && !i.idUser && i.type === RESTRICTION_TYPE_IP,
        );

        const thereIsCalendarRestrByUser =
            restrictions.filter((i) => i.idEnvironment && i.idUser && i.type === RESTRICTION_TYPE_CALENDAR).length > 0;
        const thereIsIPRestrByUser =
            restrictions.filter((i) => i.idEnvironment && i.idUser && i.type === RESTRICTION_TYPE_IP).length > 0;

        const calendarOverwritten = calendarRestrictions.length > 0 && thereIsCalendarRestrByUser;
        const ipsOverwritten = ipsRestrictions.length > 0 && thereIsIPRestrByUser;

        return (
            <>
                <Box
                    display="flex"
                    alignY="center"
                    gap="2"
                    className={`mb-3 ${calendarOverwritten || ipsOverwritten ? "section-disabled" : ""}`}>
                    <Box display="flex">
                        <Image src={`images/icons/enviroment-${activeEnvironment.type}.svg`} width="7" height="7" />
                    </Box>
                    <Text size="6" color="heading-color">
                        {activeEnvironment.name}
                    </Text>
                </Box>

                <div className="section-disabled">
                    {calendarOverwritten && this.getCalendarSection(calendarRestrictions)}
                    {ipsOverwritten && this.getIPSection(ipsRestrictions)}
                </div>
                {/* NO BARRAR (IMPLEMENTACION PRODUCTO) */}
                {/* {(calendarOverwritten || ipsOverwritten) && (
                    <div className="restrictions-warning">
                        <Image src="images/warningIcon.svg" className="svg-icon" />{" "}
                        <span>
                            <I18n id="administration.restrictions.overwritten" />
                        </span>
                    </div>
                )} */}
                <div className="section-enabled">
                    {!calendarOverwritten && this.getCalendarSection(calendarRestrictions)}
                    {!ipsOverwritten && this.getIPSection(ipsRestrictions)}
                </div>
            </>
        );
    };

    renderUserSection = (restrictions) => {
        const { calendarEnabled, iPEnabled } = this.props;
        if (
            !restrictions ||
            restrictions.length === 0 ||
            restrictions.filter((i) => i.idEnvironment && i.idUser).length === 0 ||
            (!calendarEnabled && !iPEnabled)
        ) {
            return this.renderNotRestrictionsApplied();
        }

        const calendarRestrictions = restrictions.filter(
            (i) => i.idEnvironment && i.idUser && i.type === RESTRICTION_TYPE_CALENDAR,
        );
        const ipsRestrictions = restrictions.filter(
            (i) => i.idEnvironment && i.idUser && i.type === RESTRICTION_TYPE_IP,
        );

        return (
            <>
                {calendarRestrictions.length > 0 && this.getCalendarSection(calendarRestrictions)}
                {ipsRestrictions.length > 0 && this.getIPSection(ipsRestrictions)}
            </>
        );
    };

    render() {
        const { restrictions, idUser, calendarEnabled, iPEnabled } = this.props;
        const thereIsCalendarOrIpByUser =
            restrictions.filter(
                (i) => i.idUser && (i.type === RESTRICTION_TYPE_CALENDAR || i.type === RESTRICTION_TYPE_IP),
            ).length > 0;

        return (
            // TODO (LOADER)
            <PageLoading loading={false} bgTransparent>
                <Box display="flex" column className="pb-9" border="bottom-background-divider-1">
                    <Text
                        component="label"
                        labelKey="administration.restrictions.environment"
                        color="heading-color"
                        className="mb-5 px-8"
                    />
                    <Box display="flex" column className="px-8">
                        {this.renderEnvironmentSection(restrictions)}
                    </Box>
                </Box>

                <Box display="flex" alignX="between" gap="2" wrap className="px-8 pt-5 pb-3">
                    <Box display="flex" alignY="center" gap="5">
                        <Text component="label" labelKey="administration.restrictions.user" color="heading-color" />
                        {(iPEnabled || calendarEnabled) && (
                            <Box display="flex" gap="3">
                                <Button
                                    bsStyle="link"
                                    label={thereIsCalendarOrIpByUser ? "global.edit" : "global.configure"}
                                    href={`/administration/restrictions/${idUser || ""}`}
                                />
                                {thereIsCalendarOrIpByUser && (
                                    <Button
                                        bsStyle="link"
                                        label="global.delete"
                                        href={`/administration/restrictions/delete/${idUser || ""}`}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box display="flex" column className="px-8">
                    {this.renderUserSection(restrictions)}
                </Box>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    restrictions: restrictionSelectors.getRestrictions(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    calendarEnabled: restrictionSelectors.getCalendarEnabled(state),
    iPEnabled: restrictionSelectors.getIPEnabled(state),
});

export default connect(mapStateToProps)(RestrictionsUserProfile);
