import React, { Component } from "react";
import { shape, string, func, bool } from "prop-types";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as depositsSelectors } from "reducers/deposits";
import { connect } from "react-redux";
import { resizableRoute } from "pages/_components/Resizable";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import EmptyTextBox from "pages/_components/EmptyTextBox";
import ListMovementsDeposits from "../ListMovementsDeposits";

class DepositLastMovements extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool,
        fetching: bool,
        isDesktop: bool,
        deposit: shape({
            numero: string,
        }).isRequired,
        movements: shape({}).isRequired,
    };

    generateTableColumns = () => {
        const columnArray = [
            {
                key: "transaction",
                dataIndex: "transaction",
                title: "Transaccion",
                width: 300,
            },
            {
                key: "paymentMethod",
                dataIndex: "paymentMethod",
                title: "Imp renta ",
                width: 200,
            },
            {
                key: "taxRent",
                dataIndex: "taxRent",
                title: "Imp. renta USD",
                width: 200,
            },
            {
                key: "usdValue",
                dataIndex: "usdValue",
                title: "Valor USD",
                width: 200,
            },
            {
                key: "usdNeto",
                dataIndex: "usdNeto",
                title: "Neto USD",
                width: 200,
            },
        ];
        return columnArray;
    };

    renderDeposits = () => {
        const { isDesktop, movements } = this.props;

        return (
            <>
                {movements && movements.length > 0 ? (
                    <>
                        <ListMovementsDeposits isDesktop={isDesktop} data={movements} />
                    </>
                ) : (
                    <EmptyTextBox text="product.empty.text.deposits" footerLink />
                )}
            </>
        );
    };

    render() {
        const { isDesktop, movements } = this.props;

        const columnTable = this.generateTableColumns();

        return (
            <PaginatedDataTable
                nameList="deposits"
                data={movements}
                hasFilterApplied={movements.length}
                columns={columnTable}
                rowsPerPage={10}
                component={this.renderDeposits}
                componentProps={{
                    isDesktop,
                }}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    deposit: depositsSelectors.getSelectedDeposit(state),
    movements: depositsSelectors.getMovements(state),
});

export default connect(mapStateToProps)(resizableRoute(DepositLastMovements));
