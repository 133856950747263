import ProductBody from "pages/_components/ProductBody";
import ProductHead from "pages/_components/ProductHead";
import ProductsHeader from "pages/_components/ProductsHeader";
import { node } from "prop-types";
import React, { Component, Fragment } from "react";

class ProductDetail extends Component {
    static Head = ProductHead;

    static Header = ProductsHeader;

    static Body = ProductBody;

    render() {
        const { children } = this.props;
        return <Fragment>{children}</Fragment>;
    }
}

ProductDetail.propTypes = {
    children: node,
};

ProductDetail.defaultProps = {
    children: "",
};

export default ProductDetail;
