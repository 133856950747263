import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import Enviroments from "pages/_components/Enviroments";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import { arrayOf, bool, func, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "settings";

class SetDefaultEnvironment extends Component {
    static propTypes = {
        environments: arrayOf(arrayOf(oneOfType([string, shape({})]))).isRequired,
        activeEnvironment: shape({}),
        userData: shape({}),
        isFetching: bool.isRequired,
        isMobile: bool.isRequired,
        dispatch: func.isRequired,
    };

    static defaultProps = {
        activeEnvironment: {},
        userData: {},
    };

    state = {
        isClickRemoveBtn: false,
    };

    handleRemoveDefaultEnvironmentClick = () => {
        this.props.dispatch(settingsActions.changeDefaultEnvironment(0));
        this.setState({ isClickRemoveBtn: true });
    };

    render() {
        const { environments, userData, isFetching, activeEnvironment, isMobile } = this.props;
        const { isClickRemoveBtn } = this.state;

        return (
            <>
                <Head title="settings.defaultEnvironment" backLinkTo="/settings" />
                <MainContainer>
                    <Notification scopeToShow="changeDefaultEnvironment" />
                    <Box fullWidth {...(isMobile && { fullHeight: true })}>
                        <Form {...(isMobile && { className: "full-height" })}>
                            <Box display="flex" column fullWidth {...(isMobile && { fullHeight: true })}>
                                <Row className="height-auto">
                                    <Col xs={12} md={8} mdOffset={2}>
                                        <Disclaimer
                                            className="p-3 px-5 p-md-5 mt-5 mt-md-0 mb-9"
                                            styled="info"
                                            labelKey="settings.defaultEnvironment.info"
                                        />
                                    </Col>
                                </Row>
                                <Box>
                                    <Row className="mb-5">
                                        <Col xs={12} md={6} mdOffset={3}>
                                            <Field
                                                name="environment"
                                                environments={environments}
                                                activeEnvironment={activeEnvironment}
                                                component={Enviroments}
                                                fromSetDefaultEnvironmentPage
                                                userData={userData}
                                                noLegendTextId
                                                whiteBoxed
                                            />
                                        </Col>
                                    </Row>
                                </Box>
                                <Box {...(isMobile && { className: "mt-auto" })}>
                                    <Row>
                                        <Col xs={12} md={6} mdOffset={3}>
                                            <Row>
                                                {userData.idDefaultEnvironment && (
                                                    <Col xs={12} md={6}>
                                                        <Button
                                                            type="button"
                                                            loading={isFetching && isClickRemoveBtn}
                                                            label="settings.defaultEnvironment.buttonRemoveDefaultEnvironment"
                                                            bsStyle="outline"
                                                            btnUppercase
                                                            onClick={() => this.handleRemoveDefaultEnvironmentClick()}
                                                            block
                                                        />
                                                    </Col>
                                                )}
                                                <Col
                                                    xs={12}
                                                    md={userData.idDefaultEnvironment ? 6 : 12}
                                                    {...(isMobile && { className: "grid-reversed" })}>
                                                    <Button
                                                        type="submit"
                                                        loading={isFetching && !isClickRemoveBtn}
                                                        label="global.select"
                                                        bsStyle="primary"
                                                        block
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Box>
                            </Box>
                        </Form>
                    </Box>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    environments: Object.entries(sessionSelectors.getEnvironments(state)),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    userData: sessionSelectors.getUser(state),
    isFetching: settingsSelectors.isFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: ({ userData }) => ({
            environment: userData.idDefaultEnvironment || "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                environment: Yup.string().required(i18n.get(`${FORM_ID}.defaultEnvironment.environment.required`)),
            }),
        handleSubmit: ({ environment }, formikBag) => {
            formikBag.props.dispatch(settingsActions.changeDefaultEnvironment(environment));
        },
    }),
)(SetDefaultEnvironment);
