import { createReducer, makeActionCreator } from "util/redux";

const INITIAL_STATE = {
    name: "",
    description: "",
    adminUsers: [],
    groupStatus: "",
    members: [],
    fetching: true,
    adminGroup: false,
};

export const types = {
    LOAD_GROUP_REQUEST: "administrationAdvanced/LOAD_GROUP_REQUEST",
    LOAD_GROUP_FAILURE: "administrationAdvanced/LOAD_GROUP_FAILURE",
    LOAD_GROUP_SUCCESS: "administrationAdvanced/LOAD_GROUP_SUCCES",
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_GROUP_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.LOAD_GROUP_FAILURE]: (state) => ({ ...state, fetching: false }),
    [types.LOAD_GROUP_SUCCESS]: (_, { data }) => ({
        name: data.group.name,
        description: data.group.description,
        adminUsers: data.adminUsers,
        adminGroup: data.isAdminGroup,
        groupStatus: data.group.blocked ? "blocked" : "active",
        members: data.group.userList,
        fetching: false,
    }),
});

export const actions = {
    loadGroupRequest: makeActionCreator(types.LOAD_GROUP_REQUEST, "id"),
    loadGroupFailure: makeActionCreator(types.LOAD_GROUP_FAILURE),
    loadGroupSuccess: makeActionCreator(types.LOAD_GROUP_SUCCESS, "data"),
};

export const selectors = {
    getName: ({ group }) => group.name,
    getDescription: ({ group }) => group.description,
    isAdminGroup: ({ group }) => group.adminGroup,
    getAdminUsers: ({ group }) => group.adminUsers,
    getGroupStatus: ({ group }) => group.groupStatus,
    getMembers: ({ group }) => group.members,
    isFetching: ({ group }) => group.fetching,
};
