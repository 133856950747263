import React, { Component } from "react";
import { connect } from "react-redux";
import { arrayOf, shape, string, number } from "prop-types";

import { groupsSelectors, detailsSelectors } from "reducers/administration";

import Box from "pages/_components/Box";
import Text from "pages/_components/Text";

class UserDetailsGroupList extends Component {
    static propTypes = {
        selectedGroups: arrayOf(shape({ idGroups: number, idGroupAsString: string, name: string })).isRequired,
        adminGroupsIds: arrayOf(string).isRequired,
    };

    render() {
        const { selectedGroups, adminGroupsIds } = this.props;
        return (
            <Box display="flex" gap="3" className="flex-wrap">
                {selectedGroups.map(({ idGroup, name }) => (
                    <Box
                        key={idGroup}
                        display="flex"
                        background="primary-background-color"
                        className="py-3 px-5 my-auto"
                        border="primary-focus-color"
                        borderRadius="xxl"
                        gap="3"
                        fitWidth>
                        <Text color="primary-active-color" size="6">
                            {name}
                        </Text>
                    </Box>
                ))}
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedGroups: groupsSelectors.getSelectedGroups(state),
    adminGroupsIds: detailsSelectors.getAdminGroupsIds(state),
});

export default connect(mapStateToProps)(UserDetailsGroupList);
