import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import { bool, func, string } from "prop-types";
import { resizableRoute } from "pages/_components/Resizable";
import React from "react";
import classNames from "classnames";

const MessageHeader = ({ title, onClose, isDesktop, ptSafe, isInModal, colorBg }) => (
    <>
        {isDesktop ? (
            <Box
                display="flex"
                alignX="between"
                {...(!title && { alignX: "flex-end" })}
                {...(title && { className: "mb-5" })}
                fullWidth>
                {title && (
                    <Text component="h3" bold color="heading" size="3" className="m-0 mr-8" breakWord>
                        {title}
                    </Text>
                )}
                {onClose && (
                    <Box position="relative" className={classNames({ "pt-7": title, "pt-8": !title })}>
                        <Box position="absolute" right="n-5" top={title ? "n-3" : "n-2"}>
                            <Button
                                bsStyle="only-icon"
                                image="images/icons/cross16.svg"
                                imageColor="primary-color-i"
                                ariaLabel="global.close"
                                onClick={onClose}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        ) : (
            <Box
                display="flex"
                alignX="between"
                alignY="center"
                {...(!title && { alignX: "flex-end" })}
                {...(title && { className: "mb-5" })}
                fullWidth
                className={classNames("pb-7", { "pt-safe-7": ptSafe })}
                {...(!isDesktop && colorBg && { background: "menu-background" })}>
                <Box position="relative" fullWidth>
                    {title && (
                        <Box display="flex" alignX="center" fullWidth>
                            <Text
                                component="h3"
                                bold
                                color="heading"
                                size="2"
                                className="m-0 px-10"
                                align="center"
                                breakWord>
                                {title}
                            </Text>
                        </Box>
                    )}
                    {onClose && (
                        <Box position="absolute" right={isInModal ? "n-3" : "3"} top={title ? "n-3" : "n-2"}>
                            <Button
                                bsStyle="only-icon"
                                image="images/icons/cross16.svg"
                                imageColor="primary-color-i"
                                ariaLabel="global.close"
                                onClick={onClose}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        )}
    </>
);

MessageHeader.propTypes = {
    title: string,
    onClose: func,
    isDesktop: bool.isRequired,
    ptSafe: bool,
    isInModal: bool,
    colorBg: bool,
};

MessageHeader.defaultProps = { title: null, onClose: null, ptSafe: true, isInModal: false, colorBg: false };

export default resizableRoute(MessageHeader);
