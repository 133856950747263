import React, { Component } from "react";
import { node, string } from "prop-types";
import classNames from "classnames";

class TableData extends Component {
    static propTypes = {
        children: node,
        align: string,
        className: string,
    };

    static defaultProps = {
        children: null,
        align: "center",
        className: "",
    };

    render() {
        const { children, align, className } = this.props;
        return <div className={classNames("table-data", `text-${align}`, className)}>{children}</div>;
    }
}

export default TableData;
