import React from 'react'
import Image from 'pages/_components/Image/Image'
import Navbar from "react-bootstrap/lib/Navbar";
import Text from "pages/_components/Text";
import { Link } from 'react-router-dom'

export const HeaderInfo = () => {
  return (
    <>
    <header className="app-header-modal">
        <Navbar collapseOnSelect fluid>
            <Navbar.Header>
                <div className="navbar-header-wrapper">
                    <div className="d-flex items-center">
                        <Navbar.Brand>
                            <Link
                                className="navbar-brand logo-container"
                                to="/desktop">
                                <Image src="images/logo-bolivariano-mobile.svg" />
                                
                                <Text
                                    className="visually-hidden"
                                    labelKey="global.companyName"
                                />                                                        
                            </Link>
                                
                                
                        </Navbar.Brand>
                    </div>
                </div>
            </Navbar.Header>
        </Navbar>
    </header>
    </>
  )
}
