import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import { actions as loginActions } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";

export const ERROR_IMAGE_BY_CODE_MAP = new Map([
    ["COR097E", "images/icons/errors/maintenanceTasks.svg"],
    ["BAK589E", "images/icons/errors/transactionTimeOut.svg"],
    ["COR044E", "images/icons/errors/error.svg"],
]);

class Error extends React.Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        hasActiveSession: bool.isRequired,
        location: shape({
            code: string,
            message: string,
        }).isRequired,
    };

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { code } = location;
        if (code === "COR041E") {
            dispatch(
                loginActions.goBackToLoginAndShowMessage(
                    i18n.get("administration.restrictions.unavailableEnvironment"),
                ),
            );
        }
        window.common.hideSplashScreen();
    }

    getContent = () => {
        const { hasActiveSession, location, isDesktop } = this.props;
        const { message, idTransaction, code } = location;

        if (!code) {
            return <Redirect to="/desktop" />;
        }

        let errorTitle = i18n.get(`returnCode.${code}.title`);
        if (errorTitle === `*returnCode.${code}.title*`) {
            errorTitle = "Lo siento";
        }

        let errorMessage = message || i18n.get(`returnCode.${code}`);
        if (!errorMessage || errorMessage === `*returnCode.${code}*`) {
            errorMessage = "Ha ocurrido un error";
        }

        let errorBack = i18n.get("error.back");
        if (!errorBack || errorBack === "*error.back*") {
            errorBack = "Volver";
        }

        const btnHandlerOnClick = () => {
            const { dispatch } = this.props;
            dispatch(push(hasActiveSession ? "/desktop" : "/"));
        };

        return (
            <Box display="flex" column alignX="center" alignY="center" className="pt-9 pt-md-0 px-5" fullHeight>
                <Text
                    component="h1"
                    align="center"
                    color="heading"
                    size={isDesktop ? "big" : "extra-big"}
                    bold
                    className="mt-0 mt-md-0 mb-7">
                    {errorTitle}
                </Text>

                <Box display="flex" alignX="center" className="mb-8 mb-md-0" fullWidth>
                    <Image
                        className="error-img"
                        src={ERROR_IMAGE_BY_CODE_MAP.get(code || "") || "images/icons/errors/error.svg"}
                    />
                </Box>

                {!message && i18n.get(`returnCode.${code}`) !== `*returnCode.${code}*` ? (
                    <Text
                        component="p"
                        align="center"
                        color="heading"
                        size={isDesktop ? "5" : "3"}
                        className={`mt-0 mt-md-7 mb-8 mb-md-${idTransaction ? "7" : "11"} `}
                        labelKey={`returnCode.${code}`}
                    />
                ) : (
                    <Text
                        component="p"
                        align="center"
                        color="heading"
                        size={isDesktop ? "5" : "3"}
                        className={`mt-0 mt-md-7 mb-8 mb-md-${idTransaction ? "7" : "11"} `}>
                        {errorMessage}
                    </Text>
                )}

                {idTransaction && (
                    <Box display="flex" alignX="center" fullWidth className="mt-0 mb-8 mb-md-11">
                        <Text
                            addColon
                            size={isDesktop ? "7" : "6"}
                            color="heading-color"
                            labelKey="forms.transaction.ticket.scheduled.idTransaction.label"
                        />
                        <Text size={isDesktop ? "7" : "6"} color="heading-color">
                            {idTransaction}
                        </Text>
                    </Box>
                )}
                <Row>
                    <Col xs={12} md={4} mdOffset={4}>
                        <Box display="flex" alignX="center" className="px-md-5 pb-9">
                            <Button bsStyle="primary" onClick={btnHandlerOnClick} block>
                                {errorBack}
                            </Button>
                        </Box>
                    </Col>
                </Row>
            </Box>
        );
    };

    render() {
        return this.getContent();
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(Error)));
