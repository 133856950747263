import classNames from "classnames";
import Box from "pages/_components/Box";
import { bool, node, string } from "prop-types";
import React, { Component } from "react";
import Navbar from "react-bootstrap/lib/Navbar";

class Header extends Component {
    static propTypes = {
        addClassName: string,
        additionalClassName: string,
        children: node.isRequired,
        collapsed: bool.isRequired,
        position: string,
    };

    static defaultProps = {
        addClassName: "",
        additionalClassName: "",
        position: null,
    };

    state = {
        scrolled: false,
    };

    componentDidMount() {
        this.mainElement = document.querySelector("main");
        if (this.mainElement) {
            this.mainElement.addEventListener("scroll", this.handleScroll);
        }
    }

    componentWillUnmount() {
        if (this.mainElement) {
            this.mainElement.removeEventListener("scroll", this.handleScroll);
        }
    }

    handleScroll = () => {
        const scrolled = this.mainElement.scrollTop > 0;
        this.setState({ scrolled });
    };

    handlerOnCLick = () => {
        const { collapsed } = this.state;
        this.setState({ collapsed: !collapsed });
    };

    render() {
        const { addClassName, additionalClassName, children, collapsed, position } = this.props;
        const { scrolled } = this.state;
        return (
            <Box
                component="header"
                position={position}
                className={classNames("view-header", additionalClassName, { "shadow-header": scrolled })}>
                <Navbar expanded={collapsed} collapseOnSelect fluid aria-label="Content header">
                    <Navbar.Header>
                        <Box className={classNames("toolbar", addClassName)}>{children}</Box>
                    </Navbar.Header>
                </Navbar>
            </Box>
        );
    }
}

export default Header;
