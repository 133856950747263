import classNames from "classnames";
import Badge from "pages/_components/Badge";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import { arrayOf, func, number, oneOfType, shape, string } from "prop-types";
import React from "react";
import Popup from "../Popup";
import UserLink from "../UserLink";

const NavigationBarOptions = ({ className, loggedUser, unreadCommunications, getLastLoginInfo, userNameInfo }) => (
    <Box
        id="navigation-bar-header"
        component="nav"
        role="navigation"
        // TODO (LABEL a11y)
        ariaLabel="Notificaciones y Configuración"
        className={classNames("navigation-bar-options", className)}>
        {getLastLoginInfo()}

        <Box display="flex" position="relative" className="ml-5">
            <Button
                id="notificationButton"
                bsStyle="only-icon"
                image="images/icons/bell.svg"
                href="/communications"
                imageMd>
                <I18n component="p" id="menu.chat.badge" componentProps={{ className: "visually-hidden" }} />
            </Button>
            <Badge count={unreadCommunications} />
        </Box>

        <Popup id="userPopup" popupTitleID="menu.settings.panelTitle" popupButtonContent={userNameInfo()}>
            <UserLink loggedUser={loggedUser} />
        </Popup>
    </Box>
);

NavigationBarOptions.propTypes = {
    className: string,
    loggedUser: shape({
        accessToken: string,
        defaultAvatarId: string,
        email: string,
        previousLoginInfo: arrayOf(
            shape({
                city: string,
                country: string,
                date: string,
                idUser: string,
                ip: string,
                latitude: number,
                longitude: number,
            }),
        ),
        securitySeal: oneOfType([number, string]),
        userFullName: string,
    }),
    unreadCommunications: number,
    getLastLoginInfo: func,
    userNameInfo: func,
};

NavigationBarOptions.defaultProps = {
    className: null,
    loggedUser: null,
    unreadCommunications: 0,
    getLastLoginInfo: () => {},
    userNameInfo: () => {},
};

export default NavigationBarOptions;
