import { useEffect, useState } from "react";
import * as i18n from "util/i18n";

export const generateExchangeRateLabel = (exchangeData) => {
    if (!exchangeData) {
        return "";
    }

    const { rate, targetCurrency } = exchangeData;
    const { purchase = 0, sale = 0 } = exchangeData.currencyExchangeObject;
    if (sale > purchase) {
        return `1.00 ${targetCurrency} = ${rate} USD `;
    }
    return `1.00 USD = ${rate} ${targetCurrency}`;
};

const getOptionRate = (exchangeObject, currencyOrigin, currencyDestination) => {
    const { sale, purchase, baseCurrencyCode } = exchangeObject;

    if (currencyOrigin === baseCurrencyCode) {
        return sale;
    }
    if (currencyDestination === baseCurrencyCode) {
        return purchase;
    }

    return undefined;
};

const multiplyFunction = (a, b) => a * b;
const divideFunction = (a, b) => a / b;

const getOperationRate = (exchangeObject, currencyOrigin, currencyDestination, currencyAmount) => {
    const { purchase, sale } = exchangeObject;

    if (currencyOrigin === currencyAmount) {
        return sale < purchase ? divideFunction : multiplyFunction;
    }
    if (currencyDestination === currencyAmount) {
        return sale > purchase ? multiplyFunction : divideFunction;
    }

    return undefined;
};

const getCurrencyEstimate = (currencyAmount, currencyOrigin, currencyDestination) => {
    if (currencyOrigin === currencyAmount) {
        return currencyDestination;
    }
    if (currencyDestination === currencyAmount) {
        return currencyOrigin;
    }

    return undefined;
};

const getTargetCurrency = (exchangeObject, currencyOrigin, currencyDestination) => {
    const { baseCurrencyCode } = exchangeObject;

    if (currencyOrigin === baseCurrencyCode) {
        return currencyDestination;
    }
    if (currencyDestination === baseCurrencyCode) {
        return currencyOrigin;
    }

    return undefined;
};

/**
 *
 * @returns
 */

export const useExchangeRateValue = (defaultExchangeData) => {
    const [exchange, setExchange] = useState(defaultExchangeData);

    const setExchangeData = (currencyOrigin, currencyDestination, currencyAmount, quantity, exchangeRateList) => {
        if (!currencyOrigin || !currencyDestination || !currencyAmount || !quantity || !exchangeRateList) {
            setExchange(undefined);
            return;
        }

        if (currencyOrigin === currencyDestination) {
            setExchange(undefined);
            return;
        }

        const currencyExchangeObject = exchangeRateList?.find(
            (exRate) =>
                (exRate.baseCurrencyCode === currencyOrigin && exRate.targetCurrencyCode === currencyDestination) ||
                (exRate.baseCurrencyCode === currencyDestination && exRate.targetCurrencyCode === currencyOrigin),
        );
        if (!currencyExchangeObject) {
            setExchange(undefined);
            return;
        }
        const rate = getOptionRate(currencyExchangeObject, currencyOrigin, currencyDestination);
        if (!rate) {
            setExchange(undefined);
            return;
        }
        const operationCallBack = getOperationRate(
            currencyExchangeObject,
            currencyOrigin,
            currencyDestination,
            currencyAmount,
        );
        if (!operationCallBack) {
            setExchange(undefined);
            return;
        }

        const estimatedAmount = operationCallBack(quantity, rate);

        if (!estimatedAmount) {
            setExchange(undefined);
            return;
        }

        const currencyEstimate = getCurrencyEstimate(currencyAmount, currencyOrigin, currencyDestination);
        const targetCurrency = getTargetCurrency(currencyExchangeObject, currencyOrigin, currencyDestination);

        setExchange({
            rate,
            estimatedAmount,
            currencyEstimate,
            targetCurrency,
            currencyExchangeObject,
            currencyAmount,
            currencyDestination,
            currencyOrigin,
        });
    };

    return [exchange, setExchangeData];
};

export const useOptionLabel = (listBackend) => {
    const [list, setList] = useState([]);

    useEffect(() => {
        const options =
            listBackend?.map((c) => ({
                id: c.backendId,
                label: c.code,
            })) || [];
        setList(options);
    }, [listBackend]);
    return [list];
};

export const useSelectorOptionsAccounts = (listAccountsBackend) => {
    const [accountList, setAccountList] = useState([]);
    const createAccountList = (acc) => ({
        ...acc,
        balance: { currency: acc.currency, quantity: acc.balance },
        id: acc.idProduct,
        disabled: acc.isDisabled,
    });
    useEffect(() => {
        setAccountList({
            options: listAccountsBackend?.map(createAccountList),
        });
    }, [listAccountsBackend]);
    return [accountList];
};

export const useCustomFinancialList = (listBackend) => {
    const [customList, setCustomList] = useState([]);

    useEffect(() => {
        const transformedList =
            listBackend?.map((item) => ({
                id: item.percentage,
                label: `Cuota al ${item.percentage}%`,
                installments: item.options.map((option) => ({
                    id: option,
                    label: i18n.get(`installment.purchase.homologation.${option}.label`),
                })),
            })) || [];

        setCustomList(transformedList);
    }, [listBackend]);

    return customList;
};
