import React from "react";
import { bool, func, string } from "prop-types";
import { isDesktop } from "react-device-detect";
import Text from "./Text";
import Box from "./Box";
import Button from "./Button";

const HeaderWithoutSession = ({ labelKey, showBack, handleOnBack }) => (
    <Box display="flex" fullWidth className="login-title-container mb-3">
        {isDesktop && showBack && (
            <Button image="images/arrow_back.svg" className="p-0 mr-4" onClick={handleOnBack} imageSm />
        )}
        <Box fullWidth className="login-title-text">
            <Text size={isDesktop ? "3" : "2"} className="btn-link-span" bold labelKey={labelKey} />
        </Box>
    </Box>
);

HeaderWithoutSession.propTypes = {
    labelKey: string,
    showBack: bool,
    handleOnBack: func.isRequired,
};

HeaderWithoutSession.defaultProps = {
    labelKey: "",
    showBack: false,
    handleOnBack: () => {},
};

export default HeaderWithoutSession;
