import React from "react";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import { bool, func } from "prop-types";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { replace } from "react-router-redux";

const ErrorActiveSessionContent = ({ isDesktop, dispatch }) => (
    <>
        <Box style={{width:!isDesktop?"14rem":"100%"}}>
            <Image src="images/icons/errors/error.svg" />
        </Box>
        <Text size={isDesktop ? "3" : "1"} className="mt-8" bold labelKey="login.user.errorActiveSession.title" />
        <Text className="mt-8 line-height-15 text-center" size="6" labelKey="login.user.errorActiveSession.info" />
        <Button
            onClick={() => {
                dispatch(replace("/"))
            }}
            bsStyle="link"
            defaultLabelText={i18n.get("global.return")}
            primaryLink
            className="px-2 mt-5"
            black
        />
    </>
);

ErrorActiveSessionContent.defaultProps = {};

ErrorActiveSessionContent.propTypes = {
    isDesktop: bool.isRequired,
    dispatch: func.isRequired
};

export default withRouter(connect()(ErrorActiveSessionContent));
