import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { shape, string } from "prop-types";

import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";

class GroupFormDataTicketContent extends Component {
    static propTypes = {
        transactionData: shape({ data: shape({ name: string, description: string, status: string }) }).isRequired,
    };

    render() {
        const {
            transactionData: { data },
        } = this.props;

        return (
            <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                    <div className="transfer-block">
                        <div className="transfer-data transfer-data-flow">
                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="administration.advanced.group.create.name.label" />
                                </div>
                                <div className="data-desc"> {data.name}</div>
                            </div>
                            {data.description && (
                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id="administration.advanced.group.create.description.label" />
                                    </div>
                                    <div className="data-desc">{data.description}</div>
                                </div>
                            )}

                            <div className="data-wrapper">
                                <div className="data-label">
                                    <I18n id="administration.advanced.group.create.status.label" />
                                </div>
                                <div className="data-desc">
                                    <I18n id={`administration.advanced.group.create.status.${data.status}.label`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default GroupFormDataTicketContent;
