import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { goBack, push, routerActions } from "react-router-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import { isMobileNativeFunc } from "util/device";
import * as utilsI18n from "util/i18n";
import { selectors as softTokenSelectors, actions as softTokenActions } from "reducers/softToken";
import { Modal } from "react-bootstrap";
import { WidgetErrorType, WidgetFinishStatus } from "./constants";
import TokenActivationHelp from "./TokenActivationHelp";

const TokenActivationStep1 = ({ fetching, dispatch, callbackDataSelfie, fetchingActivate, history, pathGoBack }) => {
    const [imageResult, setImageResult] = useState();
    const [showTutorial, setShowTutorial] = useState(false);
    const [loaderSmallBoxed, setLoaderSmallBoxed] = useState(false);

    useEffect(() => {
        if (!isMobileNativeFunc()) {
            dispatch(push("/"));
        }

        dispatch(enrollmentActions.clearFetching());
        dispatch(softTokenActions.clearFetching());
        dispatch(enrollmentActions.clearDocumentTypeToken());
    }, []);

    const onSuccessWidgetExtraction = (result) => {
        if (result != null && result) {
            const data = result;

            if (!data.finishStatus) {
                // eslint-disable-next-line no-console
                console.error(utilsI18n.get(`token.activation.plugin.error${WidgetErrorType.UnknownError}`));
                dispatch(push("/serverError"));
                return;
            }

            const status = parseInt(data.finishStatus, 10);
            switch (status) {
                case WidgetFinishStatus.Ok:
                    setImageResult({ bestImage: data?.bestImage, bestImageTemplateRaw: data?.bestImageTemplateRaw });
                    break;

                case WidgetFinishStatus.Error: // Error
                    if (data.errorType) {
                        dispatch(
                            notificationActions.showNotification(
                                utilsI18n.get(`token.activation.error.${status}`),
                                "error",
                                ["tokenActivation"],
                            ),
                        );

                        if (
                            status === WidgetErrorType.CameraPermissionDenied ||
                            status === WidgetErrorType.SettingsPermissionDenied
                        ) {
                            dispatch(
                                notificationActions.showNotification(
                                    utilsI18n.get(`token.activation.plugin.error${data.errorType}`),
                                    "error",
                                    ["tokenActivation"],
                                ),
                            );
                        } else {
                            // eslint-disable-next-line no-console
                            console.error(utilsI18n.get(`token.activation.plugin.error${data.errorType}`));
                        }
                    } else {
                        // eslint-disable-next-line no-console
                        console.error(utilsI18n.get(`token.activation.plugin.error${data.errorType}`));
                        dispatch(push("/serverError"));
                    }
                    return;
                case WidgetFinishStatus.CancelByUser: // CancelByUser
                    dispatch(
                        notificationActions.showNotification(
                            utilsI18n.get(`token.activation.plugin.error.manuallyStopped`),
                            "error",
                            ["tokenActivation"],
                        ),
                    );
                    return;
                case WidgetFinishStatus.Timeout: // Timeout
                    dispatch(
                        notificationActions.showNotification(
                            utilsI18n.get(`token.activation.plugin.error.timeout`),
                            "error",
                            ["tokenActivation"],
                        ),
                    );
                    break;
                default:
                    break;
            }
        } else {
            // eslint-disable-next-line no-console
            console.error(utilsI18n.get(`token.activation.plugin.error${WidgetErrorType.UnknownError}`));
            dispatch(push("/serverError"));
        }
    };

    const redirectBack = () => {
        if (callbackDataSelfie?.redirectAbort) {
            dispatch(routerActions.replace(callbackDataSelfie.redirectAbort));
            return;
        }

        if (pathGoBack) {
            dispatch(routerActions.replace(pathGoBack));
            return;
        }

        dispatch(goBack());
    };

    const cancel = () => {
        redirectBack();
    };

    const handleBack = () => {
        if (callbackDataSelfie?.redirectAbort) {
            dispatch(routerActions.replace(callbackDataSelfie.redirectAbort));
            return;
        }
        redirectBack();
    };

    const onErrorWidgetExtraction = () => {
        // TODO: implements error extraction
    };

    const startPlugin = () => {
        setImageResult(undefined);
        const config = new window.facephi.widget.config.WidgetConfig();
        const resourcesBundlePath = "fphi-selphi-widget-resources-selphi-live-1.2";
        config.setLivenessMode(window.facephi.widget.livenessmode.PassiveMode);
        config.setLocale(utilsI18n.getLang());
        config.setEnableGenerateTemplateRaw(true);
        window.facephi.widget.universal
            .StartWidget(window.facephi.widget.mode.WidgetMode.Authenticate, resourcesBundlePath, config)
            .then(
                (result) => onSuccessWidgetExtraction(result),
                (err) => onErrorWidgetExtraction(err),
            );
    };

    const next = () => {
        setLoaderSmallBoxed(true);
        const currentAttempt = history?.location?.state?.currentAttempt || 0;
        dispatch(
            enrollmentActions.validateTokenActivationSelfie(imageResult?.bestImageTemplateRaw, imageResult?.bestImage, {
                ...callbackDataSelfie,
                currentAttempt: currentAttempt + 1,
            }),
        );
    };

    const contentWithoutPicture = () => (
        <>
            <Box
                display="flex"
                alignX="center"
                alignY="center"
                borderRadius="circle"
                background="border-base-color"
                className="mx-9 p-12"
                square>
                <Image src="images/icons/cameraToken.svg" bsStyle="only-icon" />
            </Box>

            <Box align="center" className="px-11 py-4 ">
                <Text
                    component="p"
                    align="center"
                    color="text"
                    size="6"
                    regular
                    labelKey="token.activation.step1.tip"
                />
            </Box>

            <Box display="flex" column className="mt-auto" fullWidth>
                <Button
                    label="token.activation.step1.button.takePicture"
                    bsStyle="primary"
                    onClick={startPlugin}
                    block
                />
                <Button label="global.cancel" type="button" className="btn-outline" onClick={cancel} block />
            </Box>
        </>
    );

    const contentWithPicture = () => (
        <>
            <Box display="flex" alignX="center" alignY="center" className="mx-9 mb-5" square>
                <img
                    className="aspect-ratio-square"
                    alt=""
                    style={{ objectFit: "cover" }}
                    src={`data:image/jpeg;base64,${imageResult.bestImage}`}
                />
            </Box>
            <Box display="flex" alignX="center" className="mb-8">
                <Disclaimer labelKey="token.activation.step1.disclaimer" />
            </Box>
            <Box display="flex" column className="mt-auto" fullWidth>
                <Button label="token.activation.step1.button.next" bsStyle="primary" onClick={next} block />
                <Button
                    label="token.activation.step1.button.tryAgain"
                    type="button"
                    className="btn-outline"
                    onClick={startPlugin}
                    block
                />
            </Box>
        </>
    );
    return (
        <>
            <Modal aria-labelledby="modalTitleID" aria-modal="true" show={showTutorial} className="modal-splash">
                <div className="modal-container">
                    <Box fullHeight fullWidth>
                        <TokenActivationHelp
                            onCloseCustom={() => {
                                setShowTutorial(false);
                            }}
                        />
                    </Box>
                </div>
            </Modal>
            <Head onBack={handleBack} title="token.activation.title" additionalClassName="background-primary" />
            <Notification scopeToShow="tokenActivation" />
            <MainContainer
                classNameMainWrapper="background-menu-background"
                showLoader={fetching || fetchingActivate}
                showChildrenWithLoader
                loaderSmallBoxed={loaderSmallBoxed}
                classicStyle>
                <Box display="flex" column alignY="between" fullWidth fullHeight>
                    <Box display="flex" column fullWidth>
                        <Box display="flex" column alignX="center" className="mt-7 mb-9">
                            <Text
                                align="center"
                                component="h2"
                                size="2"
                                color="heading"
                                className="mt-0 mb-4"
                                labelKey="token.activation.step1.subtitle"
                                bold
                            />
                            <span className="color-heading size-5 align-center">
                                {utilsI18n.get("token.activation.step1.tutorial.text")}
                                <Button
                                    className="pl-2 py-0 pr-0"
                                    bsStyle="link"
                                    secondary
                                    bold
                                    paragraph
                                    label="token.activation.tutorial.link"
                                    onClick={() => {
                                        setShowTutorial(true);
                                    }}
                                />
                            </span>
                        </Box>
                    </Box>
                    {imageResult?.bestImage && imageResult.bestImageTemplateRaw
                        ? contentWithPicture()
                        : contentWithoutPicture()}
                </Box>
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: enrollmentSelectors.isFetching(state),
    callbackDataSelfie: enrollmentSelectors.getCallbackDataSelfie(state),
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    fetchingActivate: softTokenSelectors.isFetching(state),
});

TokenActivationStep1.propTypes = {
    fetching: bool.isRequired,
    dispatch: func.isRequired,
    callbackDataSelfie: shape(),
    pathGoBack: string,
    fetchingActivate: bool,
    history: shape({}),
};

TokenActivationStep1.defaultProps = {
    callbackDataSelfie: undefined,
    pathGoBack: "",
    fetchingActivate: false,
    history: undefined,
};

export default connect(mapStateToProps)(TokenActivationStep1);
