import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { bool } from "prop-types";
import TextField from "pages/_components/fields/TextField";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "transactions.list.filters.client";

class UserFilter extends Component {
    static propTypes = {
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
    };

    render() {
        const { fetching } = this.props;
        return (
            <Form>
                <Container className="container--layout items-center" gridClassName="form-content">
                    <Col sm={12} md={4} className="col-12 col-no-pad-mobile">
                        <Field component={TextField} idForm={FORM_ID} name="user" />
                    </Col>
                    <Col sm={12} md={4} className="col-12 col-no-pad-mobile" style={{ alignSelf: "flex-end" }}>
                        <Button
                            bsStyle="primary"
                            label="global.search"
                            loading={fetching}
                            btnUppercase={false}
                            type="submit"
                            block
                        />
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default compose(
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            user: props.filterValues?.user || "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                user: Yup.string()
                    .nullable()
                    .required(i18n.get("global.field.required")),
            }),
        handleSubmit: ({ ...filters }, formikBag) => {
            const { handleClick } = formikBag.props;
            handleClick(filters);
        },
    }),
)(UserFilter);
