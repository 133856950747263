import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    VALIDATION_REQUEST: "creditCardRequest/VALIDATION_REQUEST",
    VALIDATION_FAILURE: "creditCardRequest/VALIDATION_FAILURE",
    VALIDATION_SUCCESS: "creditCardRequest/VALIDATION_SUCCESS",
    SEND_VALIDATION_REQUEST: "creditCardRequest/SEND_VALIDATION_REQUEST",
    SEND_VALIDATION_FAILURE: "creditCardRequest/SEND_VALIDATION_FAILURE",
    SEND_VALIDATION_SUCCESS: "creditCardRequest/SEND_VALIDATION_SUCCESS",
    GET_SESSION_REQUEST: "creditCardRequest/GET_SESSION_REQUEST",
    GET_SESSION_FAILURE: "creditCardRequest/GET_SESSION_FAILURE",
    GET_SESSION_SUCCESS: "creditCardRequest/GET_SESSION_SUCCESS",
    FINISH_ONBOARDING_REQUEST: "creditCardRequest/FINISH_ONBOARDING_REQUEST",
    FINISH_ONBOARDING_FAILURE: "creditCardRequest/FINISH_ONBOARDING_FAILURE",
    FINISH_ONBOARDING_SUCCESS: "creditCardRequest/FINISH_ONBOARDING_SUCCESS",
    ACTIVATE_CARD: "creditCardRequest/ACTIVATE_CARD",
    DEACTIVATE_CARD: "creditCardRequest/DEACTIVATE_CARD",
    SELECT_CARD: "creditCardRequest/SELECT_CARD",
    CLEAN: "creditCardRequest/CLEAN",
};

export const INITIAL_STATE = {
    fetching: false,
    email: "",
    username: "",
    message: null,
    exchangeToken: null,
    activeCard: null,
    selectedCard: 3,
    cards: {
        1: {
            id: 1,
            brand: "visa",
            type: "blueTravel",
            src: "images/visaTravel.png",
            plan: ["images/bag.svg", "images/flight.svg"],
        },
        2: {
            id: 2,
            brand: "visa",
            type: "shopping",
            src: "images/visaShop.png",
            plan: ["images/bag.svg", "images/flight.svg"],
        },
        3: {
            id: 3,
            brand: "visa",
            type: "fanCard",
            src: "images/visaFC.png",
            plan: ["images/bag.svg", "images/flight.svg"],
        },
        4: {
            id: 4,
            brand: "mastercard",
            type: "platinum",
            src: "images/masterP.png",
            plan: ["images/bag.svg", "images/flight.svg"],
        },
        5: {
            id: 5,
            brand: "mastercard",
            type: "black",
            src: "images/masterB.png",
            plan: ["images/bag.svg", "images/flight.svg"],
        },
    },
};

export default createReducer(INITIAL_STATE, {
    [types.VALIDATION_REQUEST]: (state, action) => ({
        ...state,
        email: action.email,
        username: action.username,
        fetching: true,
        creditCard: action.creditCardId,
    }),
    [types.VALIDATION_FAILURE]: (state, action) => ({
        ...state,
        message: action.message,
        fetching: false,
    }),
    [types.VALIDATION_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        message: action.message,
        exchangeToken: action.exchangeToken,
    }),
    [types.SEND_VALIDATION_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.SEND_VALIDATION_FAILURE]: (state, action) => ({
        ...state,
        message: action.message,
        fetching: false,
    }),
    [types.SEND_VALIDATION_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        message: action.message,
        exchangeToken: action.exchangeToken,
    }),
    [types.GET_SESSION_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.GET_SESSION_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.GET_SESSION_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        exchangeToken: action.exchangeToken,
        email: action.email,
        username: action.username,
        activeCard: action.creditCardId,
        selectedCard: action.creditCardId,
    }),
    [types.FINISH_ONBOARDING_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.FINISH_ONBOARDING_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.FINISH_ONBOARDING_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.ACTIVATE_CARD]: (state, action) => ({
        ...state,
        activeCard: action.cardId,
    }),
    [types.DEACTIVATE_CARD]: (state) => ({
        ...state,
        activeCard: null,
    }),
    [types.SELECT_CARD]: (state, action) => ({
        ...state,
        selectedCard: action.cardId,
    }),
    [types.CLEAN]: (state) => ({
        ...state,
        email: "",
    }),
});

export const actions = {
    validationRequest: makeActionCreator(types.VALIDATION_REQUEST, "email", "username", "creditCardId"),
    validationFailure: makeActionCreator(types.VALIDATION_FAILURE),
    validationSuccess: makeActionCreator(types.VALIDATION_SUCCESS, "message", "exchangeToken"),
    sendValidationRequest: makeActionCreator(types.SEND_VALIDATION_REQUEST, "code"),
    sendValidationFailure: makeActionCreator(types.SEND_VALIDATION_FAILURE, "message"),
    sendValidationSuccess: makeActionCreator(types.SEND_VALIDATION_SUCCESS, "message", "exchangeToken"),
    getSessionRequest: makeActionCreator(types.GET_SESSION_REQUEST, "code"),
    getSessionFailure: makeActionCreator(types.GET_SESSION_FAILURE, "message"),
    getSessionSuccess: makeActionCreator(
        types.GET_SESSION_SUCCESS,
        "email",
        "username",
        "creditCardId",
        "exchangeToken",
    ),
    finishOnboardingRequest: makeActionCreator(types.FINISH_ONBOARDING_REQUEST, "exchangeToken"),
    finishOnboardingFailure: makeActionCreator(types.FINISH_ONBOARDING_FAILURE, "message"),
    finishOnboardingSuccess: makeActionCreator(types.FINISH_ONBOARDING_SUCCESS),
    activateCard: makeActionCreator(types.ACTIVATE_CARD, "cardId"),
    deactivateCard: makeActionCreator(types.DEACTIVATE_CARD),
    selectCard: makeActionCreator(types.SELECT_CARD, "cardId"),
    clean: makeActionCreator(types.CLEAN),
};

export const selectors = {
    isFetching: ({ creditCardRequest }) => creditCardRequest.fetching,
    getUsername: ({ creditCardRequest }) => creditCardRequest.username,
    getEmail: ({ creditCardRequest }) => creditCardRequest.email,
    getExchangeToken: ({ creditCardRequest }) => creditCardRequest.exchangeToken,
    sessionInformationComplete: ({ creditCardRequest }) =>
        creditCardRequest.username &&
        creditCardRequest.email &&
        creditCardRequest.activeCard &&
        creditCardRequest.exchangeToken,
    getMessage: ({ creditCardRequest }) => creditCardRequest.message,
    getCards: ({ creditCardRequest }) =>
        Object.keys(creditCardRequest.cards).map((key) => creditCardRequest.cards[key]),
    getActiveCard: ({ creditCardRequest }) => creditCardRequest.cards[creditCardRequest.activeCard],
    getSelectedCard: ({ creditCardRequest }) => creditCardRequest.cards[creditCardRequest.selectedCard],
};
