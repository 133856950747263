import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { arrayOf, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions, selectors } from "reducers/administration/restrictions";
import { actions as formActions } from "reducers/form";

class RestrictionsDeleteConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape({}).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        idTransaction: string,
        idActivity: string,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    componentDidMount() {
        const { dispatch, idTransaction, idActivity } = this.props;

        dispatch(actions.restrictionDeleteRequest(idTransaction, idActivity));
    }

    handleSubmit = (credentials, formikBag) => {
        const { dispatch, match, idTransaction, idActivity } = this.props;

        if (!idTransaction) {
            dispatch(actions.deleteUserAccessRestrictions(match.params.userId, credentials, formikBag));
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    render() {
        const { credentialGroups } = this.props;
        if (!credentialGroups) {
            return null;
        }

        return (
            <>
                <Head title="administration.restrictions.delete" onBack={this.handleBack} />
                <MainContainer>
                    <Row>
                        <Col xs={8} xsOffset={2} className="pb-80">
                            <Box
                                display="flex"
                                column
                                background="component-background"
                                borderRadius="xxl"
                                className="px-80 pt-8 pb-9"
                                fullHeight>
                                <Box display="flex" className="pb-5 mb-8" border="bottom-border-base-color-1">
                                    {/* TODO (LABEL) */}
                                    <Text
                                        component="h2"
                                        labelKey="administration.restrictions.restrictions.beforeDelete.user"
                                        className="m-0"
                                        size="5"
                                        color="heading-color"
                                        bold
                                    />
                                </Box>
                                <AdministrationFormConfirmation
                                    credentialGroups={credentialGroups}
                                    onSubmit={this.handleSubmit}
                                    idActivity="administration.restrictions.user.delete.send"
                                />
                            </Box>
                        </Col>
                    </Row>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
});

export default connect(mapStateToProps)(RestrictionsDeleteConfirmation);
