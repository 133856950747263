import { bool, func } from "prop-types";
import React, { Component } from "react";
import * as i18n from "util/i18n";
import Text from "pages/_components/Text";
import LoginInfo from "./_components/LoginInfo";
import Box from "pages/_components/Box/Box";
import { SidebarMobileLayout } from "pages/_layouts/SidebarMobileLayout";

class InfoOtp extends Component {
    
    render() {
        const { onReturn } = this.props;

        return (
         <SidebarMobileLayout>
            <LoginInfo 
                onReturn={() => {
                if (onReturn) {
                onReturn()
                }
            }}
                title={i18n.get("login.step3.secondFactor.otp.help.title")}
                image="images/iconPopOvers/OTP.svg"
                content={<>
                        <Box className="pl-6 pr-6">
                            <Box display="block" fullWidth>
                                <Text defaultValue={i18n.get("login.step3.secondFactor.otp.help.text2")} size="5" />
                                <Text bold defaultValue={i18n.get("login.step3.secondFactor.otp.help.text3")} size="5" />
                                <Text defaultValue={i18n.get("login.step3.secondFactor.otp.help.text4")} size="5" />
                            </Box>
                        </Box>
                        <Box className="pl-6 pr-6">
                            <Text
                                size="5"
                                className="secondary-description"
                                defaultValue={i18n.get("login.step3.secondFactor.otp.help.text5")}
                            />
                        </Box>
                    </>
            }/>
        </SidebarMobileLayout>     
        );
    }
}

InfoOtp.propTypes = {
    isDesktop: bool.isRequired,
    onReturn: func.isRequired,
};

InfoOtp.defaultProps = {
};

export default InfoOtp;
