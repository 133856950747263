import { Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, shape, string, func } from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import Row from "pages/_components/Row";
import Col from "react-bootstrap/lib/Col";
import { compose } from "redux";
import * as i18n from "util/i18n";
import { push } from "react-router-redux";
import * as Yup from "yup";
// import SwitchField from "pages/_components/fields/SwitchField";
// import Enviroments from "pages/_components/Enviroments";
import classNames from "classnames";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as softTokenSelector } from "reducers/softToken";
import { selectors as secondFactorSelectors } from "reducers/secondFactor";
import { USER_TOKEN_STATUS_ACTIVE } from "util/userToken.util";
import SecondFactor from "pages/secondFactor/SecondFactor";
import credentials from "util/credentials";
import LoginWrapper from "./LoginWrapper";

const FORM_ID = "login.step3";

const Step3Content = (props) => {
    const {
        isSubmitting,
        className,
        values,
        exchangeToken,
        isDesktop,
        setFieldValue,
        fetching,
        submitForm,
        tokenStatus,
        userHasToken,
        credentials,
        isFetchingCredentials,
        dispatch,
    } = props;

    const [canSubmit, setCanSubmit] = useState(false);

    const [hasDigitalKey, setHasDigitalKey] = useState(false);

    const handleCancel = () => {
        dispatch(push("/"));
    };

    const buttons = useMemo(() => {
        if (isDesktop) {
            return (
                <Button
                    className="mt-6 mb-4"
                    type="submit"
                    bsStyle="primary"
                    // label="global.continue"
                    defaultLabelText={i18n.get("", "Continuar")}
                    btnUppercase={false}
                    loading={isSubmitting || (fetching && isFetchingCredentials)}
                    disabled={!canSubmit}
                    block
                />
            );
        }
        return (
            <Row gapX="7" className="mb-3 mt-6 d-flex flex-column">
                <Col xs={6}>
                    <Button
                        className="mt-6 mb-4"
                        type="submit"
                        bsStyle="primary"
                        // label="global.continue"
                        defaultLabelText={i18n.get("", "Continuar")}
                        btnUppercase={false}
                        loading={isSubmitting || (fetching && isFetchingCredentials)}
                        disabled={!canSubmit}
                        block
                    />
                </Col>
                <Col xs={6}>
                    <Button bsStyle="outline" label="global.cancel" block type="button" onClick={handleCancel} />
                </Col>
            </Row>
        );
    }, [fetching, isSubmitting, canSubmit]);

    useEffect(() => {
        if (userHasToken && tokenStatus === USER_TOKEN_STATUS_ACTIVE) {
            setHasDigitalKey(true);
        }
    }, [tokenStatus, userHasToken]);
    useEffect(() => {
        if (values?.secondFactor === "") {
            setCanSubmit(false);
        }
    }, [values]);

    return (
        <LoginWrapper>
            <Form
                className={classNames(className, {
                    "pointer-events-none": isSubmitting,
                })}>
                <Box display="flex" column fullWidth>
                    <SecondFactor
                        onChangeToken={(tokenCode) => {
                            if (setFieldValue) {
                                setFieldValue("secondFactor", tokenCode);
                                submitForm();
                            }
                        }}
                        onValidSubmit={(validData) => {
                            setCanSubmit(validData.submit && values?.secondFactor !== "");
                        }}
                        withEchangeToken={exchangeToken}
                        idActivity="session.login.legacy.step3"
                        buttons={buttons}
                        isSubmitting={isSubmitting}
                        credentials={credentials}
                    />
                </Box>
            </Form>
        </LoginWrapper>
    );
};

const mapStateToProps = (state) => ({
    currentDefaultEnvironment: sessionSelectors.getIdDefaultEnvironment(state),
    environments: loginSelectors.getEnvironments(state),
    defEnvironment: loginSelectors.getDefEnvironment(state),
    exchangeToken: loginSelectors.getExchangeToken(state),
    fetching: loginSelectors.getFetching(state),
    tokenStatus: softTokenSelector.getStatusToken(state),
    userHasToken: secondFactorSelectors.getUserHasToken(state),
    credentials: secondFactorSelectors.getCredentials(state),
    isFetchingCredentials: secondFactorSelectors.isFetching(state),
});

Step3Content.propTypes = {
    values: shape({}),
    // isMobileNative: bool.isRequired,
    isDesktop: bool.isRequired,
    className: string,
    isSubmitting: bool.isRequired,
    // setFieldValue: func,
    // codeResponse: string,
    // environments: objectOf(
    //     shape({
    //         environmentType: string,
    //         idEnvironment: string,
    //         name: string,
    //     }),
    // ),
    exchangeToken: string,
    credentials: string,
    dispatch: func.isRequired,
};

Step3Content.defaultProps = {
    values: null,
    // setFieldValue: () => {},
    className: null,
    exchangeToken: null,
    credentials: null,
};

export default compose(
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            environment:
                props.currentDefaultEnvironment || props.defEnvironment || Object.keys(props.environments)[0] || null,
            rememberEnvironment: "false",
            secondFactor: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                secondFactor: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
            }),
        handleSubmit: ({ environment, rememberEnvironment, secondFactor }, formikBag) => {
            // const idEnvironment = Object.values(environment || {})?.[0]?.idEnvironment;
            // const rememberEnvironment = false;
            formikBag.props.dispatch(
                loginActions.loginStep3Verification(formikBag, environment, rememberEnvironment, secondFactor),
            );
        },
    }),
)(Step3Content);
