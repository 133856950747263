import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import * as statusIcons from "pages/forms/customForms/_pdf/Icons";
import LogoBolivariano from "pages/forms/customForms/_pdf/LogoBolivariano";
import LogoBolivarianoBackground from "pages/forms/customForms/_pdf/LogoBolivarianoBackground";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import { func, shape, string } from "prop-types";
import React from "react";
import { Provider } from "react-redux";
import { store } from "store";
import * as config from "util/config";
import { isMobileNative } from "util/device";
import { formatNumber } from "util/format";
import * as utils from "util/general";
import * as i18n from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import { getTransactionKind } from "util/transaction";
import variables from "styles/themes/techbank/variables/variables.json";

const getPath = (font) => {
    let fontPath = require(`styles/fonts/themeFonts/${font}`);
    fontPath = fontPath.replace(/^\//, "");

    if (!isMobileNative) {
        fontPath = `../../${fontPath}`;
    }
    return fontPath;
};

Font.register({
    family: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fonts: [
        {
            src: getPath("Roboto-Regular.ttf"),
            fontWeight: "normal",
        },
        {
            src: getPath("Roboto-Bold.ttf"),
            fontWeight: "bold",
        },
        {
            src: getPath("Roboto-Light.ttf"),
            fontWeight: "light",
        },
    ],
});

// FOR EMOJIS
Font.registerEmojiSource({
    format: "png",
    url: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/",
});

const styles = StyleSheet.create({
    header: {
        marginBottom: "12pt",
        backgroundColor: "red",
    },

    logo: {
        width: "150pt",
        margin: "auto 0pt 0pt 20pt",
        position: "absolute",
        bottom: "20pt",
        left: "0",
    },
    page: {
        flexDirection: "column",
        fontSize: "13pt",
        color: variables["text-color"],
        width: "50",
        fontFamily: "Roboto",
    },
    sectionTitle: {
        margin: "20pt 0 10pt 20pt",
        fontSize: "16pt",
    },
    title: {
        fontSize: "18pt",
        margin: "20pt",
    },
    value: {
        marginBottom: "6pt",
        textAlign: "left",
        fontWeight: "normal",
        fontSize: "16pt",
    },
    valueMessage: {
        marginBottom: "6pt",
        textAlign: "center",
        fontWeight: "normal",
        fontSize: "16pt",
    },
    emojiStyleWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "24pt",
        minWidth: "24pt",
        paddingLeft: "2pt",
        paddingRight: "2pt",
    },
    emojiStyle: {
        fontSize: "20pt",
    },
    valueAddressee: {
        marginBottom: "6pt",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "22pt",
        color: variables["heading-color"],
    },
    scaleHandler: {
        transform: "scale(0.75)",
    },
    ticketWrapper: {
        width: "424pt",
        margin: "0 auto",
        overflow: "hidden",
        paddingBottom: "64pt",
        position: "relative",
        minHeight: "519pt",
        backgroundColor: "white",
        borderRadius: "15pt",
        border: `1pt solid ${variables["background-divider"]}`,
    },
    headerWrapper: {
        width: "100%",
        borderTopLeftRadius: "14pt",
        borderTopRightRadius: "14pt",
        overflow: "hidden",
    },
    amountWrapper: {
        flexDirection: "column",
        paddingBottom: "16pt",
        marginBottom: "40pt",
        paddingTop: "16pt",
        marginTop: "-1pt",
        zIndex: "100",
    },
    amountLabel: {
        textAlign: "center",
        fontSize: "16pt",
    },
    amountValue: {
        color: variables["heading-color"],
        textAlign: "center",
        fontSize: "28pt",
        fontWeight: "bold",
    },
    status: {
        display: "flex",
        padding: "24pt 20pt 16pt 20pt",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        width: "100%",
        zIndex: "100",
    },
    statusIcon: {
        width: "40pt",
    },
    statusText: {
        marginLeft: "16pt",
        fontSize: "18pt",
        fontWeight: "bold",
        textAlign: "left",
        color: variables["heading-color"],
    },
    barckgroundPrimary: {
        backgroundColor: variables.wally,
    },
    barckgroundError: {
        backgroundColor: variables["error-background-color"],
    },
    textWhite: {
        color: variables["text-inverse-color"],
    },
    data: {
        width: "100%",
        flexDirection: "row",
        flexWrap: "wrap",
        padding: "0 24pt 12pt",
    },
    dataCenter: {
        width: "100%",
        justifyContent: "center",
        alignItems: "baseline",
        flexDirection: "row",
        flexWrap: "wrap",
        padding: "0 24pt 12pt",
    },
    dataCenterHead: {
        width: "100%",
        justifyContent: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        padding: "0 24pt 0",
    },

    dataCenterPadding: {
        width: "100%",
        justifyContent: "center",
        alignItems: "baseline",
        flexDirection: "row",
        flexWrap: "wrap",
        padding: "0 24pt 12pt",
        paddingBottom: "54pt",
    },
    label: {
        fontSize: "16pt",
        textAlign: "left",
        fontWeight: "bold",
        color: variables["heading-color"],
        marginRight: "5pt",
    },
    labelMessage: {
        fontSize: "16pt",
        textAlign: "center",
        fontWeight: "bold",
        color: variables["heading-color"],
        marginRight: "5pt",
    },
    labelExpiration: {
        fontSize: "12pt",
        textAlign: "center",
        color: variables["text-color"],
    },
    labelAdressee: {
        fontSize: "16pt",
        textAlign: "center",
        fontWeight: "normal",
        color: variables["heading-color"],
        marginRight: "5pt",
    },
    labelHeader: {
        fontSize: "18pt",
        textAlign: "left",
        fontWeight: "900",
        color: variables["heading-color"],
    },
    marginRight: {
        marginRight: "5pt",
    },
    product: {
        fontSize: "16pt",
        textAlign: "left",
        fontWeight: "normal",
        color: variables["heading-color"],
    },
    logoBackground: {
        width: "213pt",
        margin: "20pt 0pt 0pt 20pt",
        position: "absolute",
        bottom: "-20pt",
        right: "0",
        zIndex: "1",
    },
    logoWallyPdf: {
        width: "30pt",
        height: "16pt",
    },
});

export const PDFTicketWally = ({ transaction, values, renderTicket }) => {
    const { idTransactionStatus } = transaction;

    return (
        <Provider store={store}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.scaleHandler}>
                        <View style={styles.ticketWrapper}>
                            {idTransactionStatus === "FINISHED" ? (
                                <PDFStatus transaction={transaction} />
                            ) : (
                                <PDFStatusError transaction={transaction} />
                            )}

                            {renderTicket(values)}
                            <PDFLogo />
                            <PDFLogoBackground />
                        </View>
                    </View>
                </Page>
            </Document>
        </Provider>
    );
};

PDFTicketWally.propTypes = {
    transaction: shape({}).isRequired,
    values: shape({}).isRequired,
    renderTicket: func.isRequired,
};

const capitalizeFirstLetter = (value) => value.charAt(0).toUpperCase() + value.slice(1);

// TICKET SUCCESS
export const PDFStatus = ({ transaction }) => {
    let StatusIcon = "";
    const titleKind = getTransactionKind(transaction.idActivity);
    if (titleKind === "requestTransferWally" && transaction?.idTransactionStatus === "FINISHED") {
        StatusIcon = statusIcons.PendingWally;
    } else {
        const svg = utils.getTransactionStatusIcon(transaction.idTransactionStatus);
        StatusIcon = statusIcons[`${capitalizeFirstLetter(svg)}`];
    }

    return (
        <View style={styles.headerWrapper}>
            <View style={[styles.status, styles.barckgroundPrimary]}>
                <View style={styles.statusIcon}>
                    <StatusIcon />
                </View>
                <Text style={[styles.statusText, styles.textWhite]}>
                    {i18n.get(`forms.transaction.ticket.status.${transaction.idTransactionStatus}.${titleKind}`)}
                </Text>
            </View>
        </View>
    );
};

PDFStatus.propTypes = {
    transaction: shape({}).isRequired,
};

// TICKET WITH ERROR
export const PDFStatusError = ({ transaction }) => {
    const svg = utils.getTransactionStatusIcon(transaction.idTransactionStatus);
    const StatusIcon = statusIcons[`${capitalizeFirstLetter(svg)}`];
    const titleKind = getTransactionKind(transaction.idActivity);

    return (
        <View style={styles.headerWrapper}>
            <View style={[styles.status, styles.barckgroundError]}>
                <View style={styles.statusIcon}>
                    <StatusIcon />
                </View>
                <Text style={[styles.statusText]}>
                    {i18n.get(`forms.transaction.ticket.status.${transaction.idTransactionStatus}.${titleKind}`)}
                </Text>
            </View>
        </View>
    );
};

PDFStatusError.propTypes = {
    transaction: shape({}).isRequired,
};

export const PDFLogo = () => (
    <View style={styles.logo}>
        <LogoBolivariano />
    </View>
);

export const PDFLogoBackground = () => (
    <View style={styles.logoBackground}>
        <LogoBolivarianoBackground />
    </View>
);

export const PDFTitle = ({ title }) => (
    <View style={styles.title}>
        <Text>{title}</Text>
    </View>
);

PDFTitle.propTypes = {
    title: string.isRequired,
};

export const PDFSectionTitle = ({ title }) => (
    <View style={styles.sectionTitle}>
        <Text>{title}</Text>
    </View>
);

PDFSectionTitle.propTypes = {
    title: string.isRequired,
};

export const PDFHeader = ({ transaction }) => {
    const selectedOption = transaction.data.scheduler ? transaction.data.scheduler.selectedOption : null;
    const scheduled = selectedOption ? selectedOption !== schedulerUtils.TODAY : false;

    return (
        <View style={styles.header}>
            <View style={styles.data}>
                <Text style={styles.label}>{i18n.get("forms.transaction.ticket.date")}:</Text>
                <Text style={styles.value}>{transaction.submitDateTimeAsString}</Text>
            </View>
            <View style={styles.data}>
                <Text style={styles.label}>{i18n.get("forms.transaction.ticket.number")}:</Text>
                <Text style={styles.value}>{transaction.idTransaction}</Text>
            </View>
            {scheduled && (
                <View style={styles.data}>
                    <Text style={styles.label}>{i18n.get("forms.confirmation.scheduler")}:</Text>
                    <Text style={styles.value}>
                        <Message nohtml value={transaction.data.scheduler} />
                    </Text>
                </View>
            )}
        </View>
    );
};

PDFHeader.propTypes = {
    transaction: shape({}).isRequired,
};

export const PDFTextField = ({ idForm, name, values, value, label }) => (
    <View style={styles.dataCenter}>
        <Text style={styles.label}>{label || i18n.get(`forms.${idForm}.${name}.label`)}:</Text>
        <Text style={styles.value}>{value || values?.[name]}</Text>
    </View>
);

PDFTextField.propTypes = {
    idForm: string,
    name: string,
    values: shape({}),
    value: string,
    label: string,
};

PDFTextField.defaultProps = {
    idForm: null,
    name: null,
    value: null,
    values: null,
    label: null,
};

const EMOJI_RANGES = [
    "\u00A9-\u00AE", // Copyright and registered symbols
    "\u203C-\u2049", // Double exclamation mark, exclamation question mark, etc.
    "\u2122-\u2139", // Trade mark, information source, etc.
    "\u2194-\u21AA", // Various arrows
    "\u231A-\u231B", // Watch, hourglass
    "\u2328", // Keyboard
    "\u23CF", // Eject button
    "\u23E9-\u23F3", // Black right-pointing double triangle, etc.
    "\u23F8-\u23FA", // Double vertical bar, etc.
    "\u24C2", // Circled M
    "\u25AA-\u25AB", // Black small square, white small square
    "\u25B6", // Black right-pointing triangle
    "\u25C0", // Black left-pointing triangle
    "\u25FB-\u25FE", // White medium square, etc.
    "\u2600-\u2604", // Black sun with rays, cloud, etc.
    "\u260E-\u2611", // Black telephone, ballot box with check, etc.
    "\u2614-\u2615", // Umbrella with rain drops, hot beverage
    "\u2618", // Shamrock
    "\u261D", // White up pointing index
    "\u2620", // Skull and crossbones
    "\u2622-\u2623", // Radioactive sign, biohazard sign
    "\u2626", // Orthodox cross
    "\u262A", // Star and crescent
    "\u262E-\u262F", // Peace symbol, yin yang
    "\u2638-\u263A", // Wheel of dharma, etc.
    "\u2640-\u2642", // Female sign, male sign
    "\u2648-\u2653", // Aries, taurus, etc.
    "\u265F-\u2660", // Black pawn, black spade suit
    "\u2663", // Black club suit
    "\u2665-\u2666", // Black heart suit, black diamond suit
    "\u2668", // Hot springs
    "\u267B", // Black universal recycling symbol
    "\u267E-\u267F", // Permanent paper sign, wheelchair symbol
    "\u2692-\u2697", // Hammer and pick, etc.
    "\u2699", // Gear
    "\u269B-\u269C", // Atom symbol, fleur-de-lis
    "\u26A0-\u26A1", // Warning sign, high voltage sign
    "\u26AA-\u26AB", // Medium white circle, medium black circle
    "\u26B0-\u26B1", // Coffin, funeral urn
    "\u26BD-\u26BE", // Soccer ball, baseball
    "\u26C4-\u26C5", // Snowman without snow, sun behind cloud
    "\u26C8", // Thunder cloud and rain
    "\u26CF", // Pick
    "\u26D1", // Helmet with white cross
    "\u26D3-\u26D4", // Chains, no entry
    "\u26E9-\u26EA", // Shinto shrine, church
    "\u26F0-\u26F5", // Mountain, sailor, etc.
    "\u26F7-\u26FA", // Skier, tent, etc.
    "\u26FD", // Fuel pump
    "\u2702", // Black scissors
    "\u2705", // White heavy check mark
    "\u2708-\u270D", // Airplane, writing hand, etc.
    "\u270F", // Pencil
    "\u2712", // Black nib
    "\u2714", // Heavy check mark
    "\u2716", // Heavy multiplication x
    "\u271D", // Latin cross
    "\u2721", // Star of David
    "\u2728", // Sparkles
    "\u2733-\u2734", // Eight spoked asterisk, eight pointed black star
    "\u2744", // Snowflake
    "\u2747", // Sparkle
    "\u274C", // Cross mark
    "\u274E", // Negative squared cross mark
    "\u2753-\u2755", // Black question mark ornament, etc.
    "\u2757", // Heavy exclamation mark symbol
    "\u2763-\u2764", // Heavy heart exclamation mark ornament, heavy black heart
    "\u2795-\u2797", // Heavy plus sign, etc.
    "\u27A1", // Black rightwards arrow
    "\u27B0", // Curly loop
    "\u27BF", // Double curly loop
    "\u2934-\u2935", // Arrow pointing rightwards then curving upwards, etc.
    "\u2B05-\u2B07", // Leftwards black arrow, etc.
    "\u2B1B-\u2B1C", // Black large square, white large square
    "\u2B50", // White medium star
    "\u2B55", // Heavy large circle
    "\u3030", // Wavy dash
    "\u303D", // Part alternation mark
    "\u3297", // Circled ideograph congratulation
    "\u3299", // Circled ideograph secret
    "\ud83c[\udc00-\udfff]", // Misc. symbols (faces, weather, buildings, food, etc.)
    "\ud83d[\udc00-\udf7f]", // More misc. symbols
    "\ud83d[\udf80-\udfff]", // Yet more misc. symbols (many are redundant with the previous range)
    "\ud83e[\udd00-\uddff]", // Even more symbols, including more faces, gestures, etc.
    "\u25FE-\u2600", // Yet even more symbols, including nature, objects, etc.
];

const splitByEmojiAndSpace = (content) => {
    const regex = new RegExp(`(${EMOJI_RANGES.join("|")})`, "g");
    return content.split(regex).filter(Boolean);
};

const isEmoji = (str) => str.match(new RegExp(EMOJI_RANGES.join("|")));

const RenderMessage = ({ content }) => {
    const message = [];
    const parts = splitByEmojiAndSpace(content);

    parts.forEach((part, index) => {
        if (isEmoji(part)) {
            message.push(
                // eslint-disable-next-line react/no-array-index-key
                <View key={`view-${index}`} style={styles.emojiStyleWrapper}>
                    {/* eslint-disable-next-line react/no-array-index-key */}
                    <Text key={`${part}-${index}`} style={styles.emojiStyle}>
                        {part}
                    </Text>
                </View>,
            );
        } else {
            message.push(
                // eslint-disable-next-line react/no-array-index-key
                <Text key={`${part}-${index}`} style={styles.valueMessage}>
                    {part}
                </Text>,
            );
        }
    });

    return <>{message}</>;
};

RenderMessage.propTypes = {
    content: string.isRequired,
};

export const PDFTextFieldWallyMessage = ({ idForm, name, values, value, label }) => (
    <View style={styles.dataCenterPadding}>
        <Text style={styles.labelMessage}>{label || i18n.get(`forms.${idForm}.${name}.label`)}:</Text>
        <RenderMessage content={value || values?.[name]} />
    </View>
);

PDFTextFieldWallyMessage.propTypes = {
    idForm: string,
    name: string,
    values: shape({}),
    value: string,
    label: string,
};

PDFTextFieldWallyMessage.defaultProps = {
    idForm: null,
    name: null,
    value: null,
    values: null,
    label: null,
};

export const PDFExpirationText = ({ value }) => (
    <View style={styles.dataCenterPadding}>
        <Text style={styles.labelExpiration}>{value}</Text>
    </View>
);

PDFExpirationText.propTypes = {
    value: string,
};

PDFExpirationText.defaultProps = {
    value: null,
};

export const PDFaddresseeField = ({ idForm, name, values, value, label }) => (
    <View style={styles.dataCenter}>
        <Text style={styles.labelAdressee}>{label || i18n.get(`forms.${idForm}.${name}.label`)}:</Text>
        <Text style={styles.valueAddressee}>{value || values?.[name]}</Text>
    </View>
);

PDFaddresseeField.propTypes = {
    idForm: string,
    name: string,
    values: shape({}),
    value: string,
    label: string,
};

PDFaddresseeField.defaultProps = {
    idForm: null,
    name: null,
    value: null,
    values: null,
    label: null,
};

export const getNumberRender = (value) => {
    const maximumDecimals = config.getInteger("defaultDecimal.maximum") || 0;
    const minimumDecimals = config.getInteger("defaultDecimal.minimum") || 0;
    return formatNumber(value.quantity, maximumDecimals, minimumDecimals);
};

// TICKET SUCCESS
export const PDFAmountField = ({ idForm, name, values, value, label }) => (
    <View style={[styles.amountWrapper, styles.barckgroundPrimary]}>
        <View style={styles.dataCenterHead}>
            <Text style={[styles.amountLabel, styles.textWhite, styles.marginRight]}>
                {label || i18n.get(`forms.${idForm}.${name}.label`)}
            </Text>

            <Image style={[styles.logoWallyPdf]} src={require("styles/images/logos/logoWallyPDF.png")} />
        </View>
        <Text style={[styles.amountValue, styles.textWhite]}>
            {value || `${i18n.get(`currency.label.${values[name].currency}`)} ${getNumberRender(values[name])}`}
        </Text>
    </View>
);

PDFAmountField.propTypes = {
    idForm: string,
    name: string,
    values: shape({}),
    value: string,
    label: string,
};

PDFAmountField.defaultProps = {
    idForm: null,
    name: null,
    values: null,
    value: null,
    label: null,
};

// TICKET WITH ERROR
export const PDFAmountFieldError = ({ idForm, name, values, value, label }) => (
    <View style={styles.amountWrapper}>
        <Text style={styles.amountLabel}>{label || i18n.get(`forms.${idForm}.${name}.label`)}:</Text>
        <Text style={styles.amountValue}>
            {value || `${i18n.get(`currency.label.${values[name].currency}`)} ${getNumberRender(values[name])}`}
        </Text>
    </View>
);
PDFAmountFieldError.propTypes = {
    idForm: string,
    name: string,
    values: shape({}),
    value: string,
    label: string,
};

PDFAmountFieldError.defaultProps = {
    idForm: null,
    name: null,
    values: null,
    value: null,
    label: null,
};
