import _ from "lodash";

export const types = {
    LOADING_CLEAN: `loading/FETCHING_CLEAN`,
};

// Initial state
const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    const { type } = action;

    if (type === types.LOADING_CLEAN) {
        return INITIAL_STATE;
    }

    const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);

    // not a *_REQUEST / *_SUCCESS /  *_ERROR actions, so we ignore them
    if (!matches) {
        return state;
    }

    const [, requestName, requestState] = matches;

    return {
        ...state,
        // Store whether a request is happening at the moment or not
        // e.g. will be true when receiving GET_TODOS_REQUEST
        //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_ERROR
        [requestName]: requestState === "REQUEST",
    };
};

const createLoadingSelector = (actions, some) => (state) => {
    // returns true only when all actions is not loading
    let isLoading = false;
    if (some) {
        isLoading = _(actions).some((action) => _.get(state, `loading.${action}`));
    } else {
        isLoading = _(actions).every((action) => _.get(state, `loading.${action}`));
    }
    return isLoading;
};

export const loadingSelector = (state, actions, some) => {
    const selector = createLoadingSelector(actions, some);
    return selector(state);
};
