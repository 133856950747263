import React from "react";
import { arrayOf, bool, shape, string } from "prop-types";
import Box from "pages/_components/Box";
import { Col } from "react-bootstrap";
import Container from "pages/_components/Container";
import Text from "pages/_components/Text";
import { renderAdditionalData, renderAdditionalDataUserList, renderMainData } from "../../utils/groups";

const ConfirmDataGroup = ({ name, description, permissions, added, deleted, isModifyMember, groups }) => (
    <Box background="white" borderRadius="lg" className="p-7 mt-5 box-shadow-small">
        <div className="above-the-fold">
            <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2}>
                    <Text component="h4" semibold labelKey="administration.forms.confirm.data" />
                    <Box background="background-disabled" className="p-5 my-5" borderRadius="lg">
                        {name && renderMainData(name)}
                    </Box>
                    {isModifyMember ? (
                        <>
                            {renderAdditionalDataUserList("added", added)}
                            {renderAdditionalDataUserList("deleted", deleted)}
                        </>
                    ) : (
                        <>{renderAdditionalData(description, permissions, groups)}</>
                    )}
                </Col>
            </Container>
        </div>
    </Box>
);

ConfirmDataGroup.propTypes = {
    name: string.isRequired,
    description: string,
    permissions: shape({}),
    groups: shape({}).isRequired,
    added: arrayOf(shape({})),
    deleted: arrayOf(shape({})),
    isModifyMember: bool,
};

ConfirmDataGroup.defaultProps = {
    description: "",
    permissions: {},
    added: [],
    deleted: [],
    isModifyMember: false,
};

export default ConfirmDataGroup;
