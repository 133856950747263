import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    FREQUENT_DESTINATION_PRE_REQUEST: "frequentdestinations/FREQUENT_DESTINATION_PRE_REQUEST",
    FREQUENT_DESTINATION_PRE_REQUEST_SUCCESS: "frequentdestinations/FREQUENT_DESTINATION_PRE_REQUEST_SUCCESS",

    LOAD_LIST_REQUEST: "frequentdestinations/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "frequentdestinations/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "frequentdestinations/LOAD_LIST_SUCCESS",
    LOAD_MORE_REQUEST: "frequentdestinations/LOAD_MORE_REQUEST",
    LOAD_MORE_SUCCESS: "frequentdestinations/LOAD_MORE_SUCCESS",
    LOAD_SINGLE_SUCCESS: "frequentdestinations/LOAD_SINGLE_SUCCESS",

    CREATE_PREVIEW: "frequentDestination/CREATE_PREVIEW",
    CREATE_PREVIEW_FAILED: "frequentDestination/CREATE_PREVIEW_FAILED",
    CREATE_PREVIEW_SUCCESS: "frequentDestination/CREATE_PREVIEW_SUCCESS",
    CREATE_FAILURE: `frequentDestination/CREATE_FAILURE`,
    CREATE_REQUEST: `frequentDestination/CREATE_REQUEST`,
    CREATE_SUCCESS: `frequentDestination/CREATE_SUCCESS`,

    CREATE_DETAIL: `frequentDestination/CREATE_DETAIL`,
    CREATE_DETAIL_SUCCES: `frequentDestination/CREATE_DETAIL_SUCCES`,

    DELETE_REQUEST: `frequentDestination/DELETE_REQUEST`,
    DELETE_FAILURE: `frequentDestination/DELETE_FAILURE`,
    DELETE_SUCCESS: `frequentDestination/DELETE_SUCCESS`,

    DELETE_SELECTED_REQUEST: `frequentDestination/DELETE_SELECTED_REQUEST`,
    DELETE_SELECTED_FAILURE: `frequentDestination/DELETE_SELECTED_FAILURE`,
    DELETE_SELECTED_SUCCESS: `frequentDestination/DELETE_SELECTED_SUCCESS`,

    MODIFY_REQUEST: `frequentDestination/MODIFY_REQUEST`,
    MODIFY_FAILURE: `frequentDestination/MODIFY_FAILURE`,
    MODIFY_SUCCESS: `frequentDestination/MODIFY_SUCCESS`,

    PERSIST_FORM_DATA: `frequentDestination/PERSIST_FORM_DATA`,
    CLEAN_FORM_DATA: `frequentDestination/CLEAN_FORM_DATA`,

    GET_BENEFICIARY_LIST_REQUEST: "frequentdestinations/GET_BENEFICIARY_LIST_REQUEST",
    GET_BENEFICIARY_LIST_FAILURE: "frequentdestinations/GET_BENEFICIARY_LIST_FAILURE",
    GET_BENEFICIARY_LIST_SUCCESS: "frequentdestinations/GET_BENEFICIARY_LIST_SUCCESS",

    GET_BENEFICIARY_FAILURE: `frequentDestination/GET_BENEFICIARY_FAILURE`,
    GET_BENEFICIARY_REQUEST: `frequentDestination/GET_BENEFICIARY_REQUEST`,
    GET_BENEFICIARY_SUCCESS: `frequentDestination/GET_BENEFICIARY_SUCCESS`,

    SET_BENEFICIARY_SELECTED: `frequentDestination/SET_BENEFICIARY_SELECTED`,

    GET_BENEFICIARY_FORM_PRE_REQUEST: `frequentDestination/GET_BENEFICIARY_FORM_PRE_REQUEST`,
    GET_BENEFICIARY_FORM_PRE_SUCCESS: `frequentDestination/GET_BENEFICIARY_FORM_PRE_SUCCESS`,
    GET_BENEFICIARY_FORM_PRE_FAILURE: `frequentDestination/GET_BENEFICIARY_FORM_PRE_FAILURE`,
    GET_BENEFICIARY_FORM_PRE_CLEAR: `frequentDestination/GET_BENEFICIARY_FORM_PRE_CLEAR`,

    DELETE_BENEFICIARY_FAILURE: `frequentDestination/DELETE_BENEFICIARY_FAILURE`,
    DELETE_BENEFICIARY_REQUEST: `frequentDestination/DELETE_BENEFICIARY_REQUEST`,
    DELETE_BENEFICIARY_SUCCESS: `frequentDestination/DELETE_BENEFICIARY_SUCCESS`,

    SETFAVORITE_BENEFICIARY_FAILURE: `frequentDestination/SETFAVORITE_BENEFICIARY_FAILURE`,
    SETFAVORITE_BENEFICIARY_REQUEST: `frequentDestination/SETFAVORITE_BENEFICIARY_REQUEST`,
    SETFAVORITE_BENEFICIARY_SUCCESS: `frequentDestination/SETFAVORITE_BENEFICIARY_SUCCESS`,

    UPDATE_BENEFICIARY_FAILURE: `frequentDestination/UPDATE_BENEFICIARY_FAILURE`,
    UPDATE_BENEFICIARY_REQUEST: `frequentDestination/UPDATE_BENEFICIARY_REQUEST`,
    UPDATE_BENEFICIARY_SUCCESS: `frequentDestination/UPDATE_BENEFICIARY_SUCCESS`,

    CLEAR_BENEFICIARY_LIST: `frequentDestination/CLEAR_BENEFICIARY_LIST`,
};

export const INITIAL_STATE = {
    list: [],
    pageNumber: 1,
    totalPages: 0,
    hasMoreData: false,
    fetching: true,
    fetchingMoreFrequentDestinations: false,
    filterText: "",
    frequentDestination: {},
    frequentDestionationOnRow: null,
    creditNumberSelected: "",
    defaultCountry: "",
    defaultDocumentType: "",
    countryList: [],
    documentTypeList: [],
    bankList: null,
    formData: {},
    currentBeneficiary: undefined,
    preDataForm: undefined,
    fetchingDelete: false,
    beneficiarySelected: null,
};

export default createReducer(INITIAL_STATE, {
    [types.FREQUENT_DESTINATION_PRE_REQUEST]: (state) => ({
        ...state,
    }),

    [types.FREQUENT_DESTINATION_PRE_REQUEST_SUCCESS]: (state, { data }) => ({
        ...state,
        defaultCountry: data.defaultCountry,
        defaultDocumentType: data.documentTypeList.filter(
            (documentType) => documentType.id_country_code === data.defaultCountry,
        )[0].id_document_type,
        countryList: data.countryList,
        documentTypeList: data.documentTypeList,
        bankList: data.bankList,
    }),

    [types.LOAD_LIST_REQUEST]: (state, { pageNumber, filterText }) => ({
        ...state,
        fetching: true,
        pageNumber,
        filterText,
    }),
    [types.LOAD_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LOAD_LIST_SUCCESS]: (state, { data }) => ({
        list: data.frequentDestinations,
        pageNumber: data.pageNumber,
        totalPages: data.totalPages,
        hasMoreData: data.moreStatements,
        fetching: false,
    }),

    [types.LOAD_SINGLE_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.LOAD_MORE_REQUEST]: (state) => ({
        ...state,
        fetchingMoreFrequentDestinations: true,
    }),
    [types.LOAD_MORE_SUCCESS]: (state, { data }) => ({
        ...state,
        fetchingMoreFrequentDestinations: false,
        list: state.list ? state.list.concat(data.frequentDestinations) : data.list,
        hasMoreData: data.moreStatements,
        pageNumber: data.pageNumber,
    }),

    [types.CREATE_PREVIEW]: (state) => ({
        ...state,
        fetching: true,
    }),

    [types.CREATE_PREVIEW_FAILED]: () => ({
        ...INITIAL_STATE,
        fetching: false,
    }),

    [types.CREATE_PREVIEW_SUCCESS]: (state, { data }) => ({
        ...state,
        fetching: false,
        frequentDestination: data.frequentDestination,
    }),

    [types.CREATE_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.CREATE_FAILURE]: (state) => ({
        ...INITIAL_STATE,
        documentTypeList: state.documentTypeList,
        countryList: state.countryList,
        bankList: state.bankList,
        fetching: false,
    }),

    [types.DELETE_PREVIEW_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),

    [types.DELETE_PREVIEW_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.DELETE_PREVIEW_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.DELETE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),

    [types.DELETE_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.DELETE_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.DELETE_SELECTED_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),

    [types.DELETE_SELECTED_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.DELETE_SELECTED_SUCCESS]: (state, { data }) => ({
        ...state,
        fetching: false,
        list: state.list.filter((item) => !data?.data?.listToDelete.includes(item.idBeneficiary)),
    }),

    [types.CREATE_DETAIL]: (state, { record }) => ({
        ...state,
        frequentDestionationOnRow: record,
    }),

    [types.CREATE_DETAIL_SUCCES]: (state, { frequentDestionationOnRow }) => ({
        ...state,
        frequentDestionationOnRow,
    }),

    [types.MODIFY_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),

    [types.MODIFY_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.MODIFY_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.PERSIST_FORM_DATA]: (state, action) => ({
        ...state,
        formData: action.data,
    }),

    [types.CLEAN_FORM_DATA]: (state) => ({
        ...state,
        formData: {},
    }),

    [types.GET_BENEFICIARY_LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.GET_BENEFICIARY_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.GET_BENEFICIARY_LIST_SUCCESS]: (state, { payload }) => {
        const currentList = [...state.list];
        const updateList =
            !payload.page || payload.page <= 1
                ? payload?.beneficiaryList || []
                : currentList.concat(payload?.beneficiaryList || []);

        return {
            ...state,
            list: updateList,
            totalPages: !payload.page || payload.page <= 1 ? payload?.totalPages : state.totalPages || 0,
            fetching: false,
        };
    },
    [types.GET_BENEFICIARY_FAILURE]: (state) => ({
        ...state,
        fetching: true,
        currentBeneficiary: undefined,
    }),
    [types.GET_BENEFICIARY_SUCCESS]: (state, { payload }) => ({
        ...state,
        fetching: false,
        currentBeneficiary: payload?.currentBeneficiary,
    }),
    [types.GET_BENEFICIARY_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        fetchingDelete: false,
    }),
    [types.SET_BENEFICIARY_SELECTED]: (state, { beneficiary }) => ({
        ...state,
        beneficiarySelected: beneficiary,
    }),

    [types.GET_BENEFICIARY_FORM_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        preDataForm: undefined,
        fetchingDelete: false,
    }),
    [types.GET_BENEFICIARY_FORM_PRE_SUCCESS]: (state, { payload }) => ({
        ...state,
        fetching: false,
        preDataForm: payload?.preDataForm,
    }),
    [types.GET_BENEFICIARY_FORM_PRE_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        preDataForm: undefined,
    }),

    [types.DELETE_BENEFICIARY_FAILURE]: (state) => ({
        ...state,
        fetchingDelete: false,
    }),
    [types.DELETE_BENEFICIARY_REQUEST]: (state) => ({
        ...state,
        fetchingDelete: true,
    }),
    [types.DELETE_BENEFICIARY_SUCCESS]: (state) => ({
        ...state,
        fetchingDelete: false,
    }),

    [types.SETFAVORITE_BENEFICIARY_REQUEST]: (state) => ({
        ...state,
    }),
    [types.SETFAVORITE_BENEFICIARY_FAILURE]: (state) => ({
        ...state,
    }),
    [types.SETFAVORITE_BENEFICIARY_SUCCESS]: (state, { payload }) => {
        if (!payload) {
            return { ...state };
        }

        const { beneficiaryId, favorite } = payload;
        const beneficiaryListCurrent = [...state.list];
        const updateBeneficiaryList = beneficiaryListCurrent.map((beneficiary) => {
            if (beneficiary.idBeneficiary === beneficiaryId) {
                return { ...beneficiary, favorite };
            }
            return beneficiary;
        });

        return {
            ...state,
            fetching: false,
            list: updateBeneficiaryList,
        };
    },
    [types.UPDATE_BENEFICIARY_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_BENEFICIARY_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.UPDATE_BENEFICIARY_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.GET_BENEFICIARY_FORM_PRE_CLEAR]: (state) => ({
        ...state,
        fetching: false,
        preDataForm: undefined,
    }),
    [types.CLEAR_BENEFICIARY_LIST]: (state) => ({
        ...state,
        fetching: false,
        list: [],
    }),
});

export const actions = {
    frequentDestinationPre: makeActionCreator(types.FREQUENT_DESTINATION_PRE_REQUEST, "callback"),
    loadListRequest: (filters) => ({
        type: types.LOAD_LIST_REQUEST,
        filters,
    }),
    loadMoreRequest: (filters) => ({
        type: types.LOAD_MORE_REQUEST,
        filters,
    }),
    loadListFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    loadListSuccess: makeActionCreator(types.LOAD_LIST_SUCCESS, "data"),
    loadListMoreSuccess: makeActionCreator(types.LOAD_MORE_SUCCESS, "data"),
    loadSingleSuccess: makeActionCreator(types.LOAD_SINGLE_SUCCESS, "data"),

    createPreview: makeActionCreator(types.CREATE_PREVIEW, "params", "formikBag"),
    createPreviewFailed: makeActionCreator(types.CREATE_PREVIEW_FAILED),
    createPreviewSuccess: makeActionCreator(types.CREATE_PREVIEW_SUCCESS, "data"),
    createRequest: makeActionCreator(types.CREATE_REQUEST, "params", "formikBag"),
    createSuccess: makeActionCreator(types.CREATE_SUCCESS, "data"),
    createFailure: makeActionCreator(types.CREATE_FAILURE),

    createDetail: makeActionCreator(types.CREATE_DETAIL, "record"),
    createDetailSucces: makeActionCreator(types.CREATE_DETAIL_SUCCES, "frequentDestionationOnRow"),

    deleteRequest: makeActionCreator(types.DELETE_REQUEST, "frequentDestionationOnRow"),
    deleteFailure: makeActionCreator(types.DELETE_FAILURE),
    deleteSuccess: makeActionCreator(types.DELETE_SUCCESS, "data"),

    deleteSelectedRequest: makeActionCreator(types.DELETE_SELECTED_REQUEST, "listToDelete"),
    deleteSelectedFailure: makeActionCreator(types.DELETE_SELECTED_FAILURE),
    deleteSelectedSuccess: makeActionCreator(types.DELETE_SELECTED_SUCCESS, "data"),

    modifyRequest: makeActionCreator(types.MODIFY_REQUEST, "frequentDestination", "formikBag"),
    modifyFailure: makeActionCreator(types.MODIFY_FAILURE),
    modifySuccess: makeActionCreator(types.MODIFY_SUCCESS, "data"),

    persistFormData: makeActionCreator(types.PERSIST_FORM_DATA, "data"),
    cleanFormData: makeActionCreator(types.CLEAN_FORM_DATA),
    getBeneficiaryList: makeActionCreator(types.GET_BENEFICIARY_LIST_REQUEST, "filters"),
    getCurrentBeneficiary: makeActionCreator(types.GET_BENEFICIARY_REQUEST, "beneficiaryId"),
    setBeneficiarySelected: makeActionCreator(types.SET_BENEFICIARY_SELECTED, "beneficiary"),
    getPreDataForm: makeActionCreator(types.GET_BENEFICIARY_FORM_PRE_REQUEST, "beneficiaryId", "activityId"),
    deleteBeneficiary: makeActionCreator(types.DELETE_BENEFICIARY_REQUEST, "beneficiaryId", "beneficiaryType"),
    setFavoriteBeneficiary: makeActionCreator(types.SETFAVORITE_BENEFICIARY_REQUEST, "beneficiaryId", "favorite"),
    updateBeneficiaryRequest: makeActionCreator(
        types.UPDATE_BENEFICIARY_REQUEST,
        "beneficiaryType",
        "beneficiaryData",
        "beneficiaryId",
        "formikBag",
    ),
    clearPreDataForm: makeActionCreator(types.GET_BENEFICIARY_FORM_PRE_CLEAR),
    clearBeneficiaryList: makeActionCreator(types.CLEAR_BENEFICIARY_LIST),
};

export const selectors = {
    getFrequentDestinations: ({ frequentDestination }) => frequentDestination.list,
    isFetching: ({ frequentDestination }) => frequentDestination.fetching,
    isFetchingMoreFrequentDestinations: ({ frequentDestination }) =>
        frequentDestination.fetchingMoreFrequentDestinations,
    getPageNumber: ({ frequentDestination }) => frequentDestination.pageNumber,
    getFilterText: ({ frequentDestination }) => frequentDestination.filterText,
    getTotalPages: ({ frequentDestination }) => frequentDestination.totalPages,
    getHasMoreData: ({ frequentDestination }) => frequentDestination.hasMoreData,
    getFrequentDestination: ({ frequentDestination }) => frequentDestination.frequentDestination,
    getFrequentDestionationOnRow: ({ frequentDestination }) => frequentDestination.frequentDestionationOnRow,
    getCreditNumberSelected: ({ frequentDestination }) => frequentDestination.creditNumberSelected,
    getCountryList: ({ frequentDestination }) => frequentDestination.countryList,
    getDocumentTypeList: ({ frequentDestination }) => frequentDestination.documentTypeList,
    getDefaultCountry: ({ frequentDestination }) => frequentDestination.defaultCountry,
    getDefaultDocumentType: ({ frequentDestination }) => frequentDestination.defaultDocumentType,
    getBankList: ({ frequentDestination }) => frequentDestination.bankList,
    getFormData: ({ frequentDestination }) => frequentDestination.formData,
    getBeneficiaryList: ({ frequentDestination }) => frequentDestination.list,
    getBeneficiary: ({ frequentDestination }) => frequentDestination.currentBeneficiary,
    getBeneficiarySelected: ({ frequentDestination }) => frequentDestination.beneficiarySelected,
    getPreDataForm: ({ frequentDestination }) => frequentDestination.preDataForm,
    isFetchingDelete: ({ frequentDestination }) => frequentDestination.fetchingDelete,
};
