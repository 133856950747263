import formField from "pages/forms/_components/_fields/_commons/formField";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import { arrayOf, bool, func, number, oneOfType, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";

class LoanPaymentAmount extends Component {
    feeRefs = {};

    quantityRef = null;

    static propTypes = {
        setValue: func.isRequired,
        i18n: func.isRequired,
        editing: bool.isRequired,
        value: oneOfType([shape({ currency: string, quantity: number }), string]),
        dependencyField: shape({
            data: shape({
                options: arrayOf(
                    shape({
                        id: string.isRequired,
                        statementsLoan: arrayOf(shape({})),
                        currency: string.isRequired,
                    }),
                ),
            }),
        }).isRequired,
        dependencyValue: shape({
            value: string.isRequired,
        }).isRequired,
    };

    static defaultProps = { value: "" };

    state = {
        statementsLoan: [],
        loanCurrency: "",
        statementsSelected: 0,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { dependencyField, dependencyValue, value } = nextProps;

        const {
            data: { options },
        } = dependencyField;
        const loan = options.find(({ id }) => id === dependencyValue.value) || {};

        if (loan && loan.statementsLoan) {
            const { statementsLoan, currency } = loan;

            let statementsSelected = 0;
            let totalAmount = value && value.quantity ? value.quantity : 0;

            statementsLoan.forEach((statement) => {
                if (totalAmount - statement.importAmount >= 0) {
                    totalAmount -= statement.importAmount;
                    statementsSelected += 1;
                }
            });

            return {
                ...prevState,
                statementsLoan,
                loanCurrency: currency,
                statementsSelected,
            };
        }
        return {
            ...prevState,
            statementsLoan: [],
            loanCurrency: "",
            statementsSelected: 0,
        };
    }

    handleOnChange = ({ target: { value, checked } }) => {
        const { setValue } = this.props;
        const { statementsLoan, loanCurrency } = this.state;

        const feeNumber = value;
        let total = 0;

        if (checked) {
            statementsLoan.map((statement) => {
                if (statement.feeNumber <= feeNumber) {
                    this.feeRefs[statement.feeNumber].checked = true;
                    total += statement.importAmount;
                }
                return null;
            });
        } else {
            statementsLoan.map((statement) => {
                if (statement.feeNumber >= feeNumber) {
                    this.feeRefs[statement.feeNumber].checked = false;
                } else {
                    total += statement.importAmount;
                }
                return null;
            });
        }

        const amount = {
            currency: loanCurrency,
            quantity: total,
        };

        setValue(total === 0 ? "" : amount);
    };

    render() {
        const { editing, value, i18n } = this.props;
        const { statementsSelected, loanCurrency, statementsLoan } = this.state;
        const currency = value ? value.currency : loanCurrency;
        const quantity = value ? value.quantity : 0;

        if (editing) {
            if (statementsLoan.length === 0) {
                return (
                    <p>
                        <I18n id="loanPaymentAmount.noStatements" />
                    </p>
                );
            }
            return (
                <Fragment>
                    <div className="table">
                        <div className="table-body c-control--table display-flex gap-3 align-items-center position-relative">
                            {statementsLoan &&
                                statementsLoan.map((statement, idx) => (
                                    <Fragment key={statement.feeNumber}>
                                        <input
                                            id={statement.feeNumber}
                                            type="checkbox"
                                            className="c-control-input"
                                            value={statement.feeNumber}
                                            checked={idx < statementsSelected}
                                            onChange={this.handleOnChange}
                                            ref={(ref) => {
                                                this.feeRefs[statement.feeNumber] = ref;
                                            }}
                                        />
                                        <label htmlFor={statement.feeNumber} className="c-control-label table-row">
                                            <div className="c-control-icons">
                                                <div className="c-control-mark">
                                                    <Image
                                                        src="images/icons/checkBox.svg"
                                                        className="svg-icon svg-caret"
                                                    />
                                                </div>
                                            </div>
                                            <div className="table-data">
                                                <span className="data-number">{statement.feeNumber}</span>
                                            </div>
                                            <div className="table-data">
                                                <span className="data-date">
                                                    <FormattedDate date={statement.dueDate} />
                                                </span>
                                            </div>
                                            <div className="table-data">
                                                <FormattedAmount
                                                    currency={loanCurrency}
                                                    quantity={statement.importAmount}
                                                />
                                            </div>
                                        </label>
                                    </Fragment>
                                ))}
                        </div>
                    </div>

                    <div
                        className="data-wrapper data-wrapper-inline"
                        style={{
                            justifyContent: "space-between",
                            display: "flex",
                            alignItems: "baseline",
                        }}>
                        <span className="data-label">{i18n("labelTotalPay")}</span>
                        <FormattedAmount currency={currency} quantity={quantity} />
                    </div>
                </Fragment>
            );
        }
        return <FormattedAmount currency={value.currency} quantity={value.quantity} />;
    }
}

export default formField({
    formClass: "form-group--loanPayments",
})(LoanPaymentAmount);
