import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors as sessionSelector } from "reducers/session";
import { simpleMaskEmail } from "util/format";

const SoftTokenDeactivationSuccess = ({ formId, isDesktop, email, goToHome }) => {
    const handleBack = () => {
        goToHome();
    };

    return (
        <>
            <Notification scopeToShow={formId} />
            <Head title={`${formId}.title`}/>
            <MainContainer
                className={classNames("main-container", {
                    "background-white": !isDesktop,
                })}>
                <Box
                    background="white"
                    borderRadius="lg"
                    className={classNames("mt-3 ", {
                        "p-7 box-shadow-small": isDesktop,
                        "pt-9 change-question-form": !isDesktop,
                    })}>
                    <Box>
                        <Box display="flex" alignX="center" className="mb-8 mt-5">
                            <Box className="box-image-success">
                                <Image src="images/util/success.gif" />
                            </Box>
                        </Box>
                        <Box className="text-center mb-7">
                            <Text
                                size={isDesktop ? "3" : "2"}
                                labelKey={`${formId}.success.title`}
                                bold
                                className={classNames({
                                    "line-height-125": isDesktop,
                                    "line-height-15": !isDesktop,
                                })}
                            />
                        </Box>
                        <Box className="text-center mb-7 ticket-text-width">
                            <Text
                                size={isDesktop ? "5" : "5"}
                                labelKey={`${formId}.success.info`}
                                light
                                className={classNames({
                                    "line-height-125": isDesktop,
                                    "line-height-15": !isDesktop,
                                })}
                            />
                            <Box className="mt-2 display-inline ml-2">
                                <Text size={isDesktop ? "6" : "5"} bold>
                                    {simpleMaskEmail(email)}
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Row
                    className={classNames("mt-7 mb-7 row-template-center", {
                        "d-flex": !isDesktop,
                    })}>
                    <Col xs={3} xsOffset={4}>
                        <Button
                            type="button"
                            bsStyle="primary"
                            label="global.goToHome"
                            btnUppercase={false}
                            onClick={handleBack}
                            block
                        />
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};

SoftTokenDeactivationSuccess.propTypes = {
    formId: string.isRequired,
    email: string.isRequired,
    isDesktop: bool.isRequired,
    goToHome: func.isRequired,
};

const mapStateToProps = (state) => ({
    email: sessionSelector.getEmail(state),
});

export default connect(mapStateToProps)(SoftTokenDeactivationSuccess);
