/* eslint-disable jsx-a11y/interactive-supports-focus */
import classNames from "classnames";
import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import A11yNav from "pages/_components/A11yNav";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FastRechargeCarousel from "pages/_components/FastRechargeCarousel";
import FooterDesktop from "pages/_components/FooterDesktop";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import ShortCuts from "pages/_components/ShortCuts";
import Text from "pages/_components/Text";
import Header from "pages/_components/header/Header";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import Campaigns from "pages/campaigns/Campaigns";
import * as Widgets from "pages/desktop/widgets";
import fieldARP from "pages/servicePayments/hooks/field";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push, routerActions } from "react-router-redux";
import { Link } from "react-router-dom";

import { actions, selectors as desktopSelectors } from "reducers/desktop";
import { selectors as loginSelectors } from "reducers/login";
import { actions as notificationActions } from "reducers/notification";
import { actions as productActions, selectors as productSelectors } from "reducers/products";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { actions as widgetActions, selectors as widgetSelectors } from "reducers/widgets";
import { get as getConfig } from "util/config";
import * as deviceUtils from "util/device";
import * as i18n from "util/i18n";
import * as stringUtils from "util/string";
import NextPayments from "pages/_components/NextPayments";
import DataProtectionLaw from "pages/_components/modal/DataProtectionLaw";
import EconomicGroups from "./widgets/economicGroup/EconomicGroups";

// eslint-disable-next-line max-len
class Desktop extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        layout: arrayOf(
            shape({
                column: number,
                id: string,
                row: number,
                uri: string,
            }),
        ),
        isDesktop: bool,
        isTablet: bool,
        availableWidgets: arrayOf(
            shape({
                column: number,
                id: string,
                row: number,
                uri: string,
            }),
        ).isRequired,
        isEditable: bool,
        sessionFetching: bool,
        desktopFetching: bool,
        user: shape({
            userFullName: string,
            userFirstName: string,
        }),
        activeEnvironment: shape({ type: string }).isRequired,
        displayCampaigns: bool,
        urlFooterBanner: string,
        location: shape({}),
        isMobile: bool,
        showUpdateDataModal: bool,
        daysSinceLastUpdt: number,
        showAdulthoodModal: bool,
        match: shape({}),
        listPayService: arrayOf(shape({})),
        payServiceFetching: bool,
        listNextPayment: arrayOf(shape({})),
    };

    static defaultProps = {
        isDesktop: false,
        isTablet: false,
        isEditable: null,
        sessionFetching: null,
        desktopFetching: null,
        displayCampaigns: null,
        user: null,
        urlFooterBanner: null,
        location: {},
        isMobile: false,
        showUpdateDataModal: false,
        daysSinceLastUpdt: 0,
        showAdulthoodModal: false,
        match: undefined,
        listPayService: [],
        payServiceFetching: false,
        listNextPayment: null,
        layout: [],
    };

    state = {
        showDeleteWidgetModal: false,
        widgetToDelete: null,
        nextPaymentsShowMore: false,
    };

    componentDidMount() {
        const { dispatch, layout, isMobile } = this.props;
        if (layout === null) {
            dispatch(actions.loadLayoutRequest());
            if (isMobile) {
                dispatch(payServiceActions.listPayServiceRequest(true));
            }
        }
        this.showAlertTokenInvalid();
        this.showAlertPasswordExpire();

    }

    componentDidUpdate(prevProps) {
        const { dispatch, availableWidgets } = this.props;
        if (availableWidgets !== prevProps.availableWidgets) {
            const newPaymentAvailableId = availableWidgets
                .filter((item) => item.id === "creditCards" || item.id === "loans")
                .map((item) => item.id);
            // eslint-disable-next-line no-restricted-syntax
            for (const widget of newPaymentAvailableId) {
                dispatch(widgetActions.listWidgetAvailableNextPaymentRequest(widget));
            }
        }
    }
    showAlertPasswordExpire = () =>{
        const { dispatch,
            daysBeforeExpirePassword,notifyPasswordExpiration} = this.props;
        if(notifyPasswordExpiration){
            const daysLeft = i18n.get("recoverPassword.form.needChange.days", null, {
                days:daysBeforeExpirePassword,
            });
            const comp =  <div> {daysLeft} <Button
                label="settings.index"
                onClick={() =>             dispatch(routerActions.push("/settings"))
                }
                bsStyle="link"
            /></div>

            dispatch(
                notificationActions.showNotification(" ", "warning", [
                    "desktop",
                ], true, null,()=>{return comp},"100",true),
            );
        }

    };

    showAlertTokenInvalid = () => {
        const { dispatch, match } = this.props;
        if (!deviceUtils.isMobileNativeFunc() || !match?.params?.id) {
            return;
        }

        const showAlertTokenInvalid = match.params.id;
        if (showAlertTokenInvalid === 1 || showAlertTokenInvalid === "1") {
            dispatch(
                notificationActions.showNotification(i18n.get("transaction.invalid.otp.required.mobile"), "error", [
                    "desktop",
                ]),
            );
        }
    };

    hideDeleteWidgetModal = () => {
        this.setState({ showDeleteWidgetModal: false, widgetToDelete: null });
    };

    handleCloseUpdateModal = () => {
        const { dispatch } = this.props;
        dispatch(actions.updateModalHide());
    };

    showDeleteWidgetModal = (item) => {
        this.setState({ showDeleteWidgetModal: true, widgetToDelete: item });
    };

    getColumns = (layout) =>
        layout?.reduce((obj, item) => {
            const columnValue = obj[item.column] || [];

            return { ...obj, [item.column]: [...columnValue, item] };
        }, {});

    handleClick = () => {
        const { widgetToDelete } = this.state;
        const { dispatch, layout } = this.props;
        const { column, row } = widgetToDelete;

        dispatch(actions.deleteWidget(layout?.findIndex((widget) => widget.column === column && widget.row === row)));
        this.setState({ showDeleteWidgetModal: false, widgetToDelete: null });
    };

    handleIsEditableChange = () => {
        const { dispatch } = this.props;
        dispatch(actions.toggleIsEditable());
    };

    handleItemsPositionChange = (items) => {
        const { dispatch } = this.props;

        dispatch(actions.setLayout(items));
        dispatch(actions.saveLayoutRequest());
    };

    handleLinkFastRecharge = (paymentUser) => {
        const { dispatch } = this.props;
        const newPaymentUser = { ...paymentUser, fastRecharge: true };
        dispatch(payServiceActions.isLinkFastRecharge(true));

        const query = {
            paymentUser: newPaymentUser,
            biller: paymentUser?.extraData,
            savePayment: false,
            isFastRecharge: true,
        };
        dispatch(payServiceActions.setQueryBillPre(query));
        dispatch(payServiceActions.setSelectedBiller(paymentUser?.extraData));

        // Telered -> consulta de reglas
        if (paymentUser?.extraData?.svcProviderId === "Telered") {
            dispatch(payServiceActions.listRulesBillerRequest(paymentUser?.idBiller));
        } else {
            // ARP -> definicion de reglas por defecto
            dispatch(payServiceActions.listRulesBillerSuccess(fieldARP));
        }
        dispatch(routerActions.push("/formCustom/servicePayment"));
    };

    labelGreeting = () => {
        const hour = new Date().getHours();

        if (hour >= 0 && hour < 12) {
            return "desktop.greeting.morning";
        }

        if (hour >= 12 && hour < 18) {
            return "desktop.greeting.afternoon";
        }
        return "desktop.greeting.night";
    };

    renderHeader = () => {
        const {
            isDesktop,
            // activeEnvironment: { type },
            user,
        } = this.props;
        // const hasAvailableWidgets = availableWidgets.length > 0;
        const title = "menu.desktop";
        const { userFirstName } = user;

        if (!isDesktop) {
            return (
                <Head
                    labelGreeting={this.labelGreeting()}
                    contentLeft
                    dashboard
                    title={title}
                    userFullName={userFirstName}
                    userFirstName={userFirstName}
                />
            );
        }

        return (
            <>
                <Header collapsed={false}>
                    <Text
                        className="toolbar-item view-title"
                        component="h1"
                        labelKey={this.labelGreeting()}
                        username={userFirstName}
                    />
                    {/* {type !== CORPORATE_GROUP_ENVIRONMENT_TYPE && (
                        <div className="toolbar-item desktop-edit toolbar-item--fixed">
                            {!isEditable ? (
                                <Button
                                    label="desktop.editLayout.edit.label"
                                    onClick={() => this.handleIsEditableChange()}
                                    bsStyle="link"
                                    id="editDashboardBtn"
                                />
                            ) : (
                                <>
                                    {hasAvailableWidgets ? (
                                        <Dropdown
                                            enabled={isEditable}
                                            label="desktop.selectWidget"
                                            buttonClass="btn-select">
                                            {availableWidgets.map((widget, index) => (
                                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                <Button
                                                    key={widget.id}
                                                    onClick={() => dispatch(actions.addWidget(index))}
                                                    label={`list.addWidget.${widget.id}`}
                                                    className="dropdown__item-btn"
                                                    bsStyle="link"
                                                />
                                            ))}
                                        </Dropdown>
                                    ) : (
                                        <I18n
                                            id="desktop.widgets.empty"
                                            componentProps={{ className: "desktop-edit-empty-message" }}
                                        />
                                    )}
                                    <Button
                                        bsStyle="only-icon"
                                        image="images/icons/circleCross.svg"
                                        className="widget-edit-close"
                                        label="desktop.editLayout.finish.label"
                                        defaultLabelText="Terminar edición"
                                        onClick={() => this.handleIsEditableChange()}
                                    />
                                </>
                            )}
                        </div>
                    )} */}
                </Header>
            </>
        );
    };

    renderItem = (item, { draggableItemProps }) => {
        const { isEditable, isDesktop } = this.props;

        const Widget = Widgets[stringUtils.capitalizeFirstLetter(item.id)];
        const buttonActionDesc = `${i18n.get("global.close")} ${i18n.get("global.widget")}, ${i18n.get(
            `list.addWidget.${item.id}`,
        )}`;
        return (
            <>
                <Widget
                    className={classNames({ "draggable-list__item": isEditable })}
                    closeButton={
                        isEditable && (
                            <Button
                                className="btn-link btn-only-icon widget-close-icon"
                                onClick={() => this.showDeleteWidgetModal(item)}>
                                {/* onClick={() => this.handleClick(item)}> */}
                                <Image src="images/icons/circleCross.svg" />
                                <span className="visually-hidden">{buttonActionDesc}</span>
                            </Button>
                        )
                    }
                    draggableItemProps={draggableItemProps}
                    isEditable={isEditable}
                    isDesktop={isDesktop}
                />
            </>
        );
    };

    redirectTrasferShortCut = (transferUrl) => {
        const { dispatch } = this.props;
        dispatch(routerActions.push(transferUrl));
    };

    getTransferUrlShortCut = () => {
        const { activeEnvironment, dispatch } = this.props;
        if (activeEnvironment?.permissions?.transferInternal) {
            this.redirectTrasferShortCut("/formCustom/transferInternal");
            return;
        }
        if (activeEnvironment?.permissions?.transferLocal) {
            this.redirectTrasferShortCut("/formCustom/transferLocal");
            return;
        }
        if (activeEnvironment?.permissions?.transferForeign) {
            this.redirectTrasferShortCut("/formCustom/transferForeign");
            return;
        }
        dispatch(notificationActions.showNotification(i18n.get("products.operation.NoDispose"), "error", ["desktop"]));
    };

    processNextPaymentList = (listNextPayment, dispatch) => {
        let listWithOnClick = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const map of listNextPayment) {
            // eslint-disable-next-line no-restricted-syntax
            for (const value of map.values()) {
                // eslint-disable-next-line no-restricted-syntax
                for (const item of value) {
                    if (item.nextPaymentDate != null) {
                        listWithOnClick.push({
                            ...item,
                            onClick: () => {
                                if (item.image === "creditCard") {
                                    dispatch(push(`/formCustom/payCreditCardLocal?creditCard=${item.idProduct}`));
                                }
                            },
                        });
                    }
                }
            }
        }

        const currentDate = new Date();
        const daysToBeValid = getConfig("widget.next.payment.daysToBeValid");

        listWithOnClick = listWithOnClick.filter((item) => {
            const nextPaymentDate = new Date(item.nextPaymentDate.replace(/-05$/, ""));
            const currentDatePlusDays = new Date(currentDate.getTime() + daysToBeValid * 24 * 60 * 60 * 1000);
            let statusValid = true;
            if (item.image !== "creditCard" && item.overdueStatus === "NAB") {
                statusValid = false;
            }

            return (
                statusValid &&
                nextPaymentDate < currentDatePlusDays &&
                nextPaymentDate > new Date(currentDate.getTime())
            );
        });

        if (listWithOnClick.length > 1) {
            listWithOnClick.sort((a, b) => {
                const dateA = new Date(a.nextPaymentDate.replace(/-05$/, ""));
                const dateB = new Date(b.nextPaymentDate.replace(/-05$/, ""));

                const differenceA = Math.abs(dateA - currentDate);
                const differenceB = Math.abs(dateB - currentDate);

                return differenceA - differenceB;
            });
        }

        return listWithOnClick;
    };

    renderContent = () => {
        const {
            // isEditable,
            activeEnvironment: { type },
            sessionFetching,
            desktopFetching,
            isDesktop,
            isTablet,
            displayCampaigns,
            location,
            isMobile,
            showUpdateDataModal,
            daysSinceLastUpdt,
            user,
            listPayService,
            listNextPayment,
            dispatch,
        } = this.props;
        const { userFirstName } = user;
        const { showDeleteWidgetModal, widgetToDelete, nextPaymentsShowMore } = this.state;
        const isStepZero = location.pathname === "/";

        const listAllNextPayment = this.processNextPaymentList(listNextPayment, dispatch);

        const shortCutBtns = !isMobile
            ? [
                  {
                      image: { desktop: "images/icons/icon_environments.svg" },
                      label: "desktop.widgets.short.cuts.environments",
                      href: "/settings/changeDefaultEnvironment",
                  },
                  {
                      image: { desktop: "images/icons/icon_avatar.svg" },
                      label: "desktop.widgets.short.cuts.avatar",
                      href: "/settings/changeAvatar",
                  },
                  {
                      image: {
                          desktop: "images/icons/icon_virtual_token.svg",
                          //   mobile: "images/shortCutBtn/SCBpaymentsMobile.svg",
                      },
                      label: "desktop.widgets.short.cuts.virtualToken",
                      href: "/settings/authenticatorHandler",
                  },
              ]
            : [
                  {
                      image: { desktop: "images/icons/icon_environments.svg" },
                      label: "desktop.widgets.short.cuts.environments",
                      href: "/settings/changeDefaultEnvironment",
                  },
                  {
                      image: { desktop: "images/icons/icon_avatar.svg" },
                      label: "desktop.widgets.short.cuts.avatar",
                      href: "/settings/changeAvatar",
                  },
                  {
                      image: {
                          desktop: "images/icons/icon_virtual_token.svg",
                          //   mobile: "images/shortCutBtn/SCBpaymentsMobile.svg",
                      },
                      label: "desktop.widgets.short.cuts.virtualToken",
                      href: "/settings/authenticatorHandler",
                  },
              ];

        const textWidgetToDelete = widgetToDelete ? i18n.get(`widgets.hideModal.${widgetToDelete.id}`) : "";
        const infoMessage = i18n.get("widgets.hideModal", null, {
            WIDGET: textWidgetToDelete,
        });
        const infoMessageUpdateModal = i18n.get("widgets.updateModal.text", null, {
            DAYS: daysSinceLastUpdt,
        });
        const headingTextMessageUpdateModal = i18n.get("widgets.updateModal.headingText", null, {
            CLIENT: userFirstName,
        });

        return (
            <>
                <MainContainer
                    className={isMobile ? "desktop rounded-border-top dashboard-mobile-content" : "desktop"}
                    showLoader={sessionFetching || desktopFetching}
                    {...(!isDesktop && { background: "white" })}>
                    <Row
                        {...(!isDesktop && {
                            className: "display-flex flex-column",
                            gapY: "0",
                        })}>
                        <Col xs={12} className="desktop-main">
                            {isDesktop && this.renderHeader()}
                            {type === CORPORATE_GROUP_ENVIRONMENT_TYPE && <EconomicGroups />}
                            {!isDesktop && (
                                <>
                                    <FastRechargeCarousel
                                        sliderList={listPayService}
                                        onClick={(service) => this.handleLinkFastRecharge(service)}
                                    />
                                </>
                            )}

                            <>
                                <Box>
                                    {/* Tabs: Cuentas, Tarjetas, Creditos, Inversiones */}
                                    <Widgets.Products />
                                </Box>
                            </>

                            <DataProtectionLaw />

                            {/* {layout?.length > 0 && (
                                <>
                                    <DraggableList
                                        columns={this.getColumns(layout)}
                                        isDragEnabled={isEditable}
                                        itemRenderer={this.renderItem}
                                        onItemsPositionChange={this.handleItemsPositionChange}
                                    />
                                </>
                            )} */}

                            {widgetToDelete && showDeleteWidgetModal ? (
                                <TextAndButtonsModal
                                    modalShow={showDeleteWidgetModal}
                                    acceptFunction={this.handleClick}
                                    cancelFunction={this.hideDeleteWidgetModal}
                                    headingText={i18n.get("widgets.hideModal.modal.headingText")}
                                    text={infoMessage}
                                />
                            ) : (
                                <></>
                            )}

                            {showUpdateDataModal ? (
                                <TextAndButtonsModal
                                    modalShow={showUpdateDataModal}
                                    externalHref={getConfig("desktop.link.to.update")}
                                    cancelFunction={this.handleCloseUpdateModal}
                                    headingText={headingTextMessageUpdateModal}
                                    text={infoMessageUpdateModal}
                                    confirmLabel="widgets.updateModal.confirmLabel"
                                    cancelLabel="widgets.updateModal.cancelLabel"
                                    modalId="widgets.update.data.modal"
                                />
                            ) : (
                                <></>
                            )}

                            {/*
                                        <Info1
                                            label="desktop.widgets.product.request.label"
                                            btnLabel="desktop.widgets.product.request.btn"
                                            href="/settings/pointsOfInterest"
                                        />
                                     */}
                            {!isMobile && (
                                <A11yNav
                                    position="relative"
                                    // TODO (LABEL a11y)
                                    ariaLabel="Atajo  en Sección Principal"
                                    buttonList={[
                                        { label: "Ir a Menu Principal", focusOnSection: "menu" },
                                        {
                                            label: "Volver al inicio",
                                            focusOnSection: "main",
                                        },
                                    ]}
                                />
                            )}
                        </Col>

                        {!isDesktop && <ShortCuts dashboard options={shortCutBtns} isStepZero={isStepZero} />}
                        {!isDesktop && displayCampaigns ? (
                            <Box background="white" className="mx-n-5">
                                <Col
                                    className="campaign-wrapper px-5"
                                    xs={12}
                                    md={6}
                                    mdOffset={isTablet ? 3 : 0}
                                    lg={12}>
                                    <Box className="pb-9" fullWidth>
                                        <Campaigns
                                            showCardSlider
                                            classNameCard="campaign-card"
                                            className="mx-md-0 my-0 my-md-5 my-lg-0 campaign-desktop-sidebar"
                                            isDesktop={isDesktop}
                                            section="desktop-sidebar"
                                        />
                                    </Box>
                                </Col>
                            </Box>
                        ) : null}
                    </Row>
                </MainContainer>
                {isDesktop && (
                    <Box
                        component="aside"
                        id="fast-access"
                        // role="complementary"
                        display="flex"
                        column
                        className="sidebar-right-wrapper"
                        // TODO LABEL(a11y)
                        ariaLabel="Sidebar de escritorio"
                        position="relative">
                        <Row heightAuto className="align-items-center px-0">
                            <Col xs={12}>
                                <Box>
                                    <ShortCuts
                                        dashboard
                                        className="mt-7"
                                        isDesktop
                                        options={shortCutBtns}
                                        isStepZero={isStepZero}
                                    />
                                </Box>
                            </Col>

                            {/* <Col xs={12} md={campaigns?.length > 0 ? 6 : 12} lg={12}>
                                <PaymentCards />
                            </Col> */}

                            {/* NEXT PAYMENTS */}
                            {listAllNextPayment.length > 0 && (
                                <Box
                                    display="flex"
                                    alignX="center"
                                    column
                                    border="bottom-background-divider-1"
                                    className={listAllNextPayment.length > 3 ? "pb-3" : "pb-5"}
                                    gap="3">
                                    <Text component="h2" color="heading-color" size="3" bold className="mt-0 mb-3">
                                        {i18n.get("nextPayments.desktop.tittle")}
                                    </Text>
                                    <Box
                                        display="flex"
                                        column
                                        gap="5"
                                        fullWidth
                                        className={classNames("next-payment-scrollable", {
                                            "pr-3": nextPaymentsShowMore,
                                        })}>
                                        {listAllNextPayment
                                            .slice(0, nextPaymentsShowMore ? listAllNextPayment.length : 3)
                                            .map((item) => (
                                                <NextPayments
                                                    image={item.image}
                                                    prodName={
                                                        item.type !== null && item.type !== undefined
                                                            ? `${item.type} ${item.alias}`
                                                            : item.alias
                                                    }
                                                    dueDate={item.nextPaymentDateStr}
                                                    onClick={item.onClick}
                                                />
                                            ))}
                                    </Box>
                                    {listAllNextPayment.length > 3 && (
                                        <Box display="flex" alignX="center">
                                            <Button
                                                bsStyle="link"
                                                label={
                                                    nextPaymentsShowMore
                                                        ? "nextPayments.desktop.seeLess"
                                                        : "nextPayments.desktop.seeMore"
                                                }
                                                onClick={() => {
                                                    this.setState({ nextPaymentsShowMore: !nextPaymentsShowMore });
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            )}

                            {displayCampaigns ? (
                                <Col
                                    xs={12}
                                    md={6}
                                    mdOffset={isTablet ? 3 : 0}
                                    lg={12}
                                    {...(!isDesktop && { className: "mb-8" })}>
                                    <Campaigns
                                        className="mx-5 mx-md-0 my-0 my-md-5 my-lg-0 campaign-desktop-sidebar"
                                        isDesktop={isDesktop}
                                        section="desktop-sidebar"
                                    />
                                </Col>
                            ) : null}
                            {!isMobile && (
                                <Col xs={12}>
                                    <A11yNav
                                        position="relative"
                                        // TODO (LABEL a11y)
                                        ariaLabel="Atajo en Transacciones rápidas"
                                        buttonList={[
                                            { label: "Ir a Sección principal", focusOnSection: "main" },
                                            { label: "Ir a Menu Principal", focusOnSection: "menu" },
                                        ]}
                                        column
                                    />
                                </Col>
                            )}
                        </Row>
                    </Box>
                )}
            </>
        );
    };

    render() {
        const { location, isDesktop, urlFooterBanner, showAdulthoodModal, dispatch } = this.props;

        return (
            <>
                <Notification scopeToShow="desktop" />
                {!isDesktop && this.renderHeader()}
                {isDesktop ? (
                    <Box component="section" className="dashboard-section">
                        {this.renderContent()}
                    </Box>
                ) : (
                    this.renderContent()
                )}
                {location.pathname === "/desktop" && (
                    <FooterDesktop
                        dashboard
                        urlFooterBanner={urlFooterBanner}
                        moreOptions={[
                            <Link to="/" key="support">
                                <I18n id="global.support" />
                            </Link>,
                        ]}
                    />
                )}
                <TextAndButtonsModal
                    modalShow={showAdulthoodModal}
                    acceptFunction={() => {
                        dispatch(productActions.openCloseAdulthoodModal(false));
                        dispatch(sessionActions.logout());
                    }}
                    confirmLabel="adulthood.modal.button.accept"
                    headingText={i18n.get("adulthood.modal.title")}
                    text={i18n.get("adulthood.modal.text")}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    availableWidgets: desktopSelectors.getAvailableWidgets(state),
    layout: desktopSelectors.getLayout(state),
    isEditable: desktopSelectors.getIsEditale(state),
    sessionFetching: sessionSelectors.isFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    user: sessionSelectors.getUser(state),
    desktopFetching: desktopSelectors.isFetching(state),
    displayCampaigns: loginSelectors.getDisplayCampaigns(state),
    urlFooterBanner: desktopSelectors.urlFooterBanner(state),
    showUpdateDataModal: desktopSelectors.updateModalShow(state),
    daysSinceLastUpdt: desktopSelectors.getDaysSinceLastUpdt(state),
    showAdulthoodModal: productSelectors.getShowAdulthoodModal(state),
    listPayService: payServiceSelectors.getListFastRecharge(state),
    payServiceFetching: payServiceSelectors.isFetching(state),
    listNextPayment: widgetSelectors.getListNextPayment(state),
    daysBeforeExpirePassword:sessionSelectors.daysBeforeExpirePassword(state),
    notifyPasswordExpiration:sessionSelectors.notifyPasswordExpiration(state),
});

export default connect(mapStateToProps)(Desktop);
