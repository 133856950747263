import { call, put, takeLatest, select } from "redux-saga/effects";
import { push } from "react-router-redux";

import * as creditCardRequest from "middleware/creditCardRequest";
import { types, actions, selectors } from "reducers/creditCardRequest";
import { actions as notificationActions } from "reducers/notification";

const sagas = [
    takeLatest(types.VALIDATION_REQUEST, validationRequest),
    takeLatest(types.SEND_VALIDATION_REQUEST, sendValidationRequest),
    takeLatest(types.GET_SESSION_REQUEST, requestSessionInformation),
];

export default sagas;

function* validationRequest({ email, username, creditCardId }) {
    const response = yield call(creditCardRequest.validate, email, username, creditCardId);

    if (response.type === "I") {
        const { message, _exchangeToken } = response.data.data;
        yield put(actions.validationSuccess(message, _exchangeToken));
        yield put(push(`/products/creditCardRequest/emailVerification`));
    } else {
        yield put(actions.validationFailure());
    }
}

function* requestSessionInformation({ code }) {
    try {
        const response = yield call(creditCardRequest.requestSessionInformation, code);

        if (response.type === "I") {
            const { email, username, creditCardId } = response.data.data;
            yield put(actions.getSessionSuccess(email, username, creditCardId, code));
        } else {
            yield put(actions.getSessionFailure());
        }
    } catch (error) {
        if (error.response.status === 401) {
            yield put(push(`/products/creditCardRequest/showEmailVerification`));
            yield put(notificationActions.showNotification("Your code has expired", "error", ["creditCardRequest"]));
        } else {
            throw error;
        }
    }
}

function* sendValidationRequest({ code }) {
    try {
        const token = yield select(selectors.getExchangeToken);
        const response = yield call(creditCardRequest.sendValidation, code, token);

        if (response.type === "I") {
            const { message, _exchangeToken } = response.data.data;
            yield put(actions.sendValidationSuccess(message, _exchangeToken));
            // yield put(push(`/products/lifetest`));
            yield put(push(`/onboarding/step1`));
        } else {
            yield put(actions.sendValidationFailure(response.data.message));
            yield put(notificationActions.showNotification(response.data.message, "error", ["creditCardRequest"]));
        }
    } catch (error) {
        yield put(actions.sendValidationFailure(error.data.message));
        yield put(notificationActions.showNotification(error.data.message, "error", ["creditCardRequest"]));
    }
}
