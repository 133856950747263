import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { arrayOf, shape, bool, string } from "prop-types";
import { push } from "react-router-redux/actions";
import { actions } from "reducers/administration/common/groupFormData";
import { groupFormDataSelectors, permissionsSelectors } from "reducers/administration";
import PageLoading from "pages/_components/PageLoading";
import FlowTransition from "pages/_components/FlowTransition";
import GroupForm from "./GroupForm";
import GroupFormConfirm from "./GroupFormConfirm";
import GroupFormMembers from "./GroupFormMembers";
import { steps } from "../utils/groups";

const FORM_ID = "administration.advanced.group.create";

const GroupFormFlow = ({ fetching, dispatch, match, formValues, products, groups, updateMembers, currentStep }) => {
    const [confirmationData, setConfirmationData] = useState({ name: "", description: "", permissions: {} });
    const [originalUsers, setOriginalUsers] = useState([]);

    useEffect(() => {
        dispatch(actions.loadGroupFormDataRequest(match.params.id));
    }, []);

    useEffect(() => {
        setConfirmationData(formValues);
        if (updateMembers) {
            setOriginalUsers(formValues.users || []);
        }
    }, [formValues]);

    const handleBack = () => {
        if (!match.params.id) {
            dispatch(push("/administration/groups"));
        } else {
            dispatch(push(`/administration/advanced/group/${match.params.id}/details`));
        }
    };

    const handleBackConfirm = () => {
        if (match.params.id) {
            setConfirmationData(formValues);
        }
        dispatch(actions.setCurrentStep(steps.making));
    };

    const handleContinue = (values, formikBag, isFlowMembers) => {
        setConfirmationData(values);
        if (!isFlowMembers) {
            dispatch(actions.submitGroupFormPreviewRequest({ ...values }, match.params.id, formikBag));
        }else{
            dispatch(actions.setCurrentStep(steps.confirmation));
        }
    };

    const handleSubmit = (values, formikBag) => {
        const data = { ...confirmationData, ...values };
        dispatch(actions.submitGroupFormRequest(data, match.params.id, formikBag));
    };

    return (
        <PageLoading loading={fetching}>
            <FlowTransition
                currentPage={currentStep}
                pages={{
                    [steps.making]: () => (
                        <>
                            {updateMembers ? (
                                <GroupFormMembers
                                    idForm={FORM_ID}
                                    data={confirmationData}
                                    handleContinue={(values, formikBag) => handleContinue(values, formikBag, true)}
                                    handleBack={handleBack}
                                    isCreate={!match.params.id}
                                    originalUsers={originalUsers}
                                />
                            ) : (
                                <GroupForm
                                    idForm={FORM_ID}
                                    data={confirmationData}
                                    handleContinue={(values, formikBag) => handleContinue(values, formikBag, false)}
                                    handleBack={handleBack}
                                    isCreate={!match.params.id}
                                    products={products}
                                    groups={groups}
                                />
                            )}
                        </>
                    ),
                    [steps.confirmation]: () => (
                        <GroupFormConfirm
                            isCreate={!match.params.id}
                            data={confirmationData}
                            handleSubmit={handleSubmit}
                            handleBack={handleBackConfirm}
                            products={products}
                            groups={groups}
                            updateMembers={updateMembers}
                        />
                    ),
                }}
            />
        </PageLoading>
    );
};

GroupFormFlow.propTypes = {
    fetching: bool,
    products: arrayOf(shape({})).isRequired,
    groups: shape({}).isRequired,
    formValues: shape({}),
    updateMembers: bool,
    currentStep: string.isRequired,
};

GroupFormFlow.defaultProps = {
    fetching: false,
    formValues: undefined,
    updateMembers: false,
};

const mapStateToProps = (state) => ({
    // idTransaction: groupFormDataSelectors.getIdTransaction(state),
    // idActivity: groupFormDataSelectors.getIdActivity(state),
    // availableUsers: groupFormDataSelectors.getAvailableUsers(state),
    // adminUsers: groupFormDataSelectors.getAdminUsers(state),
    formValues: groupFormDataSelectors.getFormValues(state),
    fetching: groupFormDataSelectors.isFetching(state),
    // hasClose: navigationSelectors.isHasClose(state),
    // hasBack: navigationSelectors.isHasBack(state),
    products: permissionsSelectors.getProducts(state),
    groups: permissionsSelectors.getGroups(state),
    currentStep: groupFormDataSelectors.getCurrentStep(state),
});

export default connect(mapStateToProps)(GroupFormFlow);
