import React, { Component } from "react";
import { arrayOf, shape, string } from "prop-types";
import withFocus from "pages/_components/withFocus";
import Box from "pages/_components/Box";
import Select from "pages/forms/_components/_fields/Select";

import * as i18n from "util/i18n";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import FieldLabel from "./FieldLabel";
import FieldError from "./FieldError";

class ProductRequestSelect extends Component {
    contactTypeList = [
        {
            id: "phone",
            label: i18n.get("productRequest.drawer.contactType.radioButton.phone"),
        },
        {
            id: "mail",
            label: i18n.get("productRequest.drawer.contactType.radioButton.mail"),
        },
    ];

    state = {
        product: null,
        productTypeList: [],
        productType: null,
        contactType: "phone",
    };

    componentDidMount = () => {
        const { field, form, productDefault } = this.props;

        if (!productDefault) {
            return;
        }

        const productTypeList = this.getProductTypeList(productDefault);

        if (!productTypeList) {
            return;
        }

        this.setState({
            product: productDefault,
            productTypeList,
        });

        form.setFieldValue(field.name, {
            ...field.value,
            product: productDefault || "",
        });
    };

    onChangeProductSelect = (product) => {
        const { field, form } = this.props;

        if (!product?.value) {
            return;
        }

        this.setState({
            product,
        });

        const productTypeList = this.getProductTypeList(product?.value);

        this.setState({
            productTypeList,
            productType: null,
        });

        form.setFieldValue(field.name, {
            ...field.value,
            product: product?.value || "",
            productType: "",
        });

        form.setTouched(field.name, { ...form.touched, productType: false });
    };

    onChangeProductTypeSelect = (productType) => {
        const { field, form } = this.props;
        if (!productType?.value) {
            return;
        }

        this.setState({
            productType,
        });

        form.setFieldValue(field.name, {
            ...field.value,
            productType: productType?.value || "",
        });
    };

    onChangeContactType = (contactType) => {
        const { field, form } = this.props;
        this.setState({
            contactType,
        });

        form.setFieldValue(field.name, {
            ...field.value,
            contactType: contactType || "",
        });
    };

    onChangeAccountType = (accountType) => {
        const { field, form } = this.props;
        this.setState({
            productType: accountType,
        });

        form.setFieldValue(field.name, {
            ...field.value,
            productType: accountType || "",
        });
    };

    getProductTypeList = (product) => {
        let i18nProductTypeList = i18n.get(`productRequest.drawer.productTypeList.${product}`);
        i18nProductTypeList = i18nProductTypeList.split("|");

        const mapProductTypeList = [];
        i18nProductTypeList.forEach((productType) => {
            const object = {};
            object[product === "AC" ? "id" : "value"] = productType;
            object.label = i18n.get(`productRequest.drawer.productType.${product}.${productType}`);
            mapProductTypeList.push(object);
        });
        return mapProductTypeList;
    };

    fieldHasErrorAndToched = (fieldName) => {
        const { form, field } = this.props;
        const { touched, errors } = form;

        if (errors[field.name] && touched[field.name]) {
            return errors[field.name][fieldName] && touched[field.name][fieldName];
        }

        return false;
    };

    getFieldErrorMessage = (fieldName) => {
        const { form, field } = this.props;
        const { errors } = form;

        if (this.fieldHasErrorAndToched(fieldName)) {
            return errors[field.name][fieldName];
        }
        return null;
    };

    renderProductTypeOptions = (product) => {
        const { field } = this.props;
        const { productTypeList, productType } = this.state;

        if (product?.value === "AC") {
            return (
                <div className="form-group form-group--scheduler">
                    <div className="form-group-text scheduler">
                        <RadioButtonGroup
                            inLineControl={false}
                            name={`${field.name}_productType`}
                            onChange={this.onChangeAccountType}
                            options={productTypeList}
                            value={productType}
                        />
                    </div>
                    {product && this.fieldHasErrorAndToched("productType") && (
                        <FieldError error={this.getFieldErrorMessage("productType")} />
                    )}
                </div>
            );
        }
        if (product) {
            return (
                <>
                    <Box className="form-group form-group--select">
                        <FieldLabel
                            idField="productRequest.drawer.productType"
                            labelKey={
                                product
                                    ? `productRequest.drawer.productType.${product?.value || product}.label`
                                    : "productRequest.drawer.productType"
                            }
                            align="right"
                        />
                    </Box>
                    <Box className="input-group ">
                        <Select
                            clearable={false}
                            searchable={false}
                            label="accounts.movements.filters.searchBy"
                            onChange={this.onChangeProductTypeSelect}
                            name={`${field.name}_productType`}
                            options={productTypeList}
                            value={productType}
                            optionClassName="needsclick"
                        />
                    </Box>
                    {product && this.fieldHasErrorAndToched("productType") && (
                        <FieldError error={this.getFieldErrorMessage("productType")} />
                    )}
                </>
            );
        }

        return <></>;
    };

    render() {
        const { productsList, field, productDefault } = this.props;
        const { product, contactType } = this.state;

        return (
            <Box className="form-group">
                <div className="document-form">
                    <Box className="form-group form-group--select">
                        <FieldLabel
                            idField="productRequest.drawer.product"
                            labelKey="productRequest.drawer.product.label"
                            align="right"
                        />
                    </Box>
                    <Box className="input-group ">
                        <Select
                            clearable={false}
                            searchable={false}
                            label="accounts.movements.filters.searchBy"
                            onChange={this.onChangeProductSelect}
                            name={`${field.name}_product`}
                            options={productsList}
                            value={product}
                            optionClassName="needsclick"
                            disabled={!!productDefault}
                        />
                    </Box>
                    {this.fieldHasErrorAndToched("product") && (
                        <FieldError error={this.getFieldErrorMessage("product")} />
                    )}
                    {this.renderProductTypeOptions(product)}
                    <Box className="form-group ">
                        <FieldLabel
                            idField="productRequest.drawer.contactType"
                            labelKey="productRequest.drawer.contactType"
                            align="right"
                        />
                    </Box>
                    <div className="form-group form-group--scheduler">
                        <div className="form-group-text scheduler">
                            <RadioButtonGroup
                                inLineControl={false}
                                name={`${field.name}_contactType`}
                                onChange={this.onChangeContactType}
                                options={this.contactTypeList}
                                value={contactType}
                            />
                        </div>
                    </div>
                </div>
            </Box>
        );
    }
}

ProductRequestSelect.propTypes = {
    form: shape({}).isRequired,
    field: shape({}).isRequired,
    productsList: arrayOf(shape({})).isRequired,
    productDefault: string,
};

ProductRequestSelect.defaultProps = {
    productDefault: null,
};

export default withFocus(ProductRequestSelect);
