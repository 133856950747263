import React, { Component } from "react";
import { arrayOf, func, number, oneOfType, shape, string } from "prop-types";

import * as configUtils from "util/config";

import TicketContent from "pages/_components/TicketContent";
import { renderAlias, renderAdditionalData } from "../../utils/signaturesScheme";

class SignaturesScheme extends Component {
    static propTypes = {
        actions: shape({
            deleteSignaturesSchemeRequest: func,
        }).isRequired,
        signatureAlias: string,
        functionalGroups: arrayOf(string),
        signatureLevelsCounts: oneOfType([
            shape({
                A: number,
            }),
            shape(
                configUtils.getArray("administration.signatures.signatureLevels").reduce((res, signLevel) => {
                    const result = res;
                    result[signLevel] = number;

                    return result;
                }, {}),
            ),
        ]),
        match: shape({
            params: shape({
                idTransaction: string,
            }),
        }),
        signatureType: string,
        topAmount: shape({
            amount: oneOfType([number, string]),
            period: string,
        }),
        transactionData: shape({
            data: shape({}),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
    };

    static defaultProps = {
        signatureAlias: "",
        functionalGroups: [],
        match: null,
        signatureLevelsCounts: null,
        signatureType: "",
        topAmount: {
            amount: 0,
            period: "daily",
        },
    };

    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadSignaturesSchemesTicketRequest(match.params.idTransaction);
    }

    render() {
        const {
            functionalGroups,
            signatureAlias,
            signatureLevelsCounts,
            signatureType,
            topAmount,
            transactionData,
        } = this.props;

        return (
            <TicketContent head={renderAlias(signatureAlias)} transactionData={transactionData}>
                {renderAdditionalData(
                    "advanced",
                    signatureLevelsCounts,
                    signatureType,
                    topAmount,
                    functionalGroups,
                    [],
                )}
            </TicketContent>
        );
    }
}

export default SignaturesScheme;
