import React from "react";

import Heading from "pages/_components/Heading";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import { renderFunctionalGroups } from "./signaturesScheme";

export const renderNameAction = (action, name) => (
    <div className="my-3">
        <Heading.DataGroup
            containerClassName="data-wrapper data-confirm mb-0 data-confirm-head"
            label={`administration.groups.singular.action.subtitle.${action}`}
            data={name}
        />
    </div>
);

export const renderNameMassiveAction = (groupAction, list) => (
    <div className="my-3">
        <Box>
            <Text labelKey={`administration.groups.action.confirmation.${groupAction}`} light />
        </Box>
        <Box display="flex" gap="3" className="flex-wrap mt-5">
            {list.map((groupName) => (
                <Box
                    key={`administration.groups.${groupName}`}
                    display="flex"
                    background="primary-background-color"
                    className="py-3 px-5 my-auto"
                    border="primary-focus-color"
                    borderRadius="xxl"
                    gap="3"
                    fitWidth>
                    <Text color="primary-active-color" size="6">
                        {groupName}
                    </Text>
                </Box>
            ))}
        </Box>
    </div>
);

export const renderMainData = (name) => (
    <Box background="background-disabled" className="px-5 py-1 my-2" borderRadius="lg">
        <div>
            <Heading.DataGroup
                containerClassName="data-wrapper data-confirm m-0 data-confirm-head"
                label="administration.advanced.group.create.name.label"
                data={name}
            />
        </div>
    </Box>
);

const renderDescription = (description) => (
    <div className="mb-5">
        <Heading.DataGroup
            containerClassName="data-wrapper data-confirm"
            label="administration.advanced.group.create.description.label"
            data={description}
        />
    </div>
);

export const renderAdditionalData = (description, functionalGroups, groups) => (
    <>
        {description && renderDescription(description)}
        {functionalGroups &&
            Object.keys(functionalGroups) &&
            Object.keys(functionalGroups).length > 0 &&
            renderFunctionalGroups(groups, functionalGroups)}
    </>
);

export const renderAdditionalDataUserList = (action, users) =>
    users.length > 0 && (
        <Row className="mb-4">
            <Col xs={6}>
                <Box className="mt-4">
                    <Text labelKey={`administration.groups.users.${action}.list`} light />
                </Box>
            </Col>
            <Col xs={6}>
                <Box display="flex" className="flex-wrap" alignX="end">
                    {users.map(({ idUser, fullName }) => (
                        <Box
                            key={idUser}
                            display="flex"
                            background="primary-background-color"
                            className="py-3 px-5 ml-3"
                            border="primary-focus-color"
                            borderRadius="xxl"
                            gap="3"
                            fitWidth>
                            <Text color="primary-active-color" size="6">
                                {fullName}
                            </Text>
                        </Box>
                    ))}
                </Box>
            </Col>
        </Row>
    );

export const steps = {
        making: "making",
        confirmation: "confirmation",
    };
