import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import { arrayOf, bool, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors } from "reducers/administration/common/administrationTicket";

class GroupActionTicket extends Component {
    static propTypes = {
        transactionData: shape({
            data: shape({
                groupNameList: arrayOf(string),
                blocked: bool,
            }),
        }).isRequired,
    };

    render() {
        const { transactionData } = this.props;

        if (transactionData.data.groupNameList) {
            let groupAction = "delete";
            if (transactionData.data.blocked !== undefined) {
                groupAction = transactionData.data.blocked ? "block" : "unblock";
            }
            const textKey = `administration.groups.action.confirmation.${groupAction}`;
            const ticketText = transactionData.data.groupNameList.map((groupName, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}> {groupName} </div>
            ));
            return (
                <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <div className="transfer-data data-wrapper">
                            <Container.ColumnBody columnClass="list-wrapper">
                                <h4 className="form-title">
                                    <I18n id={textKey} />
                                </h4>
                                {ticketText}
                            </Container.ColumnBody>
                        </div>
                    </Col>
                </Container>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(GroupActionTicket);
