import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { selectors as wallySelectors, actions as wallyActions } from "reducers/wally";
import * as i18n from "util/i18n";
import { formatAccount } from "../hooks/WallyHooks";

const EnrollmentWallyFinish = (props) => {
    const { fetching, dispatch, mobilePhoneMask, account } = props;

    useEffect(() => {
        dispatch(settingsActions.getUserData());
        dispatch(wallyActions.userWallyRequest());
    }, []);

    const handleBack = () => {
        dispatch(push("/desktop"));
    };

    const redirectToWally = () => {
        dispatch(push("/wally"));
    };

    return (
        <PageLoading loading={fetching}>
            <Head titleImage="wally-head.svg" onBack={handleBack} title="wally.header.activation" />

            <MainContainer>
                <Box display="flex" column fullWidth fullHeight>
                    <Box display="flex" column alignY="between" alignX="center" className="pt-8">
                        <Image src="images/rocket.svg" isMobileNative height="12" className="mb-7" />
                        <Text
                            labelKey="wally.finish.title"
                            bold
                            size="1"
                            align="center"
                            color="heading"
                            className="mb-3"
                        />
                        <Box display="flex" alignY="center" alignX="center" fullWidth>
                            <span className="size-4 align-center">
                                {`${i18n.get("wally.finish.subtitle")} `}
                                <Text labelKey="wally.finish.subtitle.span" color="wally" bold size="4" />
                            </span>
                            <Image
                                src="images/wally.svg"
                                fullWidth={false}
                                isMobileNative
                                height="5"
                                wrapperWidth="8"
                                wrapperHeight="4"
                                className="ml-min"
                            />
                        </Box>
                    </Box>
                    <Box display="flex" column alignX="center" alignY="center" fullWidth className="mt-8">
                        <Box
                            background="white"
                            display="flex"
                            alignX="flex-start"
                            alignY="center"
                            fullWidth
                            className="py-6 pl-6 pr-1"
                            borderRadius="xl">
                            <Image
                                src="images/check-circle.svg"
                                isMobileNative
                                height="5"
                                wrapperWidth="5"
                                wrapperHeight="5"
                                className="mr-3"
                            />
                            <Text size="3" bold color="heading">
                                Celular:{" "}
                            </Text>
                            <Text size="3"> {mobilePhoneMask}</Text>
                        </Box>
                        <Box
                            background="white"
                            display="flex"
                            alignX="flex-start"
                            alignY="center"
                            fullWidth
                            className=" my-3 py-6 pl-6 pr-1"
                            borderRadius="xl">
                            <Image
                                src="images/check-circle.svg"
                                isMobileNative
                                height="5"
                                width="5"
                                wrapperWidth="5"
                                wrapperHeight="5"
                                className="mr-3"
                            />
                            <Text size="3" bold color="heading">
                                {account?.productAlias}{" "}
                            </Text>
                            <Text size="3">{formatAccount(account)}</Text>
                        </Box>
                    </Box>
                    <Box display="flex" column alignX="center" alignY="center" className="px-5 pt-7 mb-9">
                        <Text labelKey="wally.finish.tip.title" align="center" size="4" bold />
                        <span className="size-4 align-center text-bold">
                            {`${i18n.get("wally.finish.tip")} `}
                            <Text labelKey="wally.finish.tip.path" color="wally" bold size="4" />
                        </span>
                    </Box>
                    <Box display="flex" fullWidth className="mt-auto pb-10">
                        <Button label="wally.finish.button" bsStyle="primary" block onClick={() => redirectToWally()} />
                    </Box>
                </Box>
            </MainContainer>
        </PageLoading>
    );
};

const mapStateToProps = (state) => ({
    mobilePhoneMask: settingsSelectors.getMobilePhoneMask(state) || "",
    fetching: settingsSelectors.isFetching(state),
    account: wallySelectors.getSelectedAccount(state),
});

EnrollmentWallyFinish.propTypes = {
    dispatch: func.isRequired,
    fetching: bool,
    mobilePhoneMask: string,
    account: shape({}).isRequired,
};

EnrollmentWallyFinish.defaultProps = { fetching: false, mobilePhoneMask: "" };

export default withRouter(connect(mapStateToProps)(resizableRoute(EnrollmentWallyFinish)));
