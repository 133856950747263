import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import SwitchField from "pages/_components/fields/SwitchField";
import TextField from "pages/_components/fields/TextField";
import ServiceRemarkableInfo from "pages/servicePayments/_components/ServiceRemarkableInfo";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { compose } from "redux";
import * as i18n from "util/i18n";
import DinamicForm from "./_components/DinamicForm";
import { initialValues, validationSchema } from "./hooks/dinamicFormHooks";
import fieldARP from "./hooks/field";
import { getCategorySelected, getFieldBalance } from "./hooks/servicePaymentsHooks";

const FORM_ID = "servicePayment.new";

const AddNewServiceForm = (props) => {
    const { isDesktop, history, dispatch, fetching, field, selectedBiller, isFastRecharge, ...rest } = props;

    const [categorySelected, setCategorySelected] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (!selectedBiller) {
            dispatch(push("/servicePayments"));
        } else {
            setCategorySelected(getCategorySelected(selectedBiller?.category));
            // Telered -> consulta de reglas
            if (selectedBiller?.svcProviderId === "Telered") {
                dispatch(payServiceActions.listRulesBillerRequest(rest.match.params?.idBiller));
            } else {
                // ARP -> definicion de reglas por defecto
                dispatch(payServiceActions.listRulesBillerSuccess(fieldARP));
            }
        }
    }, []);

    const handleBack = () => {
        history.goBack();
    };

    const handleCancel = () => {
        dispatch(push("/servicePayments"));
    };

    const fieldFiltered = field.filter((el) => el.fieldInq === true);

    const handleSubmit = (values) => {
        setIsSubmitting(true);
        const query = getFieldBalance(values, fieldFiltered, selectedBiller);
        dispatch(payServiceActions.setQueryBillPre(query));
        dispatch(push("/formCustom/servicePayment"));
        setIsSubmitting(false);
    };
    return (
        <>
            <Head title="servicePayments.new.title" onBack={handleBack} />
            <MainContainer showLoader={fetching}>
                {categorySelected.length > 0 && (
                    <ServiceRemarkableInfo
                        title={i18n.get(`servicePayment.category.${categorySelected[0].idServicePayment}`)}
                        logo={categorySelected[0].logo}
                        subtitle={selectedBiller?.name}
                        className="mb-5 mb-md-7"
                    />
                )}
                {fieldFiltered.length > 0 && (
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize
                        initialValues={initialValues(fieldFiltered, isFastRecharge)}
                        validationSchema={validationSchema(fieldFiltered)}
                        onSubmit={(values) => handleSubmit(values)}>
                        {(formikItem) => (
                            <Form className={classNames("display-flex flex-column  ", { "full-height": !isDesktop })}>
                                <Row gapY={isDesktop ? "8" : "3"} className="mb-5 mb-md-8 height-auto ">
                                    <Col xs={12}>
                                        <Box className="mx-n-5">
                                            <Box
                                                display="flex"
                                                column
                                                gap="7"
                                                background="white"
                                                borderRadius="default"
                                                className={classNames("px-5 px-md-9 pt-5 pb-9 pt-md-7 pb-md-7", {
                                                    "min-height-16-5rem": isDesktop,
                                                })}
                                                fullWidth>
                                                <DinamicForm idForm={FORM_ID} fields={fieldFiltered} />

                                                {formikItem.values?.saveService && (
                                                    <Row>
                                                        <Col xs={12} md={6}>
                                                            <Field
                                                                component={TextField}
                                                                hidePlaceholder
                                                                idForm={FORM_ID}
                                                                type="text"
                                                                name="alias"
                                                                labelNoMarginTop
                                                                optional={i18n.get("forms.optional")}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                                <SwitchField
                                                    name="saveService"
                                                    idForm={FORM_ID}
                                                    value={formikItem.values?.saveService}
                                                    onChange={() =>
                                                        formikItem.setFieldValue(
                                                            "saveService",
                                                            !formikItem.values?.saveService,
                                                        )
                                                    }
                                                    disabled={isFastRecharge}
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>

                                <Box {...(!isDesktop && { className: "mt-auto" })}>
                                    <Row gapY={isDesktop ? "8" : "3"}>
                                        <Col xs={12} md={3} mdOffset={3}>
                                            <Button
                                                label="global.cancel"
                                                type="button"
                                                className="btn-outline"
                                                onClick={handleCancel}
                                                block
                                            />
                                        </Col>
                                        <Col xs={12} md={3} {...(!isDesktop && { className: "grid-reversed" })}>
                                            <Button
                                                bsStyle="primary"
                                                label="global.confirm"
                                                loading={isSubmitting}
                                                type="submit"
                                                block
                                            />
                                        </Col>
                                    </Row>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                )}
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: payServiceSelectors.isFetching(state),
    field: payServiceSelectors.getField(state),
    selectedBiller: payServiceSelectors.getSelectedBiller(state),
    isFastRecharge: payServiceSelectors.isFastRecharge(state),
});

AddNewServiceForm.propTypes = {
    isDesktop: bool,
    dispatch: func.isRequired,
    history: shape({}).isRequired,
    fetching: bool,
    field: arrayOf(shape({})),
    selectedBiller: shape({}),
    isFastRecharge: bool,
};

AddNewServiceForm.defaultProps = {
    fetching: false,
    isDesktop: false,
    field: [],
    selectedBiller: null,
    isFastRecharge: false,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(AddNewServiceForm));
