import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import { bool, func, object, oneOfType, string } from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { actions as modalActions } from "reducers/modal";
import { compose } from "redux";

const InfoModal = ({ modalSubtitle, body, preBody, postBody, dispatch }) => {
    const handleClickButtonClose = () => {
        dispatch(modalActions.modalClose());
    };

    return (
        <>
            <Modal
                aria-labelledby="modalTitleID"
                aria-modal="true"
                onHide={() => {
                    handleClickButtonClose();
                }}
                show
                className="drawer">
                <div className="modal-container full-height">
                    <Modal.Header closeButton>
                        <Modal.Title id="modalTitleID">
                            <Text labelKey={modalSubtitle} />
                            {typeof preBody !== "undefined" && <Box>{preBody}</Box>}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Box className="scrollable-content px-0 px-md-9" fullWidth fullHeight>
                            {body}
                            {typeof postBody !== "undefined" && postBody}
                        </Box>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
};

InfoModal.propTypes = {
    dispatch: func.isRequired,
    body: oneOfType([func, object]),
    preBody: oneOfType([func, object]),
    postBody: oneOfType([func, bool]),
    modalSubtitle: string,
};

InfoModal.defaultProps = {
    modalSubtitle: "",
    preBody: undefined,
    postBody: undefined,
    body: undefined,
};

export default compose(connect())(InfoModal);
