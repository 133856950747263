import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";

import { selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import withExchangeToken from "pages/_components/withExchangeToken";

import Step4Content from "pages/recoveryPassword/_components/Step4Content";
import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import LoginWrapper from "pages/login/_components/LoginWrapper";
import MainContainer from "pages/_components/MainContainer";

class RecoveryPassStep4 extends Component {
    render() {
        const { isMobile } = this.props;

        return (
            <LoginWrapper>
                {!isMobile && <BoxErrorNotification isMobile={isMobile} scopeToShow="recoveryPassword" />}

                {/* <Head isMobile={isMobile} title="recoveryPassword.step3.header" /> */}

                <MainContainer>
                    <Step4Content {...this.props} />
                </MainContainer>
            </LoginWrapper>
        );
    }
}

RecoveryPassStep4.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
};

const mapStateToProps = (state) => ({
    exchangeToken: recoveryPasswordSelectors.getExchangeToken(state),
});

export default connect(mapStateToProps)(withExchangeToken(RecoveryPassStep4));
