/* eslint-disable-next-line import/prefer-default-export */
export const convertSmartGroupsToProducts = (permissions, products) => {
    const parsedPermissions = [];
    const productsCopy = [...products];
    if (permissions) {
        for (let i = permissions.length - 1; i > -1; i -= 1) {
            const permission = permissions[i].split("_");
            if (permission.length === 1) {
                parsedPermissions.push(permission[0]);
            } else {
                for (let j = productsCopy.length - 1; j > -1; j -= 1) {
                    const { idProduct, productType } = productsCopy[j];

                    if (permission.indexOf(productType) !== -1) {
                        parsedPermissions.push(idProduct);
                        productsCopy.splice(j, 1);
                    }
                }
            }
        }
    }
    return parsedPermissions;
};
