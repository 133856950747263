import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";

class ErrorPage extends React.Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        hasActiveSession: bool.isRequired,
        location: shape({
            title: string,
        }).isRequired,
    };

    getContent = () => {
        const { hasActiveSession, location, isDesktop } = this.props;
        const { title } = location;

        const btnHandlerOnClick = () => {
            const { dispatch } = this.props;
            dispatch(push(hasActiveSession ? "/desktop" : "/"));
        };
        return (
            <>
                {isDesktop ? (
                    <Box display="flex" column alignX="center" alignY="start" className="p-8" fullHeight>
                        <Box
                            fullWidth
                            component="article"
                            background="white"
                            borderRadius="default"
                            className="mb-8 pt-12 pb-10"
                            toBorderInMobile>
                            <Box display="flex" alignX="center" className="mb-5" fullWidth>
                                <Image className="color-white" src="images/icons/errors/backendError.svg" />
                            </Box>
                            <Text
                                component="h1"
                                align="center"
                                color="heading"
                                size="3"
                                bold
                                className="mt-0 mt-md-0 mb-7">
                                {i18n.get("error.page.backend.error.title")}
                            </Text>

                            <Text component="p" align="center" color="heading" size="6" className="mt-0 mt-md-7 mb-8">
                                {i18n.get("error.page.backend.error.description")}
                            </Text>
                        </Box>

                        <Row>
                            <Col xs={12} md={4} mdOffset={4}>
                                <Box display="flex" alignX="center" className="px-md-5 pb-9">
                                    <Button bsStyle="primary" onClick={btnHandlerOnClick} block>
                                        {i18n.get("global.goToHome")}
                                    </Button>
                                </Box>
                            </Col>
                        </Row>
                    </Box>
                ) : (
                    <>
                        <Head titleText={title} />
                        <MainContainer className="px-0 border-radius-lg p-7 box-shadow-small background-white">
                            <Box
                                component="article"
                                background="white"
                                borderRadius="default"
                                className="pt-5 mb-5 d-flex flex-column flex content-center"
                                toBorderInMobile>
                                <Box display="flex" alignX="center" className="mb-5" fullWidth>
                                    <Image className="color-white" src="images/icons/errors/backendError.svg" />
                                </Box>
                                <Text
                                    component="h1"
                                    align="center"
                                    color="heading"
                                    size="3"
                                    bold
                                    className="mt-0 mt-md-0 mb-7">
                                    {i18n.get("error.page.backend.error.title")}
                                </Text>

                                <Text
                                    component="p"
                                    align="center"
                                    color="heading"
                                    size="6"
                                    className="mt-0 mt-md-7 mb-8">
                                    {i18n.get("error.page.backend.error.description")}
                                </Text>
                            </Box>

                            <Row>
                                <Col xs={12} md={4} mdOffset={4}>
                                    <Box display="flex" alignX="center" className="px-md-5 pb-9">
                                        <Button bsStyle="primary" onClick={btnHandlerOnClick} block>
                                            {i18n.get("global.goToHome")}
                                        </Button>
                                    </Box>
                                </Col>
                            </Row>
                        </MainContainer>
                    </>
                )}
            </>
        );
    };

    render() {
        return this.getContent();
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(ErrorPage)));
