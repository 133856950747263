import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    SET_MOMENT_DAY: "session/SET_MOMENT_DAY",
};

export const INITIAL_STATE = {
    moment: "",
};

const reducer = createReducer(INITIAL_STATE, {
    [types.SET_MOMENT_DAY]: (state, { moment }) => ({
        ...state,
        moment,
    }),
});

export default persistReducer(
    {
        storage,
        key: "momentDay",
        whitelist: [],
    },
    reducer,
);

export const actions = {
    setMomentDay: makeActionCreator(types.SET_MOMENT_DAY, "moment"),
};

export const selectors = {
    getMomentDay: ({ momentDay }) => momentDay.moment,
};
