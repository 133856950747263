import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";
import { routerActions } from "react-router-redux";

import Notification from "pages/_components/Notification";
import Step2Content from "pages/recoveryPassword/_components/Step2Content";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import LoginWrapper from "pages/login/_components/LoginWrapper";
import classNames from "classnames";
import Box from "pages/_components/Box";

class RecoveryPassStep2 extends Component {
    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    render() {
        const { isMobile } = this.props;

        return (
            <LoginWrapper>
                <Box
                    display="flex"
                    {...(isMobile && { alignX: "flex-end", component: "main" })}
                    column
                    zIndex="9"
                    fullHeight
                    className={classNames("login-background", { "mt-auto": isMobile })}>
                    {!isMobile && <Notification isMobile={isMobile} scopeToShow="recoveryPassword" />}

                    {!isMobile && (
                        <Head isMobile={isMobile} onBack={this.onHeaderClose} title="recoveryPassword.step2.header" />
                    )}

                    <Step2Content {...this.props} />
                </Box>
            </LoginWrapper>
        );
    }
}

RecoveryPassStep2.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
};

export default connect(null)(RecoveryPassStep2);
