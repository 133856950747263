import React from "react";
import { func, string, bool, shape } from "prop-types";
import classnames from "classnames";
import { noop } from "pages/_components/utils";
import Box from "pages/_components/Box";
import Checkbox from "pages/_components/Checkbox";
import Image from "pages/_components/Image";
import ListItem from "./ListItem";

function SelectListItem({ className, label, readonly, selected, item, itemKey, onChange, onItemClick, selectedTitle }) {
    const handleItemClick = () => {
        onItemClick(item);
    };

    return (
        <ListItem
            onClick={handleItemClick}
            key={itemKey}
            className={classnames("list-group-item", { "item-active": selectedTitle(item) }, item.className)}>
            {!readonly && !item.hideCheck ? (
                <Checkbox
                    checked={selected}
                    name={`list-item-check-${itemKey}`}
                    pureLabel={label}
                    onChange={onChange}
                    disabled={readonly}
                />
            ) : (
                <Box className={className}>
                    <Box display="flex">
                        <Box className="perm-icon-check mr-4">
                            {item.className && item.className.includes("select-item--none") && (
                                <Image src="images/check-none.svg" />
                            )}
                            {item.className && item.className.includes("select-item--some") && (
                                <Image src="images/check-some.svg" />
                            )}
                            {item.className && item.className.includes("select-item--all") && (
                                <Image src="images/check-all.svg" />
                            )}
                        </Box>
                        {label}
                    </Box>
                </Box>
            )}
        </ListItem>
    );
}

SelectListItem.propTypes = {
    className: string,
    item: shape({
        className: string,
        hideCheck: bool,
        inline: bool,
    }),
    itemKey: string.isRequired,
    label: string.isRequired,
    readonly: bool,
    selected: bool,
    onChange: func,
    selectedTitle: func,
    onItemClick: func,
};

SelectListItem.defaultProps = {
    className: "",
    item: { className: "", hideCheck: false, inline: false },
    readonly: false,
    selected: false,
    onChange: noop,
    selectedTitle: noop,
    onItemClick: noop,
};

export default SelectListItem;
