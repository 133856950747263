import { Field } from "formik";
import useGenericProps from "pages/forms/customForms/hooks/BeneficiaryHooks";
import BeneficiaryField from "pages/forms/customForms/_customFields/BeneficiaryField";
import { BENEFICIARY_THREE_NAME, IBAN_NUMBER } from "pages/forms/customForms/_customFields/BeneficiaryField/Constants";
import PageLoading from "pages/_components/PageLoading";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    actions as beneficiaryActions,
    selectors as beneficiarySelectors,
} from "reducers/frequentDestination/frequentDestination";
import { selectors as i18nSelectors } from "reducers/i18n";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";

export const ID_FORM = "beneficiary.transfer.foreign";
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

const BeneficiaryForeignForm = ({
    values,
    setFieldValue,
    dispatch,
    preDataForm,
    currentLang,
    handleSubmit,
    beneficiaryId,
    isFetching,
    directoryStyle,
}) => {
    const [genericProps] = useGenericProps(currentLang);
    const [codeBankList, setCodeBankList] = useState([]);
    const [relationshipTypeList, setRelationshipTypeList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [productTypeList, setProductTypeList] = useState([]);

    useEffect(() => {
        dispatch(beneficiaryActions.getPreDataForm(beneficiaryId, ID_ACTIVITY_PRE));
    }, []);

    const preloadData = () => {
        const codeBankListResponse = preDataForm?.codeBankList?.map((item) => ({ id: item, label: item }));
        setCodeBankList(codeBankListResponse);

        const relationshipTypeListResponse = preDataForm?.beneficiaryRelationList?.map((item) => ({
            id: item.id || "",
            label: i18nUtils.get(`forms.transfers.foreign.relationshipTypeList.option.${item?.id}`) || "",
        }));
        setRelationshipTypeList(relationshipTypeListResponse);
        const countryListResponse = preDataForm?.countryList?.map((item) => ({
            id: item.id || "",
            label: item.name || "",
        }));
        setCountryList(countryListResponse);
        const productTypeListResponse =
            preDataForm?.beneficiaryProductTypeList?.map((item) => ({
                id: item?.id || "",
                label: i18nUtils.get(`forms.transfers.foreign.productTypeList.option.${item?.id}`) || "",
            })) || [];
        setProductTypeList(productTypeListResponse);

        const { beneficiary } = preDataForm;

        if (beneficiary) {
            const extraData = JSON.parse(beneficiary.extraData);
            Object.keys(extraData).forEach((item) => {
                setFieldValue(`beneficiary.${item}`, extraData[item]);
            });
            const { addIntermediaryBank } = extraData;
            setFieldValue("addIntermediaryBank", !!addIntermediaryBank);
        }
    };

    useEffect(() => {
        if (preDataForm) {
            preloadData();
        }
    }, [preDataForm]);

    return (
        <PageLoading loading={isFetching} borderRadius="default" zIndex="9">
            <form className="full-width" onSubmit={handleSubmit}>
                <Field
                    {...genericProps}
                    isForeignForm
                    component={BeneficiaryField}
                    key="beneficiary"
                    name="beneficiary"
                    idField="beneficiary"
                    productTypeList={productTypeList || []}
                    value={
                        values?.beneficiary || {
                            bankSelector: codeBankList[0],
                            intermediary_bankSelector: codeBankList[0],
                            beneficiaryOption: BENEFICIARY_THREE_NAME,
                            birthDateFrom: new Date(),
                            accountType: IBAN_NUMBER,
                        }
                    }
                    codeBankList={codeBankList || []}
                    relationshipTypeList={relationshipTypeList || []}
                    countryList={countryList || []}
                    showHeaderComponent={false}
                    showSaveBeneficiary={false}
                    fromNewBeneficiary
                    directoryStyle={directoryStyle}
                />
            </form>
        </PageLoading>
    );
};

BeneficiaryForeignForm.propTypes = {
    values: shape({}).isRequired,
    setFieldValue: func.isRequired,
    dispatch: func.isRequired,
    preDataForm: shape({}),
    currentLang: string.isRequired,
    handleSubmit: func.isRequired,
    beneficiaryId: string,
    isFetching: bool,
    directoryStyle: bool,
};

BeneficiaryForeignForm.defaultProps = {
    preDataForm: undefined,
    beneficiaryId: undefined,
    isFetching: false,
    directoryStyle: false,
};

const mapStateToProps = (state) => ({
    preDataForm: beneficiarySelectors.getPreDataForm(state),
    currentLang: i18nSelectors.getLang(state),
    isFetching: beneficiarySelectors.isFetching(state),
});

export default compose(connect(mapStateToProps))(BeneficiaryForeignForm);
