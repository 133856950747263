import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Notification from "pages/_components/Notification";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { arrayOf, bool, func, shape, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import Text from "pages/_components/Text";
import Row from "pages/_components/Row";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import TextField from "pages/_components/fields/TextField";
import Permissions from "../_components/cashProductField/Permissions";

const GroupForm = ({ idForm, data, isCreate, handleBack, handleContinue, products, groups }) => {
    const requiredMessage = i18n.get("global.field.required");

    const renderHeader = () => (
        <>
            <Notification scopeToShow="groupForm" />
            <Head
                onBack={handleBack}
                title={`administration.advanced.group.${isCreate ? "create" : "modify"}.title`}
                textBack={isCreate ? "administration.groups.returnToList" : "administration.groups.returnToDetail"}
            />
        </>
    );

    const normalizedProducts = (items) =>
        items.map((p) => ({ idProduct: p.idProduct, label: p.label, type: p.productType }));

    const handleSubmit = (values, { setFieldError, ...restFormik }) => {
        if (!values?.permissions || Object.keys(values?.permissions).length === 0) {
            setFieldError("permissions", i18n.get("administration.advanced.group.create.permissions.error", "error"));
            return;
        }
        handleContinue(values, restFormik);
    };

    const validateFields = ({ values }) => !values.name || !specialCharactersRegex.test(values.name) || Object.keys(values.permissions || {}).length === 0;

    const specialCharactersRegex = new RegExp(configUtils.get("form.groups.name.validationFormat"));

    const validationSchema = Yup.object().shape({
        name: Yup.string()
                .matches(specialCharactersRegex, i18n.get("form.general.validations.format"))
                .required(requiredMessage),
        permissions: Yup.object().required(requiredMessage),
    });

    return (
        <>
            {renderHeader()}
            <MainContainer className="main-container">
                <Formik
                    enableReinitialize
                    initialValues={data}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}>
                    {(formik) => (
                        <Form>
                            <Box borderRadius="lg" background="white" className="box-shadow-small mt-3 p-7">
                                <Box borderRadius="lg" background="white" className="box-shadow-small px-7 pt-6 pb-7">
                                    <Row gapX="7">
                                        <Col xs={6}>
                                            <Field
                                                name="name"
                                                idForm={idForm}
                                                component={TextField}
                                                hidePlaceholder
                                                labelClassName="text-bold"
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <Field
                                                name="description"
                                                idForm={idForm}
                                                component={TextField}
                                                // renderAs="textarea"
                                                optional={i18n.get(`${idForm}.description.optional.text`)}
                                                hidePlaceholder
                                                labelClassName="text-bold"
                                            />
                                        </Col>
                                    </Row>
                                </Box>
                                <Box borderRadius="lg" background="white" className="box-shadow-small p-7 mt-7">
                                    <Text
                                        labelKey="administration.advanced.group.create.permissions.label"
                                        size="4"
                                        bold
                                    />
                                    <Permissions
                                        fieldName="permissions"
                                        administrationSchema="advanced"
                                        data={{ groups, products: normalizedProducts(products) }}
                                    />
                                </Box>
                            </Box>
                            <Row gapX="7" className="mt-7 mb-7">
                                <Col xs={3} xsOffset={3}>
                                    <Button
                                        bsStyle="outline"
                                        label="global.cancel"
                                        btnUppercase={false}
                                        block
                                        type="button"
                                        onClick={handleBack}
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Button
                                        bsStyle="primary"
                                        label="global.continue"
                                        btnUppercase={false}
                                        disabled={validateFields(formik)}
                                        // disabled={!values.accept}
                                        block
                                        type="submit"
                                        loading={formik?.isSubmitting}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </>
    );
};

GroupForm.propTypes = {
    idForm: string.isRequired,
    data: shape({}).isRequired,
    isCreate: bool.isRequired,
    handleBack: func.isRequired,
    handleContinue: func.isRequired,
    products: arrayOf(shape({})).isRequired,
    groups: shape({}).isRequired,
};

export default GroupForm;
