import { useEffect, useState } from "react";
import { numberFormat } from "util/number";
import * as i18n from "util/i18n";

const DEFAULT_MONTHS_ANUAL = 12;
const DEFAULT_FRECUENCY_ANNUAL = "ANNUAL";
const useFixedTermDepositPreData = (preDataForm, activeEnvironment) => {
    const { decimalSeparator, thousandSeparator } = numberFormat();

    const [selectorDebitAccountList, setSelectorDebitAccountList] = useState();

    const [selectorPaymentAccountList, setSelectorPaymentAccountList] = useState();

    const [depositTypeOptionList, setDepositTypeOptionList] = useState();

    const [dataAmount, setDataAmount] = useState({
        decimalSeparator,
        precision: 2,
        thousandsSeparator: thousandSeparator,
        options: [],
    });

    const [expirationActionOptionList, setExpirationActionOptionList] = useState();

    const [interestPaymentFrequencyList, setInterestPaymentFrequencyList] = useState();

    const [termList, setTermList] = useState();

    const [depositTypeMetadata, setDepositTypeMetadata] = useState();
    const [documentData, setDocumentData] = useState();

    const [beneficiaryRelationshipList, setBeneficiaryRelationshipList] = useState();

    const setBeneficiaryRelationshipListData = () => {
        const relationshipList =
            preDataForm?.beneficiaryRelationshipList?.map((item) => ({
                id: item || "",
                label: i18n.get(`beneficiaryRelationshipList.${(item || "").toLowerCase()}.label`),
            })) || [];

        setBeneficiaryRelationshipList(relationshipList);
    };

    const updateInterestPaymentFrequencyByTerm = (term, setFieldValue) => {
        const termRef = preDataForm?.termList?.find(
            (termItem) => term && term.length > 0 && termItem?.termId === term[0]?.id,
        );
        const updateList = preDataForm?.paymentFrecuencyOptionList?.map((frecuency) => ({
            id: frecuency || "",
            label: i18n.get(`paymentFrecuencyOptionList.${(frecuency || "").toLowerCase()}.label`),
            disabled:
                frecuency === DEFAULT_FRECUENCY_ANNUAL && termRef?.weight && termRef.weight < DEFAULT_MONTHS_ANUAL,
        }));
        setInterestPaymentFrequencyList(updateList);
        if (setFieldValue) {
            setFieldValue("interestPaymentFrequency", undefined);
        }
    };

    const setInterestData = () => {
        const expirationActionOptionListDef =
            preDataForm?.actionExpirationOptionList?.map((item) => ({
                id: item || "",
                label: i18n.get(`actionExpirationOptionList.${(item || "").toLowerCase()}.label`),
            })) || [];

        setExpirationActionOptionList(expirationActionOptionListDef);

        const termListData = [...(preDataForm?.termList || [])];
        termListData.sort((a, b) => a.weight - b.weight);
        const termListDef =
            termListData?.map((item) => ({
                id: item?.termId || "",
                label: item?.termDesc || "",
            })) || [];

        setTermList(termListDef);
        const defaultTermData = termListDef.length > 0 ? termListDef[0] : undefined;
        updateInterestPaymentFrequencyByTerm([defaultTermData]);
    };

    const setAmountData = () => {
        const amount = {
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options:
                preDataForm?.currencyList?.map((cur) => ({
                    id: cur?.backendId || "",
                    label: cur?.code || "",
                })) || [],
        };

        setDataAmount(amount);
    };

    const setDepositTypeData = () => {
        const depTypeOptionList =
            preDataForm?.fixedTermDepositTypeList?.map((item) => ({
                id: item || "",
                label: i18n.get(`depositType.${activeEnvironment?.type || ""}.${(item || "").toLowerCase()}.label`),
            })) || [];

        setDepositTypeOptionList(depTypeOptionList);
    };

    const setDebitAccountListData = () => {
        const debitAccountList = [...(preDataForm?.debitAccountList || [])].map((acc) => ({
            ...acc,
            balance: { currency: acc.currency, quantity: acc.balance },
            id: acc.idProduct,
            disabled: acc.isDisabled,
        }));

        setSelectorDebitAccountList({ options: debitAccountList });
    };

    const setPaymentAccountListData = () => {
        const paymentAccountList = [...(preDataForm?.debitAccountList || [])].map((acc) => ({
            ...acc,
            balance: { currency: acc.currency, quantity: acc.balance },
            id: acc.idProduct,
            disabled: acc.isDisabled && acc.statusDesc !== "ASD",
        }));

        setSelectorPaymentAccountList({ options: paymentAccountList });
    };

    useEffect(() => {
        setDebitAccountListData();
        setPaymentAccountListData();
        setDepositTypeData();
        setAmountData();
        setInterestData();
        setDepositTypeMetadata(preDataForm?.depositTypeMetadata);
        setDocumentData(preDataForm?.documentData);
        setBeneficiaryRelationshipListData();
    }, [preDataForm, activeEnvironment]);

    return [
        selectorDebitAccountList,
        selectorPaymentAccountList,
        depositTypeOptionList,
        dataAmount,
        expirationActionOptionList,
        interestPaymentFrequencyList,
        termList,
        depositTypeMetadata,
        setDepositTypeMetadata,
        documentData,
        beneficiaryRelationshipList,
        updateInterestPaymentFrequencyByTerm,
    ];
};

export default useFixedTermDepositPreData;
