import ListItemDefault from "pages/_components/ListItemDefault";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, number, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";

class ListItem extends Component {
    static propTypes = {
        amount: number.isRequired,
        amountLabel: string,
        completeFavorites: bool,
        currency: string,
        expirationDate: string,
        expirationText: string,
        expiredText: string,
        icon: string,
        isExpired: bool,
        name: string.isRequired,
        overdraftLine: number,
        path: oneOfType([
            string,
            shape({
                pathname: string,
                search: string,
            }),
        ]).isRequired,
        productType: string,
        reference: string.isRequired,
        showProductIcon: bool,
        showStar: bool,
        showStatusIcon: bool,
        status: string,
        title: string,
    };

    static defaultProps = {
        amountLabel: "",
        completeFavorites: false,
        currency: null,
        expirationDate: "",
        expirationText: "",
        expiredText: "",
        icon: "",
        isExpired: false,
        overdraftLine: null,
        productType: "",
        showProductIcon: true,
        showStar: false,
        showStatusIcon: false,
        status: null,
        title: null,
    };

    render() {
        const { productType, overdraftLine, showProductIcon, showStar, showStatusIcon, status, currency } = this.props;
        const overdraftEnabled = productType === "CC" && overdraftLine > 0;
        const { isDesktop, ...props } = { ...this.props, overdraftEnabled };

        return (
            <ListItemDefault
                isDesktop={isDesktop}
                showProductIcon={showProductIcon}
                showStar={showStar}
                {...props}
                status={status}
                showStatusIcon={showStatusIcon}
                currency={currency}
            />
        );
    }
}

export default resizableRoute(ListItem);
