import React, { Component } from "react";
import { string, bool, func } from "prop-types";
import classNames from "classnames";
import Image from "pages/_components/Image";

class Checkbox extends Component {
    static propTypes = {
        checked: bool.isRequired,
        label: string.isRequired,
        controlStyle: string,
        value: string.isRequired,
        className: string,
        image: string,
        onChange: func.isRequired,
        name: string,
    };

    static defaultProps = {
        controlStyle: "checkbox",
        image: "images/icons/checkBox.svg",
        name: "",
        className: null,
    };

    handleClick = () => {
        const { onChange, value } = this.props;
        onChange(value);
    };

    render() {
        const { controlStyle, value, label, checked, image, name, className } = this.props;
        const uniqueId = `${name}--${label.replace(/ /gm, "_")}`;
        return (
            <div
                className={classNames(
                    "c-control c-control--has-icon c-control--checkbox c-control-block display-flex gap-3 align-items-center position-relative",
                    className,
                )}>
                <input
                    readOnly
                    className="c-control-input"
                    type="checkbox"
                    value={value}
                    checked={checked}
                    id={uniqueId}
                    onClick={this.handleClick}
                    name={name}
                />
                <label className="c-control-label" htmlFor={uniqueId}>
                    {controlStyle === ("checkbox" || "radio") && (
                        <div className="c-control-icons">
                            <div className="c-control-mark">
                                <Image src={image} className="svg-icon svg-caret" />
                            </div>
                        </div>
                    )}
                    <div className="form-group-text">{label}</div>
                </label>
            </div>
        );
    }
}

export default Checkbox;
