import * as API from "middleware/api";

export const loadListRequest = ({ pageNumber = 1, orderBy = "last_login DESC" } = {}) =>
    API.executeWithAccessToken("/administration.users.list", {
        pageNumber,
        orderBy,
    });

export const exportListRequest = ({ format = "xls", orderBy = "last_login DESC" } = {}) =>
    API.executeWithAccessToken("/administration.users.export", {
        format,
        orderBy,
    });

export const changeUsersStatusPreview = (params) =>
    API.executeWithAccessToken("/administration.users.blockunblock.preview", params);

export const changeUsersStatusConfirmation = (params, credentials) =>
    API.executeWithAccessToken("/administration.users.blockunblock.send", {
        ...params,
        ...credentials,
    });

export const deleteUsersPreview = (params) =>
    API.executeWithAccessToken("/administration.users.delete.preview", params);

export const deleteUsersConfirmation = (params, credentials) =>
    API.executeWithAccessToken("/administration.users.delete.send", {
        ...params,
        ...credentials,
    });

export const updateDispacther = (params) => API.executeWithAccessToken("/administration.users.update", params);
