import React, { Component } from "react";

const withFocus = (BaseComponent) =>
    class extends Component {
        static displayName = `withFocus${BaseComponent.name}`;

        state = {
            isFocused: false,
        };

        toggleIsFocused = () => {
            this.setState((prevState) => ({ isFocused: !prevState.isFocused }));
        };

        render() {
            return (
                <BaseComponent
                    {...this.props}
                    toggleIsFocused={this.toggleIsFocused}
                    isFocused={this.state.isFocused}
                />
            );
        }
    };

export default withFocus;
