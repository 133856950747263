import React, { Component } from "react";

import Box from "pages/_components/Box";
import { selectors as sessionSelectors } from "reducers/session";
import { connect } from "react-redux";
import Text from "pages/_components/Text";
import FormattedAmount from "pages/_components/FormattedAmount";
import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";
import CircularProgressBar from "pages/_components/CircularProgressBar";
import { selectors as depositActionsSelectors } from "reducers/deposits";
import Info from "pages/_components/Info";
import moment from "moment";
import * as i18n from "util/i18n";
import Row from "pages/_components/Row";
import { shape } from "prop-types";
import Tooltip from "pages/_components/Tooltip";

class DepositResume extends Component {
    static propTypes = {
        deposit: shape({})
    };

    static defaultProps = {};

    renderBalancesDesktop = (currency, initialcapital, rate, amount) => (
        <Box borderRadius="lg" background="background-primary" display="flex" className="p-7 children-border-right">
            <Box fullWidth>
                <Box className="fit-content-width text-left">
                    <Text labelKey="deposits.widget.resume.initialcapital" />
                    <FormattedAmount
                        noAmountMargin
                        className="data-amount-account"
                        currency={currency}
                        quantity={initialcapital}
                    />
                </Box>
            </Box>
            <Box fullWidth className="text-webkit-center">
                <Box className="fit-content-width text-left">
                    <Text labelKey="deposits.detail.rate" />

                    <FormattedAmount noAmountMargin className="data-amount-account" currency="%" quantity={rate} />
                </Box>
            </Box>
            <Box fullWidth className="text-webkit-right">
                <Box className="fit-content-width text-left">
                    <Text labelKey="deposits.detail.resume.amountatexpiration" />

                    <FormattedAmount
                        noAmountMargin
                        className="data-amount-account"
                        currency={currency}
                        quantity={amount}
                    />
                </Box>
            </Box>
        </Box>
    );

    renderBalancesMobile = (currency, initialcapital, rate, amount) => (
        <Box borderRadius="lg" background="background-primary" className="p-7">
            <Box fullWidth>
                <Box className="text-center line-height-15 mb-3">
                    <Text labelKey="deposits.detail.resume.amountatexpiration" />
                    <FormattedAmount
                        noAmountMargin
                        size="2"
                        className="data-amount-deposit big-amount content-center mb-1 color-primary"
                        currency={currency}
                        quantity={amount}
                        bold
                    />
                </Box>
            </Box>
            <Box display="flex" className="content-center mb-3">
                <Text labelKey="deposits.widget.resume.initialcapital" />
                :&nbsp;
                <FormattedAmount
                    noAmountMargin
                    className="data-amount-account"
                    currency={currency}
                    quantity={initialcapital}
                />
            </Box>

            <Box display="flex" className="content-center">
                <Text labelKey="deposits.detail.rate" />
                :&nbsp;
                <FormattedAmount noAmountMargin className="data-amount-account" currency="%" quantity={rate} />
            </Box>
        </Box>
    );

    renderBalances = (currency, initialcapital, rate, amount) => {
        const { isDesktop } = this.props;

        if (isDesktop) {
            return this.renderBalancesDesktop(currency, initialcapital, rate, amount);
        }
        return this.renderBalancesMobile(currency, initialcapital, rate, amount);
    };

    renderCircleProgressBar = ({ percentTerm, missingDays, estado }) => (
        <>
            <div style={{ width: 150, height: 150 }} className="circle-widget-tooltip">
            <Tooltip
                    onHover
                    forEllipsis
                    inText={<Text labelKey="deposits.list.tooltip.text" PERCENT_TERM={percentTerm} light />}
                    position="top-left"
                    className="align-self-center height-fit-content">
                <CircularProgressBar percentage={percentTerm} status={estado}>
                    <>
                        <strong>{i18n.get("deposits.detail.circle.days.1")}</strong>
                        <strong>
                            {i18n.get("deposits.detail.circle.days", null, {
                                DAYS: missingDays,
                            })}
                        </strong>
                    </>
                </CircularProgressBar>
                </Tooltip>
            </div>
        </>
    );

    render() {
        const { deposit, isDesktop } = this.props;

        if (!deposit) {
            return null;
        }

        const capital = deposit?.montoCapital || 0;
        const interestsToPay = deposit?.interesPagar || 0;
        const total = capital + interestsToPay;

        const renderBalances = this.renderBalances("USD", capital, deposit.tasa ?? 0, total);

        const circleProgressBar = this.renderCircleProgressBar(deposit);

        return (
            <>
                <Box className="pt-5">
                    <Box className="detail-head-info pt-0 pb-4">
                        <Container gridClassName="container-fluid">
                            <Row>
                                <Col className="col-12">{renderBalances}</Col>
                            </Row>
                            {isDesktop ? (
                                <Row>
                                    <Col className="col-6">
                                        <Box
                                            className="p-7 box-shadow-small background-white border-radius-lg"
                                            fullHeight>
                                            <Box className="pb-7">
                                                <Text labelKey="deposits.detail.resume.terms" bold />
                                            </Box>
                                            <Row>
                                                <Col className="col-6">
                                                    <Box fullHeight fullWidth>
                                                        {circleProgressBar}
                                                    </Box>
                                                </Col>
                                                <Col className="col-6">
                                                    <Box className="pt-4 pb-4">
                                                        <Info
                                                            labelKey="deposit.detail.fields.open.date.label"
                                                            text={moment(deposit.fechaApertura, "YYYY-MM-DD").format(
                                                                "DD/MM/YYYY",
                                                            )}
                                                            classNameValue="size-6"
                                                        />
                                                    </Box>
                                                    <Box className="pt-4 pb-4">
                                                        <Info
                                                            labelKey="deposits.detail.resume.expirationdate"
                                                            text={moment(deposit.fechaVencimiento, "YYYY-MM-DD").format(
                                                                "DD/MM/YYYY",
                                                            )}
                                                            classNameValue="size-6"
                                                        />
                                                    </Box>
                                                </Col>
                                            </Row>
                                        </Box>
                                    </Col>
                                    <Col className="col-6">
                                        <Box
                                            className="p-7 box-shadow-small background-white border-radius-lg"
                                            fullHeight>
                                            <Box className="pb-7">
                                                <Text bold labelKey="deposits.detail.resume.interests" />
                                            </Box>
                                            <Row>
                                                <Col className="col-6">
                                                    <Box className="pt-4 pb-4">
                                                        <Info
                                                            labelKey="deposits.detail.resume.intereststopay"
                                                            text={`${deposit.interesPagar} USD`}
                                                            classNameValue="size-6"
                                                        />
                                                    </Box>
                                                </Col>
                                                <Col className="col-6 text-webkit-right">
                                                    <Box className="pt-4 pb-4 fit-content-width text-left pr-9 mr-2">
                                                        <Info
                                                            labelKey="deposits.detail.resume.paymentfrecuency"
                                                            text={deposit.frecuenciaPago}
                                                            classNameValue="size-6"
                                                        />
                                                    </Box>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-12">
                                                    <Box
                                                        borderRadius="lg"
                                                        display="flex"
                                                        className="pt-4 pb-4 children-border-right">
                                                        <Box fullWidth>
                                                            <Box className="fit-content-width text-left">
                                                                <Text labelKey="deposits.detail.resume.totalinterestpaid" />

                                                                <FormattedAmount
                                                                    noAmountMargin
                                                                    className="data-amount-account"
                                                                    currency="USD"
                                                                    quantity={deposit.totalInteresPagado}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box fullWidth className="text-webkit-right">
                                                            <Box className="fit-content-width text-left">
                                                                <Text labelKey="deposits.detail.resume.totalinterestearned" />
                                                                <FormattedAmount
                                                                    noAmountMargin
                                                                    className="data-amount-account"
                                                                    currency="USD"
                                                                    quantity={deposit.totalInteresGanado}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Col>
                                            </Row>
                                        </Box>
                                    </Col>
                                </Row>
                            ) : (
                                <>
                                    <Box borderRadius="lg" className="box-shadow-small p-7">
                                        <Box display="flex" alignX="center" className="mt-2" fullWidth>
                                            {circleProgressBar}
                                        </Box>
                                        <Row className="mt-5">
                                            <Col xs="6">
                                                <Info
                                                    labelKey="deposits.detail.resume.expirationdate"
                                                    text={moment(deposit.fechaVencimiento, "YYYY-MM-DD").format(
                                                        "DD/MM/YYYY",
                                                    )}
                                                    classNameValue="size-6"
                                                />
                                            </Col>
                                            <Col xs="6">
                                                <div className="info-data full-width">
                                                    <div className="info-data-label">
                                                        <Text labelKey="deposits.detail.term" />
                                                    </div>
                                                    <div className="info-data-value size-6 mt-3">
                                                        <Text
                                                            labelKey="deposits.detail.circle.days"
                                                            DAYS={deposit.plazoTotal}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Box>
                                    <Box borderRadius="lg" className="box-shadow-small mt-4 p-7">
                                        <Box>
                                            <Info
                                                labelKey="deposits.detail.resume.intereststopay"
                                                text={`${deposit.interesPagar} USD`}
                                                classNameValue="size-6"
                                            />
                                        </Box>
                                        <Box className="mt-5">
                                            <Info
                                                labelKey="deposits.detail.resume.paymentfrecuency"
                                                text={deposit.frecuenciaPago}
                                                classNameValue="size-6"
                                            />
                                        </Box>
                                        <hr />
                                        <Box className="mt-5 line-height-15">
                                            <Text labelKey="deposits.detail.resume.totalinterestpaid" />
                                            <FormattedAmount
                                                noAmountMargin
                                                size="2"
                                                className="data-amount-deposit big-amount mb-1"
                                                currency="USD"
                                                quantity={deposit.totalInteresPagado}
                                                bold
                                            />
                                        </Box>
                                        <Box className="mt-5 line-height-15">
                                            <Text labelKey="deposits.detail.resume.totalinterestearned" />
                                            <FormattedAmount
                                                noAmountMargin
                                                size="2"
                                                className="data-amount-deposit big-amount mb-1 color-primary"
                                                currency="USD"
                                                quantity={deposit.totalInteresGanado}
                                                bold
                                            />
                                        </Box>
                                    </Box>
                                </>
                            )}
                        </Container>
                    </Box>
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    deposit: depositActionsSelectors.getSelectedDeposit(state),
    paymentMethod: depositActionsSelectors.getPaymentMethod(state),
});

export default connect(mapStateToProps)(DepositResume);
