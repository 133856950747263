export const steps = [
    {
        stepNumber: 1,
        id: "termsAndConditions",
        label: "softToken.activate.step1.title",
    },
    {
        stepNumber: 2,
        id: "securityMethod",
        label: "softToken.activate.step2.title",
    },
    {
        stepNumber: 3,
        id: "confirmation",
        label: "softToken.activate.step3.title",
    }
];
