import { call, put, takeEvery, select } from "redux-saga/effects";

import * as widgets from "middleware/widgets";
import { actions, types, selectors as widgetsSelectors } from "reducers/widgets";
import * as i18n from "util/i18n";

const sagas = [
    takeEvery(types.LIST_REQUEST, listRequest),
    takeEvery(types.LIST_WIDGET_AVAILABLE_NEXT_PAYMENT_REQUEST, listWidgetsAvaibleForNextPaymentRequest),
];

export default sagas;

function* listRequest({ widget, takeDataCache = true }) {
    if (widget === "creditCards" && takeDataCache) {
        const widgetCreditCard = yield select(widgetsSelectors.getWidget, widget);
        if (widgetCreditCard?.data?.creditCards?.length > 0) {
            const mappedCreditCards = mapCreditCards(widgetCreditCard.data.creditCards);
            const map = new Map();
            map.set(widget, mappedCreditCards);
            yield put(actions.setListPayment(map));
            yield put(actions.listSuccess(widget, widgetCreditCard.data));

            return;
        }
    }

    const responseWidget = yield call(widgets.listRequest, widget);

    if (responseWidget.type === "W") {
        yield put(actions.listFailure(widget));
    } else {
        yield put(actions.listSuccess(widget, responseWidget.data.data));
        if (widget === "creditCards") {
            const mappedData = mapCreditCards(responseWidget.data.data.creditCards);
            const map = new Map();
            map.set(widget, mappedData);
            yield put(actions.setListPayment(map));
        }
    }
}

function* listWidgetsAvaibleForNextPaymentRequest({ widget }) {
    const responseWidget = yield call(widgets.listRequest, widget);

    if (responseWidget.type === "W") {
        yield put(actions.listWidgetAvailableNextPaymentFailure(widget));
    } else {
        yield put(actions.listWidgetAvailableNextPaymentSuccess(widget));
        if (widget === "creditCards") {
            const mappedData = mapCreditCards(responseWidget.data.data.creditCards);
            const map = new Map();
            map.set(widget, mappedData);
            yield put(actions.setListPayment(map));
        }
    }
}

function mapCreditCards(creditCards) {
    return creditCards.map((item) => ({
        alias: item.numberMask,
        expirationDate: item.expirationDate,
        nextPaymentDate: item.nextPaymentDate,
        nextPaymentDateStr: item.nextPaymentDateStrDayMonth,
        image: "creditCard",
        type: i18n.get(`nextPayments.desktop.type.creditCard`),
        expirationDateStr: item.expirationDateStrDayMonth,
        idProduct: item.idProduct,
    }));
}

// function mapLoans(loans) {
//     return loans.map((item) => ({
//         alias: item.productAlias.toLowerCase(),
//         expirationDate: item.expirationDateD,
//         expirationDateStr: item.expirationDateStrDayMonth,
//         image: item.categoryIdIcon,
//         idProduct: item.idProduct,
//         overdueStatus: item.overdueStatus,
//     }));
// }
