import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import Sticker from "pages/_components/Sticker";
import Text from "pages/_components/Text";
import { bool, func, shape } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as i18nUtils from "util/i18n";

const PaymentPlans = ({ amountPlans, shouldRender, dispatch }) => {
    const handleClick = (transactionId) => {
        dispatch(push(`/transaction/${transactionId}`));
    };

    if (shouldRender) {
        return (
            <>
                <Text
                    className="mt-8 mb-6"
                    component="h4"
                    size="6"
                    color="primary"
                    labelKey="creditCard.financing.plans.paidPlans"
                    bold
                />
                {amountPlans?.map((plan) => (
                    <Box
                        display="flex"
                        column
                        fullWidth
                        onClick={() => handleClick(plan?.transactionId)}
                        className="mb-6">
                        <Box className="mb-2" display="flex" fullWidth>
                            <Text size="7" addColon color="heading" bold>
                                {i18nUtils.get("creditCard.financing.plans.header.table.reference")} {plan?.id}
                            </Text>
                            <FormattedAmount
                                size="7"
                                quantity={plan?.amount?.quantity}
                                currency={plan?.amount?.currency}
                            />
                        </Box>
                        <Box display="flex" alignX="between" alignY="center" fullWidth>
                            <Text size="7">{plan?.transactionId}</Text>
                            <Sticker
                                status={plan?.status === "FINISHED" ? "success" : "error"}
                                labelKey={`transaction.status.${plan?.status}`}
                                className="ml-3"
                                bold
                            />
                        </Box>
                    </Box>
                ))}
            </>
        );
    }
    return null;
};
PaymentPlans.propTypes = { amountPlans: shape({}).isRequired, shouldRender: bool, dispatch: func.isRequired };
PaymentPlans.defaultProps = { shouldRender: true };

export default connect()(PaymentPlans);
