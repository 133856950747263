import { naturalDate } from "util/format";
import * as config from "util/config";
import { getDisplay, getMobileOS } from "./device";

export const getLastLoginDate = (previousLoginInfo) => {
    if (previousLoginInfo) {
        const { date } = previousLoginInfo;

        return naturalDate(date);
    }
    return null;
};

export const getLastLoginPlace = (previousLoginInfo) => {
    if (previousLoginInfo) {
        const { country, city } = previousLoginInfo;
        if (city && country) {
            return `${city}, ${country}`;
        }
        if (city) {
            return `${city}`;
        }
        if (country) {
            return `${country}`;
        }
    }
    return null;
};

export const getLastLoginPosition = (previousLoginList) => {
    if (previousLoginList && previousLoginList.length > 1) {
        return [previousLoginList[0]];
    }
    return null;
};

export const calculateScoreStrengthPassword = (pass) => {
    let score = 0;
    if (!pass) {
        return score;
    }

    const letters = {};
    for (let i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 8.0 / letters[pass[i]];
    }

    const variations = {
        digits: /\d/.test(pass),
        chars: /[#_?!@$%^&*-]/.test(pass),
    };

    const variationCount = Object.keys(variations).reduce((count, value) => {
        if (variations[value]) {
            return count + 1;
        }
        return count;
    }, 0);

    score += (variationCount - 1) * 10;
    return parseInt(score, 10);
};

/**
 * Function
 * @returns Fu
 */
export const getUrlMarket = () =>
    config.get(getMobileOS(getDisplay()) === "iOS" ? "mobile.market.url.ios" : "mobile.market.url.android");
