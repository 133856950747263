import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Text from "pages/_components/Text";
import Stepper from "pages/_layouts/DefaultStepperLayout/Stepper";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import Container from "pages/_components/Container";
import { arrayOf, func, shape, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { Redirect } from "react-router-dom";
import { actions, selectors } from "reducers/administration/usersInvite";
import { actions as formActions } from "reducers/form";
import { renderAdditionalData, renderName, steps } from "./utils/users";

const UserInviteStep3 = (props) => {
    const handleSubmit = (credentials, formikBag) => {
        const { dispatch, confirmationParams, idTransaction, idActivity } = props;
        if (!idTransaction) {
            dispatch(actions.userInviteConfirm(confirmationParams, credentials, formikBag));
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    const handleBack = () => {
        const { dispatch } = props;
        dispatch(routerActions.goBack());
    };

    const {
        // isDesktop,
        credentialGroups,
        confirmationParams,
        idActivity,
    } = props;

    // if (!isDesktop) {
    //     return <Redirect to="/desktop" />;
    // }

    if (!confirmationParams) {
        return <Redirect to="/administration/users/invite" />;
    }

    return (
        <>
            <Notification scopeToShow="administrationUsersInvite" />
            <Head title="administration.users.invite.title" onBack={handleBack} />
            <Box className="mb-3">
                <Box background="white" borderRadius="lg" className="main-container p-7 pb-7 mt-3 box-shadow-small">
                    <Stepper className="stepper-default mb-5" stepsList={steps} currentStep={3} completed={2} />
                </Box>
            </Box>
            <MainContainer>
                <Box className="border-radius-lg p-7 box-shadow-small background-white">
                    <div className="above-the-fold">
                        <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                            <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2}>
                                <Text component="h4" size="4" bold labelKey="administration.users.invite.subtitle" />
                                <Box background="background-disabled" className="p-5 my-5" borderRadius="lg">
                                    {renderName(`${confirmationParams?.firstName} ${confirmationParams?.lastName}`)}
                                </Box>

                                {renderAdditionalData(
                                    `${confirmationParams?.docType} ${confirmationParams?.docNumber}`,
                                    confirmationParams?.email,
                                    `+${confirmationParams?.prefix} ${confirmationParams?.mobilePhone}`,
                                    confirmationParams?.signatureLevel,
                                    confirmationParams?.groups,
                                    false
                                )}
                            </Col>
                        </Container>
                    </div>
                </Box>
                <Box>
                    <AdministrationFormConfirmation
                        credentialGroups={credentialGroups}
                        onSubmit={handleSubmit}
                        onClickCancel={handleBack}
                        idActivity={idActivity}
                    />
                </Box>
            </MainContainer>
        </>
    );
};

UserInviteStep3.propTypes = {
    dispatch: func.isRequired,
    credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
    confirmationParams: shape({
        docCountry: string,
        docNumber: string,
        docType: string,
        document: string,
        email: string,
        firstName: string,
        lastName: string,
        mobilePhone: string,
        role: string,
        signatureLevel: string,
    }).isRequired,
    idTransaction: string,
    idActivity: string,
    // isDesktop: bool.isRequired,
};

UserInviteStep3.defaultProps = {
    idTransaction: null,
    idActivity: null,
};

const mapStateToProps = (state) => ({
    confirmationParams: selectors.getConfirmationParams(state),
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
});

export default connect(mapStateToProps)(UserInviteStep3);
