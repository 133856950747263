import classNames from "classnames";
import { bool, node, oneOf, string } from "prop-types";
import React from "react";

const Row = ({ className, children, component, fullHeight, gapX, gapY, heightAuto, alignX, alignY, ...props }) => {
    const Component = component;
    return (
        <Component
            className={classNames("row", className, {
                [`gapX-${gapX}`]: gapX,
                [`gapY-${gapY}`]: gapY,
                [`justify-content-${alignX}`]: alignX,
                [`align-items-${alignY}`]: alignY,
                "height-auto": heightAuto,
                "full-height": fullHeight,
            })}
            {...props}>
            {children}
        </Component>
    );
};

Row.propTypes = {
    alignX: string,
    alignY: string,
    children: node.isRequired,
    className: string,
    component: oneOf(["article", "aside", "div", "footer", "header", "main", "nav", "section", "ul", "ol", "li"]),
    fullHeight: bool,
    gapX: string,
    gapY: string,
    heightAuto: bool,
};

Row.defaultProps = {
    alignX: null,
    alignY: null,
    className: null,
    component: "div",
    fullHeight: false,
    gapX: null,
    gapY: null,
    heightAuto: false,
};

export default Row;
