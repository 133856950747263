import React from "react";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { bool } from "prop-types";

const UserBlockedContent = ({ isDesktop, isBankBlock }) => (
    <>
        <Box className="login-blocked-icon">
            <Image src="images/util/error.gif" />
        </Box>
        <Text size={isDesktop ? "3" : "1"} className="mt-8" bold labelKey="login.user.blocked.title" />
        <Text
            className="mt-8 line-height-15 text-center"
            size="6"
            labelKey={isBankBlock ? "login.user.blocked.bank.info" : "login.user.blocked.info"}
        />
        <Button
            className="mt-8"
            href={isBankBlock ? "/" : "/unlockUser/step1"}
            bsStyle="primary"
            label={isBankBlock ? "general.page.userBlockedBank.btn" : "login.user.blocked.button.unlockUser"}
            btnUppercase={false}
            block
        />
    </>
);

UserBlockedContent.defaultProps = {
    isBankBlock: false,
};

UserBlockedContent.propTypes = {
    isBankBlock: bool,
    isDesktop: bool.isRequired,
};

export default UserBlockedContent;
