import { COMPANY_NAME } from "constants.js";
import { bool, func, number as numberType, shape, string } from "prop-types";
import React, { Component } from "react";
import { isMobileNativeFunc } from "util/device";
import { shareSocialText } from "util/download";
import * as i18nUtils from "util/i18n";
import { connect } from "react-redux";
import { actions as notificationActions } from "reducers/notification";
import { push } from "react-router-redux";
import Box from "pages/_components/Box";
import { Link } from "react-router-dom";
import Info from "pages/_components/Info";
import { isDesktop } from "react-device-detect";
import classNames from "classnames";
import FormattedAmount from "pages/_components/FormattedAmount";
import Text from "pages/_components/Text";
import AccountCardView from "./_components/AccountCardView";

class AccountsListItem extends Component {
    static propTypes = {
        amountLabel: string,
        client: shape({}),
        completeFavorites: bool,
        hideAmountLabel: bool,
        idProduct: string,
        number: string.isRequired,
        numberElements: numberType,
        productAlias: string,
        ownerName: string,
        productType: string.isRequired,
        setFavoriteAccount: func,
        showLink: bool,
        showProductIcon: bool,
        showStar: bool,
        showStatusIcon: bool,
        wally: bool,
        status: string.isRequired,
        dispatch: func.isRequired,
        isWidgetDesktop: bool,
        productTypeBackend: string,
        currency: string,
        countableBalance: numberType,
        balance: numberType.isRequired,
        availableCredit: numberType.isRequired,
    };

    static defaultProps = {
        amountLabel: "accounts.availableBalance",
        client: {},
        completeFavorites: false,
        hideAmountLabel: false,
        idProduct: null,
        numberElements: null,
        productAlias: null,
        ownerName: null,
        setFavoriteAccount: () => {},
        showLink: true,
        showProductIcon: true,
        showStar: false,
        showStatusIcon: false,
        wally: false,
        isWidgetDesktop: false,
        productTypeBackend: "",
        currency: "USD",
        countableBalance: 0,
    };

    productType = () => {
        const { productType } = this.props;

        if (productType === "CA") {
            return i18nUtils.get("accounts.productType.savings");
        }

        return i18nUtils.get("accounts.productType.checking");
    };

    getAccountShare = () => {
        const { client, number, productType } = this.props;
        if (!client || !client.name || !number || !productType) {
            return undefined;
        }
        return `${client.name}\n${COMPANY_NAME}\n${i18nUtils.get(`productType.${productType}`)} ${number}`;
    };

    renderItem = () => {
        const {
            idProduct,
            productAlias,
            number,
            productTypeBackend,
            countableBalance,
            balance,
            availableCredit,
            currency,
            ownerName,
        } = this.props;

        return (
            <Box display="flex" alignX="between" alignY="center" className="box-shadow-small border-radius-lg mb-4">
                <Link
                    aria-describedby={idProduct}
                    className="full-width border-radius-lg"
                    to={`/accounts/${idProduct}`}>
                    <Box
                        display={isDesktop ? "flex" : "grid"}
                        alignY="center"
                        fullWidth
                        className="item-account-list p-5 border-radius-lg justify-content-between">
                        <Info
                            className="info-account-list"
                            labelKey="accounts.numberAndType"
                            text={`${productTypeBackend} ${number}`}
                        />
                        <Info
                            className="info-account-list info-account-list-alias"
                            labelKey="accounts.alias"
                            text={ownerName || "-"}
                        />
                        <Info
                            className={classNames("info-account-list", { "text-right": isDesktop })}
                            labelText={`${i18nUtils.get("accounts.countableBalance")} ${currency}`}
                            amount={countableBalance}
                        />
                        {isDesktop && (
                            <Info
                                className={classNames("info-account-list", { "text-right": isDesktop })}
                                labelText={`${i18nUtils.get("accounts.availableBalance")} ${currency}`}
                                amount={balance}
                            />
                        )}
                        <Info
                            className={classNames("info-account-list", { "text-right": isDesktop })}
                            labelText={`${i18nUtils.get("accounts.availableCredit")} ${currency}`}
                            amount={availableCredit}
                        />
                    </Box>
                    {!isDesktop && (
                        <>
                            <Box className="border-top-background-divider-1 ml-5 mr-5 mt-5 p-1" />

                            <Box display="grid" className="item-account-list">
                                <>
                                    <Box className="color-text-grey">
                                        <Text
                                            className="size-6"
                                            labelKey="accounts.availableBalance"
                                            color="text-grey"
                                        />
                                        &nbsp;{currency}
                                    </Box>
                                    <Box className="color-text">
                                        <FormattedAmount
                                            noAmountMargin
                                            bold
                                            currency={currency}
                                            quantity={balance}
                                            noCurrency
                                            size="3"
                                        />
                                    </Box>
                                </>
                            </Box>
                        </>
                    )}
                </Link>
            </Box>
        );
    };

    render() {
        const { idProduct, showLink, numberElements, status, dispatch, isWidgetDesktop } = this.props;

        const transferHref =
            numberElements > 1
                ? `/formCustom/transferInternal?debitAccount=${idProduct}`
                : `/formCustom/transferLocal?debitAccount=${idProduct}`;
        const contextOptions = [
            {
                label: "contextMenu.accounts.widget.options.transfer",
                onClick: () => {
                    if (status !== "ACTIVA") {
                        dispatch(
                            notificationActions.showNotification(
                                i18nUtils.get("accounts.buttons.inactiveAccount"),
                                "error",
                                ["desktop"],
                            ),
                        );
                        return;
                    }
                    dispatch(push(transferHref));
                },
            },
            {
                label: "contextMenu.accounts.widget.options.pay.service",
                onClick: () => {
                    if (status !== "ACTIVA") {
                        dispatch(
                            notificationActions.showNotification(
                                i18nUtils.get("accounts.buttons.inactiveAccount"),
                                "error",
                                ["desktop"],
                            ),
                        );
                        return;
                    }
                    dispatch(push("/servicePayments"));
                },
            },
        ];

        if (isMobileNativeFunc()) {
            contextOptions.push({
                label: "contextMenu.accounts.widget.options.share",
                onClick: () => {
                    const textShared = this.getAccountShare();
                    if (textShared && textShared.length > 0) {
                        shareSocialText(textShared);
                    }
                },
            });
        }

        if (isWidgetDesktop) {
            return <AccountCardView {...this.props} path={showLink ? `/accounts/${idProduct}` : null} />;
        }

        return <>{this.renderItem()}</>;
    }
}

export default connect()(AccountsListItem);
