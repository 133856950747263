import React from "react";
import { number, string } from "prop-types";
import ListItem from "./ListItem";

function firstLetter(text) {
    return text[0].toUpperCase();
}

function ListIndex({ index, array, key = "label" }) {
    if (index === 0 || firstLetter(array[index][key]) !== firstLetter(array[index - 1][key])) {
        return <ListItem className="list-group-first-letter">{firstLetter(array[index][key])}</ListItem>;
    }
    return "";
}

ListIndex.propTypes = {
    index: number,
    array: string,
    key: string,
};

ListIndex.defaultProps = {
    index: 0,
    array: "",
    key: "",
};

export default ListIndex;
