import React from "react";
import { func, shape, string } from "prop-types";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { renderAdditionalDataContacts, renderMainData } from "pages/administration/utils/users";
import Container from "pages/_components/Container";
import { Col } from "react-bootstrap";
import Text from "pages/_components/Text";

const ModifyContactsConfirm = ({ data, idForm, handleBack, handleSubmit }) => {
    const { fullName, emailUpdate, mobileNumberUpdate } = data;

    const renderHeader = () => (
        <>
            <Notification scopeToShow="groupForm" />
            <Head onBack={handleBack} title={`${idForm}.title`} textBack="administration.users.returnToDetail" />
        </>
    );

    return (
        <>
            {renderHeader()}
            <MainContainer className="main-container">
                <Box background="white" borderRadius="lg" className="p-7 mt-5 box-shadow-small">
                    <div className="above-the-fold">
                        <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                            <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2}>
                                <Text component="h4" semibold labelKey="administration.forms.confirm.credentials" />
                                <Box background="background-disabled" className="p-5 my-5" borderRadius="lg">
                                    {fullName && renderMainData(fullName)}
                                </Box>
                                {renderAdditionalDataContacts(emailUpdate, mobileNumberUpdate)}
                            </Col>
                        </Container>
                    </div>
                </Box>
                <Box>
                    <AdministrationFormConfirmation
                        credentialGroups={[
                            { credentials: ["accessToken", "otp"], idCredentialGroup: "accessToken-otp" },
                        ]}
                        onSubmit={handleSubmit}
                        onClickCancel={handleBack}
                        idActivity="administration.medium.modify.contacts.send"
                    />
                </Box>
            </MainContainer>
        </>
    );
};

ModifyContactsConfirm.propTypes = {
    idForm: string.isRequired,
    data: shape({
        email: string,
        mobileNumber: string,
    }).isRequired,
    handleBack: func.isRequired,
    handleSubmit: func.isRequired,
};

export default ModifyContactsConfirm;
