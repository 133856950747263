import { Field, Formik } from "formik";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import Row from "pages/_components/Row";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import Text from "pages/_components/Text";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import * as Yup from "yup";
import { actions as softTokenActions, selectors as softTokenSelectors } from "reducers/softToken";
import classNames from "classnames";
import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import { bool, func, string, shape } from "prop-types";
import Notification from "pages/_components/Notification";
import {
    actions as authenticateHandlerActions,
    selectors as authenticateHandlerSelector,
} from "reducers/authenticateHandler";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import * as i18n from "util/i18n";

const FORM_ID = "softtoken.entrustCode.validation";
const INPUT_LENGTH_CONFIRMATION = 6;
const SoftTokenValidateEntrustCode = (props) => {
    const {
        history,
        dispatch,
        handleBack,
        title,
        description,
        isFetching,
        isDesactivating,
        isShowDesactivationTokenModal,
    } = props;
    const [isFromAuthenticateHandler, setIsFromAuthenticateHandler] = useState(false);

    useEffect(() => {
        if (history.location.state !== null && history.location.state !== undefined) {
            setIsFromAuthenticateHandler(history.location.state.isFromAuthenticateHandler);
        }
    }, []);

    const defaultHandleBack = () => {
        dispatch(routerActions.replace("/desktop"));
    };

    const goBack = () => {
        dispatch(routerActions.goBack());
    };

    const saveRequest = (entrustCode) => {
        if (isFromAuthenticateHandler) {
            return {
                redirectSuccess: "/settings/authenticatorHandler",
                scopeSuccess: ["settings/authenticatorHandler"],
                scopeError: ["settings/authenticatorHandler"],
                credentials: entrustCode,
            };
        }

        return {
            redirectSuccess: "/desktop",
            scopeSuccess: ["desktop", "menu"],
            scopeError: ["desktop", "menu", "softTokenValidateEntrust", "softTokenValidateLocal"],
            credentials: entrustCode,
        };
    };

    const renderHeader = () => (
        <Head
            title={`${FORM_ID}.title`}
            onBack={isFromAuthenticateHandler ? goBack : handleBack || defaultHandleBack}
        />
    );

    const showDesactivationTokenModal = () => {
        dispatch(authenticateHandlerActions.showDesactivationTokenModal());
    };

    const hideDesactivationTokenModal = () => {
        dispatch(authenticateHandlerActions.hideDesactivationTokenModal());
    };

    const desactivateHandleFuncion = () => {
        dispatch(authenticateHandlerActions.desactivateSoftTokenRequest("/settings/authenticatorHandler", true));
    };

    const renderForm = () => (
        <Formik
            initialValues={{ entrustCode: "" }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={() => {
                const requiredMessage = i18n.get(DEFAULT_REQUIRED);
                return Yup.object().shape({
                    entrustCode: Yup.string()
                        .required(requiredMessage)
                        .min(INPUT_LENGTH_CONFIRMATION, requiredMessage),
                });
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                const { entrustCode } = values;
                dispatch(softTokenActions.saveTokenRequest(saveRequest(entrustCode)));
                resetForm({
                    entrustCode: "",
                });
            }}>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="full-height">
                    <Box display="flex" column fullWidth fullHeight>
                        <Box
                            background="white"
                            className={classNames("mt-5 mt-md-0 pt-5 pt-md-8 px-5 pb-8 pb-md-12 mb-8 ml-5 mr-5")}
                            borderRadius="xl">
                            <Row fullHeight>
                                <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                    <Box display="flex" column alignY="around" fullHeight>
                                        <Box display="flex" gap={5} column alignX="center">
                                            <Text
                                                size="2"
                                                color="heading"
                                                component="p"
                                                align="center"
                                                labelKey={title || "softtoken.validation.migrate.local.title.label"}
                                                bold
                                            />
                                            <Text
                                                size="5"
                                                color="heading"
                                                component="p"
                                                align="center"
                                                labelKey={
                                                    description ||
                                                    "softtoken.validation.migrate.local.description.label"
                                                }
                                            />
                                            <Box display="flex" className="mb-md-11" fullWidth>
                                                <Field
                                                    idForm={FORM_ID}
                                                    name="entrustCode"
                                                    component={Credential}
                                                    type="otp"
                                                    labelNoMarginTop
                                                    tooltipLightSyled
                                                    tooltipPosition="left-centered"
                                                />
                                            </Box>
                                            <Button
                                                bsStyle="link"
                                                label={`${FORM_ID}.deactivate.label`}
                                                onClick={() => showDesactivationTokenModal()}
                                            />
                                        </Box>
                                    </Box>
                                </Col>
                            </Row>
                        </Box>
                        <Box className="mt-auto  ml-5 mr-5 mb-10">
                            <Row className="justify-content-center">
                                <Col xs={12} md={8} mdOffset={2} lg={4} lgOffset={4}>
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        label="global.confirm"
                                        loading={isFetching}
                                        block
                                    />
                                    <Button
                                        bsStyle="outline"
                                        label="global.cancel"
                                        block
                                        onClick={() => {
                                            dispatch(
                                                routerActions.replace(
                                                    isFromAuthenticateHandler
                                                        ? "/settings/authenticatorHandler"
                                                        : "/desktop",
                                                ),
                                            );
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Box>
                    </Box>
                </form>
            )}
        </Formik>
    );

    return (
        <>
            <Notification scopeToShow="softTokenValidateEntrust" />
            <TextAndButtonsModal
                modalShow={isShowDesactivationTokenModal}
                acceptFunction={() => {
                    desactivateHandleFuncion();
                }}
                cancelFunction={() => {
                    hideDesactivationTokenModal();
                }}
                confirmLabel="token.drawer.desactivate.button.accept"
                headingText={i18n.get("token.drawer.desactivate.title")}
                text={i18n.get("token.drawer.desactivate.text")}
                loading={isDesactivating}
                modalId="widgets.update.data.modal"
            />
            {renderHeader()} {renderForm()}
        </>
    );
};

const mapStateToProps = (state) => ({
    isFetching: softTokenSelectors.isFetching(state),
    isDesactivating: authenticateHandlerSelector.isDesactivating(state),
    isShowDesactivationTokenModal: authenticateHandlerSelector.showDesactivationTokenModal(state),
});

SoftTokenValidateEntrustCode.propTypes = {
    dispatch: func.isRequired,
    handleBack: func,
    title: string,
    description: string,
    isFetching: bool,
    isFromAuthenticateHandler: bool,
    history: shape({}),
    isDesactivating: bool,
    isShowDesactivationTokenModal: bool,
};

SoftTokenValidateEntrustCode.defaultProps = {
    handleBack: undefined,
    title: "",
    description: "",
    isFetching: false,
    isFromAuthenticateHandler: false,
    history: {},
    isDesactivating: false,
    isShowDesactivationTokenModal: false,
};

export default connect(mapStateToProps)(SoftTokenValidateEntrustCode);
