import React from "react";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import FormattedDate from "pages/_components/FormattedDate";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import { arrayOf, shape, string } from "prop-types";

const ChecksItem = ({ check, onRow, blueStatus, redStatus, grayStatus }) => (
    <Box
        className="row-checks"
        pointer
        onClick={() => {
            onRow(check);
        }}>
        <Row>
            <Col xs="12">
                <Box display="flex">
                    <div
                        className={classNames(
                            "fit-content-width",
                            { "check-status-text": check.status === "P" },
                            { "check-status-info-text": blueStatus.includes(check.status) },
                            { "check-status-err-text": redStatus.includes(check.status) },
                            { "check-status-war-text": grayStatus.includes(check.status) },
                        )}>
                        <span className="data-desc">{i18n.get(`checks.status.description.${check.status}`)}</span>
                    </div>
                </Box>
            </Col>
            <Col xs="6">
                <Box className="col-no-order">
                    <Box className="mb-2 color-text-grey">
                        <I18n id="checks.table.item.number" />
                    </Box>
                    <Box>{check.numberOfCheck}</Box>
                </Box>
            </Col>
            <Col xs="6">
                <Box className="text-right mb-2 color-text-grey">
                    <I18n id="checks.table.item.user" />
                </Box>
                <Box display="flex" alignX="flex-end">
                    {check.user}
                </Box>
            </Col>
            <Col xs="6">
                <Box className="mb-2 color-text-grey">
                    <I18n id="checks.table.item.date" />
                </Box>
                <Box display="flex">
                    <FormattedDate date={check.registrationDate} anotherFormat="DD/MM/YYYY" />
                </Box>
            </Col>
            <Col xs="6">
                <Box className="text-right mb-2 color-text-grey">
                    <I18n id="checks.table.item.amount" />
                </Box>
                <Box display="flex" alignX="flex-end">
                    <FormattedAmount quantity={check.checkAmount} noCurrency className="text-bold" />
                </Box>
            </Col>
        </Row>
    </Box>
);

ChecksItem.propTypes = {
    check: shape({}).isRequired,
    onRow: arrayOf(string).isRequired,
    blueStatus: arrayOf(string).isRequired,
    redStatus: arrayOf(string).isRequired,
    grayStatus: arrayOf(string).isRequired,
};

export default ChecksItem;
