import React, { useState } from "react";
import { Form, Formik } from "formik";
import { bool, func, string } from "prop-types";

import Button from "pages/_components/Button";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import classNames from "classnames";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import { selectors } from "reducers/changeExpiredPassword";
import { selectors as secondFactorSelectors } from "reducers/secondFactor";
import SecondFactor from "pages/secondFactor/SecondFactor";
import { connect } from "react-redux";
import * as Yup from "yup";
import * as i18n from "util/i18n";

const ChangePasswordConfirmation = ({
    idForm,
    isDesktop,
    exchangeToken,
    isFetchingSecondFactor,
    handleSubmit,
    handleCancel,
}) => {
    const [canSubmit, setCanSubmit] = useState(false);

    return (
        <Box fullWidth>
            <Formik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={() =>
                    Yup.object().shape({
                        secondFactor: Yup.string().required(i18n.get("form.credential.otp.required")),
                    })
                }
                initialValues={{
                    secondFactor: "",
                }}
                onSubmit={handleSubmit}>
                {({ setFieldValue, submitForm, isSubmitting }) => (
                    <Form>
                        <Box
                            display="flex"
                            column
                            fullWidth
                            {...(!isDesktop ? { fullHeight: true } : { fullHeight: false })}>
                            {!isFetchingSecondFactor && (
                                <Box className="mb-8">
                                    <Text labelKey={`${idForm}.title`} size="2" bold />
                                </Box>
                            )}
                            <Box display="flex" column className="my-auto pb-7">
                                <SecondFactor
                                    onChangeToken={(tokenCode) => {
                                        if (setFieldValue) {
                                            setFieldValue("secondFactor", tokenCode);
                                            submitForm();
                                        }
                                    }}
                                    onValidSubmit={(validData) => {
                                        setCanSubmit(validData.submit);
                                    }}
                                    withEchangeToken={exchangeToken}
                                    idActivity="session.changeExpiredPassword.step2"
                                    isSubmitting={isSubmitting}
                                />
                            </Box>
                            {!isFetchingSecondFactor && (
                                <Row
                                    gapX="7"
                                    className={classNames("mb-3", {
                                        "d-flex flex-column": !isDesktop,
                                    })}>
                                    <Col xs={6}>
                                        <Button
                                            bsStyle="primary"
                                            label="global.continue"
                                            btnUppercase={false}
                                            disabled={!canSubmit}
                                            block
                                            type="submit"
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <Button
                                            bsStyle="outline"
                                            label="global.cancel"
                                            btnUppercase={false}
                                            block
                                            type="button"
                                            onClick={handleCancel}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
ChangePasswordConfirmation.propTypes = {
    isDesktop: bool.isRequired,
    isFetchingSecondFactor: bool.isRequired,
    idForm: string.isRequired,
    exchangeToken: string.isRequired,
    handleSubmit: func.isRequired,
    handleCancel: func.isRequired,
};
const mapStateToProps = (state) => ({
    exchangeToken: selectors.getExchangeToken(state),
    isFetchingSecondFactor: secondFactorSelectors.isFetching(state),
});
export default connect(mapStateToProps)(ChangePasswordConfirmation);
