import classNames from "classnames";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Box from "pages/_components/Box";
import { bool, string } from "prop-types";
import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";

class Loader extends Component {
    static propTypes = {
        bgTransparent: bool,
        loaderSmallBoxed: bool,
        classicStyle: bool,
        borderRadius: string,
        fixed: string,
    };

    static defaultProps = {
        bgTransparent: false,
        loaderSmallBoxed: false,
        classicStyle: true,
        borderRadius: null,
        fixed: false,
    };

    render() {
        const { bgTransparent, loaderSmallBoxed, classicStyle, borderRadius, fixed,...props } = this.props;
        return (
            <>
                {classicStyle ? (
                    <>
                        <Box
                            {...props}
                            aria-live="polite"
                            className={classNames("preloader", {
                                "background-transparent ": bgTransparent,
                                "boxed-loader": loaderSmallBoxed,
                                [`border-radius-${borderRadius}`]: borderRadius,
                                "p-fixed": fixed,
                            })}>
                            <div className="loader">
                                <div />
                                <div />
                                <I18n id="global.loading" componentProps={{ className: "visually-hidden" }} />
                            </div>
                        </Box>
                        {loaderSmallBoxed && <Box className="loader-overlay" />}
                    </>
                ) : (
                    <BrowserRouter>
                        <>
                            <Box
                                display="flex"
                                alignX="center"
                                alignY="cenetr"
                                className={classNames("preloader icon-loader p-9", {
                                    "background-transparent ": bgTransparent,
                                })}>
                                <Image src="images/logos/logo-bolivariano.svg" />
                            </Box>
                            {loaderSmallBoxed && <Box className="loader-overlay" />}
                        </>
                    </BrowserRouter>
                )}
            </>
        );
    }
}

export default Loader;
