import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import Text from "pages/_components/Text";
import { bool, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { channelsSelectors } from "reducers/administration";
import { selectors as sessionSelectors } from "reducers/session";
import * as configUtils from "util/config";

class CapsList extends Component {
    static propTypes = {
        activeEnvironment: shape({ type: string }).isRequired,
        caps: shape({
            amount: number,
            frequency: string,
            currency: string,
        }).isRequired,
        currency: shape({
            id: string,
        }).isRequired,
        topAmount: shape({
            amount: number,
            frequency: string,
            maximum: number,
        }),
        hasDefaultLabel: bool,
        isConfirmationStep: bool,
        isInTicket: bool,
    };

    static defaultProps = {
        hasDefaultLabel: false,
        topAmount: null,
        isConfirmationStep: false,
        isInTicket: false,
    };

    renderCaps = () => {
        const {
            activeEnvironment: { type },
            caps,
            hasDefaultLabel,
            isConfirmationStep,
            isInTicket,
        } = this.props;

        return (
            <Box component="section">
                <Box display="flex" gap={isConfirmationStep ? "4" : "5"}>
                    {Object.entries(caps)
                        .filter(([channel]) => channel !== "all")
                        .map(([channel, { amount, frequency, currency }]) => (
                            <Box
                                display="flex"
                                background="background-primary"
                                className="py-4 px-5 my-auto"
                                borderRadius="xxl"
                                gap="3"
                                fitWidth>
                                <Text labelKey={`channels.${channel}`} color="primary-active-color" size="6" light />
                                <Box display="flex">
                                    <FormattedAmount
                                        className="color-primary-active-color size-6 text-light"
                                        quantity={
                                            amount === undefined
                                                ? parseFloat(configUtils.get(`default_cap_user_${type}`))
                                                : amount
                                        }
                                        currency={currency}
                                        size="6"
                                        noAmountMargin
                                    />
                                    &nbsp;
                                    <Text
                                        labelKey={`administration.channels.${frequency}`}
                                        color="primary-active-color"
                                        size="6"
                                        light
                                    />
                                </Box>
                            </Box>
                        ))}
                </Box>
            </Box>
        );
    };

    render() {
        const { currency = {}, isConfirmationStep, caps, isInTicket, topAmount } = this.props;

        const allAmount = caps?.all?.amount || topAmount?.maximum;
        const frequency = caps?.all?.frequency || topAmount?.frequency;

        let hasLimits;
        if (Object.keys(caps).length > 0) {
            hasLimits = Object.values(caps).reduce(
                (accumulator, currentValue) => accumulator && currentValue.amount >= 0,
                true,
            );
        }

        return !hasLimits ? (
            <Text labelKey="administration.user.details.limits.empty" size="6" align="center" className="px-5" />
        ) : (
            <>{this.renderCaps()}</>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    caps: channelsSelectors.getCaps(state),
    currency: channelsSelectors.getCurrencies(state)[0],
    topAmount: channelsSelectors.getTopAmount(state),
});

export default connect(mapStateToProps)(CapsList);
