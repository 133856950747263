import { shape, string, func, bool } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import withRouter from "react-router-dom/withRouter";
import { compose } from "redux";
import { goBack, replace } from "react-router-redux";
import MainContainer from "pages/_components/MainContainer";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import {
    selectors as beneficiarySelectors,
    actions as beneficiaryActions,
} from "reducers/frequentDestination/frequentDestination";
import * as i18n from "util/i18n";
import Row from "pages/_components/Row";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import classNames from "classnames";
import { routerActions } from "react-router-redux/actions";
import ConfirmDialog from "pages/_components/modal/ConfirmDialog";
import { useRevelockPositionProvider } from "providers/RevelockPositionProvider";
import { selectors as sessionSelectors } from "reducers/session";
import { formatCreditCardNumber } from "util/creditCards";

const ID_ACTIVITY = "beneficiary.view";
const ViewBeneficiary = ({
    isDesktop,
    match,
    dispatch,
    currentBeneficiary,
    isFetching,
    isFetchingDelete,
    activeEnvironment,
}) => {
    const { idBeneficiary } = match.params;
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    /**
     * Set location revelock
     */
    const { setLocationCustom } = useRevelockPositionProvider();
    const setRevelockLocation = () => {
        if (!setLocationCustom) {
            return;
        }
        setLocationCustom(match);
    };

    useEffect(() => {
        if (!idBeneficiary || idBeneficiary === "") {
            dispatch(replace({ pathname: "/desktop", state: { transition: "transition-flow-close" } }));
        }
        dispatch(beneficiaryActions.getCurrentBeneficiary(idBeneficiary));
        setRevelockLocation();
    }, []);

    const redirectEditUser = () => {
        dispatch(
            routerActions.push({
                pathname: `/editFrequentDestination/${idBeneficiary}/${currentBeneficiary?.type || ""}`,
                state: { transition: "transition-flow-close" },
            }),
        );
    };

    const deleteBeneficiary = () => {
        if (!idBeneficiary || idBeneficiary === "" || !currentBeneficiary.type) {
            return;
        }
        dispatch(beneficiaryActions.deleteBeneficiary(idBeneficiary, currentBeneficiary.type));
    };
    const renderHeaderPage = () => (
        <Head
            hideMenu
            titleText={i18n.get(`${ID_ACTIVITY}.title`)}
            onBack={() => {
                dispatch(goBack());
            }}
            disableBackButton={false}
        />
    );

    const renderItemDetail = (title, value, isCapitalize = true) => (
        <Box display="flex" gapX="3" alignX="between" className="mb-4">
            <Box display="flex" alignX="flex-start">
                <Text size="5" color="heading" ellipsis bold>
                    {title || ""}:
                </Text>
            </Box>

            <Box display="flex" alignX="flex-end" withEllipsis>
                <Text size="5" color="heading" wrap align="right" ellipsis capitalize={isCapitalize}>
                    {(value || "").toLowerCase()}
                </Text>
            </Box>
        </Box>
    );
    const renderDetailBeneficiary = () => {
        if (!currentBeneficiary) {
            return <div />;
        }
        const { name, type, productType, productNumber, bankName, email } = currentBeneficiary;
        return (
            <>
                {renderItemDetail(
                    i18n.get(`${ID_ACTIVITY}.beneficiaryType.label`),
                    i18n.get(`beneficiary.type.${(type || "").toLowerCase()}.label`),
                )}
                {renderItemDetail(i18n.get(`${ID_ACTIVITY}.beneficiaryBankName.label`), bankName)}
                {renderItemDetail(
                    i18n.get(`${ID_ACTIVITY}.beneficiaryProductType.label`),
                    i18n.get(
                        `beneficiary.productType.${(type || "").toLowerCase()}.${(
                            productType || ""
                        ).toLowerCase()}.label`,
                    ),
                )}
                {renderItemDetail(
                    i18n.get(`${ID_ACTIVITY}.beneficiaryProductNumber.label`),
                    productType === "TC" ? formatCreditCardNumber(productNumber) : productNumber,
                )}
                {renderItemDetail(i18n.get(`${ID_ACTIVITY}.beneficiaryName.label`), name)}
                {email && renderItemDetail(i18n.get(`${ID_ACTIVITY}.beneficiaryEmail.label`), email, false)}
            </>
        );
    };

    const renderButtons = () => (
        <Box display="flex" alignX="center" fullWidth {...(!isDesktop && { className: "mt-auto" })}>
            {activeEnvironment.permissions.beneficiaryManageToken ? (
                <Row>
                    <Col sm={12} md={4} mdOffset={2} lg={3} lgOffset={3}>
                        <Button
                            block
                            type="button"
                            label="global.delete"
                            bsStyle="outline"
                            onClick={() => {
                                setShowDeleteConfirm(true);
                            }}
                            loading={isFetchingDelete}
                        />
                    </Col>
                    <Col
                        sm={12}
                        md={4}
                        lg={3}
                        className={classNames({
                            "grid-reversed": !isDesktop,
                        })}>
                        <Button
                            block
                            label="global.edit"
                            bsStyle="primary"
                            onClick={() => {
                                redirectEditUser();
                            }}
                        />
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col sm={12} md={4} mdOffset={4} lg={4} lgOffset={4}>
                        <Button
                            block
                            type="button"
                            label="global.delete"
                            bsStyle="outline"
                            onClick={() => {
                                setShowDeleteConfirm(true);
                            }}
                            loading={isFetchingDelete}
                        />
                    </Col>
                </Row>
            )}
        </Box>
    );

    return (
        <>
            {renderHeaderPage()}
            <MainContainer showLoader={isFetching}>
                <Box display="flex" column className="pb-9" {...(!isDesktop && { fullHeight: true })}>
                    {currentBeneficiary && (
                        <Row className="height-auto">
                            <Col xs={12} md={8} mdOffset={2}>
                                <Box
                                    background="white"
                                    borderRadius="default"
                                    className="mt-5 mt-md-0 py-9 py-md-12 px-5 px-md-12 mb-7">
                                    {renderDetailBeneficiary()}
                                </Box>
                            </Col>
                        </Row>
                    )}
                    {renderButtons()}
                </Box>
            </MainContainer>
            <ConfirmDialog
                showDialog={showDeleteConfirm}
                title={i18n.get(`${ID_ACTIVITY}.delete.confirm.title`)}
                description={i18n.get(`${ID_ACTIVITY}.delete.confirm.description`)}
                onConfirm={() => {
                    deleteBeneficiary();
                }}
                onCancel={() => {
                    setShowDeleteConfirm(false);
                }}
                modalId="beneficiary.delete.modal"
                loading={isFetchingDelete}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    currentBeneficiary: beneficiarySelectors.getBeneficiary(state),
    isFetching: beneficiarySelectors.isFetching(state),
    isFetchingDelete: beneficiarySelectors.isFetchingDelete(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

ViewBeneficiary.propTypes = {
    isDesktop: string.isRequired,
    location: shape({}).isRequired,
    dispatch: func.isRequired,
    currentBeneficiary: shape({}).isRequired,
    isFetching: bool,
    match: shape({}).isRequired,
    isFetchingDelete: bool,
    activeEnvironment: shape({}).isRequired,
};

ViewBeneficiary.defaultProps = {
    isFetchingDelete: false,
    isFetching: false,
};

export default compose(connect(mapStateToProps), withRouter)(ViewBeneficiary);
