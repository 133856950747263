import classNames from "classnames";
import Box from "pages/_components/Box";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import MaskedInput from "react-text-mask";
import { ReactComponent as Show } from "styles/images/search.svg";
import * as i18nUtils from "util/i18n";

const TextSimpleField = (props) => {
    const {
        className,
        disabled,
        error,
        field,
        form,
        isRequired,
        labelKey,
        maxLength,
        name,
        onChange,
        pattern,
        placeholder,
        serarchStyle,
        tooltipText,
        type,
        useMaskedInput,
        value,
    } = props;

    const fieldHasError = (subfield) => {
        if (error) {
            return true;
        }
        if (Object.keys(form).length > 0) {
            const { errors } = form;
            return field.name === subfield ? errors[`${field.name}`] : errors[`${field.name}.${subfield}`];
        }
        return null;
    };

    const renderError = (subfield) => {
        if (error) {
            return <FieldError error={error} />;
        }
        if (Object.keys(form).length > 0) {
            const { errors } = form;

            if (fieldHasError(subfield)) {
                return (
                    <FieldError
                        error={field.name === subfield ? errors[`${field.name}`] : errors[`${field.name}.${subfield}`]}
                    />
                );
            }
        }
        return null;
    };

    const creditCardFormat = (fieldValue) => {
        if (fieldValue.charAt(0) === "4" || fieldValue.charAt(0) === "5" || fieldValue.charAt(0) === "") {
            return [
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ];
        }
        if (fieldValue.charAt(0) === "3") {
            return [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/];
        }

        // Necessary to accept the input after the first value

        return [/ /];
    };

    const handleCopy = (event) => {
        const text = event.target.value;
        const textWithoutSpaces = text.replace(/\s/g, "");
        event.clipboardData.setData("text/plain", textWithoutSpaces);
        event.preventDefault();
    };

    return (
        <>
            <Box
                className={classNames("form-group", className, {
                    "has-error": fieldHasError(name),
                })}>
                {labelKey ? (
                    <FieldLabel
                        optional={isRequired ? "" : i18nUtils.get("forms.optional")}
                        idField={name}
                        labelKey={labelKey}
                        tooltipText={tooltipText}
                        labelNoMarginTop
                    />
                ) : null}
                <Box className="input-group">
                    {serarchStyle && (
                        <Box
                            className="pl-4"
                            display="flex"
                            alignX="center"
                            alignY="center"
                            position="absolute"
                            fullHeight>
                            <Show className="svg-image" />
                        </Box>
                    )}
                    {useMaskedInput ? (
                        <MaskedInput
                            id={name}
                            className="form-control"
                            type={type}
                            name={Object.keys(field).length > 0 ? `${field.name}_${name}` : name}
                            placeholder={placeholder}
                            {...(value && { value })}
                            {...(onChange && { onChange: (e) => onChange(e, name) })}
                            disabled={disabled}
                            mask={creditCardFormat(value)}
                            guide={false}
                            onCopy={handleCopy}
                        />
                    ) : (
                        <input
                            id={name}
                            className={classNames("form-control", {
                                "pl-9 position-relative size-5": serarchStyle,
                            })}
                            type={type}
                            name={Object.keys(field).length > 0 ? `${field.name}_${name}` : name}
                            placeholder={placeholder}
                            {...(onChange && { onChange: (e) => onChange(e, name) })}
                            {...(value && { value })}
                            {...(maxLength && { maxLength })}
                            pattern={pattern}
                            disabled={disabled}
                            autoComplete="off"
                        />
                    )}
                </Box>
                {renderError(name)}
            </Box>
        </>
    );
};

TextSimpleField.propTypes = {
    className: bool,
    disabled: bool,
    error: string,
    field: shape({}),
    form: shape({}),
    isRequired: bool,
    labelKey: string,
    maxLength: string,
    name: string.isRequired,
    onChange: func.isRequired,
    pattern: string,
    placeholder: string,
    serarchStyle: bool,
    tooltipText: string,
    type: string,
    useMaskedInput: bool,
    value: string.isRequired,
};

TextSimpleField.defaultProps = {
    className: false,
    disabled: false,
    error: null,
    field: {},
    form: {},
    isRequired: true,
    labelKey: null,
    maxLength: null,
    pattern: null,
    placeholder: "",
    serarchStyle: false,
    tooltipText: null,
    type: "text",
    useMaskedInput: false,
};

export default TextSimpleField;
