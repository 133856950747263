import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { element, func } from "prop-types";
import React, { Component } from "react";
import * as i18n from "util/i18n";
import { INSERT_SECURITY_QUESTION, UPDATE_SECURITY_QUESTION } from "constants.js";
import WithPermissions from "./WithPermissions";

class UserLink extends Component {
    static propTypes = {
        children: element,
        onSelect: func,
        dispatch: func.isRequired,
        actionDone: func,
    };

    static defaultProps = {
        children: null,
        onSelect: null,
        actionDone: () => {},
    };

    goToSettings = () => {
        const { actionDone, onSelect, dispatch } = this.props;
        actionDone();
        if (onSelect) {
            onSelect();
        }

        dispatch(push("/settings"));
    };

    goToSecurityQuestions = () => {
        const { actionDone, onSelect, dispatch } = this.props;
        actionDone();
        if (onSelect) {
            onSelect();
        }

        dispatch(push("/securityQuestions"));
    };

    goToVirtualKey = () => {
        const { actionDone, onSelect, dispatch } = this.props;
        actionDone();
        if (onSelect) {
            onSelect();
        }

        dispatch(push("/settings/authenticatorHandler"));
    };

    render() {
        const { children } = this.props;

        return (
            <>
                <Box display="flex" className="popup-top" alignX="center" column>
                    <Text color="text-boton" size="5" bold>
                        {i18n.get("settings.link.title")}
                    </Text>
                </Box>
                <div className="separation-line" />
                <Button bsStyle="link" onClick={this.goToSettings} className="config-btn px-0" secondary block>
                    {i18n.get("settings.index")}
                </Button>
                <WithPermissions permissions={[UPDATE_SECURITY_QUESTION, INSERT_SECURITY_QUESTION]} somePermissions>
                    <Button
                        bsStyle="link"
                        onClick={this.goToSecurityQuestions}
                        className="config-btn px-0"
                        secondary
                        block>
                        {i18n.get("settings.securityQuestions")}
                    </Button>
                </WithPermissions>
                <Button bsStyle="link" onClick={this.goToVirtualKey} className="config-btn px-0" secondary block>
                    {i18n.get("settings.softToken")}
                </Button>

                {children}
            </>
        );
    }
}

export default connect()(UserLink);
