import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedDate from "pages/_components/FormattedDate";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";

class TemplateListItem extends Component {
    static propTypes = {
        item: shape({
            name: string.isRequired,
        }).isRequired,
        isDesktop: bool.isRequired,
        onDelete: func.isRequired,
        onClick: func.isRequired,
        num: number.isRequired,
    };

    handleClick = (event) => {
        const { item, onDelete } = this.props;
        event.stopPropagation();
        onDelete(item);
    };

    render() {
        const { item, num, onClick, isDesktop } = this.props;
        return (
            <Box
                component="li"
                className="px-5 py-3 mb-5 template-list-item"
                background="white"
                border="inset-background-divider"
                borderRadius="default"
                display="flex"
                alignY="center"
                alignX="between"
                fullWidth
                onClick={() => onClick(num)}
                pointer
                flex1>
                <Box flex1 className="template-list-item-description">
                    <Text size="6" bold color="secondary" ellipsis>
                        {item.name}
                    </Text>
                </Box>

                <Box flex1 display="flex" alignX="center">
                    <Text size={isDesktop ? "7" : "6"} color="heading">
                        <FormattedDate date={item.creationDate} />
                    </Text>
                </Box>

                <Box flex1 display="flex" alignX="flex-end">
                    <Button
                        className="view-close template-list-item-close"
                        onClick={this.handleClick}
                        image="images/cross.svg"
                        bsStyle="link"
                        small
                    />
                </Box>
            </Box>
        );
    }
}

export default resizableRoute(TemplateListItem);
