import React, { useEffect, useState } from "react";
import withRouter from "react-router-dom/withRouter";
import { compose } from "redux";
import { connect } from "react-redux";
import { bool, func, number, shape, string } from "prop-types";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as templateSelectors } from "reducers/template";
import { Field } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import RadioButtonCustom from "pages/forms/customForms/_customFields/RadioButtonCustom";
import * as i18n from "util/i18n";
import { Buffer } from "buffer";
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from "uuid";
import {
    selectors as transactionLinesSelectors,
    actions as transactionLinesActions,
} from "reducers/form/transactionLines";
import ShowInfoFile from "pages/forms/customForms/_customFields/ShowInfoFile";
import * as Yup from "yup";
import { validationText } from "util/validationSchemaUtil";
import FormTransition from "../_components/FormTransition";
import { LOCAL_BANK } from "./ModalManualRole";

const ID_FORM = "role.payment";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

const RolePayment = (props) => {
    const { dispatch, location, mode, fromTransaction, transaction } = props;
    const MANUAL_TYPE = "inputManually";
    const FILE_TYPE = "file";

    const optionUploadPayment = [
        { key: FILE_TYPE, label: "forms.role.payment.fileType.label", value: FILE_TYPE },
        { key: MANUAL_TYPE, label: "forms.role.payment.manualType.label", value: MANUAL_TYPE },
    ];

    const [uploadType, setUploadType] = useState(FILE_TYPE);

    useEffect(() => {
        if (mode === "edit" || mode === "view") {
            const formData = { debitAccount: null };

            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
            if (mode === "view" && !fromTransaction) {
                dispatch(
                    transactionLinesActions.listTransactionLinesRequest({
                        id: transaction.idTransaction,
                        pageNumber: 1,
                    }),
                );
            }
        }
        // eslint-disable-next-line
    }, [dispatch, location]);

    useEffect(() => {
        if (mode === "view" && !fromTransaction) {
            dispatch(
                transactionLinesActions.listTransactionLinesRequest({
                    id: transaction.idTransaction,
                    pageNumber: 1,
                }),
            );
        }
        // eslint-disable-next-line
    }, [mode]);

    const validationSchemaFile = Yup.object().shape({
        uploadBy: validationText(),
        file: validationText(`forms.${ID_FORM}.message.uploadFileRole`),
    });
    const validationSchemaManually = Yup.object().shape({
        uploadBy: validationText(),
        inputManually: validationText(`forms.${ID_FORM}.message.addItemRole`).nullable(),
    });

    const [validationSchema, setValidationSchema] = useState(validationSchemaFile);

    const mapValidationField = new Map();
    mapValidationField.set(FILE_TYPE, validationSchemaFile);
    mapValidationField.set(MANUAL_TYPE, validationSchemaManually);

    /**
     * Util Functions
     */

    const updateAmountField = (amountTotal, currency, setFieldValue) => {
        if (setFieldValue && currency) {
            setFieldValue("amount", { currency, quantity: amountTotal });
        }
    };
    const setUploanTypeCustom = (setFieldValue, uploanTypeCustom) => {
        if (uploanTypeCustom) {
            setFieldValue("uploadBy", uploanTypeCustom);
            setFieldValue("file", []);
        }
    };
    const selectUploadType = (setFieldValue, uploadTypeCustom) => {
        setUploadType(uploadTypeCustom);
        setValidationSchema(mapValidationField.get(uploadTypeCustom));
        if (setFieldValue && uploadTypeCustom === FILE_TYPE && !fromTransaction) {
            setFieldValue("inputManually", null);
        }
    };

    const processFileToObject = (fileString) => {
        const dataDecodedBuffer = Buffer.from(fileString, "base64");
        if (!dataDecodedBuffer) {
            return {};
        }
        const dataDecoded = dataDecodedBuffer.toString("ascii");
        const splitLine = dataDecoded.split(/\r?\n/);
        if (!splitLine) {
            return {};
        }

        const header = splitLine[0];
        const headerSplit = header.split(",");

        const result = {
            idFileProcess: headerSplit[0],
            currency: headerSplit[1],
        };
        const itemArray = splitLine.slice(1).map((item) => {
            const splitItem = item.split(",");
            const itemData = {
                id: uuidv4(),
                creditAccountNumber: splitItem[0],
                creditAmountCurrency: splitItem[1],
                creditAmountQuantity: Number(splitItem[2]),
                creditAccountName: splitItem[3],
            };
            if (splitItem.length >= 5) {
                itemData.bankIdentifier = splitItem[4];
            }

            return itemData;
        });

        result.items = itemArray;

        return result;
    };

    const [infoErrorFile, setInfoErrorFile] = useState({});

    const processFileUpload = (setFieldValue, fileData) => {
        if (!fileData || !fileData.fileContent) {
            return;
        }
        const resultProcessFile = processFileToObject(fileData.fileContent);
        setFieldValue("inputManually", resultProcessFile.items);
        setInfoErrorFile(fileData);
    };

    /**
     * Render Pages
     */

    const renderEditPage = (genericProps, setFieldValue) => {
        const { preDataForm, totalAmount, totalLines } = props;
        const preData = preDataForm || {
            debitAccountList: [],
            currencyList: [],
            bankList: [],
        };

        const accountOptions = {
            options: preData.debitAccountList?.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
                disabled: acc.isDisabled,
            })),
        };

        const optionCurrencyList = preData.currencyList?.map((item) => ({
            currency: item.backendId,
            label: item.code,
        }));

        // const defaultValue = { currency: optionCurrencyList[0]?.currency };

        const fileListMap = {
            en: {
                idFile: 2,
                fileName: "sampleFile.txt",
                contents: null,
                idForm: "salaryPayment",
                formVersion: 1,
                idField: "sampleFile",
                lang: "en",
            },
            es: {
                idFile: 1,
                fileName: "archivoEjemplo.txt",
                contents: null,
                idForm: "salaryPayment",
                formVersion: 1,
                idField: "sampleFile",
                lang: "es",
            },
        };

        const optionList = preData.bankList || [];
        const optionBankList = optionList.map((item) => ({ id: item.code, label: item.bankName }));
        optionBankList.splice(0, 0, { id: LOCAL_BANK, label: "Banco Cliente" });

        return (
            <>
                {mode === "edit" && <p className="text-lead">{i18n.get("forms.role.payment.titleUpload.label")}</p>}

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.ProductselectorCustom}
                    data={accountOptions}
                    key="debitAccount"
                    name="debitAccount"
                    renderAs="combo"
                    value={preData.debitAccountList[0]?.idProduct || ""}
                    idField="debitAccount"
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Text}
                    key="commentary"
                    name="commentary"
                    idField="commentary"
                    validationRegularExpresion="^[a-zA-Z0-9 áéíóúñ]*$"
                />

                <Field
                    {...genericProps}
                    component={RadioButtonCustom}
                    options={optionUploadPayment}
                    key="uploadBy"
                    name="uploadBy"
                    idField="uploadBy"
                    isRequired={false}
                    onChange={(uploadOption) => {
                        selectUploadType(setFieldValue, uploadOption);
                    }}
                    value={optionUploadPayment[0].value}
                    genericPropRender={{ column: "column", alignY: "top" }}
                />
                {mode === "edit" && (
                    <div className="form-group-text">
                        <span>{i18n.get("forms.role.payment.indicationUpload.label")}</span>
                    </div>
                )}
                {mode === "edit" && <hr />}

                {/**
                 * UPLOAD FILE TYPE
                 */}
                {mode === "edit" && uploadType === FILE_TYPE ? (
                    <div className="form-section-title">
                        <h3 className="section-title">{i18n.get("forms.role.payment.fileProcess.label")}</h3>
                    </div>
                ) : (
                    <div />
                )}

                {uploadType === FILE_TYPE && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Filelink}
                        key="fileLink"
                        name="fileLink"
                        idField="fileLink"
                        fileListMap={fileListMap}
                        singleLabel={i18n.get("forms.role.payment.indicationDownload.label")}
                    />
                )}

                {uploadType === FILE_TYPE && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Inputfile}
                        key="file"
                        name="file"
                        idField="file"
                        idActivity={ID_ACTIVITY}
                        acceptedFileTypes={["text/plain"]}
                        maxFileSizeMB={4}
                        allowMultiple={false}
                        allowImagePreview
                        maxTotalFileSize={4}
                        maxFiles={1}
                        handleFileProcess={(fileData) => processFileUpload(setFieldValue, fileData)}
                    />
                )}

                {
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.ModalSelectCustom}
                        key="inputManually"
                        name="inputManually"
                        idField="inputManually"
                        optionCurrencyList={optionCurrencyList}
                        value={[]}
                        currency={optionCurrencyList[0]?.currency}
                        onChangeManual={() => {
                            setUploanTypeCustom(setFieldValue, MANUAL_TYPE);
                        }}
                        uploadType={uploadType}
                        optionBankList={optionBankList}
                        genericProps={genericProps}
                        infoErrorFile={infoErrorFile}
                        onChangeAmount={(amountTotal, currency) => {
                            updateAmountField(amountTotal, currency, setFieldValue);
                        }}
                    />
                }

                {!fromTransaction && mode === "view" && uploadType === FILE_TYPE && (
                    <ShowInfoFile
                        {...genericProps}
                        uploadType={uploadType}
                        mode={mode}
                        dispatch={dispatch}
                        currencyCustom={totalAmount?.currency || ""}
                        amount={totalAmount?.quantity || 0}
                        lines={totalLines || 0}
                    />
                )}
            </>
        );
    };

    const renderFields = (setFieldValue) => {
        const { currentLang, fromBackoffice } = props;
        const idTransaction = transaction?.idTransaction;

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
            dispatch,
        };

        return <>{renderEditPage(genericProps, setFieldValue)}</>;
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
        // eslint-disable-next-line react/prop-types
    } = props.preData || {};

    const formProps = {
        title: "forms.role.payment.formName",
        metadata: {
            draftsEnabled: true,
            templatesEnabled: true,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },

        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validationSchema: () => validationSchema,
    };
    return <FormTransition {...props} {...formProps} />;
};

RolePayment.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: Boolean,
    isDesktop: bool,
    radioButtonOption: string,
    postData: shape({}),
    totalAmount: shape({}),
    totalLines: number,
};
RolePayment.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: null,
    previewData: null,
    transaction: null,
    location: {},
    fromTransaction: false,
    isDesktop: false,
    radioButtonOption: "",
    postData: {},
    totalAmount: {},
    totalLines: 0,
};
const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    postData: formSelectors.getData(state),
    paymentCurrency: transactionLinesSelectors.getCurrency(state),
    transactionLines: transactionLinesSelectors.getEditedLines(state),
    totalAmount: transactionLinesSelectors.getTotalAmountTransaction(state),
    totalLines: transactionLinesSelectors.getTotalLines(state),
});
export default compose(connect(mapStateToProps), withRouter)(RolePayment);
