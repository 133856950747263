import DepositAction from "pages/deposits/_components/DepositAction";
import Box from "pages/_components/Box";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import WithPermissions from "pages/_components/WithPermissions";
import { arrayOf, func, number, shape } from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";

import {
    actions as depositsActions,
    selectors as depositsSelectors,
    selectors as depositSelectors,
} from "reducers/deposits";
import { selectors as loginSelectors } from "reducers/login";
import { selectors as productSelectors } from "reducers/products";
import * as configUtil from "util/config";
import { bool, string } from "yup";
import { getLocationBasePath } from "util/revelock";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import classNames from "classnames";
import * as stringUtils from "util/string";
import * as Tabs from "./_components/tabs";
import DepositsTabs from "./DepositsTabs";
import DetailHeader from "./DetailHeader";

const PAGE_IDENTIFIER = "deposits.read";
class DepositDetails extends Component {
    state = {
        downloading: false,
        currentTabIndex: 0,
    };

    componentDidMount() {
        const { deposit, dispatch, fullList, ...rest } = this.props;
        dispatch(depositsActions.readDeposit(rest.match.params.id));
        dispatch(depositsActions.movementDetails(rest.match.params.id));

        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath, match } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath(getLocationBasePath(match), PAGE_IDENTIFIER);
        }
    };

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(depositsActions.toggleOptions());
    };

    toolbarChildren = () => {
        const { isDesktop } = this.props;

        return [
            <WithPermissions permissions={["depositCreate"]}>
                <DepositAction isDesktop={isDesktop} key="new" labelKey="deposits.new" to="/formCustom/createDeposit" />
            </WithPermissions>,
        ];
    };

    getSecondaryBtn = () => {
        const { dispatch, deposit, fetchingDownloadCertified } = this.props;
        return [
            {
                loading: fetchingDownloadCertified,
                bsStyle: "outline",
                label: `deposit.detail.button.reprint`,
                onClick: () => {
                    dispatch(depositsActions.downloadCertified(deposit));
                },
            },
        ];
    };

    onFinishDownload = () => {
        this.setState({
            downloading: false,
        });
    };

    handleClickDownload = (format, tabIndex) => {
        const { dispatch, match } = this.props;
        this.setState({
            downloading: true,
        });
        dispatch(depositsActions.downloadMovements(match.params.id, format, tabIndex, this.onFinishDownload));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/deposits"));
    };

    renderItem = (item) => {
        const Tab = Tabs[stringUtils.capitalizeFirstLetter(item.id)];
        const { isDesktop } = this.props;

        return (
            <Box
                key={item.id}
                iconLabel={item.icon}
                showIconAllways
                isDesktop={isDesktop}
                keyLabel={item.label ? item.label : `deposits.widget.${item.id}`}>
                <Tab isDesktop={isDesktop} />
            </Box>
        );
    };

    onChangeTab = (index) => {
        this.setState({ currentTabIndex: index });
    };

    renderTabs = () => {
        const { isDesktop, deposit } = this.props;

        const items = [
            { id: "depositResume", icon: "images/products/icon_tab_RI.svg", label: "deposits.widget.resume" },
            { id: "depositDetail", icon: "images/products/icon_tab_DI.svg", label: "deposits.widget.detail" },
            {
                id: "depositLastMovements",
                icon: "images/products/icon_tab_UM.svg",
                label: "deposits.widget.lastmovements",
            },
        ];
        const depositId = deposit?.numero;

        return (
            <Box
                className={classNames("background-white", {
                    "px-7 py-5 box-shadow-small border-radius-lg": isDesktop,
                    "px-5 mx-n-5 pt-0 d-flex flex flex-column full-height": !isDesktop,
                })}>
                <DetailHeader depositId={depositId} status={deposit?.estado} isMobile={!isDesktop}>
                    {" "}
                </DetailHeader>
                <DepositsTabs onSelect={this.onChangeTab}>{items.map((item) => this.renderItem(item))}</DepositsTabs>
            </Box>
        );
    };

    renderHeader = () => {
        const { deposit, isDesktop } = this.props;
        const { downloading, currentTabIndex } = this.state;

        if (deposit === undefined || Object.keys(deposit).length === 0) {
            return <></>;
        }

        const documents = [
            {
                label: "global.pdfFile",
                onClick: () => this.handleClickDownload("pdf", currentTabIndex),
            },
            {
                label: "global.xlsFile",
                onClick: () => this.handleClickDownload("xls", currentTabIndex),
            },
            {
                label: "global.txtFile",
                onClick: () => this.handleClickDownload("txt", currentTabIndex),
            },
        ];

        return (
            <Head
                onBack={this.handleBack}
                title={`deposits.myDeposits.${deposit.tipoProducto}.header.title`}
                exportList={documents}
                exportListBsStyle={isDesktop ? "download" : "only-icon"}
                isFetchingExport={downloading}
                textBack="deposits.detail.header.returnToDeposits"
                tagKey={`deposits.status.${deposit.estado}`}
                statusClassNames={`ml-5 product-status-${deposit.estado}-text`}
            />
        );
    };

    render() {
        const {
            totalCount,
            deposit,
            channels,
            fetching,
            fetchingDownload,
            fetchingMovements,
            dispatch,
            isDesktop,
            activeRegion,
            totalFavorites,
            getSelectProduct,
            isLoading,
            ...rest
        } = this.props;

        const renderTabs = this.renderTabs();
        return (
            <PageLoading
                loading={fetching || deposit === undefined || Object.keys(deposit).length === 0}
                classicStyle={false}>
                {!fetching && (
                    <>
                        <Fragment>
                            <Notification scopeToShow="deposits" />
                            {!isLoading && this.renderHeader()}
                            <MainContainer showLoader={isLoading} className="rounded-border-top">
                                {renderTabs}
                            </MainContainer>
                        </Fragment>
                    </>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    deposit: depositsSelectors.getSelectedDeposit(state),
    totalDeposits: depositsSelectors.getTotalDeposits(state),
    channels: configUtil.getArray("core.enabledChannels"),
    fetching: depositsSelectors.getFetching(state),
    fetchingDownload: depositsSelectors.getFetchingDownload(state),
    fetchingMovements: depositsSelectors.getFetchingMovements(state),
    totalCount: depositsSelectors.getTotalCount(state),
    activeRegion: loginSelectors.getRegion(state),
    fullList: depositsSelectors.getFullList(state), // TODO ¿Hay que sacar el valor de algun lado? ejemplo configuration
    totalFavorites: depositsSelectors.getTotalFavorites(state),
    getSelectProduct: productSelectors.getSelectProduct(state),
    fetchingDownloadCertified: depositSelectors.getFetchingDownloadCertified(state),
});

DepositDetails.propTypes = {
    deposit: shape({
        productType: string,
        idProduct: string,
        permissions: shape(),
        currency: string,
    }).isRequired,
    channels: arrayOf().isRequired, // mapStateToProps
    fullList: bool.isRequired, // mapStateToProps
    fetching: bool.isRequired, // mapStateToProps
    fetchingDownload: func.isRequired, // mapStateToProps
    fetchingMovements: func.isRequired, // mapStateToProps
    totalDeposits: number.isRequired, // mapStateToProps
    match: shape().isRequired,
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
    totalCount: number.isRequired, // mapStateToProps
    activeRegion: string.isRequired, // mapStateToProps
    totalFavorites: number.isRequired,
    getSelectProduct: shape().isRequired,
    setLocationCustomPath: func,
    fetchingDownloadCertified: bool.isRequired,
};
DepositDetails.defaultProps = {
    setLocationCustomPath: () => {},
};

export default connect(mapStateToProps)(withRevelockPositionContext(DepositDetails));
// export default connect(mapStateToProps)(DepositDetails);
