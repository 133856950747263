import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Text from "pages/_components/Text";
import { bool, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { channelsSelectors } from "reducers/administration";
import { selectors as sessionSelectors } from "reducers/session";
import * as configUtils from "util/config";

class CapsList extends Component {
    static propTypes = {
        activeEnvironment: shape({ type: string }).isRequired,
        caps: shape({
            amount: number,
            frequency: string,
            currency: string,
        }).isRequired,
        currency: shape({
            id: string,
        }).isRequired,
        topAmount: shape({
            amount: number,
            frequency: string,
            maximum: number,
        }),
        hasDefaultLabel: bool,
        isConfirmationStep: bool,
        isInTicket: bool,
    };

    static defaultProps = {
        hasDefaultLabel: false,
        topAmount: null,
        isConfirmationStep: false,
        isInTicket: false,
    };

    renderCaps = () => {
        const {
            activeEnvironment: { type },
            caps,
            hasDefaultLabel,
            isConfirmationStep,
        } = this.props;

        return (
            <Box component="section">
                <Box display="flex" gap={isConfirmationStep ? "4" : "5"}>
                    {Object.entries(caps)
                        .filter(([channel]) => channel !== "all")
                        .map(([channel, { amount, frequency, currency }]) => (
                            <Col xs="6">
                                <Text labelKey={`channels.${channel}`} regular />
                                <Box display="flex" className="mt-3">
                                    <FormattedAmount
                                        quantity={
                                            amount === undefined
                                                ? parseFloat(configUtils.get(`default_cap_user_${type}`))
                                                : amount
                                        }
                                        currency={currency}
                                        bold
                                        noAmountMargin
                                    />
                                    <Text
                                        labelKey={`administration.channels.${frequency}`}
                                        bold
                                        className="caps-frequency-margin"
                                    />
                                </Box>
                                {hasDefaultLabel && amount === undefined && (
                                    <small className="data-desc text-muted">
                                        <I18n id="channels.defaultCap" />
                                    </small>
                                )}
                            </Col>
                        ))}
                </Box>
            </Box>
        );
    };

    render() {
        const { currency = {}, isConfirmationStep, caps, isInTicket, topAmount } = this.props;

        const allAmount = caps?.all?.amount || topAmount?.maximum;
        // const frequency = caps?.all?.frequency || topAmount?.frequency;

        let hasLimits;
        if (Object.keys(caps).length > 0) {
            hasLimits = Object.values(caps).reduce(
                (accumulator, currentValue) => accumulator && currentValue.amount >= 0,
                true,
            );
        }

        return !hasLimits ? (
            <Text labelKey="administration.user.details.limits.empty" size="6" align="center" />
        ) : (
            <>
                {this.renderCaps()}
                <Box
                    display="flex"
                    {...(isConfirmationStep && !isInTicket && { alignX: "between" })}
                    {...(!isConfirmationStep && !isInTicket && { column: true })}
                    {...(isInTicket && { alignX: "flex-start" })}
                    className="mb-6 mt-6">
                    <Box display="flex">
                        <Text
                            component="label"
                            className="m-0"
                            labelKey="administration.channels.all"
                            size="6"
                            color={isInTicket ? "text-color" : "heading-color"}
                            {...((isInTicket || (isConfirmationStep && !isInTicket)) && { bold: true, addColon: true })}
                            {...(!isConfirmationStep && !isInTicket && { regular: true })}
                        />
                        <FormattedAmount
                            quantity={allAmount}
                            currency={currency.id}
                            size="6"
                            color={isInTicket ? "text-color" : "heading-color"}
                            {...(!isConfirmationStep && !isInTicket && { bold: true })}
                        />
                        {/* <Text
                            labelKey={`administration.channels.${frequency}`}
                            size="6"
                            color={isInTicket ? "text-color" : "heading-color"}
                            regular
                        /> */}
                    </Box>
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    caps: channelsSelectors.getCaps(state),
    currency: channelsSelectors.getCurrencies(state)[0],
    topAmount: channelsSelectors.getTopAmount(state),
});

export default connect(mapStateToProps)(CapsList);
