import { node } from "prop-types";
import React, { createContext, useContext, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

export const LoadingGlobalProviderContext = createContext({});
const LoadingGlobalProvider = (props) => {
    const { children } = props;

    const [loading, setLoading] = useState(true);

    const contextData = { loading, setLoading };

    return (
        <LoadingGlobalProviderContext.Provider value={contextData} {...props}>
            {children}
        </LoadingGlobalProviderContext.Provider>
    );
};

LoadingGlobalProvider.propTypes = {
    children: node.isRequired,
};

export default compose(connect(), withRouter)(LoadingGlobalProvider);

export const useLoadingGlobalProvider = () => {
    const context = useContext(LoadingGlobalProviderContext);
    if (!context) {
        throw new Error("useLoadingGlobalProvider only can be used inside LoadingGlobalProviderContext");
    }
    return context;
};
