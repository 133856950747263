import { types as productsTypes } from "reducers/products";

export const types = {
    DOWNLOAD_PAYMENT_REQUEST: "loans/DOWNLOAD_PAYMENT_REQUEST",
    DOWNLOAD_PAYMENT_FAILURE: "loans/DOWNLOAD_PAYMENT_FAILURE",
    DOWNLOAD_PAYMENT_SUCCESS: "loans/DOWNLOAD_PAYMENT_SUCCESS",
    DOWNLOAD_LOANS_REQUEST: "loans/DOWNLOAD_LOANS_REQUEST",
    DOWNLOAD_LOANS_FAILURE: "loans/DOWNLOAD_LOANS_FAILURE",
    DOWNLOAD_LOANS_SUCCESS: "loans/DOWNLOAD_LOANS_SUCCESS",
    DOWNLOAD_STATEMENT_LOANS_REQUEST: "loans/DOWNLOAD_STATEMENT_LOANS_REQUEST",
    DOWNLOAD_STATEMENT_LOANS_FAILURE: "loans/DOWNLOAD_STATEMENT_LOANS_FAILURE",
    DOWNLOAD_STATEMENT_LOANS_SUCCESS: "loans/DOWNLOAD_STATEMENT_LOANS_SUCCESS",
    LIST_LOANS_REQUEST: "loans/LIST_LOANS_REQUEST",
    LIST_LOANS_SUCCESS: "loans/LIST_LOANS_SUCCESS",
    LOAN_DETAILS_REQUEST: "loans/LOAN_DETAILS_REQUEST",
    LOAN_DETAILS_SUCCESS: "loans/LOAN_DETAILS_SUCCESS",
    LOAN_READ_REQUEST: "loans/LOAN_READ_REQUEST",
    LOAN_READ_SUCCESS: "loans/LOAN_READ_SUCCESS",
    LOAN_LOADED_SUCCESS: "loans/LOAN_LOADED_SUCCESS",
    SET_SELECTED_LOAN: "loans/SET_SELECTED_LOAN",
    TOGGLE_OPTIONS: "loans/TOGGLE_OPTIONS",
    CLOSE_OPTIONS: "loans/CLOSE_OPTIONS",
    SET_SELECTED_FILTER: "loans/SET_SELECTED_FILTER",
    SET_SELECTED_STATEMENT: "loans/SET_SELECTED_STATEMENT",
    LOAN_DETAILS_CLEAR: "loans/LOAN_DETAILS_CLEAR",
    LOAN_DETAILS_SELECTED: "loans/LOAN_DETAILS_SELECTED",

    LIST_LOAN_STATEMENTS_REQUEST: "loans/LIST_LOAN_STATEMENTS_REQUEST",
    LIST_LOAN_STATEMENTS_SUCCESS: "loans/LIST_LOAN_STATEMENTS_SUCCESS",
    LIST_LOAN_STATEMENTS_FAILURE: "loans/LIST_LOAN_STATEMENTS_FAILURE",
    SET_BACK_TO_DESKTOP: "loans/SET_BACK_TO_DESKTOP",
};

export const INITIAL_STATE = {
    fetchingDownload: false,
    selectedLoan: null,
    statements: null,
    moreStatements: false,
    pageNumber: 1,
    fetching: false,
    isFetchingDetail: false,
    isOptionsVisible: false,
    selectedFilter: "allFees",
    selectedStatement: null,
    amortizedLoans: null,
    propertiesLoans: null,
    loansList: [],
    totalCount: true,
    totalFavorites: 0,
    completeFavorites: false,

    feePayment: false,
    amountPayment: false,

    fees: [],
    moreFees: false,
    fetchingFees: false,
    firstFetched: true,

    filters: {
        channels: [],
        check: null,
    },
    quantity: 0,
    lastPaymentDay: null,
    backToDesktop: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        statements,
        moreStatements,
        totalCount,
        filters,
        selectedStatement,
        loansList,
        alias,
        statementSelected,
        currency,
        favorite,
        totalFavorites,
        completeFavorites,

        feePayment,
        amountPayment,
        lastPaymentDay,

        quantity,
        pageNumber,
        backToDesktop,
        ...rest
    } = action;

    switch (action.type) {
        case types.DOWNLOAD_PAYMENT_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_PAYMENT_FAILURE:
        case types.DOWNLOAD_PAYMENT_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_LOANS_REQUEST:
        case types.DOWNLOAD_STATEMENT_LOANS_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };

        case types.DOWNLOAD_LOANS_FAILURE:
        case types.DOWNLOAD_LOANS_SUCCESS:
        case types.DOWNLOAD_STATEMENT_LOANS_FAILURE:
        case types.DOWNLOAD_STATEMENT_LOANS_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.LIST_LOANS_REQUEST:
            return {
                ...state,
                isOptionsVisible: false,
                fetching: true,
                fees: [],
                moreFees: false,
                selectedFee: null,
                pageNumber: 1,
                quantity: 0,
            };
        case types.LIST_LOANS_SUCCESS:
            return {
                ...state,
                loansList,
                fetching: false,
                completeFavorites,
            };
        case types.LOAN_DETAILS_REQUEST:
            return {
                ...state,
                isFetchingDetail: true,
                fetchingFees: true,
                fees: [],
                pageNumber: 1,
                quantity: 0,
            };
        case types.LOAN_DETAILS_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            const statementsResponse = statements.map((item) => ({ ...item, selected: false }));

            return {
                ...state,
                statements: statementsResponse,
                moreStatements,
                totalCount,
                isFetchingDetail: false,
                currencyStatements: currency,
                firstFetched: false,
                fetchingFees: false,
                fees: statements,
                lastPaymentDay: action.lastPaymentDay,
                ...rest,
            };
        case types.LOAN_READ_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LOAN_READ_SUCCESS:
            return {
                ...state,
                selectedLoan: action.loan,
                numberOfFees: action.numberOfFees,
                fetching: false,
                totalFavorites: action.totalFavorites,
            };
        case types.LOAN_LOADED_SUCCESS:
            return {
                ...state,
                fetching: false,
                isFetchingDetail: false,
            };
        case types.TOGGLE_OPTIONS: {
            return {
                ...state,
                isOptionsVisible: !state.isOptionsVisible,
            };
        }
        case types.CLOSE_OPTIONS:
            return {
                ...state,
                isOptionsVisible: false,
            };
        case types.SET_SELECTED_FILTER:
            return {
                ...state,
                selectedFilter: action.selectedFilter,
            };
        case types.SET_SELECTED_STATEMENT:
            return {
                ...state,
                selectedStatement,
            };
        case productsTypes.CHANGE_PRODUCT_ALIAS_SUCCESS: {
            if (state.selectedLoan) {
                return {
                    ...state,
                    selectedLoan: {
                        ...state.selectedLoan,
                        productAlias: alias,
                    },
                };
            }

            return {
                ...state,
            };
        }
        case types.LOAN_DETAILS_CLEAR:
            return {
                ...state,
                statements: null,
                feePayment: null,
                amountPayment: null,
            };
        case types.LOAN_DETAILS_SELECTED:
            // eslint-disable-next-line no-confusing-arrow, no-case-declarations
            const statementsSelect = state.statements.map((item) =>
                item.idStatement === statementSelected.idStatement ? { ...item, selected: !item.selected } : item,
            );

            return {
                ...state,
                statements: statementsSelect,
            };
        case productsTypes.TOGGLE_FAVORITE_SUCCESS: {
            const { fromList, idProduct } = action;
            if (fromList) {
                // eslint-disable-next-line no-shadow
                const { loansList } = state;
                if (!loansList || !loansList.some((loan) => loan.idProduct === idProduct)) {
                    return state;
                }
                const updateAccountArray = loansList.map((loan) => {
                    if (loan.idProduct === idProduct) {
                        return { ...loan, favorite };
                    }
                    return loan;
                });
                return { ...state, loansList: updateAccountArray, completeFavorites };
            }
            return {
                ...state,
                selectedLoan: {
                    ...state.selectedLoan,
                    favorite,
                },
                totalFavorites,
            };
        }
        case types.LIST_LOAN_STATEMENTS_REQUEST: {
            return {
                ...state,
                statements: null,
                feePayment: false,
                amountPayment: false,
            };
        }
        case types.LIST_LOAN_STATEMENTS_SUCCESS: {
            return {
                ...state,
                statements,
                feePayment,
                amountPayment,
                lastPaymentDay,
            };
        }
        case types.SET_BACK_TO_DESKTOP: {
            return {
                ...state,
                backToDesktop,
            }
        }
        default:
            return state;
    }
};

export const actions = {
    listLoans: () => ({
        type: types.LIST_LOANS_REQUEST,
    }),
    details: (selectedLoanId) => ({
        type: types.LOAN_DETAILS_REQUEST,
        selectedLoanId,
    }),
    downloadPayment: (idLoan, format) => ({
        type: types.DOWNLOAD_PAYMENT_REQUEST,
        idLoan,
        format,
    }),
    readLoan: (loanId, familyLoan, typeLoan) => ({
        type: types.LOAN_READ_REQUEST,
        loanId,
        familyLoan,
        typeLoan,
    }),
    toggleOptions: () => ({
        type: types.TOGGLE_OPTIONS,
    }),
    closeOptions: () => ({
        type: types.CLOSE_OPTIONS,
    }),
    setSelectedFilter: (selectedFilter) => ({
        type: types.SET_SELECTED_FILTER,
        selectedFilter,
    }),
    setSelectedStatement: (selectedStatement) => ({
        type: types.SET_SELECTED_STATEMENT,
        selectedStatement,
    }),
    setBackToDesktop: (backToDesktop) => ({
        type: types.SET_BACK_TO_DESKTOP,
        backToDesktop,
    }),
    loadedLoan: () => ({
        type: types.LOAN_LOADED_SUCCESS,
    }),
    clearLoanStatement: () => ({
        type: types.LOAN_DETAILS_CLEAR,
    }),
    statementSelected: (statementSelected) => ({
        type: types.LOAN_DETAILS_SELECTED,
        statementSelected,
    }),
    listStatements: (loan, onFinish) => ({
        type: types.LIST_LOAN_STATEMENTS_REQUEST,
        loan,
        onFinish,
    }),
    downloadLoans: (format, groupCode, onFinish) => ({
        type: types.DOWNLOAD_LOANS_REQUEST,
        format,
        groupCode,
        onFinish,
    }),
    downloadStamentLoans: (format, idLoan, groupCode, typeProduct, numOperation, description, onFinish) => ({
        type: types.DOWNLOAD_STATEMENT_LOANS_REQUEST,
        format,
        idLoan,
        groupCode,
        groupCode,
        typeProduct,
        numOperation,
        description,
        onFinish,
    }),
};

export const selectors = {
    getLoans: ({ loans }) => loans.loansList,
    getSelectedLoan: ({ loans }) => loans.selectedLoan,
    getPageNumber: ({ loans }) => loans.pageNumber,
    getStatements: ({ loans }) => loans.statements,
    getMoreStatements: ({ loans }) => loans.moreStatements,
    getFetching: ({ loans }) => loans.fetching,
    getFetchingDownload: ({ loans }) => loans.fetchingDownload,
    getIsOptionsVisible: ({ loans }) => loans.isOptionsVisible,
    getSelectedFilter: ({ loans }) => loans.selectedFilter,
    getSelectedStatement: ({ loans }) => loans.selectedStatement,
    getBackToDesktop: ({ loans }) => loans.backToDesktop,
    isFetchingDetail: ({ loans }) => loans.isFetchingDetail,
    getTotalCount: ({ loans }) => loans.totalCount,
    // eslint-disable-next-line no-confusing-arrow
    getStatementsPendieng: ({ loans }) =>
        loans.statements ? loans.statements.filter((item) => item.status === "pending") : loans.statements,
    getCurrencyStatements: ({ loans }) => loans.currencyStatements,
    getTotalFavorites: ({ loans }) => loans.totalFavorites,
    getCompleteFavorites: ({ loans }) => loans.completeFavorites,

    getAmountPayment: ({ loans }) => loans.amountPayment,
    getFeePayment: ({ loans }) => loans.feePayment,
    getLastPaymentDay: ({ loans }) => loans.lastPaymentDay,

    getQuantity: ({ loans }) => loans.quantity,
    getFetchingFees: ({ loans }) => loans.fetchingFees,
    getFees: ({ loans }) => loans.fees,
    getFilters: ({ loans }) => loans.filters,
    getFirstFetched: ({ loans }) => loans.firstFetched,
};
