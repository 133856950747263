import classNames from "classnames";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, string } from "prop-types";
import React, { Component } from "react";

class EquivalentTotalBalance extends Component {
    static propTypes = {
        totalBalance: string,
        isMobile: bool,
    };

    static defaultProps = {
        totalBalance: "",
        isMobile: false,
    };

    renderBalanceUSD = () => {
        const { isMobile, totalBalance } = this.props;
        return (
            <>
                <Box fullWidth>
                    <Text
                        {...(!isMobile && { size: "5" })}
                        {...(isMobile && { size: "6" })}
                        labelKey="deposits.equivalentTotalBalance"
                    />
                    <Box className="mt-2" display="flex">
                        <Text labelKey="deposits.balanceComposed.a11y" className="visually-hidden" />
                        <Text {...(!isMobile && { size: "big" })} {...(isMobile && { size: "2" })} semibold>
                            <FormattedAmount quantity={totalBalance} className="credit-card-value" noCurrency />
                        </Text>
                        <span className="ml-2 size-big text-semibold color-text-grey">USD</span>
                    </Box>
                </Box>
            </>
        );
    };

    render() {
        const { isMobile } = this.props;

        return (
            <Box
                component="article"
                display="flex"
                borderRadius="default"
                fullWidth
                className={classNames("box-shadow-small", {
                    "p-7 mb-5": !isMobile,
                    "p-5 mb-5": isMobile,
                })}>
                {!isMobile && (
                    <Box fullWidth className="mb-2">
                        <>{this.renderBalanceUSD()}</>
                    </Box>
                )}
                <Box>
                    <Image src="images/deposits.svg" className="img-functionality" />
                </Box>
            </Box>
        );
    }
}

export default EquivalentTotalBalance;
