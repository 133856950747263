import React from "react";
import LoginWrapper from "./LoginWrapper";
import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { arrayOf, bool, shape } from "prop-types";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withExchangeToken from "pages/_components/withExchangeToken";

const ListUserEnvironmentContent = (props) => {
    const {
        isDesktop,
        isSubmitting,
        className,
        newEnvironments,
        dispatch,
        fetching,
    } = props;

    const clickHandlerRemove = () => {
        dispatch(loginActions.removeEnvironmentNotifyRequest());
    };

    const renderEnvironments = () => {
        if (newEnvironments && newEnvironments.length > 0) {
            return (
                <Box className="mb-7">
                    <ul className="color-strong-primary">
                        {newEnvironments?.map(e => (
                                <li key={e}>
                                    <Text bold defaultValue={e}/>
                                </li>
                            )
                        )}
                    </ul>
                </Box>
            );
        }
    };
    return (
        <LoginWrapper className={classNames(className, {
            "pointer-events-none": isSubmitting,
        })}>
            <Box display="flex" column fullWidth>
                <Box className="title-with-icon">
                    <Image src="images/notice.svg" />
                    <Text component="h3" defaultValue={i18n.get("session.login.newEnvironments.title")} />
                </Box>
                <Box className="mb-7">
                    <Text
                        component="h4"
                        color="heading" size={isDesktop ? "4" : "5"} 
                        labelKey=""
                        defaultValue={i18n.get("session.login.newEnvironments.names")}
                    />
                </Box>
                {renderEnvironments()}
                <Box className="mb-7">
                <Text
                    color="heading" size={isDesktop ? "4" : "5"} 
                    labelKey=""
                    defaultValue={i18n.get("session.login.newEnvironments.label")}
                />
                </Box>
                <Box fullWidth className="mb-3">
                    <Button
                        loading={fetching}
                        label="global.continue"
                        bsStyle="primary"
                        block
                        disabled={!newEnvironments}
                        onClick={clickHandlerRemove}
                    />
                </Box>
            </Box>
        </LoginWrapper>
    );
}

ListUserEnvironmentContent.propTypes = {
    isDesktop: bool.isRequired,
    isSubmitting: bool.isRequired,
    newEnvironments: arrayOf(shape({})).isRequired,
};

const mapStateToProps = (state) => ({
    newEnvironments: loginSelectors.getNewEnvironments(state),
})

export default compose(withRouter, connect(mapStateToProps))(withExchangeToken(ListUserEnvironmentContent));
