import React from "react";
import { string, bool } from "prop-types";

import I18n from "pages/_components/I18n";

const getFocus = (id) => {
    const element = document.getElementById(id) || document.getElementById(`react-select-${id}--value`);
    if (element && element.nodeName === "DIV") {
        element.querySelector(".Select-input").focus();
    }
};

const AmountLabel = (props) => {
    const { editing, label, isRequired, optionalMessage, idField, showCurrencyLabel, renderLabel } = props;

    if (editing && renderLabel) {
        return (
            <div className="form-group-text">
                {showCurrencyLabel && (
                    <label
                        className="control-label"
                        htmlFor={`${idField}.currency`}
                        role="presentation"
                        onClick={() => getFocus(`${idField}.currency`)}>
                        <I18n id="form.field.amount.currency" />
                        {!isRequired && <small className="text-optional">{optionalMessage}</small>}
                    </label>
                )}
                <label className="control-label" htmlFor="amount">
                    {label}
                </label>
            </div>
        );
    }
    return null;
};

AmountLabel.propTypes = {
    editing: bool.isRequired,
    label: string.isRequired,
    isRequired: bool.isRequired,
    optionalMessage: string.isRequired,
    idField: string.isRequired,
    showCurrencyLabel: bool,
    renderLabel: bool,
};

AmountLabel.defaultProps = {
    showCurrencyLabel: false,
    renderLabel: true,
};

export default AmountLabel;
