import formField from "pages/forms/_components/_fields/_commons/formField";
import ManualPayment from "pages/forms/_components/_fields/_multilinefile/ManualPayment";
import Button from "pages/_components/Button";
import DetailBox from "pages/_components/detailBox/DetailBox";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import { arrayOf, func, number, oneOf, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routerActions } from "react-router-redux/actions";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as transactionLinesSelectors } from "reducers/form/transactionLines";
import { compose } from "redux";

class Transactionlines extends Component {
    static propTypes = {
        setValue: func.isRequired,
        idForm: string.isRequired,
        mode: oneOf(["edit", "view"]).isRequired,
        totalAmount: shape({
            quantity: number.isRequired,
            currency: string,
        }).isRequired,
        linesWithNoAmount: number.isRequired,
        dispatch: func.isRequired,
        match: shape({
            params: shape({
                idForm: string.isRequired,
            }).isRequired,
        }).isRequired,
        transactionLines: arrayOf(shape({})).isRequired,
        form: shape({
            values: arrayOf(shape({})).isRequired,
        }).isRequired,
        value: arrayOf(shape({})).isRequired,
        transaction: shape({
            idTransaction: string.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        const { transactionLines, setValue } = this.props;
        if (!this.isTicket() && transactionLines.length) {
            setValue(transactionLines);
        }
    }

    handleDetailsClick = () => {
        const { dispatch, match, transaction } = this.props;
        if (this.isTicket()) {
            dispatch(routerActions.push(`/transaction/${transaction.idTransaction}/processDetail`));
        } else {
            const { form } = this.props;
            dispatch(routerActions.push(`/form/${match.params.idForm}/processDetail`, { shouldLoadForm: false }));
            dispatch(formActions.setData(form.values));
        }
    };

    isTicket = () => {
        const { mode } = this.props;

        return mode === "view";
    };

    render() {
        const { mode, transactionLines, totalAmount, linesWithNoAmount } = this.props;
        switch (mode) {
            case "edit": {
                return <ManualPayment {...this.props} />;
            }
            case "preview": {
                return (
                    <DetailBox>
                        {!!linesWithNoAmount && (
                            <I18n
                                component="div"
                                componentProps={{ className: "alert alert-warning mTop", role: "alert" }}
                                LINE_COUNT={linesWithNoAmount}
                                id="massive.payments.warning.noAmount"
                            />
                        )}
                        <DetailBox.Data label="forms.inputFile.massivePayments.lines">
                            <div>
                                {transactionLines.length} <I18n id="forms.inputFile.massivePayments.total.lines" />
                            </div>
                        </DetailBox.Data>
                        <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                            <FormattedAmount className="data-desc" {...totalAmount} />
                        </DetailBox.Data>
                        <div className="detailBox-row">
                            <Button
                                bsStyle="primary"
                                className="btn-small"
                                onClick={this.handleDetailsClick}
                                label="forms.inputFile.massivePayments.transaction.detail"
                            />
                        </div>
                    </DetailBox>
                );
            }
            default: {
                const { value } = this.props;
                const amount = value.reduce(
                    (result, line) => ({
                        quantity: result.quantity + line.creditAmountQuantity,
                        currency: line.creditAmountCurrency,
                    }),
                    { quantity: 0, currency: null },
                );

                return (
                    <DetailBox>
                        <DetailBox.Data label="forms.inputFile.massivePayments.lines">{value.length}</DetailBox.Data>
                        <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                            <FormattedAmount className="data-desc" {...amount} />
                        </DetailBox.Data>
                        <div className="detailBox-row">
                            <Button
                                bsStyle="primary"
                                className="btn-small"
                                onClick={this.handleDetailsClick}
                                label="forms.inputFile.massivePayments.transaction.detail"
                            />
                        </div>
                    </DetailBox>
                );
            }
        }
    }
}

const mapStateToProps = (state) => ({
    transactionLines: transactionLinesSelectors.getTransactionLines(state),
    totalAmount: transactionLinesSelectors.getTotalAmount(state),
    linesWithNoAmount: transactionLinesSelectors.getLinesWithNoAmount(state).length,
    transaction: formSelectors.getTransaction(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    formField({
        isValidValue: () => true,
        pureRender: true,
    }),
)(Transactionlines);
