import React from "react";
import MobileFilterLayout from "pages/_components/MobileFilterLayout";
import { routerActions } from "react-router-redux/actions";
import { bool, func } from "prop-types";
import { connect } from "react-redux";
import Box from "pages/_components/Box/Box";
import FormFilter from "./_components/FormFilter";

const DepositsMobileFilter = ({ dispatch, isDesktop }) => {
    const handleBack = () => {
        dispatch(routerActions.goBack());
    };
    const handleFilterClick = () => {
        dispatch(routerActions.goBack());
    };
    return (
        <Box className="background-primary deposit-background-filter">
            <MobileFilterLayout handleBack={handleBack}>
                <FormFilter handleFilter={handleFilterClick} handleCancel={handleBack} isDesktop={isDesktop} />
            </MobileFilterLayout>
        </Box>
    );
};

DepositsMobileFilter.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
};
DepositsMobileFilter.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(DepositsMobileFilter);
