/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { bool, shape, string } from "prop-types";
import { Collapse } from "react-bootstrap";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Box from "pages/_components/Box";

import Info from "pages/_components/Info";
import * as i18nUtils from "util/i18n";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import classNames from "classnames";

export const AccountStatus = {
    Actived: "ACTIVA",
    Inactived: "INACTIVA",
    NoActived: "NOACTIVA",
};

export const actionChangeStatusAccount = {
    Inactived: {
        actionName: "inactived",
        actionImage: "images/UnlockProduct.svg",
        statusExpected: AccountStatus.Inactived,
    },
    Active: {
        actionName: "active",
        actionImage: "images/UnlockProduct.svg",
        statusExpected: AccountStatus.Actived,
    },
};

class DetailHeadInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            moreDetails: false,
        };
    }

    moreDetailsHandler = () => {
        this.setState((prevState) => ({
            moreDetails: !prevState.moreDetails,
        }));
    };

    renderImage = (productType) => (
        <Box display="flex" alignY="center">
            <Image src={`images/products/logo_${productType}.svg`} wrapperClassName="product-detail-img" />
        </Box>
    );

    renderAccountTitle = (number, showText = true) => (
        <Box fullWidth>
            <Text component="h1" align="left" labelKey="accounts.detail.title" number={number} bold />
            {showText && (
                <Text align="left" labelKey="accounts.detail.text" number={number} color="text-disabled-color" />
            )}
        </Box>
    );

    renderAccountName = (ownerName) => (
        <Box className="px-7 pt-4" fullWidth>
            <Text component="h4" align="left" bold>
                {ownerName}
            </Text>
        </Box>
    );

    renderBalances = (currency, availableCredit, countableBalance, avaibleBalance) => (
        <Box borderRadius="lg" background="background-primary" display="flex" className="p-7 children-border-right">
            <Box fullWidth>
                <Box className="fit-content-width text-left">
                    <I18n id="accounts.availableCredit" />
                    <FormattedAmount
                        noAmountMargin
                        className="data-amount-account"
                        currency={currency}
                        quantity={availableCredit}
                    />
                </Box>
            </Box>
            <Box fullWidth className="text-webkit-center">
                <Box className="fit-content-width text-left">
                    <I18n id="accounts.countableBalance" />
                    <FormattedAmount
                        noAmountMargin
                        className="data-amount-account"
                        currency={currency}
                        quantity={countableBalance}
                    />
                </Box>
            </Box>
            <Box fullWidth className="text-webkit-right">
                <Box className="fit-content-width text-left">
                    <I18n id="accounts.availableBalance" />
                    <FormattedAmount
                        noAmountMargin
                        className="data-amount-account"
                        currency={currency}
                        quantity={avaibleBalance}
                    />
                </Box>
            </Box>
        </Box>
    );

    renderBalancesMobile = (currency, availableCredit, countableBalance, avaibleBalance) => (
        <Box borderRadius="lg" background="background-primary" className="p-7 mt-7">
            <Box fullWidth>
                <Box className="text-center mb-3">
                    <I18n id="accounts.availableBalance" />
                    <FormattedAmount
                        noAmountMargin
                        className="data-amount-account big-amount content-center mb-1"
                        currency={currency}
                        quantity={avaibleBalance}
                    />
                </Box>
            </Box>
            <Box display="flex" className="content-center mb-3">
                <I18n id="accounts.availableCredit" />
                :&nbsp;
                <FormattedAmount
                    noAmountMargin
                    className="data-amount-account"
                    currency={currency}
                    quantity={availableCredit}
                />
            </Box>

            <Box display="flex" className="content-center">
                <I18n id="accounts.countableBalance" />
                :&nbsp;
                <FormattedAmount
                    noAmountMargin
                    className="data-amount-account"
                    currency={currency}
                    quantity={countableBalance}
                />
            </Box>
        </Box>
    );

    renderMoreDetails = (
        currency,
        productType,
        officialName,
        deliveryAddress,
        openingDate,
        lastTransactionDate,
        internationalRemittances,
        blockedBalance,
        detainedBalance12,
        detainedBalance24,
        creditLineAmount,
        occasionalOverdrafts,
        isDesktop,
    ) => (
        <Box borderRadius="lg" background="background-disabled" className="mt-5 mb-5">
            <Box
                borderRadius="lg"
                display="flex"
                className={classNames({
                    "py-5 px-7": isDesktop,
                    "p-5": !isDesktop,
                })}
                onClick={this.moreDetailsHandler}
                pointer
                fullWidth>
                <Box fullWidth>
                    <Text align="left" labelKey="global.moreDetails" bold />
                </Box>
                <Box alignY="center">
                    <Image src={`images/util/arrow-${this.state.moreDetails ? "up" : "down"}.svg`} />
                </Box>
            </Box>

            <Collapse in={this.state.moreDetails}>
                <Box
                    className={classNames({
                        "pl-7 pr-7": isDesktop,
                        "pl-5 pr-5": !isDesktop,
                    })}>
                    <Box display={isDesktop && "flex"} className={`${isDesktop ? "mt-7" : "mt-5"}`}>
                        <Info
                            labelKey="accounts.details.info.type"
                            text={i18nUtils.get(`account.shortLabel.${productType}`)}
                            flex={!isDesktop}
                        />
                        <Info labelKey="accounts.details.info.currency" text={currency} flex={!isDesktop} />
                        <Info labelKey="accounts.details.info.official" text={officialName} flex={!isDesktop} />
                        <Info
                            labelKey="accounts.details.info.deliveryAddress"
                            text={deliveryAddress}
                            flex={!isDesktop}
                        />
                    </Box>
                    <Box
                        display={isDesktop && "flex"}
                        className={classNames({
                            "mt-7": isDesktop,
                        })}>
                        <Info labelKey="accounts.details.info.openingDate" date={openingDate} flex={!isDesktop} />
                        <Info
                            labelKey="accounts.details.info.lastTransactionDate"
                            date={lastTransactionDate}
                            flex={!isDesktop}
                        />
                        <Info
                            labelKey="accounts.details.info.creditLines"
                            amount={creditLineAmount}
                            flex={!isDesktop}
                        />
                        <Info
                            labelKey="accounts.details.info.overdrafts"
                            amount={occasionalOverdrafts}
                            flex={!isDesktop}
                        />
                    </Box>
                    <Box
                        display={isDesktop && "flex"}
                        className={classNames("pb-7", {
                            "mt-7": isDesktop,
                        })}>
                        <Info
                            labelKey="accounts.details.info.internationalRemittances"
                            amount={internationalRemittances}
                            flex={!isDesktop}
                        />
                        <Info
                            labelKey="accounts.details.info.blockedBalance"
                            amount={blockedBalance}
                            flex={!isDesktop}
                        />
                        <Info
                            labelKey="accounts.details.info.detainedBalance24"
                            amount={detainedBalance24}
                            flex={!isDesktop}
                        />
                        <Info
                            labelKey="accounts.details.info.detainedBalance12"
                            amount={detainedBalance12}
                            flex={!isDesktop}
                        />
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );

    render() {
        const { account, isDesktop, status } = this.props;

        const {
            number,
            currency,
            availableCredit,
            countableBalance,
            balance: avaibleBalance,
            productType,
            officialName,
            deliveryAddress,
            openingDate,
            lastTransactionDate,
            internationalRemittances,
            blockedBalance,
            detainedBalance12,
            detainedBalance24,
            creditLineAmount,
            occasionalOverdrafts,
            ownerName,
        } = account;

        return (
            <div className="detail-head-info pt-0">
                {isDesktop ? (
                    <>
                        {isDesktop && this.renderAccountName(ownerName)}
                        <Box display="flex" className="px-7 pb-7 mb-3">
                            {!isDesktop && this.renderImage(productType)}
                            {this.renderAccountTitle(number)}
                            {isDesktop && this.renderImage(productType)}
                        </Box>
                        {this.renderBalances(currency, availableCredit, countableBalance, avaibleBalance)}
                        {this.renderMoreDetails(
                            currency,
                            productType,
                            officialName,
                            deliveryAddress,
                            openingDate,
                            lastTransactionDate,
                            internationalRemittances,
                            blockedBalance,
                            detainedBalance12,
                            detainedBalance24,
                            creditLineAmount,
                            occasionalOverdrafts,
                            isDesktop,
                        )}
                    </>
                ) : (
                    <>
                        <Box background="white" className="p-5">
                            <Box display="flex">
                                <Box fullWidth>
                                    {this.renderImage(productType)}
                                    <Box display="flex">{this.renderAccountTitle(number, false)}</Box>
                                </Box>
                                <Box alignY="center" display="flex">
                                    <Text className="product-status-text" labelKey={`account.status.${status}`} />
                                </Box>
                            </Box>
                            <Text
                                align="left"
                                labelKey="accounts.detail.text"
                                number={number}
                                color="text-disabled-color"
                            />

                            {this.renderBalancesMobile(currency, availableCredit, countableBalance, avaibleBalance)}

                            {this.renderMoreDetails(
                                currency,
                                productType,
                                officialName,
                                deliveryAddress,
                                openingDate,
                                lastTransactionDate,
                                internationalRemittances,
                                blockedBalance,
                                detainedBalance12,
                                detainedBalance24,
                                creditLineAmount,
                                occasionalOverdrafts,
                                isDesktop,
                            )}
                        </Box>
                    </>
                )}
            </div>
        );
    }
}

DetailHeadInfo.propTypes = {
    account: shape({}),
    isDesktop: bool.isRequired,
    status: string,
};

DetailHeadInfo.defaultProps = {
    account: null,
    status: "A",
};

export default DetailHeadInfo;
