import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import PermissionsList from "pages/administration/_components/PermissionsList";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";

class PermissionsConfirm extends Component {
    static propTypes = {
        actions: shape({
            updatePermissionsRequest: func.isRequired,
        }).isRequired,
        credentialGroups: arrayOf(shape({})).isRequired,
        fetching: bool.isRequired,
        formActions: shape({
            signTransaction: func.isRequired,
        }).isRequired,
        idActivity: number.isRequired,
        idTransaction: number.isRequired,
        permissions: arrayOf(shape({})).isRequired,
        routerActions: shape({
            goBack: func.isRequired,
        }).isRequired,
        user: shape({
            firstName: string.isRequired,
            idUser: number.isRequired,
            idUserStatus: number.isRequired,
            lastName: string.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, permissions, user, idTransaction, idActivity } = this.props;
        const secondFactor = credentials;
        if (!idTransaction) {
            actions.updatePermissionsRequest(
                {
                    permissions,
                    secondFactor,
                    idUser: user.idUser,
                    status: user.idUserStatus,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    render() {
        const { fetching, credentialGroups, user, permissions } = this.props;

        return (
            <>
                <Head title="administration.permissions.configurePermissions" onBack={this.handleBack} />
                <MainContainer showLoader={fetching && !Object.keys(user).length}>
                    <Row>
                        <Col xs={8} xsOffset={2} className="pb-80">
                            <Box
                                display="flex"
                                column
                                background="component-background"
                                borderRadius="default"
                                className="px-80 pt-8 pb-9"
                                fullHeight>
                                <Box display="flex" className="pb-5 mb-8" border="bottom-border-base-color-1">
                                    <Text
                                        component="h2"
                                        labelKey="administration.permissions.forms.confirm.credentials"
                                        className="m-0"
                                        size="5"
                                        color="heading-color"
                                        bold
                                    />
                                </Box>

                                <Box display="flex" alignX="center" className="mb-9" fullWidth>
                                    <Text labelKey="administration.channels.user" color="primary" bold addColon />
                                    <Text color="primary">{user.fullName}</Text>
                                </Box>

                                <Box className="mb-10">
                                    <PermissionsList permissions={permissions} classNameContainer="px-0">
                                        {(list) => {
                                            if (list) {
                                                return <>{list}</>;
                                            }
                                            return <I18n id="administration.permissions.empty" />;
                                        }}
                                    </PermissionsList>
                                </Box>
                                <AdministrationFormConfirmation
                                    credentialGroups={credentialGroups}
                                    onSubmit={this.handleSubmit}
                                    cancelUrl={`/administration/medium/permissions/${user.idUser}`}
                                    idActivity="administration.medium.modify.permissions.send"
                                />
                            </Box>
                        </Col>
                    </Row>
                </MainContainer>
            </>
        );
    }
}

export default PermissionsConfirm;
