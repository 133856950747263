import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, string } from "prop-types";
import React, { Component } from "react";

class FieldWarning extends Component {
    static propTypes = {
        warning: string,
        relativeStyleError: bool,
        icon: string,
    };

    static defaultProps = {
        warning: "",
        relativeStyleError: false,
        icon: "warningIcon.svg",
    };

    render() {
        const { warning, relativeStyleError, icon } = this.props;

        if (!warning) {
            return null;
        }
        return (
            <Box
                fullWidth
                className={classNames("form-group-error-wrapper", {
                    "form-group-error-wrapper-relative": relativeStyleError,
                })}>
                <Box className="form-group-error mt-1">
                    <Box>
                        <Image src={`images/icons/${icon}`} className="svg-icon error-icon" wrapperWidth="6" />
                    </Box>
                    <Text color="heading-color">{warning}</Text>
                </Box>
            </Box>
        );
    }
}

export default FieldWarning;
