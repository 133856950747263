import React, { Component } from "react";
import { arrayOf, string, shape } from "prop-types";
import { connect } from "react-redux";

import { groupSelectors } from "reducers/administration";

import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import { filterData } from "util/array";

import Box from "pages/_components/Box";
import PaginatedDataTable from "pages/_components/PaginatedDataTable";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import * as configUtils from "util/config";

class MembersList extends Component {
    static propTypes = {
        members: arrayOf(shape({ fullName: string, idUser: string })),
        // adminUsers: arrayOf(string),
    };

    static defaultProps = {
        members: [],
        // adminUsers: [],
    };

    state = {
        listToBeFiltered: [],
        rowsPerPage: configUtils.getInteger("administration.rowsPerPage"),
    };

    componentDidMount() {
        const { members } = this.props;
        if (members) {
            this.setState({
                listToBeFiltered: members,
            });
        }
    }

    generateTableColumns = () => {
        const columnArray = [
            {
                key: "name",
                dataIndex: "name",
                title: i18n.get("administration.group.users.name"),
                width: 300,
            },
            {
                key: "identification",
                dataIndex: "identification",
                title: i18n.get("administration.group.users.identification"),
                width: 200,
            },
        ];
        return columnArray;
    };

    handleFilterData = (filter) => {
        const { members: dataToFilter } = this.props;
        if (filter !== "") {
            const data = filterData([...dataToFilter], ["fullName", "documentNumber"], filter);
            this.setState({ listToBeFiltered: data });
        } else {
            this.setState({ listToBeFiltered: [...dataToFilter] });
        }
    };

    populateGroupsData = (data) =>
        data.map(({ idUser, fullName, documentType, documentNumber }) => ({
            key: idUser.toString(),
            name: <div>{fullName}</div>,
            identification: (
                <div>
                    {documentType} {documentNumber}
                </div>
            ),
        }));

    render() {
        const { members } = this.props;
        const { listToBeFiltered, rowsPerPage } = this.state;

        if (!members.length) {
            return (
                <ul className="list">
                    <li className="list-item">
                        <div className="list-item-inner">
                            <div className="data-wrapper data-wrapper-flex">
                                <I18n id="administration.members.empty" />
                            </div>
                        </div>
                    </li>
                </ul>
            );
        }

        return (
            <Box>
                <TextSimpleField
                    name="search"
                    placeholder={i18n.get("administration.advanced.group.create.description.search")}
                    value=""
                    onChange={(e) => this.handleFilterData(e.target.value)}
                    serarchStyle
                />
                <PaginatedDataTable
                    data={listToBeFiltered}
                    columns={this.generateTableColumns()}
                    populateRows={this.populateGroupsData}
                    rowsPerPage={rowsPerPage}
                    hasFilterApplied={members.length !== listToBeFiltered.length}
                />
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    members: groupSelectors.getMembers(state),
    // adminUsers: groupSelectors.getAdminUsers(state),
});

export default connect(mapStateToProps)(MembersList);
