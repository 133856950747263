import { resizableRoute } from 'pages/_components/Resizable';
import TextAndButtonsModal from 'pages/_components/modal/TextAndButtonsModal'
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import * as i18n from "util/i18n";
import { compose } from 'redux';
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { bool, func } from 'prop-types';
import { push } from 'react-router-redux';

export const PendingInvitationModal = (props) => {
    const { dispatch, showPendingInvitation } = props;
    const handleAcceptModal = () => {
        dispatch(loginActions.showPendingInvitationModal(false));
        dispatch(push("/enrollment/document"));
    };

    const hideModal = () => {
        dispatch(loginActions.showPendingInvitationModal(false));
    };
    useEffect(() => {
        if (showPendingInvitation) {
            dispatch(loginActions.showPendingInvitationModal(false));
        }
    }, [])
    
  return (
    <>
    {
       showPendingInvitation && (
        <TextAndButtonsModal
            modalShow={showPendingInvitation}
            acceptFunction={handleAcceptModal}
            cancelFunction={hideModal}
            headingText={i18n.get("enrollment.pending.invitation.modal.title")}
            text={i18n.get("enrollment.pending.invitation.modal.desc")}
            confirmLabel="enrollment.pending.invitation.modal.btn.goToEnrollment"
            continueLabel="global.cancel"
        />
       )
    }
    </>
    
  )
}
PendingInvitationModal.propTypes = {
    dispatch: func.isRequired,
    showPendingInvitation: bool.isRequired,
};

const mapStateToProps = (state) => ({
    showPendingInvitation: loginSelectors.getShowPendingInvitationModal(state),
});

export default compose(connect(mapStateToProps))(resizableRoute(PendingInvitationModal));
