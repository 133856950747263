import React, { Component, Fragment } from "react";
import { bool, func, number, oneOfType, shape, arrayOf, objectOf, string } from "prop-types";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import Notification from "pages/_components/Notification";
import FormattedAmount from "pages/_components/FormattedAmount";
import Head from "pages/_components/Head";
import Heading from "pages/_components/Heading";
import MainContainer from "pages/_components/MainContainer";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Accordion from "pages/_components/Accordion";
import { getAccordionGroups } from "../../utils/signaturesScheme";
import ConfirmData from "./ConfirmData";

class SignaturesSchemeCreate extends Component {
    static propTypes = {
        actions: shape({
            createSignaturesSchemeRequest: func,
        }).isRequired,
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        fetching: bool.isRequired,
        functionalGroups: arrayOf(string),
        routerActions: shape({
            goBack: func,
        }),
        signatureLevelsCounts: oneOfType([
            shape({
                A: number,
            }),
            shape(
                configUtils.getArray("administration.signatures.signatureLevels").reduce((res, signLevel) => {
                    const result = res;
                    result[signLevel] = number;

                    return result;
                }, {}),
            ),
        ]),
        signatureType: string,
        topAmount: shape({
            amount: oneOfType([number, string]),
            period: string,
        }),
        formActions: objectOf(func).isRequired,
        idActivity: string,
        idTransaction: string,
        signatureDispatch: bool,
        alias: string,
        groups: arrayOf(shape({})).isRequired,
    };

    static defaultProps = {
        functionalGroups: [],
        routerActions: null,
        signatureLevelsCounts: null,
        signatureType: "",
        topAmount: {
            amount: 0,
            period: "daily",
        },
        idActivity: null,
        idTransaction: null,
        signatureDispatch: false,
        alias: null,
    };

    componentDidMount() {
        const { routerActions, signatureLevelsCounts, signatureType } = this.props;

        if (!signatureLevelsCounts || !signatureType) {
            routerActions.goBack();
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const {
            idTransaction,
            idActivity,
            formActions,
            actions,
            functionalGroups,
            signatureLevelsCounts,
            signatureType,
            topAmount,
            signatureDispatch,
            alias,
        } = this.props;

        const secondFactor = credentials;

        if (!idTransaction) {
            actions.createSignaturesSchemeRequest(
                {
                    signatureAlias: alias,
                    secondFactor,
                    functionalGroups: Object.keys(functionalGroups),
                    signatureLevelsCounts,
                    signatureType,
                    topAmount,
                    signatureDispatch,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    renderFunctionalGroups = (groups, functionalGroups) => {
        const accordionList = getAccordionGroups(groups, functionalGroups);
        return (
            <Box>
                <Text component="h4" bold labelKey="administration.signatures.functionalGroups.label" />
                <Text component="h4" bold labelKey="administration.permissions.title.cashProducts" className="my-6" />
                <Accordion>
                    {accordionList.map((item, i) => (
                        <Accordion.Item
                            key={item.id}
                            number={i}
                            item={
                                <Box className="text-bold" bold>
                                    {item.label}
                                </Box>
                            }>
                            <Box>
                                <Box className="mt-3 mb-5">
                                    <Text
                                        labelKey="administration.permissions.title.cashServices"
                                        bold
                                        color="text-disabled-color"
                                    />
                                </Box>
                                {item.categories.map((category) => (
                                    <>
                                        {category.id && category.label && (
                                            <Box key={category.id} className="mb-3 mt-2 color-text-disabled-color">
                                                {category.label}
                                            </Box>
                                        )}
                                        {category.features.map((feature) => (
                                            <Box
                                                key={feature.id}
                                                background="primary-background-color"
                                                className="py-3 px-7 mb-4 color-primary fit-content-width"
                                                borderRadius="xxl">
                                                {feature.label}
                                            </Box>
                                        ))}
                                    </>
                                ))}
                            </Box>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </Box>
        );
    };

    renderSchemeReference = (administrationScheme) => (
        <div>
            <Heading.DataGroup
                containerClassName="data-wrapper data-confirm"
                label={
                    administrationScheme === "medium"
                        ? `administration.signatures.create.${administrationScheme}.confirm.signersCount`
                        : `administration.signatures.create.${administrationScheme}.confirm.schemeReference`
                }
                data={this.signersCount()}
            />
        </div>
    );

    renderTopAmount = (topAmount) => (
        <div>
            <Heading.DataGroup
                containerClassName="data-wrapper data-confirm"
                label="administration.signatures.create.topAmount.label"
                data={
                    <Fragment>
                        <FormattedAmount
                            noCurrency
                            frequency={i18nUtils.get(
                                "administration.signatures.create.advanced.topAmountPerPeriod",
                                null,
                                {
                                    period: i18nUtils.get(
                                        `administration.signatures.transactions.capFrequency.${topAmount.period}`,
                                    ),
                                },
                            )}
                            quantity={topAmount.amount}
                        />
                    </Fragment>
                }
            />
        </div>
    );

    renderTransactionType = (administrationScheme, signatureType) => (
        <div>
            <Heading.DataGroup
                containerClassName="data-wrapper data-confirm"
                label={`administration.signatures.create.${administrationScheme}.confirm.transactionType`}
                data={i18nUtils.get(`administration.signatures.create.signatureType.${signatureType}`)}
            />
        </div>
    );

    renderAlias = (alias) => (
        <div>
            <Heading.DataGroup
                containerClassName="data-wrapper data-confirm mb-0"
                label="administration.signatures.create.alias.label"
                data={alias}
            />
        </div>
    );

    signersCount = () => {
        const {
            activeEnvironment: { administrationScheme },
            signatureLevelsCounts,
        } = this.props;

        return administrationScheme === "medium"
            ? `${signatureLevelsCounts.A}`
            : Object.keys(signatureLevelsCounts)
                  .sort()
                  .reduce((result, key) => result + key.toString().repeat(signatureLevelsCounts[key]), "");
    };

    handleBack = () => {
        const {
            routerActions,
            activeEnvironment: { administrationScheme },
        } = this.props;
        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes`);
    };

    render() {
        const {
            // alias,
            credentialGroups,
            activeEnvironment: { administrationScheme },
            fetching,
            // groups,
            // functionalGroups,
            // // routerActions,
            // signatureLevelsCounts,
            // signatureType,
            // topAmount,
        } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="administrationCreateSignatureScheme" />
                <Head
                    title={`administration.signatures.create.${administrationScheme}.confirm.title`}
                    // onBack={routerActions.goBack}
                    onBack={this.handleBack}
                    textBack="administration.signatures.returnToList"
                />
                <MainContainer showLoader={fetching}>
                    <ConfirmData {...this.props} />
                    <Box>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                            onClickCancel={this.handleBack}
                            idActivity="administration.signatures.create.send"
                        />
                    </Box>
                </MainContainer>
            </Fragment>
        );
    }
}

export default SignaturesSchemeCreate;
