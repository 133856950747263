import classNames from "classnames";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import withFocus from "pages/_components/withFocus";
import Select from "pages/forms/_components/_fields/Select";
import { arrayOf, bool, func, oneOfType, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";

import * as i18n from "util/i18n";

class DocumentField extends Component {
    componentDidUpdate(prevProps) {
        const { form, field, defaultDocumentType, defaultCountry } = this.props;
        if (!prevProps.defaultDocumentType && defaultDocumentType && defaultCountry) {
            form.setFieldValue(field.name, {
                ...field.value,
                type: defaultDocumentType,
                country: defaultCountry,
            });
        }
    }

    handleCountryChange = (selectedOption) => {
        const {
            data: { documentTypes },
            field,
            form,
        } = this.props;

        form.setFieldValue(field.name, {
            ...field.value,
            country: selectedOption ? selectedOption.value : null,
            type: selectedOption
                ? documentTypes.filter((documentType) => documentType.id_country_code === selectedOption.value)[0]
                      .id_document_type
                : null,
        });
    };

    handleDocumentChange = (event) => {
        const { field, form, handleDocumentChange } = this.props;

        if (handleDocumentChange) {
            handleDocumentChange(event);
        }

        form.setFieldValue(field.name, {
            ...field.value,
            document: event.target.value,
        });
    };

    handleTypeChange = (selectedOption) => {
        const { field, form } = this.props;

        form.setFieldValue(field.name, {
            ...field.value,
            type: selectedOption ? selectedOption.value : null,
        });
    };

    getLabelForValue = (val, options) => {
        if (val && val !== "") {
            return options.find((opt) => opt.value === val)?.label || "  ";
            // return options.map( opt =>  {
            //     if (opt.value === val) {
            //         return opt.label;
            //     }
            // });
        }
        return "  ";
    };

    render() {
        const {
            clearable,
            data: { countries, documentTypes },
            defaultDocumentType,
            disableDocumentTypeSelect,
            disableSelect,
            field,
            form: { touched, errors },
            hideDocumentCountryLabel,
            hideDocumentPlaceholder,
            idForm,
            inputRef,
            searchable,
            mode,
            noMarginBottom,
        } = this.props;

        const hasError = {
            countries:
                touched[field.name] && errors[field.name]
                    ? touched[field.name].country && errors[field.name].country
                    : false,
            document:
                touched[field.name] && errors[field.name]
                    ? touched[field.name].document && errors[field.name].document
                    : false,
            documentTypes:
                touched[field.name] && errors[field.name] ? touched[field.name].type && errors[field.name].type : false,
        };

        const countryOptions = countries
            ? countries.map(({ id, name }) => ({
                  value: id,
                  label: name,
              }))
            : [];

        const typesOptions =
            documentTypes && field.value.country
                ? documentTypes
                      .filter((documentType) => documentType.id_country_code === field.value.country)
                      .map(({ id_document_type }) => ({
                          value: id_document_type,
                          label: id_document_type,
                      }))
                : [];

        return (
            <Fragment>
                <div
                    className={classNames("form-group", {
                        "has-error": hasError.countries,
                        "form-group-margin-bottom": !noMarginBottom,
                    })}>
                    {!hideDocumentCountryLabel && <FieldLabel labelKey={`${idForm}.${field.name}Country.label`} />}
                    {mode === "edit" ? (
                        <>
                            <div className="input-group">
                                <Select
                                    placeholder=""
                                    clearable={clearable}
                                    className="flex-container"
                                    disabled={disableSelect}
                                    onChange={this.handleCountryChange}
                                    options={countryOptions}
                                    searchable={searchable}
                                    matchProp="any"
                                    value={field.value.country}
                                />
                            </div>
                            {hasError.countries && <FieldError error={errors[field.name].country} />}
                        </>
                    ) : (
                        <div>{this.getLabelForValue(field.value.country, countryOptions)}</div>
                    )}
                </div>
                <div
                    className={classNames("form-group", "form-group--composite", {
                        "has-error": hasError.documentType || hasError.document,
                        "form-group-margin-bottom": !noMarginBottom,
                    })}>
                    {!hideDocumentCountryLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
                    {mode === "edit" ? (
                        <>
                            <div className="input-group">
                                <Select
                                    placeholder=""
                                    noResultsText=""
                                    clearable={clearable}
                                    className="currency-selector slideFromBottom flex-container"
                                    disabled={disableDocumentTypeSelect}
                                    onChange={this.handleTypeChange}
                                    options={typesOptions}
                                    searchable={false}
                                    value={field.value.type || defaultDocumentType}
                                />
                                <input
                                    className="form-control"
                                    placeholder={
                                        hideDocumentPlaceholder ? "" : i18n.get(`${idForm}.${field.name}.placeholder`)
                                    }
                                    ref={inputRef}
                                    {...field}
                                    onChange={this.handleDocumentChange}
                                    value={field.value.document}
                                />
                            </div>
                            {hasError.documentTypes && errors[field.name].type !== errors[field.name].document && (
                                <FieldError error={errors[field.name].type} />
                            )}
                            {hasError.document && <FieldError error={errors[field.name].document} />}
                        </>
                    ) : (
                        <div>
                            {`${this.getLabelForValue(field.value.type, typesOptions)} ${
                                field.value.document ? field.value.document : ""
                            }`.replaceAll(",", "")}{" "}
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

DocumentField.propTypes = {
    clearable: bool,
    countries: arrayOf(shape({})),
    disableDocumentTypeSelect: bool,
    disableSelect: bool,
    documentTypes: arrayOf(shape({})),
    hideDocumentCountryLabel: bool,
    hideDocumentPlaceholder: bool,
    searchable: bool,
    mode: string,
    form: shape({}).isRequired,
    field: shape({}).isRequired,
    defaultDocumentType: string,
    defaultCountry: string,
    data: shape({}),
    handleDocumentChange: func,
    idForm: string,
    inputRef: oneOfType([func, shape({})]),
    noMarginBottom: bool,
};

DocumentField.defaultProps = {
    clearable: true,
    disableSelect: false,
    disableDocumentTypeSelect: false,
    hideDocumentCountryLabel: false,
    hideDocumentPlaceholder: false,
    searchable: true,
    countries: [],
    documentTypes: [],
    mode: "edit",
    defaultDocumentType: null,
    defaultCountry: null,
    data: null,
    handleDocumentChange: null,
    idForm: null,
    inputRef: null,
    noMarginBottom: false,
};

export default withFocus(DocumentField);
