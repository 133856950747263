import React, { Component } from "react";
import { connect } from "react-redux";
import { func, arrayOf, shape } from "prop-types";

import { actions as templateActions, selectors as templateSelectors } from "reducers/template";

import List from "pages/_components/List";
import TemplateListItem from "pages/forms/_components/TemplateListItem";

class TemplateList extends Component {
    static propTypes = {
        onSelect: func.isRequired,
        dispatch: func.isRequired,
        // TODO this values are dynamic, need to the define the correct type
        templates: arrayOf(shape({})).isRequired,
    };

    handleSelect = (num) => {
        const { onSelect, templates } = this.props;
        onSelect(templates[num].transactionData);
    };

    handleDelete = (item) => {
        const { dispatch } = this.props;
        dispatch(templateActions.deleteTemplate(item));
    };

    render() {
        const { templates } = this.props;
        return (
            <List onSelect={this.handleSelect}>
                {templates.map((item, index) => (
                    <TemplateListItem key={item.name} item={item} num={index} onDelete={this.handleDelete} />
                ))}
            </List>
        );
    }
}

const mapStateToProps = (state) => ({
    templates: templateSelectors.getTemplateList(state),
});

export default connect(mapStateToProps)(TemplateList);
