import React, { Component } from "react";
import { connect } from "react-redux";
import { node, func, shape, bool } from "prop-types";
import { Col } from "react-bootstrap";
import { Chart as DonutChart } from "react-google-charts";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as desktopSelector, actions } from "reducers/desktop";
import * as configUtils from "util/config";

import { resizableRoute } from "pages/_components/Resizable";
import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import ClientData from "pages/desktop/widgets/economicGroup/ClientData";
import Filter from "pages/desktop/widgets/economicGroup/Filter";

class EconomicGroups extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        corporateGroupDesktopData: shape({}).isRequired,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.loadCorporateGroupDesktopRequest());
    }

    render() {
        const { isDesktop, corporateGroupDesktopData } = this.props;

        if (Object.keys(corporateGroupDesktopData).length > 0) {
            return (
                <Wrapper>
                    <Chart corporateGroupDesktopData={corporateGroupDesktopData} />
                    <ClientsTable corporateGroupDesktopData={corporateGroupDesktopData} isDesktop={isDesktop} />
                </Wrapper>
            );
        }
        return null;
    }
}

const possibleColors = [
    { color: "#005CD5" },
    { color: "#F2B705" },
    { color: "#BDD4F2" },
    { color: "#D91604" },
    { color: "#05BAF0" },
    { color: "#5DD600" },
    { color: "#05f0b5" },
    { color: "#B505F0" },
    { color: "#D6005D" },
    { color: "#BCD028" },
];

const Wrapper = ({ children }) => (
    <Container className="container--layout flex-grow-1" gridClassName="container-fluid">
        <Col sm={12} className="col-12">
            <div className="widget">
                <div className="widget--portfolio">
                    <div className="row">{children}</div>
                </div>
            </div>
        </Col>
    </Container>
);

Wrapper.propTypes = {
    children: node.isRequired,
};

const Chart = (data) => {
    const elems = data.corporateGroupDesktopData;
    const chartData = [];
    let total = 0;
    const slices = {};
    let colorIndex = 0;
    chartData.push(["Assets", "Amount"]);
    Object.keys(elems).forEach((client) => {
        chartData.push([client, elems[client].total]);
        total += elems[client].total;
        slices[colorIndex] = possibleColors[colorIndex % possibleColors.length];
        colorIndex += 1;
    });

    const chartOptions = {
        backgroundColor: "transparent",
        legend: "none",
        pieHole: 0.975,
        pieSliceText: "none",
        tooltip: {
            trigger: "none",
        },
        chartArea: {
            left: "15",
            right: "15",
            top: "15",
            bottom: "15",
            width: "100%",
            height: "100%",
        },
        slices,
    };

    return (
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 chart">
            <div className="row">
                <DonutChart
                    height="300px"
                    width="400px"
                    chartType="PieChart"
                    data={chartData}
                    options={chartOptions}
                    graph_id="DonutChart"
                    loader={<div />}
                />
                <div className="col-md-12 col-sm-12 col-12 data-chart text-center">
                    <I18n id="desktop.corporateGroup.totalAmount" component="small" />
                    <p className="amount">
                        <FormattedAmount
                            quantity={total}
                            currency={configUtils.get("core.masterCurrency")}
                            showAbbreviature
                        />
                    </p>
                </div>
            </div>
        </div>
    );
};

const ClientsTable = (data) => (
    <div className="col-12">
        <I18n id="desktop.corporateGroup.client" component="h2" />
        {data.isDesktop && <Filter />}

        <div className="table">
            <div className="table-body">
                {Object.keys(data.corporateGroupDesktopData).map((clientId, index) => (
                    <ClientData
                        key={clientId}
                        clientId={clientId}
                        clientData={data.corporateGroupDesktopData[clientId]}
                        referenceColor={possibleColors[index % possibleColors.length].color}
                    />
                ))}
            </div>
        </div>
    </div>
);

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    corporateGroupDesktopData: desktopSelector.getCorporateGroupDesktopData(state),
    environments: sessionSelectors.getEnvironments(state),
});

export default connect(mapStateToProps)(resizableRoute(EconomicGroups));
