import globalTypes from "reducers/types/global";
import { types as sessionTypes } from "reducers/session";
import { types as loginTypes } from "reducers/login";

export const types = {
    SHOW_SESSION_EXPIRATION_MODAL: "status/SHOW_SESSION_EXPIRATION_MODAL",
    HIDE_SESSION_EXPIRATION_MODAL: "status/HIDE_SESSION_EXPIRATION_MODAL",
    SHOW_SESSION_CLOSE_MODAL: "status/SHOW_SESSION_CLOSE_MODAL",
    HIDE_SESSION_CLOSE_MODAL: "status/HIDE_SESSION_CLOSE_MODAL",
    SAVE_LAST_HREF: "status/SAVE_LAST_HREF",
    DELETE_LAST_HREF: "status/DELETE_LAST_HREF",
    RESET_COME_FROM_LOGIN: "status/RESET_COME_FROM_LOGIN",
    HAS_GO_TO_LOGIN_STEP1: "status/HAS_GO_TO_LOGIN_STEP1",
};

export const INITIAL_STATE = {
    sessionAboutToExpire: false,
    sessionSecondsToExpire: 0,
    sessionExtendedCount: 0,
    lastHref: null,
    comeFromLogin: false,
    showModalCloseSesion: false,
    hasGoToLoginStep1: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.SHOW_SESSION_EXPIRATION_MODAL:
            return {
                ...state,
                sessionAboutToExpire: true,
                sessionSecondsToExpire: action.secondsToExpire,
                showModalCloseSesion: false,
            };
        case types.SHOW_SESSION_CLOSE_MODAL:
            return {
                ...state,
                showModalCloseSesion: true,
            };
        case types.HIDE_SESSION_CLOSE_MODAL:
            return {
                ...state,
                showModalCloseSesion: false,
            };

        case types.SAVE_LAST_HREF:
            return { ...state, lastHref: action.lastHref };

        case types.DELETE_LAST_HREF:
            return { ...state, lastHref: null };

        case types.HAS_GO_TO_LOGIN_STEP1:
            return { ...state, hasGoToLoginStep1: true };

        // TODO: Delete this if oauth is the only flavour
        case loginTypes.LOGIN_STEP_2_REQUEST:
            return { ...state, comeFromLogin: true };

        case loginTypes.LOGIN_OAUTH_REQUEST:
            return { ...state, comeFromLogin: true };

        case types.RESET_COME_FROM_LOGIN:
            return { ...state, comeFromLogin: false };

        case sessionTypes.EXTEND_SUCCESS:
            return {
                ...state,
                sessionAboutToExpire: false,
                sessionSecondsToExpire: 0,
                sessionExtendedCount: state.sessionExtendedCount + 1,
            };

        case globalTypes.CLEAN_UP:
            return INITIAL_STATE;

        default:
            return state;
    }
};

export const actions = {
    saveLastHref: (lastHref) => ({
        type: types.SAVE_LAST_HREF,
        lastHref,
    }),
    deleteLastHref: () => ({
        type: types.DELETE_LAST_HREF,
    }),
    showSessionCloseModal: () => ({
        type: types.SHOW_SESSION_CLOSE_MODAL,
    }),
    hideSessionCloseModal: () => ({
        type: types.HIDE_SESSION_CLOSE_MODAL,
    }),
    resetComeFromLogin: () => ({
        type: types.RESET_COME_FROM_LOGIN,
    }),
    changeGoToLoginStep1: () => ({
        type: types.HAS_GO_TO_LOGIN_STEP1,
    }),
};

export const selectors = {
    getStatus: (state) => state.status,
    isComeFromLogin: (state) => state.status.comeFromLogin,
    hasGoToLogin: (state) => state.status.hasGoToLoginStep1,
};
