import React, { Component } from "react";
import { func, node } from "prop-types";
import Tabs from "pages/_components/Tabs";

class DepositsTabs extends Component {
    static propTypes = {
        children: node.isRequired,
        onSelect: func.isRequired,
    };

    render() {
        const { onSelect, children } = this.props;
        return <Tabs className="pt-0" components={children} onSelect={onSelect}/>;
    }
}

export default DepositsTabs;
