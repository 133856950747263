import React, { Component, Fragment } from "react";
import Measure from "react-measure";
import { func, arrayOf, shape, bool, string, number } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { actions, selectors } from "reducers/administration/groups";
import * as i18n from "util/i18n";

import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/fields/Checkbox";
import Image from "pages/_components/Image";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import { filterData } from "util/array";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Head from "pages/_components/Head";
import PaginatedDataTable from "pages/_components/PaginatedDataTable";
import * as configUtils from "util/config";
import Info from "pages/_components/Info";
import Notification from "pages/_components/Notification";

const MAX_LENGTH_GROUPS_SEARCH = 40;

class AdministrationGroups extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        groups: arrayOf(shape({ idGroup: number, name: string, status: string, usersInGroup: number, blocked: bool }))
            .isRequired,
        groupsExtendedInfo: shape({}),
        fetching: bool,
        fetchingExport: bool,
        fetchingMoreGroups: bool,
        currentPage: number,
        totalPages: number,
        hasMoreData: bool,
    };

    static defaultProps = {
        fetching: false,
        fetchingExport: false,
        fetchingMoreGroups: false,
        currentPage: 0,
        totalPages: 0,
        hasMoreData: false,
        groupsExtendedInfo: null,
    };

    state = {
        tableHeight: 0,
        selectedGroups: new Map(),
        selectAllChecked: false,
        blockedQuantity: 0,
        unblockedQuantity: 0,
        listToBeFiltered: [],
        rowsPerPage: configUtils.getInteger("administration.rowsPerPage"),
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.loadListRequest());
        const { groups } = this.props;
        if (groups) {
            this.setState({
                listToBeFiltered: groups,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { groups } = this.props;
        if (prevProps.groups !== groups) {
            this.setState({ listToBeFiltered: groups });
        }
    }

    onRowClick = ({ idGroup }) => {
        const { dispatch } = this.props;

        dispatch(routerActions.push(`/administration/advanced/group/${idGroup}/details`));
    };

    generateTableColumns = (massiveDisabledQuantity) => {
        const { selectAllChecked, selectedGroups } = this.state;
        // const { groups } = this.props;
        const { listToBeFiltered: groups } = this.state;
        const columnArray = [
            {
                key: "check",
                dataIndex: "check",
                title: (
                    <Checkbox
                        hideLabel
                        onChange={this.handleSelectAll}
                        name="selectAllGroups"
                        checked={selectAllChecked || groups.length === selectedGroups.length + massiveDisabledQuantity}
                        disabled={groups.length === massiveDisabledQuantity}
                    />
                ),
                width: 20,
                onCell: () => ({
                    onClick(e) {
                        e.stopPropagation();
                    },
                }),
            },
            {
                key: "groupName",
                dataIndex: "groupName",
                title: i18n.get("administration.groups.list.name"),
            },
            {
                key: "description",
                dataIndex: "description",
                title: i18n.get("administration.groups.list.description"),
            },
            {
                key: "usersInGrouop",
                dataIndex: "usersInGrouop",
                className: "text-right",
                title: i18n.get("administration.groups.list.usersInGroup"),
            },
            {
                className: "text-right pr-3",
                key: "groupStatus",
                dataIndex: "groupStatus",
                title: i18n.get("administration.groups.list.status"),
            },
        ];
        return columnArray;
    };

    handleFilterData = (filter) => {
        const { groups: dataToFilter } = this.props;
        if (filter !== "") {
            const data = filterData([...dataToFilter], ["name"], filter);
            this.setState({ listToBeFiltered: data });
        } else {
            this.setState({ listToBeFiltered: [...dataToFilter] });
        }
    };

    populateGroupsData = (data) => {
        const { groupsExtendedInfo } = this.props;
        const { selectedGroups, selectAllChecked } = this.state;

        const massiveDisabledQuantity = Object.values(groupsExtendedInfo).filter((group) => !group.massiveEnabled);
        return data.map(({ idGroup, name, description, usersInGroup, blocked, status }) => ({
            key: idGroup.toString(),
            check: (
                <Checkbox
                    onChange={() => this.handleCheckClick(idGroup, name, massiveDisabledQuantity)}
                    hideLabel
                    id={idGroup}
                    name={idGroup.toString()}
                    checked={
                        groupsExtendedInfo[idGroup]?.massiveEnabled && (selectAllChecked || selectedGroups.has(idGroup))
                    }
                    disabled={!groupsExtendedInfo[idGroup]?.massiveEnabled}
                />
            ),
            idGroup,
            groupName: groupsExtendedInfo[idGroup]?.massiveEnabled ? (
                <Text component="span" className="data-desc" bold defaultValue={name} />
            ) : (
                <div className="display-flex align-content-start">
                    <Box className="mr-3">
                        <Image src="images/administrator.svg" className="svg-icon svg-caret primary-color" />
                    </Box>
                    <Text component="span" className="data-desc" bold defaultValue={name} />
                </div>
            ),
            description: (
                <div className="data-wrapper data-wrapper-flex">
                    <span className="data-desc">{description}</span>
                </div>
            ),
            usersInGrouop: (
                <div>
                    <span className="data-desc text-right">{usersInGroup}</span>
                </div>
            ),
            groupStatus: (
                <Box display="flex" alignX="end">
                    <Info
                        className={`info-user-list ${blocked ? "info-user-blocked" : "info-user-active"}`}
                        text={status}
                    />
                </Box>
            ),
        }));
    };

    renderPageHeader = () => (
        <Head
            title="administration.groups.list.title"
            addLinkTo="/administration/advanced/group/create"
            addLinkToLabel="administration.advanced.group.create.link.text"
            addButtonBigPadding
        />
    );

    handleCheckClick = (idGroupChecked, nameGroupChecked, massiveDisabledQuantity) => {
        // const { groups } = this.props;
        const { listToBeFiltered: groups } = this.state;
        let { blockedQuantity, unblockedQuantity } = this.state;
        const { selectedGroups, selectAllChecked } = this.state;
        const groupChecked = groups.filter((group) => group.idGroup === idGroupChecked);

        if (!selectedGroups.has(idGroupChecked)) {
            selectedGroups.set(idGroupChecked, nameGroupChecked);
            if (groupChecked[0].blocked) {
                blockedQuantity += 1;
            } else {
                unblockedQuantity += 1;
            }
            if (selectedGroups.size === groups.length - massiveDisabledQuantity) {
                this.setState({
                    selectAllChecked: true,
                });
            }
        } else {
            selectedGroups.delete(idGroupChecked);
            if (groupChecked[0].blocked) {
                blockedQuantity -= 1;
            } else {
                unblockedQuantity -= 1;
            }
            if (selectAllChecked) {
                this.setState({ selectAllChecked: false });
            }
        }
        this.setState({
            selectedGroups,
            blockedQuantity,
            unblockedQuantity,
        });
    };

    handleSelectAll = () => {
        const { selectAllChecked } = this.state;
        const { /* groups, */ groupsExtendedInfo } = this.props;
        const { listToBeFiltered: groups } = this.state;
        const newSelectedGroups = new Map();
        let blockedQuantity = 0;
        let unblockedQuantity = 0;
        if (!selectAllChecked) {
            groups.forEach((group) => {
                if (groupsExtendedInfo[group.idGroup].massiveEnabled) {
                    newSelectedGroups.set(group.idGroup, group.name);
                    if (group.blocked) {
                        blockedQuantity += 1;
                    } else {
                        unblockedQuantity += 1;
                    }
                }
            });
        }
        this.setState((prevState) => ({
            selectAllChecked: !prevState.selectAllChecked,
            selectedGroups: newSelectedGroups,
            blockedQuantity,
            unblockedQuantity,
        }));
    };

    handleMoreDataClick = () => {
        const { dispatch, currentPage } = this.props;

        this.setState({ selectAllChecked: false });
        dispatch(actions.loadMoreRequest({ pageNumber: currentPage + 1 }));
    };

    handleClickDownload = (format) => {
        const { dispatch } = this.props;

        dispatch(actions.exportListRequest(format));
    };

    handleActionClick = (action) => {
        const { dispatch } = this.props;
        const { selectedGroups } = this.state;

        dispatch(actions.changeGroupStatusPreview([...selectedGroups.keys()], [...selectedGroups.values()], action));
    };

    renderActionButtons = () => {
        const { selectedGroups, blockedQuantity, unblockedQuantity } = this.state;

        // if (selectedGroups.size > 0) {
        return (
            <Fragment>
                <Box display="flex" alignX="end" alignY="center" className="my-5">
                    {selectedGroups.size > 0 && (
                        <div
                            className="color-heading-2 mr-5"
                            style={{
                                display: "inline-block",
                            }}>
                            {selectedGroups.size}{" "}
                            {i18n.get(
                                `administration.groups.list.selected${selectedGroups.size > 1 ? "" : ".one"}.quantity`,
                            )}
                        </div>
                    )}
                    <Box display="flex">
                        {blockedQuantity === 0 && (
                            <Box display="flex" className="mr-3">
                                <Button
                                    className="btn btn-small btn-outline"
                                    key="block"
                                    label="administration.block"
                                    disabled={selectedGroups.size === 0}
                                    onClick={() => this.handleActionClick("block")}
                                />
                            </Box>
                        )}
                        {blockedQuantity > 0 && unblockedQuantity === 0 && (
                            <Box display="flex" className="mr-3">
                                <Button
                                    className="btn btn-small btn-outline"
                                    key="unblock"
                                    label="administration.unblock"
                                    disabled={selectedGroups.size === 0}
                                    onClick={() => this.handleActionClick("unblock")}
                                />
                            </Box>
                        )}

                        <Button
                            className="btn btn-small btn-outline ml-3"
                            key="delete"
                            label="administration.delete"
                            disabled={selectedGroups.size === 0}
                            onClick={() => this.handleActionClick("delete")}
                        />
                    </Box>
                </Box>
            </Fragment>
        );
        // }
        // return null;
    };

    renderTableFooter = () => {
        const { totalPages, hasMoreData, fetchingMoreGroups } = this.props;

        return (
            <div>
                {totalPages > 1 &&
                    (hasMoreData ? (
                        <div className="text-center no-more-data" key="noMoreUsers">
                            <Button
                                className="btn btn-link"
                                onClick={this.handleMoreDataClick}
                                loading={fetchingMoreGroups}
                                image="images/show.svg"
                                label="administration.groups.list.more"
                            />
                        </div>
                    ) : (
                        <div className="text-center no-more-data" key="noMoreUsers">
                            <p className="text-lead">
                                <I18n id="administration.groups.list.more.noMoreData" />
                            </p>
                        </div>
                    ))}
            </div>
        );
    };

    render() {
        const { groups, groupsExtendedInfo, fetching } = this.props;
        const { tableHeight, rowsPerPage, listToBeFiltered } = this.state;
        const massiveDisabledQuantity = Object.values(groupsExtendedInfo).filter((group) => !group.massiveEnabled)
            .length;

        return (
            <Fragment>
                <Notification scopeToShow="administration" />
                {this.renderPageHeader()}
                <MainContainer
                    className="main-container border-radius-lg p-7 mt-3 box-shadow-small background-white texture-header"
                    showLoader={fetching}>
                    <div className="above-the-fold">
                        <Container
                            className="container--layout container--scrollable-table flex-grow-1 items-center"
                            gridClassName="form-content">
                            <Col className="col-12">
                                <Box className="flex-container">
                                    {/* {selectedGroups.size === 0 ? ( */}
                                    <Fragment>
                                        <Box
                                            fullWidth
                                            display="flex"
                                            className="px-7 py-4 mb-5 border-radius-lg box-shadow-small background-white">
                                            <Box fullWidth>
                                                <Box>
                                                    <Text
                                                        component="h3"
                                                        labelKey="administration.groups.index.title"
                                                        bold
                                                    />
                                                </Box>
                                                <Box className="mt-5">
                                                    <Text
                                                        labelKey="administration.groups.list.header.table.description"
                                                        color="text-disabled-color"
                                                    />
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Image src="images/administration/roles.svg" />
                                            </Box>
                                        </Box>
                                    </Fragment>
                                </Box>
                                {tableHeight ? (
                                    <>
                                        <TextSimpleField
                                            name="search"
                                            placeholder={i18n.get("administration.groups.search")}
                                            value=""
                                            onChange={(e) => this.handleFilterData(e.target.value)}
                                            serarchStyle
                                            maxLength={MAX_LENGTH_GROUPS_SEARCH}
                                        />
                                        {this.renderActionButtons()}
                                        <PaginatedDataTable
                                            data={listToBeFiltered}
                                            columns={this.generateTableColumns(massiveDisabledQuantity)}
                                            populateRows={this.populateGroupsData}
                                            rowsPerPage={rowsPerPage}
                                            onRowClick={this.onRowClick}
                                            hasFilterApplied={groups?.length !== listToBeFiltered?.length}
                                        />
                                    </>
                                ) : (
                                    <Measure
                                        bounds
                                        onResize={({ bounds }) => this.setState({ tableHeight: bounds.height })}>
                                        {({ measureRef }) => (
                                            <div ref={measureRef} style={{ height: "100%", width: "100%" }} />
                                        )}
                                    </Measure>
                                )}
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    groups: selectors.getGroups(state),
    groupsExtendedInfo: selectors.getGroupsExtendedInfo(state),
    currentPage: selectors.getCurrentPage(state),
    totalPages: selectors.getTotalPages(state),
    hasMoreData: selectors.getHasMoreData(state),
    fetching: selectors.isFetching(state),
    fetchingMoreGroups: selectors.isFetchingMoreGroups(state),
    fetchingExport: selectors.isFetchingExport(state),
});

export default connect(mapStateToProps)(AdministrationGroups);
