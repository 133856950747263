import { bool, func, oneOfType, shape } from "prop-types";
import React, { Component } from "react";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import Movement from "./Movement";
// import AdditionalHeader from "./AdditionalHeader";
import Box from "pages/_components/Box";
import { dictionaryMiniatureCard } from "util/creditCards.js";
import NoResults from "pages/_components/NoResultsMessage";
import Row from "pages/_components/Row";
import Col from "react-bootstrap/lib/Col";
import { isDesktop } from "react-device-detect";
import * as i18nUtils from "util/i18n";

class CreditCardAdditionalList extends Component {
    static propTypes = {
        creditCards: oneOfType([shape({})]).isRequired,
        showProductIcon: bool,
        setFavoriteAccount: func,
        completeFavorites: bool,
    };

    static defaultProps = {
        showProductIcon: true,
        setFavoriteAccount: () => {},
        completeFavorites: false,
    };

    renderEmptyResults = () => (
        <Box className="my-9">
            <NoResults />
        </Box>
    );

    renderList = () => {
        const { creditCards, isRequestAvailable } = this.props;
        if (!creditCards.length) {
            return null;
        }
        return (
            <>
                {creditCards.length === 0 && <>{this.renderEmptyResults()}</>}
                {creditCards.length > 0 && (
                    // children-bg-color
                    <Box className="children-bg-color paginate-table children-alternate-bg-color full-height full-width">
                        {isDesktop && (
                            <Box display="flex" className="movs-header-cols" borderRadius="md" fullWidth>
                                <Box display="flex" fullWidth alignY="center" className="p-5 size-6 pr-10 mr-10">
                                    <I18n id="creditCard.details.info.nameAndNumber" />
                                </Box>
                                <Box
                                    display="flex"
                                    fullWidth
                                    alignY="center"
                                    className="p-5 size-6 justify-content-flex-end">
                                    <I18n id="creditCard.details.info.USDMinimumBalance" />
                                </Box>
                                <Box
                                    display="flex"
                                    fullWidth
                                    alignY="center"
                                    className="p-5 size-6 display-flex justify-content-flex-end">
                                    <I18n id="creditCard.details.info.USDAssignedQuota" />
                                </Box>
                                <Box
                                    display="flex"
                                    fullWidth
                                    alignY="center"
                                    className="p-5 size-6 justify-content-flex-end">
                                    <I18n id="creditCard.details.info.USDAvailableQuota" />
                                </Box>
                            </Box>
                        )}
                        {creditCards.map((creditCard, index) => (
                            <>
                                {isDesktop ? (
                                    <Box display="flex" className="movs-header-cols" borderRadius="md" fullWidth>
                                        <Box display="flex" fullWidth alignY="center" className="p-1">
                                            <>
                                                <Col xs={6}>
                                                    <Image
                                                        src={`images/${dictionaryMiniatureCard(
                                                            creditCard.cardStyle,
                                                        )}-icon-creditCard.svg`}
                                                        className="fill-transparent justify-content-flex-start svg-icon mr-2 mt-2"
                                                        width="50"
                                                    />
                                                </Col>
                                                <Col xs={6}>
                                                    <Row>
                                                        <Col>
                                                            <span className="size-7 text-bold">
                                                                {creditCard.cardStyle} {creditCard.shortLabel?.slice(-9)}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <span className="size-7 mt-1"> {creditCard.nameOnCard}</span>
                                                    </Row>
                                                </Col>
                                            </>
                                        </Box>
                                        <Box
                                            display="flex"
                                            fullWidth
                                            alignY="center"
                                            className="p-5 size-6 justify-content-flex-end">
                                            <span className="size-7">{creditCard.minimumPayment}</span>
                                        </Box>
                                        <Box
                                            display="flex"
                                            fullWidth
                                            alignY="center"
                                            className="p-5 size-6 display-flex justify-content-flex-end">
                                            {/* <span className="size-6">Cupo asignado USD</span> */}
                                            <span className="size-7">{creditCard.assignedQuota}</span>
                                        </Box>
                                        <Box
                                            display="flex"
                                            fullWidth
                                            alignY="right"
                                            className="p-5 size-6 text-right justify-content-flex-end">
                                            <span className="size-7">{creditCard.availableQuota}</span>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box borderRadius="md">
                                        <Box display="flex" className="pt-3">
                                            <Box className="mr-2 pt-2 pl-2">
                                                <Image
                                                    className="img-card-mobile mt-3"
                                                    src={`images/${dictionaryMiniatureCard(
                                                        creditCard.cardStyle,
                                                    )}-icon-creditCard.svg`}
                                                    width="10"
                                                />
                                            </Box>
                                            <Box className="p2">
                                                <Col xs={6}>
                                                    <Row>
                                                        <Col>
                                                            <span className="size-7">
                                                                {i18nUtils.get("creditCard.details.info.nameAndNumber")}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <span className="size-7 text-bold">
                                                                {`${creditCard.cardStyle} ${creditCard.shortLabel?.slice(
                                                                    -9,
                                                                )}`}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <span className="size-7 mt-1">{creditCard.nameOnCard}</span>
                                                    </Row>
                                                </Col>
                                            </Box>
                                        </Box>
                                        <Box display="flex" className="p-3">
                                            <Col>
                                                <Row>
                                                    <span className="size-7">
                                                        {i18nUtils.get("creditCard.details.info.USDMinimumBalance")}
                                                    </span>
                                                </Row>
                                                <Row>
                                                    <span className="size-7"> {creditCard.minimumPayment}</span>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <span className="size-7">
                                                        {i18nUtils.get("creditCard.details.info.USDAvailableQuota")}
                                                    </span>
                                                </Row>
                                                <Row>
                                                    <span className="size-7"> {creditCard.availableQuota}</span>
                                                </Row>
                                            </Col>
                                        </Box>
                                        <Box display="flex" className="pl-3 pb-3 pt-1">
                                            <Col>
                                                <Row>
                                                    <span className="size-7">
                                                        {i18nUtils.get("creditCard.details.info.USDAssignedQuota")}
                                                    </span>
                                                </Row>
                                                <Row>
                                                    <span className="size-7"> {creditCard.assignedQuota}</span>
                                                </Row>
                                            </Col>
                                        </Box>
                                    </Box>
                                )}
                            </>
                        ))}
                    </Box>
                )}
            </>
        );
    };

    render() {
        // return <>{this.renderList()}</>;
        return <Box className="mt-3">{this.renderList()}</Box>;
    }
}

export default CreditCardAdditionalList;
