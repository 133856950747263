import { walletTypes } from "reducers/wallet/wallet.reducer";
import { call, put, takeLatest } from "redux-saga/effects";
import {
    fetchEnabledWalletPre as fetchEnabledWalletPreApi,
    fetchEnabledWalletSend as fetchEnabledWalletSendApi,
    fetchGetParameterBannerWallet as fetchGetParameterBannerWalletApi,
} from "middleware/wallet/wallet.middleware";
import * as i18n from "util/i18n";
import { BANNER_EMPTY_PRODUCT_OPTION } from "pages/wallet/banner/BannerAppleWalletContainer";
import { actions as notificationActions } from "reducers/notification";
import { USER_TOKEN_STATUS_ACTIVE } from "util/userToken.util";
import * as formApi from "middleware/form";

function* setErrorResponse({ callbackError, scopeError, errorMessage }) {
    yield put(
        notificationActions.showNotification(
            i18n.get(errorMessage || "enabled.wallet.default.error.message"),
            "error",
            scopeError || [],
            false,
        ),
    );

    if (callbackError) {
        callbackError();
    }
}

function* setErrorResponsePre(dataError) {
    yield put({
        type: walletTypes.ENABLED_WALLET_PRE_FAILURE,
    });

    yield call(setErrorResponse, dataError);
}

function* setErrorResponseSend(dataError) {
    yield put({
        type: walletTypes.ENABLED_WALLET_SEND_FAILURE,
    });

    yield call(setErrorResponse, dataError);
}

function* fetchEnabledWalletPre({ callbackError, scopeError, activityId }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(fetchEnabledWalletPreApi, deviceUuid);

    if (!response?.type || !response?.data?.data) {
        yield call(setErrorResponsePre, { callbackError, scopeError });
        yield;
        return;
    }

    if (response.type === "W") {
        yield call(setErrorResponsePre, { callbackError, scopeError });
        yield;
        return;
    }

    const { userTokenStatus } = response.data.data;

    if (!userTokenStatus || userTokenStatus !== USER_TOKEN_STATUS_ACTIVE) {
        yield call(setErrorResponsePre, {
            callbackError,
            scopeError,
            errorMessage: "enabled.wallet.token.invalid.message",
        });
        yield;
        return;
    }

    const responseCredentials = yield call(formApi.listCredentialsGroups, "", activityId);
    if (!responseCredentials?.type || !responseCredentials?.data?.data) {
        yield call(setErrorResponsePre, { callbackError, scopeError });
        yield;
        return;
    }

    if (responseCredentials.type === "W") {
        yield call(setErrorResponsePre, { callbackError, scopeError });
        yield;
        return;
    }

    const { groups } = responseCredentials.data.data;
    if (!groups) {
        yield call(setErrorResponsePre, { callbackError, scopeError });
        yield;
        return;
    }

    yield put({ type: walletTypes.ENABLED_WALLET_PRE_SUCCESS, credentialsGroups: groups });
}

function* fetchEnabledWalletSend({ cardId, cardType, callbackError, scopeError, otp }) {
    if (!cardId || !cardType) {
        yield call(setErrorResponseSend, { callbackError, scopeError });
        yield;
        return;
    }

    const response = yield call(fetchEnabledWalletSendApi, cardId, cardType, otp);
    if (!response?.type || !response?.data?.data) {
        yield call(setErrorResponseSend, { callbackError, scopeError });
        yield;
        return;
    }

    const { type, data } = response;
    if (type === "W") {
        // TODO: Custom errors
        yield call(setErrorResponseSend, { callbackError, scopeError });
        yield;
        return;
    }

    const { cardIdEncrypted, clientId } = data?.data;

    if (!cardIdEncrypted || !clientId) {
        // TODO: Custom errors
        yield call(setErrorResponseSend, { callbackError, scopeError });
        yield;
        return;
    }

    yield put({ type: walletTypes.ENABLED_WALLET_SEND_SUCCESS, cardWalletData: { cardIdEncrypted, clientId } });
}

function* getParameterBannerWallet() {
    const response = yield call(fetchGetParameterBannerWalletApi);
    if (!response?.type || !response?.data?.data) {
        yield put({ type: walletTypes.GET_PARAMETER_BANNER_WALLET_SET, bannerProduct: BANNER_EMPTY_PRODUCT_OPTION });
        yield;
        return;
    }

    const { type, data } = response;
    if (type === "W") {
        yield put({ type: walletTypes.GET_PARAMETER_BANNER_WALLET_SET, bannerProduct: BANNER_EMPTY_PRODUCT_OPTION });
        yield;
        return;
    }

    const { bannerProduct } = data.data;
    yield put({
        type: walletTypes.GET_PARAMETER_BANNER_WALLET_SET,
        bannerProduct: bannerProduct || BANNER_EMPTY_PRODUCT_OPTION,
    });
}

const sagas = [
    takeLatest(walletTypes.ENABLED_WALLET_PRE_REQUEST, fetchEnabledWalletPre),
    takeLatest(walletTypes.ENABLED_WALLET_SEND_REQUEST, fetchEnabledWalletSend),
    takeLatest(walletTypes.GET_PARAMETER_BANNER_WALLET_REQUEST, getParameterBannerWallet),
];
export default sagas;
