import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, string } from "prop-types";
import React, { Component } from "react";
import * as i18n from "util/i18n";

class DetailHeader extends Component {
    static propTypes = {
        depositId: string,
        isMobile: bool,
    };

    static defaultProps = {
        depositId: "",
        isMobile: false,
    };

    renderTitle = (isMobile, depositId) => (
        <Text {...(!isMobile && { size: "1" })} {...(isMobile && { size: "1" })} bold>
            {i18n.get("deposits.detail.header.depositnumber", null, {
                DEPOSIT_ID: depositId,
            })}
        </Text>
    );

    renderImage = () => (
        <Box display="flex" alignY="center">
            <Image src="images/products/icon_PA.svg" />
        </Box>
    );

    renderInfo = () => {
        const { isMobile, depositId } = this.props;
        return (
            <>
                <Box fullWidth>
                    {this.renderTitle(isMobile, depositId)}
                    <Box className="mt-2">
                        <Text
                            {...(!isMobile && { size: "5" })}
                            {...(isMobile && { size: "5" })}
                            color="text-grey"
                            labelKey="deposits.detail.header.description"
                            light
                        />
                    </Box>
                </Box>
            </>
        );
    };

    render() {
        const { isMobile, depositId, status } = this.props;

        return (
            <Box
                component="article"
                // display="flex"
                // borderRadius="default"
                fullWidth
                className={classNames("", {
                    "p-7 mb-5": !isMobile,
                    "py-5 mb-5": isMobile,
                })}>
                {isMobile ? (
                    <Box background="white">
                        <Box display="flex">
                            <Box alignX="left" display="flex" fullWidth>
                                {this.renderImage()}
                            </Box>
                            <Box alignY="center" display="flex">
                                <Text
                                    className={`product-status-${status?.toLowerCase()}-text`}
                                    labelKey={`deposits.status.${status}`}
                                />
                            </Box>
                        </Box>

                        <Box className="my-4">{this.renderTitle(isMobile, depositId)}</Box>
                        <Text align="left" labelKey="deposits.detail.header.description" color="text-grey" />
                    </Box>
                ) : (
                    <Box fullWidth className="mb-2">
                        <Box display="flex" fullWidth className="login-title-container">
                            <Box className="login-title-icon">
                                <Image src="images/products/icon_PA.svg" />
                            </Box>
                            <div className="login-title-icon-divisor" />
                            <Box fullWidth className="login-title-text">
                                <>{this.renderInfo()}</>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }
}

export default DetailHeader;
