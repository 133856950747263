import React, { Component, Fragment } from "react";
import { bool, shape, func } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "pages/_components/Row";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";

import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Enviroments from "pages/_components/Enviroments";
import Button from "pages/_components/Button";

class ChangeEnvironment extends Component {
    static propTypes = {
        isSubmitting: bool,
        history: shape({
            goBack: func,
        }).isRequired,
        environments: shape([]).isRequired,
        activeEnvironment: shape([]).isRequired,
    };

    static defaultProps = {
        isSubmitting: false,
    };

    backButtonAction = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { environments, activeEnvironment, isSubmitting } = this.props;
        return (
            <Fragment>
                <Head title="settings.changeEnvironment" onClose={this.backButtonAction} />
                <MainContainer>
                    <Form className="above-the-fold">
                        <section className="container--layout flex-grow-1 items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                        <Field
                                            name="environment"
                                            environments={environments}
                                            component={Enviroments}
                                            legendTextID="settings.changeEnvironment"
                                            activeEnvironment={activeEnvironment}
                                            fromSetDefaultEnvironmentPage={false}
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                        <Button
                                            type="submit"
                                            loading={isSubmitting}
                                            label="global.select"
                                            bsStyle="primary"
                                            block
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    environments: Object.entries(sessionSelectors.getEnvironments(state)),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: ({ activeEnvironment }) => ({
            environment: activeEnvironment.id,
            rememberEnvironment: false,
        }),
        handleSubmit: ({ environment, rememberEnvironment }, formikBag) => {
            formikBag.props.dispatch(sessionActions.changeEnvironment(environment, rememberEnvironment, formikBag));
        },
    }),
)(ChangeEnvironment);
