import { call, put, takeLatest } from "redux-saga/effects";
import { replace } from "react-router-redux";

import { types, actions } from "reducers/loansPayment";
import * as loansPayment from "middleware/loansPayment";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";

const sagas = [takeLatest(types.LOAD_LIST_REQUEST, loadListRequest)];

export default sagas;

function* loadListRequest() {
    const response = yield call(loansPayment.loadListRequest);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["loansPayment"]));
    } else {
        const { amortizedLoans, propertiesLoans } = response.data.data;
        let loans = [...amortizedLoans, ...propertiesLoans];

        loans = loans.filter((loan) => loan.numberOfPaidFees < loan.numberOfFees);

        if (loans.length === 1) {
            yield put(replace(`/loansPayment/list?loan=${loans[0].idProduct}`));
        } else {
            yield put(actions.loadListSuccess(loans));
        }
    }
}
