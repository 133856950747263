import classNames from "classnames";
import { Field, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import PinInput from "pages/_components/fields/PinInput";
import Head from "pages/_components/Head";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import { routerActions } from "react-router-redux/actions";
import { selectors as selectorSoftToken, actions as softTokenActions } from "reducers/softToken";
import Notification from "pages/_components/Notification";
import { bool, func, string } from "prop-types";
import { selectors as settingsSelectors, actions as settingsActions } from "reducers/settings";

const FORM_ID = "softtoken.otp.validation";
const INPUT_LENGTH_CONFIRMATION = 6;
const SoftTokenValidateOtp = ({ dispatch, isFetching, mobilePhoneMask, emailMask }) => {
    useEffect(() => {
        dispatch(settingsActions.getUserData());
    }, []);

    const handleBack = () => {
        dispatch(routerActions.replace("/desktop"));
    };

    const renderHeader = () => <Head title={`${FORM_ID}.title`} onBack={handleBack} />;
    const renderForm = () => (
        <Formik
            initialValues={{ pinCode: "" }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={() => {
                const requiredMessage = i18n.get(DEFAULT_REQUIRED);
                return Yup.object().shape({
                    pinCode: Yup.string()
                        .required(requiredMessage)
                        .min(INPUT_LENGTH_CONFIRMATION, requiredMessage),
                });
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                const { pinCode } = values;
                dispatch(
                    softTokenActions.saveTokenRequest({
                        redirectSuccess: "/desktop",
                        scopeSuccess: ["desktop"],
                        scopeError: ["desktop", "authenticateSofttoken"],
                        credentials: pinCode,
                    }),
                );
                resetForm({
                    pinCode: "",
                });
            }}>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="full-height">
                    <Box display="flex" column fullWidth fullHeight>
                        <Box
                            background="white"
                            className={classNames("mt-5 mt-md-0 pt-5 pt-md-8 px-5 pb-8 pb-md-12 mb-8 ml-5 mr-5")}
                            borderRadius="xl">
                            <Row fullHeight>
                                <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                    <Box display="flex" column alignY="around" fullHeight>
                                        <Box display="flex" gap={5} column alignX="center">
                                            <Text
                                                size="2"
                                                color="heading"
                                                component="p"
                                                align="center"
                                                labelKey={`${FORM_ID}.title.label`}
                                                bold
                                            />
                                            <Text size="5" color="heading" component="p" align="center">
                                                {`${i18n.get(`${FORM_ID}.title.description`)} ${emailMask}`}
                                            </Text>
                                            <Box display="flex" className="mb-md-11">
                                                <Field
                                                    idForm={FORM_ID}
                                                    autoComplete="off"
                                                    name="pinCode"
                                                    component={PinInput}
                                                    hidePlaceholder
                                                    maxLength={1}
                                                    labelNoMarginTop
                                                    showLabel={false}
                                                    noMarginBottom
                                                    type="number"
                                                    inputLenght={INPUT_LENGTH_CONFIRMATION}
                                                />
                                            </Box>
                                            <Text
                                                size="6"
                                                color="heading"
                                                component="p"
                                                className="m-0 mb-md-10"
                                                align="center"
                                                labelKey={`${FORM_ID}.otpNotFound.label`}
                                            />
                                            <Button
                                                bsStyle="link"
                                                label="softToken.otp.validation.resendOtp.label"
                                                onClick={() => {
                                                    dispatch(softTokenActions.resendOtpAuthenticateRequest());
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Col>
                            </Row>
                        </Box>
                        <Box className="mt-auto  ml-5 mr-5 mb-10">
                            <Row className="justify-content-center">
                                <Col xs={12} md={8} mdOffset={2} lg={4} lgOffset={4}>
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        label="global.confirm"
                                        loading={isFetching}
                                        block
                                    />
                                    <Button
                                        bsStyle="outline"
                                        label="global.cancel"
                                        block
                                        onClick={() => {
                                            dispatch(routerActions.replace("/desktop"));
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Box>
                    </Box>
                </form>
            )}
        </Formik>
    );

    return (
        <>
            <Notification scopeToShow="authenticateSofttoken" />

            {renderHeader()}
            {renderForm()}
        </>
    );
};

const mapStateToProps = (state) => ({
    isFetching: selectorSoftToken.isFetching(state),
    mobilePhoneMask: settingsSelectors.getMobilePhoneMask(state) || "",
    emailMask: settingsSelectors.getEmailMask(state) || "",
});

SoftTokenValidateOtp.propTypes = {
    dispatch: func.isRequired,
    isFetching: bool,
    mobilePhoneMask: string,
    emailMask: string,
};

SoftTokenValidateOtp.defaultProps = {
    isFetching: false,
    mobilePhoneMask: "",
    emailMask: "",
};

export default connect(mapStateToProps)(SoftTokenValidateOtp);
