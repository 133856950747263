import React from "react";
import { bool, func, shape, string } from "prop-types";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import AdministrationFormConfirmation from "../_components/AdministrationFormConfirmation";
import ConfirmDataGroup from "../_components/confirmations/ConfirmDataGroup";

const GroupFormConfirm = ({ data, isCreate, handleBack, handleSubmit, groups, updateMembers}) => {
    const renderHeader = () => (
        <>
            <Notification scopeToShow="groupForm" />
            <Head
                onBack={handleBack}
                title={`administration.advanced.group.${updateMembers ? "modifyMembers" : isCreate ? "create" : "modify"}.title`}
                // textBack={isCreate ? "administration.groups.returnToList" : "administration.groups.returnToDetail"}
                textBack="administration.groups.returnToDetail"
            />
        </>
    );

    return (
        <>
            {renderHeader()}
            <MainContainer className="main-container">
                <ConfirmDataGroup {...data} groups={groups} />
                <Box>
                    <AdministrationFormConfirmation
                        credentialGroups={[
                            { credentials: ["accessToken", "otp"], idCredentialGroup: "accessToken-otp" },
                        ]}
                        onSubmit={handleSubmit}
                        onClickCancel={handleBack}
                        idActivity={isCreate ? "administration.advanced.group.create.send" : "administration.advanced.group.modify.send"}
                    />
                </Box>
            </MainContainer>
        </>
    );
};

GroupFormConfirm.propTypes = {
    data: shape({
        name: string,
        description: string,
        permissions: shape({}),
    }).isRequired,
    isCreate: bool.isRequired,
    handleBack: func.isRequired,
    handleContinue: func.isRequired,
    groups: shape({}).isRequired,
};

export default GroupFormConfirm;
