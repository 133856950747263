import { USD_CURRENCY } from "constants.js";
import moment from "moment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import { bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { push, replace } from "react-router-redux";
import { actions as wallyActions, selectors as wallySelectors } from "reducers/wally";
import * as i18n from "util/i18n";
import { formatAccount } from "./hooks/WallyHooks";

const expirationDays = 15;

const PendingWally = ({ accountWally, dispatch, isActiveWaly, movementsPendingWally }) => {
    const [showModal, setshowModal] = useState(false);
    const [movementSelected, setMovementSelected] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isActiveWaly) {
            dispatch(wallyActions.listMovementsPendingRequest(() => setLoading(false)));
        } else {
            dispatch(replace("/wally"));
        }
    }, []);

    const handleOpenModal = () => {
        setshowModal(true);
    };

    const handleCloseModal = () => {
        setshowModal(false);
        setMovementSelected(null);
    };

    const handleBack = () => {
        dispatch(replace("/wally"));
    };

    const handleDeleteMovementPending = (event, movement) => {
        event.stopPropagation();
        setMovementSelected(movement);
        handleOpenModal();
    };

    const confirmDeleteMovementPending = () => {
        handleCloseModal();
        setLoading(true);
        dispatch(wallyActions.deleteMovementPendingRequest(movementSelected, () => setLoading(false)));
    };

    const handlerLink = () => {
        dispatch(wallyActions.setPathRedirectToWally("/pendingWally"));
        dispatch(push(`/formCustom/transferInternal?creditAccount=${accountWally.idProduct}&&from=wally`));
    };

    return (
        <>
            {showModal ? (
                <TextAndButtonsModal
                    modalShow
                    acceptFunction={confirmDeleteMovementPending}
                    cancelFunction={handleCloseModal}
                    headingText={i18n.get("wally.movementsPending.reject.modalTitle")}
                    text={i18n.get("wally.movementsPending.reject.modalSubtitle")}
                    confirmLabel="wally.movementsPending.reject.buttonConfirm"
                    modalId="wally.delete.pending.movement.modal"
                />
            ) : null}
            <Notification scopeToShow="pendingTransactionWally" />
            <Head
                titleImage="wally-head.svg"
                subtitlePrefix={accountWally?.productAlias}
                subtitle={formatAccount(accountWally)}
                onBack={handleBack}
                title="wally.movementsPending.title"
            />
            <MainContainer showLoader={loading}>
                {movementsPendingWally.length > 0 ? (
                    <>
                        <Box display="flex" column alignX="center" className="mt-4 mb-2" fullWidth>
                            <Box display="flex" alignX="center" alignY="center" fullWidth>
                                <Text color="heading" size="3" labelKey="wally.movementsPending.availableBalance" />:
                                <FormattedAmount
                                    className="ml-2"
                                    color="heading"
                                    size="2"
                                    bold
                                    quantity={accountWally?.balance}
                                    currency={USD_CURRENCY}
                                />
                            </Box>
                            <Box>
                                <Button
                                    label="wally.movementsPending.action"
                                    onClick={handlerLink}
                                    bsStyle="link-wally"
                                    small
                                />
                            </Box>
                        </Box>

                        <Box background="white" borderRadius="default" className="mx-n-5">
                            <GridTable fullWidth={false}>
                                <GridTable.Body>
                                    {movementsPendingWally.map((movement) => (
                                        <GridTable.Container
                                            columnsTemplate="1.5rem minmax(auto, 1fr) auto 2rem"
                                            key={movement.transactionXFerId}
                                            rows={3}
                                            className="px-5 py-3"
                                            removeBottomSpacing
                                            onClick={() => {
                                                dispatch(
                                                    wallyActions.setSelectedBeneficiary({
                                                        accountId: movement.creditAcctId,
                                                        fullName: movement.beneficiaryFullName,
                                                        partyId: movement.beneficiaryPartyId,
                                                        amount: movement.amount,
                                                        transactionDate: movement.transactionDate,
                                                        creditReference: movement.creditReference,
                                                        transactionXFerId: movement.transactionXFerId,
                                                    }),
                                                );
                                                dispatch(push("/transactionPendingWally"));
                                            }}>
                                            <GridTable.Data
                                                className="p-0"
                                                columnStart={1}
                                                rowWidth={2}
                                                alignX="flex-start"
                                                {...(movement.creditReference !== ""
                                                    ? { alignY: "flex-start" }
                                                    : { alignY: "center" })}>
                                                <Image
                                                    {...(movement.creditReference === "" && { className: "mb-4" })}
                                                    src="images/contact-wally.svg"
                                                    height="7"
                                                    wrapperWidth="7"
                                                    wrapperHeight="7"
                                                    color="wally"
                                                />
                                            </GridTable.Data>
                                            <GridTable.Data className="p-0" columnStart={2} alignX="flex-start">
                                                <Text bold size="4" color="heading" ellipsis>
                                                    {movement.beneficiaryFullName}
                                                </Text>
                                            </GridTable.Data>
                                            <GridTable.Data className="p-0" columnStart={2} alignX="flex-start">
                                                <Text bold size="4" color="text" ellipsis>
                                                    {movement.creditReference ? movement.creditReference : "..."}
                                                </Text>
                                            </GridTable.Data>
                                            <GridTable.Data className="p-0" columnStart={2} alignX="flex-start">
                                                <FormattedDate size="6" color="text" date={movement.transactionDate} />
                                                <Text
                                                    key="text"
                                                    className="ml-2"
                                                    size="6"
                                                    color="text"
                                                    labelKey="wally.movementsPending.expirationDays"
                                                    ellipsis
                                                    EXPIRATION_DAYS={
                                                        (expirationDays || 15) -
                                                        moment().diff(moment(movement.transactionDate), "days")
                                                    }
                                                />
                                            </GridTable.Data>
                                            <GridTable.Data
                                                fullWidth
                                                className="p-0"
                                                columnStart={3}
                                                rowStart={1}
                                                rowWidth={3}
                                                alignX="flex-end"
                                                alignY="flex-start">
                                                <Box display="flex" column alignY="center" alignX="flex-end" fullHeight>
                                                    <Text
                                                        size="4"
                                                        color="text"
                                                        align="right"
                                                        labelKey="wally.movementsPending.textType"
                                                    />
                                                    <FormattedAmount
                                                        quantity={movement.amount?.quantity}
                                                        currency={USD_CURRENCY}
                                                        noCurrency
                                                        bold
                                                        size="4"
                                                    />
                                                </Box>
                                            </GridTable.Data>
                                            <GridTable.Data
                                                className="p-0"
                                                columnStart={4}
                                                rowStart={1}
                                                rowWidth={3}
                                                alignX="flex-start"
                                                alignY="center">
                                                <Button
                                                    imageMd
                                                    image="images/icons/trash-wally.svg"
                                                    imageColor="wally"
                                                    onClick={(event) => handleDeleteMovementPending(event, movement)}
                                                />
                                            </GridTable.Data>
                                        </GridTable.Container>
                                    ))}
                                </GridTable.Body>
                            </GridTable>
                        </Box>
                    </>
                ) : (
                    <Box display="flex" alignX="center" column fullHeight className="mt-12">
                        <Box className="mb-8 mt-12">
                            <Image src="images/clock-notebook.svg" height={12} wrapperHeight={12} wrapperWidth={11} />
                        </Box>
                        <Box>
                            <Text
                                color="text-secondary"
                                size={3}
                                labelKey="wally.movementsPending.without.transaction"
                            />
                        </Box>
                    </Box>
                )}
            </MainContainer>
        </>
    );
};

PendingWally.propTypes = {
    history: shape({}).isRequired,
    accountWally: shape({}),
    isActiveWaly: bool.isRequired,
    movementsPendingWally: shape({}),
    dispatch: func.isRequired,
};

PendingWally.defaultProps = { accountWally: {}, movementsPendingWally: [] };

const mapStateToProps = (state) => ({
    accountWally: wallySelectors.getAccountWally(state),
    movementsPendingWally: wallySelectors.getMovementsPendingWally(state),
    isActiveWaly: wallySelectors.getIsActiveWally(state),
});

export default connect(mapStateToProps)(resizableRoute(PendingWally));
