import { call, put, takeLatest } from "redux-saga/effects";

import { types, actions } from "reducers/finances";
import financesReadRequest from "middleware/finances";
import * as i18n from "util/i18n";
import { actions as notificationActions } from "reducers/notification";

const sagas = [takeLatest(types.FINANCES_READ_REQUEST, financeReadRequest)];

export default sagas;

function* financeReadRequest() {
    const response = yield call(financesReadRequest);

    if (response.type === "W") {
        yield put(actions.financeReadFailure());
        yield put(notificationActions.showNotification(i18n.get("finances.error.message"), "error", ["finances"]));
    } else {
        const { report } = response.data.data;
        yield put(actions.financeReadSuccess(report));
    }
}
