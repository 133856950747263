import React from "react";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { bool, func } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import Button from "pages/_components/Button";
import classNames from "classnames";
import { isMobileNative } from "util/device";
import { replace } from "react-router-redux";

const DeviceRequired = ({ isDesktop, dispatch }) => {
    const goToHome = () => {
        dispatch(replace("/desktop"));
    };

    const goToActivate = () => {
        dispatch(replace("/settings/authenticatorHandler"));
    };

    const renderButtonToHome = () => (
        <Button type="button" bsStyle="primary" label="global.goToHome" onClick={goToHome} block />
    );
    return (
        <>
            {!isDesktop && <Head />}

            <MainContainer
                className={classNames("main-container border-radius-lg box-shadow-small background-white", {
                    "p-7  mt-8": isDesktop,
                    "p-5": !isDesktop,
                })}>
                <Box
                    className={classNames({
                        "mt-10 mx-12 pb-8": isDesktop,
                        "pt-10 display-flex flex-column justify-content-between full-height": !isDesktop,
                    })}>
                    <Box
                        className={classNames({
                            "mt-7": !isMobileNative,
                            "mt-10": isMobileNative,
                        })}>
                        <Image src="images/icons/errors/noDevice.svg" />

                        <Box className="text-center mt-7">
                            <Text bold size="3" labelKey="device.required.softToken.title" />
                        </Box>

                        {isMobileNative ? (
                            <Box className="text-center mt-7 line-height-15">
                                <Text labelKey="device.required.softToken.mobile" />
                            </Box>
                        ) : (
                            <Box
                                className={classNames("mb-11", {
                                    "mx-11 ": isDesktop,
                                })}>
                                <Box className="text-center mt-7 line-height-15">
                                    <Text className="text-center" labelKey="device.required.softToken.text" />
                                </Box>
                                <Box className="text-center mt-6 line-height-15">
                                    <Text className="text-center" labelKey="device.required.softToken.download.app" />
                                </Box>
                            </Box>
                        )}
                    </Box>
                    {!isDesktop && (
                        <Box className="pb-7">
                            {isMobileNative ? (
                                <>
                                    <Button
                                        type="button"
                                        bsStyle="primary"
                                        label="device.required.btn.activate"
                                        onClick={goToActivate}
                                        block
                                    />
                                    <Box className="mt-5">
                                        <Button
                                            type="button"
                                            bsStyle="outline"
                                            label="global.goToHome"
                                            onClick={goToHome}
                                            block
                                        />
                                    </Box>
                                </>
                            ) : (
                                <>{renderButtonToHome()}</>
                            )}
                        </Box>
                    )}
                </Box>
            </MainContainer>
            {isDesktop && (
                <Box className={classNames({ "view-app-content": isDesktop })}>
                    <Row
                        className={classNames("mt-7 mb-7 row-template-center", {
                            "d-flex": !isDesktop,
                        })}>
                        <Col xs={3} xsOffset={4}>
                            {renderButtonToHome()}
                        </Col>
                    </Row>
                </Box>
            )}
        </>
    );
};

DeviceRequired.propTypes = {
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
};

export default connect()(DeviceRequired);
