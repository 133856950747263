import React from "react";
import Modal from "react-bootstrap/lib/Modal";
import Col from "react-bootstrap/lib/Col";
import Row from "pages/_components/Row";
import { Field, Form, Formik } from "formik";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import { bool, func, shape } from "prop-types";
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from "uuid";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";

const FORM_ID = "role.payment";
export const LOCAL_BANK = "localBank";

const ModalManualRole = ({ onClose, addItem, isVisible, optionBankList, genericProps }) => (
    <>
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
                creditAccountNumber: "",
                creditAmountQuantity: "",
                creditAccountName: "",
                bankIdentifier: "",
            }}
            validate={(values) => {
                const errors = {};
                if (!values.creditAccountNumber) {
                    errors.creditAccountNumber = "Required";
                }
                if (!values.creditAmountQuantity) {
                    errors.creditAmountQuantity = "Required";
                }
                if (!values.bankIdentifier) {
                    errors.bankIdentifier = "Required";
                }
                if (!values.creditAccountName) {
                    errors.creditAccountName = "Required";
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                let bankRef = values.bankIdentifier[0];
                bankRef = bankRef !== LOCAL_BANK ? bankRef : "";
                const newValues = { ...values, id: uuidv4(), bankIdentifier: bankRef };
                addItem(newValues);
                resetForm({
                    creditAccountNumber: "",
                    creditAmountQuantity: "",
                    creditAccountName: "",
                    bankIdentifier: "",
                });
            }}>
            {(formikItem) => (
                <Modal onHide={onClose} show={isVisible} dialogClassName="modal-90w">
                    <Form className="modal-container">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <I18n id="forms.role.payment.addAccount.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container className="container--layout items-center flex-grow-1">
                                <Col className="col-12">
                                    <Field
                                        autoFocus
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="creditAccountNumber"
                                        type="text"
                                    />
                                </Col>
                                <Col className="col-12">
                                    <Field
                                        {...genericProps}
                                        autoFocus
                                        component={FormFieldsComponents.NumberFieldCustom}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="creditAmountQuantity"
                                        idField="creditAmountQuantity"
                                        decimalSeparator=","
                                        precision="2"
                                        thousandsSeparator="."
                                    />
                                </Col>
                                <Col className="col-12">
                                    <Field
                                        autoFocus
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="creditAccountName"
                                        type="text"
                                    />
                                </Col>
                                <Col className="col-12">
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.Selector}
                                        key="bankIdentifier"
                                        name="bankIdentifier"
                                        idField="bankIdentifier"
                                        labelIdField="react-select-bankIdentifier-input"
                                        optionList={optionBankList}
                                        defaultValue={[optionBankList[0].id]}
                                        renderAs="combo"
                                    />
                                </Col>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Row className="justify-content-center">
                                <Col className="col-12">
                                    <Button
                                        onClick={() => formikItem.submitForm()}
                                        label="global.send"
                                        bsStyle="primary"
                                    />
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )}
        </Formik>
    </>
);

ModalManualRole.propTypes = {
    onClose: func.isRequired,
    addItem: func.isRequired,
    isVisible: bool.isRequired,
    optionBankList: shape({}).isRequired,
    genericProps: shape({}),
};
ModalManualRole.defaultProps = {
    genericProps: {},
};
export default ModalManualRole;
