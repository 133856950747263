import * as API from "middleware/api";
import * as restAPI from "middleware/apiRESTful";
import { format } from "date-fns";
import * as configUtils from "util/config";
import moment from "moment";

export const downloadStatement = (idAccount, month, year) =>
    API.executeWithAccessToken("/accounts.downloadStatementLine", { idAccount, month, year });

export const editMovementNote = (idProduct, idStatement, note) =>
    API.executeWithAccessToken("/notes.editStatementNote", { idProduct, idStatement, note });

export const listAccounts = () => API.executeWithAccessToken("/accounts.list");

export const listAccountsWally = () => API.executeWithAccessToken("/accounts.wally.list");

export const listMovements = (idAccount, filters) => {
    let { dateFrom, dateTo } = filters;
    const {
        channels,
        check,
        minAmount,
        maxAmount,
        pageNumber,
        offset,
        quantity,
        reference,
        periodFilter,
        filterType,
    } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    if (configUtils.getBoolean("ms.account.enabled", false)) {
        return restAPI.getWithAccessToken(`/api/accounts/v1/accounts/${idAccount}/statements`, {
            channels,
            check,
            dateFrom,
            dateTo,
            minAmount,
            maxAmount,
            pageNumber,
            offset,
            quantity,
            reference,
            periodFilter,
            filterType,
        });
    }

    return API.executeWithAccessToken("/accounts.listStatements", {
        idAccount,
        channels,
        check,
        dateFrom,
        dateTo,
        minAmount,
        maxAmount,
        pageNumber,
        offset,
        quantity,
        reference,
        periodFilter,
        filterType,
    });
};

export const listStatements = (idAccount) =>
    API.executeWithAccessToken("/accounts.listStatementLines", {
        idAccount,
    });

export const listBlockStatements = (idAccount) =>
    API.executeWithAccessToken("/accounts.listBlockedStatements", {
        idAccount,
    });

export const movementDetails = (idAccount, accountType, data) =>
    API.executeWithAccessToken("/accounts.listStatementDetails", {
        idAccount,
        accountType,
        ...data,
    });

export const readAccount = (idAccount) => {
    if (configUtils.getBoolean("ms.account.enabled", false)) {
        return restAPI.getWithAccessToken(`/api/accounts/v1/accounts/${idAccount}`);
    }
    return API.executeWithAccessToken("/accounts.read", { idAccount });
};

export const downloadMovements = (
    idAccount,
    { dateFrom = null, dateTo = null, ...filters },
    fileFormat,
    ownerName,
    officialName,
) =>
    API.executeWithAccessToken("/accounts.downloadMovements", {
        idAccount,
        format: fileFormat,
        dateFrom: dateFrom && format(dateFrom, "YYYY-MM-DD"),
        dateTo: dateTo && format(dateTo, "YYYY-MM-DD"),
        ...filters,
        // quantity: -1,
        dateLabelDownload: moment().format("dddd D MMMM YYYY"),
        hourLabelDownload: moment().format("HH:mm A"),
        ownerName,
        officialName,
    });

export const downloadBlockMovements = (idAccount, fileFormat) =>
    API.executeWithAccessToken("/accounts.downloadMovementsBlocked", {
        idAccount,
        format: fileFormat,
    });
export const getTrackingUrlTransfer = (accountId, transactionRef) =>
    API.executeWithAccessToken("/transfer.foreign.tracking.getUrl", {
        accountId,
        transactionRef,
    });

export const downloadMovementDetail = (idAccount, ownerName, movement, movementDetail, documentImage) =>
    API.executeWithAccessToken("/accounts.downloadMovementDetail", {
        idAccount,
        ownerName,
        movement,
        movementDetail,
        ...documentImage,
    });

export const downloadAccounts = (fileFormat) =>
    API.executeWithAccessToken("/accounts.download", {
        format: fileFormat,
    });

export const getMovementsCut = (idAccount, date) =>
    API.executeWithAccessToken("/accounts.movementsCut", {
        idAccount,
        date,
    });

export const downloadMovementsCut = (idAccount, date, dateFrom, dateTo, fileFormat, ownerName) =>
    API.executeWithAccessToken("/accounts.downloadMovementsCut", {
        idAccount,
        date,
        dateFrom,
        dateTo,
        format: fileFormat,
        ownerName,
    });
