import React, { Component } from "react";
import Box from "pages/_components/Box";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { arrayOf, bool, func, objectOf, shape, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import * as i18n from "util/i18n";
import Heading from "pages/_components/Heading";

const FORM_ID = "administration.advanced.signature.modify";

class SignatureConfirm extends Component {
    static propTypes = {
        user: shape({ firstName: string, lastName: string }).isRequired,
        routerActions: shape({
            goBack: func,
        }),
        idActivity: string,
        idTransaction: string,
        schemeName: string.isRequired,
        signatureLevel: string,
        actions: objectOf(func).isRequired,
        formActions: objectOf(func).isRequired,
        hasSignatureEnabled: bool,
        match: shape({
            params: shape({ id: string.isRequired }),
        }).isRequired,
        fetching: bool,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        userInfo: shape({ hasSignature: bool.isRequired }).isRequired,
    };

    static defaultProps = {
        routerActions: null,
        idActivity: null,
        idTransaction: null,
        fetching: false,
        signatureLevel: "",
        hasSignatureEnabled: false,
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    formatSubmitSignatureValue = (signatureLevel) => {
        const { hasSignatureEnabled, schemeName } = this.props;

        if (schemeName === "medium") {
            return hasSignatureEnabled ? null : "A";
        }
        return signatureLevel === "N" ? null : signatureLevel;
    };

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, match, idTransaction, idActivity, signatureLevel, user } = this.props;
        const secondFactor = credentials;
        if (!idTransaction) {
            actions.updateSignatureRequest(
                {
                    secondFactor,
                    idUser: match.params.id,
                    signatureLevel: this.formatSubmitSignatureValue(signatureLevel),
                    fullName: user?.fullName,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    render() {
        const {
            fetching,
            signatureLevel,
            user,
            credentialGroups,
            // userInfo: { hasSignature } = { hasSignature: false },
            // schemeName,
            // hasSignatureEnabled
        } = this.props;

        return (
            <>
                <Head
                    title="administration.signature.configure.text"
                    onBack={this.handleBack}
                    textBack="administration.users.returnToDetail"
                />

                <MainContainer showLoader={fetching && !Object.keys(user).length}>
                    <Box background="white" borderRadius="lg" className="p-7 mt-5 box-shadow-small">
                        <div className="above-the-fold">
                            <Container
                                className="container--layout items-center flex-grow-1"
                                gridClassName="form-content">
                                <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2}>
                                    <Box display="flex">
                                        {/* TODO (LABEL) */}
                                        <Text
                                            component="h2"
                                            labelKey="administration.forms.confirm.credentials"
                                            className="m-0"
                                            size="5"
                                            color="heading-color"
                                            bold
                                        />
                                    </Box>

                                    <Box>
                                        <Box background="background-disabled" className="p-5 my-5" borderRadius="lg">
                                            <div>
                                                <Heading.DataGroup
                                                    containerClassName="data-wrapper data-confirm my-3 data-confirm-head"
                                                    label="administration.signatureLevel.confirmation.label"
                                                    data={i18n.get(
                                                        `administration.signatures.create.signatureLevelsCounts.${signatureLevel}.label`,
                                                    )}
                                                />
                                            </div>
                                        </Box>
                                        <Text
                                            component="h2"
                                            labelKey="administration.forms.confirm.user"
                                            className="m-0"
                                            size="5"
                                            color="heading-color"
                                            bold
                                        />
                                        <Box display="flex" alignX="between" className="mt-3" fullWidth>
                                            <Text
                                                labelKey="administration.user.label"
                                                className="m-0"
                                                size="5"
                                                // addColon
                                            />
                                            <Text className="m-0" color="heading-color" align="left" size="5" bold>
                                                {user?.fullName}
                                            </Text>
                                        </Box>
                                        <Box display="flex" alignX="between" className="mt-2" fullWidth>
                                            <Text
                                                labelKey="administration.users.invite.document.label"
                                                className="m-0"
                                                size="5"
                                                // addColon
                                            />
                                            <Text color="heading-color" align="left" size="5" bold>
                                                {user?.documentType} {user?.documentNumber}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Col>
                            </Container>
                        </div>
                    </Box>
                    <Box>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                            cancelUrl={`/administration/advanced/details/${user.idUser}`}
                            idActivity="administration.medium.modify.signature.send"
                        />
                    </Box>
                </MainContainer>
            </>
        );
    }
}

export default SignatureConfirm;
