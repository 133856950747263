import { Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { selectors as updateUserDataSelectors } from "reducers/updateUserData/updateUserData.reducer";
import { compose } from "redux";

const FORM_ID = "settings.personalDataUpdateStep7";

const PersonalDataUpdateStep7 = ({ isFetching, dispatch, currentUserData }) => {
    const [clientName, setClientName] = useState();

    useEffect(() => {
        const { FirstName } = currentUserData?.PersonData?.PersonName;
        const name = `${FirstName[0].toUpperCase()}${FirstName.slice(1).toLowerCase()}`;
        setClientName(name);
    }, [currentUserData]);

    return (
        <MainContainer showLoader={isFetching}>
            <Notification scopeToShow="personalDataUpdate" />
            <Form>
                <Box
                    display="flex"
                    column
                    fullWidth
                    alignX="center"
                    background="component-background"
                    className="px-5 px-md-12 py-12 mb-8"
                    borderRadius="default">
                    <Text
                        component="h2"
                        color="heading"
                        size="1"
                        className="mt-0 mb-11"
                        align="center"
                        bold
                        labelKey={`${FORM_ID}.clientName.label`}
                        textParams={{
                            CLIENT_NAME_PLACEHOLDER: clientName ? `${clientName}` : "",
                        }}
                    />
                    <Box display="flex" className="mb-11">
                        <Image src="images/icons/bigCheck.svg" />
                    </Box>
                    <Text
                        component="p"
                        color="heading"
                        size="5"
                        className="mt-0 mb-0"
                        align="center"
                        labelKey={`${FORM_ID}.message.label`}
                    />
                </Box>

                <Row>
                    <Col xs={12} md={4} mdOffset={4}>
                        <Button
                            label="global.goToDesktop"
                            onClick={() => {
                                dispatch(push("/desktop"));
                            }}
                            bsStyle="primary"
                            block
                        />
                    </Col>
                </Row>
            </Form>
        </MainContainer>
    );
};

PersonalDataUpdateStep7.propTypes = {
    currentUserData: shape({}).isRequired,
    dispatch: func.isRequired,
    isFetching: bool,
};

PersonalDataUpdateStep7.defaultProps = {
    isFetching: false,
};

const mapStateToProps = (state) => ({
    isFetching: updateUserDataSelectors.isFetching(state),
    currentUserData: updateUserDataSelectors.getCurrentUserData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        handleSubmit: () => {},
    }),
)(PersonalDataUpdateStep7);
