import classNames from "classnames";
import Box from "pages/_components/Box";
import { bool, node, string } from "prop-types";
import React, { Component } from "react";
import { config, Spring } from "react-spring";

class AccordionItemContent extends Component {
    static propTypes = {
        children: node.isRequired,
        isActive: bool.isRequired,
        contentClassName: string.isRequired,
    };

    render() {
        const { isActive, children, contentClassName } = this.props;

        return (
            <Spring
                config={config.stiff}
                from={{ height: 0, margin: "0 0 0" }}
                to={{
                    height: isActive ? "auto" : 0,
                    overflow: "hidden",
                }}>
                {(styles) => (
                    <div className="form-group" style={styles}>
                        <Box className={classNames("form-group-control-list", contentClassName)}>{children}</Box>
                    </div>
                )}
            </Spring>
        );
    }
}

export default AccordionItemContent;
