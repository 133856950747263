import { actions as revelockActions } from "reducers/revelock/revelock.reducer";
import { actions as sessionActions } from "reducers/session";
import { store } from "../store";

const PREFIJ_USERNAME = "PA";
export const generateUserNameSession = (username) => `${PREFIJ_USERNAME}${(username || "").toUpperCase()}`;

window.getSessionIdRevelock = () => store?.getState()?.session?.user?.sessionId || "";
window.getUserRevelock = () => generateUserNameSession(store?.getState()?.session?.user?.username);
window.getLocationRevelock = () => store?.getState()?.revelock?.position || window.location?.pathname || "";
window.logoutRevelock = () => {
    if (!window?.location) {
        return;
    }
    store.dispatch(revelockActions.showSuspiciousActivityModal());
    store.dispatch(sessionActions.logout());
};
window.revelockDummy = () => {
    // TODO: dummy function
};

// eslint-disable-next-line import/prefer-default-export
export const getLocationBasePath = (match) => {
    const basePathSplit = match?.path?.split(":");
    return basePathSplit && basePathSplit.length > 0 ? basePathSplit[0] : "";
};
