import DepositsListItem from "pages/deposits/ListItem";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import WithPermissions from "pages/_components/WithPermissions";
import { node, shape } from "prop-types";
import React, { Component } from "react";
import * as i18nUtils from "util/i18n";
import * as config from "util/config";
import DepositList from "pages/desktop/widgets/DepositList";

class Deposits extends Component {
    static propTypes = {
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
    };

    static defaultProps = {
        closeButton: null,
    };

    render() {
        const { draggableItemProps } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetDeposits" };
        const itemsToShowButton = Number(config.get("widgets.product.maxToShow"));

        return (
            <DepositList item={DepositsListItem} keyExtractor={(item) => item.idProduct} name="deposits">
                {(list, listLenght) => (
                    <section
                        className="widget"
                        {...uiAutomationProp}
                        {...draggableItemProps}
                        aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                        {list}
                        <WithPermissions permissions={["deposits"]}>
                            <Box display="flex" alignX="center" fullWidth flex1 className="px-md-3">
                                <WithPermissions permissions={["depositCreate"]}>
                                    <Button
                                        href="/formCustom/createFixedTermDepositForm"
                                        image="/images/plusCircle.svg"
                                        label="widgets.deposits.requestDeposit"
                                        bsStyle="link"
                                        className="btn-widget"
                                    />
                                </WithPermissions>
                                {listLenght > itemsToShowButton ? (
                                    <Button
                                        className="btn-widget"
                                        href="/deposits"
                                        label="widgets.deposits.myDeposits"
                                        bsStyle="link"
                                    />
                                ) : null}
                            </Box>
                        </WithPermissions>
                        <div className="overlay" />
                    </section>
                )}
            </DepositList>
        );
    }
}

export default Deposits;
