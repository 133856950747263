import { store } from "../store";

export const isCreditCardListCacheStatusOk = () => {
    const { list } = store.getState().creditCard;
    const isListCacheValid = store.getState().creditCard.listCacheValid;

    if (!isListCacheValid) {
        return false;
    }

    // const lastCacheUpdate = store.getState().lastCacheUpdate;  // si se usara validacion de fecha
    return list != null && list.length > 0;
};

export const isCreditCardDataCacheStatusOk = (id) => {
    const { details } = store.getState().creditCard;
    const isDetailsCacheValid = store.getState().creditCard.detailsCacheValid;

    if (!isDetailsCacheValid) {
        return false;
    }

    if (details === null) {
        return false;
    }

    const card = details.find((e) => e.idProduct === id);
    // const lastCacheUpdate = store.getState().lastCacheUpdate;  // si se usara validacion de fecha
    return card != null && Object.prototype.hasOwnProperty.call(card, "label");
};
