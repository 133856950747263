import * as recovery from "middleware/recoveryUser";
import { push, replace } from "react-router-redux";
import { actions as notificationActions } from "reducers/notification";
import { actions as recoveryUserActions, types, selectors } from "reducers/recoveryUser";
import { types as secondFactorTypes, actions as secondFactorActions } from "reducers/secondFactor";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { isDesktop } from "react-device-detect";
import { actions as loginActions } from "reducers/login";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import * as deviceUtils from "util/device";

const RecoveryUserSagas = [
    takeLatest(types.RECOVERY_USER_STEP1_PRE_REQUEST, preForm),
    takeLatest(types.RECOVERY_USER_STEP1_REQUEST, recoveryUserStep1),
    takeLatest(types.RECOVERY_USER_STEP2_REQUEST, recoveryUserStep2),
    takeLatest(types.RECOVERY_RESEND_OTP_AUTHENTICATE_CODE_REQUEST, handleRecoveryUserResendOtp),
];

export default RecoveryUserSagas;

function* preForm() {
    const response = yield call(recovery.recoveryUserStep1PreData);

    if (response && response.status === 200) {
        yield put(recoveryUserActions.preFormSuccess(response?.data?.data));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryUser"]));
        yield put(recoveryUserActions.requestFailure());
    }
}

function* recoveryUserStep1({ documentNumber, mail, formikBag }) {
    try {
        const response = yield call(recovery.recoveryUserStep1, documentNumber, mail);
        const urlSAT = configUtils.get("recover.username.oldSAT.url");
        const enabledRedirect = configUtils.getBoolean("previous.SAT.enabled.redirect");

        if (response.type === "W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            let notificationScope = "recoveryUser";

            switch (response.data.code) {
                case "API601W":
                    notificationScope = undefined;
                    // if (!enabledRedirect || deviceUtils.isMobileNativeFunc()) {
                    //     yield put(
                    //         notificationActions.showNotification(i18n.get("login.user.notFound.message"), "error", [
                    //             "externalLayout",
                    //         ]),
                    //     );
                    // } else if (enabledRedirect && urlSAT && isDesktop) {
                    //     yield call(() => window.open(urlSAT, "_blank"));
                    // }
                    if (enabledRedirect && urlSAT && isDesktop) {
                        yield put(push("/loginStep1"));
                        yield call(() => window.open(urlSAT, "_blank"));
                    }
                    break;
                case "API709W":
                    yield put(loginActions.showPendingInvitationModal(true));
                    notificationScope = undefined;
                    break;
                case "COR020W":
                    notificationScope = undefined;
                    if (enabledRedirect && urlSAT && isDesktop) {
                        yield put(push("/loginStep1"));
                        yield call(() => window.open(urlSAT, "_blank"));
                    }
                    break;
                case "API605W":
                    yield put(replace("/userBlockedBank"));
                    notificationScope = undefined;
                    break;
                case "API527W":
                    yield put(push("/loginStep1"));
                    notificationScope = "externalLayout";
                    break;
                default:
                    yield put(push("/recoveryUser/step1"));
                    notificationScope = "externalLayout";
            }
            if (notificationScope) {
                yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
            }
        } else {
            const {
                _exchangeToken,
                _operationType,
                _questions,
                contact,
                contactType,
                otpMethod,
                username,
            } = response.data.data;
            yield put({
                type: types.RECOVERY_USER_STEP1_SUCCESS,
                username,
                mail,
                operationType: _operationType,
                exchangeToken: _exchangeToken,
                questions: _questions,
                contact,
                contactType,
                otpMethod,
            });
            yield put(secondFactorActions.secondFactorStatusTokenRequest({ exchangeToken: _exchangeToken }));
            yield put(push("/recoveryUser/step2"));
        }
    } catch (e) {
        console.log("Exception", e);
        if (e?.data?.code === "COR019E") {
            if (formikBag) {
                formikBag.setSubmitting(false);
            }
            yield put(replace("/userBlocked"));
        }
        if (e?.data?.code === "API607E") {
            yield put(push("/loginStep1"));
            yield put(notificationActions.showNotification(e.data.message, "error", ["externalLayout"]));
        }
    }
    formikBag.setSubmitting(false);
}

function* recoveryUserStep2({ secondFactor, exchangeToken, formikBag }) {
    const response = yield call(recovery.recoveryUserStep2, secondFactor, exchangeToken);
    let notificationScope = "";
    let showNotification = true;
    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
        switch (response.data.code) {
            case "COR020W":
                break;
            case "API707W":
                yield put({ type: types.CLEAN_UP });
                showNotification = false;
                yield put(replace("/userBlockedBank"));
                notificationScope = "externalLayout";
                break;
            case "API708W":
                yield put({ type: types.CLEAN_UP });
                yield put(
                    notificationActions.showNotification(
                        i18n.get(
                            "secondFactor.credential.otp.expired",
                            "Código OTP expirado, solicite un nuevo código OTP",
                        ),
                        "warning",
                        ["externalLayout"],
                    ),
                );
                return;
            case "API525W":
            case "API526W":
                break;
            case "API527W":
                yield put(push("/loginStep1"));
                notificationScope = "externalLayout";
                break;
            default:
                yield put({ type: types.CLEAN_UP });
                yield put(push("/recoveryUser/step1"));
                notificationScope = "externalLayout";
        }

        if(showNotification){
            yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
        }
    } else {
        const { _exchangeToken, showCaptcha, contact } = response.data.data;

        yield put({
            type: types.RECOVERY_PASS_STEP2_SUCCESS,
            exchangeToken: _exchangeToken,
            showCaptcha,
            contact,
            idTransaction: response.data.idTransaction,
        });
        yield put(push("/recoveryUser/step3"));
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryUserResendOtp() {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const response = yield call(recovery.recoveryUserResendOtp, exchangeToken);
    yield put(notificationActions.removeNotification());
    let notificationScope = "recoveryUser";
    if (response.type === "W") {
        const notificationErrorMessage = response.data.message;
        switch (response.data.code) {
            case "COR048W":
                break;
            case "COR020W":
                break;
            default:
                yield put({ type: types.CLEAN_UP });
                yield put(push("/recoveryUser/step1"));
                notificationScope = "externalLayout";
        }

        if (response.data.code !== "COR020W") {
            yield put(notificationActions.showNotification(notificationErrorMessage, "error", [notificationScope]));
        }
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("recoveryUser.resendOtp.confirmation"), "success", [
                notificationScope,
            ]),
        );
    }
}
