import { useEffect, useState } from "react";

export const useEnvironmentList = (environmentList) => {
    const [environmentListOptions, setEnvironmentListOptions] = useState([]);

    const environmentListTmp = (environment) => ({
        ...environment,
        value: environment.idEnvironment,
        label: environment.name,
    });

    useEffect(() => {
        setEnvironmentListOptions(environmentList?.map(environmentListTmp));
    }, [environmentList]);
    return environmentListOptions;
};

export const useGroupList = (groupList, groupsExtendedInfo) => {
    const [groupListOptions, setGroupListOptions] = useState([]);

    const groupListTmp = groupList.reduce((acc, group) => {
        if (groupsExtendedInfo[group.idGroup].massiveEnabled) {
            acc.push({
                ...group,
                value: group.idGroup,
                label: group.name,
            });
        }
        return acc;
    }, []);

    useEffect(() => {
        setGroupListOptions(groupListTmp);
    }, [groupList]);
    return groupListOptions;
};

export const useSignatureLevelList = (signatureLevelList) => {
    const [signatureLevelListOptions, setSignatureLevelListOptions] = useState([]);

    const signatureLevelListTmp = (signatureLevel) => ({
        value: signatureLevel,
        id: signatureLevel,
        label: signatureLevel,
        disabled: signatureLevel === "A",
    });

    useEffect(() => {
        const signatureLevels = signatureLevelList?.map(signatureLevelListTmp);
        if (signatureLevels) {
            signatureLevels.push({
                value: "NO",
                id: "NO",
                label: "No firma",
            });
        }
        setSignatureLevelListOptions(signatureLevels);
    }, []);
    return signatureLevelListOptions;
};
