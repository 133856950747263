import React from "react";
import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape } from "prop-types";
import { Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Checkbox from "pages/_components/fields/Checkbox";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";

import I18n from "pages/_components/I18n";
import { Form, Formik } from "formik";
import * as i18n from "util/i18n";
import { isDesktop } from "react-device-detect";
import parse, { domToReact } from "html-react-parser";
import * as configUtils from "util/config";

const MODAL_ID = "LOPD";

const DataProtectionLaw = (props) => {
    const { statusModals, isMobileNative } = props;

    const handleCancel = () => {
        const { dispatch } = props;
        dispatch(sessionActions.changeModalStatus(MODAL_ID, "cancel"));
    };

    const handleAccept = () => {
        const { dispatch } = props;
        dispatch(sessionActions.changeModalStatus(MODAL_ID, "accept"));
        dispatch(sessionActions.setAcceptModal(MODAL_ID));
    };

    const handleTransform = (node) => {
        if (node.name === "a") {
            return (
                <a href="#" onClick={(e) => openLink(e)}>
                    {domToReact(node.children)}
                </a>
            );
        }
    };

    const openLink = (e) => {
        e.preventDefault();
        if (isMobileNative) {
            if (window.device.platform === "Android") {
                // eslint-disable-next-line no-undef
                cordova.InAppBrowser.open(
                    configUtils.get("modal.dataProtectionLaw.content.url"),
                    "_system",
                    "location=yes",
                );
            }
            if (window.device.platform === "iOS") {
                // eslint-disable-next-line no-undef
                cordova.InAppBrowser.open(
                    configUtils.get("modal.dataProtectionLaw.content.url"),
                    "_system",
                    "location=yes",
                );
            }
        } else {
            window.open(configUtils.get("modal.dataProtectionLaw.content.url"), "_blank");
        }
    };

    const showModal = statusModals && statusModals[MODAL_ID] === "NA";

    return (
        <div className="modal-container">
            <Modal show={showModal} className="modal-data-protection-law">
                <Formik initialValues={{ accept: false }} onSubmit={handleAccept}>
                    {(formik) => (
                        <Form>
                            <Box
                                className={classNames({
                                    "p-7 px-80": isDesktop,
                                    "p-5": !isDesktop,
                                })}>
                                <Box display="flex" fullWidth alignX="center" className={classNames("mt-5")}>
                                    <Text size={1} semibold>
                                        <I18n id="modal.dataProtectionLaw.title" />
                                    </Text>
                                </Box>
                                <Box
                                    display="flex"
                                    fullWidth
                                    alignX="center"
                                    className={classNames(
                                        "mt-5 mb-8 pr-3 overflow-auto data-protection-law-text custom-scrollbar",
                                    )}>
                                    <Text className="line-height-15" size="5" color="text" breakWord>
                                        {parse(`${i18n.get("modal.dataProtectionLaw.content")}`, {
                                            replace: (node) => handleTransform(node),
                                        })}
                                    </Text>
                                </Box>
                                <Box display="flex" alignX="center" className={classNames("mt-5 mb-9")}>
                                    <Checkbox
                                        name="accept"
                                        checked={formik.values.accept}
                                        onChange={() => {
                                            formik.setFieldValue("accept", !formik.values.accept);
                                        }}
                                        label={i18n.get("modal.dataProtectionLaw.checkbox")}
                                    />
                                </Box>

                                <Box display="flex" column fullWidth className={classNames("mb-5")}>
                                    <Row
                                        className={classNames("px-0", {
                                            "d-flex flex-column-reverse": !isDesktop,
                                        })}>
                                        <Col xs={6}>
                                            <Button
                                                type="button"
                                                label="global.notNow"
                                                bsStyle="outline"
                                                onClick={handleCancel}
                                                block
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <Button
                                                type="submit"
                                                label="global.accept"
                                                bsStyle="primary"
                                                disabled={!formik.values.accept}
                                                block
                                            />
                                        </Col>
                                    </Row>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

DataProtectionLaw.propTypes = {
    statusModals: shape({}).isRequired,
    dispatch: func.isRequired,
    isMobileNative: bool.isRequired,
};

const mapStateToProps = (state) => ({
    statusModals: sessionSelectors.getStatusModals(state),
});

export default connect(mapStateToProps)(resizableRoute(DataProtectionLaw));
