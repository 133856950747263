/* eslint-disable*/
import * as wally from "middleware/wally";
import { replace } from "react-router-redux";
import { types as loginTypes } from "reducers/login";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import globalTypes from "reducers/types/global";
import { selectors, types, actions as wallyActions } from "reducers/wally";
import { delay } from "redux-saga";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as configUtil from "util/config";
import { isMobileNative } from "util/device";
import * as i18n from "util/i18n";

const sagas = [
    takeEvery(types.ACTIVATE_REQUEST, activateWally),
    takeEvery(types.DESACTIVATE_USERWALLY_REQUEST, desactivateWally),
    takeEvery(types.UPDATE_USERWALLY_REQUEST, updateWally),
    takeEvery(types.FAVORITE_REQUEST, favoriteWally),
    takeEvery(types.READ_WALLY_USER_REQUEST, readWallyUserRequest),
    takeEvery(types.LIST_BENEFICIARIES_COMMERCE_REQUEST, listbeneficiariesCommerceRequest),
    takeEvery(types.LIST_BENEFICIARIES_PEOPLE_REQUEST, listbeneficiariesPeopleRequest),
    takeEvery(types.LIST_MOVEMENTS_REQUEST, listMovementsRequest),
    takeEvery(types.LIST_MOVEMENTS_PENDING_REQUEST, listMovementsPendingRequest),
    takeEvery(types.DELETE_MOVEMENT_PENDING_REQUEST, deleteMovementPendingRequest),
    takeLatest([globalTypes.INIT, loginTypes.LOGIN_SUCCESS], refreshPendingTransactionsWallyQuantity),
];

export default sagas;

function* activateWally({ account, onFinish }) {
    const response = yield call(wally.activateWally, account?.idProduct);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        yield put(wallyActions.setSelectedAccount(account));
        yield put(replace("/enrollmentWallyFinish"));
    }
    typeof onFinish === "function" && onFinish();
}

function* desactivateWally({ account, onFinish }) {
    const response = yield call(wally.desactivateWally, account?.idProduct);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        yield put(replace("/deactivationWally"));
    }
    typeof onFinish === "function" && onFinish();
}

function* updateWally({ account, onFinish }) {
    const response = yield call(wally.updateWally, account?.idProduct);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        yield call(readWallyUserRequest);
        yield put(replace("/settingsWally"));
        yield put(
            notificationActions.showNotification(i18n.get("wally.change.account.success"), "success", [
                "settingswally",
            ]),
        );
    }
    typeof onFinish === "function" && onFinish();
}

function* favoriteWally({ beneficiary }) {
    const response = yield call(
        wally.favoriteWally,
        beneficiary?.phoneNumber,
        beneficiary?.type,
        beneficiary?.favorite,
    );
    if (response && response.status === 200 && response.data.code === "COR000I") {
        const beneficiaries = yield select(selectors.getBeneficiariesPeople);
        const beneficiarySelected = yield select(selectors.getSelectedBeneficiary);

        let tmpBeneficiary = null;
        if (!!beneficiarySelected) {
            tmpBeneficiary = { ...beneficiarySelected, favorite: !beneficiary?.favorite };
        }

        const newBeneficiaries = [...beneficiaries];
        newBeneficiaries.map((obj) => {
            obj.phoneNumber === beneficiary.phoneNumber && (obj.favorite = !beneficiary?.favorite);
        });
        newBeneficiaries.sort(function(a, b) {
            if (b.favorite === a.favorite) {
                return 0;
            }
            return b.favorite ? 1 : -1;
        });

        yield put(wallyActions.favoriteSuccess(newBeneficiaries, tmpBeneficiary));
    }else{
        yield put({ type: types.FAVORITE_FAILURE });
    }
}

function* readWallyUserRequest() {
    const response = yield call(wally.readWallyUser);
    if(response?.data?.code === "API576W"){
        yield put(
            notificationActions.showNotification(
                i18n.get("wally.phone.invalid.reEnrollment"),
                "error",
                ["wally"],
            ),
        );
        yield put(wallyActions.userWallySuccess(null, null, false, true));
        yield put(replace("/enrollmentWally"));
    }else if (response && response.status === 200) {
        const { account, beneficiary, active, phoneValid } = response.data.data;
        yield put(wallyActions.userWallySuccess(beneficiary, account, active, phoneValid));
    }
}

function* listbeneficiariesCommerceRequest({ countryCode, start, end }) {
    const response = yield call(wally.listBeneficiariesCommerceWally, countryCode, start, end);
    if (response && response.status === 200) {
        const { beneficiaries } = response.data.data;
        yield put(wallyActions.listBeneficiariesCommerceSuccess(beneficiaries || [], countryCode));
    }
}

function* listbeneficiariesPeopleRequest({ phoneNumbers, arrContacts, onFinish }) {
    const response = yield call(wally.listBeneficiariesPeopleWally, phoneNumbers);
    if (response && response.status === 200) {
        const { beneficiaries } = response.data.data;
        const beneficiariesWithDisplayName = arrContacts
            ? beneficiaries.map((beneficiary) => ({
                  ...beneficiary,
                  displayName: arrContacts.find((contact) => contact.number === beneficiary.phoneNumber)?.displayName,
              }))
            : beneficiaries;
        yield put(wallyActions.listBeneficiariesPeolpeSuccess(beneficiariesWithDisplayName || []));
    }
    if (onFinish) {
        onFinish();
    }
}

function* listMovementsRequest({ onFinish }) {
    const response = yield call(wally.listMovementsWally);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        const { movementsWally } = response.data.data;
        yield put(wallyActions.listMovementsSuccess(movementsWally || []));
    } else {
        yield put(wallyActions.listMovementsFailure());
    }
    typeof onFinish === "function" && onFinish();
}

function* listMovementsPendingRequest({ onFinish }) {
    const response = yield call(wally.listMovementsPendingWally);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        const { movementsWally } = response.data.data;
        yield put(wallyActions.listMovementsPendingSuccess(movementsWally || []));
    } else {
        yield put(wallyActions.listMovementsPendingFailure());
    }
    typeof onFinish === "function" && onFinish();
}

function* deleteMovementPendingRequest({ movement, onFinish }) {
    const response = yield call(wally.deleteMovementPendingWally, movement);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        yield put(wallyActions.listMovementsPendingRequest(onFinish));
        const response = yield call(wally.getPendingTransactionsWallyQuantity);
        if (response.status !== 304 && response.type === "I") {
            yield put({
                type: types.REFRESH_PENDING_TRANSACTIONS_WALLY_QUANTITY_SUCCESS,
                pendingTransactionsWallyQuantity: response.data.data.pendingTransactionsWallyQuantity,
            });
        }
    }
}

function* refreshPendingTransactionsWallyQuantity() {
//    if (isMobileNative && configUtil.get("feature.transactionsWally.refreshPendingQuantity")) {
//        while (true) {
//            const hasActiveSession = yield select(sessionSelectors.isLoggedIn);
//            if (!hasActiveSession) {
//                break;
//            }
//
//            try {
//                const response = yield call(wally.getPendingTransactionsWallyQuantity);
//                if (response.status !== 304 && response.type === "I") {
//                    yield put({
//                        type: types.REFRESH_PENDING_TRANSACTIONS_WALLY_QUANTITY_SUCCESS,
//                        pendingTransactionsWallyQuantity: response.data.data.pendingTransactionsWallyQuantity,
//                    });
//                }
//            } catch (err) {
//                if ((err.response && err.response.status === 401) || err.status === 401) {
//                    break;
//                }
//                yield call(delay, 60000);
//            }
//            yield call(delay, configUtil.get("transactionsWally.pending.refreshRate", 60) * 1000);
//        }
//    }
}
