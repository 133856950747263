import React from "react";
import { node, number, string } from "prop-types";
import {
    CircularProgressbar as ReactCircularProgressBar,
    buildStyles,
    CircularProgressbarWithChildren as ReactCircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import variables from "styles/themes/techbank/variables/variables.json";
import * as configUtils from "util/config";

const CircularProgressBar = ({ percentage, title, children, status }) => {
    const getColor = () => {
        const listDeposit = configUtils.get("deposits.myDeposits.status");
        const listDepositArray = listDeposit.split("|");

        for (const deposit of listDepositArray) {
            const depositSplit = deposit.split(":");
            if (depositSplit.length === 3) {
                if (depositSplit[1] === "deposits.myDeposits.status.deposit.ven" && depositSplit[0] === status) {
                    return variables.mediumError;
                }
                if (depositSplit[1] === "deposits.myDeposits.status.deposit.can" && depositSplit[0] === status) {
                    return variables.mediumInfo;
                }
            }
        }
        return variables.primary;
    };

    if (children) {
        return (
            <ReactCircularProgressbarWithChildren
                value={percentage}
                styles={buildStyles({
                    textColor: variables["text-color"],
                    pathColor: getColor(),
                    trailColor: variables.lightGrey,
                    textSize: variables["size-4"],
                    textAlign: "center",
                })}>
                {children}
            </ReactCircularProgressbarWithChildren>
        );
    }

    return (
        <ReactCircularProgressBar
            value={percentage}
            text={title}
            styles={buildStyles({
                textColor: variables["text-color"],
                pathColor: getColor(),
                trailColor: variables.lightGrey,
                textSize: variables["size-4"],
                textAlign: "center",
            })}
        />
    );
};

CircularProgressBar.propTypes = {
    percentage: number.isRequired,
    title: string,
    children: node,
    status: string.isRequired,
};

CircularProgressBar.defaultProps = {
    title: "",
    children: null,
};

export default CircularProgressBar;
