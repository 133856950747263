import classNames from "classnames";
import GridTableBody from "pages/_components/GridTable/GridTableBody";
import GridTableContainer from "pages/_components/GridTable/GridTableContainer";
import GridTableData from "pages/_components/GridTable/GridTableData";
import GridTableFooter from "pages/_components/GridTable/GridTableFooter";
import GridTableHeader from "pages/_components/GridTable/GridTableHeader";
import { node, string } from "prop-types";
import React from "react";

const GridTable = ({ children, className }) => (
    <table aria-live="polite" className={classNames("grid-table full-width", className)}>
        {children}
    </table>
);

GridTable.propTypes = {
    children: node.isRequired,
    className: string,
};

GridTable.defaultProps = {
    className: null,
};

GridTable.Header = GridTableHeader;

GridTable.Body = GridTableBody;

GridTable.Container = GridTableContainer;

GridTable.Data = GridTableData;

GridTable.Footer = GridTableFooter;

export default GridTable;
