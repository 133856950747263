import Accordion from "pages/_components/Accordion";
import Box from "pages/_components/Box";
import Disclaimer from "pages/_components/Disclaimer";
import SwitchField from "pages/_components/fields/SwitchField";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import { arrayOf, bool, func, objectOf } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions, selectors } from "reducers/settings";
import * as i18n from "util/i18n";

class NotificationsConfiguration extends Component {
    static propTypes = {
        communicationTypes: arrayOf(objectOf(bool)),
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        isTablet: bool.isRequired,
    };

    static defaultProps = {
        communicationTypes: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.notificationsConfigurationPre());
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.push("/settings"));
    };

    changeStatusChannel(communicationType, channel, channelValue) {
        const { dispatch } = this.props;
        const [comunicationkey] = Object.entries(communicationType)[0];

        dispatch(actions.modifyNotificationConfigurations(comunicationkey, channel, channelValue));
    }

    renderChannels(communicationType) {
        const channels = Object.values(communicationType)[0];
        const comunication = Object.entries(communicationType)[0];

        return channels.map((channel) => {
            const [channelKey] = Object.entries(channel)[0];
            const channelValues = Object.values(channel)[0];
            const channelOptions = () => (
                <SwitchField
                    disabled={channelValues.isMandatory}
                    name={`${comunication}-${channelKey.toLowerCase()}`}
                    key={`${comunication}-${channelKey.toLowerCase()}`}
                    label={i18n.get(
                        `userInfo.preferences.notificationsConfiguration.${
                            channelKey.toLowerCase() === "web" ? "default" : channelKey.toLowerCase()
                        }.label`,
                    )}
                    value={channelValues.isActive}
                    onChange={() =>
                        this.changeStatusChannel(communicationType, channelKey.toLowerCase(), !channelValues.isActive)
                    }
                />
            );

            return channelOptions();
        });
    }

    renderCommunicationTypes = (oddEven = null) => {
        const { communicationTypes } = this.props;

        return communicationTypes.map((communicationType, idx) => {
            const [key] = Object.entries(communicationType)[0];

            const acordion = () => (
                <Accordion whiteBox simpleStyle key={key}>
                    <Accordion.Item
                        title={`communications.communicationsTypes.${key}`}
                        titleColor="text"
                        grayTexts
                        arrowColor="text-color">
                        <Box className="switch-wrapper">{this.renderChannels(communicationType)}</Box>
                    </Accordion.Item>
                </Accordion>
            );

            if (idx % 2 === oddEven) {
                return acordion();
            }
            if (oddEven === null) {
                return acordion();
            }
            return null;
        });
    };

    renderContent = () => {
        const { communicationTypes, isDesktop, isTablet } = this.props;

        return (
            communicationTypes && (
                <form>
                    <Row className="justify-content-center">
                        <Col xs={12} md={10} mdOffset={1}>
                            <Disclaimer
                                className="p-3 px-5 p-md-5 mt-5 mt-md-0 mr-md-auto"
                                styled="info"
                                labelKey="settings.notificationsConfiguration.title"
                            />
                            <Row className="mt-5" gapY="3">
                                {isDesktop && !isTablet ? (
                                    <>
                                        <Col xs={12} md={6}>
                                            {/* odd number */}
                                            {this.renderCommunicationTypes(0)}
                                        </Col>
                                        <Col xs={12} md={6}>
                                            {/* even number */}
                                            {this.renderCommunicationTypes(1)}
                                        </Col>
                                    </>
                                ) : (
                                    <Col xs={12}>{this.renderCommunicationTypes()}</Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </form>
            )
        );
    };

    render() {
        const { fetching } = this.props;

        return (
            <>
                <Head title="settings.notificationsConfiguration" onBack={this.handleBack} />
                <Notification scopeToShow="settings/notificationsConfiguration" />
                <MainContainer showLoader={fetching}>{this.renderContent()}</MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    communicationTypes: selectors.getCommunicationTypes(state),
    fetching: selectors.isFetching(state),
});

export default connect(mapStateToProps)(resizableRoute(NotificationsConfiguration));
