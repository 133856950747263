import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import DocumentField from "pages/_components/fields/Document";
import PhoneInput from "pages/_components/fields/PhoneInput";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from "uuid";
import TextField from "pages/_components/fields/TextField";
import Row from "pages/_components/Row";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import { bool, func, shape, string, arrayOf } from "prop-types";

const FORM_ID = "fixed.term.deposit.beneficiary.send";
const PercentageBeneficiaryForm = ({
    isDesktop,
    mode = "edit",
    currentLang,
    saveCallback,
    beneficiaryData,
    documentData,
    beneficiaryList,
    beneficiaryRelationshipList,
    isCorporate,
}) => {
    const preferredCountries = ["pa", "ve"];
    const phoneDefaultCountry = "pa";
    const areaCodes = { ve: ["412", "414", "416", "424", "426"] };

    const genericProps = {
        mode,
        lang: currentLang,
        fromBackoffice: false,
        isRequired: true,
        idActivity: FORM_ID,
        isCorporate: bool.isRequired,
    };

    const initFormValues = {
        name: beneficiaryData?.name || "",
        document: beneficiaryData?.document || {
            documentCountry: "",
            documentType: "",
            documentNumber1: "",
            documentNumber2: "",
            idNumber1: "",
            idNumber2: "",
        },
        phone: beneficiaryData?.phone || "",
        percentage: beneficiaryData?.percentage || "",
        relationship: beneficiaryData?.relationship || "",
        id: beneficiaryData?.id || "",
    };

    const isValidPercentage = (value) => {
        let beneficiaryListUpdate = [...(beneficiaryList || [])];
        if (beneficiaryData?.id) {
            beneficiaryListUpdate = beneficiaryListUpdate.filter(
                (item) => item && item.id && item.id !== beneficiaryData.id,
            );
        }

        const currentPercentage = beneficiaryListUpdate?.reduce((acc, item) => acc + (item?.percentage || 0), 0) || 0;

        return currentPercentage + +value <= 100;
    };

    const renderForm = () => (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initFormValues}
            validationSchema={() =>
                Yup.object().shape({
                    document: Yup.object().shape({
                        documentCountry: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                        documentType: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                        documentNumber1: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                        documentNumber2: Yup.string().when(["documentCountry", "documentType"], {
                            is: (documentCountry, documentType) => documentCountry === "PA" && documentType === "CIP",
                            then: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                        }),
                        idNumber1: Yup.string().when(["documentCountry", "documentType"], {
                            is: (documentCountry, documentType) =>
                                documentCountry === "PA" || (documentCountry === "VE" && documentType === "CED"),
                            then: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                        }),
                        idNumber2: Yup.string().when("documentCountry", {
                            is: "PA",
                            then: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                        }),
                    }),
                    name: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                    phone: Yup.object().shape({
                        value: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                    }),
                    percentage: Yup.string()
                        .required(i18n.get(DEFAULT_REQUIRED))
                        .test("percenta-is-valid", i18n.get(`${FORM_ID}.invalid.percentage`), (value) =>
                            isValidPercentage(value),
                        ),
                    relationship: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                })
            }
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                resetForm(initFormValues);
                if (saveCallback) {
                    saveCallback({ ...values, id: values.id || uuidv4() });
                }
            }}>
            {({ isSubmitting, submitForm }) => (
                <Form className="full-width px-5 px-md-0">
                    <Box display="flex" column fullWidth fullHeight>
                        <Row gapX="5" heightAuto className="mb-7 mb-md-12 px-0">
                            <Col xs={12} md={12}>
                                {/* TODO (FIELD NAME) */}
                                <Field
                                    autoFocus
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name={isCorporate ? "mobilization" : "name"}
                                    type="text"
                                    labelNoMarginTop
                                    // TODO (Tooltip LABEL)
                                    tooltipText="Tooltip de movilización"
                                    tooltipPosition="bottom-centered"
                                />
                            </Col>
                            <Col xs={12} md={12}>
                                <Field
                                    countries={documentData?.countryList}
                                    documentCountryMap={documentData?.documentTypeList}
                                    idNumberMap={documentData?.idNumberMap1}
                                    idNumber2Map={documentData?.idNumberMap2}
                                    component={DocumentField}
                                    name="document"
                                    reducedColumnGap
                                    labelNoMarginTop
                                    spacingInputs="mb-5 mb-md-7"
                                    {...(!isDesktop && { idNumberStyle2: true })}
                                />
                            </Col>
                            {!isCorporate && (
                                <>
                                    <Col xs={12} md={12}>
                                        <Field
                                            component={PhoneInput}
                                            idForm={FORM_ID}
                                            idField={`${FORM_ID}.mobilePhone`}
                                            name="phone"
                                            isDesktop={isDesktop}
                                            className="remove-flex"
                                            preferredCountries={preferredCountries}
                                            areaCodes={areaCodes}
                                            enableAreaCodes={preferredCountries}
                                            country={phoneDefaultCountry}
                                            labelNoMarginTop
                                        />
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <Row>
                                            <Col xs={6}>
                                                <Field
                                                    {...genericProps}
                                                    component={FormFieldsComponents.Selector}
                                                    key="relationship"
                                                    name="relationship"
                                                    idField="relationship"
                                                    labelIdField="react-select-relationship-input"
                                                    idForm={FORM_ID}
                                                    optionList={beneficiaryRelationshipList || []}
                                                    renderAs="combo"
                                                    labelNoMarginTop
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <Field
                                                    {...genericProps}
                                                    component={TextField}
                                                    idForm={FORM_ID}
                                                    key="percentage"
                                                    name="percentage"
                                                    idField="percentage"
                                                    isDesktop={isDesktop}
                                                    placeholder=""
                                                    tooltipText={`${FORM_ID}.percentage.tooltip`}
                                                    tooltipPosition="right-centered"
                                                    type="number"
                                                    maxLength={3}
                                                    labelNoMarginTop
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Box className="pb-9 pb-md-12 mt-auto">
                            <Button
                                loading={isSubmitting}
                                btn-small
                                bsStyle="primary"
                                className="mt-auto"
                                // TODO (LABEL)
                                label={isCorporate ? "Agregar Firmante" : "global.continue"}
                                onClick={() => {
                                    submitForm();
                                }}
                                block
                            />
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );

    return <>{renderForm()}</>;
};

PercentageBeneficiaryForm.propTypes = {
    isDesktop: bool,
    mode: string,
    currentLang: string,
    saveCallback: func,
    beneficiaryData: shape({}),
    documentData: shape({}),
    beneficiaryList: arrayOf(shape({})),
    beneficiaryRelationshipList: arrayOf(shape({})),
    isCorporate: bool.isRequired,
};

PercentageBeneficiaryForm.defaultProps = {
    isDesktop: true,
    mode: undefined,
    currentLang: undefined,
    saveCallback: undefined,
    beneficiaryData: undefined,
    documentData: undefined,
    beneficiaryList: {},
    beneficiaryRelationshipList: [],
};

export default PercentageBeneficiaryForm;
