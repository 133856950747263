import * as API from "middleware/api";

export const activateWally = (idAccount) =>
    API.executeWithAccessToken("/activate.wally.user", {
        idAccount,
    });

export const desactivateWally = (idAccount) =>
    API.executeWithAccessToken("/deactivate.wally.user", {
        idAccount,
    });

export const updateWally = (idAccount) =>
    API.executeWithAccessToken("/update.wally.user", {
        idAccount,
    });

export const readWallyUser = () => API.executeWithAccessToken("/read.wally.user", {});

export const listBeneficiariesCommerceWally = (countryCode, start, end) =>
    API.executeWithAccessToken("/list.beneficiaries.commerce.wally", {
        countryCode,
        start,
        end,
    });

export const listBeneficiariesPeopleWally = (phoneNumbers) =>
    API.executeWithAccessToken("/list.affiliations.phone.wally", {
        phoneNumbers,
    });

export const favoriteWally = (phoneNumber, type, isFavorite) =>
    API.executeWithAccessToken("/update.beneficiary.wally.favorite", {
        phoneNumber,
        type,
        isFavorite: !isFavorite,
    });

export const listMovementsWally = () => API.executeWithAccessToken("/movements.list.wally", {});

export const listMovementsPendingWally = () => API.executeWithAccessToken("/movements.listPending.wally", {});
export const deleteMovementPendingWally = (movement) =>
    API.executeWithAccessToken("/transfers.reject.wally.send", { ...movement });

export const getPendingTransactionsWallyQuantity = () =>
    API.executeWithAccessToken("/transactionsWally.get.pending.quantity", {});
