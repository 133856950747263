import { call, put, takeLatest, select } from "redux-saga/effects";

import { types, actions, selectors } from "reducers/desktop";
import * as desktop from "middleware/desktop";
import { actions as notificationActions } from "reducers/notification";
import { actions as widgetsActions } from "reducers/widgets";
import { types as sessionTypes } from "reducers/session";

import * as i18n from "util/i18n";

const sagas = [
    takeLatest([types.LOAD_LAYOUT_REQUEST], loadLayoutRequest),
    takeLatest(types.SAVE_LAYOUT_REQUEST, saveLayoutRequest),
    takeLatest(types.DELETE_WIDGET, deleteWidget),
    takeLatest(types.ADD_WIDGET, addWidget),
    takeLatest(types.LOAD_CORPORATE_GROUP_DESKTOP_REQUEST, loadCorporateGroupDesktop),
];

export default sagas;

function* loadLayoutRequest() {
    const response = yield call(desktop.loadLayoutRequest);
    if (response.type === "W") {
        yield put(actions.loadLayoutFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
    } else {
        const createLayout = ({ layout, availableWidgets }, widget) => {
            if (widget.column === 0) {
                return {
                    layout,
                    availableWidgets: [...availableWidgets, widget],
                };
            }

            return {
                availableWidgets,
                layout: [...layout, widget],
            };
        };

        const { urlFooterBanner, productTypes } = response.data.data;
        const allwidgets = response.data.data.widgets;

        const { layout, availableWidgets } = allwidgets.reduce(createLayout, {
            layout: [],
            availableWidgets: [],
        });
        yield put(actions.loadLayoutSuccess(layout, availableWidgets, urlFooterBanner));
        yield put({ type: sessionTypes.SET_PRODUCT_TYPES, productTypes });
    }
}

function* saveLayoutRequest() {
    const layout = yield select(selectors.getLayout);
    const response = yield call(desktop.saveLayoutRequest, layout);

    if (response.type === "W") {
        yield put(actions.saveLayoutFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
    } else {
        yield put(actions.saveLayoutSuccess(layout));
    }
}

function* deleteWidget({ index }) {
    const layout = yield select(selectors.getLayout);
    const leftSide = layout.slice(0, index);
    const rightSide = layout.slice(index + 1).map((widget) => ({
        ...widget,
        row: widget.row - 1,
    }));
    const layoutWithoutWidget = [...leftSide, ...rightSide];

    yield put(actions.setLayout(layoutWithoutWidget));
    yield put(actions.saveLayoutRequest());
    yield put(actions.addAvailableWidget(layout[index]));
    yield put(widgetsActions.deleteWidget(layout[index]));
}

function* addWidget({ index }) {
    let layout = yield select(selectors.getLayout);
    const widgets = yield select(selectors.getAvailableWidgets);

    layout = layout.map((widget) => ({
        ...widget,
        row: widget.row + 1,
    }));

    yield put(
        actions.setLayout([
            {
                ...widgets[index],
                column: 1,
            },
            ...layout,
        ]),
    );
    yield put(actions.saveLayoutRequest());
    yield put(actions.removeAvailableWidget(index));
}

function* loadCorporateGroupDesktop({ filters }) {
    const response = yield call(desktop.loadCorporateGroupDesktop, filters);

    if (response.type === "W") {
        yield put(actions.loadCorporateGroupDesktopFailure());
    } else {
        yield put(actions.loadCorporateGroupDesktopSuccess(response.data.data));
    }
}
