import React, { useEffect, useState } from "react";
import { bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { selectors, actions } from "reducers/settings";
import FlowTransition from "pages/_components/FlowTransition";
import * as config from "util/config";
import PageLoading from "pages/_components/PageLoading";
import { isDesktop } from "react-device-detect";
import RegisterQuestionsSuccess from "./RegisterQuestionsSuccess";
import RegisterQuestionsConfirm from "./RegisterQuestionsConfirm";
import RegisterQuestions from "./RegisterQuestions";
import { getAllQuestions } from "./questionsUtils";

const steps = {
    making: "making",
    confirmation: "confirmation",
    success: "success",
};

const RegisterQuestionsFlow = ({
    action,
    registeredQuestions,
    isFetching,
    registerSecurityQuestions,
    updateSecurityQuestions,
    handleBack,
}) => {
    const [allQuestions, setAllQuestions] = useState([]);
    const [maxQuestions, setMaxQuestions] = useState(5);

    const [currentStep, setCurrentStep] = useState([steps.making]);
    const [confirmationData, setConfirmationData] = useState({});

    useEffect(() => {
        setAllQuestions(getAllQuestions());
        setMaxQuestions(config.getInteger("bank.catalog.NPREGUREGIS", 5));
    }, []);

    const onSuccess = () => {
        setCurrentStep(steps.success);
    };

    const handleSubmitMaking = (questions) => {
        setConfirmationData(questions);
        setCurrentStep(steps.confirmation);
    };

    const handleSubmitConfirm = ({ secondFactor }, formikBag) => {
        if (action === "register") {
            registerSecurityQuestions(confirmationData, secondFactor, formikBag, onSuccess);
        } else {
            updateSecurityQuestions(confirmationData, secondFactor, formikBag, onSuccess);
        }
    };

    const normalizeData = () => {
        const data = {};
        Object.keys(registeredQuestions).forEach((key) => {
            data[key] = { question: registeredQuestions[key], answer: "" };
        });
        return data;
    };

    if (action === "update") {
        if (!registeredQuestions) {
            handleBack();
            return <></>;
        }
    }

    return (
        <PageLoading loading={isFetching}>
            <FlowTransition
                currentPage={currentStep}
                pages={{
                    [steps.making]: () => (
                        <RegisterQuestions
                            isDesktop={isDesktop}
                            allQuestions={allQuestions}
                            maxQuestions={maxQuestions}
                            data={action === "update" ? normalizeData() : confirmationData}
                            action={action}
                            handleSubmit={handleSubmitMaking}
                        />
                    ),
                    [steps.confirmation]: () => (
                        <RegisterQuestionsConfirm
                            action={action}
                            isDesktop={isDesktop}
                            handleSubmit={handleSubmitConfirm}
                        />
                    ),
                    [steps.success]: () => <RegisterQuestionsSuccess isDesktop={isDesktop} action={action} />,
                }}
            />
        </PageLoading>
    );
};

RegisterQuestionsFlow.propTypes = {
    isFetching: bool,
    registerSecurityQuestions: func.isRequired,
    updateSecurityQuestions: func.isRequired,
    handleBack: func.isRequired,
    action: string,
    registeredQuestions: shape({}),
};

RegisterQuestionsFlow.defaultProps = {
    action: "register",
    isFetching: false,
    registeredQuestions: undefined,
};

const mapStateToProps = (state) => ({
    isFetching: selectors.isFetching(state),
    registeredQuestions: selectors.getSecurityQuestions(state),
});

const mapDispatchToProps = (dispatch) => ({
    registerSecurityQuestions: (questions, secondFactor, formikBag, onSuccess) => {
        dispatch(actions.registerSecurityQuestions(questions, secondFactor, formikBag, onSuccess));
    },
    updateSecurityQuestions: (questions, secondFactor, formikBag, onSuccess) => {
        dispatch(actions.updateSecurityQuestions(questions, secondFactor, formikBag, onSuccess));
    },
    handleBack: () => {
        dispatch(routerActions.push("/securityQuestions"));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterQuestionsFlow);
