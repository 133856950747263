import { isMobileNativeFunc } from "util/device";

export const setCustomKeyMap = async (keyMap) => {
    if (!isMobileNativeFunc()) {
        return;
    }
    const googlePlayData = await window.app.getAppPlayServices();

    const playServices = new PlayServicesCrashFactory().createPlayService(googlePlayData);
    playServices.setCustomKeyMap(keyMap);
};

export const crashLogString = async (logMessage, keyMap) => {
    if (!isMobileNativeFunc()) {
        return;
    }
    const googlePlayData = await window.app.getAppPlayServices();
    const playServices = new PlayServicesCrashFactory().createPlayService(googlePlayData);
    playServices.crashLogString(logMessage, keyMap);
};

export const crashLogData = async (data, keyMap) => {
    if (!isMobileNativeFunc()) {
        return;
    }
    const googlePlayData = await window.app.getAppPlayServices();
    const playServices = new PlayServicesCrashFactory().createPlayService(googlePlayData);
    playServices.crashLogData(data, keyMap);
};

class PlayServicesCrash {
    // eslint-disable-next-line no-useless-constructor, no-empty-function
    constructor() {}

    // eslint-disable-next-line class-methods-use-this
    isGenericCrashAnalitycsEnabled() {
        return isMobileNativeFunc() && !!window?.cordova?.plugins?.firebase?.crashlytics;
    }

    // eslint-disable-next-line class-methods-use-this
    setInnerCustomKeyMap(keyMap) {
        if (!keyMap) {
            return;
        }
        Object.entries(keyMap).forEach(([key, value]) => {
            window.cordova.plugins.firebase.crashlytics.setCustomKey(key, value);
        });
    }

    setCustomKeyMapGeneric(keyMap) {
        if (!this.isGenericCrashAnalitycsEnabled()) {
            return;
        }
        this.setInnerCustomKeyMap(keyMap);
    }

    crashLogStringGeneric(logMessage, keyMap) {
        if (!this.isGenericCrashAnalitycsEnabled() || !logMessage) {
            return;
        }

        if (typeof logMessage !== "string") {
            return;
        }

        this.setInnerCustomKeyMap(keyMap);
        window.cordova.plugins.firebase.crashlytics.logError(logMessage);
    }

    crashLogDataGeneric(data, keyMap) {
        try {
            if (!this.isGenericCrashAnalitycsEnabled() || !data) {
                return;
            }

            this.setInnerCustomKeyMap(keyMap);
            const dataString = JSON.stringify(data);
            window.cordova.plugins.firebase.crashlytics.logError(dataString);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }
}

class GmsPlayServicesCrash extends PlayServicesCrash {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();
    }

    setCustomKeyMap(keyMap) {
        super.setCustomKeyMapGeneric(keyMap);
    }

    crashLogString = (logMessage, keyMap) => {
        super.crashLogStringGeneric(logMessage, keyMap);
    };

    crashLogData = (logMessage, keyMap) => {
        super.crashLogDataGeneric(logMessage, keyMap);
    };
}

class IOSPlayServicesCrash extends PlayServicesCrash {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();
    }

    setCustomKeyMap(keyMap) {
        super.setCustomKeyMapGeneric(keyMap);
    }

    crashLogString = (logMessage, keyMap) => {
        super.crashLogStringGeneric(logMessage, keyMap);
    };

    crashLogData = (logMessage, keyMap) => {
        super.crashLogDataGeneric(logMessage, keyMap);
    };
}

class HmsPlayServicesCrash extends PlayServicesCrash {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();
    }

    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    setCustomKeyMap(keyMap) {
        // TODO: implements huawei services
    }

    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    crashLogString = (logMessage, keyMap) => {
        // TODO: implements huawei services
    };

    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    crashLogData = (logMessage, keyMap) => {
        // TODO: implements huawei services
    };
}

class PlayServicesCrashFactory {
    static GMS_SERVICES = "GMS";

    static HMS_SERVICES = "HMS";

    static IOS_SERVICES = "IOS";

    // eslint-disable-next-line class-methods-use-this
    createPlayService(googlePlayData) {
        if (!googlePlayData?.code || googlePlayData.code !== "0000" || !googlePlayData?.data) {
            return undefined;
        }

        const type = googlePlayData?.data;

        switch (type) {
            case PlayServicesCrashFactory.GMS_SERVICES:
                return new GmsPlayServicesCrash();
            case PlayServicesCrashFactory.HMS_SERVICES:
                return new HmsPlayServicesCrash();
            case PlayServicesCrashFactory.IOS_SERVICES:
                return new IOSPlayServicesCrash();
            default:
                return undefined;
        }
    }
}
