import { call, put, takeLatest, select } from "redux-saga/effects";
import { routerActions, replace, push } from "react-router-redux";

import { actions as notificationActions } from "reducers/notification";
import { credentialsToUnderscoreFormat } from "util/form.js";
import { selectors as sessionSelectors, actions as sessionActions } from "reducers/session";
import { types as secondFactorTypes } from "reducers/secondFactor";
import * as administrationUtils from "util/administration";
import * as utilDownload from "util/download";
import * as form from "middleware/form";
import * as i18n from "util/i18n";

const createSignaturesSchemePreRequest = (middleware, actions) =>
    function* createSignaturesSchemePreRequestSaga() {
        const { data, type } = yield call(middleware.createSignaturesSchemePre);

        if (type === "W") {
            yield put(actions.createSignaturesSchemePreFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationCreateSignatureScheme",
                ]),
            );
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.signatures.create.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;
            yield put(actions.createSignaturesSchemePreSuccess(data.data, credentialGroups));
        }
    };

const createSignaturesSchemePreviewRequest = (middleware, actions) =>
    function* createSignaturesSchemePreviewRequestSaga({ signatureData, credentialGroups, formikBag }) {
        const { setSubmitting } = formikBag;
        const { alias, signatureLevelsCounts, ...restOfParams } = signatureData;
        const signatureLevelCountToInt = administrationUtils.signatuleLevelsCountToInt(signatureLevelsCounts);
        const { data, type } = yield call(middleware.createSignaturesSchemePreview, {
            ...restOfParams,
            signatureAlias: alias, 
            signatureLevelsCounts: signatureLevelCountToInt,
            ...{
                capFrequencies: [restOfParams.topAmount.period] || [],
                maxAmount: restOfParams.topAmount.amount || -1,
            }
        });

        if (type === "W") {
            let errorMessage = i18n.get("global.unexpectedError")
            if (data?.data?.signatureAlias) {
                errorMessage = data?.data?.signatureAlias;
            }
            yield put(
                notificationActions.showNotification(errorMessage, "error", [
                    "administrationCreateSignatureScheme",
                ]),
            );
            yield put(actions.createSignaturesSchemePreviewFailure());
            setSubmitting(false);
        } else {
            yield put(actions.createSignaturesSchemeConfirmPre(signatureData, credentialGroups));
            const administrationScheme = yield select((state) => sessionSelectors.getAdministrationScheme(state));
            yield put(replace(`/administration/${administrationScheme}/signaturesSchemes/create/confirm`));
            yield put(actions.createSignaturesSchemePreviewSuccess(data.data));
        }
    };

const createSignaturesSchemeRequest = (middleware, actions) =>
    function* createSignaturesSchemeRequestSaga({ signatureData, formikBag }) {
        const { setSubmitting, setErrors } = formikBag;
        const { secondFactor, signatureLevelsCounts, ...restOfParams } = signatureData;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(secondFactor);
        const signatureLevelCountToInt = administrationUtils.signatuleLevelsCountToInt(signatureLevelsCounts);
        const { data, type } = yield call(middleware.createSignaturesScheme, {
            ...restOfParams,
            signatureLevelsCounts: signatureLevelCountToInt,
            ...credentialsWithUnderscore,
            ...{
                capFrequencies: [restOfParams.topAmount.period] || [],
                maxAmount: restOfParams.topAmount.amount || -1,
            },
        });

        setSubmitting(false);

        if (type === "W") {
            const { code } = data;
            let showNotification = true;
            if (code === "COR020W" && data.data.secondFactor) {
                yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
                showNotification = false;
            }
            if (code === "API707W") {
                yield put(sessionActions.logoutUserBlocked());
                return;
            }
            if (code === "API708W") {
                yield put(
                    notificationActions.showNotification(i18n.get("secondFactor.credential.otp.expired", "Código OTP expirado, solicite un nuevo código OTP"), "warning", [
                        "administrationSignaturesSchemes",
                    ]),
                );
                return;
            }

            let errorMessage = data.data.NO_FIELD || i18n.get("global.unexpectedError");
            setErrors(data.data);

            if (data.code === "COR054W") {
                errorMessage = data.message;
            }

            if (data.code === "COR020W" && data.data.signatureAlias) {
                showNotification = true;
                errorMessage = data.data.signatureAlias;
            }

            if (showNotification) {
                yield put(
                    notificationActions.showNotification(errorMessage, "error", [
                        "administrationCreateSignatureScheme",
                    ]),
                );
            }
        } else {
            if (data.code === "COR023I") {
                const message = i18n.get("administration.signatures.create.pending.messages", data.message);
                yield put(
                    notificationActions.showNotification(message, "warning", ["administrationSignaturesSchemes"]),
                );
            } else {
                yield put(
                    notificationActions.showNotification(data.message, "success", ["administrationSignaturesSchemes"]),
                );
            }
            const administrationScheme = yield select((state) => sessionSelectors.getAdministrationScheme(state));
            yield put(replace(`/administration/${administrationScheme}/signaturesSchemes`));
            yield put(actions.createSignaturesSchemeSuccess(data.data));
        }
    };

const deleteSignaturesSchemePreRequest = (middleware, actions) =>
    function* deleteSignaturesSchemePreRequestSaga(params) {
        const { idSignature } = params;
        const { data, type } = yield call(middleware.deleteSignaturesSchemePre, idSignature);

        if (type === "W") {
            yield put(actions.deleteSignaturesSchemePreFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationSignaturesSchemes",
                ]),
            );
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.signatures.delete.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;
            yield put(actions.deleteSignaturesSchemePreSuccess(data.data, credentialGroups));
        }
    };

const deleteSignaturesSchemeRequest = (middleware, actions) =>
    function* deleteSignaturesSchemeRequestSaga({ signatureData, formikBag }) {
        const { secondFactor, ...restOfParams } = signatureData;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(secondFactor);
        const { signatureAlias, capList, functionalGroups = [], groupsMap, idSignature, signatureType } = restOfParams;
        const { data, type } = yield call(middleware.deleteSignaturesScheme, {
            ...credentialsWithUnderscore,
            signatureAlias,
            capFrequencies: capList.length ? [capList[0].frequency] : [],
            functionalGroups,
            maxAmount: capList.length ? capList[0].maximum : -1,
            signatureId: idSignature,
            signatureLevelsCounts: groupsMap,
            signatureType,
        });

        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);

        if (type === "W") {
            const { code } = data;
            let showNotification = true;
            if (code === "API707W") {
                yield put(sessionActions.logoutUserBlocked());
                return;
            }
            if (code === "API708W") {
                yield put(
                    notificationActions.showNotification(i18n.get("secondFactor.credential.otp.expired", "Código OTP expirado, solicite un nuevo código OTP"), "warning", [
                        "administrationSignaturesSchemes",
                    ]),
                );
                return;
            }
            if (code === "COR020W" && data.data.secondFactor) {
                showNotification = false;
                yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            }

            let errorMessage = data.data.NO_FIELD || i18n.get("global.unexpectedError");
            setErrors(data.data);

            if (data.code === "COR054W") {
                errorMessage = data.message;
            }

            if (showNotification) {
                yield put(
                    notificationActions.showNotification(errorMessage, "error", [
                        "administrationDeleteSignatureScheme",
                    ]),
                );
            }
        } else {
            if (data.code === "COR023I") {
                const message = i18n.get("administration.signatures.delete.pending.messages", data.message);
                yield put(
                    notificationActions.showNotification(message, "warning", ["administrationSignaturesSchemes"]),
                );
            } else {
                yield put(
                    notificationActions.showNotification(data.message, "success", ["administrationSignaturesSchemes"]),
                );
            }
            yield put(routerActions.goBack());
            yield put(actions.deleteSignaturesSchemeSuccess(data.data));
        }
    };

const listSignaturesSchemesRequest = (middleware, actions) =>
    function* listSignaturesSchemesRequestSaga({ params }) {
        const { data, type } = yield call(middleware.listSignaturesSchemes, params ? params.filters : params);

        if (type === "W") {
            yield put(actions.listSignaturesSchemesFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationSignaturesSchemes",
                ]),
            );
        } else {
            yield put(actions.listSignaturesSchemesSuccess(data.data));
        }
    };

const loadMoreSignaturesRequest = (middleware, actions) =>
    function* loadMoreSignaturesRequestSaga({ pageNumber }) {
        const { data, type } = yield call(middleware.listSignaturesSchemes, pageNumber);

        if (type === "W") {
            yield put(actions.listSignaturesSchemesFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationSignaturesSchemes",
                ]),
            );
        } else {
            yield put(actions.listMoreSignaturesSchemesSuccess(data.data));
        }
    };

const modifySignaturesSchemePreRequest = (middleware, actions) =>
    function* modifySignaturesSchemePreRequesSaga(params) {
        const { idSignature } = params;
        const { data, type } = yield call(middleware.modifySignaturesSchemePre, idSignature);

        if (type === "W") {
            yield put(actions.modifySignaturesSchemePreFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationModifySignatureScheme",
                ]),
            );
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.signatures.modify.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;
            yield put(actions.modifySignaturesSchemePreSuccess(data.data, credentialGroups));
        }
    };

const modifySignaturesSchemeRequest = (middleware, actions) =>
    function* modifySignaturesSchemeRequestSaga({ signatureData, formikBag }) {
        const { secondFactor, signatureLevelsCounts, ...restOfParams } = signatureData;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(secondFactor);
        const signatureLevelCountToInt = administrationUtils.signatuleLevelsCountToInt(signatureLevelsCounts);
        const { data, type } = yield call(middleware.modifySignaturesScheme, {
            ...restOfParams,
            signatureLevelsCounts: signatureLevelCountToInt,
            ...credentialsWithUnderscore,
            ...{
                capFrequencies: [restOfParams.topAmount.period] || [],
                maxAmount: restOfParams.topAmount.amount || -1,
            },
        });
        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);

        if (type === "W") {
            let showNotification = true;
            const { code } = data;
            if (code === "COR020W" && data.data.secondFactor) {
                yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
                showNotification = false;
            }
            if (code === "API707W") {
                yield put(sessionActions.logoutUserBlocked());
                return;
            }

            if (code === "API708W") {
                yield put(
                    notificationActions.showNotification(i18n.get("secondFactor.credential.otp.expired", "Código OTP expirado, solicite un nuevo código OTP"), "warning", [
                        "administrationSignaturesSchemes",
                    ]),
                );
                return;
            }

            let errorMessage = data.data.NO_FIELD || i18n.get("global.unexpectedError");
            setErrors(data.data);

            if (data.code === "COR054W") {
                errorMessage = data.message;
            }

            if (showNotification) {
                yield put(
                    notificationActions.showNotification(errorMessage, "error", [
                        "administrationModifySignatureScheme",
                    ]),
                );
            }
        } else {
            if (data.code === "COR023I") {
                const message = i18n.get("administration.signatures.modify.pending.messages", data.message);
                yield put(
                    notificationActions.showNotification(message, "warning", ["administrationSignaturesSchemes"]),
                );
            } else {
                yield put(
                    notificationActions.showNotification(data.message, "success", ["administrationSignaturesSchemes"]),
                );
            }
            yield put(routerActions.go(-2));
            yield put(actions.modifySignaturesSchemeSuccess(data.data));
        }
    };

    const modifySignaturesSchemePreviewRequest = (middleware) =>
    function* modifySignaturesSchemePreviewRequestSaga({ data: signatureData, formikBag }) {
        const { alias, signatureLevelsCounts, ...restOfParams } = signatureData;
        const signatureLevelCountToInt = administrationUtils.signatuleLevelsCountToInt(signatureLevelsCounts);
        const { data, type } = yield call(middleware.modifySignaturesSchemePreview, {
            ...restOfParams,
            signatureAlias: alias, 
            signatureLevelsCounts: signatureLevelCountToInt,
            ...{
                capFrequencies: [restOfParams.topAmount.period] || [],
                maxAmount: restOfParams.topAmount.amount || -1,
            },
        });
        const { setSubmitting } = formikBag;

        if (type === "W") {
            let errorMessage = i18n.get("global.unexpectedError")
            if (data?.data?.signatureAlias) {
                errorMessage = data?.data?.signatureAlias;
            }
            yield put(
                notificationActions.showNotification(errorMessage, "error", [
                    "administrationModifySignatureScheme",
                ]),
            );
            setSubmitting(false);
        } else {
            const administrationScheme = yield select((state) => sessionSelectors.getAdministrationScheme(state));
            yield put(push(`/administration/${administrationScheme}/signaturesSchemes/${signatureData.signatureId}/confirm`));
        }
    };

const signatureSchemeExportRequest = (middleware, actions) =>
    function* signatureSchemeExportRequestSaga({ format }) {
        const { data, type } = yield call(middleware.exportSignatureSchemes, { format, orderBy: "id_signature ASC" });

        if (type === "W") {
            yield put(actions.exportListFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationUsers",
                ]),
            );
        } else {
            const { content, fileName } = data.data;
            if (format === "pdf") {
                utilDownload.downloadPdf(fileName, content);
            } else {
                utilDownload.downloadXls(fileName, content);
            }
            yield put(actions.exportListSuccess());
        }
    };

const sagasCreator = (middleware, types, actions) => [
    takeLatest(types.CREATE_SIGNATURES_SCHEME_PRE_REQUEST, createSignaturesSchemePreRequest(middleware, actions)),
    takeLatest(types.CREATE_SIGNATURES_SCHEME_REQUEST, createSignaturesSchemeRequest(middleware, actions)),
    takeLatest(types.CREATE_SIGNATURES_SCHEME_PREVIEW_REQUEST, createSignaturesSchemePreviewRequest(middleware, actions)),
    takeLatest(types.DELETE_SIGNATURES_SCHEME_PRE_REQUEST, deleteSignaturesSchemePreRequest(middleware, actions)),
    takeLatest(types.DELETE_SIGNATURES_SCHEME_REQUEST, deleteSignaturesSchemeRequest(middleware, actions)),
    takeLatest(types.LIST_SIGNATURES_SCHEMES_REQUEST, listSignaturesSchemesRequest(middleware, actions)),
    takeLatest(types.SIGNATURES_SCHEME_LOAD_MORE_REQUEST, loadMoreSignaturesRequest(middleware, actions)),
    takeLatest(types.MODIFY_SIGNATURES_SCHEME_PRE_REQUEST, modifySignaturesSchemePreRequest(middleware, actions)),
    takeLatest(types.MODIFY_SIGNATURES_SCHEME_REQUEST, modifySignaturesSchemeRequest(middleware, actions)),
    takeLatest(types.MODIFY_SIGNATURES_SCHEME_CONFIRM_PRE, modifySignaturesSchemePreviewRequest(middleware, actions)),
    takeLatest(types.SIGNATURES_SCHEME_EXPORT_LIST_REQUEST, signatureSchemeExportRequest(middleware, actions)),
];

export default sagasCreator;
