import Box from "pages/_components/Box";
import React from "react";
import Text from "pages/_components/Text";
import { string, func } from "prop-types";
import Image from "pages/_components/Image";
import Button from "../Button";

class BoxFieldError extends React.Component {
    static propTypes = {
        message: string,
        notificationType: string.isRequired, // error, warning, info, success
        handleClickClose: func.isRequired,
    };

    static defaultProps = {
        message: "",
    };

    render() {
        const { message, notificationType, handleClickClose } = this.props;
        return (
            <Box
                className="p-4 pr-8 boxFieldError"
                background={notificationType}
                border={`${notificationType}-1`}
                borderRadius="md"
                display="flex"
                alignY="center">
                <Box className="form-group-error mr-2">
                    <Image src={`images/icons/${notificationType}Icon.svg`} className="svg-icon" />
                </Box>
                <Text size="5" color="text-boton">
                    {message}
                </Text>
                <Box position="absolute" top="0" right="0">
                    <Button
                        ariaLabel="global.close"
                        className="view-close"
                        onClick={() => {
                            handleClickClose();
                        }}
                        image="images/cross.svg"
                        bsStyle="link"
                    />
                </Box>
            </Box>
        );
    }
}

export default BoxFieldError;
