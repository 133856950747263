import React from "react";
import { arrayOf, number, string, shape, func, bool } from "prop-types";
import NumberFormat from "react-number-format";
import { compose } from "redux";

import * as numberUtils from "util/number";

import formField from "pages/forms/_components/_fields/_commons/formField";
import withFocus from "pages/_components/withFocus";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Select from "pages/forms/_components/_fields/Select";
import AmountLabel from "pages/forms/_components/_fields/Amount/AmountLabel";
import FieldLabel from "pages/_components/fields/FieldLabel";

const INPUT_REGEX_REPLACE = /[^0-9.,]/g;

class Paycreditcardamount extends React.Component {
    quantityRef = null;

    static propTypes = {
        data: shape({
            options: arrayOf(shape({})).isRequired,
            decimalSeparator: string.isRequired,
            precision: number.isRequired,
            thousandsSeparator: string.isRequired,
        }).isRequired,
        form: shape({ values: arrayOf(shape({})) }).isRequired,
        fieldList: shape({}).isRequired,
        idCreditCardSelector: string.isRequired,
        focus: bool.isRequired,
        value: shape({ currency: string, quantity: string }),
        setValue: func.isRequired,
        editing: bool.isRequired,
        placeholder: string,
        toggleIsFocused: func,
        onBlur: func,
        idField: string.isRequired,
    };

    static defaultProps = {
        value: {},
        placeholder: "",
        onBlur: null,
        toggleIsFocused: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedPaymentOption: "total",
        };
    }

    componentDidMount() {
        const { editing, data, focus } = this.props;
        if (editing) {
            this.handleCurrencyChange(data.options[0]);
        }

        // al primer campo del formulario por lo general se le pasa focus en true
        if (this.quantityRef && focus) {
            this.quantityRef.focus();
        }
    }

    handleCurrencyChange = ({ id }) => {
        const { setValue } = this.props;
        const { selectedPaymentOption } = this.state;
        const { totalPayment, minimumPayment } = this.creditCardData();

        let quantity;
        if (selectedPaymentOption === "") {
            quantity = "";
        } else if (selectedPaymentOption === "total") {
            quantity = totalPayment;
        } else {
            quantity = minimumPayment;
        }

        setValue({
            currency: id,
            quantity,
        });
    };

    handleQuantityChange = () => {
        const {
            data: { decimalSeparator, precision },
            value,
            setValue,
        } = this.props;

        let quantity = this.quantityRef.value.replace(INPUT_REGEX_REPLACE, "");
        quantity = numberUtils.createDecimalNumber(quantity, decimalSeparator, precision);
        const amount = {
            currency: value.currency,
            quantity,
        };

        setValue(amount);
        this.setState({
            selectedPaymentOption: "",
        });
    };

    handlePaymentOptionChange = ({ target: { value: newValue } }) => {
        const { value, setValue } = this.props;
        const { minimumPayment, totalPayment } = this.creditCardData();

        let quantity;
        if (newValue === "total") {
            quantity = totalPayment;
        } else {
            quantity = minimumPayment;
        }

        setValue({
            currency: value.currency,
            quantity,
        });

        this.setState({
            selectedPaymentOption: newValue,
        });
    };

    creditCardData() {
        const { idCreditCardSelector, form, fieldList } = this.props;
        const selectedCreditCard = form.values[idCreditCardSelector].value || form.values[idCreditCardSelector];

        const {
            data: { options },
        } = fieldList.find(({ idField }) => idField === idCreditCardSelector) || {};
        return options.find(({ id }) => id === selectedCreditCard) || {};
    }

    render() {
        const {
            editing,
            value,
            placeholder,
            data: { options, decimalSeparator, precision, thousandsSeparator },
            toggleIsFocused,
            onBlur,
            idField,
        } = this.props;

        const { selectedPaymentOption } = this.state;

        const selectedCurrency = value ? value.currency : "";
        const selectedQuantity = value ? value.quantity : "";

        if (editing) {
            return (
                <>
                    <div className="input-group">
                        {options.length === 1 ? (
                            <span className="currency">{options[0].label}</span>
                        ) : (
                            <>
                                <FieldLabel
                                    hideLabel
                                    labelKey="form.field.amount.currency"
                                    mode="edit"
                                    idField={`${idField}.currency`}
                                />
                                <Select
                                    id={`${idField}.currency`}
                                    className="currency-selector slideFromBottom flex-container"
                                    name="currency"
                                    searchable={false}
                                    onChange={this.handleCurrencyChange}
                                    value={selectedCurrency}
                                    valueKey="id"
                                    labelKey="label"
                                    options={options}
                                    clearable={false}
                                />
                            </>
                        )}
                        <NumberFormat
                            id={idField}
                            name="quantity"
                            className="form-control"
                            type="text"
                            onBlur={onBlur}
                            onChange={this.handleQuantityChange}
                            maxLength="20"
                            value={selectedQuantity}
                            placeholder={placeholder}
                            getInputRef={(ref) => {
                                this.quantityRef = ref;
                            }}
                            onFocus={toggleIsFocused}
                            decimalScale={precision}
                            thousandSeparator={thousandsSeparator}
                            decimalSeparator={decimalSeparator}
                            fixedDecimalScale
                        />
                    </div>

                    <div className="form-group-control-list">
                        <div className="c-control c-control--radio display-flex gap-3 align-items-center position-relative">
                            <input
                                id="totalAmount"
                                className="c-control-input"
                                type="radio"
                                name="pago"
                                value="total"
                                checked={selectedPaymentOption === "total"}
                                onChange={this.handlePaymentOptionChange}
                            />

                            <label className="c-control-label" htmlFor="totalAmount">
                                <div className="c-control-icons">
                                    <div className="c-control-mark">
                                        <Image src="images/icons/checkBox.svg" className="svg-icon svg-caret" />
                                    </div>
                                </div>
                                <I18n id="paycreditcardamount.totalPaymentLabel" />
                            </label>
                        </div>
                        <div className="c-control c-control--radio display-flex gap-3 align-items-center position-relative">
                            <input
                                id="minimumAmount"
                                className="c-control-input"
                                type="radio"
                                name="pago"
                                value="minimum"
                                checked={selectedPaymentOption === "minimum"}
                                onChange={this.handlePaymentOptionChange}
                            />

                            <label className="c-control-label" htmlFor="minimumAmount">
                                <div className="c-control-icons">
                                    <div className="c-control-mark">
                                        <Image src="images/icons/checkBox.svg" className="svg-icon svg-caret" />
                                    </div>
                                </div>
                                <I18n id="paycreditcardamount.minimumPaymentLabel" />
                            </label>
                        </div>
                    </div>
                </>
            );
        }
        return (
            /* TODO: falta la moneda */
            <FormattedAmount {...value} />
        );
    }
}

export default compose(
    withFocus,
    formField({
        formClass: "form-group--composite",
        customLabel: AmountLabel,
    }),
)(Paycreditcardamount);
