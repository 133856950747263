import { TIME_CHANGE_OTP } from "constants.js";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import { bool, func, number, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { connect } from "react-redux";
import {
    actions as authenticateHandlerActions,
    selectors as authenticateHandlerSelector,
} from "reducers/authenticateHandler";
import styled from "styled-components";
import { ENT000, generateOTP, hasRegisteredIdentity } from "util/softToken.util";

const TimerWrapper = styled.div`
    > div {
        width: 100% !important;
        display: flex;
        justify-content: center;
    }
    path:last-child {
        stroke: ${(props) => props.theme.primaryColor};
        stroke-linecap: round;
    }
`;
const colors = ["#229FA5", "#229FA5", "#DB3528", "#DB3528"];
const colorTimes = [30, 5, 5, 0];

const SoftTokenTimer = ({
    isMobile,
    getTokenInfo,
    tokenInfo,
    tokenStatus,
    isFetching,
    dispatch,
    onChange,
    isActive,
}) => {
    const [validateError, setValidateError] = useState(false);
    const [currentOtp, setCurrentOtp] = useState("");
    const [countDownTime, setCountDownTime] = useState(TIME_CHANGE_OTP);
    const [remainingTime, setRemainingTime] = useState(0);
    const [hasEntrustIdentity, setHasEntrustIdentity] = useState(false);

    const getOtpCode = async () => {
        try {
            const responseHasRegisterIdentity = await hasRegisteredIdentity();
            if (!responseHasRegisterIdentity) {
                return;
            }

            const { code: codeIdentity, data: dataIdentity } = JSON.parse(responseHasRegisterIdentity);
            if (!codeIdentity || !dataIdentity || codeIdentity !== ENT000 || dataIdentity !== "true") {
                return;
            }

            const currentOtpResponse = await generateOTP();
            if (!currentOtpResponse) {
                return;
            }

            const { code, data } = JSON.parse(currentOtpResponse);
            if (!code || !data || code !== ENT000) {
                return;
            }

            console.log("Tiene identidad entrust registrada: ", code, data);
            setHasEntrustIdentity(true);

            if (data === currentOtp) {
                return;
            }

            setCurrentOtp(data);

            if (onChange) {
                onChange(data);
            }
        } catch (error) {
            console.log("Ha ocurrido un error verificando la identidad entrust ", error);
        }
    };

    useEffect(() => {
        getOtpCode();
    }, []);

    useEffect(() => {
        console.log(currentOtp, hasEntrustIdentity);
        if (currentOtp && currentOtp !== "" && hasEntrustIdentity) {
            setValidateError(false);
        } else {
            setValidateError(true);
        }
    }, [currentOtp, hasEntrustIdentity]);

    const renderTime = ({ remainingTime }) => {
        setRemainingTime(remainingTime);
        if (remainingTime === 0) {
            return <div className="timer" />;
        }
        return (
            <div>
                <h1 style={{ textAlign: "center", fontSize: "28px" }}>
                    <b>{currentOtp}</b>
                </h1>
            </div>
        );
    };

    const handleOnComplete = () => {
        getOtpCode();
        return { shouldRepeat: true, delay: 0.1 };
    };

    useEffect(() => {
        const intervalCodeOtp = setTimeout(() => {
            if (countDownTime === TIME_CHANGE_OTP) {
                getOtpCode();
                setCountDownTime(TIME_CHANGE_OTP);
            } else {
                getOtpCode();
            }
            setCountDownTime(countDownTime - 1);
        }, TIME_CHANGE_OTP * 1000);

        return () => {
            clearTimeout(intervalCodeOtp);
            setCountDownTime(TIME_CHANGE_OTP);
        };
    }, [remainingTime, isFetching]);

    const renderContent = () => (
        <>
            <MainContainer showLoader={isFetching}>
                <TimerWrapper>
                    <CountdownCircleTimer
                        isPlaying
                        duration={TIME_CHANGE_OTP}
                        initialRemainingTime={countDownTime}
                        colors={colors}
                        colorsTime={colorTimes}
                        onComplete={handleOnComplete}
                        strokeLinecap="round"
                        trailColor="#E0E0E0"
                        isGrowing
                        rotation="counterclockwise">
                        {renderTime}
                    </CountdownCircleTimer>
                </TimerWrapper>
                <span
                    className="value"
                    style={{
                        textAlign: "center",
                        paddingTop: "10px",
                    }}>
                    {remainingTime} segundos restantes.
                </span>
            </MainContainer>
        </>
    );

    const renderLoading = () => (
        <Box display="flex" alignX="center" alignY="center" fullWidth>
            <Button className="btn-token-loading" loading {...{ block: true }} />
        </Box>
    );

    return validateError ? renderLoading() : renderContent();
};

SoftTokenTimer.propTypes = {
    isActive: bool,
    pinCode: bool,
    pinTries: number.isRequired,
    showPinValidationModal: bool.isRequired,
    tokenStatus: string,
    hasActiveSession: bool,
    isFetching: bool,
    onChange: func,
};

SoftTokenTimer.defaultProps = {
    isActive: undefined,
    pinCode: false,
    isFetching: true,
    tokenStatus: "",
    onChange: () => {},
};

const mapStateToProps = (state) => ({
    pinCode: authenticateHandlerSelector.getPinCode(state),
    pinTries: authenticateHandlerSelector.getPinTries(state),
    isPinProtectionValidated: authenticateHandlerSelector.isPinProtectionValidated(state),
    showPinValidationModal: authenticateHandlerSelector.showPinValidationModal(state),
    isPinProtectionValidatedCount: authenticateHandlerSelector.isPinProtectionValidatedCount(state),
    isFetching: authenticateHandlerSelector.isFetching(state),
    tokenStatus: authenticateHandlerSelector.getTokenStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    getTokenInfo: () => {
        dispatch(authenticateHandlerActions.getTokenInfoRequest());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SoftTokenTimer);
