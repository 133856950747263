// Action types
export const types = {
    PUSH_NOTIFICATIONS_LIST_DEVICES: "pushNotifications/PUSH_NOTIFICATIONS_LIST_DEVICES",
    PUSH_NOTIFICATIONS_LIST_DEVICES_SUCCESS: "pushNotifications/PUSH_NOTIFICATIONS_LIST_DEVICES_SUCCESS",
    PUSH_NOTIFICATIONS_DELETE_DEVICE: "pushNotifications/PUSH_NOTIFICATIONS_DELETE_DEVICE",
    PUSH_NOTIFICATIONS_DELETE_DEVICE_PRE: "pushNotifications/PUSH_NOTIFICATIONS_DELETE_DEVICE_PRE",
    PUSH_NOTIFICATIONS_REGISTER_DEVICE: "pushNotifications/PUSH_NOTIFICATIONS_REGISTER_DEVICE",
    LIST_ALL_USER_DEVICES: "pushNotifications/LIST_ALL_USER_DEVICES",
};

// Initial state
export const INITIAL_STATE = {
    fetching: false,
    pushNotificationsConfiguredUserDevices: null,
    device: null,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.PUSH_NOTIFICATIONS_LIST_DEVICES:
            return { ...state, fetching: true };
        case types.PUSH_NOTIFICATIONS_LIST_DEVICES_SUCCESS:
            return { ...state, fetching: false, pushNotificationsConfiguredUserDevices: action.listUserDevices };
        case types.PUSH_NOTIFICATIONS_DELETE_DEVICE_PRE:
            return { ...state, device: action.device };
        case types.CLEAN:
            return INITIAL_STATE;
        case types.LIST_ALL_USER_DEVICES:
            return { ...state, fetching: true };
        default:
            return state;
    }
};

// Action creators
export const actions = {
    pushNotificationsListUserDevices: () => ({
        type: types.PUSH_NOTIFICATIONS_LIST_DEVICES,
    }),
    pushNotificationsDeleteUserDevice: (idDevice) => ({
        type: types.PUSH_NOTIFICATIONS_DELETE_DEVICE,
        idDevice,
    }),
    pushNotificationsDeleteUserDevicePre: (device) => ({
        type: types.PUSH_NOTIFICATIONS_DELETE_DEVICE_PRE,
        device,
    }),
    pushNotificationsRegisterUserDevice: (idDevice, pushToken, extraInfo) => ({
        type: types.PUSH_NOTIFICATIONS_REGISTER_DEVICE,
        idDevice,
        pushToken,
        extraInfo,
    }),
    listAllUserDevices: () => ({
        type: types.LIST_ALL_USER_DEVICES,
    }),
};

// Selectors
export const selectors = {
    getFetching: (state) => state.pushNotifications.fetching,
    getPushNotificationsConfiguredUserDevices: (state) =>
        state.pushNotifications.pushNotificationsConfiguredUserDevices,
    getDevice: (state) => state.pushNotifications.device,
};
