import Container from "pages/_components/Container";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, shape } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import Route from "react-router-dom/Route";
import withRouter from "react-router-dom/withRouter";

class BackofficeLayout extends Component {
    static propTypes = {
        component: shape({}).isRequired,
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        isMobileNative: bool.isRequired,
    };

    getDesktopLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };

        return (
            <>
                <div className="app theme-auth backoffice-layout">
                    <ErrorBoundary>
                        <Container className="container--layout flex-grow-1 items-center">
                            <Col className="col-12">
                                <ReceivedComponent {...matchProps} {...extras} />
                            </Col>
                        </Container>
                    </ErrorBoundary>
                </div>
            </>
        );
    };

    render() {
        const { component: ReceivedComponent, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(matchProps) => <React.Fragment>{this.getDesktopLayout(matchProps)}</React.Fragment>}
            />
        );
    }
}

export default withRouter(connect()(resizableRoute(BackofficeLayout)));
