import React from "react";
import { node, func, string } from "prop-types";
import classNames from "classnames";

import ListItem from "pages/_components/ListItem";

const ListContext = React.createContext({
    onSelect: () => {},
});

class List extends React.Component {
    static propTypes = {
        children: node.isRequired,
        onSelect: func,
        className: string,
    };

    static defaultProps = {
        className: null,
        onSelect: () => {},
    };

    static Item = ListItem;

    static Consumer = ListContext.Consumer;

    render() {
        const { children, onSelect, className } = this.props;
        return (
            <ListContext.Provider value={{ onSelect }}>
                <ul className={classNames("list", className)} role="listbox">
                    {children}
                </ul>
            </ListContext.Provider>
        );
    }
}

export function withList(Component) {
    const Wrapper = (props) => (
        <List.Consumer>{(listContext) => <Component list={listContext} {...props} />}</List.Consumer>
    );
    Wrapper.displayName = `withList${Component.displayName || Component.name}`;
    return Wrapper;
}

export default List;
