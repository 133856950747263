import React from "react";

export default function SpinnerCircle() {
    return (
        <>
            <span className="btn-loading-indicator-circle">
                <svg width="20" height="20" viewBox="-2 -2 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                </svg>
                {/* <ReactSVG id="svg-spinner-circle" src="../../../styles/images/spinner-circle.svg" wrapper="i" /> */}
            </span>
            <span className="btn-loading-text">Loading</span>
        </>
    );
}
