import { USD_CURRENCY, ITBMS } from "constants.js";
import { useEffect, useState } from "react";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { formatNumber } from "util/format";

const maximumDecimals = configUtils.getInteger("defaultDecimal.maximum") || 0;
const minimumDecimals = configUtils.getInteger("defaultDecimal.minimum") || 0;

const formatCost = (quantity) => {
    const quantityFormat = formatNumber(parseFloat(quantity).toFixed(2), maximumDecimals, minimumDecimals);
    const textCost = `${USD_CURRENCY} ${quantityFormat} + ${ITBMS}`;
    const freeCharge = i18n.get("operation.cost.free");
    return parseFloat(quantity) === 0 ? freeCharge : textCost;
};

export const useSelectorOptions = (preDataForm, isReportCard) => {
    const [reasonList, setReasonList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [jurisdictionList, setJurisdictionList] = useState([]);

    useEffect(() => {
        setReasonList(
            preDataForm.reasonList
                ?.filter((reason) => (isReportCard ? reason.id !== "RALLEX" : reason.id === "RALLEX"))
                .map((reason) => ({
                    id: reason.id,
                    label: i18n.get(`creditCard.reason.type.${reason.id}`),
                })),
        );
    }, [preDataForm.countryList, isReportCard]);
    useEffect(() => {
        setCountryList(
            preDataForm.countryList?.map((country) => ({
                id: country.id,
                label: country.name,
                alpha3: country.alpha3,
            })),
        );
    }, [preDataForm.countryList]);
    useEffect(() => {
        setProvinceList(preDataForm.provinceList);
    }, [preDataForm.provinceList]);
    useEffect(() => {
        setDistrictList(preDataForm.districtList);
    }, [preDataForm.districtList]);
    useEffect(() => {
        setJurisdictionList(
            preDataForm.jurisdictionList?.map((jurisdiction) => ({
                id: jurisdiction.idJurisdiction,
                idDistrict: jurisdiction.idDistrict,
                idProvince: jurisdiction.idProvince,
                label: jurisdiction.nameJurisdiction,
            })),
        );
    }, [preDataForm.jurisdictionList]);
    return [reasonList, countryList, provinceList, districtList, jurisdictionList];
};

export const useCostInfo = (isReportCard) => {
    const [costInfo, setCostInfo] = useState([]);
    useEffect(() => {
        const costInfoReplace = [
            {
                id: 1,
                title: i18n.get("forms.report.replace.typeCost.title"),
                items: [
                    {
                        id: 1,
                        title: `${i18n.get("creditCard.type.holder")} = `,
                        cost: formatCost(configUtils.get("creditCard.type.holder.cost")),
                    },
                    {
                        id: 2,
                        title: `${i18n.get("creditCard.type.metallic")} = `,
                        cost: formatCost(configUtils.get("creditCard.type.metallic.cost")),
                    },
                    {
                        id: 3,
                        title: `${i18n.get("creditCard.type.pedidosya")} = `,
                        cost: formatCost(configUtils.get("creditCard.type.pedidosya.cost")),
                    },
                    {
                        id: 4,
                        title: `${i18n.get("creditCard.type.stripe")} = `,
                        cost: formatCost(configUtils.get("creditCard.type.stripe.cost")),
                    },
                ],
            },
            {
                id: 2,
                title: i18n.get("forms.report.replace.deliveryCost.title"),
                items: [
                    {
                        id: 1,
                        title: `${i18n.get("country.name.PA")} = `,
                        cost: formatCost(configUtils.get("creditCard.delivery.Panama.cost")),
                    },
                    {
                        id: 2,
                        title: `${i18n.get("country.name.VE")} = `,
                        cost: formatCost(configUtils.get("creditCard.delivery.Vanezuela.cost")),
                    },
                    {
                        id: 3,
                        title: `${i18n.get("country.name.other")} = `,
                        cost: formatCost(configUtils.get("creditCard.delivery.restWorld.cost")),
                    },
                ],
            },
        ];
        const costInfoRenew = [
            {
                id: 1,
                title: i18n.get("forms.report.renew.deliveryCost.title"),
                items: [
                    {
                        id: 1,
                        title: `${i18n.get("country.name.PA")} = `,
                        cost: formatCost(configUtils.get("creditCard.delivery.Panama.cost")),
                    },
                    {
                        id: 2,
                        title: `${i18n.get("country.name.VE")} = `,
                        cost: formatCost(configUtils.get("creditCard.delivery.Vanezuela.cost")),
                    },
                    {
                        id: 3,
                        title: `${i18n.get("country.name.other")} = `,
                        cost: formatCost(configUtils.get("creditCard.delivery.restWorld.cost")),
                    },
                ],
            },
        ];
        setCostInfo(isReportCard ? costInfoReplace : costInfoRenew);
    }, [isReportCard]);
    return [costInfo];
};
