import classNames from "classnames";
import { bool, func, node, oneOf, string } from "prop-types";
import React, { useState, useEffect, useRef } from "react";

const Box = ({
    absoluteCentered,
    alignX,
    alignY,
    ariaHiden,
    ariaLabel,
    background,
    backgroundHover,
    border,
    borderRadius,
    bottom,
    children,
    className,
    column,
    columnReverse,
    component,
    display,
    fitHeight,
    fitWidth,
    flex1,
    fullHeight,
    fullWidth,
    gap,
    gapX,
    gapY,
    heightAuto,
    id,
    left,
    maxContentHeight,
    noList,
    noWrap,
    onBlur,
    onClick,
    onFocus,
    onKeyDown,
    opacityGradientScroll,
    overflow,
    pointer,
    position,
    right,
    role,
    rowReverse,
    scrollable,
    square,
    tabIndex,
    toBorderInMobile,
    top,
    withEllipsis,
    wrap,
    zIndex,
    ...props
}) => {
    const Component = component;
    const ref = useRef(null);
    const [withScroll, setWithScroll] = useState(false);
    const realAlignX = column ? alignY : alignX;
    const realAlignY = column ? alignX : alignY;

    const checkScroll = () => {
        const elemento = ref.current;
        if (elemento.scrollHeight > elemento.clientHeight) {
            if (elemento.scrollTop + elemento.clientHeight === elemento.scrollHeight) {
                setWithScroll(false);
            } else {
                setWithScroll(true);
            }
        } else {
            setWithScroll(false);
        }
    };

    useEffect(() => {
        checkScroll();

        const elemento = ref.current;
        window.addEventListener("resize", checkScroll);
        elemento.addEventListener("scroll", checkScroll);

        return () => {
            window.removeEventListener("resize", checkScroll);
            elemento.removeEventListener("scroll", checkScroll);
        };
    }, []);

    const handleClick = (e) => {
        if (typeof onClick === "function") {
            onClick(e);
        }
    };
    const handleFocus = (e) => {
        if (typeof onFocus === "function") {
            onFocus(e);
        }
    };

    const handleBlur = (e) => {
        if (typeof onBlur === "function") {
            onBlur(e);
        }
    };

    const handleKeyDown = (e) => {
        if (typeof onKeyDown === "function") {
            onKeyDown(e);
        }
    };

    return (
        <Component
            ref={ref}
            id={id}
            {...(onClick && { onClick: (e) => handleClick(e) })}
            {...(onBlur && { onBlur: (e) => handleBlur(e) })}
            {...(onFocus && { onFocus: (e) => handleFocus(e) })}
            {...(onKeyDown && { onKeyDown: (e) => handleKeyDown(e) })}
            {...(tabIndex && { tabIndex })}
            {...(ariaHiden && { "aria-hiden": ariaHiden })}
            {...(ariaLabel && { "aria-label": ariaLabel })}
            {...(role && { role })}
            {...((absoluteCentered ||
                realAlignX ||
                realAlignY ||
                background ||
                backgroundHover ||
                border ||
                borderRadius ||
                className ||
                column ||
                columnReverse ||
                display ||
                fitHeight ||
                fitWidth ||
                flex1 ||
                fullHeight ||
                fullWidth ||
                gap ||
                gapX ||
                gapY ||
                heightAuto ||
                left ||
                maxContentHeight ||
                overflow ||
                pointer ||
                position ||
                right ||
                rowReverse ||
                scrollable ||
                square ||
                toBorderInMobile ||
                top ||
                withEllipsis ||
                wrap ||
                zIndex) && {
                className: classNames(className, {
                    "absolute-centered": absoluteCentered,
                    "aspect-ratio-square": square,
                    "fit-content-width ": fitWidth,
                    "flex-column-reverse": columnReverse,
                    "flex-column": column,
                    "flex-no-wrap": noWrap,
                    "flex-row-reverse": rowReverse,
                    "flex-wrap": wrap,
                    "full-height": fullHeight,
                    "full-width": fullWidth,
                    "height-auto": heightAuto,
                    "height-fit-content": fitHeight,
                    "height-max-content": maxContentHeight,
                    "list-style-none": noList,
                    "opacity-on-scroll": withScroll && opacityGradientScroll,
                    "pointer-cursor": pointer,
                    "scrollable-content": scrollable,
                    "to-border-in-mobile": toBorderInMobile,
                    "with-ellipsis-container": withEllipsis,
                    [`align-items-${realAlignY}`]: realAlignY,
                    [`justify-content-${realAlignX}`]: realAlignX,
                    [`background-${background}`]: background,
                    [`background-hover-${backgroundHover}`]: backgroundHover,
                    [`border-${border}`]: border,
                    [`border-radius-${borderRadius}`]: borderRadius,
                    [`bottom-${bottom}`]: bottom,
                    [`display-${display}`]: display,
                    [`gap-${gap}`]: gap,
                    [`gapX-${gapX}`]: gapX,
                    [`gapY-${gapY}`]: gapY,
                    [`left-${left}`]: left && left,
                    [`overflow-${overflow}`]: overflow,
                    [`position-${position}`]: position,
                    [`right-${right}`]: right,
                    [`top-${top}`]: top,
                    [`z-index-${zIndex}`]: zIndex,
                    flex: flex1,
                }),
            })}
            {...props}>
            {children}
        </Component>
    );
};

Box.propTypes = {
    absoluteCentered: bool,
    alignX: string,
    alignY: string,
    ariaHiden: bool,
    ariaLabel: string,
    background: string,
    backgroundHover: string,
    border: string,
    borderRadius: string,
    bottom: string,
    children: node,
    className: string,
    column: bool,
    columnReverse: bool,
    component: oneOf([
        "article",
        "aside",
        "div",
        "footer",
        "header",
        "main",
        "nav",
        "section",
        "ul",
        "ol",
        "li",
        "table",
        "tr",
        "th",
        "tbody",
        "thead",
        "fieldset",
    ]),
    display: oneOf(["flex", "inline-flex", "grid", "block", "inline-block", "none"]),
    fitHeight: bool,
    fitWidth: bool,
    flex1: bool,
    fullHeight: bool,
    fullWidth: bool,
    gap: string,
    gapX: string,
    gapY: string,
    heightAuto: bool,
    id: string,
    left: string,
    maxContentHeight: bool,
    noList: bool,
    noWrap: bool,
    onBlur: func,
    onClick: func,
    onFocus: func,
    onKeyDown: func,
    opacityGradientScroll: bool,
    overflow: string,
    pointer: bool,
    position: string,
    right: string,
    role: string,
    rowReverse: bool,
    scrollable: bool,
    square: bool,
    tabIndex: string,
    toBorderInMobile: bool,
    top: string,
    withEllipsis: bool,
    wrap: bool,
    zIndex: string,
};

Box.defaultProps = {
    absoluteCentered: false,
    alignX: null,
    alignY: null,
    ariaHiden: null,
    ariaLabel: null,
    background: null,
    backgroundHover: null,
    border: null,
    borderRadius: null,
    bottom: null,
    children: null,
    className: "",
    column: false,
    columnReverse: false,
    component: "div",
    display: null,
    fitHeight: false,
    fitWidth: false,
    flex1: false,
    fullHeight: false,
    fullWidth: false,
    gap: null,
    gapX: undefined,
    gapY: undefined,
    heightAuto: false,
    id: null,
    left: null,
    maxContentHeight: null,
    noList: false,
    noWrap: false,
    onBlur: null,
    onClick: null,
    onFocus: null,
    onKeyDown: null,
    opacityGradientScroll: false,
    overflow: null,
    pointer: false,
    position: null,
    right: null,
    role: null,
    rowReverse: false,
    scrollable: false,
    square: false,
    tabIndex: null,
    toBorderInMobile: false,
    top: null,
    withEllipsis: false,
    wrap: false,
    zIndex: null,
};

export default Box;
