import React, { Component } from "react";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { func, shape } from "prop-types";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Col from "react-bootstrap/lib/Col";
import Button from "pages/_components/Button";
import Selector from "pages/_components/fields/formik/Selector";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import TextField from "pages/_components/fields/TextField";
import { TYPE_FILTER_USERS } from "constants.js";
import { getSignatureLevelsOptions } from "util/administration";

const FORM_ID = "transactions.list.filters.order";

class UsersFilter extends Component {
    static propTypes = {
        // fetching: bool,
        values: shape({}).isRequired,
        // handleSearch: func.isRequired,
        setFieldValue: func.isRequired,
    };

    static defaultProps = {
        // fetching: false,
    };

    constructor() {
        super();
        this.state = {};
    }

    renderFilters = () => {
        const { values } = this.props;
        const { typeFilter } = values;

        const typeFilterOptionsSelect = () => [
            {
                value: TYPE_FILTER_USERS.NAME,
                label: i18n.get("users.list.filters.types.name"),
            },
            {
                value: TYPE_FILTER_USERS.STATUS,
                label: i18n.get("users.list.filters.types.status"),
            },
            {
                value: TYPE_FILTER_USERS.DOCUMENT,
                label: i18n.get("users.list.filters.types.document"),
            },
            {
                value: TYPE_FILTER_USERS.SIGNATURE,
                label: i18n.get("users.list.filters.types.signature"),
            },
        ];

        const statusTypes = () => [
            {
                value: "active",
                label: i18n.get("global.active"),
            },
            {
                value: "blocked",
                label: i18n.get("global.blocked"),
            },
        ];

        const resetFields = () => {
            const { setFieldValue } = this.props;
            setFieldValue("nameFilter", "");
            setFieldValue("statusFilter", null);
            setFieldValue("documentFilter", "");
            setFieldValue("signatureFilter", null);
        };

        return (
            <Form>
                <Row>
                    <Col md={3}>
                        <Box className="form-group">
                            <Text
                                component="label"
                                htmlFor="react-select-operationType-input"
                                labelKey="accounts.movements.filters.searchBy"
                            />
                            <Field
                                noLabel
                                component={Selector}
                                options={typeFilterOptionsSelect()}
                                name="typeFilter"
                                placeholder=""
                                handleChange={() => resetFields()}
                            />
                        </Box>
                    </Col>
                    {typeFilter === TYPE_FILTER_USERS.NAME && (
                        <Col md={7}>
                            <Box display="flex" alignY="flex-end" fullHeight>
                                <Box className="form-group">
                                    <Field
                                        showLabel={false}
                                        component={TextField}
                                        name="nameFilter"
                                        placeholder={i18n.get("users.list.filters.types.name.placeholder")}
                                        serarchStyle
                                    />
                                </Box>
                            </Box>
                        </Col>
                    )}
                    {typeFilter === TYPE_FILTER_USERS.STATUS && (
                        <Col md={3}>
                            <Box className="form-group">
                                <Text
                                    component="label"
                                    htmlFor="react-select-operationType-input"
                                    labelKey="users.list.filters.types.status"
                                />
                                <Field
                                    noLabel
                                    component={Selector}
                                    options={statusTypes()}
                                    name="statusFilter"
                                    placeholder={i18n.get("administration.users.invite.groups.placeholder")}
                                />
                            </Box>
                        </Col>
                    )}

                    {typeFilter === TYPE_FILTER_USERS.DOCUMENT && (
                        <Col md={7}>
                            <Box display="flex" alignY="flex-end" fullHeight>
                                <Box className="form-group">
                                    <Field
                                        showLabel={false}
                                        component={TextField}
                                        name="documentFilter"
                                        placeholder={i18n.get("users.list.filters.types.document.placeholder")}
                                        serarchStyle
                                    />
                                </Box>
                            </Box>
                        </Col>
                    )}
                    {typeFilter === TYPE_FILTER_USERS.SIGNATURE && (
                        <Col md={3}>
                            <Box className="form-group">
                                <Text
                                    component="label"
                                    htmlFor="signatureFilter"
                                    labelKey="users.list.filters.types.signature"
                                />
                                <Field
                                    noLabel
                                    id="signatureFilter"
                                    component={Selector}
                                    options={getSignatureLevelsOptions()}
                                    label="users.list.filters.types.signature"
                                    name="signatureFilter"
                                    placeholder={i18n.get("administration.users.invite.groups.placeholder")}
                                />
                            </Box>
                        </Col>
                    )}
                    <Col md={2}>
                        <Box display="flex" alignY="flex-end" fullHeight>
                            <Button
                                label="users.list.filters.search.btn"
                                bsStyle="primary"
                                className="mt-auto full-width px-9"
                                // disabled={!isValidForm()}
                                type="submit"
                            />
                        </Box>
                    </Col>
                </Row>
            </Form>
        );
    };

    render() {
        // const { fetching, filterValues } = this.props;
        return <Box>{this.renderFilters()}</Box>;
    }
}

export default compose(
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            typeFilter: TYPE_FILTER_USERS.NAME,
            nameFilter: "",
            statusFilter: null,
            documentFilter: "",
            signatureFilter: null,
        }),
        validationSchema: () =>
            Yup.object().shape({
                typeFilter: Yup.string()
                    .nullable()
                    .required(i18n.get(`${FORM_ID}.searchBy.required`)),
            }),
        handleSubmit: ({ ...filters }, formikBag) => {
            const { handleSearch } = formikBag.props;
            let hasFilterApplied = false;
            if (filters.nameFilter || filters.statusFilter || filters.documentFilter || filters.signatureFilter) {
                hasFilterApplied = true;
            }
            handleSearch(filters, hasFilterApplied);
        },
    }),
)(UsersFilter);
