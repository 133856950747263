import classNames from "classnames";
import RadioButton from "pages/_components/fields/Radio";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Tooltip from "pages/_components/Tooltip";
import flowRight from "lodash/flowRight";
import formField from "pages/forms/_components/_fields/_commons/formField";
import withFocus from "pages/_components/withFocus";

class RadioButtonGroupCustom extends Component {
    static propTypes = {
        inLineControl: bool,
        labelKey: string,
        name: string.isRequired,
        onChange: func.isRequired,
        options: arrayOf(shape({})),
        value: string,
        valueKey: string,
        selectorId: string.isRequired,
        className: string,
        radioButtonGroupFlex: bool,
        radioLabelClassName: string,
        disabled: bool,
        tooltipText: string,
    };

    static defaultProps = {
        labelKey: "label",
        inLineControl: false,
        value: "",
        valueKey: "id",
        className: "",
        options: [],
        radioButtonGroupFlex: false,
        radioLabelClassName: null,
        disabled: false,
        tooltipText: null,
    };

    handleRadioChange = (event) => {
        const { onChange } = this.props;

        onChange(event.target.value);
    };

    render() {
        const {
            inLineControl,
            labelKey,
            name,
            options,
            selectorId,
            value,
            valueKey,
            className,
            radioButtonGroupFlex,
            radioLabelClassName,
            disabled,
            tooltipText,
        } = this.props;

        return (
            <div
                className={classNames("form-group-control-list", className, {
                    "form-group-control-list-flex": radioButtonGroupFlex,
                })}>
                {options.map((option) => (
                    <RadioButton
                        id={`${selectorId}_${option[valueKey]}`}
                        checked={option[valueKey] === value}
                        inLineControl={inLineControl}
                        key={`${selectorId}_${option[valueKey]}`}
                        label={option[labelKey]}
                        name={name}
                        onChange={this.handleRadioChange}
                        value={option[valueKey]}
                        radioLabelClassName={radioLabelClassName}
                        disabled={disabled}
                    />
                ))}
                {tooltipText && <Tooltip {...(radioButtonGroupFlex && { className: "mb-2" })} text={tooltipText} />}
            </div>
        );
    }
}

export default flowRight(withFocus, formField())(RadioButtonGroupCustom);
