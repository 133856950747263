import { push, replace, routerActions } from "react-router-redux";
import { actions as notificationActions } from "reducers/notification";
import { takeLatest, call, put, select } from "redux-saga/effects";
import * as softtokenApi from "middleware/softToken/softToken";
import {
    USER_TOKEN_STATUS_ACTIVE,
    USER_TOKEN_STATUS_AUTHENTICATE,
    USER_TOKEN_STATUS_INACTIVE,
    USER_TOKEN_STATUS_MIGRATE_ENTRUST,
    USER_TOKEN_STATUS_MIGRATE_LOCAL,
    USER_TOKEN_STATUS_PENDING,
} from "util/userToken.util";
import { softTokenTypes, selectors as softTokenSelectors, actions as softTokenActions } from "reducers/softToken";
import { actions as secondFactorActions, types as secondFactorTypes } from "reducers/secondFactor";

import { deleteIdentity, ENT000, registerIdentity } from "util/softToken.util";
import { selectors as sessionSelectors, actions as sessionActions } from "reducers/session";
import * as i18n from "util/i18n";
import { types as enrollmentTypes } from "reducers/enrollment";
import { crashLogData } from "util/crashReport/crashReport.util";
import * as secureStorageUtils from "util/secureStorage";

const ACTIVATE_MESSAGE_SEVERITY_MAP = new Map([
    [USER_TOKEN_STATUS_PENDING, { message: "token.entrust.saveToken.pending.message", severity: "warning" }],
]);

function* showGenericErrorSaveToken(redirectError, scopeError, messageError) {
    if (redirectError) {
        yield put(push(redirectError));
    }

    yield put(
        notificationActions.showNotification(
            i18n.get(messageError || "token.entrust.saveToken.error.message"),
            "error",
            scopeError || [],
            false,
        ),
    );
}

function* setSaveTokenSuccess(redirectSuccess, scopeSuccess, messageSuccess, severity, onSuccess) {
    yield put({
        type: softTokenTypes.SAVE_TOKEN_SUCCESS,
    });

    /* if (redirectSuccess) {
        yield put(push(redirectSuccess));
    } */

    if (onSuccess) {
        onSuccess();
    }

    yield put(
        notificationActions.showNotification(
            i18n.get(messageSuccess || "token.entrust.saveToken.success.message"),
            severity || "success",
            scopeSuccess || [],
        ),
    );
}

function* saveToken({ saveRequest }) {
    const {
        redirectSuccess,
        redirectError,
        scopeSuccess,
        scopeError,
        enableActivate = true,
        exchangeToken,
        credentials,
        secondFactor,
        onSuccess,
        formikBag,
    } = saveRequest;

    const deviceUUID = window?.app?.getDeviceUUID() || "";
    const deviceModel = window?.device?.model || "";
    const deviceBrand = window?.device?.manufacturer || "";

    if (!deviceUUID || deviceUUID === "") {
        yield call(showGenericErrorSaveToken, redirectError, scopeError);
        yield call(putSaveTokenFailure);
        yield;
        return;
    }
    const isLoggedIn = yield select(sessionSelectors.isLoggedIn);
    const pinCode = yield select(softTokenSelectors.getTokenPin);

    console.log("SaveToken Call +pinCode: ", pinCode);
    const saveTokenResponse = yield call(
        isLoggedIn ? softtokenApi.saveUserToken : softtokenApi.saveUserTokenPublic,
        deviceUUID,
        credentials,
        deviceModel,
        deviceBrand,
        pinCode,
        secondFactor,
        exchangeToken,
    );
    if (!saveTokenResponse) {
        yield call(showGenericErrorSaveToken, redirectError, scopeError);
        yield call(putSaveTokenFailure);
        yield;
        return;
    }
    console.log("SaveToken response! ", saveTokenResponse);

    const { type, data } = saveTokenResponse;
    console.log("SaveToken response type: ! ", type);
    if (!type || !data) {
        yield call(showGenericErrorSaveToken, redirectError, scopeError);
        yield call(putSaveTokenFailure);
        yield;
        return;
    }

    /**
     * validate code error
     */

    if (type === "W") {
        // TODO: validate response codes
        console.log("SaveToken response error: ! ", data?.code, data?.data?.secondFactor, formikBag, saveRequest);
        yield call(putSaveTokenFailure);

        let showNotification = true;
        if (data?.code === "COR020W" && data?.data?.secondFactor) {
            showNotification = false;
            formikBag.setErrors(data?.data);
            // formikBag.setErrors(adjustIdFieldErrors(data?.data));
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            formikBag.setSubmitting(false);
            return;
        }
        if (data?.code === "API707W") {
            yield put(sessionActions.logoutUserBlocked());
            return;
        }

        if (data?.code === "API708W") {
            yield put(
                notificationActions.showNotification(
                    i18n.get(
                        "secondFactor.credential.otp.expired",
                        "Código OTP expirado, solicite un nuevo código OTP",
                    ),
                    "warning",
                    ["tokenActivation"],
                ),
            );
            return;
        }

        if (showNotification) {
            const errorResponseMessage = yield call(getCodeErrorSaveToken, data?.code);
            yield put(
                notificationActions.showNotification(data.message ||
                    i18n.get(errorResponseMessage || "token.entrust.saveToken.error.message"),
                    "error",
                    scopeError || [],
                    false,
                ),
            );
            return;
        }
    }

    console.log("SaveToken response data: ! ", data);
    const { serialNumber, activateCode, message } = data.data || { serialNumber: undefined, activateCode: undefined };
    if (!serialNumber || !activateCode) {
        if (message) {
            console.log("Bad request message: ! ", message);
            yield put(
                notificationActions.showNotification(
                    message,
                    "error",
                    scopeError,
                    false,
                ),
            );
        }else{
            yield call(showGenericErrorSaveToken, redirectError, scopeError);
        }
        yield call(putSaveTokenFailure);
        yield;
        return;
    }

    if (!enableActivate || enableActivate !== true) {
        yield call(setSaveTokenSuccess, redirectSuccess, scopeSuccess);
        yield;
        return;
    }

    if (pinCode) {
        yield put(softTokenActions.setDevicePin(pinCode));
        yield put(softTokenActions.setSecurityMethod("PIN"));
    } else {
        yield put(softTokenActions.setSecurityMethod("FINGERPRINT"));
    }

    yield call(generateSoftTokenLocal, {
        activateRequest: {
            serialNumber,
            activateCode,
            deviceUuid: deviceUUID,
            redirectSuccess,
            redirectError,
            scopeSuccess,
            scopeError,
            isLoggedIn,
            exchangeToken,
            onSuccess,
            formikBag,
        },
    });
}

const RETURN_CODE_ERROR_ENTRUST_MAP = new Map([
    ["API571W", "entrust.save.token.authenticate.otp.invalid"],
    ["API572W", "entrust.save.token.authenticate.otp.expire"],
    ["API573W", "entrust.save.token.authenticate.otp.used"],
]);

function* getCodeErrorSaveToken(code) {
    if (!code) {
        yield;
        return undefined;
    }

    yield;
    return RETURN_CODE_ERROR_ENTRUST_MAP.get(code);
}

function* generateSoftTokenLocal({ activateRequest }) {
    try {
        const {
            serialNumber,
            activateCode,
            deviceUuid,
            redirectSuccess,
            redirectError,
            scopeSuccess,
            scopeError,
            isLoggedIn,
            exchangeToken,
            onSuccess,
            formikBag,
        } = activateRequest;

        let loggedUser = yield select((state) => sessionSelectors.getUser(state));
        loggedUser = loggedUser || { user: "Undefined" };

        console.log("sagas.softToken.generateSoftTokenLocal.registerIdentity: ", serialNumber, activateCode);
        const responseGenerateIdentity = yield call(registerIdentity, serialNumber, activateCode);
        crashLogData({
            title: "respSDK",
            user: loggedUser?.idUser || "",
            deviceUuid,
            responseSDK: responseGenerateIdentity,
        });

        console.log(
            "sagas.softToken.generateSoftTokenLocal.registerIdentity responseGenerateIdentity: ",
            responseGenerateIdentity,
        );
        if (!responseGenerateIdentity) {
            yield call(showGenericErrorSaveToken, redirectError, scopeError);
            yield call(putActivateTokenFailure);

            yield call(softtokenApi.resetActivateToken, deviceUuid);
            yield;
            return;
        }

        const { code, data } = JSON.parse(responseGenerateIdentity);
        console.log("sagas.softToken.generateSoftTokenLocal.registerIdentity code: ", code, data);

        if (!code || !data || code !== ENT000) {
            console.log("reset registerIdentity: ");
            yield call(resetIdentityGenerated);
            yield call(showGenericErrorSaveToken, redirectError, scopeError);
            yield call(putActivateTokenFailure);
            yield call(softtokenApi.resetActivateToken, deviceUuid);

            try {
                const pinCode = yield call(secureStorageUtils.get, "softTokenPinCode");
                if (pinCode) {
                    console.log("ResetActivateToken +pinCode: ", pinCode);
                    yield put(softTokenActions.removeDevicePin());
                }
            } catch (error) {
                /* do something with the error */
                console.log("Ha ocurrido un error en el resetActivateToken pin: ", error);
            }

            /* try {
                const securityMethod = yield call(secureStorageUtils.get, "softTokenSecurityMethod");
                if(securityMethod && securityMethod === "FINGERPRINT"){
                    console.log("ResetActivateToken +fingerprint: ", securityMethod);
                    yield put(fingerprintActions.fingerprintDeleteAllDevices());
                }
            } catch (error) {
                console.log("Ha ocurrido un error en el resetActivateToken biometria: ", error);
            } */

            try {
                console.log("ResetActivateToken +removeSecurityMethod: ");
                yield put(softTokenActions.removeSecurityMethod());
            } catch (error) {
                /* do something with the error */
                console.log("Ha ocurrido un error en el resetActivateToken del securityMethod: ", error);
            }

            yield;
            return;
        }

        const { registrationCode } = JSON.parse(data);
        if (!registrationCode || registrationCode === "") {
            yield call(showGenericErrorSaveToken, redirectError, scopeError);
            yield call(putActivateTokenFailure);
            yield call(softtokenApi.resetActivateToken, deviceUuid);
            yield;
            return;
        }

        /**
         * Activate user token in entrust
         */

        crashLogData({ title: "respSDK", user: loggedUser?.idUser || "", deviceUuid, step: "pre request activate" });
        console.log("softtokenApi.activateUserToken: ");
        const responseActivateToken = yield call(
            isLoggedIn ? softtokenApi.activateUserToken : softtokenApi.activateUserTokenPublic,
            deviceUuid,
            registrationCode,
            exchangeToken,
        );

        crashLogData({
            title: "respSDK2",
            user: loggedUser?.idUser || "",
            deviceUuid,
            responseActivate: responseActivateToken,
        });

        console.log("softtokenApi.activateUserToken: responseActivateToken: ", responseActivateToken);
        if (!responseActivateToken) {
            console.log("!responseActivateToken resetIdentityGenerated: ", responseActivateToken);
            yield call(resetIdentityGenerated);
            yield call(putActivateTokenFailure);
            yield call(showGenericErrorSaveToken, redirectError, scopeError);
            yield call(softtokenApi.resetActivateToken, deviceUuid);
            yield;
            return;
        }

        const { data: dataActivate, type } = responseActivateToken;
        if (!type) {
            yield call(resetIdentityGenerated);
            yield call(putActivateTokenFailure);
            yield call(showGenericErrorSaveToken, redirectError, scopeError);
            yield call(softtokenApi.resetActivateToken, deviceUuid);
            yield;
            return;
        }

        if (type === "W") {
            yield call(resetIdentityGenerated);
            yield call(putActivateTokenFailure);
            formikBag.setSubmitting(false);
            console.log("Mensaje: ", dataActivate?.data?.message);
            yield put(
                notificationActions.showNotification(dataActivate?.data?.message ||
                    i18n.get("token.entrust.saveToken.error.message"),
                    "error",
                    scopeError || [],
                    false,
                ),
            );
            yield call(softtokenApi.resetActivateToken, deviceUuid);
            yield;
            return;
        }

        const messageSeverityActivate = ACTIVATE_MESSAGE_SEVERITY_MAP.get(dataActivate?.data?.tokenStatus || "");

        yield call(
            setSaveTokenSuccess,
            redirectSuccess,
            scopeSuccess,
            messageSeverityActivate?.message,
            messageSeverityActivate?.severity,
            onSuccess,
        );

        try {
            // yield put(secondFactorActions.secondFactorStatusTokenRequest({ exchangeToken: undefined }));
            yield put(secondFactorActions.secondFactorStatusTokenSuccess(true));
            yield put(softTokenActions.getStatusTokenSuccess(USER_TOKEN_STATUS_ACTIVE));
        } catch (error) {
            /* Posibles errores al actualizar el status del softToken activo */
        }
    } catch (e) {
        crashLogData({ title: "SDK error", e });
    }
}

function* resetIdentityGenerated() {
    yield call(deleteIdentity);
}

function* putSaveTokenFailure() {
    yield put({
        type: softTokenTypes.SAVE_TOKEN_FAILURE,
    });
}

function* putActivateTokenFailure() {
    yield put({
        type: softTokenTypes.ACTIVATE_TOKEN_FAILURE,
    });
}

function* showGenericErrorValidateTokenStatus() {
    yield put(
        notificationActions.showNotification(
            i18n.get("token.entrust.validateStatus.error.message"),
            "error",
            ["desktop", "menu"],
            false,
        ),
    );
    yield put(replace("/desktop"));
}

function* validateTokenStatus({ exchangeToken }) {
    const deviceUUID = window?.app?.getDeviceUUID();
    if (!deviceUUID || deviceUUID === "") {
        yield call(showGenericErrorValidateTokenStatus);
        yield;
        return;
    }
    const validateStatusTokenResponse = yield call(softtokenApi.validateStatusToken, deviceUUID, exchangeToken);
    if (!validateStatusTokenResponse) {
        yield call(showGenericErrorValidateTokenStatus);
        yield;
        return;
    }
    const { data, type } = validateStatusTokenResponse;
    if (!type || !data) {
        yield call(showGenericErrorValidateTokenStatus);
        yield;
        return;
    }

    /**
     * validate code error
     */

    if (type === "W") {
        // TODO: validate response codes

        yield call(showGenericErrorValidateTokenStatus);
        yield;
        return;
    }

    const { tokenStatus } = data.data || { tokenStatus: undefined };
    if (!tokenStatus) {
        yield call(showGenericErrorValidateTokenStatus);
        yield;
        return;
    }
    if (tokenStatus === USER_TOKEN_STATUS_INACTIVE) {
        yield put(replace("/auth/tokenActivationStep1"));
        yield;
        return;
    }
    if (tokenStatus === USER_TOKEN_STATUS_AUTHENTICATE) {
        yield put(replace("/authenticateSofttoken"));
        yield;
        return;
    }
    if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_LOCAL) {
        yield put(replace("/migrateSoftTokenLocal"));
        yield;
        return;
    }
    if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_ENTRUST) {
        yield put(push("/migrateSoftTokenEntrust"));
        yield;
        return;
    }
    yield call(showGenericErrorValidateTokenStatus);
    yield;
}

function* getStatusToken({ preview }) {
    const { exchangeToken } = preview;
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.validateStatusToken, deviceUuid, exchangeToken);
    if (!response?.type || !response?.data?.data) {
        yield put({
            type: softTokenTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield;
        return;
    }
    const { type, data } = response;
    if (type === "W") {
        yield put({
            type: softTokenTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield;
        return;
    }
    const { tokenStatus } = data?.data;
    if (!tokenStatus) {
        yield put({
            type: softTokenTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield;
        return;
    }
    yield put(softTokenActions.getStatusTokenSuccess(tokenStatus));
}

function* migrateEntrustTokenFailure() {
    yield put({
        type: softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_FAILURE,
    });

    yield put(
        notificationActions.showNotification(
            i18n.get("token.entrust.migrateEntrust.validate.error.message"),
            "error",
            ["tokenActivation"],
            false,
        ),
    );
}

function* redirectBiometricValidation(callBackSelfie) {
    yield put({ type: enrollmentTypes.SET_CALLBACK_DATA_SELFIE, callBackSelfie });
    yield put(routerActions.replace("/auth/tokenActivationStep1"));
    yield put(
        notificationActions.showNotification(
            i18n.get("token.entrust.migrateEntrust.validate.error.message"),
            "error",
            ["tokenActivation"],
            false,
        ),
    );
}

function* migrateEntrustTokenPre({ validateData }) {
    const { callBackSelfie } = validateData;
    const deviceUuid = window?.app?.getDeviceUUID();
    if (!deviceUuid || deviceUuid === "") {
        yield call(migrateEntrustTokenFailure);
        yield;
        return;
    }

    const response = yield call(softtokenApi.migrateEntrustTokenPre, deviceUuid);
    if (!response?.type || !response?.data?.data) {
        yield call(migrateEntrustTokenFailure);
        yield;
        return;
    }

    const {
        type,
        data: { data: dataResponse },
    } = response;
    if (type === "W") {
        yield call(migrateEntrustTokenFailure);
        yield;
        return;
    }

    const { isValidDeviceUuid } = dataResponse;
    if (isValidDeviceUuid === undefined || isValidDeviceUuid !== true) {
        redirectBiometricValidation(callBackSelfie);
        yield;
        return;
    }

    yield put({
        type: softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_SUCCESS,
    });
}

function* setResultResendOtpAuthenticate(type, message, scope) {
    yield put({
        type,
    });
    yield put(notificationActions.showNotification(i18n.get(message), scope, ["authenticateSofttoken", "menu"], false));
}

function* resendOtpAuthenticate() {
    const response = yield call(softtokenApi.resendOtpAuthenticate);
    if (!response?.type || response.type === "W") {
        yield call(
            setResultResendOtpAuthenticate,
            softTokenTypes.RESEND_OTP_AUTHENTICATE_FAILURE,
            "token.entrust.resendOtpAuthenticate.error.message",
            "error",
        );
        yield;
        return;
    }
    yield call(
        setResultResendOtpAuthenticate,
        softTokenTypes.RESEND_OTP_AUTHENTICATE_SUCCESS,
        "token.entrust.resendOtpAuthenticate.success.message",
        "success",
    );
}

function* getRateValue() {
    const response = yield call(softtokenApi.getRateValue);
    if (response.type === "W") {
        yield put({
            type: softTokenTypes.GET_RATE_VALUE_FAILURE,
        });
        yield put(
            notificationActions.showNotification(
                i18n.get("token.entrust.activate.fee.timeout.error.message"),
                "error",
                ["/activateSoftToken"],
                false,
            ),
        );
    } else {
        const { amountActivateRate, currencyActivateRate } = response.data.data;
        yield put({
            type: softTokenTypes.GET_RATE_VALUE_SUCCESS,
            amountActivateRate,
            currencyActivateRate,
        });
    }
}

function* cleanUpDevicePin() {
    try {
        yield call(secureStorageUtils.remove, "softTokenPinCode");
    } catch (error) {
        /* do something with the error */
    }
}

function* removeSecurityMethod() {
    try {
        yield call(secureStorageUtils.remove, "softTokenSecurityMethod");
    } catch (error) {
        /* do something with the error */
    }
}

function* getDevicePin() {
    try {
        const pinCode = yield call(secureStorageUtils.get, "softTokenPinCode");
        yield put({
            type: softTokenTypes.GET_DEVICE_PIN_SUCCESS,
            pinCode,
        });
    } catch (error) {
        /* do something with the error */
    }
}

function* getSecurityMethod() {
    try {
        const securityMethod = yield call(secureStorageUtils.get, "softTokenSecurityMethod");
        yield put({
            type: softTokenTypes.GET_SECURITY_METHOD_SUCCESS,
            securityMethod,
        });
    } catch (error) {
        /* do something with the error */
    }
}

function* setDevicePin({ pinCode }) {
    try {
        const result = yield call(secureStorageUtils.set, "softTokenPinCode", pinCode);
        if (!result) {
            yield;
        }
    } catch (error) {
        /* do something with the error */
    }
}

function* setSecurityMethod({ securityMethod }) {
    try {
        const result = yield call(secureStorageUtils.set, "softTokenSecurityMethod", securityMethod);
        if (!result) {
            yield;
        }
    } catch (error) {
        /* do something with the error */
    }
}

const sagas = [
    takeLatest(softTokenTypes.VALIDATE_TOKEN_STATUS_REQUEST, validateTokenStatus),
    takeLatest(softTokenTypes.SAVE_TOKEN_REQUEST, saveToken),
    takeLatest(softTokenTypes.ACTIVATE_TOKEN_REQUEST, generateSoftTokenLocal),
    takeLatest(softTokenTypes.GET_STATUS_TOKEN_REQUEST, getStatusToken),
    takeLatest(softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_REQUEST, migrateEntrustTokenPre),
    takeLatest(softTokenTypes.RESEND_OTP_AUTHENTICATE_REQUEST, resendOtpAuthenticate),
    takeLatest(softTokenTypes.GET_RATE_VALUE_REQUEST, getRateValue),
    takeLatest(softTokenTypes.SET_DEVICE_PIN, setDevicePin),
    takeLatest(softTokenTypes.SET_SECURITY_METHOD, setSecurityMethod),
    takeLatest(softTokenTypes.REMOVE_DEVICE_PIN, cleanUpDevicePin),
    takeLatest(softTokenTypes.REMOVE_SECURITY_METHOD, removeSecurityMethod),
    takeLatest(softTokenTypes.GET_DEVICE_PIN, getDevicePin),
    takeLatest(softTokenTypes.GET_SECURITY_METHOD, getSecurityMethod),
];
export default sagas;
