import * as i18n from "util/i18n";

export const getCustomTitleTicket = (transactionData ) => {
    const { idActivity, data } = transactionData;
    switch (idActivity) {
        case "administration.users.blockunblock.send": {
            const { newStatus } = data;
            const actionUser = newStatus === "blocked" ? "block" : "unblock";
            return i18n.get(`administration.users.action.title.${actionUser}`);
        }
        default:
            return "";
    }
};
