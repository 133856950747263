import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { createReducer, makeActionCreator } from "util/redux";
import globalTypes from "reducers/types/global";
import { types as productsTypes } from "reducers/products";
import moment from "moment";

const getDetailIdx = (id, state) => state.details.findIndex((e) => e.idProduct === id);

const getListIdx = (id, state) => state.list.findIndex((e) => e.idProduct === id);

export const types = {
    DETAILS_RESET: "CREDIT_CARD/DETAILS_RESET",
    DETAILS_STOP_FETCHING: "CREDIT_CARD/DETAILS_STOP_FETCHING",
    DETAILS_REQUEST: "creditCard/DETAILS_REQUEST",
    DETAILS_FAILURE: "creditCard/DETAILS_FAILURE",
    DETAILS_SUCCESS: "creditCard/DETAILS_SUCCESS",

    READ_REQUEST: "creditCard/READ_REQUEST",
    READ_SUCCESS: "creditCard/READ_SUCCESS",
    READ_FAILURE: "creditCard/READ_FAILURE",

    DETAILS_NO_MOVEMENTS_REQUEST: "creditCard/DETAILS_NO_MOVEMENTS_REQUEST",

    STATUS_REQUEST: "creditCard/STATUS_REQUEST",
    STATUS_FAILURE: "creditCard/STATUS_FAILURE",
    STATUS_SUCCESS: "creditCard/STATUS_SUCCESS",
    DOWNLOAD_MOVEMENTS_REQUEST: "creditCard/DOWNLOAD_MOVEMENTS_REQUEST",
    DOWNLOAD_MOVEMENTS_SUCCESS: "creditCard/DOWNLOAD_MOVEMENTS_SUCCESS",
    DOWNLOAD_MOVEMENTS_FAILURE: "creditCard/DOWNLOAD_MOVEMENTS_FAILURE",
    DOWNLOAD_DEFERRED_REQUEST: "creditCard/DOWNLOAD_DEFERRED_REQUEST",
    DOWNLOAD_DEFERRED_SUCCESS: "creditCard/DOWNLOAD_DEFERRED_SUCCESS",
    DOWNLOAD_DEFERRED_FAILURE: "creditCard/DOWNLOAD_DEFERRED_FAILURE",
    DOWNLOAD_ADDITIONALS_REQUEST: "creditCard/DOWNLOAD_ADDITIONALS_REQUEST",
    DOWNLOAD_ADDITIONALS_SUCCESS: "creditCard/DOWNLOAD_ADDITIONALS_SUCCESS",
    DOWNLOAD_ADDITIONALS_FAILURE: "creditCard/DOWNLOAD_ADDITIONALS_FAILURE",
    MOVEMENTS_REQUEST: "creditCardMovements/MOVEMENTS_REQUEST",
    MOVEMENTS_FAILURE: "creditCardMovements/MOVEMENTS_FAILURE",
    MOVEMENTS_SUCCESS: "creditCardMovements/MOVEMENTS_SUCCESS",
    SHOW_OPTIONS: "creditCardOptions/SHOW",
    HIDE_OPTIONS: "creditCardOptions/HIDE",
    FETCH_MORE_MOVEMENTS_REQUEST: "creditCardMovements/FETCH_MORE_MOVEMENTS_REQUEST",
    NO_FETCH_MORE_MOVEMENTS_REQUEST: "creditCardMovements/NO_FETCH_MORE_MOVEMENTS_REQUEST",
    FETCH_MORE_MOVEMENTS_FAILURE: "creditCardMovements/FETCH_MORE_MOVEMENTS_FAILURE",
    FETCH_MORE_MOVEMENTS_SUCCESS: "creditCardMovements/FETCH_MORE_MOVEMENTS_SUCCESS",
    DETAILS_EVERY_REQUEST: "creditCard/DETAILS_EVERY_REQUEST",
    DETAILS_EVERY_FAILURE: "creditCard/DETAILS_EVERY_FAILURE",
    DETAILS_EVERY_SUCCESS: "creditCard/DETAILS_EVERY_SUCCESS",
    FINANCING_PLANS_REQUEST: "creditCard/FINANCING_PLANS_REQUEST",
    FINANCING_PLANS_SUCCESS: "creditCard/FINANCING_PLANS_SUCCESS",
    MOVEMENTS_TRANSIT_REQUEST: "creditCardMovements/MOVEMENTS_TRANSIT_REQUEST",
    MOVEMENTS_TRANSIT_FAILURE: "creditCardMovements/MOVEMENTS_TRANSIT_FAILURE",
    MOVEMENTS_TRANSIT_SUCCESS: "creditCardMovements/MOVEMENTS_TRANSIT_SUCCESS",
    DOWNLOAD_MOVEMENTS_TRANSIT_REQUEST: "creditCardMovements/DOWNLOAD_MOVEMENTS_TRANSIT_REQUEST",
    DOWNLOAD_MOVEMENTS_TRANSIT_FAILURE: "creditCardMovements/DOWNLOAD_MOVEMENTS_TRANSIT_FAILURE",
    DOWNLOAD_MOVEMENTS_TRANSIT_SUCCESS: "creditCardMovements/DOWNLOAD_MOVEMENTS_TRANSIT_SUCCESS",
    SET_EXTRA_DATA_DETAIL_CREDIT_CARD: "creditCard/SET_EXTRA_DATA_DETAIL_CREDIT_CARD",
    CLEAR_EXTRA_DATA_DETAIL_CREDIT_CARD: "creditCard/CLEAR_EXTRA_DATA_DETAIL_CREDIT_CARD",
    DETAILS_SUCCESS_MOVEMENTS: "creditCard/DETAILS_SUCCESS_MOVEMENTS",
    ASSIGN_PIN_CREDIT_CARD: "creditCard/ASSIGN_PIN_CREDIT_CARD",
    LIST_ADDITIONAL_REQUEST: "creditCard/LIST_ADDITIONAL_REQUEST",
    LIST_ADDITIONAL_FAILURE: "creditCard/LIST_ADDITIONAL_FAILURE",
    LIST_ADDITIONAL_SUCCESS: "creditCard/LIST_ADDITIONAL_SUCCESS",

    // from creditCards
    LIST_REQUEST: "creditCards/LIST_REQUEST",
    LIST_FAILURE: "creditCards/LIST_FAILURE",
    LIST_SUCCESS: "creditCards/LIST_SUCCESS",
    LIST_FETCHING: "creditCards/LIST_FETCHING",
    LIST_FETCHING_CARDS: "creditCards/LIST_FETCHING_CARDS",

    INVALIDATE_CACHE: "creditCards/INVALIDATE_CACHE",

    CREDITCARD_DETAILS_STATEMENTS_SET_PAFINATED_INFO: "creditCard/CREDITCARD_DETAILS_STATEMENTS_SET_PAFINATED_INFO",

    OPEN_TC_STATUS_SAT_SERVICES_REQUEST: "creditCards/OPEN_TC_STATUS_SAT_SERVICES_REQUEST",
    OPEN_TC_STATUS_SERVICES_SUCCESS: "creditCards/OPEN_TC_STATUS_SERVICES_SUCCESS",
    OPEN_TC_STATUS_SERVICES_FAILURE: "creditCards/OPEN_TC_STATUS_SERVICES_FAILURE",
    CREDITCARD_DETAILS_STATEMENTS_CLEAR_FILTERS: "creditCard/CREDITCARD_DETAILS_STATEMENTS_CLEAR_FILTERS",
};

export const INITIAL_STATE = {
    isFetching: false,
    isFetchingMovements: false,
    detail: null,
    movements: [],
    pageNumber: 0,
    hasMoreMovements: false,
    isOptionsVisible: false,
    from: null,
    filters: {},
    totalCount: 0,
    totalCurrentPeriod: 0,
    totalFavorites: 0,
    offset: 0,
    quantity: 0,
    movementTransitList: undefined,
    extraDataDetail: undefined,
    detailPayment: undefined,

    details: [],

    // from creditCards
    fetching: false,
    fetchingCards: false,
    list: [],

    lastCacheUpdate: null,
    listCacheValid: false,
    detailsCacheValid: false,
    financingPlans: [],
    isFetchingPoints: true,
    closingDate: "",
    expirationDate: "",
    minimumPayment: "",
    account: "",
    isAditional: null,
    nameOnCard: "",
    initialBalance: 0,
    finalBalance: 0,
    assignedQuota: 0,
    availableQuota: 0,
    usedQuota: 0,
    totalDebt: 0,
    expiredDebt: 0,
    currentPage: undefined,
    paginatedDataMap: undefined,
    itemsMap: undefined,
    // filters,
    hasFiltersApplied: undefined,
    isFetchingAdditionals: false,

    additionalCreditCards: [],
    isfetchingToken: false,
    creditCardDetail: undefined,
};

const reducer = createReducer(INITIAL_STATE, {
    [globalTypes.CLEAN_UP]: () => ({
        ...INITIAL_STATE,
    }),
    [types.DETAILS_RESET]: () => ({
        ...INITIAL_STATE,
        isFetching: false,
    }),
    [types.DETAILS_STOP_FETCHING]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.DETAILS_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        isFetchingMovements: true,
        filters: action.filters,
        pageNumber: 1,
        offset: 0,
        quantity: 0,
        movements: [],
        detail: null,
        details: state.detailsCacheValid ? state.details : [],
        detailsCacheValid: true,
        isFetchingPoints: true,
    }),
    [types.DETAILS_NO_MOVEMENTS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        detail: null,
        details: state.detailsCacheValid ? state.details : [],
        detailsCacheValid: true,
    }),
    [types.DETAILS_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
        detail: null,
    }),
    [types.DETAILS_SUCCESS]: (state, action) => {
        const id = action.detail.idProduct;

        const listIdx = getListIdx(id, state);

        const newList = [...state.list];
        if (listIdx !== -1) {
            newList[listIdx] = {
                ...newList[listIdx],
                isFetchingPoints: false,
                totalPoints: action.detail?.totalPoints,
                cashBackAmount: action.detail?.cashBackAmount,
                cashBackAmountCurrency: action.detail?.cashBackAmountCurrency,
                hasErrorLoadingPoints: action.detail?.hasErrorLoadingPoints,
                isIssuerAvaibleForPoints: action.detail?.isIssuerAvaibleForPoints,
                isIssuerAvaibleForCashback: action.detail?.isIssuerAvaibleForCashback,
            };
        }

        const detailIdx = getDetailIdx(id, state);
        let newDetailsList = [...state.details];
        if (detailIdx === -1) {
            newDetailsList = [...newDetailsList, { idProduct: id, ...action.detail }];
        } else {
            newDetailsList[detailIdx] = {
                ...state.details[detailIdx],
                ...action.detail,
            };
        }

        return {
            ...state,
            details: newDetailsList,
            list: newList,
            isFetching: false,
            detail: {
                idForm: action.idForm,
                ...action.detail,
            },
            totalFavorites: action.totalFavorites,
            detailsCacheValid: true,
        };
    },
    [types.DETAILS_SUCCESS_MOVEMENTS]: (state, action) => ({
        ...state,
        isFetching: false,
        isFetchingMovements: false,
        movements: action.movements,
        hasMoreMovements: action.hasMoreMovements,
        totalCount: action.totalCount,
        totalCurrentPeriod: action.totalCurrentPeriod,
        offset: action.offset,
    }),
    [types.MOVEMENTS_REQUEST]: (state, action) => ({
        ...state,
        isFetchingMovements: true,
        filters: action.filters,
        pageNumber: 1,
        offset: 0,
        quantity: 0,
        movements: [],
    }),
    [types.FETCH_MORE_MOVEMENTS_REQUEST]: (state) => ({
        ...state,
        isFetchingMovements: true,
    }),
    [types.NO_FETCH_MORE_MOVEMENTS_REQUEST]: (state, action) => ({
        ...state,
        fetchingMovements: false,
        hasMoreMovements: action.hasMoreMovements,
        pageNumber: action.pageNumber,
        offset: action.offset,
        quantity: action.quantity,
    }),
    [types.MOVEMENTS_FAILURE]: (state) => ({ ...state, isFetchingMovements: false }),
    [types.MOVEMENTS_SUCCESS]: (state, action) => ({
        ...state,
        isFetchingMovements: false,
        movements: action.movements,
        hasMoreMovements: action.hasMoreMovements,
        totalCount: action.totalCount,
        totalCurrentPeriod: action.totalCurrentPeriod,
    }),
    [types.FETCH_MORE_MOVEMENTS_FAILURE]: (state) => ({ ...state, isFetchingMovements: false }),
    [types.FETCH_MORE_MOVEMENTS_SUCCESS]: (state, action) => ({
        ...state,
        isFetchingMovements: false,
        movements: state.movements ? [...state.movements, ...action.movements] : action.movements,
        hasMoreMovements: action.hasMoreMovements,
        pageNumber: state.pageNumber + 1,
        totalCount: action.totalCount,
        totalCurrentPeriod: action.totalCurrentPeriod,
        offset: action.offset,
        quantity: action.quantity,
    }),
    [types.SHOW_OPTIONS]: (state) => ({
        ...state,
        isOptionsVisible: true,
    }),
    [types.HIDE_OPTIONS]: (state) => ({
        ...state,
        isOptionsVisible: false,
    }),
    [productsTypes.CHANGE_PRODUCT_ALIAS_SUCCESS]: (state, action) => {
        const detailIdx = getDetailIdx(action.idProduct, state);

        const newDetailsList = [...state.details];
        newDetailsList[detailIdx] = {
            ...state.details[detailIdx],
            productAlias: action.alias,
        };

        return {
            ...state,
            details: newDetailsList,
        };
    },
    [types.DOWNLOAD_MOVEMENTS_REQUEST]: (state) => ({
        ...state,
        fetchingDownload: true,
    }),
    [types.DOWNLOAD_MOVEMENTS_FAILURE]: () => ({}),
    [types.DOWNLOAD_MOVEMENTS_SUCCESS]: (state) => ({
        ...state,
        fetchingDownload: false,
    }),
    [types.DOWNLOAD_DEFERRED_REQUEST]: (state) => ({
        ...state,
        fetchingDownload: true,
    }),
    [types.DOWNLOAD_DEFERRED_FAILURE]: () => ({}),
    [types.DOWNLOAD_DEFERRED_SUCCESS]: (state) => ({
        ...state,
        fetchingDownload: false,
    }),
    [types.DOWNLOAD_ADDITIONALS_REQUEST]: (state) => ({
        ...state,
        fetchingDownload: true,
    }),
    [types.DOWNLOAD_ADDITIONALS_FAILURE]: () => ({}),
    [types.DOWNLOAD_ADDITIONALS_SUCCESS]: (state) => ({
        ...state,
        fetchingDownload: false,
    }),
    [types.STATUS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.STATUS_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.STATUS_FAILURE]: (state) => ({
        ...state,
        detail: {
            ...state.detail,
            blocked: !state.detail.blocked,
        },
        isFetching: false,
    }),

    [types.DETAILS_EVERY_REQUEST]: (state, action) => {
        const { id } = action;

        const listIdx = getListIdx(id, state);
        const newList = [...state.list];
        if (listIdx !== -1) {
            newList[listIdx] = {
                ...newList[listIdx],
                isFetchingPoints: true,
            };
        }

        return {
            ...state,
            list: newList,
            detail: null,
            details: state.detailsCacheValid ? state.details : [],
            detailsCacheValid: true,
        };
    },
    [types.DETAILS_EVERY_FAILURE]: (state, action) => {
        const { id } = action;

        const listIdx = getListIdx(id, state);

        const newList = [...state.list];
        newList[listIdx] = {
            ...newList[listIdx],
            isFetchingPoints: false,
        };

        const detailIdx = getDetailIdx(id, state);
        const newDetailList = [...state.details];
        newDetailList[detailIdx] = {
            ...newDetailList[detailIdx],
            ...action.detail,
        };

        return {
            ...state,
            list: newList,
            details: newDetailList,
            totalFavorites: null,
            detail: null,
        };
    },
    [types.DETAILS_EVERY_SUCCESS]: (state, action) => {
        const { id } = action;

        const listIdx = getListIdx(id, state);

        const newList = [...state.list];
        if (listIdx !== -1) {
            newList[listIdx] = {
                ...state.list[listIdx],
                isFetchingPoints: false,
                totalPoints: action.detail?.totalPoints,
                cashBackAmount: action.detail?.cashBackAmount,
                cashBackAmountCurrency: action.detail?.cashBackAmountCurrency,
                hasErrorLoadingPoints: action.detail?.hasErrorLoadingPoints,
                isIssuerAvaibleForPoints: action.detail?.isIssuerAvaibleForPoints,
                isIssuerAvaibleForCashback: action.detail?.isIssuerAvaibleForCashback,
                closingDate: action.detail?.closingDate,
                expirationDate: action.detail?.expirationDate,
                minimumPayment: action.detail?.minimumPayment,
                account: action.detail?.account,
                nameOnCard: action.detail?.nameOnCard,
                initialBalance: action.detail?.initialBalance,
                finalBalance: action.detail?.finalBalance,
                assignedQuota: action.detail?.assignedQuota,
                availableQuota: action.detail?.availableQuota,
                usedQuota: action.detail?.usedQuota,
                totalDebt: action.detail?.totalDebt,
                expiredDebt: action.detail?.expiredDebt,
            };
        }

        const detailIdx = getDetailIdx(id, state);
        let newDetailsList = [...state.details];
        if (detailIdx === -1) {
            newDetailsList = [...newDetailsList, { idProduct: id, ...action.detail }];
        } else {
            newDetailsList[detailIdx] = {
                ...state.details[detailIdx],
                ...action.detail,
            };
        }

        return {
            ...state,
            details: newDetailsList,
            list: newList,
            totalFavorrites: action.totalFavorites,
            detail: action.detail,
            detailsCacheValid: true,
        };
    },
    [types.MOVEMENTS_TRANSIT_REQUEST]: (state) => ({
        ...state,
        isFetchingMovements: true,
    }),
    [types.MOVEMENTS_TRANSIT_FAILURE]: (state) => ({
        ...state,
        isFetchingMovements: false,
        movementTransitList: [],
    }),
    [types.MOVEMENTS_TRANSIT_SUCCESS]: (state, action) => {
        const { movementTransitList } = action.payload;
        return {
            ...state,
            isFetchingMovements: false,
            movementTransitList: movementTransitList ? [...movementTransitList] : [],
        };
    },
    [types.DOWNLOAD_MOVEMENTS_TRANSIT_REQUEST]: (state) => ({
        ...state,
        isFetchingMovements: true,
    }),
    [types.DOWNLOAD_MOVEMENTS_TRANSIT_FAILURE]: (state) => ({
        ...state,
        isFetchingMovements: false,
    }),
    [types.DOWNLOAD_MOVEMENTS_TRANSIT_SUCCESS]: (state) => ({
        ...state,
        isFetchingMovements: false,
    }),
    [types.SET_EXTRA_DATA_DETAIL_CREDIT_CARD]: (state, action) => {
        if (!action) {
            return { ...state };
        }
        const { creditcard } = action;

        return {
            ...state,
            extraDataDetail: creditcard,
        };
    },
    [types.CLEAR_EXTRA_DATA_DETAIL_CREDIT_CARD]: (state) => ({
        ...state,
        extraDataDetail: undefined,
    }),

    // from creditCards
    [types.LIST_FETCHING]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.LIST_FETCHING_CARDS]: (state) => ({
        ...state,
        fetchingCards: true,
    }),
    [types.LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        list: state.listCacheValid ? state.list : [],
        listCacheValid: true,
    }),
    [types.LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        fetchingCards: false,
    }),
    [types.LIST_SUCCESS]: (state, action) => {
        // si ya se cargo el detalle, agrego los datos
        // para el widget
        const { details } = state;
        const newList = action.list.map((e) => {
            const idx = getDetailIdx(e.idProduct, state);
            let element = e;
            if (idx !== -1) {
                element = {
                    ...element,
                    isFetchingPoints: false,
                    totalPoints: details[idx]?.totalPoints,
                    cashBackAmount: details[idx]?.cashBackAmount,
                    cashBackAmountCurrency: details[idx]?.cashBackAmountCurrency,
                    hasErrorLoadingPoints: details[idx]?.hasErrorLoadingPoints,
                    isIssuerAvaibleForPoints: details[idx]?.isIssuerAvaibleForPoints,
                    isIssuerAvaibleForCashback: details[idx]?.isIssuerAvaibleForCashback,
                };
            }
            return element;
        });

        return {
            ...state,
            fetching: false,
            fetchingCards: false,
            list: newList,
            lastCacheUpdate: moment(),
            listCacheValid: true,
        };
    },
    [types.INVALIDATE_CACHE]: (state) => ({
        ...state,
        detailsCacheValid: false,
        list: [],
        listCacheValid: false,
    }),

    [types.FINANCING_PLANS_SUCCESS]: (state, action) => ({
        ...state,
        financingPlans: action.financingPlans,
    }),
    [types.CREDITCARD_DETAILS_STATEMENTS_SET_PAFINATED_INFO]: (state, action) => ({
        ...state,
        currentPage: action.currentPage,
        paginatedDataMap: action.paginatedDataMap,
        itemsMap: action.itemsMap,
        filters: action.filters,
        hasFiltersApplied: action.hasFiltersApplied,
    }),
    [types.CREDITCARD_DETAILS_STATEMENTS_CLEAR_FILTERS]: (state, action) => ({
        ...state,
        currentPage: undefined,
        paginatedDataMap: undefined,
        itemsMap: undefined,
        filters: undefined,
        hasFiltersApplied: false,
    }),
    [types.LIST_ADDITIONAL_REQUEST]: (state) => ({
        ...state,
        isFetchingAdditionals: true,
    }),
    [types.LIST_ADDITIONAL_FAILURE]: (state) => ({
        ...state,
        isFetchingAdditionals: false,
    }),
    [types.LIST_ADDITIONAL_SUCCESS]: (state, action) => ({
        ...state,
        isFetchingAdditionals: false,
        additionalCreditCards: action.creditCards,
    }),
    [types.OPEN_TC_STATUS_SAT_SERVICES_REQUEST]: (state) => ({
        ...state,
        isfetchingToken: true,
    }),
    [types.OPEN_TC_STATUS_SERVICES_SUCCESS]: (state) => ({
        ...state,
        isfetchingToken: false,
    }),
    [types.OPEN_TC_STATUS_SERVICES_FAILURE]: (state) => ({
        ...state,
        isfetchingToken: false,
    }),
    [types.READ_REQUEST]: (state) => ({
        ...state,
        creditCardDetail: undefined,
        isFetching: true,
    }),
    [types.READ_SUCCESS]: (state, action) => ({
        ...state,
        creditCardDetail: action.creditCardDetail,
        isFetching: false,
    }),
    [types.READ_FAILURE]: (state) => ({
        ...state,
        creditCardDetail: undefined,
        isFetching: false,
    }),
});

export default persistReducer(
    {
        key: "creditCard",
        storage: storageSession,
        blacklist: ["isFetching", "isFetchingMovements", "fetching", "movements"],
    },
    reducer,
);

export const actions = {
    detailReset: makeActionCreator(types.DETAILS_RESET),
    detailStopFetching: makeActionCreator(types.DETAILS_STOP_FETCHING),
    detailRequest: makeActionCreator(
        types.DETAILS_REQUEST,
        "id",
        "filters",
        "relationType",
        "hasOwnLimit",
        "updatingByPoints",
        "idForm",
    ),
    detailNoMovementsRequest: makeActionCreator(
        types.DETAILS_NO_MOVEMENTS_REQUEST,
        "id",
        "relationType",
        "hasOwnLimit",
        "idForm",
    ),
    detailFailure: makeActionCreator(types.DETAILS_FAILURE),
    detailSuccess: makeActionCreator(types.DETAILS_SUCCESS, "detail", "idForm", "totalFavorites"),
    detailSuccessMovement: makeActionCreator(
        types.DETAILS_SUCCESS_MOVEMENTS,
        "movements",
        "pageNumber",
        "hasMoreMovements",
        "totalCount",
        "totalCurrentPeriod",
        "offset",
    ),
    statusFailure: makeActionCreator(types.STATUS_FAILURE),
    statusSuccess: makeActionCreator(types.STATUS_SUCCESS),
    movementsRequest: makeActionCreator(types.MOVEMENTS_REQUEST, "id", "filters", "formikBag"),
    movementsFailure: makeActionCreator(types.MOVEMENTS_FAILURE),
    movementsSuccess: makeActionCreator(
        types.MOVEMENTS_SUCCESS,
        "movements",
        "hasMoreMovements",
        "totalCount",
        "totalCurrentPeriod",
    ),
    showOptions: makeActionCreator(types.SHOW_OPTIONS),
    hideOptions: makeActionCreator(types.HIDE_OPTIONS),
    fetchMoreMovementsRequest: makeActionCreator(types.FETCH_MORE_MOVEMENTS_REQUEST, "id", "filters", "formikBag"),
    noFetchMovements: makeActionCreator(
        types.NO_FETCH_MORE_MOVEMENTS_REQUEST,
        "accountId",
        "pageNumber",
        "moreStatements",
    ),
    fetchMoreMovementsFailure: makeActionCreator(types.FETCH_MORE_MOVEMENTS_FAILURE),
    fetchMoreMovementsSuccess: makeActionCreator(
        types.FETCH_MORE_MOVEMENTS_SUCCESS,
        "movements",
        "hasMoreMovements",
        "totalCount",
        "totalCurrentPeriod",
        "pageNumber",
        "offset",
        "quantity",
    ),
    downloadMovements: makeActionCreator(
        types.DOWNLOAD_MOVEMENTS_REQUEST,
        "idCreditCard",
        "filters",
        "format",
        "onFinishDownload",
        "creditCard",
    ),
    downloadDeferred: makeActionCreator(
        types.DOWNLOAD_DEFERRED_REQUEST,
        "idCreditCard",
        "filters",
        "format",
        "onFinishDownload",
        "creditCard",
    ),
    downloadAdditionals: makeActionCreator(
        types.DOWNLOAD_ADDITIONALS_REQUEST,
        "idCreditCard",
        "filters",
        "format",
        "onFinishDownload",
        "creditCard",
    ),
    detailEveryRequest: makeActionCreator(types.DETAILS_EVERY_REQUEST, "id"),
    detailEveryFailure: makeActionCreator(types.DETAILS_EVERY_FAILURE, "id", "detail"),
    detailEverySuccess: makeActionCreator(types.DETAILS_EVERY_SUCCESS, "id", "detail", "totalFavorites"),
    movementsTransitRequest: makeActionCreator(types.MOVEMENTS_TRANSIT_REQUEST, "productId"),
    downloadMovementsTransitRequest: makeActionCreator(
        types.DOWNLOAD_MOVEMENTS_TRANSIT_REQUEST,
        "productId",
        "contentType",
    ),
    setExtraDataDetail: makeActionCreator(types.SET_EXTRA_DATA_DETAIL_CREDIT_CARD, "creditcard"),
    clearExtraDataDetail: makeActionCreator(types.CLEAR_EXTRA_DATA_DETAIL_CREDIT_CARD),
    assignPinRequest: makeActionCreator(types.ASSIGN_PIN_CREDIT_CARD, "data"),

    // from creditCards
    listFetching: makeActionCreator(types.LIST_FETCHING),
    listFetchingCards: makeActionCreator(types.LIST_FETCHING_CARDS),
    listRequest: makeActionCreator(types.LIST_REQUEST, "path"),
    listFailure: makeActionCreator(types.LIST_FAILURE),
    listSuccess: makeActionCreator(types.LIST_SUCCESS, "list"),

    invalidateCache: makeActionCreator(types.INVALIDATE_CACHE),
    financingPlansRequest: makeActionCreator(types.FINANCING_PLANS_REQUEST, "productId", "onFinish"),

    setPaginatedInfo: (currentPage, paginatedDataMap, itemsMap, filters, hasFiltersApplied) => ({
        type: types.CREDITCARD_DETAILS_STATEMENTS_SET_PAFINATED_INFO,
        currentPage,
        paginatedDataMap,
        itemsMap,
        filters,
        hasFiltersApplied,
    }),
    additionalCreditCardRequest: makeActionCreator(types.LIST_ADDITIONAL_REQUEST, "productId", "typeOperation"),
    additionalCreditCardFailure: makeActionCreator(types.LIST_ADDITIONAL_FAILURE),

    additionalCreditCardSuccess: makeActionCreator(types.LIST_ADDITIONAL_SUCCESS, "creditCards"),

    openTCStatusSATServices: makeActionCreator(types.OPEN_TC_STATUS_SAT_SERVICES_REQUEST, "onFinish", "productDataTC"),
    clearFilters: () => ({
        type: types.CREDITCARD_DETAILS_STATEMENTS_CLEAR_FILTERS,
    }),
    readCreditCard: (creditCardId) => ({
        type: types.READ_REQUEST,
        creditCardId,
    }),
    readCreditCardSuccess: (creditCardDetail) => ({
        type: types.READ_SUCCESS,
        creditCardDetail,
    }),
    readCreditCardFailure: () => ({
        type: types.READ_FAILURE,
    }),
};

export const selectors = {
    getFetching: ({ creditCard }) => creditCard.isFetching,
    isFetchingMovements: ({ creditCard }) => creditCard.isFetchingMovements,
    getDetail: ({ creditCard }, id) => creditCard.details.find((e) => e.idProduct === id) || null,
    getDetailInList: ({ creditCard }, id) =>
        creditCard.list.find((e) => e.idProduct === id) ||
        creditCard.list
            .find((e) => e.idProduct === id || e.additionalCreditCardList?.find((eCh) => eCh.idProduct === id))
            ?.additionalCreditCardList?.find((e) => e.idProduct === id),
    getMovements: ({ creditCard }) => creditCard.movements,
    isHasMoreMovements: ({ creditCard }) => creditCard.hasMoreMovements,
    getPageNumber: ({ creditCard }) => creditCard.pageNumber,
    isOptionsVisible: ({ creditCard }) => creditCard.isOptionsVisible,
    getFilters: ({ creditCard }) => creditCard.filters,
    getTotalCount: ({ creditCard }) => creditCard.totalCount,
    getTotalCurrentPeriod: ({ creditCard }) => creditCard.totalCurrentPeriod,
    getTotalFavorites: ({ creditCard }) => creditCard.totalFavorites,
    getOffset: ({ creditCard }) => creditCard.offset,
    getQuantity: ({ creditCard }) => creditCard.quantity,
    getMovementTransitList: ({ creditCard }) => creditCard.movementTransitList,
    getFinancingPlans: ({ creditCard }) => creditCard.financingPlans,
    getExtraDataDetail: ({ creditCard }) => creditCard?.extraDataDetail,
    // eslint-disable-next-line no-confusing-arrow
    getDetailByForm: ({ creditCard }, idForm) =>
        creditCard?.detail?.idForm && creditCard.detail.idForm === idForm ? creditCard.detail : undefined,

    // from creditCards
    isFetching: ({ creditCard }) => creditCard.fetching,
    getList: ({ creditCard }) => creditCard.list,

    isListCacheValid: ({ creditCard }) => creditCard.listCacheValid,
    isDetailsCacheValid: ({ creditCard }) => creditCard.detailsCacheValid,

    getCurrentPage: ({ creditCard }) => creditCard.currentPage,
    getPaginatedDataMap: ({ creditCard }) => creditCard.paginatedDataMap,
    getItemsMap: ({ creditCard }) => creditCard.itemsMap,
    getAdditionalCreditCards: ({ creditCard }) => creditCard.additionalCreditCards,
    isfetchingToken: ({ creditCard }) => creditCard.isfetchingToken,
    isFetchingCards: ({ creditCard }) => creditCard.fetchingCards,
    isFetchingAdditionals: ({ creditCard }) => creditCard.isFetchingAdditionals,
    getCreditCardDetail: ({ creditCard }) => creditCard.creditCardDetail,
};
