import React, { Component } from "react";
import { string, objectOf, bool, shape, number } from "prop-types";
import Button from "pages/_components/Button";

import { downloadTxt, download } from "util/download";
import { downloadFilelink } from "middleware/file";

import formField from "pages/forms/_components/_fields/_commons/formField";

class Filelink extends Component {
    static propTypes = {
        label: string.isRequired,
        lang: string.isRequired,
        hintMap: objectOf(string).isRequired,
        editing: bool.isRequired,
        fileListMap: objectOf(
            shape({
                idFile: number.isRequired,
                fileName: string.isRequired,
            }),
        ).isRequired,
        field: objectOf(
            shape({
                name: string.isRequired,
            }),
        ).isRequired,
        singleLabel: string,
    };

    static defaultProps = {
        singleLabel: null,
    };

    downloadFile = async ({ idFile, fileName }) => {
        const { data } = await downloadFilelink(idFile);

        if (fileName.includes(".txt")) {
            downloadTxt(fileName, data.data.content);
        } else {
            download(fileName, data.data.content);
        }
    };

    render() {
        const {
            label,
            hintMap,
            lang,
            editing,
            fileListMap,
            field: { name },
            singleLabel,
        } = this.props;

        if (!editing) {
            return null;
        }

        let customLabel = singleLabel;
        if (!customLabel) {
            customLabel = hintMap && hintMap[lang] ? hintMap[lang] : "";
        }
        return (
            <div className="form-group">
                <p>{label}</p>

                <Button
                    id={name}
                    image="images/download.svg"
                    onClick={() => this.downloadFile(fileListMap[lang])}
                    bsStyle="link">
                    {customLabel}
                </Button>
            </div>
        );
    }
}

export default formField({ pureRender: true })(Filelink);
