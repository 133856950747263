import classNames from "classnames";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Text from "pages/_components/Text";
import { bool, shape } from "prop-types";
import React, { Component } from "react";
import * as i18nUtils from "util/i18n";
import * as i18n from "util/i18n";

class Statement extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        fee: shape({}).isRequired,
        paymentPlan: bool.isRequired,
        isFeePaid: bool.isRequired,
    };

    render() {
        const { isDesktop, paymentPlan, fee, isFeePaid } = this.props;

        let lastColumnNumber = "5";
        if (!isDesktop) {
            lastColumnNumber = "3";
        } else if (isFeePaid) {
            lastColumnNumber = "5";
        }

        return (
            <>
                {isDesktop ? (
                    <>
                        <GridTable.Data columnStart={1} alignX={isDesktop ? "left" : "flex-start"}>
                            <Box>
                                <FormattedDate
                                    date={fee.dueDate}
                                    size={isDesktop ? "6" : "5"}
                                    {...(!isDesktop && { color: "text" })}
                                />
                            </Box>
                        </GridTable.Data>
                        <GridTable.Data columnStart={1} alignX="left">
                            <Box>
                                <Text>Nro. {fee.numberFee}</Text>
                            </Box>
                        </GridTable.Data>
                        <GridTable.Data columnStart={2} alignX="flex-start">
                            <FormattedAmount
                                size="6"
                                className="data-amount justify-content-end"
                                quantity={fee.amountBalanceDue}
                                noCurrency
                            />
                        </GridTable.Data>
                        <GridTable.Data columnStart={3} alignX="flex-start">
                            <FormattedAmount
                                size="6"
                                className="data-amount justify-content-end"
                                quantity={fee.amountInterest}
                                noCurrency
                            />
                        </GridTable.Data>
                        <GridTable.Data columnStart={4} alignX="center">
                            <Text
                                align="center"
                                className={classNames(
                                    "ml-3",
                                    { "product-status-text": fee.status === "VIG" },
                                    { "product-status-can-text": fee.status === "CAN" },
                                    { "product-status-ven-text": fee.status === "VEN" },
                                    { "product-status-nvi-text": fee.status === "NVI" },
                                )}>
                                {fee.status === "CAN"
                                    ? i18nUtils.get("loan.detail.fee.can.status")
                                    : fee.status === "VIG"
                                    ? i18nUtils.get("loan.detail.fee.vig.status")
                                    : i18nUtils.get("loan.detail.fee.nvi.status")}
                            </Text>
                        </GridTable.Data>
                        <GridTable.Data columnStart={5} alignX="flex-end">
                            <FormattedAmount
                                size="6"
                                className="data-amount justify-content-end"
                                quantity={fee.totalAmount}
                                noCurrency
                            />
                        </GridTable.Data>
                    </>
                ) : (
                    <>
                        <Box display="flex" column borderRadius="default">
                            <Box display="flex" alignX="between" alignY="center" fullWidth className="my-3">
                                <Box display="flex">
                                    <Text
                                        align="center"
                                        className={classNames(
                                            { "product-status-text": fee.status === "VIG" },
                                            { "product-status-can-text": fee.status === "CAN" },
                                            { "product-status-ven-text": fee.status === "VEN" },
                                            { "product-status-nvi-text": fee.status === "NVI" },
                                        )}>
                                        {fee.status === "CAN"
                                            ? i18nUtils.get("loan.detail.fee.can.status")
                                            : fee.status === "VIG"
                                            ? i18nUtils.get("loan.detail.fee.vig.status")
                                            : i18nUtils.get("loan.detail.fee.nvi.status")}
                                    </Text>
                                </Box>
                                <Box display="flex" column alignX="end">
                                    <Box>
                                        <Text
                                            size=""
                                            color="text-disabled-color"
                                            defaultValue={i18n.get("loan.detail.statement.header.capital.mobile")}
                                        />
                                    </Box>

                                    <Box display="flex">
                                        <FormattedAmount
                                            size="5"
                                            className="data-amount justify-content-end"
                                            quantity={fee.amountBalanceDue}
                                            noCurrency
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box display="flex" alignX="between" fullWidth className="my-3" alignY="center">
                                <Box display="flex" column>
                                    <Box>
                                        <Text
                                            size="5"
                                            color="text-disabled-color"
                                            defaultValue={i18n.get("loan.detail.statement.header.dueDate.mobile")}
                                        />
                                    </Box>

                                    <Box display="flex">
                                        <FormattedDate
                                            date={fee.dueDate}
                                            size={isDesktop ? "6" : "5"}
                                            {...(!isDesktop && { color: "text" })}
                                        />
                                    </Box>
                                    <Text>Nro. {fee.numberFee}</Text>
                                </Box>
                                <Box display="flex" column alignX={"end"}>
                                    <Box>
                                        <Text
                                            size="5"
                                            color="text-disabled-color"
                                            defaultValue={i18n.get("loan.detail.statement.header.interest.mobile")}
                                        />
                                    </Box>

                                    <Box display="flex">
                                        <FormattedAmount
                                            size="5"
                                            className="data-amount justify-content-end"
                                            quantity={fee.amountInterest}
                                            noCurrency
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box display="flex" alignX="between" fullWidth className="my-3">
                                <Box display="flex" column>
                                    <Box/>
                                </Box>

                                <Box display="flex" column alignX={"end"}>
                                    <Box>
                                        <Text
                                            size="5"
                                            color="text-disabled-color"
                                            defaultValue={i18n.get("loan.detail.statement.header.amount.mobile")}
                                        />
                                    </Box>

                                    <Box display="flex">
                                        <FormattedAmount
                                            size="5"
                                            className="data-amount justify-content-end"
                                            quantity={fee.totalAmount}
                                            noCurrency
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </>
        );
    }
}

export default Statement;
