import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Credential from "pages/_components/fields/credentials/Credential";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { compose } from "redux";
import * as config from "util/config";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import EnrollmentWrapper from "../_components/EnrollmentWrapper";
import { actions as notificationActions } from "../../../reducers/notification";
import LabelWithIcon from "../../_components/LabelWithIcon";

const FORM_ID = "enrollment.step3.validate";

class ValidateUser extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(
            notificationActions.showNotification(i18n.get("enrollment.user.validate.migrated.message"), "warning", [
                "enrollment",
            ]),
        );
    }

    cancel = () => {
        const { dispatch } = this.props;

        dispatch(enrollmentActions.showExitEnrollmentModal(true));
    };

    onBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/enrollment/document"));
    };

    content = () => {
        const { isSubmitting, isDesktop, userInfo, values, errors } = this.props;

        const maxLength = config.getInteger("core.password.maxLength");

        const { firstName, username } = userInfo;
        const enableButton = values.password.length > 0;

        let error = "";
        Object.keys(errors).forEach((key) => {
            error += errors[key];
        });

        const alertMessage = error && (
            <Box fullWidth className="mb-7 mb-md-7 mt-3">
                <LabelWithIcon type="error" color="text" text="enrollment.user.validate.alert.password.invalid" />
            </Box>
        );
        return (
            <>
                <Form className={classNames("display-flex flex-column", { "full-height": !isDesktop })}>
                    <Box display="flex" fullWidth className="login-title-container">
                        {isDesktop && (
                            <Box className="login-title-icon pr-4" onClick={this.onBack} pointer>
                                <Image src="images/arrow_back.svg" />
                            </Box>
                        )}
                        <Box fullWidth className="login-title-text">
                            <Text className="btn-link-span" size={isDesktop ? "3" : "2"} bold>
                                {i18n.get("enrollment.step3.validate.title", null, {
                                    USER_NAME: firstName,
                                })}
                            </Text>
                        </Box>
                    </Box>

                    <Box background="white" borderRadius="xxl">
                        <Box>
                            <Text labelKey="enrollment.step3.validate.info" />
                        </Box>
                        <Box className="mb-7 mt-3">
                            <Text bold className="username-text">
                                {username}
                            </Text>
                        </Box>

                        <Box fullWidth className="mb-7 mb-md-7">
                            <Field
                                name="password"
                                idForm={FORM_ID}
                                component={Credential}
                                maxLength={maxLength}
                                placeholder={i18n.get("enrollment.step3.validate.password.placeholder")}
                                autoComplete="off"
                            />
                            {alertMessage}
                        </Box>
                    </Box>
                    <Box {...(!isDesktop && { className: "mt-auto" })}>
                        <Row>
                            <Col xs={isDesktop ? 6 : 12}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                    disabled={!enableButton}
                                    className="full-width"
                                />
                            </Col>
                            <Col xs={isDesktop ? 6 : 12}>
                                <Button
                                    label="global.cancel"
                                    onClick={() => {
                                        this.cancel();
                                    }}
                                    bsStyle="outline"
                                    block
                                />
                            </Col>
                        </Row>
                    </Box>
                </Form>
            </>
        );
    };

    render() {
        const { isDesktop, userInfo } = this.props;

        if (!userInfo || Object.keys(userInfo).length === 0) {
            this.onBack();
            return <></>;
        }

        return (
            <EnrollmentWrapper onBack={this.onBack} isDesktop={isDesktop}>
                <>{this.content()}</>
            </EnrollmentWrapper>
        );
    }
}

ValidateUser.propTypes = {
    dispatch: func.isRequired,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    values: shape({
        password: string,
    }).isRequired,
    isValid: bool.isRequired,
    showExitModal: bool.isRequired,
    userInfo: shape({
        idUsuario: string,
        email: string,
        mobileNumber: string,
        firstName: string,
        lastName: string,
        documentNumber: string,
        documentType: string,
        username: string,
    }).isRequired,
    errors: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    userInfo: enrollmentSelectors.getUserInfo(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,

        mapPropsToValues: () => ({
            password: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                password: Yup.string().required("global.field.required"),
            }),
        handleSubmit: ({ password }, formikBag) => {
            const { dispatch, userInfo } = formikBag.props;
            dispatch(enrollmentActions.saveUserInfo(userInfo.username, password, formikBag));
            dispatch(enrollmentActions.validateUserCredentials(formikBag));
        },
    }),
)(ValidateUser);
