import classNames from "classnames";
import Box from "pages/_components/Box";
import ListItem from "pages/accounts/ListItem";
import InfoAccount from "pages/accounts/_components/InfoAccount";
import { bool, func, oneOfType, shape } from "prop-types";
import React, { Component } from "react";
import * as configUtil from "util/config";
import * as i18n from "util/i18n";

class AccountsList extends Component {
    static propTypes = {
        accounts: oneOfType([shape({})]).isRequired,
        showProductIcon: bool,
        setFavoriteAccount: func,
        completeFavorites: bool,
    };

    static defaultProps = {
        showProductIcon: true,
        setFavoriteAccount: () => {},
        completeFavorites: false,
    };

    renderList = () => {
        const { accounts, showProductIcon, setFavoriteAccount, completeFavorites } = this.props;
        if (!accounts.length) {
            return null;
        }

        const maxBoxDisplay = configUtil.getInteger("product.list.maxBoxDisplay", 5);

        let showAsCards = accounts.length < maxBoxDisplay;
        showAsCards = false;

        return (
            <Box
                component="nav"
                ariaLabel={i18n.get("activities.accounts.list")}
                className={classNames("table-body", { "table-body--grid": showAsCards })}>
                <Box component="ul" noList>
                    {accounts.map((account) => {
                        if (showAsCards) {
                            return <InfoAccount account={account} key={`${account.idProduct}`} productAsCard />;
                        }

                        return (
                            <ListItem
                                {...account}
                                key={account.idProduct}
                                showProductIcon={showProductIcon}
                                setFavoriteAccount={setFavoriteAccount}
                                showStar
                                completeFavorites={completeFavorites}
                                showStatusIcon
                            />
                        );
                    })}
                </Box>
            </Box>
        );
    };

    render() {
        return <Box className="table table--products">{this.renderList()}</Box>;
    }
}

export default AccountsList;
