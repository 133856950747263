import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { actions as recoveryUserActions, selectors } from "reducers/recoveryUser";
import { selectors as sessionSelectors } from "reducers/session";
import classNames from "classnames";
import LoginWrapper from "pages/login/_components/LoginWrapper";
import Head from "pages/_components/Head";
import * as i18n from "util/i18n";
import { Form, withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import { push } from "react-router-redux";
import Image from "pages/_components/Image";
import { simpleMaskEmail, maskPhoneNumber } from "util/format";

const FORM_ID = "recoveryUser.step3";
const CREDENTIAL_TYPE_TOKEN = "token";
const CREDENTIAL_TYPE_OTP = "otp";
const CREDENTIAL_TYPE_QUESTION = "question";
const METHOD_EMAIL = "MAIL";
const METHOD_PHONE = "SMS";

const RecoverUserStep3 = (props) => {
    const { isSubmitting, contact, contactType, isMobile, isDesktop } = props;

    const goToLogin = () => {
        const { dispatch } = props;
        dispatch(push("/loginStep1"));
    };

    return (
        <LoginWrapper>
            <>
                <MainContainer
                    className={classNames("main-container", {
                        "background-white": true,
                    })}>
                    <Box
                        background="white"
                        borderRadius="lg"
                        className={classNames("mt-3 ", {
                            "p-7": isDesktop,
                            "pt-9 change-question-form": !isDesktop,
                        })}>
                        <Box>
                            <Box display="flex" alignX="center" className="mb-8 mt-5">
                                <Box className="box-image-success">
                                    <Image src="images/util/success.gif" />
                                </Box>
                            </Box>
                            <Box className="text-center mb-7">
                                <Text
                                    size={isDesktop ? "3" : "2"}
                                    labelKey={`${FORM_ID}.success.title`}
                                    bold
                                    className={classNames({
                                        "line-height-125": isDesktop,
                                        "line-height-15": !isDesktop,
                                    })}
                                />
                            </Box>
                            <Box className="text-center mb-7 line-height-15">
                                <Text
                                    size={isDesktop ? "5" : "5"}
                                    labelKey={`${FORM_ID}.success.info`}
                                    defaultValue={
                                        contactType
                                            ? i18n.get(`${FORM_ID}.${contactType.toLowerCase()}.notification`)
                                            : i18n.get("recoveryUser.step3.mail.notification")
                                    }
                                    light
                                    className={classNames({
                                        "line-height-125": isDesktop,
                                        "line-height-15": !isDesktop,
                                    })}
                                />
                                <Text
                                    bold
                                    defaultValue={
                                        contactType
                                            ? contactType === METHOD_EMAIL
                                                ? simpleMaskEmail(contact)
                                                : maskPhoneNumber(contact)
                                            : contact
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" className="mb-7 mb-md-0 mt-auto">
                        <Button label="login.comeIn" type="submit" bsStyle="primary" onClick={goToLogin} block />
                    </Box>
                </MainContainer>
            </>
        </LoginWrapper>
    );
};

RecoverUserStep3.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    isDesktop: bool.isRequired,
    fetching: bool.isRequired,
    formData: shape({}),
    isActiveCorporate: bool.isRequired,
    isSubmitting: bool.isRequired,
    contact: string.isRequired,
};

RecoverUserStep3.defaultProps = {
    formData: {},
};

const mapStateToProps = (state) => ({
    fetching: selectors.getFetching(state),
    formData: selectors.getFormData(state),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
    contact: selectors.getContact(state),
    contactType: selectors.getContactType(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            questionUser: {
                answer: "",
            },
            phisicalToken: "",
            validationCode: "",
            operationType: "",
        }),
        validationSchema: ({ operationType }) =>
            Yup.object().shape({
                questionUser: Yup.object().shape({
                    answer:
                        operationType === CREDENTIAL_TYPE_QUESTION
                            ? Yup.string().required(i18n.get(`${FORM_ID}.answer.required`))
                            : Yup.string(),
                }),
                phisicalToken: Yup.string().when("operationType", {
                    is: CREDENTIAL_TYPE_TOKEN,
                    then: Yup.string().required(i18n.get(`${FORM_ID}.token.required`)),
                }),
                validationCode: Yup.string().when("operationType", {
                    is: CREDENTIAL_TYPE_OTP,
                    then: Yup.string().required(i18n.get(`${FORM_ID}.validationCode.required`)),
                }),
            }),
        handleSubmit: ({ questionUser, phisicalToken, validationCode }, formikBag) => {
            const { answer } = questionUser;
            const {
                props: { exchangeToken, operationType },
            } = formikBag;
            formikBag.props.dispatch(
                recoveryUserActions.recoveryUserStep2(
                    answer,
                    phisicalToken,
                    validationCode,
                    operationType,
                    exchangeToken,
                    formikBag,
                ),
            );
        },
    }),
)(RecoverUserStep3);
