import { bool, func, number as typeNumber, shape, string } from "prop-types";
import React, { Component } from "react";
import CreditCardWidget from "pages/_components/CreditCardWidget";
import { withRouter, Link } from "react-router-dom";
import { actions as productActions } from "reducers/products";
import { actions as creditCardActions } from "reducers/creditCard";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import { isDesktop } from "react-device-detect";
import Info from "pages/_components/Info";
import classNames from "classnames";
import * as i18nUtils from "util/i18n";
import Text from "pages/_components/Text";
import { dictionaryMiniatureCard } from "util/creditCards.js";

class CreditCardsListItem extends Component {
    static propTypes = {
        availableBalance: typeNumber,
        availableBalanceCurrency: string,
        blocked: bool,
        cashBackAmount: typeNumber,
        cashBackAmountCurrency: typeNumber,
        creditCardData: shape({}),
        description: string,
        dispatch: func,
        expirationDateMask: string,
        franchise: string,
        hasErrorLoadingPoints: bool,
        hideAmountLabel: bool,
        history: shape({}),
        idProduct: string,
        isFetchingPoints: bool,
        label: string,
        minimumPayment: typeNumber,
        minimumPaymentCurrency: string,
        numberMask: string,
        path: string,
        productAlias: string,
        shortLabel: string,
        showLink: bool,
        statusCode: string,
        totalPoints: typeNumber,
        isList: bool,
        isIssuerAvaibleForPoints: bool,
        isIssuerAvaibleForCashback: bool,
    };

    static defaultProps = {
        availableBalance: null,
        availableBalanceCurrency: null,
        blocked: false,
        cashBackAmount: null,
        cashBackAmountCurrency: null,
        creditCardData: {},
        description: "",
        dispatch: () => {},
        expirationDateMask: null,
        franchise: null,
        hasErrorLoadingPoints: false,
        hideAmountLabel: false,
        history: {},
        idProduct: null,
        isFetchingPoints: true,
        label: null,
        minimumPayment: null,
        minimumPaymentCurrency: null,
        numberMask: "",
        path: null,
        productAlias: null,
        shortLabel: "",
        showLink: true,
        statusCode: "",
        totalPoints: null,
        isList: false,
        isIssuerAvaibleForPoints: false,
        isIssuerAvaibleForCashback: false,
    };

    onClickLoadCashBackPoint = (idProduct) => {
        const { dispatch } = this.props;
        dispatch(creditCardActions.detailEveryRequest(idProduct));
    };

    onClickItem = (idProduct, description, statusCode, creditcard) => {
        const { path, history, dispatch } = this.props;
        dispatch(creditCardActions.setExtraDataDetail(creditcard));
        dispatch(productActions.setSelectProduct({ idProduct, description, statusCode }));
        const finalPath = path || `/creditCards/${idProduct}`;
        history.push(finalPath);
    };

    renderItem = () => {
        const { idProduct, creditCardData } = this.props;
        const creditCardStyle = dictionaryMiniatureCard(creditCardData.cardStyle);
        return (
            <Box display="flex" alignX="between" alignY="center" className="box-shadow-small border-radius-lg mb-4">
                <Link
                    aria-describedby={idProduct}
                    className="row-credit-card full-width border-radius-lg"
                    to={`/creditCards/${idProduct}`}>
                    <Box fullWidth>
                        {!isDesktop && (
                            <Box display="flex" className="pt-5 px-5">
                                <Box className="mr-5">
                                    <Image
                                        className="img-card-mobile"
                                        color="text-inverse-color"
                                        src={`images/${creditCardStyle}-miniature-creditCard.png`}
                                    />
                                </Box>
                                <Box fullWidth>
                                    <Info
                                        className="info-account-list"
                                        labelText={creditCardData.cardStyle}
                                        text={creditCardData.shortLabel}
                                    />
                                    <Box className="mt-2">
                                        <Text color="text-color" size="6" regular>
                                            {creditCardData.nameOnCard}
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        <Box
                            display={isDesktop ? "flex" : "grid"}
                            alignY="center"
                            gapX="5"
                            fullWidth
                            className="item-account-list p-5 border-radius-lg justify-content-between">
                            {isDesktop && (
                                <>
                                    <Box display="flex" alignY="center" className="mr-4">
                                        <Image
                                            src={`images/${creditCardStyle}-icon-creditCard.svg`}
                                            className="img-header-card fill-transparent"
                                        />
                                    </Box>
                                    <Box className="info-account-list card-list-head">
                                        <Info
                                            className="info-account-list"
                                            labelText={creditCardData.cardStyle}
                                            text={creditCardData.shortLabel}
                                        />
                                        <Info
                                            className="info-account-list"
                                            labelText={creditCardData.nameOnCard}
                                            flex={!isDesktop}
                                        />
                                    </Box>
                                </>
                            )}
                            <Info
                                className="info-account-list"
                                labelKey="creditCard.details.info.closingDate"
                                // date={creditCardData.closingDate}
                                {...(creditCardData.closingDate ? { date: creditCardData.closingDate } : { text: "-" })}
                            />
                            <Info
                                className="info-account-list"
                                labelKey="creditCard.details.info.dueDate"
                                // date={creditCardData.expirationDate}
                                {...(creditCardData.expirationDate
                                    ? { date: creditCardData.expirationDate }
                                    : { text: "-" })}
                            />
                            {!creditCardData.isAditional ? (
                                <>
                                    <Info
                                        className={classNames("info-account-list", { "text-right": isDesktop })}
                                        labelText={`${i18nUtils.get("creditCard.details.info.minimum.pay")}
                                    ${creditCardData.minimumPaymentCurrency}`}
                                        amount={creditCardData.minimumPayment}
                                    />
                                    <Info
                                        className={classNames("info-account-list data-amount", {
                                            "text-right": isDesktop,
                                        })}
                                        labelText={`${i18nUtils.get("creditLine.total.balance")} ${
                                            creditCardData.currentDueCurrency
                                        }`}
                                        amount={creditCardData.currentDue}
                                    />
                                </>
                            ) : (
                                <>
                                    <Info
                                        className={classNames("info-account-list", { "text-right": isDesktop })}
                                        labelText={`${i18nUtils.get("creditCard.details.info.assignedQuota")}
                                    ${creditCardData.minimumPaymentCurrency}`}
                                        // amount={creditCardData.assignedQuota}
                                        {...(creditCardData.assignedQuota
                                            ? { amount: creditCardData.assignedQuota }
                                            : { text: "-" })}
                                    />
                                    <Info
                                        className={classNames("info-account-list data-amount", {
                                            "text-right": isDesktop,
                                        })}
                                        labelText={`${i18nUtils.get("creditCard.details.info.availableQuota")} ${
                                            creditCardData.currentDueCurrency
                                        }`}
                                        {...(creditCardData.availableQuota
                                            ? { amount: creditCardData.availableQuota }
                                            : { text: "-" })}
                                        // amount={creditCardData.availableQuota}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                </Link>
            </Box>
        );
    };

    render() {
        const {
            minimumPayment,
            minimumPaymentCurrency,
            idProduct,
            blocked,
            numberMask,
            franchise,
            availableBalanceCurrency,
            description,
            statusCode,
            shortLabel,
            creditCardData,
            expirationDateMask,
            isFetchingPoints,
            hasErrorLoadingPoints,
            isList,
        } = this.props;
        return (
            <>
                {isList ? (
                    <>{this.renderItem()}</>
                ) : (
                    <CreditCardWidget
                        {...this.props}
                        minimumPayment={minimumPayment}
                        minimumPaymentCurrency={minimumPaymentCurrency}
                        currency={availableBalanceCurrency}
                        icon={franchise.toLowerCase()}
                        id={idProduct}
                        blocked={blocked}
                        onClick={() => {
                            this.onClickItem(idProduct, description, statusCode, creditCardData);
                        }}
                        reference={numberMask}
                        hasErrorLoadingPoints={hasErrorLoadingPoints}
                        isFetchingPoints={isFetchingPoints}
                        statusCode={statusCode}
                        shortLabel={shortLabel}
                        idProduct={idProduct}
                        expirationDate={expirationDateMask}
                    />
                )}
            </>
        );
    }
}

export default withRouter(CreditCardsListItem);
