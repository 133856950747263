export const isString = (value) => typeof value === "string";

export const isEmpty = (value) => isString(value) && (value.trim() === "" || value.trim() === "_empty");

export const trim = (value) => {
    if (isString(value)) {
        return value.trim();
    }

    return value;
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const insertLinebreaks = (string) => string.replace(/<br ?\/?>/g, "\n");

export const insertHTMLLinebreaks = (string) => string.replace(/\n/g, "<br>");

export const maskString = (string, padding) => {
    if (!string || string.length < padding * 2) {
        return "";
    }

    return `${string.slice(0, padding)}${"*".repeat(
        string.slice(padding, string.length - padding).length,
    )}${string.slice(string.length - padding)}`;
};

export const formatDescriptionWithCapitalizedFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};