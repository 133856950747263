import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import { bool, func, string, arrayOf } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { getArray } from "util/config";

class ChangeLanguage extends Component {
    getContent() {
        const { supportedLanguages, activeLanguage, isDesktop, fetching } = this.props;
        return (
            <Row className="justify-content-center">
                <Col xs={12} md={8} mdOffset={2}>
                    <Disclaimer
                        className="p-3 px-5 p-md-5 mt-5 mt-md-0 mb-12 mb-md-8"
                        styled="info"
                        labelKey="settings.changeLanguage.title"
                    />
                    <Box
                        {...(isDesktop && { background: "white" })}
                        className={classNames("py-md-12 px-5 px-md-80 pb-8 pb-md-12 mb-8", {
                            "mx-n-5": !isDesktop,
                        })}
                        borderRadius="xl">
                        <Row className="my-8">
                            {Object.keys(supportedLanguages).map((idLanguage) => (
                                <Col xs={12} md={6} key={`language-col-${idLanguage}`}>
                                    <Box key={`language-${idLanguage}`} fullWidth>
                                        <Button
                                            type="button"
                                            className={classNames({
                                                "is-active": supportedLanguages[idLanguage] === activeLanguage,
                                            })}
                                            bsStyle="big"
                                            label={`settings.changeLanguage.label.${supportedLanguages[idLanguage]}`}
                                            loading={supportedLanguages[idLanguage] !== activeLanguage && fetching}
                                            block
                                            onClick={
                                                supportedLanguages[idLanguage] !== activeLanguage
                                                    ? () => this.handleClick(supportedLanguages[idLanguage])
                                                    : null
                                            }
                                        />
                                    </Box>
                                </Col>
                            ))}
                        </Row>
                    </Box>
                </Col>
            </Row>
        );
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    handleClick = (language) => {
        const { dispatch } = this.props;
        document.documentElement.lang = language;
        dispatch(settingsActions.changeLanguage(language));
    };

    render() {
        const { fetching } = this.props;
        return (
            <PageLoading loading={fetching}>
                <Head title="settings.changeLanguage" onBack={this.handleBack} />
                <MainContainer>
                    <Notification scopeToShow="changeLanguage" />
                    {this.getContent()}
                </MainContainer>
            </PageLoading>
        );
    }
}
ChangeLanguage.propTypes = {
    fetching: bool,
    dispatch: func.isRequired,
    activeLanguage: string.isRequired,
    isDesktop: bool.isRequired,
    supportedLanguages: arrayOf(string).isRequired,
};
ChangeLanguage.defaultProps = {
    fetching: false,
};
const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
    supportedLanguages: getArray("frontend.i18n.languages"),
    fetching: settingsSelectors.isFetching(state) || i18nSelectors.getFetching(state),
});
export default connect(mapStateToProps)(resizableRoute(ChangeLanguage));
