import React from "react";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { shape, string } from "prop-types";

const LabelWithIcon = ({ text, type, color, textParams }) => (
    <Box background="white" display="flex" alignX="center" alignY="center" fitWidth>
        <Box>
            <Image
                src={type === "error" ? "images/error.svg" : "images/successRadious.svg"}
                ariaLabel="tokenStatus"
                wrapperWidth="4"
                wrapperHeight="4"
            />
            {/* <Image src="images/successRadious.svg" ariaLabel="tokenStatus" wrapperWidth="6" wrapperHeight="6" /> */}
        </Box>
        <Text size="5" color={color} regular className="pl-3" labelKey={text} textParams={textParams} />
        {/* <Text size="6" color="heading" regular className="mr-2" labelKey={text} /> */}
    </Box>
);

LabelWithIcon.propTypes = {
    text: string.isRequired,
    type: string.isRequired,
    color: string,
    textParams: shape({}),
};
LabelWithIcon.defaultProps = {
    color: "heading",
    textParams: null,
};

export default LabelWithIcon;
