import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, string } from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Image from "pages/_components/Image";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";

const ExitModal = (props) => {
    const { acceptFunction, cancelFunction, text, headingText, modalShow } = props;
    return (
        <div className="modal-container">
            <Modal show={modalShow}>
                <Box className="p-7">
                    <Box
                        className={classNames({
                            "px-11 py-7": isDesktop,
                            "px-0 py-5": !isDesktop,
                        })}>
                        <Box isplay="flex" alignX="center" className="my-7">
                            <Image src="images/util/warning.svg" className="size-icn-warning" />
                        </Box>
                        <Box display="flex" alignX="center" className="mb-7">
                            <Text align="center" size="3" color="text" bold>
                                {headingText}
                            </Text>
                        </Box>
                        <Box display="flex" alignX="center" className="mb-8">
                            <Text align="center" size="5" color="text" defaultValue={text} />
                        </Box>
                        <Box display="flex" column fullWidth>
                            <Button
                                label="global.exit"
                                className="mb-4"
                                bsStyle="primary"
                                onClick={acceptFunction}
                                block
                            />
                            <Button label="global.continue" bsStyle="outline" onClick={cancelFunction} block />
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

ExitModal.propTypes = {
    acceptFunction: func.isRequired,
    cancelFunction: func.isRequired,
    text: string.isRequired,
    modalShow: bool.isRequired,
    headingText: string.isRequired,
};

export default connect()(resizableRoute(ExitModal));
