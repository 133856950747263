import * as API from "middleware/api";

export const getTransferList = () => API.executeWithAccessToken("/transfers.foreign.support.list", {});
export const sendSupportRequest = (supportRequestType, transactionIdentifier, accountId, beneficiaryData) =>
    API.executeWithAccessToken("/transfers.foreign.support.request", {
        supportRequestType,
        transactionIdentifier,
        accountId,
        beneficiaryData,
    });
