import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux/actions";

const DeactivationWally = ({ dispatch, fetching }) => {
    const handleBack = () => {
        dispatch(push("/wally"));
    };

    return (
        <PageLoading loading={fetching}>
            <Head titleImage="wally-head.svg" onBack={handleBack} title="wally.settings.title" />
            <MainContainer>
                <Box display="flex" column fullHeight>
                    <Box display="flex" column alignY="between" alignX="center" className="pt-8 mb-9">
                        <Image
                            src="images/smartphone-cancel-wally.svg"
                            color="wally"
                            isMobileNative
                            height="12"
                            className="mb-9"
                        />

                        <Box
                            display="flex"
                            column
                            alignY="center"
                            alignX="center"
                            background="white"
                            fullWidth
                            borderRadius="xxl"
                            className="pt-11 pb-10 mb-9">
                            <Text
                                labelKey="wally.settings.deactivate.confirmation.text1"
                                size={4}
                                align="center"
                                className="px-5 mb-3"
                            />
                            <Box>
                                <Text labelKey="wally.finish.subtitle.span" color="wally" bold size="4" />
                                <Image
                                    src="images/wally.svg"
                                    fullWidth={false}
                                    isMobileNative
                                    height="5"
                                    wrapperWidth="8"
                                    wrapperHeight="4"
                                    className="ml-1"
                                />
                            </Box>
                        </Box>
                        <Text
                            labelKey="wally.settings.deactivate.confirmation.text2"
                            align="center"
                            size={3}
                            color="heading"
                        />
                    </Box>

                    <Button label="global.goToDesktop" bsStyle="outline" block className="mt-auto" href="/desktop" />
                </Box>
            </MainContainer>
        </PageLoading>
    );
};

DeactivationWally.propTypes = {
    dispatch: func.isRequired,
    fetching: bool,
};

DeactivationWally.defaultProps = { fetching: false };

export default connect()(resizableRoute(DeactivationWally));
