import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import SelectAccount from "pages/_components/fields/SelectAccount/SelectAccount";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, number, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { selectors as accountsSelectors } from "reducers/accounts";
import { actions as wallyActions } from "reducers/wally";

const EnrrolmentWallySelectAccount = (props) => {
    const { dispatch, history, fetching, accounts } = props;

    const [fetchingSubmit, setFetchingSubmit] = useState(false);

    useEffect(() => {
        if (accounts.length === 0) {
            dispatch(push("/wally"));
        }
    }, []);

    const handleBack = () => {
        history.goBack();
    };

    const handleCancel = () => {
        dispatch(push("/desktop"));
    };

    const onFinish = () => {
        setFetchingSubmit(false);
    };

    const handleSubmit = (values) => {
        const accountSelected = accounts?.find((item) => item.idProduct === values.account);
        if (accountSelected && !fetchingSubmit) {
            setFetchingSubmit(true);
            dispatch(wallyActions.activateRequest(accountSelected, onFinish));
        }
    };

    return (
        <PageLoading loading={fetching}>
            <Head titleImage="wally-head.svg" onBack={handleBack} title="wally.header.activation" />
            <MainContainer>
                <Box display="flex" column className="pt-8">
                    <Box display="flex" alignX="center" fullWidth className="mb-7">
                        <Image src="images/phone-cash.svg" isMobileNative />
                    </Box>
                    <Text
                        labelKey="wally.select.account.title"
                        bold
                        size="1"
                        align="center"
                        color="heading"
                        className="mb-3"
                    />
                    <Text labelKey="wally.select.account.subtitle" align="center" size="4" className="px-3 mb-5" />
                </Box>
                <Formik onSubmit={handleSubmit} initialValues={{ account: "" }} enableReinitialize>
                    {(form) => (
                        <Form className="full-height">
                            <Box display="flex" column fullHeight>
                                <Field
                                    name="account"
                                    component={SelectAccount}
                                    options={accounts.filter((account) => account.status === "ACTIVA")}
                                />
                                <Box display="flex" column fullWidth className="mt-auto">
                                    <Button
                                        label="wally.select.button.confirm"
                                        bsStyle="primary"
                                        block
                                        disabled={fetchingSubmit || !form?.values?.account}
                                        loading={fetchingSubmit}
                                        type="submit"
                                    />
                                    <Button
                                        label="wally.select.button.cancel"
                                        bsStyle="outline"
                                        disabled={fetchingSubmit}
                                        block
                                        onClick={handleCancel}
                                    />
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </PageLoading>
    );
};

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccountsForWally(state),
    fetching: accountsSelectors.getFetchingAccountsWally(state),
});

EnrrolmentWallySelectAccount.propTypes = {
    dispatch: func.isRequired,
    history: shape({}).isRequired,
    fetching: bool,
    accounts: arrayOf(shape({ length: number })),
};

EnrrolmentWallySelectAccount.defaultProps = { fetching: false, accounts: [] };

export default withRouter(connect(mapStateToProps)(resizableRoute(EnrrolmentWallySelectAccount)));
