import React, { Component } from "react";
import classNames from "classnames";
import { string, bool, shape, func, oneOfType, number } from "prop-types";
import { Col } from "react-bootstrap";
import Row from "pages/_components/Row";
import Box from "pages/_components/Box";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Checkbox from "pages/_components/Checkbox";

import * as dateUtils from "util/date";

class BitwiseDays extends Component {
    static propTypes = {
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: oneOfType([number, string]),
        }).isRequired,
        idForm: string.isRequired,
        form: shape({
            touched: shape({}),
            errors: shape({}),
        }).isRequired,
        hideLabel: bool,
        allDaysChecked: bool,
        onChange: func,
        disabled: bool,
    };

    static defaultProps = {
        hideLabel: false,
        allDaysChecked: false,
        onChange: () => {},
        disabled: false,
    };

    dayClicked = (day) => {
        const { field, form, onChange } = this.props;
        let newValue;
        /* eslint-disable no-bitwise */
        if (day & field.value) {
            newValue = field.value - day;
        } else {
            newValue = field.value + day;
        }
        /* eslint-enable no-bitwise */
        form.setFieldValue(field.name, newValue);
        onChange(newValue);
    };

    renderField = (name, value) => {
        const { allDaysChecked, field, disabled } = this.props;
        return (
            /* eslint-disable no-bitwise */
            <Checkbox
                checked={allDaysChecked || value & field.value}
                name={name}
                labelText={`administration.restrictions.days.${name}`}
                onChange={() => {
                    this.dayClicked(value);
                }}
                disabled={disabled}
            />
            /* eslint-enable no-bitwise */
        );
    };

    renderControl = () => (
        <>
            <Row>
                <Col xs={12} md={12} lg={6}>
                    <Box display="flex" column gap="3">
                        <div>{this.renderField("monday", dateUtils.MONDAY)}</div>
                        <div>{this.renderField("tuesday", dateUtils.TUESDAY)}</div>
                        <div>{this.renderField("wednesday", dateUtils.WEDNESDAY)}</div>
                        <div>{this.renderField("thursday", dateUtils.THURSDAY)}</div>
                    </Box>
                </Col>
                <Col xs={12} md={12} lg={6}>
                    <Box display="flex" column gap="3">
                        <div>{this.renderField("friday", dateUtils.FRIDAY)}</div>
                        <div>{this.renderField("saturday", dateUtils.SATURDAY)}</div>
                        <div>{this.renderField("sunday", dateUtils.SUNDAY)}</div>
                    </Box>
                </Col>
            </Row>
        </>
    );

    render() {
        const {
            field,
            form: { touched, errors },
            hideLabel,
            idForm,
        } = this.props;

        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={classNames("form-group", "form-group--image-selector", {
                    "has-error": hasError,
                })}>
                {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}

                {this.renderControl()}
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default BitwiseDays;
