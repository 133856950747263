const personalDataUpdateList = [
    {
        stepNumber: 1,
        label: "settings.options.updateUserData.forms.edit.generalInfo",
    },
    {
        stepNumber: 2,
        label: "settings.options.updateUserData.forms.edit.addressInfo",
    },
    {
        stepNumber: 3,
        label: "settings.options.updateUserData.forms.edit.employmentInfo",
    },
    {
        stepNumber: 4,
        label: "settings.options.updateUserData.forms.edit.contactInfo",
    },
    {
        stepNumber: 5,
        label: "settings.options.updateUserData.forms.edit.additionalInfo",
    },
    {
        stepNumber: 6,
        label: "settings.options.updateUserData.forms.edit.documents",
    },
];

export default personalDataUpdateList;
