import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { routerActions } from "react-router-redux/actions";
import Redirect from "react-router-dom/Redirect";
import { func, arrayOf, oneOf, oneOfType, shape, string, number } from "prop-types";

import { actions, selectors } from "reducers/administration/groups";
import { actions as formActions } from "reducers/form";

import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import { renderNameAction } from "../utils/groups";

class GroupActionConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        groupsToApplyAction: arrayOf(oneOfType([number, string])).isRequired,
        groupsNamesToApplyAction: arrayOf(string).isRequired,
        groupAction: oneOf(["block", "unblock", "delete"]).isRequired,
        idTransaction: string,
        idActivity: string,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    handleSubmit = (credentials, formikBag) => {
        const {
            groupsToApplyAction,
            groupsNamesToApplyAction,
            groupAction,
            idTransaction,
            idActivity,
            dispatch,
        } = this.props;

        const secondFactor = credentials;

        if (!idTransaction) {
            dispatch(
                actions.changeGroupsStatusConfirmation(
                    groupsToApplyAction,
                    groupsNamesToApplyAction,
                    groupAction,
                    secondFactor,
                    formikBag,
                ),
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, secondFactor, formikBag }));
        }
    };

    handleClose = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    render() {
        const { groupsToApplyAction, groupsNamesToApplyAction, groupAction, idActivity, } = this.props;

        if (!groupsToApplyAction || groupsToApplyAction.length === 0) {
            return <Redirect to="/administration/groups" />;
        }

        // const groupsText = groupsNamesToApplyAction.map((groupName) => (
        //     <span key={`administration.groups.${groupName}`}> {groupName} </span>
        // ));

        const titleKey = `administration.groups.action.title.${groupAction}`;

        return (
            <Fragment>
                <Notification scopeToShow="administration" />
                <Head title={titleKey} onBack={this.handleClose} textBack="administration.groups.returnToList" />
                <MainContainer>
                    <div className="above-the-fold">
                        <Container
                            className="container--layout items-center flex-grow-1 border-radius-lg p-7 mt-5 box-shadow-small background-white"
                            gridClassName="form-content">
                            <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2}>
                                <Text component="h4" semibold labelKey="administration.forms.confirm.credentials" />

                                {groupsNamesToApplyAction.length === 1 ? (
                                    <Box background="background-disabled" className="p-5 my-5" borderRadius="lg">
                                        {renderNameAction(groupAction, groupsNamesToApplyAction[0])}
                                    </Box>
                                ) : (
                                    <div className="transfer-block">
                                        <Box className="mt-4">
                                            <Text
                                                labelKey={`administration.groups.action.confirmation.${groupAction}`}
                                                light
                                            />
                                        </Box>
                                        <Box display="flex" gap="3" className="flex-wrap mt-5">
                                            {groupsNamesToApplyAction.map((groupName) => (
                                                <Box
                                                    key={`administration.groups.${groupName}`}
                                                    display="flex"
                                                    background="primary-background-color"
                                                    className="py-3 px-5 my-auto"
                                                    border="primary-focus-color"
                                                    borderRadius="xxl"
                                                    gap="3"
                                                    fitWidth>
                                                    <Text color="primary-active-color" size="6">
                                                        {groupName}
                                                    </Text>
                                                </Box>
                                            ))}
                                        </Box>
                                    </div>
                                )}
                            </Col>
                        </Container>
                        <AdministrationFormConfirmation
                            credentialGroups={[
                                // { credentials: ["accessToken"], idCredentialGroup: "accessToken" },
                                // { credentials: ["digitalKey"], idCredentialGroup: "digitalKey" },
                                { credentials: ["accessToken", "otp"], idCredentialGroup: "accessToken-otp" },
                            ]}
                            onSubmit={this.handleSubmit}
                            cancelUrl="/administration/groups"
                            idActivity={idActivity}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    groupAction: selectors.getGroupAction(state),
    groupsToApplyAction: selectors.getGroupsToApplyAction(state),
    groupsNamesToApplyAction: selectors.getGroupsNamesToApplyAction(state),
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
});

export default connect(mapStateToProps)(GroupActionConfirmation);
