import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import {
    actions as updateUserDataActions,
    selectors as updateUserDataSelectors,
} from "reducers/updateUserData/updateUserData.reducer";
import { compose } from "redux";
import { push } from "react-router-redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { useLoadingGlobalProvider } from "providers/LoadingGlobalProvider";
import classNames from "classnames";

const FORM_ID = "settings.personalDataUpdateStep5";

const PersonalDataUpdateStep5 = ({
    isSubmitting,
    isFetching,
    isDesktop,
    dispatch,
    exchangeToken,
    currentUserData,
    values,
    setFieldValue,
    preDataStep5,
}) => {
    // Section 1
    const [isUSCitizenQ1, setIsUSCitizenQ1] = useState();
    const [countryNameQ2, setCountryNameQ2] = useState();
    const [isDiffResidenceQ2, setIsDiffResidenceQ2] = useState();
    const [isPEPQ3, setIsPEPQ3] = useState();
    const [isPEPFamilyQ4, setIsPEPFamilyQ4] = useState();

    const { setLoading } = useLoadingGlobalProvider();

    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);

    useEffect(() => {
        dispatch(updateUserDataActions.preFormStep5(exchangeToken));
    }, [dispatch]);

    useEffect(() => {
        if (!isFetching && currentUserData) {
            const { US183Permanence } = currentUserData.FATCA;
            const usResident = currentUserData.residenceCountry === "US";
            const isSelectedQ1 = US183Permanence || usResident;
            setIsUSCitizenQ1(isSelectedQ1);
            setFieldValue("isUSCitizenQ1", isSelectedQ1);
            setIsDiffResidenceQ2(false);
            setFieldValue("isDiffResidenceQ2", false);

            const { GovRelated, IsPEP, Family, Collaborator } = currentUserData.PEPData;
            const isSelectedQ3 = GovRelated || IsPEP || Collaborator;
            setIsPEPQ3(isSelectedQ3);
            setIsPEPFamilyQ4(Family);

            setFieldValue("isPEPQ3", isSelectedQ3);
            setFieldValue("isPEPFamilyQ4", Family);
        }
    }, []);

    useEffect(() => {
        if (!isFetching && preDataStep5) {
            const { countryName } = preDataStep5;
            setCountryNameQ2(countryName);
            setFieldValue("acceptConditions", false);
        }
    }, [preDataStep5]);

    const handleQuestion = (questionId, valueSelected) => {
        if (questionId === 1) {
            setFieldValue("isUSCitizenQ1", valueSelected);
            setIsUSCitizenQ1(valueSelected);
        } else if (questionId === 2) {
            setFieldValue("isDiffResidenceQ2", valueSelected);
            setIsDiffResidenceQ2(valueSelected);
        } else if (questionId === 3) {
            setFieldValue("isPEPQ3", valueSelected);
            setIsPEPQ3(valueSelected);
        } else if (questionId === 4) {
            setFieldValue("isPEPFamilyQ4", valueSelected);
            setIsPEPFamilyQ4(valueSelected);
        }
    };

    const handleCancel = () => {
        dispatch(push("/desktop"));
    };

    const aditionalDataList = [
        { id: 1, name: "isUSCitizenQ1", label: "question1.label", validation: isUSCitizenQ1 },
        {
            id: 2,
            name: "isDiffResidenceQ2",
            label: "question2.label",
            textParams: { COUNTRY_PLACEHOLDER: countryNameQ2 ? `${countryNameQ2}` : "" },
            validation: isDiffResidenceQ2,
        },
        { id: 3, name: "isPEPQ3", label: "question3.label", validation: isPEPQ3 },
        { id: 4, name: "isPEPFamilyQ4", label: "question4.label", validation: isPEPFamilyQ4 },
    ];

    return (
        <>
            <Notification scopeToShow="personalDataUpdate" />
            <Form className="mx-n-5">
                <Box
                    display="flex"
                    column
                    fullWidth
                    className="px-md-12 pt-3 pt-md-8 pb-md-8 mb-md-5"
                    {...(isDesktop && { background: "component-background", borderRadius: "default" })}>
                    <Box display="flex" column className="px-0">
                        {aditionalDataList.map((data) => (
                            <Box
                                key={data.id}
                                display="flex"
                                {...(isDesktop
                                    ? { alignX: "between", alignY: "center" }
                                    : {
                                          column: true,
                                          alignY: "between",
                                          alignX: "center",
                                          gap: "5",
                                          background: "component-background",
                                          borderRadius: "xxl",
                                      })}
                                className={classNames("mb-3 mb-md-9", { "p-5": !isDesktop })}
                                fullWidth>
                                <Text
                                    labelKey={`${FORM_ID}.${data.label}`}
                                    {...(data.textParams && { textParams: data.textParams })}
                                    className="mr-md-7"
                                />
                                <Box
                                    display="flex"
                                    {...(isDesktop
                                        ? { alignX: "between" }
                                        : { alignX: "flex-end", className: "ml-auto max-width-half" })}
                                    gap={isDesktop ? "7" : "5"}>
                                    <Button
                                        bsStyle="chip"
                                        className={classNames("full-width", {
                                            "is-active show-active-check-mobile": !data.validation,
                                            "btn-height-8": !isDesktop,
                                        })}
                                        label="global.no"
                                        image={!data.validation ? "images/check.svg" : ""}
                                        onClick={() => handleQuestion(data.id, false)}
                                    />
                                    <Button
                                        bsStyle="chip"
                                        className={classNames("full-width", {
                                            "is-active show-active-check-mobile": data.validation,
                                            "btn-height-8": !isDesktop,
                                        })}
                                        label="global.yes"
                                        image={data.validation ? "images/check.svg" : ""}
                                        onClick={() => handleQuestion(data.id, true)}
                                    />
                                </Box>
                                <input type="hidden" name={data.name} value={data.validation} />
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box
                    display="flex"
                    column
                    fullWidth
                    background="component-background"
                    className="px-5 px-md-12 py-8 mb-5 mb-md-8"
                    borderRadius="default">
                    <Field
                        name="acceptConditions"
                        idForm={FORM_ID}
                        labelText={`${FORM_ID}.conditions.label`}
                        component={Checkbox}
                        formGroup
                    />
                </Box>

                <Row className="px-5 px-md-0" {...(!isDesktop && { gapY: "3" })}>
                    <Col xs={12} md={4} mdOffset={2}>
                        <Button label="global.cancel" bsStyle="outline" onClick={handleCancel} block />
                    </Col>
                    <Col xs={12} md={4} {...(!isDesktop && { className: "grid-reversed" })}>
                        <Button
                            disabled={!values.acceptConditions}
                            label="global.continue"
                            loading={isSubmitting || isFetching}
                            type="submit"
                            bsStyle="primary"
                            block
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

PersonalDataUpdateStep5.propTypes = {
    dispatch: func.isRequired,
    isFetching: bool,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    exchangeToken: string.isRequired,
    currentUserData: shape({}),
    values: shape({}).isRequired,
    setFieldValue: func.isRequired,
    preDataStep5: shape({}),
};

PersonalDataUpdateStep5.defaultProps = {
    isFetching: false,
    currentUserData: null,
    preDataStep5: null,
};

const mapStateToProps = (state) => ({
    isFetching: updateUserDataSelectors.isFetching(state),
    exchangeToken: updateUserDataSelectors.getExchangeToken(state),
    currentUserData: updateUserDataSelectors.getCurrentUserData(state),
    preDataStep5: updateUserDataSelectors.getPreDataStep5(state),
});

export default compose(
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            isUSCitizenQ1: false,
            isDiffResidenceQ2: false,
            isPEPQ3: false,
            isPEPFamilyQ4: false,
            acceptConditions: false,
        }),
        validationSchema: () =>
            Yup.object().shape({
                acceptConditions: Yup.boolean().oneOf([true], i18n.get(`${FORM_ID}.conditions.required`)),
            }),
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(updateUserDataActions.saveForm(values, 5, formikBag));
        },
    }),
)(PersonalDataUpdateStep5);
