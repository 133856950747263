import * as API from "middleware/api";

// upload file in base64 format
export const upload = (fileToSave, fileName, fileDescription, fileIdActivity) =>
    API.executeWithAccessToken("/files.save", { fileToSave, fileName, fileDescription, fileIdActivity });

// returns base64 string
export const download = (idFile) => API.executeWithAccessToken("/files.download", { idFile });

// returns base64 string
export const downloadLines = (idTransactionToRead, filters, format) =>
    API.executeWithAccessToken("/files.downloadLines", { idTransactionToRead, ...filters, format });

// returns base64 string
export const downloadFilelink = (idFile) => API.executeWithAccessToken("/core.forms.filelink.download", { idFile });

// returns base64 string
export const downloadMultilineErrorsFile = (idTransactionToRead) =>
    API.executeWithAccessToken("/files.download.multiline.errors", { idTransactionToRead });

export const deleteFile = (idFile) => API.executeWithAccessToken("/files.delete", { idFile });

export const downloadStream = (idFile, fileName) =>
    API.downloadWithAccessToken("/files.downloadStream", idFile, fileName);

export const listTransactionLines = ({ filters, ...data }) =>
    API.executeWithAccessToken("/files.list.transaction.lines", { ...data, ...filters });
