import classNames from "classnames";
import Box from "pages/_components/Box";
import ShortCutBtn from "pages/_components/ShortCutBtn";
import { array, bool, oneOfType, shape, string } from "prop-types";
import React from "react";
import * as i18n from "util/i18n";

const ShortCuts = ({ isDesktop, className, dashboard, options, inverse, isStepZero }) => (
    <Box
        component="nav"
        // TODO (LABEL a11y)
        ariaLabel={`${i18n.get("desktop.widgets.short.cuts")} - navegación secundaria`}
        id="shortCuts"
        className={classNames(className, {
            "widget short-cuts-widget": dashboard,
            inverse,
            isStepZero,
        })}
        display="flex"
        column
        alignY="center">
        <Box component="ul" className="short-cuts-wrapper" display="flex" fullWidth flex1 alignX="between">
            {options.map((option) => (
                <ShortCutBtn
                    key={option.label}
                    image={!isDesktop && option.image.mobile ? option.image.mobile : option.image.desktop}
                    label={option.label}
                    href={option.href}
                    onClick={option.click}
                    isDesktop={isDesktop}
                    isStepZero={isStepZero}
                />
            ))}
        </Box>
    </Box>
);

ShortCuts.propTypes = {
    options: oneOfType([
        shape({
            image: shape({}),
            href: string,
            label: string,
        }),
        array,
    ]).isRequired,
    isDesktop: bool,
    className: string,
    dashboard: bool,
    inverse: string,
    isStepZero: bool,
};

ShortCuts.defaultProps = {
    className: "",
    isDesktop: false,
    dashboard: false,
    inverse: "",
    isStepZero: false,
};

export default ShortCuts;
