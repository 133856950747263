import React, { useState } from "react";
import { func } from "prop-types";
import PageLoading from "pages/_components/PageLoading";
import { isDesktop } from "react-device-detect";
import FlowTransition from "pages/_components/FlowTransition";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as i18n from "util/i18n";
import { actions as sessionActions } from "reducers/session";
import ExitModal from "pages/_components/modal/ExitModal";
import UnlockDeviceQuestion from "./UnlockDeviceQuestion";
import UnlockDeviceSecondFactor from "./UnlockDeviceSecondFactor";
import UnlockDeviceSuccess from "./UnlockDeviceSuccess";

const steps = {
    question: "question",
    secondFactor: "secondFactor",
    success: "success",
};

const FORM_ID = "unlockDevice";

const UnlockDeviceFlow = ({ dispatch }) => {
    const [currentStep, setCurrentStep] = useState([steps.secondFactor]);
    const [showExitModal, setShowExitModal] = useState(false);

    const goToHome = () => {
        dispatch(push("/settings/authenticatorHandler"));
    };

    const goToDesktop = () => {
        dispatch(push("/desktop"));
    };

    const handleAcceptModal = () => {
        goToHome();
        setShowExitModal(false);
    };

    const handleHideModal = () => {
        setShowExitModal(false);
    };

    const handleCancel = () => {
        setShowExitModal(true);
    };

    const handleContinueQuestion = () => {
        setCurrentStep(steps.secondFactor);
    };

    const onBack = () => {
        goToHome();
    };

    const onSuccess = () => {
        setCurrentStep(steps.success);
    };

    return (
        <PageLoading loading={false}>
            <FlowTransition
                currentPage={currentStep}
                pages={{
                    [steps.question]: () => (
                        <UnlockDeviceQuestion
                            formId={FORM_ID}
                            isDesktop={isDesktop}
                            handleSubmit={handleContinueQuestion}
                            handleCancel={handleCancel}
                            goToHome={goToHome}
                            goToDesktop={goToDesktop}
                        />
                    ),
                    [steps.secondFactor]: () => (
                        <UnlockDeviceSecondFactor
                            formId={FORM_ID}
                            isDesktop={isDesktop}
                            handleBack={onBack}
                            handleCancel={handleCancel}
                            onSuccess={onSuccess}
                            goToHome={goToHome}
                            goToDesktop={goToDesktop}
                        />
                    ),
                    [steps.success]: () => (
                        <UnlockDeviceSuccess
                            formId={FORM_ID}
                            isDesktop={isDesktop}
                            goToHome={goToHome}
                            goToDesktop={goToDesktop}
                        />
                    ),
                }}
            />
            <ExitModal
                modalShow={showExitModal}
                acceptFunction={handleAcceptModal}
                cancelFunction={handleHideModal}
                headingText={i18n.get("confirm.exit.title")}
                text={i18n.get("confirm.exit.info")}
            />
        </PageLoading>
    );
};

UnlockDeviceFlow.propTypes = {
    dispatch: func.isRequired,
};

export default connect()(UnlockDeviceFlow);
