import React from "react";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";

const TransactionHeader = () => (
    <Box className="movs-header-cols">
        <Box className="p-5">
            <Text labelKey="tableHeader.transaction.order" size="6" />
        </Box>
        <Box className="p-5">
            <Text labelKey="tableHeader.transaction.type" size="6" />
        </Box>
        <Box className="p-5">
            <Text labelKey="tableHeader.transaction.dateAndHour" size="6" />
        </Box>
        <Box className="p-5">
            <Text labelKey="tableHeader.transaction.createdBy" size="6" />
        </Box>
        <Box className="p-5" display="flex">
            <Text labelKey="tableHeader.state" size="6" />
        </Box>
        <Box className="p-5 justify-content-flex-end" display="flex">
            <div>
                <Text labelKey="tableHeader.amountUsd" size="6" />
            </div>
        </Box>
    </Box>
);

export default TransactionHeader;
