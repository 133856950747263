import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, shape } from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import withRouter from "react-router-dom/withRouter";

const ServiceCategoryCard = ({ isDesktop, elem }) => (
    <Link
        aria-label={elem.logo}
        aria-describedby={elem.logo}
        to={`/addNewService/company/${elem.idServicePayment}`}
        //  TODO (onClick)
        onClick={() => {}}>
        <Box
            component="article"
            display="flex"
            alignY="center"
            background="white"
            backgroundHover="background-secondary"
            borderRadius="default"
            className="py-4 py-md-5 px-5"
            gap="5"
            pointer>
            <Box display="flex">
                <Image
                    src={`images/icons/servicesCategories/${elem.logo}.svg`}
                    {...(!isDesktop && { width: "9", height: "9" })}
                />
            </Box>
            <Text color="secondary" size="2" bold labelKey={`servicePayment.category.${elem?.idServicePayment}`} />
        </Box>
    </Link>
);

ServiceCategoryCard.propTypes = {
    isDesktop: bool.isRequired,
    elem: shape({}),
};

ServiceCategoryCard.defaultProps = {
    elem: null,
};

export default withRouter(resizableRoute(ServiceCategoryCard));
