import { node, string } from "prop-types";
import React, { Component } from "react";

import Image from "pages/_components/Image";

class ProductTag extends Component {
    static propTypes = {
        alias: string.isRequired,
        children: node,
        icon: string,
        name: string,
    };

    static defaultProps = {
        children: undefined,
        icon: null,
        name: "",
    };

    render() {
        const { icon, alias, name, children } = this.props;

        return (
            <div className="newProduct display-inline-i">
                <div className="newProduct-row display-inline">
                    {icon && (
                        <div className="newProduct-cell newProduct-cell--icon">
                            <Image src={`images/${icon}.svg`} className="svg-icon" />
                        </div>
                    )}
                    <div className="newProduct-cell newProduct-cell--ellipsis display-inline">
                        <span className="data-name display-inline">{alias}</span>
                        <span className="data-product">{name}</span>
                    </div>
                </div>
                {children}
            </div>
        );
    }
}

export default ProductTag;
