import Box from "pages/_components/Box";
import Compose from "pages/communications/_components/Compose";
import Reader from "pages/communications/_components/Reader";
import { bool, func, oneOf, shape } from "prop-types";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { selectors as communicationSelectors } from "reducers/communication";
import { selectors as communicationsSelectors } from "reducers/communications";

class LeftPanel extends Component {
    static propTypes = {
        close: func.isRequired,
        handleMessageModalClose: func,
        isDesktop: bool.isRequired,
        leftPanel: oneOf(["compose", "reply", "read"]),
        preloadedMessage: shape({}),
    };

    static defaultProps = {
        handleMessageModalClose: null,
        leftPanel: null,
        preloadedMessage: {},
    };

    state = {
        replyModalShow: false,
    };

    handleReplyModalShow = () => {
        this.setState({
            replyModalShow: true,
        });
    };

    render() {
        const { close, handleMessageModalClose, isDesktop, leftPanel, preloadedMessage } = this.props;
        const { replyModalShow } = this.state;
        switch (leftPanel) {
            case "compose":
                return <Compose preloadedMessage={preloadedMessage} isDesktop={isDesktop} onCloseClick={close} />;
            case "reply":
                return (
                    <Box position="relative" fullHeight>
                        <Reader isDesktop={isDesktop} isReply handleMessageModalClose={handleMessageModalClose} />
                        {isDesktop ? (
                            <Compose isDesktop={isDesktop} isReply onCloseClick={close} />
                        ) : (
                            <Modal show={replyModalShow}>
                                <Compose isDesktop={isDesktop} isReply onCloseClick={close} />
                            </Modal>
                        )}
                    </Box>
                );
            case "read":
                return (
                    <Reader
                        isDesktop={isDesktop}
                        handleMessageModalClose={handleMessageModalClose}
                        {...(!isDesktop && { handleReplyModalShow: this.handleReplyModalShow })}
                    />
                );
            default:
                return null;
        }
    }
}

const mapStateToProps = (state) => ({
    leftPanel: communicationsSelectors.leftPanel(state),
    preloadedMessage: communicationSelectors.getPreloadedMessage(state),
});
export default connect(mapStateToProps)(LeftPanel);
