import { persistReducer } from "redux-persist";
import { createReducer, makeActionCreator } from "util/redux";
import storage from "redux-persist/lib/storage";

export const types = {
    MODAL_SHOW: "changeStatusProduct/MODAL_SHOW",
    MODAL_CLOSE: "changeStatusProduct/MODAL_CLOSE",
    CREDENTIALS_GROUPS_REQUEST: "changeStatusProduct/CREDENTIALS_GROUPS_REQUEST",
    CREDENTIALS_GROUPS_SUCCESS: "changeStatusProduct/CREDENTIALS_GROUPS_SUCCESS",
    CHANGE_PRODUCT_STATE_REQUEST: "changeStatusProduct/CHANGE_PRODUCT_STATE_REQUEST",
};

export const INITIAL_STATE = {
    displayModal: false,
    values: {},
    credentialsGroups: [],
    hasCompleteCredentialGroups: false,
};

const reducer = createReducer(INITIAL_STATE, {
    [types.MODAL_SHOW]: (state, action) => ({ ...state, displayModal: true, values: action.values }),
    [types.MODAL_CLOSE]: (state) => ({ ...state, displayModal: false }),
    [types.CREDENTIALS_GROUPS_SUCCESS]: (state, action) => ({
        ...state,
        credentialsGroups: action.credentialsGroups,
        hasCompleteCredentialGroups: true,
    }),
    [types.CREDENTIALS_GROUPS_REQUEST]: (state) => ({ ...state, hasCompleteCredentialGroups: false }),
});

export const actions = {
    modalShow: makeActionCreator(types.MODAL_SHOW, "values"),
    modalHide: makeActionCreator(types.MODAL_CLOSE),
    credentialsGroupsRequest: makeActionCreator(types.CREDENTIALS_GROUPS_REQUEST, "data"),
    credentialsGroupsSuccess: makeActionCreator(types.CREDENTIALS_GROUPS_SUCCESS, "credentialsGroups"),
    changeProductStatusRequest: makeActionCreator(types.CHANGE_PRODUCT_STATE_REQUEST, "data"),
};
export default persistReducer(
    {
        storage,
        key: "changeStatusProduct",
        blacklist: ["displayModal"],
    },
    reducer,
);

export const selectors = {
    getDisplayModal: ({ changeStatusProduct }) => changeStatusProduct.displayModal,
    getDataModal: ({ changeStatusProduct }) => changeStatusProduct.values,
    getCredentialsGroups: ({ changeStatusProduct }) => changeStatusProduct.credentialsGroups,
    hasCompleteCredentialGroups: ({ changeStatusProduct }) => changeStatusProduct.hasCompleteCredentialGroups,
};
