import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import { arrayOf, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";

class GroupsOfUserTicket extends Component {
    static propTypes = {
        actions: shape({
            loadGroupsOfUserTicketRequest: func.isRequired,
        }).isRequired,
        match: shape({
            params: shape({
                idTransaction: string.isRequired,
            }).isRequired,
        }).isRequired,
        groups: arrayOf(
            shape({
                name: string.isRequired,
                idGroupAsString: string.isRequired,
            }),
        ).isRequired,
        user: shape({
            firstName: string.isRequired,
            lastName: string.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadGroupsOfUserTicketRequest(match.params.idTransaction);
    }

    render() {
        const { groups, user } = this.props;
        const groupText = groups.map(({ name, idGroupAsString }) => <div key={idGroupAsString}> {name} </div>);

        return (
            <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                    <div className="transfer-block">
                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper"
                            label="administration.signature.user"
                            data={`${user.firstName} ${user.lastName}`}
                        />
                        <div className="transfer-data transfer-data-flow">
                            <Container.ColumnBody columnClass="list-wrapper">
                                <div className="transfer-block">
                                    <h4>
                                        <I18n id="administration.users.detail.configureGroups.confirm" />
                                    </h4>
                                    {groupText}
                                </div>
                            </Container.ColumnBody>
                        </div>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default GroupsOfUserTicket;
