import { Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import EnvironmentTag from "pages/_components/EnvironmentTag";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import CalendarRestriction from "pages/administration/restrictions/CalendarRestriction";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import {
    INITIAL_STATE,
    actions as restrictionActions,
    selectors as restrictionSelectors,
} from "reducers/administration/restrictions";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import * as maskUtils from "util/mask";
import * as Yup from "yup";
// NO BORRAR (IMPLEMENTACION PRODUCTO)
// import IPRestriction from "pages/administration/restrictions/IPRestriction";

const isCalendarToSave = (values) =>
    INITIAL_STATE.days !== values.days ||
    INITIAL_STATE.startTime !== values.startTime ||
    INITIAL_STATE.endTime !== values.endTime ||
    INITIAL_STATE.perpetual !== values.perpetual ||
    INITIAL_STATE.timeZone !== values.timeZone;

class Restrictions extends Component {
    static propTypes = {
        days: number.isRequired,
        dispatch: func.isRequired,
        activeEnvironmentId: number.isRequired,
        perpetual: bool.isRequired,
        idUser: string,
        values: shape({}),
        history: shape({}).isRequired,
        timeZones: arrayOf(string),
        fetching: bool.isRequired,
        isFirstFetching: bool.isRequired,
        activeEnvironment: shape({}).isRequired,
        userName: string,
        restrictions: shape({}).isRequired,
    };

    static defaultProps = {
        idUser: undefined,
        values: {},
        timeZones: [],
        userName: "",
    };

    componentDidMount() {
        this.preLoadInfo();
    }

    componentDidUpdate(prevProps) {
        const { idUser } = this.props;
        if (prevProps.idUser !== idUser) {
            this.preLoadInfo();
        }
    }

    shouldActivateSaveButton = () => {
        const { values } = this.props;
        return !isCalendarToSave(values) && values.idUser && values.ipsList.length === 0 && values.anyIP === false;
    };

    onCancelClick = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    preLoadInfo() {
        const { dispatch, timeZones, idUser } = this.props;
        dispatch(restrictionActions.setInitialState());

        if (timeZones.length === 0) {
            dispatch(restrictionActions.manageRestrictionsPre());
        }
        dispatch(restrictionActions.getRestrictionsAvailability());
        if (typeof idUser === "undefined") {
            dispatch(restrictionActions.getEnvironmentRestrictionsData());
        } else {
            dispatch(restrictionActions.getUserRestrictionsData(idUser));
        }
    }

    render() {
        const { idUser, fetching, activeEnvironment, userName, isFirstFetching } = this.props;
        const isEnvironmentRestrictions = typeof idUser === "undefined";

        return (
            <Fragment>
                <Notification scopeToShow="restrictions" />

                <Head
                    title={
                        isEnvironmentRestrictions
                            ? "administration.restrictions.environmentRestrictions.title"
                            : "administration.restrictions.userRestrictions.title"
                    }
                    onBack={this.handleBack}
                />
                <MainContainer showLoader={fetching && isFirstFetching}>
                    <Form>
                        {isEnvironmentRestrictions && (
                            <Box
                                display="flex"
                                background="component-background"
                                borderRadius="default"
                                className="pl-8 pr-5 py-7 mb-5">
                                <EnvironmentTag name={activeEnvironment.name} type={activeEnvironment.type} />
                            </Box>
                        )}
                        {!isEnvironmentRestrictions && (
                            <Box
                                display="flex"
                                alignY="center"
                                background="component-background"
                                borderRadius="default"
                                className="pl-8 pr-5 py-7 mb-5"
                                gap="2">
                                <Box display="flex">
                                    <Image src="images/icons/enviroment-retail.svg" />
                                </Box>
                                <Text>{userName}</Text>
                            </Box>
                        )}

                        <Box
                            display="flex"
                            alignY="center"
                            background="component-background"
                            borderRadius="default"
                            className="mb-9"
                            gap="2">
                            <CalendarRestriction {...this.props} idUser={idUser} />
                        </Box>
                        {/* NO BORRAR (IMPLEMENTACION PRODUCTO) */}
                        {/* <IPRestriction {...this.props} idUser={idUser} /> */}

                        <Row>
                            <Col xs={3} xsOffset={3}>
                                <Button
                                    bsStyle="outline"
                                    className="btn btn-outline "
                                    label="global.cancel"
                                    onClick={this.onCancelClick}
                                    block
                                />
                            </Col>
                            <Col xs={3}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    type="submit"
                                    disabled={this.shouldActivateSaveButton()}
                                    block
                                    loading={fetching}
                                />
                            </Col>
                        </Row>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, { match }) => ({
    days: restrictionSelectors.getDays(state),
    startTime: restrictionSelectors.getStartTime(state),
    endTime: restrictionSelectors.getEndTime(state),
    timeZone: restrictionSelectors.getTimeZone(state),
    perpetual: restrictionSelectors.getPerpetual(state),
    ipsList: restrictionSelectors.getIpsList(state),
    restrictions: restrictionSelectors.getRestrictions(state),
    timeZones: restrictionSelectors.getTimezones(state),
    fetching: restrictionSelectors.getFetching(state),
    isFirstFetching: restrictionSelectors.getFirstFetch(state),
    anyIP: restrictionSelectors.getAnyIP(state),
    calendarRestrictionId: restrictionSelectors.getCalendarRestrictionId(state),
    activeEnvironmentId: sessionSelectors.getActiveEnvironment(state).id,
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    idUser: match.params.userId,
    calendarEnabled: restrictionSelectors.getCalendarEnabled(state),
    iPEnabled: restrictionSelectors.getIPEnabled(state),
    userName: restrictionSelectors.getUserName(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: (props) => {
            const { userId } = props.match.params;
            const allDays = props.days === 127;

            return {
                days: props.days,
                startTime: props.startTime,
                endTime: props.endTime,
                timeZone: props.timeZone,
                perpetual: props.perpetual,
                ipsList: props.ipsList,
                anyIP: props.anyIP,
                calendarEnabled: props.calendarEnabled,
                iPEnabled: props.iPEnabled,
                idUser: userId,
                allDays,
            };
        },
        validationSchema: () => {
            const EMPTY_FIELD_ERROR = "administration.restrictions.field.error.empty";
            return Yup.lazy((values) => {
                const calendarToSave = isCalendarToSave(values);
                const shouldValidateCalendarFields =
                    values.calendarEnabled &&
                    !values.perpetual &&
                    !values.disableCalendar &&
                    !values.allDays &&
                    calendarToSave;

                return Yup.object().shape({
                    days: shouldValidateCalendarFields
                        ? Yup.number()
                              .moreThan(0, i18nUtils.get(EMPTY_FIELD_ERROR))
                              .required(i18nUtils.get(EMPTY_FIELD_ERROR))
                        : Yup.number(),
                    timeZone: shouldValidateCalendarFields
                        ? Yup.string()
                              .required(i18nUtils.get(EMPTY_FIELD_ERROR))
                              .nullable()
                        : Yup.string().nullable(),
                    startTime: shouldValidateCalendarFields
                        ? Yup.string()
                              .required(i18nUtils.get(EMPTY_FIELD_ERROR))
                              .matches(
                                  new RegExp(maskUtils.timeRegex(), "g"),
                                  i18nUtils.get("administration.restrictions.error.startTime.invalid"),
                              )
                        : Yup.string().notRequired(),
                    endTime: shouldValidateCalendarFields
                        ? Yup.string()
                              .required(i18nUtils.get(EMPTY_FIELD_ERROR))
                              .matches(
                                  new RegExp(maskUtils.timeRegex(), "g"),
                                  i18nUtils.get("administration.restrictions.error.endTime.invalid"),
                              )
                        : Yup.string().notRequired(),
                });
            });
        },
        handleSubmit: (fields, formikBag) => {
            const { dispatch, calendarRestrictionId, activeEnvironmentId, calendarEnabled } = formikBag.props;
            const calendarToSave = isCalendarToSave(fields);
            let actionRestrinction = !calendarEnabled ? "Save" : "Modify";
            actionRestrinction = !fields.calendarEnabled ? "Delete" : actionRestrinction;
            const dataToSave = {
                ...fields,
                calendarRestrictionId,
                calendarToSave,
                environmentId: activeEnvironmentId,
                days: fields.allDays ? 127 : fields.days,
                actionRestrinction,
            };
            if (dataToSave.idUser) {
                delete dataToSave.calendarEnabled;
                delete dataToSave.iPEnabled;
                dataToSave.isEnvironmentRestrictions = false;
            } else {
                dataToSave.perpetual = false;
                dataToSave.isEnvironmentRestrictions = true;
            }
            dispatch(restrictionActions.manageRestrictionsConfirmPre(dataToSave, formikBag));
        },
    }),
)(Restrictions);
