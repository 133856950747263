import { resizableRoute } from "pages/_components/Resizable";
import React from "react";
import withRouter from "react-router-dom/withRouter";
import Row from "pages/_components/Row";
import { bool, shape, arrayOf, string } from "prop-types";
import TextField from "pages/_components/fields/TextField";
import { Field } from "formik";
import Selector from "pages/_components/fields/formik/Selector";
import { Col } from "react-bootstrap";
import * as i18n from "util/i18n";

const DinamicForm = ({ idForm, fields, disabled }) => {
    const components = [];

    fields.forEach((field) => {
        switch (field?.fieldType) {
            case "Date":
                break;

            case "ListBox":
                components.push(
                    <Row key={`row-${field?.fieldId}`}>
                        <Col xs={12} md={6}>
                            <Field
                                component={Selector}
                                idForm={idForm}
                                name={field?.fieldId}
                                placeholder={i18n.get("forms.placeholder.select")}
                                labelText={field?.fieldLabel}
                                options={field?.listValues}
                                renderAs="combo"
                                labelNoMarginTop
                                disabled={disabled}
                            />
                        </Col>
                    </Row>,
                );
                break;

            default:
                components.push(
                    <Row key={`row-${field?.fieldId}`}>
                        <Col xs={12} md={6}>
                            <Field
                                component={TextField}
                                idForm={idForm}
                                name={field?.fieldId}
                                hidePlaceholder
                                labelText={field?.fieldLabel}
                                type={field?.fieldType === "String" ? "text" : "number"}
                                labelNoMarginTop
                                disabled={disabled}
                            />
                        </Col>
                    </Row>,
                );
                break;
        }
    });
    return components;
};

DinamicForm.propTypes = {
    idForm: string,
    isDesktop: bool.isRequired,
    fields: arrayOf(shape({})),
    disabled: bool,
};

DinamicForm.defaultProps = {
    fields: [],
    disabled: false,
};

export default withRouter(resizableRoute(DinamicForm));
