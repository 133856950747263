import React, { Component } from "react";
import { string, number as numberType, bool, func } from "prop-types";

import * as i18nUtils from "util/i18n";
// import * as dateUtils from "util/date";
import { push } from "react-router-redux";
import { connect } from "react-redux";
// import ListItem from "pages/_components/listItem/ListItem";
import SideBarModal from "pages/_components/modal/SideBarModal";
import DownloadAccoutDetailForm from "pages/forms/customForms/DownloadAccoutDetailForm";
import LoanCardView from "pages/loans/_components/LoanCardView";
import Box from "pages/_components/Box";
import { Link } from "react-router-dom";
import Info from "pages/_components/Info";
import { actions as loansActions } from "reducers/loans";
import {parseISOAlternative, formatDate }  from "util/date";

class LoansListItem extends Component {
    static propTypes = {
        alias: string,
        description: string,
        completeFavorites: bool,
        currentLang: string.isRequired,
        hideContextsOptions: bool,
        idProduct: string,
        isDesktop: bool.isRequired,
        nextDueDate: string.isRequired,
        number: string.isRequired,
        numberOfFees: numberType.isRequired,
        numberOfPaidFees: numberType.isRequired,
        overdueStatus: string,
        paidAmount: numberType.isRequired,
        push: func.isRequired,
        setFavoriteLoan: func,
        showLink: bool,
        showPayment: bool,
        showProductIcon: bool,
        showStar: bool,
        subType: string.isRequired,
        totalAmount: numberType.isRequired,
        productAlias: string,
        isWidgetDesktop: bool,
    };

    static defaultProps = {
        alias: null,
        description: null,
        completeFavorites: false,
        hideContextsOptions: false,
        idProduct: null,
        overdueStatus: "",
        setFavoriteLoan: () => {},
        showLink: true,
        showPayment: false,
        showProductIcon: true,
        showStar: false,
        productAlias: null,
        isWidgetDesktop: false,
    };

    state = {
        showAccountStatusModal: false,
    };

    componentDidMount() {
        const { dispatch, isWidgetDesktop } = this.props;
        dispatch(loansActions.setBackToDesktop(isWidgetDesktop));
    }

    getNextDueDate(nextDueDate){
        const parsedDate = parseISOAlternative(nextDueDate);
        return formatDate(parsedDate)
    }

    renderItem() {
        const GROUP_CODE_COMEXT = "COMEXT";
        const {
            idProduct,
            description,
            number,
            isDesktop,
            totalAmount,
            paidAmount,
            nextDueDate,
            currency,
            groupCode,
        } = this.props;
        const pendingBalance = totalAmount - paidAmount;
        const nextDueDatedFormatted  = this.getNextDueDate(nextDueDate);
        const labelTextDueDate = groupCode === GROUP_CODE_COMEXT
        ? i18nUtils.get("general.loans.dueDate")
        : i18nUtils.get("widgets.loans.dueDate");

        return (
            <Box display="flex" alignX="between" alignY="center" className="box-shadow-small border-radius-lg mb-4">
                <Link aria-describedby={idProduct} className="full-width border-radius-lg" to={`/loans/${idProduct}`}>
                    <Box
                        display={isDesktop ? "" : "grid"}
                        alignY="center"
                        fullWidth
                        className= {isDesktop ? "info-loan-list p-5 border-radius-lg info-loan-grid" : "item-account-list p-5 border-radius-lg justify-content-between"}
                        >
                        <Info
                            className={isDesktop ? "info-loan-list display-flex flex-column" : "info-account-list display-flex flex-column"}
                            labelText={description || "-"}
                            text={`${number}`}
                            
                        />
                        <Info
                             className={isDesktop ? "info-loan-list display-flex flex-column" : "info-account-list display-flex flex-column"}
                             labelText={labelTextDueDate}  
                            text={nextDueDatedFormatted}
                            
                        />
                        <Info
                            className={isDesktop ? "info-loan-list text-right display-flex flex-column" : "info-account-list display-flex flex-column"}
                            labelText={`${i18nUtils.get("loans.list.amount.pending")} ${currency}`}
                            amount={pendingBalance}
                            size={ !isDesktop  && 2}
                        />
                    </Box>
                </Link>
            </Box>
        );
    }

    render() {
        const {
            alias,
            completeFavorites,
            currentLang,
            dispatch,
            hideContextsOptions,
            idProduct,
            isDesktop,
            nextDueDate,
            number,
            numberOfFees,
            numberOfPaidFees,
            overdueStatus,
            paidAmount,
            setFavoriteLoan,
            showLink,
            showPayment,
            showProductIcon,
            showStar,
            subType,
            totalAmount,
            description,
            isWidgetDesktop,
            productAlias,
            ...props
        } = this.props;
        const reference = `${number}`;
        // const isExpired = dateUtils.isDateLessThanToday(nextDueDate);
        const pendingBalance = totalAmount - paidAmount;
        // const contextOptions = [
        //     {
        //         label: "contextMenu.loans.widget.options.account.status",
        //         onClick: () => {
        //             // dispatch(push("/websiteunderconstruction"));
        //             this.setState({ showAccountStatusModal: true });
        //         },
        //     },
        //     {
        //         label: "contextMenu.loans.widget.options.pay.service",
        //         onClick: () => {
        //             this.props.push(`/formCustom/payLoan?loan=${idProduct}`);
        //         },
        //     },
        // ];

        return (
            <>
                {!isWidgetDesktop && this.renderItem()}
                {isWidgetDesktop && (
                    <LoanCardView
                        {...props}
                        idProduct={idProduct}
                        productTypeTitle={description}
                        title={subType}
                        name={alias || description}
                        reference={reference}
                        amount={pendingBalance}
                        nextDueDate={nextDueDate}
                        amountLabel={i18nUtils.get("widgets.loans.pendingTotal")}
                        dueDateLabel={i18nUtils.get("widgets.loans.dueDate")}
                        path={showLink ? `/loans/${idProduct}` : null}
                    />
                )}

                <SideBarModal
                    show={this.state.showAccountStatusModal}
                    onClose={() => {
                        this.setState({ showAccountStatusModal: false });
                    }}
                    title="forms.product.state.download.title">
                    <DownloadAccoutDetailForm isDesktop={isDesktop} account={{ idProduct }} currentLang={currentLang} />
                </SideBarModal>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    push: (url) => dispatch(push(url)),
});

LoansListItem.propTypes = {
    dispatch: func.isRequired,
    push: func.isRequired,
};

export default connect(null, mapDispatchToProps)(LoansListItem);
