import React, { Component } from "react";
import { shape, string, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers/administration/common/administrationTicket";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18nUtils from "util/i18n";
import TicketContent from "pages/_components/TicketContent";
import { renderName, renderAdditionalData } from "pages/administration/utils/users";

class UserInviteTicket extends Component {
    static propTypes = {
        transactionData: shape({
            data: shape({
                signatureLevel: string,
                role: string,
                groupNames: arrayOf(string),
                firstName: string,
                lastName: string,
                email: string,
                mobilePhone: string,
            }),
        }).isRequired,
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
    };

    render() {
        const { transactionData } = this.props;

        if (transactionData.data) {
            const { data } = transactionData;

            const signatureLabel =
                data.signatureLevel === "N"
                    ? i18nUtils.get("administration.users.edit.signatureLevel.dontSign")
                    : data.signatureLevel;
            return (
                <TicketContent
                    head={renderName(`${data?.firstName} ${data?.lastName}`)}
                    transactionData={transactionData}>
                    {renderAdditionalData(
                        `${data?.docType} ${data?.docNumber}`,
                        data?.email,
                        data?.mobilePhone,
                        signatureLabel,
                        data.groups,
                    )}
                </TicketContent>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(UserInviteTicket);
