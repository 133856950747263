import CreditCardSlider from "pages/_components/CreditCardSlider";
import WidgetLoading from "pages/_components/WidgetLoading";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as desktopSelectors } from "reducers/desktop";
import { actions, selectors } from "reducers/widgets";
import * as i18nUtils from "util/i18n";
import WidgetsError from "pages/_components/WidgetsError/WidgetsError";
import DepositSlider from "pages/_components/DepositSlider";
import NoProduct from "./_components/NoProduct";

class DepositList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        children: func.isRequired,
        item: func,
        keyExtractor: func,
        name: string.isRequired,
        shouldMapList: bool,
        list: arrayOf(shape({})).isRequired,
        listLength: number,
        isFetching: bool,
        isEditable: bool,
        activeEnvironment: shape({}),
        hadError: bool,
    };

    static defaultProps = {
        shouldMapList: true,
        keyExtractor: () => {},
        item: null,
        isFetching: false,
        isEditable: false,
        listLength: 0,
        activeEnvironment: {},
        hadError: false,
    };

    componentDidMount() {
        const { dispatch, name } = this.props;
        dispatch(actions.listRequest(name));
    }

    refreshWidget = () => {
        const { dispatch, name } = this.props;
        dispatch(actions.listRequest(name));
    };

    renderItem = (item) => {
        const { item: Item, keyExtractor, list } = this.props;
        const numberElements = list.length;
        return <Item key={keyExtractor(item)} {...item} numberElements={numberElements} />;
    };

    validateWidgetList(isFetching, list) {
        const { name } = this.props;
        if (!isFetching && name === "deposits") {
            return <DepositSlider>{list.map(this.renderItem)}</DepositSlider>;
        }
        if (!isFetching && name !== "deposits") {
            return <CreditCardSlider>{list.map(this.renderItem)}</CreditCardSlider>;
        }
        return <WidgetLoading loading />;
    }

    renderList = () => {
        const { list, isFetching, name, shouldMapList, isEditable, hadError } = this.props;
        if (!shouldMapList) {
            return list;
        }

        const widgetList = this.validateWidgetList(isFetching, list);

        return (
            <WidgetLoading loading={!list.length && isFetching}>
                <div className={`table table--products mb-0 mb-3 ${name}`} aria-hidden={isEditable}>
                    <ul aria-label={i18nUtils.get(`widgets.list.title.${name}.a11y`)} className="table-body">
                        {hadError ? (
                            <>
                                <WidgetsError onClick={this.refreshWidget} name={name} />
                            </>
                        ) : (
                            widgetList
                        )}
                    </ul>
                </div>

                {list.length === 0 && <NoProduct text={`desktop.widgets.${name}.empty`} />}
            </WidgetLoading>
        );
    };

    render() {
        const { children, list, listLength } = this.props;
        const length = listLength || list.length;
        return children(this.renderList(), length);
    }
}

const mapStateToProps = (state, { name }) => {
    const { isFetching, data, hadError } = selectors.getWidget(state, name);
    let listData = data[name] === undefined ? [] : data[name];
    if (name === "creditLines") {
        listData = data.total === undefined ? [] : [data.total];
    }

    return {
        isFetching,
        list: listData,
        listLength: data?.total ? data.total : null,
        isEditable: desktopSelectors.getIsEditale(state),
        hadError,
    };
};

export default connect(mapStateToProps)(DepositList);
