import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { bool, node, string } from "prop-types";
import React, { useEffect } from "react";
import { isDesktop } from "react-device-detect";
import Slider from "react-slick";
import * as i18nUtils from "util/i18n";

const ArrowLeft = (props) => (
    <Button
        {...props}
        image="images/util/arrow-left.svg"
        bsStyle="link"
        aria-label={i18nUtils.get("global.prev.a11y")}
    />
);
const ArrowRight = (props) => (
    <Button
        {...props}
        image="images/util/arrow-right.svg"
        bsStyle="link"
        aria-label={i18nUtils.get("global.next.a11y")}
    />
);

const CardSlider = ({ children, className, showDots }) => {
    const sliderRef = React.useRef();

    const updateTabIndex = () => {
        if (sliderRef.current) {
            const slides = sliderRef.current.innerSlider.list.querySelectorAll(".slick-slide");

            slides.forEach((slide) => {
                const button = slide.querySelector("button");
                const switchToggle = slide.querySelector(".switch-toggle");
                const dropdown = slide.querySelector(".dropdown button");

                if (slide.getAttribute("aria-hidden") === "true") {
                    if (switchToggle) {
                        switchToggle.setAttribute("tabIndex", "-1");
                    }
                    if (button) {
                        button.setAttribute("tabIndex", "-1");
                    }
                    if (dropdown) {
                        dropdown.setAttribute("tabIndex", "-1");
                    }
                } else {
                    if (switchToggle) {
                        switchToggle.setAttribute("tabIndex", "0");
                    }
                    if (button) {
                        button.removeAttribute("tabIndex");
                    }
                    if (dropdown) {
                        dropdown.removeAttribute("tabIndex");
                    }
                }
            });
        }
    };

    const settings = {
        arrows: isDesktop,
        prevArrow: isDesktop && <ArrowLeft />,
        nextArrow: isDesktop && <ArrowRight />,
        infinite: false,
        dots: showDots,
        slidesToShow: 2,
        lazyLoad: false,
        slidesToScroll: 2,
        draggable: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        afterChange: updateTabIndex,
    };
    useEffect(updateTabIndex, []);
    const directChildrenCount = React.Children.count(children);
    return (
        <Box component="li" noList className={classNames("prod-widget-slider-dep fade-in", className)}>
            <Slider
                ref={sliderRef}
                {...settings}
                className={classNames({
                    "two-cards": directChildrenCount === 2,
                    "one-card": directChildrenCount === 1,
                })}>
                {children}
            </Slider>
        </Box>
    );
};

CardSlider.propTypes = {
    children: node.isRequired,
    className: string,
    showDots: bool,
};

CardSlider.defaultProps = {
    className: "",
    showDots: true,
};

export default CardSlider;
