import React from "react";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Button from "pages/_components/Button";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import * as i18n from "util/i18n";
import * as config from "util/config";
import { func, instanceOf, shape } from "prop-types";
import Selector from "pages/_components/fields/formik/Selector";
import TextField from "pages/_components/fields/TextField";

const FORM_ID = "creditCards.additional.filters";
const AdditionalsFilter = ({ initialData, handleFilter, handleCancel }) => {
    return (
        <Formik
            initialValues={{
                transactionType: initialData.transactionType,
                numberAdditional: initialData.number,
            }}
            onSubmit={(values) => {
                const { numberAdditional } = values;
                handleFilter(numberAdditional);
            }}>
            {(formik) => (
                <Form>
                    <Box column fullWidth className={classNames("mb-5")}>
                        <Row>
                            <Field
                                component={TextField}
                                labelText={i18n.get("creditCard.search.placeholder")}
                                name="numberAdditional"
                                placeholder={i18n.get("forms.transaction.ticket.number")}
                                type="number"
                            />
                        </Row>
                    </Box>

                    <Box display="flex" column fullWidth className={classNames("mb-5")}>
                        <Row className={classNames("px-0", { "d-flex flex-column-reverse": !isDesktop })}>
                            <Col xs={6}>
                                <Button
                                    type="button"
                                    label="global.cancel"
                                    bsStyle="outline"
                                    onClick={handleCancel}
                                    block
                                />
                            </Col>
                            <Col xs={6}>
                                <Button
                                    type="submit"
                                    label="product.filters.filter"
                                    bsStyle="primary"
                                    disabled={!formik.values.numberAdditional}
                                    block
                                />
                            </Col>
                        </Row>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

AdditionalsFilter.propTypes = {
    handleFilter: func.isRequired,
    handleCancel: func.isRequired,
};

export default AdditionalsFilter;
