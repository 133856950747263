import classNames from "classnames";
import Step2Content from "pages/login/_components/Step2Content";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import withExchangeToken from "pages/_components/withExchangeToken";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push, routerActions } from "react-router-redux/actions";
import { selectors as loginSelectors } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";

import { actions as notificationActions } from "reducers/notification";
// import * as i18n from "util/i18n";

// let stepsTour = [];
// let runTooltipTour = false;

const LoginStep2 = (props) => {
    const {
        dispatch,
        // firstLog,
        activeEnvironment,
        username,
        isDesktop,
    } = props;

    useEffect(() => {
        if (activeEnvironment) {
            dispatch(push("/desktop"));
            return;
        }
        if (!username) {
            dispatch(push("/"));
        }
        // stepsTour.push({
        //     selector: "figureId",
        //     title: "tooltipTour.login.securityImage.title",
        //     content: i18n.get("tooltipTour.login.securityImage.content"),
        // });
        // runTooltipTour = firstLog;
        // return () => {
        //     stepsTour = [];
        //     runTooltipTour = false;
        // }
    }, [activeEnvironment, username, dispatch]);

    const handleBack = () => {
        dispatch(routerActions.replace("/"));
        dispatch(notificationActions.removeNotification());
    };

    return (
        <>
            {/* <TooltipTour
                steps={stepsTour}
                run={runTooltipTour}
                showNavigation={false}
                showCloseButton={false}
                showNumber={false}
                closeWithMask={false}
                lastStepNextButton={
                    <Button className="btn btn-outline btn-small">
                        {i18n.get("tooltipTour.lastButton.label")}
                    </Button>
                }
            /> */}
            <Notification scopeToShow="loginStep2" />
            {/* {isInFlow && (
                <> */}
            {/* {!fromOnboardingLoginData ? (
                        <Head
                            onBack={handleBack}
                            {...(isDesktop && {
                                logo: <Logo isDesktop={isDesktop} className="svg-image logo pr-11" />,
                            })}
                            accessibilityTextId="login.step2.header.a11y"
                            flex
                        />
                    ) : (
                        <Head onBack={handleBack} logo={<Logo className="svg-image logo pr-11" />} flex />
                    )} */}
            <MainContainer className={classNames("main-container max-width-full", { "container-fluid": isDesktop })}>
                <Step2Content className={classNames({ "app-default-main": !isDesktop })} handleBack={handleBack} />
            </MainContainer>
            {/* </>
            )} */}
        </>
    );
};

LoginStep2.propTypes = {
    isDesktop: bool.isRequired,
    // isInFlow: bool.isRequired,
    dispatch: func.isRequired,
    // firstLog: bool.isRequired,
    username: string,
    activeEnvironment: shape({}),
};

LoginStep2.defaultProps = {
    username: null,
    activeEnvironment: undefined,
};

const mapStateToProps = (state) => ({
    // isInFlow: loginSelectors.getIsInFlow(state),
    // fromOnboardingLoginData: loginSelectors.getFromOnboardingLoginData(state),
    // firstLog: !loginSelectors.getHasLogged(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    username: loginSelectors.getUsername(state),
});

export default compose(withRouter, connect(mapStateToProps))(withExchangeToken(LoginStep2));
