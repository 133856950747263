import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, string } from "prop-types";
import React, { Component } from "react";

class EquivalentTotalBalance extends Component {
    static propTypes = {
        equivalentTotalBalance: string,
        equivalentTotalBalanceEUR: string,
        isMobile: bool,
    };

    static defaultProps = {
        equivalentTotalBalance: "",
        equivalentTotalBalanceEUR: undefined,
        isMobile: false,
    };

    renderBalanceUSD = () => {
        const { equivalentTotalBalance, isMobile } = this.props;

        return (
            <>
                <Box fullWidth>
                    <Text
                        {...(!isMobile && { size: "5" })}
                        {...(isMobile && { size: "6" })}
                        labelKey="accounts.equivalentTotalBalance"
                    />
                    <Box className="mt-2">
                        <Text labelKey="accounts.balanceComposed.a11y" className="visually-hidden" />
                        <Text {...(!isMobile && { size: "big" })} {...(isMobile && { size: "2" })} semibold>
                            {equivalentTotalBalance || 0}
                        </Text>
                        <Text
                            {...(!isMobile && { size: "big" })}
                            {...(isMobile && { size: "2" })}
                            color="text-grey"
                            semibold>
                            &nbsp;USD
                        </Text>
                    </Box>
                </Box>
            </>
        );
    };

    renderBalanceEUR = () => {
        const { equivalentTotalBalanceEUR, isMobile } = this.props;

        return (
            <>
                <Text
                    {...(!isMobile && { size: "5" })}
                    {...(isMobile && { size: "6" })}
                    labelKey="accounts.equivalentTotalBalance"
                />
                <Box display="flex" className="mt-2">
                    <Text labelKey="accounts.balanceComposed.a11y" className="visually-hidden" />
                    <Text {...(!isMobile && { size: "big" })} {...(isMobile && { size: "2" })} semibold>
                        {equivalentTotalBalanceEUR || 0}
                    </Text>
                    <Text
                        {...(!isMobile && { size: "big" })}
                        {...(isMobile && { size: "2" })}
                        color="text-grey"
                        semibold>
                        &nbsp;EUR
                    </Text>
                </Box>
            </>
        );
    };

    render() {
        const { equivalentTotalBalanceEUR, isMobile } = this.props;

        return (
            <Box
                component="article"
                display="flex"
                borderRadius="default"
                fullWidth
                className={classNames("box-shadow-small", {
                    "p-7 mb-5": !isMobile,
                    "p-5 mb-5": isMobile,
                })}>
                <Box fullWidth className="mb-2">
                    {this.renderBalanceUSD()}

                    {isMobile && equivalentTotalBalanceEUR && <Box className="mt-5">{this.renderBalanceEUR()}</Box>}
                </Box>

                {!isMobile && (
                    <Box fullWidth className="mb-2">
                        <>{this.renderBalanceEUR()}</>
                    </Box>
                )}
                <Box>
                    <Image src="images/accounts.svg" className="img-functionality" />
                </Box>
            </Box>
        );
    }
}

export default EquivalentTotalBalance;
