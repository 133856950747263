/* eslint-disable*/
import * as tour from "middleware/tour";
import { types, actions } from "reducers/tour";
import { call, put, takeLatest } from "redux-saga/effects";

const sagas = [
    takeLatest(types.ADD_SKIP_REQUEST, addSkip),
    takeLatest(types.READ_SKIPS_COUNTER_REQUEST, readSkipsCounter),
];

export default sagas;

function* addSkip({ number, onFinish }) {
    const response = yield call(tour.addSkip, number);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        yield put(actions.setSkipsCounter(response.data.data.skipsCounter));
        yield put({ type: types.ADD_SKIP_SUCCESS });
    } else {
        yield put({ type: types.ADD_SKIP_FAILURE });
    }
    typeof onFinish === "function" && onFinish();
}

function* readSkipsCounter({ onFinish }) {
    const response = yield call(tour.readSkipsCounter);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        yield put({ type: types.READ_SKIPS_COUNTER_SUCCESS, skipsCounter: response.data.data.skipsCounter });
    } else {
        yield put({ type: types.READ_SKIPS_COUNTER_FAILURE });
    }
    typeof onFinish === "function" && onFinish();
}
