import React from "react";
import { bool, func } from "prop-types";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";

const FiltersButtons = ({ showFilters, handleShowFilters, handleHideFilters }) => (
    <Box display="flex" alignX="end" fullWidth>
        {showFilters ? (
            <Button
                className="btn-clear-filter color-primary-color"
                image="images/icons/filter.svg"
                // bsStyle="link"
                label="transactions.button.hideFilters"
                onClick={handleHideFilters}
            />
        ) : (
            <Button
                className="btn-clear-filter color-primary-color"
                image="images/icons/filter.svg"
                // bsStyle="link"
                label="transactions.button.showFilters"
                onClick={handleShowFilters}
            />
        )}
    </Box>
);

FiltersButtons.propTypes = {
    showFilters: bool.isRequired,
    handleShowFilters: func.isRequired,
    handleHideFilters: func.isRequired,
};

export default FiltersButtons;
