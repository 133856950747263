import React, { Component } from "react";
import { connect } from "react-redux";
import { func, arrayOf, shape, bool } from "prop-types";
import { actions as templateActions, selectors as templateSelectors } from "reducers/template";
import I18n from "pages/_components/I18n";
import Box from "pages/_components/Box";
import { Modal } from "react-bootstrap";
import List from "pages/_components/List";
import TemplateListItem from "pages/forms/_components/TemplateListItem";
import Text from "pages/_components/Text";
import classNames from "classnames";

class ListTemplateModal extends Component {
    static propTypes = {
        onSelect: func.isRequired,
        dispatch: func.isRequired,
        // TODO this values are dynamic, need to the define the correct type
        templates: arrayOf(shape({})).isRequired,
        isVisible: bool,
    };

    static defaultProps = {
        isVisible: false,
    };

    handleHide = () => {
        this.props.dispatch(templateActions.closeListModal());
    };

    handleSelect = (num) => {
        const { onSelect, templates } = this.props;
        onSelect(templates[num].transactionData);
        this.handleHide();
    };

    handleDelete = (item) => {
        const { dispatch } = this.props;
        dispatch(templateActions.deleteTemplate(item));
    };

    render() {
        const { templates, isVisible } = this.props;

        return (
            <Modal
                aria-labelledby="modalTitleID"
                aria-modal="true"
                onHide={this.handleHide}
                show={isVisible}
                className={classNames("drawer")}>
                <div className="modal-container">
                    <Modal.Header closeButton>
                        <Modal.Title id="modalTitleID">
                            <I18n id="forms.template.list.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Box fullWidth fullHeight className="scrollable-content px-0 px-md-9">
                            <Text size="6" labelkey="forms.template.list.title" />
                            <List className="template-list-wrapper" onSelect={this.handleSelect}>
                                {templates.map((item, index) => (
                                    <TemplateListItem
                                        key={item.name}
                                        item={item}
                                        num={index}
                                        onDelete={this.handleDelete}
                                        onClick={this.handleSelect}
                                    />
                                ))}
                            </List>
                        </Box>
                    </Modal.Body>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    templates: templateSelectors.getTemplateList(state),
    isVisible: templateSelectors.isListModalVisible(state),
});

export default connect(mapStateToProps)(ListTemplateModal);
