import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { routerActions } from "react-router-redux/actions";

import { channelsSelectors, channelsTicketSelectors } from "reducers/administration";
import { channelsTicketActions } from "reducers/administration/advanced";

import ChannelsTicket from "pages/administration/_components/ChannelsTicket";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";

class ChannelsTicketContent extends Component {
    render() {
        return (
            <AdministrationTicket {...this.props}>
                <ChannelsTicket {...this.props} />
            </AdministrationTicket>
        );
    }
}

const mapStateToProps = (state) => ({
    user: channelsSelectors.getUser(state),
    fetching: channelsTicketSelectors.isFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(channelsTicketActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsTicketContent);
