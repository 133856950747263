import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { node, shape, string, bool, func, number } from "prop-types";
import React, { Children, Component } from "react";
import { Col } from "react-bootstrap";
import { isDesktop as isDesktopDevice } from "react-device-detect";
import { withContentRect } from "react-measure";
import { Tab, TabList, TabPanel, Tabs as RTTabs } from "react-tabs";

class Tabs extends Component {
    static propTypes = {
        components: node.isRequired,
        contentRect: shape({}).isRequired,
        measureRef: shape({}).isRequired,
        background: string,
        borderRadius: string,
        wally: bool,
        onSelect: func,
        selectedIndex: number,
        isAditional: bool,
        className: string,
    };

    static defaultProps = {
        background: null,
        borderRadius: null,
        wally: false,
        onSelect: undefined,
        selectedIndex: undefined,
        className: "",
    };

    render() {
        const {
            components,
            contentRect,
            measureRef,
            background,
            borderRadius,
            wally,
            onSelect,
            selectedIndex,
            className,
        } = this.props;

        const tabsProps = {
            className: classNames("container--layout flex-grow-1 flex-column mb-0 mt-0", { wally }),
            onSelect,
            ...(selectedIndex !== undefined && { selectedIndex }),
        };

        return Children.count(components) === 1 ? (
            <div className="containerDetails container-fluid" ref={measureRef}>
                <Row className="justify-content-center">
                    <Col className="col-12">
                        {React.cloneElement(Children.only(components), {
                            containerBounds: contentRect.bounds,
                        })}
                    </Col>
                </Row>
            </div>
        ) : (
            <RTTabs {...tabsProps}>
                <Box className="z-index-1 " background={background} borderRadius={borderRadius}>
                    <TabList className={classNames("react-tabs__tab-list", className)}>
                        {Children.map(components, (child) => {
                            if (child) {
                                const {
                                    keyLabel,
                                    iconLabel,
                                    colorIconLabel,
                                    customKeyLabel,
                                    showIconAllways,
                                    keyLabelColor,
                                    isDesktop,
                                    isAditional,
                                } = child.props;
                                return (
                                    <Tab className={`react-tabs__tab ${isAditional && "is-aditional"} `}>
                                        {iconLabel && (isDesktop || showIconAllways) && (
                                            <Image color={colorIconLabel} src={iconLabel} />
                                        )}
                                        {customKeyLabel ? (
                                            <span>{keyLabel}</span>
                                        ) : (
                                            <Text labelKey={keyLabel} className={keyLabelColor} bold={isAditional} />
                                        )}
                                    </Tab>
                                );
                            }
                            return null;
                        })}
                    </TabList>
                </Box>

                <Box
                    className={classNames("containerDetails container-fluid", { "mt-1": !isDesktopDevice })}
                    ref={measureRef}>
                    <Box className="justify-content-center px-0">
                        {Children.map(components, (child) => {
                            if (child) {
                                return (
                                    <TabPanel>
                                        {React.cloneElement(child, {
                                            containerBounds: contentRect.bounds,
                                        })}
                                    </TabPanel>
                                );
                            }
                            return null;
                        })}
                    </Box>
                </Box>
            </RTTabs>
        );
    }
}

export default withContentRect("bounds")(Tabs);
