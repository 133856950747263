import classNames from "classnames";
import Box from "pages/_components/Box";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import { arrayOf, bool, node, oneOfType, string } from "prop-types";
import { useLoadingGlobalProvider } from "providers/LoadingGlobalProvider";
import React from "react";

export const MainContainerContext = React.createContext();

const MainContainer = ({
    alignX,
    background,
    children,
    className,
    classicStyle,
    columnReverse,
    forceMain,
    isDesktop,
    loaderSmallBoxed,
    showChildrenWithLoader,
    showLoader,
    loadChildrenAtOnce,
}) => {
    const { loading } = useLoadingGlobalProvider();
    return (
        <Box
            {...(columnReverse && { columnReverse })}
            component={!isDesktop || forceMain ? "main" : "section"}
            className={classNames("view-app-content", className)}
            background={background}
            {...(alignX && { alignX })}>
            <PageLoading
                loading={showLoader || loading === true}
                classicStyle={classicStyle}
                loaderSmallBoxed={loaderSmallBoxed}
                loadChildrenAtOnce={loadChildrenAtOnce}>
                {showChildrenWithLoader || loadChildrenAtOnce
                    ? children
                    : (!showLoader || loading === false) && children}
            </PageLoading>
        </Box>
    );
};

MainContainer.propTypes = {
    alignX: string,
    background: string,
    children: oneOfType([arrayOf(node), node]),
    className: string,
    classicStyle: bool,
    columnReverse: bool,
    forceMain: bool,
    isDesktop: bool.isRequired,
    loaderSmallBoxed: bool,
    showChildrenWithLoader: bool,
    showLoader: bool,
    loadChildrenAtOnce: bool,
};
MainContainer.defaultProps = {
    alignX: null,
    background: null,
    children: null,
    className: null,
    classicStyle: false,
    columnReverse: false,
    forceMain: false,
    loaderSmallBoxed: false,
    showChildrenWithLoader: false,
    showLoader: false,
    loadChildrenAtOnce: false,
};

export default resizableRoute(MainContainer);
