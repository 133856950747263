import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import TokenButtonStatusWrapper from "pages/_components/TokenButton/TokenButtonStatusWrapper";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { selectors as softTokenSelector } from "reducers/softToken";
import { selectors as sessionSelector } from "reducers/session";
import { isMobileNativeFunc } from "util/device";
import {
    USER_TOKEN_STATUS_AUTHENTICATE,
    USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID,
    USER_TOKEN_STATUS_MIGRATE_ENTRUST,
    USER_TOKEN_STATUS_MIGRATE_LOCAL,
    USER_TOKEN_STATUS_STARTED,
} from "util/userToken.util";
import Credential from "./Credential";

const CredentialTokenComponent = ({ credential, tokenStatus, onChangeToken, isFetching }) => {
    const [userHasToken] = useState(false);

    const renderCredentials = () => (
        <>
            {/* {credentials.map((credential) => ( */}
            <Box className="form-confirmation-info-wrapper">
                <Credential credentialType={credential} />
            </Box>
            {/* ))} */}
        </>
    );
    // eslint-disable-next-line no-confusing-arrow
    const renderCredentialToken = () =>
        isFetching ||
        (tokenStatus &&
            userHasToken &&
            tokenStatus !== USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID &&
            tokenStatus !== USER_TOKEN_STATUS_MIGRATE_ENTRUST &&
            tokenStatus !== USER_TOKEN_STATUS_MIGRATE_LOCAL &&
            tokenStatus !== USER_TOKEN_STATUS_AUTHENTICATE &&
            tokenStatus !== USER_TOKEN_STATUS_STARTED) ? (
            <TokenButtonStatusWrapper
                loading={isFetching}
                tokenStatus={tokenStatus}
                onChangeToken={(code) => {
                    if (onChangeToken) {
                        onChangeToken(code);
                    }
                }}
                credentials={[credential]}
            />
        ) : (
            renderCredentials()
        );

    const renderDesktop = () => renderCredentials();
    const renderMobile = () => renderCredentialToken();

    const loadingToken = () => (
        <div className="widget-preloader no-background min-height-2rem">
            <div>
                <span />
                <span />
                <span />
            </div>
        </div>
    );

    return (
        <>
            {isFetching && !isMobileNativeFunc() && loadingToken()}
            {!isMobileNativeFunc() && !isFetching && renderDesktop()}
            {isMobileNativeFunc() && renderMobile()}
        </>
    );
};

const mapStateToProps = (state) => ({
    credential: sessionSelector.getCredentialType(state),
    otpMethod: sessionSelector.getOtpMethod(state),
    tokenStatus: softTokenSelector.getStatusToken(state),
    isFetching: softTokenSelector.isFetching(state),
});

CredentialTokenComponent.propTypes = {
    credentials: arrayOf(string).isRequired,
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    tokenStatus: string,
    onChangeToken: func,
    propsForm: shape({}),
    isFetching: bool.isRequired,
    relativeStyleError: bool,
    warningIcon: string,
};

CredentialTokenComponent.defaultProps = {
    tokenStatus: undefined,
    onChangeToken: () => {},
    propsForm: {},
    relativeStyleError: true,
    warningIcon: "tooltip.svg",
};

export default connect(mapStateToProps)(resizableRoute(CredentialTokenComponent));
