import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import LabelWithIcon from "pages/_components/LabelWithIcon";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import TextField from "pages/_components/fields/TextField";
import Credential from "pages/_components/fields/credentials/Credential";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { compose } from "redux";
import * as config from "util/config";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import EnrollmentWrapper from "../_components/EnrollmentWrapper";
import { validatePassword } from "util/credentials";

const FORM_ID = "enrollment.wizard.finishCreate";

class CreateUser extends Component {
    cancel = () => {
        const { dispatch } = this.props;

        dispatch(enrollmentActions.showExitEnrollmentModal(true));
    };

    handleDeleteMessage = () => {
        const { dispatch } = this.props;

        dispatch(enrollmentActions.showExitEnrollmentModal(false));
        dispatch(push("/"));
    };

    hideModal = () => {
        const { dispatch } = this.props;

        dispatch(enrollmentActions.showExitEnrollmentModal(false));
    };

    onBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/enrollment/document"));
    };

    content = () => {
        const { isSubmitting, isDesktop, values, isValid } = this.props;

        const {
            isErrorForm,
            hasSpecialChar,
            hasNumericChar,
            hasUppercase,
            hasLowercase,
            maxLength,
            minLength
        } = validatePassword(values);

        const ResultForm = isErrorForm && isValid && values?.newUsername?.length > 7;

        const userNameAlertMessage = (
            <Box fullWidth className="mb-7 mb-md-7 mt-3">
                <LabelWithIcon
                    type={values?.newUsername?.length < 8 ? "error" : "alert"}
                    color="text"
                    text="enrollment.newUser.min"
                />
            </Box>
        );
        const alertMessage = (
            <Box fullWidth className="mb-7 mb-md-7 mt-3">
                <LabelWithIcon
                    type={values.newPassword.length < minLength || values.newPassword.length > maxLength ? "error" : "alert"}
                    color="text"
                    text="enrollment.step3.password.alert.1"
                    textParams={{minLength: minLength, maxLength: maxLength}}
                />
                <LabelWithIcon
                    type={!hasSpecialChar ? "error" : "alert"}
                    color="text"
                    text="enrollment.step3.password.alert.2"
                />
                <LabelWithIcon
                    type={!hasNumericChar ? "error" : "alert"}
                    color="text"
                    text="enrollment.step3.password.alert.3"
                />
                <LabelWithIcon
                    type={!hasUppercase ? "error" : "alert"}
                    color="text"
                    text="enrollment.step3.password.alert.4"
                />
                <LabelWithIcon
                    type={!hasLowercase ? "error" : "alert"}
                    color="text"
                    text="enrollment.step3.password.alert.5"
                />
            </Box>
        );
        return (
            <Form className={classNames("display-flex flex-column", { "full-height": !isDesktop })}>
                <Box display="flex" fullWidth className="login-title-container">
                    {isDesktop && (
                        <Box className="login-title-icon pr-4" onClick={this.onBack} pointer>
                            <Image src="images/arrow_back.svg" />
                        </Box>
                    )}
                    <Box fullWidth className="login-title-text">
                        <Text
                            size={isDesktop ? "3" : "2"}
                            className="btn-link-span"
                            bold
                            labelKey="enrollment.step3.title"
                        />
                    </Box>
                </Box>

                <Box background="white" borderRadius="xxl">
                    <Box fullWidth className="mb-7 mb-md-7">
                        <Field
                            idForm={FORM_ID}
                            name="newUsername"
                            pattern="^[a-zA-Z0-9]*$"
                            component={TextField}
                            placeholder={i18n.get("enrollment.step3.user.placeholder")}
                            labelNoMarginTop
                            maxLength={config.getInteger(`${FORM_ID}.field.newUsername.maxLength`) || 50}
                        />
                        {userNameAlertMessage}
                    </Box>
                    <Box fullWidth className="mb-7 mb-md-7 ">
                        <Field
                            name="newPassword"
                            idForm={FORM_ID}
                            component={Credential}
                            maxLength={maxLength}
                            placeholder={i18n.get("enrollment.step3.password.placeholder")}
                            labelNoMarginTop
                        />
                        {alertMessage}
                    </Box>

                    <Box fullWidth className="mb-7 mb-md-7">
                        <Field
                            name="newPasswordRepeat"
                            idForm={FORM_ID}
                            component={Credential}
                            maxLength={maxLength}
                            placeholder={i18n.get("enrollment.step3.confirmpassword.placeholder")}
                            labelNoMarginTop
                            autoComplete="off"
                        />
                    </Box>
                </Box>
                <Box {...(!isDesktop && { className: "mt-auto" })}>
                    <Row>
                        <Col xs={isDesktop ? "6" : "12"}>
                            <Button
                                bsStyle="primary"
                                label="global.continue"
                                loading={isSubmitting}
                                type="submit"
                                disabled={!ResultForm}
                                className="full-width"
                            />
                        </Col>
                        <Col xs={isDesktop ? "6" : "12"}>
                            <Button
                                label="global.cancel"
                                onClick={() => {
                                    this.cancel();
                                }}
                                bsStyle="outline"
                                block
                            />
                        </Col>
                    </Row>
                </Box>
            </Form>
        );
    };

    render() {
        const { isDesktop, userInfo } = this.props;

        if (!userInfo || Object.keys(userInfo).length === 0) {
            this.onBack();
            return <></>;
        }

        return (
            <EnrollmentWrapper onBack={this.onBack} isDesktop={isDesktop}>
                <>{this.content()}</>
            </EnrollmentWrapper>
        );
    }
}

CreateUser.propTypes = {
    dispatch: func.isRequired,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    values: shape({
        newUsername: string,
        newPassword: string,
        newPasswordRepeat: string,
    }).isRequired,
    userInfo: shape({
        email: string,
        mobileNumber: string,
        firstName: string,
        lastName: string,
        documentNumber: string,
        documentType: string,
        username: string,
    }).isRequired,
    isValid: bool.isRequired,
    showExitModal: bool.isRequired,
};

const mapStateToProps = (state) => ({
    userInfo: enrollmentSelectors.getUserInfo(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: true,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            newUsername: "",
            newPassword: "",
            newPasswordRepeat: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                newUsername: Yup.string(),
                // .required(i18n.get(`${FORM_ID}.newUsername.empty`)),
                // .min(8, i18n.get(`enrollment.newUser.min`)),
                newPassword: Yup.string(),
                newPasswordRepeat: Yup.string()
                    .required(i18n.get(`${FORM_ID}.newPasswordRepeat.empty`))
                    .oneOf([Yup.ref("newPassword"), null], "Las contraseñas deben coincidir"),
            }),
        handleSubmit: ({ newUsername, newPassword }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(enrollmentActions.saveUserInfo(newUsername, newPassword, formikBag));
            dispatch(enrollmentActions.validateUserCredentials(formikBag));
        },
    }),
)(CreateUser);
