import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as notificationActions } from "reducers/notification";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import * as config from "util/config";
import { simpleMaskEmail } from "util/format";

import Image from "pages/_components/Image";
import PopOver from "pages/_components/PopOver";
import PinInput from "pages/_components/fields/PinInput";
import CountdownCounter from "pages/login/_components/CountdownCounter";
import * as Yup from "yup";
import EnrollmentWrapper from "../_components/EnrollmentWrapper";
import SecondFactor from "pages/secondFactor/SecondFactor";
import secondFactor from "reducers/secondFactor";

const FORM_ID = "enrollment.wizard.validaCodeSMS";
const RESEND_WAITING_TIME = 300000; // 5 min
const CODE_PHONE_ECU = "593";
const OTP_LENGTH = config.getInteger("credential.otp.length", 6);

class InvitationCodeToken extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startCountDown: true,
            canSubmit: false,
        };
    }

    setStartCountDown = () => {
        const { dispatch } = this.props;
        // dispatch(push("/enrollment/document"));
        this.setState({
            startCountDown: false,
        });
        dispatch(enrollmentActions.resetValidationSMS());
    };

    generateCodeSMS = () => {
        const { dispatch, userInfo } = this.props;
        if (this.state.startCountDown) {
            dispatch(
                notificationActions.showNotification(
                    i18nUtils.get("enrollment.invitation.invalidNotification"),
                    "warning",
                    ["enrollment"],
                ),
            );
        } else {
            this.getMilisecondSend();
            this.setState({
                startCountDown: true,
            });
            const document = {
                documentNumber: userInfo.documentNumber,
                contactMail: userInfo.email,
                contactTelephone: userInfo.mobileNumber,
            };

            dispatch(enrollmentActions.generateValidationSMS(document, "refresh"));
        }
    };

    cancel = () => {
        const { dispatch } = this.props;

        dispatch(enrollmentActions.showExitEnrollmentModal(true));
    };

    onBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/enrollment/document"));
    };

    getMilisecondSend = () => {
        const { verificationCodeCreateDate } = this.props;

        const initDate = new Date(verificationCodeCreateDate);
        const actualDate = new Date();
        return verificationCodeCreateDate !== null
            ? RESEND_WAITING_TIME - Math.abs(actualDate - initDate)
            : RESEND_WAITING_TIME;
    };

    formatTelephone = (telephone) => {
        let phoneFormat = null;
        if (telephone?.length > 4) {
            const lastFourDigits = telephone.slice(-4);
            phoneFormat = lastFourDigits;
        }
        return phoneFormat;
    };

    content = () => {
        const {
            isSubmitting,
            isDesktop,
            values,
            isGeneratedValidationCode,
            userInfo,
            attempt,
            setFieldValue,
            submitForm,
            exchangeToken,
        } = this.props;

        const { canSubmit } = this.state;

        if (!userInfo || Object.keys(userInfo).length === 0) {
            this.onBack();
            return <></>;
        }

        const userMobileNumber = userInfo.mobileNumber;
        const codePhone = userMobileNumber?.substring(0, 3);
        const isSendSms = CODE_PHONE_ECU === codePhone;
        const subjectMessage = isSendSms
            ? i18nUtils.get("enrollment.step2.subject")
            : i18nUtils.get("enrollment.step2.subject.email");
        const formatUserMobile = this.formatTelephone(userMobileNumber);
        const dataInfo = isSendSms ? formatUserMobile : simpleMaskEmail(userInfo.email);

        //const disableButton = values.code.length === 6;
        const miliSecond = this.getMilisecondSend();
        return (
            <Form className={classNames("display-flex flex-column", { "full-height": !isDesktop })}>
                <Box display="flex" fullWidth className="login-title-container mb-3">
                    {isDesktop && (
                        <Box className="login-title-icon pr-4" onClick={this.onBack} pointer>
                            <Image src="images/arrow_back.svg" />
                        </Box>
                    )}
                    <Box fullWidth className="login-title-text">
                        <Text
                            size={isDesktop ? "3" : "2"}
                            className="btn-link-span"
                            bold
                            labelKey="enrollment.step2.title"
                        />
                    </Box>
                </Box>

                <SecondFactor
                    scopeToShow="enrollment"
                    onChangeToken={(tokenCode) => {
                        if (setFieldValue) {
                            setFieldValue("secondFactor", tokenCode);
                            submitForm();
                        }
                    }}
                    onValidSubmit={(validData) => {
                        this.setState({ canSubmit: validData.submit });
                    }}
                    withEchangeToken={exchangeToken}
                    idActivity="enrollment.wizard.validateVerificationCode"
                    /* buttons={continueButton} */
                    isSubmitting={isSubmitting}
                />

                <Box {...(!isDesktop && { className: "mt-auto" })}>
                    <Row fullWidth className="mt-8 mt-md-8">
                        <Col xs={isDesktop ? "6" : "12"}>
                            <Button
                                bsStyle="primary"
                                label="global.continue"
                                loading={isSubmitting}
                                type="submit"
                                className="full-width"
                                disabled={!canSubmit}
                            />
                        </Col>
                        <Col xs={isDesktop ? "6" : "12"}>
                            <Button
                                label="global.cancel"
                                onClick={() => {
                                    this.cancel();
                                }}
                                bsStyle="outline"
                                block
                            />
                        </Col>
                    </Row>
                </Box>
            </Form>
        );
    };

    render() {
        const { isDesktop } = this.props;

        return (
            <EnrollmentWrapper onBack={this.onBack} isDesktop={isDesktop}>
                <>{this.content()}</>
            </EnrollmentWrapper>
        );
    }
}

InvitationCodeToken.propTypes = {
    dispatch: func.isRequired,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    showCaptcha: bool.isRequired,
    isGeneratedValidationCode: bool.isRequired,
    showErrorGeneratedValidationCode: bool.isRequired,
    userInfo: shape({
        email: string,
        mobileNumber: string,
        firstName: string,
        lastName: string,
        documentNumber: string,
        documentType: string,
        username: string,
    }).isRequired,
    values: shape({
        code: string,
    }).isRequired,
    showExitModal: bool.isRequired,
    verificationCodeCreateDate: string.isRequired,
    attempt: number.isRequired,
    exchangeToken: string,
};

const mapStateToProps = (state) => ({
    showCaptcha: enrollmentSelectors.getShowCaptchaInvitationCode(state),
    isGeneratedValidationCode: enrollmentSelectors.isGeneratedValidationCode(state),
    showErrorGeneratedValidationCode: enrollmentSelectors.showErrorGeneratedValidationCode(state),
    userInfo: enrollmentSelectors.getUserInfo(state),
    showExitModal: enrollmentSelectors.getShowEnrollmentModal(state),
    verificationCodeCreateDate: enrollmentSelectors.getVerificationCreateDate(state),
    attemptRefresh: enrollmentSelectors.getAttemptRefresh(state),
    attempt: enrollmentSelectors.getAttemptOtp(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        // validate: (values) => {
        //     const errors = {};
        //     if (values.code.length !== 6) {
        //         errors.code = "Verifique el código ingresado, le restan (x) intentos."; // Validar cuantos intentos son
        //     }

        //     return errors;
        // },
        mapPropsToValues: (props) => ({
            secondFactor: props.match.params.invitationCode || "",
            captcha: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                secondFactor: Yup.string().required(i18nUtils.get(`${FORM_ID}.code.empty`)),
            }),
        handleSubmit: ({ secondFactor }, formikBag) => {
            const { dispatch, userInfo, exchangeToken } = formikBag.props;
            dispatch(enrollmentActions.validateInvitationCodeSMS(exchangeToken, secondFactor, userInfo, formikBag));
        },
    }),
)(InvitationCodeToken);
