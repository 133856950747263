import React, { useEffect } from "react";
import { Field, Formik, Form } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import PinInput from "pages/_components/fields/PinInput";
import { bool, func, shape } from "prop-types";
import { connect } from "react-redux";
import { push, routerActions } from "react-router-redux";
import * as i18n from "util/i18n";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Text from "pages/_components/Text";
import classNames from "classnames";
import LabelWithIcon from "pages/_components/LabelWithIcon";
import { actions as notificationActions } from "reducers/notification";
import { actions as softTokenActions, selectors as softTokenSelector } from "reducers/softToken";

const FORM_ID = "softtoken.pin.validate";
const INPUT_LENGTH_PIN = 6;

const SoftTokenPinForm = (props) => {
    const { isMobile, dispatch, handleBack, isFetching, propsForm, onChangePin, getDevicePin, savedPinCode } = props;

    useEffect(() => {
        getDevicePin();
    }, []);

    const renderHeader = () => (
        <>
            <Notification isMobile={isMobile} scopeToShow="softTokenValidatePin" />
            <Head isMobile={isMobile} onBack={handleBack} title="settings.softToken" textBack="softToken" />
        </>
    );

    const renderForm = (propsForm) => {
        const { errors, setErrors, values } = propsForm;
        const handlePinCodeChange = () => {
            setErrors({});
        };
    
        const isValidPin = savedPinCode && values.pinCode === savedPinCode; 
    
        const alertMessage = isValidPin ? (
            <Box fullWidth className="mb-7 mb-md-7 mt-3">
                <LabelWithIcon type="info" text="enrollment.step1.code.ok" />
            </Box>
        ) : (
            <></>
        );
    
        return (
            <Form className="full-height d-flex flex-column justify-content-between">
                <Box display="flex" column fullWidth>
                    <Box display="flex" column className="mb-2 mt-4">
                        <Text
                            className={classNames({
                                "line-height-125": !isMobile,
                                "line-height-15": isMobile,
                            })}
                            labelKey="softtoken.pin.validate.pinCode.title"
                            size={!isMobile ? "2" : "1"}
                            bold
                        />
                    </Box>
                    <Box display="flex" column className="mb-2 mt-4">
                        <Text
                            component="p"
                            size="4"
                            color="text-grey"
                            className="mt-0"
                            labelKey="softtoken.pin.validate.pinCode.text"
                            INPUT_LENGTH={INPUT_LENGTH_PIN}
                        />
                    </Box>
                    <Box display="flex" column className="mb-2 mt-4">
                        <Field
                            name="pinCode"
                            component={PinInput}
                            inputLenght={INPUT_LENGTH_PIN}
                            placeholder="*"
                            maxLength={1}
                            defaultLabelText={i18n.get("token.pin.create.label")}
                            noMarginBottom
                            className="mt-7 security-pin"
                            handleChangeProp={() => handlePinCodeChange(errors, setErrors)}
                        />
                        {alertMessage}
                    </Box>
                </Box>
                <Box>
                    <Button
                        id="btnConfirm"
                        className="mb-7"
                        type="submit"
                        bsStyle="primary"
                        label="global.continue"
                        btnUppercase={false}
                        loading={propsForm.isSubmitting}
                        disabled={!(propsForm.isValid && propsForm.dirty)}
                        block
                    />
                </Box>
            </Form>
        );
    };

    return (
        <>
            {renderHeader()}
            <MainContainer className="main-container rounded-border-top p-7 box-shadow-small background-white">
                <div className="above-the-fold full-height">
                    <Formik
                        initialValues={{ pinCode: "" }}
                        validate={(values) => {
                            const errors = {};
                            if (!values?.pinCode) {
                                errors.pinCode = i18n.get(DEFAULT_REQUIRED);
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(false);
                            if (onChangePin) {
                                onChangePin(values.pinCode);
                            }
                            if (savedPinCode && values.pinCode === savedPinCode) {
                                dispatch(push("/softToken"));
                            } else {
                                dispatch(
                                    notificationActions.showNotification(
                                        i18n.get(`token.validate.pin.error`),
                                        "error",
                                        ["softTokenValidatePin"],
                                    ),
                                );
                                dispatch(push("/"));
                            }

                            resetForm({
                                pinCode: "",
                            });
                        }}>
                        {(propsForm) => renderForm(propsForm)}
                    </Formik>
                </div>
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    savedPinCode: softTokenSelector.getDevicePin(state),
});

const mapDispatchToProps = (dispatch) => ({
    handleBack: () => {
        dispatch(push("/"));
    },
    getDevicePin: () => {
        dispatch(softTokenActions.getDevicePin());
    },
});

SoftTokenPinForm.propTypes = {
    dispatch: func.isRequired,
    getDevicePin: func,
    handleBack: func,
    isFetching: bool,
    onChangePin: func,
    propsForm: shape({}),
};

SoftTokenPinForm.defaultProps = {
    handleBack: undefined,
    isFetching: false,
    onChangePin: () => {},
    propsForm: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(SoftTokenPinForm);
