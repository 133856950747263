import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Slider from "react-slick";
import { Row, Col } from "react-bootstrap";
import { shape, node, func, bool } from "prop-types";

import { actions, selectors } from "reducers/widgets";
import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import WidgetLoading from "pages/_components/WidgetLoading";
import WidgetHeader from "./_components/WidgetHeader";

class ExchangeRates extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isEditable: bool,
        isFetching: bool,
        isDesktop: bool,
        data: shape({}).isRequired,
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
    };

    static defaultProps = {
        closeButton: null,
        isEditable: false,
        isFetching: false,
        isDesktop: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.listRequest("exchangeRates"));
    }

    renderMobileItem = ({ targetCurrencyCode, purchase, baseCurrencyCode, sale }) => (
        <Col key={targetCurrencyCode} className="col-12">
            <Row>
                <div className="exchenge-data">
                    <span className="flag">
                        <Image src={`images/currencyFlags/${targetCurrencyCode}.svg`} />
                        <span className="visually-hidden">
                            {i18nUtils.get(`widgets.exchangeRates.flag.${targetCurrencyCode}`)}
                        </span>
                    </span>
                    <span className="data-name">{i18nUtils.get(`currency.name.${targetCurrencyCode}`)}</span>
                </div>
            </Row>
            <Row>
                <div className="data-wrapper text-center col-6">
                    <span className="data-label">{i18nUtils.get(`widgets.exchangeRates.purchase`)}</span>{" "}
                    <FormattedAmount quantity={purchase} currency={baseCurrencyCode} enabledMask={false} />
                </div>
                <div className="data-wrapper text-center col-6">
                    <span className="data-label">{i18nUtils.get(`widgets.exchangeRates.sale`)}</span>{" "}
                    <FormattedAmount quantity={sale} currency={baseCurrencyCode} enabledMask={false} />
                </div>
            </Row>
        </Col>
    );

    renderItem = ({ targetCurrencyCode, purchase, baseCurrencyCode, sale }) => (
        <Col key={targetCurrencyCode} className="col-12" md={12}>
            <Row>
                {/* currency with code 555 has no flag */}
                {targetCurrencyCode !== "555" && (
                    <div className="flag col-md-4 text-left">
                        <Image src={`images/currencyFlags/${targetCurrencyCode}.svg`} />
                        <span className="visually-hidden">
                            {i18nUtils.get(`widgets.exchangeRates.flag.${targetCurrencyCode}`)}
                        </span>
                    </div>
                )}
                <p className="data-name col-md-8 text-left">{i18nUtils.get(`currency.name.${targetCurrencyCode}`)}</p>
            </Row>
            <Row>
                <Col className="data-wrapper text-left col-12" md={12} lg={6}>
                    <span className="data-label">{i18nUtils.get(`widgets.exchangeRates.purchase`)}</span>{" "}
                    <FormattedAmount quantity={purchase} currency={baseCurrencyCode} enabledMask={false} />
                </Col>
                <Col className="data-wrapper text-left col-12" md={12} lg={6}>
                    <span className="data-label">{i18nUtils.get(`widgets.exchangeRates.sale`)}</span>{" "}
                    <FormattedAmount quantity={sale} currency={baseCurrencyCode} enabledMask={false} />
                </Col>
            </Row>
        </Col>
    );

    renderList = () => {
        const ArrowLeft = (props) => (
            <Button
                {...props}
                image="images/arrowLeft.svg"
                bsStyle="link"
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
            />
        );
        const ArrowRight = (props) => (
            <Button
                {...props}
                image="images/arrowRight.svg"
                bsStyle="link"
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
            />
        );

        const settings = {
            arrows: true,
            prevArrow: <ArrowLeft />,
            nextArrow: <ArrowRight />,
        };

        const {
            data: { rates },
            isDesktop,
            isFetching,
        } = this.props;
        const invalidCurrencies = ["888", "999"];
        const filteredRates = rates.filter(({ targetCurrencyCode }) => !invalidCurrencies.includes(targetCurrencyCode));

        if (!filteredRates.length) {
            if (isFetching) {
                return <WidgetLoading loading />;
            }

            return <Col className="col-12">{i18nUtils.get("desktop.widgets.rates.empty")}</Col>;
        }
        if (isDesktop) {
            return (
                <Col className="col-12">
                    <Slider
                        {...settings}
                        dots
                        infinite={false}
                        speed={200}
                        slidesToShow={2}
                        slidesToScroll={filteredRates.length % 3}>
                        {filteredRates.map((rate) => (
                            <div key={rate.targetCurrencyCode}> {this.renderItem(rate)} </div>
                        ))}
                    </Slider>
                </Col>
            );
        }

        return (
            <Col sm={12}>
                <Slider {...settings} dots infinite={false} speed={200} slidesToShow={1} slidesToScroll={1}>
                    {filteredRates.map((rate) => (
                        <div key={rate.targetCurrencyCode}> {this.renderMobileItem(rate)} </div>
                    ))}
                </Slider>
            </Col>
        );
    };

    render() {
        const { closeButton, draggableItemProps } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetExchangeRates" };

        const { isEditable } = this.props;

        return (
            <section
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                className="widget"
                {...uiAutomationProp}
                {...draggableItemProps}
                aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                <WidgetHeader title={i18nUtils.get("widgets.exchangeRates.title")} action={closeButton} />
                <article className="widget--exchangeRates" aria-hidden={isEditable}>
                    <Row>{this.renderList()}</Row>
                </article>

                <div className="overlay" />
            </section>
        );
    }
}

const mapStateToProps = (state) => selectors.getWidget(state, "exchangeRates");

export default compose(connect(mapStateToProps), resizableRoute)(ExchangeRates);
