import { USD_CURRENCY } from "constants.js";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { push, replace } from "react-router-redux";
import { actions as wallyActions, selectors as wallySelectors } from "reducers/wally";
import { formatAccount } from "./hooks/WallyHooks";

const LastMovementsWally = (props) => {
    const { dispatch, accountWally, movementsWally, isActiveWaly } = props;
    const [loading, setLoading] = useState(true);

    const handleBack = () => {
        dispatch(replace({ pathname: "/wally", state: { transition: "transition-flow-close" } }));
    };

    useEffect(() => {
        if (isActiveWaly) {
            dispatch(wallyActions.listMovementsRequest(() => setLoading(false)));
        } else {
            dispatch(push("/wally"));
        }
    }, []);

    return (
        <>
            <Head
                onBack={handleBack}
                title="wally.movements.title"
                subtitlePrefix={accountWally?.productAlias}
                subtitle={formatAccount(accountWally)}
                titleImage="wally-head.svg"
            />
            <MainContainer showLoader={loading}>
                {movementsWally.length > 0 ? (
                    <Box background="white" borderRadius="default" className="mx-n-5">
                        <GridTable fullWidth={false}>
                            <GridTable.Body>
                                {movementsWally.map((movement) => (
                                    <GridTable.Container
                                        columnsTemplate="1.5rem 1fr minmax(4rem, auto) 1.5rem"
                                        rowsTemplate="minmax(1rem, 1fr) minmax(1rem, 1fr) minmax(1rem, 1fr)"
                                        key={movement.transactionCode}
                                        className="px-5 py-3"
                                        removeBottomSpacing
                                        onClick={() => {
                                            const idActivity =
                                                movement.transactionType === "Credit"
                                                    ? "receivedTransfers.wally.send"
                                                    : "transfers.wally.send";
                                            dispatch(
                                                push({
                                                    pathname: "/transactionWally",
                                                    state: { ...movement, idActivity },
                                                }),
                                            );
                                        }}>
                                        <GridTable.Data
                                            className="p-0"
                                            columnStart={1}
                                            rowWidth={3}
                                            alignY="flex-start"
                                            {...(movement.creditReference !== ""
                                                ? { alignY: "flex-start" }
                                                : { alignY: "center" })}>
                                            <Image
                                                {...(movement.creditReference === "" && { className: "mb-4" })}
                                                src="images/contact-wally.svg"
                                                height="7"
                                                wrapperWidth="7"
                                                wrapperHeight="7"
                                                color="wally"
                                            />
                                        </GridTable.Data>
                                        <GridTable.Data
                                            className="p-0"
                                            columnStart={2}
                                            rowWidth={3}
                                            alignX="flex-start">
                                            <Box display="flex" column fullWidth>
                                                <Text bold size="4" color="heading" className="mb-2" ellipsis>
                                                    {movement.beneficiaryFullName}
                                                </Text>

                                                {movement.creditReference !== "" && (
                                                    <Text bold size="4" color="text" className="mb-2" ellipsis>
                                                        {movement.creditReference}
                                                    </Text>
                                                )}
                                                <FormattedDate size="6" color="text" date={movement.transactionDate} />
                                            </Box>
                                        </GridTable.Data>

                                        <GridTable.Data
                                            fullWidth
                                            className="p-0"
                                            columnStart={3}
                                            rowWidth={3}
                                            alignX="flex-end"
                                            alignY="center">
                                            <Box display="flex" column alignX="flex-end">
                                                <Text
                                                    size="4"
                                                    color="text"
                                                    align="right"
                                                    className="mb-2"
                                                    labelKey={
                                                        movement?.transactionType
                                                            ? `wally.movements.type.${movement?.transactionType}`
                                                            : "wally.movements.type.Debit"
                                                    }
                                                />
                                                <FormattedAmount
                                                    quantity={
                                                        movement.amount?.quantity *
                                                        (movement.transactionType === "Credit" ? 1 : -1)
                                                    }
                                                    currency={USD_CURRENCY}
                                                    noCurrency
                                                    color={movement.transactionType === "Credit" ? "primary" : "error"}
                                                    size="4"
                                                />
                                            </Box>
                                        </GridTable.Data>

                                        <GridTable.Data columnStart={4} rowWidth={3} alignX="center" alignY="center">
                                            <Image
                                                src="images/arrow-right.svg"
                                                height="4"
                                                wrapperWidth="4"
                                                wrapperHeight="4"
                                                color="text"
                                            />
                                        </GridTable.Data>
                                    </GridTable.Container>
                                ))}
                            </GridTable.Body>
                        </GridTable>
                    </Box>
                ) : (
                    <Box display="flex" alignX="center" column fullHeight className="mt-12">
                        <Box className="mb-8 mt-12">
                            <Image src="images/movements.svg" height={12} wrapperHeight={12} wrapperWidth={11} />
                        </Box>
                        <Box>
                            <Text color="text-secondary" size={3} labelKey="wally.movements.without.transaction" />
                        </Box>
                    </Box>
                )}
            </MainContainer>
        </>
    );
};

LastMovementsWally.propTypes = {
    history: shape({}).isRequired,
    accountWally: shape({}),
    fetching: bool,
    movementsWally: shape({}),
    dispatch: func.isRequired,
    isActiveWaly: bool.isRequired,
};

LastMovementsWally.defaultProps = {
    fetching: false,
    accountWally: {},
    movementsWally: [],
};

const mapStateToProps = (state) => ({
    accountWally: wallySelectors.getAccountWally(state),
    movementsWally: wallySelectors.getMovementsWally(state),
    isActiveWaly: wallySelectors.getIsActiveWally(state),
});

export default connect(mapStateToProps)(resizableRoute(LastMovementsWally));
