import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { routerActions } from "react-router-redux/actions";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { compose } from "redux";
import { filterData } from "util/array";
import * as config from "util/config";
import { filterOptions, viewLinksNewRecharge, viewServiceCards } from "./_components/ViewServicePaymentList";
import fieldARP from "./hooks/field";

export const ID_ACTIVITY = `payService.list`;
export const NUMBER_FAST_RECHARGES = config.getInteger("payments.services.defaultFastRecharges", 3);

const ServicePaymentList = (props) => {
    const { fetching, listPayService, dispatch, fetchingFavorite, isDesktop, isShowAllRecharges } = props;

    const [listFilter, setListFilter] = useState([]);
    const [isShowAll, setIsShowAll] = useState(isShowAllRecharges);
    const [isSelectRecharge, setIsSelectRecharge] = useState(false);
    const buttonsGroupIni = [
        {
            id: "myEntity",
            label: "servicePayments.my.entity",
            selected: true,
        },
        {
            id: "fastRecharge",
            label: "servicePayments.fast.recharge",
            selected: false,
        },
    ];
    const [buttonsGroup, setButtonsGroup] = useState(buttonsGroupIni);

    const handleShowAll = (showAll) => {
        if (showAll) {
            dispatch(payServiceActions.isFastRecharge(false));
            setListFilter(listPayService.filter((el) => el?.fastRecharge !== true));
        } else {
            setListFilter(listPayService.filter((el) => el?.fastRecharge === true));
        }
        dispatch(payServiceActions.isShowAllRecharges(showAll));
        setIsShowAll(showAll);
    };

    const handleBack = () => {
        if (isSelectRecharge) {
            setIsSelectRecharge(false);
            handleShowAll(false);
        } else {
            dispatch(routerActions.push("/desktop"));
        }
    };

    const handleFavorite = (payService) => {
        dispatch(payServiceActions.favoriteRequest(payService));
    };

    const handleDelete = (payService) => {
        dispatch(payServiceActions.deleteRequest(payService));
    };

    const handleEdit = (e, idPaymentUser) => {
        e.preventDefault();
        e.stopPropagation();
        if (isShowAll || isSelectRecharge) {
            dispatch(routerActions.push(`/servicePayments/editService/${idPaymentUser}`));
        } else {
            dispatch(routerActions.push(`/servicePayments/editFastRecharge/${idPaymentUser}`));
        }
    };

    const handleLink = (paymentUser, isNewFastRecharge = false) => {
        const newPaymentUser = { ...paymentUser, fastRecharge: isNewFastRecharge };
        if (isNewFastRecharge) {
            dispatch(payServiceActions.isFastRecharge(true));
            delete newPaymentUser.amountCurrency;
            delete newPaymentUser.amountQuantity;
            delete newPaymentUser.idProduct;
            delete newPaymentUser.productType;
        }
        const query = {
            paymentUser: newPaymentUser,
            biller: paymentUser?.extraData,
            savePayment: false,
            isFastRecharge: isNewFastRecharge,
        };
        dispatch(payServiceActions.setQueryBillPre(query));
        dispatch(payServiceActions.setSelectedBiller(paymentUser?.extraData));

        // Telered -> consulta de reglas
        if (paymentUser?.extraData?.svcProviderId === "Telered") {
            dispatch(payServiceActions.listRulesBillerRequest(paymentUser?.idBiller));
        } else {
            // ARP -> definicion de reglas por defecto
            dispatch(payServiceActions.listRulesBillerSuccess(fieldARP));
        }
    };

    const handleFilterData = (filter) => {
        if (filter !== "") {
            const dataToFilter = listPayService.filter((el) => el?.fastRecharge !== isShowAll);
            const data = filterData(
                [...dataToFilter],
                ["alias", "idCategory", "nameBiller", "idBill", "partyId"],
                filter,
            );
            setListFilter(data);
        } else {
            handleShowAll(isShowAll);
        }
    };

    const handleSelectRecharge = (isNew = false) => {
        const rechargesData = listPayService.filter((el) => el?.idCategory === "RECARGAS" && el?.fastRecharge !== true);
        if (rechargesData.length > 0 && !isNew) {
            setListFilter(rechargesData);
            setIsSelectRecharge(true);
        } else {
            dispatch(payServiceActions.isFastRecharge(true));
            dispatch(routerActions.push("/addNewService/company/RECARGAS"));
        }
    };

    const handleButtonChipClick = (id) => {
        handleShowAll(id === "myEntity");

        const newButtonsGroup = buttonsGroup.map((button) => ({ ...button, selected: button.id === id }));
        setButtonsGroup(newButtonsGroup);
    };

    useEffect(() => {
        dispatch(payServiceActions.listPayServiceRequest());
        dispatch(payServiceActions.isLinkFastRecharge(false));
        dispatch(payServiceActions.isFastRecharge(false));
        handleShowAll(isShowAllRecharges);
        if (!isShowAllRecharges) {
            handleButtonChipClick("fastRecharge");
        }
    }, []);

    useEffect(() => {
        if (listPayService) {
            setListFilter(listPayService.filter((el) => el?.fastRecharge === !isShowAll));
        }
    }, [listPayService]);

    return (
        <>
            <Notification scopeToShow="servicePayments" />
            <Head
                title="servicePayments.list.title"
                addLinkToLabel="servicePayments.new"
                addLinkTo="/addNewService/category"
                onBack={handleBack}
            />
            <MainContainer showLoader={fetching}>
                {isSelectRecharge ? (
                    <>
                        <Box className="pb-7">
                            <Box display={isDesktop ? "block" : "none"} alignX="left" className="pt-5 pb-7">
                                <Text
                                    size="8"
                                    color="primary"
                                    align="left"
                                    bold
                                    labelKey="servicePayments.fast.recharge.help"
                                />
                            </Box>
                            <Row>
                                {viewServiceCards(
                                    listFilter,
                                    handleFavorite,
                                    handleDelete,
                                    handleEdit,
                                    handleLink,
                                    fetchingFavorite,
                                    true,
                                    isSelectRecharge,
                                )}
                            </Row>
                        </Box>
                        <Box {...(!isDesktop && { className: "mt-auto" })}>
                            <Row gapY={isDesktop ? "8" : "3"}>
                                <Col xs={12} md={3} mdOffset={3}>
                                    <Button
                                        label="global.cancel"
                                        type="button"
                                        className="btn-outline"
                                        onClick={handleBack}
                                        block
                                    />
                                </Col>
                                <Col xs={12} md={3} {...(!isDesktop && { className: "grid-reversed" })}>
                                    <Button
                                        bsStyle="primary"
                                        label="servicePayments.new.entities"
                                        type="submit"
                                        block
                                        onClick={() => handleSelectRecharge(true)}
                                    />
                                </Col>
                            </Row>
                        </Box>
                    </>
                ) : (
                    <>
                        {filterOptions(isDesktop, buttonsGroup, handleButtonChipClick, handleFilterData)}

                        {isShowAll ? (
                            <Box component="section">
                                {listFilter.length > 0 ? (
                                    <Row>
                                        {viewServiceCards(
                                            listFilter,
                                            handleFavorite,
                                            handleDelete,
                                            handleEdit,
                                            handleLink,
                                            fetchingFavorite,
                                            isShowAll,
                                        )}
                                    </Row>
                                ) : (
                                    <Box
                                        display="flex"
                                        alignX="center"
                                        borderRadius="default"
                                        border="dashed-image-border-base-color"
                                        className="px-7 py-5 py-md-8">
                                        <Text
                                            size="6"
                                            color="heading"
                                            align="cenetr"
                                            labelKey="servicePayments.without.entities"
                                        />
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Box component="section">
                                <Row>
                                    {viewServiceCards(
                                        listFilter,
                                        handleFavorite,
                                        handleDelete,
                                        handleEdit,
                                        handleLink,
                                        fetchingFavorite,
                                        isShowAll,
                                    )}
                                    {viewLinksNewRecharge(
                                        NUMBER_FAST_RECHARGES -
                                            listPayService.filter((el) => el?.fastRecharge === true).length,
                                        handleSelectRecharge,
                                    )}
                                </Row>
                            </Box>
                        )}
                    </>
                )}
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: payServiceSelectors.isFetching(state),
    fetchingFavorite: payServiceSelectors.isFetchingFavorite(state),
    listPayService: payServiceSelectors.getListPayService(state),
    isShowAllRecharges: payServiceSelectors.isShowAllRecharges(state),
});

ServicePaymentList.propTypes = {
    dispatch: func.isRequired,
    fetching: bool,
    fetchingFavorite: bool,
    listPayService: arrayOf(shape({})),
    isDesktop: bool,
    location: shape({}).isRequired,
    isShowAllRecharges: bool,
};

ServicePaymentList.defaultProps = {
    fetching: false,
    isDesktop: true,
    fetchingFavorite: false,
    listPayService: [],
    isShowAllRecharges: true,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(ServicePaymentList));
