import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import GoogleMapContainer from "pages/settings/_components/GoogleMap";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as settingsSelectors } from "reducers/settings";
import { getLastLoginDate, getLastLoginPlace } from "util/settings";

class LastLogin extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        previousLoginInfo: arrayOf(
            shape({
                city: string,
                country: string,
                date: string,
                idUser: string,
                ip: string,
                latitude: number,
                longitude: number,
            }),
        ),
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        previousLoginInfo: null,
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    render() {
        const { previousLoginInfo, isDesktop } = this.props;

        return (
            <>
                <Head title="settings.lastLogin.head" onBack={this.handleBack} />
                <MainContainer>
                    <Box
                        display="flex"
                        column
                        component="article"
                        background="component-background"
                        className="pt-5 pt-md-8 pb-5 pb-md-8 px-5 px-md-7 mt-5 mt-md-0 mb-5 mb-md-0"
                        borderRadius="default">
                        <Box display="flex" alignY="baseline" className="mb-5">
                            <Text
                                color="heading"
                                component="label"
                                className="mb-0"
                                labelKey="settings.lastLogin.date"
                                bold
                                addColon
                            />
                            <Text color="heading">{getLastLoginDate(previousLoginInfo[0])}</Text>
                        </Box>
                        <Box display="flex" alignY="baseline" className="mb-md-8">
                            <Text
                                color="heading"
                                component="label"
                                className="mb-0"
                                labelKey="settings.lastLogin.from"
                                bold
                                addColon
                            />
                            <Text color="heading">{getLastLoginPlace(previousLoginInfo[0])}</Text>
                        </Box>
                        {isDesktop && (
                            <Box display="block" className="google-map-wrapper mt-7">
                                <GoogleMapContainer google={window.google} positions={previousLoginInfo} />
                            </Box>
                        )}
                    </Box>
                    {!isDesktop && (
                        <Box display="block" className="google-map-wrapper">
                            <GoogleMapContainer google={window.google} positions={previousLoginInfo} />
                        </Box>
                    )}
                </MainContainer>
            </>
        );
    }
}

export default connect((state) => ({
    ...sessionSelectors.getUser(state),
    errors: settingsSelectors.getErrors(state),
}))(resizableRoute(LastLogin));
