import ServiceCategoryCard from "pages/servicePayments/_components/ServiceCategoryCard";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func } from "prop-types";
import React from "react";
import { Col } from "react-bootstrap";
import withRouter from "react-router-dom/withRouter";
import { routerActions } from "react-router-redux/actions";
import { connect } from "react-redux";
import * as i18n from "util/i18n";
import { getServiceCategoriesList } from "./hooks/servicePaymentsHooks";

const AddNewServiceCategoryList = ({ isDesktop, dispatch }) => {
    const handleBack = () => {
        dispatch(routerActions.push("/servicePayments"));
    };

    return (
        <>
            <Notification scopeToShow="servicePayments" />
            <Head title="servicePayments.new.title" onBack={handleBack} />
            <MainContainer showLoader={false}>
                <Text component="h2" color="primary" size={isDesktop ? "5" : "3"} bold className="mt-5 mt-md-0 mb-4">
                    {i18n.get("servicePayments.addNewService.title")}:
                </Text>
                <Row gapY={isDesktop ? "5" : "3"}>
                    {getServiceCategoriesList().map((elem) => (
                        <Col xs={12} md={6} key={elem?.idServicePayment}>
                            <ServiceCategoryCard elem={elem} />
                        </Col>
                    ))}
                </Row>
            </MainContainer>
        </>
    );
};

AddNewServiceCategoryList.propTypes = {
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
};

export default withRouter(connect()(resizableRoute(AddNewServiceCategoryList)));
