/* eslint-disable no-confusing-arrow */
import classNames from "classnames";
import AccessBarBtn from "pages/_components/AccessBarBtn";
import Badge from "pages/_components/Badge";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import { arrayOf, bool, number, shape, string, func } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors, actions as sessionActions } from "reducers/session";
import { selectors as wallySelectors } from "reducers/wally";
import { getUrlMarket } from "util/settings";

const AccessBar = ({
    options,
    defaultAccesBar,
    wally,
    notificationCount,
    showNotification,
    isActiveCorporate,
    dispatch,
}) => {
    const setMobileMenuClosed = () => {
        dispatch(sessionActions.setMobileMenuClosed());
    };

    return defaultAccesBar ? (
        <Box className="access-bar-wrapper" component="article" display="flex" alignX="between" alignY="center">
            <Box className="access-bar" display="flex">
                {options.map((option, index) =>
                    index === 2 ? (
                        <AccessBarBtn
                            key={`${option.label}_${option.image}`}
                            image={option.image}
                            label={option.label}
                            className={option.className}
                            href={option.href}
                            externalHref={option.externalHref}
                            onClick={option.onClick}
                        />
                    ) : (
                        <AccessBarBtn
                            key={`${option.label}_${option.image}`}
                            image={option.image}
                            label={option.label}
                            className={option.className}
                            href={option.href}
                            externalHref={option.externalHref}
                            onClick={option.label === "login.access.bar.menu" ? setMobileMenuClosed : option.onClick}
                        />
                    ),
                )}
            </Box>
        </Box>
    ) : (
        <Box
            className={classNames("access-bar-wrapper access-bar-wrapper-blue", { corporate: isActiveCorporate })}
            component="article"
            display="flex"
            alignX="between"
            alignY="center">
            <Box display="flex" column className="pr-5">
                <Text labelKey="login.access.app.title" size="6" color="inverse" className="pb-2" />
            </Box>
            <Button
                externalHref={getUrlMarket()}
                label="login.access.app.btn"
                bsStyle="outline"
                className="access-bar-app-btn"
                inverse
            />
        </Box>
    );
};

AccessBar.propTypes = {
    options: arrayOf(
        shape({
            image: string,
            href: string,
            label: string,
            className: string,
        }),
    ).isRequired,
    wally: shape({
        image: string,
        href: string,
        label: string,
        className: string,
    }).isRequired,
    defaultAccesBar: bool,
    notificationCount: number,
    showNotification: bool,
    isActiveCorporate: bool.isRequired,
    dispatch: func,
};

AccessBar.defaultProps = {
    defaultAccesBar: true,
    notificationCount: 0,
    showNotification: false,
};

const mapStateToProps = (state) => ({
    notificationCount: wallySelectors.getPendingTransactionsWallyQuantity(state),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
});

export default connect(mapStateToProps)(AccessBar);
