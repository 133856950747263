import Box from "pages/_components/Box";
import Disclaimer from "pages/_components/Disclaimer";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Text from "pages/_components/Text";
import { array, bool, func, oneOf, oneOfType, shape, string } from "prop-types";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    actions as pushNotificationsActions,
    selectors as pushNotificationsSelectors,
} from "reducers/pushNotifications";
import { naturalDate } from "util/format";
import * as i18n from "util/i18n";

class MyDevices extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        location: shape({ search: string }),
        isMobile: bool.isRequired,
        pushNotificationsConfiguredUserDevices: oneOfType([array.isRequired, oneOf([null]).isRequired]),
        fetching: bool,
    };

    static defaultProps = {
        pushNotificationsConfiguredUserDevices: null,
        location: shape({ search: "" }),
        fetching: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(pushNotificationsActions.listAllUserDevices());
    }

    generateTableColumns = () => [
        {
            key: "icon",
            dataIndex: "icon",
            title: i18n.get("settings.myDevices.data.device"),
            width: 100,
        },
        {
            key: "description",
            dataIndex: "description",
            title: "",
            width: 100,
        },
        {
            key: "idDeviceToShow",
            dataIndex: "idDeviceToShow",
            title: i18n.get("settings.myDevices.data.id"),
            width: 200,
        },
        {
            key: "lastLogin",
            dataIndex: "lastLogin",
            title: i18n.get("settings.myDevices.data.lastLogin"),
            width: 200,
        },
    ];

    renderIcon = (data) => {
        let icon = "images/channel-web.svg";

        if (data.isAndroid) {
            icon = data.isTablet ? "images/tablet-android.svg" : "images/channel-mobile.svg";
        }

        if (data.isIOS) {
            icon = data.isTablet ? "images/tablet-ipad.svg" : "images/channel-mobile.svg";
        }

        return <Image src={icon} className="svg-icon" />;
    };

    getTableData = () => {
        const { pushNotificationsConfiguredUserDevices } = this.props;

        return pushNotificationsConfiguredUserDevices.map(
            ({ idDevice, model, platform, modificationDate, lastEntryCountry, lastEntryCity, ...rest }) => ({
                key: idDevice,
                idDeviceToShow: `****${idDevice.substring(idDevice.length - 4, idDevice.length)}`,
                icon: <div className="my-devices-name">{this.renderIcon(rest)}</div>,
                description: model ? (
                    <I18n id={`devices.apple.identifier.${model}`} defaultValue={`${model}`} />
                ) : (
                    <I18n id={`devices.platform.${platform}`} defaultValue={`${platform}`} />
                ),
                lastLogin:
                    lastEntryCountry || lastEntryCity
                        ? `${naturalDate(modificationDate)} (${lastEntryCity} ${
                              lastEntryCity ? "," : ""
                          } ${lastEntryCountry})`
                        : naturalDate(modificationDate),
            }),
        );
    };

    setSlectedRow = (record) => {
        const { location } = this.props;
        const { query } = queryString.parseUrl(location.search);

        if (record.key === query.idDevice) {
            return "tableNoInteraction clicked";
        }
        return "tableNoInteraction";
    };

    columnTemplates = () => {
        const { isMobile } = this.props;
        return isMobile ? "3rem 1fr 1fr" : "3rem 1fr 1fr 2fr";
    };

    getContent = () => {
        const { pushNotificationsConfiguredUserDevices, isMobile } = this.props;

        if (pushNotificationsConfiguredUserDevices) {
            return (
                <Box>
                    {isMobile && (
                        <Disclaimer
                            className="p-3 px-5 p-md-5 mt-5 mt-md-0 mb-5"
                            styled="info"
                            labelKey="settings.myDevices.disclaimer"
                        />
                    )}
                    <Box background="white" borderRadius="default" className="mx-n-5">
                        <Box
                            display="flex"
                            className="px-7 py-6"
                            fullWidth
                            border="bottom-background-secondary"
                            {...(isMobile && { alignX: "center" })}>
                            <Text
                                color="heading"
                                labelKey="settings.myDevices.title"
                                {...(isMobile && { align: "center" })}
                            />
                        </Box>

                        <GridTable>
                            {!isMobile ? (
                                <GridTable.Header>
                                    <GridTable.Container columnsTemplate={this.columnTemplates()}>
                                        <GridTable.Data columnStart={2} inHeader>
                                            <Text>Dispositivo</Text>
                                        </GridTable.Data>
                                        <GridTable.Data columnStart={3} inHeader>
                                            <Text>Identificador</Text>
                                        </GridTable.Data>
                                        <GridTable.Data className="mr-md-9" columnStart={4} alignX="flex-end" inHeader>
                                            <Text>Última conexión</Text>
                                        </GridTable.Data>
                                    </GridTable.Container>
                                </GridTable.Header>
                            ) : null}
                            <GridTable.Body>
                                {pushNotificationsConfiguredUserDevices.map(
                                    ({
                                        idDevice,
                                        model,
                                        platform,
                                        modificationDate,
                                        lastEntryCountry,
                                        lastEntryCity,
                                        ...rest
                                    }) => (
                                        <GridTable.Container
                                            columnsTemplate={this.columnTemplates()}
                                            {...(isMobile && { rows: 2 })}>
                                            <GridTable.Data
                                                columnStart={1}
                                                {...(isMobile && { rowWidth: 2, alignX: "left" })}>
                                                {this.renderIcon(rest)}
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={2} {...(isMobile && { alignX: "left" })}>
                                                <Text size="6" color="heading">
                                                    {model ? (
                                                        <I18n
                                                            id={`devices.apple.identifier.${model}`}
                                                            defaultValue={`${model}`}
                                                        />
                                                    ) : (
                                                        <I18n
                                                            id={`devices.platform.${platform}`}
                                                            defaultValue={`${platform}`}
                                                        />
                                                    )}
                                                </Text>
                                            </GridTable.Data>
                                            <GridTable.Data
                                                columnStart={isMobile ? 2 : 3}
                                                {...(isMobile && { rowStart: 2, alignX: "left" })}>
                                                <Text size="6" color={isMobile ? "text-secondary" : "heading"}>
                                                    {`****${idDevice.substring(idDevice.length - 4, idDevice.length)}
                                            `}
                                                </Text>
                                            </GridTable.Data>
                                            <GridTable.Data
                                                className="mr-md-9"
                                                columnStart={isMobile ? 3 : 4}
                                                alignX="flex-end"
                                                {...(isMobile && { rowWidth: 2 })}>
                                                <Box
                                                    display="flex"
                                                    alignX="flex-end"
                                                    {...(isMobile && { column: true })}>
                                                    <Text
                                                        size="6"
                                                        color="heading"
                                                        align="right"
                                                        {...(!isMobile && { className: "mr-2" })}>
                                                        {naturalDate(modificationDate)}
                                                    </Text>
                                                    {(lastEntryCountry || lastEntryCity) && (
                                                        <Text size="6" color="heading" align="right">
                                                            ({lastEntryCity}
                                                            {`${lastEntryCity ? "," : ""}`} {lastEntryCountry})
                                                        </Text>
                                                    )}
                                                </Box>
                                            </GridTable.Data>
                                        </GridTable.Container>
                                    ),
                                )}
                            </GridTable.Body>
                        </GridTable>
                    </Box>
                    {!isMobile && (
                        <Disclaimer
                            className="p-3 px-5 p-md-5 mt-5 mb-8"
                            styled="info"
                            labelKey="settings.myDevices.disclaimer"
                        />
                    )}
                </Box>
            );
        }
        return null;
    };

    render() {
        const { fetching } = this.props;

        return (
            <>
                <Head title="settings.myDevices" backLinkTo="/settings" />

                <MainContainer showLoader={fetching}>
                    <Notification scopeToShow="pushNotifications" />
                    {this.getContent()}
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: pushNotificationsSelectors.getFetching(state),
    pushNotificationsConfiguredUserDevices: pushNotificationsSelectors.getPushNotificationsConfiguredUserDevices(state),
});

export default connect(mapStateToProps)(MyDevices);
