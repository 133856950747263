export default [
    {
        fieldCalc: null,
        fieldId: "Campo53",
        fieldInq: false,
        fieldLabel: "Valor a pagar",
        fieldType: "Double",
        listValues: [],
        restriction: {
            isReadOnly: false,
            maxLength: null,
            minLength: null,
            maxValue: null,
            minValue: null,
        },
        serviceField: "SubTotal",
        serviceOper: null,
    },
    {
        fieldCalc: null,
        fieldId: "Campo1",
        fieldInq: true,
        fieldLabel: "NÚMERO DE REFERENCIA",
        fieldType: "String",
        listValues: [],
        restriction: {
            isReadOnly: true,
            maxLength: null,
            minLength: null,
            maxValue: null,
            minValue: null,
        },
        serviceField: "BillId",
        serviceOper: "BillInqRq",
    },
    {
        fieldCalc: null,
        fieldId: "Campo32",
        fieldInq: false,
        fieldLabel: "MONTO A PAGAR",
        fieldType: "Double",
        listValues: [],
        restriction: {
            isReadOnly: false,
            maxLength: null,
            minLength: null,
            maxValue: null,
            minValue: null,
        },
        serviceField: "TotalPmt",
        serviceOper: "PmtUtilAddRq",
    },
];
