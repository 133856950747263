import React, { Component } from "react";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { bool, func } from "prop-types";
import { connect } from "react-redux";
import * as i18n from "util/i18n";
import TextAndButtonsModal from "./TextAndButtonsModal";

class AccountRequestModal extends Component {
    render() {
        const { showModal, dispatch } = this.props;

        return (
            <div className="modal-container">
                <TextAndButtonsModal
                    modalShow={showModal}
                    acceptFunction={() => {
                        dispatch(accountsActions.modalHide());
                    }}
                    headingText={i18n.get("productRequest.drawer.account.noLimit.title")}
                    text={i18n.get("productRequest.drawer.account.noLimit.text")}
                    loading={false}
                    modalId="productRequest.drawer.account.noLimitBalance"
                />
            </div>
        );
    }
}

AccountRequestModal.propTypes = {
    showModal: bool.isRequired,
    dispatch: func.isRequired,
};

const mapStateToProps = (state) => ({
    showModal: accountsSelectors.getShowModal(state),
});

export default connect(mapStateToProps)(AccountRequestModal);
