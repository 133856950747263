import Text from "pages/_components/Text";
import { bool, func, string } from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import Box from "../Box";

const LoadingModal = ({ showModal, content, handleClose, disabledClickOutside = true }) => (
    <div className="modal-container">
        <Modal
            show={showModal}
            {...(handleClose && { onHide: handleClose })}
            {...(disabledClickOutside && { backdrop: "static" })}
            keyboard={false}>
            <Box className="p-7">
                <Box display="flex" fullWidth alignX="center" alignY="center">
                    <img src={require("styles/images//gif/piggyPlane.gif")} alt="loading..." />
                </Box>
                {content && (
                    <Box display="flex" alignX="center" className="mt-5 mb-9 mx-md-12">
                        <Text align="center" size="5" color="text" defaultValue={content} breakWord />
                    </Box>
                )}
            </Box>
        </Modal>
    </div>
);

LoadingModal.propTypes = {
    showModal: bool.isRequired,
    content: string,
    handleClose: func,
    disabledClickOutside: bool,
};
LoadingModal.defaultProps = {
    content: undefined,
    handleClose: () => {},
    disabledClickOutside: true,
};
export default LoadingModal;
