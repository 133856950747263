import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, shape, string } from "prop-types";
import React from "react";
import { Col } from "react-bootstrap";

const MyServicesCard = ({ className, info, isDesktop, logo, subtitle, title }) => (
    <Box
        component="section"
        display="flex"
        alignY="center"
        background="white"
        borderRadius="default"
        className={classNames("px-5 py-5 py-md-4 mt-3 mx-n-5  ", className, {
            "min-height-5-5rem": isDesktop,
        })}
        gap="5">
        <Row alignY="center" {...(!isDesktop && { gapY: "2" })}>
            <Col xs={12} md={6}>
                <Box display="flex" alignY="center">
                    <Box display="flex" position="absolute">
                        <Image
                            src={`images/icons/servicesCategories/${logo}.svg`}
                            {...(!isDesktop && { width: "9", height: "9" })}
                        />
                    </Box>
                    <Box
                        display="flex"
                        column
                        alignY="center"
                        gap={isDesktop ? "3" : "2"}
                        className="pl-10 pl-md-12 ml-0 ml-md-3">
                        <Text color="secondary" size={isDesktop ? "2" : "5"} bold>
                            {title}
                        </Text>
                        {subtitle && (
                            <Text color="text" size={isDesktop ? "5" : "5"}>
                                {subtitle}
                            </Text>
                        )}
                    </Box>
                </Box>
            </Col>
            {info.length !== 0 && (
                <Col xs={12} md={6} className="ml-0 ml-md-9">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    <Box display="flex" column alignY="center" gap={info.length > 2 ? "1" : isDesktop ? "4" : "2"}>
                        {info.map((elem) => (
                            <Box display="inline-block" key={elem?.fieldId} className="pl-10 pl-md-0">
                                <Text
                                    component="label"
                                    color="heading"
                                    size={isDesktop ? "5" : "5"}
                                    className="mb-0 mr-2"
                                    bold>
                                    {elem?.fieldLabel}
                                    {":"}
                                </Text>
                                <Text color="heading" size={isDesktop ? "5" : "5"}>
                                    {elem?.value}
                                </Text>
                            </Box>
                        ))}
                    </Box>
                </Col>
            )}
        </Row>
    </Box>
);

MyServicesCard.propTypes = {
    className: string,
    info: shape({}),
    isDesktop: bool,
    logo: string,
    subtitle: string,
    title: string,
};

MyServicesCard.defaultProps = {
    className: "mb-5 mb-md-9",
    info: [],
    logo: null,
    subtitle: null,
    title: null,
    isDesktop: false,
};

export default resizableRoute(MyServicesCard);
