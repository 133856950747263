import { arrayOf, shape, string, number, objectOf, oneOfType } from "prop-types";
import * as configUtils from "util/config";
import * as arrayUtils from "util/array";

export const smartGroupsOptionsCount = (productTypes, values) => {
    const smartGroupAmount = productTypes.length;
    const selectedSmartGroupAmount = arrayUtils.intersection(
        values,
        productTypes.map((productType) => `ALL_${productType}`),
    ).length;

    return { selectedSmartGroupAmount, smartGroupAmount };
};

export const productsOptionsCount = (products, values) => {
    const productsAmount = products.length;
    const selectedProductsAmount = arrayUtils.intersection(
        values,
        products.map(({ idProduct }) => idProduct),
    ).length;

    return { selectedProductsAmount, productsAmount };
};

const groupShape = {
    idItem: string,
    label: string,
    ordinal: number,
};

groupShape.childrenList = arrayOf(shape(groupShape)).isRequired;

export const groupsPropType = arrayOf(shape(groupShape));

export const permissionsPropType = objectOf(oneOfType([string, arrayOf(string), objectOf(string)]));

export const signatuleLevelsCountToInt = (object) =>
    Object.entries(object).reduce((values, [key, value]) => ({ ...values, [key]: parseInt(value, 10) }), {});

export const getSignatureLevelsOptions = () => {
    const signatureLevels = configUtils
        .getArray("administration.signatures.signatureLevels", [])
        .map((signatureLevel) => ({
            label: signatureLevel,
            value: signatureLevel,
        }));
    if (signatureLevels) {
        signatureLevels.push({
            value: "NO",
            id: "NO",
            label: "No firma",
        });
    }
    return signatureLevels;
};

export const signatureLevelOptions = (options, i18n) =>
    options.map((op) => ({
        ...op,
        label: i18n.get(`administration.signatures.create.signatureLevelsCounts.${op.id}.label`),
    }));
