import { TransactionFilterContext } from "providers/TransactionFilterProvider";
import React from "react";

const withTransactionFilterContext = (ChildComponent) => (props) => (
    <TransactionFilterContext.Consumer>
        {({
            operationType,
            setOperationType,
            dateFrom,
            setDateFrom,
            dateTo,
            setDateTo,
            channelType,
            setChannelType,
            status,
            setStatus,
            downloadFilterTransaction,
            clearFilters,
        }) => (
            <ChildComponent
                {...props}
                operationType={operationType}
                setOperationType={setOperationType}
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
                channelType={channelType}
                setChannelType={setChannelType}
                status={status}
                setStatus={setStatus}
                downloadFilterTransaction={downloadFilterTransaction}
                clearFilters={clearFilters}
            />
        )}
    </TransactionFilterContext.Consumer>
);

export default withTransactionFilterContext;
