import { RevelockPositionContext } from "providers/RevelockPositionProvider";
import React from "react";

const withRevelockPositionContext = (ChildComponent) => (props) => (
    <RevelockPositionContext.Consumer>
        {({ setLocationCustom, setLocationCustomPath, setLocationPathWithParameters }) => (
            <ChildComponent
                {...props}
                setLocationCustom={setLocationCustom}
                setLocationCustomPath={setLocationCustomPath}
                setLocationPathWithParameters={setLocationPathWithParameters}
            />
        )}
    </RevelockPositionContext.Consumer>
);

export default withRevelockPositionContext;
