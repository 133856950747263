import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { func, shape, string, bool, arrayOf } from "prop-types";

import { groupsSelectors } from "reducers/administration";

import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import AdministrationHeading from "pages/administration/_components/Heading";
import PageLoading from "pages/_components/PageLoading";
import MainContainer from "pages/_components/MainContainer";
import MultiSelectField from "pages/_components/fields/MultiSelectField";

const FORM_ID = "administration.groupsOfUser.modify";

class GroupsOfUser extends Component {
    static propTypes = {
        actions: shape({ loadChannelsRequest: func }).isRequired,
        routerActions: shape({ goBack: func.isRequired }).isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        fetching: bool.isRequired,
        availableGroups: arrayOf(
            shape({
                idGroupAsString: string,
                name: string,
            }),
        ),
        selectedGroups: arrayOf(
            shape({
                idGroupAsString: string,
                name: string,
            }),
        ),
    };

    static defaultProps = {
        availableGroups: [],
        selectedGroups: [],
    };

    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadGroupsRequest(match.params.id);
    }

    handleBack = () => {
        const { routerActions } = this.props;
        routerActions.goBack();
    };

    handleSubmit = (values, formikBag) => {
        const { match, actions } = this.props;

        actions.updateGroupsOfUserPreview(
            {
                groups: values.groups,
                idUser: match.params.id,
            },
            formikBag,
        );
    };

    renderContent = () => (
        <Fragment>
            <AdministrationHeading />
            <MainContainer>
                <Formik initialValues={{ groups: "" }} onSubmit={this.handleSubmit} enableReinitialize>
                    {this.renderForm}
                </Formik>
            </MainContainer>
        </Fragment>
    );

    renderForm = ({ isSubmitting }) => {
        const { availableGroups, selectedGroups } = this.props;
        const groupOptionsMap = new Map();
        availableGroups.forEach((group) => {
            groupOptionsMap.set(group.idGroupAsString, group.name);
        });
        const groupOptions = [...groupOptionsMap.keys()];

        const initialOptionsMap = new Map();
        selectedGroups.forEach((group) => {
            initialOptionsMap.set(group.idGroupAsString, group.name);
        });
        const initialOptions = [...initialOptionsMap.keys()];
        return (
            <Form className="above-the-fold">
                <Container className="container--layout flex-grow-1 items-center" gridClassName="form-content">
                    <Container.Column xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <Container.ColumnHeader title="administration.groupsOfUsers.title" />
                        <Container.ColumnBody>
                            <Field
                                component={MultiSelectField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="groups"
                                options={groupOptions}
                                hideLabel
                                textOptionsMap={groupOptionsMap}
                                initialOptions={initialOptions}
                            />
                        </Container.ColumnBody>
                    </Container.Column>
                </Container>
                <Container className="container--layout items-center" gridClassName="form-content">
                    <Container.Column xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <Container.ColumnBody>
                            <Button
                                type="submit"
                                bsStyle="primary"
                                label="administration.permissions.modify"
                                loading={isSubmitting}
                                block
                            />
                        </Container.ColumnBody>
                    </Container.Column>
                </Container>
            </Form>
        );
    };

    render() {
        const { fetching } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="administrationGroupsOfUser" />
                <Head
                    title="administration.groupsOfUsers.title"
                    onBack={this.handleBack}
                    closeLinkTo="/administration/users"
                />
                <PageLoading loading={fetching}>{!fetching && this.renderContent()}</PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: groupsSelectors.getUser(state),
    availableGroups: groupsSelectors.getAvailableGroups(state),
    selectedGroups: groupsSelectors.getSelectedGroups(state),
});

export default connect(mapStateToProps)(GroupsOfUser);
