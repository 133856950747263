import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import globalTypes from "reducers/types/global";
import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    ADD_SKIP_REQUEST: "tour/ADD_SKIP_REQUEST",
    ADD_SKIP_SUCCESS: "tour/ADD_SKIP_SUCCESS",
    ADD_SKIP_FAILURE: "tour/ADD_SKIP_FAILURE",
    READ_SKIPS_COUNTER_REQUEST: "tour/READ_SKIPS_COUNTER_REQUEST",
    READ_SKIPS_COUNTER_SUCCESS: "tour/READ_SKIPS_COUNTER_SUCCESS",
    READ_SKIPS_COUNTER_FAILURE: "tour/READ_SKIPS_COUNTER_FAILURE",
    SET_SKIPS_COUNTER: "tour/SET_SKIPS_COUNTER",
    HIDE: "tour/HIDE",
};

export const INITIAL_STATE = {
    skipsCounter: 0,
    hidden: false,
};

const reducer = createReducer(INITIAL_STATE, {
    [globalTypes.CLEAN_UP]: () => ({
        ...INITIAL_STATE,
    }),
    [types.SET_SKIPS_COUNTER]: (state, action) => ({
        ...state,
        skipsCounter: action.skipsCounter,
    }),
    [types.ADD_SKIP_SUCCESS]: (state, action) => ({
        ...state,
        skipsCounter: action.skipsCounter,
    }),
    [types.READ_SKIPS_COUNTER_SUCCESS]: (state, action) => ({
        ...state,
        skipsCounter: action.skipsCounter,
    }),
    [types.HIDE]: (state) => ({
        ...state,
        hidden: true,
    }),
});

export default persistReducer(
    {
        key: "tour",
        storage: storageSession,
        blacklist: [],
    },
    reducer,
);

export const actions = {
    addSkip: (onFinish) => ({
        type: types.ADD_SKIP_REQUEST,
        number: 1,
        onFinish,
    }),
    finish: (onFinish) => ({
        type: types.ADD_SKIP_REQUEST,
        number: 3,
        onFinish,
    }),
    hide: () => ({
        type: types.HIDE,
    }),
    readSkipsCounter: makeActionCreator(types.READ_SKIPS_COUNTER_REQUEST, "onFinish"),
    setSkipsCounter: makeActionCreator(types.SET_SKIPS_COUNTER, "skipsCounter"),
};

export const selectors = {
    getSkipsCounter: ({ tour }) => tour.skipsCounter,
    isHidden: ({ tour }) => tour.hidden,
};
