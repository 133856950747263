import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { signatureTicketSelectors, detailsSelectors } from "reducers/administration";
import { signatureTicketActions } from "reducers/administration/advanced";

import SignatureTicket from "pages/administration/_components/tickets/SignatureTicket";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";

class SignatureTicketContent extends Component {
    render() {
        return (
            <AdministrationTicket {...this.props}>
                <SignatureTicket {...this.props} />
            </AdministrationTicket>
        );
    }
}

const mapStateToProps = (state) => ({
    user: detailsSelectors.getUser(state),
    signatureLevel: detailsSelectors.getSignatureLevel(state),
    fetching: signatureTicketSelectors.isFetching(state),
    schemeName: "advanced",
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(signatureTicketActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignatureTicketContent);
