import React, { createContext, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { isMobileNativeFunc } from "util/device";
import { REVELOCK_LOCATION_DEFAULT, setPositionRevelock } from "util/revelockMobile/revelockMobile.util";
import { actions as revelockActions } from "reducers/revelock/revelock.reducer";
import { connect } from "react-redux";
import { compose } from "redux";
import { func, node, shape, bool } from "prop-types";

export const RevelockPositionContext = createContext({});
const RevelockPositionProvider = (props) => {
    const { children, location, dispatch, defaultValidationPosition = true } = props;

    const setLocationRevelock = (pathname, prefij) => {
        const screenLocation = `${pathname || REVELOCK_LOCATION_DEFAULT}${prefij || ""}`;
        dispatch(revelockActions.setPosition(screenLocation));

        if (isMobileNativeFunc()) {
            setPositionRevelock(screenLocation);
        }
    };

    const setLocationCustom = (matchCustom, prefij) => {
        let pathRef = matchCustom?.path?.split(":");
        pathRef = pathRef && pathRef.length > 0 ? pathRef[0] : "";
        setLocationRevelock(pathRef, prefij);
    };

    const setLocationCustomPath = (basePath, prefij) => {
        setLocationRevelock(basePath, prefij);
    };

    const setLocationPathWithParameters = (parameters) => {
        const basePath = location?.pathname || "";

        const resultPath = parameters.reduce((acc, item) => `${acc}/${item}`, basePath);

        setLocationRevelock(resultPath, "");
    };

    useEffect(() => {
        if (defaultValidationPosition && defaultValidationPosition === true) {
            setLocationRevelock(location?.pathname, "");
        }
    }, [location]);

    const contextData = { setLocationCustom, setLocationCustomPath, setLocationPathWithParameters };

    return (
        <RevelockPositionContext.Provider value={contextData} {...props}>
            {children}
        </RevelockPositionContext.Provider>
    );
};

RevelockPositionProvider.propTypes = {
    children: node.isRequired,
    dispatch: func.isRequired,
    location: shape({}),
    /**
     * Flag for validate position from provider,
     * if you want validate from component you have to set this property to false in Routes or PrivateRoutes
     * for example:
     * <DefaultLayout
            exact
            path="/editFrequentDestination/:beneficiaryId/:type"
            component={CreateBeneficiary}
            defaultValidationPosition={false}
        />
     */
    defaultValidationPosition: bool,
};
RevelockPositionProvider.defaultProps = {
    location: {},
    defaultValidationPosition: true,
};

export default compose(connect(), withRouter)(RevelockPositionProvider);

export const useRevelockPositionProvider = () => {
    const context = useContext(RevelockPositionContext);
    if (!context) {
        throw new Error("useRevelockPositionProvider only can be used inside RevelockPositionContext");
    }
    return context;
};
