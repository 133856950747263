import Box from "pages/_components/Box";
import PinInput from "pages/_components/fields/PinInput";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { replace } from "react-router-redux";
import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { compose } from "redux";
import { Field } from "formik";
import { actions as authenticateHandlerActions } from "reducers/authenticateHandler";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import { validationLength } from "util/validationSchemaUtil";
import * as Yup from "yup";
import Stepper from "pages/_layouts/DefaultStepperLayout/Stepper";
import classNames from "classnames";
import { steps } from "pages/softToken/steps/utils";
import FormTransition from "../_components/FormTransition";
import LabelWithIcon from "pages/_components/LabelWithIcon";
import { actions as softTokenActions } from "reducers/softToken";
import { routerActions } from "react-router-redux/actions";

const ID_FORM = "token.pin";
const ID_ACTIVITY = `${ID_FORM}.send`;
const TITLE_FORM = "settings.softToken";
const INPUT_LENGTH_PIN = 6;

const CreateTokenPinForm = (props) => {
    const { dispatch, isDesktop } = props;

    const {
        nonWorkingDays = [],
        enabledWeekDays = [true, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
    } = {};

    const [submitDisabled, setSubmitDisabled] = useState(true);

    const preFormReady = () => {
        dispatch(authenticateHandlerActions.preFormReady());
    };

    useEffect(() => {
        preFormReady();
    }, []);

    const validationSchema = () =>
        Yup.object().shape({
            pinCode: validationLength(
                "",
                "token.pin.validation.pin",
                INPUT_LENGTH_PIN,
                INPUT_LENGTH_PIN,
                true,
                undefined,
            ),
            pinCodeRepeat: Yup.string().required(i18n.get(`${ID_FORM}.pinCode.empty`))
            .oneOf([Yup.ref("pinCode"), null], "Los pines deben coincidir"),
        });

    const cancelAction = () => {
        dispatch(
            replace({
                pathname: "/settings/authenticatorHandler",
                state: { transition: "transition-flow-close" },
            }),
        );
    };

    const handleBack = () => {
        dispatch(softTokenActions.setActivationStep("securityMethod"));
        dispatch(
            routerActions.push({
                pathname: "/activateSoftToken",
            }),
        );
    };
    
    const renderFields = (setFieldValue, values, setValues, renderScheduler, errors, setErrors) => {
        const handlePinCodeChange = () => {
            setErrors({});
        };
        const handlePinCodeRepeatChange = () => {
            setErrors({});
        };

        const isValidPin = values.pinCode && values.pinCodeRepeat && values.pinCodeRepeat === values.pinCode;
        setSubmitDisabled(!isValidPin);

        const alertMessage =
            isValidPin ? (<Box fullWidth className="mb-7 mb-md-7 mt-3"><LabelWithIcon type="info" text="enrollment.step1.code.ok" /></Box>) : (<></>);
        return (
            <Box
                background="white"
                borderRadius="lg"
                className={classNames("mt-3 ", {
                    "p-7 box-shadow-small": isDesktop,
                    "pt-6 full-width security-pin": !isDesktop,
                })}>
                <Box>
                    <Stepper className="mb-6" stepsList={steps} currentStep={2} completed={1} showLabelMobile />
                    <Box>
                        <Text
                            className={classNames({
                                "line-height-125": isDesktop,
                                "line-height-15": !isDesktop,
                            })}
                            labelKey="softToken.activate.step2.createPin.title"
                            size={isDesktop ? "2" : "1"}
                            bold
                        />
                    </Box>
                    <Box>
                        <Text
                            component="p"
                            size="3"
                            color="heading"
                            className="mt-5"
                            labelKey="token.pin.create.text"
                            INPUT_LENGTH={INPUT_LENGTH_PIN}
                        />
                        <Field
                            name="pinCode"
                            component={PinInput}
                            inputLenght={INPUT_LENGTH_PIN}
                            placeholder="*"
                            maxLength={1}
                            defaultLabelText={i18n.get("token.pin.create.label")}
                            // labelNoMarginTop
                            // showLabel={false}
                            noMarginBottom
                            className="mt-7"
                            isMasked
                            handleChangeProp={() => handlePinCodeChange(errors, setErrors)}
                        />
                        <Field
                            name="pinCodeRepeat"
                            component={PinInput}
                            inputLenght={INPUT_LENGTH_PIN}
                            placeholder="*"
                            maxLength={1}
                            defaultLabelText={i18n.get("token.pinRepeat.create.label")}
                            // labelNoMarginTop
                            // showLabel={false}
                            noMarginBottom
                            className="mt-7"
                            // defaultMessage={message}
                            isMasked
                            handleChangeProp={() => handlePinCodeRepeatChange(errors, setErrors)}
                        />
                        {alertMessage}
                    </Box>
                </Box>
            </Box>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        renderTicket: null,
        useDefaultSubmit: true,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        validationSchema,
        showSchedulerMessage: false,
        topDisclaimer: "",
        showFilterChips: false,
        // cancelAction,
        formFlex: true,
        submitButtonLabel: "global.continue",
        handleBackCustom: handleBack,
        submitDisabled
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    loggedUser: sessionSelectors.getUser(state),
});

CreateTokenPinForm.propTypes = {
    dispatch: func.isRequired,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    location: shape({}),
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    fromTransaction: bool,
    loggedUser: shape({
        bankType: string,
    }),
};
CreateTokenPinForm.defaultProps = {
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    location: {},
    fromTransaction: false,
    loggedUser: null,
};
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(CreateTokenPinForm));
