import { call, put, takeLatest } from "redux-saga/effects";
import * as debitCards from "middleware/debitCards";
import * as i18n from "util/i18n";
import { types, actions } from "reducers/debitCards";
import { adjustIdFieldErrors } from "util/form.js";
import { actions as notificationActions } from "reducers/notification";
import { actions as changeStatusProductActions } from "reducers/changeStatusProduct";

const sagas = [takeLatest(types.LIST_REQUEST, listRequest), takeLatest(types.REQUEST_PIN_REQUEST, requestPinRequest)];

export default sagas;

function* listRequest() {
    const response = yield call(debitCards.listRequest);

    if (response.type === "W") {
        yield put(actions.listFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["debitcards"]));
    } else {
        const { debitCards: responseCards } = response.data.data;
        yield put(actions.listSuccess(responseCards));
    }
}

function* requestPinRequest({ data }) {
    const { parametersSubmit, credentialsForm, formikBag } = data;
    try {
        const response = yield call(debitCards.requestPinRequest, parametersSubmit.cardNumber, credentialsForm);
        if (response?.status === 200) {
            if (response.data.code === "COR020W") {
                if (response.data.data.cardNumber) {
                    yield put(changeStatusProductActions.modalHide());
                    yield put(
                        notificationActions.showNotification(response.data.data.cardNumber, "error", ["debitcards"]),
                    );
                } else {
                    formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                }
            } else if (response.data.code === "COR000I") {
                yield put(changeStatusProductActions.modalHide());
                yield put(
                    notificationActions.showNotification(
                        i18n.get("debitCards.snackbar.requestPin.messageOk"),
                        "success",
                        ["debitcards"],
                    ),
                );
            } else {
                yield put(changeStatusProductActions.modalHide());
                yield put(
                    notificationActions.showNotification(
                        i18n.get("debitCards.snackbar.requestPin.messageError"),
                        "error",
                        ["debitcards"],
                    ),
                );
            }
        } else {
            yield put(changeStatusProductActions.modalHide());
            yield put(notificationActions.showNotification(response.data.message, "error", ["debitcards"]));
        }
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
    } catch (error) {
        yield put(changeStatusProductActions.modalHide());
        yield put(
            notificationActions.showNotification(i18n.get("debitCards.snackbar.requestPin.messageError"), "error", [
                "debitcards",
            ]),
        );
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
    }
}
