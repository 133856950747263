import React from "react";
import { bool, func, shape, string } from "prop-types";
import { Link } from "react-router-dom";

import Box from "pages/_components/Box";
import Info from "pages/_components/Info";
import * as i18n from "util/i18n";
import BolivarianoCheckbox from "pages/_components/fields/BolivarianoCheckbox";

const UserList = ({ users, administrationScheme, usersInfo, showCheckBox, handleCheckClick, selectedUsers }) => {
    if (!users.length) {
        return null;
    }

    const path = {
        simple: "simple/permissions",
        medium: "medium/details",
        advanced: "advanced/details",
    };

    return (
        <Box>
            {users.map((user) => {
                const { idUser, fullName, documentType, documentNumber, idUserStatus } = user;
                const dataUser = usersInfo && usersInfo[idUser] ? usersInfo[idUser] : undefined;
                const signatureLevel =
                    dataUser?.signatureLevel !== null && dataUser?.hasSignature ? dataUser.signatureLevel : "N";
                const status = dataUser ? dataUser.status : "";
                const massiveEnabled = dataUser ? dataUser.massiveEnabled : false;
                const defaultChecked = selectedUsers && selectedUsers.has(idUser);

                const massiveDissabledQuantity = Object.values(usersInfo).filter((userInfo) => !userInfo.massiveEnabled)
                    .length;

                return (
                    <Box
                        display="flex"
                        alignX="between"
                        alignY="center"
                        className="box-shadow-small border-radius-lg mb-4">
                        <Link
                            className="full-width border-radius-lg"
                            to={`/administration/${path[administrationScheme]}/${idUser}`}>
                            <Box
                                display="flex"
                                alignY="center"
                                fullWidth
                                className="p-5 border-radius-lg justify-content-between">
                                {showCheckBox && (
                                    <Box>
                                        <BolivarianoCheckbox
                                            name={`checkbox-${idUser}`}
                                            value="testing"
                                            defaultChecked={defaultChecked}
                                            onChange={() => {
                                                handleCheckClick(user, massiveDissabledQuantity);
                                            }}
                                            hideLabel
                                            disabled={!massiveEnabled}
                                        />
                                    </Box>
                                )}
                                <Info
                                    className="info-user-list"
                                    labelKey="administration.users.list.header.name"
                                    text={fullName}
                                />
                                <Info
                                    className="info-user-list"
                                    labelKey="administration.users.list.header.identification"
                                    text={`${documentType} ${documentNumber}`}
                                />
                                <Info
                                    className="info-user-list"
                                    labelKey="administration.users.list.header.signature"
                                    text={i18n.get(
                                        `administration.signatures.create.signatureLevelsCounts.${signatureLevel}.label`,
                                    )}
                                />
                                <Box>
                                    <Info
                                        className={`info-user-list info-user-${status}`}
                                        text={i18n.get(`global.${status}`)}
                                    />
                                </Box>
                            </Box>
                        </Link>
                    </Box>
                );
            })}
        </Box>
    );
};

UserList.propTypes = {
    administrationScheme: string,
    usersInfo: shape({
        signatureLevel: string,
        massiveEnabled: bool,
    }),
    showCheckBox: bool,
    handleCheckClick: func,
    selectedUsers: shape({}),
};

UserList.defaultProps = {
    administrationScheme: "advanced",
    usersInfo: undefined,
    showCheckBox: false,
    handleCheckClick: () => {},
    selectedUsers: {},
};

export default UserList;
