import { call, put, select, takeLatest } from "redux-saga/effects";
import { replace } from "react-router-redux";
import queryString from "query-string";

import * as creditCards from "middleware/creditCards";
import * as i18n from "util/i18n";
import { NO_TRANSITION } from "constants.js";
import { types, actions, selectors } from "reducers/creditCard";
import { actions as notificationActions } from "reducers/notification";
import * as creditCardsCache from "util/creditCardsCache";
import { actions as productActions } from "reducers/products";

const sagas = [takeLatest(types.LIST_REQUEST, listRequest)];

export default sagas;

function* listRequest({ path }) {
    // valido si la data del cache es valida
    if (creditCardsCache.isCreditCardListCacheStatusOk()) {
        const responseCards = yield select(selectors.getList);
        yield call(redirectFlow, responseCards, path);
    } else {
        const response = yield call(creditCards.listRequest);

        if (response.type === "W") {
            yield put(actions.listFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "creditCardsPayment",
                    "creditCardsList",
                ]),
            );
        } else {
            const { creditCards: responseCards } = response.data.data;
            yield call(redirectFlow, responseCards, path);
        }
    }
}

function* redirectFlow(responseCards, path) {
    yield put(actions.hideOptions());

    yield put(actions.listSuccess(responseCards));

    if (responseCards.length === 1 && path) {
        const { idProduct } = responseCards[0];

        // listRequest method is reused on credit cards payment which should redirect to a dynamic
        // form instead of credit card detail
        if (path.startsWith("/form")) {
            yield put(
                replace({
                    pathname: path,
                    search: queryString.stringify({ creditCard: idProduct }),
                }),
            );
        } else {
            yield put(productActions.setSelectProduct(responseCards[0]));
            yield put(actions.setExtraDataDetail(responseCards[0]));
            yield put(
                replace({
                    pathname: `${path}/${idProduct}`,
                    state: { transition: NO_TRANSITION },
                }),
            );
        }
    }
}
