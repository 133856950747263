/* eslint-disable*/
import { call, put, takeEvery, select } from "redux-saga/effects";
import { replace } from "react-router-redux";
import * as payService from "middleware/payService";
import { actions, types, selectors } from "reducers/payService";
import { actions as notificationActions } from "reducers/notification";
import * as config from "util/config";
import * as i18n from "util/i18n";
import { capitalizeFirstLetter } from "util/string";

const sagas = [
    takeEvery(types.LIST_PAYSERVICE_REQUEST, listPayServiceRequest),
    takeEvery(types.FAVORITE_REQUEST, setFavoritePayService),
    takeEvery(types.UPDATE_REQUEST, updatePayService),
    takeEvery(types.CREATE_REQUEST, createPayService),
    takeEvery(types.GET_REQUEST, getPayServiceRequest),
    takeEvery(types.DELETE_REQUEST, deletePayService),
    takeEvery(types.LIST_CATEGORIES_REQUEST, listCategoriesRequest),
    takeEvery(types.LIST_RULES_BILLER_REQUEST, listRulesBillerRequest),
];

export default sagas;

function* listPayServiceRequest({isFastRecharge}) {
    const response = yield call(payService.listPayService, "-1", isFastRecharge);
    if (response && response.status === 200) {
        const { paymentUserList } = response.data.data;
        const list = config.get("payments.services.defaultCategories");
        const serviceCategoriesList = list===null ? []: JSON.parse(list);
        //union con detalle de categorías por idCategory
        paymentUserList?.elementList.map((element) => {
            const category = serviceCategoriesList.filter((el) => el.idServicePayment === element?.idCategory);
            element.category = category.length > 0 ? category[0] : {};
            element.extraData = JSON.parse(element.extraData);
            element.billInqRq = JSON.parse(element?.billInqRq);
            element.pmtUtilAddRq = JSON.parse(element?.pmtUtilAddRq);
        });

        if(isFastRecharge){
            yield put(actions.listFastRechargeSuccess(paymentUserList?.elementList));
        }else{
            yield put(actions.listPayServiceSuccess(paymentUserList?.elementList));
            yield put(actions.listFastRechargeSuccess(paymentUserList?.elementList.filter(el=> el.fastRecharge === true)));
        }
        
    }
}

function* setFavoritePayService({ payServiceObj }) {
    const response = yield call(
        payService.setFavoritePayService,
        payServiceObj?.idPaymentUser,
        payServiceObj?.favorite,
    );
    if (response && response.status === 200) {
        const paymentUserList = yield select(selectors.getListPayService);
        paymentUserList.map((obj) => {
            obj.idPaymentUser === payServiceObj.idPaymentUser && (obj.favorite = !payServiceObj?.favorite);
        });

        yield put(actions.favoriteSuccess(paymentUserList));
    }
}

function* updatePayService({ payServiceObj }) {
    payServiceObj.billInqRq = JSON.stringify(payServiceObj.billInqRq);
    payServiceObj.pmtUtilAddRq = JSON.stringify(payServiceObj.pmtUtilAddRq);
    const response = yield call(payService.updatePayService, payServiceObj);
    if (response && response.status === 200) {
        yield put(replace("/servicePayments"));
        const message = payServiceObj?.fastRecharge ? "servicePayments.fast.recharge.modify.success" : "servicePayment.modify.success";
        yield put(notificationActions.showNotification(i18n.get(message), "success", ["servicePayments"]));
    }else{
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["servicePayments"]));
    }
}

function* createPayService({ payServiceObj }) {
    payServiceObj.billInqRq = JSON.stringify(payServiceObj.billInqRq);
    payServiceObj.pmtUtilAddRq = JSON.stringify(payServiceObj.pmtUtilAddRq);
    const response = yield call(payService.createPayService, payServiceObj);
    if (response && response.status !== 200) {
        yield put(
            notificationActions.showNotification(i18n.get("servicePayments.error.save.payment"), "error", [
                "form",
            ]),
        );
    }
}


function* getPayServiceRequest({idPaymentUser}) {
    const response = yield call(payService.getPayService, idPaymentUser);
    if (response && response.status === 200) {
        const { paymentUser } = response.data.data;
        if(paymentUser){
            const list = config.get("payments.services.defaultCategories");
            const serviceCategoriesList = list===null ? []: JSON.parse(list);
            //union con detalle de categorías por idCategory
            const category = serviceCategoriesList.filter((el) => el.idServicePayment === paymentUser?.idCategory);
            paymentUser.category =  category.length > 0 ? category[0] : {};
            paymentUser.extraData = JSON.parse(paymentUser?.extraData);
            paymentUser.billInqRq = JSON.parse(paymentUser?.billInqRq);
            paymentUser.pmtUtilAddRq = JSON.parse(paymentUser?.pmtUtilAddRq);
            yield put(actions.getPayServiceSuccess(paymentUser));
        }else{
            yield put(replace("/servicePayments"));
        }
    }
}

function* deletePayService({ payServiceObj }) {
    const response = yield call(
        payService.deletePayService,
        payServiceObj?.idPaymentUser,
    );
    if (response && response.status === 200) {
        const paymentUserList = yield select(selectors.getListPayService);
      
        const filterList = paymentUserList.filter(el => el.idPaymentUser !== payServiceObj.idPaymentUser);
        const filterFastRechargeList = filterList.filter(el => el.fastRecharge === true)
        const message = payServiceObj?.fastRecharge ? "servicePayments.fast.recharge.delete.success" : "servicePayment.delete.success";

        yield put(actions.deleteSuccess(filterList, filterFastRechargeList));
        yield put(notificationActions.showNotification(i18n.get(message), "success", ["servicePayments"]));
    }else{
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["servicePayments"]));
    }
}

function* listCategoriesRequest({categoryId}) {
    const response = yield call(payService.listCategories, categoryId);
    if (response && response.status === 200) {
        const { billers } = response.data.data;
        if(billers){
            const tmp_billers = billers.map(el=>{return{...el,name:capitalizeFirstLetter(el?.name ? el?.name.toLowerCase():"")}})
            yield put(actions.listCategoriesSuccess(tmp_billers));
        }else{
            yield put(replace("/addNewServiceCategory"));
        }
    }
}

function* listRulesBillerRequest({idBiller}) {
    const response = yield call(payService.listRulesBiller, idBiller);
    if (response && response.status === 200) {
        const { field } = response.data.data;
        if(field){
            yield put(actions.listRulesBillerSuccess(field));
        }else{
            yield put(replace("/addNewServiceCategory"));
        }
    }
}