import React, { Component } from "react";
import { arrayOf, func, string, bool, shape, oneOfType } from "prop-types";
import { compose } from "redux";

import formField from "pages/forms/_components/_fields/_commons/formField";
import TagsInput from "pages/_components/fields/TagsInput";

import withFocus from "pages/_components/withFocus";

class Emaillist extends Component {
    static propTypes = {
        value: arrayOf(
            oneOfType([
                shape({
                    id: string,
                    text: string,
                }),
                string,
            ]),
        ),
        editing: bool,
        setTagValue: func.isRequired,
        setAllTagValue: func.isRequired,
        removeTagValue: func.isRequired,
        placeholder: string,
        idField: string.isRequired,
    };

    static defaultProps = {
        editing: true,
        placeholder: "",
        value: undefined,
    };

    render() {
        const { editing, value, placeholder, idField, setTagValue, setAllTagValue, removeTagValue } = this.props;

        if (editing) {
            return (
                <TagsInput
                    idField={idField}
                    removeTagValue={removeTagValue}
                    placeholder={placeholder}
                    customDelimiter={[",", " "]}
                    validationRegex={new RegExp(/^[A-Za-z][\w.+-_]{0,99}@[\w.+-_]{1,99}\.[\w.+-_]{2,50}$/)}
                    setTagValue={setTagValue}
                    setAllTagValue={setAllTagValue}
                    allowUnique
                />
            );
        }
        return <span>{value.length > 0 ? value.join(", ") : ""}</span>;
    }
}

export default compose(
    withFocus,
    formField({
        formClass: "form-group--emailList",
    }),
)(Emaillist);
