import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { Field } from "formik";
import { arrayOf, bool, func, objectOf, oneOfType, shape, string } from "prop-types";

import Col from "react-bootstrap/lib/Col";
import Row from "pages/_components/Row";
import FieldError from "pages/_components/fields/FieldError";
import Selector from "pages/_components/fields/formik/Selector";
import TextField from "pages/_components/fields/TextField";
import * as i18n from "util/i18n";

class RegisterQuestionsField extends Component {
    static propTypes = {
        field: shape({
            name: string,
        }).isRequired,
        form: shape({
            errors: objectOf(oneOfType([string, objectOf(string)])),
            touched: objectOf(oneOfType([arrayOf(bool), bool, objectOf(bool)])),
        }).isRequired,
        handleChange: func,
        hideLabel: bool,
        idForm: string.isRequired,
        maxQuestions: string.isRequired,
        questions: arrayOf({ label: string, value: string }).isRequired,
        showFirstErrorWhenEquals: bool,
        isDesktop: bool,
    };

    static defaultProps = {
        handleChange: null,
        hideLabel: false,
        showFirstErrorWhenEquals: false,
        isDesktop: false,
    };

    state = {
        rowsToShow: [],
    };

    componentWillMount() {
        const { maxQuestions } = this.props;
        const rows = [];
        let i = 0;
        const len = maxQuestions;
        while (++i <= len) {
            rows.push(i);
        }

        this.setState({
            rowsToShow: rows,
        });
    }

    sameErrorForAll = (errors, field, optionsLength) =>
        optionsLength > 1 &&
        Object.keys(errors[field.name]).length === optionsLength &&
        Object.values(errors[field.name]).every((error) => Object.values(errors[field.name])[0] === error);

    renderErrors = (errors, field, options, showFirstErrorWhenEquals) => {
        if (showFirstErrorWhenEquals && this.sameErrorForAll(errors, field, options.length)) {
            return <FieldError error={Object.values(errors[field.name])[0]} />;
        }

        return Object.keys(errors[field.name]).map((error) => (
            <FieldError error={`${error} - ${errors[field.name][error]}`} key={error} />
        ));
    };

    handleChangueQuestions = () => {};

    filterQuestions = (index) => {
        const { field, questions } = this.props;
        const { rowsToShow } = this.state;

        const filtered = questions.filter((q) => {
            let show = true;
            rowsToShow.forEach((i) => {
                if (i !== index && field?.value[i] && field?.value[i]?.question === q.value) {
                    show = false;
                }
            });
            return show;
        });

        return filtered;
    };

    render() {
        const { field, form, handleChange, hideLabel, idForm, isDesktop } = this.props;
        const { rowsToShow } = this.state;
        const { errors } = form;

        return (
            <Fragment>
                {rowsToShow.map((index) => {
                    const hasError = errors && errors[index] && errors[index].question !== undefined;
                    const hasErrorAnswer = errors && errors[index] && errors[index].answer !== undefined;
                    return (
                        <Row
                            gapX="7"
                            gapY="0"
                            className={classNames({
                                "row-template-1fr": !isDesktop,
                            })}>
                            <Col xs="6">
                                <Field
                                    className={classNames({
                                        "has-error": hasError,
                                    })}
                                    component={Selector}
                                    handleChange={handleChange}
                                    hideLabel={hideLabel}
                                    idForm={idForm}
                                    key={index}
                                    name={`${field.name}.${index}.question`}
                                    options={this.filterQuestions(index)}
                                    labelText={i18n.get("securityQuestions.register.questions.select.label", "", {
                                        index,
                                    })}
                                    placeholder={i18n.get("securityQuestions.register.questions.select.placeholder")}
                                    applyCustomStyles
                                />
                                <div
                                    className={classNames({
                                        "has-error": hasError,
                                    })}>
                                    {hasError && <FieldError error={errors[index]?.question} />}
                                </div>
                            </Col>
                            <Col xs="6">
                                <Field
                                    component={TextField}
                                    idForm={idForm}
                                    name={`${field.name}.${index}.answer`}
                                    type="text"
                                    autoComplete="off"
                                    labelText={i18n.get("securityQuestions.register.questions.answer.label", "", {
                                        index,
                                    })}
                                    placeholderText={i18n.get(
                                        "securityQuestions.register.questions.answer.placeholder",
                                    )}
                                    // validationRegularExpresion="^[a-zA-Z0-9 áéíóúñ@]*$"
                                    pattern="^[a-zA-Z0-9]*$"
                                    className={classNames("input-uppercase", {
                                        "has-error-answer": hasErrorAnswer,
                                    })}
                                    copyEnabled={false}
                                />
                                <div
                                    className={classNames({
                                        "has-error": hasErrorAnswer,
                                    })}>
                                    {hasErrorAnswer && <FieldError error={errors[index]?.answer} />}
                                </div>
                            </Col>
                        </Row>
                    );
                })}
            </Fragment>
        );
    }
}

export default RegisterQuestionsField;
