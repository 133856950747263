import ServiceRemarkableInfo from "pages/servicePayments/_components/ServiceRemarkableInfo";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, shape, arrayOf, func } from "prop-types";
import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import withRouter from "react-router-dom/withRouter";
import { connect } from "react-redux";
import { compose } from "redux";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import * as i18n from "util/i18n";
import { getCategorySelected } from "./hooks/servicePaymentsHooks";

const AddNewServiceCompany = (props) => {
    const { isDesktop, history, dispatch, listCategories, fetching, ...rest } = props;
    const categorySelected = getCategorySelected(rest.match.params?.idServicePayment);

    useEffect(() => {
        dispatch(payServiceActions.listCategoriesRequest(rest.match.params?.idServicePayment));
    }, []);

    const handleBack = () => {
        history.goBack();
    };

    return (
        <>
            <Notification scopeToShow="servicePayments" />
            <Head title="servicePayments.new.title" onBack={handleBack} />
            <MainContainer showLoader={fetching}>
                {categorySelected.length > 0 && (
                    <ServiceRemarkableInfo
                        title={i18n.get(`servicePayment.category.${categorySelected[0].idServicePayment}`)}
                        logo={categorySelected[0].logo}
                    />
                )}

                <Text
                    component="h2"
                    color="primary"
                    size="5"
                    bold
                    className="mb-4"
                    labelKey="servicePayments.category.title"
                />

                <Row gapY={isDesktop ? "5" : "3"}>
                    {listCategories.map((elem) => (
                        <Col xs={12} md={6} key={elem.billerId}>
                            <Link
                                aria-label={elem.billerId}
                                aria-describedby={elem.category}
                                to={`/addNewService/addNewServiceForm/${elem.billerId}`}
                                onClick={() => dispatch(payServiceActions.setSelectedBiller(elem))}>
                                <Box
                                    component="article"
                                    display="flex"
                                    alignY="center"
                                    background="white"
                                    backgroundHover="background-secondary"
                                    borderRadius="md"
                                    className="p-5"
                                    pointer>
                                    <Text color="text" size="5">
                                        {elem.name}
                                    </Text>
                                </Box>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: payServiceSelectors.isFetching(state),
    listCategories: payServiceSelectors.getCategories(state),
});

AddNewServiceCompany.propTypes = {
    fetching: bool,
    isDesktop: bool,
    listCategories: arrayOf(shape({})),
    history: shape({}).isRequired,
    dispatch: func.isRequired,
};

AddNewServiceCompany.defaultProps = {
    fetching: false,
    isDesktop: false,
    listCategories: [],
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(AddNewServiceCompany));
