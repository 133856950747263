import classNames from "classnames";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Image from "pages/_components/Image";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import Text from "pages/_components/Text";
import { bool, number, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import Box from "pages/_components/Box";
import I18n from "pages/_components/I18n";

class CreditCardMovement extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isTablet: bool.isRequired,
        data: shape({
            idProduct: string,
            idStatement: number,
            date: string,
            concept: string,
            sourceAmount: number,
            sourceAmountCurrency: string,
            amount: number,
            type: string,
        }).isRequired,
    };

    render() {
        const { data, isDesktop, isTablet } = this.props;
        const { concept, date, sourceAmount, note, type, idStatement, amount, sourceAmountCurrency } = data;
        return (
            <Box
                className="row-account-mov"
                pointer
                // onClick={() => {
                //     history.push(`/accounts/${idAccount}/movement`);
                //     this.handleMovementClick(data);
                // }}
            >
                {isDesktop ? (
                    <>
                        <Box className="p-5" display="flex" alignY="center">
                            <FormattedDate size="6" date={date} />
                        </Box>
                        <Box className="p-5">
                            <span className="size-7">{concept}</span>
                        </Box>
                        <Box className="p-5">
                            <span className="size-7">{idStatement}</span>
                        </Box>
                        <Box className="p-5">
                            <span className="size-7">{type}</span>
                        </Box>

                        <Box className="p-5 justify-content-flex-end" display="flex" alignY="center">
                            <FormattedAmount className="data-amount data-numeric size-6" noCurrency quantity={amount} />
                        </Box>
                    </>
                ) : (
                    <>
                        <Box>
                            <Box className="mb-3">
                                <FormattedDate size="6" date={date} />
                            </Box>
                            <Box className="mb-2">
                                <I18n id="accounts.movementsCut.download.file.descriptionHeader" />
                            </Box>
                            <Box className="text-bold size-6">
                                <span className="size-7">{concept}</span>
                            </Box>
                        </Box>
                        <Box>
                            <Box display="flex" alignX="flex-end" className="mb-3 size-7">
                             <FormattedAmount className="data-amount data-numeric" currency={sourceAmountCurrency} quantity={amount} size={8}/>
                            </Box>
                            <Box display="flex" alignX="flex-end" className="mb-2 size-6">
                             <span className="size-6">Referencia: {idStatement}</span>
                            </Box>
                            <Box display="flex" alignX="flex-end" className="mb-2 size-6">
                             <span className="size-6">Tipo de movimiento: {type}</span>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        );
    }
}

export default CreditCardMovement;
