import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import ShortCutBtn from "pages/_components/ShortCutBtn";
import Text from "pages/_components/Text";
import { bool, func, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { actions as statusActions } from "reducers/status";
import * as i18nUtils from "util/i18n";

const OneTouchAccess = ({ landingPage, dispatch, isMobile, hasActiveSession }) => {
    const handleClose = () => {
        dispatch(push("/home"));
    };

    const send = (loginType) => {
        switch (loginType) {
            case "email": {
                if (isMobile) {
                    dispatch(push("/loginStep1"));
                }
                break;
            }
            default: {
                // TODO Undefined
                break;
            }
        }
    };

    const buttonsArrayOptions = {
        transfersAccess: [
            {
                image: "images/shortCutBtn/SCBaccounts.svg",
                label: "landing.transfer.access.transfers.internal",
                click: (event) => {
                    dispatch(statusActions.saveLastHref("/formCustom/transferInternal"));
                    send("email", event);
                },
                href: "/formCustom/transferInternal",
            },
            {
                image: "images/shortCutBtn/SCBthirdParties.svg",
                label: "landing.transfer.access.transfers.thirdParties",
                click: (event) => {
                    dispatch(statusActions.saveLastHref("/formCustom/transferLocal"));
                    send("email", event);
                },
                href: "/formCustom/transferLocal",
            },
            {
                image: "images/shortCutBtn/SCBworld.svg",
                label: "landing.transfer.access.transfers.foreign",
                click: (event) => {
                    dispatch(statusActions.saveLastHref("/formCustom/transferForeign"));
                    send("email", event);
                },
                href: "/formCustom/transferForeign",
            },
            {
                image: "images/shortCutBtn/SCBplus.svg",
                label: "landing.new.beneficiary",
                click: (event) => {
                    dispatch(statusActions.saveLastHref("/createFrequentDestination"));
                    send("email", event);
                },
                href: "/createFrequentDestination",
            },
        ],
        paymentsAccess: [
            {
                image: "images/shortCutBtn/SCBcard.svg",
                label: "landing.payment.access.pay.creditcard",
                click: (event) => {
                    dispatch(statusActions.saveLastHref("/formCustom/payCreditCardLocal"));
                    send("email", event);
                },
                href: "/formCustom/payCreditCardLocal",
            },
            {
                image: "images/shortCutBtn/SCBcardPayment.svg",
                label: "landing.payment.access.reload.creditcard",
                click: (event) => {
                    dispatch(statusActions.saveLastHref("/formCustom/rechargeCreditCardLocal"));
                    send("email", event);
                },
                href: "/formCustom/rechargeCreditCardLocal",
            },
            {
                image: "images/shortCutBtn/SCBpayments.svg",
                label: "landing.payment.access.pay.services.recharges",
                click: (event) => {
                    dispatch(statusActions.saveLastHref("/servicePayments"));
                    send("email", event);
                },
                href: "/servicePayments",
            },
            {
                image: "images/shortCutBtn/SCBbag.svg",
                label: "landing.payment.access.loan.pay",
                click: (event) => {
                    dispatch(statusActions.saveLastHref("/formCustom/payLoan"));
                    send("email", event);
                },
                href: "/formCustom/payLoan",
            },
            {
                image: "images/shortCutBtn/SCBplus.svg",
                label: "landing.new.beneficiary",
                click: (event) => {
                    dispatch(statusActions.saveLastHref("/createFrequentDestination"));
                    send("email", event);
                },
                href: "/createFrequentDestination",
            },
        ],
    };
    const buttonsArray = buttonsArrayOptions[landingPage];

    return (
        <Box display="flex" column alignY="flex-start" fullHeight fullWidth>
            <Box component="header" display="flex" alignX="between" background="primary" className="p-2 pt-2-safe">
                <Box display="flex" alignX="flex-start" alignY="center" className="p-4">
                    <Image height="9" src="images/icons/MbBolivarianoLogo.svg" />
                </Box>

                <Box display="flex" alignX="flex-end" alignY="center" className="p-4">
                    <Button
                        image="images/icons/cross16.svg"
                        className="btn-login py-4"
                        bsStyle="link"
                        onClick={handleClose}
                        inverse
                    />
                </Box>
            </Box>
            <Box
                display="flex"
                alignY="center"
                alignX="center"
                column
                background="primary"
                className="py-7"
                fullHeight
                fullWidth>
                <Box display="flex" className="mb-auto">
                    <Text size="3" color="inverse" bold component="h1">
                        {landingPage === "transfersAccess" && i18nUtils.get("login.step0.short.cut.transfer")}
                        {landingPage === "paymentsAccess" && i18nUtils.get("login.step0.short.cut.payments")}
                    </Text>
                </Box>

                <Box display="flex" alignY="center" className="px-12 mb-auto" fullWidth>
                    <Row gapX="3" gapY="5" className="height-auto px-4">
                        {buttonsArray.map((el) => (
                            <Col xs={6} className="height-auto">
                                <Box display="flex" alignX="center" fullWidth fullHeight>
                                    {hasActiveSession ? (
                                        <ShortCutBtn
                                            key={`${el.label}_${el.image}`}
                                            image={el.image}
                                            label={el.label}
                                            href={el.href}
                                            inverse
                                        />
                                    ) : (
                                        <ShortCutBtn
                                            key={`${el.label}_${el.image}`}
                                            image={el.image}
                                            label={el.label}
                                            onClick={el.click}
                                            inverse
                                        />
                                    )}
                                </Box>
                            </Col>
                        ))}
                    </Row>
                </Box>
            </Box>
        </Box>
    );
};

OneTouchAccess.propTypes = {
    landingPage: string,
    dispatch: func.isRequired,
    isMobile: bool,
    hasActiveSession: bool,
};

OneTouchAccess.defaultProps = {
    landingPage: "",
    isMobile: false,
    hasActiveSession: false,
};

export default resizableRoute(OneTouchAccess);
