import React from "react";
import { func, bool } from "prop-types";
import Box from "pages/_components/Box";
import Checkbox from "pages/_components/Checkbox";
// import View from "../../view/View";
// import Checkbox from "../../checkbox/Checkbox";

function BulkCheckBoxCell({ toggleSelectedProps, disabled, isSelected }) {
    const handleClick = (e) => {
        e.stopPropagation();
    };

    if (disabled) {
        return (
            <Box className="checkbox">
                <span className="checkbox__input disabled" onClick={handleClick} />
            </Box>
        );
    }

    const selectedProps = toggleSelectedProps();

    const checkboxProps = {
        ...selectedProps,
        indeterminate: `${selectedProps ? selectedProps.indeterminate : false}`,
    };

    return (
        <Box onClick={handleClick}>
            <Checkbox {...checkboxProps} name="bulk_checkbox" value={isSelected} />
        </Box>
    );
}

BulkCheckBoxCell.propTypes = {
    toggleSelectedProps: func.isRequired,
    disabled: bool,
    isSelected: bool,
};

BulkCheckBoxCell.defaultProps = {
    disabled: false,
    isSelected: false,
};

export default BulkCheckBoxCell;
