import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import { objectOf, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors } from "reducers/administration/common/administrationTicket";

class RestrictionsUserDeleteTicketData extends Component {
    static propTypes = {
        transactionData: shape({
            data: objectOf(string),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
    };

    render() {
        const { transactionData } = this.props;
        if (transactionData.data) {
            return (
                <Box display="flex" column>
                    <Text
                        component="h3"
                        labelKey="administration.restrictions.user.deleted.all"
                        color="primary"
                        className="m-o"
                        size="5"
                        bold
                    />

                    {transactionData.data.userName}
                </Box>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(RestrictionsUserDeleteTicketData);
