import { createReducer, makeActionCreator } from "util/redux";
import * as arrayUtils from "util/array";

export const types = {
    LOAD_LAYOUT_REQUEST: "desktop/LOAD_LAYOUT_REQUEST",
    LOAD_LAYOUT_FAILURE: "desktop/LOAD_LAYOUT_FAILURE",
    LOAD_LAYOUT_SUCCESS: "desktop/LOAD_LAYOUT_SUCCESS",
    SAVE_LAYOUT_REQUEST: "desktop/SAVE_LAYOUT_REQUEST",
    SAVE_LAYOUT_FAILURE: "desktop/SAVE_LAYOUT_FAILURE",
    SAVE_LAYOUT_SUCCESS: "desktop/SAVE_LAYOUT_SUCCESS",
    TOGGLE_IS_EDITABLE: "desktop/TOGGLE_IS_EDITABLE",
    DELETE_WIDGET: "desktop/DELETE_WIDGET",
    ADD_WIDGET: "desktop/ADD_WIDGET",
    SET_LAYOUT: "desktop/SET_LAYOUT",
    ADD_AVAILABLE_WIDGET: "desktop/ADD_AVAILABLE_WIDGET",
    REMOVE_AVAILABLE_WIDGET: "desktop/REMOVE_AVAILABLE_WIDGET",
    LOAD_CORPORATE_GROUP_DESKTOP_REQUEST: "desktop/LOAD_CORPORATE_GROUP_DESKTOP_REQUEST",
    LOAD_CORPORATE_GROUP_DESKTOP_SUCCESS: "desktop/LOAD_CORPORATE_GROUP_DESKTOP_SUCCESS",
    LOAD_CORPORATE_GROUP_DESKTOP_FAILURE: "desktop/LOAD_CORPORATE_GROUP_DESKTOP_FAILURE",
    SIDEBAR_COLLAPSED_DESKTOP_TABLET: "desktop/SIDEBAR_COLLAPSED_DESKTOP_TABLET",
    UPDATEMODAL_HIDE: "desktop/UPDATEMODAL_HIDE",
    UPDATEMODAL_SHOW: "desktop/UPDATEMODAL_SHOW",
    LOAD_LAYOUT_REMOVE_WIDGET: "desktop/LOAD_LAYOUT_REMOVE_WIDGET",
    TOGGLE_MENU_BTN: "desktop/TOGGLE_MENU_BTN",
};

export const INITIAL_STATE = {
    availableWidgets: [],
    layout: null,
    corporateGroupDesktopData: {},
    isEditable: false,
    fetching: true,
    fetchingCorporateGroupDesktop: true,
    urlFooterBanner: null,
    isSidebarCollapsed: false,
    showUpdateDataModal: false,
    daysSinceLastUpdt: 0,
    toggleMenuBtnPress: false,
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_LAYOUT_REQUEST]: (state) => ({
        ...state,
        isEditable: false,
        fetching: true,
    }),
    [types.LOAD_LAYOUT_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LOAD_LAYOUT_SUCCESS]: (state, action) => ({
        ...state,
        availableWidgets: action.availableWidgets,
        layout: action.layout,
        urlFooterBanner: action.urlFooterBanner,
        fetching: false,
    }),
    [types.SAVE_LAYOUT_SUCCESS]: (state, action) => ({
        ...state,
        layout: action.layout,
    }),

    [types.TOGGLE_IS_EDITABLE]: (state) => ({
        ...state,
        isEditable: !state.isEditable,
    }),
    [types.SET_LAYOUT]: (state, action) => ({
        ...state,
        layout: action.layout,
    }),
    [types.ADD_AVAILABLE_WIDGET]: (state, action) => ({
        ...state,
        availableWidgets: [...state.availableWidgets, { ...action.widget, row: 0, column: 0 }],
    }),
    [types.REMOVE_AVAILABLE_WIDGET]: (state, action) => ({
        ...state,
        availableWidgets: arrayUtils.removeElement(state.availableWidgets, action.index),
    }),
    [types.LOAD_CORPORATE_GROUP_DESKTOP_REQUEST]: (state) => ({
        ...state,
        fetchingCorporateGroupDesktop: true,
    }),
    [types.LOAD_CORPORATE_GROUP_DESKTOP_SUCCESS]: (state, action) => ({
        ...state,
        fetchingCorporateGroupDesktop: false,
        corporateGroupDesktopData: action.payload.clientsData,
    }),
    [types.LOAD_CORPORATE_GROUP_DESKTOP_FAILURE]: (state) => ({
        ...state,
        fetchingCorporateGroupDesktop: false,
    }),
    [types.SIDEBAR_COLLAPSED_DESKTOP_TABLET]: (state, action) => ({
        ...state,
        isSidebarCollapsed: action.sidebarCollapsed,
    }),
    [types.UPDATEMODAL_SHOW]: (state, action) => ({
        ...state,
        showUpdateDataModal: true,
        daysSinceLastUpdt: action.daysSinceLastUpdt,
    }),
    [types.UPDATEMODAL_HIDE]: (state) => ({
        ...state,
        showUpdateDataModal: false,
    }),
    [types.LOAD_LAYOUT_REMOVE_WIDGET]: (state, action) => ({
        ...state,
        layout: state?.layout?.filter((element) => element.id !== action.index),
        availableWidgets: state?.availableWidgets?.filter((element) => element.id !== action.index),
    }),
    [types.TOGGLE_MENU_BTN]: (state) => ({
        ...state,
        toggleMenuBtnPress: true,
    }),
});

export const actions = {
    loadLayoutRequest: makeActionCreator(types.LOAD_LAYOUT_REQUEST),
    loadLayoutFailure: makeActionCreator(types.LOAD_LAYOUT_FAILURE),
    loadLayoutSuccess: makeActionCreator(types.LOAD_LAYOUT_SUCCESS, "layout", "availableWidgets", "urlFooterBanner"),
    saveLayoutRequest: makeActionCreator(types.SAVE_LAYOUT_REQUEST),
    saveLayoutFailure: makeActionCreator(types.SAVE_LAYOUT_FAILURE),
    saveLayoutSuccess: makeActionCreator(types.SAVE_LAYOUT_SUCCESS, "layout"),
    toggleIsEditable: makeActionCreator(types.TOGGLE_IS_EDITABLE),
    deleteWidget: makeActionCreator(types.DELETE_WIDGET, "index"),
    addWidget: makeActionCreator(types.ADD_WIDGET, "index"),
    setLayout: makeActionCreator(types.SET_LAYOUT, "layout"),
    addAvailableWidget: makeActionCreator(types.ADD_AVAILABLE_WIDGET, "widget"),
    removeAvailableWidget: makeActionCreator(types.REMOVE_AVAILABLE_WIDGET, "index"),
    loadCorporateGroupDesktopRequest: makeActionCreator(types.LOAD_CORPORATE_GROUP_DESKTOP_REQUEST, "filters"),
    loadCorporateGroupDesktopSuccess: makeActionCreator(types.LOAD_CORPORATE_GROUP_DESKTOP_SUCCESS),
    loadCorporateGroupDesktopFailure: makeActionCreator(types.LOAD_CORPORATE_GROUP_DESKTOP_FAILURE),
    changeStatusSidebarCollapsed: makeActionCreator(types.SIDEBAR_COLLAPSED_DESKTOP_TABLET, "sidebarCollapsed"),
    updateModalShow: makeActionCreator(types.UPDATEMODAL_SHOW, "daysSinceLastUpdt"),
    updateModalHide: makeActionCreator(types.UPDATEMODAL_HIDE),
    loadLayoutRemoveWidget: makeActionCreator(types.LOAD_LAYOUT_REMOVE_WIDGET, "index"),
    toggleMenuPressed: makeActionCreator(types.TOGGLE_MENU_BTN, "sidebarCollapsed"),
};

export const selectors = {
    getAvailableWidgets: ({ desktop }) => desktop.availableWidgets,
    getLayout: ({ desktop }) => desktop.layout,
    getCorporateGroupDesktopData: ({ desktop }) => desktop.corporateGroupDesktopData,
    isFetching: ({ desktop }) => desktop.fetching,
    isFetchingCorporateGroupDesktop: ({ desktop }) => desktop.fetchingCorporateGroupDesktop,
    getIsEditale: ({ desktop }) => desktop.isEditable,
    urlFooterBanner: ({ desktop }) => desktop.urlFooterBanner,
    isSidebarCollapsed: ({ desktop }) => desktop.isSidebarCollapsed,
    updateModalShow: ({ desktop }) => desktop.showUpdateDataModal,
    getDaysSinceLastUpdt: ({ desktop }) => desktop.daysSinceLastUpdt,
    toggleMenuBtnPress: ({ desktop }) => desktop.toggleMenuBtnPress,
};
