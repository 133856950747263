import Image from "pages/_components/Image";
import { bool, string } from "prop-types";
import React, { Component } from "react";
import { config, Spring } from "react-spring";
import classNames from "classnames";

class DropdownArrow extends Component {
    static propTypes = {
        isActive: bool.isRequired,
        arrowColor: string,
    };

    static defaultProps = {
        arrowColor: "primary-color",
    };

    render() {
        const { isActive, arrowColor } = this.props;

        return (
            <Spring
                config={config.stiff}
                from={{ transform: "rotate(90deg)" }}
                to={{
                    transform: isActive ? "rotate(180deg)" : "rotate(0)",
                }}>
                {(styles) => (
                    <Image
                        src="images/icons/accordionArrow.svg"
                        styles={styles}
                        color={arrowColor}
                        className={classNames("svg-image", { "rotate-180": isActive })}
                    />
                )}
            </Spring>
        );
    }
}

export default DropdownArrow;
