import Box from "pages/_components/Box";
import { func, string } from "prop-types";
import React from "react";
import Image from "pages/_components/Image";
import * as i18n from "util/i18n";

const SearchField = ({ id, autoComplete, placeholder, onChange }) => (
    <Box className="form-group">
        <Box className="input-group">
            <Box className="pl-5" display="flex" alignX="center" alignY="center" position="absolute" fullHeight>
                <Image src="images/search.svg" width="5" />
            </Box>

            <input
                id={id}
                type="text"
                autoComplete={autoComplete}
                className="form-control pl-9 position-relative"
                placeholder={i18n.get(placeholder)}
                onChange={onChange}
            />
        </Box>
    </Box>
);

SearchField.propTypes = {
    id: string.isRequired,
    autoComplete: string,
    placeholder: string,
    onChange: func.isRequired,
};

SearchField.defaultProps = {
    autoComplete: "off",
    placeholder: "global.search",
};

export default SearchField;
