import BankSearchDetailedItem from "pages/forms/_components/_fields/_bankselector/bankSearch/DetailedItem";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { routerActions } from "react-router-redux";
import { actions, actions as bankActions } from "reducers/bankSelector";
import * as i18nUtils from "util/i18n";

const bankSearchOuterComponents = (BaseComponent) =>
    class extends Component {
        static displayName = "BankSearchOuterComponents";

        static propTypes = {
            history: shape({}).isRequired,
            match: shape({}),
            dispatch: func.isRequired,
            field: shape({}).isRequired,
            form: shape({}).isRequired,
            idField: string.isRequired,
            selectedBank: shape({}),
            formMode: string.isRequired,
            beforeRedirectSearch: func,
            mode: string,
            onlySearchButton: bool,
        };

        static defaultProps = {
            match: { params: {} },
            selectedBank: null,
            beforeRedirectSearch: () => {},
            mode: null,
            onlySearchButton: false,
        };

        componentWillUnmount() {
            const { history, match, dispatch } = this.props;

            if (history.location.pathname.indexOf(match.url) === -1) {
                dispatch(actions.resetSelectedBanks());
            }
        }

        handleClick = () => {
            const { dispatch, match, field, form, idField, beforeRedirectSearch } = this.props;

            beforeRedirectSearch();

            const data = {
                ...form.values,
                idField: { idField },
            };
            dispatch(bankActions.setData(data));
            dispatch(
                routerActions.push(`${match.url}/bankSearch/${field.name}`, {
                    shouldLoadForm: false,
                    callbackUrl: match.url,
                }),
            );
        };

        render() {
            const { selectedBank, mode, onlySearchButton } = this.props;

            return onlySearchButton ? (
                <>
                    <BaseComponent {...this.props} />
                    <Box display="flex" className="mx-7">
                        <Button
                            className="mt-auto"
                            onClick={this.handleClick}
                            bsStyle="primary"
                            image="images/search.svg"
                        />
                    </Box>
                </>
            ) : (
                <>
                    <BaseComponent {...this.props} />
                    {mode === "edit" && (
                        <>
                            {`${i18nUtils.get("forms.bankselector.searchText")} `}

                            {selectedBank && <BankSearchDetailedItem {...selectedBank} />}
                        </>
                    )}
                </>
            );
        }
    };

export default bankSearchOuterComponents;
