export const REVELOCK_CUSTOMER_ID_DEFAULT = "Bolivariano-personas";
export const REVELOCK_LOCATION_DEFAULT = "/undefined";
export const isValidRevelockSdk = () => !!window?.RevelockSdk;
export const setCustomerIdRevelock = (customerId) =>
    isValidRevelockSdk() && customerId && customerId !== "" && window.RevelockSdk.setCustomerId(customerId);
export const setUserIdRevelock = (userId) =>
    isValidRevelockSdk() && userId && userId !== "" && window.RevelockSdk.setUserId(userId);
export const setPositionRevelock = (screenPosition) =>
    isValidRevelockSdk() && screenPosition && screenPosition !== "" && window.RevelockSdk.setPosition(screenPosition);
export const setSessionIdRevelock = (sessionId) =>
    isValidRevelockSdk() && sessionId && sessionId !== "" && window.RevelockSdk.setSessionId(sessionId);
export const registerTextFieldRevelock = (input) =>
    isValidRevelockSdk() && input && window.RevelockSdk.registerTextField(input);
export const registerUsernameFieldRevelock = (input) =>
    isValidRevelockSdk() && input && window.RevelockSdk.registerUsernameField(input);
export const registerPasswordFieldRevelock = (input) =>
    isValidRevelockSdk() && input && window.RevelockSdk.registerPasswordField(input);
export const setAutoLogoutActionRevelock = (callback) =>
    isValidRevelockSdk() && callback && window.RevelockSdk.setAutoLogoutAction(callback);
export const clearSessionDataRevelock = () => isValidRevelockSdk() && window.RevelockSdk.clearSessionData();
