import React, { Component } from "react";
import { string, bool } from "prop-types";
import { compose } from "redux";

import formField from "pages/forms/_components/_fields/_commons/formField";
import withFocus from "pages/_components/withFocus";

class Hidden extends Component {
    render() {
        const { editing, value, name, idField } = this.props;

        if (editing) {
            return <input id={idField} className="form-control" type="hidden" value={value || ""} name={name} />;
        }
        return null;
    }
}

Hidden.propTypes = {
    editing: bool.isRequired,
    value: string,
    name: string,
    idField: string.isRequired,
};

Hidden.defaultProps = {
    value: "",
    name: "",
};

export default compose(
    withFocus,
    formField({
        pureRender: true,
    }),
)(Hidden);
