import { Field, Form, withFormik } from "formik";
import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, shape } from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { actions as recoveryUserActions, selectors as recoveryUserSelectors } from "reducers/recoveryUser";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { selectors as sessionSelectors } from "reducers/session";
import TextField from "pages/_components/fields/TextField";
import Head from "pages/_components/Head";
import LoginWrapper from "pages/login/_components/LoginWrapper";
import { routerActions } from "react-router-redux/actions";
import Captcha from "pages/_components/fields/credentials/Captcha";
import { selectors as settingsSelector } from "reducers/settings";
import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import HeaderWithoutSession from "pages/_components/HeaderWithoutSession";
import PendingInvitationModal from "pages/login/_components/PendingInvitationModal";

const FORM_ID = "recoverUser.step1";

const RecoverUserStep1 = (props) => {
    const { isMobile, isSubmitting, isDesktop, values } = props;

    const [isValidEmail, setIsValidEmail] = useState(false);

    const handleBack = () => {
        props.dispatch(routerActions.goBack());
    };

    const renderDesktop = () => (
        <>
            <LoginWrapper>
                <>
                    {" "}
                    {!isMobile && <BoxErrorNotification isMobile={isMobile} scopeToShow="recoveryUser" />}
                    <Head
                        title="transactions.list.filters.operationType.historic.RECUPERARUSUARIO.IR"
                        onBack={handleBack}
                    />
                    <MainContainer>
                        <Form
                            className={classNames({
                                "pointer-events-none": isSubmitting,
                            })}
                            noValidate="novalidate">
                            <Box display="flex" column fullWidth>
                                <Box display="flex" column className="mb-2 mt-4">
                                    <Field
                                        hideLabel={isDesktop}
                                        idForm={FORM_ID}
                                        name="documentNumber"
                                        component={TextField}
                                        autoFocus={isDesktop}
                                        placeholder={i18n.get("", "Ingrese su cédula o pasaporte")}
                                        noMarginBottom
                                        className="mb-3"
                                        idField="documentnumberfield"
                                        labelText={i18n.get("", "Cédula o pasaporte")}
                                        labelClassName="text-bold"
                                        spellCheck="false"
                                    />
                                </Box>
                                <Box display="flex" column className="mb-2 mt-4">
                                    <Field
                                        hideLabel={isDesktop}
                                        idForm={FORM_ID}
                                        name="mail"
                                        type="email"
                                        component={TextField}
                                        autoFocus={isDesktop}
                                        placeholder={i18n.get("", "Ingrese su correo electrónico")}
                                        noMarginBottom
                                        className="mb-3"
                                        idField="usernamefield"
                                        labelText={i18n.get("", "Correo electrónico")}
                                        labelClassName="text-bold"
                                        spellCheck="false"
                                        handleOnChange={(event) => {
                                            const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
                                            if (!regex.test(event.target.value)) {
                                                setIsValidEmail(false);
                                            } else {
                                                setIsValidEmail(true);
                                            }
                                        }}
                                    />
                                </Box>
                                <Box display="flex" column className="mb-2 mt-4">
                                    <Field idForm={FORM_ID} name="captcha" component={Captcha} />
                                </Box>

                                <Button
                                    className="mt-7"
                                    type="submit"
                                    bsStyle="primary"
                                    label="global.continue"
                                    defaultLabelText={i18n.get("", "Continuar")}
                                    btnUppercase={false}
                                    loading={isSubmitting}
                                    disabled={
                                        !values.captcha || !values.mail || !isValidEmail || !values.documentNumber
                                    }
                                    block
                                />
                            </Box>
                        </Form>
                    </MainContainer>
                </>
            </LoginWrapper>
        </>
    );

    const renderMobile = () => (
        <Form className="full-width">
            <Box position="relative" display="flex" column alignX="between" fullWidth heightAuto>
                <MainContainer>
                    <Box display="flex" column className="mt-7 mx-7">
                        <Box className="mb-5">
                            <HeaderWithoutSession labelKey="transactions.list.filters.operationType.historic.RECUPERARUSUARIO.IR" />
                        </Box>
                        <Field
                            hideLabel={isDesktop}
                            idForm={FORM_ID}
                            name="documentNumber"
                            component={TextField}
                            autoFocus={isDesktop}
                            placeholder={i18n.get("", "Ingrese su cédula o pasaporte")}
                            noMarginBottom
                            className="mb-3"
                            idField="documentnumberfield"
                            labelText={i18n.get("", "Cédula o pasaporte")}
                            labelClassName="text-bold"
                            spellCheck="false"
                        />
                        <Box display="flex" column className="mb-2 mt-4">
                            <Field
                                hideLabel={isDesktop}
                                idForm={FORM_ID}
                                name="mail"
                                type="email"
                                component={TextField}
                                autoFocus={isDesktop}
                                placeholder={i18n.get("", "Ingrese su correo electrónico")}
                                noMarginBottom
                                className="mb-3"
                                idField="usernamefield"
                                labelText={i18n.get("", "Correo electrónico")}
                                labelClassName="text-bold"
                                spellCheck="false"
                            />
                        </Box>
                        <Box display="flex" column className="mb-2 mt-4">
                            <Field idForm={FORM_ID} name="captcha" component={Captcha} />
                        </Box>
                    </Box>
                    <Box className="mb-8 mx-7">
                        <Button
                            type="submit"
                            bsStyle="primary"
                            label="global.continue"
                            block
                            disabled={!values.captcha || !values.mail || !values.documentNumber}
                        />
                    </Box>
                </MainContainer>
            </Box>
        </Form>
    );

    return (
        <Box
            display="flex"
            {...(!isDesktop && { alignX: "flex-end", component: "main" })}
            position="relative"
            zIndex="9"
            className={classNames("login-background", { "mt-auto": !isDesktop })}>
            {isDesktop ? renderDesktop() : renderMobile()}
            <PendingInvitationModal />
        </Box>
    );
};

RecoverUserStep1.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isMobile: bool.isRequired,
    fetching: bool.isRequired,
    preData: shape({}).isRequired,
    isActiveCorporate: bool.isRequired,
    showCaptcha: bool.isRequired,
    isSubmitting: bool.isRequired,
    values: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    fetching: recoveryUserSelectors.getFetching(state),
    preData: recoveryUserSelectors.getPreData(state),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
    showCaptcha: settingsSelector.getShowCaptcha(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            documentNumber: "",
            mail: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                documentNumber: Yup.string().required(i18n.get(`${FORM_ID}.username.required`)),
                mail: Yup.string()
                    .required(i18n.get(`${FORM_ID}.emailUser.required`))
                    .email(i18n.get(`${FORM_ID}.emailUser.invalid`)),
            }),
        handleSubmit: ({ documentNumber, mail }, formikBag) => {
            formikBag.props.dispatch(recoveryUserActions.saveForm(documentNumber, mail));
            formikBag.props.dispatch(recoveryUserActions.recoveryUserStep1(documentNumber, mail, formikBag));
        },
    }),
)(resizableRoute(RecoverUserStep1));
