import React from "react";
import { string } from "prop-types";
import Box from "./Box";
import Text from "./Text";
import Image from "./Image";

const Help = ({ titleKey, textKey }) => (
    <Box className="p-4" background="background-disabled" borderRadius="lg">
        <Box display="flex">
            <Box>
                <Image src="images/icons/icon_notice.svg" />
            </Box>
            <Box className="ml-4">
                {titleKey && (
                    <Box>
                        <Text labelKey={titleKey} bold />
                    </Box>
                )}
                {textKey && (
                    <Box className="mt-3">
                        <Text color="text-grey" size="6" labelKey={textKey} />
                    </Box>
                )}
            </Box>
        </Box>
    </Box>
);

Help.propTypes = {
    titleKey: string,
    textKey: string,
};

Help.defaultProps = {
    titleKey: undefined,
    textKey: undefined,
};

export default Help;
