import classNames from "classnames";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import { bool, number, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";

const InfoExchangeRates = (props) => {
    const {
        isDesktop,
        labelAmountEstimate,
        amountEstimate,
        exchangeRateEstimate,
        currencyEstimate,
        twoInlineLayout,
    } = props;
    return (
        <Row fullHeight {...(twoInlineLayout && { gapX: "4" })}>
            <Col xs={12} md={12} lg={twoInlineLayout ? 6 : 12} className="height-auto">
                <Box
                    display="flex"
                    {...(!isDesktop || twoInlineLayout
                        ? { column: true, alignY: "between" }
                        : { alignX: "between", alignY: !isDesktop && twoInlineLayout ? "flex-start" : "center" })}
                    wrap
                    fullWidth
                    fullHeight
                    className={classNames("py-3 min-height-12", {
                        "px-5 px-md-10": !twoInlineLayout,
                        "px-5": twoInlineLayout,
                        "align-content-center": isDesktop && !twoInlineLayout,
                        "align-content-start": !isDesktop,
                    })}
                    borderRadius="default"
                    background="heading-color">
                    <Box display="flex" alignY="center" fitWidth className="mb-2 mb-0">
                        <Text
                            size="5"
                            color="inverse"
                            {...(!twoInlineLayout && { className: "pr-3" })}
                            labelKey={labelAmountEstimate}
                        />
                    </Box>
                    <Box display="flex" alignY="center" fitWidth>
                        <FormattedAmount
                            currency={currencyEstimate}
                            quantity={amountEstimate}
                            size={isDesktop ? "big" : "2"}
                            {...(twoInlineLayout && isDesktop && { size: "3" })}
                            color="inverse"
                            bold
                        />
                    </Box>
                </Box>
            </Col>
            <Col xs={12} md={12} lg={twoInlineLayout ? 6 : 12} className="height-auto">
                <Box
                    display="flex"
                    alignX="center"
                    fullWidth
                    fullHeight
                    className={classNames("py-3 min-height-12", {
                        "px-5 px-md-10": !twoInlineLayout,
                        "px-5": twoInlineLayout,
                    })}
                    borderRadius="default"
                    background="grey"
                    alignY="center"
                    wrap>
                    <Box
                        display="flex"
                        className={classNames({
                            "align-content-center": isDesktop && !twoInlineLayout,
                            "align-content-start": !isDesktop,
                        })}
                        {...(!isDesktop || twoInlineLayout
                            ? { column: true, alignY: "between" }
                            : { alignX: "between", alignY: isDesktop && !twoInlineLayout ? "center" : "flex-start" })}
                        wrap
                        fullWidth
                        fullHeight>
                        <Box display="flex" alignY="center" className="mb-2 mb-md-0">
                            <Text
                                size="5"
                                color="heading"
                                {...(!twoInlineLayout && { className: "mr-3" })}
                                labelKey="forms.transfers.internal.rate_estimate.label"
                            />
                            <Tooltip text="forms.transfers.internal.rate_estimate.info" />
                        </Box>
                        <Box display="flex" alignY="center">
                            <Text
                                size={isDesktop ? "3" : "2"}
                                {...(twoInlineLayout && isDesktop && { size: "5" })}
                                color="heading"
                                bold
                                noWrap>
                                {exchangeRateEstimate}
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Col>
        </Row>
    );
};

InfoExchangeRates.propTypes = {
    isDesktop: bool.isRequired,
    labelAmountEstimate: string,
    amountEstimate: number,
    exchangeRateEstimate: string,
    currencyEstimate: string,
    twoInlineLayout: bool,
};

InfoExchangeRates.defaultProps = {
    labelAmountEstimate: "",
    amountEstimate: 0,
    currencyEstimate: "",
    exchangeRateEstimate: "",
    twoInlineLayout: false,
};

export default resizableRoute(InfoExchangeRates);
