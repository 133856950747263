import classNames from "classnames";
import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { string } from "prop-types";
import React from "react";

const EmptyTable = ({ className, labelKey }) => (
    <Box display="flex" alignX="center" alignY="center" className={classNames("p-12", className)}>
        <Text align="center" labelKey={labelKey} size="5" />
    </Box>
);

EmptyTable.propTypes = {
    className: string,
    labelKey: string,
};

EmptyTable.defaultProps = {
    className: null,
    labelKey: null,
};

export default resizableRoute(EmptyTable);
