import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import ProgressBar from "pages/_components/ProgressBar";
import { resizableRoute } from "pages/_components/Resizable";
import SwitchToggle from "pages/_components/SwitchToggle/SwitchToggle";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import { bool, func, number, string } from "prop-types";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { actions as changeStatusProductActions } from "reducers/changeStatusProduct";
import { CREDIT_CARD_TYPE_CARD } from "util/creditCards";
import { AccountStatus, actionChangeStatusAccount } from "../../accounts/_components/DetailHeadInfo/DetailHeadInfo";
import { CreditCardStatus, ProductionStatus, actionChangeStatusProduct } from "../CreditCard/CreditCard";

const RemarkableProductInfo = ({
    accountNumber,
    artifactType,
    cardStyle,
    completed,
    currency,
    dispatch,
    franchise,
    handlePointsClick,
    icon,
    iDCc: id,
    idProduct,
    isAdditionalCreditCard,
    isDesktop,
    isIssuerAvaibleForPoints,
    isIssuerAvaibleForCashback,
    isPrepaid,
    isCorporative,
    isTablet,
    numberMask,
    points,
    productionStatus,
    productType,
    quantity,
    shortLabel,
    showSwitch,
    startDate,
    status,
    statusCode,
    tooltipText,
    isSwitchVisible,
    cashback,
    showEnableWalletRequest,
    cardWasDigitized,
}) => {
    const renderDisclaimerWalletDigitized = () => (
        <Box
            alignX="between"
            alignY="center"
            borderRadius="xl"
            background="white"
            className="p-5 py-md-3 py-xl-4 pr-xl-3"
            display="flex"
            flex1
            wrap>
            <Box display="flex" alignY="center" gap={3}>
                <Box display="flex">
                    <Image src="images/applePay.svg" ariaLabel="applePayLogo" wrapperWidth="auto" />
                </Box>
                <Text size="5" color="heading" regular className="mr-2" labelKey="enabled.wallet.disclaimer.applePay" />
            </Box>
        </Box>
    );

    const renderButtonWallet = () => (
        <Box
            alignX="between"
            alignY="center"
            borderRadius="xl"
            background="white"
            className="p-5 py-md-3 py-xl-4 pr-xl-3"
            display="flex"
            flex1
            wrap>
            <Box display="flex" alignY="center" gap={3}>
                <Box display="flex">
                    <Image src="images/applePay.svg" ariaLabel="applePayLogo" wrapperWidth="auto" />
                </Box>
                <Text size="5" color="heading" regular className="mr-2" labelKey="enabled.wallet.button.applePay" />
            </Box>
            <Button
                className="toolbar-btn view-back"
                onClick={() => {
                    if (showEnableWalletRequest) {
                        showEnableWalletRequest();
                    }
                }}
                image="images/arrowRightLight.svg"
                bsStyle="link"
            />
        </Box>
    );

    const handleToggleAccount = () => {
        let action = "";
        action = status === AccountStatus.Inactived ? actionChangeStatusAccount.Active : action;

        const values = {
            productType: "accounts",
            productData: {
                idProduct,
                accountNumber,
                statusCode,
                shortLabel,
                parametersSubmit: { idProduct },
            },
            title: `accounts.drawer.${action.actionName}.title`,
            actionName: action.actionName,
            statusExpected: action.statusExpected,
            actionImage: action.actionImage,
            paramsNotification: {
                success: {
                    ACCOUNT_LABEL: shortLabel,
                },
                error: { ACCOUNT_LABEL: shortLabel },
            },
        };
        dispatch(changeStatusProductActions.modalShow(values));
    };

    const remarkableInfo = () => (
        <Box
            className="px-5 pb-5 pt-3 pt-md-5 available-balance"
            display="flex"
            background="primary"
            borderRadius="xl"
            fullWidth
            {...(isDesktop && { fullHeight: true })}
            alignY="center"
            position="relative"
            {...(isDesktop ? { column: true } : { rowReverse: true })}
            column>
            {showSwitch ? (
                <Box
                    position={isDesktop ? "absolute" : "relative"}
                    {...(isDesktop && { right: "5" })}
                    {...(isDesktop && { top: "5" })}
                    className="switch-toggle"
                    display="flex"
                    alignX="flex-end"
                    fullWidth>
                    {status === AccountStatus.Inactived && (
                        <SwitchToggle
                            productType={productType}
                            switchIsOn={status === AccountStatus.Actived}
                            color="inverse"
                            greyStyle
                            size="6"
                            labelOnKey="account.status.ACTIVA"
                            labelOffKey="account.status.INACTIVA"
                            handleToggle={handleToggleAccount}
                        />
                    )}
                    {status === AccountStatus.Actived && (
                        <Box display="flex">
                            <Image src="images/Union.svg" wrapperClassName="item-info-icon mr-3" />
                            <Text labelKey="account.status.ACTIVA" color="inverse" size={isDesktop ? "6" : "5"} />
                        </Box>
                    )}
                    {status === AccountStatus.NoActived && (
                        <Box display="flex">
                            <Image src="images/Stop.svg" wrapperClassName="item-info-icon mr-3" />
                            <Text labelKey="account.status.INACTIVA" color="inverse" size={isDesktop ? "6" : "5"} />
                        </Box>
                    )}
                </Box>
            ) : null}

            <Box fullWidth>
                {productType === "PA" ? (
                    <Box display="flex" {...(isDesktop ? { column: true } : { alignY: "center", gap: "5" })} fullWidth>
                        <Box display="flex" column {...(!isDesktop && { flex1: true })}>
                            <Text
                                size={isDesktop ? "7" : "6"}
                                color="inverse"
                                className="available-balance-data-label"
                                labelKey="loan.detail.owed.amount.label"
                            />
                            <Box display="flex" alignX="flex-start" alignY="center">
                                <FormattedAmount
                                    color="inverse"
                                    size={isDesktop ? "big" : "2"}
                                    bold
                                    currency={currency}
                                    quantity={quantity}
                                />
                            </Box>
                            {!isDesktop && (
                                <Box fullWidth>
                                    <Text size="6" color="inverse">
                                        Fecha de inicio: <b>{startDate}</b>
                                    </Text>
                                </Box>
                            )}
                        </Box>
                        <Box
                            display="flex"
                            alignY="flex-end"
                            className="progress-bar-section"
                            {...(!isDesktop && { flex1: true })}>
                            <ProgressBar completed={completed} />
                            {isDesktop && (
                                <Box className="loan-icon">
                                    <Image src={`images/icons/productType${icon}.svg`} color="text-inverse-color" />
                                </Box>
                            )}
                        </Box>
                        {isDesktop && (
                            <Box fullWidth>
                                <Text size="6" color="inverse">
                                    Fecha de inicio: <b>{startDate}</b>
                                </Text>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <>
                        <Box
                            display="flex"
                            // eslint-disable-next-line no-nested-ternary
                            alignX={isDesktop ? "flex-start" : showSwitch ? "flex-start" : "center"}
                            alignY="center"
                            className="mb-2">
                            <Text
                                size={isDesktop ? "5" : "6"}
                                color="inverse"
                                className="available-balance-data-label"
                                labelKey={
                                    productType === "PF"
                                        ? "deposit.detail.fields.open.amount"
                                        : "accounts.availableBalance"
                                }
                            />
                            {tooltipText && (
                                <Tooltip
                                    text={tooltipText}
                                    position={isDesktop ? "left-centered" : "bottom-right"}
                                    inverseIconColor
                                />
                            )}
                        </Box>
                        <Box
                            display="flex"
                            // eslint-disable-next-line no-nested-ternary
                            alignX={isDesktop ? "flex-start" : showSwitch ? "flex-start" : "center"}
                            alignY="center">
                            <FormattedAmount color="inverse" size="big" bold currency={currency} quantity={quantity} />
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );

    const handleToggle = () => {
        let action;
        action = statusCode === CreditCardStatus.Actived ? actionChangeStatusProduct.Block : action;
        action = statusCode === CreditCardStatus.Blocked ? actionChangeStatusProduct.Unlock : action;
        action =
            statusCode === CreditCardStatus.Inactived ||
            (statusCode === CreditCardStatus.Actived && productionStatus === ProductionStatus.Locked)
                ? actionChangeStatusProduct.Active
                : action;

        const productionStatusExpected =
            action.actionName === actionChangeStatusProduct.Active.actionName
                ? ProductionStatus.Active
                : productionStatus;

        const cardBrand = `${franchise.charAt(0).toUpperCase()}${franchise.slice(1).toLowerCase()}`;
        const values = {
            productType: "creditCards",
            productData: {
                idProduct: id,
                numberMask,
                statusCode,
                shortLabel,
                parametersSubmit: { idCreditCard: id, cardBrand, type: franchise },
            },
            title: `creditCards.drawer.${action.actionName}.title`,
            actionName: action.actionName,
            statusExpected: action.statusExpected,
            productionStatusExpected,
            actionImage: action.actionImage,
            paramsNotification: {
                success: {
                    CARD_NUMBER: numberMask,
                    CARD_BRAND: cardBrand,
                },
                error: { CARD_NUMBER: numberMask },
            },
        };
        dispatch(changeStatusProductActions.modalShow(values));
    };

    const switchIsOn = statusCode === CreditCardStatus.Actived && productionStatus !== ProductionStatus.Locked;
    const creditCardIsActive = useMemo(
        () => statusCode === CreditCardStatus.Actived && productionStatus !== ProductionStatus.Locked,
        [statusCode, productionStatus],
    );

    const remarkableInfoCreditCard = () => (
        <Box className="remarkable-card">
            <Box
                display="flex"
                gap={isDesktop ? "3" : "4"}
                background="white"
                borderRadius="xl"
                className="p-3 pr-md-4 min-height-12"
                {...(isTablet && { flex1: true })}
                {...(isPrepaid && { fullHeight: true })}>
                <Box className={`remarkable-card-switch-gradient ${cardStyle}`}>
                    <Image src={`images/${franchise.toLowerCase()}-creditCard.svg`} ariaLabel={franchise} />
                </Box>
                <Box display="flex" alignX="flex-start" alignY="center" fullWidth>
                    <Box
                        display="flex"
                        alignX={isDesktop ? "between" : "flex-start"}
                        alignY={isDesktop ? "center" : "flex-start"}
                        fullWidth
                        wrap
                        gap="2"
                        {...(!isDesktop && { column: true })}>
                        <Text
                            className="my-0 mr-2 line-height-125"
                            component="h4"
                            size={isDesktop ? "5" : "3"}
                            bold
                            color="heading"
                            labelKey={isAdditionalCreditCard ? "creditCard.type.additional.label" : "creditCard.holder"}
                        />
                        {isSwitchVisible && (
                            <SwitchToggle
                                productType={productType}
                                switchIsOn={switchIsOn}
                                labelOnKey="creditCard.unblocked"
                                labelOffKey={
                                    statusCode === CreditCardStatus.Blocked
                                        ? "creditCard.blocked"
                                        : "creditCard.inactive"
                                }
                                color="text"
                                textClassName={classNames("ml-3", {
                                    "remarkable-switch-off": !switchIsOn,
                                    "remarkable-switch-on": switchIsOn,
                                })}
                                id={id}
                                handleToggle={handleToggle}
                            />
                        )}
                    </Box>
                    {!isDesktop && (
                        <Button
                            bsStyle="primary"
                            href={
                                isPrepaid
                                    ? `/formCustom/rechargeCreditCardLocal?creditCard=${id}`
                                    : `/formCustom/payCreditCardLocal?creditCard=${id}`
                            }
                            className="px-6">
                            <Text
                                size={isDesktop ? "6" : "5"}
                                labelKey={isPrepaid ? "creditCard.buttons.reloadCard" : "creditCard.pay"}
                            />
                        </Button>
                    )}
                </Box>
            </Box>
            {/** Section credit card points */}
            {!isPrepaid && isIssuerAvaibleForPoints && (!isAdditionalCreditCard || isCorporative) && (
                <Box
                    alignX="between"
                    alignY="center"
                    borderRadius="xl"
                    background="white"
                    className="p-5 py-md-3 py-xl-4 pr-xl-3"
                    display="flex"
                    flex1
                    wrap>
                    <Box display="flex" alignY="center">
                        <Text
                            size={isDesktop ? "6" : "5"}
                            color="heading"
                            className="mr-2"
                            labelKey="creditCard.details.info.points"
                        />
                        <FormattedAmount
                            color="heading"
                            size="5"
                            bold
                            quantity={points}
                            noCurrency
                            noDecimalsWhenRound
                        />
                    </Box>
                    {points > 0 && (
                        <Button bsStyle="link" onClick={handlePointsClick}>
                            <Text size={isDesktop ? "7" : "6"} labelKey="creditCard.details.info.pointsBtn" />
                        </Button>
                    )}
                </Box>
            )}

            {/** Section credit card cashBack */}
            {!isPrepaid && isIssuerAvaibleForCashback && (!isAdditionalCreditCard || isCorporative) && (
                <Box
                    alignX="between"
                    alignY="center"
                    borderRadius="xl"
                    background="white"
                    className="p-5 py-md-3 py-xl-4 pr-xl-3"
                    display="flex"
                    flex1
                    wrap>
                    <Box display="flex" alignY="center">
                        <Text
                            size={isDesktop ? "6" : "5"}
                            color="heading"
                            className="mr-2"
                            labelKey="creditCard.details.info.cashBack"
                        />
                        <Text size="5" bold>
                            {cashback}
                        </Text>
                    </Box>
                </Box>
            )}
            {showEnableWalletRequest &&
                creditCardIsActive &&
                cardWasDigitized !== undefined &&
                cardWasDigitized === false &&
                renderButtonWallet()}
            {showEnableWalletRequest &&
                creditCardIsActive &&
                cardWasDigitized !== undefined &&
                cardWasDigitized === true &&
                renderDisclaimerWalletDigitized()}
        </Box>
    );

    // eslint-disable-next-line no-unused-vars
    const remarkableInfoCreditCardAdditional = () => {
        const cardLogo = franchise.toLowerCase();
        const cardLogoLow = cardLogo === "visa" ? `${cardLogo}-blue` : cardLogo;
        return (
            <Box className="px-sm-5 pt-sm-5 pb-sm-3 p-md-0" background="gray" fullHeight>
                <Box
                    className="py-4 px-5"
                    display="flex"
                    alignX="between"
                    background="white"
                    borderRadius="xl"
                    fullHeight
                    gap="3">
                    <Box display="flex">
                        <Image
                            src={`images/icons/creditCard-${artifactType || CREDIT_CARD_TYPE_CARD}.svg`}
                            height="8"
                        />
                    </Box>

                    <Box
                        flex1
                        display="flex"
                        {...(isDesktop ? { alignX: "between", alignY: "center" } : { column: true, gap: "2" })}>
                        <Box display="flex" alignX="flex-start" alignY="center" gap="2" wrap>
                            <Box display="flex" className="mr-2">
                                <Image src={`images/${cardLogoLow}-creditCard.svg`} height={isDesktop ? "5" : "4"} />
                            </Box>

                            <Text
                                className="my-0 line-height-125"
                                size="5"
                                bold
                                color="heading"
                                labelKey="creditCard.type.additional.label"
                            />
                        </Box>
                        {isSwitchVisible && (
                            <SwitchToggle
                                productType={productType}
                                switchIsOn={
                                    statusCode === CreditCardStatus.Actived &&
                                    productionStatus !== ProductionStatus.Locked
                                }
                                labelOnKey="creditCard.unblocked"
                                labelOffKey={
                                    statusCode === CreditCardStatus.Blocked
                                        ? "creditCard.blocked"
                                        : "creditCard.inactive"
                                }
                                textClassName={classNames("ml-3", { "remarkable-switch-off": !switchIsOn })}
                                color="text"
                                id={id}
                                handleToggle={handleToggle}
                            />
                        )}
                    </Box>
                    {!isDesktop && (
                        <Button
                            bsStyle="primary"
                            href={
                                isPrepaid
                                    ? `/formCustom/rechargeCreditCardLocal?creditCard=${id}`
                                    : `/formCustom/payCreditCardLocal?creditCard=${id}`
                            }
                            className="px-6">
                            <Text
                                size={isDesktop ? "6" : "5"}
                                labelKey={isPrepaid ? "creditCard.buttons.reloadCard" : "creditCard.pay"}
                            />
                        </Button>
                    )}
                </Box>
            </Box>
        );
    };

    const getProduct = () => {
        let productInfo = "";
        if (productType === "TC") {
            productInfo =
                isAdditionalCreditCard && !isCorporative
                    ? remarkableInfoCreditCardAdditional()
                    : remarkableInfoCreditCard();
        }
        if (productType === "CA" || productType === "CC" || productType === "PF" || productType === "PA") {
            productInfo = remarkableInfo();
        }
        return productInfo;
    };

    return getProduct();
};

RemarkableProductInfo.propTypes = {
    // common data
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    productType: string.isRequired,
    status: string.isRequired,
    // account
    currency: Number,
    quantity: Number,
    // credit cards
    artifactType: string,
    blocked: bool,
    cardStyle: string,
    completed: number,
    description: string,
    franchise: string,
    handlePointsClick: func.isRequired,
    holder: string,
    icon: string,
    iDCc: string,
    isAdditionalCreditCard: bool,
    isIssuerAvaibleForPoints: bool,
    isIssuerAvaibleForCashback: bool,
    isPrepaid: bool.isRequired,
    isCorporative: bool.isRequired,
    points: string,
    shortLabel: string,
    showSwitch: bool,
    startDate: string,
    statusCode: string,
    theNumber: string.isRequired,
    tooltipText: string,
    isSwitchVisible: bool,
    cashback: string,
    cardWasDigitized: bool,
};

RemarkableProductInfo.defaultProps = {
    currency: 0,
    quantity: 0,
    blocked: false,
    cardStyle: "default",
    icon: null,
    iDCc: null,
    holder: null,
    franchise: null,
    points: null,
    statusCode: "",
    shortLabel: "",
    description: "",
    showSwitch: true,
    tooltipText: null,
    startDate: null,
    completed: 0,
    isAdditionalCreditCard: false,
    artifactType: CREDIT_CARD_TYPE_CARD,
    isIssuerAvaibleForPoints: false,
    isIssuerAvaibleForCashback: false,
    isSwitchVisible: true,
    cashback: null,
    cardWasDigitized: undefined,
};

export default connect()(resizableRoute(RemarkableProductInfo));
