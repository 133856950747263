import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form, withFormik, Field } from "formik";
import { func, shape, bool, string } from "prop-types";
import Button from "pages/_components/Button";
import * as i18nUtils from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import * as dateUtils from "util/date";
import Select from "pages/forms/_components/_fields/Select";
import CommonFrequencySubOption from "pages/forms/_components/_scheduler/CommonFrequencySubOption";
import CustomFrequencySubOption from "pages/forms/_components/_scheduler/CustomFrequencySubOption";
import moment from "moment";
import I18n from "pages/_components/I18n";
import { Modal } from "react-bootstrap";
import ModalNotification from "pages/forms/_components/ModalNotification";
import classNames from "classnames";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { flattenArray, removeDuplicateItems } from "util/array";
import CredentialTokenComponent from "../credential/CredentialTokenComponent";

const STARTS_ON_DATE = moment(new Date()).add(1, "days");

class SchedulerModal extends Component {
    static propTypes = {
        goBack: func,
        onScheduleClick: func,
        setTouched: func,
        setErrors: func,
        setFieldValue: func,
        handleCloseBottomSheetClick: func,
        touched: shape({}),
        errors: shape({}),
        values: shape({}),
        isDisplayed: bool,
        idForm: string.isRequired,
        drawer: bool,
        titleKind: string,
        shouldRequestCredentials: bool,
        credentials: shape({}),
        propsForm: shape({}),
        fetching: bool.isRequired,
    };

    static defaultProps = {
        goBack: () => {},
        onScheduleClick: () => {},
        setTouched: () => {},
        setErrors: () => {},
        setFieldValue: () => {},
        handleCloseBottomSheetClick: () => {},
        touched: {},
        errors: {},
        values: {},
        isDisplayed: false,
        drawer: false,
        titleKind: "transaction",
        shouldRequestCredentials: false,
        credentials: {},
        propsForm: {},
    };

    goBack = () => {
        const { goBack } = this.props;
        goBack();
    };

    validateFields = () => {
        const { setErrors, errors, touched, setTouched, values } = this.props;
        let res = true;
        // clean errors
        setErrors({});

        const moreThan2daysaWeek = dateUtils.moreThan2daysSelected(values.days);

        // validate if it's week and days is just one day and freqValue = 1
        if (values.customFreq === schedulerUtils.WEEK) {
            if (moreThan2daysaWeek && values.customFreqValue !== "1") {
                setTouched({ ...touched, days: true });
                setErrors({
                    ...errors,
                    days: { value: i18nUtils.get(`scheduler.multipleDaysSelectedValidation`) },
                });
                res = false;
            }
        }

        // starts on must be after than today
        if (moment(values.startsOn).isBefore(moment())) {
            setTouched({ ...touched, startsOn: true });
            setErrors({
                ...errors,
                startsOn: i18nUtils.get(`scheduler.startsOnBeforeToday`),
            });
            res = false;
        }

        // starts on must be before than end date
        if (values.frequency !== schedulerUtils.ONCE && values.frequency !== schedulerUtils.TODAY) {
            let endDate = null;

            if (typeof values.lapse.date === "string") {
                if (values.lapse.date.split(".")[0] !== null && values.lapse.date.split(".")[0] !== undefined) {
                    endDate = new Date(values.lapse.date.split(".")[0]);
                }
            } else {
                endDate = values.lapse.date._d;
            }

            if (moment(endDate).isSameOrBefore(values.startsOn)) {
                setTouched({ ...touched, startsOn: true });
                setErrors({
                    ...errors,
                    startsOn: i18nUtils.get(`scheduler.startsOnAfterFinishOn`),
                });
                res = false;
            }
        }
        return res;
    };

    onScheduleClick = () => {
        const { onScheduleClick, values } = this.props;

        if (this.validateFields()) {
            onScheduleClick(values);
        }
    };

    // eslint-disable-next-line no-unused-vars
    setScheduleMessage = (lapse, frequency, startsOn, futureDate) => {
        const { titleKind } = this.props;
        const formatDate = {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
        };

        let message = "";

        if (frequency === schedulerUtils.ONCE) {
            const dateText = dateUtils.DAY_MONTH_FORMAT(i18nUtils.getLang(), startsOn, formatDate);
            message = i18nUtils.get(`scheduler.${schedulerUtils.ONCE.toLowerCase()}.execute.${titleKind}`, null, {
                START_DATE: dateText,
                END_DATE: dateText,
            });
        } else if (frequency === schedulerUtils.CUSTOM) {
            return message;
        } else {
            const initDate = dateUtils.DAY_MONTH_FORMAT(i18nUtils.getLang(), startsOn, formatDate);
            let endDate = null;

            if (typeof lapse.date === "string") {
                endDate = new Date(lapse.date.split(".")[0]);
                endDate = dateUtils.DAY_MONTH_FORMAT(i18nUtils.getLang(), endDate, formatDate);
            } else {
                endDate =
                    lapse.date?._d === undefined
                        ? dateUtils.DAY_MONTH_FORMAT(i18nUtils.getLang(), startsOn, formatDate)
                        : dateUtils.DAY_MONTH_FORMAT(i18nUtils.getLang(), lapse.date?._d, formatDate);
            }

            message = i18nUtils.get(`scheduler.${frequency.toLowerCase()}.execute.${titleKind}`, null, {
                START_DATE: initDate,
                END_DATE: endDate,
            });
        }
        const splitMessage = message?.split(".");

        return splitMessage?.map((text) => (
            <>
                {text}. <br />
            </>
        ));
    };

    render() {
        const {
            isDisplayed,
            handleCloseBottomSheetClick,
            values: { lapse, frequency, customFreq, startsOn, futureDate },
            errors,
            idForm,
            drawer,
            titleKind,
            credentials,
            shouldRequestCredentials,
            propsForm,
            fetching,
        } = this.props;

        return (
            <Modal
                aria-labelledby="modalTitleID"
                aria-modal="true"
                onHide={handleCloseBottomSheetClick}
                show={isDisplayed}
                className={classNames({ drawer })}>
                <div className="modal-container">
                    <Modal.Header closeButton>
                        <Modal.Title id="modalTitleID">
                            <I18n id={`scheduler.title.${titleKind}`} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="drawer-form full-height full-width">
                            <Box display="flex" column fullWidth fullHeight className="px-0 px-md-9">
                                <ModalNotification idForm={idForm} errors={errors} />
                                <Row className="px-0 height-auto">
                                    <Col sm={12}>
                                        <Box className="form-group">
                                            <Box display="flex" alignY="center" className="data-label-special-mb">
                                                <Text
                                                    id={`label.${idForm}.frequency`}
                                                    component="label"
                                                    className="data-label"
                                                    labelKey="scheduler.frequency"
                                                />
                                            </Box>
                                            <div className="input-group">
                                                <Field
                                                    id={`${idForm}.frequency`}
                                                    component={Select}
                                                    name="frequency"
                                                    className="slideFromBottom"
                                                    options={schedulerUtils.listRecurrencies()}
                                                    clearable={false}
                                                    searchable={false}
                                                    aria-owns="endRulesFieldsetID"
                                                    aria-haspopup
                                                    aria-expanded={isDisplayed}
                                                />
                                            </div>
                                        </Box>
                                    </Col>
                                    {frequency !== schedulerUtils.CUSTOM ? (
                                        <CommonFrequencySubOption
                                            lapse={lapse}
                                            frequency={frequency}
                                            startsOn={STARTS_ON_DATE}
                                            futureDate={STARTS_ON_DATE}
                                        />
                                    ) : (
                                        <CustomFrequencySubOption
                                            lapse={lapse}
                                            frequency={frequency}
                                            startsOn={STARTS_ON_DATE}
                                            customFreq={customFreq}
                                        />
                                    )}
                                </Row>

                                <Box className="drawer-form-button">
                                    <Col sm={12} className="col-12">
                                        <Box className="mb-8">
                                            <Text component="p" align="center" color="text" size="6">
                                                {this.setScheduleMessage(lapse, frequency, startsOn, futureDate)}
                                            </Text>
                                        </Box>

                                        {shouldRequestCredentials && credentials && (
                                            <Box className="pb-7">
                                                <CredentialTokenComponent
                                                    credentials={credentials}
                                                    propsForm={propsForm}
                                                    onChangeToken={(tokenCode) => {
                                                        const { setFieldValue } = propsForm;
                                                        if (setFieldValue) {
                                                            setFieldValue("otp", tokenCode);
                                                            setFieldValue("credentials.otp", tokenCode);
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        )}

                                        <Button
                                            type="button"
                                            bsStyle="primary"
                                            loading={shouldRequestCredentials && fetching}
                                            label="scheduler.schedule"
                                            onClick={this.onScheduleClick}
                                            block
                                        />
                                    </Col>
                                </Box>
                            </Box>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: transactionsSelectors.getFetchingCustom(state),
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(transactionsSelectors.getCredentialsGroups(state)),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { value } = props;

            const newProgram = {
                frequency: schedulerUtils.ONCE,
                startsOn:
                    value && value.valueDate && value.selectedOption === schedulerUtils.ONCE
                        ? moment(value.valueDate).toDate()
                        : STARTS_ON_DATE.toDate(),
                futureDate:
                    value && value.valueDate && value.selectedOption === schedulerUtils.ONCE
                        ? moment(value.valueDate).toDate()
                        : STARTS_ON_DATE.toDate(),
                lapse: {
                    date: STARTS_ON_DATE.toDate(),
                    lapse: schedulerUtils.NEVER,
                },
                customFreq: schedulerUtils.DAY,
                customFreqValue: "1",
                days: dateUtils.getDayFromDate(STARTS_ON_DATE.toDate()),
                occurrenceType: "day", // day(every month on x day ) or occurrence(every month on the "tirth" "tuesday")
            };

            if (!value || !value.program) {
                return newProgram;
            }

            const startsOn =
                typeof value.program.startsOn === "string"
                    ? moment(value.program.startsOn).toDate()
                    : value.program.startsOn;

            return {
                ...value.program,
                startsOn,
                frequency: value.program.isCustom ? schedulerUtils.CUSTOM : value.program.frequency,
                lapse: {
                    lapse: value.program.lapse,
                    date: value.program.date,
                    number: value.program.number,
                },
                customFreq: value.program.frequency,
                customFreqValue: value.program.frequencyValue,
                occurrenceType: value.program.occurrence ? "occurrence" : "day",
            };
        },
    }),
)(SchedulerModal);
