import Box from "pages/_components/Box";
import PageLoading from "pages/_components/PageLoading";
import Text from "pages/_components/Text";
import CapsList from "pages/administration/_components/CapsList";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { channelsSelectors } from "reducers/administration";

class AdministrationChannelsTicket extends Component {
    static propTypes = {
        actions: shape({
            loadChannelsTicketRequest: func.isRequired,
        }).isRequired,
        match: shape({
            params: shape({
                idTransaction: string.isRequired,
            }).isRequired,
        }).isRequired,
        user: shape({
            firstName: string.isRequired,
            lastName: string.isRequired,
        }).isRequired,
        isFetching: bool.isRequired,
    };

    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadChannelsTicketRequest(match.params.idTransaction);
    }

    render() {
        const { user, isFetching } = this.props;
        return (
            <Box display="flex" column>
                <Box display="flex" className="mb-4">
                    <Text component="label" labelKey="administration.channels.user" addColon className="m-0" />
                    <Text>{`${user.firstName} ${user.lastName}`}</Text>
                </Box>
                <PageLoading loading={isFetching}>
                    <Box className="list-wrapper">
                        <CapsList isInTicket />
                    </Box>
                </PageLoading>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetching: channelsSelectors.isFetching(state),
});

export default connect(mapStateToProps)(AdministrationChannelsTicket);
