import { resizableRoute } from "pages/_components/Resizable";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text";
import { func, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { push } from "react-router-redux";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import { isDesktop } from "react-device-detect";
import { Col } from "react-bootstrap";
import Row from "pages/_components/Row";
import classNames from "classnames";

const ChangeAvatarConfirmation = ({ userEmail, dispatch }) => {
    const emailWithMask = !userEmail
        ? ""
        : userEmail
              .split("@")
              .map((x, i) => (!i ? [...x].map((y, j) => (j === x.length - 1 || !j ? y : "x")).join("") : x))
              .join("@");

    const handleClickBack = () => {
        dispatch(push("/desktop"));
    };

    const renderButton = () => (
        <Box className={classNames({ "view-app-content": isDesktop })}>
            <Row
                className={classNames("mt-7 mb-7 row-template-center", {
                    "d-flex": !isDesktop,
                })}>
                <Col xs={3} xsOffset={4}>
                    <Button type="button" bsStyle="primary" label="global.goToHome" onClick={handleClickBack} block />
                </Col>
            </Row>
        </Box>
    );

    return (
        <>
            <Head {...(!isDesktop && { onBack: handleClickBack })} titleText={i18n.get("", "Avatar")} />
            <MainContainer
                className="main-container border-radius-lg p-7 box-shadow-small background-white"
                showLoader={!userEmail}>
                <Box display="flex" column fullWidth alignX="center" className="above-the-fold pb-5">
                    <Box display="flex" column alignX="center" className="max-width-25875rem">
                        <Box display="flex" alignX="center" fullWidth className="mb-5 mt-7">
                            <Image
                                src="images/icons/ticketSignatureCheck.svg"
                                height="12"
                                wrapperWidth="12"
                                wrapperHeight="12"
                            />
                        </Box>
                        <Text
                            align="center"
                            component="h2"
                            className="mb-6"
                            defaultValue={i18n.get("", "Su imagen de seguridad se actualizó con éxito.")}
                            bold
                        />
                        <Box className="mb-8 align-center">
                            <Text
                                defaultValue={i18n.get(
                                    "",
                                    "Se ha enviado un mensaje como confirmación al correo electrónico&nbsp;",
                                )}
                            />
                            <Text defaultValue={emailWithMask} bold />
                        </Box>
                    </Box>
                </Box>
                {!isDesktop && renderButton()}
            </MainContainer>
            {isDesktop && renderButton()}
        </>
    );
};

const mapStateToProps = (state) => ({
    userEmail: sessionSelectors.getUserEmail(state),
});

ChangeAvatarConfirmation.propTypes = {
    userEmail: string.isRequired,
    dispatch: func.isRequired,
};

export default connect(mapStateToProps)(resizableRoute(ChangeAvatarConfirmation));
