import React, { useEffect, useState } from "react";
import { bool, number, func } from "prop-types";
import { timeinMinToString } from "util/date";
import Text from "pages/_components/Text";

const CountdownCounter = ({ total, startCountDown, setStartCountDown, onFinish, ...props }) => {
    const [time, setTime] = useState(total);
    const [referenceTime, setReferenceTime] = useState(Date.now());
    const INTERVAL_IN_MILISECONDS = 1000;

    useEffect(() => {
        const countDownUntilZero = () => {
            setTime((prevTime) => {
                if (prevTime <= 0) {
                    setStartCountDown(false);
                    return 0;
                }

                const now = Date.now();
                const interval = now - referenceTime;
                setReferenceTime(now);
                if (prevTime - interval <= 0) {
                    onFinish();
                }
                return prevTime - interval;
            });
        };

        if (startCountDown) {
            setTimeout(countDownUntilZero, INTERVAL_IN_MILISECONDS);
        }
    }, [time, startCountDown]);

    useEffect(() => {
        if (startCountDown) {
            setTime(total);
            setReferenceTime(Date.now());
        }
    }, [startCountDown]);

    return <Text {...props} defaultValue={timeinMinToString(Math.round((time < 0 ? 0 : time) / 1000))} />;
};

CountdownCounter.propTypes = {
    total: number.isRequired,
    startCountDown: bool.isRequired,
    setStartCountDown: func.isRequired,
    onFinish: func,
};

CountdownCounter.defaultProps = {
    onFinish: () => {},
};

export default CountdownCounter;
