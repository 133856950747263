import React, { Component } from "react";
import { Field } from "formik";

import Radio from "pages/_components/fields/Radio";
import { bool, shape, string } from "prop-types";

class FormikRadio extends Component {
    static propTypes = {
        name: string.isRequired,
        label: shape({}).isRequired,
        value: string.isRequired,
        inLineControl: bool,
        mode: string.isRequired,
        id: string.isRequired,
    };

    static defaultProps = {
        inLineControl: false,
    };

    render() {
        const { name, label, value, inLineControl, mode, id } = this.props;

        return (
            <Field name={name}>
                {({ field }) => (
                    <Radio
                        {...field}
                        id={id}
                        value={value}
                        checked={field.value === value}
                        label={label}
                        inLineControl={inLineControl}
                        mode={mode}
                    />
                )}
            </Field>
        );
    }
}

export default FormikRadio;
