import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import Image from "pages/_components/Image/Image";
import Row from "pages/_components/Row/Row";
import Text from "pages/_components/Text";
import React, { useMemo } from "react";
import { isAndroidPlatform, isIOSPlatform, isMobileNativeFunc } from "util/device";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

import Col from "react-bootstrap/lib/Col";
import { shape, string } from "prop-types";
import { withRouter } from "react-router-dom";

const InvalidMobileVersionError = ({ errorTitleDefault, errorMessageDefault, location }) => {
    const urlMarket = useMemo(() => {
        if (!isMobileNativeFunc()) {
            return undefined;
        }

        if (isIOSPlatform()) {
            return "mobile.market.url.ios.mobile";
        }

        if (isAndroidPlatform()) {
            return "mobile.market.url.android.mobile";
        }

        return undefined;
    }, []);

    return (
        <Box
            display="flex"
            column
            alignX="center"
            alignY="center"
            className="pt-9 pb-9 pb-md-0 pt-md-0 px-5"
            fullHeight>
            <Box display="flex" column alignX="center" className="mb-9">
                <Text
                    size="big"
                    bold
                    color="heading-color"
                    className="mb-3"
                    labelKey="mobile.validate.version.error.big.title"
                />

                <Text size="3" color="heading-color" align="center" labelKey="mobile.validate.version.error.subtitle" />
            </Box>
            <Box display="flex" alignX="center" className="mb-9 mb-md-0" fullWidth>
                <Image className="error-img" src="images/icons/errors/update.svg" />
            </Box>
            <Text component="h1" align="center" color="heading" size="3" bold className="mt-0 mb-2">
                {i18n.get(errorTitleDefault || "mobile.validate.version.error.title")}
            </Text>
            <Text component="p" align="center" color="heading" size="3" className="mt-0 mb-8 mb-md-11">
                {location?.message || i18n.get(errorMessageDefault || "mobile.validate.version.error.message")}
            </Text>
            <Row>
                <Col xs={12} md={4} mdOffset={4}>
                    <Box display="flex" alignX="center" className="px-md-5" fullWidth>
                        {urlMarket && (
                            <Button
                                block
                                externalHref={configUtils.get(urlMarket)}
                                label="mobile.validate.version.update.title"
                                bsStyle="primary"
                                className="access-bar-app-btn"
                            />
                        )}
                    </Box>
                </Col>
            </Row>
        </Box>
    );
};

InvalidMobileVersionError.propTypes = {
    errorTitleDefault: string,
    errorMessageDefault: string,
    location: shape({}),
};
InvalidMobileVersionError.defaultProps = {
    errorTitleDefault: undefined,
    errorMessageDefault: undefined,
    location: undefined,
};
export default withRouter(InvalidMobileVersionError);
