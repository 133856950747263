import classNames from "classnames";
import { Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, string } from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { compose } from "redux";
import { flattenArray, removeDuplicateItems } from "util/array";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import CredentialTokenComponent from "./credential/CredentialTokenComponent";

class TransactionTicketCancelConfirmation extends Component {
    render() {
        const {
            credentials,
            isSubmitting,
            isLoading,
            isDesktop,
            isTablet,
            handleCancelPreview,
            setFieldValue,
            labelConfirm,
        } = this.props;
        if (!isLoading) {
            return (
                <Form className="full-width px-md-7">
                    {credentials && (
                        <Row className="px-7 mb-7">
                            <Col xs={12} md={12}>
                                <Box {...(!isTablet && { className: "ticket-width" })}>
                                    <CredentialTokenComponent
                                        credentials={credentials}
                                        onChangeToken={(token) => {
                                            if (setFieldValue) {
                                                setFieldValue("otp", token);
                                            }
                                        }}
                                    />
                                </Box>
                            </Col>
                        </Row>
                    )}
                    <Row className="px-7">
                        <Col xs={12} md={6}>
                            <Button label="global.cancel" bsStyle="outline" onClick={handleCancelPreview} block />
                        </Col>
                        <Col
                            xs={12}
                            md={6}
                            className={classNames({
                                "grid-reversed": !isDesktop,
                            })}>
                            <Button type="submit" label={labelConfirm} bsStyle="primary" loading={isSubmitting} block />
                        </Col>
                    </Row>
                </Form>
            );
        }
        return null;
    }
}

TransactionTicketCancelConfirmation.propTypes = {
    credentials: string.isRequired,
    isSubmitting: bool.isRequired,
    isLoading: bool.isRequired,
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    handleCancelPreview: func.isRequired,
    setFieldValue: func,
    labelConfirm: string,
};
TransactionTicketCancelConfirmation.defaultProps = {
    setFieldValue: () => {},
    labelConfirm: null,
};

const mapStateToProps = (state) => ({
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(formSelectors.getCredentialsGroups(state)),
    idForm: formSelectors.getId(state),
    isLoading: formSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ credentials }) =>
            credentials.reduce((values, credential) => ({ ...values, [credential]: "" }), { otp: "" }),
        validationSchema: ({ credentials }) =>
            Yup.object().shape(
                credentials.reduce(
                    (values, credential) => ({
                        ...values,
                        [credential]: Yup.string().required(i18n.get(`form.credential.${credential}.required`)),
                    }),
                    {},
                ),
            ),
        handleSubmit: (credentials, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(formActions.cancelTransaction({ credentials, formikBag }));
        },
    }),
)(resizableRoute(TransactionTicketCancelConfirmation));
