import React, { Component } from "react";
import { Field, Form } from "formik";
import { arrayOf, func, shape, bool, string, oneOf } from "prop-types";
import { Route } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";

import * as i18nUtils from "util/i18n";
import { actions as navigationActions } from "reducers/administration/common/navigation";

import Container from "pages/_components/Container";
import TextField from "pages/_components/fields/TextField";
import GroupFormWizardSteps from "pages/administration/_components/groupForm/WizardSteps";
import Button from "pages/_components/Button";
import Wizard from "pages/_components/Wizard";
import PermissionsPanel from "pages/administration/_components/advancedPermissionsForm/PermissionsPanel";

import I18n from "pages/_components/I18n";
import { permissionsSelectors } from "reducers/administration";
import Box from "pages/_components/Box";
import CredentialTokenComponent from "pages/forms/_components/credential/CredentialTokenComponent";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import AdvancedPermissionsForm from "../AdvancedPermissionsForm";
import Permissions from "../cashProductField/Permissions";

class GroupForm extends Component {
    static propTypes = {
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })),
        handleSubmit: func.isRequired,
        isSubmitting: bool.isRequired,
        idForm: string.isRequired,
        mode: oneOf(["view", "edit"]),
        buttonText: string.isRequired,
        location: shape({
            pathname: string,
        }).isRequired,
        actions: shape({
            updateHasClose: func,
            updateHasBack: func,
            reset: func,
        }).isRequired,
    };

    static defaultProps = {
        mode: "edit",
        credentialGroups: [],
    };

    componentDidMount() {
        this.updateCloseBackButtonsVisibility();
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
        } = this.props;

        if (prevProps.location.pathname !== pathname) {
            this.updateCloseBackButtonsVisibility();
        }
    }

    updateCloseBackButtonsVisibility = () => {
        const {
            actions,
            location: { pathname },
        } = this.props;
        actions.reset();
        if (pathname.match(/create\/step/)) {
            actions.updateHasClose(true);
        } else if (pathname.match(/create\/confirm/)) {
            actions.updateHasBack(true);
            actions.updateHasClose(true);
        } else if (pathname.match(/group\/\d+\/step/)) {
            actions.updateHasClose(true);
        } else if (pathname.match(/group\/\d+\/confirm/)) {
            actions.updateHasBack(true);
            actions.updateHasClose(true);
        }
    };

    validateName = (value) => {
        const { idForm } = this.props;

        if (!value) {
            return i18nUtils.get(`${idForm}.name.required`);
        }

        return undefined;
    };

    normalizedProducts = (items) => items.map((p) => ({ idProduct: p.idProduct, label: p.label, type: p.productType }));

    render() {
        const {
            credentialGroups,
            handleSubmit,
            isSubmitting,
            idForm,
            mode,
            buttonText,
            adminGroup,
            groups,
            products,
            location,
            setFieldValue,
            ...props
        } = this.props;
        const path = location.pathname.split("/");
        return (
            <Form className="above-the-fold">
                <Wizard>
                    {!path.includes("step3") && (
                        <Box className="border-radius-lg p-7 mt-5 box-shadow-small background-white">
                            <Container className="container--layout flex-grow-1">
                                <Container.Column md={6} sm={12}>
                                    <Container.ColumnBody>
                                        <Field
                                            name="name"
                                            idForm={idForm}
                                            component={TextField}
                                            mode={mode}
                                            validate={this.validateName}
                                            hidePlaceholder
                                        />
                                        <Field
                                            name="description"
                                            idForm={idForm}
                                            component={TextField}
                                            // renderAs="textarea"
                                            optional={i18nUtils.get(`${idForm}.description.optional.text`)}
                                            mode={mode}
                                            hidePlaceholder
                                        />
                                        {mode === "edit" ? (
                                            <Permissions
                                                fieldName="permissions"
                                                administrationSchema="advanced"
                                                data={{ groups, products: this.normalizedProducts(products) }}
                                                mode={mode}
                                            />
                                        ) : (
                                            <div className="form-group">
                                                <div className="form-group-text">
                                                    <span className="control-label">
                                                        <I18n id="administration.groups.permissions" />
                                                    </span>
                                                </div>
                                                <AdvancedPermissionsForm {...props} mode={mode} />
                                            </div>
                                        )}
                                        {/* <GroupFormWizardTabs idForm={idForm} mode={mode} formProps={props} /> */}
                                    </Container.ColumnBody>
                                </Container.Column>
                            </Container>
                        </Box>
                    )}
                    <Route
                        render={({ location }) => (
                            <PermissionsPanel
                                key={location.pathname}
                                mode={location.pathname.indexOf("confirm") === -1 ? "edit" : "view"}
                                render={(content) => (
                                    <Container className="container--layout flex-grow-1">
                                        <Container.Column md={12} sm={12}>
                                            <Container.ColumnBody>
                                                <GroupFormWizardSteps idForm={idForm} mode={mode} formProps={props} />
                                                {mode === "view" && credentialGroups && credentialGroups.length > 0 && (
                                                    <Box className="border-radius-lg p-7 mt-5 box-shadow-small background-white">
                                                        {/* <Box display="flex" column fullWidth>
                                                            <Box className="title-with-icon">
                                                                <Image src="images/iconTitles/security_device.svg" />
                                                                <Text
                                                                    component="h3"
                                                                    defaultValue={i18nUtils.get("", "Verificación")}
                                                                />
                                                            </Box>
                                                            <Box display="block" fullWidth className="mb-7">
                                                                <Text
                                                                    defaultValue={i18nUtils.get(
                                                                        "",
                                                                        "Introduzca el código de 8 dígitos que generó en la app Banca Empresas",
                                                                    )}
                                                                />
                                                            </Box>
                                                            <Field
                                                                idForm={idForm}
                                                                autoComplete="off"
                                                                name="otp"
                                                                component={PinInput}
                                                                maxLength={1}
                                                                labelNoMarginTop
                                                                noMarginBottom
                                                                type="number"
                                                                inputLenght={6}
                                                                defaultLabelText={i18nUtils.get("", "Clave virtual")}
                                                            />
                                                        </Box> */}

                                                        <Row>
                                                            <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2}>
                                                                <CredentialTokenComponent
                                                                    onChangeToken={(tokenCode) => {
                                                                        if (setFieldValue) {
                                                                            setFieldValue("otp", tokenCode);
                                                                        }
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Box>
                                                )}
                                            </Container.ColumnBody>
                                        </Container.Column>
                                        <Container.Column md={6} sm={12}>
                                            <Container.ColumnBody>{content}</Container.ColumnBody>
                                        </Container.Column>
                                    </Container>
                                )}
                            />
                        )}
                    />
                </Wizard>
                <Container className="container--layout items-center" gridClassName="form-content">
                    <Container.Column className="col-12 col-xl-6" lg={6} md={6} sm={12}>
                        <Container.ColumnBody>
                            <Button
                                type="submit"
                                bsStyle="primary"
                                label="administration.advanced.group.create.button.confirm.text"
                                loading={isSubmitting}
                                block
                            />
                        </Container.ColumnBody>
                    </Container.Column>
                </Container>
            </Form>
        );
    }
}
const mapStateToProps = (state) => ({
    products: permissionsSelectors.getProducts(state),
    groups: permissionsSelectors.getGroups(state),
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(navigationActions, dispatch),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GroupForm);
