import { bool, string } from "prop-types";
import React from "react";

const SecuritySealImage = ({ securitySeal, securitySealAlt, withoutBorder, size }) => (
    <div
        className="btn-image security-image"
        title={securitySealAlt}
        style={{
            minWidth: size,
            width: size,
            height: size,
            minHeight: size,
            borderRadius: "50%",
            border: withoutBorder ? null : "6px solid var(--primary-active-color)",
            backgroundImage: `url(${securitySeal})`,
            backgroundSize: "cover",
        }}
    />
);

SecuritySealImage.propTypes = {
    securitySeal: string.isRequired,
    securitySealAlt: string.isRequired,
    withoutBorder: bool,
    size: string,
};

SecuritySealImage.defaultProps = {
    withoutBorder: false,
    size: "83px",
};

export default SecuritySealImage;
