import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import ProductDetail from "pages/_components/ProductDetail";
import SideBarModal from "pages/_components/modal/SideBarModal";
import DetailHeadInfo from "pages/accounts/_components/DetailHeadInfo";
import DownloadAccoutDetailForm from "pages/forms/customForms/DownloadAccoutDetailForm";
import { arrayOf, bool, func, instanceOf, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { push, replace } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as widgetSelectors } from "reducers/widgets";
import * as dateUtils from "util/date";
import * as i18nUtils from "util/i18n";
import { getLocationBasePath } from "util/revelock";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as tableActions } from "reducers/paginatedTable";
import PaginatedTable from "pages/_components/PaginatedTable/PaginatedTable";
import Dropdown from "pages/_components/Dropdown";
import { PERMISSION_ACCOUNT_STATEMENT, PRODUCT_TYPE } from "constants.js";
import Movement from "./_components/Movement";
import MovementHeader from "./_components/MovementHeader";
import MovementsFilter from "./_components/MovementsFilter";
import StatementLines from "./StatementLines";
import MovementsCut from "./MovementsCut";

const PAGE_IDENTIFIER = "accounts.read";

const INICIAL_FILTER_STATE = {
    dateFrom: dateUtils.getDateWithDaysSubtract(5),
    dateTo: new Date(),
    lastMovements: true,
};

class Details extends Component {
    state = {
        showSideBarDownloadDetail: false,
        downloadingMovements: false,
        showMovFilters: false,
        filters: INICIAL_FILTER_STATE,
        hasFiltersApplied: false,
        previousUrl: "",
    };

    componentWillMount() {
        this.setState({
            fetchingDetails: true,
        });
        const { dispatch, accounts, accountsWidget, ...rest } = this.props;

        // si no hay cuentas cargadas redirijo a mis cuentas
        if ((!accounts || accounts.length === 0) && (!accountsWidget || accountsWidget.length === 0)) {
            dispatch(replace("/accounts"));
        } else {
            dispatch(accountsActions.resetFilters());
            dispatch(tableActions.clearPagedDataList());
            dispatch(accountsActions.readAccount(rest.match.params.id));
        }
        this.setRevelockLocation();
    }

    componentDidMount() {
        this.setState({
            fetchingDetails: false,
        });

        // Agregar event listener para detectar cambios en la URL
        this.unlisten = this.props.history.listen((location, action) => {
            if (action === "POP") {
                this.setState({ previousUrl: this.props.history.location.pathname });
            }
        });
    }

    componentDidUpdate(prevProps) {
        const { previousUrl } = this.state;
        const { location } = this.props.history;

        // Resetear el estado si la navegación no es un retroceso
        if (prevProps.history.action !== "POP" && location.pathname !== previousUrl) {
            const { dispatch } = this.props;
            dispatch(accountsActions.resetInitialValues());
        }
    }

    componentWillUnmount() {
        // Quitar event listener cuando el componente se desmonta
        this.unlisten();
    }

    handleUrlChange = () => {
        this.setState({ previousUrl: window.location.href });
    };

    /**
     * Set position revelock
     */
    setRevelockLocation = () => {
        const { setLocationCustomPath, match } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath(getLocationBasePath(match), PAGE_IDENTIFIER);
        }
    };

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(accountsActions.toggleOptions());
    };

    onFinishDownload = () => {
        this.setState({
            downloadingMovements: false,
        });
    };

    handleClickDownload = (format) => {
        const { dispatch, match } = this.props;
        const { filters } = this.state;
        this.setState({
            downloadingMovements: true,
        });

        dispatch(accountsActions.downloadMovements(match.params.id, format, filters, this.onFinishDownload));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.push("/accounts"));
    };

    getMergedAccount = () => {
        const { account, accounts, accountsWidget } = this.props;
        let accountFromList = null;

        if (account) {
            if (accounts && accounts.length > 0) {
                accountFromList = accounts?.find((a) => a.idProduct === account.idProduct);
            } else if (accountsWidget && accountsWidget.length > 0) {
                accountFromList = accountsWidget?.find((a) => a.idProduct === account.idProduct);
            }
        }

        let mergedAccount = null;
        if (accountFromList) {
            mergedAccount = {
                ...account,
                status: accountFromList.status,
                isValidToReferenceLetter: accountFromList.isValidToReferenceLetter,
            };
        }

        return mergedAccount;
    };

    handleClickEditAlias = () => {
        const { dispatch, account } = this.props;
        dispatch(push(`/accounts/${account.idProduct}/setAlias`));
    };

    renderDownloadDocs = (disabled) => {
        const { downloadingMovements } = this.state;

        // if (!downloadingMovements) {
        const documents = [
            {
                label: "global.pdfFile",
                onClick: () => this.handleClickDownload("pdf"),
            },
            {
                label: "global.xlsFile",
                onClick: () => this.handleClickDownload("xls"),
            },
            {
                label: "global.txtFile",
                onClick: () => this.handleClickDownload("txt"),
            },
        ];

        return (
            <Dropdown
                image="images/util/arrow-down.svg"
                label="global.download"
                buttonClass="btn-download"
                fetching={downloadingMovements}
                imageRight
                disabled={disabled}
                pullRight>
                {documents.map((item) => (
                    <Button
                        key={item.label}
                        label={item.label}
                        onClick={() => {
                            item.onClick();
                        }}
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                ))}
            </Dropdown>
        );
    };

    handleShowFilterClick = () => {
        this.setState({
            showMovFilters: true,
        });
    };

    handleClearFilterClick = () => {
        const { dispatch } = this.props;
        this.setState({
            filters: INICIAL_FILTER_STATE,
            hasFiltersApplied: false,
        });

        dispatch(accountsActions.clearFilters());
        dispatch(accountsActions.resetFilters());
    };

    handleFilterClick = (values) => {
        const { dispatch } = this.props;
        // const { page } = this.state;
        const filterValues = { ...values };
        const { selectedDate } = filterValues;
        if (selectedDate === "lastSeven") {
            filterValues.dateFrom = dateUtils.getDateWithDaysSubtract(7);
            filterValues.dateTo = new Date();
        }
        if (selectedDate === "lastThirty") {
            filterValues.dateFrom = dateUtils.getDateWithDaysSubtract(30);
            filterValues.dateTo = new Date();
        }
        filterValues.lastMovements = false;

        dispatch(accountsActions.setPaginatedInfo(1, undefined, undefined, filterValues));

        this.setState({
            filters: filterValues,
            hasFiltersApplied: true,
            showMovFilters: false,
        });
    };

    setPage = (page) => {
        const { dispatch } = this.props;
        dispatch(accountsActions.setCurrentPage(page));
    };

    render() {
        const { showSideBarDownloadDetail, showMovFilters, fetchingDetails, filters, hasFiltersApplied } = this.state;
        const {
            account,
            fetching,
            fetchingMovements,
            dispatch,
            isDesktop,
            isTablet,
            currentLang,
            accounts,
            accountsWidget,

            currentPage,
            paginatedDataMap,
            itemsMap,
            filtersBKP,

            hasFiltersAppliedBKP,
            permissionStatement,
            ...rest
        } = this.props;

        const mergedAccount = this.getMergedAccount(account);

        return (
            <>
                <PageLoading loading={fetching} classicStyle={false}>
                    {!fetching && !fetchingDetails && mergedAccount && (
                        <>
                            <Notification scopeToShow="account/details" />

                            <ProductDetail>
                                <ProductDetail.Header
                                    showMobileEditAlias
                                    handleClickEditAlias={this.handleClickEditAlias}
                                    dispatch={dispatch}
                                    handleOptionsClick={this.handleClick}
                                    isDesktop={isDesktop}
                                    onBack={this.handleBack}
                                    productId={rest.match.params.id}
                                    product={mergedAccount}
                                    totalFavorites={0}
                                    textBack="accounts.detail.returnToList"
                                    renderProductTitle={false}
                                    productStatus={`account.status.${account.status}`}>
                                    <></>
                                </ProductDetail.Header>

                                <ProductDetail.Body
                                    id="productDetail.body"
                                    closeMoreFiltersWhenSiblingClick
                                    isDesktop={isDesktop}
                                    product={mergedAccount}
                                    productKind="accounts"
                                    filters={[]}
                                    {...(mergedAccount.isTherePendingTransaction && {
                                        disclaimerStyled: "warning",
                                        disclaimerLabelkey: "accounts.disclaimer.pendingTransaction",
                                    })}
                                    {...{
                                        detailHeadInfo: (
                                            <DetailHeadInfo
                                                {...this.props}
                                                account={mergedAccount}
                                                status={account.status}
                                            />
                                        ),
                                    }}>
                                    <Box
                                        isDesktop={isDesktop}
                                        iconLabel="images/icons/movements.svg"
                                        keyLabel="accounts.movements"
                                        keyLabelColor="text-capitalize">
                                        <PaginatedTable
                                            idActivity="accounts.listStatements"
                                            name="account-movs"
                                            idProduct={account.idProduct}
                                            headerProps={{
                                                currency: account.currency,
                                            }}
                                            itemComponent={Movement}
                                            headerComponent={MovementHeader}
                                            filters={filtersBKP || filters}
                                            hasFiltersApplied={hasFiltersApplied || hasFiltersAppliedBKP}
                                            isDesktop={isDesktop}
                                            isTablet={isTablet}
                                            withoutItemsMessage={i18nUtils.get("accounts.movements.none")}
                                            noMoreDataMessage={i18nUtils.get("accounts.movements.noMoreMovements")}
                                            currentPage={currentPage}
                                            paginatedDataMapBKP={paginatedDataMap}
                                            itemsMapBKP={itemsMap}
                                            idExportActivity="account.detail.download"
                                            handleShowFilter={this.handleShowFilterClick}
                                            handleClearFilter={this.handleClearFilterClick}
                                            renderDownloadDocs={this.renderDownloadDocs}
                                            idFieldName="idAccount"
                                            notificationScopes={["account/details"]}
                                            // setCurrentPageD={this.setPage}
                                            // autoClearWhenUnmounted={true}
                                        />
                                    </Box>
                                    {permissionStatement && account.productType === PRODUCT_TYPE.ACCOUNT_CC && (
                                        <Box
                                            isDesktop={isDesktop}
                                            iconLabel="images/icons/note.svg"
                                            keyLabel="accounts.statements">
                                            <StatementLines
                                                idAccount={account.idProduct}
                                                openingDate={account.openingDate}
                                                isDesktop={isDesktop}
                                            />
                                        </Box>
                                    )}
                                    <Box
                                        isDesktop={isDesktop}
                                        iconLabel="images/icons/file.svg"
                                        keyLabel="accounts.movementCut">
                                        <MovementsCut
                                            idAccount={account.idProduct}
                                            openingDate={account.openingDate}
                                            isDesktop={isDesktop}
                                        />
                                    </Box>
                                </ProductDetail.Body>
                            </ProductDetail>
                        </>
                    )}
                </PageLoading>
                <SideBarModal
                    show={showSideBarDownloadDetail}
                    onClose={() => {
                        this.setState({ showSideBarDownloadDetail: false });
                    }}
                    title="forms.product.state.download.title">
                    <DownloadAccoutDetailForm
                        isDesktop={isDesktop}
                        account={mergedAccount}
                        currentLang={currentLang}
                        dispatch={dispatch}
                    />
                </SideBarModal>
                {!fetching && mergedAccount && (
                    <SideBarModal
                        show={showMovFilters}
                        onClose={() => {
                            this.setState({ showMovFilters: false });
                        }}
                        title="global.filter">
                        <MovementsFilter
                            initialData={filtersBKP || filters}
                            handleFilter={this.handleFilterClick}
                            handleCancel={() => {
                                this.setState({ showMovFilters: false });
                            }}
                        />
                    </SideBarModal>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    fetching: accountsSelectors.getFetchingDetail(state),
    fetchingMovements: accountsSelectors.getFetchingMovements(state),
    currentLang: i18nSelectors.getLang(state),

    accounts: accountsSelectors.getAccounts(state),
    accountsWidget: widgetSelectors.getWidget(state, "accounts")?.data?.accounts,
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),

    paginatedDataMap: accountsSelectors.getPaginatedDataMap(state),
    itemsMap: accountsSelectors.getItemsMap(state),
    currentPage: accountsSelectors.getCurrentPage(state),
    filtersBKP: accountsSelectors.getFiltersBKP(state),
    hasFiltersAppliedBKP: accountsSelectors.getHasFiltersApplied(state),
    permissionStatement: sessionSelectors.hasPermissions(state, [PERMISSION_ACCOUNT_STATEMENT]),
});

Details.propTypes = {
    account: shape({
        productType: string,
        idProduct: string,
        permissions: shape({}),
        currency: string,
    }),
    accounts: arrayOf(shape({})),
    accountsWidget: arrayOf(shape({})),
    activeEnvironment: shape({}).isRequired,
    currentLang: string,
    dispatch: func.isRequired,
    fetching: bool.isRequired,
    fetchingMovements: bool.isRequired,
    isCheckUrlFetch: bool.isRequired,
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    match: shape({}).isRequired,
    setLocationCustomPath: func.isRequired,
    currentPage: number,
    paginatedDataMap: instanceOf(Map),
    itemsMap: instanceOf(Map),
    filtersBKP: shape({}),
    hasFiltersAppliedBKP: bool,
    history: shape({
        location: shape({
            pathname: string.isRequired,
        }).isRequired,
    }).isRequired,
    permissionStatement: bool.isRequired,
};

Details.defaultProps = {
    account: null,
    currentLang: "",
    accounts: null,
    accountsWidget: null,
    currentPage: undefined,
    paginatedDataMap: undefined,
    itemsMap: undefined,
    filtersBKP: undefined,
    hasFiltersAppliedBKP: false,
};

export default connect(mapStateToProps)(withRevelockPositionContext(Details));
