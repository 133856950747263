import WidgetLoading from "pages/_components/WidgetLoading";
import { bool, func, node, number, shape } from "prop-types";
import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { actions, selectors } from "reducers/widgets";
import * as i18nUtils from "util/i18n";
import WidgetHeader from "./_components/WidgetHeader";

const Points = (props) => {
    const { closeButton, data, draggableItemProps, isFetching, isEditable } = props;
    const { pointsAmount } = data;
    const uiAutomationProp = { "data-ui-automation": "widgetPoints" };

    useEffect(() => {
        const { dispatch } = props;
        dispatch(actions.listRequest("points"));
    }, [props]);

    return (
        <section
            className="widget"
            {...uiAutomationProp}
            {...draggableItemProps}
            aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
            <WidgetHeader title={i18nUtils.get("desktop.widgets.points.title")} action={closeButton} />

            <WidgetLoading loading={isFetching}>
                {Object.keys(data).length !== 0 && (
                    <article className="widget--portfolio" aria-hidden={isEditable}>
                        <div className="row">
                            <ul id="chartReference" aria-describedby="chartReferenceDescription">
                                <Row>{pointsAmount}</Row>
                            </ul>
                            <span id="chartReferenceDescription" className="visually-hidden">
                                {i18nUtils.get("desktop.widgets.points.chartReferenceDescription")}
                            </span>
                        </div>
                    </article>
                )}
            </WidgetLoading>

            <div className="overlay" />
        </section>
    );
};

Points.propTypes = {
    data: shape({ pointsAmount: number }),
    dispatch: func.isRequired,
    closeButton: node,
    isFetching: bool,
    isEditable: bool,
    draggableItemProps: shape({}).isRequired,
};

Points.defaultProps = {
    data: {},
    closeButton: null,
    isFetching: false,
    isEditable: false,
};

const mapStateToProps = (state) => ({
    ...selectors.getWidget(state, "points"),
});

export default connect(mapStateToProps)(Points);
