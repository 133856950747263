import { call, put, takeLatest } from "redux-saga/effects";

import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import * as form from "middleware/form";

const loadSignaturesSchemesTicketRequest = (middleware, actions) =>
    function*({ idTransaction }) {
        const transactionResponse = yield call(form.readTransaction, idTransaction);

        if (transactionResponse.type === "W") {
            yield put(actions.loadSignaturesSchemesTicketFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
            );
        } else {
            const {
                capFrequencies,
                signatureAlias,
                functionalGroups,
                maxAmount,
                signatureLevelsCounts,
                signatureType,
                signatureDispatch,
            } = transactionResponse.data.data.transaction.data;

            yield put(
                actions.loadSignaturesSchemesTicketSuccess({
                    signatureAlias,
                    functionalGroups,
                    signatureLevelsCounts,
                    signatureType,
                    topAmount: { amount: maxAmount, period: capFrequencies[0] },
                    signatureDispatch,
                }),
            );
        }
    };

const sagasCreator = (middleware, types, actions) => [
    takeLatest(types.LOAD_SIGNATURES_SCHEMES_TICKET_REQUEST, loadSignaturesSchemesTicketRequest(middleware, actions)),
];

export default sagasCreator;
