import classNames from "classnames";
import MessageActions from "pages/communications/_components/MessageActions";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import { bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import FormattedDate from "pages/_components/FormattedDate";
import { resizableRoute } from "pages/_components/Resizable";

function MessageSender(props) {
    const { communication } = props;

    if (communication.direction === "BANK_TO_CUSTOMER") {
        return (
            <Text
                color="secondary"
                labelKey={`communications.tray.${communication.idCommunicationTray}`}
                {...(!communication.userRead && { bold: true })}
                ellipsis
            />
        );
    }
    return (
        <Text
            color="secondary"
            labelKey="communication.thread.message.me"
            {...(!communication.userRead && { bold: true })}
            ellipsis
        />
    );
}

MessageSender.propTypes = {
    communication: shape({
        idCommunication: number,
        direction: string,
    }).isRequired,
};

class MessageItem extends Component {
    static propTypes = {
        index: number.isRequired,
        communication: shape({
            idCommunication: number,
            userRead: bool,
            sentDateAsString: string,
            direction: string,
            subject: string,
            body: string,
        }).isRequired,
        handleRemoveClick: func.isRequired,
        handleChangeMessageStatus: func.isRequired,
        handleSelectMessageClick: func.isRequired,
        handleMessageModalShow: func,
        headerTitle: func,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        handleMessageModalShow: null,
        headerTitle: null,
    };

    removeTextWithRegex = (text) => {
        const regex = /\n?\(BAN-LINK (.*?)\|(.*?) BAN-LINK\)\n?/g;
        const cleanedText = text.replace(regex, "");

        return cleanedText;
    };

    render() {
        const {
            communication,
            index,
            handleRemoveClick,
            handleChangeMessageStatus,
            handleSelectMessageClick,
            isDesktop,
            handleMessageModalShow,
            headerTitle,
        } = this.props;
        const textBody = this.removeTextWithRegex(communication.body);
        return (
            <Box
                component="li"
                ref={index}
                onClick={() => {
                    if (isDesktop) {
                        handleSelectMessageClick(communication.idCommunication, communication.userRead, index);
                    } else {
                        handleSelectMessageClick(communication.idCommunication, communication.userRead, index);
                        handleMessageModalShow();
                        headerTitle(communication.subject);
                    }
                }}
                key={communication.idCommunication}
                className={classNames("py-3 px-5 message-notification-item", {
                    "read-message": communication.userRead,
                })}
                pointer
                noList>
                <Box component="article" display="flex">
                    <Box className="pr-5" flex1 withEllipsis>
                        <MessageSender communication={communication} />

                        <Text
                            component="h4"
                            size="6"
                            color="heading"
                            className="my-0"
                            {...(!communication.userRead && { bold: true })}
                            ellipsis>
                            {communication.subject}
                        </Text>

                        <Box display="flex">
                            <Text className="message-body-content" defaultValue={textBody} size="6" color="heading" />
                        </Box>
                    </Box>

                    <Box display="flex" column alignX="flex-end">
                        <Text color="heading" size="7" bold>
                            <FormattedDate date={communication.sentDate} anotherFormat="DD/MM/YYYY HH:mm" />
                        </Text>
                        <MessageActions
                            communication={communication}
                            handleRemoveClick={handleRemoveClick}
                            handleChangeMessageStatus={handleChangeMessageStatus}
                            index={index}
                        />
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default resizableRoute(MessageItem);
