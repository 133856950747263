import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, shape } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { actions as sessionActions } from "reducers/session";
import { actions as statusActions, selectors as statusSelectors } from "reducers/status";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import * as i18nUtils from "util/i18n";

const SessionCLose = (props) => {
    const { dispatch, status } = props;

    const closeSession = () => {
        dispatch(sessionActions.logout());
    };
    const closeModal = () => {
        dispatch(statusActions.hideSessionCloseModal());
    };

    return (
        <div className="modal-container">
            <TextAndButtonsModal
                modalShow={!status.sessionAboutToExpire && status?.showModalCloseSesion}
                acceptFunction={closeSession}
                cancelFunction={closeModal}
                headingText={i18nUtils.get("global.logout")}
                text={i18nUtils.get("desktop.askCloseApp")}
            />
        </div>
    );
};

SessionCLose.propTypes = {
    dispatch: func.isRequired,
    status: shape({
        sessionAboutToExpire: bool.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => ({ status: statusSelectors.getStatus(state) });
export default connect(mapStateToProps)(resizableRoute(SessionCLose));
