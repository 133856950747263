import { Formik } from "formik";
import Box from "pages/_components/Box/Box";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text/Text";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import * as i18n from "util/i18n";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import CredentialTokenComponent from "pages/forms/_components/credential/CredentialTokenComponent";
import Row from "pages/_components/Row/Row";
import { Col } from "react-bootstrap";
import Button from "pages/_components/Button/Button";
import { compose } from "redux";
import { flattenArray, removeDuplicateItems } from "util/array";
import { selectors as walletSelectors, actions as walletActions } from "reducers/wallet/wallet.reducer";
import { arrayOf, bool, func, shape, string } from "prop-types";
import Loader from "pages/_components/Loader/Loader";
import TokenButtonDisclaimer from "pages/_components/TokenButton/TokenButtonDisclaimer";
import { applePayRenderButton, startEventCallback } from "util/wallet/wallet.util";

const STEP_PRE = "stepPre";
const STEP_SEND = "stepSend";

// eslint-disable-next-line no-unused-vars
const EnabledWalletCard = ({
    credentials = [],
    fetching,
    cardId,
    cardType,
    dispatch,
    callbackError,
    scopeError,
    activityId,
    cardWalletData,
    callbackProvision,
}) => {
    const [currentStep, setCurrentStep] = useState(STEP_PRE);

    const buttonWalletRef = useRef(null);

    useEffect(() => {
        setCurrentStep(STEP_PRE);
        dispatch(walletActions.enabledWalletPreRequest(callbackError, scopeError, activityId));
    }, []);

    const renderButtonProvision = (x, y, width, height) => {
        applePayRenderButton(cardWalletData?.cardIdEncrypted, cardWalletData?.clientId, x, y, width, height);
    };

    const callbackProvisionResult = (result) => {
        if (callbackProvision) {
            callbackProvision(result);
        }
    };

    useEffect(() => {
        if (buttonWalletRef?.current && currentStep && currentStep === STEP_SEND) {
            startEventCallback(callbackProvisionResult);
            const boundingButton = buttonWalletRef?.current?.getBoundingClientRect();
            renderButtonProvision(
                boundingButton?.left || boundingButton?.x,
                boundingButton?.top || boundingButton?.y,
                boundingButton?.width,
                40,
            );
        }
    }, [currentStep]);

    const enabledCardWalletProvision = (cardWalletDataRef) => {
        if (cardWalletDataRef?.cardIdEncrypted && cardWalletDataRef?.clientId) {
            setCurrentStep(STEP_SEND);
            return;
        }
        setCurrentStep(STEP_PRE);
    };

    useEffect(() => {
        enabledCardWalletProvision(cardWalletData);
    }, [cardWalletData]);

    const renderConfirmMode = () => (
        <Box display="flex" column alignX="center" background="white" className="mt-7" borderRadius="md" fullWidth>
            <Text
                component="p"
                size="4"
                bold
                align="center"
                color="heading"
                className="mx-5 mb-5"
                labelKey="enabled.wallet.step1.title"
            />
            <Text
                component="p"
                align="center"
                size="5"
                color="heading"
                className="mx-5  mb-5"
                labelKey="enabled.wallet.step2.subtitle"
            />

            <Box display="flex" className="p-1 mb-5 mt-10" fullWidth>
                <TokenButtonDisclaimer label="enabled.wallet.otp.success" icon="images/successRadious.svg" />
            </Box>
            <Box className="mt-10" fullWidth>
                <Row className="justify-content-center">
                    <Col xs={12} md={8} lg={12}>
                        <div ref={buttonWalletRef} className="appleWalletButton" />
                        <Button
                            bsStyle="outline"
                            className="mt-1"
                            label="global.cancel"
                            block
                            onClick={() => {
                                if (callbackError) {
                                    callbackError();
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Box>
        </Box>
    );

    const renderPreModeForm = () => (
        <Formik
            initialValues={{ otp: "" }}
            validationSchema={() => {
                const requiredMessage = i18n.get(DEFAULT_REQUIRED);
                return Yup.object().shape({
                    otp: Yup.string().required(requiredMessage),
                });
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                dispatch(
                    walletActions.enabledWalletSendRequest(callbackError, scopeError, cardId, cardType, values?.otp),
                );
                resetForm({
                    otp: "",
                });
            }}>
            {(props) => (
                // eslint-disable-next-line react/prop-types
                <form onSubmit={props.handleSubmit} className="full-height full-width">
                    <Box display="flex" column fullWidth fullHeight>
                        <CredentialTokenComponent
                            credentials={credentials}
                            propsForm={props}
                            onChangeToken={(tokenCode) => {
                                // eslint-disable-next-line react/prop-types
                                if (props?.setFieldValue) {
                                    // eslint-disable-next-line react/prop-types
                                    props.setFieldValue("otp", tokenCode);
                                }
                            }}
                        />
                        <Box className="mt-10" fullWidth>
                            <Row className="justify-content-center">
                                <Col xs={12} md={8} lg={12}>
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        label="enabled.wallet.step1.title.confirm"
                                        loading={false}
                                        block
                                    />
                                    <Button
                                        bsStyle="outline"
                                        label="global.cancel"
                                        block
                                        onClick={() => {
                                            if (callbackError) {
                                                callbackError();
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Box>
                    </Box>
                </form>
            )}
        </Formik>
    );

    const renderPreMode = () => (
        <Box display="flex" column alignX="center" background="white" className="mt-7" borderRadius="md" fullWidth>
            <Text
                component="p"
                size="2"
                bold
                align="center"
                color="heading"
                className="mx-5 mb-5"
                labelKey="enabled.wallet.step1.title"
            />
            <Text
                component="p"
                align="center"
                size="4"
                color="heading"
                className="mx-5 mb-5"
                labelKey="enabled.wallet.step1.subtitle"
            />

            <Box display="flex" className="mt-10" fullWidth>
                {renderPreModeForm()}
            </Box>
        </Box>
    );

    return (
        <>
            {fetching && (
                <Loader
                    zIndex="8"
                    borderRadius="md"
                    classicStyle="true"
                    bgTransparent={false}
                    loaderSmallBoxed={false}
                />
            )}
            <MainContainer classNameMainWrapper="background-menu-background">
                <>
                    {currentStep && currentStep === STEP_PRE && renderPreMode()}
                    {currentStep && currentStep === STEP_SEND && renderConfirmMode()}
                </>
            </MainContainer>
        </>
    );
};

EnabledWalletCard.propTypes = {
    credentials: shape(string),
    fetching: bool,
    cardId: string.isRequired,
    cardType: string.isRequired,
    dispatch: func.isRequired,
    callbackError: func,
    scopeError: arrayOf(string),
    activityId: string,
    cardWalletData: shape({}),
    callbackProvision: func,
};

EnabledWalletCard.defaultProps = {
    credentials: [],
    fetching: false,
    callbackError: () => {},
    scopeError: [],
    activityId: undefined,
    cardWalletData: undefined,
    callbackProvision: undefined,
};

const mapStateToProps = (state) => ({
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(walletSelectors.getCredentialsGroups(state)),
    fetching: walletSelectors.isFetching(state),
    cardWalletData: walletSelectors.getCardWalletData(state),
});
export default connect(mapStateToProps)(EnabledWalletCard);
