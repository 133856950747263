import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { detailsSelectors } from "reducers/administration";
import { actions as formActions } from "reducers/form";
import { detailsActions } from "reducers/administration/medium";
import { actions, selectors as administrationUsersSelector } from "reducers/administration/users";

import DispatcherConfirm from "pages/administration/_components/confirmations/DispatcherConfirm";

const mapStateToProps = (state) => {
    const user = detailsSelectors.getUser(state);
    const userInfo = administrationUsersSelector.getUserInfo(state, user);
    const fetchingUser = detailsSelectors.isFetching(state);
    const fetchingUserInfo = administrationUsersSelector.isFetching(state);
    return {
        user,
        fetching: fetchingUser || fetchingUserInfo,
        hasDispatcherEnabled: userInfo.dispatcher,
        credentialGroups: detailsSelectors.getCredentialGroups(state),
        idTransaction: detailsSelectors.getIdTransaction(state),
        idActivity: detailsSelectors.getIdActivity(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ ...actions, ...detailsActions }, dispatch),
    formActions: bindActionCreators(formActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DispatcherConfirm);
