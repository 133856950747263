import React, { Component, Fragment } from "react";
import { string, shape, arrayOf, func, oneOf, objectOf, bool } from "prop-types";

import * as i18nUtils from "util/i18n";

import Select from "pages/_components/fields/Select";
import List from "pages/_components/List";
import MultiSelectTable from "./MultiSelectTable";
import Button from "../Button";

class MultiSelect extends Component {
    static propTypes = {
        name: string.isRequired,
        options: shape({
            ids: arrayOf(string),
            byId: objectOf(
                shape({
                    label: string,
                    value: string,
                }),
            ),
        }).isRequired,
        values: arrayOf(string),
        noResultsText: string,
        placeholder: string,
        children: func.isRequired,
        onSelect: func.isRequired,
        onDelete: func.isRequired,
        mode: oneOf(["view", "edit"]),
        labelKey: string,
        valueKey: string,
        searchable: bool,
        deletable: bool,
        renderCloseButton: func,
        label: string,
        renderTablePagination: bool,
        tableColumns: arrayOf(shape({})),
    };

    static defaultProps = {
        noResultsText: "global.no.results",
        placeholder: "",
        mode: "edit",
        values: [],
        labelKey: "label",
        valueKey: "value",
        searchable: true,
        deletable: true,
        renderCloseButton: null,
        label: null,
        renderTablePagination: false,
        tableColumns: [],
    };

    renderCloseButton = (option) => {
        const { renderCloseButton, onDelete } = this.props;
        if (renderCloseButton) {
            return renderCloseButton(option);
        }
        return (
            <Button
                bsStyle="link"
                image="images/icons/deleteTrash.svg"
                className="btn-circle-bg"
                onClick={() => onDelete(option)}
            />
        );
    };

    generateTableColumns = () => {
        const { tableColumns, deletable } = this.props;
        if (deletable) {
            tableColumns.push({
                key: "actions",
                dataIndex: "actions",
                width: 1,
            });
        }
        return tableColumns;
    };

    render() {
        const {
            options,
            values,
            noResultsText,
            placeholder,
            children,
            onSelect,
            onDelete,
            mode,
            label,
            deletable,
            renderTablePagination,
            ...props
        } = this.props;
        const i18nLabel = label && i18nUtils.get(label);

        if (mode === "view") {
            return (
                <div className="form-group">
                    <div className="form-group-text">
                        <span className="control-label">{label}</span>
                    </div>
                    <ul className="form-group-control-list list">
                        {values.map((id) => {
                            const { valueKey, labelKey } = this.props;
                            const option = options.byId[id];

                            return (
                                <li key={option[valueKey]} className="list-item">
                                    {option[labelKey]}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        }

        const valuesToRender = values.filter((value) => options.ids.includes(value));
        return (
            <Fragment>
                <Select
                    onChange={onSelect}
                    options={options.ids
                        .filter((id) => !values.some((selectedOption) => id === selectedOption))
                        .map((id) => options.byId[id])}
                    noResultsText={i18nUtils.get(noResultsText)}
                    placeholder={i18nUtils.get(placeholder)}
                    label={i18nLabel}
                    avoidChangeOneOption
                    {...props}
                />
                {values.length > 0 && (
                    <>
                        {renderTablePagination ? (
                            <MultiSelectTable
                                {...this.props}
                                columns={this.generateTableColumns()}
                                list={valuesToRender}
                                options={options}
                                deletable={deletable}
                                deleteButton={this.renderCloseButton}
                            />
                        ) : (
                            <List>
                                {valuesToRender.map((value, index) => (
                                    <List.Item key={value} num={index} className="list-item--deleteable">
                                        {() => {
                                            const option = options.byId[value];
                                            return (
                                                <div className="list-item-inner">
                                                    {children(option)}
                                                    {deletable && this.renderCloseButton(option)}
                                                </div>
                                            );
                                        }}
                                    </List.Item>
                                ))}
                            </List>
                        )}
                    </>
                )}
            </Fragment>
        );
    }
}

export default MultiSelect;
