import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import imageMessageConstruction from "util/messageWeb/resources";
import { get as getConfig } from "util/config";

const WebSiteConstruction = (props) => {
    const { isMobile, history } = props;
    const messageKey = history?.location?.message
        ? i18n.get("forms.servicePayment.outOf.service.label", null, {
              END: getConfig("closing.time.finish"),
              START: getConfig("closing.time.start"),
          })
        : i18n.get("notAvailable.feature");
    const btnHandlerOnClick = () => {
        const { dispatch, hasActiveSession } = props;
        dispatch(push(hasActiveSession ? "/desktop" : "/"));
    };

    const nameResourceImage = isMobile ? "webUnderConstructionMobile" : "webUnderConstruction";
    const imageSrc = imageMessageConstruction[nameResourceImage];
    return (
        <Box display="flex" alignY="center" fullHeight className="px-5">
            <Row heightAuto>
                <Col xs={12}>
                    <Image src={imageSrc} className="web-site-construction-img svg-big-icon" />
                </Col>
                <Col xs={12}>
                    <Box display="flex" alignX="center" fullWidth>
                        <Text
                            component="h1"
                            align="center"
                            color="heading"
                            bold
                            labelKey="global.unexpectedErrorExpression"
                        />
                    </Box>
                    <Box display="flex" alignX="center" fullWidth>
                        <Text align="center" defaultValue={messageKey} />
                    </Box>
                </Col>
                <Col xs={12} md={4} mdOffset={4} className="mt-8">
                    <Button
                        bsStyle="primary"
                        defaultLabelText={i18n.get("error.back")}
                        onClick={btnHandlerOnClick}
                        block
                    />
                </Col>
            </Row>
        </Box>
    );
};
WebSiteConstruction.propTypes = {
    isMobile: bool.isRequired,
    dispatch: func.isRequired,
    hasActiveSession: bool.isRequired,
    history: shape({}),
};

WebSiteConstruction.defaultProps = {
    history: undefined,
};

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default connect(mapStateToProps)(resizableRoute(WebSiteConstruction));
