import { Component } from "react";
import { node } from "prop-types";

import WizardTabs from "pages/_components/wizard/WizardTabs";
import WizardSteps from "pages/_components/wizard/WizardSteps";

class Wizard extends Component {
    static Tab = ({ children }) => children;

    static Step = ({ children }) => children;

    static propTypes = {
        children: node.isRequired,
    };

    static Tabs = WizardTabs;

    static Steps = WizardSteps;

    render() {
        const { children } = this.props;

        return children;
    }
}

export default Wizard;
