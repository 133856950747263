import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import * as i18n from "util/i18n";

class MessageActions extends Component {
    static propTypes = {
        communication: shape({
            direction: string,
            idCommunication: number,
            idFileList: arrayOf(number),
            userRead: bool,
        }).isRequired,
        handleChangeMessageStatus: func.isRequired,
        handleRemoveClick: func.isRequired,
        index: number.isRequired,
    };

    render() {
        const { communication, handleRemoveClick, handleChangeMessageStatus, index } = this.props;

        return (
            <Box display="flex">
                {communication.idFileList.length > 0 && (
                    <Box display="flex" alignY="center" className="px-3">
                        <Image src="images/icons/attachment.svg" />
                    </Box>
                )}
                <Button
                    tooltipHover={
                        communication.userRead
                            ? i18n.get("communications.unreadMessage")
                            : i18n.get("communications.readMessage")
                    }
                    imageMd
                    bsStyle="only-icon"
                    image={communication.userRead ? "images/icons/readMessage.svg" : "images/icons/unreadMessage.svg"}
                    onClick={(e) =>
                        handleChangeMessageStatus(e, communication.idCommunication, communication.userRead, index)
                    }
                />
                <Button
                    tooltipHover={i18n.get("communications.message.delete.label")}
                    imageMd
                    bsStyle="only-icon"
                    image="images/icons/deleteTrash.svg"
                    onClick={(e) => handleRemoveClick(e, communication.idCommunication, index, communication.userRead)}
                />
            </Box>
        );
    }
}

export default MessageActions;
