import WidgetLoading from "pages/_components/WidgetLoading";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as desktopSelectors } from "reducers/desktop";
import { actions, selectors } from "reducers/widgets";
import { selectors as creditCardSelectors } from "reducers/creditCard";
import * as i18nUtils from "util/i18n";
import WidgetsError from "pages/_components/WidgetsError/WidgetsError";
import CardSlider from "pages/_components/CardSlider";
import * as config from "util/config";
import NoProduct from "./_components/NoProduct";

class WidgetCardList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        children: func.isRequired,
        item: func,
        keyExtractor: func,
        name: string.isRequired,
        shouldMapList: bool,
        list: arrayOf(shape({})).isRequired,
        listLength: number,
        isFetching: bool,
        isEditable: bool,
        slider: bool,
        activeEnvironment: shape({}),
        hadError: bool,
    };

    static defaultProps = {
        shouldMapList: true,
        keyExtractor: () => {},
        item: null,
        isFetching: false,
        isEditable: false,
        listLength: 0,
        slider: false,
        activeEnvironment: {},
        hadError: false,
    };

    componentDidMount() {
        const { dispatch, name } = this.props;
        dispatch(actions.listRequest(name));
    }

    refreshWidget = () => {
        const { dispatch, name } = this.props;
        dispatch(actions.listRequest(name));
    };

    renderItem = (item) => {
        const { item: Item, keyExtractor, list } = this.props;
        const numberElements = list.length;
        return <Item key={keyExtractor(item)} {...item} numberElements={numberElements} isWidgetDesktop />;
    };

    validateWidgetList(isFetching, list) {
        if (!isFetching) {
            return <CardSlider>{list.map(this.renderItem)}</CardSlider>;
        }
        return <WidgetLoading loading />;
    }

    renderList = () => {
        const { list, isFetching, name, shouldMapList, isEditable, hadError, creditCards } = this.props;
        if (!shouldMapList) {
            return list;
        }

        const itemsToShowButton = Number(config.get("widgets.product.maxToShow"));
        let widgetList;

        if (list.length > 0 && list[0].productType === "TC") {
            list.forEach((item) => {
                const found = creditCards?.find((creditCard) => creditCard.idProduct === item.idProduct);
                if (found) {
                    item.isAditional = found.isAditional;
                }
            });
            widgetList = this.validateWidgetList(
                isFetching,
                list?.filter((item) => item.isAditional === false).slice(0, itemsToShowButton),
            );
        } else {
            widgetList = this.validateWidgetList(isFetching, list?.slice(0, itemsToShowButton));
        }

        return (
            <WidgetLoading loading={!list.length && isFetching}>
                <div className={`table table--products mb-0 mb-3 ${name}`} aria-hidden={isEditable}>
                    <ul aria-label={i18nUtils.get(`widgets.list.title.${name}.a11y`)} className="table-body">
                        {hadError ? (
                            <>
                                <WidgetsError onClick={this.refreshWidget} name={name} />
                            </>
                        ) : (
                            widgetList
                        )}
                    </ul>
                </div>

                {list.length === 0 && <NoProduct text={`desktop.widgets.${name}.empty`} />}
            </WidgetLoading>
        );
    };

    render() {
        const { children, list, listLength } = this.props;
        const length = listLength || list.length;
        return children(this.renderList(), length);
    }
}

const mapStateToProps = (state, { name }) => {
    const { isFetching, data, hadError } = selectors.getWidget(state, name);
    const isFetchingCards = creditCardSelectors.isFetchingCards(state);
    let listData = data[name] === undefined ? [] : data[name];
    if (name === "creditLines") {
        listData = data.total === undefined ? [] : [data.total];
    }

    return {
        creditCards: creditCardSelectors.getList(state),
        isFetching: isFetching || (name === "creditCards" && isFetchingCards),
        list: listData,
        listLength: data?.total ? data.total : null,
        isEditable: desktopSelectors.getIsEditale(state),
        hadError,
    };
};

export default connect(mapStateToProps)(WidgetCardList);
