import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Notification from "pages/_components/Notification";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { arrayOf, bool, func, shape, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import Text from "pages/_components/Text";
import Row from "pages/_components/Row";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import { groupFormDataSelectors } from "reducers/administration";

import Image from "pages/_components/Image";
import MultiSelect from "pages/_components/fields/formik/MultiSelect";
import { connect } from "react-redux";

const GroupFormMembers = ({
    idForm,
    data,
    isCreate,
    handleBack,
    handleContinue,
    adminUsers,
    availableUsers,
    originalUsers,
}) => {
    const { name } = data;
    const requiredMessage = i18n.get("global.field.required");

    const renderHeader = () => (
        <>
            <Notification scopeToShow="groupForm" />
            <Head
                onBack={handleBack}
                title="administration.advanced.group.modifyMembers.title"
                textBack={isCreate ? "administration.groups.returnToList" : "administration.groups.returnToDetail"}
            />
        </>
    );

    const handleSubmit = (values, { setFieldError }) => {
        const added = [];
        const deleted = [];
        values.users.forEach((idUser) => {
            if (!originalUsers.includes(idUser)) {
                added.push(availableUsers.byId[idUser]);
            }
        });
        originalUsers.forEach((idUser) => {
            if (!values.users.includes(idUser)) {
                deleted.push(availableUsers.byId[idUser]);
            }
        });
        if (deleted.length === 0 && added.length === 0) {
            setFieldError("users", i18n.get("administration.advanced.group.modify.members.error", "error"));
            return;
        }

        handleContinue({ ...values, added, deleted, isModifyMember: true });
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(requiredMessage),
    });

    const populateDataTable = (dataToRender, deletable, deleteButton) =>
        dataToRender.map((item) => {
            const { idUser, fullName, documentType, documentNumber } = item;
            return {
                key: idUser.toString(),
                name: <div>{fullName}</div>,
                identification: (
                    <div>
                        {documentType} {documentNumber}
                    </div>
                ),
                actions: deletable && deleteButton(item),
            };
        });

    return (
        <>
            {renderHeader()}
            <MainContainer className="main-container">
                <Formik
                    enableReinitialize
                    initialValues={data}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}>
                    {() => (
                        <Form>
                            <Box borderRadius="lg" background="white" className="box-shadow-small mt-3 p-7">
                                <Box borderRadius="lg" background="white" className="box-shadow-small px-7 pt-6 pb-7">
                                    <Box>
                                        <Text
                                            size="5"
                                            labelKey="administration.advanced.group.modify.members.title"
                                            semibold
                                            rol={name}
                                        />
                                    </Box>

                                    <Box className="mt-3 mb-7">
                                        <Text labelKey="administration.advanced.group.modify.members.info" />
                                    </Box>
                                    <MultiSelect
                                        name="users"
                                        label={`${idForm}.members.list.title`}
                                        options={availableUsers}
                                        placeholder={`${idForm}.users.placeholder`}
                                        mode="edit"
                                        labelKey="labelView"
                                        valueKey="idUser"
                                        tableFilters={["fullName", "lastName", "documentNumber"]}
                                        tableColumns={[
                                            {
                                                key: "name",
                                                dataIndex: "name",
                                                width: 300,
                                            },
                                            {
                                                key: "identification",
                                                dataIndex: "identification",
                                                width: 200,
                                            },
                                        ]}
                                        populateDataTable={populateDataTable}
                                        renderTablePagination>
                                        {({ idUser, fullName }) => (
                                            <>
                                                <span className="data-desc">{fullName}</span>
                                                {adminUsers.includes(idUser) && (
                                                    <Image
                                                        src="images/administrator.svg"
                                                        className="svg-icon svg-caret"
                                                    />
                                                )}
                                            </>
                                        )}
                                    </MultiSelect>
                                </Box>
                            </Box>
                            <Row gapX="7" className="mt-7 mb-7">
                                <Col xs={3} xsOffset={3}>
                                    <Button
                                        bsStyle="outline"
                                        label="global.cancel"
                                        btnUppercase={false}
                                        block
                                        type="button"
                                        onClick={handleBack}
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Button
                                        bsStyle="primary"
                                        label="global.continue"
                                        btnUppercase={false}
                                        block
                                        type="submit"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </>
    );
};

GroupFormMembers.propTypes = {
    idForm: string.isRequired,
    data: shape({}).isRequired,
    isCreate: bool.isRequired,
    handleBack: func.isRequired,
    handleContinue: func.isRequired,
    adminUsers: arrayOf(string).isRequired,
    originalUsers: arrayOf(string).isRequired,
    availableUsers: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    availableUsers: groupFormDataSelectors.getAvailableUsers(state),
    adminUsers: groupFormDataSelectors.getAdminUsers(state),
    adminGroup: groupFormDataSelectors.isAdminGroup(state),
});

export default connect(mapStateToProps)(GroupFormMembers);
