import classNames from "classnames";
import { node, string } from "prop-types";
import React from "react";

const GridTableHeader = ({ children, className, tableHeaderBackground, ...props }) => (
    <>
        {!className?.includes("noHeader") && (
            <thead
                // background={tableHeaderBackground}
                className={classNames("grid-table-header", className, {
                    [`background-${tableHeaderBackground}`]: tableHeaderBackground,
                })}
                {...props}>
                {React.cloneElement(children, {
                    isInHeader: true,
                })}
            </thead>
        )}
    </>
);

GridTableHeader.propTypes = {
    children: node.isRequired,
    className: string,
    tableHeaderBackground: string,
};

GridTableHeader.defaultProps = {
    className: null,
    tableHeaderBackground: "secondary-background-color",
};

export default GridTableHeader;
