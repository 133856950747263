import React from "react";
import { string, number, node, object, arrayOf, oneOfType } from "prop-types";

export default function FlowTransition({ pages, currentPage }) {
    const Page = pages[currentPage];
    return <Page />;
}

FlowTransition.propTypes = {
    pages: oneOfType([node, arrayOf(node), object]),
    currentPage: oneOfType([number, string]),
};

FlowTransition.defaultProps = {
    pages: [],
    currentPage: 0,
};
