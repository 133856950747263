import React, { Component, Fragment } from "react";
import { shape } from "prop-types";
import { connect } from "react-redux";

import { selectors as formSelectors } from "reducers/form";

import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import ProductTag from "pages/_components/ProductTag";

class PayThirdPartiesCreditCard extends Component {
    static propTypes = {
        previewData: shape({}).isRequired,
    };

    render() {
        const {
            debitAmount,
            amount,
            creditCardNumber,
            debitAccountAlias,
            notificationEmails,
            notificationBody,
        } = this.props.previewData;

        const showDebitAmount = debitAmount.currency !== amount.currency;

        return (
            <Fragment>
                <p className="text-lead">
                    <I18n id="forms.payThirdPartiesCreditCard.confirmation.title" />
                </p>

                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.payThirdPartiesCreditCard.confirmation.creditCard" />{" "}
                    </span>
                    <span className="data-text">{creditCardNumber}</span>
                </div>

                <div className="transfer-block">
                    <div className="transfer-data data-wrapper transfer-data-highlight">
                        <span className="data-label">
                            <I18n id="forms.payThirdPartiesCreditCard.confirmation.amount" />{" "}
                        </span>
                        <FormattedAmount currency={amount.currency} quantity={amount.quantity} />
                    </div>

                    {showDebitAmount && (
                        <div className="transfer-data data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.payThirdPartiesCreditCard.confirmation.debitAmount" />{" "}
                            </span>
                            <FormattedAmount currency={debitAmount.currency} quantity={debitAmount.quantity} />
                        </div>
                    )}
                </div>

                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.payThirdPartiesCreditCard.confirmation.debitAccount" />{" "}
                    </span>
                    <ProductTag alias={debitAccountAlias} />
                </div>

                {notificationEmails && (
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.payThirdPartiesCreditCard.confirmation.notificationEmails" />{" "}
                        </span>
                        <span className="data-text">{notificationEmails.join(", ")}</span>
                    </div>
                )}
                {notificationBody && (
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.payThirdPartiesCreditCard.confirmation.notificationBody" />{" "}
                        </span>
                        <span className="data-text">{notificationBody}</span>
                    </div>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    name: formSelectors.getName(state),
    previewData: formSelectors.getPreviewData(state),
});

export default connect(mapStateToProps)(PayThirdPartiesCreditCard);
