import { crashLogData } from "util/crashReport/crashReport.util";

export const DEBIT_CARD_WALLET_TYPE = "DEBIT";
export const CREDIT_CARD_WALLET_TYPE = "CREDIT";
export const DEFAULT_CARD_BRAND = "mastercard";

export const PROVISION_SUCCESS_CODE = "PROVISIONING_SUCCESS";
export const PROVISIONING_ERROR_CODE = "PROVISIONING_ERROR";
export const PROVISIONING_EXCEPTION_CODE = "PROVISIONING_EXCEPTION";
export const START_EVENTS_CODE = "START_EVENTS";
export const MINIMUM_VERSION_IOS_SUPPORTED = "13.4";

export const applePayPluginIsAvailable = () => !!window?.VisaDigitalEnablementPlugin;

export const applePayCardWasDigitized = (creditCardPanSuffix) =>
    new Promise((resolve) => {
        try {
            crashLogData({ title: "respDigitized1", creditCardPanSuffix });

            if (!applePayPluginIsAvailable()) {
                resolve(false);
                return;
            }

            if (!creditCardPanSuffix) {
                resolve(false);
                return;
            }

            window.VisaDigitalEnablementPlugin.isCardDigitizedWithPanSuffix(
                creditCardPanSuffix,
                (response) => {
                    crashLogData({ title: "respDigitized", data: response, creditCardPanSuffix });
                    const isDigitized = !!(response && response.trim().toUpperCase() === "YES");
                    resolve(isDigitized);
                },
                (error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    resolve(false);
                    crashLogData({ title: "respDigitized2", error });
                },
            );
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            resolve(true);
            crashLogData({ title: "respDigitized2", e });
        }
    });

export const applePayRenderButton = (cardIdEncrypted, clientId, x, y, width, heigth, cardBrand = DEFAULT_CARD_BRAND) =>
    new Promise((resolve) => {
        try {
            if (!applePayPluginIsAvailable()) {
                resolve(false);
                return;
            }

            if (!cardIdEncrypted || !clientId || !cardBrand || !x || !y || !width || !heigth) {
                resolve(false);
                return;
            }

            window.VisaDigitalEnablementPlugin.startPushProvisioning(
                cardIdEncrypted,
                clientId,
                cardBrand,
                x,
                y,
                width,
                heigth,
                // eslint-disable-next-line no-unused-vars
                (responseCode) => {
                    resolve(true);
                },
                // eslint-disable-next-line no-unused-vars
                (error) => {
                    resolve(false);
                },
            );
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            resolve(false);
        }
    });

export const applePayRemovePassButton = () =>
    new Promise((resolve) => {
        try {
            if (!applePayPluginIsAvailable()) {
                resolve(false);
                return;
            }

            window.VisaDigitalEnablementPlugin.removePassButton();
            resolve(true);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            resolve(false);
        }
    });

export const startEventCallback = (callback) => {
    try {
        if (!applePayPluginIsAvailable() || !callback) {
            callback(PROVISIONING_EXCEPTION_CODE);
            return;
        }

        window.VisaDigitalEnablementPlugin.startEvents(callback);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        callback(PROVISIONING_EXCEPTION_CODE);
    }
};
