import Box from "pages/_components/Box";
import GridTable from "pages/_components/GridTable/GridTable";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, shape, arrayOf, func } from "prop-types";
import React from "react";

const ListCommerce = ({ viewList, commercePanama, commerceOtherCountry, onSelectBeneficiary }) => (
    <GridTable>
        <GridTable.Header tableHeaderBackground="transparent">
            <GridTable.Container columns={1} rows={1} padding="p-5">
                <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                    <Box display="flex" fullWidth>
                        <Text labelKey="wally.forms.transfers.commerce" size={4} color="heading" bold capitalize />
                    </Box>
                </GridTable.Data>
            </GridTable.Container>
        </GridTable.Header>
        <GridTable.Body>
            {viewList
                ? commercePanama.map((element) => (
                      <GridTable.Container
                          columnsTemplate="2rem 1fr 1.5rem"
                          key={element.fullName}
                          rows={1}
                          className="min-height-10-i"
                          onClick={() => onSelectBeneficiary(element)}>
                          <GridTable.Data className="p-0" columnStart={1} rowWidth={1} alignX="flex-start">
                              {element?.logo ? (
                                  <img src={element?.logo} alt={element?.logoDescription || ""} />
                              ) : (
                                  <Image
                                      src="bolivariano-mobile-logo-color.svg"
                                      height="8"
                                      wrapperWidth="8"
                                      wrapperHeight="8"
                                  />
                              )}
                          </GridTable.Data>
                          <GridTable.Data className="p-0" columnStart={2} alignX="flex-start">
                              <Text bold size="4" color="heading">
                                  {element.fullName}
                              </Text>
                          </GridTable.Data>
                      </GridTable.Container>
                  ))
                : commerceOtherCountry.map((element) => (
                      <GridTable.Container
                          columnsTemplate="2rem 1fr 2rem"
                          key={element.fullName}
                          rows={1}
                          className="min-height-10-i"
                          onClick={() => onSelectBeneficiary(element)}>
                          <GridTable.Data className="p-0" columnStart={1} rowWidth={1} alignX="flex-start">
                              {element?.logo ? (
                                  <img src={element?.logo} alt={element?.logoDescription || ""} />
                              ) : (
                                  <Image
                                      src="bolivariano-mobile-logo-color.svg"
                                      height="8"
                                      wrapperWidth="8"
                                      wrapperHeight="8"
                                  />
                              )}
                          </GridTable.Data>
                          <GridTable.Data className="p-0" columnStart={2} alignX="flex-start">
                              <Text bold size="4" color="heading">
                                  {element.fullName}
                              </Text>
                          </GridTable.Data>
                          <GridTable.Data className="p-0" columnStart={3} alignX="flex-end">
                              <Image
                                  src="images/location.svg"
                                  height="5"
                                  width="4"
                                  wrapperWidth="4"
                                  wrapperHeight="5"
                              />
                              <Text size="6" color="text-secondary" className="ml-2">
                                  {element.countryCode}
                              </Text>
                          </GridTable.Data>
                      </GridTable.Container>
                  ))}
            {viewList
                ? commercePanama.length === 0 && (
                      <Box display="flex" alignX="center" className="pt-5 px-5">
                          <Text labelKey="wally.list.without.contacts" />
                      </Box>
                  )
                : commerceOtherCountry.length === 0 && (
                      <Box display="flex" alignX="center" className="pt-5 px-5">
                          <Text labelKey="wally.list.without.contacts" />
                      </Box>
                  )}
        </GridTable.Body>
    </GridTable>
);

ListCommerce.propTypes = {
    viewList: bool,
    commercePanama: arrayOf(shape({})),
    commerceOtherCountry: arrayOf(shape({})),
    onSelectBeneficiary: func.isRequired,
};

ListCommerce.defaultProps = {
    viewList: true,
    commercePanama: [],
    commerceOtherCountry: [],
};

export default ListCommerce;
