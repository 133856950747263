import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import HeadSimple from "pages/_components/HeadSimple";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as settingsActions } from "reducers/settings";
import { getArray } from "util/config";

class LanguageSelection extends Component {
    static propTypes = {
        activeLanguage: string.isRequired,
        dispatch: func.isRequired,
        history: shape({
            goBack: func,
        }),
        supportedLanguages: arrayOf(string).isRequired,
        legendTextID: string,
        fetching: bool,
    };

    static defaultProps = {
        history: null,
        legendTextID: "settings.changeLanguage",
        fetching: false,
    };

    state = {
        firstLoading: true,
    };

    handleClick = (language) => {
        const { activeLanguage, dispatch } = this.props;

        if (language !== activeLanguage) {
            this.setState({ firstLoading: false });
            document.documentElement.lang = language;

            dispatch(settingsActions.changeLanguage(language, false));
        }
    };

    backButtonAction = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { activeLanguage, supportedLanguages, legendTextID, fetching } = this.props;
        const { firstLoading } = this.state;

        return (
            <Box display="flex" column className="px-md-7 pt-7 pt-md-3 pb-7 px-5 language-page">
                <Notification scopeToShow="changeLanguage" />
                <HeadSimple
                    onRightClick={this.backButtonAction}
                    title="settings.changeLanguage"
                    className="mb-6 mb-md-0"
                />
                <MainContainer showLoader={fetching && firstLoading}>
                    <Row>
                        <Col className="col-12">
                            <fieldset className="form-group">
                                {legendTextID && (
                                    <I18n
                                        id={legendTextID}
                                        component="legend"
                                        componentProps={{ className: "visually-hidden" }}
                                    />
                                )}
                                <div className="selection-list p-1 mt-7">
                                    {Object.keys(supportedLanguages).map((idLanguage) => (
                                        <div className="c-control  c-control--radio" key={`language-${idLanguage}`}>
                                            <Button
                                                className={classNames("primary-loader", {
                                                    "is-active": supportedLanguages[idLanguage] === activeLanguage,
                                                })}
                                                bsStyle="chip"
                                                image={
                                                    supportedLanguages[idLanguage] === activeLanguage
                                                        ? "images/check.svg"
                                                        : ""
                                                }
                                                onClick={() => this.handleClick(supportedLanguages[idLanguage])}
                                                label={`settings.changeLanguage.label.${supportedLanguages[idLanguage]}`}
                                                loading={fetching && supportedLanguages[idLanguage] !== activeLanguage}
                                                block
                                            />
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                        </Col>
                    </Row>
                </MainContainer>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
    supportedLanguages: getArray("frontend.i18n.languages"),
    fetching: i18nSelectors.getFetching(state),
});

export default connect(mapStateToProps)(LanguageSelection);
