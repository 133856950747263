import { Component } from "react";
import { connect } from "react-redux";
import { arrayOf, string, shape, func } from "prop-types";

import { permissionsSelectors } from "reducers/administration";
import * as administrationUtils from "util/administration";

class AdvancedPermissionsAmount extends Component {
    static propTypes = {
        groups: administrationUtils.groupsPropType.isRequired,
        permissions: administrationUtils.permissionsPropType.isRequired,
        products: arrayOf(
            shape({
                idProduct: string,
            }),
        ),
        children: func.isRequired,
    };

    static defaultProps = {
        products: [],
    };

    countOptions = ({ childrenList, permissionList, idItem }, predicate) => {
        if (permissionList.length && permissionList[0].advancedAllowProductSelection) {
            return predicate ? predicate({ idItem, productTypes: permissionList[0].productTypes.split(",") }) : 1;
        }

        if (childrenList.length) {
            return childrenList.reduce((amount, option) => amount + this.countOptions(option, predicate), 0);
        }

        if (predicate && !predicate({ idItem })) {
            return 0;
        }

        return 1;
    };

    render() {
        const { children, groups, permissions, products } = this.props;

        return children(
            groups.reduce((amounts, group) => {
                const amount = this.countOptions(group, ({ idItem, productTypes = [] }) => {
                    const values = permissions[idItem] || [];

                    if (!productTypes.length) {
                        return values.length;
                    }

                    const filteredProducts = products.filter(({ productType }) => productTypes.includes(productType));
                    const { selectedSmartGroupAmount, smartGroupAmount } = administrationUtils.smartGroupsOptionsCount(
                        productTypes,
                        values,
                    );
                    const { selectedProductsAmount, productsAmount } = administrationUtils.productsOptionsCount(
                        filteredProducts,
                        values,
                    );

                    if (selectedSmartGroupAmount === smartGroupAmount || selectedProductsAmount === productsAmount) {
                        return true;
                    }

                    return values.length;
                });

                if (!amount) {
                    return amounts;
                }

                return {
                    ...amounts,
                    [group.idItem]: amount,
                };
            }, {}),
            groups.reduce((amounts, group) => ({ ...amounts, [group.idItem]: this.countOptions(group) }), {}),
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    products: permissionsSelectors.getProducts(state),
    permissions: ownProps.permissions || permissionsSelectors.getPermissions(state),
    groups: permissionsSelectors.getGroups(state),
});

export default connect(mapStateToProps)(AdvancedPermissionsAmount);
