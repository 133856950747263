import React, { Component } from "react";
import { format } from "date-fns";
import { string, bool } from "prop-types";
import { connect } from "react-redux";
import Text from "pages/_components/Text";
import * as i18n from "util/i18n";

class FormattedDate extends Component {
    static propTypes = {
        date: string.isRequired,
        dateFormat: string.isRequired,
        anotherFormat: string,
        bold: bool,
        color: string,
        size: string,
    };

    static defaultProps = {
        bold: false,
        color: null,
        size: null,
        anotherFormat: null,
    };

    render() {
        const { date, dateFormat, bold, color, size, anotherFormat } = this.props;

        return (
            <Text color={color} size={size} bold={bold}>
                {format(date, anotherFormat || dateFormat)}
            </Text>
        );
    }
}

const mapStateToProps = () => ({
    dateFormat: i18n.get("datepicker.format"),
});

export default connect(mapStateToProps)(FormattedDate);
