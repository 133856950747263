import React, { Component } from "react";
import { object } from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";

import * as config from "util/config";

import FieldError from "pages/_components/fields/FieldError";

class Captcha extends Component {
    captchaRef = React.createRef();

    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        form: object.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        field: object.isRequired,
    };

    componentDidUpdate(prevProps) {
        if (this.props.field.value !== "" && prevProps.form.isSubmitting && !this.props.form.isSubmitting) {
            this.captchaRef.current.reset();
        }
    }

    handleChange = (value) => {
        this.props.form.setFieldValue(this.props.field.name, value);
    };

    handleExpired = () => {
        this.props.form.setFieldValue(this.props.field.name, "");
    };

    render() {
        const {
            field,
            form: { touched, errors },
        } = this.props;

        return (
            <div
                className={classNames("form-group captcha-wrapper", {
                    "has-error": touched[field.name] && errors[field.name],
                })}>
                <ReCAPTCHA
                    sitekey={config.get("core.auth.captcha.google.siteKey")}
                    onChange={this.handleChange}
                    ref={this.captchaRef}
                    onExpired={this.handleExpired}
                    className="g-recaptcha"
                />
                {touched[field.name] && errors[field.name] && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default Captcha;
