import React, { Component, Fragment } from "react";
import { Form, Field } from "formik";
import Col from "react-bootstrap/lib/Col";
import Redirect from "react-router-dom/Redirect";

import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { shape, string, bool, arrayOf } from "prop-types";

class GroupsOfUserConfirm extends Component {
    static propTypes = {
        user: shape({}).isRequired,
        routerActions: shape({}).isRequired,
        actions: shape({}).isRequired,
        formActions: shape({}).isRequired,
        match: shape({}).isRequired,
        groupsToSave: arrayOf(shape({})).isRequired,
        idTransaction: string,
        idActivity: string,
        isDesktop: bool.isRequired,
        credentialGroups: arrayOf(shape({})).isRequired,
        fetching: bool.isRequired,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    componentDidMount() {
        const { user, routerActions } = this.props;
        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, match, groupsToSave, idTransaction, idActivity } = this.props;
        const secondFactor = credentials;
        if (!idTransaction) {
            actions.updateGroupsOfUserConfirm(
                {
                    secondFactor,
                    idUser: match.params.id,
                    groupsToSave,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;
        routerActions.goBack();
    };

    renderForm = ({ isSubmitting }) => (
        <Form>
            <Container className="container--layout items-center" gridClassName="form-content container--flex-middle">
                <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                    <I18n id="form.credential.hint" />
                    <Field name="otp" type="otp" idForm="administration.users.action" component={Credential} />
                </Col>
            </Container>
            <Container className="container--layout items-center" gridClassName="form-footer">
                <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                    <Button type="submit" bsStyle="primary" label="global.send" loading={isSubmitting} block />
                </Col>
            </Container>
        </Form>
    );

    render() {
        const { isDesktop, credentialGroups, groupsToSave, fetching, user } = this.props;
        if (!isDesktop) {
            return <Redirect to="/desktop" />;
        }

        const groupText = groupsToSave.map(({ name, idGroupAsString }) => <span key={idGroupAsString}> {name} </span>);

        return (
            <Fragment>
                <Notification scopeToShow="administrationGroupsOfUser" />
                <Head
                    title="administration.groupsOfUsers.title"
                    onBack={this.handleBack}
                    closeLinkTo="/administration/users"
                />
                <MainContainer showLoader={fetching && !Object.keys(user).length}>
                    <div className="above-the-fold">
                        <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                <p className="text-lead">
                                    <span>
                                        <I18n id="forms.default.confirmation.title" />
                                    </span>
                                </p>
                                <div className="transfer-block">
                                    <Heading.DataGroup
                                        containerClassName="transfer-data data-wrapper"
                                        label="administration.signature.user"
                                        data={`${user.firstName} ${user.lastName}`}
                                    />
                                </div>

                                <div className="transfer-block">
                                    <h4>
                                        <I18n id="administration.users.detail.configureGroups.confirm" />
                                    </h4>
                                    {groupText}
                                </div>
                            </Col>
                        </Container>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                            idActivity="administration.user.detail.groups.modify.send"
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default resizableRoute(GroupsOfUserConfirm);
