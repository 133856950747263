import { useEffect, useState } from "react";
import { parse } from "query-string";
import { USD_CURRENCY } from "constants.js";
import * as creditCardsUtil from "util/creditCards";

export const MINIMUM_PAYMENT = "minimumPayment";
export const STMT_BALANCE = "stmtBalance";
export const BALANCE_DUE_DATE = "balanceDueDate";
export const OTHER_AMOUNT = "otherAmount";

export const generateTypePaymentList = (acc) => {
    let optionList = [
        {
            paymentType: MINIMUM_PAYMENT,
            amount: {
                quantity: acc?.minimumPayment || 0,
                currency: acc?.minimumPaymentCurrency || USD_CURRENCY,
            },
        },
        {
            paymentType: STMT_BALANCE,
            amount: {
                quantity: acc?.stmtBalance || 0,
                currency: acc?.stmtBalanceCurrency || USD_CURRENCY,
            },
            tooltip: "creditCard.details.info.detainedBalance.tooltip",
        },
        {
            paymentType: BALANCE_DUE_DATE,
            amount: {
                quantity: acc?.balance || 0,
                currency: acc?.balanceCurrency || USD_CURRENCY,
            },
            tooltip: "creditCard.details.info.currentDue.tooltip",
        },
        {
            paymentType: OTHER_AMOUNT,
            amount: null,
        },
    ];

    if (acc?.relationType && acc?.relationType === "N" && !acc?.hasOwnLimit) {
        optionList = optionList.filter(
            (item) => item.paymentType === BALANCE_DUE_DATE || item.paymentType === OTHER_AMOUNT,
        );
    }
    return optionList;
};

export const useAmountPaymentOptions = (creditCardInfo) => {
    const [amountPaymentOptions, setAmountPaymentOptions] = useState({ options: [] });

    const createTypePaymentList = (acc) => ({
        idProduct: acc?.idProduct,
        types: generateTypePaymentList(acc),
    });

    useEffect(() => {
        if (
            creditCardInfo &&
            Object.keys(creditCardInfo).length > 0 &&
            !amountPaymentOptions?.options?.some((option) => option.idProduct === creditCardInfo?.idProduct)
        ) {
            setAmountPaymentOptions((state) => ({
                options: [...state.options, createTypePaymentList(creditCardInfo)],
            }));
        }
    }, [creditCardInfo]);
    return [amountPaymentOptions, setAmountPaymentOptions];
};

export const useCreditCardPaySelectorOptions = (creditCardList) => {
    const [creditCardOptions, setCreditCardOptions] = useState([]);

    const createCreditCardList = (acc) => ({
        ...acc,
        balance: { currency: acc.currency, quantity: acc.totalAmount },
        id: acc.idProduct,
    });
    useEffect(() => {
        const nonPrepaidWithAdditionals = creditCardsUtil.getNonPrepaidCreditCardsWithAdditionals(creditCardList);
        const onlyDueCreditCards = nonPrepaidWithAdditionals.filter((creditCard) => creditCard.isVisibleInPaymentCard);
        setCreditCardOptions({
            options: onlyDueCreditCards.map(createCreditCardList),
        });
    }, [creditCardList]);
    return creditCardOptions;
};

export const useCreditCardRechargeSelectorOptions = (creditCardList) => {
    const [creditCardOptions, setCreditCardOptions] = useState([]);

    const createCreditCardList = (acc) => ({
        ...acc,
        balance: { currency: acc.currency, quantity: acc.totalAmount },
        id: acc.idProduct,
    });
    useEffect(() => {
        const nonPrepaidWithAdditionals = creditCardsUtil.getPrepaidCreditCards(creditCardList);
        setCreditCardOptions({
            options: nonPrepaidWithAdditionals.map(createCreditCardList),
        });
    }, [creditCardList]);
    return creditCardOptions;
};

export const useSelectorOptions = (preDataForm) => {
    const [accountOptions, setAccountOptions] = useState([]);

    const createAccountList = (acc) => ({
        ...acc,
        balance: { currency: acc.currency, quantity: acc.balance },
        id: acc.idProduct,
        disabled: acc.isDisabled,
    });
    useEffect(() => {
        setAccountOptions({
            options: preDataForm?.debitAccountList?.map(createAccountList),
        });
    }, [preDataForm]);
    return accountOptions;
};

export const useCreditCardPayment = (location) => {
    const [creditCardPayment, setCreditCardPayment] = useState("");
    useEffect(() => {
        setCreditCardPayment(parse(location.search)?.creditCard);
    }, [location]);
    return [creditCardPayment];
};
