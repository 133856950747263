import * as API from "middleware/api";

export const listCheckbooks = (idAccount) => API.executeWithAccessToken("/checkbooks.list", { idAccount });

export const listChecks = (idAccount, idCheckbook) =>
    API.executeWithAccessToken("/checks.list", { idAccount, idCheckbook });

export const listAllChecks = (idAccount, typeFilter, startDate, endDate, amountStart, amountEnd, checkNumber, nextIndex) => 
    API.executeWithAccessToken("/checks.listAll", { 
        idAccount,
        typeFilter,
        startDate,
        endDate,
        amountStart,
        amountEnd,
        checkNumber,
        nextIndex
    });

export const getCheckUrl = (idAccount, checkNumber) =>
    API.executeWithAccessToken("/checks.image", {
        idAccount,
        checkNumber,
    });

export const downloadChecks = (
    {
        idAccount, 
        typeFilter, 
        startDate, 
        endDate, 
        amountStart, 
        amountEnd, 
        checkNumber, 
        nextIndex, 
    },
    format,
) =>
    API.executeWithAccessToken("/checks.download", {
        idAccount, 
        typeFilter, 
        startDate, 
        endDate, 
        amountStart, 
        amountEnd, 
        checkNumber, 
        nextIndex,
        format,
    });

export const imageCheck = (idAccount, checkNumber, dateCheck) =>
    API.executeWithAccessToken("/checks.image", {
        idAccount,
        checkNumber,
        dateCheck,
    });