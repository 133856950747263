import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import PinInput from "pages/_components/fields/PinInput";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux/actions";

const changePhoneNumberStep2 = ({ dispatch, history, fetching }) => {
    const handleBack = () => {
        history.goBack();
    };
    return (
        <PageLoading loading={fetching}>
            <Head titleImage="wally-head.svg" onBack={handleBack} title="ajustes wally" />
            <MainContainer>
                <Box display="flex" alignX="center" fullWidth className="pt-8 mb-7">
                    <Image src="images/smartphone-text-wally.svg" isMobileNative />
                </Box>
                <Text
                    /* TODO : label */
                    labelKey="Verificación del número"
                    bold
                    size="1"
                    align="center"
                    color="heading"
                    className="mb-3"
                />

                <Text
                    /* TODO : label [ se debe ingresar el numero de telefono entre <b></b> en el i18n ] */
                    labelKey="Escribe el número de verificación que te enviamos al número +507 ****3974"
                    className="px-3 mb-5"
                    color="text-color"
                    bold
                    align="center"
                    size="4"
                />

                <Formik
                    onSubmit={() => {
                        dispatch(push("/settingsWally"));
                    }}>
                    <Form className="full-height">
                        <Box display="flex" column fullHeight>
                            <Box
                                display="flex"
                                column
                                alignX="center"
                                alignY="center"
                                background="white"
                                className="pt-6 pb-5 px-5 mb-12"
                                borderRadius="default">
                                <Field
                                    name="pinCode1"
                                    component={PinInput}
                                    placeholder="*"
                                    maxLength={1}
                                    labelNoMarginTop
                                    showLabel={false}
                                    type="number"
                                    noMarginBottom
                                />
                                <Text
                                    /* TODO : label */
                                    labelKey="En caso de no recibir tu código en los próximos minutos, haz clic en"
                                    size={6}
                                    color="heading"
                                    className="mt-3 mb-2 px-4"
                                    align="center"
                                />
                                <Button
                                    /* TODO : label */
                                    label="No recibi mi codigo"
                                    bsStyle="link-wally"
                                    small
                                />
                            </Box>

                            <Box display="flex" column className="mt-auto">
                                <Button
                                    label="global.confirm"
                                    bsStyle="primary"
                                    block
                                    type="submit"
                                    href="/settingsWally"
                                />
                                <Button label="wally.select.button.cancel" bsStyle="outline" block />
                            </Box>
                        </Box>
                    </Form>
                </Formik>
            </MainContainer>
        </PageLoading>
    );
};

changePhoneNumberStep2.propTypes = { dispatch: func.isRequired, history: shape({}).isRequired, fetching: bool };

changePhoneNumberStep2.defaultProps = { fetching: false };

export default withRouter(connect()(resizableRoute(changePhoneNumberStep2)));
