import classNames from "classnames";
import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { arrayOf, bool, node, oneOf, oneOfType, string } from "prop-types";
import React from "react";

const Sticker = ({
    addPaddingY,
    bold,
    borderRadius,
    children,
    className,
    color,
    fixedWidth,
    labelKey,
    status,
    textSize,
    uppercase,
}) => (
    <Box
        display="flex"
        alignY="center"
        alignX="center"
        background={status}
        borderRadius={borderRadius}
        className={classNames("px-3 py-1", className, { "sticker-fixed-width": fixedWidth, "py-2": addPaddingY })}>
        {children ? (
            <Text uppercase={uppercase} color={color} size={textSize} bold={bold}>
                {children}
            </Text>
        ) : (
            <Text labelKey={labelKey} uppercase={uppercase} color={color} size={textSize} bold={bold} />
        )}
    </Box>
);

Sticker.propTypes = {
    addPaddingY: bool,
    bold: bool,
    borderRadius: oneOf(["min", "xs", "sm", "md", "default", "lg", "xl", "xxl", "extreme", "circle"]),
    children: oneOfType([arrayOf(node), node]),
    className: string,
    color: string,
    fixedWidth: bool,
    labelKey: string,
    status: oneOf(["success", "warning", "warning-border-color", "error", "error-border-color", "background-divider"]),
    textSize: string,
    uppercase: bool,
};

Sticker.defaultProps = {
    addPaddingY: false,
    bold: false,
    borderRadius: "sm",
    children: null,
    className: null,
    color: "text-boton",
    fixedWidth: false,
    labelKey: null,
    status: "success",
    textSize: "small",
    uppercase: true,
};

export default resizableRoute(Sticker);
