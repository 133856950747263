import Box from "pages/_components/Box";
import EditableLabel from "pages/_components/fields/EditableLabel";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, func, int, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { actions as productsActions } from "reducers/products";
import * as i18n from "util/i18n";

class DetailHeaderTitleForProducts extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        product: shape({
            account: string,
            closingDate: string,
            constitutedDate: string, // loans
            creditLimitCurrency: string.isRequired,
            expirationDate: string,
            extraInfo: string,
            extraInfoMarked: string,
            favorite: bool.isRequired,
            holder: string,
            idEnvironment: int,
            idProduct: string,
            label: string,
            lastPaymentDate: string,
            nextDueDate: string, // loans
            number: string.isRequired,
            ownerName: string,
            productAlias: string,
            productType: string.isRequired,
            shortLabel: string,
            percentageUsed: number,
        }).isRequired,
        className: string,
        selectProduct: shape({}),
        titleImage: string,
        renderProductTitle: bool,
        productStatus: string,
    };

    static defaultProps = {
        className: null,
        selectProduct: undefined,
        titleImage: null,
        renderProductTitle: true,
        productStatus: null,
    };

    constructor(props) {
        super(props);
        this.titleRef = React.createRef();
        this.state = {
            // eslint-disable-next-line react/no-unused-state
            fetching: false,
        };
    }

    componentDidMount() {
        if (this.titleRef.current) {
            this.titleRef.current.focus();
        }
    }

    componentDidUpdate() {
        if (this.titleRef.current) {
            this.titleRef.current.focus();
        }
    }

    saveAlias = (alias) => {
        const { dispatch, product } = this.props;
        dispatch(productsActions.changeProductAlias(alias, product.idProduct));
    };

    handleFavoriteClick = () => {
        const { dispatch, product } = this.props;
        this.setState({
            // eslint-disable-next-line react/no-unused-state
            fetching: true,
        });

        dispatch(
            productsActions.toggleFavorite(product.idProduct, product.productType, !product.favorite, this.onFinish),
        );
    };

    onFinish = () => {
        this.setState({
            // eslint-disable-next-line react/no-unused-state
            fetching: false,
        });
    };

    renderProductType = (productType, product) => {
        let productTypeText = "";

        if (productType === "TC") {
            productTypeText = "creditCard.label";
        } else if (productType === "PA" || productType === "PI") {
            productTypeText = product.subType;
        } else if (productType === "PF") {
            productTypeText = "deposit.detail.head.certification.number";
        } else {
            productTypeText = "";
        }
        return productTypeText;
    };

    renderAlias = (product, productType, productAlias, n) => {
        let alias = "";

        if (productAlias) {
            return productAlias;
        }

        if (productType === "CA" || productType === "CC") {
            alias = productAlias;
        } else if (productType === "TC") {
            alias = productAlias;
        } else if (productType === "PA" || productType === "PI") {
            alias = product.alias;
        } else if (productType === "PF" && !productAlias) {
            alias = `${i18n.get("deposit.detail.head.description")} ${n}`;
        }
        return alias;
    };

    getDescriptionProduct = () => {
        const { selectProduct, product } = this.props;
        if (
            !selectProduct ||
            !selectProduct.idProduct ||
            !product ||
            !product.idProduct ||
            selectProduct.idProduct !== product.idProduct
        ) {
            return "";
        }

        return selectProduct.description || "";
    };

    render() {
        const {
            product,
            isDesktop,
            className,
            selectProduct,
            titleImage,
            productStatus,
            renderProductTitle,
        } = this.props;
        const { productAlias, productType, label, number: n } = product;
        const isCreditCard = productType === "TC";
        const isAccount = productType === "CC";
        const alias = this.renderAlias(product, productType, productAlias, n);
        const avaibleCurrency = (item) => item?.availableBalanceCurrency || item?.currency;
        const currency = avaibleCurrency(product);

        return (
            <Box display="flex" column alignX={isDesktop ? "flex-start" : "center"} className={className}>
                <Box className="title-edit" display="flex" alignX="flex-start" column fullWidth>
                    <Box display="flex" alignY="center" alignX={isDesktop ? "flex-start" : "center"} fullWidth>
                        <div className="visually-hidden" ref={this.titleRef}>
                            <span>{`${productType} ${currency}, ${productAlias || n}`}</span>
                        </div>
                        {productType === "TC" ? (
                            <Box
                                display="flex"
                                alignY="center"
                                {...(!isDesktop && { alignX: "center", align: "center" })}
                                fullWidth>
                                <Text
                                    className="px-4 px-md-0"
                                    size={1}
                                    align={isDesktop ? "left" : "center"}
                                    color={isDesktop ? "heading" : "inverse"}
                                    bold>
                                    {isCreditCard ? `${product.cardStyle}` : n}
                                </Text>
                                {isDesktop && (
                                    <Text
                                        className={`ml-3 ${
                                            productStatus === "creditCard.status.additional.false"
                                                ? "product-status-text"
                                                : "product-status-can-text"
                                        }`}
                                        labelKey={productStatus}
                                    />
                                )}
                            </Box>
                        ) : (
                            <>
                                {isDesktop && titleImage && (
                                    <EditableLabel
                                        isDesktop={isDesktop}
                                        onSave={this.saveAlias}
                                        value={alias || (isCreditCard ? label : n)}>
                                        <Text
                                            component="h1"
                                            align="center"
                                            className="data-name product-title"
                                            ellipsis>
                                            {alias || (isCreditCard ? label : n)}
                                        </Text>
                                    </EditableLabel>
                                )}
                                {!isDesktop && titleImage && (
                                    <>
                                        <Text size={1} color="inverse" bold align="center">
                                            {alias || (isCreditCard ? label : n)}
                                        </Text>
                                        <Image
                                            src={titleImage}
                                            height="5"
                                            wrapperWidth="8"
                                            wrapperHeight="5"
                                            className="ml-3"
                                        />
                                    </>
                                )}
                                {isDesktop && !titleImage && (
                                    <>
                                        <EditableLabel
                                            hideLabel
                                            isDesktop={isDesktop}
                                            onSave={this.saveAlias}
                                            value={alias || (isCreditCard ? label : n)}>
                                            <Text component="h1" align="center" className="data-name product-title">
                                                {alias || (isCreditCard ? label : n)}
                                            </Text>
                                        </EditableLabel>
                                        <Text className="ml-3 product-status-text" labelKey={productStatus} />
                                    </>
                                )}
                                {!isDesktop && !titleImage && (
                                    <Text component="h1" align="center">
                                        {alias || (isCreditCard ? label : n)}
                                    </Text>
                                )}
                            </>
                        )}
                    </Box>
                    {isDesktop && isAccount && (
                        <Text
                            align="left"
                            labelKey="forms.servicePayment.add.alias.label"
                            number={number}
                            color="text-disabled-color"
                        />
                    )}
                    {productType === "TC" ? (
                        <Box display="flex" alignY="center" alignX={isDesktop ? "flex-start" : "center"} fullWidth>
                            <Text
                                // eslint-disable-next-line no-nested-ternary
                                size={isDesktop && productType === "TC" ? "6" : productType === "TC" ? "5" : "6"}
                                color={isDesktop ? "heading" : "inverse"}
                                {...(!isDesktop && { alignX: "center", wordSpacing: "sm", align: "center" })}
                                uppercase
                                ellipsis>
                                {this.getDescriptionProduct()}
                            </Text>
                        </Box>
                    ) : (
                        <Box display="flex" alignY="center" alignX={isDesktop ? "flex-start" : "center"} fullWidth>
                            <Text
                                // eslint-disable-next-line no-nested-ternary
                                size={isDesktop && productType === "TC" ? "6" : productType === "TC" ? "5" : "6"}
                                color={isDesktop ? "heading" : "inverse"}
                                uppercase
                                {...(!isDesktop && { align: "center" })}>
                                {renderProductTitle && (
                                    <>
                                        {productType === "PA"
                                            ? this.renderProductType(productType, product)
                                            : this.renderProductType(productType, product)
                                            ? i18n.get(this.renderProductType(productType, product))
                                            : ""}{" "}
                                        {isCreditCard ? `**** ${product.number.slice(-4)}` : n}
                                    </>
                                )}
                            </Text>
                        </Box>
                    )}
                </Box>
            </Box>
        );
    }
}

export default DetailHeaderTitleForProducts;
