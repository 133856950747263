import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";

import { selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import withExchangeToken from "pages/_components/withExchangeToken";
import { routerActions } from "react-router-redux";

import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import Step3Content from "pages/recoveryPassword/_components/Step3Content";
import MainContainer from "pages/_components/MainContainer";
import LoginWrapper from "pages/login/_components/LoginWrapper";
import Head from "pages/_components/Head";
import classNames from "classnames";
import Box from "pages/_components/Box";

class RecoveryPassStep3 extends Component {
    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    render() {
        const { isMobile } = this.props;

        return (
            <LoginWrapper>
                  <Box
                    display="flex"
                    {...(isMobile && { alignX: "flex-end", component: "main" })}
                    column
                    zIndex="9"
                    fullHeight
                    className={classNames("login-background", { "mt-auto": isMobile })}>
                {!isMobile && <BoxErrorNotification isMobile={isMobile} scopeToShow="recoveryPassword" />}
                {!isMobile && (
                    <Head isMobile={isMobile} onBack={this.onHeaderClose} title="recoveryPassword.step3.header" />
                )}

            
                    <Step3Content {...this.props} />
               
                </Box>
            </LoginWrapper>
        );
    }
}

RecoveryPassStep3.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
};

const mapStateToProps = (state) => ({
    exchangeToken: recoveryPasswordSelectors.getExchangeToken(state),
});

export default connect(mapStateToProps)(withExchangeToken(RecoveryPassStep3));
