import classNames from "classnames";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import SelectField from "pages/_components/fields/Select";
import withFocus from "pages/_components/withFocus";
import Select from "pages/forms/_components/_fields/Select";
import { arrayOf, bool, func, number, oneOfType, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import { compose } from "redux";
import { getInteger } from "util/config";
import { getNestedObject } from "util/general";
import { countDecimalPlaces, numberFormat, toNumber } from "util/number";
import Box from "../Box";

class PeriodAmountField extends Component {
    static handleBlur = () => {
        const { field, form, toggleIsFocused } = this.props;

        toggleIsFocused();
        form.setFieldTouched(field.name);
    };

    static propTypes = {
        clearable: bool,
        data: shape({
            currencies: arrayOf(
                shape({
                    label: string,
                    value: string,
                }),
            ),
        }).isRequired,
        disableSelect: bool,
        field: shape({
            name: string,
            value: shape({
                amount: oneOfType([number, string]),
                currency: string,
                period: string,
            }),
        }).isRequired,
        form: shape({
            errors: shape({}),
            touched: shape({}),
        }).isRequired,
        hideCurrency: bool,
        hideLabel: bool,
        idForm: string.isRequired,
        isFocused: bool,
        lang: string,
        maximumDecimals: number,
        minimumDecimals: number,
        nestedErrorsObject: bool,
        toggleIsFocused: func,
        size: string,
    };

    static defaultProps = {
        clearable: false,
        disableSelect: false,
        hideCurrency: false,
        hideLabel: false,
        isFocused: false,
        lang: "es",
        maximumDecimals: getInteger("defaultDecimal.maximum"),
        minimumDecimals: getInteger("defaultDecimal.minimum"),
        nestedErrorsObject: false,
        toggleIsFocused: null,
        size: "",
    };

    handleCurrencyChange = ({ value }) => {
        const { field, form } = this.props;

        form.setFieldValue(`${field.name}.currency`, value);
    };

    handleInputChange = ({ target }) => {
        const { field, form, lang } = this.props;
        const { decimalSeparator } = numberFormat(lang);

        form.setFieldValue(`${field.name}.amount`, toNumber(target.value, decimalSeparator));
    };

    handlePeriodChange = ({ value }) => {
        const { field, form } = this.props;

        form.setFieldValue(`${field.name}.period`, value);
    };

    render() {
        const {
            clearable,
            data,
            disableSelect,
            field,
            form: { touched, errors },
            hideLabel,
            idForm,
            isFocused,
            lang,
            maximumDecimals,
            minimumDecimals,
            nestedErrorsObject,
            toggleIsFocused,
            hideCurrency,
            size,
        } = this.props;

        let currencyList;
        if (!hideCurrency) {
            const { currencies } = data;

            currencyList = currencies;
        }
        const hasError = nestedErrorsObject
            ? getNestedObject(touched, field.name.split(".")) && getNestedObject(errors, field.name.split("."))
            : touched[field.name] && errors[field.name];
        const { decimalSeparator, thousandSeparator } = numberFormat(lang);
        const decimalPlaces = this.amountRef ? countDecimalPlaces(this.amountRef.value, decimalSeparator) : 0;
        const decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);

        return (
            <Fragment>
                <Box
                    display="flex"
                    gapX="3"
                    className={classNames(
                        "pr-9",
                        "form-group-wrapper",
                        "form-group-currency-period",
                        "form-group-wrapper-inline",
                        {
                            "has-error": hasError,
                            "has-focus": isFocused,
                            "form-group--small": size === "small",
                        },
                    )}
                    style={{ alignItems: "flex-end" }}>
                    <div className="form-group form-group--composite">
                        <FieldLabel labelKey={`${idForm}.${field.name}.periodLabel`} />
                        <div className="input-group">
                            <SelectField
                                className="form-group select-small"
                                hideLabel
                                name={`${field.name}.period`}
                                onBlur={this.handleBlur}
                                onChange={this.handlePeriodChange}
                                options={data.frequencyList}
                                value={field.value && field.value.period}
                                disabled={disableSelect}
                            />
                        </div>
                    </div>
                    <div
                        className="form-group form-group--composite"
                        id="topAmount"
                        onBlur={this.handleBlur}
                        onFocus={toggleIsFocused}>
                        {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
                        <div className="input-group">
                            {!hideCurrency && currencyList.length === 1 ? (
                                <span className="currency">{currencyList[0].label}</span>
                            ) : (
                                !hideCurrency && (
                                    <Select
                                        clearable={clearable}
                                        containerClassName="currency-selector"
                                        disabled={disableSelect}
                                        name={`${field.name}.period`}
                                        onChange={this.handleCurrencyChange}
                                        options={currencyList.map(({ id, label }) => ({ value: id, label }))}
                                        searchable={false}
                                        value={field.value && field.value.currency}
                                    />
                                )
                            )}
                            <NumberFormat
                                allowNegative={false}
                                className="form-control"
                                decimalScale={decimalScale}
                                decimalSeparator={decimalSeparator}
                                maxLength="15"
                                onChange={this.handleInputChange}
                                thousandSeparator={thousandSeparator}
                                type="text"
                                value={field.value && field.value.amount}
                            />
                        </div>
                    </div>

                </Box>
                {hasError && <FieldError error={errors[field.name].amount} />}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: i18nSelectors.getLang(state),
    maximumDecimals: getInteger("defaultDecimal.maximum"),
    minimumDecimals: getInteger("defaultDecimal.minimum"),
});

export default compose(connect(mapStateToProps), withFocus)(PeriodAmountField);
