/* eslint-disable jsx-a11y/label-has-associated-control */
import { isEmpty } from "lodash";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import { bool, func, number } from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";
import { compose } from "redux";
import * as deviceUtils from "util/device";
import * as i18n from "util/i18n";

const BiometricModal = (props) => {
    const { dispatch, fingerPrinterModalShow = false, updateShowModal } = props;
    const [showModal, setShowModal] = useState(false);
    const [showAgain, setShowAgain] = useState(true);
    useEffect(() => {
        dispatch(fingerprintActions.fingerPrintConfigurationModalValidate());
    }, []);

    useEffect(() => {
        dispatch(fingerprintActions.fingerprintConfigurationPre());
        dispatch(fingerprintActions.fingerprintAvailability());
    }, []);

    useEffect(() => {
        const shouldShowModal = fingerPrinterModalShow;
        setShowModal(shouldShowModal);
    }, [updateShowModal, fingerPrinterModalShow]);

    const handleCheckboxChange = (event) => {
        setShowAgain(!event.target.checked);
    };
    const handleClose = () => {
        setShowModal(false);
        dispatch(fingerprintActions.fingerprintHideWidget(showAgain));
    };

    const isDeviceIdInSession = () => {
        const { fingerprintConfiguredUserDevices } = props;
        if (deviceUtils.isMobileNativeFunc() && fingerprintConfiguredUserDevices) {
            const device = fingerprintConfiguredUserDevices.filter(
                (item) => item.deviceId.search(deviceUtils.getDeviceId()) !== -1,
            );
            return !isEmpty(device);
        }
        return false;
    };

    const isBiometricAvailable = () => {
        const { isDeviceWithFingerprint, availability } = props;
        return (
            deviceUtils.isMobileNativeFunc() &&
            !isDeviceWithFingerprint &&
            availability &&
            availability.isHardwareDetected &&
            !isDeviceIdInSession()
        );
    };

    const configureNow = () => {
        dispatch(fingerprintActions.fingerprintVerification());
    };

    const enrollFingerprintOnDevice = () => {
        dispatch(fingerprintActions.enrollFingerprintOnDevice());
    };
    
    const activateFingerprintDevice = () => {
        const { availability } = props;
        if (deviceUtils.isMobileNativeFunc()) {
            if (isBiometricAvailable()) {
                if (availability.hasEnrolledFingerprints && availability.isAvailable) {
                    configureNow();
                }else{
                    enrollFingerprintOnDevice();
                }
            }
            return;
        }
    };

    const handleConfigure = () => {
        setShowModal(false);
        // dispatch(push("/settings/fingerprintConfiguration"));
        activateFingerprintDevice();
    };

    return (
        <TextAndButtonsModal
            modalShow={showModal}
            acceptFunction={handleConfigure}
            cancelFunction={handleClose}
            confirmLabel="widgets.biometricIdentification.configureNow"
            continueLabel="global.cancel"
            cancelLabel="global.cancel"
            headingText={i18n.get("widgets.biometricIdentification.title")}
            text={i18n.get("widgets.biometricIdentification.text")}
        />
    );
};

BiometricModal.propTypes = {
    dispatch: func.isRequired,
    updateShowModal: number,
    fingerPrinterModalShow: bool,
};

BiometricModal.defaultProps = {
    updateShowModal: 0,
    fingerPrinterModalShow: false,
};
const mapStateToProps = (state) => ({
    fingerPrinterModalShow: fingerprintSelectors.fingerPrinterModalShow(state) || false,
    updateShowModal: fingerprintSelectors.updateShowModal(state),
    availability: fingerprintSelectors.getAvailability(state),
    isDeviceWithFingerprint: fingerprintSelectors.getIsDeviceWithFingerprint(state),
    fingerprintConfiguredUserDevices: fingerprintSelectors.getFingerprintConfiguredUserDevices(state),
});

export default compose(connect(mapStateToProps), resizableRoute)(BiometricModal);
