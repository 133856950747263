import classNames from "classnames";
import { bool, func, oneOfType, shape } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";

import Container from "pages/_components/Container";
import ListItem from "pages/deposits/ListItem";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class DepositsList extends Component {
    static propTypes = {
        deposits: oneOfType([shape({})]).isRequired,
        showProductIcon: bool,
        setFavoriteDeposit: func,
        completeFavorites: bool,
        dispatch: func.isRequired,
    };

    static defaultProps = {
        showProductIcon: true,
        setFavoriteDeposit: () => {},
        completeFavorites: false,
    };

    renderList = () => {
        const { deposits, showProductIcon, setFavoriteDeposit, completeFavorites, ...props } = this.props;
        if (!deposits.length) {
            return null;
        }

        return (
            <div role="menu" className={classNames("table-body")}>
                {deposits.map((dep) => (
                    <ListItem
                        {...props}
                        {...dep}
                        key={dep.idProduct}
                        showProductIcon={showProductIcon}
                        setFavoriteDeposit={setFavoriteDeposit}
                        showStar
                        completeFavorites={completeFavorites}
                        hideContextsOptions
                    />
                ))}
            </div>
        );
    };

    render() {
        return (
            <Container className="container--layout flex-grow-1">
                <Col className="col-12" sm={12}>
                    <div className="table table--products">{this.renderList()}</div>
                </Col>
            </Container>
        );
    }
}

// export default DepositsList;
export default withRouter(connect()(DepositsList));

