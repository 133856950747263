import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import PhoneInput from "pages/_components/fields/PhoneInput";
import TextField from "pages/_components/fields/TextField";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
    actions as updateUserDataActions,
    selectors as updateUserDataSelectors,
} from "reducers/updateUserData/updateUserData.reducer";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { useLoadingGlobalProvider } from "providers/LoadingGlobalProvider";

const FORM_ID = "settings.personalDataUpdateStep4";

const PersonalDataUpdateStep4 = ({
    isSubmitting,
    isFetching,
    isDesktop,
    dispatch,
    exchangeToken,
    currentUserData,
    isDisabledStep4,
    setFieldValue,
}) => {
    const preferredCountries = ["pa", "ve"];
    const areaCodes = { ve: ["412", "414", "416", "424", "426"] };

    // Section 1
    const [mobilePhoneValue, setMobilePhoneValue] = useState();
    const [homePhoneValue, setHomePhoneValue] = useState();
    const [otherPhoneValue, setOtherPhoneValue] = useState();
    const [email, setEmail] = useState();
    const [confirmEmail, setConfirmEmail] = useState();

    const { setLoading } = useLoadingGlobalProvider();

    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);

    useEffect(() => {
        dispatch(updateUserDataActions.preFormStep4(exchangeToken));
    }, [dispatch]);

    useEffect(() => {
        if (!isFetching && currentUserData && currentUserData.PersonData) {
            const { Phone, Email } = currentUserData.PersonData.Contact?.Locator;
            const mobilePhoneObj = Phone.find(({ PhoneType }) => PhoneType === "Mobile") || {};
            const homePhoneObj = Phone.find(({ PhoneType }) => PhoneType === "Home") || {};
            const otherPhoneObj = Phone.find(({ PhoneType }) => PhoneType === "Other") || {};

            if (mobilePhoneObj) {
                let mpAreaCode = "";
                let mpNum = "";

                if (mobilePhoneObj?.AreaCode) {
                    mpAreaCode = mobilePhoneObj.AreaCode;
                }

                if (mobilePhoneObj?.PhoneNum) {
                    mpNum = mobilePhoneObj.PhoneNum;
                }

                let mobilePhoneAux = `+${mpAreaCode}${mpNum}`;
                if (mobilePhoneAux === "+") {
                    mobilePhoneAux = "";
                }
                setMobilePhoneValue(mobilePhoneAux);
                setFieldValue("mobilePhone", mobilePhoneAux);
            }

            if (homePhoneObj) {
                let hpAreaCode = "";
                let hpNum = "";

                if (homePhoneObj.AreaCode) {
                    hpAreaCode = homePhoneObj.AreaCode;
                }

                if (homePhoneObj.PhoneNum) {
                    hpNum = homePhoneObj.PhoneNum;
                }

                let homePhoneAux = `+${hpAreaCode}${hpNum}`;
                if (homePhoneAux === "+") {
                    homePhoneAux = null;
                }
                setHomePhoneValue(homePhoneAux);
                setFieldValue("homePhone", homePhoneAux);
            }

            if (otherPhoneObj) {
                let opAreaCode = "";
                let opNum = "";

                if (otherPhoneObj.AreaCode) {
                    opAreaCode = otherPhoneObj.AreaCode;
                }

                if (otherPhoneObj.PhoneNum) {
                    opNum = otherPhoneObj.PhoneNum;
                }

                let otherPhoneAux = `+${opAreaCode}${opNum}`;
                if (otherPhoneAux === "+") {
                    otherPhoneAux = null;
                }
                setOtherPhoneValue(otherPhoneAux);
                setFieldValue("otherPhone", otherPhoneAux);
            }

            const emailAux = Email ? Email[0]?.Value : "";
            setEmail(emailAux);
            setFieldValue("email", emailAux);

            setConfirmEmail(emailAux);
            setFieldValue("checkEmail", emailAux);
        }
    }, [currentUserData]);

    const enableStep4 = () => {
        dispatch(updateUserDataActions.enableStep4(!isDisabledStep4));
    };

    const handleCancel = () => {
        dispatch(updateUserDataActions.enableStep4(false));
        dispatch(push("/desktop"));
    };

    return (
        <>
            <Notification scopeToShow="personalDataUpdate" />
            <Form className="mx-n-5">
                <Box
                    display="flex"
                    column
                    fullWidth
                    background="component-background"
                    className="px-5 px-md-12 pt-7 pb-11 mb-5 mb-md-8"
                    borderRadius="default">
                    <Box className="mb-7 mb-md-11">
                        <Field
                            component={SwitchField}
                            idForm={FORM_ID}
                            name="modifyDataControl"
                            onChange={enableStep4}
                        />
                    </Box>

                    <Row>
                        <Col xs={12}>
                            <Field
                                component={PhoneInput}
                                idForm={FORM_ID}
                                idField={`${FORM_ID}.mobilePhone`}
                                name="mobilePhone"
                                isDesktop={isDesktop}
                                className="remove-flex"
                                mobilePhone={mobilePhoneValue}
                                preferredCountries={preferredCountries}
                                areaCodes={areaCodes}
                                enableAreaCodes={preferredCountries}
                                labelNoMarginTop
                                disabled={isDisabledStep4}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Field
                                component={PhoneInput}
                                idForm={FORM_ID}
                                idField={`${FORM_ID}.homePhone`}
                                name="homePhone"
                                isDesktop={isDesktop}
                                className="remove-flex"
                                mobilePhone={homePhoneValue}
                                preferredCountries={preferredCountries}
                                areaCodes={areaCodes}
                                enableAreaCodes={preferredCountries}
                                optional={i18n.get("global.optional")}
                                labelNoMarginTop
                                disabled={isDisabledStep4}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Field
                                component={PhoneInput}
                                idForm={FORM_ID}
                                idField={`${FORM_ID}.otherPhone`}
                                name="otherPhone"
                                isDesktop={isDesktop}
                                className="remove-flex"
                                mobilePhone={otherPhoneValue}
                                preferredCountries={preferredCountries}
                                areaCodes={areaCodes}
                                enableAreaCodes={preferredCountries}
                                optional={i18n.get("global.optional")}
                                labelNoMarginTop
                                disabled={isDisabledStep4}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Field
                                idForm={FORM_ID}
                                name="email"
                                type="email"
                                labelNoMarginTop
                                component={TextField}
                                initValue={email}
                                autoFocus={isDesktop}
                                className="mb-md-11 remove-flex"
                                disabled={isDisabledStep4}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Field
                                idForm={FORM_ID}
                                name="checkEmail"
                                type="email"
                                labelNoMarginTop
                                component={TextField}
                                initValue={confirmEmail}
                                autoFocus={isDesktop}
                                className="mb-md-11 remove-flex"
                                disabled={isDisabledStep4}
                            />
                        </Col>
                    </Row>
                </Box>

                <Row className="px-5 px-md-0" {...(!isDesktop && { gapY: "3" })}>
                    <Col xs={12} md={4} mdOffset={2}>
                        <Button label="global.cancel" bsStyle="outline" onClick={handleCancel} block />
                    </Col>
                    <Col xs={12} md={4} {...(!isDesktop && { className: "grid-reversed" })}>
                        <Button
                            label="global.continue"
                            loading={isSubmitting || isFetching}
                            type="submit"
                            bsStyle="primary"
                            block
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

PersonalDataUpdateStep4.propTypes = {
    dispatch: func.isRequired,
    isFetching: bool,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    exchangeToken: string.isRequired,
    currentUserData: shape({}),
    isDisabledStep4: bool,
    setFieldValue: func.isRequired,
};

PersonalDataUpdateStep4.defaultProps = {
    isFetching: false,
    currentUserData: null,
    isDisabledStep4: true,
};

const mapStateToProps = (state) => ({
    isFetching: updateUserDataSelectors.isFetching(state),
    exchangeToken: updateUserDataSelectors.getExchangeToken(state),
    currentUserData: updateUserDataSelectors.getCurrentUserData(state),
    isDisabledStep4: updateUserDataSelectors.isDisabledStep4(state),
});

export default compose(
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            mobilePhone: "",
            homePhone: "",
            otherPhone: "",
            email: "",
            checkEmail: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                mobilePhone: Yup.string().required(i18n.get(`${FORM_ID}.mobilePhone.required`)),
                // homePhone: Yup.string().required(i18n.get(`${FORM_ID}.homePhone.required`)),
                // otherPhone: Yup.string().required(i18n.get(`${FORM_ID}.otherPhone.required`)),
                email: Yup.string().required(i18n.get(`${FORM_ID}.email.required`)),
                checkEmail: Yup.string().required(i18n.get(`${FORM_ID}.checkEmail.required`)),
            }),
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(updateUserDataActions.saveForm(values, 4, formikBag));
        },
    }),
)(PersonalDataUpdateStep4);
