import React, { Component } from "react";
import { shape, string, bool, func } from "prop-types";
import { compose } from "redux";

import formField from "pages/forms/_components/_fields/_commons/formField";
import withFocus from "pages/_components/withFocus";
import Select from "pages/forms/_components/_fields/Select";
import FieldLabel from "pages/_components/fields/FieldLabel";

class Document extends Component {
    static propTypes = {
        defaultCountry: string.isRequired,
        defaultDocumentType: string.isRequired,
        data: shape({ countries: shape([]), documentCountryMap: shape({}) }).isRequired,
        editing: bool.isRequired,
        value: shape({
            document: string,
            documentCountry: string,
            documentType: string,
        }).isRequired,
        focus: bool.isRequired,
        setValue: func.isRequired,
        placeholder: string,
        onBlur: func,
        toggleIsFocused: func,
        readOnly: bool.isRequired,
        idField: string.isRequired,
    };

    static defaultProps = {
        placeholder: "",
        onBlur: null,
        toggleIsFocused: null,
    };

    constructor(props) {
        super(props);
        this.docNumberRef = null;

        const { editing, value, focus } = this.props;
        if (editing && !value) {
            const { defaultCountry, defaultDocumentType, setValue } = this.props;

            setValue({
                documentCountry: defaultCountry,
                documentType: defaultDocumentType,
                document: "",
            });
        }

        // al primer campo del formulario por lo general se le pasa focus en true
        if (this.docNumberRef && focus) {
            this.docNumberRef.focus();
        }
    }

    handleCountryChange = ({ id: documentCountry }) => {
        const {
            setValue,
            value: previousValue,
            data: { documentCountryMap },
            defaultDocumentType,
        } = this.props;

        if (documentCountry === previousValue.documentCountry) {
            return;
        }

        const existsType = (country, type) => documentCountryMap[country].some(({ id }) => id === type);

        let documentType;

        if (existsType(documentCountry, previousValue.documentType)) {
            documentType = previousValue.documentType;
        } else if (existsType(documentCountry, defaultDocumentType)) {
            documentType = defaultDocumentType;
        } else {
            documentType = "";
        }

        setValue({
            documentType,
            documentCountry,
            document: documentType === previousValue.documentType ? previousValue.document : "",
        });
    };

    handleTypeChange = ({ id }) => {
        const { setValue, value: previousValue } = this.props;

        setValue({
            ...previousValue,
            documentType: id,
        });
    };

    handleDocumentChange = ({ target: { value } }) => {
        const { setValue, value: previousValue } = this.props;

        setValue({
            ...previousValue,
            document: value,
        });
    };

    render() {
        const {
            editing,
            value,
            placeholder,
            onBlur,
            toggleIsFocused,
            readOnly,
            defaultCountry,
            defaultDocumentType,
            data: { countries, documentCountryMap },
            idField,
        } = this.props;

        const selectedCountry = value && value.documentCountry ? value.documentCountry : defaultCountry;
        const selectedType = value && value.documentType ? value.documentType : defaultDocumentType;
        const selectedNumber = value && value.document ? value.document : "";

        const documentTypes = documentCountryMap[selectedCountry];

        if (editing) {
            return (
                <div className="input-group" onFocus={toggleIsFocused} onBlur={toggleIsFocused}>
                    {countries.length > 1 && (
                        <>
                            <FieldLabel
                                hideLabel
                                labelKey="forms.document.country.label"
                                mode="edit"
                                idField={idField}
                            />
                            <Select
                                id={idField}
                                className="currency-selector slideFromBottom flex-container document"
                                name="country"
                                searchable={false}
                                onChange={this.handleCountryChange}
                                value={selectedCountry}
                                valueKey="id"
                                labelKey="name"
                                options={countries}
                                clearable={false}
                            />
                        </>
                    )}
                    <FieldLabel
                        hideLabel
                        labelKey="forms.document.type.label"
                        mode="edit"
                        idField={countries.length > 1 ? `${idField}.documentType` : idField}
                    />
                    <Select
                        id={countries.length > 1 ? `${idField}.documentType` : idField}
                        className="currency-selector slideFromBottom flex-container document"
                        name="documentType"
                        searchable={false}
                        onChange={this.handleTypeChange}
                        value={selectedType}
                        valueKey="id"
                        labelKey="name"
                        options={documentTypes}
                        clearable={false}
                    />
                    <FieldLabel
                        hideLabel
                        labelKey="forms.document.number.label"
                        mode="edit"
                        idField={`${idField}.documentNumber`}
                    />
                    <input
                        id={`${idField}.documentNumber`}
                        className="form-control"
                        name="documentNumber"
                        type="text"
                        onChange={this.handleDocumentChange}
                        onBlur={onBlur}
                        ref={(ref) => {
                            this.docNumberRef = ref;
                        }}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        value={selectedNumber}
                        maxLength={50}
                        autoComplete="off"
                    />
                </div>
            );
        }
        const { name: documentCountryLabel } = countries.find(({ id }) => id === selectedCountry) || {};
        const { name: documentTypeLabel } = documentTypes.find(({ id }) => id === selectedType) || {};

        return (
            <span>
                {documentTypeLabel} {selectedCountry !== defaultCountry && documentCountryLabel} {selectedNumber}
            </span>
        );
    }
}

export default compose(
    withFocus,
    formField({
        formClass: "form-group--composite",
        isEmptyValue: (value) => {
            const { documentCountry, documentType, document } = value || {};
            return !documentCountry || !documentType || !document;
        },
        renderLegend: true,
    }),
)(Document);
