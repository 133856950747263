import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import Text from "pages/_components/Text";
import { bool, string, func, shape } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as assistantSelectors } from "reducers/assistant";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { actions as generalCondActions } from "reducers/generalConditions";
import { GENERAL_CONDITIONS_CATEGORY_DEFAULT } from "constants.js";
import { selectors as sessionSelectors } from "reducers/session";

const FORM_ID = "login.step4";

class Step4Content extends Component {
    render() {
        const { isSubmitting, className, dispatch, values, isDesktop } = this.props;

        return (
            <Form className={`${className} d-flex mt-3 justify-content-between align-items-center flex-column`}>
                <Box className="mb-5" display="flex" alignX="center" fullWidth>
                    <Text color="heading" size={isDesktop ? "2" : "3"} bold labelKey="global.termAndConditions" />
                </Box>
                <Box
                    className="terms-and-conditions mt-auto"
                    display="flex"
                    column
                    alignX="center"
                    alignY="center"
                    fullWidth>
                    <Button
                        bsStyle="link"
                        onClick={() => {
                            dispatch(
                                generalCondActions.downloadGeneralConditions({
                                    category: GENERAL_CONDITIONS_CATEGORY_DEFAULT,
                                }),
                            );
                        }}
                        className="px-0"
                        image="images/icons/download.svg"
                        secondary
                        label="global.download.termandconditions"
                        block
                    />
                </Box>
                <Box className="mb-9 mb-md-10 mt-auto" display="flex" alignX="center">
                    <Field name="acceptConditions" idForm={FORM_ID} component={Checkbox} formGroup />
                </Box>
                <Box fullWidth>
                    <Button
                        disabled={!values.acceptConditions}
                        type="submit"
                        loading={isSubmitting}
                        label="login.comeIn"
                        bsStyle="primary"
                        block
                    />
                </Box>
            </Form>
        );
    }
}

Step4Content.propTypes = {
    isSubmitting: bool,
    className: string,
    dispatch: func,
    values: shape({}).isRequired,
    isDesktop: bool.isRequired,
};

Step4Content.defaultProps = {
    dispatch: () => {},
    isSubmitting: false,
    className: null,
};

const mapStateToProps = (state) => ({
    termsAndConditions: loginSelectors.getTermsAndConditions(state),
    isAssistantLogin: assistantSelectors.isAssistantLogin(state),
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ acceptConditions: false }),
        validationSchema: () =>
            Yup.object().shape({
                acceptConditions: Yup.boolean().oneOf([true], i18n.get(`${FORM_ID}.acceptConditions.required`)),
            }),
        handleSubmit: ({ acceptConditions }, formikBag) => {
            formikBag.props.dispatch(
                formikBag.props.hasActiveSession
                    ? loginActions.loginFingerPrinterStep4Request(acceptConditions, formikBag)
                    : loginActions.loginStep4(acceptConditions, formikBag),
            );
        },
    }),
)(Step4Content);
