import React, { useEffect, useRef } from "react";
import { bool, func, shape, string } from "prop-types";
import { bindActionCreators } from "redux";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";
import TicketContent from "pages/_components/TicketContent";
import { connect } from "react-redux";

import { routerActions } from "react-router-redux/actions";

import { actions, selectors } from "reducers/administration/common/administrationTicket";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import { actions as formActions } from "reducers/form";

import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import { isMobileNativeFunc } from "util/device";
import MainDataTicket from "./_components/tickets/MainDataTicket";
import AdditionalDataTicket from "./_components/tickets/AdditionalDataTicket";
import { getCustomTitleTicket } from "./utils/customTitleTicket";

const Ticket = (props) => {
    const {
        match,
        transactionData,
        fetching,
        routerAction,
        loadTicket,
        downloadTicket,
        shareTicket,
        fromPending,
        fromAction,
        setFromTicket,
    } = props;

    const contentRef = useRef();

    useEffect(() => {
        const { idTransaction } = match.params;
        loadTicket(idTransaction);
    }, []);

    const handleBack = () => {
        setFromTicket();
        if (fromPending) {
            routerAction.push("/pendingTransaction/list", { fromTicket: true });
        } else {
            routerAction.push("/transactions/list", { fromTicket: true });
        }
    };

    const handleDownloadPdf = () => {
        const { idTransaction } = match.params;
        if (isMobileNativeFunc()) {
            shareTicket(idTransaction, contentRef);
        } else {
            downloadTicket(idTransaction, contentRef);
        }
    };

    return (
        <>
            <Notification scopeToShow="ticket" />
            <Head
                titleText={getCustomTitleTicket(transactionData) || transactionData.activityName}
                onBack={handleBack}
                textBack={`forms.transaction.ticket.${fromPending ? "returnToPending" : "returnToList"}`}
            />
            <MainContainer
                showLoader={fetching}
                className={classNames({
                    "background-white": !isDesktop,
                })}>
                <AdministrationTicket
                    {...props}
                    fromPending={fromPending}
                    fromAction={fromAction}
                    handleDownloadPdf={handleDownloadPdf}
                    handleBack={handleBack}>
                    <TicketContent
                        contentRef={contentRef}
                        {...props}
                        head={<MainDataTicket transactionData={transactionData} />}>
                        <AdditionalDataTicket {...props} />
                    </TicketContent>
                </AdministrationTicket>
            </MainContainer>
        </>
    );
};

Ticket.propTypes = {
    fetching: bool.isRequired,
    fromPending: bool.isRequired,
    fromAction: bool.isRequired,
    transactionData: shape({
        data: shape({}),
        idActivity: string,
        idTransaction: string,
    }).isRequired,
    loadTicket: func.isRequired,
    routerAction: shape({
        goBack: func.isRequired,
    }).isRequired,
    match: shape({
        params: shape({ idTransaction: string.isRequired }),
    }).isRequired,
    downloadTicket: func.isRequired,
    shareTicket: func.isRequired,
    setFromTicket: func.isRequired,
};

const mapStateToProps = (state) => ({
    fetching: selectors.isFetching(state),
    transactionData: selectors.getData(state),
    emailUserCreator: selectors.getEmailUserCreator(state),
    groups: selectors.getUIpermission(state),
    fromPending: transactionsSelectors.getFromPending(state),
    fromAction: transactionsSelectors.getFromAction(state),
});

const mapDispatchToProps = (dispatch) => ({
    routerAction: bindActionCreators(routerActions, dispatch),
    loadTicket: (idTransaction) => dispatch(actions.loadAdministrationTicketRequest(idTransaction)),
    downloadTicket: (idTransaction, image) => dispatch(formActions.downloadImageTicket(idTransaction, image)),
    shareTicket: (idTransaction, image) => dispatch(formActions.shareImageTicket(idTransaction, image)),
    setFromTicket: () => dispatch(transactionsActions.setFromTicket()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ticket);
