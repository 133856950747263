import * as API from "middleware/api";

export const validateStatusToken = (deviceUuid, exchangeToken) => {
    if (exchangeToken) {
        return API.executeWithExchangeToken(
            "/token.entrust.validateStatus.public",
            {
                deviceUuid,
            },
            exchangeToken,
        );
    }

    return API.executeWithAccessToken("/token.entrust.validateStatus", {
        deviceUuid,
    });
};

export const getTokenInfo = (deviceUuid) => API.executeAnonymous("/token.get.info", { deviceUuid });

export const saveUserToken = (deviceUuid, credentials, deviceModel, deviceBrand, pinCode, secondFactor) =>
    API.executeWithAccessToken("/token.entrust.saveSoftToken", {
        deviceUuid,
        credentials,
        deviceModel,
        deviceBrand,
        pinCode,
        _secondFactor: secondFactor
    });

export const resetActivateToken = (deviceUuid) =>
    API.executeWithAccessToken("/token.entrust.resetActivateToken", { deviceUuid });
export const activateUserToken = (deviceUuid, registrationCode) =>
    API.executeWithAccessToken("/token.entrust.activateUserToken", { deviceUuid, registrationCode });

export const saveUserTokenPublic = (deviceUuid, credentials, deviceModel, deviceBrand, pinCode, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/token.entrust.saveSoftToken.public",
        { deviceUuid, credentials, deviceModel, deviceBrand, pinCode },
        _exchangeToken,
    );

export const activateUserTokenPublic = (deviceUuid, registrationCode, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/token.entrust.activateUserToken.public",
        { deviceUuid, registrationCode },
        _exchangeToken,
    );

export const desactivateUserTokenPreview = (deviceUuid, secondFactor) =>
    API.executeWithAccessToken("/token.entrust.desactivateToken.preview", { deviceUuid, _secondFactor: secondFactor });
    
export const desactivateUserToken = (deviceUuid, secondFactor) =>
    API.executeWithAccessToken("/token.entrust.desactivateToken", { deviceUuid, _secondFactor: secondFactor });

export const unlockUserAuthenticator = (deviceUuid, secondFactor) =>
    API.executeWithAccessToken("/authenticator.entrust.unlock", { deviceUuid,
        _secondFactor: secondFactor,
    });

export const validateStatusAuthenticator = (authenticatorType) =>
    API.executeWithAccessToken("/authenticator.entrust.validateStatus", { authenticatorType });

export const deleteTokenPinRequest = (deviceUuid) => API.executeWithAccessToken("/token.pin.delete", { deviceUuid });

export const validateTokenPinRequest = (deviceUuid, pinCode) =>
    API.executeAnonymous("/token.pin.unlock", { deviceUuid, pinCode });

export const migrateEntrustTokenPre = (deviceUuid) =>
    API.executeWithAccessToken("/token.entrust.migrateEntrustTokenPre", { deviceUuid });

export const resendOtpAuthenticate = () => API.executeWithAccessToken("/token.entrust.resendOtpAuthenticate");

export const validateStatusTokenOnBase = (deviceUuid) =>
    API.executeWithAccessToken("/token.onBase.validateStatus", { deviceUuid });

export const getRateValue = () => API.executeWithAccessToken("/token.entrust.rateValue");
