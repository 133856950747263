import * as i18nUtils from "util/i18n";

import { arrayOf, func, node, number, shape } from "prop-types";
import React, { Component } from "react";

import { CORPORATE_ENVIRONMENT_TYPE } from "constants.js";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import WithPermissions from "pages/_components/WithPermissions";
import AccountsListItem from "pages/accounts/ListItem";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";
import * as config from "util/config";
import WidgetCardList from "./WidgetCardList";

class Accounts extends Component {
    static propTypes = {
        accounts: arrayOf(shape({ length: number })).isRequired,
        closeButton: node,
        dispatch: func.isRequired,
        draggableItemProps: shape({}),
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        closeButton: null,
        draggableItemProps: {},
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(accountsActions.listAccounts());
    }

    hasOnlyOneSimplificateAccount = () => {
        const { accounts } = this.props;

        if (accounts.length !== 1) {
            return false;
        }

        let simplicateList = config.get("accounts.subcategory.simplified");
        simplicateList = simplicateList !== null ? simplicateList.split("|") : null;

        if (simplicateList === null) {
            return false;
        }

        let simplificadas = 0;
        accounts.forEach((account) => {
            if (simplicateList.includes(account.subProductType)) {
                simplificadas += 1;
            }
        });

        return simplificadas === 1;
    };

    renderNewAccountButton = () => {
        const { dispatch, activeEnvironment } = this.props;

        const isCorporate = activeEnvironment.type === CORPORATE_ENVIRONMENT_TYPE;
        const hasOnlyOneSimplificateAccount = this.hasOnlyOneSimplificateAccount();

        const isNotExternalRef = isCorporate || hasOnlyOneSimplificateAccount;
        if (isNotExternalRef) {
            return (
                <Button
                    image="/images/plusCircle.svg"
                    label="accounts.new"
                    bsStyle="link"
                    className="btn-widget"
                    onClick={() => {
                        if (isCorporate && !hasOnlyOneSimplificateAccount) {
                            // TODO: redirigir a la nueva funcionalidad de abrir nueva cuenta para empresa.
                            dispatch(push("/websiteunderconstruction"));
                        }
                        if (hasOnlyOneSimplificateAccount) {
                            dispatch(accountsActions.showModal());
                        }
                    }}
                />
            );
        }

        return (
            <Button
                externalHref={config.get("accessBar.requests.url")}
                image="/images/plusCircle.svg"
                label="accounts.new"
                bsStyle="link"
                className="btn-widget"
            />
        );
    };

    render() {
        const { draggableItemProps } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetAccounts" };
        const itemsToShowButton = Number(config.get("widgets.product.maxToShow"));

        return (
            <WidgetCardList item={AccountsListItem} keyExtractor={(item) => item.idProduct} name="accounts">
                {(list, listLenght) => (
                    <>
                        <section
                            className={`widget ${listLenght === 2 ? "two-cards" : ""}`}
                            {...uiAutomationProp}
                            {...draggableItemProps}
                            aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                            {list}
                            <WithPermissions permissions={["accounts"]}>
                                <Box display="flex" alignX="between" fullWidth flex1 className="px-md-3" zIndex="0">
                                    <WithPermissions permissions={["accountOpen"]}>
                                        {this.renderNewAccountButton()}
                                    </WithPermissions>
                                    {listLenght > itemsToShowButton ? (
                                        <Button
                                            className="btn-widget"
                                            href="/accounts"
                                            label="widgets.accounts.myAccounts"
                                            bsStyle="link"
                                        />
                                    ) : null}
                                </Box>
                            </WithPermissions>
                            <div className="overlay" />
                        </section>
                    </>
                )}
            </WidgetCardList>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(Accounts);
