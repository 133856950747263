import { call, put, takeLatest } from "redux-saga/effects";
import creditLines from "middleware/creditLines";
import * as i18n from "util/i18n";
import { types, actions } from "reducers/creditLines";
import { actions as notificationActions } from "reducers/notification";

const sagas = [takeLatest(types.LIST_REQUEST, listRequest)];

export default sagas;

function* listRequest() {
    const response = yield call(creditLines);

    if (response.type === "W") {
        yield put(actions.listFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditLines"]));
    } else {
        const { creditLines: responseCreditLines, total } = response.data.data;
        yield put(actions.listSuccess(responseCreditLines, total));
    }
}
