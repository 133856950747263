import React from "react";
import Box from "pages/_components/Box/Box";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import { string } from "prop-types";

const TokenButtonDisclaimer = ({ label, icon }) => (
    <Box
        background="white"
        borderRadius="extreme"
        border="background-divider"
        display="flex"
        alignX="center"
        alignY="center"
        fitWidth
        className="py-2 pl-2 pr-2 mx-auto ">
        {icon && (
            <Box>
                <Image className="svg-icon" src={icon || "images/successRadious.svg"} ariaLabel="tokenStatus" />
            </Box>
        )}
        {label && <Text size="5" color="heading" regular className="mr-2" labelKey={label} />}
    </Box>
);

TokenButtonDisclaimer.propTypes = {
    label: string,
    icon: string,
};

TokenButtonDisclaimer.defaultProps = {
    label: undefined,
    icon: undefined,
};

export default TokenButtonDisclaimer;
