import React, { Component } from "react";
import { node } from "prop-types";

class TableHeader extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    render() {
        const { children } = this.props;
        return (
            <div className="table-head">
                <div className="table-row">{children}</div>
            </div>
        );
    }
}

export default TableHeader;
