import classNames from "classnames";
import CloseCampaignModal from "pages/campaigns/CloseCampaignModal";
import Box from "pages/_components/Box";
import { bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { openLink } from "util/browser";
import * as configUtils from "util/config";
import { isMobileNative } from "util/device";

class CampaignItem extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        idCampaign: number.isRequired,
        name: string.isRequired,
        clickable: bool.isRequired,
        url: string,
        image: string,
        isDesktop: bool.isRequired,
        className: string,
        mainImage: shape({
            content: string,
        }),
    };

    static defaultProps = {
        className: null,
        image: undefined,
        url: undefined,
        mainImage: undefined,
    };

    state = {
        displayModal: false,
        selectedCampaign: 0,
    };

    renderImage = (image, url = null) => (
        <>
            {image === undefined ? (
                <div />
            ) : (
                <Box className="campaign-animate-in">
                    <img
                        className="display-block"
                        src={`data:image/png;base64, ${image}`}
                        alt="Campaign"
                        onClick={url ? () => this.handleClick(url) : null}
                    />
                </Box>
            )}
        </>
    );

    handleClick = (url) => {
        if (isMobileNative) {
            openLink(url).then();
        }
    };

    showModal = () => {
        const { idCampaign } = this.props;
        this.setState({ displayModal: true, selectedCampaign: idCampaign });
    };

    hideModal = () => {
        this.setState({ displayModal: false, selectedCampaign: 0 });
    };

    render() {
        const { idCampaign, url, image, mainImage, dispatch, isDesktop, className } = this.props;
        const baseUrl = configUtils.get("client.baseURL");
        const { selectedCampaign, displayModal } = this.state;

        const img = image || mainImage?.content;

        const linkURL = url && url.includes(baseUrl) ? url.replace(baseUrl, "") : url;
        return (
            <Box
                className={classNames("campaignWrapper", className)}
                {...(!isDesktop && { borderRadius: "xl", overflow: "hidden" })}>
                <CloseCampaignModal
                    dispatch={dispatch}
                    idCampaign={selectedCampaign}
                    isDisplayed={displayModal}
                    closeModal={this.hideModal}
                />
                <Box key={idCampaign}>
                    {linkURL ? (
                        // TODO (LABEL a11y)
                        <Link
                            to={{ pathname: linkURL }}
                            aria-label={`ir a ${linkURL}`}
                            replace
                            target="_blank"
                            rel="noreferrer noopener">
                            {this.renderImage(img, linkURL)}
                        </Link>
                    ) : (
                        this.renderImage(img)
                    )}
                </Box>
            </Box>
        );
    }
}

export default CampaignItem;
