import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Select from "pages/_components/fields/Select";
import AmountField from "pages/_components/fields/formik/AmountField";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import * as Yup from "yup";
import * as i18n from "util/i18n";
import SwitchField from "pages/_components/fields/SwitchField";
import Info from "pages/_components/Info";

class Channels extends Component {
    static propTypes = {
        actions: shape({ loadChannelsRequest: func.isRequired }).isRequired,
        routerActions: shape({ goBack: func.isRequired }).isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        enabledChannelsFrequencies: arrayOf(
            shape({
                value: string.isRequired,
                label: string.isRequired,
            }),
        ),
        enabledChannels: arrayOf(string).isRequired,
        currencies: arrayOf(
            shape({
                id: string.isRequired,
                value: string,
                label: string.isRequired,
            }),
        ).isRequired,
        nonRemovableChannels: arrayOf(string).isRequired,
        caps: shape({}).isRequired,
        topAmount: shape({
            maximum: number,
            amount: number,
            frequency: string,
        }),
        fetching: bool.isRequired,
        user: shape({}).isRequired,
        signatureLevel: string.isRequired,
        userEnvStatus: string.isRequired,
    };

    static defaultProps = {
        enabledChannelsFrequencies: null,
        topAmount: null,
    };

    componentDidMount() {
        const { actions, match } = this.props;
        actions.loadChannelsRequest(match.params.id);
    }

    handleBack = () => {
        const { routerActions } = this.props;
        routerActions.goBack();
    };

    handleSubmit = (values, { setSubmitting }) => {
        const { match, actions, topAmount } = this.props;
        const enabledChannels = [...values.enabledChannels];
        values.channels.all = { ...values.channels.all, amount: topAmount?.maximum };
        actions.updateChannelsPreview(
            {
                caps: Object.entries(values.channels).reduce((enabled, [channel, data]) => {
                    if (!enabledChannels.includes(channel)) {
                        return enabled;
                    }

                    return { ...enabled, [channel]: data };
                }, {}),
                idUser: match.params.id,
            },
            setSubmitting,
        );
    };

    validationSchema = () => {
        const { topAmount } = this.props;

        return Yup.lazy((values) =>
            Yup.object().shape({
                channels: Yup.object().shape({
                    frontend: Yup.object().shape({
                        amount: !values.enabledChannels.includes("frontend")
                            ? Yup.number()
                                  .transform((cv, ov) => (ov === "" ? undefined : cv))
                                  .nullable()
                            : Yup.number()
                                  .transform((cv, ov) => (ov === "" ? undefined : cv))
                                  .nullable()
                                  .max(
                                      topAmount.maximum,
                                      i18n.get("administration.limits.channels.topAmount.maxAmount.error"),
                                  )
                                  .min(1, i18n.get("administration.limits.channels.frontend.required"))
                                  .required(i18n.get("administration.limits.channels.frontend.required")),
                    }),
                    phonegap: Yup.object().shape({
                        amount: !values.enabledChannels.includes("phonegap")
                            ? Yup.number()
                                  .transform((cv, ov) => (ov === "" ? undefined : cv))
                                  .nullable()
                            : Yup.number()
                                  .transform((cv, ov) => (ov === "" ? undefined : cv))
                                  .nullable()
                                  .max(
                                      topAmount.maximum,
                                      i18n.get("administration.limits.channels.topAmount.maxAmount.error"),
                                  )
                                  .min(1, i18n.get("administration.limits.channels.phonegap.required"))
                                  .required(i18n.get("administration.limits.channels.phonegap.required")),
                    }),
                }),
            }),
        );
    };

    isValidValues = (values) => {
        const { enabledChannels } = values;
        const validChannels = [];
        enabledChannels.forEach((channel) => {
            if (values?.channels[channel]?.amount > 0) {
                validChannels.push(channel);
            }
        });
        if (validChannels.length === enabledChannels.length) {
            return true;
        }
        return false;
    };

    isDisabledField = (values, channel) => !values.enabledChannels.includes(channel);

    renderForm = ({ isSubmitting, values }) => {
        const {
            enabledChannelsFrequencies,
            enabledChannels,
            nonRemovableChannels,
            user,
            topAmount,
            signatureLevel,
            userEnvStatus,
        } = this.props;

        return (
            <Form>
                <Box
                    component="section"
                    display="flex"
                    column
                    background="component-background"
                    borderRadius="default"
                    className="p-7 box-shadow-small background-white border-radius-lg"
                    fullHeight>
                    <Box
                        display="flex"
                        fullWidth
                        className="p-7 box-shadow-small background-white border-radius-lg mb-3"
                        background="white"
                        borderRadius="lg">
                        <Box display="flex" className="mt-7" gapX="3">
                            <Row>
                                <Col xs={4}>
                                    <Box display="flex" column>
                                        <Text
                                            component="p"
                                            labelKey="administration.user.label"
                                            className="m-0 pb-3"
                                            color="heading-color"
                                            size="7"
                                            regular
                                        />
                                        <Text
                                            component="label"
                                            className="my-0 mr-5"
                                            color="heading-color"
                                            bold
                                            size="6">
                                            {user?.fullName}
                                        </Text>
                                    </Box>
                                </Col>
                                <Col xs={4}>
                                    <Box display="flex" column>
                                        <Text
                                            component="label"
                                            className="m-0 pb-3"
                                            labelKey="administration.user.details.documentNumber"
                                            color="heading-color"
                                            size="7"
                                            regular
                                        />
                                        <Text color="heading-color" ellipsis bold size="6">
                                            {user?.documentType} {user?.documentNumber}
                                        </Text>
                                    </Box>
                                </Col>
                                <Col xs={2}>
                                    <Box display="flex" column>
                                        <Text
                                            component="label"
                                            className="m-0 pb-3"
                                            labelKey="administration.user.details.signature"
                                            color="heading-color"
                                            size="7"
                                            regular
                                        />
                                        <Text
                                            component="label"
                                            className="my-0 mr-5"
                                            color="heading-color"
                                            bold
                                            size="6">
                                            {signatureLevel}
                                        </Text>
                                    </Box>
                                </Col>

                                <Col xs={1}>
                                    <Info
                                        className={`info-user-list info-user-${userEnvStatus}`}
                                        text={i18n.get(`global.${userEnvStatus}`)}
                                    />
                                </Col>
                            </Row>
                        </Box>
                    </Box>
                    <Box
                        component="ul"
                        display="flex"
                        column
                        gap="3"
                        fullHeight
                        noList
                        className="p-7 box-shadow-small background-white border-radius-lg">
                        {enabledChannels
                            .filter((channel) => channel !== "all")
                            .map((channel, i) => (
                                <Box
                                    component="li"
                                    display="flex"
                                    alignX="between"
                                    alignY="center"
                                    background="component-background"
                                    borderRadius="default"
                                    className="py-5"
                                    fullHeight
                                    noList
                                    key={channel}>
                                    <Row alignY="center">
                                        <Col xs={4}>
                                            <Field name={`channels.${channel}`}>
                                                {({ field, form }) => (
                                                    <div className="display-flex gap-3 align-items-center">
                                                        <SwitchField
                                                            {...field}
                                                            idForm="administration"
                                                            name={field.name}
                                                            value={values.enabledChannels.includes(channel)}
                                                            onChange={() =>
                                                                form.setFieldValue(
                                                                    "enabledChannels",
                                                                    values.enabledChannels.includes(channel)
                                                                        ? values.enabledChannels.filter(
                                                                              (value) => value !== channel,
                                                                          )
                                                                        : values.enabledChannels.concat(channel),
                                                                )
                                                            }
                                                            disabled={nonRemovableChannels.includes(channel)}
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                        </Col>
                                        <Col xs={8}>
                                            <Row alignX="center">
                                                <Col xs={6}>
                                                    <Select
                                                        name={`channels.${channel}.frequency`}
                                                        className={classNames("form-group ellipsis-text", {
                                                            "select-open-top": i === enabledChannels.length - 1,
                                                        })}
                                                        label={i18n.get(`channels.${channel}.frequency`)}
                                                        options={enabledChannelsFrequencies}
                                                        disabled={this.isDisabledField(values, channel)}
                                                    />
                                                </Col>
                                                <Col xs={6}>
                                                    <Field
                                                        name={`channels.${channel}`}
                                                        component={AmountField}
                                                        maxLength={15}
                                                        data={{
                                                            options: [{ id: 1, label: "USD" }],
                                                        }}
                                                        clearable={false}
                                                        labelText={i18n.get(`channels.${channel}.amount`)}
                                                        bold={false}
                                                        disabled={this.isDisabledField(values, channel)}
                                                        hideCurrency
                                                        labelNoMarginTop
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Box>
                            ))}
                        <Box display="flex" className="my-3">
                            <span className="size-7 display-flex">
                                {i18n.get("administration.limits.maximum.text")}
                                <FormattedAmount
                                    quantity={topAmount?.maximum}
                                    currency="USD"
                                    size="7"
                                    bold
                                    className="mx-2"
                                />
                                {i18n.get("administration.limits.daily.text")}
                            </span>
                        </Box>
                    </Box>
                </Box>
                <Box className="main-container p-7">
                    <Row>
                        <Col xs={3} xsOffset={3}>
                            <Button bsStyle="outline" label="global.cancel" onClick={this.handleBack} block />
                        </Col>
                        <Col xs={3}>
                            <Button
                                type="submit"
                                bsStyle="primary"
                                label="global.continue"
                                loading={isSubmitting}
                                disabled={
                                    !values.enabledChannels ||
                                    !values.enabledChannels.length > 0 ||
                                    !this.isValidValues(values)
                                }
                                block
                            />
                        </Col>
                    </Row>
                </Box>
            </Form>
        );
    };

    render() {
        const { fetching, enabledChannels, caps, topAmount } = this.props;
        const { maximum, amount, frequency } = { ...topAmount };
        const isLoading = fetching && !Object.keys(caps).length;
        return (
            <>
                <Head onBack={this.handleBack} textBack="administration.users.returnToDetail" hasCenterContent>
                    <Text component="h2" size="1" color="heading-color" classNames="m-0" bold>
                        <I18n id="administration.limits.title" />
                    </Text>
                </Head>
                <MainContainer showLoader={isLoading || !enabledChannels.length}>
                    <Formik
                        onSubmit={this.handleSubmit}
                        initialValues={{
                            channels: enabledChannels.reduce(
                                (values, channel) => ({
                                    ...values,
                                    [channel]: caps[channel] || { frequency: "daily" },
                                }),
                                {
                                    topAmount: {
                                        amount: maximum || amount,
                                        frequency,
                                    },
                                },
                            ),
                            enabledChannels: Object.keys(caps),
                        }}
                        validationSchema={this.validationSchema}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </>
        );
    }
}

export default Channels;
