import { createReducer } from "util/redux";

const INITIAL_STATE = {
    list: [],
    pageNumber: 0,
    totalLines: 0,
    lastPage: true,
    fetching: false,
};

export default ([requestType, successType, failureType]) =>
    createReducer(INITIAL_STATE, {
        [requestType]: (state, action) => {
            if (!action.payload.pageNumber) {
                return INITIAL_STATE;
            }
            return {
                ...state,
                fetching: true,
            };
        },
        [successType]: (state, action) => ({
            ...state,
            fetching: false,
            pageNumber: state.pageNumber + 1,
            totalLines: action.payload.totalLines,
            lastPage: action.payload.isLastPage,
            list: [...state.list, ...action.payload.list],
            totalAmountQuantity: action.payload.totalAmountQuantity,
            totalAmountCurrency: action.payload.totalAmountCurrency,
        }),
        [failureType]: (state) => ({
            ...state,
            fetching: false,
        }),
    });

export const selectors = {
    getList: ({ list }) => list,
    getPageNumber: ({ pageNumber }) => pageNumber,
    getTotalLines: ({ totalLines }) => totalLines,
    isLastPage: ({ lastPage }) => lastPage,
    isFetching: ({ fetching }) => fetching,
    getTotalAmount: ({ totalAmountQuantity, totalAmountCurrency }) => ({
        currency: totalAmountCurrency,
        quantity: totalAmountQuantity,
    }),
};
