import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import TextArea from "pages/_components/fields/TextArea";
import Checkbox from "pages/_components/fields/formik/Checkbox";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { actions as productRequestActions, selectors as productRequestSelectors } from "reducers/productRequest";
import * as i18n from "util/i18n";
import * as Yup from "yup";

import ProductRequestSelect from "pages/_components/fields/ProductRequestSelect";
import { selectors as sessionSelectors } from "reducers/session";

import { RETAIL_ENVIRONMENT_TYPE, CORPORATE_ENVIRONMENT_TYPE } from "constants.js";

const FORM_ID = "productRequest.drawer";

const ProductRequestModal = (props) => {
    const { dispatch, showModal, isFetching, productDefault, activeEnvironment } = props;

    const [apcChecking, setApcChecking] = useState(false);
    const [dataTreatmentCheck, setDataTreatmentCheck] = useState(false);
    const [productListTest, setProductListTest] = useState([]);

    const getProductListByEnviromentType = () => {
        let i18nProductList = [];
        if (activeEnvironment.type === RETAIL_ENVIRONMENT_TYPE) {
            i18nProductList = i18n.get("productRequest.drawer.productList.retail");
        } else if (activeEnvironment.type === CORPORATE_ENVIRONMENT_TYPE) {
            i18nProductList = i18n.get("productRequest.drawer.productList.corporate");
        }

        i18nProductList = i18nProductList.split("|");
        const mapProductList = [];
        i18nProductList.forEach((product) => {
            const object = {};
            object.value = product;
            object.label = i18n.get(`productRequest.drawer.product.${product}`);
            mapProductList.push(object);
        });
        setProductListTest(mapProductList);
    };

    useEffect(() => {
        getProductListByEnviromentType();
    }, []);

    useEffect(() => {
        if (!showModal) {
            setTimeout(() => {
                setApcChecking(false);
                setDataTreatmentCheck(false);
            }, 1000);
        }
    }, [showModal]);

    const handleHideModal = () => {
        dispatch(productRequestActions.modalHide());
    };

    const onChangeApcCheckBox = () => {
        setApcChecking(!apcChecking);
    };

    const onChangeDataTreatmentCheckBox = () => {
        setDataTreatmentCheck(!dataTreatmentCheck);
    };

    const handleSubmit = (values) => {
        const {
            productRequestSelect: { contactType, product, productType },
            message,
        } = values;

        let pathname = props.history?.location?.pathname;
        pathname = pathname.replace("/", "");
        pathname = pathname === "creditCards" ? "creditcards" : pathname;

        dispatch(productRequestActions.sendRequestProduct(product, productType, contactType, message, pathname));
    };

    const validationSchema = () =>
        Yup.object().shape({
            productRequestSelect: Yup.object().shape({
                product: Yup.string().required(i18n.get(`${FORM_ID}.productRequestSelect.product.required`)),
                productType: Yup.string()
                    .trim()
                    .required(i18n.get(`${FORM_ID}.productRequestSelect.productType.required`))
                    .nullable(),
            }),
        });

    return (
        <Modal
            aria-labelledby="modalTitleID"
            aria-modal="true"
            onHide={handleHideModal}
            show={showModal}
            className={classNames({ drawer: true })}>
            <div className="modal-container">
                <Modal.Header closeButton>
                    <Text
                        id="modalTitleID"
                        component="h4"
                        labelKey="productRequest.drawer.title"
                        className="modal-title"
                    />
                </Modal.Header>
                <Modal.Body>
                    <Box
                        display="flex"
                        column
                        alignY="between"
                        className="scrollable-content px-0 px-md-9"
                        fullWidth
                        fullHeight>
                        <Formik
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            initialValues={{
                                productRequestSelect: { product: "", productType: "", contactType: "phone" },
                                message: "",
                            }}>
                            <Form>
                                <Box display="flex" column className="mb-auto">
                                    <Text key="text" align="center" labelKey="productRequest.drawer.text" />

                                    <Field
                                        component={ProductRequestSelect}
                                        productsList={productListTest}
                                        className="slideFromBottom"
                                        idForm={FORM_ID}
                                        name="productRequestSelect"
                                        type="text"
                                        productDefault={productDefault}
                                    />

                                    <Field
                                        hidePlaceholder
                                        component={TextArea}
                                        idForm={FORM_ID}
                                        name="message"
                                        maxLength={1500}
                                    />

                                    <Box className="my-5" display="flex" alignX="center">
                                        <Field
                                            idForm={FORM_ID}
                                            name="apcReview"
                                            component={Checkbox}
                                            checked={apcChecking}
                                            block={false}
                                            onChange={onChangeApcCheckBox}
                                        />
                                    </Box>

                                    <Box className="mb-5" display="flex" alignX="flex-start">
                                        <Field
                                            idForm={FORM_ID}
                                            name="dataTreatment"
                                            component={Checkbox}
                                            checked={dataTreatmentCheck}
                                            block={false}
                                            noMarginBottom
                                            onChange={onChangeDataTreatmentCheckBox}
                                        />
                                    </Box>

                                    <Box className="mt-7 pb-9 pb-md-12">
                                        <Button
                                            block
                                            type="submit"
                                            label="forms.reference.letter.submit.button"
                                            bsStyle="primary"
                                            loading={isFetching}
                                            disabled={!apcChecking || !dataTreatmentCheck}
                                        />
                                    </Box>
                                </Box>
                            </Form>
                        </Formik>
                    </Box>
                </Modal.Body>
            </div>
        </Modal>
    );
};

ProductRequestModal.propTypes = {
    showModal: bool.isRequired,
    isFetching: bool,
    productDefault: string,
    dispatch: func.isRequired,
    activeEnvironment: shape({
        type: string.isRequired,
    }).isRequired,
    history: shape({
        location: shape({
            pathname: string.isRequired,
        }).isRequired,
    }).isRequired,
};

ProductRequestModal.defaultProps = {
    isFetching: false,
    productDefault: null,
};

const mapStateToProps = (state) => ({
    showModal: productRequestSelectors.getShowModal(state),
    isFetching: productRequestSelectors.isFetching(state),
    productDefault: productRequestSelectors.getProductDefault(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(ProductRequestModal);
