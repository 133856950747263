import React from "react";
import { selectors as notificationSelectors, actions as notificationActions } from "reducers/notification";
import { connect } from "react-redux";
import { arrayOf, bool, func, number, string, shape, node } from "prop-types";
import NotificationSystem from "react-notification-system";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { isMobileNativeFunc } from "util/device";

class Notification extends React.Component {
    static propTypes = {
        clearNotifications: bool,
        currentLang: string,
        dispatch: func.isRequired,
        errors: shape({}),
        level: string,
        message: string,
        metadata: shape({
            idForm: string,
            version: number,
            enabled: bool,
            category: string,
            type: string,
            adminOption: string,
            idActivity: string,
            fieldList: arrayOf(
                shape({
                    idField: string.isRequired,
                    labelMap: shape({}).isRequired,
                    requiredErrorMap: shape({}).isRequired,
                }),
            ).isRequired,
        }),
        scopes: arrayOf(string).isRequired,
        scopeToShow: string.isRequired,
        timeOut: number,
        getRightComponent: node,
        isDesktop: bool,
        persistent: bool,
        autoDismiss: string,
    };

    static defaultProps = {
        clearNotifications: true,
        currentLang: undefined,
        errors: {},
        level: null,
        message: null,
        metadata: undefined,
        getRightComponent: undefined,
        timeOut: undefined,
        isDesktop: false,
        persistent: false,
        autoDismiss: '5', // default autoDismiss time as a string
    };

    constructor(props) {
        super(props);
        this.notificationRef = React.createRef();

        this.state = {
            notificationSystem: null,
        };
    }

    componentDidMount() {
        const { current } = this.notificationRef;
        this.setState({ notificationSystem: current });
    }

    componentDidUpdate() {
        const { message, scopes, scopeToShow } = this.props;

        if (message && scopes && scopes.indexOf(scopeToShow) !== -1) {
            this.addNotification();
        }
    }

    removeNotificationAction = (notificationUid) => {
        const { dispatch, timeOut, isDesktop, persistent } = this.props;
        if ((isMobileNativeFunc() || !isDesktop) && timeOut && !persistent) {
            setTimeout(() => {
                dispatch(notificationActions.removeNotification());
            }, timeOut);
            return;
        }
        if (!persistent) {
            dispatch(notificationActions.removeNotification());
        }
    };

    addNotification = () => {
        const { notificationSystem } = this.state;
        const { clearNotifications, persistent, autoDismiss, currentLang, errors, level, message, metadata, getRightComponent, title } = this.props;

        let allFormFieldsInfo;
        let errorFieldsKeys;
        let errorFieldsInfo;

        if (metadata) {
            allFormFieldsInfo = metadata.fieldList;
            errorFieldsKeys = Object.keys(errors);
            errorFieldsInfo = allFormFieldsInfo.filter((field) => errorFieldsKeys.includes(field.idField));
        }

        if (clearNotifications) {
            notificationSystem.state.notifications.forEach((notification) => {
                if (!notification.persistent) {
                    notificationSystem.removeNotification(notification.uid);
                }
            });
        }

        const notificationUid = notificationSystem.addNotification({
            message:
                typeof message === "object" ? (
                    message
                ) : (
                    <Box display="flex" column>
                        {title && <Text bold defaultValue={title} className="line-height-15" />}
                        <Text defaultValue={message} />
                        {getRightComponent && getRightComponent()}
                    </Box>
                ),
            level,
            position: "tc",
            autoDismiss: autoDismiss, // autoDismiss as string
            dismissible: true,
            children: (
                <>
                    <Box position="absolute" {...!title && { top: "50" }} className="notification-icon">
                        <Image src={`images/icons/sb-${level}.svg`} />
                    </Box>

                    <div className="visually-hidden">
                        {errorFieldsInfo &&
                            errorFieldsInfo.map((field) => (
                                <div key={field.idField}>{`${field.labelMap[currentLang]} : ${field.requiredErrorMap[currentLang]}`}</div>
                            ))}
                    </div>
                </>
            ),
            persistent: persistent, // Add persistent to notification object
        });

        if (!persistent) {
            this.removeNotificationAction(notificationUid);
        }

        // Scroll to notification
        const { current } = this.notificationRef;
        if (current) {
            const scrollableContent = document.querySelector(".scrollable-content");

            if (scrollableContent) {
                scrollableContent.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }
        }
    };

    render() {
        return (
            <div aria-live="polite">
                <NotificationSystem
                    ref={this.notificationRef}
                    // eslint-disable-next-line react/style-prop-object
                    style={false}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    title: notificationSelectors.getTitle(state),
    message: notificationSelectors.getMessage(state),
    level: notificationSelectors.getLevel(state),
    scopes: notificationSelectors.getScopes(state),
    clearNotifications: notificationSelectors.getClearNotifications(state),
    timeOut: notificationSelectors.getTimeOut(state),
    getRightComponent: notificationSelectors.getRightComponent(state),
    persistent: notificationSelectors.getPersistent(state),
    autoDismiss: notificationSelectors.getAutodismiss(state),
});

export default connect(mapStateToProps)(Notification);
