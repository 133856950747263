import classNames from "classnames";
import moment from "moment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import ChipsButtonsGroup from "pages/_components/ChipsButtonsGroup";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Sticker from "pages/_components/Sticker";
import Text from "pages/_components/Text";
import DateField from "pages/_components/fields/DateField";
import SelectSimpleField from "pages/forms/customForms/_customFields/SelectSimpleField";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import { bool, func, number, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { goBack } from "react-router-redux";
import {
    actions as requestsAndClaimsActions,
    selectors as requestsAndClaimsSelectors,
} from "reducers/requestAndComplaints";
import * as i18n from "util/i18n";
import * as config from "util/config";

export const STATUS_OPEN = "OPEN";
export const STATUS_CLOSE = "CLOSE";
export const STATUS_PROCESING = "IN_PROGRESS";

const buttonsGroup = [
    {
        id: STATUS_OPEN,
        label: "requestAndComplaints.chipsButtonsGroup.open",
    },
    {
        id: STATUS_PROCESING,
        label: "requestAndComplaints.chipsButtonsGroup.procesing",
    },
    {
        id: STATUS_CLOSE,
        label: "requestAndComplaints.chipsButtonsGroup.close",
    },
];
const DAYS_FILTER_MAX_FROM = -90;
const PAGINATION_ROW = 10;

const RequestsAndComplaints = ({ isDesktop, dispatch, requestsAndClaims, offset, moreRecords }) => {
    const [searchBy, setSearchBy] = useState("3");
    const [open, setOpen] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [inputNumberOfcase, setInputNumberOfcase] = useState("");
    const [selectedDateFrom, setSelectedDateFrom] = useState("");
    const [selectedDateTo, setSelectedDateTo] = useState("");
    const [selectedButonId, setSelectedButonId] = useState(STATUS_OPEN);
    const [filters, setFilters] = useState();
    const [errorNroCase, setErrorNroCase] = useState(false);
    const [errorPeriod, setErrorPeriod] = useState(false);

    const styleStatus = config.getIdValueMapArray("requests.complaints.status.code.homologation");

    const searchByOptions = [
        { value: "1", label: i18n.get("requestAndComplaints.filter.all.label") },
        { value: "2", label: i18n.get("requestAndComplaints.filter.period.label") },
        { value: "3", label: i18n.get("requestAndComplaints.filter.request.label") },
    ];
    const onFinish = () => {
        setFetching(false);
    };

    const cleanFilters = () => {
        setInputNumberOfcase("");
        setSelectedDateFrom("");
        setSelectedDateTo("");
    };

    useEffect(() => {
        if (filters) {
            setFetching(true);
            dispatch(requestsAndClaimsActions.listRequestsAndClaimsRequest(filters, onFinish));
        }
    }, [filters]);

    const handleBack = () => {
        dispatch(goBack());
    };

    useEffect(() => {
        cleanFilters();
        setErrorNroCase(false);
        if (selectedButonId === STATUS_OPEN || selectedButonId === STATUS_PROCESING) {
            setSearchBy("3");
            setFilters({ status: selectedButonId, offset: 0, limit: PAGINATION_ROW, searchBy: "3" });
        } else if (selectedButonId === STATUS_CLOSE) {
            setSearchBy("1");
            setFilters({ status: selectedButonId, offset: 0, limit: PAGINATION_ROW, searchBy: "1" });
        }
    }, [selectedButonId]);
    useEffect(() => {
        cleanFilters();
    }, [searchBy]);

    const getColumnsTemplate = () => (isDesktop ? "1fr 1.4fr 1fr 1fr 1fr" : "1.25fr 1fr");

    const handleFilteredButton = () => {
        if (searchBy === "1" && filters.searchBy !== "1") {
            setFilters({ status: selectedButonId, offset: 0, limit: PAGINATION_ROW, searchBy });
        } else if (
            searchBy === "2" &&
            (selectedDateFrom || selectedDateTo) &&
            (filters.searchBy !== "2" || filters.dateFrom !== selectedDateFrom || filters.dateTo !== selectedDateTo)
        ) {
            const dateFromSelected = selectedDateFrom || moment().add(DAYS_FILTER_MAX_FROM, "days");
            const dateToSelected = selectedDateTo || moment();
            setFilters({
                status: selectedButonId,
                dateFrom: dateFromSelected,
                dateTo: dateToSelected,
                offset: 0,
                limit: PAGINATION_ROW,
                searchBy,
            });
            setSelectedDateFrom(dateFromSelected);
            setSelectedDateTo(dateToSelected);
        } else if (
            searchBy === "3" &&
            (filters.searchBy !== "3" || filters.nroCase !== inputNumberOfcase) &&
            inputNumberOfcase.trim() !== ""
        ) {
            setFilters({
                status: selectedButonId,
                nroCase: inputNumberOfcase,
                offset: 0,
                limit: PAGINATION_ROW,
                searchBy,
            });
        }

        setErrorNroCase(searchBy === "3" && !inputNumberOfcase.trim());
        setErrorPeriod(searchBy === "2" && !selectedDateFrom && !selectedDateTo);
    };

    return (
        <>
            <Notification scopeToShow="reuqestAndComplaints" />
            <Head onBack={handleBack} title="requestAndComplaints.title" />
            <MainContainer showLoader={fetching && filters?.offset === 0}>
                <Box
                    borderRadius={isDesktop ? "top-default" : "default"}
                    background="component-background"
                    className="mx-n-5 p-5 mt-3 mt-md-0 mb-3 mb-md-0">
                    <ChipsButtonsGroup
                        buttonsGroup={buttonsGroup}
                        onClickHandler={setSelectedButonId}
                        defaultSelect={selectedButonId}
                    />
                </Box>
                <Box
                    borderRadius={isDesktop ? "bottom-default" : "default"}
                    background="component-background"
                    className="mx-n-5 pt-3 pt-md-0">
                    {!isDesktop && (
                        <Box display="flex" alignX="flex-end" className="pr-5 mb-3">
                            <Button
                                label={
                                    open
                                        ? "global.productFilters.btnFilter.opened.label"
                                        : "global.productFilters.btnFilter.closed.label"
                                }
                                bsStyle="link"
                                className="mt-auto"
                                onClick={() => {
                                    setOpen(!open);
                                }}
                            />
                        </Box>
                    )}
                    <Collapse in={isDesktop ? true : open}>
                        <Box>
                            <Box
                                {...(isDesktop && { border: "top-background-divider-1" })}
                                className="pt-0 pt-md-5 pb-7 pb-md-9 px-5 px-md-7">
                                <Row {...(!isDesktop && { gapX: "3" })}>
                                    <Col xs={12} md={3}>
                                        <SelectSimpleField
                                            labelKey="requestAndComplaints.filter.searchBy.label"
                                            name="searchBy"
                                            placeholder={i18n.get("forms.placeholder.select")}
                                            value={searchBy}
                                            onChange={(e) => {
                                                setSearchBy(e.value);
                                            }}
                                            options={searchByOptions}
                                            disabled={
                                                selectedButonId === STATUS_OPEN ||
                                                (selectedButonId === STATUS_PROCESING && searchBy === "3")
                                            }
                                        />
                                    </Col>
                                    {searchBy === "3" && (
                                        <Col xs={12} md={6}>
                                            <TextSimpleField
                                                labelKey="requestAndComplaints.filter.numberOfCase.label"
                                                name="caseNumber"
                                                placeholder=""
                                                value={inputNumberOfcase}
                                                onChange={(event) => setInputNumberOfcase(event.target.value)}
                                                pattern="^[a-zA-Z0-9ñ@+._-]*$"
                                                error={
                                                    (errorNroCase &&
                                                        i18n.get("fields.defaultForm.defaultField.requiredError")) ||
                                                    (filters?.nroCase &&
                                                        requestsAndClaims?.length === 0 &&
                                                        i18n.get("forms.inputFile.massivePayments.invalid.field"))
                                                }
                                            />
                                        </Col>
                                    )}
                                    {searchBy === "2" && (
                                        <>
                                            <Col xs={6} md={3}>
                                                <DateField
                                                    name="dateFrom"
                                                    customLabel={i18n.get("global.from")}
                                                    labelNoMarginTop
                                                    hidePlaceholder
                                                    handleChange={setSelectedDateFrom}
                                                    minDate={moment().add(DAYS_FILTER_MAX_FROM, "days")}
                                                    maxDate={selectedDateTo || moment()}
                                                    popperPlacement="bottom"
                                                    valueSelectedDate={selectedDateFrom}
                                                    popperModifiers={{
                                                        flip: {
                                                            behavior: ["bottom"],
                                                        },
                                                    }}
                                                    error={
                                                        errorPeriod &&
                                                        i18n.get("fields.defaultForm.defaultField.requiredError")
                                                    }
                                                />
                                            </Col>
                                            <Col xs={6} md={3}>
                                                <DateField
                                                    name="dateUntil"
                                                    customLabel={i18n.get("global.until")}
                                                    labelNoMarginTop
                                                    hidePlaceholder
                                                    handleChange={setSelectedDateTo}
                                                    minDate={
                                                        selectedDateFrom || moment().add(DAYS_FILTER_MAX_FROM, "days")
                                                    }
                                                    maxDate={moment()}
                                                    popperPlacement="bottom"
                                                    valueSelectedDate={selectedDateTo}
                                                    popperModifiers={{
                                                        flip: {
                                                            behavior: ["bottom"],
                                                        },
                                                    }}
                                                    error={
                                                        errorPeriod &&
                                                        i18n.get("fields.defaultForm.defaultField.requiredError")
                                                    }
                                                />
                                            </Col>
                                        </>
                                    )}
                                    <Col xs={12} md={3} className="display-flex">
                                        <Button
                                            label="product.filters.filter"
                                            bsStyle="primary"
                                            block
                                            className="mt-auto"
                                            onClick={handleFilteredButton}
                                        />
                                    </Col>
                                </Row>
                            </Box>
                        </Box>
                    </Collapse>
                    <Box className="min-height-10rem">
                        <GridTable>
                            <GridTable.Header>
                                <GridTable.Container
                                    className={classNames({ "gapX-3": isDesktop })}
                                    padding={isDesktop ? "py-2 px-5" : "py-2 pl-5 pr-3"}
                                    columnsTemplate={getColumnsTemplate()}>
                                    {}
                                    <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                                        <Text
                                            labelKey={
                                                isDesktop
                                                    ? "requestAndComplaints.table.header.date"
                                                    : "requestAndComplaints.table.header.date.category"
                                            }
                                        />
                                    </GridTable.Data>
                                    <GridTable.Data
                                        columnStart={2}
                                        alignX={isDesktop ? "flex-start" : "flex-end"}
                                        inHeader>
                                        <Text
                                            labelKey={
                                                isDesktop
                                                    ? "requestAndComplaints.table.header.category"
                                                    : "requestAndComplaints.table.header.numberOfCase.state"
                                            }
                                        />
                                    </GridTable.Data>
                                    {isDesktop && (
                                        <>
                                            <GridTable.Data columnStart={3} alignX="center" inHeader>
                                                <Text labelKey="requestAndComplaints.table.header.operationType" />
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={4} alignX="center" inHeader>
                                                <Text labelKey="requestAndComplaints.table.header.numberOfCase" />
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={5} alignX="center" inHeader>
                                                <Text labelKey="requestAndComplaints.table.header.state" />
                                            </GridTable.Data>
                                        </>
                                    )}
                                </GridTable.Container>
                            </GridTable.Header>
                            <GridTable.Body>
                                {requestsAndClaims?.length > 0 &&
                                    requestsAndClaims?.map((requestAndClaim) => (
                                        <GridTable.Container
                                            className={classNames({ "gapX-3": isDesktop })}
                                            padding={isDesktop ? "py-2 px-5" : "py-2 px-5"}
                                            columnsTemplate={getColumnsTemplate()}>
                                            <GridTable.Data columnStart={1} alignX="flex-start">
                                                {isDesktop ? (
                                                    <FormattedDate
                                                        date={requestAndClaim.registrationDate}
                                                        anotherFormat="DD/MM/YYYY"
                                                    />
                                                ) : (
                                                    <Box display="flex" column>
                                                        <Text bold>{requestAndClaim.category}</Text>
                                                        <Text>{requestAndClaim.typeOperation}</Text>
                                                        <FormattedDate
                                                            date={requestAndClaim.registrationDate}
                                                            anotherFormat="DD/MM/YYYY"
                                                        />
                                                    </Box>
                                                )}
                                            </GridTable.Data>
                                            <GridTable.Data
                                                columnStart={2}
                                                alignX={isDesktop ? "flex-start" : "flex-end"}>
                                                {isDesktop ? (
                                                    <Text>{requestAndClaim.category}</Text>
                                                ) : (
                                                    <Box display="flex" column>
                                                        <Text align="right" size={5}>
                                                            {requestAndClaim.numberOfCase}
                                                        </Text>
                                                        <Sticker
                                                            status={
                                                                requestAndClaim?.status
                                                                    ? styleStatus.find(
                                                                          (item) => item.id === requestAndClaim.status,
                                                                      )?.value || "warning-border-color"
                                                                    : "warning-border-color"
                                                            }
                                                            fixedWidth
                                                            textSize="7"
                                                            addPaddingY
                                                            bold>
                                                            {requestAndClaim.status}
                                                        </Sticker>
                                                    </Box>
                                                )}
                                            </GridTable.Data>
                                            {isDesktop && (
                                                <>
                                                    <GridTable.Data columnStart={3} alignX="center">
                                                        <Text>{requestAndClaim.typeOperation}</Text>
                                                    </GridTable.Data>
                                                    <GridTable.Data columnStart={4} alignX="center">
                                                        <Text>{requestAndClaim.numberOfCase}</Text>
                                                    </GridTable.Data>
                                                    <GridTable.Data columnStart={5} alignX="center">
                                                        <Sticker
                                                            status={
                                                                requestAndClaim?.status
                                                                    ? styleStatus.find(
                                                                          (item) => item.id === requestAndClaim.status,
                                                                      )?.value || "warning-border-color"
                                                                    : "warning-border-color"
                                                            }
                                                            fixedWidth
                                                            textSize="7"
                                                            addPaddingY
                                                            bold>
                                                            {requestAndClaim.status}
                                                        </Sticker>
                                                    </GridTable.Data>
                                                </>
                                            )}
                                        </GridTable.Container>
                                    ))}

                                {moreRecords && (
                                    <Box display="flex" fullWidth alignX="center" className="no-more-data">
                                        <Button
                                            bsStyle="link"
                                            onClick={() => {
                                                setFilters({
                                                    ...filters,
                                                    offset: offset + PAGINATION_ROW,
                                                    limit: PAGINATION_ROW,
                                                });
                                            }}
                                            image="images/arrow-down.svg"
                                            loading={fetching}
                                            label="transactions.list.filters.list.moreData"
                                            className="btn-small"
                                            imageRight
                                        />
                                    </Box>
                                )}

                                {(requestsAndClaims?.length === 0 || !moreRecords) && (
                                    <Box
                                        display="flex"
                                        fullWidth
                                        alignX="center"
                                        className={classNames("no-more-data ", {
                                            "min-height-8rem": requestsAndClaims?.length === 0,
                                        })}>
                                        <Text
                                            labelKey={
                                                requestsAndClaims?.length === 0
                                                    ? "requestAndComplaints.table.empty"
                                                    : "requestAndComplaints.table.noMoreOperation"
                                            }
                                            component="p"
                                            align="center"
                                            bold
                                            size={isDesktop ? "6" : "5"}
                                            color="text"
                                        />
                                    </Box>
                                )}
                            </GridTable.Body>
                        </GridTable>
                    </Box>
                </Box>
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    requestsAndClaims: requestsAndClaimsSelectors.getRequestAndClaims(state)?.listRequestAndClaims,
    limit: requestsAndClaimsSelectors.getRequestAndClaims(state)?.limit,
    offset: requestsAndClaimsSelectors.getRequestAndClaims(state)?.offset,
    moreRecords: requestsAndClaimsSelectors.getRequestAndClaims(state)?.moreRecords,
});

RequestsAndComplaints.propTypes = {
    isDesktop: bool.isRequired,
    history: shape({}).isRequired,
    dispatch: func.isRequired,
    offset: number.isRequired,
    moreRecords: bool.isRequired,
    requestsAndClaims: shape({}).isRequired,
};

export default connect(mapStateToProps)(withRouter(resizableRoute(RequestsAndComplaints)));
