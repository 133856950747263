import React from "react";
import { bool, string, func } from "prop-types";
import classNames from "classnames";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Box from "pages/_components/Box";

const AccessBarBtn = ({ id, image, label, href, externalHref, highlighted, className, onClick }) => (
    <Button
        id={id}
        href={href}
        externalHref={externalHref}
        onClick={onClick}
        className={classNames("access-bar-btn", className, {
            "highlighted-btn": highlighted,
        })}>
        <Box className="access-bar-btn-wrapper">
            <Image
                wrapperClassName="access-bar-btn-icon-wrapper"
                width="8"
                height="8"
                className="access-bar-btn-icon"
                src={image}
            />
            <I18n componentProps={{ className: classNames("access-bar-btn-label") }} id={label} />
        </Box>
    </Button>
);

AccessBarBtn.propTypes = {
    id: string,
    image: string,
    label: string,
    href: string,
    highlighted: bool,
    className: string,
    externalHref: string,
    onClick: func,
};

AccessBarBtn.defaultProps = {
    id: null,
    image: null,
    label: null,
    href: null,
    highlighted: false,
    className: "",
    externalHref: null,
    onClick: null,
};

export default AccessBarBtn;
