import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LOAD_LIST_REQUEST: "loans/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "loans/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "loans/LOAD_LIST_SUCCESS",
};

export const INITIAL_STATE = {
    list: [],
    fetching: true,
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.LOAD_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LOAD_LIST_SUCCESS]: (_, action) => ({
        list: action.list,
        fetching: false,
    }),
});

export const actions = {
    loadListRequest: makeActionCreator(types.LOAD_LIST_REQUEST),
    loadListFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    loadListSuccess: makeActionCreator(types.LOAD_LIST_SUCCESS, "list"),
};

export const selectors = {
    getLoans: ({ loansPayment }) => loansPayment.list,
    isFetching: ({ loansPayment }) => loansPayment.fetching,
};
