import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { shape, func, bool, node, string, number, instanceOf } from "prop-types";
import { actions, selectors, GET_PAGINATED_DATA_LIST } from "reducers/paginatedTable";
import { loadingSelector } from "reducers/loading";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import PageLoading from "pages/_components/PageLoading";
import classNames from "classnames";
import Button from "pages/_components/Button";
import { Col } from "react-bootstrap";
import PaginatorButtonsBar from "../PaginatorButtonsBar";
import Row from "../Row";

const FIRST_PAGE = 1;

const Table = React.memo(
    ({
        idActivity,
        name,
        filters,
        hasFiltersApplied,
        isFetching,
        isDesktop,
        itemProps,
        headerProps,
        itemComponent: ItemComponent,
        headerComponent: HeaderComponent,
        noMoreDataMessage,
        withoutItemsMessage,
        withoutItemsMessageFilter,
        autoClearWhenUnmounted,
        timeOutErrorMessage,
        alternateBackgroundColor,
        handleShowFilter,
        handleClearFilter,
        renderDownloadDocs,
        showFilters,
        extraActionMobile,
        extraActionTwo,

        currentPage,
        setCurrentPage,

        paginatedDataMapBKP,
        itemsMapBKP,

        paginatedData,
        getPagedDataList,
        clearPagedDataList,
        keepLastPage,
        resetKeeptLatPage,
    }) => {
        const [paginatedDataMap, setPaginatedDataMap] = useState(paginatedDataMapBKP || new Map());
        const [itemsMap, setItemsMap] = useState(itemsMapBKP || new Map());
        const [itemsToShow, setItemsToShow] = useState([]);
        const [nextIndex, setNextIndex] = useState(0);
        const [totalpages, setTotalpages] = useState(1);
        const [showWithoutItemsMessage, setShowWithoutItemsMessage] = useState(false);
        const [hasPaginatedData, setHasPaginatedData] = useState(false);
        const [rPerPage, setRowsPerPage] = useState(0);
        const [totalR, setTotalRows] = useState(0);

        useEffect(() => {
            if (!keepLastPage) {
                if (autoClearWhenUnmounted) {
                    clearPagedDataList();
                }
            }else{
                handleKeeptLastPage()
            }
        }, []);

        useEffect(() => {
             // cambio de filtros -- resetea y vuelve a consultar
            if (!keepLastPage) {
                clearPagedDataList();
                setPaginatedDataMap(new Map());
                setItemsMap(new Map());
                setCurrentPage(1);
                getPagedDataList(filters, 1, 0);
            }else{
                handleKeeptLastPage(); 
            }
        }, [filters]);

        const handleKeeptLastPage = ()=>{
            const paginatedDataActivity = paginatedData[`${idActivity}.${name}`];
            if (paginatedDataActivity) {
                const { paginatedList } = paginatedDataActivity;
                const nextIdx = paginatedDataActivity ? paginatedDataActivity.nextIndex : 0;
                if (paginatedList) {
                    const { currentPage: pageNumber } = paginatedList;
                    setCurrentPage(pageNumber);
                    getPagedDataList(filters, pageNumber, nextIdx);
                }
            }
            resetKeeptLatPage();
        } 

        const generateKey = () =>
            Math.random()
                .toString(16)
                .slice(2);

        const getItemsComponent = (elementList, pageNumber, paginatedDataMapVal) => {
            let items = [];
            if (elementList && elementList.length > 0) {
                items = elementList.map((data) => {
                    const newProps = {
                        data,
                        hasFiltersApplied,
                        key: generateKey(),
                        currentPage: pageNumber,
                        paginatedDataMap: paginatedDataMapVal,
                        itemsMap,
                        filters,
                        isDesktop,
                        ...itemProps,
                    };
                    return React.createElement(ItemComponent, newProps);
                });
            }
            return items;
        };

        const setNewItemsToShow = (newItems) => {
            setItemsToShow(newItems);
        };

        useEffect(() => {
            const paginatedDataActivity = paginatedData[`${idActivity}.${name}`];
            if (paginatedDataActivity) {
                const { paginatedList } = paginatedDataActivity;
                const nextIdx = paginatedDataActivity ? paginatedDataActivity.nextIndex : 0;
                if (paginatedList) {
                    const { elementList, currentPage: pageNumber, totalPages, totalRows, rowsPerPage } = paginatedList;
                    if (!elementList || elementList.length === 0) {
                        setNewItemsToShow([]);
                        if (pageNumber === FIRST_PAGE) {
                            setShowWithoutItemsMessage(true);
                        }
                    } else {
                        setTotalpages(totalPages);
                        paginatedDataMap.set(pageNumber, paginatedDataActivity);
                        setPaginatedDataMap(paginatedDataMap);
                        const items = getItemsComponent(elementList, pageNumber, paginatedDataMap);
                        itemsMap.set(pageNumber, items);
                        setItemsMap(itemsMap);
                        setNewItemsToShow(items);
                        setShowWithoutItemsMessage(false);
                    }
                    setNextIndex(nextIdx);
                    setRowsPerPage(rowsPerPage);
                    setTotalRows(totalRows);
                }
                setHasPaginatedData(true);
            }
        }, [paginatedData]);

        const fetchMore = (page) => {
            getPagedDataList(filters, page, nextIndex);
        };

        const goToFirstPage = () => {
            setCurrentPage(1);
            const prevPageItems = itemsMap.get(1);
            if (prevPageItems) {
                setItemsToShow(prevPageItems);
                setShowWithoutItemsMessage(false);
            } else {
                fetchMore(1);
            }
        };

        const goToPreviousPage = () => {
            if (currentPage > 1) {
                const prev = currentPage - 1;
                const prevPageItems = itemsMap.get(prev);
                if (prevPageItems) {
                    setItemsToShow(prevPageItems);
                    setShowWithoutItemsMessage(false);
                } else {
                    fetchMore(prev);
                }
                setCurrentPage(prev);
            }
        };

        const goToNextPage = () => {
            if (currentPage < totalpages) {
                const next = currentPage + 1;
                const nextPageItems = itemsMap.get(next);
                if (nextPageItems) {
                    setNewItemsToShow(nextPageItems);
                    // setCurrentPage(next);
                } else {
                    fetchMore(next);
                }
                setCurrentPage(next);
            }
        };

        const goToLastPage = () => {
            const nextPageItems = itemsMap.get(totalpages);
            if (nextPageItems) {
                setNewItemsToShow(nextPageItems);
            } else {
                setNextIndex((totalpages - 1) * rPerPage);
                fetchMore(totalpages);
            }
            setCurrentPage(totalpages);
        };

        const getHeader = () => {
            const newProps = {
                ...headerProps,
            };
            return React.createElement(HeaderComponent, newProps);
        };

        const rows = () => {
            if (totalR < rPerPage) {
                return totalR;
            }
            return currentPage === totalpages ? totalR : rPerPage * currentPage;
        };

        const renderLabelResults = () => (
            <Box fullWidth display="flex" alignY="center">
                <Text labelKey="global.pagination.results" color="text" size="5" rows={rows()} totalRows={totalR} />
            </Box>
        );

        const renderLabelResultsMobile = () => (
            <Box fullWidth display="flex" alignY="center">
                <Row>
                    <Col xs="6">
                        <Box fullWidth fullHeight display="flex" alignY="center">
                            <Text
                                labelKey="global.pagination.results"
                                color="text"
                                size="5"
                                rows={rows()}
                                totalRows={totalR}
                            />
                        </Box>
                    </Col>
                    <Col xs="6">{extraActionMobile && extraActionMobile()}</Col>
                </Row>
            </Box>
        );

        const renderFilters = () => (
            <>
                {hasFiltersApplied && !showWithoutItemsMessage ? (
                    <Button
                        className="btn-clear-filter mr-7"
                        image="images/util/close.svg"
                        bsStyle="link"
                        label="global.clear.filter"
                        onClick={handleClearFilter}
                    />
                ) : (
                    <Button
                        className="btn-filter mr-7"
                        image="images/util/filter.svg"
                        bsStyle="link"
                        label="global.filter"
                        onClick={handleShowFilter}
                    />
                )}
            </>
        );

        return (
            <Box className={`pagination-component pagination-${name}-content`}>
                {hasPaginatedData && (
                    <PageLoading relative loading={isFetching}>
                        <div>
                            <Box fullWidth display="flex" className="pt-4 pb-3">
                                <>
                                    {isDesktop && renderLabelResults()}
                                    <Box fullWidth={!isDesktop} display="flex" alignY="center">
                                        {showFilters && renderFilters()}
                                    </Box>
                                    <Box display="flex" alignY="end">
                                        {renderDownloadDocs && renderDownloadDocs(showWithoutItemsMessage)}
                                    </Box>
                                </>
                            </Box>

                            {!isDesktop && renderLabelResultsMobile()}

                            {extraActionTwo && extraActionTwo()}

                            {itemsToShow && itemsToShow.length > 0 && (
                                <Box
                                    fullHeight
                                    fullWidth
                                    className={classNames("paginate-table", {
                                        "children-alternate-bg-color": alternateBackgroundColor,
                                    })}>
                                    {isDesktop && getHeader()}
                                    {itemsToShow}
                                </Box>
                            )}
                            {!showWithoutItemsMessage && currentPage >= totalpages && (
                                <Box className="mt-7 mb-7 text-center">{noMoreDataMessage}</Box>
                            )}

                            {showWithoutItemsMessage && (
                                <Box className="mt-7 mb-7 text-center">
                                    {hasFiltersApplied && withoutItemsMessageFilter ? (
                                        <> {withoutItemsMessageFilter}</>
                                    ) : (
                                        <> {withoutItemsMessage}</>
                                    )}
                                </Box>
                            )}
                        </div>
                    </PageLoading>
                )}

                {!showWithoutItemsMessage && (
                    <PaginatorButtonsBar
                        page={currentPage}
                        totalpages={totalpages}
                        goToFirstPage={goToFirstPage}
                        goToPreviousPage={goToPreviousPage}
                        goToNextPage={goToNextPage}
                        goToLastPage={goToLastPage}
                    />
                )}
                {!hasPaginatedData && (
                    <PageLoading relative loading={isFetching}>
                        <Box>{timeOutErrorMessage}</Box>
                    </PageLoading>
                )}
            </Box>
        );
    },
);

Table.propTypes = {
    idActivity: string.isRequired,
    name: string,
    filters: shape({}),
    hasFiltersApplied: bool.isRequired,
    paginatedData: shape({}),
    isFetching: bool,
    isDesktop: bool,
    itemProps: shape({}).isRequired,
    headerProps: shape({}),
    itemComponent: node.isRequired,
    headerComponent: node,
    noMoreDataMessage: node,
    withoutItemsMessage: node,
    withoutItemsMessageFilter: node,

    currentPage: number.isRequired,
    setCurrentPage: func.isRequired,

    paginatedDataMapBKP: instanceOf(Map),
    itemsMapBKP: instanceOf(Map),

    getPagedDataList: func.isRequired,
    clearPagedDataList: func.isRequired,

    autoClearWhenUnmounted: bool,
    timeOutErrorMessage: string,
    alternateBackgroundColor: bool,
    renderDownloadDocs: func,
    handleShowFilter: func,
    handleClearFilter: func,
    showFilters: bool,
    extraActionTwo: func,
    extraActionMobile: func,
    keepLastPage: bool.isRequired,
};

Table.defaultProps = {
    name: "",
    filters: [],
    paginatedData: undefined,
    isFetching: false,
    isDesktop: true,
    headerProps: undefined,
    headerComponent: undefined,
    noMoreDataMessage: undefined,
    withoutItemsMessage: undefined,
    withoutItemsMessageFilter: undefined,
    paginatedDataMapBKP: undefined,
    itemsMapBKP: undefined,
    autoClearWhenUnmounted: true,
    timeOutErrorMessage: "account.statements.error.time.out",
    alternateBackgroundColor: true,
    renderDownloadDocs: undefined,
    handleShowFilter: () => {},
    handleClearFilter: () => {},
    showFilters: true,
    extraActionTwo: undefined,
    extraActionMobile: undefined,
};

const mapStateToProps = (state) => ({
    paginatedData: selectors.getPaginatedData(state),
    keepLastPage: selectors.getKeepLastPage(state),
    isFetching: loadingSelector(state, [GET_PAGINATED_DATA_LIST]),
});

const mapDispatchToProps = (dispatch, { idActivity, idProduct, idFieldName, notificationScopes, name }) => ({
    getPagedDataList: (filters, page, nextIndex) => {
        dispatch(
            actions.getPagedDataList(
                idActivity,
                page,
                nextIndex,
                filters,
                idProduct,
                idFieldName,
                notificationScopes,
                name,
            ),
        );
    },
    clearPagedDataList: () => {
        dispatch(actions.clearPagedDataList());
    },
    resetKeeptLatPage: () => {
        dispatch(actions.setKeepLastPage(false));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
