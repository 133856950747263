/* eslint-disable react/destructuring-assignment */
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import { arrayOf, bool, element, func, oneOfType, string } from "prop-types";
import React, { Component, Fragment } from "react";
import Tooltip from "../Tooltip";

class EditableLabel extends Component {
    static propTypes = {
        hidePlaceholder: bool,
        placeholder: string,
        onSave: func.isRequired,
        value: string.isRequired,
        children: oneOfType([element, arrayOf(element)]).isRequired,
        hideLabel: bool,
    };

    static defaultProps = {
        hidePlaceholder: true,
        placeholder: "",
        hideLabel: false,
    };

    state = {
        isEditing: false,
        value: "",
    };

    handleEdit = () => {
        const { value } = this.props;

        this.setState({ isEditing: true, value });
    };

    handleKeyDown = (event) => {
        const { value } = this.state;
        const { onSave } = this.props;

        if (event.key === "Enter" && onSave) {
            this.setState({ isEditing: false });

            onSave(value);
        } else if (event.key === "Escape") {
            this.setState({ isEditing: false });
        }
    };

    handleOnChange = (event) => {
        const value = event.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
        this.setState({ value });
    };

    handleSave = () => {
        const { value } = this.state;
        const { onSave } = this.props;

        if (onSave) {
            this.setState({ isEditing: false });

            onSave(value);
        }
    };

    handleCancel = () => {
        const { value } = this.props;

        this.setState({
            isEditing: false,
            value,
        });
    };

    render() {
        const { isEditing, value } = this.state;
        const { children, hidePlaceholder, placeholder, hideLabel, value: original } = this.props;

        return (
            (isEditing && (
                <Fragment>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="modifyAlias" className="visually-hidden">
                        <I18n id="products.alias.editingAliasLabel.a11y" />
                    </label>
                    <div className="input-group">
                        <input
                            id="modifyAlias"
                            className="form-control no-shadow-focus"
                            onChange={this.handleOnChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={!hidePlaceholder ? placeholder : ""}
                            value={value}
                            autoComplete="off"
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus={isEditing}
                            maxLength="60"
                        />
                        <p className="ml-3 mr-3 color-text-disabled-color">
                            {this.state.value ? this.state.value.length : 0}
                            {"/60"}
                        </p>
                    </div>
                    <Button
                        bsStyle="primary"
                        className="ml-3"
                        label="global.rename"
                        disabled={!this.state.value || this.state.value.length < 3 || original === this.state.value}
                        onClick={this.handleSave}
                    />
                    <Button bsStyle="outline" className="ml-3 mr-3" label="global.cancel" onClick={this.handleCancel} />
                </Fragment>
            )) || (
                <>
                    <Box className="d-grid mr-6" fullWidth>
                        {children}
                    </Box>
                    <Tooltip
                        onHover
                        forEllipsis
                        text="accounts.detail.editAlias.tooltip"
                        position="top-left"
                        className="height-fit-content pos-relative">
                        <Button
                            className="p-0 mr-6"
                            image="images/icons/icon_edit.svg"
                            label={!hideLabel && "global.edit"}
                            onClick={this.handleEdit}
                        />
                    </Tooltip>
                </>
            )
        );
    }
}

export default EditableLabel;
