import React, { Fragment } from "react";
import Heading from "pages/_components/Heading";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Accordion from "pages/_components/Accordion";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18nUtils from "util/i18n";

const getFeatures = (functionalGroups, child) => {
    const features = [];
    Object.keys(functionalGroups).forEach((feature) => {
        if (child.idItem === feature) {
            features.push({ id: feature, label: child.label });
        }
    });

    return features;
};

export const getAccordionGroups = (groups, functionalGroups) => {
    const accordionList = [];

    if (!groups) {
        return accordionList;
    }
    groups.forEach((group) => {
        const item = { id: group.idItem, label: group.label, categories: [] };
        if (group.childrenList) {
            group.childrenList.forEach((cat) => {
                const category = { id: null, label: null, features: [] };

                if (cat.childrenList && cat.childrenList.length > 0) {
                    category.id = cat.idItem;
                    category.label = cat.label;
                    const featuresCat = [];
                    cat.childrenList.forEach((child) => {
                        const f = getFeatures(functionalGroups, child);
                        if (f.length > 0) {
                            featuresCat.push(...f);
                        }
                    });
                    if (featuresCat && featuresCat.length > 0) {
                        category.features = featuresCat;
                        item.categories.push(category);
                    }
                } else {
                    category.features = getFeatures(functionalGroups, cat);
                    if (category.features && category.features.length > 0) {
                        item.categories.push(category);
                    }
                }
            });
        }
        let hasData = false;
        item.categories.forEach((cat) => {
            hasData = cat.features && cat.features.length > 0;
        });
        if (hasData) {
            accordionList.push(item);
        }
    });
    return accordionList;
};

export const featuresMap = (features) => {
    if (!Array.isArray(features)) {
        return features;
    }
    const map = {};
    if (!features) {
        return map;
    }
    features.forEach((f) => {
        map[f] = ["NONE"];
    });
    return map;
};

export const renderAlias = (alias) => (
    <div>
        <Heading.DataGroup
            containerClassName="data-wrapper data-confirm my-3 data-confirm-head"
            label="administration.signatures.create.alias.label"
            data={alias}
        />
    </div>
);

export const renderFunctionalGroups = (groups, functionalGroups, showTitle = false) => {
    const accordionList = getAccordionGroups(groups, functionalGroups);
    return (
        <Box>
            {showTitle && (
                <Box className="mt-2">
                    <Text size="6" bold labelKey="administration.signatures.functionalGroups.label" />
                </Box>
            )}
            <Box className="my-6">
                <Text size="5" bold labelKey="administration.permissions.title.cashProducts" />
            </Box>
            <Accordion>
                {accordionList.map((item, i) => (
                    <Accordion.Item
                        borderItem="item"
                        key={item.id}
                        number={i}
                        item={
                            <Box className="text-bold" bold>
                                {item.label}
                            </Box>
                        }>
                        <Box>
                            <Box className="mt-3 mb-5">
                                <Text
                                    labelKey="administration.permissions.title.cashServices"
                                    bold
                                    color="text-disabled-color"
                                />
                            </Box>
                            {item.categories.map((category) => (
                                <>
                                    {category.id && category.label && (
                                        <Box key={category.id} className="mb-3 mt-2 color-text-disabled-color">
                                            {category.label}
                                        </Box>
                                    )}
                                    {category.features.map((feature) => (
                                        <Box
                                            key={feature.id}
                                            background="primary-background-color"
                                            className="py-3 px-7 mb-4 color-primary fit-content-width"
                                            border="primary-focus-color"
                                            borderRadius="xxl">
                                            {feature.label}
                                        </Box>
                                    ))}
                                </>
                            ))}
                        </Box>
                    </Accordion.Item>
                ))}
            </Accordion>
        </Box>
    );
};

const signersCount = (administrationScheme, signatureLevelsCounts) =>
    administrationScheme === "medium"
        ? `${signatureLevelsCounts.A}`
        : Object.keys(signatureLevelsCounts)
              .sort()
              .reduce((result, key) => result + key.toString().repeat(signatureLevelsCounts[key]), "");

const renderSchemeReference = (administrationScheme, signatureLevelsCounts) => (
    <div>
        <Heading.DataGroup
            containerClassName="data-wrapper data-confirm"
            label={
                administrationScheme === "medium"
                    ? `administration.signatures.create.${administrationScheme}.confirm.signersCount`
                    : `administration.signatures.create.${administrationScheme}.confirm.schemeReference`
            }
            data={signersCount(administrationScheme, signatureLevelsCounts)}
        />
    </div>
);

const renderTopAmount = (topAmount) => (
    <div>
        <Heading.DataGroup
            containerClassName="data-wrapper data-confirm"
            label="administration.signatures.create.topAmount.label"
            data={
                <Fragment>
                    <FormattedAmount noCurrency quantity={topAmount.amount} />
                </Fragment>
            }
        />
        <Heading.DataGroup
            containerClassName="data-wrapper data-confirm"
            label="administration.signatures.create.frequency.label"
            data={i18nUtils.get(`administration.signatures.transactions.capFrequency.${topAmount.period}`)}
        />
    </div>
);

const renderTransactionType = (administrationScheme, signatureType) => (
    <div>
        <Heading.DataGroup
            containerClassName="data-wrapper data-confirm"
            label={`administration.signatures.create.${administrationScheme}.confirm.transactionType`}
            data={i18nUtils.get(`administration.signatures.create.signatureType.${signatureType}`)}
        />
    </div>
);

export const renderAdditionalData = (
    administrationScheme,
    signatureLevelsCounts,
    signatureType,
    topAmount,
    functionalGroups,
    groups,
) => (
    <>
        {signatureLevelsCounts && renderSchemeReference(administrationScheme, signatureLevelsCounts)}
        {signatureType && renderTransactionType(administrationScheme, signatureType)}
        {topAmount && administrationScheme === "advanced" && signatureType === "AMOUNT" && renderTopAmount(topAmount)}
        {functionalGroups &&
            Object.keys(functionalGroups) &&
            Object.keys(functionalGroups).length > 0 &&
            renderFunctionalGroups(groups, functionalGroups, true)}
    </>
);
