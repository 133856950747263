import React, { Component, Fragment } from "react";
import { bool, string, func } from "prop-types";

import Checkbox from "pages/forms/_components/_fields/_commons/Checkbox";
import formField from "pages/forms/_components/_fields/_commons/formField";
import { Field } from "formik";
import * as utilI18n from "util/i18n";

class Termsandconditions extends Component {
    static propTypes = {
        showAcceptOption: bool.isRequired,
        editing: bool,
        i18n: func.isRequired,
        name: string,
        value: bool,
        setValue: func.isRequired,
        contentCustom: string,
    };

    static defaultProps = {
        value: false,
        editing: false,
        name: "",
        contentCustom: "",
    };

    componentDidMount() {
        const { showAcceptOption, setValue } = this.props;
        if (!showAcceptOption) {
            setValue(true);
        }
    }

    handleChange = () => {
        const { setValue, value } = this.props;
        setValue(!value);
    };

    render() {
        const { editing, i18n, name, value, showAcceptOption, contentCustom } = this.props;
        const content = contentCustom || i18n("termsAndConditions");
        let labelCheckbox = i18n("showAcceptOptionText");
        labelCheckbox =
            !labelCheckbox || labelCheckbox === "null" ? utilI18n.get("showAcceptOptionText") : labelCheckbox;
        if (editing) {
            return (
                <Fragment>
                    <Field
                        component="textarea"
                        type="text"
                        className="input-group-text-area input-group"
                        value={content}
                        aria-label={utilI18n.get("global.termAndConditions")}
                        editing="false"
                        id={name}
                        name={name}
                        onChange={() => {}} // Ignores onChange event of Checkbox
                    />
                    {showAcceptOption && (
                        <Checkbox value={name} label={labelCheckbox} checked={!!value} onChange={this.handleChange} />
                    )}
                </Fragment>
            );
        }
        return <div className="data-desc">{i18n("termsAndConditions")}</div>;
    }
}

export default formField({
    formClass: "form-group--termsAndConditions",
})(Termsandconditions);
