import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import * as deviceUtils from "util/device";
import { isEmpty } from "lodash";

export const MODAL_TEXT_ACTIVATE = "activate";

const Settings = ({ 
    isMobile, 
    userFullName, 
    dispatch, 
    isDeviceWithFingerprint,
    fingerprintConfiguredUserDevices,
    availability,
    deviceHasFingerPrinter,
}) => {
    const [orderedOptions, setOrderedOptions] = useState([]);
    const [colsInRow, setColsInRow] = useState(null);
    const [showDeleteFingerprintModal, setShowDeleteFingerprintModal] = useState(false);
    const [showFingerDisclaimerModal, setShowFingerDisclaimerModal] = useState(false);
    const [fingerprintAction, setFingerprintAction] = useState("");

    useEffect(() => {
        dispatch(fingerprintActions.fingerprintConfigurationPre());
        dispatch(fingerprintActions.fingerprintAvailability());
    }, []);

    useEffect(() => {
        buildMenu();
    }, [isDeviceWithFingerprint]);

    useEffect(() => {
        if (colsInRow === null) {
            buildMenu();
        }
    }, [isMobile, colsInRow]);

    const handleClickLink = (url) => {
        dispatch(push(url));
    };

    const getItemButton = () => {
        let fingerprintActionButton = {};
        if (fingerprintConfiguredUserDevices && fingerprintConfiguredUserDevices.length) {
            fingerprintActionButton = {
                buttonStyle: "text",
                buttonText: i18n.get("", "Eliminar"),
                buttonIcon: "images/icons/trash.svg",
                buttonAction: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowDeleteFingerprintModal(true);
                },
            };
        }else{
            fingerprintActionButton = {
                buttonStyle: "text",
                buttonText: i18n.get("", "Activar"),
                buttonIcon: "images/icons/activate.svg",
                buttonAction: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    activateFingerprintDevice();
                },
            };
        }
        return fingerprintActionButton;
    }

    const buildMenu = () => {
        const newColInRow = isMobile ? 1 : 3;
        const newOptions = [];

        newOptions.push({
            icon: "images/icons/icn-password.svg",
            title: i18n.get("", "Contraseña"),
            description: i18n.get(
                "",
                "Actualice su contraseña de ingreso a la Banca Empresa de Banco Bolivariano.",
            ),
            link: "/settings/changePassword",
        });

        newOptions.push({
            icon: "images/icons/icn-avatar.svg",
            title: i18n.get("", "Avatar"),
            description: i18n.get(
                "",
                "Cambie la imagen de seguridad que lo identifica al acceder a la Banca Digital de Empresas.",
            ),
            link: "/settings/changeAvatar",
        });

        // TODO: MVP2
        // newOptions.push({
        //     icon: ",
        //     title: i18n.get("", "Mis equipos"),
        //     description: i18n.get("", ""),
        //     link: "/settings/myDevices",
        // });

        newOptions.push({
            icon: "images/icons/icn-mybusiness.svg",
            title: i18n.get("", "Mis empresas"),
            description: i18n.get("", "Actualice la empresa a visualizar en la posición consolidada."),
            link: "/settings/changeDefaultEnvironment",
        });

        newOptions.push({
            icon: "images/icons/icn-accessmethod.svg",
            title: i18n.get("", "Método de acceso"),
            description: i18n.get("", "Elimine el uso de identificación biométrica de acceso a Banca Empresas."),
            link: "",
            ...(getItemButton()),
        });

        const newOrderedOptions = newOptions.reduce(
            (allOrdOpts, opt, i) => {
                const newAllOrdOpts = [...allOrdOpts];

                // Agrupar de a 3 elementos por fila
                if (newAllOrdOpts[newAllOrdOpts.length - 1].length === newColInRow) {
                    newAllOrdOpts.push([]);
                }
                const lastI = newAllOrdOpts.length - 1;
                newAllOrdOpts[lastI].push(opt);

                // En ultima iteración agregar elementos vacios en la ultima fila
                // para mantener mismo tamaño de elemento
                if (newOptions.length - 1 === i) {
                    while (newAllOrdOpts[lastI].length !== newColInRow) {
                        newAllOrdOpts[lastI].push({});
                    }
                }

                return newAllOrdOpts;
            },
            [[]],
        );

        setColsInRow(newColInRow);
        setOrderedOptions(newOrderedOptions);
    };

    const isBiometricAvailable = () => {
        return (
            deviceUtils.isMobileNativeFunc() &&
            !isDeviceWithFingerprint &&
            availability &&
            availability.isHardwareDetected &&
            !isDeviceIdInSession()
        );
    };

    const isDeviceIdInSession = () => {
        if (deviceUtils.isMobileNativeFunc() && fingerprintConfiguredUserDevices) {
            const device = fingerprintConfiguredUserDevices.filter(
                (item) => item.deviceId.search(deviceUtils.getDeviceId()) !== -1,
            );
            return !isEmpty(device);
        }
        return false;
    };

    const configureNow = () => {
        dispatch(fingerprintActions.fingerprintVerification());
    };

    const enrollFingerprintOnDevice = () => {
        dispatch(fingerprintActions.enrollFingerprintOnDevice());
    };

    const onClickFingerActionHandler = (fingerprintAction) => {
        setShowFingerDisclaimerModal(true);
        setFingerprintAction(fingerprintAction);
    };

    const activateFingerprintDevice = () => {
        if (deviceUtils.isMobileNativeFunc()) {
            if (isBiometricAvailable()) {
                if (availability.hasEnrolledFingerprints && availability.isAvailable) {
                    configureNow();
                }else{
                    enrollFingerprintOnDevice();
                }
            }
            return;
        }
        onClickFingerActionHandler(MODAL_TEXT_ACTIVATE);
    };
    
    const removeFingerprintDevices = () => {
        console.log("Remove all user devices: ", fingerprintConfiguredUserDevices);
        dispatch(fingerprintActions.fingerprintDeleteAllDevices());
        hideDeleteFingerprintModal();
    }

    const hideDeleteFingerprintModal = () => {
        setShowDeleteFingerprintModal(false);
        // /settings/fingerprintConfiguration
    }

    return (
        <>
            <Notification scopeToShow="settings" />
            <Head titleText={i18n.get("", "Administración de perfil")} backLinkTo="/desktop" />
            <MainContainer
                className="main-container border-radius-lg p-7 mt-3 box-shadow-small background-white"
                showLoader={false}>
                <div className="above-the-fold">
                    <Box fullWidth display="flex" className="px-7 py-7 mb-7 border-radius-lg box-shadow-small">
                        <Box fullWidth>
                            <Box>
                                <Text component="h2" defaultValue={userFullName} bold />
                            </Box>
                            <Box>
                                <Text
                                    defaultValue={i18n.get(
                                        "",
                                        "Este es su perfil. Administre su información y métodos de acceso.",
                                    )}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Image src="images/icons/settings_header.svg" color="transparent" />
                        </Box>
                    </Box>
                    {orderedOptions.map((rowOptions) => (
                        <Box fullWidth display="flex" key={rowOptions[0]?.title}>
                            {rowOptions.map((option, i) => (
                                <Box
                                    flex1
                                    key={option.title || `empty-${i}`}
                                    className={classNames("px-7 py-7 mb-7 border-radius-lg", {
                                        "mr-7": !!((i + 1) % colsInRow),
                                        "box-shadow-small": !!option.title,
                                        "cursor-pointer": !!option.title,
                                    })}
                                    onClick={() => (option.link ? handleClickLink(option.link) : {})}>
                                    <Box display="flex" alignX="between">
                                        {option.icon && (
                                            <Box fitWidth className="mb-2">
                                                <Image src={option.icon} color="transparent" />
                                            </Box>
                                        )}
                                        {option.buttonAction && (
                                            <Button
                                                bsStyle={option.buttonStyle}
                                                image={option.buttonIcon}
                                                defaultLabelText={option.buttonText}
                                                onClick={option.buttonAction}
                                                className="mb-2"
                                            />
                                        )}
                                    </Box>
                                    {option.title && (
                                        <Box>
                                            <Text component="h4" bold defaultValue={option.title} />
                                        </Box>
                                    )}
                                    {option.description && (
                                        <Box>
                                            <Text defaultValue={option.description} />
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    ))}
                </div>
            </MainContainer>
            <TextAndButtonsModal
                modalShow={showDeleteFingerprintModal}
                acceptFunction={() => {
                    removeFingerprintDevices();
                }}
                cancelFunction={() => {
                    hideDeleteFingerprintModal();
                }}
                confirmLabel="fingerprint.deactivate.button.accept"
                headingText={i18n.get("fingerprint.deactivate.title")}
                text={i18n.get("fingerprint.deactivate.text")}
                modalId="widgets.update.data.modal"
            />
            <TextAndButtonsModal
                modalShow={showFingerDisclaimerModal}
                acceptFunction={() => {
                    setShowFingerDisclaimerModal(false);
                }}
                confirmLabel="global.exit"
                headingText={i18n.get(`fingerprint.disclaimer.${fingerprintAction.toLowerCase()}.title`)}
                text={`${i18n.get(`fingerprint.disclaimer.${fingerprintAction.toLowerCase()}.text`)} ${i18n.get(
                    `global.disclaimer.note`,
                )}`}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
    availability: fingerprintSelectors.getAvailability(state),
    isDeviceWithFingerprint: fingerprintSelectors.getIsDeviceWithFingerprint(state),
    fingerprintConfiguredUserDevices: fingerprintSelectors.getFingerprintConfiguredUserDevices(state),
    deviceHasFingerPrinter: fingerprintSelectors.deviceHasFingerPrinter(state),
});

Settings.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    userFullName: string,
    isDeviceWithFingerprint: bool,
    fingerprintConfiguredUserDevices: arrayOf(
        shape({
            device: shape({
                deviceId: string.isRequired,
                deviceModel: string.isRequired,
                idSession: string.isRequired,
            }),
        }),
    ), 
    availability: shape({
        isHardwareDetected: bool,
        isAvailable: bool,
    }),
    deviceHasFingerPrinter: bool.isRequired,
};

Settings.defaultProps = {
    userFullName: null,
    isDeviceWithFingerprint: false,
    fingerprintConfiguredUserDevices: null,
    isDeviceWithFingerprint: false,
    availability: null
};

export default connect(mapStateToProps)(resizableRoute(Settings));
