import { getDeviceVersionPlatform, isAndroidPlatform, isIOSPlatform, isMobileNativeFunc } from "util/device";

const validatePostNotificationAndroid = () =>
    new Promise((resolve) => {
        const permissions = window?.cordova?.plugins?.permissions;
        if (!permissions) {
            resolve(false);
            return;
        }

        permissions.hasPermission(permissions.POST_NOTIFICATIONS, (status) => {
            if (!status.hasPermission) {
                permissions.requestPermission(
                    permissions.POST_NOTIFICATIONS,
                    () => {
                        // eslint-disable-next-line no-console
                        console.log("Push post android permission is allowed");
                        resolve(true);
                    },
                    () => {
                        // eslint-disable-next-line no-console
                        console.log("Push post android permission is denied");
                        resolve(true);
                    },
                );
            }
        });
    });

export const validatePermissionPushIOS = () =>
    new Promise((resolve) => {
        if (!window.cordova.plugins.firebase.messaging.requestPermission) {
            resolve(false);
        } else {
            window.cordova.plugins.firebase.messaging.requestPermission({ forceShow: false }).then(
                () => {
                    // eslint-disable-next-line no-console
                    console.log("Push permission is allowed");
                    resolve(true);
                },
                (e) => {
                    // eslint-disable-next-line no-console
                    console.log("Push permission is denied", e);
                    resolve(false);
                },
            );
        }
    });

const validatePermissionsIOS = async () => {
    const responsePermission = await Promise.all([validatePermissionPushIOS()]);
    // eslint-disable-next-line no-console
    console.log("Response permission", responsePermission);
};

const validatePermissionsAndroid = async () => {
    const googlePlayData = await window.app.getAppPlayServices();
    if (
        googlePlayData?.code &&
        googlePlayData.code === "0000" &&
        googlePlayData?.data &&
        googlePlayData.data === "HMS"
    ) {
        return;
    }

    const androidVersion = getDeviceVersionPlatform();
    if (!androidVersion) {
        return;
    }

    if (parseInt(androidVersion, 10) >= 13) {
        const responsePostNotification = await validatePostNotificationAndroid();
        // eslint-disable-next-line no-console
        console.log(`Post notification permission: ${responsePostNotification || false}`);
    }
};

export const validatePermissionMobile = async () => {
    try {
        if (!isMobileNativeFunc()) {
            return;
        }

        if (isIOSPlatform()) {
            validatePermissionsIOS();
            return;
        }

        if (isAndroidPlatform()) {
            validatePermissionsAndroid();
        }

        // eslint-disable-next-line no-empty
    } catch (e) {}
};

export const ID_ALL_PRODUCT_CREDITS = "037c202f412545bba404cbc58455c5a3379da69bb6b142cd946ae6722ab98e5f";
export const ID_ALL_PRODUCT_INVESTMENTS = "38befef8ad704eaeaa4467d46ebf009ac65bbfc6d7bd49c9a7af242f88472a40";
