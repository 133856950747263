import classNames from "classnames";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import WidgetLoading from "pages/_components/WidgetLoading";
import { bool, func, node, shape } from "prop-types";
import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { Chart } from "react-google-charts";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actions, selectors } from "reducers/widgets";
import * as config from "util/config";
import * as i18nUtils from "util/i18n";
import WidgetHeader from "./_components/WidgetHeader";

class Portfolio extends Component {
    chartEvents = [
        {
            eventName: "ready",
            callback: () => {
                const { isChartLoading } = this.state;
                if (isChartLoading) {
                    this.setState({ isChartLoading: false });
                }
            },
        },
    ];

    static propTypes = {
        data: shape({}),
        dispatch: func.isRequired,
        closeButton: node,
        isDesktop: bool,
        isFetching: bool,
        isEditable: bool,
        draggableItemProps: shape({}).isRequired,
    };

    static defaultProps = {
        data: {},
        closeButton: null,
        isDesktop: true,
        isFetching: false,
        isEditable: false,
    };

    state = {
        isChartLoading: true,
        showItems: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(actions.listRequest("portfolio"));
    }

    renderChartLinks = (props = {}) => {
        const { destination, key, empty, quantity, currency, amount } = props;

        return (
            <>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <Link
                        disabled={empty}
                        tabIndex={empty ? -1 : null}
                        to={`/${destination}`}
                        className={classNames(`item ${key} portfolio-item`, {
                            empty,
                        })}>
                        <div className="flex-container_portfolio-Items">
                            <div className="data-wrapper">
                                <span className="data-title">{i18nUtils.get(`desktop.widgets.portfolio.${key}`)}</span>
                                <span className="data-aux">
                                    {i18nUtils.get("desktop.widgets.portfolio.products", null, {
                                        quantity,
                                    })}
                                </span>
                            </div>
                            <div className="data-wrapper">
                                <FormattedAmount quantity={amount} currency={currency} />
                            </div>
                        </div>
                    </Link>
                </div>
            </>
        );
    };

    render() {
        const { closeButton, data = {}, draggableItemProps, isFetching, isEditable, isDesktop } = this.props;

        const {
            fixedIncomesAmount,
            fixedIncomesQuantity,
            liquidityAmount,
            liquidityQuantity,
            othersAmount,
            othersQuantity,
            variableIncomesAmount,
            variableIncomesQuantity,
        } = data;
        const { isChartLoading, showItems } = this.state;

        const totalQuantity = fixedIncomesQuantity + liquidityQuantity + othersQuantity + variableIncomesQuantity;

        const totalAmount = fixedIncomesAmount + liquidityAmount + othersAmount + variableIncomesAmount;

        const masterCurrency = config.get("core.masterCurrency");

        const chartData =
            totalAmount === 0.0 || totalQuantity === 0
                ? [
                      ["Assets", "Amount"],
                      ["Total", 1],
                  ]
                : [
                      ["Assets", "Amount"],
                      [i18nUtils.get("desktop.widgets.portfolio.liquidity"), liquidityAmount],
                      [i18nUtils.get("desktop.widgets.portfolio.fixedIncomes"), fixedIncomesAmount],
                      [i18nUtils.get("desktop.widgets.portfolio.variableIncomes"), variableIncomesAmount],
                      [i18nUtils.get("desktop.widgets.portfolio.others"), othersAmount],
                  ];

        const chartOptions = {
            backgroundColor: "transparent",
            legend: "none",
            pieHole: 0.975,
            pieSliceText: "none",
            tooltip: {
                trigger: "none",
            },
            chartArea: {
                left: "15",
                right: "15",
                top: "15",
                bottom: "15",
                width: "100%",
                height: "100%",
            },
            slices:
                totalAmount === 0.0 || totalQuantity === 0
                    ? {
                          0: { color: "#d7d9dd" },
                      }
                    : {
                          0: { color: "#005CD5" },
                          1: { color: "#F2B705" },
                          2: { color: "#BDD4F2" },
                          3: { color: "#D91604" },
                      },
        };

        // TODO: export these colors from scss file directly
        const uiAutomationProp = { "data-ui-automation": "widgetPortfolio" };
        const chartLinks = [
            {
                destination: "accounts",
                key: "liquidity",
                empty: liquidityAmount === 0.0 || liquidityQuantity === 0,
                quantity: liquidityQuantity,
                currency: masterCurrency,
                amount: liquidityAmount,
            },
            {
                destination: "desktop",
                key: "fixedIncomes",
                empty: fixedIncomesAmount === 0.0 || fixedIncomesQuantity === 0,
                quantity: fixedIncomesQuantity,
                currency: masterCurrency,
                amount: fixedIncomesAmount,
            },
            {
                destination: "desktop",
                key: "variableIncomes",
                empty: variableIncomesAmount === 0.0 || variableIncomesQuantity === 0,
                quantity: variableIncomesQuantity,
                currency: masterCurrency,
                amount: variableIncomesAmount,
            },
            {
                destination: "desktop",
                key: "others",
                empty: othersAmount === 0.0 || othersQuantity === 0,
                quantity: othersQuantity,
                currency: masterCurrency,
                amount: othersAmount,
            },
        ];

        return (
            <div
                role="button"
                className="widget"
                {...uiAutomationProp}
                {...draggableItemProps}
                aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                <WidgetHeader title={i18nUtils.get("desktop.widgets.portfolio.title")} action={closeButton} />

                <WidgetLoading loading={(isChartLoading && isFetching) || isChartLoading}>
                    {Object.keys(data).length !== 0 && (
                        <article className="widget--portfolio" aria-hidden={isEditable}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 chart">
                                    <div className="row">
                                        {!isChartLoading && (
                                            <div className="col-md-12 col-sm-12 col-12 data-chart text-center">
                                                <span>{i18nUtils.get("desktop.widgets.portfolio.text")}</span>
                                                <p className="amount">
                                                    <FormattedAmount
                                                        quantity={totalAmount}
                                                        currency={masterCurrency}
                                                        showAbbreviature
                                                    />
                                                </p>
                                                <p className="visually-hidden">
                                                    {i18nUtils.get("desktop.widgets.portfolio.message.balance")}
                                                </p>
                                                <p className="products">
                                                    {i18nUtils.get("desktop.widgets.portfolio.products", null, {
                                                        quantity: totalQuantity,
                                                    })}
                                                </p>
                                                <a className="visually-hidden" href="#chartReference">
                                                    {i18nUtils.get("desktop.widgets.portfolio.goChartReference")}
                                                </a>
                                            </div>
                                        )}
                                        <Chart
                                            height="300px"
                                            width="400px"
                                            chartEvents={this.chartEvents}
                                            chartType="PieChart"
                                            data={chartData}
                                            options={chartOptions}
                                            graph_id="DonutChart"
                                            loader={<div />}
                                        />
                                    </div>
                                </div>
                                {!isChartLoading && (
                                    <>
                                        <ul id="chartReference" aria-describedby="chartReferenceDescription">
                                            <Row>
                                                {chartLinks.map((chartLink) => {
                                                    if (!isDesktop && !showItems) {
                                                        return chartLink.amount > 0 && this.renderChartLinks(chartLink);
                                                    }
                                                    return this.renderChartLinks(chartLink);
                                                })}
                                            </Row>
                                        </ul>
                                        {!isDesktop && !showItems && (
                                            <Button
                                                bsStyle="link"
                                                onClick={() => this.setState({ showItems: !showItems })}
                                                image="images/show.svg"
                                                defaultLabelText={i18nUtils.get("desktop.widgets.portfolio.seeMore")}
                                                className="btn-small"
                                            />
                                        )}
                                        <span id="chartReferenceDescription" className="visually-hidden">
                                            {i18nUtils.get("desktop.widgets.portfolio.chartReferenceDescription")}
                                        </span>
                                    </>
                                )}
                            </div>
                        </article>
                    )}
                </WidgetLoading>

                <div className="overlay" />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...selectors.getWidget(state, "portfolio"),
});

export default connect(mapStateToProps)(Portfolio);
