import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { isEmpty } from "lodash";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import RadioButton from "pages/_components/fields/RadioBox";
import Stepper from "pages/_layouts/DefaultStepperLayout/Stepper";
import BiometricModal from "pages/login/_components/BiometricModal";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";
import { actions as softTokenActions } from "reducers/softToken";
import * as deviceUtils from "util/device";
import * as i18n from "util/i18n";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import * as Yup from "yup";

const FORM_ID = "softToken.activate.securityMethod";
const STEP_ID = 2;
const SoftTokenSecurityMethod = (props) => {
    const { steps, isDesktop, dispatch, handleSubmit } = props;

    const securityMethodList = [
        {
            id: "FINGERPRINT",
            label: "Biometría",
            description: "Utilice su huella dactilar o FaceID para validar sus transacciones en la plataforma",
        },
        {
            id: "PIN",
            label: "PIN",
            description: "Utilice un código personal para validar sus transacciones en la plataforma",
        },
    ];
    const [selectedOption, setSelectedOption] = useState(null);
    const [showSideBarBiometricModal, setShowSideBarBiometricModal] = useState(false);

    useEffect(() => {
        dispatch(fingerprintActions.fingerprintConfigurationPre());
        dispatch(fingerprintActions.fingerprintAvailability());
    }, []);

    useEffect(() => {
        if (selectedOption === null) {
            setSelectedOption(securityMethodList[0].id);
        }
    }, [selectedOption]);

    const handleBack = () => {
        dispatch(softTokenActions.setActivationStep(steps.filter((x) => x.stepNumber === STEP_ID-1).id));
        // dispatch(routerActions.goBack());
        dispatch(
            routerActions.push({
                pathname: "/activateSoftToken",
            }),
        );
    };

    const renderHeader = () => (
        <>
            <Notification scopeToShow="softTokenTermsAndConditions" />
            <Head onBack={handleBack} title="settings.softToken" textBack="softToken" />
        </>
    );

    const handleChange = (form, value) => {
        // const { value } = event.target;
        setSelectedOption(value);
        form.setFieldValue("securityMethod", value);
    };

    const isDeviceIdInSession = () => {
        const { fingerprintConfiguredUserDevices } = props;
        if (deviceUtils.isMobileNativeFunc() && fingerprintConfiguredUserDevices) {
            const device = fingerprintConfiguredUserDevices.filter(
                (item) => item.deviceId.search(deviceUtils.getDeviceId()) !== -1,
            );
            return !isEmpty(device);
        }
        return false;
    };

    const isBiometricAvailable = () => {
        const { isDeviceWithFingerprint, availability } = props;
        return (
            deviceUtils.isMobileNativeFunc() &&
            !isDeviceWithFingerprint &&
            availability &&
            availability.isHardwareDetected &&
            !isDeviceIdInSession()
        );
    };

    const handleSubmitForm = ({ securityMethod }, formikBag) => {
        if (formikBag) {
            formikBag.setSubmitting(false);
        }

        if (securityMethod === "PIN") {
            // dispatch(softTokenActions.setActivationStep("securityMethod"));
            dispatch(
                routerActions.push({
                    pathname: "/createTokenPinForm",
                }),
            );

            /* if (handleSubmit) {
                handleSubmit(securityMethod, formikBag);
            } */
        } else if (securityMethod === "FINGERPRINT") {
            setShowSideBarBiometricModal(true);

            if (!isBiometricAvailable()) {
                dispatch(fingerprintActions.fingerPrintConfigurationModalHide());
                setShowSideBarBiometricModal(false);
                if (handleSubmit) {
                    handleSubmit(securityMethod, formikBag);
                }
            } else {
                dispatch(fingerprintActions.fingerPrintConfigurationModalShow());
                // activateFingerprintDevice();
            }
        }
    };

    const initialValues = {
        securityMethod: selectedOption || "",
    };

    const validationSchema = Yup.object().shape({
        securityMethod: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
    });

    const renderField = (securityMethod, selectedOption) => (
        <Field name="securityMethod" key={securityMethod.id} idForm={FORM_ID} formGroup>
            {({ form, field }) => {
                const value = field.value || "";
                return (
                    <Box
                        fitWidth
                        className="mb-5 security-radiobutton"
                        key={securityMethod.id}
                        onClick={() => handleChange(form, securityMethod.id)}>
                        {!!securityMethod.id && (
                            <RadioButton
                                name={securityMethod.id}
                                id={securityMethod.id}
                                key={securityMethod.id}
                                value={value}
                                checked={securityMethod.id === selectedOption}
                                label={securityMethod.label}
                                description={securityMethod.description}
                                onChange={() => handleChange(form, securityMethod.id)}
                            />
                        )}
                    </Box>
                );
            }}
        </Field>
    );

    const renderForm = (formik) => (
        <Form className="display-flex flex-column justify-content-between full-height">
            <Box
                background="white"
                borderRadius="lg"
                className={classNames("mt-3 ", {
                    "p-7 box-shadow-small": isDesktop,
                    "pt-6": !isDesktop,
                })}>
                <Box>
                    <Stepper className="mb-6" stepsList={steps} currentStep={STEP_ID} completed={1} showLabelMobile />

                    <Box>
                        <Text
                            className={classNames({
                                "line-height-125": isDesktop,
                                "line-height-15": !isDesktop,
                            })}
                            labelKey="softToken.activate.securityMethod.text"
                            size={isDesktop ? "2" : "1"}
                            bold
                        />

                        {securityMethodList.map((securityMethod) => renderField(securityMethod, selectedOption))}
                    </Box>
                </Box>
            </Box>
            <Row
                gapX="7"
                className={classNames("mt-7 mb-7", {
                    "d-flex flex-column-reverse": !isDesktop,
                })}>
                <Col xs={3} xsOffset={3}>
                    <Button type="button" bsStyle="outline" label="global.cancel" block onClick={handleBack} />
                </Col>
                <Col xs={3}>
                    <Button
                        type="submit"
                        bsStyle="primary"
                        label="global.continue"
                        btnUppercase={false}
                        // disabled={validateFields(values)}
                        // loading={formik.isSubmitting}
                        block
                    />
                </Col>
            </Row>

            {showSideBarBiometricModal && <BiometricModal />}
        </Form>
    );
    return (
        <>
            {renderHeader()}
            <MainContainer
                className={classNames("main-container", {
                    "background-white": !isDesktop,
                })}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
                    validationSchema={validationSchema}>
                    {renderForm}
                </Formik>
            </MainContainer>
        </>
    );
};

SoftTokenSecurityMethod.propTypes = {
    dispatch: func.isRequired,
    handleSubmit: func.isRequired,
    isDesktop: bool.isRequired,
    steps: arrayOf(shape({})).isRequired,
};

const mapStateToProps = (state) => ({
    availability: fingerprintSelectors.getAvailability(state),
    isDeviceWithFingerprint: fingerprintSelectors.getIsDeviceWithFingerprint(state),
    fingerprintConfiguredUserDevices: fingerprintSelectors.getFingerprintConfiguredUserDevices(state),
});

export default connect(mapStateToProps)(SoftTokenSecurityMethod);
