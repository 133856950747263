import classNames from "classnames";
import { node, string } from "prop-types";
import React from "react";

const GridTableFooter = ({ children, className, ...props }) => (
    <tfoot className={classNames("grid-table-footer", className)} {...props}>
        {children}
    </tfoot>
);

GridTableFooter.propTypes = {
    children: node.isRequired,
    className: string,
};

GridTableFooter.defaultProps = {
    className: null,
};

export default GridTableFooter;
