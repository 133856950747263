/* eslint-disable no-nested-ternary */
import { resizableRoute } from "pages/_components/Resizable";

import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { node, oneOfType, string } from "prop-types";
import React, { useState } from "react";

const PopOver = ({ content, children, className, image, position, ariaLabel }) => {
    const [show, setShow] = useState(false);

    return (
        <Button
            ariaLabel={ariaLabel}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShow(!show);
            }}
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    setShow(false);
                }
            }}
            onBlur={() => setShow(false)}
            className={classNames("popover-trigger-container", className, {
                "z-index-win": show,
            })}>
            {children}
            {show ? (
                <Box show={show} className={`popover-text-container popover-${position}`}>
                    <Button
                        onClick={() => setShow(false)}
                        image="images/lucideIcons/x.svg"
                        className="cross-close m-3"
                        bsStyle="only-icon"
                    />
                    <Image src={image} />
                    <div className="popover-content">{content}</div>
                </Box>
            ) : null}
        </Button>
    );
};

PopOver.propTypes = {
    ariaLabel: string,
    children: node,
    content: oneOfType([node, string]),
    className: string,
    image: string,
    position: string,
};

PopOver.defaultProps = {
    ariaLabel: "PopOver",
    children: null,
    content: null,
    className: null,
    image: "",
    position: "bottom-centered",
};

export default resizableRoute(PopOver);
