import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Notification from "pages/_components/Notification";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import RegisterQuestionsField from "pages/_components/fields/RegisterQuestionsField";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import classNames from "classnames";
import ExitModal from "pages/_components/modal/ExitModal";

const FORM_ID = "securityQuestions.register";

const RegisterQuestions = ({ data, isDesktop, action, allQuestions, maxQuestions, dispatch, handleSubmit }) => {
    const [showExitModal, setShowExitModal] = useState(false);

    const handleBack = () => {
        dispatch(routerActions.push("/securityQuestions"));
    };

    const handleAcceptModal = () => {
        handleBack();
        setShowExitModal(false);
    };

    const handleHideModal = () => {
        setShowExitModal(false);
    };

    const handlePreBack = () => {
        setShowExitModal(true);
    };

    const renderHeader = () => (
        <>
            <Notification scopeToShow="registerQuestions" />
            <Head
                onBack={handlePreBack}
                title={`securityQuestions.action.${action}.title`}
                textBack="securityQuestions.returnToList"
            />
        </>
    );

    const handleSubmitForm = ({ questions }, formikBag) => {
        const qRequired = i18n.get("securityQuestions.validate.question.required");
        const aRequired = i18n.get("securityQuestions.validate.answer.required");
        const invalid = i18n.get("securityQuestions.validate.answer.invalid");
        const duplicated = i18n.get("securityQuestions.validate.answer.duplicated.invalid");
        let i = 0;
        const answers = [];
        let hasErrors = false;
        while (++i <= maxQuestions) {
            if (!questions[i]) {
                formikBag.setFieldError(`${i}.question`, qRequired);
                formikBag.setFieldError(`${i}.answer`, aRequired);
                hasErrors = true;
                continue;
            }
            if (questions[i].question === undefined) {
                formikBag.setFieldError(`${i}.question`, qRequired);
                hasErrors = true;
            }
            if (questions[i].answer === undefined) {
                formikBag.setFieldError(`${i}.answer`, aRequired);
                hasErrors = true;
            } else if (questions[i].answer.length < 2) {
                formikBag.setFieldError(`${i}.answer`, invalid);
                hasErrors = true;
            } else if (answers.includes(questions[i].answer.toUpperCase())) {
                formikBag.setFieldError(`${i}.answer`, duplicated);
                hasErrors = true;
            } else {
                answers.push(questions[i].answer.toUpperCase());
            }
        }

        if (!hasErrors) {
            handleSubmit(questions, formikBag);
        }
    };

    const validateFields = ({ values }) => {
        const { questions } = values;
        let i = 0;
        while (++i <= maxQuestions) {
            if (!questions[i] || questions[i].question === undefined || questions[i].answer === undefined) {
                return true;
            }
        }
        return false;
    };

    const validationSchema = Yup.object().shape({
        questions: Yup.object().required(""),
    });

    const initialValues = {
        questions: data || {},
    };

    return (
        <>
            {renderHeader()}
            <MainContainer
                showLoader={!allQuestions || allQuestions.length === 0}
                className={classNames("main-container", {
                    "texture-header box-shadow-small background-white": !isDesktop,
                })}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    validationSchema={validationSchema}>
                    {(formik) => (
                        <Form>
                            <Box
                                borderRadius="lg"
                                background="white"
                                className={classNames(" mt-3 pt-4", {
                                    "texture-header box-shadow-small": isDesktop,
                                })}>
                                <Box
                                    borderRadius="lg"
                                    background="white"
                                    className={classNames("security-questions", {
                                        "p-7": isDesktop,
                                    })}>
                                    <Box
                                        display="flex"
                                        fullWidth
                                        className={classNames({
                                            "px-7 py-6 mb-7": isDesktop,
                                            "py-5": !isDesktop,
                                        })}>
                                        <Box className="width-icn-security">
                                            <Image src="images/icons/icn-security.svg" color="transparent" />
                                        </Box>
                                        <Box
                                            className={classNames({
                                                "ml-4": isDesktop,
                                                "ml-3": !isDesktop,
                                            })}>
                                            <Box>
                                                <Text
                                                    className={classNames({
                                                        "line-height-125": isDesktop,
                                                        "line-height-15": !isDesktop,
                                                    })}
                                                    labelKey={`securityQuestions.action.${action}.subTitle`}
                                                    size={isDesktop ? "3" : "2"}
                                                    bold
                                                />
                                            </Box>
                                            <Box className="mt-2">
                                                <Text
                                                    className={classNames({
                                                        "line-height-125": isDesktop,
                                                        "line-height-15": !isDesktop,
                                                    })}
                                                    labelKey="securityQuestions.register.info"
                                                    size={isDesktop ? "6" : "5"}
                                                    color="text-grey-color"
                                                    light
                                                />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box
                                        display="flex"
                                        borderRadius="lg"
                                        background="info"
                                        border="info-border-color"
                                        className={classNames({
                                            "py-4 px-6": isDesktop,
                                            "py-4 px-5 mb-2": !isDesktop,
                                        })}>
                                        <Box className="width-info-icon mt-2">
                                            <Image src="images/icons/info.svg" />
                                        </Box>
                                        <Box className="ml-4">
                                            <Text
                                                labelKey="securityQuestions.help.info"
                                                size="6"
                                                color="heading"
                                                light
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="mb-4">
                                        <Field
                                            component={RegisterQuestionsField}
                                            hideLabel={false}
                                            idForm={FORM_ID}
                                            name="questions"
                                            maxQuestions={maxQuestions}
                                            questions={allQuestions}
                                            isDesktop={isDesktop}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Row
                                gapX="7"
                                className={classNames("mt-7 mb-7", {
                                    "d-flex flex-column-reverse": !isDesktop,
                                })}>
                                <Col xs={3} xsOffset={3}>
                                    <Button
                                        bsStyle="outline"
                                        label="global.cancel"
                                        btnUppercase={false}
                                        block
                                        type="button"
                                        onClick={handlePreBack}
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Button
                                        bsStyle="primary"
                                        label="global.save"
                                        btnUppercase={false}
                                        disabled={validateFields(formik)}
                                        block
                                        type="submit"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
            <ExitModal
                modalShow={showExitModal}
                acceptFunction={handleAcceptModal}
                cancelFunction={handleHideModal}
                headingText={i18n.get("confirm.exit.title")}
                text={i18n.get("confirm.exit.info")}
            />
        </>
    );
};

RegisterQuestions.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    handleSubmit: func.isRequired,
    action: string,
    data: shape({}),
    allQuestions: arrayOf(
        shape({
            label: string.isRequired,
            value: string.isRequired,
        }),
    ).isRequired,
    maxQuestions: number,
};

RegisterQuestions.defaultProps = {
    action: "register",
    data: {},
    maxQuestions: 5,
};

export default connect()(RegisterQuestions);
