import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import RadioButton from "pages/_components/fields/Radio";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import SecuritySealImage from "pages/login/_components/SecuritySealImage";
import { bool, func, number, oneOfType, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import * as i18n from "util/i18n";

const ChangeAvatar = ({ isMobile, securitySeals, currentSecuritySeal, fetching, dispatch }) => {
    const [orderedSecSeals, setOrderedSecSeals] = useState([]);
    const [colsInRow, setColsInRow] = useState(null);
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [showSettingsExitModal, setShowSettingsExitModal] = useState(false);

    useEffect(() => {
        dispatch(settingsActions.changeSecuritySealPre());
    }, []);

    useEffect(() => {
        const securitySealsArray = Object.entries(securitySeals || {})?.filter(
            (secSeal) => secSeal?.[0] !== `${currentSecuritySeal}`,
        );

        if (!orderedSecSeals?.length && currentSecuritySeal && securitySealsArray?.[0]?.[1]) {
            const newColInRow = isMobile ? 2 : 5;

            // Order in Grid
            const newOrderedSecSeals = securitySealsArray.reduce(
                (allOrdSecSeals, [secSealId, secSealImage], i) => {
                    const newAllOrdSecSeals = [...allOrdSecSeals];

                    // Agrupar de a 3 elementos por fila
                    if (newAllOrdSecSeals[newAllOrdSecSeals.length - 1].length === newColInRow) {
                        newAllOrdSecSeals.push([]);
                    }
                    const lastI = newAllOrdSecSeals.length - 1;
                    newAllOrdSecSeals[lastI].push({
                        id: secSealId,
                        image: secSealImage,
                    });

                    // En ultima iteración agregar elementos vacios en la ultima fila
                    // para mantener mismo tamaño de elemento
                    if (securitySealsArray.length - 1 === i) {
                        while (newAllOrdSecSeals[lastI].length !== newColInRow) {
                            newAllOrdSecSeals[lastI].push({});
                        }
                    }

                    return newAllOrdSecSeals;
                },
                [[]],
            );

            setColsInRow(newColInRow);
            setOrderedSecSeals(newOrderedSecSeals);
        }
    }, [isMobile, orderedSecSeals, securitySeals, currentSecuritySeal]);

    const handleClickOption = (avatarId) => {
        setSelectedAvatar(avatarId);
    };

    const handleClickCancel = () => {
        setShowSettingsExitModal(true);
    };

    const handleClickSubmit = () => {
        dispatch(settingsActions.changeSecuritySealConfirmation(selectedAvatar));
    };

    const handleOnBack = () => {
        dispatch(routerActions.goBack());
    };

    const buttonHandler = () => (
        <Box className={classNames({ "view-app-content": !isMobile })}>
            <Row
                gapX="7"
                className={classNames(" mb-7", {
                    "d-flex flex-column-reverse": isMobile,
                    "mt-7": !isMobile,
                })}>
                <Col xs={3} xsOffset={3}>
                    <Button
                        bsStyle="outline"
                        label="global.cancel"
                        btnUppercase={false}
                        onClick={handleClickCancel}
                        block
                    />
                </Col>
                <Col xs={3}>
                    <Button
                        bsStyle="primary"
                        label="global.save"
                        loading={fetching}
                        btnUppercase={false}
                        onClick={handleClickSubmit}
                        disabled={!selectedAvatar}
                        block
                    />
                </Col>
            </Row>
        </Box>
    );

    return (
        <>
            <Notification scopeToShow="settings" />
            <Head textBack="settings.changeAvatar.goBack" onBack={handleOnBack} hasCenterContent>
                <Text
                    component="h2"
                    size="1"
                    color={isMobile ? "text-inverse-color" : "heading-color"}
                    classNames="m-0"
                    bold>
                    <I18n id="settings.changeAvatar.title" />
                </Text>
            </Head>
            <MainContainer
                className="main-container rounded-border-top p-7 mt-3 box-shadow-small background-white"
                showLoader={fetching}>
                <div className="above-the-fold">
                    <Box fullWidth display="flex" column={isMobile} alignX="between" className="pb-7 mb-7">
                        <Box display="flex mb-7">
                            <Box fitWidth className="mr-3">
                                <Image src="images/icons/icn-avatar.svg" width="24px" color="transparent" />
                            </Box>
                            <Box fullWidth>
                                <Box>
                                    <Text
                                        size={isMobile && "2"}
                                        component="h3"
                                        defaultValue={i18n.get("settings.changeAvatar.selectAvatar")}
                                        bold
                                    />
                                </Box>
                                <Box>
                                    <Text
                                        size={isMobile && "3"}
                                        defaultValue={i18n.get("settings.changeAvatar.selectAvatar.subtitle")}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {!!currentSecuritySeal && (
                            <Box
                                display="flex"
                                alignY="center"
                                alignX="between"
                                style={{
                                    backgroundColor: "#E4F3F4",
                                    borderRadius: "40px",
                                    padding: 8,
                                    paddingLeft: "16px",
                                }}>
                                <Text
                                    color="primary-active-color"
                                    size={isMobile ? "4" : "6"}
                                    bold
                                    noWrap
                                    defaultValue={i18n.get("settings.changeAvatar.actualAvatar")}
                                />
                                <Box className="ml-8">
                                    <SecuritySealImage
                                        securitySeal={securitySeals?.[currentSecuritySeal]}
                                        // securitySealAlt={securitySeals?.[currentSecuritySeal]}
                                        withoutBorder
                                        size="56px"
                                    />
                                </Box>
                            </Box>
                        )}
                    </Box>
                    {orderedSecSeals.map((rowOptions) => (
                        <Box fullWidth display="flex" key={rowOptions[0]?.id}>
                            {rowOptions.map((option, i) => (
                                <Box
                                    key={option.id || `empty-${i}`}
                                    flex1
                                    display="flex"
                                    column
                                    alignX="center"
                                    className={classNames("px-7 py-7 mb-7 border-radius-lg security-image", {
                                        "mr-7": !!((i + 1) % colsInRow),
                                        "box-shadow-small": !!option.id,
                                        "cursor-pointer": !!option.id,
                                        "sec-img-mobile": isMobile,
                                        selected: option.id === selectedAvatar,
                                    })}
                                    onClick={() => handleClickOption(option.id)}>
                                    {!!option.id && (
                                        <>
                                            <Box fitWidth className={!isMobile && "mb-5"}>
                                                <SecuritySealImage
                                                    securitySeal={option.image}
                                                    securitySealAlt={option.id}
                                                    withoutBorder
                                                />
                                            </Box>
                                            <RadioButton
                                                id={option.id}
                                                key={option.id}
                                                name="new_avatar"
                                                value={option.id}
                                                checked={option.id === selectedAvatar}
                                                className="gap-0"
                                            />
                                        </>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    ))}
                </div>
                {isMobile && buttonHandler()}
            </MainContainer>
            {!isMobile && buttonHandler()}
            <TextAndButtonsModal
                modalShow={showSettingsExitModal}
                cancelFunction={() => setShowSettingsExitModal(false)}
                acceptFunction={() => dispatch(goBack())}
                confirmLabel="enrollment.modal.exit"
                headingText={i18n.get("enrollment.modal.headingText")}
                text={i18n.get("enrollment.modal.text")}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    securitySeals: settingsSelectors.getSecuritySeals(state),
    fetching: settingsSelectors.isFetching(state),
    currentSecuritySeal: sessionSelectors.getUserSecuritySeal(state),
});

ChangeAvatar.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    currentSecuritySeal: oneOfType([number, string]),
    fetching: bool.isRequired,
    securitySeals: shape({}).isRequired,
};

ChangeAvatar.defaultProps = {
    currentSecuritySeal: null,
};

export default connect(mapStateToProps)(resizableRoute(ChangeAvatar));
