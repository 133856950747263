import classNames from "classnames";
import flowRight from "lodash/flowRight";
import formField from "pages/forms/_components/_fields/_commons/formField";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FieldWarning from "pages/_components/fields/FieldWarning";
import FormattedAmount from "pages/_components/FormattedAmount";
import { resizableRoute } from "pages/_components/Resizable";
import Tooltip from "pages/_components/Tooltip";
import withFocus from "pages/_components/withFocus";
import { bool, func, node, number, oneOfType, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { getNestedObject } from "util/general";
import * as numberUtils from "util/number";

const INPUT_REGEX_REPLACE = /[^0-9.,]/g;

const NumberField = ({
    autocomplete,
    className,
    decimalSeparator,
    field,
    form: { touched, errors },
    hideLabel,
    idField,
    idForm,
    inputAlign,
    isFocused,
    isMobile,
    isTablet,
    labelClassName,
    labelNoMarginTop,
    labelText,
    mode,
    nestedErrorsObject,
    noMarginBottom,
    onBlur,
    onKeyDown,
    optional,
    placeholder = "",
    precision,
    setValue,
    showLabel,
    thousandsSeparator,
    toggleIsFocused,
    tooltip,
    tooltipInfo,
    tooltipLightSyled,
    tooltipPosition,
    tooltipText,
    value,
    warningMessage,
    textError,
    fixedDecimalScale,
}) => {
    const [hasWarning, setHasWarning] = useState(false);

    useEffect(() => {
        setValue("");
        // eslint-disable-next-line
    }, []);

    let quantityRef = null;

    const handleChange = () => {
        let quantity = quantityRef.value.replace(INPUT_REGEX_REPLACE, "");
        quantity = numberUtils.createDecimalNumber(quantity, decimalSeparator, precision);
        setValue(quantity);
        setHasWarning(false);
    };

    const renderViewMode = () => <FormattedAmount {...value} />;

    const renderEditMode = () => {
        const hasError = nestedErrorsObject
            ? getNestedObject(touched, field.name.split(".")) && getNestedObject(errors, field.name.split("."))
            : touched[field.name] && errors[field.name];

        const fieldLabel = labelText || `${idForm}.${field.name}.label`;
        const id = idField || `${idForm}.${field.name}`;
        return (
            <>
                <div
                    className={classNames("form-group", className, {
                        "has-error": hasError,
                        "has-focus": isFocused,
                        "form-group-margin-bottom": !noMarginBottom,
                    })}>
                    {showLabel && (
                        <FieldLabel
                            hideLabel={hideLabel}
                            idField={id}
                            labelClassName={labelClassName}
                            {...(labelText ? { labelText } : { labelKey: fieldLabel })}
                            labelNoMarginTop={labelNoMarginTop}
                            optional={optional}
                            tooltipInfo={tooltipInfo}
                            tooltipLightSyled={tooltipLightSyled}
                            tooltipPosition={tooltipPosition}
                            tooltipText={tooltipText}
                        />
                    )}

                    <div className="input-group" onFocus={toggleIsFocused} onBlur={toggleIsFocused}>
                        <NumberFormat
                            className={classNames("form-control", { [`text-${inputAlign}`]: inputAlign })}
                            decimalSeparator={decimalSeparator}
                            fixedDecimalScale={fixedDecimalScale}
                            getInputRef={(ref) => {
                                quantityRef = ref;
                            }}
                            id={idField}
                            maxLength="20"
                            name="quantity"
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            onChange={() => {
                                handleChange();
                            }}
                            placeholder={placeholder}
                            thousandSeparator={thousandsSeparator}
                            type="text"
                            value={value}
                            autocomplete={autocomplete}
                        />
                    </div>

                    {hasError && (
                        <FieldError
                            error={
                                nestedErrorsObject ? getNestedObject(errors, field.name.split(".")) : errors[field.name]
                            }
                        />
                    )}

                    {textError && <FieldError error={textError} />}

                    {hasWarning && <FieldWarning warning={warningMessage} />}

                    {tooltip && isFocused && (
                        <Tooltip
                            tooltipLightSyled={tooltipLightSyled}
                            position={tooltipPosition || (isTablet || isMobile ? "top-right" : "left-centered")}>
                            {tooltip}
                        </Tooltip>
                    )}
                </div>
            </>
        );
    };
    return <>{mode === "edit" ? renderEditMode() : renderViewMode()}</>;
};

NumberField.propTypes = {
    autocomplete: string,
    className: string,
    decimalSeparator: string,
    hideLabel: bool,
    idField: string.isRequired,
    idForm: string,
    inputAlign: string,
    isFocused: bool,
    isMobile: bool.isRequired,
    isTablet: bool.isRequired,
    field: shape({
        onBlur: func,
        onChange: func,
        name: string,
        value: oneOfType([number, string]),
    }).isRequired,
    form: shape({
        errors: shape({}),
        touched: shape({}),
    }).isRequired,
    labelClassName: string,
    labelNoMarginTop: bool,
    labelText: string,
    mode: string,
    nestedErrorsObject: bool,
    noMarginBottom: bool,
    onBlur: func,
    onKeyDown: func,
    optional: string,
    placeholder: string,
    precision: string,
    setValue: func,
    showLabel: bool,
    thousandsSeparator: string,
    toggleIsFocused: string,
    tooltip: node,
    tooltipInfo: node,
    tooltipLightSyled: bool,
    tooltipPosition: string,
    tooltipText: string,
    value: shape({}).isRequired,
    warningMessage: string,
    textError: string,
    fixedDecimalScale: bool,
};
NumberField.defaultProps = {
    autocomplete: "on",
    className: null,
    decimalSeparator: "",
    hideLabel: false,
    idForm: "",
    inputAlign: "right",
    isFocused: false,
    labelClassName: null,
    labelNoMarginTop: false,
    labelText: null,
    mode: "",
    nestedErrorsObject: false,
    noMarginBottom: false,
    onBlur: () => {},
    onKeyDown: () => {},
    optional: "",
    placeholder: "",
    precision: "",
    setValue: () => {},
    showLabel: true,
    thousandsSeparator: "",
    toggleIsFocused: "",
    tooltip: null,
    tooltipInfo: null,
    tooltipLightSyled: false,
    tooltipPosition: null,
    tooltipText: null,
    warningMessage: "",
    textError: null,
    fixedDecimalScale: true,
};

export default flowRight(resizableRoute, withFocus, formField())(NumberField);
