import { call, put, takeLatest, select } from "redux-saga/effects";
import { delay } from "redux-saga";

import globalTypes from "reducers/types/global";
import { types, actions } from "reducers/transactions";
import { types as loginTypes } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";
import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import { adjustIdFieldErrors } from "util/form.js";
import * as configUtil from "util/config";
import * as transactionsMiddleware from "middleware/transactions";
import * as formsMiddleware from "middleware/form";
import * as i18n from "util/i18n";
import { isMobileNativeFunc } from "util/device";
import { downloadCSVFile, downloadMobileFile, downloadPdf, downloadXls } from "util/download";
import b64toBlob from "b64-to-blob";

const sagas = [
    takeLatest([globalTypes.INIT, loginTypes.LOGIN_SUCCESS], refreshPendingTransactionsQuantity),
    takeLatest(types.LOAD_LIST_REQUEST, loadListRequest),
    takeLatest(types.LOAD_HISTORIC_LIST_REQUEST, loadHistoricListRequest),
    takeLatest(types.LOAD_MORE_TRANSACTIONS_REQUEST, fetchMoreTransactions),
    takeLatest(types.LOAD_MORE_HISTORIC_TRANSACTIONS_REQUEST, fetchMoreHistoricTransactions),
    takeLatest(types.DELETE_DRAFT_REQUEST, deleteDraftRequest),
    takeLatest(types.DOWNLOAD_LIST_REQUEST, downloadTransactionList),
    takeLatest(types.LOAD_MIGRATION_TRANSACTION_LIST_REQUEST, loadMigrationListRequest),
    takeLatest(types.DELETE_MIGRATION_TRANSACTION_REQUEST, deleteMigrationTransaction),
    takeLatest(types.RE_SCHEDULE_MIGRATION_TRANSACTION_REQUEST, reScheduleMigrationTransaction),
    takeLatest(types.LIST_CREDENTIAL_GROUPS_REQUEST, listCredentialsGroups),
];

export default sagas;

function* refreshPendingTransactionsQuantity() {
    const activeEnvironment = yield select(sessionSelectors.getActiveEnvironment);
    if (activeEnvironment?.type !== CORPORATE_GROUP_ENVIRONMENT_TYPE) {
        if (configUtil.get("feature.transactions.refreshPendingQuantity")) {
            while (true) {
                const hasActiveSession = yield select(sessionSelectors.isLoggedIn);
                if (!hasActiveSession) {
                    break;
                }

                try {
                    const response = yield call(transactionsMiddleware.getPendingTransactionsQuantity);
                    if (response.status !== 304 && response.type === "I") {
                        yield put({
                            type: types.REFRESH_PENDING_TRANSACTIONS_QUANTITY_SUCCESS,
                            pendingTransactionsQuantity:
                                response.data.data.pendingTransactionsQuantity +
                                response.data.data.procesingTransactionsQuantity,
                        });
                    }
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.error(err);
                }
                yield call(delay, configUtil.get("transactions.pending.refreshRate", 60) * 1000);
            }
        }
    }
}

function* loadListRequest({ filters, onlyPendings, onlyProcessing, pendingDispatch, formikBag }) {
    const response = yield call(
        transactionsMiddleware.loadListRequest,
        filters,
        onlyPendings,
        onlyProcessing,
        pendingDispatch,
    );

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        if (formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]),
            );
        }
    } else {
        const { transactions, pageNumber, totalPages, disclaimerDate } = response.data.data;
        yield put(actions.loadListSuccess(transactions, pageNumber, totalPages, disclaimerDate));
    }
}

function* loadMigrationListRequest({ formikBag }) {
    try {
        const response = yield call(transactionsMiddleware.loadTransactionMigrationList);

        if (response.type === "W") {
            yield put(actions.loadMigrationTransactionListFailure());
            if (formikBag) {
                formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                        "transactionMigration",
                    ]),
                );
            }
        } else {
            const { transactions } = response.data.data;
            yield put(actions.loadMigrationTransactionListSuccess(transactions));
        }
    } catch (err) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactionMigration"]),
        );
    }
}

function* listCredentialsGroups({ idActivity }) {
    const response = yield call(formsMiddleware.listCredentialsGroups, null, idActivity);
    const neededCredentials = response.data.data.groups;

    yield put({
        type: types.LIST_CREDENTIAL_GROUPS_SUCCESS,
        credentialsGroups: neededCredentials,
    });
}

function* reScheduleMigrationTransaction({
    transactionId,
    transactionType,
    scheduler,
    beneficiary,
    idDebitAccount,
    reason,
    amount,
    debitAccount,
    debitAccountData,
    creditAccount,
    creditAccountData,
    beneficiaryId,
    credentials,
    formikBag,
    creationDateTime,
}) {
    try {
        const response = yield call(
            transactionsMiddleware.reScheduleMigrationTransaction,
            transactionId,
            transactionType,
            scheduler,
            beneficiary,
            idDebitAccount,
            reason,
            amount,
            debitAccount,
            debitAccountData,
            creditAccount,
            creditAccountData,
            beneficiaryId,
            credentials,
            creationDateTime,
        );

        if (response.type === "W") {
            yield put(actions.reScheduleMigrationTransactionFailure());
            if (formikBag) {
                const errors = response.data.data;
                if (Object.values(errors).length) {
                    yield put(
                        notificationActions.showNotification(Object.values(errors)[0], "error", [
                            "transactionMigration",
                        ]),
                    );
                } else if (response.data.code && response.data.code === "API580W") {
                    yield put(
                        notificationActions.showNotification(response.data.message, "warning", [
                            "transactionMigration",
                        ]),
                    );
                }
                formikBag.setErrors(adjustIdFieldErrors(errors));
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                        "transactionMigration",
                    ]),
                );
            }
        } else {
            yield put(actions.reScheduleMigrationTransactionSuccess(transactionId));
            yield put(
                notificationActions.showNotification(i18n.get("scheduled.transaction.reschedule.success"), "success", [
                    "transactionMigration",
                ]),
            );
        }
    } catch (err) {
        yield put(actions.reScheduleMigrationTransactionFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactionMigration"]),
        );
    }
}

function* deleteMigrationTransaction({ idTransaction, transactionType, formikBag, onFinish }) {
    const response = yield call(transactionsMiddleware.deleteMigrationTransaction, idTransaction, transactionType);

    if (response.type === "W") {
        yield put(actions.deleteMigrationTransactionFailure());
        if (formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "transactionMigration",
                ]),
            );
        }
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("scheduled.transaction.migration.delete.success"),
                "success",
                ["transactionMigration"],
            ),
        );
        yield put(actions.deleteMigrationTransactionSuccess(idTransaction));
    }

    if (onFinish) {
        onFinish();
    }
}

function* loadHistoricListRequest({ filters, lastRegistryNumber, previousRegistryNumber, formikBag }) {
    const response = yield call(
        transactionsMiddleware.loadListRequestHistoric,
        filters,
        lastRegistryNumber,
        previousRegistryNumber,
    );

    if (response.type === "W") {
        yield put(actions.loadHistoricListFailure());
        if (response.data.data.NO_FIELD) {
            yield put(
                notificationActions.showNotification(response.data.data.NO_FIELD, "error", ["historicTransactions"]),
            );
        }
        if (formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "historicTransactions",
                ]),
            );
        }
    } else {
        const {
            transactions,
            lastRegistryNumber: newLastRegistryNumber,
            previousRegistryNumber: newPreviousRegistryNumber,
        } = response.data.data;
        yield put(actions.loadHistoricListSuccess(transactions, newLastRegistryNumber, newPreviousRegistryNumber));
    }
}

function* fetchMoreTransactions({ filters, onlyPendings, onlyProcessing, pendingDispatch }) {
    const page = filters.pageNumber;
    const response = yield call(
        transactionsMiddleware.loadListRequest,
        { ...filters, pageNumber: page + 1 },
        onlyPendings,
        onlyProcessing,
        pendingDispatch,
    );
    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    } else {
        const { transactions, pageNumber, totalPages } = response.data.data;
        yield put(actions.loadMoreTransactionsSuccess(transactions, pageNumber, totalPages));
    }
}

function* fetchMoreHistoricTransactions({ filters, lastRegistryNumber }) {
    const response = yield call(transactionsMiddleware.loadListRequestHistoric, filters, lastRegistryNumber);
    if (response.type === "W") {
        yield put(actions.loadHistoricListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["historicTransactions"]),
        );
    } else {
        const { transactions, lastRegistryNumber: newLastRegistryNumber } = response.data.data;
        yield put(actions.loadMoreHistoricTransactionsSuccess(transactions, newLastRegistryNumber));
    }
}

function* deleteDraftRequest({ idTransaction }) {
    const response = yield call(transactionsMiddleware.deleteDraftRequest, idTransaction);

    if (response.type === "W") {
        yield put(actions.deleteDraftFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    } else {
        const { deleted } = response.data.data;

        if (deleted) {
            yield put(actions.deleteDraftSuccess(idTransaction));
            yield put(
                notificationActions.showNotification(i18n.get("transactions.list.draft.deleted"), "success", [
                    "transactions",
                ]),
            );
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("transactions.list.draft.deleted.fail"), "success", [
                    "transactions",
                ]),
            );
        }
    }
}
function* downloadTransactionList({ filters, url, lastRegistryNumber }) {
    const response = yield call(transactionsMiddleware.downloadListTransaction, filters, url, lastRegistryNumber);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({ type: types.DOWNLOAD_LIST_FAILURE });
    } else {
        const { type, data } = response;
        if (type === "W") {
            if (data && data.code && data.code === "API547W") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("transactions.download.file.transactions.notfound"),
                        "error",
                        ["pendingTransaction", "menu", "transactions"],
                    ),
                );
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                        "pendingTransaction",
                        "menu",
                        "transactions",
                    ]),
                );
            }
            yield put({ type: types.DOWNLOAD_LIST_FAILURE });
        } else {
            const { fileName, content, contentType } = response.data.data;
            if (fileName && content && contentType) {
                if (isMobileNativeFunc()) {
                    const fileBlob = b64toBlob(content, contentType);
                    downloadMobileFile(fileBlob, fileName, contentType);
                } else if (contentType === "application/pdf") {
                    downloadPdf(fileName, content);
                } else if (contentType === "application/vnd.ms-excel") {
                    downloadXls(fileName, content);
                } else if (contentType === "text/plain") {
                    downloadCSVFile(fileName, content);
                }
                yield put(actions.downloadListSuccess());
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]),
                );
                yield put({ type: types.DOWNLOAD_LIST_FAILURE });
            }
        }
    }
}
