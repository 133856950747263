import { call, put, takeLatest } from "redux-saga/effects";

import * as creditCardMovementDetails from "middleware/creditCardMovementDetails";
import { actions, types } from "reducers/creditCardMovementDetails";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.DETAILS_REQUEST, detailRequest),
    takeLatest(types.UPDATE_NOTE_REQUEST, updateNoteRequest),
];

export default sagas;

function* detailRequest({ idCreditCard, idStatement }) {
    const response = yield call(creditCardMovementDetails.detailRequest, idCreditCard, idStatement);

    if (response.type === "W") {
        yield put(actions.detailFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "creditCardMovementDetail",
            ]),
        );
    } else {
        const { statement } = response.data.data;

        yield put(actions.detailSuccess(statement));
    }
}

function* updateNoteRequest({ idProduct, idStatement, note, onFinish }) {
    const response = yield call(creditCardMovementDetails.updateNoteRequest, idProduct, idStatement, note);

    if (response.type === "W") {
        const errorMessage = i18n.get("creditCards.movement.detail.noteUnsaved");
        yield put(notificationActions.showNotification(errorMessage, "error", ["movementDetail"]));
    } else {
        const confirmationMessage = i18n.get("creditCards.movement.detail.noteSaved");
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["movementDetail"]));
    }
    onFinish();
}
