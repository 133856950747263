import { func, instanceOf, number, number as numberType, oneOfType, string } from "prop-types";
import React, { Component } from "react";
import DepositWidget from "pages/_components/DepositWidget/DepositWidget";
import moment from "moment";
import Box from "pages/_components/Box";
import { Link } from "react-router-dom";
import Info from "pages/_components/Info";
import { isDesktop } from "react-device-detect";
import Text from "pages/_components/Text";
import CircularProgressBar from "pages/_components/CircularProgressBar";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Tooltip from "pages/_components/Tooltip";

class DepositsListItem extends Component {
    static propTypes = {
        currency: string,
        dispatch: func.isRequired,
        dueDate: instanceOf(Date),
        idProduct: string,
        number: string,
        productType: string.isRequired,
        number: string.isRequired,
        percentTerm: numberType.isRequired,
        totalBalance: oneOfType([string, numberType]),
        expirationDate: string,
        percentTerm: number,
        status: string.isRequired,
    };

    static defaultProps = {
        currency: null,
        dueDate: null,
        idProduct: null,
        number: "",
        rate: 0,
        totalBalance: null,
        expirationDate: "",
        percentTerm: 0,
    };

    formatProductName = (subProductType) => {
        const listDeposit = configUtils.get("deposits.myDeposits.type.list");

        const listDepositArray = listDeposit.split("|");

        for (const deposit of listDepositArray) {
            const depositSplit = deposit.split(":");
            if (depositSplit.length === 2 && depositSplit[0] === subProductType) {
                return (depositSplit[1]? i18nUtils.get(depositSplit[1]).toUpperCase() :"");
            }
        }
        return null;
    };

    renderCircleProgressBar = (rate, status) => (
        <>
            <div style={{ width: 60, height: 60 }} className="circle-widget-tooltip">
                <Tooltip
                    onHover
                    forEllipsis
                    inText={<Text labelKey="deposits.list.tooltip.text" PERCENT_TERM={rate} light />}
                    position="top-left"
                    className="align-self-center height-fit-content">
                    <CircularProgressBar percentage={rate} status={status} />
                </Tooltip>
            </div>
        </>
    );

    getStatusStyle = (status) => {
        const listDeposit = configUtils.get("deposits.myDeposits.status");
        const listDepositArray = listDeposit.split("|");

        for (const deposit of listDepositArray) {
            const depositSplit = deposit.split(":");
            if (depositSplit.length === 3 && depositSplit[0] === status) {
                return { style: `info-user-list ${depositSplit[2]}`, label: `deposits.status.${status}` };
            }
        }
        return null;
    };

    onClickItem = (idDeposit) => {
        const { dispatch } = this.props;
        dispatch(push(`/deposits/${idDeposit}`));
    };

    renderItem = () => {
        const {
            idProduct,
            number,
            // balance,
            // currency,
            status,
            // rate,
            totalBalance,
            expirationDate,
            totalTerm,
            subProductType,
            percentTerm,
        } = this.props;

        const getStatusLabel = this.getStatusStyle(status);
        const statusLabel = i18nUtils.get(getStatusLabel.label);
        const circleProgressBar = this.renderCircleProgressBar(percentTerm, status);

        return (
            <Box
                display="flex"
                alignX="between"
                alignY="center"
                fullWidth
                className="box-shadow-small border-radius-lg mb-4"
                onClick={() => {
                    this.onClickItem(idProduct);
                }}>
                <Link
                    aria-describedby={idProduct}
                    className="full-width border-radius-lg"
                    to={`/deposits/${idProduct}`}>
                    {!isDesktop ? (
                        <>
                            <Box
                                display="flex"
                                alignY="center"
                                fullWidth
                                column
                                className="item-account-list p-5 border-radius-lg justify-content-between">
                                <Box display="flex" fullWidth>
                                    <Box display="flex" alignX="between" alignY="center">
                                        {circleProgressBar}
                                    </Box>
                                    <Box className="pl-4">
                                        <Info
                                            className="info-account-list regular-weight-list"
                                            text={this.formatProductName(subProductType)}
                                        />
                                        <Info className="info-account-list" text={number} />
                                    </Box>
                                </Box>
                                <Box display="flex" alignX="between" alignY="center" className="my-6">
                                    <Box>
                                        <Info
                                            className="info-account-list"
                                            labelKey="deposits.myDeposits.expiration.date"
                                            text={moment(expirationDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                        />
                                    </Box>
                                    <Box>
                                        <Info className={getStatusLabel.style} text={statusLabel} />
                                    </Box>
                                </Box>
                                <Box display="flex" alignX="between" alignY="center" className="my-6">
                                    <Box>
                                        <Info
                                            className="info-account-list"
                                            labelKey="deposits.myDeposits.expiration.term"
                                            text={`${totalTerm} ${i18nUtils.get("global.day.plural")}`}
                                        />
                                    </Box>
                                    <Box>
                                        <Info
                                            className="info-account-list size-2"
                                            labelKey="deposits.myDeposits.expiration.capital.amount"
                                            amount={totalBalance}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box
                                alignY="center"
                                fullWidth
                                className="item-account-list p-5 border-radius-lg justify-content-between">
                                <Box display="flex" alignY="center">
                                    <Box display="flex" alignX="between" alignY="center">
                                        {circleProgressBar}
                                    </Box>
                                    <Box className="px-4">
                                        <Info
                                            size={6}
                                            className="info-account-list"
                                            labelText={this.formatProductName(subProductType)}
                                        />
                                        <Info className="info-account-list" text={number} />
                                    </Box>
                                </Box>

                                <Info
                                    className="info-account-list"
                                    labelKey="deposits.myDeposits.expiration.date"
                                    text={moment(expirationDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                />

                                <Info
                                    className="info-account-list"
                                    labelKey="deposits.myDeposits.expiration.term"
                                    text={`${totalTerm} ${i18nUtils.get("global.day.plural")}`}
                                />

                                <Box className="info-status" align="center">
                                    <Info className={getStatusLabel.style} text={statusLabel} fullWidth={false} />
                                </Box>

                                <Info
                                    className="info-account-list text-right"
                                    labelKey="deposits.myDeposits.expiration.capital.amount"
                                    amount={totalBalance}
                                />
                            </Box>
                        </>
                    )}
                </Link>
            </Box>
        );
    };

    renderWidget() {
        const {
            idProduct,
            percentTerm,
            totalBalance,
            currency,
            expirationDate,
            subProductType,
            number,
            // dueDateStr,
        } = this.props;

        return (
            <DepositWidget
                {...this.props}
                id={idProduct}
                // onClick={() => {
                //     this.onClickItem();
                // }}
                onClick={() => {
                    this.onClickItem(idProduct);
                }}
                number={number}
                rate={percentTerm}
                totalAmount={totalBalance}
                productDetail={number}
                expirationDate={moment(expirationDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                currencyDescription={i18nUtils.get("widgets.deposits.myDeposits.currecydetail", null, {
                    CURRECY: currency,
                })}
                productType={this.formatProductName(subProductType)}
                percentDetail={i18nUtils.get("widgets.deposits.myDeposits.percent", null, {
                    PERCENT: percentTerm,
                })}
            />
        );
    }

    render() {
        const { match } = this.props;

        if (match) {
            if (match.path === "/deposits") {
                return this.renderItem();
            }
            return this.renderWidget();
        }
        return this.renderWidget();
    }
}

export default connect()(DepositsListItem);
