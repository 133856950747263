import React, { useEffect } from "react";
import withRouter from "react-router-dom/withRouter";
import { compose } from "redux";
import { connect } from "react-redux";
import { bool, func, shape, string } from "prop-types";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as templateSelectors } from "reducers/template";
import { Field } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import * as i18nUtils from "util/i18n";
import { TEXT_TYPE, AMOUNT_TYPE } from "pages/forms/_components/_fields/ReadText";
import * as Yup from "yup";
import { validationText, validationAmount, validationBool } from "util/validationSchemaUtil";
import { numberFormat } from "util/number";
import FormTransition from "../_components/FormTransition";

const ID_FORM = "request.newloan";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

const RequestLoanForm = (props) => {
    const { mode, dispatch, location } = props;
    const { decimalSeparator, thousandSeparator } = numberFormat();

    useEffect(() => {
        if (mode === "edit" || mode === "view") {
            const formData = { debitAccount: null };

            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
        }
        // eslint-disable-next-line
    }, [dispatch, location]);

    const validationSchema = () =>
        Yup.object().shape({
            loanType: validationText(),
            amountFees: validationText(),
            amount: validationAmount(),
            termsCondition: validationBool(),
        });

    /**
     * Display field custom
     */

    const renderFieldAmountCustom = (amount, genericProps, key) => (
        <FormFieldsComponents.ReadTextCustom
            {...genericProps}
            key={key}
            name={key}
            idField={key}
            amount={amount}
            type={AMOUNT_TYPE}
        />
    );

    const renderFieldTextCustom = (value, genericProps, key) => (
        <FormFieldsComponents.ReadTextCustom
            {...genericProps}
            key={key}
            name={key}
            idField={key}
            value={value}
            type={TEXT_TYPE}
        />
    );

    /**
     * Render function
     */

    const renderSimulationGeneral = (dataSimulation, genericProps) => {
        if (!dataSimulation) {
            return <div />;
        }

        return (
            <>
                {dataSimulation.firstFeeAmount && dataSimulation.firstFeeAmount.currency ? (
                    renderFieldAmountCustom(dataSimulation.firstFeeAmount, genericProps, "firstFeeAmount")
                ) : (
                    <div />
                )}
                {dataSimulation.averageFeeAmount && dataSimulation.averageFeeAmount.currency ? (
                    renderFieldAmountCustom(dataSimulation.averageFeeAmount, genericProps, "averageFeeAmount")
                ) : (
                    <div />
                )}
                {dataSimulation.tna && renderFieldTextCustom(`${dataSimulation.tna}%`, genericProps, "tna")}
                {dataSimulation.tea && renderFieldTextCustom(`${dataSimulation.tea}%`, genericProps, "tea")}
                {dataSimulation.grantingExpenses && dataSimulation.grantingExpenses.currency ? (
                    renderFieldAmountCustom(dataSimulation.grantingExpenses, genericProps, "grantingExpenses")
                ) : (
                    <div />
                )}
                {dataSimulation.rateType && renderFieldTextCustom(dataSimulation.rateType, genericProps, "rateType")}
                {dataSimulation.cftWithIva &&
                    renderFieldTextCustom(`${dataSimulation.cftWithIva}%`, genericProps, "cftWithIva")}
                {dataSimulation.cftWithOutIva &&
                    renderFieldTextCustom(`${dataSimulation.cftWithOutIva}%`, genericProps, "cftWithOutIva")}
                {dataSimulation.dateFirstExpiration &&
                    renderFieldTextCustom(`${dataSimulation.dateFirstExpiration}`, genericProps, "dateFirstExpiration")}
            </>
        );
    };

    const renderPreviewData = (genericProps) => {
        const { previewData } = props;
        if (!previewData) {
            return <div />;
        }

        return renderSimulationGeneral(previewData, genericProps);
    };

    const renderPostData = (genericProps) => {
        const { postData } = props;
        if (!postData) {
            return <div />;
        }

        return renderSimulationGeneral(postData, genericProps);
    };

    const renderFields = () => {
        const { preDataForm, currentLang, fromBackoffice, transaction } = props;
        const idTransaction = transaction?.idTransaction;

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
            dispatch,
        };

        const preData = preDataForm || {
            debitAccountList: [],
            loanTypeArray: [],
            currencyList: [],
            feesTypeArray: [],
        };

        const dataAmount = {
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options: preData.currencyList?.map((c) => ({
                id: c.backendId,
                label: c.code,
            })),
        };

        const loanTypeOptions = {
            options: preData.loanTypeArray?.map((item) => ({
                ...item,
            })),
        };

        const dataAmountDef =
            preData.currencyList && preData.currencyList[0]
                ? { ...preData.currencyList[0], currency: preData.currencyList[0].backendId }
                : {};

        const feesTypeOptions = preData.feesTypeArray?.map((item) => ({ ...item }));

        const accountOptions = {
            options: preData.debitAccountList?.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
                disabled: acc.isDisabled,
            })),
        };

        return (
            <>
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.ProductselectorCustom}
                    data={loanTypeOptions}
                    key="loanType"
                    name="loanType"
                    renderAs="combo"
                    isRequired
                    customPlaceholder={i18nUtils.get(`${ID_FORM}.loanType.placeholder`)}
                    idField="loanType"
                />
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Amount}
                    data={dataAmount}
                    key="amount"
                    name="amount"
                    value={dataAmountDef}
                    isRequired
                    idField="amount"
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    key="amountFees"
                    name="amountFees"
                    idField="amountFees"
                    labelIdField="react-select-amountFees-input"
                    optionList={feesTypeOptions}
                    isFocused={false}
                    renderAs="combo"
                    customPlaceholder={i18nUtils.get(`${ID_FORM}.amountFees.placeholder`)}
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.ProductselectorCustom}
                    data={accountOptions}
                    key="debitAccount"
                    name="debitAccount"
                    renderAs="combo"
                    value={preData.debitAccountList[0]?.idProduct || ""}
                    idField="debitAccount"
                />

                {mode === "edit" && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Termsandconditions}
                        key="termsCondition"
                        name="termsCondition"
                        idField="termsCondition"
                        isFocused={false}
                        contentCustom={preData.termsConditions}
                        showAcceptOption
                    />
                )}

                {mode === "preview" ? renderPreviewData(genericProps) : <div />}
                {mode === "view" ? renderPostData(genericProps) : <div />}
            </>
        );
    };

    /**
     * Return Page
     */

    const { preData } = props;
    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
        // eslint-disable-next-line react/prop-types
    } = preData || {};

    const formProps = {
        title: "forms.request.newloan.formName",
        metadata: {
            draftsEnabled: true,
            templatesEnabled: true,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },

        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validationSchema,
    };
    return <FormTransition {...props} {...formProps} />;
};

/**
 * Properties
 */
RequestLoanForm.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
    isDesktop: bool,
    preData: shape({}),
    postData: shape({}),
};
RequestLoanForm.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: null,
    previewData: null,
    transaction: null,
    location: {},
    fromTransaction: false,
    isDesktop: false,
    preData: {},
    postData: {},
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    postData: formSelectors.getData(state),
});
export default compose(connect(mapStateToProps), withRouter)(RequestLoanForm);
