import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    CLOSE_OPTIONS: "creditCardMovementDetail/CLOSE_OPTIONS",
    DETAILS_REQUEST: "creditCardMovementDetail/DETAILS_REQUEST",
    DETAILS_FAILURE: "creditCardMovementDetail/DETAILS_FAILURE",
    DETAILS_SUCCESS: "creditCardMovementDetail/DETAILS_SUCCESS",
    UPDATE_NOTE_REQUEST: "creditCardMovementDetail/UPDATE_NOTE_REQUEST",
    UPDATE_NOTE_FAILURE: "creditCardMovementDetail/UPDATE_NOTE_FAILURE",
    UPDATE_NOTE_SUCCESS: "creditCardMovementDetail/UPDATE_NOTE_SUCCESS",
    SET_SELECTED_MOVEMENT: "creditCardMovementDetail/SET_SELECTED_MOVEMENT",
};

export const INITIAL_STATE = {
    isFetching: true,
    selectedMovement: null,
    detail: null,
};

const reducer = createReducer(INITIAL_STATE, {
    [types.DETAILS_REQUEST]: () => INITIAL_STATE,
    [types.CLOSE_OPTIONS]: () => INITIAL_STATE,
    [types.DETAILS_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.DETAILS_SUCCESS]: (action) => ({
        isFetching: false,
        detail: action.movement,
    }),
    [types.SET_SELECTED_MOVEMENT]: (state, action) => ({
        ...state,
        selectedMovement: action.movement,
    }),
    [types.UPDATE_NOTE_REQUEST]: (state) => ({ ...state, isFetching: true }),
    [types.UPDATE_NOTE_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.UPDATE_NOTE_SUCCESS]: (state, action) => ({
        isFetching: false,
        detail: {
            ...state.detail,
            note: action.note,
        },
    }),
});

export default persistReducer(
    {
        storage,
        key: "creditCardMovementDetails",
    },
    reducer,
);

export const actions = {
    closeOptions: makeActionCreator(types.CLOSE_OPTIONS),
    detailRequest: makeActionCreator(types.DETAILS_REQUEST, "idCreditCard", "idStatement"),
    detailFailure: makeActionCreator(types.DETAILS_FAILURE),
    detailSuccess: makeActionCreator(types.DETAILS_SUCCESS, "movement"),
    setSelectedMovement: makeActionCreator(types.SET_SELECTED_MOVEMENT, "movement"),
    updateNoteRequest: makeActionCreator(types.UPDATE_NOTE_REQUEST, "idProduct", "idStatement", "note", "onFinish"),
    updateNoteFailure: makeActionCreator(types.UPDATE_NOTE_FAILURE),
    updateNoteSuccess: makeActionCreator(types.UPDATE_NOTE_SUCCESS, "note"),
};

export const selectors = {
    isFetching: ({ creditCardMovementDetails }) => creditCardMovementDetails.isFetching,
    getMovement: ({ creditCardMovementDetails }) => creditCardMovementDetails.detail,
    getSelectedMovement: ({ creditCardMovementDetails }) => creditCardMovementDetails.selectedMovement,
};
