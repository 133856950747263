import classNames from "classnames";
import AmountLabel from "pages/forms/_components/_fields/Amount/AmountLabel";
import Select from "pages/forms/_components/_fields/Select";
import formField from "pages/forms/_components/_fields/_commons/formField";
import Box from "pages/_components/Box";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import withFocus from "pages/_components/withFocus";
import { array, bool, func, node, number, oneOfType, shape, string } from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import { compose } from "redux";
import * as configUtils from "util/config";
import * as numberUtils from "util/number";
import loadash from "lodash";

const INPUT_REGEX_REPLACE = /[^0-9.,]/g;

class Amount extends React.Component {
    quantityRef = null;

    static propTypes = {
        bigDataAmount: bool,
        boldAmount: bool,
        currencyBox: bool,
        customPlaceholderCurrency: string,
        customPlaceholderQuantity: string,
        data: shape({
            // eslint-disable-next-line react/forbid-prop-types
            options: array,
            thousandsSeparator: string.isRequired,
            decimalSeparator: string.isRequired,
            precision: number,
        }).isRequired,
        disabled: bool,
        disabledCurrency: bool,
        editing: bool.isRequired,
        field: shape({ value: oneOfType([string, shape({})]) }).isRequired,
        focus: bool,
        hideCurrency: bool,
        idField: string.isRequired,
        isFocused: bool,
        isMobile: bool.isRequired,
        isTablet: bool.isRequired,
        mode: string.isRequired,
        onBlur: func,
        onChange: func,
        placeholder: string,
        quantityTextAlign: string,
        setValue: func.isRequired,
        showCurrencyLabel: bool,
        sizeAmount: string,
        toggleIsFocused: func,
        tooltip: node,
        tooltipLightSyled: bool,
        tooltipPosition: string,
        value: shape({ currency: string, quantity: oneOfType([number, string]) }),
        valueDef: shape({}),
        inputmode: string,
        onChangeDelay: func,
    };

    static defaultProps = {
        bigDataAmount: false,
        boldAmount: false,
        currencyBox: true,
        customPlaceholderCurrency: undefined,
        customPlaceholderQuantity: undefined,
        disabled: false,
        disabledCurrency: false,
        focus: false,
        hideCurrency: false,
        isFocused: false,
        onBlur: null,
        onChange: null,
        placeholder: "",
        quantityTextAlign: "text-right",
        showCurrencyLabel: false,
        sizeAmount: null,
        toggleIsFocused: null,
        tooltip: null,
        tooltipLightSyled: false,
        tooltipPosition: null,
        value: {},
        valueDef: null,
        inputmode: "decimal",
        onChangeDelay: undefined,
    };

    constructor(...args) {
        super(...args);
        this.state = {
            numberFormatFocused: false,
        };
        const { onChangeDelay } = this.props;
        if (onChangeDelay) {
            this.debounceCheckInput = loadash.debounce(this.checkInput.bind(this), 500);
        }
    }

    componentDidMount() {
        const { mode, field, focus } = this.props;

        if (mode === "edit" && field.value === "") {
            this.handleChange({ value: configUtils.get("core.masterCurrency") });
        }
        // al primer campo del formulario por lo general se le pasa focus en true
        if (this.quantityRef && focus) {
            this.quantityRef.focus();
        }
    }

    handleChange = (selectedCurrency = {}) => {
        const {
            data: { decimalSeparator, precision },
            value,
            setValue,
            onChange,
            onChangeDelay,
        } = this.props;
        let quantity = this.quantityRef.value.replace(INPUT_REGEX_REPLACE, "");
        quantity = numberUtils.createDecimalNumber(quantity, decimalSeparator, precision);
        const amount = {
            currency: selectedCurrency.value || value.currency,
            quantity,
        };

        setValue(amount);

        if (onChange) {
            onChange(amount);
        }

        if (onChangeDelay) {
            this.debounceCheckInput();
        }
    };

    buildProductOptions = (options) =>
        options?.map((elem) => ({
            value: elem.id,
            label: (
                <Box display="flex" alignY="center" fullWidth aria-label={elem.label}>
                    <Image
                        src={`images/icons/flags/${elem.id}.svg`}
                        wrapperClassName="currency-flag-wrapper"
                        className="mr-3"
                        alt={elem.id}
                    />
                    <Text size="5" color="text" className="currency-flag-abbreviation mr-4">
                        {elem.label}
                    </Text>
                    <Text size="5" color="text" labelKey={`currency.name.${elem.id}`} className="currency-flag-text" />
                </Box>
            ),
        }));

    onFocusNumberFormat = () => {
        this.setState({ numberFormatFocused: true });
    };

    onBlurNumberFormat = () => {
        this.setState({ numberFormatFocused: false });
    };

    checkInput() {
        const { onChangeDelay, value } = this.props;
        if (onChangeDelay) {
            onChangeDelay(value);
        }
    }

    render() {
        const {
            bigDataAmount,
            boldAmount,
            currencyBox,
            customPlaceholderCurrency,
            customPlaceholderQuantity,
            data: { options, decimalSeparator, thousandsSeparator },
            disabled,
            disabledCurrency,
            editing,
            hideCurrency,
            idField,
            isMobile,
            isTablet,
            onBlur,
            placeholder,
            quantityTextAlign,
            sizeAmount,
            tooltip,
            tooltipLightSyled,
            tooltipPosition,
            value,
            inputmode = "decimal",
        } = this.props;

        const { numberFormatFocused } = this.state;

        const selectedCurrency = value ? value.currency : "";
        const selectedQuantity = value ? value.quantity : "";

        if (editing) {
            return (
                <div className="input-group">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {currencyBox && (
                        <>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {options.length === 1 ? (
                                hideCurrency ? (
                                    ""
                                ) : (
                                    <span
                                        className={classNames("currency is-only", {
                                            "is-disabled": disabledCurrency || disabled,
                                        })}>
                                        {options[0].label}
                                    </span>
                                )
                            ) : (
                                <>
                                    <FieldLabel
                                        idField={`${idField}.currency`}
                                        hideLabel
                                        labelKey="form.field.amount.currency"
                                        mode="edit"
                                    />
                                    <Select
                                        id={`${idField}.currency`}
                                        containerClassName="currency-selector"
                                        name="currency"
                                        searchable={false}
                                        onChange={this.handleChange}
                                        value={selectedCurrency}
                                        options={this.buildProductOptions(options)}
                                        clearable={false}
                                        placeholder={customPlaceholderCurrency}
                                        disabled={disabledCurrency || disabled}
                                        valueKey="value"
                                    />
                                </>
                            )}
                        </>
                    )}

                    <Box
                        position="relative"
                        fullWidth
                        className={classNames("amount-field-wrapper", {
                            "no-currency-box": !currencyBox || hideCurrency,
                        })}
                        onFocus={this.onFocusNumberFormat}
                        onBlur={this.onBlurNumberFormat}>
                        <NumberFormat
                            id={idField}
                            name="quantity"
                            className={classNames("form-control", quantityTextAlign, {
                                "text-bold": boldAmount,
                                "max-height-9": sizeAmount,
                                [`size-${sizeAmount}`]: sizeAmount,
                            })}
                            type="text"
                            onBlur={onBlur}
                            onChange={() => this.handleChange()}
                            maxLength="20"
                            value={selectedQuantity}
                            placeholder={placeholder || customPlaceholderQuantity}
                            getInputRef={(ref) => {
                                this.quantityRef = ref;
                            }}
                            thousandSeparator={thousandsSeparator}
                            decimalSeparator={decimalSeparator}
                            fixedDecimalScale
                            autoComplete="off"
                            disabled={disabled}
                            inputMode={inputmode}
                        />
                        {tooltip && numberFormatFocused && (
                            <Tooltip
                                tooltipLightSyled={tooltipLightSyled}
                                position={tooltipPosition || (isTablet || isMobile ? "top-right" : "left-centered")}>
                                {tooltip}
                            </Tooltip>
                        )}
                    </Box>
                </div>
            );
        }
        return <FormattedAmount {...value} bigDataAmount={bigDataAmount} />;
    }
}

export default compose(
    withFocus,
    formField({
        formClass: "form-group--composite",
        customLabel: AmountLabel,
    }),
)(resizableRoute(Amount));
