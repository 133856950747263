import { createReducer, makeActionCreator } from "util/redux";

export const softTokenDisclaimerTypes = {
    VALIDATE_TOKEN_STATUS_REQUEST: "softTokenDisclaimer/VALIDATE_TOKEN_STATUS_REQUEST",
    VALIDATE_TOKEN_STATUS_SUCCESS: "softTokenDisclaimer/VALIDATE_TOKEN_STATUS_SUCCESS",
    VALIDATE_TOKEN_STATUS_FAILURE: "softTokenDisclaimer/VALIDATE_TOKEN_STATUS_FAILURE",
    TOKEN_STATUS_ACTION: "softTokenDisclaimer/TOKEN_STATUS_ACTION",
    TOKEN_STATUS_ACTION_FINISH: "softTokenDisclaimer/TOKEN_STATUS_ACTION_FINISH",
};

export const INITIAL_STATE = {
    fetching: false,
    tokenStatus: undefined,
    fetchingTokenAction: undefined,
};

export const selectors = {
    isFetching: ({ softTokenDisclaimer }) => softTokenDisclaimer.fetching || false,
    getTokenStatus: ({ softTokenDisclaimer }) => softTokenDisclaimer.tokenStatus,
    isFetchingTokenAction: ({ softTokenDisclaimer }) => softTokenDisclaimer.fetchingTokenAction || false,
};

export const actions = {
    validateTokenStatusRequest: makeActionCreator(softTokenDisclaimerTypes.VALIDATE_TOKEN_STATUS_REQUEST),
    validateTokenStatusSuccess: makeActionCreator(
        softTokenDisclaimerTypes.VALIDATE_TOKEN_STATUS_SUCCESS,
        "tokenStatus",
    ),
    tokenStatusAction: makeActionCreator(
        softTokenDisclaimerTypes.TOKEN_STATUS_ACTION,
        "redirectSuccess",
        "redirectError",
        "scopeSuccess",
        "scopeError",
        "redirectAbort",
    ),
};

export default createReducer(INITIAL_STATE, {
    [softTokenDisclaimerTypes.VALIDATE_TOKEN_STATUS_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        tokenStatus: undefined,
    }),
    [softTokenDisclaimerTypes.VALIDATE_TOKEN_STATUS_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        tokenStatus: action?.tokenStatus,
    }),
    [softTokenDisclaimerTypes.VALIDATE_TOKEN_STATUS_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        tokenStatus: undefined,
    }),
    [softTokenDisclaimerTypes.TOKEN_STATUS_ACTION]: (state) => ({
        ...state,
        fetchingTokenAction: true,
    }),
    [softTokenDisclaimerTypes.TOKEN_STATUS_ACTION_FINISH]: (state) => ({
        ...state,
        fetchingTokenAction: false,
    }),
});
