import formField from "pages/forms/_components/_fields/_commons/formField";
import FilePayment from "pages/forms/_components/_fields/_multilinefile/FilePayment";
import Button from "pages/_components/Button";
import DetailBox from "pages/_components/detailBox/DetailBox";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import { arrayOf, bool, func, number, oneOf, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routerActions } from "react-router-redux/actions";
import { actions as fileActions } from "reducers/files";
import { selectors as transactionLinesSelectors } from "reducers/form/transactionLines";
import { selectors as multilineFileSelectors } from "reducers/formFields/multilineFile";
import { selectors as multilineFileProcessSelectors } from "reducers/formFields/multilineFileProcess";
import { compose } from "redux";

export class Multilinefile extends Component {
    static propTypes = {
        // ... existing propTypes ...
        dispatch: func.isRequired,
        match: shape({
            params: shape({
                idTransaction: string,
                idForm: string,
            }),
        }).isRequired,
        idTransactionTicket: string,
        processedFileData: shape({
            invalidLines: number,
            validLines: number,
            totalAmount: shape({
                // ... your totalAmount propTypes ...
            }),
            fileIdentifier: string,
            linesWithNoAmount: number,
        }).isRequired,
        idRelatedFile: string,
        nameRelatedFile: string,
        pendingLines: bool.isRequired,
        value: arrayOf(
            shape({
                fileId: number.isRequired,
                fileSize: number.isRequired,
                fileName: string.isRequired,
                fileType: string.isRequired,
            }),
        ),
        setValue: func.isRequired,
        fromBackoffice: bool.isRequired,
        editing: bool.isRequired,
        idForm: string.isRequired,
        idField: string.isRequired,
        formTitle: string.isRequired,
        label: string.isRequired,
        maxFileSizeMB: number.isRequired,
        acceptedFileTypes: arrayOf(string).isRequired,
        mode: oneOf(["edit", "view"]).isRequired,
    };

    static defaultProps = {
        value: null,
        idTransactionTicket: null,
        idRelatedFile: null,
        nameRelatedFile: null,
    };

    handleClick = () => {
        const { dispatch, match, idTransactionTicket } = this.props;
        const idTransaction = match.params.idTransaction ? match.params.idTransaction : idTransactionTicket;

        dispatch(routerActions.push(`/transaction/${idTransaction}/processDetail`));
    };

    handleDetailsClick = () => {
        const { dispatch, match } = this.props;
        dispatch(routerActions.push(`/form/${match.params.idForm}/processDetail`, { shouldLoadForm: false }));
    };

    handleDownloadErrorsClick = () => {
        const { dispatch, idRelatedFile, nameRelatedFile } = this.props;
        dispatch(fileActions.downloadFileRequest(idRelatedFile, nameRelatedFile));
    };

    renderLinesInfo = (downloadFile) => {
        const { processedFileData } = this.props;
        const { invalidLines, validLines } = processedFileData;

        if (!invalidLines) {
            return validLines;
        }

        return (
            <Fragment>
                <div>
                    {validLines + invalidLines} <I18n id="forms.inputFile.massivePayments.total.lines" />
                </div>
                <div>
                    <I18n id="forms.inputFile.massivePayments.invalid.lines" /> {invalidLines} / {validLines}{" "}
                    <I18n id="forms.inputFile.massivePayments.valid.lines" />
                </div>

                <Button
                    onClick={() => downloadFile({ isDownloadingRelatedFile: true })}
                    label="forms.inputFile.massivePayments.download.errors"
                    bsStyle="link"
                />

                <Button
                    onClick={() => downloadFile({ isDownloadingRelatedFile: true })}
                    label="forms.inputFile.massivePayments.download.correct"
                    bsStyle="link"
                />
            </Fragment>
        );
    };

    renderAmount = () => {
        const { processedFileData } = this.props;
        const { invalidLines, totalAmount } = processedFileData;

        if (!invalidLines) {
            return <FormattedAmount className="data-desc" {...totalAmount} />;
        }

        return (
            <Fragment>
                <FormattedAmount className="data-desc" {...totalAmount} />*
                <div className="detailBox-data-aux">
                    <I18n id="forms.inputFile.massivePayments.calculated.amount.info" />
                </div>
            </Fragment>
        );
    };

    render() {
        const { mode, processedFileData, pendingLines } = this.props;
        const { totalAmount, validLines, invalidLines, fileIdentifier, linesWithNoAmount } = processedFileData;
        switch (mode) {
            case "edit": {
                return <FilePayment {...this.props} />;
            }
            case "preview": {
                return (
                    <DetailBox>
                        {!!linesWithNoAmount && (
                            <I18n
                                component="div"
                                componentProps={{ className: "alert alert-warning mTop", role: "alert" }}
                                LINE_COUNT={linesWithNoAmount}
                                id="massive.payments.warning.noAmount"
                            />
                        )}
                        <DetailBox.Data label="forms.inputFile.massivePayments.id">{fileIdentifier}</DetailBox.Data>
                        <DetailBox.Data label="forms.inputFile.massivePayments.lines">
                            <div>
                                {validLines + invalidLines} <I18n id="forms.inputFile.massivePayments.total.lines" />
                            </div>
                            {invalidLines > 0 && (
                                <Fragment>
                                    <div>
                                        <I18n id="forms.inputFile.massivePayments.invalid.lines" />
                                        {` ${invalidLines} / ${validLines} `}
                                        <I18n id="forms.inputFile.massivePayments.valid.lines" />
                                    </div>
                                    {/* <button
                                        type="button"
                                        className="btn btn-asLink btn-dl-error"
                                        onClick={this.handleDownloadErrorsClick}>
                                        <I18n id="forms.inputFile.massivePayments.download.errors" />
                                    </button> */}
                                    <Button
                                        onClick={this.handleDownloadErrorsClick}
                                        label="forms.inputFile.massivePayments.download.errors"
                                        bsStyle="link"
                                    />
                                </Fragment>
                            )}
                        </DetailBox.Data>
                        <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                            <FormattedAmount className="data-desc" {...totalAmount} />
                        </DetailBox.Data>
                        <div className="detailBox-row">
                            <Button
                                bsStyle="primary"
                                className="btn-small"
                                onClick={this.handleDetailsClick}
                                label="forms.inputFile.massivePayments.transaction.detail"
                            />
                        </div>
                    </DetailBox>
                );
            }
            case "view": {
                return (
                    <DetailBox>
                        <DetailBox.Data label="forms.inputFile.massivePayments.id">{fileIdentifier}</DetailBox.Data>
                        <DetailBox.Data label="forms.inputFile.massivePayments.lines">{validLines}</DetailBox.Data>
                        <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                            <FormattedAmount className="data-desc" {...totalAmount} />
                        </DetailBox.Data>
                        {!pendingLines && (
                            <div className="detailBox-row">
                                <Button
                                    bsStyle="primary"
                                    className="btn-small"
                                    onClick={this.handleClick}
                                    label="forms.inputFile.massivePayments.transaction.detail"
                                />
                            </div>
                        )}
                    </DetailBox>
                );
            }
            default: {
                return null;
            }
        }
    }
}
const mapStateToProps = (state) => ({
    pendingLines: multilineFileProcessSelectors.getPendingLines(state),
    transactionLines: transactionLinesSelectors.getTransactionLines(state),
    processedFileData: multilineFileSelectors.getProcessedFileData(state),
    hasFile: multilineFileSelectors.hasFile(state),
    idRelatedFile: multilineFileSelectors.getIdRelatedFile(state),
    nameRelatedFile: multilineFileSelectors.getNameRelatedFile(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    formField({
        customLabel: ({ editing }) => (!editing ? true : null),
        isValidValue: (_, { processedFileData }) => {
            const { invalidHeader, invalidFile } = processedFileData;
            return !invalidHeader && !invalidFile;
        },
        pureRender: true,
    }),
)(Multilinefile);
