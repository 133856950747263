import React from "react";
import { bool, func, number, shape } from "prop-types";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import * as i18n from "util/i18n";
import Button from "pages/_components/Button";

const UserListActions = ({ selectedUsers, unblockedQuantity, blockedQuantity, handleActionClick, showCheckBox }) => (
    <Box>
        <>
            {showCheckBox && (
                <Box display="flex" alignY="center" gap="3" className="pl-7 mb-2">
                    <Text component="h4" size="6" className="m-0 line-height-125 ">
                        {selectedUsers.size > 0 && (
                            <>
                                {selectedUsers.size} {i18n.get("administration.users.list.selected.quantity")}
                            </>
                        )}
                    </Text>
                    <Button
                        className="btn btn-small btn-outline ml-3"
                        key="block"
                        bsStyle="outline"
                        label="administration.block"
                        disabled={selectedUsers?.size === 0 || unblockedQuantity === 0 || blockedQuantity > 0}
                        onClick={() => handleActionClick("block")}
                    />
                    <Button
                        className="btn btn-small btn-outline ml-3"
                        key="unblock"
                        bsStyle="outline"
                        label="administration.unblock"
                        disabled={selectedUsers?.size === 0 || blockedQuantity === 0 || unblockedQuantity > 0}
                        onClick={() => handleActionClick("unblock")}
                    />
                    <Button
                        className="btn btn-small btn-outline ml-3"
                        key="delete"
                        bsStyle="outline"
                        label="administration.delete"
                        disabled={selectedUsers?.size === 0}
                        onClick={() => handleActionClick("delete")}
                    />
                </Box>
            )}
        </>
    </Box>
);

UserListActions.propTypes = {
    selectedUsers: shape({}),
    unblockedQuantity: number,
    blockedQuantity: number,
    handleActionClick: func,
    showCheckBox: bool,
};

UserListActions.defaultProps = {
    selectedUsers: {},
    unblockedQuantity: 0,
    blockedQuantity: 0,
    handleActionClick: () => {},
    showCheckBox: false,
};

export default UserListActions;
