import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import { push, goBack } from "react-router-redux";
import MainContainer from "pages/_components/MainContainer";
import { Field, withFormik, Form } from "formik";
import { compose } from "redux";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import DocumentField from "pages/_components/fields/DocumentField";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import Notification from "pages/_components/Notification";
import { actions, selectors } from "reducers/frequentDestination/frequentDestination";
import { func, date, shape, bool, string, arrayOf, oneOf } from "prop-types";
import { Grid, Col } from "react-bootstrap";
import Row from "pages/_components/Row";
import { actions as formActions } from "reducers/form";
import I18n from "pages/_components/I18n";
import Select from "pages/_components/fields/Select";
import PageLoading from "pages/_components/PageLoading";
import { selectors as bankSelectors } from "reducers/bankSelector";
import { selectors as i18nSelectors } from "reducers/i18n";

const FORM_ID = "frequentDestination.detailModifyDelete";

class CreateFrequentDestination extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        frequentDestionationOnRow: shape({
            name: string,
            productType: string,
            localBank: string,
            exteriorBank: shape({}),
            accountNumber: string,
            recipientName: string,
            recipientDocument: shape({}),
            recipientAddress: string,
            creditCardNumber: string,
            loanNumber: string,
            creationDate: date,
            extraDataMap: shape({}),
        }),
        values: shape({ filter: oneOf(["", "foreignAccount", "localAccount", "account", "creditcard"]) }),
        countries: arrayOf(shape({ id: string, name: string })),
        documentTypes: arrayOf(shape({ id_country_code: string, id_document_type: string })),
        selectedBank: string,
        banks: arrayOf(shape({})),
        currentLang: string,
    };

    static defaultProps = {
        values: {},
        frequentDestionationOnRow: {},
        countries: [],
        documentTypes: [],
        selectedBank: "",
        banks: [],
        currentLang: "",
    };

    constructor(props) {
        super(props);

        const { selectedBank } = props;

        this.state = {
            fieldMode: selectedBank ? "edit" : "view",
        };
    }

    componentDidMount() {
        const { dispatch, frequentDestionationOnRow } = this.props;

        if (!frequentDestionationOnRow) {
            dispatch(goBack());
        } else {
            dispatch(actions.frequentDestinationPre());

            setTimeout(() => {
                dispatch(formActions.setFormFetching(false));
            }, 0);
        }
    }

    getRecipientAccountCodeMapOptions = () => [
        {},
        {
            id: "ABA",
            label: i18n.get("forms.transfers.foreign.recipientAccountCodeMap.ABA"),
        },
        {
            id: "IBAN",
            label: i18n.get("forms.transfers.foreign.recipientAccountCodeMap.IBAN"),
        },
        {
            id: "SWIFT",
            label: i18n.get("forms.transfers.foreign.recipientAccountCodeMap.SWIFT"),
        },
    ];

    handleHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/frequentDestination/"));
    };

    renderHeader = () => {
        const { fieldMode } = this.state;
        return (
            <Fragment>
                <Notification scopeToShow={FORM_ID} />
                {fieldMode === "view" ? (
                    <Head title="frequentDestination.detail.title" onClose={this.handleHeaderBack} />
                ) : (
                    <Head title="frequentDestination.modify.title" onClose={this.handleHeaderBack} />
                )}
            </Fragment>
        );
    };

    handleDelete = () => {
        const { dispatch, frequentDestionationOnRow } = this.props;
        dispatch(actions.deleteRequest(frequentDestionationOnRow));
    };

    handleModify = () => {
        const { fieldMode } = this.state;

        let newState = "";

        if (fieldMode === "view") {
            newState = "edit";
        } else {
            newState = "view";
        }
        this.setState(() => ({
            fieldMode: newState,
        }));
    };

    beforeRedirectSearch = () => {
        const { values, dispatch } = this.props;
        dispatch(actions.persistFormData(values));
    };

    renderForm = () => {
        const { fetching, countries, documentTypes, values, banks, currentLang } = this.props;
        const { fieldMode } = this.state;
        const bankOptionList = banks?.map((item) => ({ value: item.code, label: item.bankName })) || [];
        const countryOptions = countries
            ? countries.map(({ id, name }) => ({
                  value: id,
                  label: name,
              }))
            : [];

        if (!banks) {
            return <PageLoading loading />;
        }

        return (
            <Form>
                <MainContainer>
                    <div className="above-the-fold tight-containers" ref={this.containerRef}>
                        <section className="container--layout flex-grow-1 align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                        <h4 className="navigational-list-title">
                                            <I18n id="frequentDestination.create.title.sectionOne" />
                                        </h4>
                                        <Field
                                            mode={fieldMode}
                                            idForm={FORM_ID}
                                            component={TextField}
                                            name="name"
                                            type="text"
                                            isFocused
                                        />

                                        <Select
                                            name="filter"
                                            mode={fieldMode}
                                            label={i18n.get("frequentDestination.create.select.productType")}
                                            className="form-group form-group--select"
                                            options={[
                                                {
                                                    label: i18n.get("frequentDestination.create.select.creditcard"),
                                                    value: "creditcard",
                                                },
                                                {
                                                    label: i18n.get("frequentDestination.create.select.account"),
                                                    value: "account",
                                                },
                                                {
                                                    label: i18n.get("frequentDestination.create.select.localAccount"),
                                                    value: "localAccount",
                                                },
                                                {
                                                    label: i18n.get("frequentDestination.create.select.foreignAccount"),
                                                    value: "foreignAccount",
                                                },
                                            ]}
                                        />

                                        {values.filter === "localAccount" && (
                                            <Select
                                                mode={fieldMode}
                                                name="localBank"
                                                label={i18n.get("frequentDestination.create.localBank.label")}
                                                className="form-group form-group--select"
                                                options={bankOptionList}
                                            />
                                        )}

                                        {values.filter === "foreignAccount" && (
                                            <div style={{ marginBottom: "0.5rem" }}>
                                                <Field
                                                    idForm={FORM_ID}
                                                    idActivity={`${FORM_ID}.send`}
                                                    mode={fieldMode}
                                                    component={FormFieldsComponents.Bankselector}
                                                    codeTypeList={["ABA", "IBAN", "SWIFT"]}
                                                    type="bankselector"
                                                    name="foreignBank"
                                                    idField="foreignBank"
                                                    data={{
                                                        codes: this.getRecipientAccountCodeMapOptions(),
                                                        countries,
                                                    }}
                                                    lang={currentLang}
                                                    validationRegularExpresion="^[a-zA-Z0-9]*$"
                                                    beforeRedirectSearch={this.beforeRedirectSearch}
                                                    subType="vertical-display"
                                                />
                                            </div>
                                        )}

                                        <Field
                                            mode={fieldMode}
                                            idForm={FORM_ID}
                                            component={TextField}
                                            name="number"
                                            validationregularexpresion="^[0-9]*$"
                                            isFocused
                                        />

                                        <h4 className="navigational-list-title">
                                            <I18n id="frequentDestination.create.title.sectionTwo" />
                                        </h4>

                                        <Field
                                            mode={fieldMode}
                                            idForm={FORM_ID}
                                            component={TextField}
                                            name="recipientName"
                                            type="text"
                                            isFocused
                                            validationregularexpresion="^[a-zA-Z áéíóúñ@]*$"
                                        />

                                        <Field
                                            mode={fieldMode}
                                            autoComplete="off"
                                            clearable={false}
                                            component={DocumentField}
                                            data={{ countries, documentTypes }}
                                            idForm={FORM_ID}
                                            name="recipientDocument"
                                            validationregularexpresion="^[a-zA-Z0-9]*$"
                                        />

                                        <Field
                                            mode={fieldMode}
                                            idForm={FORM_ID}
                                            component={TextField}
                                            name="recipientAddress"
                                            type="text"
                                            isFocused
                                        />

                                        {values.filter === "foreignAccount" && (
                                            <div style={{ marginBottom: "0.5rem" }}>
                                                <Select
                                                    mode={fieldMode}
                                                    name="extraData.country"
                                                    label="Pais del beneficiario"
                                                    className="form-group form-group--select"
                                                    options={countryOptions}
                                                />
                                            </div>
                                        )}

                                        <Row className="justify-content-center">
                                            {fieldMode === "view" ? (
                                                <Col md={6} sm={12}>
                                                    <Button
                                                        bsStyle="primary"
                                                        label="global.delete"
                                                        loading={fetching}
                                                        onClick={this.handleDelete}
                                                        block
                                                    />
                                                </Col>
                                            ) : (
                                                ""
                                            )}
                                            {fieldMode === "view" ? (
                                                <Col md={6} sm={12}>
                                                    <Button
                                                        bsStyle="primary"
                                                        label="frequentDestination.modify.label.enableFields"
                                                        onClick={this.handleModify}
                                                        block
                                                    />
                                                </Col>
                                            ) : (
                                                <></>
                                            )}
                                            {fieldMode !== "view" ? (
                                                <Col sm={12}>
                                                    <Button
                                                        bsStyle="primary"
                                                        type="submit"
                                                        label="global.modify"
                                                        loading={fetching}
                                                        block
                                                    />
                                                </Col>
                                            ) : (
                                                <></>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </Form>
        );
    };

    render() {
        return (
            <Fragment>
                {this.renderHeader()}
                <MainContainer>{this.renderForm()}</MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: selectors.isFetching(state),
    frequentDestination: selectors.getFrequentDestination(state),
    frequentDestionationOnRow: selectors.getFrequentDestionationOnRow(state),
    countries: selectors.getCountryList(state),
    documentTypes: selectors.getDocumentTypeList(state),
    defaultCountry: selectors.getDefaultCountry(state),
    defaultDocumentType: selectors.getDefaultDocumentType(state),
    banks: selectors.getBankList(state),
    currentLang: i18nSelectors.getLang(state),
    formData: selectors.getFormData(state),
    selectedBank: bankSelectors.getSelectedBank(state, "foreignBank"),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ frequentDestionationOnRow, formData, selectedBank }) => ({
            recipientName: formData?.recipientName || frequentDestionationOnRow?.recipientName || "",
            recipientDocument: {
                country:
                    formData?.recipientDocument?.country ||
                    frequentDestionationOnRow?.recipientDocument?.documentCountry ||
                    "",
                type:
                    formData?.recipientDocument?.type ||
                    frequentDestionationOnRow?.recipientDocument?.documentType ||
                    "",
                document:
                    formData?.recipientDocument?.document ||
                    frequentDestionationOnRow?.recipientDocument?.document ||
                    "",
            },
            recipientAddress: formData?.recipientAddress || frequentDestionationOnRow?.recipientAddress || "",
            name: formData?.name || frequentDestionationOnRow?.name || "",
            filter: formData?.filter || frequentDestionationOnRow?.productType || "",
            number: formData?.number || frequentDestionationOnRow?.freqDestNumber || "",
            localBank: formData?.localBank || frequentDestionationOnRow?.localBank || "",
            // eslint-disable-next-line no-nested-ternary
            foreignBank: selectedBank
                ? {
                      type: selectedBank?.type || "",
                      code: selectedBank?.code || "",
                  }
                : formData?.foreignBank || frequentDestionationOnRow?.exteriorBank
                ? {
                      type: frequentDestionationOnRow.exteriorBank.codeType,
                      code: frequentDestionationOnRow.exteriorBank.codeNumber,
                  }
                : null,
            extraData: formData?.extraData || frequentDestionationOnRow?.extraDataMap || null,
        }),
        validationSchema: () =>
            Yup.lazy((values) => {
                switch (values.filter) {
                    case "creditcard":
                        return Yup.object().shape({
                            recipientName: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            recipientDocument: Yup.object().shape({
                                country: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                                document: Yup.string()
                                    .required(i18n.get(`${FORM_ID}.field.error.required`))
                                    .max(21, i18n.get(`${FORM_ID}.validation.recipientDocument.document.maxLength`)),
                                type: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            }),
                            name: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            filter: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            number: Yup.string()
                                .required(i18n.get(`${FORM_ID}.field.error.required`))
                                .max(16, i18n.get(`${FORM_ID}.validation.creditcard.numberExact`))
                                .min(16, i18n.get(`${FORM_ID}.validation.creditcard.numberExact`)),
                            recipientAddress: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                        });
                    case "localAccount":
                        return Yup.object().shape({
                            recipientName: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            recipientDocument: Yup.object().shape({
                                country: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                                document: Yup.string()
                                    .required(i18n.get(`${FORM_ID}.field.error.required`))
                                    .max(21, i18n.get(`${FORM_ID}.validation.recipientDocument.document.maxLength`)),
                                type: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            }),
                            name: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            filter: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            number: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            recipientAddress: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            localBank: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                        });
                    case "foreignAccount":
                        return Yup.object().shape({
                            recipientName: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            recipientDocument: Yup.object().shape({
                                country: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                                document: Yup.string()
                                    .required(i18n.get(`${FORM_ID}.field.error.required`))
                                    .max(21, i18n.get(`${FORM_ID}.validation.recipientDocument.document.maxLength`)),
                                type: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            }),
                            name: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            filter: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            number: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            recipientAddress: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            foreignBank: Yup.object()
                                .test(
                                    "checkNotEmpty",
                                    i18n.get(`${FORM_ID}.field.error.required`),
                                    (item) => item.type && item.code,
                                )
                                .required(i18n.get(`${FORM_ID}.field.error.required`))
                                .nullable(),
                            extraData: Yup.object().shape({
                                country: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            }),
                        });
                    default:
                        return Yup.object().shape({
                            recipientName: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            recipientDocument: Yup.object().shape({
                                country: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                                document: Yup.string()
                                    .required(i18n.get(`${FORM_ID}.field.error.required`))
                                    .max(21, i18n.get(`${FORM_ID}.validation.recipientDocument.document.maxLength`)),
                                type: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            }),
                            name: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            filter: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            number: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                            recipientAddress: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                        });
                }
            }),
        handleSubmit: (values, formikBag) => {
            const { dispatch, frequentDestionationOnRow } = formikBag.props;

            const frequentDestination = {
                idFrequentDestination: frequentDestionationOnRow.idFrequentDestination,
                recipientName: values.recipientName,
                recipientDocumentType: values.recipientDocument.type,
                recipientDocument: values.recipientDocument.document,
                recipientDocumentCountry: values.recipientDocument.country,
                recipientAddress: values.recipientAddress,
                name: values.name,
                productType: values.filter,
                number: values.number,
                localBank: values.filter === "localAccount" ? values.localBank : null,
                foreignBank: values.filter === "foreignAccount" ? values.foreignBank : null,
                extraData: values.extraData,
            };

            dispatch(actions.modifyRequest(frequentDestination, formikBag));
        },
    }),
)(CreateFrequentDestination);
