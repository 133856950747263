import FlowTransition from "pages/_components/FlowTransition";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import { bool, func, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import {
    actions as authenticateHandlerActions,
    selectors as authenticateHandlerSelector,
} from "reducers/authenticateHandler";
import { selectors as selectorSoftToken, actions as softTokenActions } from "reducers/softToken";
import SoftTokenConfirm from "./steps/SoftTokenConfirm";
import SoftTokenSecurityMethod from "./steps/SoftTokenSecurityMethod";
import SoftTokenSuccess from "./steps/SoftTokenSuccess";
import SoftTokenTermsAndConditions from "./steps/SoftTokenTermsAndConditions";
import { steps } from "./steps/utils";

const flowSteps = {
    making: "making",
    securityMethod: "securityMethod",
    confirmation: "confirmation",
};

const SoftTokenActivationFlow = ({
    isFetching,
    getTokenActivationFee,
    getTokenStatus,
    handleBack,
    activationStep,
    setActivationStep,
    amountActivateRate,
    currencyActivateRate,
    tokenStatus,
}) => {
    const [currentStep, setCurrentStep] = useState(flowSteps.making);
    const [confirmationData, setConfirmationData] = useState({});

    useEffect(() => {
        getTokenActivationFee();
    }, []);

    useEffect(() => {
        if (currentStep === flowSteps.making) {
            getTokenStatus();
        }
    }, []);

    useEffect(() => {
        console.log("start step: ", currentStep);
        if (activationStep) {
            setCurrentStep(activationStep);
        }
    }, [activationStep]);

    const onSuccess = () => {
        setCurrentStep("success");
    };

    const handleSubmitTerms = (formikBag) => {
        // setCurrentStep(flowSteps.securityMethod);
        setActivationStep(flowSteps.securityMethod);
    };

    const handleSubmitSecurityMethod = (securityMethod, formikBag) => {
        setConfirmationData(securityMethod);
        // setCurrentStep(flowSteps.confirmation);
        setActivationStep(flowSteps.confirmation);
    };

    return (
        <PageLoading loading={false}>
            <Notification scopeToShow="/activateSoftToken" />
            <FlowTransition
                currentPage={currentStep}
                pages={{
                    [flowSteps.making]: () => (
                        <SoftTokenTermsAndConditions
                            isDesktop={isDesktop}
                            data={confirmationData}
                            handleSubmit={handleSubmitTerms}
                            amountActivation={amountActivateRate}
                            currencyActivation={currencyActivateRate}
                            tokenStatus={tokenStatus}
                            steps={steps}
                        />
                    ),
                    [flowSteps.securityMethod]: () => (
                        <SoftTokenSecurityMethod
                            isDesktop={isDesktop}
                            handleSubmit={handleSubmitSecurityMethod}
                            steps={steps}
                        />
                    ),
                    [flowSteps.confirmation]: () => (
                        <SoftTokenConfirm isDesktop={isDesktop} handleSubmit={onSuccess} steps={steps} />
                    ),
                    ["success"]: () => <SoftTokenSuccess isDesktop={isDesktop} />,
                }}
            />
        </PageLoading>
    );
};

SoftTokenActivationFlow.propTypes = {
    isFetching: bool,
    handleBack: func.isRequired,
    activationStep: string,
    setActivationStep: func.isRequired,
    tokenStatus: string,
};

SoftTokenActivationFlow.defaultProps = {
    isFetching: false,
    activationStep: "",
    tokenStatus: "",
};

const mapStateToProps = (state) => ({
    // isFetching: selectorSoftToken.isFetching(state),
    activationStep: selectorSoftToken.getActivationStep(state),
    amountActivateRate: selectorSoftToken.getAmountRateFee(state),
    currencyActivateRate: selectorSoftToken.getCurrencyRateFee(state),
    tokenStatus: authenticateHandlerSelector.getTokenStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    handleBack: () => {
        dispatch(routerActions.push("/desktop"));
    },
    setActivationStep: (step) => {
        dispatch(softTokenActions.setActivationStep(step));
    },
    getTokenActivationFee: () => {
        dispatch(softTokenActions.getRateAmountActivateRequest());
    },
    getTokenStatus: () => {
        dispatch(authenticateHandlerActions.getTokenStatusRequest());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SoftTokenActivationFlow);
