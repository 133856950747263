import React, { Component } from "react";
import { string, number, func, bool } from "prop-types";
import { compose } from "redux";

import * as i18n from "util/i18n";

import formField from "pages/forms/_components/_fields/_commons/formField";
import withFocus from "pages/_components/withFocus";

class Text extends Component {
    static propTypes = {
        maxLength: number,
        validationRegularExpresion: string,
        minLength: number,
        setValue: func.isRequired,
        idValidation: string,
        editing: bool.isRequired,
        placeholder: string,
        value: string,
        name: string,
        toggleIsFocused: func,
        idField: string.isRequired,
        disabled: bool,
    };

    static defaultProps = {
        minLength: 0,
        maxLength: undefined,
        validationRegularExpresion: "",
        idValidation: "",
        placeholder: "",
        value: "",
        name: "",
        toggleIsFocused: null,
        disabled: false,
    };

    handleChange = ({ target }) => {
        const { setValue } = this.props;
        setValue(target.value);

        this.customMinLengthValidation({ target });
    };

    customMinLengthValidation = ({ target }) => {
        const { minLength, idValidation, validationRegularExpresion } = this.props;

        const { value } = target;

        if (minLength) {
            if (value && value.length < minLength) {
                target.setCustomValidity(
                    i18n.get("generic.text.field.minLength.warning.message", null, {
                        MINLENGTH: minLength,
                        CURRENTLENGTH: value.length,
                    }),
                );
            } else {
                target.setCustomValidity("");
            }
        }

        if (idValidation && validationRegularExpresion) {
            const rexp = new RegExp(validationRegularExpresion);
            if (!rexp.test(value)) {
                switch (idValidation) {
                    case "email":
                        target.setCustomValidity(i18n.get("generic.text.field.email.validationError", null));
                        break;
                    default:
                        break;
                }
            } else {
                target.setCustomValidity("");
            }
        }
    };

    /* getLabelField = (optional, idField, hideLabel, labelKey) => (
        <FieldLabel
            optional={optional}
            idField={`${idField}.label`}
            hideLabel={hideLabel}
            labelKey={labelKey}
            mode="edit"
        />
    ); */

    render() {
        const {
            editing,
            placeholder,
            minLength,
            maxLength,
            value,
            name,
            validationRegularExpresion,
            toggleIsFocused,
            idField,
            /* hideLabel,
            labelKey,
            optional, */
            disabled = false,
        } = this.props;

        if (editing) {
            return (
                <>
                    <div className="input-group">
                        <input
                            id={idField}
                            onInvalid={this.customMinLengthValidation}
                            className="form-control"
                            type="text"
                            value={value || ""}
                            name={name}
                            onChange={this.handleChange}
                            onBlur={toggleIsFocused}
                            minLength={minLength}
                            maxLength={maxLength}
                            placeholder={placeholder}
                            pattern={validationRegularExpresion}
                            onFocus={toggleIsFocused}
                            disabled={disabled}
                            autoComplete="off"
                        />
                    </div>
                </>
            );
        }
        return (
            <>
                <span>{value}</span>
            </>
        );
    }
}

export default compose(
    withFocus,
    formField({
        isValidValue: (value, props) => {
            const { validationRegularExpresion } = props;

            if (validationRegularExpresion) {
                try {
                    const regex = new RegExp(validationRegularExpresion);
                    return regex.test(value);
                } catch (e) {
                    return true;
                }
            }

            return true;
        },
    }),
)(Text);
