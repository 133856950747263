import CreditLineWigetItem from "pages/creditLines/_components/CreditLineWigetItem";
import WidgetList from "pages/desktop/widgets/WidgetList";
import { node, shape } from "prop-types";
import React, { Component } from "react";
import * as i18nUtils from "util/i18n";
import WidgetHeader from "./_components/WidgetHeader";

class CreditLines extends Component {
    static propTypes = {
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
    };

    static defaultProps = {
        closeButton: null,
    };

    render() {
        const { closeButton, draggableItemProps } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetCreditLines" };

        return (
            <WidgetList item={CreditLineWigetItem} keyExtractor={(item) => item.id} name="creditLines">
                {(list) => (
                    <section
                        className="widget"
                        {...uiAutomationProp}
                        {...draggableItemProps}
                        aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                        <WidgetHeader title={i18nUtils.get("menu.creditLines")} action={closeButton} />
                        {list}
                        <div className="overlay" />
                    </section>
                )}
            </WidgetList>
        );
    }
}

export default CreditLines;
