import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import GridTable from "pages/_components/GridTable/GridTable";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import React, { useState } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { connect } from "react-redux";
import { selectors as wallySelectors } from "reducers/wally";
import { arrayOf, func, shape, bool } from "prop-types";

const ListContacts = ({ contacts, onSelectBeneficiary, updateFavorite, isFetchingPeople }) => {
    const [pressedButtonIndex, setPressedButtonIndex] = useState(null);

    return contacts.length > 0 ? (
        <>
            <GridTable>
                <GridTable.Header tableHeaderBackground="transparent">
                    <GridTable.Container columns={1} rows={1} padding="p-5">
                        <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                            <Box display="flex" fullWidth>
                                <Text labelKey="wally.list.contacts" size={4} color="heading" bold capitalize />
                            </Box>
                        </GridTable.Data>
                    </GridTable.Container>
                </GridTable.Header>
                <GridTable.Body>
                    {contacts.map((element, key) => (
                        <GridTable.Container
                            columnsTemplate="1.5rem 1fr 2.5rem"
                            // eslint-disable-next-line react/no-array-index-key
                            key={`phone-${element.phoneNumber}${key}`}
                            rows={2}
                            onClick={() => onSelectBeneficiary(element)}
                            className="pl-5 pr-2 py-3">
                            <GridTable.Data className="p-0" columnStart={1} rowWidth={2} alignX="flex-start">
                                <Image
                                    src="images/contact-wally.svg"
                                    height="7"
                                    wrapperWidth="7"
                                    wrapperHeight="7"
                                    color="wally"
                                />
                            </GridTable.Data>
                            <GridTable.Data className="p-0" columnStart={2} alignX="flex-start">
                                <Text bold size="4" color="heading">
                                    {element.displayName || element.fullName}
                                </Text>
                            </GridTable.Data>
                            <GridTable.Data className="p-0" columnStart={2} rowStart={2} alignX="flex-start">
                                <Text size="6">
                                    {formatPhoneNumberIntl(`+${element.phoneNumber}`) || `+${element.phoneNumber}`}
                                </Text>
                            </GridTable.Data>
                            <GridTable.Data className="p-0" columnStart={3} rowWidth={2} alignX="flex-end">
                                <Button
                                    loading={isFetchingPeople && pressedButtonIndex === key}
                                    disabled={isFetchingPeople}
                                    bsStyle="only-icon"
                                    imageSm
                                    image={element.favorite ? "images/star.svg" : "images/star-off.svg"}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        updateFavorite(element);
                                        setPressedButtonIndex(key);
                                    }}
                                />
                            </GridTable.Data>
                        </GridTable.Container>
                    ))}
                </GridTable.Body>
            </GridTable>
        </>
    ) : (
        <Box display="flex" column alignY="center" alignX="center" className="py-12 px-5">
            <Image src="images/icons/wallyNoContacts.svg" className="pb-4" />
            <Text color="text-secondary-color" labelKey="wally.list.without.contacts" />
        </Box>
    );
};

const mapStateToProps = (state) => ({
    isFetchingPeople: wallySelectors.isFetchingPeople(state),
});

ListContacts.propTypes = {
    isFetchingPeople: bool,
    contacts: arrayOf(shape({})),
    onSelectBeneficiary: func.isRequired,
    updateFavorite: func.isRequired,
};

ListContacts.defaultProps = {
    isFetchingPeople: false,
    contacts: [],
};

export default connect(mapStateToProps)(ListContacts);
