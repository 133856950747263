import { createReducer, makeActionCreator } from "util/redux";

export const revelockTypes = {
    SET_POSITION: "revelock/SET_POSITION",
    CLEAR_POSITION: "revelock/CLEAR_POSITION",
    SHOW_SUSPICIOUS_ACTIVITY_MODAL: "revelock/SHOW_SUSPICIOUS_ACTIVITY_MODAL",
    CLEAR_SUSPICIOUS_ACTIVITY_MODAL: "revelock/CLEAR_SUSPICIOUS_ACTIVITY_MODAL",
};

export const INITIAL_STATE = {
    position: undefined,
    showSuspiciousActivityModal: undefined,
};

export const actions = {
    setPosition: makeActionCreator(revelockTypes.SET_POSITION, "position"),
    clearPosition: makeActionCreator(revelockTypes.CLEAR_POSITION),
    showSuspiciousActivityModal: makeActionCreator(revelockTypes.SHOW_SUSPICIOUS_ACTIVITY_MODAL),
    clearSuspiciousActivityModal: makeActionCreator(revelockTypes.CLEAR_SUSPICIOUS_ACTIVITY_MODAL),
};

export const selectors = {
    getPosition: ({ revelock }) => revelock.position || "",
    getShowSuspiciousActivityModal: ({ revelock }) => revelock.showSuspiciousActivityModal,
};

export default createReducer(INITIAL_STATE, {
    [revelockTypes.SET_POSITION]: (state, action) => ({
        ...state,
        position: action?.position,
    }),
    [revelockTypes.CLEAR_POSITION]: (state) => ({
        ...state,
        position: undefined,
    }),
    [revelockTypes.SHOW_SUSPICIOUS_ACTIVITY_MODAL]: (state) => ({
        ...state,
        showSuspiciousActivityModal: (state?.showSuspiciousActivityModal || 0) + 1,
    }),
    [revelockTypes.CLEAR_SUSPICIOUS_ACTIVITY_MODAL]: (state) => ({ ...state, showSuspiciousActivityModal: undefined }),
});
