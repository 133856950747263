import { Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import Row from "pages/_components/Row";
import Sticker from "pages/_components/Sticker";
import Text from "pages/_components/Text";
import PermissionsForm from "pages/administration/_components/PermissionsForm";
import { arrayOf, bool, element, func, number as num, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { Route, Switch } from "react-router-dom";
// import AdministrationHeading from "pages/administration/_components/Heading";

const groupShape = {
    idItem: string,
    label: string,
    ordinal: num,
};

groupShape.childrenList = arrayOf(shape(groupShape));

class Permissions extends Component {
    static propTypes = {
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        actions: shape({
            loadPermissionsRequest: func.isRequired,
            updatePermissionsPreview: func.isRequired,
        }).isRequired,
        products: arrayOf(shape(groupShape)).isRequired,
        groups: arrayOf(
            shape({
                idItem: string.isRequired,
                label: string.isRequired,
                ordinal: num.isRequired,
            }),
        ).isRequired,
        permissions: shape({}),
        fetching: bool,
        routerActions: shape({
            goBack: func.isRequired,
        }).isRequired,
        confirmRoute: element.isRequired,
        user: shape({}).isRequired,
    };

    static defaultProps = {
        permissions: null,
        fetching: false,
    };

    componentDidMount() {
        const { match, actions } = this.props;

        actions.loadPermissionsRequest(match.params.id);
    }

    handleSubmit = (values, { setSubmitting }) => {
        const { match, actions } = this.props;

        actions.updatePermissionsPreview(
            {
                permissions: values,
                idUser: match.params.id,
            },
            setSubmitting,
        );
    };

    renderContent = ({ isSubmitting, ...props }) => {
        const { user, routerActions } = this.props;
        const userStatus = user?.status?.idUserStatus;
        const status = () => {
            if (userStatus === "active") {
                return "success";
            }
            if (userStatus === "blocked") {
                return "error";
            }
            if (userStatus === "pending") {
                return "warning";
            }
            return null;
        };
        return (
            <>
                {/* <AdministrationHeading /> */}
                <MainContainer>
                    <Form>
                        <Box
                            display="flex"
                            column
                            background="component-background"
                            borderRadius="default"
                            className="px-8 py-7 mb-7"
                            fullHeight>
                            <Box display="flex">
                                <Text
                                    component="label"
                                    labelKey="administration.user.label"
                                    className="m-0"
                                    color="heading-color"
                                    bold
                                    addColon
                                />

                                <Text component="label" className="my-0 mr-5" color="heading-color" regular>
                                    {user?.fullName}
                                </Text>

                                <Sticker
                                    labelKey={`user.status.${userStatus}`}
                                    uppercase={false}
                                    bold
                                    status={status()}
                                    textSize="7"
                                    addPaddingY
                                />
                            </Box>
                        </Box>

                        <Text
                            component="h2"
                            labelKey="administration.permissions.functionalities"
                            className="mt-0 mb-5"
                            size="3"
                            color="heading-color"
                            bold
                        />
                        <Box className="mb-9">
                            <PermissionsForm {...props} />
                        </Box>

                        <Row>
                            <Col xs={3} xsOffset={3}>
                                <Button bsStyle="outline" label="global.cancel" onClick={routerActions.goBack} block />
                            </Col>
                            <Col xs={3}>
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="administration.permissions.modify"
                                    loading={isSubmitting}
                                    block
                                />
                            </Col>
                        </Row>
                    </Form>
                </MainContainer>
            </>
        );
    };

    render() {
        const { fetching, groups, routerActions, match, confirmRoute, permissions } = this.props;
        const isLoading = fetching && !groups.length;

        return (
            <Formik initialValues={permissions} onSubmit={this.handleSubmit} enableReinitialize={isLoading}>
                {(props) => (
                    <Switch>
                        <Route path={match.url} exact>
                            <>
                                <Head
                                    title="administration.permissions.configurePermissions"
                                    onBack={routerActions.goBack}
                                />
                                <PageLoading loading={isLoading}>{!isLoading && this.renderContent(props)}</PageLoading>
                            </>
                        </Route>
                        <Route>{confirmRoute}</Route>
                    </Switch>
                )}
            </Formik>
        );
    }
}

export default Permissions;
