import isEmpty from "lodash/isEmpty";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import DefaultModal from "pages/_components/modal/DefaultModal";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";
import * as deviceUtils from "util/device";
import * as i18n from "util/i18n";

class FingerprintConfiguration extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fingerprintConfiguredUserDevices: arrayOf(
            shape({
                device: shape({
                    deviceId: string.isRequired,
                    deviceModel: string.isRequired,
                    idSession: string.isRequired,
                }),
            }),
        ),
        isDeviceWithFingerprint: bool,
        availability: shape({
            isHardwareDetected: bool,
            isAvailable: bool,
        }),
        fetching: bool,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        fingerprintConfiguredUserDevices: null,
        isDeviceWithFingerprint: false,
        availability: null,
        fetching: false,
    };

    state = {
        showDefaultModal: false,
        itemToDelete: null,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fingerprintActions.fingerprintConfigurationPre());
        dispatch(fingerprintActions.fingerprintAvailability());
    }

    getContent() {
        const { isDesktop } = this.props;

        return (
            <>
                <form {...(!isDesktop && { className: "full-height" })}>
                    <Box display="flex" column alignY="between" fullHeight>
                        <Row className="height-auto">
                            <Col xs={12} md={10} mdOffset={1}>
                                <Disclaimer
                                    className="p-3 px-5 p-md-5 mt-5 mt-md-0 mb-8 mb-md-9"
                                    styled="info"
                                    labelKey="settings.biometrics.title"
                                />

                                <Box display="flex" className="mb-5">
                                    <Text
                                        component="h2"
                                        className="m-0"
                                        size="4"
                                        bold
                                        color="heading"
                                        labelKey="settings.fingerprintConfiguration.configuredDevices"
                                    />
                                </Box>
                                {this.renderDevices()}
                            </Col>
                        </Row>

                        <Row className="height-auto">
                            {this.renderText()}

                            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                {this.renderConfigureButton()}
                            </Col>
                        </Row>
                    </Box>
                </form>
            </>
        );
    }

    deleteDevice = () => {
        const { itemToDelete } = this.state;

        const { dispatch } = this.props;
        dispatch(fingerprintActions.fingerprintDeleteDevice(itemToDelete));
        this.hideDefaultModal();
    };

    hideDefaultModal = () => {
        this.setState({ showDefaultModal: false, itemToDelete: null });
    };

    showDefaultModal = (item) => {
        this.setState({ showDefaultModal: true, itemToDelete: item });
    };

    configureNow = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(fingerprintActions.fingerprintVerification());
    };

    enrollFingerprintOnDevice = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(fingerprintActions.enrollFingerprintOnDevice());
    };

    handleHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    isDeviceIdInSession = () => {
        const { fingerprintConfiguredUserDevices } = this.props;
        if (deviceUtils.isMobileNativeFunc() && fingerprintConfiguredUserDevices) {
            const device = fingerprintConfiguredUserDevices.filter(
                (item) => item.deviceId.search(deviceUtils.getDeviceId()) !== -1,
            );
            return !isEmpty(device);
        }
        return false;
    };

    isBiometricAvailable = () => {
        const { isDeviceWithFingerprint, availability } = this.props;
        return (
            deviceUtils.isMobileNativeFunc() &&
            !isDeviceWithFingerprint &&
            availability &&
            availability.isHardwareDetected &&
            !this.isDeviceIdInSession()
        );
    };

    renderDevices() {
        const { fingerprintConfiguredUserDevices, isDesktop } = this.props;

        if (fingerprintConfiguredUserDevices && fingerprintConfiguredUserDevices.length) {
            return (
                <ul className="navigational-list">
                    {Object.keys(fingerprintConfiguredUserDevices).map((deviceIndex) => {
                        const { deviceId, deviceModel, idSession } = fingerprintConfiguredUserDevices[deviceIndex];

                        return (
                            <Box
                                component="li"
                                display="flex"
                                alignX="between"
                                alignY="center"
                                background="white"
                                fullWidth
                                borderRadius="md"
                                className="px-5 py-2 mb-3"
                                key={deviceIndex}>
                                <Box display="flex" flex1>
                                    <Text
                                        size={isDesktop ? "6" : "5"}
                                        color="heading"
                                        labelKey={`devices.apple.identifier.${deviceModel}`}
                                        defaultValue={deviceModel}
                                        ellipsis
                                    />
                                </Box>
                                <Box display="flex" flex1>
                                    <Text size={isDesktop ? "6" : "5"} color="heading" ellipsis>
                                        {deviceId && deviceId.length >= 4
                                            ? deviceId.substring(deviceId.length - 4, deviceId.length)
                                            : deviceModel}
                                    </Text>
                                </Box>
                                <Button
                                    image="images/icons/trash.svg"
                                    label="settings.fingerprintConfiguration.device.remove"
                                    className="btn-only-icon"
                                    id={deviceIndex}
                                    onClick={() => this.showDefaultModal(idSession)}
                                    bsStyle="circle"
                                />
                            </Box>
                        );
                    })}
                </ul>
            );
        }
        return (
            <Box
                display="flex"
                alignX="center"
                alignY="center"
                background="white"
                fullWidth
                borderRadius="md"
                className="px-5 py-5 mb-3">
                <Text
                    labelKey="settings.fingerprintConfiguration.configuredDevices.none"
                    align="center"
                    size={isDesktop ? "6" : "5"}
                    color="heading-color"
                />
            </Box>
        );
    }

    renderConfigureButton() {
        const { availability } = this.props;
        if (this.isBiometricAvailable()) {
            if (availability.hasEnrolledFingerprints && availability.isAvailable) {
                return (
                    <Row>
                        <Col sm={12} className="col-12">
                            <Button
                                className="mb-9"
                                label="settings.fingerprintConfiguration.device.register"
                                bsStyle="primary"
                                onClick={this.configureNow}
                                block
                            />
                        </Col>
                    </Row>
                );
            }

            return (
                <Row>
                    <Col sm={12} className="col-12">
                        <Button
                            className="mb-9"
                            label="settings.fingerprintConfiguration.device.enrolledFingerprints.enroll"
                            bsStyle="primary"
                            onClick={this.enrollFingerprintOnDevice}
                            block
                        />
                    </Col>
                </Row>
            );
        }
        return null;
    }

    renderText() {
        const { availability } = this.props;
        if (this.isBiometricAvailable()) {
            if (availability.hasEnrolledFingerprints && availability.isAvailable) {
                return (
                    <Box display="flex" alignX="center" fullWidth>
                        <Text size="6" align="center" labelKey="settings.fingerprintConfiguration.warning" />
                    </Box>
                );
            }
            return (
                <Box display="flex" alignX="center" fullWidth>
                    <Text labelKey="settings.fingerprintConfiguration.device.enrolledFingerprints.none" />
                </Box>
            );
        }
        return null;
    }

    render() {
        const { fetching } = this.props;
        const { showDefaultModal } = this.state;

        return (
            <>
                <Notification scopeToShow="fingerprintConfiguration" />
                <Head title="settings.biometrics" backLinkTo="/settings" />
                <MainContainer showLoader={fetching}>{this.getContent()}</MainContainer>
                <DefaultModal
                    modalShow={showDefaultModal}
                    acceptFunction={this.deleteDevice}
                    cancelFunction={this.hideDefaultModal}
                    headingText={i18n.get("settings.fingerprintConfiguration.modal.title")}
                    text={i18n.get("settings.fingerprintConfiguration.modal.text")}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: fingerprintSelectors.getFetching(state),
    availability: fingerprintSelectors.getAvailability(state),
    isDeviceWithFingerprint: fingerprintSelectors.getIsDeviceWithFingerprint(state),
    fingerprintConfiguredUserDevices: fingerprintSelectors.getFingerprintConfiguredUserDevices(state),
});

export default connect(mapStateToProps)(FingerprintConfiguration);
