import React from "react";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Button from "pages/_components/Button";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import * as i18n from "util/i18n";
import * as config from "util/config";
import { func, instanceOf, shape } from "prop-types";
import Selector from "pages/_components/fields/formik/Selector";

const FORM_ID = "creditCards.movements.filters";
const MovementsFilter = ({ initialData, minDate, handleFilter, handleCancel }) => {
    
    const optionsDate = [];
    let currentDate = new Date();
    for (let i = 0; i < 6; i++) {
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();
        let formattedDate = `${String(month).padStart(2, '0')}/${year}`;
            optionsDate.push({
            value: formattedDate,
            label: formattedDate,
        });
        currentDate.setMonth(currentDate.getMonth() - 1);
    }

    return (
        <Formik
            initialValues={{
                selectedDate: initialData.selectedDate,
                transactionType: initialData.transactionType,
            }}
            onSubmit={(values) => {
                handleFilter(values);
            }}
        >
            {(formik) => (
                <Form>
                    <Box column fullWidth className={classNames("mb-5")}>
                        <Row>
                           <Field
                                component={Selector}
                                options={optionsDate}
                                idForm={FORM_ID}
                                name="selectedDate"
                                placeholder="Mes y año"
                            />
                        </Row>
                    </Box>

                    <Box display="flex" column fullWidth className={classNames("mb-5")}>
                        <Row className={classNames("px-0", { "d-flex flex-column-reverse": !isDesktop })}>
                            <Col xs={6}>
                                <Button
                                    type="button"
                                    label="global.cancel"
                                    bsStyle="outline"
                                    onClick={handleCancel}
                                    block
                                />
                            </Col>
                            <Col xs={6}>
                                <Button
                                    type="submit"
                                    label="product.filters.filter"
                                    bsStyle="primary"
                                    disabled={!formik.values.selectedDate}
                                    block
                                />
                            </Col>
                        </Row>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

MovementsFilter.propTypes = {
    minDate: instanceOf(Date),
    initialData: shape({}).isRequired,
    handleFilter: func.isRequired,
    handleCancel: func.isRequired,
};

export default MovementsFilter;
