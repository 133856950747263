import Loader from "pages/_components/Loader";
import { arrayOf, bool, node, oneOfType, string } from "prop-types";
import React, { Component } from "react";

/**
 * * Puede considerarse ubicarlo en sitios diferentes para bloquear toda o parte de la app
 */
class PageLoading extends Component {
    static propTypes = {
        bgTransparent: bool,
        children: oneOfType([arrayOf(node), node]),
        loading: bool,
        loaderSmallBoxed: bool,
        zIndex: string,
        classicStyle: bool,
        borderRadius: string,
        loadChildrenAtOnce: bool,
    };

    static defaultProps = {
        bgTransparent: false,
        children: null,
        loading: true,
        loaderSmallBoxed: false,
        zIndex: "loader",
        classicStyle: true,
        borderRadius: null,
        loadChildrenAtOnce: false,
    };

    render() {
        const {
            bgTransparent,
            children,
            loading,
            loaderSmallBoxed,
            zIndex,
            classicStyle,
            borderRadius,
            loadChildrenAtOnce,
        } = this.props;

        const loaderProps = {
            zIndex: classicStyle ? "8" : zIndex,
            classicStyle,
            bgTransparent,
            loaderSmallBoxed,
            borderRadius,
        };

        return (
            <>
                {/* eslint-disable-next-line no-nested-ternary */}
                {loadChildrenAtOnce ? (
                    <>
                        {loading && <Loader {...loaderProps} />}
                        <div {...(loading && { className: "visually-hidden full-height full-width" })}>{children}</div>
                    </>
                ) : loading ? (
                    <>
                        <Loader {...loaderProps} />
                        {loaderSmallBoxed && children}
                    </>
                ) : (
                    children
                )}
            </>
        );
    }
}

export default PageLoading;
