import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LIST_REQUESTS_AND_CLAIMS_REQUEST: "requestAndClaims/LIST_REQUESTS_AND_CLAIMS_REQUEST",
    LIST_REQUESTS_AND_CLAIMS_RESPONSE: "requestAndClaims/LIST_REQUESTS_AND_CLAIMS_RESPONSE",
};

export const INITIAL_STATE = {
    listRequestAndClaims: [],
    offset: 0,
    limit: 0,
    moreRecords: false,
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_REQUESTS_AND_CLAIMS_REQUEST]: (state) => ({
        ...state,
    }),
    [types.LIST_REQUESTS_AND_CLAIMS_RESPONSE]: (state, action) => {
        let Acumulatelist = [];
        if (action.data.offset === 0) {
            Acumulatelist = action.data.requestsandclaims;
        } else {
            Acumulatelist = [...state.listRequestAndClaims, ...action.data.requestsandclaims];
        }
        return {
            ...state,
            listRequestAndClaims: Acumulatelist,
            offset: action.data.offset,
            limit: action.data.limit,
            moreRecords: action.data.moreRecords,
        };
    },
});

export const actions = {
    listRequestsAndClaimsRequest: makeActionCreator(types.LIST_REQUESTS_AND_CLAIMS_REQUEST, "filters", "onFinish"),
    listRequestsAndClaimsSuccess: makeActionCreator(types.LIST_REQUESTS_AND_CLAIMS_RESPONSE, "data"),
};

export const selectors = {
    getRequestAndClaims: ({ requestAndComplaints }) => requestAndComplaints,
};
