import * as API from "middleware/api";

export const secondFactorPreview = (deviceUUID, deviceModel, deviceBrand, hasTokenSeed, idActivity, exchangeToken) => {
    if (exchangeToken) {
        return API.executeWithExchangeToken(
            "/second.factor.preview.public",
            {
                deviceUUID,
                deviceModel,
                deviceBrand,
                hasTokenSeed,
                idActivityToRead: idActivity,
            },
            exchangeToken,
        );
    }

    return API.executeWithAccessToken("/second.factor.preview", {
        deviceUUID,
        deviceModel,
        deviceBrand,
        hasTokenSeed,
        idActivityToRead: idActivity
    });
};

export const secondFactorVerificationCode = (exchangeToken, idActivity) => {
    if (exchangeToken) {
        return API.executeWithExchangeToken("/second.factor.verificationCode.public", {
            idActivityToRead: idActivity,
        }, exchangeToken);
    }

    return API.executeWithAccessToken("/second.factor.verificationCode", {idActivityToRead: idActivity});
};

export const secondFactorVerificationQuestions = (exchangeToken, isChange) => {
    if (exchangeToken) {
        return API.executeWithExchangeToken("/second.factor.verificationQuestions.public", { isChange }, exchangeToken);
    }

    return API.executeWithAccessToken("/second.factor.verificationQuestions", { isChange });
};
