
export const types = {
    SHOW_NOTIFICATION: "notification/SHOW_NOTIFICATION",
    SHOW_NOTIFICATION_WITH_TITLE: "notification/SHOW_NOTIFICATION_WITH_TITLE",
    REMOVE_NOTIFICATION: "notification/REMOVE_NOTIFICATION",
};

export const INITIAL_STATE = {
    message: null,
    level: null,
    scopes: [],
    cont: 0,
    clearNotifications: true,
    timeOut: null,
    rightComponent: undefined,
    autoDismiss: "5",
    persistent:false,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.SHOW_NOTIFICATION:
            return {
                ...state,
                message: action.message,
                level: action.level,
                scopes: action.scopes,
                cont: state.cont + 1,
                clearNotifications: action?.clearNotifications !== undefined ? action.clearNotifications : true,
                timeOut: action.timeOut,
                rightComponent: action?.rightComponent,
                autoDismiss:action?.autoDismiss,
                persistent:action?.persistent,
            };
        case types.SHOW_NOTIFICATION_WITH_TITLE:
            return {
                ...state,
                title: action.title,
                message: action.message,
                level: action.level,
                scopes: action.scopes,
                cont: state.cont + 1,
                clearNotifications: action?.clearNotifications !== undefined ? action.clearNotifications : true,
                timeOut: action.timeOut,
                rightComponent: action?.rightComponent,
                autoDismiss:action?.autoDismiss,
                persistent:action?.persistent,
            };
        case types.REMOVE_NOTIFICATION:
            return INITIAL_STATE;
        default:
            return state;
    }
};

// Action creators
export const actions = {
    showNotification: (message, level, scopes, clearNotifications = true, timeOut = null, rightComponent=undefined,autoDismiss="5",persistent=false) => ({
        type: types.SHOW_NOTIFICATION,
        message,
        level,
        scopes,
        clearNotifications,
        timeOut,
        rightComponent,
        autoDismiss,
        persistent,
    }),
    showNotificationWithTitle: (title, message, level, scopes, clearNotifications = true, timeOut = null, rightComponent=undefined,autoDismiss="5",persistent=false) => ({
        type: types.SHOW_NOTIFICATION_WITH_TITLE,
        title,
        message,
        level,
        scopes,
        clearNotifications,
        timeOut,
        rightComponent,
        autoDismiss,
        persistent,
    }),
    removeNotification: () => ({
        type: types.REMOVE_NOTIFICATION,
    }),
};

// Selectors
export const selectors = {
    getTitle: (state) => state.notification.title,
    getMessage: (state) => state.notification.message,
    getLevel: (state) => state.notification.level,
    getScopes: (state) => state.notification.scopes,
    getCont: (state) => state.notification.cont,
    getClearNotifications: (state) =>
        (state?.notification?.clearNotifications !== undefined ? state?.notification?.clearNotifications : true),
    getTimeOut: (state) => state.notification.timeOut,
    getRightComponent: (state) => state.notification.rightComponent,
    getAutodismiss: (state) => state.notification.autoDismiss,
    getPersistent: (state)=>state.notification.persistent,

};
