import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { compose } from "redux";
import EnrollmentWrapper from "../_components/EnrollmentWrapper";

class SelectAvatar extends Component {
    state = {
        loading: false,
    };

    selectedAvatar = (avatar) => {
        const { dispatch } = this.props;
        dispatch(enrollmentActions.saveSelectedAvatar(avatar));
    };

    cancel = () => {
        const { dispatch } = this.props;
        dispatch(enrollmentActions.showExitEnrollmentModal(true));
    };

    submitAvatar = () => {
        const { dispatch, isMigrated, userInfo } = this.props;
        const { updateSequrityQuestions } = userInfo;
        if (isMigrated && updateSequrityQuestions) {
            this.setState({ loading: true });
            dispatch(enrollmentActions.getQuestions());
        } else {
            dispatch(push("/enrollment/termsAndConditions"));
        }
    };

    importAll = (r) => {
        const images = [];
        r.keys().forEach((item, index) => {
            const itemFormat = item.replace("./", "");
            const image = {
                id: index + 1,
                name: item.replace("./", "").replace(".png", ""),
                src: `images/securityimages/${itemFormat}`,
            };
            images.push(image);
        });
        return images;
    };

    SealList = () => {
        const sealImages = this.importAll(require.context("styles/images/securityimages", false, /\.png$/));
        return sealImages;
    };

    generateAvatarList = (avatars) => {
        const { selectedAvatar } = this.props;

        return (
            <>
                <Box display="flex" className="avatar-container custom-scrollbar">
                    {avatars.map((avatar) => (
                        <Box
                            className={classNames("avatar-box box-shadow-small", {
                                "avatar-box-selected": avatar.id === selectedAvatar?.id,
                            })}
                            onClick={() => {
                                this.selectedAvatar(avatar);
                            }}>
                            <Box className="enrollment-avatar" alignX="center" display="flex">
                                <Image
                                    // className="enrollment-avatar"
                                    src={avatar.src}
                                    height="12"
                                    wrapperWidth="12"
                                    wrapperHeight="12"
                                />
                            </Box>
                            <Box alignX="center" display="flex" className="mt-3 p-2">
                                <label
                                    className={
                                        avatar.id === selectedAvatar?.id ? "input-circle checked" : "input-circle"
                                    }
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>
            </>
        );
    };

    onBack = () => {
        const { dispatch, isMigrated } = this.props;
        if (isMigrated) {
            dispatch(push("/enrollment/validateUser"));
        } else {
            dispatch(push("/enrollment/createUser"));
        }
    };

    content = () => {
        const { isDesktop, selectedAvatar } = this.props;
        const { loading } = this.state;
        const avatars = this.SealList();
        const renderAvatars = this.generateAvatarList(avatars);
        return (
            <>
                <Box className="avatar-wrapper" display="flex" column fullWidth>
                    <Box display="flex" fullWidth className="login-title-container mb-7">
                        {isDesktop && (
                            <Box className="login-title-icon" onClick={this.onBack} pointer>
                                <Image src="images/arrow_back.svg" />
                            </Box>
                        )}
                        <Box fullWidth className="login-title-text pl-4">
                            <Text
                                size={isDesktop ? "3" : "2"}
                                className="btn-link-span"
                                bold
                                labelKey="enrollment.step4.title"
                            />
                        </Box>
                    </Box>
                    <Box {...(!isDesktop && { className: "mt-auto" })}>{renderAvatars}</Box>

                    <Box className="mt-5">
                        <Row>
                            <Col xs={isDesktop ? "6" : "12"}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={loading}
                                    type="submit"
                                    onClick={() => {
                                        this.submitAvatar();
                                    }}
                                    block
                                    className="full-width"
                                    disabled={selectedAvatar === null}
                                />
                            </Col>
                            <Col xs={isDesktop ? "6" : "12"}>
                                <Button
                                    label="global.cancel"
                                    bsStyle="outline"
                                    block
                                    onClick={() => {
                                        this.cancel();
                                    }}
                                />
                            </Col>
                        </Row>
                    </Box>
                </Box>
            </>
        );
    };

    render() {
        const { isDesktop } = this.props;

        return (
            <EnrollmentWrapper onBack={this.onBack} isDesktop={isDesktop}>
                <>{this.content()}</>
            </EnrollmentWrapper>
        );
    }
}

SelectAvatar.propTypes = {
    dispatch: func.isRequired,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    selectedAvatar: shape({
        id: string,
        src: string,
    }).isRequired,
    showExitModal: bool.isRequired,
    isMigrated: bool.isRequired,
    userInfo: shape({
        updateSequrityQuestions: bool,
    }).isRequired,
};
const mapStateToProps = (state) => ({
    selectedAvatar: enrollmentSelectors.getSelectedAvatar(state),
    isMigrated: enrollmentSelectors.isMigrated(state),
    userInfo: enrollmentSelectors.getUserInfo(state),
});

export default compose(connect(mapStateToProps))(SelectAvatar);
