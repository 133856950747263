import React, { Component } from "react";
import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, node, shape, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as formActions } from "reducers/form";
import { getTransactionKind } from "util/transaction";
import { isDesktop } from "react-device-detect";
import { simpleMaskEmail } from "util/format";

class AdministrationTicket extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        transactionData: shape({
            data: shape({}),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
        user: shape({
            userId: string,
        }).isRequired,
        emailUserCreator: string,
        fetching: bool.isRequired,
        children: node.isRequired,
        isCancelling: bool,
        showButtons: bool,
        fromPending: bool,
        fromAction: bool,
        handleBack: func.isRequired,
    };

    static defaultProps = {
        emailUserCreator: "",
        isCancelling: false,
        showButtons: true,
        fromPending: false,
        fromAction: false,
    };

    onClick = (action) => {
        const { dispatch, transactionData, fromPending, handleDownloadPdf } = this.props;
        const { idTransaction, idActivity, idForm, data } = transactionData;
        switch (action) {
            case "downloadTicket":
                dispatch(formActions.downloadTicket(idTransaction, "pdf", idForm));
                break;
            case "shareTicket":
                dispatch(formActions.shareTicket(idTransaction, "pdf", idForm));
                break;
            case "cancelTransaction":
                // dispatch(
                //     formActions.cancelTransactionPre({
                //         idActivity: transactionData.idActivity,
                //         idTransaction: transactionData.idTransaction,
                //         ticketData: transactionData.data,
                //     }),
                // );
                dispatch(notificationActions.showNotification("Estamos trabajando en eso!!", "warning", ["ticket"]));
                break;
            case "rejectTransaction":
                dispatch(
                    formActions.cancelTransactionPre({
                        idActivity,
                        idTransaction,
                        ticketData: data,
                        fromPending,
                    }),
                );
                break;
            default:
                dispatch(
                    formActions.signTransactionPreview({
                        idActivity,
                        idTransaction,
                        ticketData: data,
                        fromPending,
                    }),
                );
                break;
        }
    };

    userHasSigned = (signatures) => {
        const { user } = this.props;
        let hasSigned = false;
        signatures.forEach((element) => {
            if (element.idUser === user.userId) {
                hasSigned = true;
            }
        });

        return hasSigned;
    };

    getViewButtons = () => {
        const { fetching, transactionData, fromAction, handleBack, handleDownloadPdf } = this.props;

        if (transactionData) {
            const { idTransactionStatus, signatures } = transactionData;

            const showOnlyReject = this.userHasSigned(signatures);
            if (idTransactionStatus === "PENDING") {
                return (
                    <>
                        {showOnlyReject ? (
                            <Row
                                className={classNames("mt-7 row-template-center", {
                                    "d-flex": !isDesktop,
                                })}>
                                <Col xs={3} xsOffset={4}>
                                    <Button
                                        bsStyle="outline"
                                        onClick={() => this.onClick("rejectTransaction")}
                                        label="forms.declineTransaction.link"
                                        loading={fetching}
                                        btnUppercase={false}
                                        block
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <Row gapY="7" className="mt-7">
                                <Col lg={6} lgOffset={3}>
                                    <Row
                                        className={classNames("justify-content-center", {
                                            "d-flex flex-column-reverse": !isDesktop,
                                        })}>
                                        <Col xs={6}>
                                            <Button
                                                bsStyle="outline"
                                                onClick={() => this.onClick("rejectTransaction")}
                                                label="forms.declineTransaction.link"
                                                loading={fetching}
                                                btnUppercase={false}
                                                block
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <Button
                                                onClick={() => this.onClick("signTransaction")}
                                                label="forms.signTransaction.link"
                                                bsStyle="primary"
                                                loading={fetching}
                                                btnUppercase={false}
                                                block
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </>
                );
            }
            return (
                <>
                    {fromAction ? (
                        <Row gapY="7" className="mt-7">
                            <Col lg={6} lgOffset={3}>
                                <Row
                                    className={classNames("justify-content-center", {
                                        "d-flex flex-column-reverse": !isDesktop,
                                    })}>
                                    <Col xs={6}>
                                        <Button
                                            bsStyle="outline"
                                            onClick={handleBack}
                                            label="forms.otherApproval.link"
                                            loading={fetching}
                                            btnUppercase={false}
                                            block
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <Button
                                            onClick={handleDownloadPdf}
                                            label={
                                                isDesktop ? "transactions.button.download" : "transactions.button.share"
                                            }
                                            image={
                                                isDesktop ? "images/icons/download.svg" : "images/icons/shareTicket.svg"
                                            }
                                            bsStyle="primary"
                                            loading={fetching}
                                            btnUppercase={false}
                                            block
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ) : (
                        <Row
                            className={classNames("mt-7 row-template-center", {
                                "d-flex": !isDesktop,
                            })}>
                            <Col xs={3} xsOffset={4}>
                                <Button
                                    bsStyle="primary"
                                    onClick={handleDownloadPdf}
                                    label={isDesktop ? "transactions.button.download" : "transactions.button.share"}
                                    image={isDesktop ? "images/icons/download.svg" : "images/icons/shareTicket.svg"}
                                    loading={fetching}
                                    btnUppercase={false}
                                    block
                                />
                            </Col>
                        </Row>
                    )}
                </>
            );
        }
        return null;
    };

    render() {
        const { transactionData, children, isCancelling, showButtons, emailUserCreator } = this.props;
        const viewButtons = this.getViewButtons();

        const transaction = transactionData;

        const statusIcon = () => {
            let icon = "";
            if (transactionData.idTransactionStatus === "FINISHED") {
                icon = "success.gif";
            } else if (transactionData.idTransactionStatus === "PARCIAL") {
                icon = "partial.svg";
            } else if (transactionData.idTransactionStatus === "SCHEDULED") {
                icon = "success-scheduled.svg";
            } else if (
                transactionData.idTransactionStatus === "PROCESSING" ||
                transactionData.idTransactionStatus === "DRAFT"
            ) {
                icon = "processing.svg";
            } else if (transactionData.idTransactionStatus === "PENDING") {
                icon = "pending.svg";
            } else {
                icon = "error.svg";
            }
            return icon;
        };
        const titleKind = getTransactionKind(transaction?.idActivity);
        let labelKeyHeader = `forms.transaction.ticket.status.${transaction.idTransactionStatus}.${titleKind}`;

        // const labelCancelDetail = "forms.transfers.confirmation.cancel.detail";

        if (isCancelling) {
            labelKeyHeader = "forms.transfers.confirmation.cancel.title";
        }

        if (transactionData.data) {
            return (
                <>
                    <Box
                        background="white"
                        borderRadius="lg"
                        className={classNames({
                            "box-shadow-small": isDesktop,
                        })}>
                        <Row gapY="5">
                            <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2}>
                                <Box component="article" className="ticket">
                                    <Box
                                        component="header"
                                        display="flex"
                                        alignX="center"
                                        alignY="center"
                                        className={classNames(`ticket-header  ${statusIcon()}`)}>
                                        <Box
                                            className={classNames("ticket-header-info", {
                                                "mt-5": isDesktop,
                                                "mt-2": !isDesktop,
                                            })}>
                                            <Box display="flex" alignX="center">
                                                <Image
                                                    src={`images/icons/tickets/${statusIcon()}`}
                                                    wrapperClassName="ticket-header-image"
                                                />
                                            </Box>
                                            <Box className="mt-6">
                                                <Text className="ticket-header-text" labelKey={labelKeyHeader} />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box
                                        className={classNames("default-ticket-content zig-zag-border", {
                                            "success-ticket":
                                                statusIcon() === "success" || statusIcon() === "success-scheduled",
                                            "partial-ticket": statusIcon() === "partial",
                                        })}
                                        fullWidth
                                        background="white"
                                        position="relative">
                                        <Box display="flex" column className="pt-0">
                                            <Box display="flex" column className="mb-5">
                                                {transactionData.idTransactionStatus !== "PENDING" && (
                                                    <Box className="text-center mt-5 mb-3">
                                                        <Text
                                                            labelKey="transaction.ticket.status.notification.message"
                                                            size={isDesktop ? "7" : "6"}
                                                        />
                                                        <Box className="mt-1">
                                                            <Text size="6" bold>
                                                                {simpleMaskEmail(emailUserCreator, "x")}
                                                            </Text>
                                                        </Box>
                                                    </Box>
                                                )}
                                                {children}
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/* </div> */}
                                </Box>
                            </Col>
                        </Row>
                    </Box>

                    {viewButtons && showButtons && <> {viewButtons}</>}
                </>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    user: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(AdministrationTicket);
