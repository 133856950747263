import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { Redirect } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { arrayOf, bool, func, shape, string } from "prop-types";
import * as Yup from "yup";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";
import Selector from "pages/_components/fields/formik/Selector";
import StepperFieldGroup from "pages/_components/fields/StepperFieldGroup";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import TextField from "pages/_components/fields/TextField";
import { SPECIAL_CHARACTERS_REGEX } from "constants.js";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Row from "pages/_components/Row";
import ShowCurrency from "./ShowCurrency";
import Permissions from "./cashProductField/Permissions";

const FORM_ID = "administration.signatures.create";

class SignaturesSchemeCreate extends Component {
    static propTypes = {
        actions: shape({
            createSignaturesSchemePreRequest: func,
        }),
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        capFrequencyList: arrayOf(string),
        fetching: bool,
        isDesktop: bool.isRequired,
        masterCurrency: string,
        routerActions: shape({
            goBack: func,
            push: func,
        }),
        signatureTypeList: arrayOf(string),
        selectedFunctionalGroups: shape({}),
        groups: arrayOf(shape({})),
    };

    static defaultProps = {
        actions: null,
        capFrequencyList: [],
        fetching: false,
        masterCurrency: configUtils.get("core.masterCurrency"),
        routerActions: null,
        signatureTypeList: null,
        selectedFunctionalGroups: {},
        groups: [],
    };

    componentDidMount() {
        const { actions } = this.props;
        actions.createSignaturesSchemePreRequest();
    }

    handleSubmit = ({
        alias,
        functionalGroups,
        signatureType,
        signatureLevelsCounts,
        toggleFunctionalGroups,
        topAmount,
    }, formikBag) => {
        const {
            credentialGroups,
            actions,
        } = this.props;

        actions.createSignaturesSchemePreviewRequest(
            {
                alias,
                selectedFunctionalGroups: toggleFunctionalGroups ? functionalGroups : {},
                signatureLevelsCounts,
                signatureType,
                topAmount,
                signatureDispatch: false,
            },
            credentialGroups,
            formikBag,
        );
    };

    renderContent = () => {
        const {
            activeEnvironment: { administrationScheme },
            selectedFunctionalGroups,
        } = this.props;

        let signatureLevelsCounts = {};
        if (administrationScheme === "advanced") {
            signatureLevelsCounts = configUtils.getArray("administration.signatures.signatureLevels").reduce(
                (acc, signLevel) => ({
                    ...acc,
                    [signLevel]: 0,
                }),
                signatureLevelsCounts,
            );
        } else {
            signatureLevelsCounts = {
                A: 0,
            };
        }

        const initialValues = {
            alias: "",
            functionalGroups: selectedFunctionalGroups || {},
            signatureLevelsCounts,
            toggleFunctionalGroups: selectedFunctionalGroups.length > 0,
            topAmount: {
                amount: "",
                period: configUtils.get("administration.signatures.topAmount.defaultFrequency"),
            },
            signatureType: "",
            signatureDispatch: false,
        };

        return (
            <Fragment>
                <MainContainer>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={this.validationSchema}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </Fragment>
        );
    };

    renderForm = ({ isSubmitting, values }) => {
        const { activeEnvironment, capFrequencyList, groups, masterCurrency, signatureTypeList } = this.props;
        const { administrationScheme } = activeEnvironment;
        const { signatureType, toggleFunctionalGroups } = values;

        return (
            <Form className="above-the-fold">
                <Box className="border-radius-lg p-7 mt-3 box-shadow-small background-white">
                    <Box className="p-7 border-radius-lg box-shadow-small">
                        <Field component={TextField} idForm={FORM_ID} name="alias" type="text" autoComplete="off" />
                    </Box>
                    <Box className="p-7 mt-7 border-radius-lg box-shadow-small">
                        {this.renderRequiredSignatures(administrationScheme)}
                    </Box>
                    <Box className="p-7 mt-7 border-radius-lg box-shadow-small">
                        {signatureTypeList &&
                            this.renderTransactions(
                                capFrequencyList,
                                masterCurrency,
                                signatureTypeList,
                                administrationScheme === "advanced" && signatureType && signatureType === "AMOUNT",
                            )}
                    </Box>
                    <Box className="p-7 mt-7 border-radius-lg box-shadow-small">
                        {groups && this.renderFunctionalGroups(groups, toggleFunctionalGroups)}
                    </Box>
                </Box>
                <Row className="mt-7">
                    <Col xs={3} xsOffset={3}>
                        <Button
                            bsStyle="outline"
                            label="global.cancel"
                            loading={isSubmitting}
                            btnUppercase={false}
                            block
                            type="button"
                            onClick={this.handleBack}
                        />
                    </Col>
                    <Col xs={3}>
                        <Button
                            bsStyle="primary"
                            label="global.continue"
                            loading={isSubmitting}
                            btnUppercase={false}
                            block
                            type="submit"
                        />
                    </Col>
                </Row>
            </Form>
        );
    };

    renderFunctionalGroups = (groups, showFunctionalGroups) => (
        <Fragment>
            <Field component={SwitchField} idForm={FORM_ID} name="toggleFunctionalGroups" />
            {showFunctionalGroups && (
                <Permissions
                    fieldName="functionalGroups"
                    administrationSchema="advanced"
                    data={{ groups, products: [] }}
                    mode="edit"
                    reduced
                />
            )}
        </Fragment>
    );

    renderRequiredSignatures = (administrationScheme) => (
        <>
            <Box>
                <Text component="h4" semibold labelKey="administration.signatures.create.requiredSignatures" />
            </Box>
            <Box className="mt-5 mb-3">
                <Text size="6" labelKey="administration.signatures.create.info" />
            </Box>
            <Box>
                <Text size="7" labelKey={`administration.signatures.create.${administrationScheme}.signersCount`} />
            </Box>
            <div className="form-group form-group--stepper-field">
                <Field
                    component={StepperFieldGroup}
                    hideLabel={administrationScheme === "medium"}
                    idForm={FORM_ID}
                    name="signatureLevelsCounts"
                    min="0"
                    max={configUtils.get("administration.signatures.maxNeeded")}
                    options={
                        administrationScheme === "medium"
                            ? ["A"]
                            : configUtils.getArray("administration.signatures.signatureLevels")
                    }
                    showFirstErrorWhenEquals
                />
            </div>
        </>
    );

    renderTransactions = (capFrequencyList, masterCurrency, signatureTypeList, transactionWithAmount) => (
        <Fragment>
            <Box>
                <Text component="h4" semibold labelKey="administration.signatures.create.transactions" />
            </Box>
            <Box className="mt-5 mb-7">
                <Text size="6" labelKey="administration.signatures.create.transactions.subtitle" />
            </Box>

            <Text size="5" semibold labelKey="administration.signatures.create.transactions.type" />
            <div className="form-group">
                <div className="form-group-control-list">
                    <Field
                        component={Selector}
                        idForm={FORM_ID}
                        name="signatureType"
                        options={signatureTypeList.map((signatureType) => ({
                            id: signatureType,
                            icon: signatureType === "NO_AMOUNT" ? "icons/no-amount.svg" : "icons/amount.svg",
                            label: i18nUtils.get(`administration.signatures.create.signatureType.${signatureType}`),
                        }))}
                        renderAs="radio"
                        radioClassNames="radio-like-buttons"
                    />
                </div>
            </div>
            {transactionWithAmount && masterCurrency && capFrequencyList && (
                <ShowCurrency
                    masterCurrency={masterCurrency}
                    capFrequencyList={capFrequencyList}
                    form={FORM_ID}
                    hideCurrency
                />
            )}
        </Fragment>
    );

    validationSchema = () => {
        const {
            activeEnvironment: { administrationScheme },
        } = this.props;
        const maxSignersCount = configUtils.get("administration.signatures.maxNeeded");

        // const specialCharactersRegex = /^[a-zA-Z0-9 ]+$/i;

        return Yup.lazy((values) =>
            Yup.object().shape({
                alias: Yup.string()
                    .matches(
                        SPECIAL_CHARACTERS_REGEX,
                        i18nUtils.get("administration.signatures.validations.name.format"),
                    )
                    .required(i18nUtils.get("administration.signatures.validations.name.required")),
                functionalGroups: values.toggleFunctionalGroups
                    ? Yup.object()
                          .nullable()
                          .test(
                              "has-any-key",
                              i18nUtils.get("administration.signatures.functionalGroups.atLeastOne"),
                              (value) => value && Object.keys(value) && Object.keys(value).length > 0,
                          )
                          .required(i18nUtils.get("administration.signatures.functionalGroups.atLeastOne"))
                    : Yup.object()
                          .nullable()
                          .notRequired(),
                signatureLevelsCounts: Yup.object().shape(
                    administrationScheme === "medium"
                        ? {
                              A: Yup.number()
                                  .moreThan(
                                      0,
                                      i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                          maxSignersCount,
                                      }),
                                  )
                                  .max(
                                      maxSignersCount,
                                      i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                          maxSignersCount,
                                      }),
                                  )
                                  .required(i18nUtils.get(`${FORM_ID}.signersCount.medium.required`))
                                  .typeError(i18nUtils.get(`${FORM_ID}.signersCount.medium.required`)),
                          }
                        : configUtils.getArray("administration.signatures.signatureLevels").reduce((levels, level) => {
                              const newLevels = levels;
                              newLevels[level] = Object.values(values.signatureLevelsCounts).some((value) => value)
                                  ? Yup.number()
                                        .min(
                                            0,
                                            i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                                maxSignersCount,
                                            }),
                                        )
                                        .max(
                                            maxSignersCount,
                                            i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                                maxSignersCount,
                                            }),
                                        )
                                        .required(i18nUtils.get(`${FORM_ID}.signersCount.advanced.required`))
                                        .typeError(i18nUtils.get(`${FORM_ID}.signersCount.advanced.required`))
                                  : Yup.number().moreThan(0, i18nUtils.get(`${FORM_ID}.signersCount.atLeastOne`));
                              return newLevels;
                          }, {}),
                ),
                signatureType: Yup.string().required(i18nUtils.get(`${FORM_ID}.signatureType.required`)),
                topAmount: Yup.object().when("signatureType", {
                    is: (signatureType) => administrationScheme === "advanced" && signatureType === "AMOUNT",
                    then: Yup.object().shape({
                        amount: Yup.number()
                            .moreThan(0, i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.mustBePositive`))
                            .required(i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.required`))
                            .typeError(i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.required`)),
                        period: Yup.string().required(),
                    }),
                    otherwise: Yup.object().notRequired(),
                }),
            }),
        );
    };

    handleBack = () => {
        const {
            routerActions,
            activeEnvironment: { administrationScheme },
        } = this.props;
        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes`);
    };

    render() {
        const { fetching, isDesktop } = this.props;

        if (!isDesktop) {
            return <Redirect to="/desktop" />;
        }

        return (
            <Fragment>
                <Notification scopeToShow="administrationCreateSignatureScheme" />
                <Head
                    title="administration.signatures.create.title"
                    onBack={this.handleBack}
                    textBack="administration.signatures.returnToList"
                />
                <PageLoading loading={fetching}>{this.renderContent()}</PageLoading>
            </Fragment>
        );
    }
}

export default resizableRoute(SignaturesSchemeCreate);
