import EnrollmentStepIndicator, { Step } from "pages/enrollment/_components/EnrollmentStepIndicator";
import I18n from "pages/_components/I18n";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";

import { shape, number, string, bool, arrayOf } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { selectors as enrollmentSelectors } from "reducers/enrollment";
import { isMobile } from "react-device-detect";

const Stepper = ({ currentStep, className, completed, stepsList, showLabelMobile, isDesktop }) => {
    const stepLabel = stepsList.find((item) => item.stepNumber === currentStep);

    return (
        <Box className={className}>
            <EnrollmentStepIndicator className="stepper-default">
                {stepsList.map((step) => (
                    <>
                        <Step currentStep={currentStep} stepNumber={step.stepNumber} completed={completed}>
                            <I18n id={step.label} />
                        </Step>
                    </>
                ))}
            </EnrollmentStepIndicator>
            {showLabelMobile && !isDesktop && (
                <Box display="flex" fullWidth className="mb-3">
                    <Text
                        labelKey="stepper.mobile.label"
                        step={currentStep}
                        total={stepsList.length}
                        size={isMobile ? "3" : "5"}
                    />
                    &nbsp;
                    <Text labelKey={stepLabel.label} size={isMobile ? "3" : "5"} bold />
                </Box>
            )}
        </Box>
    );
};

Stepper.propTypes = {
    isDesktop: bool.isRequired,
    currentStep: number.isRequired,
    completed: number,
    className: string,
    showLabelMobile: bool,
    stepsList: arrayOf(shape({})).isRequired,
};

Stepper.defaultProps = {
    completed: null,
    className: undefined,
    showLabelMobile: false,
};

const mapStateToProps = (state) => ({
    isMigrated: enrollmentSelectors.isMigrated(state),
});

export default connect(mapStateToProps)(resizableRoute(Stepper));
