import { arrayOf, bool, node, oneOfType } from "prop-types";
import React, { Component } from "react";

class WidgetLoading extends Component {
    static propTypes = {
        loading: bool,
        children: oneOfType([arrayOf(node), node]),
    };

    static defaultProps = {
        loading: true,
        children: null,
    };

    render() {
        const { loading, children } = this.props;
        return (
            <>
                {loading ? (
                    <div className="widget-preloader">
                        <div>
                            <span />
                            <span />
                            <span />
                        </div>
                    </div>
                ) : (
                    children
                )}
            </>
        );
    }
}

export default WidgetLoading;
