import { Field, Form, withFormik } from "formik";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import TextField from "pages/_components/fields/TextField";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { bool, func, shape } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as productsActions } from "reducers/products";
import { compose } from "redux";
import { getLocationBasePath } from "util/revelock";
import * as Yup from "yup";

const FORM_ID = "accounts.alias";
const PAGE_IDENTIFIER = "accounts.setAlias";
class SetAlias extends Component {
    componentDidMount() {
        const { dispatch, ...rest } = this.props;
        dispatch(accountsActions.readAccount(rest.match.params.id));
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath, match } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath(getLocationBasePath(match), PAGE_IDENTIFIER);
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    disabledButton = () => {
        const {
            account,
            values: { setAlias },
        } = this.props;
        const original = account?.productAlias || "";
        return !setAlias || setAlias?.length < 3 || original === setAlias;
    };

    characterCounter = () => {
        const {
            values: { setAlias },
        } = this.props;
        return (
            <p className="ml-3 mt-4 mr-3 color-text-disabled-color">
                {setAlias ? setAlias?.length : 0}
                {"/60"}
            </p>
        );
    };

    render() {
        const { fetching, isSubmitting } = this.props;

        return (
            <>
                <Notification scopeToShow="accounts/setAlias" />
                <Head onBack={this.handleBack} title="accounts.alias.setAlias.title" />
                <MainContainer showLoader={fetching} className="main-container border-radius-lg background-white">
                    <Form className="above-the-fold full-height">
                        <Box fullHeight fullWidth display="flex" className="pb-7" column>
                            <Box background="white" borderRadius="default" className="mt-5 p-5 pb-9 mx-n-5">
                                <Field
                                    autoFocus
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="setAlias"
                                    type="text"
                                    pattern="^[a-zA-Z0-9 ]*$"
                                    labelNoMarginTop
                                    maxLength={60}
                                    showLabel={false}
                                    inputFunctions={this.characterCounter()}
                                />
                            </Box>

                            <Button
                                type="submit"
                                className="mt-auto mb-3"
                                bsStyle="primary"
                                label="accounts.alias.setAlias.save"
                                loading={isSubmitting}
                                disabled={this.disabledButton()}
                                block
                            />

                            <Button
                                type="button"
                                className="mt-auto mb-5"
                                bsStyle="outline"
                                label="global.cancel"
                                onClick={this.handleBack}
                                block
                            />
                        </Box>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    fetching: accountsSelectors.getFetchingDetail(state),
});

SetAlias.propTypes = {
    dispatch: func.isRequired,
    fetching: bool.isRequired,
    isSubmitting: bool.isRequired,
    setLocationCustomPath: func,
    match: shape().isRequired,
};
SetAlias.defaultProps = {
    setLocationCustomPath: () => {},
};
export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ account, match }) => ({
            setAlias: account?.productAlias || "",
            productId: match.params.id,
        }),
        validationSchema: () =>
            Yup.object().shape({
                setAlias: Yup.string().nullable(),
            }),
        handleSubmit: ({ productId, setAlias }, formikBag) => {
            formikBag.props.dispatch(productsActions.changeProductAlias(setAlias, productId, true), formikBag);
        },
    }),
)(withRevelockPositionContext(SetAlias));
