import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, string } from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import * as config from "util/config";

const MenuChat = ({ exact, className }) => (
    <Box className={classNames("menu-chat mt-5 mt-md-9", className)}>
        <NavLink
            exact={exact}
            className="menu-chat-link"
            to={{ pathname: config.get("accessBar.chat.url") }}
            activeClassName="is-active"
            target="_blank">
            <Box display="flex" className=" py-2">
                <Box
                    display="flex"
                    alignX="center"
                    alignY="center"
                    borderRadius="circle"
                    background="white"
                    className="menu-chat-link-img-wrapper ">
                    <Image wrapperClassName="menu-chat-link-img" src="images/icons/chatWithVane.svg" />
                </Box>
                <Box display="flex" className="ml-4" column>
                    <Text color="inverse" labelKey="menu.chat.dani" className="menu-chat-link-text p-0" />
                    <Text color="inverse" labelKey="menu.chat.online" className="menu-chat-link-text p-0" bold />
                </Box>
            </Box>
        </NavLink>
    </Box>
);

MenuChat.propTypes = {
    className: string,
    exact: bool,
};

MenuChat.defaultProps = {
    className: null,
    exact: false,
};

export default MenuChat;
