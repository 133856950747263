/* eslint-disable no-useless-computed-key */
/* eslint-disable import/prefer-default-export */
export const historicOperationTypes = {
    all: false,
    ["PAGOS-PROGRAMADOS.DETALLE"]: false,
    ["SEGURIDAD-EQUIPOCONFIANZA.VER"]: false,
    ["TDCDETALLE-CANJEDEPUNTOS.EJECUTAR"]: false,
    ["TDCDETALLE-CANJEDEPUNTOS.VER"]: false,
    ["SOLICITUDES-TDCPINREQUEST.EJECUTAR"]: false,
    ["SOLICITUDES-TDDPINREQUEST.EJECUTAR"]: false,
    ["SOLICITUDES-TDCACTIVACION.EJECUTAR"]: false,
    ["SOLICITUDES-TDCDESBLOQUEO.EJECUTAR"]: false,
    ["SOLICITUDES-TDCBLOQUEO.EJECUTAR"]: false,
    ["SOLICITUDES-TDDACTIVACION.EJECUTAR"]: false,
    ["MENU-DESBLOQUEARCUENTA.EJECUTAR"]: false,
    ["MENU-DESBLOQUEARCUENTA.VER"]: false,
    ["DASHBOARD-LIMRESUMEN.VER"]: false,
    ["PAGOS-PENDIENTESRECHAZAR.EJECUTAR"]: true,
    ["PAGOS-PENDIENTESAUTORIZAR.EJECUTAR"]: true,
    ["PAGOS-HISTORIAL.VER"]: false,
    ["PAGOS-BENEFICIARIOSSERVICIOS.ELIMINAR"]: false,
    ["PAGOS-BENEFICIARIOSSERVICIOS.EDITAR"]: false,
    ["PAGOS-BENEFICIARIOSSERVICIOS.CREAR"]: false,
    ["PAGOS-BENEFICIARIOSSERVICIOS.VER"]: false,
    ["PAGOS-BENEFICIARIOS.ELIMINAR"]: false,
    ["PAGOS-BENEFICIARIOS.EDITAR"]: false,
    ["PAGOS-BENEFICIARIOS.CREAR"]: false,
    ["PAGOS-BENEFICIARIOS.VER"]: false,
    ["PAGOS-INTERNACIONALESINMEDIATA.EJECUTAR"]: true,
    ["PAGOS-TERCEROSOTROBANCOPERIODICA.EJECUTAR"]: true,
    ["PAGOS-TERCEROSOTROBANCOFUTURA.EJECUTAR"]: true,
    ["PAGOS-TERCEROSOTROBANCOINMEDIATA.EJECUTAR"]: true,
    ["PAGOS-TERCEROSBOLIVARIANOPERIODICA.EJECUTAR"]: true,
    ["PAGOS-TERCEROSBOLIVARIANOFUTURA.EJECUTAR"]: true,
    ["PAGOS-TERCEROSBOLIVARIANOINMEDIATA.EJECUTAR"]: true,
    ["PAGOS-MISCTASOTROBANCOPERIODICA.EJECUTAR"]: true,
    ["PAGOS-MISCTASOTROBANCOFUTURA.EJECUTAR"]: true,
    ["PAGOS-MISCTASOTROBANCOINMEDIATA.EJECUTAR"]: true,
    ["PAGOS-MISCTASBOLIVARIANOPERIODICA.EJECUTAR"]: true,
    ["PAGOS-MISCTASBOLIVARIANOFUTURA.EJECUTAR"]: true,
    ["PAGOS-MISCTASBOLIVARIANOINMEDIATA.EJECUTAR"]: true,
    ["PAGOS-PROGRAMADOS.CANCELAR"]: false,
    ["PAGOS-PROGRAMADOS.EDITAR"]: false,
    ["PAGOS-PROGRAMADOS.VER"]: false,
    ["PAGOS-TDCEXTRAFININMEDIATA.EJECUTAR"]: true,
    ["PAGOS-LOANPAGAR.EJECUTAR"]: true,
    ["PAGOS-SERVICIOSPERIODICA.EJECUTAR"]: true,
    ["PAGOS-SERVICIOSINMEDIATO.EJECUTAR"]: true,
    ["PAGOS-RECARGATDCBOLIVARIANOPERIODICA.EJECUTAR"]: true,
    ["PAGOS-RECARGATDCBOLIVARIANOFUTURA.EJECUTAR"]: true,
    ["PAGOS-RECARGATDCBOLIVARIANOINMEDIATA.EJECUTAR"]: true,
    ["PAGOS-RECARGAMISTARJETASPERIODICA.EJECUTAR"]: true,
    ["PAGOS-RECARGAMISTARJETASFUTURA.EJECUTAR"]: true,
    ["PAGOS-RECARGAMISTARJETASINMEDIATA.EJECUTAR"]: true,
    ["PAGOS-TDCOTROBANCOPERIODICA.EJECUTAR"]: true,
    ["PAGOS-TDCOTROBANCOFUTURA.EJECUTAR"]: true,
    ["PAGOS-TDCOTROBANCOINMEDIATA.EJECUTAR"]: true,
    ["PAGOS-TDCBOLIVARIANOPERIODICA.EJECUTAR"]: true,
    ["PAGOS-TDCBOLIVARIANOFUTURA.EJECUTAR"]: true,
    ["PAGOS-TDCBOLIVARIANOINMEDIATA.EJECUTAR"]: true,
    ["PAGOS-TDCMISTARJETASPERIODICA.EJECUTAR"]: true,
    ["PAGOS-TDCMISTARJETASFUTURA.EJECUTAR"]: true,
    ["PAGOS-TDCMISTARJETASINMEDIATA.EJECUTAR"]: true,
    ["PAGOS-FRECUENTES.EJECUTAR"]: true,
    ["SEGURIDAD-AUTENTICADORESDESBLOQUEARQA.EJECUTAR"]: false,
    ["SEGURIDAD-AUTENTICADORESDESBLOQUEARSOFT.EJECUTAR"]: false,
    ["SEGURIDAD-AUTENTICADORESDESBLOQUEAROTP.EJECUTAR"]: false,
    ["SEGURIDAD-AUTENTICADORESDESBLOQUEARCLAVE.EJECUTAR"]: false,
    ["SEGURIDAD-TOKENREGISTRO.EJECUTAR"]: false,
    ["SEGURIDAD-PREGUNTASACTUALIZACION.EJECUTAR"]: false,
    ["SEGURIDAD-EQUIPOCONFIANZAELIMINAR.EJECUTAR"]: false,
    ["SEGURIDAD-AUTENTICADORESCANCELAR.EJECUTAR"]: false,
    ["SEGURIDAD-AUTENTICADORES.VER"]: false,
    ["SEGURIDAD-ALERTASELIMINAR.EJECUTAR"]: false,
    ["SEGURIDAD-ALERTASAGREGAR.EJECUTAR"]: false,
    ["SEGURIDAD-CLAVECAMBIO.EJECUTAR"]: false,
    ["SOLICITUDES-DPFNUEVO.EJECUTAR"]: false,
    ["SOLICITUDES-REFERENCIASNUEVA.EJECUTAR"]: false,
    ["TDCDETALLE-ESTADOCUENTA.VER"]: false,
    ["TDCDETALLE-PENDIENTES.VER"]: false,
    ["TDCDETALLE-MOVFACTURAR.VER"]: false,
    ["TDCDETALLE-DATOS.VER"]: false,
    ["LOANDETALLE-CUOTAS.VER"]: false,
    ["LOANDETALLE-RESUMEN.VER"]: false,
    ["LOANDETALLE-DATOS.VER"]: false,
    ["DPFDETALLE-RESUMEN.VER"]: false,
    ["DPFDETALLE-DATOS.VER"]: false,
    ["CTADETALLE-SUSPENDERCHEQUES.EJECUTAR"]: false,
    ["CTADETALLE-SUSPENDERCHEQUES.VER"]: false,
    ["CTADETALLE-DETALLECHEQUES.VER"]: false,
    ["CTADETALLE-SALDOGARANTIZADO.VER"]: false,
    ["CTADETALLE-SALDOBLOQUEADO.VER"]: false,
    ["CTADETALLE-SALDODIFERIDO.VER"]: false,
    ["CTADETALLE-ESTADOCUENTA.VER"]: false,
    ["CTADETALLE-MOVIMIENTOS.VER"]: false,
    ["CTADETALLE-DATOS.VER"]: false,
    ["ALIASMULTIPLE.EDITAR"]: false,
    ["ALIASMULTIPLE.VER"]: false,
    ["IDIOMA.EDITAR"]: false,
    ["USUARIOPERFIL.EDITAR"]: false,
    ["USUARIOPERFIL.VER"]: false,
    ["ALIASINDIVIDUAL.EDITAR"]: false,
    ["DASHBOARD-TDCRESUMEN.VER"]: false,
    ["DASHBOARD-LOANRESUMEN.VER"]: false,
    ["DASHBOARD-DPFRESUMEN.VER"]: false,
    ["DASHBOARD-CTARESUMEN.VER"]: false,
    ["DASHBOARD-LOGOUT.EJECUTAR"]: false,
    ["LOGIN-CLAVEVENCIDAACTUALIZAR.EDITAR"]: false,
    ["LOGIN-CONTINGENCIAVALIDAR.EJECUTAR"]: false,
    ["LOGIN-EQUIPOTEMPORAL.EJECUTAR"]: false,
    ["LOGIN-VALIDACIONEQUIPOCONFIANZA.EJECUTAR"]: false,
    ["LOGIN-EQUIPOCONFIANZA.REGISTRAR"]: false,
    ["LOGIN.EJECUTAR"]: false,
};
