import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import TextField from "pages/_components/fields/TextField";
import ServiceRemarkableInfo from "pages/servicePayments/_components/ServiceRemarkableInfo";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import DinamicForm from "./_components/DinamicForm";
import { initialValuesService } from "./hooks/dinamicFormHooks";
import fieldARP from "./hooks/field";

const FORM_ID = "servicePayment.modify";

const EditServicePayment = (props) => {
    const { isDesktop, history, dispatch, fetching, payService, field, ...rest } = props;
    const [fieldFiltered, setFieldFiltered] = useState([]);
    const handleBack = () => {
        history.goBack();
    };

    const handleCancel = () => {
        dispatch(push("/servicePayments"));
    };

    useEffect(() => {
        dispatch(payServiceActions.getPayServiceRequest(rest.match.params?.id));
    }, [rest.match.params?.id]);

    useEffect(() => {
        if (payService && rest.match.params?.id === `${payService?.idPaymentUser}`) {
            // Telered -> consulta de reglas
            if (payService?.extraData?.svcProviderId === "Telered") {
                dispatch(payServiceActions.listRulesBillerRequest(payService?.idBiller));
            } else {
                // ARP -> definicion de reglas por defecto
                dispatch(payServiceActions.listRulesBillerSuccess(fieldARP));
            }
        }
    }, [payService]);

    useEffect(() => {
        if (field.length > 0) {
            setFieldFiltered(field.filter((el) => el.fieldInq === true));
        }
    }, [field]);

    const handleSubmit = (values) => {
        payService.alias = values.alias;
        delete payService.category;
        dispatch(payServiceActions.updateRequest(payService));
    };

    return (
        <>
            <Head title="servicePayments.new.title" onBack={handleBack} />
            <MainContainer showLoader={fetching}>
                <ServiceRemarkableInfo
                    title={i18n.get(`servicePayment.category.${payService?.category?.idServicePayment}`)}
                    logo={payService?.category?.logo}
                    subtitle={payService?.nameBiller}
                    className="mb-5 mb-md-7"
                />
                {fieldFiltered.length > 0 && (
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize
                        initialValues={initialValuesService(fieldFiltered, payService)}
                        validationSchema={Yup.object().shape({
                            alias: Yup.string().required(i18n.get(`servicePayment.dinamicForm.validate.required`)),
                        })}
                        onSubmit={(values) => handleSubmit(values)}>
                        {() => (
                            <Form className={classNames("display-flex flex-column  ", { "full-height": !isDesktop })}>
                                <Row gapY={isDesktop ? "8" : "3"} className="mb-5 mb-md-8 height-auto ">
                                    <Col xs={12}>
                                        <Box className="mx-n-5">
                                            <Box
                                                display="flex"
                                                column
                                                gap="7"
                                                background="white"
                                                borderRadius="default"
                                                className={classNames("px-5 px-md-9 pt-5 pb-9 pt-md-7 pb-md-7", {
                                                    "min-height-16-5rem": isDesktop,
                                                })}
                                                fullWidth>
                                                <DinamicForm idForm={FORM_ID} fields={fieldFiltered} disabled />
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <Field
                                                            component={TextField}
                                                            hidePlaceholder
                                                            idForm={FORM_ID}
                                                            type="text"
                                                            name="alias"
                                                            labelNoMarginTop
                                                            maxLength="28"
                                                            optional={i18n.get("forms.optional")}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>

                                <Box {...(!isDesktop && { className: "mt-auto" })}>
                                    <Row gapY={isDesktop ? "8" : "3"}>
                                        <Col xs={12} md={3} mdOffset={3}>
                                            <Button
                                                label="global.cancel"
                                                type="button"
                                                className="btn-outline"
                                                onClick={handleCancel}
                                                block
                                            />
                                        </Col>
                                        <Col xs={12} md={3} {...(!isDesktop && { className: "grid-reversed" })}>
                                            <Button
                                                bsStyle="primary"
                                                label="global.save.change"
                                                loading={fetching}
                                                type="submit"
                                                block
                                            />
                                        </Col>
                                    </Row>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                )}
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: payServiceSelectors.isFetching(state),
    payService: payServiceSelectors.getPayService(state),
    field: payServiceSelectors.getField(state),
});

EditServicePayment.propTypes = {
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
    history: shape({}).isRequired,
    fetching: bool,
    payService: shape({}),
    field: arrayOf(shape({})),
};

EditServicePayment.defaultProps = {
    fetching: false,
    payService: {},
    field: [],
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(EditServicePayment));
