import React from "react";
import Head from "pages/_components/Head";

import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { bool, string } from "prop-types";
import { push } from "react-router-redux";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import classNames from "classnames";
import ListUserEnvironmentContent from "./_components/ListUserEnvironmentContent";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withExchangeToken from "pages/_components/withExchangeToken";

const ListEnvironmentUser = (props) => {
    const {
        isDesktop,
        username,
    } = props;

    const onHeaderClose = () => {
        const { dispatch } = props;
        dispatch(loginActions.reset());
        dispatch(push("/"));
    };

    return (
        <>
            {!isDesktop && <Head onClose={onHeaderClose} />}
            <Notification scopeToShow="listUserEnvironment" />
            <MainContainer
                className={classNames("main-container max-width-full", {
                    "container-fluid with-special-header-login": isDesktop,
                })}>
                <ListUserEnvironmentContent />
            </MainContainer>
        </>
    );
}

ListEnvironmentUser.propTypes = {
    isDesktop: bool.isRequired,
    username: string.isRequired,
};

const mapStateToProps = (state) => ({
    username: loginSelectors.getUsername(state),
});

export default compose(withRouter, connect(mapStateToProps))(withExchangeToken(ListEnvironmentUser));