import * as API from "middleware/api";

export const recoveryUserStep1PreData = () => API.executeAnonymous("/session.recoverUser.predata");

export const recoveryUserStep1 = (documentNumber, mail) =>
    API.executeAnonymous("/session.recoverUser.step1", {
        _documentNumber: documentNumber,
        _mail: mail,
    });

export const recoveryUserStep2 = (_secondFactor, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.recoverUser.step2",
        {
            _secondFactor
        },
        exchangeToken,
    );

export const recoveryUserResendOtp = (exchangeToken) =>
    API.executeWithExchangeToken("/session.recoveryPassword.resendOtp", {}, exchangeToken);
