import * as i18n from "util/i18n";
import * as config from "util/config";

export const unifyListParams = (response) => {
    const { data } = response;
    if (data && data.body) {
        return {
            moreStatements: !(data.body.last || data.body.numberOfElements < data.body.size),
            pageNumber: data.body.pageable.pageNumber + 1,
            totalCount: data.body.totalElements,
            statements: data.body.content,
        };
    }
    return data.data;
};

export default unifyListParams;

export const filterDeposits = (filtersData, deposits) => {
    if (filtersData) {
        let filteredDeposits = deposits.filter(
            (deposit) =>
                deposit.status?.toUpperCase().includes(filtersData?.status.toUpperCase()) &&
                deposit.subProductType?.toUpperCase().includes(filtersData?.depositType.toUpperCase()),
        );
        if (filtersData?.selectedDate !== "") {
            let initDate = null;
            let endDate = null;
            const currentDate = new Date();
            if (filtersData?.selectedDate === "lastSeven") {
                const sevenDaysAgo = new Date(currentDate);
                sevenDaysAgo.setDate(currentDate.getDate() - 7);
                initDate = currentDate;
                endDate = sevenDaysAgo;
            }
            if (filtersData?.selectedDate === "lastThirty") {
                const thirtyDaysAgo = new Date(currentDate);
                thirtyDaysAgo.setDate(currentDate.getDate() - 30);
                initDate = currentDate;
                endDate = thirtyDaysAgo;
            }
            if (filtersData?.selectedDate === "period") {
                initDate = new Date(filtersData?.dateTo);
                endDate = new Date(filtersData?.dateFrom);
            }
            filteredDeposits = filteredDeposits.filter((deposit) => {
                const expirationDate1 = deposit.expirationDate;
                const expWithoutOffset = expirationDate1.replace(/-[\d:.]+$/, "");
                const expirationDate = new Date(expWithoutOffset);
                const initValidation = expirationDate <= initDate;
                const endValidation = expirationDate >= endDate;
                return initValidation && endValidation;
            });
        }
        return filteredDeposits;
    }
    return deposits;
};

const getTypeDepositFilter = (subProductType) => {
    const listDeposit = config.get("deposits.myDeposits.type.list");
    const listDepositArray = listDeposit.split("|");

    for (const deposit of listDepositArray) {
        const depositSplit = deposit.split(":");
        if (depositSplit.length === 2 && depositSplit[0] === subProductType) {
            return i18n.get(depositSplit[1]);
        }
    }
    return null;
};

export const filterSubProductType = (deposits) => {
    const listStatus = [];

    deposits.forEach((deposit) => {
        const existingStatus = listStatus.find((subProductType) => subProductType.value === deposit.subProductType);
        if (!existingStatus) {
            listStatus.push({
                value: deposit.subProductType,
                label: getTypeDepositFilter(deposit.subProductType),
            });
        }
    });

    return listStatus;
};

export const filterStatus = () => {
    const listStatus = [];
    const statusItems = config.getArray("deposits.filters.status.items");

    statusItems.forEach((item) => {
        const statusSplit = item.split(":");
        listStatus.push({ value: statusSplit[0], label: statusSplit[1] });
    });

    return listStatus;
};
