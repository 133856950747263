import { generateIdField } from "pages/forms/customForms/foreignTransfer/SwitfBankSelector";
import {
    BENEFICIARY_NAME_DATE,
    BENEFICIARY_THREE_NAME,
} from "pages/forms/customForms/_customFields/BeneficiaryField/Constants";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";

export const INTERNAL_TRANSFER = "INTERNAL_TRANSFER";
export const LOCAL_TRANSFER = "LOCAL_TRANSFER";
export const FOREIGN_TRANSFER = "FOREIGN_TRANSFER";
export const PRODUCT_TYPE_TC = "TC";
export const PRODUCT_TYPE_TP = "TP";
export const BOLIVARIANO_BANK_CODE = "BANSPAPA";
const PRODUCTYPE_COMPANY = "02";

export const COUNTRY_BENEFICIARY_MX = "MX";

export const isEmail = (email) => {
    const regExpEmail = new RegExp(/^[A-Za-z][\w.+-_]{0,99}@[\w.+-_]{1,99}\.[\w.+-_]{2,50}$/);
    return regExpEmail.test(email);
};
export const validateTransferLocalForm = (
    values,
    messageRquired,
    emailRequired,
    numberRequired,
    creditCardNotFound,
) => {
    const errors = {};
    if (!values) {
        errors.form = messageRquired;
        return errors;
    }

    const { beneficiary } = values;
    if (!beneficiary) {
        errors.beneficiary = messageRquired;
        return errors;
    }

    if (beneficiary.email && beneficiary.email !== "" && !isEmail(beneficiary.email)) {
        errors["beneficiary.email"] = emailRequired;
    }
    if (!beneficiary.name || beneficiary.name === "") {
        errors["beneficiary.name"] = messageRquired;
    }
    if (!beneficiary.number || beneficiary.number === "") {
        errors["beneficiary.number"] = messageRquired;
    }
    if (beneficiary.number && beneficiary?.number.length > 34) {
        errors["beneficiary.number"] = numberRequired;
    }

    if (
        beneficiary.number &&
        beneficiary?.bank?.id === BOLIVARIANO_BANK_CODE &&
        beneficiary?.productType?.id !== "TC" &&
        beneficiary?.productType?.id !== "TP" &&
        beneficiary?.number.length > 12
    ) {
        errors["beneficiary.number"] = numberRequired;
    }

    if (
        ((beneficiary?.number?.charAt(0) === "4" || beneficiary?.number?.charAt(0) === "5") &&
            beneficiary?.number.replaceAll("•", "").length > 16 &&
            (beneficiary?.productType?.id === "TC" || beneficiary?.productType?.id === "TP")) ||
        (beneficiary?.number?.charAt(0) === "3" &&
            beneficiary?.number.replaceAll("•", "").length > 15 &&
            (beneficiary?.productType?.id === "TC" || beneficiary?.productType?.id === "TP"))
    ) {
        errors["beneficiary.number"] = numberRequired;
    }

    if (
        ((beneficiary?.number?.charAt(0) === "4" || beneficiary?.number?.charAt(0) === "5") &&
            (beneficiary?.productType?.id === "TC" || beneficiary?.productType?.id === "TP") &&
            beneficiary?.number.replaceAll("•", "").length < 16) ||
        (beneficiary?.number?.charAt(0) === "3" &&
            (beneficiary?.productType?.id === "TC" || beneficiary?.productType?.id === "TP") &&
            beneficiary?.number.replaceAll("•", "").length < 15)
    ) {
        errors["beneficiary.number"] = creditCardNotFound;
    }

    if (!beneficiary.bank?.id || !beneficiary.bank?.label) {
        errors["beneficiary.bank"] = messageRquired;
    }
    if (!beneficiary.productType?.id || !beneficiary.productType?.label) {
        errors["beneficiary.productType"] = messageRquired;
    }

    return errors;
};

const validateBeneficiaryName = (beneficiary, errors, messageRquired, i18n, configUtils) => {
    const beneficiaryErrors = { ...errors };
    if (!beneficiary.productType || beneficiary.productType === "") {
        beneficiaryErrors["beneficiary.productType"] = messageRquired;
    }
    if (!beneficiary.name || beneficiary.name === "") {
        beneficiaryErrors["beneficiary.name"] = messageRquired;
        return beneficiaryErrors;
    }

    if (beneficiary?.productType?.id === PRODUCTYPE_COMPANY) {
        return beneficiaryErrors;
    }

    if (!beneficiary.beneficiaryOption) {
        beneficiaryErrors["beneficiary.beneficiaryOption"] = messageRquired;
    }

    if (beneficiary.beneficiaryOption === BENEFICIARY_THREE_NAME) {
        const isInValidCharacteres = beneficiary.name
            .trim()
            .split(" ")
            .some((item) => item.length < 2);
        if (isInValidCharacteres) {
            beneficiaryErrors["beneficiary.name"] = i18n.get(
                `forms.transfers.foreign.beneficiary.name.pattern.message`,
            );
            return beneficiaryErrors;
        }
        const isInValidLength = beneficiary.name.split(" ").length < 3;
        if (isInValidLength) {
            beneficiaryErrors["beneficiary.name"] = i18n.get(
                `forms.transfers.foreign.beneficiary.name.pattern.message`,
            );
            return beneficiaryErrors;
        }

        const beneficiaryThreeNameLength =
            configUtils?.getInteger("forms.transfers.foreign.field.beneficiaryThreeName.length.max") || 35;
        if (beneficiary.name.length > beneficiaryThreeNameLength) {
            beneficiaryErrors["beneficiary.name"] = i18n.get(
                "forms.transfers.foreign.field.beneficiaryThreeName.length.max.message",
            );
            return beneficiaryErrors;
        }
    } else if (beneficiary.beneficiaryOption === BENEFICIARY_NAME_DATE) {
        const isInValidCharacteres = beneficiary.name
            .trim()
            .split(" ")
            .some((item) => item.length < 2);
        if (isInValidCharacteres) {
            beneficiaryErrors["beneficiary.name"] = i18n.get(
                `forms.transfers.foreign.beneficiary.name.birthDate.pattern.message`,
            );
            return beneficiaryErrors;
        }

        const isInValidLength = beneficiary.name.split(" ").length < 2;
        if (isInValidLength) {
            beneficiaryErrors["beneficiary.name"] = i18n.get(
                `forms.transfers.foreign.beneficiary.nameDate.pattern.message`,
            );
            return beneficiaryErrors;
        }

        const beneficiaryDateNameLength =
            configUtils?.getInteger("forms.transfers.foreign.field.beneficiaryNameDate.length.max") || 24;
        if (beneficiary.name.length > beneficiaryDateNameLength) {
            beneficiaryErrors["beneficiary.name"] = i18n.get(
                "forms.transfers.foreign.field.beneficiaryNameDate.length.max.message",
            );
            return beneficiaryErrors;
        }

        if (!beneficiary.birthDateFrom || beneficiary.birthDateFrom === "") {
            beneficiaryErrors["beneficiary.birthDateFrom"] = messageRquired;
            return beneficiaryErrors;
        }
    }

    return beneficiaryErrors;
};

const isEmptyFieldValue = (fieldValue) => !fieldValue || fieldValue === "";
const validateBankResponse = (beneficiary, errors, fieldBank, i18n, configUtils) => {
    const errorsBankResponse = { ...errors };
    const fieldBankName = generateIdField("bankName", fieldBank);
    const fieldBankCountry = generateIdField("bankCountry", fieldBank);
    const fieldBankSelector = generateIdField("bankSelector", fieldBank);
    const swiftCode = generateIdField("swiftCode", fieldBank);
    const bankAddress = generateIdField("bankAddress", fieldBank);
    const bankCity = generateIdField("bankCity", fieldBank);

    if (isEmptyFieldValue(beneficiary[fieldBankName]) || isEmptyFieldValue(beneficiary[fieldBankCountry])) {
        errorsBankResponse[`beneficiary.${fieldBankSelector}`] = i18n.get("beneficiary.bank.selector.query.error");
    }

    if (beneficiary[fieldBankSelector]?.id === "CUENTA") {
        const beneficiaryFieldLength =
            configUtils?.getInteger("forms.transfers.foreign.field.beneficiaryIntermediaryBank.length.max") || 35;
        if (isEmptyFieldValue(beneficiary[fieldBankName])) {
            errorsBankResponse[`beneficiary.${fieldBankName}`] = i18n.get(DEFAULT_REQUIRED);
        } else if (beneficiary[fieldBankName].length > beneficiaryFieldLength) {
            errorsBankResponse[`beneficiary.${fieldBankName}`] = i18n.get(
                "forms.transfers.foreign.field.beneficiaryIntermediaryBank.length.max.message",
            );
        }

        if (isEmptyFieldValue(beneficiary[fieldBankCountry])) {
            errorsBankResponse[`beneficiary.${fieldBankCountry}Sel`] = i18n.get(DEFAULT_REQUIRED);
        } else if (beneficiary[fieldBankCountry].length > beneficiaryFieldLength) {
            errorsBankResponse[`beneficiary.${fieldBankCountry}`] = i18n.get(
                "forms.transfers.foreign.field.beneficiaryIntermediaryBank.length.max.message",
            );
        }

        if (isEmptyFieldValue(beneficiary[swiftCode])) {
            errorsBankResponse[`beneficiary.${fieldBankSelector}`] = i18n.get(DEFAULT_REQUIRED);
        } else if (beneficiary[swiftCode].length > beneficiaryFieldLength) {
            errorsBankResponse[`beneficiary.${fieldBankSelector}`] = i18n.get(
                "forms.transfers.foreign.field.beneficiaryIntermediaryBank.length.max.message",
            );
        }

        if (isEmptyFieldValue(beneficiary[bankAddress])) {
            errorsBankResponse[`beneficiary.${bankAddress}`] = i18n.get(DEFAULT_REQUIRED);
        } else if (
            !isEmptyFieldValue(beneficiary[bankAddress]) &&
            beneficiary[bankAddress].length > beneficiaryFieldLength
        ) {
            errorsBankResponse[`beneficiary.${bankAddress}`] = i18n.get(
                "forms.transfers.foreign.field.beneficiaryIntermediaryBank.length.max.message",
            );
        }

        if (isEmptyFieldValue(beneficiary[bankCity])) {
            errorsBankResponse[`beneficiary.${bankCity}`] = i18n.get(DEFAULT_REQUIRED);
        } else if (!isEmptyFieldValue(beneficiary[bankCity]) && beneficiary[bankCity].length > beneficiaryFieldLength) {
            errorsBankResponse[`beneficiary.${bankCity}`] = i18n.get(
                "forms.transfers.foreign.field.beneficiaryIntermediaryBank.length.max.message",
            );
        }

        // CUENTA debe tener banco intermediario
        if (isEmptyFieldValue(beneficiary.intermediary_swiftCode) || !beneficiary?.addIntermediaryBank) {
            errorsBankResponse[`beneficiary.intermediary_swiftCode`] = i18n.get(
                "beneficiary.bank.selector.query.error",
            );
            errorsBankResponse.addIntermediaryBank = i18n.get(DEFAULT_REQUIRED);
        }
    }

    return errorsBankResponse;
};

const validateAccountNumberForeign = (beneficiary, errors, i18n, configUtils, messageRquired) => {
    const { country, account } = beneficiary;
    const errorsAccount = { ...errors };
    if (!account || account === "") {
        errorsAccount["beneficiary.account"] = messageRquired;
        return errorsAccount;
    }
    if (country && country.id && country.id === COUNTRY_BENEFICIARY_MX) {
        const accountMaxLength = configUtils.getInteger("forms.transfers.foreign.field.account.length.mx.max") || 18;
        if (account.length !== accountMaxLength) {
            errorsAccount["beneficiary.account"] = i18n.get("forms.transfer.foreign.field.account.length.mx.message");
        }
        return errorsAccount;
    }

    const accountMaxLength = configUtils.getInteger("forms.transfers.foreign.field.account.length.generic.max") || 35;
    if (account.length > accountMaxLength) {
        errorsAccount["beneficiary.account"] = i18n.get("forms.transfer.foreign.field.account.length.generic.message");
    }

    return errorsAccount;
};

const validateBeneficiaryForeignAddress = (messageRquired, beneficiary, errors, i18n, configUtils) => {
    const errorsDef = { ...errors };
    if (!beneficiary.address || beneficiary.address === "") {
        errorsDef["beneficiary.address"] = messageRquired;
        return errorsDef;
    }

    const addressLength = configUtils?.getInteger("forms.transfers.foreign.field.address.length.max") || 35;
    if (beneficiary.address.length > addressLength) {
        errorsDef["beneficiary.address"] = i18n?.get("forms.transfers.foreign.field.address.length.max.message") || "";
    }

    return errorsDef;
};

const validateBeneficiaryForeignCity = (messageRquired, beneficiary, errors, i18n, configUtils) => {
    const errorsDef = { ...errors };
    if (!beneficiary.city || beneficiary.city === "") {
        errorsDef["beneficiary.city"] = messageRquired;
        return errorsDef;
    }

    const cityMaxLength = configUtils.getInteger("forms.transfers.foreign.field.city.length.generic.max") || 32;
    if (beneficiary.city.length > cityMaxLength) {
        errorsDef["beneficiary.city"] = i18n.get("forms.transfer.foreign.field.city.length.generic.message");
    }

    return errorsDef;
};

export const validateTransferForeignForm = (values, i18n, configUtils) => {
    const messageRquired = i18n.get(DEFAULT_REQUIRED);
    let errors = {};
    const { beneficiary } = values;
    if (!beneficiary) {
        errors.beneficiary = messageRquired;
    }

    if (!beneficiary.relationshipType || beneficiary.relationshipType === "") {
        errors["beneficiary.relationshipType"] = messageRquired;
    }

    if (!beneficiary.serviceDescription || beneficiary.serviceDescription === "") {
        errors["beneficiary.serviceDescription"] = messageRquired;
    }

    errors = validateBeneficiaryForeignAddress(messageRquired, beneficiary, errors, i18n, configUtils);

    if (!beneficiary.country || beneficiary.country === "") {
        errors["beneficiary.country"] = messageRquired;
    }

    errors = validateBeneficiaryForeignCity(messageRquired, beneficiary, errors, i18n, configUtils);

    errors = validateAccountNumberForeign(beneficiary, errors, i18n, configUtils, messageRquired);

    if (
        !beneficiary.bankSelector ||
        beneficiary.bankSelector.length === 0 ||
        !beneficiary.swiftCode ||
        beneficiary.swiftCode.length === 0
    ) {
        errors["beneficiary.bankSelector"] = messageRquired;
    } else {
        errors = validateBankResponse(beneficiary, errors, "", i18n, configUtils);
    }

    if (beneficiary.addIntermediaryBank && beneficiary.addIntermediaryBank === true) {
        const fieldBankAddress = generateIdField("bankSelector", "intermediary");
        const fieldSwiftCode = generateIdField("swiftCode", "intermediary");

        if (
            !beneficiary[fieldBankAddress] ||
            beneficiary[fieldBankAddress].length === 0 ||
            !beneficiary[fieldSwiftCode] ||
            beneficiary[fieldSwiftCode].length === 0
        ) {
            errors[`beneficiary.${fieldBankAddress}`] = messageRquired;
        } else {
            errors = validateBankResponse(beneficiary, errors, "intermediary", i18n, configUtils);
        }
    }

    return validateBeneficiaryName(beneficiary, errors, messageRquired, i18n);
};
