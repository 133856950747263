import * as API from "middleware/api";

export const listPayService = (pageNumber, isFastRecharge) =>
    API.executeWithAccessToken("/payService.list", {
        pageNumber,
        fastRecharge: isFastRecharge,
    });

export const setFavoritePayService = (idPaymentUser, favorite) =>
    API.executeWithAccessToken("/payService.setFavorite", {
        idPaymentUser,
        favorite: !favorite,
    });

export const updatePayService = (paymentUser) =>
    API.executeWithAccessToken("/payService.update", {
        paymentUser,
    });

export const createPayService = (paymentUser) =>
    API.executeWithAccessToken("/payService.create", {
        paymentUser,
    });

export const getPayService = (idPaymentUser) =>
    API.executeWithAccessToken("/payService.get", {
        idPaymentUser,
    });

export const deletePayService = (idPaymentUser) =>
    API.executeWithAccessToken("/payService.delete", {
        idPaymentUser,
    });

export const listCategories = (categoryId) =>
    API.executeWithAccessToken("/payService.list.category", {
        categoryId,
    });

export const listRulesBiller = (idBiller) =>
    API.executeWithAccessToken("/payService.list.rules.biller", {
        idBiller,
    });
