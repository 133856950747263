import React, { Component, Fragment } from "react";
import { any, arrayOf, bool, func, number, objectOf, oneOfType, shape, string } from "prop-types";

import * as configUtils from "util/config";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import Notification from "pages/_components/Notification";
import Box from "pages/_components/Box";
import ConfirmData from "./ConfirmData";
import { featuresMap } from "../../utils/signaturesScheme";

class SignaturesSchemeDelete extends Component {
    static propTypes = {
        actions: shape({
            deleteSignaturesSchemeRequest: func,
        }).isRequired,
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        fetching: bool.isRequired,
        functionalGroups: arrayOf(string),
        masterCurrency: string,
        match: shape({
            params: shape({
                idSignature: string,
            }),
        }),
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        signature: shape({
            capList: arrayOf(objectOf(any)),
            groupsMap: oneOfType([
                shape({
                    A: number,
                }),
                shape(
                    configUtils.getArray("administration.signatures.signatureLevels").reduce((res, signLevel) => {
                        const result = res;
                        result[signLevel] = number;

                        return result;
                    }, {}),
                ),
            ]),
            idSignature: number,
        }),
        formActions: objectOf(func).isRequired,
        idActivity: string,
        idTransaction: string,
        groups: arrayOf(shape({})).isRequired,
        routerActions: shape({
            push: func,
        }),
    };

    static defaultProps = {
        functionalGroups: [],
        masterCurrency: configUtils.get("core.masterCurrency"),
        match: null,
        signature: null,
        idActivity: null,
        idTransaction: null,
        routerActions: null,
    };

    componentDidMount() {
        const {
            actions,
            match: {
                params: { idSignature },
            },
        } = this.props;

        actions.deleteSignaturesSchemePreRequest(idSignature);
    }

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, idTransaction, idActivity, functionalGroups, signature } = this.props;
        const secondFactor = credentials;
        if (!idTransaction) {
            actions.deleteSignaturesSchemeRequest(
                {
                    secondFactor,
                    ...{ ...signature, functionalGroups },
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const {
            routerActions,
            activeEnvironment: { administrationScheme },
        } = this.props;
        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes`);
    };

    render() {
        const {
            credentialGroups,
            activeEnvironment: { administrationScheme },
            fetching,
            // functionalGroups,
            // masterCurrency,
            signature,
        } = this.props;
        const hasTopAmount =
            administrationScheme === "advanced" &&
            signature &&
            signature.signatureType === "AMOUNT" &&
            signature.capList &&
            signature.capList[0];

        const topAmount = { amount: 0, period: "" };
        if (hasTopAmount) {
            topAmount.amount = signature.capList[0].maximum;
            topAmount.period = signature.capList[0].frequency;
        }
        let features = {};
        if (signature) {
            features = featuresMap(signature.signatureFeatures);
        }

        return (
            <Fragment>
                <Notification scopeToShow="administrationDeleteSignatureScheme" />
                <Head
                    title={`administration.signatures.delete.${administrationScheme}.confirm.title`}
                    onBack={this.handleBack}
                    textBack="administration.signatures.returnToList"
                />
                <MainContainer showLoader={fetching || !signature}>
                    {signature && (
                        <ConfirmData
                            {...this.props}
                            {...signature}
                            alias={signature.signatureAlias}
                            topAmount={topAmount}
                            signatureLevelsCounts={signature.groupsMap}
                            functionalGroups={features}
                        />
                    )}
                    <Box>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                            onClickCancel={this.handleBack}
                            idActivity="administration.signatures.delete.send"
                            scopeToShow="administrationDeleteSignatureScheme"
                        />
                    </Box>
                    {/* </div> */}
                </MainContainer>
            </Fragment>
        );
    }
}

export default SignaturesSchemeDelete;
