import Box from "pages/_components/Box";
import Disclaimer from "pages/_components/Disclaimer";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions, selectors as restrictionSelectors, selectors } from "reducers/administration/restrictions";
import { actions as formActions } from "reducers/form";
import * as dateUtils from "util/date";
import * as i18n from "util/i18n";

class RestrictionsManageConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        dataToSave: shape({}).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        idTransaction: string,
        idActivity: string,
        userName: string,
        fetching: bool.isRequired,
        calendarEnabled: bool.isRequired,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
        userName: null,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(actions.setFetchingFalse());
    }

    handleSubmit = (credentials, formikBag) => {
        const { dispatch, dataToSave, idTransaction, idActivity } = this.props;
        if (!idTransaction) {
            dispatch(actions.manageRestrictions(dataToSave, credentials, formikBag));
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    renderDays = (bitWiseNumber) => (
        <div>
            {dateUtils
                .getDaysNamesWithBitwiseNumber(bitWiseNumber)
                .map((i) => i)
                .join(", ")}
        </div>
    );

    renderCalendarAndIPSection(onlyEnvironment, dataToSave, userName) {
        return (
            <Box component="section">
                {/* calendar Detail */}

                <>
                    {!dataToSave.perpetual && dataToSave.calendarToSave && (dataToSave.calendarEnabled || userName) && (
                        <Box display="flex" column gap="3" className="mb-11" fullWidth>
                            {userName && (
                                <Box display="flex" alignX="between" gap="10" fullWidth>
                                    <Text
                                        component="label"
                                        labelKey="administration.restrictions.user"
                                        className="m-0"
                                        color="heading-color"
                                        addColon
                                    />
                                    <Text className="m-0" color="heading-color" align="left">
                                        {userName}
                                    </Text>
                                </Box>
                            )}
                            <Box display="flex" alignX="between" gap="10" fullWidth>
                                <Text
                                    component="label"
                                    labelKey="administration.restrictions.timeZone.label"
                                    className="m-0"
                                    color="heading-color"
                                    addColon
                                />
                                <Text className="m-0" color="heading-color" align="left">
                                    {dataToSave.timeZone}
                                </Text>
                            </Box>
                            <Box display="flex" alignX="between" gap="10" fullWidth>
                                <Text
                                    component="label"
                                    labelKey="administration.restrictions.restrictions.days"
                                    className="m-0"
                                    color="heading-color"
                                    addColon
                                />
                                <Text className="m-0" color="heading-color" align="left">
                                    {dataToSave.days === 127
                                        ? i18n.get("administration.restrictions.allDays.label")
                                        : this.renderDays(dataToSave.days)}
                                </Text>
                            </Box>
                            <Box display="flex" alignX="between" gap="10" fullWidth>
                                <Text
                                    component="label"
                                    labelKey="global.hour"
                                    className="m-0"
                                    color="heading-color"
                                    addColon
                                    capitalize
                                />
                                <Text align="left">
                                    <Text color="heading-color" labelKey="administration.restrictions.from" />
                                    <Text color="heading-color">{` ${dataToSave.startTime} `}</Text>
                                    <Text color="heading-color" labelKey="administration.restrictions.to" />

                                    <Text color="heading-color">{` ${dataToSave.endTime} `}</Text>
                                    <Text color="heading-color" labelKey="administration.restrictions.hrs" />
                                </Text>
                            </Box>
                        </Box>
                    )}
                    {dataToSave.perpetual && (
                        <Box
                            display="flex"
                            column
                            alignX="center"
                            alignY="center"
                            gap="4"
                            className="min-height-10rem"
                            fullWidth>
                            {userName && (
                                <Box display="flex" alignX="between" gap="10" fullWidth>
                                    <Text
                                        component="label"
                                        labelKey="administration.restrictions.user"
                                        className="m-0"
                                        color="heading-color"
                                        addColon
                                    />
                                    <Text className="m-0" color="heading-color" align="left">
                                        {userName}
                                    </Text>
                                </Box>
                            )}
                            <Text
                                color="heading-color"
                                labelKey="administration.restrictions.perpetual.detail"
                                className="mt-7"
                                bold
                            />
                        </Box>
                    )}
                    {onlyEnvironment && !dataToSave.calendarEnabled && (
                        <Box
                            display="flex"
                            column
                            alignX="center"
                            alignY="center"
                            gap="4"
                            className="min-height-10rem"
                            fullWidth>
                            {userName && (
                                <Box display="flex" alignX="between" gap="10" fullWidth>
                                    <Text
                                        component="label"
                                        labelKey="administration.restrictions.user"
                                        className="m-0"
                                        color="heading-color"
                                        addColon
                                    />
                                    <Text className="m-0" color="heading-color" align="left">
                                        {userName}
                                    </Text>
                                </Box>
                            )}
                            <Box display="flex" alignX="between" gap="10" fullWidth>
                                <Text
                                    component="label"
                                    labelKey="administration.restrictions.disableCalendar.label"
                                    className="m-0"
                                    color="heading-color"
                                    addColon
                                />
                                <Text
                                    className="m-0"
                                    color="heading-color"
                                    align="left"
                                    labelKey="administration.restrictions.disableCalendar.text"
                                />
                            </Box>
                        </Box>
                    )}
                    {!dataToSave.calendarToSave && onlyEnvironment && dataToSave.calendarEnabled && (
                        <section className="container--layout flex-grow-1">
                            <I18n id="administration.restrictions.enableCalendar.label" />
                        </section>
                    )}
                </>
                {/* NO BORRAR (IMPLEMENTACION PRODUCTO) ip Detail */}
                {/* {(dataToSave.ipsList && dataToSave.ipsList.length > 0) || dataToSave.anyIP ? (
                    <div>
                        <I18n id="administration.restrictions.IP" />{" "}
                        {dataToSave.anyIP && <I18n id="administration.restrictions.anyIP.label" />}
                        <span>{!dataToSave.anyIP && dataToSave.ipsList.join(", ")}</span>
                    </div>
                ) : (
                    <>
                        {onlyEnvironment && !dataToSave.iPEnabled && (
                            <section className="container--layout flex-grow-1">
                                <I18n id="administration.restrictions.disableIP.label" />
                            </section>
                        )}
                        {onlyEnvironment && dataToSave.iPEnabled && (
                            <section className="container--layout flex-grow-1">
                                <I18n id="administration.restrictions.enableIP.label" />
                            </section>
                        )}
                    </>
                )} */}
            </Box>
        );
    }

    renderDetail() {
        const { dataToSave, userName } = this.props;

        const onlyEnvironment = typeof dataToSave.idUser === "undefined";

        return (
            <>
                <Box display="flex" className="pb-5 mb-8" border="bottom-border-base-color-1">
                    <Text
                        component="h2"
                        labelKey={`administration.restrictions.restrictions.before${dataToSave.actionRestrinction}`}
                        className="m-0"
                        size="5"
                        color="heading-color"
                        bold
                    />
                </Box>

                {this.renderCalendarAndIPSection(onlyEnvironment, dataToSave, userName)}
                {/* NO BORRAR (IMPLEMENTACION PRODUCTO) {!onlyEnvironment && (
                    <section className="container--layout flex-grow-1">
                        <div className="restrictions-warning">
                            <Image src="images/warningIcon.svg" className="svg-icon" />{" "}
                            <span>
                                <I18n id="administration.restrictions.restrictions.beforeSave.userNote" />
                            </span>
                        </div>
                    </section>
                )} */}
            </>
        );
    }

    render() {
        const { credentialGroups, fetching, dataToSave } = this.props;
        const onlyEnvironment = typeof dataToSave.idUser === "undefined";
        if (!credentialGroups) {
            return null;
        }

        return (
            <>
                <Head
                    title={
                        onlyEnvironment
                            ? "administration.restrictions.environmentRestrictions.title"
                            : "administration.restrictions.manage"
                    }
                    onBack={this.handleBack}
                />
                <MainContainer>
                    <Row>
                        <Col xs={8} xsOffset={2} className="pb-80">
                            <Box display="flex" column background="component-background" borderRadius="xxl" fullHeight>
                                {onlyEnvironment && (
                                    <Disclaimer
                                        styled="info"
                                        iconClassname="tooltip-img"
                                        borderRadius="top-xl"
                                        labelKey="administration.restrictions.confirmation.enviroment.disclaimer"
                                        notBorder
                                        textColor="text-boton"
                                        textSize="6"
                                    />
                                )}

                                <Box className="px-80 pt-8 pb-9">
                                    {this.renderDetail()}
                                    <AdministrationFormConfirmation
                                        credentialGroups={credentialGroups}
                                        onSubmit={this.handleSubmit}
                                        fetching={fetching}
                                        onClickCancel={this.handleBack}
                                        idActivity="administration.restrictions.manage.send"
                                    />
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    credentialGroups: selectors.getCredentialGroups(state),
    dataToSave: selectors.getDataToSave(state),
    calendarEnabled: restrictionSelectors.getCalendarEnabled(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
    userName: restrictionSelectors.getUserName(state),
    fetching: restrictionSelectors.getFetching(state),
});

export default connect(mapStateToProps)(RestrictionsManageConfirmation);
