import React from "react";
import { func } from "prop-types";
import Navbar from "react-bootstrap/lib/Navbar";
import { Link } from "react-router-dom";

import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import classNames from "classnames";

const SimpleHead = ({ onBack }) => (
    <header className="app-header fixed-header">
        <Navbar collapseOnSelect fluid>
            <Navbar.Header>
                <div className="navbar-header-wrapper">
                    <div className="d-flex items-center">
                        <Navbar.Brand>
                            {onBack && (
                                <div className="toolbar-item toolbar-item--fixed toolbar-item-left">
                                    <Button
                                        className="toolbar-btn btn-back-outside"
                                        inverse
                                        onClick={onBack}
                                        image="images/arrowLeft.svg"
                                        ariaLabel="global.back"
                                    />
                                </div>
                            )}
                            <Link
                                className={classNames("navbar-brand logo-container", {
                                    "has-btn-back": onBack !== undefined,
                                })}
                                to="/desktop">
                                <Image src="images/logo-bolivariano-mobile.svg" />
                            </Link>
                        </Navbar.Brand>
                    </div>
                </div>
            </Navbar.Header>
        </Navbar>
    </header>
);

SimpleHead.propTypes = {
    onBack: func,
};

SimpleHead.defaultProps = {
    onBack: undefined,
};

export default SimpleHead;
