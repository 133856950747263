import { createReducer, makeActionCreator } from "util/redux";

export const generalConditionsTypes = {
    DOWNLOAD_GENERAL_CONDITIONS: "DOWNLOAD_GENERAL_CONDITIONS",
    SUCCESS_DOWNLOAD_GENERAL_CONDITIONS: "SUCCESS_DOWNLOAD_GENERAL_CONDITIONS",
    SET_ACTION_DISPATCH: "SET_ACTION_DISPATCH",
    EXECUTE_ACTION_DISPATCH: "EXECUTE_ACTION_DISPATCH",
};

export const INITIAL_STATE = {
    fetching: false,
    submitGeneralCondition: undefined,
    submitParamGeneralCondition: {},
};

export const selectors = {
    getDownloadFetching: ({ generalConditions }) => generalConditions.fetching,
    getSubmitGeneralCondition: ({ generalConditions }) => generalConditions.submitGeneralCondition,
    getSubmitParamGeneralCondition: ({ generalConditions }) => generalConditions.submitParamGeneralCondition,
};

export const actions = {
    downloadGeneralConditions: makeActionCreator(generalConditionsTypes.DOWNLOAD_GENERAL_CONDITIONS, "data"),
    executeActionDispatch: makeActionCreator(generalConditionsTypes.EXECUTE_ACTION_DISPATCH, "data"),
};

export default createReducer(INITIAL_STATE, {
    [generalConditionsTypes.DOWNLOAD_GENERAL_CONDITIONS]: (state) => ({ ...state, fetching: false }),
    [generalConditionsTypes.SUCCESS_DOWNLOAD_GENERAL_CONDITIONS]: (state) => ({ ...state, fetching: true }),
    [generalConditionsTypes.SET_ACTION_DISPATCH]: (state, action) => ({
        ...state,
        submitGeneralCondition: action.submitGeneralCondition,
        submitParamGeneralCondition: action.submitParamGeneralCondition,
    }),
});
