import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import React, { Component } from "react";

class UsersTableHead extends Component {
    render() {
        return (
            <Box fullWidth display="flex" className="px-7 py-4 mb-5 border-radius-lg box-shadow-small background-white">
                <Box fullWidth>
                    <Box>
                        <Text component="h3" labelKey="administration.users.list.header.table.title" bold />
                    </Box>
                    <Box className="mt-5">
                        <Text
                            labelKey="administration.users.list.header.table.description"
                            color="text-disabled-color"
                        />
                    </Box>
                </Box>
                <Box>
                    <Image src="images/administration/users_view.svg" />
                </Box>
            </Box>
        );
    }
}

export default UsersTableHead;
