import Compose from "pages/communications/_components/Compose";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func } from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

const MessageModal = (props) => {
    const { isDesktop, modalShow, isReply, onCloseClick } = props;
    return (
        <Modal show={modalShow}>
            <Compose isDesktop={isDesktop} isReply={isReply} onCloseClick={onCloseClick} />
        </Modal>
    );
};

MessageModal.propTypes = {
    isDesktop: bool.isRequired,
    onCloseClick: func.isRequired,
    modalShow: bool.isRequired,
    isReply: bool,
};
MessageModal.defaultProps = {
    isReply: false,
};

export default connect()(resizableRoute(MessageModal));
