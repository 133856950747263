import classNames from "classnames";
import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, shape, string } from "prop-types";
import React from "react";
import { Col } from "react-bootstrap";

const MyServicesCard = ({ className, info, isDesktop }) => (
    <Box
        component="section"
        {...(isDesktop && { background: "white" })}
        borderRadius="default"
        className={classNames("px-md-0 py-md-8  ", className)}>
        <Row gapY={isDesktop ? "4" : "3"}>
            {info.map((elem) => (
                <Col xs={12} md={6} key={elem?.fieldId} className="pl-0 pl-md-9 pr-0 pr-md-9">
                    <Box display="inline-block">
                        <Text component="label" color="heading" size={isDesktop ? "5" : "5"} className="mb-0 mr-2" bold>
                            {elem?.fieldLabel}
                            {":"}
                        </Text>
                        <Text color="heading" size={isDesktop ? "5" : "5"}>
                            {elem?.value}
                        </Text>
                    </Box>
                </Col>
            ))}
        </Row>
    </Box>
);

MyServicesCard.propTypes = {
    className: string,
    info: shape({}),
    isDesktop: bool.isRequired,
};

MyServicesCard.defaultProps = {
    className: "mb-5 mb-md-9",
    info: [],
};

export default resizableRoute(MyServicesCard);
