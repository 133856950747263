import { isAndroidPlatform, isIOSPlatform } from "util/device";

export const RESPONSE_SUCCESS_CODE = "0000";
export const RESPONSE_NOT_FOUND_CODE = "0001";
export const RESPONSE_ERROR_CODE = "0002";
const DECRYPT_KEY_FINGERPRINTER = "BolivarianoMovil_AppPA";

const isAvailablePlugin = () => window?.cordova?.plugins?.BiometricMigratePlugin;

export const decryptCredential = (credential) =>
    new Promise((resolve, reject) => {
        if (!isAvailablePlugin()) {
            resolve(undefined);
            return;
        }

        window.cordova.plugins.BiometricMigratePlugin.decryptCredential(
            credential,
            (result) => {
                resolve(result);
            },
            (error) => {
                reject(error);
            },
        );
    });

// eslint-disable-next-line import/prefer-default-export
export const getCurrentCredentials = () =>
    new Promise((resolve) => {
        if (!isAvailablePlugin()) {
            resolve(undefined);
            return;
        }
        window.cordova.plugins.BiometricMigratePlugin.getCurrentCredentials(
            (result) => {
                resolve(result);
            },
            (error) => {
                // eslint-disable-next-line no-console
                console.log(error);
                resolve(error);
            },
        );
    });

export const requestFingerPrinterDecrypt = (username, token, i18Configuration) => {
    if (isAndroidPlatform()) {
        return new Promise((resolve, reject) => {
            const decryptConfig = {
                clientId: DECRYPT_KEY_FINGERPRINTER,
                username: username.replace(/\//g, "&#47;"),
                token,
                disableBackup: true,
                maxAttempts: 3,
                locale: i18Configuration.locale || "",
                dialogTitle: i18Configuration.title || "",
                dialogMessage: i18Configuration.message || "",
            };
            // eslint-disable-next-line no-undef
            FingerprintAuth.decrypt(
                decryptConfig,
                (result) => {
                    resolve(result);
                },
                // eslint-disable-next-line no-unused-vars
                (error) => {
                    reject(error);
                },
            );
        });
    }

    if (isIOSPlatform()) {
        return new Promise((resolve) => {
            window.plugins.touchid.verifyFingerprint(
                i18Configuration?.message || "",
                () => {
                    if (!isAvailablePlugin()) {
                        resolve(undefined);
                        return;
                    }

                    window.cordova.plugins.BiometricMigratePlugin.getPasswordKeyChain(
                        (password) => {
                            if (!password) {
                                resolve(undefined);
                            } else {
                                const passData = JSON.parse(password);
                                resolve({ password: passData?.data });
                            }
                        },
                        (error) => {
                            // eslint-disable-next-line no-console
                            console.log(error);
                            resolve(undefined);
                        },
                    );
                },
                (error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    resolve(undefined);
                },
            );
        });
    }

    return undefined;
};

export const deleteBiometricCredentials = () =>
    new Promise((resolve) => {
        window.cordova.plugins.BiometricMigratePlugin.deleteBiometricCredentials(
            (result) => {
                resolve(result);
            },
            (error) => {
                resolve(error);
            },
        );
    });
