// import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions as paginatedTableActions } from "reducers/paginatedTable";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";

import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import Text from "pages/_components/Text";
import FormattedDate from "pages/_components/FormattedDate";
import TransactionStatus from "pages/transactions/_components/TransactionStatus";
import { withRouter } from "react-router-dom";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";

class TransactionsItem extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isDeletingDraft: bool.isRequired,
        fromPending: bool.isRequired,
        transaction: shape({
            cancelEnabled: bool.isRequired,
            transaction: shape({
                channel: string,
                idTransaction: string,
                creationDateTime: string,
                submitDateTime: string,
                activityName: string,
                idTransactionStatus: string,
            }),
            transactionAmounts: shape(),
        }).isRequired,
        // showEnvironment: bool,
        // activeEnvironment: shape({ type: string.isRequired }).isRequired,
        // hasTicket: bool,
        history: shape({
            push: func.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        // showEnvironment: false,
        // hasTicket: true,
    };

    // eslint-disable-next-line class-methods-use-this
    getDateTime(data, creationDateTime, idParentTransaction, submitDateTime) {
        if (idParentTransaction && data?.scheduler?.selectedOption !== "TODAY") {
            return submitDateTime;
        }
        return creationDateTime;
    }

    handleDeleteTransactionDraft = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(transactionsActions.deleteDraftRequest(idTransaction));
    };

    onClickHandler = (idTransaction) => {
        const { history, fromPending, dispatch } = this.props;
        history.push(`/transaction/${idTransaction}`, { fromPending });
        dispatch(paginatedTableActions.setKeepLastPage(true));
        if (fromPending) {
            dispatch(transactionsActions.setFromPending());
        } else {
            dispatch(transactionsActions.resetFrom());
        }
    };

    render() {
        const {
            isDesktop,
            // isDeletingDraft,
            transaction: itemTransaction,
            // eslint-disable-next-line react/prop-types
            data: dataTransaction,
            // showEnvironment,
            // activeEnvironment,
            // hasTicket,
        } = this.props;
        const propsTransaction = itemTransaction || dataTransaction;
        const { transaction, transactionAmounts } = propsTransaction;
        const {
            idTransaction,
            creationDateTime,
            activityName,
            idTransactionStatus,
            data,
            idParentTransaction,
            submitDateTime,
            programed,
            userCreatorFirstName,
            userCreatorLastName,
        } = transaction;
        const [currency] = Object.keys(transactionAmounts);
        // const amount = transactionAmounts[currency];
        // const isEconomicGroup = activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE;
        // const extra = showEnvironment ? 1 : 0;
        const quantity =
            data?.amount?.quantity !== null && data?.amount?.quantity !== undefined ? data.amount.quantity : 0;
        // const showAmount = amount !== 0 && quantity !== 0;
        // const destinatary =
        //     // eslint-disable-next-line no-nested-ternary
        //     data?.beneficiaryDescription !== null && data?.beneficiaryDescription !== undefined
        //         ? data.beneficiaryDescription
        //         : isDesktop
        //         ? "-"
        //         : null;

        return (
            <Box
                className="row-transactions-mov"
                pointer
                onClick={() => {
                    this.onClickHandler(idTransaction);
                }}>
                {isDesktop ? (
                    <>
                        <Box className="p-5 col-no-order" display="flex" alignY="center">
                            <div>{idTransaction}</div>
                        </Box>
                        <Box className="p-5" display="flex" alignY="center">
                            {programed ? i18n.get("forms.transaction.ticket.typeOperation.program") : ""}
                            <Text bold>{activityName}</Text>
                        </Box>
                        <Box className="p-5" display="flex" alignY="center">
                            <FormattedDate
                                date={this.getDateTime(data, creationDateTime, idParentTransaction, submitDateTime)}
                                anotherFormat="DD/MM/YYYY HH:mm:ss"
                            />
                        </Box>
                        <Box className="p-5" display="flex" alignY="center">
                            {`${userCreatorFirstName} ${userCreatorLastName}`}
                        </Box>
                        <Box className="p-5">
                            <TransactionStatus showIcon={false} idTransactionStatus={idTransactionStatus} showLabel />
                        </Box>
                        <Box className="p-5 justify-content-flex-end" display="flex" alignY="center">
                            {quantity && quantity > 0 ? (
                                <FormattedAmount size="4" className="mb-2" currency={currency} quantity={quantity} />
                            ) : (
                                "-"
                            )}
                        </Box>
                    </>
                ) : (
                    <Row>
                        <Col xs="6">
                            <Box className="col-no-order">
                                <Box className="mb-2 color-text-grey">
                                    <I18n id="tableHeader.transaction.order" />
                                </Box>
                                <Box className="text-bold">{idTransaction}</Box>
                            </Box>
                        </Col>
                        <Col xs="6">
                            <Box display="flex" alignX="flex-end" className="mb-2">
                                <TransactionStatus
                                    showIcon={false}
                                    idTransactionStatus={idTransactionStatus}
                                    showLabel
                                />
                            </Box>
                        </Col>
                        <Col xs="6">
                            <Box className="mb-2 color-text-grey">
                                <I18n id="tableHeader.transaction.type" />
                            </Box>
                            <Box display="flex">
                                {programed ? i18n.get("forms.transaction.ticket.typeOperation.program") : ""}
                                <Text bold>{activityName}</Text>
                            </Box>
                        </Col>
                        <Col xs="6">
                            <Box className="text-right mb-2 color-text-grey">
                                <I18n id="tableHeader.transaction.createdBy" />
                            </Box>
                            <Box display="flex" alignX="flex-end">
                                {`${userCreatorFirstName} ${userCreatorLastName}`}
                            </Box>
                        </Col>
                        <Col xs="6">
                            <Box className="mb-2 color-text-grey">
                                <I18n id="tableHeader.transaction.dateAndHour" />
                            </Box>
                            <Box display="flex">
                                <FormattedDate
                                    date={this.getDateTime(data, creationDateTime, idParentTransaction, submitDateTime)}
                                    anotherFormat="DD/MM/YYYY HH:mm:ss"
                                />
                            </Box>
                        </Col>
                        <Col xs="6">
                            <Box className="text-right mb-2 color-text-grey">
                                <I18n id="tableHeader.amountUsd" />
                            </Box>
                            <Box display="flex" alignX="flex-end">
                                {quantity && quantity > 0 ? (
                                    <FormattedAmount
                                        size="4"
                                        className="mb-2"
                                        currency={currency}
                                        quantity={quantity}
                                    />
                                ) : (
                                    "-"
                                )}
                            </Box>
                        </Col>
                    </Row>
                )}
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isDeletingDraft: transactionsSelectors.isDeletingDraft(state),
    // activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default withRouter(connect(mapStateToProps)(TransactionsItem));
