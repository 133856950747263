import React from "react";
import { bool, func, string } from "prop-types";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import { Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import Button from "pages/_components/Button";
import classNames from "classnames";
import MainContainer from "pages/_components/MainContainer";
// import * as Yup from "yup";

const UnlockDeviceQuestion = ({ formId, isDesktop, handleSubmit, handleCancel, goToHome }) => {
    const handleBack = () => {
        goToHome();
    };

    const handleSubmitForm = () => {
        handleSubmit();
    };

    const initialValues = {
        question: "test",
    };

    return (
        <>
            <Notification scopeToShow={formId} />
            <Head onBack={handleBack} title={`${formId}.title`} textBack="settings.returnToSoftToken" />
            <MainContainer
                className={classNames("main-container", {
                    "box-shadow-small background-white": !isDesktop,
                })}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    // validationSchema={validationSchema}
                >
                    {() => (
                        <Form>
                            <Box
                                borderRadius="lg"
                                background="white"
                                className={classNames(" mt-3 pt-4", {
                                    "box-shadow-small": isDesktop,
                                })}>
                                <Box
                                    borderRadius="lg"
                                    background="white"
                                    className={classNames("", {
                                        "py-7": isDesktop,
                                    })}>
                                    <Row>
                                        <Col xs={isDesktop ? 6 : 12} md={6} mdOffset={3} lg={6} lgOffset={3}>
                                            <Text size="3" labelKey="unlockDevice.question.title" bold />
                                        </Col>
                                    </Row>
                                </Box>
                            </Box>
                            <Row
                                gapX="7"
                                className={classNames("mt-7 mb-7", {
                                    "d-flex flex-column-reverse": !isDesktop,
                                })}>
                                <Col xs={3} xsOffset={3}>
                                    <Button
                                        bsStyle="outline"
                                        label="global.cancel"
                                        btnUppercase={false}
                                        block
                                        type="button"
                                        onClick={handleCancel}
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Button
                                        bsStyle="primary"
                                        label="global.continue"
                                        btnUppercase={false}
                                        // disabled={validateFields(formik)}
                                        block
                                        type="submit"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </>
    );
};

UnlockDeviceQuestion.propTypes = {
    formId: string.isRequired,
    isDesktop: bool.isRequired,
    handleSubmit: func.isRequired,
    handleCancel: func.isRequired,
    goToHome: func.isRequired,
};

export default UnlockDeviceQuestion;
