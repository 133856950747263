import classNames from "classnames";
import { arrayOf, bool, shape, string } from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

const A11yNav = ({ ariaLabel, background, bottom, buttonList, column, left, position, right, top }) => {
    const [showButtons, setShowButtons] = useState(false);
    const buttonsRef = useRef([]);

    const adjustScroll = (element) => {
        const scrollableElement = document.querySelector(".scrollable-content");
        const extraScroll = 100;

        // Verificamos si el elemento está fuera de la vista en la parte superior
        if (element?.getBoundingClientRect().top < 0) {
            scrollableElement.scrollBy({
                top: element?.getBoundingClientRect().top - extraScroll,
                behavior: "smooth",
            });
        }

        // Verificamos si el elemento está fuera de la vista en la parte inferior
        if (element?.getBoundingClientRect().bottom > window.innerHeight) {
            scrollableElement.scrollBy({
                top: element?.getBoundingClientRect().bottom - window.innerHeight + extraScroll,
                behavior: "smooth",
            });
        }
    };

    const handleKeyDown = useCallback(
        (event, index) => {
            let newIndex;
            switch (event.key) {
                case "ArrowDown":
                case "ArrowRight":
                    event.preventDefault();
                    newIndex = (index + 1) % buttonList.length;
                    buttonsRef.current[newIndex].focus();
                    break;
                case "ArrowUp":
                case "ArrowLeft":
                    event.preventDefault();
                    newIndex = (index - 1 + buttonList.length) % buttonList.length;
                    buttonsRef.current[newIndex].focus();
                    break;
                case "Escape":
                    setShowButtons(false);
                    break;
                default:
                    break;
            }
        },
        [buttonList.length],
    );

    const handleOnFocus = useCallback((index) => {
        setShowButtons(true);
        adjustScroll(buttonsRef?.current[index]);
    }, []);

    const handleOnBlur = useCallback((event) => {
        if (!buttonsRef.current.includes(event.relatedTarget)) {
            setShowButtons(false);
        }
    }, []);

    useEffect(() => {
        if (showButtons) {
            buttonsRef.current[0].focus();
        }
    }, [showButtons]);

    return (
        <nav
            aria-label={ariaLabel}
            className={classNames(`a11y-nav position-${position}`, {
                "is-focused": showButtons,
                "flex-column": column,
                [`top-${top}`]: top && showButtons,
                [`bottom-${bottom}`]: bottom && showButtons,
                [`right-${right}`]: right,
                [`left-${left}`]: left,
                [`background-color-${background}`]: background,
            })}>
            {buttonList.map((button, index) => (
                <a
                    key={button.focusOnSection}
                    ref={(el) => {
                        buttonsRef.current[index] = el;
                    }}
                    className="a11y-skip-link"
                    href={`#${button.focusOnSection}`}
                    onFocus={() => handleOnFocus(index)}
                    onBlur={handleOnBlur}
                    onKeyDown={(event) => handleKeyDown(event, index)}
                    onClick={() => {
                        setShowButtons(false);
                    }}>
                    {button.label}
                </a>
            ))}
        </nav>
    );
};

A11yNav.propTypes = {
    ariaLabel: string.isRequired,
    background: string,
    bottom: string,
    buttonList: arrayOf(
        shape({
            focusOnSection: string.isRequired,
            label: string.isRequired,
        }),
    ).isRequired,
    column: bool,
    left: string,
    position: string,
    right: string,
    top: string,
};

A11yNav.defaultProps = {
    background: null,
    bottom: null,
    column: false,
    left: null,
    position: "absolute",
    right: null,
    top: null,
};

export default A11yNav;
