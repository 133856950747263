import { Field } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { bool, shape, string } from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import Text from "pages/_components/Text";
import PinInput from "pages/_components/fields/PinInput";
import Image from "pages/_components/Image";
import PopOver from "pages/_components/PopOver";
import Tooltip from "pages/_components/Tooltip";
import CountdownCounter from "pages/login/_components/CountdownCounter";
import * as config from "util/config";
import { maskPhoneNumber, simpleMaskEmail } from "util/format";
import { isMobile } from "react-device-detect";

const FORM_ID = "secondFactor.credential";

const CREDENTIAL_TYPE_DIGITAL_KEY = "digitalKey";
const DIGITAL_KEY_LENGTH = 8;
const CREDENTIAL_TYPE_OTP = "otp";
const OTP_LENGTH = config.getInteger("credential.otp.length", 6);

const OTP_METHOD_EMAIL = "email";
const OTP_METHOD_PHONE = "phone";
const OTP_METHOD_APP_ENTRUST = "appEntrust";
const OTP_METHOD_APP_BANCA = "appBancaEmpresa";
const OTP_METHOD_SECURITY_DEVICE = "securityDevice";
// const CODE_RESPONSE_SUCCESS = "SUCCESS";
// const CODE_RESPONSE_FAILURE = "FAILURE";

const RESEND_WAITING_TIME = 300000; // 5 min

const Credential = ({
    email,
    mobileNumber,
    isSubmitting,
    values,
    credentialType,
    otpMethod,
    // codeResponse,
    isDesktop,
}) => {
    const [startCountDown, setStartCountDown] = useState(false);

    useEffect(() => {
        if (credentialType === CREDENTIAL_TYPE_OTP) {
            setStartCountDown(true);
        }
    }, [credentialType]);

    const handleResendCode = () => {
        if (!startCountDown) {
            // resendCode
            setStartCountDown(true);
        } else {
            setStartCountDown(false);
            // Send error message
        }
    };

    const [popoverData, setPopoverData] = useState({
        buttonTitle: null,
        image: "",
        textRegular: null,
        textBold: null,
        textSecondRegular: null,
        textExtraInfo: null,
    });

    const isDigitalKey = credentialType === CREDENTIAL_TYPE_DIGITAL_KEY;
    const isOtp = credentialType === CREDENTIAL_TYPE_OTP;

    const codeByAppEntrust = otpMethod === OTP_METHOD_APP_ENTRUST;
    const codeByAppBanca = otpMethod === OTP_METHOD_APP_BANCA;
    const codeBySecurityDevice = otpMethod === OTP_METHOD_SECURITY_DEVICE;
    const codeByEmail = otpMethod === OTP_METHOD_EMAIL;
    const codeByPhone = otpMethod === OTP_METHOD_PHONE;

    const canSubmit =
        (isOtp && values?.otp?.length === OTP_LENGTH) ||
        (isDigitalKey && values?.digitalKey?.length === DIGITAL_KEY_LENGTH);

    useEffect(() => {
        const newPopoverData = {};
        if (credentialType === CREDENTIAL_TYPE_DIGITAL_KEY) {
            newPopoverData.buttonTitle = i18n.get("softToken.credentials.popover.title");
            if (otpMethod === OTP_METHOD_APP_ENTRUST) {
                newPopoverData.image = "images/iconPopOvers/SOFTOKEN.svg";
                newPopoverData.textRegular = i18n.get("softToken.credentials.popover.text");
                newPopoverData.textBold = i18n.get("softToken.credentials.popover.app.entrust.label");
            }
            if (otpMethod === OTP_METHOD_APP_BANCA) {
                newPopoverData.image = "images/iconPopOvers/SOFTOKEN.svg";
                newPopoverData.textRegular = i18n.get("softToken.credentials.popover.text");
                newPopoverData.textBold = i18n.get("softToken.credentials.popover.app.empresas.label");
            }
            if (otpMethod === OTP_METHOD_SECURITY_DEVICE) {
                newPopoverData.image = "images/iconPopOvers/TOKEN.svg";
                newPopoverData.textRegular = i18n.get("hardToken.credentials.popover.text")
                newPopoverData.textBold = i18n.get("hardToken.credentials.popover.token.device.label");
                newPopoverData.textSecondRegular = i18n.get("hardToken.credentials.popover.token.device.extra.label");
            }
        } else {
            newPopoverData.buttonTitle = "¿Qué es el código temporal?";
            newPopoverData.image = "images/iconPopOvers/OTP.svg";
            newPopoverData.textRegular = "El código temporal u OTP es un código de 6 dígitos que enviamos a su&nbsp;";
            newPopoverData.textBold = "correo electrónico o por mensaje de texto";
            newPopoverData.textSecondRegular = "&nbsp;para confirmar su transacción.";
            newPopoverData.textExtraInfo =
                "Una vez enviada tendrá hasta 5min para ingresarla y poder continuar con su transacción.";
        }
        setPopoverData(newPopoverData);
    }, [credentialType, otpMethod]);

    // const CodeStatus = () => {
    //     if (codeResponse === CODE_RESPONSE_SUCCESS) {
    //         return (
    //             <Box display="flex">
    //                 {/* <Image src={"images/icons/hidePass.svg"} className="svg-icon" color="primary-color-i" /> */}
    //                 <Text size="5" bold defaultValue={i18n.get("", "Código correcto")} />
    //             </Box>
    //         );
    //     }
    //     if (codeResponse === CODE_RESPONSE_FAILURE) {
    //         return (
    //             <Box display="flex">
    //                 {/* <Image src={"images/icons/hidePass.svg"} className="svg-icon" color="primary-color-i" /> */}
    //                 <Text size="5" bold defaultValue={i18n.get("", "Código incorrecto")} />
    //             </Box>
    //         );
    //     }
    //     return <></>;
    // };

    const resendButton = useMemo(
        () => (
            <Tooltip
                onHover
                forEllipsis
                inText={i18n.get("", "Solicitar nuevo OTP")}
                position="top-centered"
                className="height-fit-content pos-relative"
                classNameTooltip="login-tooltip">
                <Button
                    onClick={handleResendCode}
                    bsStyle="only-icon"
                    image="images/sync-btn.svg"
                    imageMd
                    imageColor="primary"
                    className="m-0"
                />
            </Tooltip>
        ),
        [],
    );

    // const continueButton = useMemo(
    //     () => (
    //         <Button
    //             className="mt-6 mb-4"
    //             type="submit"
    //             bsStyle="primary"
    //             // label="global.continue"
    //             defaultLabelText={i18n.get("", "Continuar")}
    //             btnUppercase={false}
    //             loading={isSubmitting}
    //             disabled={!canSubmit}
    //             block
    //         />
    //     ),
    //     [isSubmitting, canSubmit],
    // );

    return (
        <Box display="flex" column fullWidth>
            <Box className="title-with-icon">
                <Image src="images/iconTitles/security_device.svg" />
                <Text component="h3" defaultValue={i18n.get("", "Verificación")} />
            </Box>
            {isDigitalKey ? (
                <>
                    <Text
                        component="h4"
                        color="heading"
                        bold
                        labelKey=""
                        defaultValue={`${i18n.get("softToken.credentials.title")} ${codeBySecurityDevice ? i18n.get("hardToken.credentials.alias.label") : ""}`}
                    />
                    <Box display="block" fullWidth className="mb-7">
                        <Text defaultValue={i18n.get("softToken.credentials.text")} />
                        <Text
                            defaultValue={i18n.get(
                                "",
                                codeBySecurityDevice
                                    ? i18n.get("hardToken.credentials.text")
                                    : i18n.get("softToken.credentials.text"),
                            )}
                        />
                        {codeByAppEntrust && <Text bold defaultValue={i18n.get("softToken.credentials.app.entrust.label")} />}
                        {codeByAppBanca && <Text bold defaultValue={i18n.get("softToken.credentials.app.empresas.label")} />}
                        {codeBySecurityDevice && <Text bold defaultValue={i18n.get("hardToken.credentials.label")} />}
                    </Box>
                    <Field
                        idForm={FORM_ID}
                        autoComplete="off"
                        name="digitalKey"
                        component={PinInput}
                        maxLength={1}
                        labelNoMarginTop
                        noMarginBottom
                        type="number"
                        inputLenght={8}
                        // defaultLabelText={i18n.get("", "Clave virtual")}
                        autoFocus={isDesktop}
                    />
                </>
            ) : (
                <>
                    <Text
                        component="h4"
                        color="heading"
                        bold
                        labelKey=""
                        size={isMobile && "2"}
                        defaultValue={i18n.get("", "Ingresa su código OTP")}
                    />
                    {codeByEmail && (
                        <Box display="block" fullWidth className="mb-7">
                            <Text
                                className="line-height-15"
                                size={isMobile && "3"}
                                defaultValue={i18n.get(
                                    "",
                                    "Se ha enviado un mensaje como confirmación al correo electrónico&nbsp;",
                                )}
                            />
                            <Text size={isMobile && "3"} bold>
                                {simpleMaskEmail(email)}
                            </Text>
                        </Box>
                    )}
                    {codeByPhone && (
                        <Box display="block" fullWidth className="mb-7">
                            <Text defaultValue={i18n.get("", "Se ha enviado un mensaje SMS al número celular&nbsp;")} />
                            <Text bold>{maskPhoneNumber(mobileNumber)}</Text>
                        </Box>
                    )}
                    <Box display="flex" fullWidth>
                        <Field
                            idForm={FORM_ID}
                            autoComplete="off"
                            name="otp"
                            component={PinInput}
                            maxLength={1}
                            labelNoMarginTop
                            noMarginBottom
                            type="number"
                            inputLenght={6}
                            // defaultLabelText={i18n.get("", "Código OTP")}
                            autoFocus={isDesktop}
                            className="remove-flex"
                        />
                        {resendButton}
                    </Box>
                    {/* <CodeStatus /> */}
                    {startCountDown && (
                        <Box display="flex" className="mt-3 mb-4">
                            <Image
                                src="images/icons/info.svg"
                                wrapperClassName="fit-content-width align-self-center svg-color-login-i"
                            />
                            <Text
                                color="text-disabled-color"
                                size="5"
                                className="pl-2 pt-1 pb-2"
                                defaultValue="Su código temporal expirará en&nbsp;"
                            />
                            <CountdownCounter
                                total={RESEND_WAITING_TIME}
                                startCountDown={startCountDown}
                                setStartCountDown={setStartCountDown}
                                className="pt-1 pb-2 color-text-disabled-color"
                                size="5"
                            />
                            <Text
                                color="text-disabled-color"
                                className="pt-1 pb-2"
                                size="5"
                                defaultValue="&nbsp;minutos"
                            />
                        </Box>
                    )}
                </>
            )}
            {/* {continueButton} */}
            <PopOver
                image={popoverData.image}
                content={
                    <>
                        <Box display="block" fullWidth>
                            <Text defaultValue={i18n.get("", popoverData.textRegular)} />
                            <Text bold defaultValue={i18n.get("", popoverData.textBold)} />
                            {popoverData.textSecondRegular && (
                                <Text defaultValue={i18n.get("", popoverData.textSecondRegular)} />
                            )}
                        </Box>
                        {popoverData.textExtraInfo && (
                            <Box display="block" fullWidth className="mt-3">
                                <Text
                                    bold
                                    size="7"
                                    color="strong-primary"
                                    defaultValue={i18n.get("", popoverData.textExtraInfo)}
                                />
                            </Box>
                        )}
                    </>
                }
                position="top-left">
                <Button
                    primaryLink
                    bsStyle="link"
                    defaultLabelText={i18n.get("", popoverData.buttonTitle)}
                    className={isMobile ? "px-0 size-3" : "px-0"}
                />
            </PopOver>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    otpMethod: sessionSelectors.getOtpMethod(state),
    email: sessionSelectors.getEmail(state),
    mobileNumber: sessionSelectors.getMobileNumber(state),
    codeResponse: "SUCCESS",
});

Credential.propTypes = {
    values: shape({}),
    isDesktop: bool.isRequired,
    credentialType: string,
    otpMethod: string,
    className: string,
    isSubmitting: bool.isRequired,
};

Credential.defaultProps = {
    values: null,
    className: null,
    credentialType: null,
    otpMethod: null,
};

export default connect(mapStateToProps)(Credential);
