import { call, put, select, takeLatest } from "redux-saga/effects";
import { routerActions, replace, push } from "react-router-redux";

import { ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE } from "util/responses.js";
import { types, actions } from "reducers/administration/usersInvite";
import * as usersInvite from "middleware/administration/usersInvite";
import * as form from "middleware/form";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { credentialsToUnderscoreFormat } from "util/form.js";
import { types as secondFactorTypes } from "reducers/secondFactor";
import { selectors as sessionSelectors, actions as sessionActions } from "reducers/session";

const sagas = [
    takeLatest(types.ADMINISTRATION_USER_INVITE_PRE_REQUEST, userInvitePre),
    takeLatest(types.ADMINISTRATION_USER_INVITE_VERIFY, userInviteVerify),
    takeLatest(types.ADMINISTRATION_USER_INVITE_PREVIEW, userInvitePreview),
    takeLatest(types.ADMINISTRATION_USER_INVITE_CONFIRM, userInviteConfirm),
];

export default sagas;

function* userInvitePre() {
    const response = yield call(usersInvite.inviteUsersPre);

    if (response.type === "W") {
        yield put(actions.userInvitePreFailure());
        yield put(routerActions.replace("/administration/users"));
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
        );
    } else {
        yield put({
            type: types.ADMINISTRATION_USER_INVITE_PRE_REQUEST_SUCCESS,
            data: response.data.data,
        });
    }
}

function* userInviteVerify({ document }) {
    const { document: documentNumber, type } = document;
    // const documentNumber = utilIdentification.generateIdentificationToSend(document);
    const params = {
        documentCountry: "EC",
        documentType: type,
        documentNumber,
    };
    const response = yield call(usersInvite.inviteUserVerify, params);

    if (response.type === "W") {
        let responseMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");
        switch (response.data.code) {
            case "COR020W":
                responseMessage = response.data.data.documentNumber;
                break;
            default:
        }
        yield put(notificationActions.showNotification(responseMessage, "error", ["administrationUserInvite"]));
        yield put({ type: types.ADMINISTRATION_USER_INVITE_VERIFY_FAILED });
    } else {
        const { data } = response.data;
        yield put({ type: types.ADMINISTRATION_USER_INVITE_VERIFY_SUCCESS, data });
        yield put(push("/administration/users/inviteStep2"));
    }
}

function* userInvitePreview({ params, formikBag }) {
    const response = yield call(usersInvite.inviteUserPreview, params);

    if (response.type === "W") {
        if (response.data.data.NO_FIELD) {
            yield put(
                notificationActions.showNotification(response.data.data.NO_FIELD, "error", [
                    "administrationUserInvite",
                ]),
            );
        } else {
            formikBag.setErrors(response.data.data);
            yield put(replace("/administration/users/inviteStep2"));
        }
    } else {
        const responseCredentials = yield call(form.listCredentialsGroups, null, "administration.users.invite.send");
        const credentialGroups = responseCredentials.data.data.groups;
        const idActivity = "administration.users.invite.send";
        yield put({ type: types.ADMINISTRATION_USER_INVITE_PREVIEW_SUCCESS, params, credentialGroups, idActivity });
        yield put(push("/administration/users/inviteStep3"));
    }
}

function* userInviteConfirm({ confirmationParams, secondFactor, formikBag }) {
    const activeEnvironment = yield select(sessionSelectors.getActiveEnvironment);
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(secondFactor);
    const response = yield call(usersInvite.inviteUserConfirm, {
        ...confirmationParams,
        ...credentialsWithUnderscore,
    });

    formikBag.setSubmitting(false);
    if (response.type === "W") {
        const { code, data } = response.data;
        if (code === "COR020W" && data.secondFactor) {
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
        }
        if (code === "API707W") {
            yield put(sessionActions.logoutUserBlocked());
            return;
        }
        if (code === "API708W") {
            yield put(
                notificationActions.showNotification(
                    i18n.get(
                        "secondFactor.credential.otp.expired",
                        "Código OTP expirado, solicite un nuevo código OTP",
                    ),
                    "warning",
                    ["administration"],
                ),
            );
            return;
        }
        formikBag.setErrors(response.data.data);
        yield put(replace("/administration/users/inviteStep3"));
    } else {
        yield put(actions.userInviteFinish());
        if (response.data.code && response.data.code === ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE) {
            const message = i18n.get(
                confirmationParams?.isInvitingNewUser
                    ? "administration.users.invite.pending.messages"
                    : "administration.users.invite.exist.pending.message",
                response.data.message,
            );
            yield put(notificationActions.showNotification(message, "warning", ["administration"]));
        } else {
            let message = "";
            if (confirmationParams?.isInvitingNewUser) {
                message = i18n.get("administration.users.invite.success");
            } else {
                message = i18n.get("administration.users.invite.exist.success", "", {
                    customer: confirmationParams?.docNumber,
                    workspace: activeEnvironment?.name,
                });
            }
            yield put(notificationActions.showNotification(message, "success", ["administration"]));
        }

        yield put(replace("/administration/users"));
    }
}
