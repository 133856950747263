import classNames from "classnames";
import Logo from "pages/login/_components/Logo";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text";
import { bool, func, shape } from "prop-types";
import React, { Component } from "react";
import { getLang } from "util/i18n";

class TermsAndConditions extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        history: shape({ goBack: func }),
        fromOnboarding: bool,
    };

    static defaultProps = {
        history: null,
        fromOnboarding: false,
    };

    backButtonAction = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { isMobile, fromOnboarding } = this.props;

        return (
            <>
                {isMobile ? (
                    <Box top="0" position="absolute" fullWidth>
                        <Head
                            onBack={!fromOnboarding && this.backButtonAction}
                            logo={<Logo isDesktop={!isMobile} className="svg-image logo" />}
                        />
                    </Box>
                ) : (
                    <Head
                        additionalClassName="special-header-login"
                        accessibilityTextId="global.termAndConditions"
                        logo={<Logo isDesktop={!isMobile} className="svg-image logo" />}
                        onClose={!fromOnboarding && this.backButtonAction}
                        flex
                    />
                )}

                <MainContainer
                    className={classNames("main-container", {
                        "container-fluid with-special-header-login": !isMobile,
                    })}>
                    <Box className="app-default-main styled-2">
                        <Box className="mb-5" display="flex" alignX="center" fullWidth>
                            <Text color="text" size="2" bold labelKey="global.termAndConditions" />
                        </Box>
                        <Box className="terms-and-conditions" display="flex" alignX="center" fullWidth>
                            <Text color="text" size="7" labelKey="global.termAndConditions">
                                {getLang() === "en" ? (
                                    <>
                                        Terms and Conditions General Site Usage Welcome to Techbank. This site is
                                        provided as a service to our visitors and may be used for informational
                                        informational purposes only. Because the Terms and Conditions contain legal
                                        obligations, please read them carefully. 1. YOUR AGREEMENT By using this Site,
                                        you agree to be bound by, and to comply with, these Terms and Conditions. If you
                                        do not agree to these Terms and Conditions, please do not use this site. PLEASE
                                        NOTE: We reserve the right, at our sole discretion, to change, modify or
                                        otherwise alter these Terms and Conditions at any time. Unless otherwise
                                        indicated, amendments will become effective immediately. Please review these
                                        Terms and Conditions periodically. Your continued use of the Site following the
                                        posting of changes and/or modifications will constitute your acceptance of the
                                        revised Terms and Conditions and the reasonableness of these standards standards
                                        for notice of changes. For your information, this page was last updated as of
                                        the date at the top of these terms and conditions. 2. PRIVACY Please review our
                                        Privacy Policy, which also governs your visit to this Site, to understand our
                                        practices. 3. LINKED SITES This Site may contain links to other independent
                                        third-party Web sites (&quot;Linked Sites”). These Linked Sites are provided
                                        solely as a convenience to our visitors. Such Linked Sites are not under our
                                        control, and we are not responsible for and does not endorse the content of such
                                        Linked Sites, including any information or materials contained on such Linked
                                        Sites. You will need to make your own independent judgment regarding your
                                        interaction with these Linked Sites. 4. FORWARD LOOKING STATEMENTS All materials
                                        reproduced on this site speak as of the original date of publication or filing.
                                        The fact that a document is available on this site does not mean that the
                                        information contained in such document has not been modified or superseded by
                                        events or by a subsequent document or filing. We have no duty or policy to
                                        update any information or statements contained on this site and, therefore, such
                                        information or statements should not be relied upon as being current as of the
                                        date you access this site. 5. DISCLAIMER OF WARRANTIES AND LIMITATION OF
                                        LIABILITY A. THIS SITE MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL ERRORS. WE
                                        DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF THE MATERIALS OR THE
                                        RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION DISPLAYED OR
                                        DISTRIBUTED THROUGH THE SITE. YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (i) YOUR
                                        USE OF THE SITE, INCLUDING ANY RELIANCE ON ANY SUCH OPINION, ADVICE, STATEMENT,
                                        MEMORANDUM, OR INFORMATION CONTAINED HEREIN, SHALL BE AT YOUR SOLE RISK; (ii)
                                        THE SITE IS PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS;
                                        (iii) EXCEPT AS EXPRESSLY PROVIDED HEREIN WE DISCLAIM ALL WARRANTIES OF ANY
                                        KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO IMPLIED
                                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE
                                        EFFORT, TITLE AND NON-INFRINGEMENT; (iv) WE MAKE NO WARRANTY WITH RESPECT TO THE
                                        RESULTS THAT MAY BE OBTAINED FROM THIS SITE, THE PRODUCTS OR SERVICES ADVERTISED
                                        OR OFFERED OR MERCHANTS INVOLVED; (v) ANY MATERIAL DOWNLOADED OR OTHERWISE
                                        OBTAINED THROUGH THE USE OF THE SITE IS DONE AT YOUR OWN DISCRETION AND RISK;
                                        and (vi) YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM
                                        OR FOR ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. B.
                                        YOU UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED
                                        TO, NEGLIGENCE, SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                                        SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE
                                        INABILITY TO USE, ANY OF OUR SITES OR MATERIALS OR FUNCTIONS ON ANY SUCH SITE,
                                        EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING
                                        LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY
                                        LIMITED REMEDY. 6. EXCLUSIONS AND LIMITATIONS SOME JURISDICTIONS DO NOT ALLOW
                                        THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY
                                        FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, OUR LIABILITY IN SUCH
                                        JURISDICTION SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW. 7. OUR
                                        PROPRIETARY RIGHTS This Site and all its Contents are intended solely for
                                        personal, non-commercial use. Except as expressly provided, nothing within the
                                        Site shall be construed as conferring any license under our or any third
                                        party&apos;s intellectual property rights, whether by estoppel, implication,
                                        waiver, or otherwise. Without limiting the generality of the foregoing, you
                                        acknowledge and agree that all content available through and used to operate the
                                        Site and its services is protected by copyright, trademark, patent, or other
                                        proprietary rights. You agree not to: (a) modify, alter, or deface any of the
                                        trademarks, service marks, trade dress (collectively &quot;Trademarks&quot;) or
                                        other intellectual property made available by us in connection with the Site;
                                        (b) hold yourself out as in any way sponsored by, affiliated with, or endorsed
                                        by us, or any of our affiliates or service providers; (c) use any of the
                                        Trademarks or other content accessible through the Site for any purpose other
                                        than the purpose for which we have made it available to you; (d) defame or
                                        disparage us, our Trademarks, or any aspect of the Site; and (e) adapt,
                                        translate, modify, decompile, disassemble, or reverse engineer the Site or any
                                        software or programs used in connection with it or its products and services.
                                        The framing, mirroring, scraping or data mining of the Site or any of its
                                        content in any form and by any method is expressly prohibited. 8. INDEMNITY By
                                        using the Site web sites you agree to indemnify us and affiliated entities
                                        (collectively &quot;Indemnities&quot;) and hold them harmless from any and all
                                        claims and expenses, including (without limitation) attorney&apos;s fees,
                                        arising from your use of the Site web sites, your use of the Products and
                                        Services, or your submission of ideas and/or related materials to us or from any
                                        person&apos;s use of any ID, membership or password you maintain with any
                                        portion of the Site, regardless of whether such use is authorized by you. 9.
                                        COPYRIGHT AND TRADEMARK NOTICE Except our generated dummy copy, which is free to
                                        use for private and commercial use, all other text is copyrighted.
                                        generator.lorem-ipsum.info © 2013, all rights reserved 10. INTELLECTUAL PROPERTY
                                        INFRINGEMENT CLAIMS It is our policy to respond expeditiously to claims of
                                        intellectual property infringement. We will promptly process and investigate
                                        notices of alleged infringement and will take appropriate actions under the
                                        Digital Millennium Copyright Act (&quot;DMCA&quot;) and other applicable
                                        intellectual property laws. Notices of claimed infringement should be directed
                                        to: contact@lorem-ipsum.info 11. PLACE OF PERFORMANCE This Site is controlled,
                                        operated and administered by us from our office in Kiev, Ukraine. We make no
                                        representation that materials at this site are appropriate or available for use
                                        at other locations outside of the Ukraine and access to them from territories
                                        where their contents are illegal is prohibited. If you access this Site from a
                                        location outside of the Ukraine, you are responsible for compliance with all
                                        local laws. 12. GENERAL A. If any provision of these Terms and Conditions is
                                        held to be invalid or unenforceable, the provision shall be removed (or
                                        interpreted, if possible, in a manner as to be enforceable), and the remaining
                                        provisions shall be enforced. Headings are for reference purposes only and in no
                                        way define, limit, construe or describe the scope or extent of such section. Our
                                        failure to act with respect to a breach by you or others does not waive our
                                        right to act with respect to subsequent or similar breaches. These Terms and
                                        Conditions set forth the entire understanding and agreement between us with
                                        respect to the subject matter contained herein and supersede any other
                                        agreement, proposals and communications, written or oral, between our
                                        representatives and you with respect to the subject matter hereof, including any
                                        terms and conditions on any of customer&apos;s documents or purchase orders. B.
                                        No Joint Venture, No Derogation of Rights. You agree that no joint venture,
                                        partnership, employment, or agency relationship exists between you and us as a
                                        result of these Terms and Conditions or your use of the Site. Our performance of
                                        these Terms and Conditions is subject to existing laws and legal process, and
                                        nothing contained herein is in derogation of our right to comply with
                                        governmental, court and law enforcement requests or requirements relating to
                                        your use of the Site or information provided to or gathered by us with respect
                                        to such use.
                                    </>
                                ) : (
                                    <>
                                        Términos y condiciones Uso general del sitio Bienvenido a Techbank. Este sitio
                                        se proporciona como un servicio a nuestros visitantes y puede usarse solo con
                                        fines informativos. Debido a que los Términos y Condiciones contienen
                                        obligaciones legales, léalos detenidamente. 1. SU ACUERDO Al usar este Sitio,
                                        usted acepta estar obligado y cumplir con estos Términos y condiciones. Si no
                                        está de acuerdo con estos Términos y condiciones, no utilice este sitio. TENGA
                                        EN CUENTA: Nos reservamos el derecho, a nuestro exclusivo criterio, de cambiar,
                                        modificar o alterar estos Términos y condiciones en cualquier momento. A menos
                                        que se indique lo contrario, las enmiendas entrarán en vigencia de inmediato.
                                        Revise estos Términos y condiciones periódicamente. Su uso continuado del Sitio
                                        después de la publicación de cambios y / o modificaciones constituirá su
                                        aceptación de los Términos y Condiciones revisados y la razonabilidad de estas
                                        normas para la notificación de cambios. Para su información, esta página se
                                        actualizó por última vez a partir de la fecha en la parte superior de estos
                                        términos y condiciones. 2. PRIVACIDAD Revise nuestra Política de privacidad, que
                                        también rige su visita a este Sitio, para comprender nuestras prácticas. 3.
                                        SITIOS ENLAZADOS Este Sitio puede contener enlaces a otros sitios web de
                                        terceros independientes (&quot;Sitios Enlazados&quot;). Estos Sitios Enlazados
                                        se proporcionan únicamente para conveniencia de nuestros visitantes. Dichos
                                        Sitios Enlazados no están bajo nuestro control y no estamos responsable y no
                                        respalda el contenido de dichos Sitios vinculados, incluida cualquier
                                        información o materiales contenidos en dichos Sitios vinculados. Deberá hacer su
                                        propio juicio independiente con respecto a su interacción con estos Sitios
                                        vinculados. 4. DECLARACIONES DE MIRADA HACIA ADELANTE Todos los materiales
                                        reproducidos en este sitio habla a partir de la fecha original de publicación o
                                        archivo. El hecho de que un documento esté disponible en este sitio no significa
                                        que la información contenida en dicho documento no haya sido modificada o
                                        reemplazada por eventos o por un documento o archivo posterior. no tiene el
                                        deber ni la política de actualizar ninguna información o declaración contenida
                                        en este sitio y, por lo tanto, no se debe confiar en que dicha información o
                                        declaraciones estén actualizadas a partir de la fecha e accede a este sitio. 5.
                                        DESCARGO DE RESPONSABILIDAD DE GARANTÍAS Y LIMITACIÓN DE RESPONSABILIDAD A. ESTE
                                        SITIO PUEDE CONTENER IMPRECISIONES Y ERRORES TIPOGRÁFICOS. NO GARANTIZAMOS LA
                                        PRECISIÓN O LA COMPLETIDAD DE LOS MATERIALES O LA CONFIABILIDAD DE CUALQUIER
                                        CONSEJO, OPINIÓN, DECLARACIÓN U OTRA INFORMACIÓN VISUALIZADA O DISTRIBUIDA A
                                        TRAVÉS DEL SITIO. USTED ENTIENDE Y ACEPTA EXPRESAMENTE QUE: (i) SU USO DEL
                                        SITIO, INCLUYENDO CUALQUIER CONFIANZA EN CUALQUIER OPINIÓN, ASESORAMIENTO,
                                        DECLARACIÓN, MEMORANDO O INFORMACIÓN CONTENIDA AQUÍ, SERÁ BAJO SU PROPIO RIESGO;
                                        (ii) EL SITIO SE PROPORCIONA &quot;TAL CUAL&quot; Y &quot;SEGÚN
                                        DISPONIBILIDAD&quot;; (iii) EXCEPTO LO EXPRESAMENTE PROPORCIONADO AQUÍ NOSOTROS
                                        RENUNCIAMOS A TODAS LAS GARANTÍAS DE CUALQUIER TIPO, INCLUSO EXPRESAS O
                                        IMPLÍCITAS, INCLUYENDO, PERO SIN LIMITARSE A LAS GARANTÍAS IMPLÍCITAS DE
                                        COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO ESPECÍFICO, ESFUERZO DE TRABAJO Y
                                        TENER EN CUENTA; (iv) NO OFRECEMOS GARANTÍA CON RESPECTO A LOS RESULTADOS QUE
                                        PUEDEN OBTENERSE DE ESTE SITIO, LOS PRODUCTOS O SERVICIOS PUBLICITADOS O
                                        OFRECIDOS O LOS COMERCIANTES INVOLUCRADOS; (v) CUALQUIER MATERIAL DESCARGADO O
                                        DE OTRA MANERA OBTENIDO A TRAVÉS DEL USO DEL SITIO SE HACE A SU PROPIA
                                        DISCRECIÓN Y RIESGO; y (vi) SERÁ RESPONSABLE POR CUALQUIER DAÑO A SU SISTEMA
                                        INFORMÁTICO O POR CUALQUIER PÉRDIDA DE DATOS QUE RESULTE DE LA DESCARGA DE DICHO
                                        MATERIAL. B. USTED ENTIENDE Y ACEPTA QUE BAJO NINGUNA CIRCUNSTANCIA, INCLUYENDO,
                                        PERO SIN LIMITARSE A, NEGLIGENCIA, SEREMOS RESPONSABLES POR CUALQUIER DAÑO
                                        DIRECTO, INDIRECTO, INCIDENTAL, ESPECIAL, PUNITIVO O CONSECUENTE QUE RESULTE DEL
                                        USO O LA INCAPACIDAD DE USO , CUALQUIERA DE NUESTROS SITIOS O MATERIALES O
                                        FUNCIONES EN CUALQUIER SITIO, INCLUSO SI HEMOS SIDO INFORMADOS DE LA POSIBILIDAD
                                        DE TALES DAÑOS. LAS LIMITACIONES ANTERIORES SE APLICARÁN SIN PENSAR CUALQUIER
                                        FALLA DEL PROPÓSITO ESENCIAL DE CUALQUIER RECURSO LIMITADO. 6. EXCLUSIONES Y
                                        LIMITACIONES ALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN DE CIERTAS
                                        GARANTÍAS O LA LIMITACIÓN O EXCLUSIÓN DE RESPONSABILIDAD POR DAÑOS INCIDENTALES
                                        O CONSECUENTES. POR LO TANTO, NUESTRA RESPONSABILIDAD EN TAL JURISDICCIÓN SE
                                        LIMITARÁ AL EXTREMO MÁXIMO PERMITIDO POR LA LEY. 7. NUESTROS DERECHOS DE
                                        PROPIEDAD Este sitio y todos sus contenidos están destinados exclusivamente para
                                        uso personal, no comercial. Salvo lo dispuesto expresamente, nada en el Sitio se
                                        interpretará como una concesión de licencia bajo nuestros derechos de propiedad
                                        intelectual o los de terceros, ya sea por impedimento, implicación, renuncia u
                                        otro. Sin limitar la generalidad de lo anterior, usted reconoce y acepta que
                                        todo el contenido disponible y utilizado para operar el Sitio y sus servicios
                                        está protegido por derechos de autor, marca registrada, patente u otros derechos
                                        de propiedad. Usted acepta no: (a) modificar, alterar o desfigurar ninguna de
                                        las marcas comerciales, marcas de servicio, imagen comercial (colectivamente
                                        &quot;Marcas comerciales&quot;) u otra propiedad intelectual puesta a nuestra
                                        disposición en relación con el Sitio; (b) mantenerse como patrocinado, afiliado
                                        o respaldado por nosotros, o cualquiera de nuestros afiliados o proveedores de
                                        servicios; (c) utilizar cualquiera de las Marcas comerciales u otro contenido
                                        accesible a través del Sitio para cualquier otro propósito que no sea el que
                                        hemos puesto a su disposición; (d) difamarnos o menospreciarnos, nuestras Marcas
                                        comerciales o cualquier aspecto del Sitio; y (e) adaptar, traducir, modificar,
                                        descompilar, desensamblar o aplicar ingeniería inversa al Sitio o cualquier
                                        software o programa utilizado en relación con él o sus productos y servicios.
                                        Queda expresamente prohibido enmarcar, duplicar, raspar o extraer datos del
                                        Sitio o de cualquiera de sus contenidos en cualquier forma y por cualquier
                                        método. 8. INDEMNIZACIÓN Al usar los sitios web del Sitio, usted acepta
                                        indemnizarnos a nosotros y a las entidades afiliadas (colectivamente
                                        &quot;Indemnizaciones&quot;) y eximirlos de toda responsabilidad por todos los
                                        reclamos y gastos, incluidos (sin limitación) los honorarios de abogados,
                                        derivados de su uso del Sitio sitios web, su uso de los Productos y Servicios, o
                                        su presentación de ideas y / o materiales relacionados a nosotros o del uso de
                                        cualquier persona de cualquier ID, membresía o contraseña que mantenga con
                                        cualquier parte del Sitio, independientemente de si dicho uso es autorizado por
                                        usted 9. AVISO DE DERECHOS DE AUTOR Y MARCA COMERCIAL Excepto nuestra copia
                                        ficticia generada, que es de uso gratuito para uso privado y comercial, todos
                                        los demás textos tienen derechos de autor. generator.lorem-ipsum.info © 2013,
                                        todos los derechos reservados 10. RECLAMACIONES POR INFRACCIÓN DE LA PROPIEDAD
                                        INTELECTUAL Es nuestra política responder rápidamente a las reclamaciones de
                                        infracción de la propiedad intelectual. Procesaremos e investigaremos de
                                        inmediato los avisos de presuntas infracciones y tomaremos las medidas
                                        apropiadas en virtud de la Ley de Derechos de Autor Digital Millennium
                                        (&quot;DMCA&quot;) y otras leyes de propiedad intelectual aplicables. Los avisos
                                        de supuesta infracción deben dirigirse a: contact@lorem-ipsum.info 11. LUGAR DE
                                        DESEMPEÑO Este sitio es controlado, operado y administrado por nosotros desde
                                        nuestra oficina en Kiev, Ucrania. No garantizamos que los materiales en este
                                        sitio sean apropiados o estén disponibles para su uso en otros lugares fuera de
                                        Ucrania y que el acceso a ellos desde territorios donde su contenido es ilegal
                                        está prohibido. Si accede a este Sitio desde una ubicación fuera de Ucrania,
                                        usted es responsable de cumplir con todas las leyes locales. 12. GENERAL A. Si
                                        alguna disposición de estos Términos y Condiciones se considera inválida o
                                        inaplicable, la disposición se eliminará (o se interpretará, si es posible, de
                                        manera que sea exigible), y las disposiciones restantes se aplicarán. Los
                                        encabezados son solo para fines de referencia y de ninguna manera definen,
                                        limitan, interpretan o describen el alcance o la extensión de dicha sección. El
                                        hecho de que no actuemos con respecto a una infracción suya o de terceros no
                                        renuncia a nuestro derecho a actuar con respecto a infracciones posteriores o
                                        similares. Estos Términos y Condiciones establecen la comprensión y el acuerdo
                                        completo entre nosotros con respecto al tema contenido en este documento y
                                        reemplazan cualquier otro acuerdo, propuesta y comunicación, escrita u oral,
                                        entre nuestros representantes y usted con respecto al tema del presente,
                                        incluido cualquier términos y condiciones en cualquiera de los documentos u
                                        órdenes de compra del cliente. B. Sin empresa conjunta, sin excepción de
                                        derechos. Usted acepta que no existe una relación de empresa conjunta, sociedad,
                                        empleo o agencia entre usted y nosotros como resultado de estos Términos y
                                        Condiciones o su uso del Sitio. Nuestro cumplimiento de estos Términos y
                                        condiciones está sujeto a las leyes y procesos legales existentes, y nada de lo
                                        contenido en este documento constituye una derogación de nuestro derecho a
                                        cumplir con las solicitudes o requisitos gubernamentales, judiciales y
                                        policiales relacionados con el uso del Sitio o la información proporcionada o
                                        reunidos por nosotros con respecto a dicho uso.
                                    </>
                                )}
                            </Text>
                        </Box>
                        <Box className="mt-10 mb-5 mb-md-10">
                            <Button href="/" label="login.comeIn" bsStyle="primary" block />
                        </Box>
                    </Box>
                </MainContainer>
            </>
        );
    }
}

export default TermsAndConditions;
