import classNames from "classnames";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { arrayOf, bool, element, func, string, number, shape } from "prop-types";
import React, { Component } from "react";
import MaskedInput from "react-text-mask";
import * as i18n from "util/i18n";

class MaskedTextField extends Component {
    static propTypes = {
        autoComplete: string,
        autoFocus: bool,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        handleChange: func,
        className: string,
        hidelabel: bool,
        hidePlaceholder: bool,
        inputFunctions: element,
        inputRef: shape({}),
        isDesktop: bool.isRequired,
        isMobile: bool.isRequired,
        isMobileNative: bool.isRequired,
        mask: arrayOf(string),
        maxLength: number,
        mobileOS: string,
        pattern: string,
        renderAs: string,
        type: string,
        uppercase: bool,
        labelNoMarginTop: bool,
        isFocused: bool,
        idForm: string,
        disabled: bool,
    };

    static defaultProps = {
        autoComplete: "on",
        autoFocus: false,
        hidelabel: false,
        hidePlaceholder: false,
        inputFunctions: null,
        inputRef: React.createRef(),
        handleChange: null,
        mask: null,
        maxLength: 50,
        className: null,
        pattern: null,
        renderAs: "input",
        mobileOS: null,
        type: "text",
        uppercase: true,
        labelNoMarginTop: false,
        isFocused: false,
        idForm: null,
        disabled: false,
    };

    handleBlur = (event) => {
        const {
            field: { onBlur },
        } = this.props;

        onBlur(event);
    };

    handleChange = (event) => {
        const { field, form, uppercase } = this.props;

        form.setFieldValue(field.name, uppercase ? event.target.value.toUpperCase() : event.target.value);

        this.setError(null);
    };

    setError = (msg) => {
        const {
            form: { errors, setErrors },
            field: { name },
        } = this.props;

        if (msg) {
            setErrors({ ...errors, [name]: msg });
        } else {
            const { [name]: error, ...rest } = errors;
            setErrors(rest);
        }
    };

    render() {
        const {
            field,
            form: { touched, errors },
            hidelabel,
            hidePlaceholder,
            idForm,
            inputFunctions,
            isFocused,
            mask,
            labelNoMarginTop,
            className,
            disabled,
        } = this.props;
        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={classNames("form-group", className, {
                    "has-error": hasError,
                    "has-focus": isFocused,
                })}>
                {!hidelabel && (
                    <FieldLabel labelNoMarginTop={labelNoMarginTop} labelKey={`${idForm}.${field.name}.label`} />
                )}

                <div className="input-group">
                    <MaskedInput
                        className="form-control"
                        guide={false}
                        mask={mask}
                        onBlur={this.handleBlur}
                        placeholder={hidePlaceholder ? "" : i18n.get(`${idForm}.${field.name}.placeholder`)}
                        {...field}
                        onChange={this.handleChange}
                        disabled={disabled}
                    />
                    {inputFunctions}
                </div>
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default MaskedTextField;
