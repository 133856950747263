import React, { Component, Children } from "react";
import { node, string } from "prop-types";
import { Route, Redirect } from "react-router-dom";

import Nav from "pages/_components/Nav";

class WizardTabs extends Component {
    static propTypes = {
        children: node.isRequired,
        className: string,
    };

    static defaultProps = {
        className: "",
    };

    render() {
        const { children, className } = this.props;
        const steps = Children.count(children);

        return (
            <Route>
                {({ match, location }) => (
                    <Nav className={className}>
                        {Children.map(children, (child, index) => {
                            const path = `${match.url}/step${index + 1}`;
                            const { disabled } = child.props;

                            if (disabled && location.pathname === path) {
                                const nextStep = index + 2 > steps ? 1 : index + 2;

                                return <Redirect to={`${match.url}/step${nextStep}`} />;
                            }

                            return (
                                <Nav.Item
                                    className="btn tab"
                                    href={disabled ? "#" : path}
                                    disabled={disabled}
                                    tabIndex={disabled ? -1 : null}>
                                    {child}
                                </Nav.Item>
                            );
                        })}
                    </Nav>
                )}
            </Route>
        );
    }
}

export default WizardTabs;
