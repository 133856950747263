import * as API from "middleware/api";

export const frequentDestinationPre = () => API.executeWithAccessToken("/frequentdestinations.pre");

export const loadListRequest = ({ pageNumber = 1, filterText, fullList, fieldType } = {}) =>
    API.executeWithAccessToken("/frequentdestinations.list", {
        pageNumber,
        nameSearch: filterText,
        fullList,
        fieldType,
    });

export const createDestinationPreview = (name, productType) =>
    API.executeWithAccessToken("/frequentdestinations.create.preview", {
        name,
        productType,
    });

export const createDestination = (
    number,
    idEnvironment,
    idFrequentDestination,
    localBank,
    name,
    productType,
    recipientDocumentType,
    recipientDocument,
    recipientDocumentCountry,
    recipientAddress,
    recipientName,
    foreignBank,
    extraData,
) =>
    API.executeWithAccessToken("/frequentdestinations.create", {
        number,
        name,
        productType,
        localBank,
        recipientName,
        idEnvironment,
        recipientDocument,
        recipientDocumentType,
        recipientDocumentCountry,
        recipientAddress,
        idFrequentDestination,
        foreignBank,
        extraData,
    });

export const deleteRequest = (params) =>
    API.executeWithAccessToken("/frequentdestinations.delete", {
        idFrequentDestination: params.frequentDestionationOnRow.idFrequentDestination,
    });

export const deleteSelectedRequest = (params) =>
    API.executeWithAccessToken("/frequentdestinations.deleteSelected", { listToDelete: params.listToDelete });

export const modifyFrequentDestination = (params) =>
    API.executeWithAccessToken("/frequentdestinations.modify", {
        idFrequentDestination: params.frequentDestination.idFrequentDestination,
        number: params.frequentDestination.number,
        name: params.frequentDestination.name,
        productType: params.frequentDestination.productType,
        localBank: params.frequentDestination.localBank,
        exteriorBank: params.frequentDestination.exteriorBank,
        recipientName: params.frequentDestination.recipientName,
        recipientDocument: params.frequentDestination.recipientDocument,
        recipientDocumentType: params.frequentDestination.recipientDocumentType,
        recipientDocumentCountry: params.frequentDestination.recipientDocumentCountry,
        recipientAddress: params.frequentDestination.recipientAddress,
        foreignBank: params.frequentDestination.foreignBank,
        extraData: params.frequentDestination.extraData,
    });

export const getBeneficiaryList = ({ pageNumber, beneficiaryType, searchValue, productType } = {}) => {
    const payload = { beneficiaryType };
    if (pageNumber) {
        payload.pageNumber = pageNumber - 1;
    }
    if (searchValue) {
        payload.searchValue = searchValue;
    }
    if (productType) {
        payload.productType = productType;
    }

    return API.executeWithAccessToken("/beneficiary.list", payload);
};

export const getCurrentBeneficiary = (beneficiaryId) =>
    API.executeWithAccessToken("/beneficiary.get", {
        beneficiaryId,
    });
export const getPreDataForm = (beneficiaryId, activityId) => {
    const data = {};
    if (beneficiaryId && beneficiaryId !== "") {
        data.beneficiaryId = beneficiaryId;
    }

    return API.executeWithAccessToken(`/${activityId}`, data);
};
export const deleteBeneficiary = (beneficiaryId, beneficiaryType) =>
    API.executeWithAccessToken("/beneficiary.delete", {
        beneficiaryId,
        beneficiaryType,
    });

export const setFavoriteBeneficiary = (beneficiaryId, favorite) =>
    API.executeWithAccessToken("/beneficiary.setFavorite", {
        beneficiaryId,
        favorite,
    });
