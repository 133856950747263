/* eslint import/prefer-default-export: 0 */
import * as API from "middleware/api";

export const validate = (email, username, creditCardId) =>
    API.executeAnonymous("/productrequest.creditcard.sendVerificationCode", {
        email,
        user_name: username,
        creditCardId,
    });

export const requestSessionInformation = (token) =>
    API.executeWithExchangeToken("/productrequest.creditcard.preVerifyEmail", null, token);

export const sendValidation = (code, token) =>
    API.executeWithExchangeToken("/productrequest.creditcard.verifyEmail", { _code: code }, token);

export const finishOnboarding = (token) => API.executeWithExchangeToken("/productrequest.onboarding.step5", {}, token);
