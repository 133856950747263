import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LIST_SERVICE_PAYMENTS_REQUEST: "servicePayments/LIST_SERVICE_PAYMENTS_REQUEST",
    LIST_SERVICE_PAYMENTS_FAILURE: "servicePayments/LIST_SERVICE_PAYMENTS_FAILURE",
    LIST_SERVICE_PAYMENTS_SUCCESS: "servicePayments/LIST_SERVICE_PAYMENTS_SUCCESS",

    LIST_SERVICE_PAYMENTS_HISTORY_REQUEST: "servicePayments/LIST_SERVICE_PAYMENTS_HISTORY_REQUEST",
    LIST_SERVICE_PAYMENTS_HISTORY_FAILURE: "servicePayments/LIST_SERVICE_PAYMENTS_HISTORY_FAILURE",
    LIST_SERVICE_PAYMENTS_HISTORY_SUCCESS: "servicePayments/LIST_SERVICE_PAYMENTS_HISTORY_SUCCESS",
};

export const INITIAL_STATE = {
    servicePayments: [],
    paymentsHistory: [],
    hasMoreData: false,
    pageNumber: 1,
    historyPageNumber: 1,
    historyHasMoreData: false,
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_SERVICE_PAYMENTS_REQUEST]: (state, action) => ({
        ...state,
        servicePayments: action.pageNumber === 1 ? [] : [...state.servicePayments],
    }),
    [types.LIST_SERVICE_PAYMENTS_SUCCESS]: (state, action) => ({
        ...state,
        servicePayments:
            action.pageNumber === 1 ? action.servicePayments : [...state.servicePayments, ...action.servicePayments],
        hasMoreData: action.pageNumber < action.totalPages,
        pageNumber: action.pageNumber,
    }),
    [types.LIST_SERVICE_PAYMENTS_HISTORY_REQUEST]: (state, action) => ({
        ...state,
        paymentsHistory: action.pageNumber === 1 ? [] : [...state.paymentsHistory],
    }),
    [types.LIST_SERVICE_PAYMENTS_HISTORY_SUCCESS]: (state, action) => ({
        ...state,
        paymentsHistory: action.pageNumber === 1 ? action.payments : [...state.paymentsHistory, ...action.payments],
        historyHasMoreData: action.pageNumber < action.totalPages,
        historyPageNumber: action.pageNumber,
    }),
});

export const actions = {
    listServicePaymentsRequest: makeActionCreator(
        types.LIST_SERVICE_PAYMENTS_REQUEST,
        "pageNumber",
        "onSuccess",
        "onFail",
    ),
    listServicePaymentsSuccess: makeActionCreator(
        types.LIST_SERVICE_PAYMENTS_SUCCESS,
        "servicePayments",
        "pageNumber",
        "totalPages",
    ),
    listServicePaymentsHistoryRequest: makeActionCreator(
        types.LIST_SERVICE_PAYMENTS_HISTORY_REQUEST,
        "idServicePayment",
        "dateFrom",
        "dateTo",
        "pageNumber",
        "onSuccess",
        "onFail",
    ),
    listServicePaymentsHistorySuccess: makeActionCreator(
        types.LIST_SERVICE_PAYMENTS_HISTORY_SUCCESS,
        "payments",
        "pageNumber",
        "totalPages",
    ),
};

export const selectors = {
    getServicePayments: ({ servicePayments }) => servicePayments.servicePayments,
    getHasMoreData: ({ servicePayments }) => servicePayments.hasMoreData,
    getPageNumber: ({ servicePayments }) => servicePayments.pageNumber,
    getPaymentsHistory: ({ servicePayments }) => servicePayments.paymentsHistory,
    getHistoryHasMoreData: ({ servicePayments }) => servicePayments.historyHasMoreData,
    getHistoryPageNumber: ({ servicePayments }) => servicePayments.historyPageNumber,
};
