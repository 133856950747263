import classNames from "classnames";
import { Field } from "formik";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import SwitchField from "pages/_components/fields/SwitchField";
import FormTransition from "pages/forms/_components/FormTransition";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { SCHEDULER_DATE } from "pages/forms/_components/_fields/ReadText";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import {
    PDFAmountField,
    PDFAmountFieldError,
    PDFProductSelectorField,
    PDFTextField,
} from "pages/forms/customForms/PDFTicket";
import { useDataAmount, useGenericProps, useMetaData } from "pages/forms/customForms/hooks/TransferInternalHooks";
import PaymentInfoCard from "pages/servicePayments/_components/PaymentInfoCard";
import ServiceRemarkableInfo from "pages/servicePayments/_components/ServiceRemarkableInfo";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { compose } from "redux";
import * as config from "util/config";
import * as i18n from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import fieldARP from "./hooks/field";
import {
    getInputFieldValues,
    getOuputFieldValues,
    initialValues,
    useAccountPaySelectorOptions,
    useCreditCardPaySelectorOptions,
    validateDinamicForm,
} from "./hooks/servicePaymentFormHooks";
import { fieldSubTotal, getCategorySelected } from "./hooks/servicePaymentsHooks";

const ID_FORM = "transfers.payService";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
const TITLE_FORM = "servicePayments.new.title";
const RECHARGE_PAYMENT_TYPE = "R";

const ServicePaymentForm = (props) => {
    const {
        mode,
        preDataForm,
        isDesktop,
        dispatch,
        queryBill,
        selectedBiller,
        fetchingForm,
        field,
        currentLang,
        transaction,
        creditCardList,
        fetchingList,
        usesJointAccount,
        isFastRecharge,
        isLinkFastRecharge,
        isFetchingFastRecharge,
        listFastRecharge,
        ...rest
    } = props;

    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);
    const [genericProps] = useGenericProps(props, ID_ACTIVITY);
    const [dataAmount] = useDataAmount(preDataForm);
    const [inputFieldValues] = getInputFieldValues(props);
    const [ouputFieldValues] = getOuputFieldValues(preDataForm?.billBalance, field);

    let accountOptions = useAccountPaySelectorOptions(preDataForm?.debitAccountList);
    if (usesJointAccount) {
        const daData = transaction.data.debitAccountData;
        if (daData) {
            accountOptions = { options: [{ ...daData, id: daData.idProduct, label: daData.otherLabel }] };
        }
    }

    const creditCardOptions = useCreditCardPaySelectorOptions(creditCardList);
    const [isReadOnlyField, labelSubTotal] = fieldSubTotal(field);

    const [sameCurrency, setSameCurrency] = useState(false);
    const [categorySelected, setCategorySelected] = useState({});
    const [typeAccount, setTypeAccount] = useState("debitAccount");
    const [amountDebitAccount, setAmountDebitAccount] = useState({ currency: "USD", quantity: 0 });
    const [isErrorBalance, setIsErrorBalance] = useState(false);
    const isErrorRequest = props.selectedBiller?.hasQuery && props.preDataForm?.billBalance == null;

    const radiButtonOptions = [
        {
            id: "debitAccount",
            label: i18n.get("servicePayment.dinamicForm.label.account"),
        },
        {
            id: "creditCard",
            label: i18n.get("servicePayment.dinamicForm.label.creditCard"),
        },
    ];

    useEffect(() => () => dispatch(payServiceActions.cleanCachePayService()), []);

    useEffect(() => {
        // preview fast recharges
        if (mode === "edit" && !fetchingForm && isLinkFastRecharge) {
            dispatch(payServiceActions.isFetchingFastRecharge(true));
            dispatch(formActions.confirmPaymentFastRecharge(ID_ACTIVITY));
        }
    }, [preDataForm?.billBalance]);

    useEffect(() => {
        if (queryBill) {
            if (mode === "edit") {
                dispatch(creditCardActions.listRequest());
                const category = getCategorySelected(selectedBiller?.category);
                if (category.length > 0) {
                    setCategorySelected(category[0]);
                }
                const requestData = { ...queryBill, paymentUser: { ...queryBill.paymentUser } };

                // Caso billId es diferente en billInqRq por caso fieldId

                const billInqRq = queryBill?.paymentUser?.billInqRq;
                const billId = billInqRq?.filter((el) => el.label === "BillId");
                if (billId && billId.length > 0) {
                    if (billId[0].value !== queryBill?.paymentUser?.idBill) {
                        billInqRq.map((elem) => {
                            const tmpEl = elem;
                            if (elem.label === "BillId") {
                                tmpEl.label = "BillId";
                                tmpEl.value = `${queryBill?.paymentUser?.idBill}`;
                            }
                            return tmpEl;
                        });
                    }
                }

                requestData.paymentUser.billInqRq = JSON.stringify(billInqRq);
                requestData.paymentUser.pmtUtilAddRq = JSON.stringify(queryBill?.paymentUser.pmtUtilAddRq);
                dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData }));
            }
        } else if (!transaction?.data) {
            dispatch(push("/servicePayments"));
        }
    }, [mode]);

    useEffect(() => {
        // Visualizar datos dinamicos en view
        if (
            transaction?.data?.biller?.billerId &&
            rest.match.params?.idTransaction &&
            transaction.idTransaction === rest.match.params?.idTransaction
        ) {
            // Telered -> consulta de reglas
            if (transaction?.data?.biller?.svcProviderId === "Telered") {
                dispatch(payServiceActions.listRulesBillerRequest(transaction?.data?.biller?.billerId));
            } else {
                // ARP -> definicion de reglas por defecto
                dispatch(payServiceActions.listRulesBillerSuccess(fieldARP));
            }
            // tipo de cuenta
            setTypeAccount(transaction?.data?.typeAccount);

            // datos de categoria
            const category = getCategorySelected(transaction?.data?.biller.category);
            if (category.length > 0) {
                setCategorySelected(category[0]);
            }
        }
    }, [rest.match.params]);

    useEffect(() => {
        setIsErrorBalance(isErrorRequest);
    }, [isErrorRequest]);

    const validateForm = (values) => validateDinamicForm(values, props, typeAccount, amountDebitAccount);

    const handlerCancelAction = () => {
        dispatch(push("/servicePayments"));
    };

    const currencyIsSame = (debitAccount, creditCard) => {
        const isSameCurrency = debitAccount?.currency === creditCard?.currency;
        if (isSameCurrency !== sameCurrency) {
            setSameCurrency(isSameCurrency);
        }
        return isSameCurrency;
    };

    const renderTicket = (values) => {
        const showReference =
            transaction?.idTransactionStatus === "FINISHED" || transaction?.idTransactionStatus === "REJECTED";

        return (
            <>
                {transaction?.idTransactionStatus === "FINISHED" ? (
                    <PDFAmountField
                        idForm={ID_FORM}
                        name="TotalBalance"
                        values={values}
                        label={i18n.get(`servicePayment.dinamicForm.amount.label_view`)}
                    />
                ) : (
                    <PDFAmountFieldError
                        idForm={ID_FORM}
                        name="TotalBalance"
                        values={values}
                        label={i18n.get(`servicePayment.dinamicForm.amount.label_view`)}
                    />
                )}

                {showReference && (
                    <PDFTextField
                        idForm={ID_FORM}
                        name="backendReference"
                        value={transaction?.data?.backendReference}
                        label={i18n.get(`servicePayment.dinamicForm.label.voucher`)}
                    />
                )}

                <PDFTextField
                    idForm={ID_FORM}
                    value={values?.scheduler?.valueDate?.format("DD/MM/YYYY")}
                    label={i18n.get("servicePayment.dinamicForm.label.date")}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={`${
                        transaction?.programed ? i18n.get("forms.transaction.ticket.typeOperation.program") : ""
                    }${i18n.get("servicePayment.dinamicForm.label.servicePayment")}`}
                    label={i18n.get("servicePayment.dinamicForm.label.typeOperation")}
                />

                <PDFProductSelectorField
                    idForm={ID_FORM}
                    name={transaction?.data?.typeAccount}
                    values={values}
                    label={
                        transaction?.data?.typeAccount === "debitAccount"
                            ? i18n.get("servicePayment.dinamicForm.label.account")
                            : i18n.get("servicePayment.dinamicForm.label.creditCard")
                    }
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={i18n.get(`servicePayment.category.${values?.biller?.category}`)}
                    label={i18n.get("servicePayment.dinamicForm.label.category")}
                />
                <PDFTextField
                    idForm={ID_FORM}
                    value={values?.biller?.name}
                    label={i18n.get("servicePayment.dinamicForm.label.company")}
                />

                {/** Campos dinamicos tax */}
                {values?.biller?.hasTax && (
                    <>
                        <PDFTextField
                            idForm={ID_FORM}
                            value={
                                typeof values?.SubTotal?.quantity === "string"
                                    ? values?.SubTotal?.quantity
                                    : values?.SubTotal?.quantity.toFixed(2)
                            }
                            label={i18n.get("servicePayment.dinamicForm.label.recharge")}
                        />
                        <PDFTextField
                            idForm={ID_FORM}
                            value={
                                typeof values?.TotalTax?.quantity === "string"
                                    ? values?.TotalTax?.quantity
                                    : values?.TotalTax?.quantity.toFixed(2)
                            }
                            label={i18n.get("servicePayment.dinamicForm.label.tax")}
                        />
                    </>
                )}
            </>
        );
    };

    const renderFields = (setFieldValue, values, setValues, renderScheduler) => {
        const isSameCurrency = !!currencyIsSame(values?.debitAccount, values?.creditACard);

        if (mode !== "view" && (!preDataForm || fetchingForm || fetchingList || isFetchingFastRecharge)) {
            return <PageLoading loading classicStyle={false} />;
        }

        let isVisibleSwitchFastRecharge = false;
        const NUMBER_FAST_RECHARGES = config.getInteger("payments.services.defaultFastRecharges", 3);
        if (mode === "edit" && listFastRecharge && listFastRecharge.length < NUMBER_FAST_RECHARGES) {
            isVisibleSwitchFastRecharge =
                isFastRecharge || (categorySelected?.idServicePayment === "RECARGAS" && values?.savePayment);
        }

        if (values?.debitAccount && mode === "edit") {
            const debitAccount = preDataForm?.debitAccountList?.find((acc) => acc.idProduct === values?.debitAccount);
            const currency = debitAccount?.currency;
            const balance = debitAccount?.balance;

            if (amountDebitAccount?.currency !== currency || amountDebitAccount?.quantity !== balance) {
                setAmountDebitAccount({ currency: debitAccount?.currency, quantity: debitAccount?.balance });
            }
        }

        if (values?.scheduler?.selectedOption && values?.scheduler?.selectedOption !== "TODAY") {
            setIsErrorBalance(false);
        }

        const onChangeAmount = (value) => {
            let tax = 0;
            const quantity = typeof value.quantity === "string" ? Number(value.quantity) : value.quantity;

            if (selectedBiller?.hasTax && quantity > 0) {
                tax = Number((Math.round(quantity * selectedBiller.tax) / 100).toFixed(2));
                setFieldValue("TotalTax", { currency: value?.currency, quantity: tax.toFixed(2) });
            }
            setFieldValue("TotalPmt", value);
            setFieldValue("TotalBalance", { currency: value?.currency, quantity: Number((tax + quantity).toFixed(2)) });
            setFieldValue("amount", { currency: value?.currency, quantity: Number((tax + quantity).toFixed(2)) });

            if (values.isFastRecharge) {
                setFieldValue("paymentUser.amountCurrency", value?.currency);
                setFieldValue("paymentUser.amountQuantity", quantity);
            }
        };

        const onChangeTypeAccount = (value) => {
            setTypeAccount(value);
            setFieldValue("typeAccount", value);
            if (values.isFastRecharge) {
                setFieldValue("paymentUser.productType", value);
            }

            if (value === "creditCard") {
                setFieldValue("debitAccount", null);
                setFieldValue("debitAccountData", null);
            }
            if (value === "debitAccount") {
                setFieldValue("creditCard", null);
                setFieldValue("creditCardData", null);
            }
        };

        const onChangeProduct = (value) => {
            if (values.isFastRecharge) {
                setFieldValue("paymentUser.idProduct", value);
            }
        };
        const onChangeSaveFastRecharge = () => {
            setFieldValue("isFastRecharge", !values.isFastRecharge);
            const actualPaymentUser = values?.paymentUser;
            actualPaymentUser.amountCurrency = values?.TotalPmt?.currency;
            actualPaymentUser.amountQuantity = values?.TotalPmt?.quantity;
            actualPaymentUser.productType = typeAccount;
            actualPaymentUser.idProduct = values?.debitAccount || values?.creditCard;

            setFieldValue("paymentUser", { ...actualPaymentUser, fastRecharge: !values.isFastRecharge });
        };

        return (
            <>
                {mode === "edit" && (
                    <>
                        <ServiceRemarkableInfo
                            title={i18n.get(`servicePayment.category.${categorySelected?.idServicePayment}`)}
                            logo={categorySelected?.logo}
                            subtitle={selectedBiller?.name}
                            className="mb-5"
                            info={inputFieldValues}
                        />

                        {preDataForm?.messages && (
                            <Box
                                component="section"
                                background="warning"
                                borderRadius="default"
                                border="warning-1"
                                className="mt-3 mt-md-0 px-6 py-6 mb-3 mx-n-5 align-items-center"
                                display="flex">
                                <Box display="flex" className="pt-1">
                                    <Image src="images/icons/warning.svg" className="mr-3" />
                                </Box>
                                <Text size="6" color="heading" align="center">
                                    {preDataForm?.messages}
                                </Text>
                            </Box>
                        )}

                        {ouputFieldValues.length > 0 && <PaymentInfoCard info={ouputFieldValues} className="mb-5" />}

                        <Row {...(!isDesktop && { gapY: "0" })} className="mb-5">
                            <Col xs={12} md={6}>
                                <Box
                                    component="section"
                                    background="white"
                                    borderRadius={isDesktop ? "default" : "top-default"}
                                    className="pt-5 pt-md-12 pb-5 pb-md-12 px-5 px-md-9 mx-n-5"
                                    fullHeight>
                                    <RadioButtonGroup
                                        className="d-flex"
                                        inLineControl={false}
                                        name="beneficiaryOption"
                                        selectorId="beneficiaryOption"
                                        onChange={(value) => onChangeTypeAccount(value)}
                                        options={radiButtonOptions}
                                        value={typeAccount}
                                        radioButtonGroupFlex
                                        radioLabelClassName="color-text-boton"
                                        cControlClassName="mr-3"
                                    />

                                    <Box display="flex" fullWidth className=" pt-2 pt-md-4 pb-2">
                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.ProductselectorCustom}
                                            data={typeAccount === "debitAccount" ? accountOptions : creditCardOptions}
                                            key={typeAccount}
                                            name={typeAccount}
                                            renderAs="combo"
                                            value={values[typeAccount]}
                                            noLabelEditMode
                                            idField={typeAccount}
                                            customPlaceholder={i18n.get(
                                                `servicePayment.dinamicForm.placeholder.account`,
                                            )}
                                            optionClassName="needsclick"
                                            onChange={(value) => onChangeProduct(value)}
                                        />
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} md={6}>
                                <Box
                                    component="section"
                                    background="white"
                                    borderRadius={isDesktop ? "default" : "bottom-default"}
                                    className="pt-0 pt-md-8 pb-6 pb-md-8 px-5 px-md-9 mx-n-5 "
                                    alignY="center"
                                    fullHeight
                                    display={selectedBiller?.hasTax || isVisibleSwitchFastRecharge ? "block" : "flex"}>
                                    <Box display="flex" fullWidth className="pt-2 pt-md-4 pb-2">
                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.Amount}
                                            data={dataAmount}
                                            key="SubTotal"
                                            name="SubTotal"
                                            idField="SubTotal"
                                            value={values.SubTotal}
                                            customPlaceholderCurrency=""
                                            customPlaceholderQuantity="0.00"
                                            quantityTextAlign="text-left"
                                            currencyBox={false}
                                            labelMap={{
                                                [currentLang]: labelSubTotal,
                                            }}
                                            onChange={onChangeAmount}
                                            disabled={isReadOnlyField || isErrorBalance}
                                        />
                                    </Box>
                                    {selectedBiller?.hasTax && (
                                        <Box display="flex" fullWidth className="pt-2 pt-md-4 pb-2">
                                            <Field
                                                {...genericProps}
                                                component={FormFieldsComponents.Amount}
                                                data={dataAmount}
                                                key="TotalTax"
                                                name="TotalTax"
                                                idField="TotalTax"
                                                value={values.TotalTax}
                                                customPlaceholderCurrency=""
                                                customPlaceholderQuantity="0.00"
                                                quantityTextAlign="text-left"
                                                currencyBox={false}
                                                disabled
                                                labelMap={{
                                                    [currentLang]: i18n.get("servicePayment.dinamicForm.label.tax"),
                                                }}
                                                className="pt-2 pt-md-4 pb-2"
                                            />
                                        </Box>
                                    )}

                                    {/** Guardar como recarga rapida */}
                                    {isVisibleSwitchFastRecharge && (
                                        <Box display="flex" fullWidth className="pt-2 pt-md-4 pb-2">
                                            <SwitchField
                                                name="saveFastRecharge"
                                                idForm={ID_FORM}
                                                label={i18n.get("servicePayments.fast.recharge.save")}
                                                value={values?.isFastRecharge}
                                                onChange={onChangeSaveFastRecharge}
                                                disabled={isFastRecharge && !values?.savePayment}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            {renderScheduler && (
                                <Col xs={12} md={6}>
                                    {renderScheduler(
                                        selectedBiller?.paymentType === RECHARGE_PAYMENT_TYPE || isReadOnlyField,
                                        isErrorRequest,
                                        false,
                                    )}
                                    {(isReadOnlyField || selectedBiller?.paymentType === RECHARGE_PAYMENT_TYPE) && (
                                        <Box className="pl-5 pl-lg-10 pr-5 pr-lg-10">
                                            <Image
                                                wrapperClassName="fit-content"
                                                className="mr-3 mt-2"
                                                src="images/icons/tooltip.svg"
                                            />
                                            <Text
                                                className="mr-3 mr-md-4"
                                                size="5"
                                                labelKey="servicePayment.dinamicForm.label.scheduler"
                                            />
                                        </Box>
                                    )}
                                </Col>
                            )}
                            <Col xs={12} md={6}>
                                <Box
                                    display="flex"
                                    alignX="between"
                                    borderRadius="default"
                                    background="heading-color"
                                    className="py-7 px-9"
                                    fullHeight>
                                    <Box display="flex" alignY="center" fitWidth className="mb-2 mb-0">
                                        <Text
                                            size="5"
                                            color="inverse"
                                            labelKey="servicePayment.dinamicForm.label.total"
                                        />
                                    </Box>
                                    <Box display="flex" alignY="center" fitWidth>
                                        <FormattedAmount
                                            currency="USD"
                                            quantity={values?.TotalBalance?.quantity || 0}
                                            size={isDesktop ? "big" : "2"}
                                            color="inverse"
                                            bold
                                        />
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </>
                )}

                {mode === "preview" || mode === "view" ? (
                    <>
                        <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                            <Text
                                size="6"
                                align="center"
                                className="data-amount"
                                labelKey={
                                    mode === "preview"
                                        ? "servicePayment.dinamicForm.label.total"
                                        : "servicePayment.dinamicForm.label.totalPay"
                                }
                            />
                        </Box>
                        <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Amount}
                                data={dataAmount}
                                key="TotalBalance"
                                name="TotalBalance"
                                value={values?.TotalBalance}
                                idField="TotalBalance"
                                bigDataAmount
                            />
                        </Box>

                        <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                            {transaction?.data?.backendReference && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={transaction?.data?.backendReference}
                                    label="servicePayment.dinamicForm.label.voucher"
                                    shouldRender={mode === "view" && transaction?.data?.backendReference}
                                />
                            )}
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.scheduler?.valueDate?.format("DD/MM/YYYY")}
                                label="servicePayment.dinamicForm.label.date"
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={`${
                                    transaction?.programed
                                        ? i18n.get("forms.transaction.ticket.typeOperation.program")
                                        : ""
                                }${i18n.get("servicePayment.dinamicForm.label.servicePayment")}`}
                                label="servicePayment.dinamicForm.label.typeOperation"
                            />

                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.ProductselectorCustom}
                                data={typeAccount === "debitAccount" ? accountOptions : creditCardOptions}
                                key={typeAccount}
                                name={typeAccount}
                                renderAs="combo"
                                value={values[typeAccount]}
                                noLabelEditMode
                                idField={typeAccount}
                                customPlaceholder={i18n.get(`servicePayment.dinamicForm.placeholder.account`)}
                                optionClassName="needsclick"
                            />

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={i18n.get(`servicePayment.category.${values?.biller?.category}`)}
                                label="servicePayment.dinamicForm.label.category"
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.biller?.name}
                                label="servicePayment.dinamicForm.label.company"
                            />
                            {/* Datos dinamicos de entrada */}
                            {inputFieldValues.map((elem) => (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={elem?.value}
                                    labelCustom={elem?.fieldLabel}
                                />
                            ))}
                            {/** Campos dinamicos tax */}
                            {values?.biller?.hasTax && (
                                <>
                                    <FormFieldsComponents.ReadTextCustom
                                        {...genericProps}
                                        value={
                                            typeof values?.SubTotal?.quantity === "string"
                                                ? values?.SubTotal?.quantity
                                                : values?.SubTotal?.quantity.toFixed(2)
                                        }
                                        label="servicePayment.dinamicForm.label.recharge"
                                    />
                                    <FormFieldsComponents.ReadTextCustom
                                        {...genericProps}
                                        value={
                                            typeof values?.TotalTax?.quantity === "string"
                                                ? values?.TotalTax?.quantity
                                                : values?.TotalTax?.quantity.toFixed(2)
                                        }
                                        label="servicePayment.dinamicForm.label.tax"
                                    />
                                </>
                            )}
                            {isSameCurrency &&
                            values?.scheduler?.selectedOption &&
                            values?.scheduler?.selectedOption !== schedulerUtils.TODAY ? (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    type={SCHEDULER_DATE}
                                    value={values?.scheduler}
                                    label="forms.transfers.internal.dateScheduler.label_preview"
                                />
                            ) : (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={i18n.get("scheduler.immediate.radiobutton.label.servicePayment")}
                                    label="forms.transfers.internal.dateScheduler.label_preview_inmedite"
                                />
                            )}
                        </Box>
                    </>
                ) : null}
            </>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        showFilterChips: false,
        ticketConfirmation: true,
        cancelAction: handlerCancelAction,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        showSchedulerMessage: false,
        validate: validateForm,
        titleFormConfirmation: "OTP CODE",
        data: initialValues(props),
        submitButtonLabel: "forms.servicePayment.confirmation.button",
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    fetching: payServiceSelectors.isFetching(state),
    field: payServiceSelectors.getField(state),
    selectedBiller: payServiceSelectors.getSelectedBiller(state),
    queryBill: payServiceSelectors.getQueryBill(state),
    fetchingForm: formSelectors.getFetching(state),
    preDataForm: formSelectors.getPreData(state),
    currentLang: i18nSelectors.getLang(state),
    transaction: formSelectors.getTransaction(state),
    mode: formSelectors.getMode(state),
    previewData: formSelectors.getPreviewData(state),
    fetchingList: creditCardSelectors.isFetching(state),
    creditCardList: creditCardSelectors.getList(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
    isFastRecharge: payServiceSelectors.isFastRecharge(state),
    isLinkFastRecharge: payServiceSelectors.isLinkFastRecharge(state),
    isFetchingFastRecharge: payServiceSelectors.isFetchingFastRecharge(state),
    listFastRecharge: payServiceSelectors.getListFastRecharge(state),
});

ServicePaymentForm.propTypes = {
    history: shape({}).isRequired,
    isDesktop: bool,
    mode: string,
    currentLang: string,
    preDataForm: shape({}),
    previewData: shape({}),
    fromBackoffice: bool,
    subtitle: string,
    subtitlePrefix: string,
    fetching: bool,
    field: arrayOf(shape({})),
    selectedBiller: shape({}),
    queryBill: shape({}),
    fetchingForm: bool,
    dispatch: func.isRequired,
    transaction: shape({}),
    fetchingList: bool,
    creditCardList: arrayOf(shape({})),
    usesJointAccount: bool,
    isFastRecharge: bool,
    isLinkFastRecharge: bool,
    isFetchingFastRecharge: bool,
    listFastRecharge: arrayOf(shape({})),
};

ServicePaymentForm.defaultProps = {
    fromBackoffice: false,
    mode: "edit",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    subtitle: true,
    subtitlePrefix: true,
    fetching: false,
    isDesktop: false,
    field: [],
    selectedBiller: null,
    queryBill: null,
    fetchingForm: false,
    transaction: null,
    fetchingList: false,
    creditCardList: [],
    usesJointAccount: false,
    isFastRecharge: false,
    isLinkFastRecharge: false,
    isFetchingFastRecharge: false,
    listFastRecharge: [],
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(ServicePaymentForm));
