import * as recovery from "middleware/unlockUser";
import { push } from "react-router-redux";
import { actions as notificationActions } from "reducers/notification";
import { actions as loginActions } from "reducers/login";
import { types, selectors } from "reducers/unlockUser";
import { isDesktop } from "react-device-detect";
import { types as secondFactorTypes, actions as secondFactorActions } from "reducers/secondFactor";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

const UnlockUser = [
    takeLatest(types.UNLOCK_USER_STEP1_REQUEST, unlockUserStep1),
    takeLatest(types.UNLOCK_USER_STEP2_REQUEST, unlockUserStep2),
    takeLatest(types.RECOVERY_RESEND_OTP_AUTHENTICATE_CODE_REQUEST, handleunlockUserResendOtp),
];

export default UnlockUser;

function* unlockUserStep1({ userName, mail, formikBag }) {
    const response = yield call(recovery.unlockUserStep1, userName, mail);
    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        let notificationScope = "unlockUser";
        switch (response.data.code) {
            case "API601W":
                notificationScope = undefined;
                const urlSAT = configUtils.get("unlockUser.previous.SAT.url");
                const enabledRedirect = configUtils.getBoolean("previous.SAT.enabled.redirect");
                if (enabledRedirect && urlSAT && isDesktop) {
                    yield put(push("/loginStep1"));
                    yield call(() => window.open(urlSAT, "_blank"));
                }else{
                    formikBag.setErrors({"username": i18n.get("unlockUser.step1.username.userNotExists")});
                }
                break;
            case "COR020W":
                notificationScope = undefined;
                break;
            case "API527W":
                yield put(push("/loginStep1"));
                notificationScope = "externalLayout";
                break;
            case "API606W":
            case "API605W":
                yield put({
                    type: types.UNLOCK_USER_REQUEST_FAILURE,
                    errorMessage: response.data.message,
                    errorTitle: `unlockUser.title.${response.data.code}`,
                });
                yield put(push("/unlockUser/unlockError"));
                notificationScope = "externalLayout";
                return;
            case "API709W":
                yield put(loginActions.showPendingInvitationModal(true));
                notificationScope = undefined;
                break;
            default:
                yield put(push("/unlockUser/step1"));
                notificationScope = "externalLayout";
        }
        if (notificationScope) {
            yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
        }
    } else {
        const { _exchangeToken } = response.data.data;
        yield put({
            type: types.UNLOCK_USER_STEP1_SUCCESS,
            exchangeToken: _exchangeToken,
            username: userName,
            mail,
        });
        yield put(secondFactorActions.secondFactorStatusTokenRequest({ exchangeToken: _exchangeToken }));
        yield put(push("/unlockUser/step2"));
    }

    formikBag.setSubmitting(false);
}

function* unlockUserStep2({ secondFactor, exchangeToken, formikBag }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(recovery.unlockUserStep2, deviceUuid, secondFactor, exchangeToken);
    let notificationScope = "unlockUser";

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
        let showNotification = true;
        switch (response.data.code) {
            case "COR020W":
                showNotification = false;
                break;
            case "API525W":
            case "API526W":
                break;
            case "API527W":
                yield put(push("/loginStep1"));
                notificationScope = "externalLayout";
                break;
            case "API707W":
            case "API705W":
                yield put({ type: types.CLEAN_UP });
                yield put(push("/userBlockedBank"));
                showNotification = false;
                break;
            case "API708W":
                yield put({ type: types.CLEAN_UP });
                yield put(
                    notificationActions.showNotification(
                        i18n.get(
                            "secondFactor.credential.otp.expired",
                            "Código OTP expirado, solicite un nuevo código OTP",
                        ),
                        "warning",
                        ["externalLayout"],
                    ),
                );
                return;
            default:
                yield put({ type: types.CLEAN_UP });
                yield put(push("/unlockUser/step1"));
                notificationScope = "externalLayout";
        }

        if (showNotification) {
            yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
        }
    } else {
        const { _exchangeToken, showCaptcha, contact } = response.data.data;

        yield put({
            type: types.RECOVERY_PASS_STEP2_SUCCESS,
            exchangeToken: _exchangeToken,
            showCaptcha,
            contact,
            idTransaction: response.data.idTransaction,
        });
        yield put(push("/unlockUser/step3"));
    }

    formikBag.setSubmitting(false);
}

function* handleunlockUserResendOtp() {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const response = yield call(recovery.unlockUserResendOtp, exchangeToken);
    yield put(notificationActions.removeNotification());
    let notificationScope = "unlockUser";
    if (response.type === "W") {
        const notificationErrorMessage = response.data.message;
        switch (response.data.code) {
            case "COR048W":
                break;
            case "COR020W":
                break;
            default:
                yield put({ type: types.CLEAN_UP });
                yield put(push("/unlockUser/step1"));
                notificationScope = "externalLayout";
        }

        if (response.data.code !== "COR020W") {
            yield put(notificationActions.showNotification(notificationErrorMessage, "error", [notificationScope]));
        }
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("unlockUser.resendOtp.confirmation"), "success", [
                notificationScope,
            ]),
        );
    }
}
