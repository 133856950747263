import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { values } from "lodash";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import ExitModal from "pages/_components/modal/ExitModal";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import Stepper from "pages/_layouts/DefaultStepperLayout/Stepper";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as i18n from "util/i18n";
import { USER_TOKEN_STATUS_MIGRATE_LOCAL } from "util/userToken.util";
import * as Yup from "yup";

const FORM_ID = "softToken.activate";

const SoftTokenTermsAndConditions = (props) => {
    const { steps, data, isDesktop, dispatch, handleSubmit, amountActivation, currencyActivation, tokenStatus } = props;
    const [showExitModal, setShowExitModal] = useState(false);
    const [showMigrateModal, setShowMigrateModal] = useState(false);

    const handleBack = () => {
        dispatch(push("/settings/authenticatorHandler"));
    };

    const handleAcceptModal = () => {
        handleBack();
        setShowExitModal(false);
    };

    const handleHideModal = () => {
        setShowExitModal(false);
    };

    const handlePreBack = () => {
        setShowExitModal(true);
    };

    const handleCancelModal = () => {
        handleBack();
        setShowMigrateModal(false);
    };

    const handleContinueModal = () => {
        const { values, formikBag } = props;
        console.log("handleContinueModal: ", values, formikBag);
        setShowMigrateModal(false);
        handleSubmit(values?.acceptConditions, formikBag);
    };

    const renderHeader = () => (
        <>
            <Notification scopeToShow="softTokenTermsAndConditions" />
            <Head onBack={handlePreBack} title="settings.softToken" textBack="softToken" />
        </>
    );

    const handleSubmitForm = ({ acceptConditions }, formikBag) => {
        if (formikBag) {
            formikBag.setSubmitting(false);
        }

        if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_LOCAL && acceptConditions) {
            setShowMigrateModal(true);
        } else {
            setShowMigrateModal(false);
            handleSubmit(acceptConditions, formikBag);
        }
    };

    const validationSchema = Yup.object().shape({
        acceptConditions: Yup.boolean().oneOf([true], i18n.get(`${FORM_ID}.acceptConditions.required`)),
    });

    const initialValues = {
        acceptConditions: false,
    };

    return (
        <>
            {renderHeader()}
            <MainContainer
                className={classNames("main-container", {
                    "box-shadow-small background-white": !isDesktop,
                })}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    validationSchema={validationSchema}>
                    {(formik) => (
                        <Form>
                            <Box
                                borderRadius="lg"
                                background="white"
                                className={classNames(" mt-3 pt-4", {
                                    "texture-header box-shadow-small": isDesktop,
                                })}>
                                <Box fullWidth className="login-title-text">
                                    <Text className="btn-link-span" bold labelKey="" />
                                </Box>

                                <Stepper
                                    className="mb-6"
                                    stepsList={steps}
                                    currentStep={1}
                                    completed={0}
                                    showLabelMobile
                                />

                                <Box>
                                    <Box>
                                        <Text
                                            className={classNames({
                                                "line-height-125": isDesktop,
                                                "line-height-15": !isDesktop,
                                            })}
                                            labelKey="softToken.activate.step1.title"
                                            size={isDesktop ? "2" : "1"}
                                            bold
                                        />
                                    </Box>
                                </Box>
                                <Box borderRadius="lg" background="background-primary" className="p-7 mt-7">
                                    <Box fullWidth>
                                        <Text
                                            bold
                                            {...(!isDesktop && { size: "3" })}
                                            {...(isDesktop && { size: "4" })}
                                            labelKey="softToken.activate.commision.label"
                                        />
                                        <Box className="mt-4 mb-2">
                                            <Text
                                                {...(!isDesktop && { size: "extra-big" })}
                                                {...(isDesktop && { size: "2" })}
                                                semibold>
                                                {amountActivation}
                                            </Text>
                                            &nbsp;
                                            <span className="size-extra-big text-light color-text-grey ml-2">
                                                {currencyActivation}
                                            </span>
                                        </Box>
                                        <Text
                                            className="color-text-grey"
                                            light
                                            {...(!isDesktop && { size: "6" })}
                                            {...(isDesktop && { size: "7" })}
                                            labelKey="softToken.activate.commision.text"
                                        />
                                    </Box>
                                </Box>

                                <Box className="mt-4 mb-4">
                                    <Box
                                        className="terms-and-conditions max-height-15"
                                        display="flex"
                                        alignX="center"
                                        fullWidth>
                                        <div className="container-term-and-deposit custom-scrollbar mt-3 without-border">
                                            <Text
                                                align="center"
                                                className="mb-1 mb-md-3"
                                                {...(!isDesktop && { size: "3" })}
                                                {...(isDesktop && { size: "4" })}>
                                                {i18n.get("softToken.activate.termsConditions.text")}
                                            </Text>
                                        </div>
                                    </Box>
                                </Box>

                                <Box className="mt-auto mt-md-auto">
                                    <Field name="acceptConditions" idForm={FORM_ID} component={Checkbox} formGroup />
                                </Box>
                            </Box>
                            <Row
                                gapX="7"
                                className={classNames("mt-7 mb-7", {
                                    "d-flex flex-column-reverse": !isDesktop,
                                })}>
                                <Col xs={3} xsOffset={3}>
                                    <Button
                                        bsStyle="outline"
                                        label="global.cancel"
                                        btnUppercase={false}
                                        block
                                        type="button"
                                        onClick={handlePreBack}
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Button
                                        bsStyle="primary"
                                        label="global.continue"
                                        btnUppercase={false}
                                        disabled={!(formik.isValid && formik.dirty)}
                                        block
                                        type="submit"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
            <ExitModal
                modalShow={showExitModal}
                acceptFunction={handleAcceptModal}
                cancelFunction={handleHideModal}
                headingText={i18n.get("confirm.exit.title")}
                text={i18n.get("confirm.exit.info")}
            />
            <TextAndButtonsModal
                modalShow={showMigrateModal}
                acceptFunction={handleContinueModal}
                cancelFunction={handleCancelModal}
                confirmLabel="global.continue"
                continueLabel="global.cancel"
                cancelLabel="global.cancel"
                headingText={i18n.get("confirm.continue.title")}
                text={i18n.get("token.confirm.info")}
            />
        </>
    );
};

SoftTokenTermsAndConditions.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    handleSubmit: func.isRequired,
    data: shape({}),
    steps: arrayOf(shape({})).isRequired,
};

SoftTokenTermsAndConditions.defaultProps = {
    data: {},
    steps: [],
};

export default connect()(SoftTokenTermsAndConditions);
