import Box from "pages/_components/Box";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { arrayOf, bool, func, oneOf, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import Redirect from "react-router-dom/Redirect";
import { routerActions } from "react-router-redux/actions";
import { actions, selectors } from "reducers/administration/users";
import { actions as formActions } from "reducers/form";
import { renderNameAction } from "./utils/users";
import * as i18n from "util/i18n";

class UserActionConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        usersToApplyAction: arrayOf(string).isRequired,
        usersNamesToApplyAction: arrayOf(shape({})).isRequired,
        userAction: oneOf(["block", "unblock", "delete"]).isRequired,
        idTransaction: string,
        idActivity: string,
        isFromList: bool,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
        isFromList: false,
    };

    handleSubmit = (credentials, formikBag, userDocumentList) => {
        const {
            dispatch,
            usersToApplyAction,
            usersNamesToApplyAction,
            userAction,
            idTransaction,
            idActivity,
        } = this.props;

        if (!idTransaction) {
            dispatch(
                actions.changeUserStatusConfirmation(
                    usersToApplyAction,
                    usersNamesToApplyAction,
                    userAction,
                    credentials,
                    formikBag,
                    userDocumentList
                ),
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    buildUserData = (currentUserData) => renderNameAction(currentUserData?.fullName, <span key={currentUserData?.documentNumber}> {`${i18n.get(`documentType.abbreviation.${currentUserData?.documentType}`)} ${currentUserData?.documentNumber}`} </span>);

    render() {
        const { usersToApplyAction, usersNamesToApplyAction, userAction, credentialGroups, isFromList, idActivity } = this.props;

        if (!usersToApplyAction || usersToApplyAction.length === 0) {
            return <Redirect to="/administration/users" />;
        }

        // const usersText = usersNamesToApplyAction.map((userName) => userName?.fullName);
        const titleKey = `administration.users.action.title.${userAction}`;
        const subtitleKey = "administration.users.invite.subtitle";
        const subtitleActionKey = `administration.users.action.subtitle.${userAction}`;

        return (
            <>
                <Head
                    title={titleKey}
                    onBack={this.handleBack}
                    textBack={isFromList ? "administration.users.returnToList" : "administration.users.returnToDetail"}
                />
                <MainContainer>
                    <Box className="border-radius-lg p-7 box-shadow-small background-white">
                        <div className="above-the-fold">
                            <Container
                                className="container--layout items-center flex-grow-1"
                                gridClassName="form-content">
                                <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2}>
                                    <Box display="flex">
                                        <Text
                                            component="h2"
                                            labelKey={subtitleKey}
                                            className="m-0"
                                            size="5"
                                            color="heading-color"
                                            bold
                                        />
                                    </Box>

                                   
                                    {usersNamesToApplyAction.length > 0 && (
                                        <>
                                             <Box display="flex">
                                                <Text
                                                    labelKey={subtitleActionKey}
                                                    className="mt-5"
                                                    size="5"
                                                    color="heading-color"
                                                />
                                            </Box>
                                            <Box background="background-disabled" className="p-5 my-5" borderRadius="lg">
                                            {usersNamesToApplyAction.map((user) => (
                                            <>
                                                {this.buildUserData(user)}
                                            </>
                                            ))}
                                                
                                            </Box>
                                        </>
                                    )}
                                </Col>
                            </Container>
                        </div>
                    </Box>
                    <Box>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                            onClickCancel={this.handleBack}
                            idActivity={idActivity}
                        />
                    </Box>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userAction: selectors.getUserAction(state),
    usersToApplyAction: selectors.getUsersToApplyAction(state),
    usersNamesToApplyAction: selectors.getUsersNamesToApplyAction(state),
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
    isFromList: selectors.getIsFromList(state),
});

export default connect(mapStateToProps)(UserActionConfirmation);
