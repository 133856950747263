import classNames from "classnames";
import { Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Container from "pages/_components/Container";
import Disclaimer from "pages/_components/Disclaimer";
import GridTable from "pages/_components/GridTable/GridTable";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, instanceOf, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import { compose } from "redux";
import { flattenArray, removeDuplicateItems } from "util/array";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import * as Yup from "yup";
import ScheduledTransactionItem from "./ScheduledTransactionItem";

const FORM_ID = "scheduler";
class ListScheduledTransactions extends Component {
    static propTypes = {
        defaultFilters: shape({
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            pageNumber: number,
            filter: string,
        }),
        dispatch: func.isRequired,
        pageNumber: number,
        onlyPendings: bool,
        onlyProcessing: bool,
        pendingDispatch: bool,
        filters: shape({
            idFilter: string,
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            pageNumber: number,
            minAmount: number,
            maxAmount: number,
        }),
        transactions: arrayOf(shape({})),
        isDesktop: bool.isRequired,
        isTablet: bool.isRequired,
        hasMoreData: bool.isRequired,
        fetching: bool.isRequired,
        showEnvironment: bool,
        ascending: bool.isRequired,
        handleOrder: func.isRequired,
        submitForm: func.isRequired,
        setFieldValue: func.isRequired,
        modalsDisplay: arrayOf(bool).isRequired,
    };

    static defaultProps = {
        transactions: null,
        defaultFilters: null,
        pageNumber: 1,
        onlyPendings: false,
        onlyProcessing: false,
        pendingDispatch: false,
        filters: null,
        showEnvironment: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(transactionsActions.listCredentialGroupsRequest("transactions.migration.reSchedule.send"));
    }

    handleOrderList = () => {
        const {
            defaultFilters,
            dispatch,
            pageNumber,
            onlyPendings,
            onlyProcessing,
            pendingDispatch,
            ascending,
            handleOrder,
        } = this.props;
        let { filters } = this.props;

        filters = filters
            ? { ...filters, pageNumber, isAscending: !ascending, isSorting: true }
            : { ...defaultFilters, pageNumber };

        dispatch(transactionsActions.loadListRequest(filters, onlyPendings, onlyProcessing, pendingDispatch));

        handleOrder();
    };

    scheduleAction = () => {
        const { submitForm } = this.props;
        submitForm();
    };

    render() {
        const { transactions, isDesktop, showEnvironment, setFieldValue, modalsDisplay } = this.props;

        const columnsTemplate = isDesktop ? "1fr 1fr 1fr 1fr 1fr 2rem" : "1fr 0.90fr";

        if (!transactions) {
            return null;
        }

        const list = transactions.map((transaction, index) => (
            <GridTable.Container
                key={transaction.idTransaction}
                className="product-data"
                columnsTemplate={columnsTemplate}>
                <ScheduledTransactionItem
                    {...this.props}
                    modalIsDisplayed={modalsDisplay[index]}
                    transactionIndex={index}
                    key={`transaction-${transaction.idTransaction}`}
                    scheduleAction={this.scheduleAction}
                    transaction={transaction}
                    isDesktop={isDesktop}
                    showEnvironment={showEnvironment}
                    setFieldValue={setFieldValue}
                />
            </GridTable.Container>
        ));

        const DEADLINE = configUtils.get("scheduled.transactions.migration.deadline");
        const renderDisclaimerHistory = () => (
            <Box
                background="warning"
                display="flex"
                alignY="flex-start"
                alignX="flex-start"
                className="px-5 py-3 mt-5"
                borderRadius="top-xl"
                border="warning-1">
                <Image wrapperClassName="fit-content" className="mr-3 mb-auto" src="images/icons/warning.svg" />
                <Text
                    className="mr-3 mr-md-4 my-span-p-0"
                    labelKey="scheduled.transactions.migration.list.disclaimer"
                    textParams={{
                        DATE: `${DEADLINE}`,
                    }}
                    size={isDesktop ? "6" : "small"}
                    color="text-boton"
                />
            </Box>
        );

        return (
            <Container className="container--layout flex-grow-1 no-scrollable">
                <Col className="col-12 ">
                    <div
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        <Form>
                            <Box
                                className={classNames("min-height-10rem mt-5 mt-md-0", {
                                    "pt-10": !transactions.length,
                                })}
                                {...(!transactions.length && {
                                    background: "component-background",
                                    borderRadius: "xl",
                                })}>
                                {transactions.length ? renderDisclaimerHistory("disclaimerDate") : null}
                                <GridTable>
                                    <GridTable.Header>
                                        <GridTable.Container columnsTemplate={columnsTemplate}>
                                            {isDesktop ? (
                                                <>
                                                    <GridTable.Data
                                                        columnStart={1}
                                                        columnWidth={1}
                                                        alignX="start"
                                                        alignY="center"
                                                        inHeader>
                                                        <Text labelKey="transactions.list.header.operationType" />
                                                    </GridTable.Data>
                                                    <GridTable.Data
                                                        columnStart={2}
                                                        columnWidth={1}
                                                        alignX="flex-start"
                                                        inHeader>
                                                        <Text labelKey="transactions.list.header.creationDateOnly" />
                                                    </GridTable.Data>
                                                    <GridTable.Data
                                                        columnStart={3}
                                                        columnWidth={1}
                                                        alignX="center"
                                                        inHeader>
                                                        <Text labelKey="transactions.list.header.receiver" />
                                                    </GridTable.Data>

                                                    <GridTable.Data
                                                        columnStart={4}
                                                        columnWidth={1}
                                                        alignX="flex-end"
                                                        inHeader>
                                                        <Text labelKey="transactions.list.header.amount" />
                                                    </GridTable.Data>
                                                    <GridTable.Data
                                                        columnStart={5}
                                                        columnWidth={1}
                                                        alignX="center"
                                                        inHeader>
                                                        <Text labelKey="transactions.list.header.actions" />
                                                    </GridTable.Data>
                                                </>
                                            ) : (
                                                <>
                                                    <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                                                        <Text
                                                            align="left"
                                                            labelKey="scheduled.transactions.migration.list.header.operationType.mobile"
                                                        />
                                                    </GridTable.Data>
                                                    <GridTable.Data columnStart={2} alignX="flex-end" inHeader>
                                                        <Text
                                                            align="right"
                                                            labelKey="scheduled.transactions.migration.list.header.actions.mobile"
                                                        />
                                                    </GridTable.Data>
                                                </>
                                            )}
                                        </GridTable.Container>
                                    </GridTable.Header>

                                    <GridTable.Body>
                                        {transactions.length ? (
                                            list
                                        ) : (
                                            <Box
                                                display="flex"
                                                fullWidth
                                                column
                                                alignX="center"
                                                alignY="center"
                                                borderRadius="bottom-default"
                                                background="component-background"
                                                className="min-height-10rem">
                                                <Box className="pb-5">
                                                    <Image height="9" width="9" src="images/icons/calendarTime.svg" />
                                                </Box>
                                                <Text
                                                    labelKey="scheduled.transactions.migration.list.none"
                                                    component="p"
                                                    className="px-12 m-0"
                                                    align="center"
                                                    size={isDesktop ? "6" : "5"}
                                                    color="text-secondary-color"
                                                />
                                            </Box>
                                        )}
                                        <Disclaimer
                                            textSize={isDesktop ? "7" : "6"}
                                            textColor="secondary-hover-color"
                                            iconClassname="tooltip-img"
                                            labelKey="scheduled.transactions.migration.list.tooltip.text"
                                            notBorder
                                            className="pb-5"
                                            styled="info"
                                            borderRadius="bottom-xl"
                                            background="component-background"
                                        />
                                    </GridTable.Body>
                                </GridTable>
                            </Box>
                        </Form>
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    modalsDisplay: transactionsSelectors.getDisplayMigrationSchedulerModal(state),
    transactions: transactionsSelectors.getMigrationTransactions(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    fetching: transactionsSelectors.getFetching(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    filters: transactionsSelectors.getFilters(state),
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(transactionsSelectors.getCredentialsGroups(state)),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ credentials }) => ({
            scheduler: null,
            ...credentials.reduce((values, credential) => ({ ...values, [credential]: "" }), {}),
        }),
        validationSchema: (props) => {
            const { credentials } = props;

            const result = Yup.object().shape({
                scheduler: Yup.object().required(`${FORM_ID}.captcha.required`),
                ...credentials.reduce(
                    (values, credential) => ({
                        ...values,
                        [credential]: Yup.string().required(i18nUtils.get(`form.credential.${credential}.required`)),
                    }),
                    {},
                ),
                credentials: Yup.object().shape(
                    credentials.reduce(
                        (values, credential) => ({
                            ...values,
                            [credential]: Yup.string().required(
                                i18nUtils.get(`form.credential.${credential}.required`),
                            ),
                        }),
                        {},
                    ),
                ),
            });

            return result;
        },
        handleSubmit: (values, formikBag) => {
            const {
                transactionId,
                transactionType,
                scheduler,
                beneficiary,
                idDebitAccount,
                reason,
                amount,
                credentials = {},
                debitAccount,
                debitAccountData,
                creditAccount,
                creditAccountData,
                beneficiaryId,
                creationDateTime,
            } = values;

            formikBag.props.dispatch(
                transactionsActions.reScheduleMigrationTransactionRequest(
                    transactionId,
                    transactionType,
                    scheduler,
                    beneficiary,
                    idDebitAccount,
                    reason,
                    amount,
                    debitAccount,
                    debitAccountData,
                    creditAccount,
                    creditAccountData,
                    beneficiaryId,
                    credentials,
                    formikBag,
                    creationDateTime,
                ),
            );
        },
    }),
)(ListScheduledTransactions);
