import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { actions as recoveryUserActions, selectors } from "reducers/recoveryUser";
import { selectors as sessionSelectors } from "reducers/session";
import classNames from "classnames";
import LoginWrapper from "pages/login/_components/LoginWrapper";
import Head from "pages/_components/Head";
import * as i18n from "util/i18n";
import { Form, withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import { push } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import * as config from "util/config";
import SecondFactor from "pages/secondFactor/SecondFactor";

const FORM_ID = "recoveryUser.step2";
const CREDENTIAL_TYPE_TOKEN = "token";
const CREDENTIAL_TYPE_OTP = "otp";
const CREDENTIAL_TYPE_QUESTION = "question";
const OTP_METHOD_EMAIL = "MAIL";
const OTP_METHOD_PHONE = "SMS";

const RecoverUserStep2 = (props) => {
    const {
        dispatch,
        isMobile,
        isSubmitting,
        operationType,
        questions,
        otpMethod,
        contact,
        setFieldValue,
        exchangeToken,
        submitForm,
    } = props;

    const maxReloads = config.getInteger("core.attempts.resetUser.reloadQuestions.maxAttempts");
    const remainingValidTime = config.get("recoveryUser.remaining.valid.time");
    const recoveryUser = true;

    const [canSubmit, setCanSubmit] = useState(false);

    const handleBack = () => {
        props.dispatch(routerActions.goBack());
    };

    const goToLogin = () => {
        props.dispatch(push("/loginStep1"));
    };

    return (
        <LoginWrapper>
            <>
                {!isMobile && <BoxErrorNotification isMobile={isMobile} scopeToShow="recoveryUser" />}
                <Head title="transactions.list.filters.operationType.historic.RECUPERARUSUARIO.IR" />
                <MainContainer>
                    <Form
                        className={classNames({
                            "pointer-events-none": isSubmitting,
                        })}
                        noValidate="novalidate">
                        <Box
                            display="flex"
                            column
                            fullWidth
                            {...(isMobile ? { fullHeight: true } : { fullHeight: false })}>
                            <SecondFactor
                                onChangeToken={(tokenCode) => {
                                    if (setFieldValue) {
                                        setFieldValue("secondFactor", tokenCode);
                                        submitForm();
                                    }
                                }}
                                onValidSubmit={(validData) => {
                                    setCanSubmit(validData.submit);
                                }}
                                withEchangeToken={exchangeToken}
                                idActivity="session.recoverUser.step2"
                                isSubmitting={isSubmitting}
                            />

                            <Box display="flex" column={isMobile} className="mb-7 mb-md-0 mt-3">
                                <Button
                                    label="global.continue"
                                    type="submit"
                                    bsStyle="primary"
                                    loading={isSubmitting}
                                    block
                                    disabled={!canSubmit}
                                    className={isMobile ? "mb-5" : "mr-5"}
                                />

                                <Button
                                    label="global.cancel"
                                    bsStyle="outline"
                                    onClick={goToLogin}
                                    block
                                    className="m-0"
                                />
                            </Box>
                        </Box>
                    </Form>
                </MainContainer>
            </>
        </LoginWrapper>
    );
};

RecoverUserStep2.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    isDesktop: bool.isRequired,
    fetching: bool.isRequired,
    formData: shape({}),
    isActiveCorporate: bool.isRequired,
    isSubmitting: bool.isRequired,
    operationType: string.isRequired,
    questions: arrayOf(string).isRequired,
    otpMethod: string.isRequired,
    contact: string.isRequired,
};

RecoverUserStep2.defaultProps = {
    formData: {},
};

const mapStateToProps = (state) => ({
    fetching: selectors.getFetching(state),
    formData: selectors.getFormData(state),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
    operationType: selectors.getTypeOperation(state),
    questions: selectors.getClientQuestions(state),
    exchangeToken: selectors.getExchangeToken(state),
    otpMethod: selectors.getOtpMethod(state),
    contact: selectors.getContact(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            secondFactor: "",
        }),
        validationSchema: ({ operationType }) =>
            Yup.object().shape({
                secondFactor: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
            }),
        handleSubmit: ({ secondFactor }, formikBag) => {
            const {
                props: { exchangeToken, operationType },
            } = formikBag;
              formikBag.props.dispatch(recoveryUserActions.recoveryUserStep2(secondFactor, exchangeToken, formikBag));
        },
    }),
)(RecoverUserStep2);
