import * as API from "middleware/api";

export const listServicePayments = (pageNumber) =>
    API.executeWithAccessToken("/servicePayments.list", {
        pageNumber,
    });

export const listServicePaymentsHistory = (idServicePayment, dateFrom, dateTo, pageNumber) =>
    API.executeWithAccessToken("/servicePayments.listHistory", {
        idServicePayment,
        dateFrom,
        dateTo,
        pageNumber,
    });
