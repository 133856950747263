import React, { Component } from "react";
import { resizableRoute } from "pages/_components/Resizable";
import classNames from "classnames";
import { string, oneOf, bool } from "prop-types";
import * as utils from "util/general";
import Image from "pages/_components/Image";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";

class TransactionStatus extends Component {
    static propTypes = {
        idTransactionStatus: string.isRequired,
        iconFidelity: oneOf(["transaction", "ticket"]),
        isDesktop: bool.isRequired,
        showLabel: bool,
        showIcon: bool,
    };

    static defaultProps = {
        iconFidelity: "transaction",
        showLabel: false,
        showIcon: true,
    };

    render() {
        const { idTransactionStatus, showLabel, showIcon, iconFidelity, isDesktop } = this.props;
        return (
            <div className="bubble-wrapper">
                {showIcon && (
                    <Image
                        src={`images/${iconFidelity}StatusIcons/${utils.getTransactionStatusIcon(
                            idTransactionStatus,
                        )}.svg`}
                        className={classNames("svg-icon", {
                            "svg-icon-big": iconFidelity === "ticket",
                        })}
                    />
                )}
                {showLabel && (
                    <Box
                        display="flex"
                        alignX="center"
                        borderRadius="sm"
                        className={classNames("px-3 py-2", {
                            "background-error border-error-border-color":
                                idTransactionStatus === "FAILED" ||
                                idTransactionStatus === "CANCELLED" ||
                                idTransactionStatus === "DELETED" ||
                                idTransactionStatus === "RETURNED",

                            "background-warning-border-color": idTransactionStatus === "PROCESSING",
                            "background-warning border-warning-color":
                                idTransactionStatus === "PENDING" ||
                                idTransactionStatus === "DRAFT" ||
                                idTransactionStatus === "SCHEDULED",
                            "background-success border-success-border-color": idTransactionStatus === "FINISHED",
                            "background-partial": idTransactionStatus === "PARCIAL",
                            // "border-warning-color": idTransactionStatus === "PENDING",
                        })}>
                        <Text
                            color="text-boton"
                            align="center"
                            size={isDesktop ? "7" : "6"}
                            labelKey={`transaction.status.${idTransactionStatus}`}
                            light
                        />
                    </Box>
                )}
            </div>
        );
    }
}

export default resizableRoute(TransactionStatus);
