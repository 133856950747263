import * as API from "middleware/api";

export const loadPermissionsRequest = (id) =>
    API.executeWithAccessToken("/administration.simple.read.permissions", {
        id,
    });

export const updatePermissionsPreview = (data) =>
    API.executeWithAccessToken("/administration.simple.modify.permissions.preview", data);

export const updatePermissionsRequest = (data) =>
    API.executeWithAccessToken("/administration.simple.modify.permissions.send", data);
