import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedDate from "pages/_components/FormattedDate";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

const GlobalPay = ({ date, isDesktop, shouldRender, parentTransactionId, dispatch }) => {
    const handleClick = () => {
        dispatch(push(`/transaction/${parentTransactionId}`));
    };

    if (shouldRender) {
        return (
            <Box display="flex" alignX="between" alignY="center" fullWidth className="mb-6">
                <Box display="flex" column>
                    <Text component="h4" className="mt-6 mb-2" size={isDesktop ? "6" : "5"} color="primary" bold>
                        Pago Global
                    </Text>
                    <Box display="inline-flex">
                        <Text size={isDesktop ? "7" : "6"} color="heading" bold addColon>
                            Fecha de creación
                        </Text>
                        <FormattedDate size={isDesktop ? "7" : "6"} color="heading" date={date} />
                    </Box>
                </Box>
                <Button bsStyle="link" onClick={handleClick} small>
                    Ver transacción
                </Button>
            </Box>
        );
    }
    return null;
};
GlobalPay.propTypes = {
    date: string.isRequired,
    isDesktop: bool.isRequired,
    shouldRender: bool,
    parentTransactionId: string,
    dispatch: func.isRequired,
};
GlobalPay.defaultProps = { shouldRender: true, parentTransactionId: null };

export default connect()(resizableRoute(GlobalPay));
