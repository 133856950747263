import { ID_ACTIVITY_DOWNLOAD_STATE, ID_ACTIVITY_DOWNLOAD_STATE_PRE, ID_ACTIVITY_EXCHANGE_POINTS } from "constants.js";
import * as API from "middleware/api";
import { credentialsToUnderscoreFormat } from "util/form";

export const changeProductAlias = (alias, idProduct) =>
    API.executeWithAccessToken("/core.product.changeAlias", {
        alias,
        idProduct,
    });

export const toggleFavorite = (idProduct, productType, favorite) =>
    API.executeWithAccessToken("/products.toggleFavorite", {
        idProduct,
        productType,
        favorite,
    });
export const getMetadataDownloadState = () => API.executeWithAccessToken(ID_ACTIVITY_DOWNLOAD_STATE_PRE, {});

export const downloadStateProduct = (month, year, productId) =>
    API.executeWithAccessToken(ID_ACTIVITY_DOWNLOAD_STATE, {
        month,
        year,
        productId,
    });

export const exchangePointsProduct = (productId, points, moneyValue) =>
    API.executeWithAccessToken(ID_ACTIVITY_EXCHANGE_POINTS, {
        productId,
        points,
        moneyValue,
    });

export const changeProductStatus = (idActivity, data, credentials) => {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    return API.executeWithAccessToken(`/${idActivity}`, {
        ...data,
        ...credentialsWithUnderscore,
    });
};

export const SyncEnvironmentProducts = (shouldGetLastSynchronization = false) =>
    API.executeWithAccessToken("session.login.legacy.synchronization", {
        shouldGetLastSynchronization,
    });

export const sendRequestProduct = (product, productType, contactType, message) =>
    API.executeWithAccessToken("/productRequest.send", {
        product,
        productType,
        contactType,
        message,
    });
