import EnrollmentTermsAndConditions from "pages/enrollment/termsAndConditions/index";
import EnrollmentInvitationCodeToken from "pages/enrollment/invitationCodeToken/index";
import EnrollmentInvitationCode from "pages/enrollment/invitationCode/index";
import EnrollmentCreateUser from "pages/enrollment/createUser/index";
import EnrollmentSelectAvatar from "pages/enrollment/selectAvatar/index";
import EnrollmentFinish from "pages/enrollment/finish/Finish";
import EnrollmentStart from "pages/enrollment/Start";
import EnrollmentValidateUser from "pages/enrollment/validateUser/index";
import EnrollmentQuestion from "pages/enrollment/question/index";

import Error from "pages/error/Error";
import ServerError from "pages/error/ServerError";
import BackofficeTransaction from "pages/forms/BackofficeTransaction";
import LanguageSelection from "pages/login/LanguageSelection";
import LoginStep1 from "pages/login/LoginStep1";
import LoginStep2 from "pages/login/LoginStep2";
import LoginStep3 from "pages/login/LoginStep3";
import LoginStep4 from "pages/login/LoginStep4";
import WebSiteConstruction from "pages/messagesWeb/WebSiteConstruction";
import OneTouchAccess from "pages/OneTouchAccess/OneTouchAccess";
import RecoveryPassStep1 from "pages/recoveryPassword/RecoveryPassStep1";
import RecoveryPassStep2 from "pages/recoveryPassword/RecoveryPassStep2";
import RecoveryPassStep3 from "pages/recoveryPassword/RecoveryPassStep3";
import RecoveryPassStep4 from "pages/recoveryPassword/RecoveryPassStep4";
import RecoveryUserStep1 from "pages/recoveryUser/RecoveryUserStep1";
import RecoveryUserStep2 from "pages/recoveryUser/RecoveryUserStep2";
import RecoveryUserStep3 from "pages/recoveryUser/RecoveryUserStep3";
import TokenActivationFailed from "pages/Token/TokenActivationFailed";
import TokenActivationHelp from "pages/Token/TokenActivationHelp";
import TokenActivationPending from "pages/Token/TokenActivationPending";
import TokenActivationStep1 from "pages/Token/TokenActivationStep1";
import TokenActivationStep2 from "pages/Token/TokenActivationStep2";
import TokenActivationSuccess from "pages/Token/TokenActivationSuccess";
import { resizableRoute } from "pages/_components/Resizable";
import BackofficeLayout from "pages/_layouts/BackofficeLayout";
import EnrollmentLayout from "pages/_layouts/EnrollmentLayout";
import ExternalLayout from "pages/_layouts/ExternalLayout";
import SimpleLayout from "pages/_layouts/SimpleLayout";
import { bool, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, withRouter } from "react-router-dom";
import { selectors as sessionSelectors } from "reducers/session";
import PrivateRoutes from "routes/PrivateRoutes";
import { isMobileNativeFunc } from "util/device";
// import UiComponents from "pages/UiComponents";
import TokenActivationAttemps from "pages/Token/TokenActivationAttemps";
import TokenActivationFailedDocument from "pages/Token/TokenActivationFailedDocument";
import InvalidMobileVersionError from "pages/error/mobile/InvalidMobileVersionError";
import UnlockUserStep1 from "pages/unlockUser/UnlockUserStep1";
import UnlockUserStep2 from "pages/unlockUser/UnlockUserStep2";
import UnlockUserStep3 from "pages/unlockUser/UnlockUserStep3";
import UserBlocked from "pages/login/UserBlocked";
import ListEnvironmentUser from "pages/login/ListEnvironmentUser";
import UserBlockedBank from "pages/login/UserBlockedBank";
import ErrorActiveSession from "pages/login/ErrorActiveSession";
import UnlockError from "pages/unlockUser/Error";
import ExpiredPassword from "pages/login/ExpiredPassword";
import ChangePassword from "pages/expiredPassword/ChangePassword";
import SoftToken from "pages/softToken/SoftToken";
import SoftTokenPinForm from "pages/softToken/_components/SoftTokenPinForm";
import InfoOtp from "pages/login/InfoOtp";
import Lobby from "pages/login/Lobby";
import UserBlockedAdmin from "pages/login/UserBlockedAdmin";

class Routes extends Component {
    static propTypes = {
        hasActiveSession: bool,
        isMobileNative: bool.isRequired,
        location: shape({
            pathname: string,
        }).isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        hasActiveSession: false,
    };

    shouldComponentUpdate(nextProps) {
        const {
            location: { pathname },
            hasActiveSession,
        } = this.props;
        // With oauth, the login step 1 sets an active session before routing to step3
        // Changing the value of active session triggers a componentDidMount on the login
        // componente and logs out the recently created session
        // This avoids that error
        if (
            pathname.includes("loginStep1") ||
            pathname.includes("loginStep2") ||
            pathname.includes("loginStep3") ||
            pathname === "/"
        ) {
            if (!hasActiveSession && nextProps.hasActiveSession) {
                return false;
            }
        }
        return true;
    }

    render() {
        const { hasActiveSession, isDesktop } = this.props;
        return (
            <Switch>
                <SimpleLayout exact path="/serverError" component={ServerError} background="menu-background" />
                <SimpleLayout exact path="/error" component={Error} background="menu-background" />
                <SimpleLayout exact path="/websiteunderconstruction" component={WebSiteConstruction} headerMobile />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/"
                    loginBar
                    /* component={isMobileNativeFunc() ? Lobby : LoginStep1} */
                    component={isMobileNativeFunc() ? Lobby : LoginStep1}
                    {...(isMobileNativeFunc()
                        ? { pageStep0: false, hideNotification: false }
                        : { pageStep0: false, hideNotification: false })}
                    hideFooter={!!isMobileNativeFunc()}
                />
                <ExternalLayout
                    exact
                    path="/transfersAccsess"
                    landingPage="transfersAccess"
                    withoutBackgroundMobile
                    component={OneTouchAccess}
                    hasActiveSession={hasActiveSession}
                    redirect={hasActiveSession}
                />
                <ExternalLayout
                    exact
                    path="/paymentsAccess"
                    landingPage="paymentsAccess"
                    withoutBackgroundMobile
                    component={OneTouchAccess}
                    hasActiveSession={hasActiveSession}
                    redirect={hasActiveSession}
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationStep1"
                    landingPage="tokenActivationStep1"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationStep1}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationStep2"
                    landingPage="tokenActivationStep2"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationStep2}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationHelp"
                    landingPage="tokenActivationHelp"
                    withoutBackgroundMobile
                    background="white"
                    component={TokenActivationHelp}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationSuccess"
                    landingPage="tokenActivationSuccess"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationSuccess}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationPending"
                    landingPage="tokenActivationPending"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationPending}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationFailed"
                    landingPage="tokenActivationFailed"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationFailed}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                    componentProps={{
                        showDocumentButton: "1",
                    }}
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationAttemps"
                    landingPage="tokenActivationAttemps"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationAttemps}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />
                <ExternalLayout
                    exact
                    path="/tokenActivationFailedDocument"
                    landingPage="tokenActivationFailedDocument"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationFailedDocument}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                />

                <ExternalLayout
                    exact
                    path="/tokenEntrustActivationFailed"
                    landingPage="tokenEntrustActivationFailed"
                    withoutBackgroundMobile
                    background="background-secondary"
                    component={TokenActivationFailed}
                    hasActiveSession={hasActiveSession}
                    transition="transition-change-feature"
                    componentProps={{
                        showDocumentButton: "0",
                    }}
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/loginStep1"
                    // hideNotification
                    loginBar
                    component={LoginStep1}
                    backMobile={isMobileNativeFunc() ? "/" : undefined}
                    titleKey="global.login"
                    hideLogoHeader={!!isMobileNativeFunc()}
                    hideHeader={isMobileNativeFunc()}
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/loginStep2"
                    component={LoginStep2}
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/loginStep3"
                    component={LoginStep3}
                    // backMobile="/"
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/loginStep4"
                    component={LoginStep4}
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/newEnvironments"
                    component={ListEnvironmentUser}
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/userBlocked"
                    hideNotification
                    loginBar
                    component={UserBlocked}
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/userBlockedBank"
                    hideNotification
                    loginBar
                    component={UserBlockedBank}
                />
                 <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/userBlockedAdmin"
                    hideNotification
                    loginBar
                    component={UserBlockedAdmin}
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/errorActiveSession"
                    hideNotification
                    loginBar
                    component={ErrorActiveSession}
                    hideFooter={!isDesktop}
                />
                <ExternalLayout exact path="/unlockUser/unlockError" withoutBackgroundMobile component={UnlockError} />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/expiredPassword"
                    hideNotification
                    loginBar
                    component={ExpiredPassword}
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/changePassword"
                    hideNotification
                    loginBar
                    component={ChangePassword}
                />
                <ExternalLayout
                    exact
                    path="/recoveryPassword/step1"
                    withoutBackgroundMobile
                    component={RecoveryPassStep1}
                    footerLayout
                    backMobile="/"
                />
                <ExternalLayout
                    exact
                    path="/recoveryPassword/step2"
                    withoutBackgroundMobile
                    footerLayout
                    component={RecoveryPassStep2}
                />
                <ExternalLayout
                    exact
                    path="/recoveryPassword/step3"
                    withoutBackgroundMobile
                    footerLayout
                    component={RecoveryPassStep3}
                />
                <ExternalLayout
                    exact
                    path="/recoveryPassword/step4"
                    withoutBackgroundMobile
                    component={RecoveryPassStep4}
                />
                <ExternalLayout
                    exact
                    path="/recoverUser/step1"
                    component={RecoveryUserStep1}
                    withoutBackgroundMobile
                    backMobile="/"
                />
                <ExternalLayout exact path="/recoverUser/step2" component={RecoveryUserStep2} withoutBackgroundMobile />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/unlockUser/step1"
                    component={UnlockUserStep1}
                    withoutBackgroundMobile
                    backMobile="/loginStep1"
                />
                <ExternalLayout
                    exact
                    path="/unlockUser/step2"
                    component={UnlockUserStep2}
                    withoutBackgroundMobile
                    backMobile="/unlockUser/step1"
                />
                <ExternalLayout exact path="/unlockUser/step3" component={UnlockUserStep3} withoutBackgroundMobile />
                <ExternalLayout
                    exact
                    path="/recoveryUser/step1"
                    component={RecoveryUserStep1}
                    withoutBackgroundMobile
                    backMobile="/loginStep1"
                />
                <ExternalLayout
                    exact
                    path="/recoveryUser/step2"
                    component={RecoveryUserStep2}
                    withoutBackgroundMobile
                />
                <ExternalLayout
                    exact
                    path="/recoveryUser/step3"
                    component={RecoveryUserStep3}
                    withoutBackgroundMobile
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/languageSelection"
                    component={LanguageSelection}
                />
                <EnrollmentLayout exact path="/enrollment" component={EnrollmentStart} showBack />
                <EnrollmentLayout
                    exact
                    path="/enrollment/document"
                    step={1}
                    component={EnrollmentInvitationCode}
                    showBack
                />
                <EnrollmentLayout
                    exact
                    path="/enrollment/invitationCodeToken"
                    component={EnrollmentInvitationCodeToken}
                    step={2}
                    completed={2}
                />
                <EnrollmentLayout
                    exact
                    path="/enrollment/createUser"
                    component={EnrollmentCreateUser}
                    showBack
                    step={3}
                    completed={3}
                />
                <EnrollmentLayout
                    exact
                    path="/enrollment/validateUser"
                    component={EnrollmentValidateUser}
                    step={3}
                    completed={3}
                />
                <EnrollmentLayout
                    exact
                    path="/enrollment/selectAvatar"
                    component={EnrollmentSelectAvatar}
                    step={5}
                    completed={5}
                />
                <EnrollmentLayout
                    exact
                    path="/enrollment/question"
                    component={EnrollmentQuestion}
                    step={6}
                    completed={6}
                />
                <EnrollmentLayout
                    exact
                    path="/enrollment/termsAndConditions"
                    component={EnrollmentTermsAndConditions}
                    step={7}
                    completed={7}
                />
                <EnrollmentLayout
                    exact
                    path="/enrollment/finishAssociate"
                    component={EnrollmentFinish}
                    step={8}
                    completed={8}
                />

                <BackofficeLayout exact path="/forms/backoffice/ticket" component={BackofficeTransaction} />
                <SimpleLayout
                    exact
                    path="/invalidMobileVersion"
                    component={InvalidMobileVersionError}
                    background="menu-background"
                />

                <ExternalLayout
                    exact
                    path="/softToken"
                    component={SoftToken}
                    withoutBackgroundMobile
                    isAnonymous
                    aditionalClassName="background-gradient"
                />
                <ExternalLayout
                    exact
                    path="/softTokenPin"
                    component={SoftTokenPinForm}
                    withoutBackgroundMobile
                    isAnonymous
                    aditionalClassName="background-gradient"
                />
                <ExternalLayout
                    aditionalClassName="login-desktop-wrapper"
                    exact
                    path="/info-otp"
                    component={InfoOtp}
                    hideFooter
                />
                <PrivateRoutes />
            </Switch>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(Routes)));
