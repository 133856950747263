import Image from "pages/_components/Image";
import React, { Component } from "react";

class ChevromRight extends Component {
    render() {
        return (
            <div className="table-data table-data-icon">
                <Image src="images/chevromRight.svg" className="svg-icon svg-caret" ariaHiden />
            </div>
        );
    }
}

export default ChevromRight;
