import classNames from "classnames";
import filesize from "filesize";
import { Field, Form, withFormik } from "formik";
import Parser from "html-react-parser";
import MessageHeader from "pages/communications/_components/MessageHeader";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Selector from "pages/_components/fields/formik/Selector";
import TextArea from "pages/_components/fields/TextArea";
import TextField from "pages/_components/fields/TextField";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";
import {
    actions as communicationTraysActions,
    selectors as communicationTraysSelectors,
} from "reducers/communicationTrays";
import { actions as notificationActions } from "reducers/notification";
import { compose } from "redux";
import * as config from "util/config";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "communications.compose";

class Compose extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        onCloseClick: func.isRequired,
        selectedFiles: arrayOf(string),
        isDesktop: bool.isRequired,
        detail: oneOfType([arrayOf(shape({})), shape({})]),
        trays: arrayOf(shape({})),
        isReply: bool,
    };

    static defaultProps = {
        selectedFiles: [],
        trays: [],
        isReply: false,
        detail: {},
    };

    state = {
        fileInputRef: React.createRef(),
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(communicationTraysActions.listRequest());
    }

    onChangeFile(event) {
        const { dispatch } = this.props;

        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        const { fileInputRef } = this.state;

        if (file) {
            const fileTypes = config.get("core.allowedFileExtensions");
            const fileExtension = `${file.name.split(".").slice(-1)}`;

            if (fileTypes.indexOf(fileExtension) > -1) {
                const fileMaxSize = config.get("core.maxFileSize");
                if (file.size < fileMaxSize) {
                    dispatch(communicationActions.selectAttachment(file));
                } else {
                    dispatch(
                        notificationActions.showNotification(
                            `${i18n.get("communications.compose.attachment.maxFileSize") +
                                fileMaxSize / (1024 * 1024)}Mb`,
                            "error",
                            ["communications"],
                        ),
                    );
                }
            } else {
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("communications.compose.attachment.invalid"),
                        "error",
                        ["communications"],
                    ),
                );
            }
        }

        fileInputRef.current.value = "";
    }

    removeSelectedFile = (index) => {
        const { dispatch } = this.props;

        dispatch(communicationActions.removeSelectedAttachment(index));
    };

    render() {
        const { trays, isReply, isDesktop, selectedFiles, onCloseClick } = this.props;
        const { fileInputRef } = this.state;

        return (
            <Form>
                <Box
                    display="flex"
                    column
                    background="white"
                    borderRadius="default"
                    className={classNames("px-5 px-md-7 pb-8 mb-7", {
                        "pt-3": isReply,
                        "pt-5": !isReply,
                        "mb-11": isReply && !isDesktop,
                    })}>
                    <MessageHeader
                        {...(!isReply && { title: i18n.get("communications.compose.message.new") })}
                        {...(!isDesktop && { ptSafe: false, isInModal: true })}
                        onClose={onCloseClick}
                    />

                    {!isReply && (
                        <Field
                            hidePlaceholder
                            component={TextField}
                            idForm={FORM_ID}
                            name="subject"
                            type="text"
                            maxLength={config.getInteger("notification.subject.length") || 100}
                        />
                    )}
                    {!isReply && (
                        <Field
                            component={Selector}
                            options={trays.map((tray) => ({
                                value: tray.idCommunicationTray,
                                label: Parser(i18n.get(`communications.tray.${tray.idCommunicationTray}`)),
                            }))}
                            className="slideFromBottom"
                            idForm={FORM_ID}
                            name="tray"
                            type="text"
                        />
                    )}
                    <Field
                        hidePlaceholder
                        component={TextArea}
                        idForm={FORM_ID}
                        name="body"
                        maxLength={1500}
                        {...(isReply && { labelNoMarginTop: true })}
                    />
                    {selectedFiles && (
                        <>
                            {selectedFiles.map((file, index) => (
                                <Box background="grey" borderRadius="md" className="mt-3">
                                    <Button
                                        key={file.name}
                                        bsStyle="link"
                                        imageMd
                                        role="presentation"
                                        onClick={() => this.removeSelectedFile(index)}
                                        block>
                                        <Box display="flex" alignY="center" fullWidth>
                                            <Image
                                                src="images/icons/attachment.svg"
                                                color="text-disabled-color"
                                                wrapperWidth="7"
                                                wrapperHeight="7"
                                                width="7"
                                                height="7"
                                            />
                                            <Text color="text" size="6" ellipsis>
                                                {file.name}
                                            </Text>

                                            <Text color="text" size="6" className="ml-2">
                                                ({filesize(file.size)})
                                            </Text>
                                            <Image
                                                src="images/icons/deleteTrash.svg"
                                                wrapperWidth="7"
                                                wrapperHeight="7"
                                                width="7"
                                                height="7"
                                            />
                                        </Box>
                                    </Button>
                                </Box>
                            ))}
                        </>
                    )}
                </Box>
                <Box display="flex" className="pb-10 px-5 px-md-0" fullWidth>
                    <Row gapY="3" {...(!isDesktop && { className: "mb-7" })}>
                        <Col xs={12} md="6">
                            <Button
                                bsStyle="outline"
                                label="communications.compose.attach"
                                onClick={() => {
                                    fileInputRef.current.click();
                                }}
                                block
                            />
                        </Col>

                        <Col xs={12} md="6" {...(!isDesktop && { className: "grid-reversed" })}>
                            <Button bsStyle="primary" label="communications.compose.send" type="submit" block />
                        </Col>
                    </Row>
                </Box>

                <input
                    id="myInput"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => this.onChangeFile(e)}
                />
            </Form>
        );
    }
}

const mapsStateToProps = (state) => ({
    trays: communicationTraysSelectors.list(state),
    detail: communicationSelectors.detail(state),
    selectedFiles: communicationSelectors.selectedAttachments(state),
});

const chargeSubject = ({ isReply, detail, preloadedMessage }) => {
    if (isReply) {
        if (detail && detail[0]) {
            return detail[0].subject;
        }
        if (preloadedMessage) {
            return preloadedMessage.subject;
        }
    }
    return "";
};

export default compose(
    connect(mapsStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: (props) => ({
            subject: chargeSubject(props),
            body: "",
            tray: "",
        }),
        validationSchema: (props) => {
            const { isReply } = props;

            return Yup.object().shape({
                subject: !isReply
                    ? Yup.string()
                          .trim()
                          .required(i18n.get("communications.subject.required"))
                    : Yup.string().notRequired(),
                body: Yup.string()
                    .trim()
                    .required(i18n.get("communications.body.required")),
                tray: !isReply
                    ? Yup.string()
                          .trim()
                          .required(i18n.get("communications.tray.required"))
                    : Yup.string().notRequired(),
            });
        },

        handleSubmit: (fields, formikBag) => {
            const { dispatch, selectedFiles, isReply, isDesktop, onCloseClick } = formikBag.props;
            const body = fields.body.trim();
            const subject = fields.subject.trim();
            const { tray } = fields;

            onCloseClick();

            if (isReply) {
                const { detail } = formikBag.props;

                dispatch(
                    communicationActions.replyRequest(
                        detail[0].communicationTray.idCommunicationTray,
                        `RE: ${detail[0].subject}`,
                        body,
                        detail[0].idCommunication,
                        0,
                        selectedFiles,
                        isDesktop,
                    ),
                );
            } else {
                dispatch(communicationActions.sendRequest(tray, subject, body, selectedFiles, isDesktop));
            }
        },
    }),
)(Compose);
