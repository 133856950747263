import React, { useMemo } from "react";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text/Text";
import Row from "pages/_components/Row/Row";
import { Col } from "react-bootstrap";
import { func, string } from "prop-types";

const BannerAppleWalletComponent = ({ redirectCallback, redirect, setShow }) => {
    const renderProductButtons = () => (
        <Box className="mt-10" fullWidth>
            <Row className="justify-content-center">
                <Col xs={12} md={8} lg={12}>
                    <Button
                        bsStyle="primary"
                        label="global.continue"
                        loading={false}
                        block
                        onClick={() => {
                            if (redirectCallback) {
                                redirectCallback();
                            }
                        }}
                    />
                    <Button
                        bsStyle="outline"
                        label="global.cancel"
                        block
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                </Col>
            </Row>
        </Box>
    );

    const renderEmptyProductButtons = () => (
        <Box className="mt-10" fullWidth>
            <Row className="justify-content-center">
                <Col xs={12} md={8} lg={12}>
                    <Button
                        bsStyle="primary"
                        label="global.close"
                        loading={false}
                        block
                        onClick={() => {
                            if (redirectCallback) {
                                redirectCallback();
                            }
                        }}
                    />
                </Col>
            </Row>
        </Box>
    );

    const hasRedirectProducts = useMemo(() => redirect && redirect !== "", [redirect]);

    const renderContentBanner = () => (
        <Box display="flex" column alignX="center" background="white" className="mt-7" borderRadius="md" fullWidth>
            <Box display="flex" alignX="center">
                <Image src="images/applePayBanner.svg" ariaLabel="applePayLogo" wrapperWidth="auto" width="13" />
            </Box>

            <Text
                component="p"
                align="center"
                size="5"
                color="heading"
                className="mx-5  mb-5 mt-10"
                labelKey={hasRedirectProducts ? "banner.apple.wallet.title" : "banner.apple.wallet.title.emptyProducts"}
            />
            {!hasRedirectProducts && (
                <Text
                    component="p"
                    align="center"
                    size="6"
                    color="heading"
                    className="mx-5  mb-5 mt-1"
                    labelKey="banner.apple.wallet.subtitle.emptyProducts"
                />
            )}
            {redirect && redirect !== "" && renderProductButtons()}
            {(!redirect || redirect === "") && renderEmptyProductButtons()}
        </Box>
    );

    return renderContentBanner();
};

BannerAppleWalletComponent.propTypes = {
    redirectCallback: func,
    redirect: string,
    setShow: func,
};

BannerAppleWalletComponent.defaultProps = {
    redirectCallback: () => {},
    redirect: "",
    setShow: () => {},
};

export default BannerAppleWalletComponent;
