import React, { Component } from "react";

import UserActionTicket from "pages/administration/_components/tickets/UserActionTicket";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";

class UserActionTicketContent extends Component {
    render() {
        return (
            <AdministrationTicket {...this.props}>
                <UserActionTicket {...this.props} />
            </AdministrationTicket>
        );
    }
}

export default UserActionTicketContent;
