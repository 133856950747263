import React, { Component } from "react";
import { routerActions } from "react-router-redux";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { bool, func, shape } from "prop-types";

import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";
import * as i18n from "util/i18n";

import Box from "pages/_components/Box";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import Captcha from "pages/_components/fields/credentials/Captcha";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import classNames from "classnames";
import Stepper from "pages/_layouts/DefaultStepperLayout/Stepper";
import HeaderWithoutSession from "pages/_components/HeaderWithoutSession";
import { steps } from "./utils";

const FORM_ID = "recoveryPassword.step1";

class Step1Content extends Component {
    constructor(props) {
        super(props);
        this.onHeaderClose = this.onHeaderClose.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.recoveryPassCleanUp());
    }

    onHeaderClose() {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    }

    renderMobile() {
        const { isDesktop, isSubmitting, values } = this.props;
        const emailRegex = /^[a-zA-Z0-9+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/g;
        const handleEmailInput = (e) => {
            const inputValue = e.target.value;
            const validMail = inputValue.replace(/[^a-zA-Z0-9@._+&*-]/g, "");
            if (!emailRegex.test(e.target.value)) {
                e.target.value = validMail;
            }
        };
        return (
            <MainContainer>
                <Box fullWidth>
                    <Form>
                        <Box display="flex" column className="mt-7">
                            <Box className="mb-5">
                                <HeaderWithoutSession labelKey="recoveryPassword.step1.header" />
                            </Box>
                            <Stepper className="mb-6" stepsList={steps} currentStep={1} completed={0} showLabelMobile />
                            <Field
                                hideLabel={isDesktop}
                                name="usernameToReset"
                                idForm={FORM_ID}
                                autoFocus={isDesktop}
                                component={TextField}
                                placeholder={i18n.get("recoverPassword.form.user.placeholder", "Ingrese su usuario")}
                                noMarginBottom
                                className="mb-3"
                                maxLength={20}
                                labelClassName="text-bold"
                                spellCheck="false"
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
                                }}
                            />
                            <Box display="flex" column className="mb-2 mt-4">
                                <Field
                                    name="emailUser"
                                    hideLabel={isDesktop}
                                    idForm={FORM_ID}
                                    component={TextField}
                                    autoFocus={isDesktop}
                                    placeholder={i18n.get("", "Ingrese su correo electrónico")}
                                    noMarginBottom
                                    className="mb-3"
                                    type="email"
                                    labelClassName="text-bold"
                                    spellCheck="false"
                                    onInput={handleEmailInput}
                                />
                            </Box>
                            <Box display="flex" column className="mb-2 mt-4">
                                <Field idForm={FORM_ID} name="captcha" component={Captcha} />
                            </Box>
                        </Box>
                        <Box className="mt-6 mb-6">
                            <Button
                                type="submit"
                                bsStyle="primary"
                                loading={isSubmitting}
                                label="global.continue"
                                block
                                disabled={!values.usernameToReset || !values.emailUser || !values.captcha}
                            />
                        </Box>
                        <Box display="flex" alignX="center" fullWidth>
                            <Button
                                href="/recoveryUser/step1"
                                primaryLink
                                bsStyle="link"
                                defaultLabelText={i18n.get("", "Recuperar usuario")}
                                className="px-0 mb-2"
                            />
                        </Box>
                    </Form>
                </Box>
            </MainContainer>
        );
    }

    renderDesktop() {
        const { isSubmitting, isMobile, values } = this.props;
        const emailRegex = /^[a-zA-Z0-9+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/g;
        const handleEmailInput = (e) => {
            const inputValue = e.target.value;
            const validMail = inputValue.replace(/[^a-zA-Z0-9@._+&*-]/g, "");
            if (!emailRegex.test(e.target.value)) {
                e.target.value = validMail;
            }
        };
        return (
            <>
                {!isMobile && <BoxErrorNotification isMobile={isMobile} scopeToShow="recoveryPassword" />}
                <Head title="recoveryPassword.step1.header" onBack={this.onHeaderClose} />
                <MainContainer>
                    <Form
                        className={classNames({
                            "pointer-events-none": isSubmitting,
                        })}
                        noValidate="novalidate">
                        <Box display="flex" column fullWidth>
                            <Box display="flex" column className="mb-2 mt-4">
                                <Stepper
                                    className="stepper-default mb-7"
                                    stepsList={steps}
                                    currentStep={1}
                                    completed={0}
                                    showLabelMobile
                                />

                                <Field
                                    name="usernameToReset"
                                    idForm={FORM_ID}
                                    autoComplete="on"
                                    component={TextField}
                                    placeholder={i18n.get(
                                        "recoverPassword.form.user.placeholder",
                                        "Ingrese su usuario",
                                    )}
                                    noMarginBottom
                                    className="mb-6"
                                    maxLength={20}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
                                    }}
                                />

                                <Field
                                    name="emailUser"
                                    idForm={FORM_ID}
                                    component={TextField}
                                    placeholder={i18n.get("", "Ingrese su correo electrónico")}
                                    autoCapitalize="characters"
                                    type="email"
                                    onInput={handleEmailInput}
                                />

                                <Box display="flex" fullWidth className="mt-6 mb-6">
                                    <Field idForm={FORM_ID} name="captcha" component={Captcha} />
                                </Box>
                            </Box>
                            <Box display="flex" className="mb-4 mt-auto">
                                <Button
                                    label="global.continue"
                                    type="submit"
                                    bsStyle="primary"
                                    loading={isSubmitting}
                                    block
                                    disabled={!values.usernameToReset || !values.emailUser || !values.captcha}
                                />
                            </Box>
                            <Button
                                href="/recoveryUser/step1"
                                primaryLink
                                bsStyle="link"
                                defaultLabelText={i18n.get("", "Recuperar usuario")}
                            />
                        </Box>
                    </Form>
                </MainContainer>
            </>
        );
    }

    render() {
        const { isMobile } = this.props;

        return isMobile ? this.renderMobile() : this.renderDesktop();
    }
}

Step1Content.propTypes = {
    isMobile: bool.isRequired,
    isDesktop: bool.isRequired,
    isSubmitting: func,
    values: shape({}),
    dispatch: func.isRequired,
};

Step1Content.defaultProps = {
    values: null,
    isSubmitting: null,
};

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({ usernameToReset: "", emailUser: "" }),
    validationSchema: () =>
        Yup.object().shape({
            usernameToReset: Yup.string().required(i18n.get(`${FORM_ID}.usernameToReset.required`)),
            emailUser: Yup.string().required(i18n.get(`${FORM_ID}.emailUser.required`)),
        }),
    handleSubmit: (values, formikBag) => {
        const { usernameToReset, emailUser } = values;
        formikBag.props.dispatch(recoveryPasswordActions.recoveryPassStep1(usernameToReset, emailUser, formikBag));
    },
})(Step1Content);
