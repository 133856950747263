import React from "react";
import { TOKEN_STATUS_AS_ACTIVE_LIST, TOKEN_STATUS_AS_LOCKED, TOKEN_STATUS_AS_PENDING_LIST } from "util/userToken.util";
import { arrayOf, func, string, bool } from "prop-types";
import TokenButton from "./TokenButton";
import Button from "../Button/Button";
import Box from "../Box/Box";

const CREDENTIAL_TOKEN_ID = "otp";
const TokenButtonStatusWrapper = ({ tokenStatus, onChangeToken, credentials, loading }) => {
    const hasCredentialById = (credentialId) =>
        credentials && credentials.some((item) => item && item === credentialId);

    const renderButtonInvalidStatus = (label) => (
        <Box display="flex" alignX="center" alignY="center" fullWidth>
            <Button className="btn-login-action btn-token-action-dashed" label={label} disabled />
        </Box>
    );

    // eslint-disable-next-line no-confusing-arrow
    const renderTokenComponent = () =>
        hasCredentialById(CREDENTIAL_TOKEN_ID) ? (
            <TokenButton
                onChange={(code) => {
                    if (onChangeToken) {
                        onChangeToken(code);
                    }
                }}
            />
        ) : (
            <div />
        );

    const renderOptionChip = () => {
        if (loading) {
            return (
                <Box display="flex" alignX="center" alignY="center" fullWidth>
                    <Button className="btn-token-loading" loading {...{ block: true }} />
                </Box>
            );
        }
        if (!tokenStatus) {
            return <div />;
        }

        if (TOKEN_STATUS_AS_ACTIVE_LIST.includes(tokenStatus)) {
            return renderTokenComponent();
        }

        if (TOKEN_STATUS_AS_LOCKED.includes(tokenStatus)) {
            return renderButtonInvalidStatus("token.entrust.status.locked.embed");
        }
        if (TOKEN_STATUS_AS_PENDING_LIST.includes(tokenStatus)) {
            return renderButtonInvalidStatus("login.step0.pending.token");
        }

        return renderButtonInvalidStatus("token.entrust.status.undefined.embed");
    };

    return <>{renderOptionChip()}</>;
};

TokenButtonStatusWrapper.propTypes = {
    tokenStatus: string,
    onChangeToken: func,
    credentials: arrayOf(string),
    loading: bool,
};
TokenButtonStatusWrapper.defaultProps = {
    tokenStatus: undefined,
    onChangeToken: () => {},
    credentials: [],
    loading: false,
};

export default TokenButtonStatusWrapper;
