import { createReducer, makeActionCreator } from "util/redux";

const INITIAL_STATE = {
    fetching: false,
    functionalGroups: [],
    signatureLevelsCounts: {},
    signatureType: "",
    signatureDispatch: false,
    topAmount: {},
};

export const selectors = {
    signatureAlias: ({ signaturesSchemesTicket }) => signaturesSchemesTicket.signatureAlias,
    functionalGroups: ({ signaturesSchemesTicket }) => signaturesSchemesTicket.functionalGroups,
    isFetching: ({ signaturesSchemesTicket }) => signaturesSchemesTicket.fetching,
    signatureLevelsCounts: ({ signaturesSchemesTicket }) => signaturesSchemesTicket.signatureLevelsCounts,
    signatureType: ({ signaturesSchemesTicket }) => signaturesSchemesTicket.signatureType,
    signatureDispatch: ({ signaturesSchemesTicket }) => signaturesSchemesTicket.signatureDispatch,
    topAmount: ({ signaturesSchemesTicket }) => signaturesSchemesTicket.topAmount,
};

export default (name) => {
    const types = {
        LOAD_SIGNATURES_SCHEMES_TICKET_REQUEST: `${name}/LOAD_SIGNATURES_SCHEMES_TICKET_REQUEST`,
        LOAD_SIGNATURES_SCHEMES_TICKET_FAILURE: `${name}/LOAD_SIGNATURES_SCHEMES_TICKET_FAILURE`,
        LOAD_SIGNATURES_SCHEMES_TICKET_SUCCESS: `${name}/LOAD_SIGNATURES_SCHEMES_TICKET_SUCCESS`,
    };

    return {
        types,
        reducer: createReducer(INITIAL_STATE, {
            [types.LOAD_SIGNATURES_SCHEMES_TICKET_REQUEST]: (state) => ({
                ...state,
                fetching: true,
            }),
            [types.LOAD_SIGNATURES_SCHEMES_TICKET_FAILURE]: (state) => ({
                ...state,
                fetching: false,
            }),
            [types.LOAD_SIGNATURES_SCHEMES_TICKET_SUCCESS]: (
                state,
                {
                    data: {
                        signatureAlias,
                        functionalGroups,
                        signatureLevelsCounts,
                        signatureType,
                        topAmount,
                        signatureDispatch,
                    },
                },
            ) => ({
                ...state,
                fetching: false,
                signatureAlias,
                functionalGroups,
                signatureLevelsCounts,
                signatureType,
                topAmount,
                signatureDispatch,
            }),
        }),
        actions: {
            loadSignaturesSchemesTicketRequest: makeActionCreator(
                types.LOAD_SIGNATURES_SCHEMES_TICKET_REQUEST,
                "idTransaction",
            ),
            loadSignaturesSchemesTicketFailure: makeActionCreator(types.LOAD_SIGNATURES_SCHEMES_TICKET_FAILURE),
            loadSignaturesSchemesTicketSuccess: makeActionCreator(types.LOAD_SIGNATURES_SCHEMES_TICKET_SUCCESS, "data"),
        },
    };
};
