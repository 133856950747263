import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { bool, func, shape, string } from "prop-types";
import withRouter from "react-router-dom/withRouter";
import * as Yup from "yup";
import { Field } from "formik";

import { selectors as i18nSelectors } from "reducers/i18n";
import { get } from "util/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as templateSelectors } from "reducers/template";

import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import FormTransition from "pages/forms/_components/FormTransition";
import PageLoading from "pages/_components/PageLoading";
import { PDFTextField, PDFSelectorField } from "pages/forms/customForms/PDFTicket";

const ID_FORM = "accounts.openingRequest";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

const AccountOpeningRequestForm = (props) => {
    const { mode, dispatch, transaction, preDataForm } = props;

    useEffect(() => {
        const formData = {};
        dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
    }, [dispatch]);

    const accountTypeOptions = [
        { id: "CA", label: get("productType.CA") },
        { id: "CC", label: get("productType.CC") },
    ];

    const currenciesOptions = [
        { id: "Dolar", label: get(`forms.${ID_FORM}.currencies.option.Dolar.label`) },
        { id: "Euros", label: get(`forms.${ID_FORM}.currencies.option.Euros.label`) },
    ];

    const debitCardOptions = [
        { id: "Si", label: get("global.yes") },
        { id: "No", label: get("global.no") },
    ];

    const placeSelectorOptions = [
        { id: "homeDelivery", label: get(`forms.${ID_FORM}.placeSelector.option.homeDelivery.label`) },
        { id: "retreatBranch", label: get(`forms.${ID_FORM}.placeSelector.option.retreatBranch.label`) },
    ];

    const overdraftOptions = [
        { id: "No", label: get(`forms.${ID_FORM}.overdraft.option.No.label`) },
        { id: "Si", label: get(`forms.${ID_FORM}.overdraft.option.Si.label`) },
    ];

    const getBranchList = () => {
        const branchList = props?.preDataForm?.branchList;
        return { branches: branchList || [] };
    };

    const renderTicket = (values) => (
        <>
            <PDFSelectorField idForm={ID_FORM} name="accountType" options={accountTypeOptions} values={values} />
            <PDFSelectorField idForm={ID_FORM} name="currencies" options={currenciesOptions} values={values} />
            <PDFSelectorField
                idForm={ID_FORM}
                name="accountBranch"
                options={getBranchList().branches}
                optionLabelId="name"
                values={values}
            />
            <PDFSelectorField idForm={ID_FORM} name="debitCard" options={debitCardOptions} values={values} />
            {values.debitCard?.[0] === "Si" && (
                <PDFSelectorField
                    idForm={ID_FORM}
                    name="placeSelector"
                    options={placeSelectorOptions}
                    values={values}
                />
            )}
            {values.placeSelector?.[0] === "homeDelivery" && (
                <PDFTextField idForm={ID_FORM} name="address" values={values} />
            )}
            {values.placeSelector?.[0] === "retreatBranch" && (
                <PDFSelectorField
                    idForm={ID_FORM}
                    name="placeOfRetreat"
                    options={getBranchList().branches}
                    optionLabelId="name"
                    values={values}
                />
            )}
            <PDFSelectorField idForm={ID_FORM} name="overdraft" options={overdraftOptions} values={values} />
        </>
    );

    const renderFields = (setFieldValue, values) => {
        const { currentLang, fromBackoffice } = props;

        const idTransaction = transaction?.idTransaction;

        if (!preDataForm) {
            return <PageLoading loading />;
        }

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
            isFocused: false,
        };

        return (
            <>
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    key="accountType"
                    name="accountType"
                    idField="accountType"
                    labelIdField="react-select-accountType-input"
                    optionList={accountTypeOptions}
                    renderAs="combo"
                    placeholder={`forms.${ID_FORM}.accountType.placeholder`}
                    isRequired
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    key="currencies"
                    name="currencies"
                    idField="currencies"
                    labelIdField="react-select-currencies-input"
                    optionList={currenciesOptions}
                    renderAs="combo"
                    placeholder={`forms.${ID_FORM}.currencies.placeholder`}
                    isRequired
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Branchlist}
                    data={getBranchList()}
                    key="accountBranch"
                    name="accountBranch"
                    idField="accountBranch"
                    isRequired
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    key="debitCard"
                    name="debitCard"
                    idField="debitCard"
                    labelIdField="react-select-debitCard-input"
                    optionList={debitCardOptions}
                    renderAs="combo"
                />

                {values.debitCard?.[0] === "Si" && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        key="placeSelector"
                        name="placeSelector"
                        idField="placeSelector"
                        labelIdField="react-select-placeSelector-input"
                        optionList={placeSelectorOptions}
                        renderAs="combo"
                        isRequired
                    />
                )}

                {values.placeSelector?.[0] === "homeDelivery" && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="address"
                        name="address"
                        idField="address"
                        minLength={1}
                        maxLength={200}
                        isRequired
                    />
                )}

                {values.placeSelector?.[0] === "retreatBranch" && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Branchlist}
                        data={getBranchList()}
                        key="placeOfRetreat"
                        name="placeOfRetreat"
                        idField="placeOfRetreat"
                        placeholder={`forms.${ID_FORM}.placeOfRetreat.placeholder`}
                        isRequired
                    />
                )}

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Horizontalrule}
                    key="horizontalrule"
                    name="horizontalrule"
                    idField="horizontalrule"
                />

                {mode === "edit" && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Termsandconditions}
                        key="termsConditions"
                        name="termsConditions"
                        idField="termsConditions"
                        contentCustom={get(`forms.${ID_FORM}.termsConditions.text`)}
                        showAcceptOptionText={get(`forms.${ID_FORM}.termsConditions.showAcceptOptionText`)}
                        showAcceptOption
                        isRequired
                    />
                )}

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    key="overdraft"
                    name="overdraft"
                    idField="overdraft"
                    labelIdField="react-select-overdraft-input"
                    optionList={overdraftOptions}
                    renderAs="combo"
                />
            </>
        );
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
        // eslint-disable-next-line react/prop-types
    } = preDataForm || {};

    const validationSchema = () =>
        Yup.object().shape({
            accountType: Yup.array()
                .of(Yup.string().required(get(`forms.${ID_ACTIVITY}.accountType.empty`)))
                .required(get(`forms.${ID_ACTIVITY}.accountType.empty`))
                .nullable(),
            currencies: Yup.array()
                .of(Yup.string().required(get(`forms.${ID_ACTIVITY}.currencies.empty`)))
                .required(get(`forms.${ID_ACTIVITY}.currencies.empty`))
                .nullable(),
            accountBranch: Yup.string()
                .required(get(`forms.${ID_ACTIVITY}.accountBranch.empty`))
                .nullable(),
            // eslint-disable-next-line no-confusing-arrow
            placeSelector: Yup.array().when("debitCard", (value) =>
                value?.[0] === "Si"
                    ? Yup.array()
                          .of(Yup.string().required(get(`forms.${ID_ACTIVITY}.placeSelector.empty`)))
                          .required(get(`forms.${ID_ACTIVITY}.placeSelector.empty`))
                          .nullable()
                    : Yup.array(),
            ),
            // eslint-disable-next-line no-confusing-arrow
            address: Yup.array().when("placeSelector", (value) =>
                value?.[0] === "homeDelivery"
                    ? Yup.string()
                          .required(get(`forms.${ID_ACTIVITY}.address.empty`))
                          .nullable()
                    : Yup.string(),
            ),
            // eslint-disable-next-line no-confusing-arrow
            placeOfRetreat: Yup.array().when("placeSelector", (value) =>
                value?.[0] === "retreatBranch"
                    ? Yup.string()
                          .required(get(`forms.${ID_ACTIVITY}.placeOfRetreat.empty`))
                          .nullable()
                    : Yup.string(),
            ),
            termsConditions: Yup.string().test(
                "checked",
                get(`fields.defaultForm.defaultField.requiredError`),
                (value) => value === "true",
            ),
        });

    const formProps = {
        title: "forms.accounts.openingRequest.formName",
        metadata: {
            draftsEnabled: true,
            templatesEnabled: true,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },

        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validationSchema,
    };

    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
});

AccountOpeningRequestForm.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
};

AccountOpeningRequestForm.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: null,
    previewData: {},
    transaction: {},
    location: {},
    fromTransaction: false,
};

export default compose(connect(mapStateToProps), withRouter)(AccountOpeningRequestForm);
