import React from "react";
import { StyleSheet, Svg, G, Line, Path, Circle, Rect } from "@react-pdf/renderer";

export const PendingWally = () => (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.16683 9.99967C8.7066 9.99967 8.3335 9.62657 8.3335 9.16634C8.3335 8.70611 8.7066 8.33301 9.16683 8.33301H11.6668C11.6668 5.57157 13.9054 3.33301 16.6668 3.33301C19.4283 3.33301 21.6668 5.57157 21.6668 8.33301H24.1668C24.6271 8.33301 25.0002 8.70611 25.0002 9.16634C25.0002 9.62657 24.6271 9.99967 24.1668 9.99967H9.16683ZM16.6668 4.99967C14.8259 4.99967 13.3335 6.49206 13.3335 8.33301H20.0002C20.0002 6.49206 18.5078 4.99967 16.6668 4.99967Z"
            fill="white"
        />
        <Path
            d="M25.0002 6.66634H23.1235C22.9713 6.07506 22.74 5.51552 22.4416 4.99967H25.0002C27.7616 4.99967 30.0002 7.23824 30.0002 9.99967V16.9562C29.4592 16.8284 28.9025 16.7412 28.3335 16.6979V9.99967C28.3335 8.15873 26.8411 6.66634 25.0002 6.66634Z"
            fill="white"
        />
        <Path
            d="M8.3335 34.9997H19.6828C19.1931 34.4894 18.7529 33.9313 18.3699 33.333H8.3335C6.49255 33.333 5.00016 31.8406 5.00016 29.9997V9.99967C5.00016 8.15873 6.49255 6.66634 8.3335 6.66634H10.2102C10.3624 6.07506 10.5937 5.51552 10.892 4.99967H8.3335C5.57206 4.99967 3.3335 7.23824 3.3335 9.99967V29.9997C3.3335 32.7611 5.57206 34.9997 8.3335 34.9997Z"
            fill="white"
        />
        <Path
            d="M27.5002 21.6663C27.9604 21.6663 28.3335 22.0394 28.3335 22.4997V27.1545L31.4227 30.2438C31.7482 30.5692 31.7482 31.0968 31.4227 31.4223C31.0973 31.7477 30.5697 31.7477 30.2443 31.4223L26.6668 27.8449V22.4997C26.6668 22.0394 27.0399 21.6663 27.5002 21.6663Z"
            fill="white"
        />
        <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M36.6668 27.4997C36.6668 32.5623 32.5628 36.6663 27.5002 36.6663C22.4376 36.6663 18.3335 32.5623 18.3335 27.4997C18.3335 22.4371 22.4376 18.333 27.5002 18.333C32.5628 18.333 36.6668 22.4371 36.6668 27.4997ZM35.0002 27.4997C35.0002 31.6418 31.6423 34.9997 27.5002 34.9997C23.358 34.9997 20.0002 31.6418 20.0002 27.4997C20.0002 23.3575 23.358 19.9997 27.5002 19.9997C31.6423 19.9997 35.0002 23.3575 35.0002 27.4997Z"
            fill="white"
        />
    </Svg>
);

export const Check = () => (
    <Svg width="40" class="success-icon" height="37" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M33.9972 18.1395C33.9972 17.811 33.9874 17.4847 33.9682 17.161L35.7828 15.3322C35.9238 16.2472 35.997 17.1847 35.997 18.1395C35.997 28.1577 27.9388 36.2791 17.9985 36.2791C8.05821 36.2791 0 28.1577 0 18.1395C0 8.12135 8.05821 0 17.9985 0C22.2043 0 26.0732 1.45388 29.1374 3.89017L27.7121 5.32655C25.0189 3.24954 21.6517 2.0155 17.9985 2.0155C9.16269 2.0155 1.99984 9.23448 1.99984 18.1395C1.99984 27.0446 9.16269 34.2636 17.9985 34.2636C26.8343 34.2636 33.9972 27.0446 33.9972 18.1395Z"
            fill="white"
        />
        <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.8267 27.0363L38.8252 8.89674C39.6816 8.03363 40.0715 6.93968 39.9893 5.84079C39.9112 4.79708 39.4213 3.89009 38.7789 3.24268C38.1366 2.59528 37.2366 2.10156 36.201 2.02288C35.1107 1.94004 34.0252 2.33292 33.1688 3.19604L17.9985 18.4852L12.8274 13.2736C11.2654 11.6994 8.73295 11.6994 7.17098 13.2736C5.60901 14.8478 5.60901 17.4001 7.17098 18.9743L15.1703 27.0363C16.7323 28.6105 19.2647 28.6105 20.8267 27.0363ZM11.4133 14.6987L17.9985 21.3356L34.5829 4.62121C36.4067 2.78317 39.2349 5.63353 37.4111 7.47157L19.4126 25.6111C18.6316 26.3982 17.3654 26.3982 16.5844 25.6111L8.58508 17.5491C7.8041 16.762 7.8041 15.4858 8.58508 14.6987C9.36606 13.9116 10.6323 13.9116 11.4133 14.6987Z"
            fill="white"
        />
    </Svg>
);

export const Alert = () => (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Circle cx="16" cy="16" r="15.175" stroke="#E31D19" fill="#FCE9E7" stroke-width="2" />
        <Path
            d="M22.8619 8.19526C23.1223 7.93491 23.5444 7.93491 23.8047 8.19526C24.0651 8.45561 24.0651 8.87772 23.8047 9.13807L16.9428 16L23.8047 22.8619C24.0651 23.1223 24.0651 23.5444 23.8047 23.8047C23.5444 24.0651 23.1223 24.0651 22.8619 23.8047L16 16.9428L9.13807 23.8047C8.87772 24.0651 8.45561 24.0651 8.19526 23.8047C7.93491 23.5444 7.93491 23.1223 8.19526 22.8619L15.0572 16L8.19526 9.13807C7.93491 8.87772 7.93491 8.45561 8.19526 8.19526C8.45561 7.93491 8.87772 7.93491 9.13807 8.19526L16 15.0572L22.8619 8.19526Z"
            fill="#E31D19"
        />
    </Svg>
);

export const Cross = () => (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Circle cx="16" cy="16" r="15.175" stroke="#E31D19" fill="#FCE9E7" stroke-width="2" />
        <Path
            d="M22.8619 8.19526C23.1223 7.93491 23.5444 7.93491 23.8047 8.19526C24.0651 8.45561 24.0651 8.87772 23.8047 9.13807L16.9428 16L23.8047 22.8619C24.0651 23.1223 24.0651 23.5444 23.8047 23.8047C23.5444 24.0651 23.1223 24.0651 22.8619 23.8047L16 16.9428L9.13807 23.8047C8.87772 24.0651 8.45561 24.0651 8.19526 23.8047C7.93491 23.5444 7.93491 23.1223 8.19526 22.8619L15.0572 16L8.19526 9.13807C7.93491 8.87772 7.93491 8.45561 8.19526 8.19526C8.45561 7.93491 8.87772 7.93491 9.13807 8.19526L16 15.0572L22.8619 8.19526Z"
            fill="#E31D19"
        />
    </Svg>
);

export const Draft = () => {
    const styles = StyleSheet.create({
        cls1: {
            fill: "#f8e17e",
            stroke: "#44377b",
            strokeWidth: "2",
            strokeMiterlimit: "10",
        },
        cls2: {
            stroke: "#44377b",
            strokeWidth: "2",
            strokeMiterlimit: "10",
            fill: "none",
        },
        cls3: {
            stroke: "#44377b",
            strokeWidth: "2",
            strokeMiterlimit: "10",
            fill: "#fff",
            strokeLinejoin: "round",
        },
        cls4: {
            stroke: "#44377b",
            strokeWidth: "2",
            fill: "none",
        },
    });

    return (
        <Svg height="45" xmlns="http://www.w3.org/2000/svg" class="doc-icon" viewBox="0 0 42 52">
            <G id="Group_2287" data-name="Group 2287" transform="translate(-1089 -484)">
                <Path
                    id="Path_1542"
                    data-name="Path 1542"
                    style={styles.cls1}
                    d="M33.727,1,41,7.9V51H1V1Z"
                    transform="translate(1089 484)"
                />
                <Line
                    id="Line_364"
                    data-name="Line 364"
                    style={styles.cls2}
                    x2="30"
                    x1="0"
                    y1="0"
                    y2="0"
                    transform="translate(1095 505)"
                />
                <Line
                    id="Line_365"
                    data-name="Line 365"
                    style={styles.cls2}
                    x2="30"
                    x1="0"
                    y1="0"
                    y2="0"
                    transform="translate(1095 509)"
                />
                <Line
                    id="Line_366"
                    data-name="Line 366"
                    style={styles.cls2}
                    x2="30"
                    x1="0"
                    y1="0"
                    y2="0"
                    transform="translate(1095 513)"
                />
                <Line
                    id="Line_367"
                    data-name="Line 367"
                    style={styles.cls2}
                    x2="14"
                    x1="0"
                    y1="0"
                    y2="0"
                    transform="translate(1095 517)"
                />
                <Rect
                    id="Rectangle_1685"
                    data-name="Rectangle 1685"
                    style={styles.cls3}
                    width="15"
                    height="6"
                    transform="translate(1096 493)"
                />
                <Path
                    id="Path_1543"
                    data-name="Path 1543"
                    style={styles.cls4}
                    d="M-1232.719,6769.2v8h8"
                    transform="translate(2354.719 -6284.197)"
                />
            </G>
        </Svg>
    );
};

export const Process = () => {
    const styles = StyleSheet.create({
        cls1: {
            fill: "#94caee",
            stroke: "#44377b",
            strokeMiterlimit: "10",
            strokeWidth: "2",
        },
        cls2: {
            stroke: "#44377b",
            strokeMiterlimit: "10",
            strokeWidth: "2",
            fill: "none",
            strokeLinejoin: "round",
        },
        cls3: {
            stroke: "#44377b",
            strokeMiterlimit: "10",
            strokeWidth: "2",
            strokeLinejoin: "round",
            fill: "#a2a7a7",
        },
        cls4: {
            stroke: "#44377b",
            strokeMiterlimit: "10",
            strokeWidth: "2",
            strokeLinejoin: "round",
            fill: "#f8e17e",
        },
    });

    return (
        <Svg height="45" xmlns="http://www.w3.org/2000/svg" class="setup-icon" viewBox="0 0 55 55">
            <G id="Group_2296" data-name="Group 2296" transform="translate(-882.5 -482.5)">
                <Path
                    id="Path_1535"
                    data-name="Path 1535"
                    style={styles.cls1}
                    d="M13.788,15H48.212A2.764,2.764,0,0,1,51,17.736V52.264A2.764,2.764,0,0,1,48.212,55H13.788A2.764,2.764,0,0,1,11,52.264V17.736A2.764,2.764,0,0,1,13.788,15Z"
                    transform="translate(879 475)"
                />
                <Line
                    id="Line_348"
                    data-name="Line 348"
                    style={styles.cls2}
                    x2="10"
                    x1="0"
                    y1="0"
                    y2="0"
                    transform="translate(894 495)"
                />
                <Path
                    id="Path_1537"
                    data-name="Path 1537"
                    style={styles.cls3}
                    d="M54.412,7.353H49.647V2.588L45.676,1,42.5,4.971,39.324,1,35.353,2.588V7.353H30.588L29,11.323,32.971,14.5,29,17.676l1.588,3.971h4.765v4.765L39.324,28,42.5,24.029,45.676,28l3.971-1.588V21.647h4.765L56,17.676,52.029,14.5,56,11.323Z"
                    transform="translate(854.5 508.5)"
                />
                <Path
                    id="Path_1538"
                    data-name="Path 1538"
                    style={styles.cls3}
                    d="M54.412,7.353H49.647V2.588L45.676,1,42.5,4.971,39.324,1,35.353,2.588V7.353H30.588L29,11.323,32.971,14.5,29,17.676l1.588,3.971h4.765v4.765L39.324,28,42.5,24.029,45.676,28l3.971-1.588V21.647h4.765L56,17.676,52.029,14.5,56,11.323Z"
                    transform="translate(880.5 482.5)"
                />
                <Circle
                    id="Ellipse_444"
                    data-name="Ellipse 444"
                    style={styles.cls4}
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(892 518)"
                />
                <Circle
                    id="Ellipse_445"
                    data-name="Ellipse 445"
                    style={styles.cls4}
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(918 492)"
                />
                <Line
                    id="Line_349"
                    data-name="Line 349"
                    style={styles.cls2}
                    x2="2"
                    x1="0"
                    y1="0"
                    y2="0"
                    transform="translate(894 499)"
                />
                <Line
                    id="Line_350"
                    data-name="Line 350"
                    style={styles.cls2}
                    x2="2"
                    x1="0"
                    y1="0"
                    y2="0"
                    transform="translate(898 499)"
                />
                <Line
                    id="Line_354"
                    data-name="Line 354"
                    style={styles.cls2}
                    x2="2"
                    x1="0"
                    y1="0"
                    y2="0"
                    transform="translate(896 523)"
                />
                <Line
                    id="Line_355"
                    data-name="Line 355"
                    style={styles.cls2}
                    x2="2"
                    x1="0"
                    y1="0"
                    y2="0"
                    transform="translate(922 497)"
                />
                <Line
                    id="Line_351"
                    data-name="Line 351"
                    style={styles.cls2}
                    x2="10"
                    x1="0"
                    y1="0"
                    y2="0"
                    transform="translate(916 525)"
                />
                <Line
                    id="Line_352"
                    data-name="Line 352"
                    style={styles.cls2}
                    x2="2"
                    x1="0"
                    y1="0"
                    y2="0"
                    transform="translate(920 521)"
                />
                <Line
                    id="Line_353"
                    data-name="Line 353"
                    style={styles.cls2}
                    x2="2"
                    x1="0"
                    y1="0"
                    y2="0"
                    transform="translate(924 521)"
                />
            </G>
        </Svg>
    );
};

export const Schedule = () => {
    const styles = StyleSheet.create({
        cls1: {
            fill: "#f8e17e",
            stroke: "#44377b",
            strokeWidth: "2",
        },
        cls2: {
            stroke: "#44377b",
            strokeWidth: "2",
            fill: "#fff",
        },
        cls3: {
            stroke: "#44377b",
            strokeWidth: "2",
            fill: "none",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
        },
        cls4: {
            stroke: "#44377b",
            strokeWidth: "2",
            strokeLinejoin: "round",
            strokeMiterlimit: "10",
            fill: "#8bd6ad",
        },
        cls5: {
            stroke: "none",
        },
        cls6: {
            fill: "none",
        },
    });

    return (
        <Svg height="45" xmlns="http://www.w3.org/2000/svg" class="agenda-icon" viewBox="0 0 54 55">
            <G id="Group_2297" data-name="Group 2297" transform="translate(-786 -485)">
                <Path
                    id="Path_1533"
                    data-name="Path 1533"
                    style={styles.cls1}
                    d="M5-3.874H51V40.052a3.006,3.006,0,0,1-2.934,3.074H7.934A3.006,3.006,0,0,1,5,40.052Z"
                    transform="translate(782 490)"
                />
                <G id="Rectangle_1666" data-name="Rectangle 1666" style={styles.cls2} transform="translate(786 485)">
                    <Rect style={styles.cls5} width="48" height="10" />
                    <Rect style={styles.cls6} x="1" y="1" width="46" height="8" />
                </G>
                <Line
                    id="Line_345"
                    data-name="Line 345"
                    style={styles.cls3}
                    x1="2"
                    x2="0"
                    y1="0"
                    y2="0"
                    transform="translate(793 502.065)"
                />
                <Line
                    id="Line_346"
                    data-name="Line 346"
                    style={styles.cls3}
                    x2="38"
                    x1="0"
                    y1="0"
                    y2="0"
                    transform="translate(791 490)"
                />
                <G id="Rectangle_1669" data-name="Rectangle 1669" style={styles.cls2} transform="translate(807 499)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <G id="Rectangle_1670" data-name="Rectangle 1670" style={styles.cls2} transform="translate(815 499)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <G id="Rectangle_1671" data-name="Rectangle 1671" style={styles.cls2} transform="translate(823 499)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <G id="Rectangle_1672" data-name="Rectangle 1672" style={styles.cls2} transform="translate(791 507)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <G id="Rectangle_1673" data-name="Rectangle 1673" style={styles.cls2} transform="translate(799 507)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <G id="Rectangle_1674" data-name="Rectangle 1674" style={styles.cls2} transform="translate(807 507)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <G id="Rectangle_1675" data-name="Rectangle 1675" style={styles.cls2} transform="translate(815 507)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <G id="Rectangle_1676" data-name="Rectangle 1676" style={styles.cls2} transform="translate(823 507)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <G id="Rectangle_1677" data-name="Rectangle 1677" style={styles.cls2} transform="translate(791 515)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <G id="Rectangle_1678" data-name="Rectangle 1678" style={styles.cls2} transform="translate(799 515)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <G id="Rectangle_1679" data-name="Rectangle 1679" style={styles.cls2} transform="translate(807 515)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <G id="Rectangle_1682" data-name="Rectangle 1682" style={styles.cls2} transform="translate(791 523)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <G id="Rectangle_1683" data-name="Rectangle 1683" style={styles.cls2} transform="translate(799 523)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <G id="Rectangle_1684" data-name="Rectangle 1684" style={styles.cls2} transform="translate(807 523)">
                    <Rect style={styles.cls5} width="6" height="6" />
                    <Rect style={styles.cls6} x="1" y="1" width="4" height="4" />
                </G>
                <Circle
                    id="Ellipse_441"
                    data-name="Ellipse 441"
                    style={styles.cls4}
                    cx="13.645"
                    cy="13.645"
                    r="13.645"
                    transform="translate(811.71 511.71)"
                />
                <Path
                    id="Path_1532"
                    data-name="Path 1532"
                    style={styles.cls3}
                    d="M53.839,45l-7.226,7.226L43,48.613"
                    transform="translate(776.935 476.742)"
                />
                <Line
                    id="Line_347"
                    data-name="Line 347"
                    style={styles.cls3}
                    x1="2"
                    x2="0"
                    y1="0"
                    y2="0"
                    transform="translate(801 502.064)"
                />
            </G>
        </Svg>
    );
};

export const Sign = () => {
    const styles = StyleSheet.create({
        cls1: {
            fill: "#f8e17e",
            stroke: "#44377b",
            strokeWidth: "2",
            strokeMiterlimit: "10",
        },
        cls2: {
            stroke: "#44377b",
            strokeWidth: "2",
            fill: "none",
        },
        cls3: {
            stroke: "#44377b",
            strokeWidth: "2",
            strokeMiterlimit: "10",
            fill: "#94caee",
            strokeLinejoin: "round",
        },
        cls4: {
            stroke: "#44377b",
            strokeWidth: "2",
            strokeMiterlimit: "10",
            fill: "none",
            strokeLinejoin: "round",
        },
        cls5: {
            stroke: "#44377b",
            strokeWidth: "2",
            strokeMiterlimit: "10",
            strokeLinejoin: "round",
            fill: "#c9d1d2",
        },
        cls6: {
            stroke: "#44377b",
            strokeWidth: "2",
            fill: "#8bd6ad",
        },
        cls7: {
            stroke: "#44377b",
            strokeWidth: "2",
            strokeMiterlimit: "10",
            strokeLinejoin: "round",
            fill: "#fff",
        },
        cls8: {
            stroke: "none",
        },
        cls9: {
            fill: "none",
        },
    });

    return (
        <Svg height="45" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 62">
            <G id="Group_2313" data-name="Group 2313" transform="translate(-1279 -369)">
                <G id="Group_2312" data-name="Group 2312" transform="translate(190 -110)">
                    <Path
                        id="Path_1542"
                        data-name="Path 1542"
                        style={styles.cls1}
                        d="M33.574.973,41,7.9V50H1V1Z"
                        transform="translate(1089 484)"
                    />
                    <Path
                        id="Path_1543"
                        data-name="Path 1543"
                        style={styles.cls2}
                        d="M-1232.719,6769.2v8h8"
                        transform="translate(2354.719 -6284.197)"
                    />
                </G>
                <Path
                    id="Path_1582"
                    data-name="Path 1582"
                    style={styles.cls3}
                    d="M11,6H5L4.993,55.532,8,61l2.993-5.468Z"
                    transform="translate(1285 369)"
                />
                <Path
                    id="Path_1583"
                    data-name="Path 1583"
                    style={styles.cls4}
                    d="M1,29V6H11"
                    transform="translate(1285 369)"
                />
                <Path
                    id="Path_1584"
                    data-name="Path 1584"
                    style={styles.cls5}
                    d="M4.859,54.969,8,61l2.859-6.031Z"
                    transform="translate(1285 369)"
                />
                <G id="Rectangle_1691" data-name="Rectangle 1691" style={styles.cls6} transform="translate(1289 407)">
                    <Rect style={styles.cls8} width="8" height="18" />
                    <Rect style={styles.cls9} x="1" y="1" width="6" height="16" />
                </G>
                <Path
                    id="Path_1585"
                    data-name="Path 1585"
                    style={styles.cls7}
                    d="M11,4A3,3,0,0,0,5,4V6h6Z"
                    transform="translate(1285 369)"
                />
            </G>
        </Svg>
    );
};
