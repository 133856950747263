import React from "react";
import Box from "pages/_components/Box";
import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { isDesktop } from "react-device-detect";
import { isMobileNative } from "util/device";
import ErrorContent from "./_components/ErrorContent";
import LoginWrapper from "../login/_components/LoginWrapper";

const Error = () => (
    <>
        {isDesktop ? (
            <>
                <Notification scopeToShow="recoveryUser" />
                <BoxErrorNotification isMobile={false} scopeToShow="externalLayout" />
                <MainContainer shouldHideOnLoad className="pt-0 max-width-full">
                    <Box display="flex" position="relative" zIndex="9" className="login-background">
                        <LoginWrapper transition="transition-drill-in">
                            <ErrorContent isDesktop={isDesktop} />
                        </LoginWrapper>
                    </Box>
                </MainContainer>
            </>
        ) : (
            <>
                <Box display="flex" column alignX="between" className="scroll" fullHeight>
                    <Head position="relative" {...isMobileNative} />
                    <Box
                        display="flex"
                        alignX="flex-end"
                        component="main"
                        position="relative"
                        zIndex="9"
                        className="login-background mt-auto">
                        <Box display="block" position="absolute" top="n-12" fullWidth>
                            <BoxErrorNotification isMobile scopeToShow="externalLayout" />
                        </Box>
                        <LoginWrapper>
                            <ErrorContent isDesktop={isDesktop} />
                        </LoginWrapper>
                    </Box>
                </Box>
            </>
        )}
    </>
);

export default Error;
