import classNames from "classnames";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { func, string } from "prop-types";
import React from "react";

const FastRechargeSlide = ({ id, title, label, serialNumber, quantity, currency, className, onClick }) => (
    <button type="button" onClick={onClick} className="full-width">
        <Box
            id={id}
            display="flex"
            alignY="center"
            alignX="between"
            className={classNames("fast-recharge-slide-wrapper", className)}
            gap="3">
            <Box display="flex" column>
                <Box className="d-grid">
                    <Text color="text-inverse-color" size="6" align="left" ellipsis>
                        {title}
                    </Text>
                </Box>
                <Box display="flex">
                    {label && label !== "" && (
                        <>
                            <Box className="d-grid">
                                <Text color="text-inverse-color" size="6" align="left" bold ellipsis>
                                    {label}
                                </Text>
                            </Box>
                            <Text color="text-inverse-color" size="6" align="left" bold className="px-2">
                                |
                            </Text>
                        </>
                    )}
                    <Text color="text-inverse-color" size="6" align="left">
                        {serialNumber}
                    </Text>
                </Box>
            </Box>
            <Box display="flex" alignX="flex-end" className="mr-2">
                <FormattedAmount
                    quantity={quantity}
                    currency={currency}
                    color="text-inverse-color"
                    size="6"
                    bold
                    align="right"
                />
                <Box display="flex">
                    <Image className="fast-recharge-slide-chevron" src="images/selectArrowDown.svg" ariaHiden />
                </Box>
            </Box>
        </Box>
    </button>
);

FastRechargeSlide.propTypes = {
    className: string,
    currency: string,
    id: string,
    label: string,
    onClick: func,
    quantity: string,
    serialNumber: string,
    title: string,
};

FastRechargeSlide.defaultProps = {
    className: null,
    currency: null,
    id: null,
    label: null,
    onClick: null,
    quantity: null,
    serialNumber: null,
    title: null,
};

export default FastRechargeSlide;
