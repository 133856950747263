import React from "react";
import { bool } from "prop-types";
import { MainContainerContext } from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import CustomDatePicker from "pages/_components/fields/datepicker/CustomDatePicker";

class DatePickerWithCustomInput extends React.Component {
    startRef = React.createRef();

    static propTypes = {
        isMobile: bool.isRequired,
    };

    onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            this.startRef.current.setOpen(false);
        }
    };

    render() {
        const { isMobile } = this.props;

        if (isMobile) {
            return (
                <MainContainerContext.Consumer>
                    {(ref) => (
                        <CustomDatePicker
                            {...this.props}
                            onKeyDown={(e) => e.preventDefault()}
                            isMobile={isMobile}
                            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                            popperPlacement="bottom-start"
                            popperModifiers={{
                                flip: {
                                    behavior: ["bottom-start"], // don't allow it to flip to be above
                                },
                            }}
                            viewContentRef={ref}
                        />
                    )}
                </MainContainerContext.Consumer>
            );
        }

        return (
            <CustomDatePicker
                {...this.props}
                isMobile={isMobile}
                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                autoComplete="off"
                ref={this.startRef}
                onKeyDown={this.onKeyDown}
                popperPlacement="bottom-start"
                popperModifiers={{
                    flip: {
                        behavior: ["bottom-start"], // don't allow it to flip to be above
                    },
                }}
            />
        );
    }
}

export default resizableRoute(DatePickerWithCustomInput);
