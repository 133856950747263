/* eslint-disable */
export const hasPermission = () =>
    new Promise((resolve, reject) => {
        try {
            window.ContactsX.hasPermission(
                (success) => {
                    resolve(success);
                },
                (error) => {
                    resolve(error);
                },
            );
        } catch (e) {
            reject();
        }
    });

export const findContact = (query) =>
    new Promise((resolve, reject) => {
        try {
            window.ContactsX.find(
                (success) => {
                    resolve(success);
                },
                (error) => {
                    resolve(error);
                },
                { ...query },
            );
        } catch (e) {
            reject();
        }
    });

export const requestPermission = () =>
    new Promise((resolve, reject) => {
        try {
            window.ContactsX.requestPermission(
                (success) => {
                    resolve(success);
                },
                (error) => {
                    resolve(error);
                },
            );
        } catch (e) {
            reject();
        }
    });

export const requestWritePermission = () =>
    new Promise((resolve, reject) => {
        try {
            window.ContactsX.requestWritePermission(
                (success) => {
                    resolve(success);
                },
                (error) => {
                    resolve(error);
                },
            );
        } catch (e) {
            reject();
        }
    });

/**
 * 
 * @param {Object} contact - Contact will be save, the contact have to have this format:
 * {
    firstName: "Adrian",
    familyName: "Anguisaca",
    organizationName : "Technisys",
    phoneNumbers: [{
      type: "mobile",
      value: "0999999999"
    }]
  },
 * @returns Promise
 */
export const saveContact = (contact) =>
    new Promise((resolve, reject) => {
        try {
            window.ContactsX.save(
                { ...contact },
                (success) => {
                    resolve(success);
                },
                (error) => {
                    resolve(error);
                },
            );
        } catch (e) {
            reject();
        }
    });

export const getContactDisplayName = (contact) => contact?.displayName || (`${contact?.firstName ? `${contact?.firstName } `  : ""}${contact?.middleName ? `${contact?.middleName } `  : ""}${contact?.familyName ? `${contact?.familyName} `  : ""}` );

