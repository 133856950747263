import { createReducer, makeActionCreator } from "util/redux";

export const fixedTermDepositTypes = {
    CALCULATE_RATE_REQUEST: "fixedTermDeposit/CALCULATE_RATE_REQUEST",
    CALCULATE_RATE_SUCCESS: "fixedTermDeposit/CALCULATE_RATE_SUCCESS",
    CALCULATE_RATE_FAILURE: "fixedTermDeposit/CALCULATE_RATE_FAILURE",
    CLEAR_CALCULATE_RATE: "fixedTermDeposit/CLEAR_CALCULATE_RATE",

    DEPOSITE_TYPE_METADATA_REQUEST: "fixedTermDeposit/DEPOSITE_TYPE_METADATA_REQUEST",
    DEPOSITE_TYPE_METADATA_FAILURE: "fixedTermDeposit/DEPOSITE_TYPE_METADATA_FAILURE",
    DEPOSITE_TYPE_METADATA_SUCCESS: "fixedTermDeposit/DEPOSITE_TYPE_METADATA_SUCCESS",
    DEPOSITE_TYPE_METADATA_CLEAR: "fixedTermDeposit/DEPOSITE_TYPE_METADATA_CLEAR",
};

export const INITIAL_STATE = {
    rate: undefined,
    fetchingRate: false,
    depositTypeMetadata: undefined,
    fetchingDepositTypeMetadata: false,
    depositTypeMetadataMap: new Map(),
};

export const actions = {
    calculateRateRequest: makeActionCreator(fixedTermDepositTypes.CALCULATE_RATE_REQUEST, "dataCalculateRate"),
    clearCalculateRateRequest: makeActionCreator(fixedTermDepositTypes.CLEAR_CALCULATE_RATE),
    depositTypeMetadataRequest: makeActionCreator(
        fixedTermDepositTypes.DEPOSITE_TYPE_METADATA_REQUEST,
        "dataDepositTypeMetadata",
    ),
};

export const selectors = {
    getCalculateRate: ({ fixedTermDepositReducer }) => fixedTermDepositReducer.rate,
    isFetchingRate: ({ fixedTermDepositReducer }) => fixedTermDepositReducer.fetchingRate,
    isFetchingDepositTypeMetadata: ({ fixedTermDepositReducer }) => fixedTermDepositReducer.fetchingDepositTypeMetadata,
    getDepositTypeMetadataMap: ({ fixedTermDepositReducer }) => fixedTermDepositReducer.depositTypeMetadataMap,
};

export default createReducer(INITIAL_STATE, {
    [fixedTermDepositTypes.CALCULATE_RATE_REQUEST]: (state) => ({
        ...state,
        fetchingRate: true,
    }),

    [fixedTermDepositTypes.CALCULATE_RATE_SUCCESS]: (state, action) => ({
        ...state,
        rate: action?.rate,
        fetchingRate: false,
    }),
    [fixedTermDepositTypes.CALCULATE_RATE_FAILURE]: (state) => ({
        ...state,
        rate: undefined,
        fetchingRate: false,
    }),
    [fixedTermDepositTypes.CLEAR_CALCULATE_RATE]: (state) => ({
        ...state,
        rate: undefined,
        fetchingRate: false,
    }),

    [fixedTermDepositTypes.DEPOSITE_TYPE_METADATA_REQUEST]: (state) => ({
        ...state,
        fetchingDepositTypeMetadata: true,
    }),

    [fixedTermDepositTypes.DEPOSITE_TYPE_METADATA_SUCCESS]: (state, action) => {
        const currentDepositTypeMetadataMap = state.depositTypeMetadataMap;
        if (action?.currency && action?.productName) {
            currentDepositTypeMetadataMap.set(
                `${action.currency.toLowerCase()}-${action.productName.toLowerCase()}`,
                action?.depositTypeMetadata,
            );
        }

        return {
            ...state,
            depositTypeMetadata: action?.depositTypeMetadata,
            fetchingDepositTypeMetadata: false,
            depositTypeMetadataMap: currentDepositTypeMetadataMap,
        };
    },
    [fixedTermDepositTypes.DEPOSITE_TYPE_METADATA_FAILURE]: (state) => ({
        ...state,
        depositTypeMetadata: undefined,
        fetchingDepositTypeMetadata: false,
    }),
});
