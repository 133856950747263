import React from "react";
import { node, func, number } from "prop-types";
import Tabs from "pages/_components/Tabs";

const LoanTabs = ({ children, onSelect, selectedIndex }) => {
    return (
        <Tabs className="pt-0" components={children} onSelect={onSelect} selectedIndex={selectedIndex}/>
    );
};

LoanTabs.propsTypes = {
    children: node.isRequired,
    onSelect: func.isRequired,
    selectedIndex: number,
};

export default LoanTabs;