import classNames from "classnames";
import InternationalTransferTrackingModal from "pages/InternationalTransferTracking/InternationalTransferTrackingModal";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import { arrayOf, bool, func, number, shape } from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import * as i18n from "util/i18n";
import {
    selectors as transferSupportSelectors,
    actions as transferSupportActions,
} from "reducers/transferSupport/transferSupport.reducer";
import {
    TRANSFER_SUPPORT_AMENDMENT_TYPE,
    TRANSFER_SUPPORT_CONFIRM_TYPE,
    TRANSFER_SUPPORT_SUPPORT_TYPE,
} from "util/transferSupport.util";
import { actions as notificationActions } from "reducers/notification";
import LoadingModal from "pages/_components/modal/LoadingModal";
import { selectors as accountsSelectors, actions as accountActions } from "reducers/accounts";
import { replace } from "react-router-redux";

const PAGE_SIZE = 5;
const InternationalTransferTracking = ({ isDesktop, dispatch, transferList, fetching, isTrackingUrlFetch }) => {
    /**
     * Pagination
     */

    const [filterTransferList, setFilterTransferList] = useState([]);
    const [currentTransferList, setCurrentTransferList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const getPaginationData = (currentPageRef) => {
        const offset = currentPageRef * PAGE_SIZE;
        const take = offset + PAGE_SIZE;

        return [offset, take];
    };

    const hasMoreElements = useMemo(() => {
        const pageRef = currentPage + 1;
        let totalPage = Math.floor(filterTransferList.length / PAGE_SIZE);
        if (totalPage > 0) {
            totalPage += filterTransferList.length % PAGE_SIZE;
        }
        return pageRef < totalPage;
    }, [currentPage, filterTransferList]);

    const initPagination = () => {
        if (!transferList || transferList.length === 0) {
            return;
        }

        setFilterTransferList([...transferList]);
        const [offset, take] = getPaginationData(currentPage);
        setCurrentTransferList(transferList.slice(offset, take));
    };

    const nextPage = () => {
        const currentPageRef = currentPage + 1;
        setCurrentPage(currentPageRef);

        const [offset, take] = getPaginationData(currentPageRef);
        const newElementList = filterTransferList.slice(offset, take);

        setCurrentTransferList([...currentTransferList, ...newElementList]);
    };

    const debounce = (funcRef, delay) => {
        let timeoutId;
        return function(...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                funcRef(...args);
            }, delay);
        };
    };
    /**
     * When user search a transfer reference, the filter list is save in filterTransferList and the process continues
     */
    const handleSearch = debounce((searchText) => {
        const filterList =
            searchText && searchText !== ""
                ? [...transferList].filter(
                      (item) =>
                          searchText &&
                          item?.tracking &&
                          item.tracking.toLowerCase().includes(searchText.toLowerCase()),
                  )
                : [...transferList];
        setCurrentPage(0);
        setFilterTransferList([...filterList]);
        const [offset, take] = getPaginationData(0);
        setCurrentTransferList(filterList.slice(offset, take));
    }, 300);

    useEffect(() => {
        initPagination();
    }, [transferList]);

    /**
     * Load data
     */
    useEffect(() => {
        dispatch(accountActions.clearTrackingTransferUrlRequest());
        dispatch(transferSupportActions.getTransferListRequest());
    }, []);

    /**
     * Scroll pagination
     */
    const scrollToRef = useRef(null);
    useEffect(() => {
        if (scrollToRef?.current?.scrollIntoView) {
            scrollToRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [currentTransferList]);

    const renderLoadMore = () => (
        <div className="text-center no-more-data flex" ref={scrollToRef}>
            <Button
                bsStyle="link"
                onClick={nextPage}
                image="images/show.svg"
                label="transactions.list.moreTransactions"
                className="btn-small"
            />
        </div>
    );

    /**
     * Modal support actions
     */
    const [showModal, setShowModal] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [transferSupportData, setTransferSupportData] = useState();
    const [transferSupportType, setTransferSupportType] = useState();

    const createContextOptions = (data) => {
        const newContextOptions = [
            {
                label: "transfers.foreign.support.request.type.refund",
                onClick: (transferItem) => {
                    setShowModal(true);
                    setTransferSupportData(transferItem);
                    setTransferSupportType(TRANSFER_SUPPORT_SUPPORT_TYPE);
                },
            },
        ];

        if (data?.idTransactionStatus === "FINISHED") {
            newContextOptions.push({
                label: "transfers.foreign.support.request.type.ammend",
                onClick: (transferItem) => {
                    setShowModal(true);
                    setShowWarningModal(transferItem?.wasAmmended);
                    setTransferSupportData(transferItem);
                    setTransferSupportType(TRANSFER_SUPPORT_AMENDMENT_TYPE);
                },
            });
            newContextOptions.push({
                label: "transfers.foreign.support.request.type.confirmation",
                onClick: (transferItem) => {
                    setShowModal(true);
                    setShowWarningModal(transferItem?.wasConfirmed);
                    setTransferSupportData(transferItem);
                    setTransferSupportType(TRANSFER_SUPPORT_CONFIRM_TYPE);
                },
            });
        }

        return newContextOptions;
    };

    const handleBack = () => {
        dispatch(replace("/desktop"));
    };

    const getColumnsTemplate = () => (isDesktop ? "1fr 1fr 1fr 1.5fr 1.5fr 3rem" : "1fr 1fr 2rem");

    const handleCloseModal = () => {
        setShowModal(false);
        setTimeout(() => {
            dispatch(transferSupportActions.setShowSupportTransferWarningModal(true));
        }, 800);
    };

    const callbackSuccessRequest = (procedureNUmber) => {
        setShowModal(false);
        setTimeout(() => {
            dispatch(transferSupportActions.setShowSupportTransferWarningModal(true));
            dispatch(
                notificationActions.showNotification(
                    `${i18n.get("transfers.foreign.support.request.success.message")} ${procedureNUmber || ""}`,
                    "success",
                    ["InternationalTransferTracking"],
                    false,
                ),
            );
        }, 800);
    };

    const callbackErrorRequest = () => {
        setShowModal(false);
        dispatch(transferSupportActions.setShowSupportTransferWarningModal(true));
    };

    const renderContentTable = () => (
        <GridTable.Body>
            {currentTransferList?.length > 0 &&
                currentTransferList.map((data) => {
                    const contextOptions = createContextOptions(data);
                    return (
                        <GridTable.Container
                            className={classNames({ "gapX-3": isDesktop })}
                            padding={isDesktop ? "py-2 px-5" : "py-2 pl-5 pr-3"}
                            {...(!isDesktop && { row: 2 })}
                            columnsTemplate={getColumnsTemplate()}>
                            <GridTable.Data columnStart={1} alignX="flex-start">
                                {isDesktop ? (
                                    <FormattedDate date={data.date} anotherFormat="DD/MM/YYYY" />
                                ) : (
                                    <Box display="flex" column withEllipsis>
                                        <Text color="secondary" size="5" bold ellipsis>
                                            {data.recipient}
                                        </Text>
                                        <FormattedDate date={data.date} anotherFormat="DD/MM/YYYY" size="5" />
                                    </Box>
                                )}
                            </GridTable.Data>
                            <GridTable.Data columnStart={2} alignX={isDesktop ? "flex-start" : "flex-end"}>
                                {isDesktop ? (
                                    <Text ellipsis>{data.recipient}</Text>
                                ) : (
                                    <Box display="flex" alignX="flex-end" column withEllipsis>
                                        <FormattedAmount
                                            quantity={data.amount}
                                            currency={data.currency}
                                            className="credit-card-value"
                                            size="5"
                                        />
                                        <Text size="5" ellipsis>
                                            {data.tracking}
                                        </Text>
                                    </Box>
                                )}
                            </GridTable.Data>

                            {isDesktop && (
                                <>
                                    <GridTable.Data columnStart={3} alignX="flex-end">
                                        <FormattedAmount
                                            quantity={data.amount}
                                            currency={data.currency}
                                            className="credit-card-value"
                                            size="6"
                                        />
                                    </GridTable.Data>
                                    <GridTable.Data columnStart={4} alignX="center">
                                        <Text ellipsis align="center">
                                            {data.tracking}
                                        </Text>
                                    </GridTable.Data>
                                </>
                            )}
                            <GridTable.Data
                                columnStart={isDesktop ? 5 : 1}
                                {...(!isDesktop && { rowStart: 2, columnWidth: 2 })}
                                alignX={isDesktop ? "center" : "flex-end"}>
                                <Button
                                    bsStyle="link"
                                    {...(!isDesktop && { className: "px-0" })}
                                    label="transfers.foreign.support.list.header.follow"
                                    image="images/icons/markerLocation.svg"
                                    onClick={() => {
                                        dispatch(
                                            accountActions.getTrackingTransferUrlRequest(
                                                data?.debitAccount,
                                                data?.tracking,
                                            ),
                                        );
                                    }}
                                />
                            </GridTable.Data>

                            {contextOptions?.length > 0 && (
                                <GridTable.Data columnStart={isDesktop ? 6 : 3} alignX="center" overflow="initial">
                                    <Dropdown
                                        image="images/contextualMenu.svg"
                                        buttonClass="p-2 mx-2 icon-big heading-color"
                                        bsStyle="only-icon"
                                        ariaLabel="global.contextMenu.a11y"
                                        pullLeft
                                        maxContentWidth>
                                        {contextOptions?.map((item) => (
                                            <Button
                                                key={item.label}
                                                label={item.label}
                                                ariaLabel={item.label}
                                                className="dropdown__item-btn"
                                                bsStyle="link"
                                                onClick={(e) => {
                                                    if (item.onClick) {
                                                        e.preventDefault();
                                                        item.onClick(data);
                                                    }
                                                }}
                                            />
                                        ))}
                                    </Dropdown>
                                </GridTable.Data>
                            )}
                        </GridTable.Container>
                    );
                })}
            {currentTransferList.length === 0 && (
                <Box
                    display="flex"
                    fullWidth
                    alignX="center"
                    className={classNames("no-more-data ", {
                        "min-height-8rem": currentTransferList.length === 0,
                    })}>
                    <Text
                        labelKey="transfers.foreign.support.list.empty"
                        component="p"
                        light
                        align="center"
                        size={isDesktop ? "6" : "5"}
                        color="text"
                    />
                </Box>
            )}
            {hasMoreElements && renderLoadMore()}
        </GridTable.Body>
    );

    return (
        <>
            <Notification scopeToShow="InternationalTransferTracking" />
            <Head onBack={handleBack} title="transfers.foreign.support.list.title" />
            <MainContainer showLoader={fetching}>
                <Box
                    borderRadius="default"
                    background="component-background"
                    className="mx-n-5 pt-7 pt-md-8 mt-3 mt-md-0 mb-3 mb-md-0">
                    <Row className="mb-7 mb-md-8">
                        <Col xs={12} md={6}>
                            <Box
                                display="flex"
                                alignX="flex-start"
                                gap={isDesktop ? "7" : "5"}
                                {...(!isDesktop && { column: true })}
                                className="px-5">
                                <TextSimpleField
                                    name="search"
                                    placeholder={i18n.get("transfer.foreign.tracking.searcher.placeholder")}
                                    value=""
                                    onChange={(event) => {
                                        const { value } = event?.target || {};
                                        handleSearch(value);
                                    }}
                                    pattern="^[a-zA-Z0-9ñ@+._-]*$"
                                    serarchStyle
                                />
                            </Box>
                        </Col>
                    </Row>
                    <Box className="min-height-10rem">
                        <GridTable>
                            <GridTable.Header>
                                <GridTable.Container
                                    className={classNames({ "gapX-3": isDesktop })}
                                    padding={isDesktop ? "py-2 px-5" : "py-2 pl-5 pr-3"}
                                    columnsTemplate={getColumnsTemplate()}>
                                    <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                                        <Text
                                            labelKey={
                                                isDesktop
                                                    ? "transfers.foreign.support.list.header.date.desktop"
                                                    : "transfers.foreign.support.list.header.date.mobile"
                                            }
                                        />
                                    </GridTable.Data>
                                    <GridTable.Data
                                        columnStart={2}
                                        alignX={isDesktop ? "flex-start" : "flex-end"}
                                        inHeader>
                                        <Text
                                            labelKey={
                                                isDesktop
                                                    ? "transfers.foreign.support.list.header.beneficiary.desktop"
                                                    : "transfers.foreign.support.list.header.beneficiary.mobile"
                                            }
                                        />
                                    </GridTable.Data>
                                    {isDesktop && (
                                        <>
                                            <GridTable.Data columnStart={3} alignX="flex-end" inHeader>
                                                <Text labelKey="transfers.foreign.support.list.header.amount" />
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={4} alignX="center" inHeader>
                                                <Text labelKey="transfers.foreign.voucher.list.header.tracking" />
                                            </GridTable.Data>
                                        </>
                                    )}
                                </GridTable.Container>
                            </GridTable.Header>
                            {renderContentTable()}
                        </GridTable>
                    </Box>
                </Box>
            </MainContainer>
            {transferSupportData && transferSupportType && (
                <InternationalTransferTrackingModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    transferSupportType={transferSupportType}
                    transferSupportData={transferSupportData}
                    callbackSuccess={callbackSuccessRequest}
                    callbackError={callbackErrorRequest}
                    scopeError={["InternationalTransferTracking"]}
                    showWarningModal={
                        (transferSupportType === TRANSFER_SUPPORT_CONFIRM_TYPE ||
                            transferSupportType === TRANSFER_SUPPORT_AMENDMENT_TYPE) &&
                        showWarningModal
                    }
                />
            )}
            {isTrackingUrlFetch && isTrackingUrlFetch === true && (
                <div className="modal-container">
                    <LoadingModal
                        showModal={isTrackingUrlFetch}
                        content={i18n.get("transfer.foreign.tracking.getUrl.loading.content")}
                    />
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    transferList: transferSupportSelectors.getTransferList(state),
    fetching: transferSupportSelectors.isFetching(state),
    isTrackingUrlFetch: accountsSelectors.isTrackingUrlFetch(state),
});

InternationalTransferTracking.propTypes = {
    isDesktop: bool.isRequired,
    history: shape({}).isRequired,
    dispatch: func.isRequired,
    totalPages: number.isRequired,
    transferList: arrayOf(shape({})),
    fetching: bool,
    isTrackingUrlFetch: bool,
};

InternationalTransferTracking.defaultProps = {
    transferList: [],
    fetching: false,
    isTrackingUrlFetch: false,
};

export default connect(mapStateToProps)(withRouter(resizableRoute(InternationalTransferTracking)));
