import React from "react";
import { func, node, string, oneOfType } from "prop-types";

class TableRow extends React.Component {
    static propTypes = {
        children: node.isRequired,
        renderAs: oneOfType([func, string]),
    };

    static defaultProps = {
        renderAs: "div",
    };

    render() {
        const { children, renderAs: Component, ...props } = this.props;
        return (
            <Component className="table-row" {...props}>
                {children}
            </Component>
        );
    }
}

export default TableRow;
