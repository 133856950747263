import { call, put, takeLatest } from "redux-saga/effects";
import { routerActions } from "react-router-redux";

import { actions as notificationActions } from "reducers/notification";
import { actions as userActions } from "reducers/administration/users";
import * as form from "middleware/form";
import * as i18n from "util/i18n";
import { credentialsToUnderscoreFormat } from "util/form.js";
import { channelsActions } from "reducers/administration/advanced";
import { types as secondFactorTypes } from "reducers/secondFactor";
import { actions as sessionActions } from "reducers/session";
import { ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE } from "util/responses";

const loadDetailsRequest = (middleware, actions) =>
    function* loadRequest({ id }) {
        const response = yield call(middleware.loadDetailsRequest, id);

        if (response.type === "W") {
            yield put(actions.loadDetailsFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
            );
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.medium.modify.signature.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;

            const userExtendedInfo = {
                idUser: response.data.data.user.idUser,
                status: response.data.data.userEnvStatus,
                signatureLevel: response.data.data.signatureLevel,
                massiveEnabled: response.data.data.hasMassiveEnabled,
                dispatcher: response.data.data.dispatcher,
                isAdmin: response.data.data.isAdmin,
                hasSignature: response.data.data.hasSignature,
            };
            yield put(userActions.loadSingleSuccess(userExtendedInfo));
            yield put(channelsActions.loadChannelsSuccess({ ...response.data.data }));

            yield put(actions.loadDetailsSuccess({ ...response.data.data, credentialGroups }));
            if (actions.loadPermissionsSuccess !== undefined) {
                yield put(actions.loadPermissionsSuccess({ ...response.data.data }));
            }
            if (actions.loadGroupsSuccess !== undefined) {
                yield put(actions.loadGroupsSuccess(response.data.data));
            }
        }
    };

const updateSignatureRequest = (middleware, actions) =>
    function* updateRequest({ data, formikBag }) {
        const { fullName, signatureLevel, secondFactor, ...rest } = data;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(secondFactor);
        const response = yield call(middleware.updateSignatureRequest, {
            ...rest,
            ...credentialsWithUnderscore,
            signatureLevel,
            fullName,
        });
        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);
        if (response.type === "W") {
            const { code, data } = response.data;
            let showNotification = true;
            if (code === "COR020W" && data.secondFactor) {
                yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
                showNotification = false;
            }
            if (code === "API707W") {
                yield put(sessionActions.logoutUserBlocked());
                return;
            }
            if (code === "API708W") {
                yield put(
                    notificationActions.showNotification(i18n.get("secondFactor.credential.otp.expired", "Código OTP expirado, solicite un nuevo código OTP"), "warning", [
                        "administration",
                    ]),
                );
                return;
            }
            const errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");

            setErrors(response.data.data);
            if (showNotification) {
                yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
            }
        } else {
            if (response.data.code && response.data.code === ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE) {
                const message = i18n.get(
                    "administration.users.modifySignature.pending.approval",
                    response.data.message,
                );
                yield put(
                    notificationActions.showNotification(message, "warning", ["administration", "administrationUsers"]),
                );
            } else {
                yield put(
                    notificationActions.showNotification(response.data.message, "success", [
                        "administration",
                        "administrationUsers",
                    ]),
                );
            }
            // if (signatureLevel === "A") {
            //     yield put(
            //         notificationActions.showNotification(
            //             i18n.get("administration.user.signature.enabled.confirmation"),
            //             "success",
            //             ["administration"],
            //         ),
            //     );
            // } else {
            //     yield put(
            //         notificationActions.showNotification(
            //             i18n.get("administration.user.signature.disabled.confirmation"),
            //             "success",
            //             ["administration"],
            //         ),
            //     );
            // }
            yield put(routerActions.goBack());
            yield put(actions.updateChannelsRequestSuccess());
        }
    };

const updateSignaturePreview = (middleware, actions) =>
    function* updatePreview({ data, formikBag }) {
        const { signatureLevel } = data;
        const { setSubmitting } = formikBag;

        setSubmitting(false);
        yield put(actions.updateSignaturePreviewSuccess(signatureLevel));
    };

const sagasCreator = (middleware, types, actions) => [
    takeLatest(types.LOAD_DETAILS_REQUEST, loadDetailsRequest(middleware, actions)),
    takeLatest(types.UPDATE_SIGNATURE_REQUEST, updateSignatureRequest(middleware, actions)),
    takeLatest(types.UPDATE_SIGNATURE_PREVIEW, updateSignaturePreview(middleware, actions)),
];

export default sagasCreator;
