// Dashboard Tour steps Mobile
// eslint-disable-next-line import/prefer-default-export
export const dashboardTourStepsMobile = [
    {
        image: "images/mobileDashboardTour/step1.svg",
        title: "dashboardTour.mobile.title.step1",
        content: "dashboardTour.mobile.text.step1",
    },
    {
        image: "images/mobileDashboardTour/step2.svg",
        title: "dashboardTour.mobile.title.step2",
        content: "dashboardTour.mobile.text.step2",
    },
    {
        image: "images/mobileDashboardTour/step3.svg",
        title: "dashboardTour.mobile.title.step3",
        content: "dashboardTour.mobile.text.step3",
    },
    {
        image: "images/mobileDashboardTour/step4.svg",
        title: "dashboardTour.mobile.title.step4",
        content: "dashboardTour.mobile.text.step4",
    },
    {
        image: "images/mobileDashboardTour/step5.svg",
        content: "dashboardTour.mobile.text.step5",
    },
];
