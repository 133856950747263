import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { store } from "store";

import "styles/main.scss";

import App from "App";

// eslint-disable-next-line no-unused-vars
import revelock from "util/revelock";

/* eslint-disable react/jsx-filename-extension */
window.startApp = () => {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById("root"),
    );
};
/* eslint-enable react/jsx-filename-extension */

// Phonegap app start in ondeviceready inside index.js
if (!window.cordova) {
    window.startApp();
}
