import MainContainer from 'pages/_components/MainContainer';
import { node } from 'prop-types';
import React from 'react'
import { HeaderInfo } from './Components/HeaderInfo';

export const SidebarMobileLayout = ({children}) => {
  return (
    <div className=" login-background">  
        <div className="login-background-wrapper background-and-logo">
            <HeaderInfo />
            <MainContainer
                className="main-container max-width-full container-modal-info-background background-white rounded-border-top">
                    {children}
            </MainContainer>
        </div>
    </div>
  )
}
SidebarMobileLayout.propTypes = {
    children: node.isRequired,
};
