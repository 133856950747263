import React, { Component } from "react";
import { node } from "prop-types";

class TableBody extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    render() {
        const { children } = this.props;
        return <div className="table-body">{children}</div>;
    }
}

export default TableBody;
