import { takeLatest, put, call } from "redux-saga/effects";
import FileDownload from "js-file-download";

import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import { types, actions } from "reducers/files";
import * as fileMiddleware from "middleware/file";

const sagas = [
    takeLatest(types.DOWNLOAD_FILE_REQUEST, downloadFileRequest),
    takeLatest(types.GET_FILE_CONTENTS_REQUEST, getFileContentsRequest),
];

export default sagas;

function* downloadFileRequest({ idFile, fileName }) {
    const fileResponse = yield call(fileMiddleware.downloadStream, idFile);

    // dispara el download del archivo al navegador
    FileDownload(fileResponse.data, fileName);

    // solo para quitar el descargando
    yield put(actions.downloadFileSuccess());
}

function getBlobContent(blob) {
    const url = URL.createObjectURL(blob);
    const request = new XMLHttpRequest();
    request.open("GET", url, false);
    request.send();
    URL.revokeObjectURL(url);
    return request.responseText;
}

function* getFileContentsRequest({ idFile, deleteFile }) {
    const fileResponse = yield fileMiddleware.downloadStream(idFile);
    const content = getBlobContent(fileResponse.data)
        .split("\n")
        .slice(1)
        .map((line, i) => {
            const [
                creditAccountNumber,
                creditAmountCurrency,
                creditAmountQuantity,
                creditAccountName,
                bankIdentifier,
            ] = line.split(",");
            return {
                creditAccountNumber,
                creditAmountCurrency,
                creditAmountQuantity: Number(creditAmountQuantity),
                creditAccountName,
                bankIdentifier,
                lineNumber: i + 1,
            };
        });
    if (deleteFile) {
        yield fileMiddleware.deleteFile(Number(idFile));
    }
    yield put(transactionLinesActions.getFileContentsSuccess(content));
}
