import { call, put, takeLatest, select } from "redux-saga/effects";

import { types, actions } from "reducers/chatbot";
import sendMessage from "middleware/das";

const sagas = [takeLatest(types.SEND_MESSAGE_REQUEST, sendMessageRequest)];

export default sagas;

// Generates an Id based on the actual time plus the user Id
const generateSessionId = (user) => {
    const d = new Date();
    const commId = `${d.getFullYear()}${d.getMonth() +
        1}${d.getDate()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}-${user}`;

    return commId;
};

function* sendMessageRequest({ text, event }) {
    const accessToken = yield select((state) => state.session.accessToken);
    let sessionId = yield select((state) => state.chatbot.sessionId);

    // This will generate the communicationId if there is no previos open chat session
    if (!sessionId) {
        const user = yield select((state) => state.session.user.userId);
        sessionId = generateSessionId(user);
        yield put(actions.startSession(sessionId));
    }

    const body = {
        text: text.length > 0 ? text : null,
        event: event || null,
        idCommunication: sessionId,
        Accestoken: accessToken,
    };

    if (text) {
        yield put(actions.setMessage(body.text, true));
    }

    const response = yield call(sendMessage, body);

    if (response.type === "W") {
        yield put(actions.sendMessageFailure());
    } else if (response && response.data && response.data.text) {
        yield put(actions.setMessage(response.data.text.trim(), false));
    }
}
