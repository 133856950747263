import { call, put, takeLatest } from "redux-saga/effects";

import { types, actions } from "reducers/servicePayments";
import * as servicePayments from "middleware/servicePayments";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.LIST_SERVICE_PAYMENTS_REQUEST, listServicePaymentsRequest),
    takeLatest(types.LIST_SERVICE_PAYMENTS_HISTORY_REQUEST, listServicePaymentsHistoryRequest),
];

export default sagas;

function* listServicePaymentsRequest({ pageNumber, onSuccess, onFail }) {
    const response = yield call(servicePayments.listServicePayments, pageNumber);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["servicePayments"]),
        );
        onFail();
    } else {
        const { servicePayments: payments, totalPages } = response.data.data;
        yield put(actions.listServicePaymentsSuccess(payments, pageNumber, totalPages));
        onSuccess();
    }
}

function* listServicePaymentsHistoryRequest({ idServicePayment, dateFrom, dateTo, pageNumber, onSuccess, onFail }) {
    const response = yield call(
        servicePayments.listServicePaymentsHistory,
        idServicePayment,
        dateFrom,
        dateTo,
        pageNumber,
    );

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "historyServicePayments",
            ]),
        );
        onFail();
    } else {
        const { payments, totalPages } = response.data.data;
        yield put(actions.listServicePaymentsHistorySuccess(payments, pageNumber, totalPages));
        onSuccess();
    }
}
