import { createReducer, makeActionCreator } from "util/redux";
// Action types
export const types = {
    RESET: "recoveryUser/RESET",
    RECOVERY_USER_STEP1_PRE_REQUEST: "recoveryUser/RECOVERY_USER_STEP1_PRE_REQUEST",
    RECOVERY_USER_STEP1_PRE_SUCCESS: "recoveryUser/RECOVERY_USER_STEP1_PRE_SUCCESS",
    RECOVERY_USER_STEP1_SAVE_FORM: "recoveryUser/RECOVERY_USER_STEP1_SAVE_FORM",
    RECOVERY_USER_STEP1_REQUEST: "recoveryUser/RECOVERY_USER_STEP1_REQUEST",
    RECOVERY_USER_STEP1_SUCCESS: "recoveryUser/RECOVERY_USER_STEP1_SUCCESS",
    RECOVERY_USER_REQUEST_FAILURE: "recoveryUser/RECOVERY_USER_STEP1_FAILURE",
    RECOVERY_USER_STEP2_REQUEST: "recoveryUser/RECOVERY_USER_STEP2_REQUEST",
    RECOVERY_USER_STEP2_SUCCESS: "recoveryUser/RECOVERY_USER_STEP2_SUCCESS",
    RECOVERY_RESEND_OTP_AUTHENTICATE_CODE_REQUEST: "recoveryUser/RECOVERY_RESEND_OTP_AUTHENTICATE_CODE_REQUEST",
};

// Initial state
export const INITIAL_STATE = {
    fetching: false,
    preData: {},
    documentCountry: "",
    documentType: "",
    documentNumber: "",
    documentNumberToShow: "",
    documentNumber: "",
    mail: "",
    exchangeToken: null,
    showCaptcha: false,
    resetCode: null,
    preData: null,
    contact: "",
    contactType: "",
    idTransaction: "",
    operationType: "",
    questions: [],
};

// Reducer
export default createReducer(INITIAL_STATE, {
    [types.RESET]: () => ({ ...INITIAL_STATE }),
    [types.RECOVERY_USER_STEP1_PRE_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.RECOVERY_USER_STEP1_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preData: action.preData,
    }),
    [types.RECOVERY_USER_STEP1_SAVE_FORM]: (state, action) => ({
        ...state,
        documentNumber: action.documentNumber,
        mail: action.mail,
    }),
    [types.RECOVERY_USER_STEP1_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.RECOVERY_USER_STEP1_SUCCESS]: (state, action) => ({
        ...state,
        username: action.username,
        mail: action.mail,
        showCaptcha: false,
        operationType: action.operationType,
        contact: action.contact,
        contactType: action.contactType,
        exchangeToken: action.exchangeToken,
        questions: action.questions,
        otpMethod: action.otpMethod,
    }),
    [types.RECOVERY_USER_STEP2_SUCCESS]: (state, action) => ({
        ...state,
        exchangeToken: action.exchangeToken,
        showCaptcha: false,
        idTransaction: action.idTransaction,
    }),
    [types.RECOVERY_USER_REQUEST_FAILURE]: (state) => ({ ...state, fetching: false }),
});

// Action creators
export const actions = {
    preForm: makeActionCreator(types.RECOVERY_USER_STEP1_PRE_REQUEST),
    preFormSuccess: makeActionCreator(types.RECOVERY_USER_STEP1_PRE_SUCCESS, "preData"),
    saveForm: makeActionCreator(types.RECOVERY_USER_STEP1_SAVE_FORM, "documentNumber", "mail"),
    recoveryUserStep1: makeActionCreator(types.RECOVERY_USER_STEP1_REQUEST, "documentNumber", "mail", "formikBag"),
    recoveryUserStep2: makeActionCreator(
        types.RECOVERY_USER_STEP2_REQUEST,
        "secondFactor",
        "exchangeToken",
        "formikBag",
    ),
    requestFailure: makeActionCreator(types.RECOVERY_USER_REQUEST_FAILURE),
    reset: makeActionCreator(types.RESET),
    recoveryUserResendOtp: () => ({
        type: types.RECOVERY_RESEND_OTP_AUTHENTICATE_CODE_REQUEST,
    }),
};

// Selectors
export const selectors = {
    getPreData: ({ recoveryUser }) => recoveryUser.preData,
    getFormData: ({ recoveryUser }) => recoveryUser.formData,
    getFetching: ({ recoveryUser }) => recoveryUser.fetching,
    getUsername: ({ recoveryUser }) => recoveryUser.username,
    getNotificationType: ({ recoveryUser }) => recoveryUser.notificationType,
    getExchangeToken: ({ recoveryUser }) => recoveryUser.exchangeToken,
    getShowCaptcha: ({ recoveryUser }) => recoveryUser.showCaptcha,
    getContact: ({ recoveryUser }) => recoveryUser.contact,
    getContactType: ({ recoveryUser }) => recoveryUser.contactType,
    getIdTransaction: ({ recoveryUser }) => recoveryUser.idTransaction,
    getTypeOperation: ({ recoveryUser }) => recoveryUser.operationType,
    getClientQuestions: ({ recoveryUser }) => recoveryUser.questions,
    getOtpMethod: ({ recoveryUser }) => recoveryUser.otpMethod,
};
