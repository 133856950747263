import Box from "pages/_components/Box";
import { arrayOf, string } from "prop-types";
import React from "react";
import Slider from "react-slick";
import Image from "pages/_components/Image";

import { getSafeRandomNumber } from "util/number";

const ImageSlider = ({ images }) => {
    const sliderRef = React.useRef();

    const settings = {
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        infinite: true,
        dots: true,
        slidesToShow: 1,
        lazyLoad: false,
        slidesToScroll: 1,
        draggable: false,
        customPaging: () => (
            <div className="slick-dot-custom">
                <div />
            </div>
        ),
    };

    if (!images.length) {
        return <></>;
    }

    return (
        <Box component="li" noList className="image-slider fade-in">
            <Slider ref={sliderRef} {...settings}>
                {images.map((imageUrl) => (
                    <div key={`slide_${getSafeRandomNumber()}`} className="image-slide">
                        <Image src={imageUrl} />
                    </div>
                ))}
            </Slider>
        </Box>
    );
};

ImageSlider.propTypes = {
    images: arrayOf(string).isRequired,
};

export default ImageSlider;
