import React from "react";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { bool } from "prop-types";

const ExpiredPasswordContent = ({ isDesktop }) => (
    <>
        <Box className="login-blocked-icon">
            <Image src="images/util/error.gif" />
        </Box>
        <Text size={isDesktop ? "3" : "1"} className="mt-8" bold labelKey="login.user.expiredPassword.title" />
        <Box className="px-11 py-5 text-center">
            <Text className="mt-8" size="6" labelKey="login.user.expiredPassword.info" />
        </Box>
        <Button
            className="mt-8"
            href="/changePassword"
            bsStyle="primary"
            label="login.user.expiredPassword.button.change"
            btnUppercase={false}
            block
        />
    </>
);
ExpiredPasswordContent.propTypes = {
    isDesktop: bool.isRequired,
};
export default ExpiredPasswordContent;
