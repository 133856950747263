import classNames from "classnames";
// import Logo from "pages/login/_components/Logo";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import withExchangeToken from "pages/_components/withExchangeToken";
import { bool, func, number, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux/actions";
import { selectors as loginSelectors } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";
import Step3Content from "./_components/Step3Content";

class LoginStep3 extends Component {
    componentDidMount() {
        const { dispatch, activeEnvironment, username } = this.props;
        if (activeEnvironment) {
            dispatch(push("/desktop"));
        } else if (!username) {
            dispatch(push("/"));
        }
    }

    render() {
        const { isDesktop, loadingDesktop, username } = this.props;

        return (
            <>
                <Notification scopeToShow="loginStep3" />
                <MainContainer
                    showLoader={loadingDesktop}
                    classicStyle
                    className={classNames("main-container max-width-full", {
                        "container-fluid with-special-header-login": isDesktop,
                    })}>
                        {username &&
                            <Step3Content />
                        }
                    
                </MainContainer>
            </>
        );
    }
}

LoginStep3.propTypes = {
    isDesktop: bool.isRequired,
    loadingDesktop: bool.isRequired,
    dispatch: func.isRequired,
    username: string.isRequired,
    activeEnvironment: number,
};

LoginStep3.defaultProps = {
    activeEnvironment: null,
};

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    username: loginSelectors.getUsername(state),
    loadingDesktop: loginSelectors.getLoadingDesktop(state),
});

export default compose(withRouter, connect(mapStateToProps))(withExchangeToken(LoginStep3));
