import React, { Component } from "react";
import { number, string, arrayOf, shape, func, bool } from "prop-types";
import { connect } from "react-redux";

import { selectors as fileSelectors, actions as multilineFileActions } from "reducers/formFields/multilineFile";
import { actions as filesActions } from "reducers/files";
import { get } from "util/config";

class FileActions extends Component {
    static propTypes = {
        value: arrayOf(
            shape({
                fileId: number.isRequired,
                fileSize: number.isRequired,
                fileName: string.isRequired,
                fileType: string.isRequired,
            }),
        ),
        idRelatedFile: string,
        nameRelatedFile: string,
        setValue: func.isRequired,
        dispatch: func.isRequired,
        fromBackoffice: bool.isRequired,
        render: func.isRequired,
        renderPreview: func,
        editing: bool.isRequired,
    };

    static defaultProps = {
        value: null,
        renderPreview: null,
        idRelatedFile: null,
        nameRelatedFile: null,
    };

    componentDidMount() {
        const { value, setValue } = this.props;

        if (value === null) {
            setValue([]);
        }
    }

    onAddFile = (file) => {
        const { value, setValue, dispatch } = this.props;
        const nextValue = [...value, file];

        dispatch(multilineFileActions.setMetadata(file));
        setValue(nextValue);
    };

    onRemoveFile = (fileId) => {
        const { value, setValue } = this.props;

        setValue(value.filter((file) => file.fileId !== fileId));
    };

    downloadFile = (...params) => {
        const { fromBackoffice, dispatch, value, idRelatedFile, nameRelatedFile } = this.props;

        let idFile = "";
        let fileName = "";
        if (params.length && params[0].isDownloadingRelatedFile) {
            idFile = idRelatedFile;
            fileName = nameRelatedFile;
        } else {
            [idFile, fileName] = params.length ? params : [value[0].fileId, value[0].fileName];
        }

        if (fromBackoffice) {
            const parentWin = this.elem.contentWindow.parent;
            const win = this.elem.contentWindow;
            if (win !== parentWin) {
                parentWin.postMessage(`file ${idFile}`, get("backoffice.baseURL"));
            } else {
                win.postMessage(`file ${idFile}`, get("backoffice.baseURL"));
            }
        } else {
            dispatch(filesActions.downloadFileRequest(idFile, fileName));
        }
    };

    render() {
        const { render, renderPreview, value, editing } = this.props;

        if (editing) {
            return render({
                onAddFile: this.onAddFile,
                onRemoveFile: this.onRemoveFile,
                downloadFile: this.downloadFile,
            });
        }

        if (value && value.length > 0) {
            return renderPreview
                ? renderPreview({ downloadFile: this.downloadFile, value })
                : value.map((file) => (
                      <button
                          type="button"
                          className="btn-asLink"
                          key={file.fileId}
                          onClick={() => this.downloadFile(file.fileId, file.fileName)}>
                          {file.fileName}
                      </button>
                  ));
        }

        return null;
    }
}

const mapStateToProps = (state) => ({
    idRelatedFile: fileSelectors.getIdRelatedFile(state),
    nameRelatedFile: fileSelectors.getNameRelatedFile(state),
});

export default connect(mapStateToProps)(FileActions);
