import React, { Component } from "react";
import { string, node, bool } from "prop-types";
import Image from "pages/_components/Image";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";

class NoProduct extends Component {
    static propTypes = {
        text: string.isRequired,
        imagePath: string,
        showContacts: bool,
        button: node,
    };

    static defaultProps = {
        showContacts: true,
        imagePath: "images/empty.svg",
        button: null,
    };

    render() {
        const { text, showContacts, imagePath, button } = this.props;

        return (
            <Box className="newProduct mb-5">
                <Image src={imagePath} />

                <Box className="text-center mt-6">
                    <Text labelKey={text} size="4" semibold />
                </Box>

                {showContacts && (
                    <Box className="text-center mt-4">
                        <Text labelKey="desktop.widgets.accounts.empty.requestService" />
                    </Box>
                )}
                {button && <div>{button}</div>}
            </Box>
        );
    }
}

export default NoProduct;
