import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    MODAL_SHOW: "forms/MODAL_SHOW",
    MODAL_CLOSE: "forms/MODAL_CLOSE",
};

export const INITIAL_STATE = {
    displayModal: false,
};

export default createReducer(INITIAL_STATE, {
    [types.MODAL_SHOW]: (state) => ({ ...state, displayModal: true }),
    [types.MODAL_CLOSE]: (state) => ({ ...state, displayModal: false }),
});

export const actions = {
    modalShow: makeActionCreator(types.MODAL_SHOW),
    modalClose: makeActionCreator(types.MODAL_CLOSE),
};

export const selectors = {
    getDisplayModal: ({ modal }) => modal.displayModal,
};
