export const getSumTotalAmountList = (total) => {
    const arrayTotal = [];
    if (total?.totalUSD?.currency) {
        arrayTotal.push(total.totalUSD);
    }
    if (total?.totalEUR?.currency) {
        arrayTotal.push(total?.totalEUR);
    }
    return arrayTotal;
};

export const getAvailableAmountList = (total) => {
    const arrayTotal = [];
    if (total?.availableUSD?.currency) {
        arrayTotal.push(total.availableUSD);
    }
    if (total?.availableEUR?.currency) {
        arrayTotal.push(total?.availableEUR);
    }
    return arrayTotal;
};
