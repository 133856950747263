import { Field } from "formik";
import Scheduler from "pages/forms/_components/_fields/Scheduler";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import Text from "pages/_components/Text";
import { bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions as transactionsActions } from "reducers/transactions";
import * as i18n from "util/i18n";

class ScheduledTransactionItem extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        transaction: shape({
            cancelEnabled: bool.isRequired,
            transaction: shape({
                channel: string,
                idTransaction: string,
                creationDateTime: string,
                submitDateTime: string,
                activityName: string,
                idTransactionStatus: string,
            }),
            transactionAmounts: shape(),
        }).isRequired,
        showEnvironment: bool,
        hasTicket: bool,
        scheduleAction: func,
        setFieldValue: func.isRequired,
        migrationTransactionFinished: bool,
        transactionIndex: number.isRequired,
        modalIsDisplayed: bool.isRequired,
    };

    static defaultProps = {
        showEnvironment: false,
        hasTicket: true,
        scheduleAction: () => {},
        migrationTransactionFinished: false,
    };

    state = {
        displayConfirmationModal: false,
        loadingActionDelete: false,
    };

    // eslint-disable-next-line class-methods-use-this
    getDateTime(data, creationDateTime, idParentTransaction, submitDateTime) {
        if (idParentTransaction && data.scheduler.selectedOption !== "TODAY") {
            return submitDateTime;
        }
        return creationDateTime;
    }

    onFinishDelete = () => {
        this.setState({
            loadingActionDelete: false,
        });
    };

    handleDelete = (event) => {
        this.setState({ loadingActionDelete: true });
        event.stopPropagation();
        event.preventDefault();
        const {
            dispatch,
            transaction: { idTransaction, data },
        } = this.props;

        dispatch(
            transactionsActions.deleteMigrationTransactionRequest(
                idTransaction,
                data?.transactionType,
                null,
                this.onFinishDelete,
            ),
        );
    };

    handleCloseBottomSheetClick = () => {
        const { dispatch, transactionIndex } = this.props;
        dispatch(transactionsActions.setDisplayMigrationSchedulerModal(transactionIndex, false));
    };

    openSchedulerModal = () => {
        const { dispatch, setFieldValue, transaction, transactionIndex } = this.props;
        setFieldValue("transactionId", transaction.idTransaction);
        setFieldValue("transactionType", transaction.data.transactionType);
        setFieldValue("beneficiary", transaction.data.beneficiary);
        setFieldValue("reason", transaction.data.reason);
        setFieldValue("amount", transaction.data.amount);
        setFieldValue("debitAccount", transaction.data.debitAccount);
        setFieldValue("debitAccountData", transaction.data.debitAccountData);
        setFieldValue("creditAccount", transaction.data.creditAccount);
        setFieldValue("creditAccountData", transaction.data.creditAccountData);
        setFieldValue("scheduler", null);
        setFieldValue("beneficiaryId", transaction.data.beneficiaryId);
        setFieldValue("creationDateTime", transaction.creationDateTime);
        dispatch(transactionsActions.setDisplayMigrationSchedulerModal(transactionIndex, true));
    };

    renderScheduler = (schedulerDisabled) => {
        const data = {
            nonWorkingDays: [],
            enabledWeekDays: [false, true, true, true, true, true, true, true],
            firstWorkingDate: new Date(),
            maxDaysToSchedule: 30,
            DeletingDraft: false,
            // lang: currentLang,
            mode: "edit",
            programable: true,
            schedulable: true,
        };

        const { scheduleAction, modalIsDisplayed } = this.props;
        return (
            <Field
                component={Scheduler}
                data={data}
                buttonAction={scheduleAction}
                radioInline
                externalIsDisplayed={modalIsDisplayed}
                name="scheduler"
                idField="scheduler"
                disabled={schedulerDisabled}
                idForm="activity1"
                buttonAsSubmit
                displayNone
                shouldRequestCredentials
                propsForm={{ ...this.props, form: { ...this.props } }}
                closeModalAction={this.handleCloseBottomSheetClick}
            />
        );
    };

    render() {
        const { isDesktop, transaction } = this.props;
        const { displayConfirmationModal, loadingActionDelete } = this.state;
        const {
            creationDateTime,
            creationDateTimeAsString,
            activityName,
            data,
            idParentTransaction,
            submitDateTime,
        } = transaction;

        const currency = data?.amount?.currency;

        const quantity =
            data?.amount?.quantity !== null && data?.amount?.quantity !== undefined && data?.amount?.quantity !== ""
                ? data.amount.quantity
                : 0;
        const showAmount = quantity !== 0;
        const destinatary = data?.beneficiary !== null && data.beneficiary.name ? data.beneficiary.name : "-";
        const content = (
            <>
                <TextAndButtonsModal
                    modalShow={displayConfirmationModal}
                    acceptFunction={this.handleDelete}
                    cancelFunction={() => this.setState({ displayConfirmationModal: false })}
                    headingText={i18n.get("scheduled.transactions.migration.delete.modal.title")}
                    text={i18n.get("scheduled.transactions.migration.delete.modal.text")}
                    loading={loadingActionDelete}
                />
                {this.renderScheduler()}
                {isDesktop ? (
                    <>
                        <GridTable.Data columnStart={1} rowStart={1} alignX="flex-start" alignY="center">
                            <Text ellipsis>{activityName}</Text>
                        </GridTable.Data>
                        <GridTable.Data
                            columnStart={2}
                            rowStart={1}
                            alignX="flex-start"
                            alignY="center"
                            flexDirection="row">
                            <Text ellipsis align="start">
                                {creationDateTimeAsString && creationDateTimeAsString.length > 0
                                    ? creationDateTimeAsString.split(" ")[0]
                                    : "-"}
                            </Text>
                        </GridTable.Data>
                        <GridTable.Data columnStart={3} rowStart={1} alignX="center" alignY="center">
                            <Text ellipsis align="center">
                                {destinatary}
                            </Text>
                        </GridTable.Data>

                        <GridTable.Data
                            columnStart={4}
                            columnWidth={1}
                            rowStart={1}
                            rowWidth={1}
                            alignX="flex-end"
                            alignY="center">
                            {showAmount ? (
                                <FormattedAmount
                                    {...(!isDesktop && { bold: true })}
                                    align="right"
                                    size="6"
                                    currency={currency}
                                    quantity={quantity}
                                />
                            ) : (
                                <Text ellipsis align="right">
                                    -
                                </Text>
                            )}
                        </GridTable.Data>

                        <GridTable.Data
                            columnStart={5}
                            columnWidth={1}
                            rowStart={1}
                            rowWidth={1}
                            alignX="center"
                            alignY="center">
                            <Button
                                label="scheduled.transactions.migration.list.item.reschedule"
                                onClick={this.openSchedulerModal}
                                bsStyle="link"
                            />
                        </GridTable.Data>
                        <GridTable.Data
                            columnStart={6}
                            columnWidth={1}
                            rowStart={1}
                            rowWidth={1}
                            alignX="center"
                            alignY="center">
                            <Button
                                onClick={() => this.setState({ displayConfirmationModal: true })}
                                image="images/icons/deleteTrash.svg"
                                bsStyle="only-icon"
                                imageMd
                            />
                        </GridTable.Data>
                    </>
                ) : (
                    <>
                        <GridTable.Data columnStart={1} alignX="flex-start" alignY="center">
                            <Box fullWidth display="flex" column alignX="flex-start">
                                <Text className="mb-1" size="6" ellipsis bold color="heading">
                                    {activityName}
                                </Text>
                                <Text className="mb-1" size="6" ellipsis>
                                    {destinatary}
                                </Text>
                                <FormattedDate
                                    size="6"
                                    date={this.getDateTime(data, creationDateTime, idParentTransaction, submitDateTime)}
                                    anotherFormat="DD/MM/YYYY HH:mm:ss"
                                />
                            </Box>
                        </GridTable.Data>
                        <GridTable.Data columnStart={2} alignX="flex-end" alignY="center">
                            <Box display="flex" column alignX="flex-end" alignY="between">
                                {showAmount ? (
                                    <FormattedAmount
                                        bold
                                        align="right"
                                        size="5"
                                        currency={currency}
                                        quantity={quantity}
                                    />
                                ) : (
                                    <Text size="5" ellipsis align="right">
                                        -
                                    </Text>
                                )}
                                <Box display="flex" alignX="flex-end" alignY="center">
                                    <Button
                                        small
                                        label="scheduled.transactions.migration.list.item.reschedule"
                                        onClick={this.openSchedulerModal}
                                        bsStyle="link"
                                    />
                                    <Button
                                        className="p-0 justify-content-end"
                                        style={{ padding: "0" }}
                                        onClick={() => this.setState({ displayConfirmationModal: true })}
                                        image="images/icons/deleteTrash.svg"
                                        bsStyle="only-icon"
                                        imageMd
                                    />
                                </Box>
                            </Box>
                        </GridTable.Data>
                    </>
                )}
            </>
        );

        return content;
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ScheduledTransactionItem);
