import React, { useMemo, useState } from "react";
import { func } from "prop-types";
import PageLoading from "pages/_components/PageLoading";
import { isDesktop } from "react-device-detect";
import FlowTransition from "pages/_components/FlowTransition";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as i18n from "util/i18n";
import ExitModal from "pages/_components/modal/ExitModal";
import SoftTokenDeactivationQuestion from "./SoftTokenDeactivationQuestion";
import SoftTokenDeactivationConfirm from "./SoftTokenDeactivationConfirm";
import SoftTokenDeactivationSuccess from "./SoftTokenDeactivationSuccess";

const steps = {
    question: "question",
    confirmation: "confirmation",
    success: "success",
};

const FORM_ID = "softToken.deactivation";

const SoftTokenDeactivationFlow = ({ dispatch }) => {
    const [currentStep, setCurrentStep] = useState([steps.question]);
    const [showExitModal, setShowExitModal] = useState(false);

    const goToHome = () => {
        dispatch(push("/desktop"));
    };

    const goToAuthenticator = () => {
        dispatch(push("/settings/authenticatorHandler"));
    };

    const goToDesktop = () => {
        dispatch(push("/desktop"));
    };

    const handleAcceptModal = () => {
        goToHome();
        setShowExitModal(false);
    };

    const handleHideModal = () => {
        setShowExitModal(false);
    };

    const handleCancel = () => {
        setShowExitModal(true);
    };

    const handleContinueQuestion = () => {
        setCurrentStep(steps.confirmation);
    };

    const onSuccess = () => {
        setCurrentStep(steps.success);
    };

    const flowTransition = useMemo(() => (
        <FlowTransition
            currentPage={currentStep}
            pages={{
                [steps.question]: () => (
                    <SoftTokenDeactivationQuestion
                        formId={FORM_ID}
                        isDesktop={isDesktop}
                        handleSubmit={handleContinueQuestion}
                        handleCancel={handleCancel}
                        goToHome={goToHome}
                        goToDesktop={goToDesktop}
                    />
                ),
                [steps.confirmation]: () => (
                    <SoftTokenDeactivationConfirm
                        formId={FORM_ID}
                        isDesktop={isDesktop}
                        handleCancel={handleCancel}
                        onSuccess={onSuccess}
                        goToHome={goToHome}
                        goToDesktop={goToDesktop}
                    />
                ),
                [steps.success]: () => (
                    <SoftTokenDeactivationSuccess
                        formId={FORM_ID}
                        isDesktop={isDesktop}
                        goToHome={goToHome}
                        goToDesktop={goToDesktop}
                    />
                ),
            }}
        />
    ), [currentStep]);

    return (
        <PageLoading loading={false}>
            {flowTransition}
            <ExitModal
                modalShow={showExitModal}
                acceptFunction={handleAcceptModal}
                cancelFunction={handleHideModal}
                headingText={i18n.get("confirm.exit.title")}
                text={i18n.get("confirm.exit.info")}
            />
        </PageLoading>
    );
};

SoftTokenDeactivationFlow.propTypes = {
    dispatch: func.isRequired,
};

export default connect()(SoftTokenDeactivationFlow);
