import React from "react";
import { node } from "prop-types";
import Tabs from "pages/_components/Tabs";

const CreditCardTabs = ({ children, onSelect }) => <Tabs className="pt-0" components={children} onSelect={onSelect} />;

CreditCardTabs.propsTypes = {
    children: node.isRequired,
};

export default CreditCardTabs;
