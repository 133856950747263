import { useState, useEffect } from "react";

const useGenericProps = (mode, currentLang, idActivity) => {
    const [genericProps, setGenericProps] = useState({});

    useEffect(() => {
        setGenericProps({
            mode: mode || "edit",
            lang: currentLang || "",
            fromBackoffice: false,
            isRequired: true,
            idActivity: idActivity || "",
        });
    }, [currentLang]);

    return [genericProps];
};

export default useGenericProps;
