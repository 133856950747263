import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedDate from "pages/_components/FormattedDate";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, oneOfType, shape, string, number as typeNumber } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { selectors as creditCardSelectors } from "reducers/creditCard";
import * as i18nUtils from "util/i18n";
import { dictionaryCardStyle, dictionaryMiniatureCard } from "util/creditCards.js";

export const CreditCardStatus = {
    Actived: "00",
    Blocked: "106",
    Inactived: "32",
};

export const ProductionStatus = {
    Locked: "Locked",
    Active: "Active",
};

export const actionChangeStatusProduct = {
    Block: {
        actionName: "block",
        actionImage: "images/BlockProduct.svg",
        statusExpected: CreditCardStatus.Blocked,
    },
    Unlock: {
        actionName: "unlock",
        actionImage: "images/UnlockProduct.svg",
        statusExpected: CreditCardStatus.Actived,
    },
    Active: {
        actionName: "active",
        actionImage: "images/UnlockProduct.svg",
        statusExpected: CreditCardStatus.Actived,
    },
};

const CreditCardWidget = ({
    cardStyle,
    className,
    icon,
    id,
    isDesktop,
    path,
    onClick,
    // dispatch,
    // shortLabel,
    // idProduct,
    // client,
    description,
    creditCardDetail,
    // isFetchingList,
}) => {
    // const [viewAvailable, setViewAvailable] = useState(false);
    const Component = path ? Link : Box;

    // const handleClickAvailable = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setViewAvailable(true);
    // };
    const creditCardStyle = dictionaryCardStyle(creditCardDetail.cardStyle);
    const isWhite = creditCardStyle === "white";

    return (
        <Component
            {...(path && { to: path })}
            {...(!path &&
                onClick && {
                    onClick: () => {
                        onClick();
                    },
                })}>
            <Box id={id} className={classNames("credit-card credit-card-widget mx-auto", creditCardStyle, className)}>
                <Box display="flex" alignY="flex-start" fullWidth flex1>
                    <Box display="flex" alignY="center" fullWidth>
                        <Box display="flex" alignY="start">
                            <Image
                                src={`images/${dictionaryMiniatureCard(
                                    creditCardDetail.cardStyle,
                                )}-widget-creditCard.svg`}
                                className="fill-transparent"
                            />
                            <Text
                                size={isDesktop ? "6" : "5"}
                                color={isWhite ? "black" : "inverse"}
                                bold
                                className="mt-3 pt-3">
                                XXXX &middot; {creditCardDetail?.panSuffix}
                            </Text>
                        </Box>
                        <Box className="pr-3 d-grid max-width-105rem">
                            <Text size="small" color={isWhite ? "black" : "inverse-color"} uppercase ellipsis>
                                {description}
                            </Text>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" alignX="flex-start" fullWidth flex1>
                    <Text
                        size={isDesktop ? "4" : "6"}
                        color={isWhite ? "black" : "inverse-color"}
                        uppercase
                        className="mb-1"
                        bold>
                        {creditCardDetail?.nameOnCard}
                    </Text>
                </Box>
                <Box display="flex" className="justify-content-between align-items-center">
                    <Box display="flex">
                        <Text
                            size={isDesktop ? "6" : "5"}
                            color={isWhite ? "black" : "inverse-color"}
                            addColon
                            labelKey="forms.creditCard.payment.dateScheduler.label_preview"
                        />

                        {creditCardDetail?.expirationDate ? (
                            <FormattedDate
                                size={isDesktop ? "6" : "5"}
                                color={isWhite ? "black" : "inverse-color"}
                                date={creditCardDetail?.expirationDate}
                                anotherFormat="DD/MM/YY"
                            />
                        ) : (
                            <Text
                                size={isDesktop ? "6" : "5"}
                                color={isWhite ? "black" : "inverse-color"}
                                defaultValue="-"
                                bold
                            />
                        )}
                    </Box>
                    <Button
                        small
                        image="images/icons/sliderArrowRight.svg"
                        bsStyle="link"
                        aria-label={i18nUtils.get("global.next.a11y")}
                    />
                </Box>
            </Box>
        </Component>
    );
};

CreditCardWidget.propTypes = {
    blocked: bool,
    cardStyle: string,
    className: string,
    client: shape({}).isRequired,
    creditCardDetail: shape({}),
    description: string,
    dispatch: func.isRequired,
    expirationDateMask: string,
    franchise: string,
    icon: string,
    id: string,
    idProduct: string,
    isDesktop: bool.isRequired,
    isPrepaid: bool.isRequired,
    isSwitchVisible: bool.isRequired,
    minimumPayment: typeNumber.isRequired,
    minimumPaymentCurrency: string.isRequired,
    number: string,
    onClick: func,
    path: oneOfType([string, shape({ pathname: string })]),
    shortLabel: string,
    isFetchingList: bool,
};

CreditCardWidget.defaultProps = {
    blocked: false,
    cardStyle: null,
    className: "",
    creditCardDetail: {},
    description: "",
    expirationDateMask: "",
    franchise: "",
    icon: null,
    id: null,
    idProduct: "",
    number: "",
    onClick: () => {},
    path: null,
    shortLabel: "",
    isFetchingList: false,
};

const mapStateToProps = (state, ownProps) => ({
    creditCardDetail: creditCardSelectors.getDetailInList(state, ownProps.idProduct),
    isFetchingList: creditCardSelectors.isFetching(state),
});

export default connect(mapStateToProps)(resizableRoute(CreditCardWidget));
