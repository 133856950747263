import * as API from "middleware/api";

export const changeExpiredPassStep1 = (username, password, newPassword, newPasswordRepeat) =>
    API.executeAnonymous("/session.changeExpiredPassword.step1", {
        _username: username,
        _password: password,
        _newPassword: newPassword,
        _newPasswordRepeat: newPasswordRepeat,
    });

export const changeExpiredPassStep2 = (password, newPassword, secondFactor, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.changeExpiredPassword.step2",
        {
            _password: password,
            _newPassword: newPassword,
            _secondFactor: secondFactor,
        },
        exchangeToken,
    );
