import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, string, func } from "prop-types";
import React from "react";

const WidgetsError = ({ className, isDesktop, onClick, name }) => {
    const handleClick = () => {
        onClick();
    };

    return (
        <Box
            {...(isDesktop ? { column: false } : { column: true })}
            display="flex"
            alignX={isDesktop ? "between" : "center"}
            alignY={isDesktop ? "center" : "between"}
            border="dashed-background-divider"
            borderRadius="default"
            className={classNames("p-5 mb-3", className)}>
            <Box className="mr-md-7 flex-2" display="flex" alignX={isDesktop ? "flex-start" : "center"} column>
                <Text
                    size={isDesktop ? "7" : "6"}
                    color="text"
                    align={isDesktop ? "left" : "center"}
                    labelKey={`widgets.error.text1.${name}`}
                />

                <Text
                    size={isDesktop ? "7" : "6"}
                    color="text"
                    align={isDesktop ? "left" : "center"}
                    labelKey="widgets.error.text2"
                />
            </Box>

            <Button
                className="mt-3"
                bsStyle="outline"
                image="images/icons/reloadIcon.svg"
                label="widgets.error.reload"
                onClick={handleClick}
                {...(isDesktop ? { block: false } : { block: true })}
            />
        </Box>
    );
};

WidgetsError.propTypes = {
    isDesktop: bool.isRequired,
    className: string,
    onClick: func,
    name: string.isRequired,
};

WidgetsError.defaultProps = {
    className: null,
    onClick: null,
};

export default resizableRoute(WidgetsError);
