import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const types = {
    LIST_CAMPAIGN_REQUEST: "campaigns/LIST_CAMPAIGN_REQUEST",
    LIST_CAMPAIGN_SUCCESS: "campaigns/LIST_CAMPAIGN_SUCCESS",
    LIST_CAMPAIGN_FAILURE: "campaigns/LIST_CAMPAIGN_FAILURE",
    LOAD_CAMPAIGN_REQUEST: "campaigns/LOAD_CAMPAIGN_REQUEST",
    LOAD_CAMPAIGN_ANONYMOUS_REQUEST: "campaigns/LOAD_CAMPAIGN_ANONYMOUS_REQUEST",
    LOAD_CAMPAIGN_SUCCESS: "campaigns/LOAD_CAMPAIGN_SUCCESS",
    LOAD_CAMPAIGN_FAILURE: "campaigns/LOAD_CAMPAIGN_FAILURE",
    LOAD_CAMPAIGN_IMAGE: "campaigns/LOAD_CAMPAIGN_IMAGE",
    REMOVE_CAMPAIGNS: "campaigns/REMOVE_CAMPAIGNS",
    DISMISS_CAMPAIGN_PERMANENT_REQUEST: "campaigns/DISMISS_CAMPAIGN_PERMANENT_REQUEST",
    DISMISS_CAMPAIGN_PERMANENT_SUCCESS: "campaigns/DISMISS_CAMPAIGN_PERMANENT_SUCCESS",
    DISMISS_CAMPAIGN_PERMANENT_FAILURE: "campaigns/DISMISS_CAMPAIGN_PERMANENT_FAILURE",
};

export const INITIAL_STATE = {
    fetching: false,
    items: [],
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.LIST_CAMPAIGN_REQUEST:
        case types.LOAD_CAMPAIGN_REQUEST:
        case types.LOAD_CAMPAIGN_ANONYMOUS_REQUEST:
            return {
                ...state,
                fetching: true,
                items: [],
            };
        case types.LIST_CAMPAIGN_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: action.items,
            };
        case types.LOAD_CAMPAIGN_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: [action.item],
            };
        case types.LOAD_CAMPAIGN_IMAGE:
            return {
                ...state,
                fetching: true,
                items: [action.item],
            };

        case types.LIST_CAMPAIGN_FAILURE:
        case types.LOAD_CAMPAIGN_FAILURE:
            return { ...state, fetching: false };

        case types.REMOVE_CAMPAIGNS:
            return INITIAL_STATE;

        case types.DISMISS_CAMPAIGN_PERMANENT_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.DISMISS_CAMPAIGN_PERMANENT_SUCCESS:
        case types.DISMISS_CAMPAIGN_PERMANENT_FAILURE:
            return {
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};

export default persistReducer(
    {
        storage,
        key: "campaigns",
        whitelist: ["fetching"],
    },
    reducer,
);

export const actions = {
    listCampaigns: (section) => ({
        type: types.LIST_CAMPAIGN_REQUEST,
        section,
    }),
    loadCampaign: (section) => ({
        type: types.LOAD_CAMPAIGN_REQUEST,
        section,
    }),
    loadCampaignAnonymous: (section) => ({
        type: types.LOAD_CAMPAIGN_ANONYMOUS_REQUEST,
        section,
    }),
    removeCampaigns: () => ({
        type: types.REMOVE_CAMPAIGNS,
    }),
    dismissPermanentCampaign: (idCampaign) => ({
        type: types.DISMISS_CAMPAIGN_PERMANENT_REQUEST,
        idCampaign,
    }),
};

export const selectors = {
    getCampaigns: ({ campaigns }) => campaigns.items,
    getFetching: ({ campaigns }) => campaigns.fetching,
};
