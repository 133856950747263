import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import { arrayOf, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors } from "reducers/administration/common/administrationTicket";

const userStatusToAction = { blocked: "block", active: "unblock", delete: "delete" };

class UserActionTicket extends Component {
    static propTypes = {
        transactionData: shape({
            data: shape({
                userNameList: arrayOf(string),
                newStatus: string,
            }),
        }).isRequired,
    };

    render() {
        const { transactionData } = this.props;

        if (transactionData.data.userNameList) {
            const userAction = transactionData.data.newStatus || "delete";
            const textKey = `administration.users.action.confirmation.${userStatusToAction[userAction]}`;
            const ticketText = transactionData.data.userNameList.map((userName, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}> {userName} </div>
            ));

            return (
                <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <div className="transfer-data data-wrapper">
                            <Container.ColumnBody columnClass="list-wrapper">
                                <h4 className="form-title">
                                    <I18n id={textKey} />
                                </h4>
                                {ticketText}
                            </Container.ColumnBody>
                        </div>
                    </Col>
                </Container>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(UserActionTicket);
