import React, { Component } from "react";
import { connect } from "react-redux";

import { permissionsSelectors } from "reducers/administration";
import * as administrationUtils from "util/administration";

import Accordion from "pages/_components/Accordion";
import AdvancedPermissionsAmount from "pages/administration/_components/AdvancedPermissionsAmount";
import PermissionField from "pages/administration/_components/advancedPermissionsForm/PermissionField";
import I18n from "pages/_components/I18n";

class AdvancedPermissionsList extends Component {
    static propTypes = {
        permissions: administrationUtils.permissionsPropType.isRequired,
        groups: administrationUtils.groupsPropType.isRequired,
    };

    renderItem = (option) => (
        <li className="list-item" key={option.idItem}>
            <div className="list-item-inner">{option.label}</div>
        </li>
    );

    renderOptions = (parentOption) =>
        parentOption.childrenList.reduce((categoryOptions, childOption) => {
            if (childOption.childrenList && childOption.childrenList.length) {
                const subOptions = this.renderOptions(childOption);

                if (!subOptions.length) {
                    return categoryOptions;
                }

                return [
                    ...categoryOptions,
                    <li className="list-item" key={childOption.idItem}>
                        <div className="list-item-inner">
                            <span className="navigational-list-subtitle">{childOption.label}</span>
                        </div>
                        <ul className="list">{subOptions}</ul>
                    </li>,
                ];
            }

            const { permissions } = this.props;
            const permission = permissions[childOption.idItem] || [];

            if (permission.length) {
                const [{ advancedAllowProductSelection }] = childOption.permissionList || [];

                const field = advancedAllowProductSelection ? (
                    <PermissionField
                        key={childOption.idItem}
                        {...childOption}
                        parentOption={parentOption}
                        mode="view"
                    />
                ) : (
                    this.renderItem(childOption)
                );

                return [...categoryOptions, field];
            }

            return categoryOptions;
        }, []);

    render() {
        const { groups, permissions } = this.props;

        return (
            <AdvancedPermissionsAmount permissions={permissions}>
                {(amountsById) => {
                    if (!Object.keys(amountsById).length) {
                        return (
                            <ul className="list">
                                <li className="list-item">
                                    <div className="list-item-inner">
                                        <div className="data-wrapper data-wrapper-flex">
                                            <I18n id="administration.permissions.empty" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        );
                    }

                    return (
                        <Accordion className="list list--permissions">
                            {groups.reduce((mainCategories, option, index) => {
                                const { idItem, label, permissionList = [] } = option;
                                const parentOption = { ...option, number: index };
                                const categoryOptions = this.renderOptions(parentOption);
                                const optionsAmount = amountsById[idItem];
                                const [permission] = permissionList;

                                if (
                                    (permission && permission.advancedAllowProductSelection) ||
                                    categoryOptions.length
                                ) {
                                    if (optionsAmount === 0) {
                                        return mainCategories;
                                    }

                                    if (permission) {
                                        return [
                                            ...mainCategories,
                                            <li key={idItem} className="navigational-list-item list-item--noChilds">
                                                <PermissionField
                                                    key={option.idItem}
                                                    {...option}
                                                    number={index}
                                                    mode="view"
                                                />
                                            </li>,
                                        ];
                                    }

                                    return [
                                        ...mainCategories,
                                        <Accordion.Item
                                            key={idItem}
                                            number={index}
                                            item={
                                                <span>
                                                    <span>{label}</span>{" "}
                                                    <span className="list-item-hint">{optionsAmount}</span>
                                                </span>
                                            }>
                                            <ul className="list">{categoryOptions}</ul>
                                        </Accordion.Item>,
                                    ];
                                }

                                if (!optionsAmount) {
                                    return mainCategories;
                                }

                                return [...mainCategories, this.renderItem(option)];
                            }, [])}
                        </Accordion>
                    );
                }}
            </AdvancedPermissionsAmount>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    permissions: ownProps.permissions || permissionsSelectors.getPermissions(state),
    groups: permissionsSelectors.getGroups(state),
});

export default connect(mapStateToProps)(AdvancedPermissionsList);
