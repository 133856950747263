import React, { Component } from "react";
import { node } from "prop-types";
import Tabs from "pages/_components/Tabs";

class ProductsTabs extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    render() {
        const { children } = this.props;
        return <Tabs className="pt-0" components={children} />;
    }
}

export default ProductsTabs;
