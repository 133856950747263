import { createReducer, makeActionCreator } from "util/redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export const walletTypes = {
    ENABLED_WALLET_PRE_REQUEST: "wallet/ENABLED_WALLET_PRE_REQUEST",
    ENABLED_WALLET_PRE_SUCCESS: "wallet/ENABLED_WALLET_PRE_SUCCESS",
    ENABLED_WALLET_PRE_FAILURE: "wallet/ENABLED_WALLET_PRE_FAILURE",

    ENABLED_WALLET_SEND_REQUEST: "wallet/ENABLED_WALLET_SEND_REQUEST",
    ENABLED_WALLET_SEND_SUCCESS: "wallet/ENABLED_WALLET_SEND_SUCCESS",
    ENABLED_WALLET_SEND_FAILURE: "wallet/ENABLED_WALLET_SEND_FAILURE",

    GET_PARAMETER_BANNER_WALLET_REQUEST: "wallet/GET_PARAMETER_BANNER_WALLET_REQUEST",
    GET_PARAMETER_BANNER_WALLET_SET: "wallet/GET_PARAMETER_BANNER_WALLET_SET",
    HIDDEN_BANNER_WALLET_SET: "wallet/HIDDEN_BANNER_WALLET_SET",
};

export const INITIAL_STATE = {
    fetching: false,
    credentialsGroups: undefined,
    cardWalletData: undefined,
    bannerProduct: undefined,
    hiddenBanner: undefined,
};

export const selectors = {
    isFetching: ({ wallet }) => wallet?.fetching || false,
    getCredentialsGroups: ({ wallet }) => wallet?.credentialsGroups || [],
    getCardWalletData: ({ wallet }) => wallet?.cardWalletData,
    getBannerProduct: ({ wallet }) => wallet?.bannerProduct,
    isHiddenBanner: ({ wallet }) => wallet?.hiddenBanner,
};

export const actions = {
    enabledWalletPreRequest: makeActionCreator(
        walletTypes.ENABLED_WALLET_PRE_REQUEST,
        "callbackError",
        "scopeError",
        "activityId",
    ),
    enabledWalletSendRequest: makeActionCreator(
        walletTypes.ENABLED_WALLET_SEND_REQUEST,
        "callbackError",
        "scopeError",
        "cardId",
        "cardType",
        "otp",
    ),
    getParameterBannerWallet: makeActionCreator(walletTypes.GET_PARAMETER_BANNER_WALLET_REQUEST),
    setParameterBannerWallet: makeActionCreator(walletTypes.GET_PARAMETER_BANNER_WALLET_SET, "bannerProduct"),
    setHiddenBanner: makeActionCreator(walletTypes.HIDDEN_BANNER_WALLET_SET, "hiddenBanner"),
};

const reducer = createReducer(INITIAL_STATE, {
    [walletTypes.ENABLED_WALLET_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        cardWalletData: undefined,
    }),
    [walletTypes.ENABLED_WALLET_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        credentialsGroups: action?.credentialsGroups,
    }),
    [walletTypes.ENABLED_WALLET_PRE_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        credentialsGroups: undefined,
    }),
    [walletTypes.ENABLED_WALLET_SEND_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        cardWalletData: undefined,
    }),
    [walletTypes.ENABLED_WALLET_SEND_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        cardWalletData: undefined,
    }),
    [walletTypes.ENABLED_WALLET_SEND_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        cardWalletData: action?.cardWalletData,
    }),
    [walletTypes.GET_PARAMETER_BANNER_WALLET_SET]: (state, action) => ({
        ...state,
        bannerProduct: action?.bannerProduct,
    }),
    [walletTypes.HIDDEN_BANNER_WALLET_SET]: (state, action) => ({
        ...state,
        hiddenBanner: action?.hiddenBanner,
    }),
});
export default persistReducer(
    {
        storage,
        key: "wallet",
        whitelist: ["hiddenBanner"],
    },
    reducer,
);
