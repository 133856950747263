import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, number } from "prop-types";
import React from "react";

const ProgressBar = ({ completed, isDesktop }) => {
    const fillerStyles = {
        width: `${completed}%`,
    };

    return (
        <Box display="flex" column fullWidth>
            <Box display="flex" fullWidth className="mb-2">
                <Text
                    size={isDesktop ? "7" : "6"}
                    color="inverse"
                    uppercase
                    PERCENT_COMPLETE={completed}
                    labelKey="products.detail.progressbar.completed"
                />
            </Box>
            <Box className="progress-bar-container">
                <div className="progress-bar-completed" style={fillerStyles} />
                <div className="progress-bar-background" />
            </Box>
        </Box>
    );
};

ProgressBar.propTypes = {
    isDesktop: bool.isRequired,
    completed: number,
};

ProgressBar.defaultProps = {
    completed: 0,
};

export default resizableRoute(ProgressBar);
