import * as campaigns from "middleware/campaigns";

import { call, put, takeLatest } from "redux-saga/effects";

import { types } from "reducers/campaigns";
import { getDisplay } from "util/device";

const sagas = [
    takeLatest(types.LIST_CAMPAIGN_REQUEST, listCampaign),
    takeLatest(types.LOAD_CAMPAIGN_REQUEST, loadCampaign),
    takeLatest(types.LOAD_CAMPAIGN_ANONYMOUS_REQUEST, loadCampaignAnonymous),
    takeLatest(types.LOAD_CAMPAIGN_IMAGE, loadCampaignImage),
    takeLatest(types.DISMISS_CAMPAIGN_PERMANENT_REQUEST, dismissPermanentCampaign),
];

export default sagas;

function* listCampaign({ section }) {
    try {
        const response = yield call(campaigns.listCampaigns, section, getDisplay());
        if (response.status === 304 || !response?.data?.data?.campaigns) {
            yield put({ type: types.LIST_CAMPAIGN_FAILURE });
            yield;
            return;
        }
        yield put({ type: types.LIST_CAMPAIGN_SUCCESS, items: response.data.data.campaigns });
    } catch (err) {
        yield put({ type: types.LIST_CAMPAIGN_FAILURE });
    }
}

function* loadCampaign({ section }) {
    try {
        const response = yield call(campaigns.fetchCampaign, section);
        if (response.status === 304 || !response?.data?.data?.campaign) {
            yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
            yield;
            return;
        }

        yield put({ type: types.LOAD_CAMPAIGN_IMAGE, item: response.data.data.campaign });
    } catch (err) {
        yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
    }
}

function* loadCampaignAnonymous({ section }) {
    try {
        const response = yield call(campaigns.fetchCampaignAnonymous, section);
        if (response.status === 304 || !response?.data?.data?.campaign) {
            yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
            yield;
            return;
        }

        yield put({ type: types.LOAD_CAMPAIGN_IMAGE, item: response.data.data.campaign });
    } catch (err) {
        yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
    }
}

function getFittingImage([mobileImage, tabletImage, desktopImage]) {
    if (!tabletImage && !desktopImage) {
        return mobileImage;
    }
    switch (getDisplay()) {
        case "mobile":
            return mobileImage;
        case "tablet":
            return tabletImage;
        default:
            return desktopImage;
    }
}

function* loadCampaignImage({ item }) {
    try {
        const image = getFittingImage(item.imageList);
        if (!image) {
            yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
        } else {
            const { idImage, idCampaign } = image;
            const response = yield call(campaigns.fetchCampaignImage, { idImage, idCampaign });

            if (response.status !== 304) {
                const { data } = response.data;
                yield put({ type: types.LOAD_CAMPAIGN_IMAGE_SUCCESS, item: { ...item, image: data.image.content } });
            }
        }
    } catch (err) {
        yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
    }
}

function* dismissPermanentCampaign(idCampaign) {
    try {
        const response = yield call(campaigns.dismissPermanentCampaign, idCampaign);

        if (response.data.code !== "COR000I") {
            yield put({ type: types.DISMISS_CAMPAIGN_PERMANENT_SUCCESS });
        } else {
            yield put({ type: types.DISMISS_CAMPAIGN_PERMANENT_FAILURE });
        }
    } catch (err) {
        yield put({ type: types.DISMISS_CAMPAIGN_PERMANENT_FAILURE });
    }
}
