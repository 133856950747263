import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { actions as sessionActions } from "reducers/session";
import { selectors, actions as statusActions } from "reducers/status";
import moment from "moment";

class SessionAboutToExpire extends Component {
    static getDerivedStateFromProps({ status }, { seconds }) {
        if (status.sessionAboutToExpire && seconds === 0) {
            return { seconds: status.sessionSecondsToExpire };
        }
        if (!status.sessionAboutToExpire && seconds !== 0) {
            return { seconds: 0 };
        }
        return null;
    }

    state = { seconds: 0 };

    interval = null;

    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        location: shape({
            href: string,
        }).isRequired,
        status: shape({
            sessionAboutToExpire: bool.isRequired,
        }).isRequired,
        isMobile: bool.isRequired,
    };

    componentDidUpdate() {
        const { status } = this.props;
        if (!this.interval && status.sessionAboutToExpire) {
            this.interval = setInterval(this.decreaseSeconds, 1000);
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    decreaseSeconds = () => {
        const { seconds } = this.state;
        if (seconds > 1) {
            this.setState((prevState) => ({ seconds: prevState.seconds - 1 }));
        } else {
            this.closeSession();
        }
    };

    closeSession = () => {
        const { location, dispatch, isMobile } = this.props;
        if (isMobile) {
            dispatch(statusActions.changeGoToLoginStep1());
        }
        dispatch(sessionActions.expire(location.href));
    };

    extendSession = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.extend());
        clearInterval(this.interval);
        this.interval = null;
    };

    render() {
        const { status, isDesktop } = this.props;
        const { seconds } = this.state;
        const formatView = ((seconds >= 86400 && "DD ") || "") + ((seconds >= 3600 && "hh:") || "") + (true && "mm:ss");
        const formatted = moment.utc(seconds * 1000).format(formatView);
        return (
            <div className="modal-container">
                <Modal show={status.sessionAboutToExpire}>
                    <Box className="p-7">
                        <Box display="flex" fullWidth>
                            <Text size="5" bold color="heading" labelKey="session.aboutToExpire" />
                        </Box>
                        <Box display="flex" alignX="center" className="my-9 mx-md-12">
                            <Image src="images/icons/stopwatch.svg" wrapperClassName="fit-content svg-wrapper mr-6" />
                            <Box display="flex" column>
                                <Text size="5" color="text" labelKey="session.expires.in" />
                                <Text size="5" color="text" bold>
                                    {formatted} seg
                                </Text>
                            </Box>
                        </Box>

                        <Box display="flex" column fullWidth>
                            {isDesktop ? (
                                <Row className="px-0">
                                    <Col xs={6}>
                                        <Button
                                            label="session.expires.logout"
                                            bsStyle="outline"
                                            onClick={this.closeSession}
                                            block
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <Button
                                            label="session.extend"
                                            bsStyle="primary"
                                            onClick={this.extendSession}
                                            block
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <>
                                    <Button
                                        label="session.extend"
                                        bsStyle="primary"
                                        onClick={this.extendSession}
                                        block
                                    />
                                    <Button
                                        label="session.expires.logout"
                                        bsStyle="outline"
                                        onClick={this.closeSession}
                                        block
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({ status: selectors.getStatus(state) });
export default connect(mapStateToProps)(resizableRoute(SessionAboutToExpire));
