import { call, put, takeLatest } from "redux-saga/effects";
import { types, actions } from "reducers/frequentDestination/frequentDestination";
import { replace, push } from "react-router-redux";
import { actions as notificationActions } from "reducers/notification";
import * as frequentDestination from "middleware/frequentDestination";
import { adjustIdFieldErrors } from "util/form";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.FREQUENT_DESTINATION_PRE_REQUEST, frequentDestinationPre),

    takeLatest(types.LOAD_LIST_REQUEST, loadListRequest),
    takeLatest(types.LOAD_MORE_REQUEST, loadMoreRequest),

    takeLatest(types.CREATE_REQUEST, frequentDestinationCreate),

    takeLatest(types.MODIFY_REQUEST, modifyFrequentDestinationRequest),
    takeLatest(types.DELETE_REQUEST, deleteFrequentDestination),
    takeLatest(types.DELETE_SELECTED_REQUEST, deleteSeletedFrequentDestination),
    takeLatest(types.GET_BENEFICIARY_LIST_REQUEST, getBeneficiaryList),
    takeLatest(types.GET_BENEFICIARY_REQUEST, getCurrentBeneficiary),
    takeLatest(types.GET_BENEFICIARY_FORM_PRE_REQUEST, getPreDataForm),
    takeLatest(types.DELETE_BENEFICIARY_REQUEST, deleteBeneficiary),
    takeLatest(types.SETFAVORITE_BENEFICIARY_REQUEST, setFavoriteBeneficiary),
];

export default sagas;

function* frequentDestinationPre({ callback }) {
    const response = yield call(frequentDestination.frequentDestinationPre);
    if (response.type === "W") {
        yield put(replace(`/`));
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["frequentDestination"]),
        );
    } else {
        yield put({
            type: types.FREQUENT_DESTINATION_PRE_REQUEST_SUCCESS,
            data: response.data.data,
        });

        if (callback) {
            callback();
        }
    }
}

function* loadListRequest(params) {
    const { filters } = params;
    const response = yield call(frequentDestination.loadListRequest, filters);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["frequentDestination"]),
        );
    } else {
        yield put(actions.loadListSuccess(response.data.data));
    }
}

function* loadMoreRequest(params) {
    const { filters } = params;
    const response = yield call(frequentDestination.loadListRequest, filters);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["frequentDestination"]),
        );
    } else {
        yield put(actions.loadListMoreSuccess(response.data.data));
    }
}

function* frequentDestinationCreate(params) {
    const { setSubmitting } = params.formikBag;
    const {
        number,
        idEnvironment,
        idFrequentDestination,
        localBank,
        name,
        productType,
        recipientDocumentType,
        recipientDocument,
        recipientDocumentCountry,
        recipientAddress,
        recipientName,
        foreignBank,
        extraData,
    } = params.params;

    const response = yield call(
        frequentDestination.createDestination,
        number,
        idEnvironment,
        idFrequentDestination,
        localBank,
        name,
        productType,
        recipientDocumentType,
        recipientDocument,
        recipientDocumentCountry,
        recipientAddress,
        recipientName,
        foreignBank,
        extraData,
    );

    setSubmitting(false);
    if (response.type === "W") {
        yield put(actions.createFailure());
        const { data } = response.data;

        if (data) {
            params.formikBag.setErrors(adjustIdFieldErrors(data));
            yield put(
                notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", [
                    "frequentDestination.create",
                ]),
            );
        }
    } else {
        yield put(actions.createSuccess(response.data.data));
        yield put(replace(`/frequentDestination`));
        yield put(
            notificationActions.showNotification(i18n.get("frequentDestination.create.success"), "success", [
                "frequentDestination",
            ]),
        );
    }
}

function* deleteFrequentDestination(params) {
    const response = yield call(frequentDestination.deleteRequest, params);
    if (response.type === "W") {
        yield put(actions.deleteFailure());
        if (response.data.code === "COR020W") {
            params.formikBag.setErrors(response.data.data);
        } else {
            yield put(
                notificationActions.showNotification(response.data.message, "error", [
                    "frequentDestination.detailModifyDelete",
                ]),
            );
        }
    } else {
        yield put(actions.deleteSuccess());
        yield put(push(`/frequentDestination`));
        yield put(
            notificationActions.showNotification(i18n.get("frequentDestination.delete.success"), "success", [
                "frequentDestination",
            ]),
        );
    }
}

function* deleteSeletedFrequentDestination(listToDelete) {
    const response = yield call(frequentDestination.deleteSelectedRequest, listToDelete);

    if (response.type === "W") {
        yield put(actions.deleteSelectedFailure());
        yield put(notificationActions.showNotification(response.data.message, "error", ["frequentDestination"]));
    } else {
        yield put(actions.deleteSelectedSuccess(response.data));
        yield put(
            notificationActions.showNotification(i18n.get("frequentDestination.delete.success"), "success", [
                "frequentDestination",
            ]),
        );
    }
}

function* modifyFrequentDestinationRequest(params) {
    const response = yield call(frequentDestination.modifyFrequentDestination, params);
    if (response.type === "W") {
        yield put(actions.modifyFailure());

        const { data } = response.data;

        if (data) {
            params.formikBag.setErrors(adjustIdFieldErrors(data));
            yield put(
                notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", [
                    "frequentDestination.detailModifyDelete",
                ]),
            );
        }
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("frequentDestination.modify.success"), "success", [
                "frequentDestination",
            ]),
        );
        yield put(actions.modifySuccess());
        yield put(push(`/frequentDestination`));
    }
}

function* getBeneficiaryList({ filters }) {
    const response = yield call(frequentDestination.getBeneficiaryList, filters);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({ type: types.GET_BENEFICIARY_LIST_FAILURE });
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
            yield put(replace("/desktop"));
            yield put({ type: types.GET_BENEFICIARY_LIST_FAILURE });
        } else {
            yield put({
                type: types.GET_BENEFICIARY_LIST_SUCCESS,
                payload: {
                    beneficiaryList: response?.data?.data?.beneficiaryList?.elementList || [],
                    totalPages: response?.data?.data?.beneficiaryList?.totalPages || 0,
                    page: filters?.pageNumber || 0,
                },
            });
        }
    }
}

function* getCurrentBeneficiary({ beneficiaryId }) {
    const response = yield call(frequentDestination.getCurrentBeneficiary, beneficiaryId);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({ type: types.GET_BENEFICIARY_LIST_FAILURE });
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
            yield put(replace("/desktop"));
            yield put({ type: types.GET_BENEFICIARY_FAILURE });
        } else {
            yield put({
                type: types.GET_BENEFICIARY_SUCCESS,
                payload: {
                    currentBeneficiary: response?.data?.data?.beneficiary,
                },
            });
        }
    }
}

function* getPreDataForm({ beneficiaryId, activityId }) {
    const response = yield call(frequentDestination.getPreDataForm, beneficiaryId, activityId);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({ type: types.GET_BENEFICIARY_FORM_PRE_FAILURE });
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
            yield put(replace("/desktop"));
            yield put({ type: types.GET_BENEFICIARY_FORM_PRE_FAILURE });
        } else {
            yield put({
                type: types.GET_BENEFICIARY_FORM_PRE_SUCCESS,
                payload: {
                    preDataForm: response?.data?.data,
                },
            });
        }
    }
}

function* deleteBeneficiary({ beneficiaryId, beneficiaryType }) {
    const response = yield call(frequentDestination.deleteBeneficiary, beneficiaryId, beneficiaryType);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({ type: types.DELETE_BENEFICIARY_FAILURE });
        yield put(replace("/desktop"));
    } else {
        const { type, data } = response;
        if (type === "W") {
            if (data && data.code && data.code === "API539W") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("beneficiary.notfound"),
                        "error",
                        ["desktop", "viewFrequentDestination", "menu"],
                        true,
                        1000,
                    ),
                );
            } else if (data && data.code && data.code === "API540W") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("beneficiary.delete.error"),
                        "error",
                        ["desktop", "viewFrequentDestination", "menu"],
                        true,
                        1000,
                    ),
                );
            } else {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("global.unexpectedError"),
                        "error",
                        ["desktop"],
                        true,
                        1000,
                    ),
                );
                yield put(replace("/desktop"));
            }

            yield put({ type: types.DELETE_BENEFICIARY_FAILURE });
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("beneficiary.delete.success"),
                    "success",
                    ["desktop", "frequentDestination", "menu"],
                    true,
                    1000,
                ),
            );

            yield put({
                type: types.DELETE_BENEFICIARY_SUCCESS,
            });
            yield put(replace("/frequentDestination"));
        }
    }
}

function* setFavoriteBeneficiary({ beneficiaryId, favorite }) {
    const response = yield call(frequentDestination.setFavoriteBeneficiary, beneficiaryId, favorite);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({ type: types.DELETE_BENEFICIARY_FAILURE });
        yield put(replace("/desktop"));
    } else {
        const { type, data } = response;
        if (type === "W") {
            if (data && data.code && data.code === "API541W") {
                yield put(
                    notificationActions.showNotification(i18n.get("beneficiary.setFavorite.error"), "error", [
                        "desktop",
                        "frequentDestination",
                        "menu",
                    ]),
                );
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]),
                );
                yield put(replace("/desktop"));
            }

            yield put({ type: types.SETFAVORITE_BENEFICIARY_FAILURE });
        } else {
            yield put({
                type: types.SETFAVORITE_BENEFICIARY_SUCCESS,
                payload: { beneficiaryId, favorite },
            });
        }
    }
}
