import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import React, { Component } from "react";
import { string } from "prop-types";

class NoResults extends Component {
    static propTypes = {
        message: string.isRequired,
    };

    render() {
        const { message } = this.props;
        return (
            <Box
                display="flex"
                alignX="center"
                alignY="center"
                background="white"
                borderRadius="default"
                border="dashed-image-border-base-color"
                className="py-8"
                fullWidth>
                <Text labelKey={message} size="6" align="center" color="heading" />
            </Box>
        );
    }
}

export default NoResults;
