/* eslint-disable */
import { bool, func, shape, string, arrayOf } from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux/actions";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import * as i18n from "util/i18n";

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import PhoneInput from "pages/_components/fields/PhoneInput";
import TextField from "pages/_components/fields/TextField";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import SwitchToggle from "pages/_components/SwitchToggle";
import Text from "pages/_components/Text";

import { actions as wallyActions, selectors as wallySelectors } from "reducers/wally";
import { actions as notificationActions } from "reducers/notification";
import { selectors as loginSelectors } from "reducers/login";

import { REGION_REST_OF_LATAM, REGION_USA } from "constants.js";
import { saveContact, requestWritePermission } from "util/contact.util";
import { formatAccount } from "./hooks/WallyHooks";

const SendWally = (props) => {
    const { dispatch, history, fetching, accountWally, activeRegion, beneficiariesPeople } = props;
    const isRequestAddContact = location.hash === "#/sendWallyRequestAddContact" || location.pathname === "/sendWallyRequestAddContact";
    const [isActived, setIsActived] = useState(true);
    const [isSearch, setIsSearch] = useState(false);
    const [contactName, setContactName] = useState("");
    const [responseWriteRequest, setResponseWriteRequest] = useState(false);
    const [listPeople, setListPeople] = useState([]);

    let country = REGION_USA;
    if (activeRegion !== REGION_REST_OF_LATAM) {
        country = activeRegion.toLowerCase();
    }

    useEffect(() => {
        requestSavePhone();
        setListPeople(beneficiariesPeople)
    }, []);

    useEffect(() => {
        if (!fetching && isSearch) {
            if (beneficiariesPeople.length > 0) {
                if (isActived && responseWriteRequest) {
                    const isSaved= listPeople.some(el=>el.phoneNumber===beneficiariesPeople[0].phoneNumber)
                    if (!isSaved) {
                        handleSavePhone({
                            firstName: contactName ? contactName : beneficiariesPeople[0].fullName ,
                            familyName: "",
                            organizationName: "",
                            phoneNumbers: [
                                {
                                    type: "mobile",
                                    value: beneficiariesPeople[0].phoneNumber,
                                },
                            ],
                        });
                    dispatch(wallyActions.setSelectedBeneficiary(beneficiariesPeople[0]));
                    dispatch(push(isRequestAddContact?"/sendWallyRequestTransaction":"/sendWallyTransaction"));
                    }else{
                        dispatch(
                            notificationActions.showNotification(
                                i18n.get("wally.error.exist.contact"),
                                "error",
                                ["wally"],
                                false,
                            ),
                        );
                    }
                }else{
                    dispatch(wallyActions.setSelectedBeneficiary(beneficiariesPeople[0]));
                    dispatch(push(isRequestAddContact?"/sendWallyRequestTransaction":"/sendWallyTransaction"));
                }
                
            } else {
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("wally.error.without.contact"),
                        "error",
                        ["wally"],
                        false,
                    ),
                );
            }
            setIsSearch(false);
            dispatch(wallyActions.listBeneficiariesPeopleRequest([]));
        }
    }, [fetching]);

    const requestSavePhone = async () => {
        const responseWriteRequest = await requestWritePermission();
        setResponseWriteRequest(responseWriteRequest);
    };

    const handleSavePhone = async (contact) => {
        saveContact(contact);
    };

    const handleBack = () => {
        history.goBack();
    };

    const handleCancel = () => {
        dispatch(push("/desktop"));
    };

    const handleSubmit = ({ cellPhone, name }) => {
        setIsSearch(true);
        setContactName(name);
        const phone = cellPhone.prefix + cellPhone.areaCode + cellPhone.value;

        dispatch(wallyActions.listBeneficiariesPeopleRequest([phone]));
    };

    const validationSchema = () =>
        Yup.lazy(() =>
            Yup.object().shape({
                cellPhone: Yup.object().shape({
                    value: Yup.string().required(i18n.get("wally.cellPhone.error.empty")),
                }),
            }),
        );

    return (
        <PageLoading loading={fetching}>
            <Head
                titleImage="wally-head.svg"
                subtitlePrefix={accountWally?.productAlias}
                subtitle={formatAccount(accountWally)}
                onBack={handleBack}
                title={isRequestAddContact?"wally.forms.requestTransfers.title":"forms.transfers.wally.title"}
            />
            <Notification scopeToShow="wally" />
            <MainContainer>
                <Box display="flex" column fullWidth fullHeight className="pt-7">
                    <Box display="flex" column alignX="center" alignY="center" className="pb-6">
                        <Text bold size="2" align="center" color="heading">
                            {i18n.get("wally.add.contact")}
                        </Text>
                    </Box>
                    <Formik
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={validationSchema}
                        initialValues={{ cellPhone: { prefix: "", areaCode: "", value: "" }, name: "" }}
                        onSubmit={handleSubmit}>
                        <Form className="full-height">
                            <Box display="flex" column fullWidth fullHeight>
                                <Box background="white" className="pt-1 pb-9 px-5 mx-n-5 mb-3" borderRadius="default">
                                    <Field
                                        idForm="wally"
                                        name="cellPhone"
                                        component={PhoneInput}
                                        country={country}
                                        placeholder=""
                                    />
                                </Box>
                                <Box
                                    background="white"
                                    display="flex"
                                    column
                                    className="px-5 mb-9 mx-n-5"
                                    borderRadius="default">
                                    <Box className="pb-7">
                                        <Field
                                            name="name"
                                            component={TextField}
                                            optional={i18n.get("wally.name.optional")}
                                            placeholder=""
                                            labelText={i18n.get("wally.name.label")}
                                        />
                                    </Box>
                                    <Box fullWidth className="pb-7">
                                        <SwitchToggle
                                            switchIsOn={isActived}
                                            handleToggle={() => setIsActived(!isActived)}
                                            labelOnKey="wally.switch.contact.label"
                                            labelOffKey="wally.switch.contact.label"
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" column fullWidth className="mt-auto">
                                    <Button bsStyle="primary" block type="submit">
                                        {i18n.get("wally.continue.button")}
                                    </Button>
                                    <Button
                                        label="wally.select.button.cancel"
                                        onClick={handleCancel}
                                        bsStyle="outline"
                                        block
                                    />
                                </Box>
                            </Box>
                        </Form>
                    </Formik>
                </Box>
            </MainContainer>
        </PageLoading>
    );
};

const mapStateToProps = (state) => ({
    accountWally: wallySelectors.getAccountWally(state),
    activeRegion: loginSelectors.getRegion(state),
    beneficiariesPeople: wallySelectors.getBeneficiariesPeople(state),
    fetching: wallySelectors.isFetchingPeople(state),
});

SendWally.propTypes = {
    dispatch: func.isRequired,
    accountWally: shape({}),
    beneficiariesPeople: arrayOf(shape({})),
    history: shape({}).isRequired,
    fetching: bool,
    activeRegion: string,
};

SendWally.defaultProps = {
    beneficiariesPeople: [],
    fetching: false,
    accountWally: {},
    activeRegion: "",
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(SendWally));
