import { resizableRoute } from "pages/_components/Resizable";
import { func, shape } from "prop-types";
import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as notificationActions } from "reducers/notification";
import { actions as statusActions } from "reducers/status";
import { isMobileNativeFunc } from "util/device";

const BackButtonListener = ({ history, location, dispatch }) => {
    const validateCloseSessionMobilePath = () => {
        if (location?.pathname && location.pathname === "/desktop") {
            return true;
        }

        if (
            location?.state?.isFromPublicPath !== undefined &&
            (location.state.isFromPublicPath === true || location.state.isFromPublicPath === "true")
        ) {
            return true;
        }

        return false;
    };

    const backButtonAction = () => {
        const closeSession = validateCloseSessionMobilePath();
        if (closeSession !== undefined && closeSession === true) {
            dispatch(statusActions.showSessionCloseModal());
            return;
        }

        dispatch(notificationActions.removeNotification());
        history.goBack();
    };
    useEffect(() => {
        if (isMobileNativeFunc()) {
            document.addEventListener("backbutton", backButtonAction);
        }

        return () => {
            if (isMobileNativeFunc()) {
                document.removeEventListener("backbutton", backButtonAction);
            }
        };
    }, []);

    return null;
};

BackButtonListener.propTypes = {
    dispatch: func.isRequired,
    history: shape({}).isRequired,
    location: shape({}).isRequired,
};

export default connect()(resizableRoute(withRouter(BackButtonListener)));
