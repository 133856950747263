import React, { Component } from "react";
import { string } from "prop-types";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";

class InfoMessage extends Component {
    static propTypes = {
        labelKey: string,
        label: string,
        size: string,
    };

    static defaultProps = {
        labelKey: undefined,
        label: undefined,
        size: "6",
    };

    render() {
        const { labelKey, label, size } = this.props;

        return (
            <Box display="flex">
                <Box className="p-2">
                    <Image src="images/icons/info.svg" />
                </Box>
                <Box className={`pl-4 size-${size}`} fullWidth>
                    {label || <Text labelKey={labelKey} />}
                </Box>
            </Box>
        );
    }
}

export default InfoMessage;
