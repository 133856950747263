import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LIST_PAYSERVICE_REQUEST: "payService/LIST_PAYSERVICE_REQUEST",
    LIST_PAYSERVICE_FAILURE: "payService/LIST_PAYSERVICE_FAILURE",
    LIST_PAYSERVICE_SUCCESS: "payService/LIST_PAYSERVICE_SUCCESS",
    LIST_FAST_RECHARGE_SUCCESS: "payService/LIST_FAST_RECHARGE_SUCCESS",
    FAVORITE_REQUEST: "payService/FAVORITE_REQUEST",
    FAVORITE_SUCCESS: "payService/FAVORITE_SUCCESS",
    FAVORITE_FAILURE: "payService/FAVORITE_FAILURE",
    UPDATE_REQUEST: "payService/UPDATE_REQUEST",
    UPDATE_SUCCESS: "payService/UPDATE_SUCCESS",
    UPDATE_FAILURE: "payService/UPDATE_FAILURE",
    CREATE_REQUEST: "payService/CREATE_REQUEST",
    CREATE_SUCCESS: "payService/CREATE_SUCCESS",
    CREATE_FAILURE: "payService/CREATE_FAILURE",
    GET_REQUEST: "payService/GET_REQUEST",
    GET_SUCCESS: "payService/GET_SUCCESS",
    GET_FAILURE: "payService/GET_FAILURE",
    DELETE_REQUEST: "payService/DELETE_REQUEST",
    DELETE_SUCCESS: "payService/DELETE_SUCCESS",
    DELETE_FAILURE: "payService/DELETE_FAILURE",
    LIST_CATEGORIES_REQUEST: "payService/LIST_CATEGORIES_REQUEST",
    LIST_CATEGORIES_FAILURE: "payService/LIST_CATEGORIES_FAILURE",
    LIST_CATEGORIES_SUCCESS: "payService/LIST_CATEGORIES_SUCCESS",
    SET_SELECTED_BILLER: "payService/SET_SELECTED_BILLER",
    LIST_RULES_BILLER_REQUEST: "payService/LIST_RULES_BILLER_REQUEST",
    LIST_RULES_BILLER_FAILURE: "payService/LIST_RULES_BILLER_FAILURE",
    LIST_RULES_BILLER_SUCCESS: "payService/LIST_RULES_BILLER_SUCCESS",
    SET_QUERY_BILL_PRE: "payService/SET_QUERY_BILL_PRE",
    CLEAN_CACHE_PAYSERVICE: "payService/CLEAN_CACHE_PAYSERVICE",
    IS_FAST_RECHARGE: "payService/IS_FAST_RECHARGE",
    IS_LINK_FAST_RECHARGE: "payService/IS_LINK_FAST_RECHARGE",
    IS_FETCHING_FAST_RECHARGE: "payService/IS_FETCHING_FAST_RECHARGE",
    IS_SHOW_ALL_RECHARGES: "payService/IS_SHOW_ALL_RECHARGES",
};

export const INITIAL_STATE = {
    fetching: false,
    fetchingFavorite: false,
    listPayService: [],
    listFastRecharge: [],
    payService: null,
    listCategories: [],
    selectedBiller: null,
    field: [],
    queryBill: null,
    isFastRecharge: false,
    isLinkFastRecharge: false,
    isFetchingFastRecharge: false,
    isShowAllRecharges: true,
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_PAYSERVICE_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.LIST_PAYSERVICE_FAILURE]: (state) => ({ ...state, fetching: false, listPayService: [] }),
    [types.LIST_PAYSERVICE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        listPayService: action.list,
    }),
    [types.LIST_FAST_RECHARGE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        listFastRecharge: action.list,
    }),

    [types.FAVORITE_REQUEST]: (state) => ({ ...state, fetchingFavorite: true }),
    [types.FAVORITE_SUCCESS]: (state, action) => ({ ...state, listPayService: action.list, fetchingFavorite: false }),
    [types.FAVORITE_FAILURE]: (state) => ({ ...state, fetchingFavorite: false }),

    [types.UPDATE_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.UPDATE_SUCCESS]: (state) => ({ ...state, fetching: false }),
    [types.UPDATE_FAILURE]: (state) => ({ ...state, fetching: false }),

    [types.CREATE_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.CREATE_SUCCESS]: (state) => ({ ...state, fetching: false }),
    [types.CREATE_FAILURE]: (state) => ({ ...state, fetching: false }),

    [types.GET_REQUEST]: (state) => ({ ...state, fetching: true, payService: null }),
    [types.GET_FAILURE]: (state) => ({ ...state, fetching: false, payService: null }),
    [types.GET_SUCCESS]: (state, action) => ({ ...state, fetching: false, payService: action.object }),

    [types.DELETE_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.DELETE_SUCCESS]: (state, action) => ({
        ...state,
        listPayService: action.list,
        listFastRecharge: action.listFastRecharge,
        fetching: false,
    }),
    [types.DELETE_FAILURE]: (state) => ({ ...state, fetching: false }),

    [types.LIST_CATEGORIES_REQUEST]: (state) => ({ ...state, fetching: true, listCategories: [] }),
    [types.LIST_CATEGORIES_FAILURE]: (state) => ({ ...state, fetching: false, listCategories: [] }),
    [types.LIST_CATEGORIES_SUCCESS]: (state, action) => ({ ...state, fetching: false, listCategories: action.list }),

    [types.SET_SELECTED_BILLER]: (state, action) => ({ ...state, selectedBiller: action.biller }),

    [types.LIST_RULES_BILLER_REQUEST]: (state) => ({ ...state, fetching: true, field: [] }),
    [types.LIST_RULES_BILLER_FAILURE]: (state) => ({ ...state, fetching: false, field: [] }),
    [types.LIST_RULES_BILLER_SUCCESS]: (state, action) => ({ ...state, fetching: false, field: action.list }),

    [types.SET_QUERY_BILL_PRE]: (state, action) => ({ ...state, queryBill: action.object }),
    [types.CLEAN_CACHE_PAYSERVICE]: (state) => ({ ...state, payService: null, queryBill: null }),
    [types.IS_FAST_RECHARGE]: (state, action) => ({ ...state, isFastRecharge: action.isFastRecharge }),
    [types.IS_LINK_FAST_RECHARGE]: (state, action) => ({ ...state, isLinkFastRecharge: action.isLinkFastRecharge }),
    [types.IS_FETCHING_FAST_RECHARGE]: (state, action) => ({
        ...state,
        isFetchingFastRecharge: action.isFetchingFastRecharge,
    }),
    [types.IS_SHOW_ALL_RECHARGES]: (state, action) => ({
        ...state,
        isShowAllRecharges: action.isShowAllRecharges,
    }),
});

export const actions = {
    cleanCachePayService: makeActionCreator(types.CLEAN_CACHE_PAYSERVICE),
    listPayServiceRequest: makeActionCreator(types.LIST_PAYSERVICE_REQUEST, "isFastRecharge"),
    listPayServiceFailure: makeActionCreator(types.LIST_PAYSERVICE_FAILURE),
    listPayServiceSuccess: makeActionCreator(types.LIST_PAYSERVICE_SUCCESS, "list"),
    listFastRechargeSuccess: makeActionCreator(types.LIST_FAST_RECHARGE_SUCCESS, "list"),

    favoriteRequest: makeActionCreator(types.FAVORITE_REQUEST, "payServiceObj"),
    favoriteSuccess: makeActionCreator(types.FAVORITE_SUCCESS, "list"),

    updateRequest: makeActionCreator(types.UPDATE_REQUEST, "payServiceObj"),
    updateSuccess: makeActionCreator(types.UPDATE_SUCCESS),

    createRequest: makeActionCreator(types.CREATE_REQUEST, "payServiceObj"),
    createSuccess: makeActionCreator(types.CREATE_SUCCESS),

    getPayServiceRequest: makeActionCreator(types.GET_REQUEST, "idPaymentUser"),
    getPayServiceFailure: makeActionCreator(types.GET_FAILURE),
    getPayServiceSuccess: makeActionCreator(types.GET_SUCCESS, "object"),

    deleteRequest: makeActionCreator(types.DELETE_REQUEST, "payServiceObj"),
    deleteSuccess: makeActionCreator(types.DELETE_SUCCESS, "list", "listFastRecharge"),

    listCategoriesRequest: makeActionCreator(types.LIST_CATEGORIES_REQUEST, "categoryId"),
    listCategoriesFailure: makeActionCreator(types.LIST_CATEGORIES_FAILURE),
    listCategoriesSuccess: makeActionCreator(types.LIST_CATEGORIES_SUCCESS, "list"),

    setSelectedBiller: makeActionCreator(types.SET_SELECTED_BILLER, "biller"),

    listRulesBillerRequest: makeActionCreator(types.LIST_RULES_BILLER_REQUEST, "idBiller"),
    listRulesBillerFailure: makeActionCreator(types.LIST_RULES_BILLER_FAILURE),
    listRulesBillerSuccess: makeActionCreator(types.LIST_RULES_BILLER_SUCCESS, "list"),

    setQueryBillPre: makeActionCreator(types.SET_QUERY_BILL_PRE, "object"),

    isFastRecharge: makeActionCreator(types.IS_FAST_RECHARGE, "isFastRecharge"),
    isLinkFastRecharge: makeActionCreator(types.IS_LINK_FAST_RECHARGE, "isLinkFastRecharge"),
    isFetchingFastRecharge: makeActionCreator(types.IS_FETCHING_FAST_RECHARGE, "isFetchingFastRecharge"),
    isShowAllRecharges: makeActionCreator(types.IS_SHOW_ALL_RECHARGES, "isShowAllRecharges"),
};

export const selectors = {
    isFetching: ({ payService }) => payService.fetching,
    isFetchingFavorite: ({ payService }) => payService.fetchingFavorite,
    isShowAllRecharges: ({ payService }) => payService.isShowAllRecharges,
    isFastRecharge: ({ payService }) => payService.isFastRecharge,
    isLinkFastRecharge: ({ payService }) => payService.isLinkFastRecharge,
    isFetchingFastRecharge: ({ payService }) => payService.isFetchingFastRecharge,
    getListPayService: ({ payService }) => payService.listPayService,
    getListFastRecharge: ({ payService }) => payService.listFastRecharge,
    getPayService: ({ payService }) => payService.payService,
    getCategories: ({ payService }) => payService.listCategories,
    getSelectedBiller: ({ payService }) => payService.selectedBiller,
    getField: ({ payService }) => payService.field,
    getQueryBill: ({ payService }) => payService.queryBill,
};
