import React from "react";
import Col from "react-bootstrap/lib/Col";
import { number, oneOfType, shape, arrayOf, string } from "prop-types";
import Container from "pages/_components/Container";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import * as configUtils from "util/config";
import { renderAlias, renderAdditionalData } from "pages/administration/utils/signaturesScheme";

const ConfirmData = ({
    alias,
    signatureAlias,
    activeEnvironment: { administrationScheme },
    groups,
    functionalGroups,
    signatureLevelsCounts,
    signatureType,
    topAmount,
}) => (
    <Box className="border-radius-lg p-7 mt-3 box-shadow-small background-white">
        <div className="above-the-fold">
            <Container className="container--layout items-center flex-grow-1" gridClassName="form-content">
                <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2}>
                    <Text component="h4" bold labelKey="administration.signatures.confirm.title" />
                    <Box background="background-disabled" className="p-5 my-5" borderRadius="lg">
                        {renderAlias(alias || signatureAlias)}
                    </Box>

                    {renderAdditionalData(
                        administrationScheme,
                        signatureLevelsCounts,
                        signatureType,
                        topAmount,
                        functionalGroups,
                        groups,
                    )}
                </Col>
            </Container>
        </div>
    </Box>
);
ConfirmData.propTypes = {
    activeEnvironment: shape({
        administrationScheme: string,
    }).isRequired,
    functionalGroups: arrayOf(string),
    signatureLevelsCounts: oneOfType([
        shape({
            A: number,
        }),
        shape(
            configUtils.getArray("administration.signatures.signatureLevels").reduce((res, signLevel) => {
                const result = res;
                result[signLevel] = number;

                return result;
            }, {}),
        ),
    ]),
    signatureType: string,
    topAmount: shape({
        amount: oneOfType([number, string]),
        period: string,
    }),
    alias: string,
    signatureAlias: string,
    groups: arrayOf(shape({})).isRequired,
};

ConfirmData.defaultProps = {
    alias: "",
    signatureAlias: undefined,
    signatureType: "",
    functionalGroups: [],
    signatureLevelsCounts: [],
    topAmount: {},
};

export default ConfirmData;
