import { call, put, takeLatest } from "redux-saga/effects";
import { types, actions } from "reducers/paginatedTable";
import getPagedDataList from "middleware/paginatedTable";
import { actions as notificationActions } from "reducers/notification";
import { replace } from "react-router-redux";
import { API_TRANSACTIONAL_BAD_REQUEST } from "util/responses";

export default [takeLatest(types.GET_PAGINATED_DATA_LIST_REQUEST, getPagedDataListReq)];

function* getPagedDataListReq({
    idActivity,
    page,
    nextIndex,
    filters,
    idProduct,
    idFieldName,
    notificationScopes = [],
    name,
    setHasError,
}) {
    try {
        yield put(actions.getPagedDataListStart());
        const response = yield call(getPagedDataList, idActivity, page, nextIndex, filters, idProduct, idFieldName);

        if (response.type === "W") {
            const { NO_FIELD } = response.data.data;
            let message = NO_FIELD;
            if (response?.data?.code === API_TRANSACTIONAL_BAD_REQUEST) {
                message = response?.data?.data?.message;
            }

            yield put(actions.getPagedDataListError());
            if (notificationScopes.length > 0) {
                yield put(notificationActions.showNotification(message, "error", notificationScopes));
            }
        } else {
            const { paginatedData } = response.data.data;
            yield put(actions.getPagedDataListSuccess(`${idActivity}.${name}`, paginatedData));
        }
    } catch (e) {
        if ((e.response && e.response.status === 401) || e.status === 401) {
            yield put(replace("/"));
        } else {
            yield put(actions.getPagedDataListError());
            if (e && e.data && e.data.message && notificationScopes.length > 0) {
                yield put(notificationActions.showNotification(e.data.message, "error", notificationScopes));
            } else {
                if(setHasError) {
                    setHasError(true);
                }
            }
        }
    }
}
