import React, { useState } from "react";
import { bool, func, instanceOf, node, oneOfType, shape, string } from "prop-types";
import { Collapse } from "react-bootstrap";
import Box from "pages/_components/Box";
import FormSignatures from "pages/forms/_components/FormSignatures";
import Button from "pages/_components/Button";
import Row from "pages/_components/Row";
import Heading from "pages/_components/Heading";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import Image from "./Image";
import Text from "./Text";

const TicketContent = ({ head, children, transactionData, isRejecting, contentRef }) => {
    const [moreDetails, setMoreDetails] = useState(false);

    const moreDetailsHandler = () => {
        setMoreDetails(!moreDetails);
    };

    if (!transactionData) {
        return null;
    }

    const { idTransaction, fullCreationDateTimeAsString } = transactionData;

    const renderDetail = (desktop) => (
        <Box>
            <Box
                background="background-disabled"
                className={classNames("p-5", {
                    "my-5": desktop,
                    "my-2": !desktop,
                })}
                borderRadius="lg">
                <Heading.DataGroup
                    containerClassName={classNames("data-wrapper data-confirm", {
                        "dw-mobile": !desktop,
                    })}
                    label="forms.transaction.ticket.noOrder"
                    data={idTransaction}
                />
                <Heading.DataGroup
                    containerClassName={classNames("data-wrapper data-confirm", {
                        "dw-mobile": !desktop,
                    })}
                    label="forms.transaction.ticket.date"
                    data={fullCreationDateTimeAsString}
                />
                <Box className="ticket-transaction-data">{children}</Box>
                <FormSignatures transaction={transactionData} isDesktop={desktop} isInTicket />
            </Box>
        </Box>
    );
    const contentDownload = () => (
        <Box className="wrapper-download-ticket">
            <div ref={contentRef}>
                {head}
                {renderDetail(true)}
            </div>
        </Box>
    );

    return (
        <Row className="container--layout items-center flex-grow-1 form-content " gapY="3">
            {head}

            {isRejecting && (
                <Box border="info-border-color" borderRadius="md" background="info" className="p-4 mb-4" fullWidth>
                    <Box display="flex">
                        <Box className="p-2">
                            <Image src="images/icons/info.svg" />
                        </Box>
                        <Box className="pl-4" fullWidth>
                            <Box>
                                <Text labelKey="transaction.reject.message.title" bold />
                            </Box>
                            <Box className="mt-2">
                                <Text
                                    labelKey="transaction.reject.message.info"
                                    size="6"
                                    className="line-height-125 mt"
                                    light
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}

            <Box display="flex" alignX="center">
                <Button
                    className="button-see-more"
                    image={`images/util/arrow-${moreDetails ? "down" : "up"}.svg`}
                    label={moreDetails ? "transactions.link.seeMore" : "transactions.link.seeLess"}
                    onClick={moreDetailsHandler}
                    bsStyle="link"
                    imageRight
                />
            </Box>

            {contentDownload()}
            <Collapse in={!moreDetails}>{renderDetail(isDesktop)}</Collapse>
        </Row>
    );
};

TicketContent.propTypes = {
    head: node.isRequired,
    children: node.isRequired,
    transactionData: shape({
        data: shape({}),
        idActivity: string,
        idTransaction: string,
    }).isRequired,
    isRejecting: bool,
    contentRef: oneOfType([
        func,
        shape({
            current: instanceOf(Element),
        }),
    ]).isRequired,
};

TicketContent.defaultProps = {
    isRejecting: false,
};

export default TicketContent;
