import React, { Component, Fragment } from "react";
import { arrayOf, bool, func, number, oneOfType, objectOf, shape, string } from "prop-types";

import * as configUtils from "util/config";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import Notification from "pages/_components/Notification";
import Box from "pages/_components/Box";
import ConfirmData from "./ConfirmData";

class SignaturesSchemeModify extends Component {
    static propTypes = {
        actions: shape({
            modifySignaturesSchemeRequest: func,
        }).isRequired,
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        fetching: bool.isRequired,
        functionalGroups: arrayOf(string),
        masterCurrency: string,
        match: shape({
            params: shape({
                idSignature: string,
            }),
        }),
        routerActions: shape({
            goBack: func,
        }),
        signatureLevelsCounts: oneOfType([
            shape({
                A: number,
            }),
            shape(
                configUtils.getArray("administration.signatures.signatureLevels").reduce((res, signLevel) => {
                    const result = res;
                    result[signLevel] = number;

                    return result;
                }, {}),
            ),
        ]),
        signatureType: string,
        topAmount: shape({
            amount: oneOfType([number, string]),
            period: string,
        }),
        formActions: objectOf(func).isRequired,
        idActivity: string,
        idTransaction: string,
        signatureDispatch: bool,
        alias: string,
        groups: arrayOf(shape({})).isRequired,
    };

    static defaultProps = {
        functionalGroups: [],
        masterCurrency: configUtils.get("core.masterCurrency"),
        match: null,
        routerActions: null,
        signatureLevelsCounts: null,
        signatureType: "",
        topAmount: {
            amount: 0,
            period: "daily",
        },
        idActivity: null,
        idTransaction: null,
        signatureDispatch: false,
        alias: null,
    };

    componentDidMount() {
        const { routerActions, signatureLevelsCounts, signatureType } = this.props;

        if (!signatureLevelsCounts || !signatureType) {
            routerActions.goBack();
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const {
            idTransaction,
            idActivity,
            formActions,
            actions,
            functionalGroups,
            match: {
                params: { idSignature },
            },
            signatureLevelsCounts,
            signatureType,
            topAmount,
            signatureDispatch,
            alias,
        } = this.props;
        const secondFactor = credentials;
        if (!idTransaction) {
            actions.modifySignaturesSchemeRequest(
                {
                    secondFactor,
                    functionalGroups: Object.keys(functionalGroups),
                    signatureId: idSignature,
                    signatureLevelsCounts,
                    signatureType,
                    topAmount,
                    signatureDispatch,
                    signatureAlias: alias,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const {
            routerActions,
            activeEnvironment: { administrationScheme },
        } = this.props;
        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes`);
    };

    render() {
        const {
            credentialGroups,
            activeEnvironment: { administrationScheme },
            fetching,
        } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="administrationModifySignatureScheme" />
                <Head
                    title={`administration.signatures.modify.${administrationScheme}.confirm.title`}
                    onBack={this.handleBack}
                    textBack="administration.signatures.returnToList"
                />
                <MainContainer showLoader={fetching}>
                    <ConfirmData {...this.props} />
                    <Box>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                            onClickCancel={this.handleBack} 
                            idActivity="administration.signatures.modify.send"
                        />
                    </Box>
                </MainContainer>
            </Fragment>
        );
    }
}

export default SignaturesSchemeModify;
