import moment from "moment";
import { func, node, shape, string } from "prop-types";
import React, { createContext, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import {
    selectors as updateDataUserSelectors,
    actions as updateUserDataActions,
} from "reducers/updateUserData/updateUserData.reducer";

const UpdateUserDataContext = createContext({});
const UpdateUserDataProvider = (props) => {
    const { children, exchangeToken, currentUserData, dispatch } = props;
    const [stepData, setStepData] = useState([]);

    const setCurrentStep = (step) => {
        const stepDataUpdate = { ...stepData, currentStep: step };
        setStepData(stepDataUpdate);
    };

    const enabledStepEdit = (step, enabled) => {
        let enabledStepList = [...(stepData?.enabledStepList || [])].filter((item) => item?.step && item.step !== step);
        enabledStepList = [...enabledStepList, { step, enabled }];
        setStepData({ ...stepData, enabledStepList });
    };

    const isStepEnabled = (step) =>
        stepData?.enabledStepList?.find((item) => item && item.step === step)?.enabled || false;

    const updateUserDataStep1 = (values) => {
        if (!isStepEnabled(1) || !values) {
            return { ...currentUserData };
        }
        let currentUserDataUpdate = { ...currentUserData };
        let personDataUpdate = { ...(currentUserDataUpdate?.PersonData || {}) };
        let issuedIdentUpdateItem = {
            ...(personDataUpdate?.IssuedIdent && personDataUpdate?.IssuedIdent.length > 0
                ? personDataUpdate.IssuedIdent[0]
                : {}),
        };
        issuedIdentUpdateItem = {
            ...issuedIdentUpdateItem,
            IssueDt: values?.documentUserIssueDt
                ? moment(values.documentUserIssueDt).format("yyyy-MM-DDTHH:mm:ss")
                : issuedIdentUpdateItem?.IssueDt,
            ExpDt: values?.documentUserExpirationDt
                ? moment(values.documentUserExpirationDt).format("yyyy-MM-DDTHH:mm:ss")
                : issuedIdentUpdateItem.ExpDt,
        };

        let issuedLocUpdate = { ...(issuedIdentUpdateItem?.IssuedLoc || {}) };
        issuedLocUpdate = {
            ...issuedLocUpdate,
            Value: values?.documentUser?.documentCountry || issuedLocUpdate?.Value,
        };
        issuedIdentUpdateItem = { ...issuedIdentUpdateItem, IssuedLoc: issuedLocUpdate };

        let identUpdate = { ...(issuedIdentUpdateItem?.Ident || {}) };
        identUpdate = {
            ...identUpdate,
            IssuedIdentType: values?.documentUser?.documentType || identUpdate?.documentType,
        };
        issuedIdentUpdateItem = { ...issuedIdentUpdateItem, Ident: identUpdate };

        personDataUpdate = {
            ...personDataUpdate,
            BirthDt: values?.birthDate ? moment(values.birthDate).format("yyyy-MM-DD") : personDataUpdate?.BirthDt,
        };
        personDataUpdate = { ...personDataUpdate, IssuedIdent: [issuedIdentUpdateItem] };
        currentUserDataUpdate = { ...currentUserDataUpdate, PersonData: personDataUpdate };
        dispatch(updateUserDataActions.setUserDateUpdate(currentUserDataUpdate));
        return currentUserDataUpdate;
    };

    const contextData = {
        exchangeToken,
        currentUserData,
        isStepEnabled,
        enabledStepEdit,
        setCurrentStep,
        updateUserDataStep1,
        stepData,
    };
    useEffect(() => {
        if (!exchangeToken) {
            dispatch(routerActions.replace("/desktop"));
        }
    }, []);
    return (
        <UpdateUserDataContext.Provider value={contextData} {...props}>
            {children}
        </UpdateUserDataContext.Provider>
    );
};

const mapStateToProps = (state) => ({
    exchangeToken: updateDataUserSelectors.getExchangeToken(state),
    currentUserData: updateDataUserSelectors.getCurrentUserData(state),
});

UpdateUserDataProvider.propTypes = {
    children: node.isRequired,
    exchangeToken: string,
    currentUserData: shape({}),
    dispatch: func.isRequired,
};

UpdateUserDataProvider.defaultProps = {
    exchangeToken: undefined,
    currentUserData: undefined,
};

export const useUpdateDataUserContext = () => {
    const context = useContext(UpdateUserDataContext);
    if (!context) {
        throw new Error("useUpdateDataUserContext only can be used inside UpdateUserDataContext");
    }
    return context;
};

export default connect(mapStateToProps)(UpdateUserDataProvider);
