import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { groupsOfUserTicketSelectors } from "reducers/administration";
import { groupsOfUserTicketActions } from "reducers/administration/advanced";

import GroupsOfUserTicket from "pages/administration/_components/tickets/GroupsOfUserTicket";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";

class GroupsOfuserTicketContent extends Component {
    render() {
        return (
            <AdministrationTicket {...this.props}>
                <GroupsOfUserTicket {...this.props} />
            </AdministrationTicket>
        );
    }
}

const mapStateToProps = (state) => ({
    user: groupsOfUserTicketSelectors.getUser(state),
    fetching: groupsOfUserTicketSelectors.isFetching(state),
    groups: groupsOfUserTicketSelectors.getGroups(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(groupsOfUserTicketActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsOfuserTicketContent);
