import React from "react";
import { connect } from "react-redux";
import { bool, func, string } from "prop-types";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import { selectors } from "reducers/changeExpiredPassword";
import { simpleMaskEmail } from "util/format";

const ChangePasswordSuccess = ({ idForm, isDesktop, email, goToLogin }) => (
    <Box display="flex" column fullWidth {...(!isDesktop ? { fullHeight: true } : { fullHeight: false })}>
        <Box display="flex" alignX="center" className="mb-8">
            <Box className="login-blocked-icon">
                <Image src="images/util/success.gif" />
            </Box>
        </Box>
        <Box className="text-center mb-7">
            <Text size="3" labelKey={`${idForm}.success.title`} bold />
        </Box>
        <Box display="flex" column className="my-auto pb-7">
            <Text
                labelKey={`${idForm}.success.confirmMessage`}
                textParams={{
                    EMAIL: `${simpleMaskEmail(email)}`,
                }}
                align="center"
                size={`${isDesktop ? "6" : "5"}`}
                component="p"
                className="my-0"
                color="text"
            />
        </Box>
        <Box display="flex" column className="mb-7 mb-md-0 mt-auto">
            <Button label="login.comeIn" type="button" bsStyle="primary" onClick={goToLogin} block />
        </Box>
    </Box>
);
ChangePasswordSuccess.propTypes = {
    isDesktop: bool.isRequired,
    idForm: string.isRequired,
    email: string.isRequired,
    goToLogin: func.isRequired,
};
const mapStateToProps = (state) => ({
    email: selectors.getEmail(state),
});
export default connect(mapStateToProps)(ChangePasswordSuccess);
