import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import CampaignItem from "pages/campaigns/CampaignItem";
import { arrayOf, bool, element, func, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { actions, selectors } from "reducers/campaigns";
import WidgetLoading from "pages/_components/WidgetLoading";
import CardSlider from "pages/_components/CardSlider";

class Campaigns extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        section: string.isRequired,
        fetchingCampaigns: bool.isRequired,
        items: arrayOf(
            shape({
                image: string,
                idCampaign: string,
                url: string,
                clickable: bool,
            }),
        ).isRequired,
        container: element,
        isDesktop: bool,
        className: string,
        isStepZero: bool,
        showCardSlider: bool,
        classNameCard: string,
    };

    static defaultProps = {
        container: null,
        isDesktop: false,
        className: null,
        isStepZero: false,
        showCardSlider: false,
        classNameCard: "",
    };

    componentDidMount() {
        const { dispatch, section, isStepZero } = this.props;
        if (isStepZero) {
            dispatch(actions.loadCampaignAnonymous(section));
        } else {
            dispatch(actions.listCampaigns(section));
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(actions.removeCampaigns());
    }

    render() {
        const {
            items,
            container,
            dispatch,
            isDesktop,
            className,
            fetchingCampaigns,
            isStepZero,
            showCardSlider,
            classNameCard,
        } = this.props;

        const list = items?.map((item) => (
            <CampaignItem
                key={item.idCampaign}
                className={className}
                {...item}
                isDesktop={isDesktop}
                dispatch={dispatch}
            />
        ));

        const listRender = showCardSlider ? (
            <CardSlider showDots={false} className={classNameCard}>
                {list}
            </CardSlider>
        ) : (
            list
        );

        const campaignsOut = container ? (
            <Box className={container.className}>{list}</Box>
        ) : (
            <Fragment>{listRender}</Fragment>
        );

        const campaignDefaultLanding = (
            <Image
                wrapperClassName="image-wrapper"
                src="images/campaigns/campaignDefaultPadel.png"
                externalHref="https://www.bolivariano.com.ec/tarjetas/padel-club"
            />
        );

        const campaignDefault = isStepZero ? (
            <Image wrapperClassName="image-wrapper" src="images/campaigns/campaignDefault.svg" />
        ) : null;

        return isStepZero ? (
            // campaignsOut //Se deja por si quieren volver
            campaignDefaultLanding
        ) : (
            <WidgetLoading loading={fetchingCampaigns}>
                {items.length > 0 ? campaignsOut : campaignDefault}
            </WidgetLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    items: selectors.getCampaigns(state) || [],
    fetchingCampaigns: selectors.getFetching(state),
});

export default connect(mapStateToProps)(Campaigns);
