export const steps = [
    {
        stepNumber: 1,
        label: "recoveryPassword.step.1",
    },
    {
        stepNumber: 2,
        label: "recoveryPassword.step.2",
    },
    {
        stepNumber: 3,
        label: "recoveryPassword.step.3",
    },
];
