import classNames from "classnames";
import Container from "pages/_components/Container";
import ListItem from "pages/loans/ListItem";
import { bool, func, oneOfType, shape } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";

class LoansList extends Component {
    static propTypes = {
        loans: oneOfType([shape({})]).isRequired,
        showProductIcon: bool,
        setFavoriteLoan: func,
        completeFavorites: bool,
    };

    static defaultProps = {
        showProductIcon: true,
        setFavoriteLoan: () => {},
        completeFavorites: false,
    };

    renderList = () => {
        const { loans, showProductIcon, setFavoriteLoan, completeFavorites, ...props } = this.props;
        if (!loans.length) {
            return null;
        }

        return (
            <div role="menu" className={classNames("table-body")}>
                {loans.map((loan) => (
                    <ListItem
                        {...props}
                        {...loan}
                        key={loan.idProduct}
                        showProductIcon={showProductIcon}
                        setFavoriteLoan={setFavoriteLoan}
                        showStar
                        completeFavorites={completeFavorites}
                        hideContextsOptions
                    />
                ))}
            </div>
        );
    };

    render() {
        return (
            <Container className="container--layout flex-grow-1">
                <Col className="col-12" sm={12}>
                    <div className="table table--products">{this.renderList()}</div>
                </Col>
            </Container>
        );
    }
}

export default LoansList;
