import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import { string } from "prop-types";
import React from "react";
import Tooltip from "pages/_components/Tooltip";

const ContainerColumnHeader = ({ title, url, linkText, className, tooltipText }) => (
    <Box component="header" display="flex" gap="5" border="bottom-background-divider-1" className={className} fullWidth>
        <Box display="flex">
            <Text component="h2" size="3" color="heading-color" classNames="m-0" labelKey={title} bold />
            {tooltipText && (
                <Tooltip className="cursor-pointer pl-2 pr-2" position="left-centered" text={tooltipText} />
            )}
        </Box>
        {linkText && <Button bsStyle="link" label={linkText} href={url} className="px-0" />}
    </Box>
);

ContainerColumnHeader.propTypes = {
    title: string.isRequired,
    url: string,
    linkText: string,
    className: string,
    tooltipText: string,
};

ContainerColumnHeader.defaultProps = {
    url: null,
    linkText: null,
    className: "px-8 py-3",
    tooltipText: null,
};

export default ContainerColumnHeader;
