import React from "react";

import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";

const HeaderLoanDescription = ({ isMobile }) => (
    <Box component="article" display="flex" borderRadius="default" fullWidth className="p-7 mb-5 box-shadow-small">
        <Box fullWidth className="mb-2">
            <Text
                {...(!isMobile && { size: "3" })}
                {...(isMobile && { uppercase: true, size: "6" })}
                semibold
                labelKey="loans.baseTitleList"
            />
            <Box className="mt-2">
                <Text labelKey="loans.title.description" />
            </Box>
        </Box>
        <Box>
            <Image src="images/loans.svg" className="img-functionality" />
        </Box>
    </Box>
);

export default HeaderLoanDescription;
