import React from "react";
import Head from "./Head";
import MainContainer from "./MainContainer";

const MobileFilterLayout = ({ handleBack, children }) => (
    <>
        <Head onBack={handleBack} title="deposits.detail.filters" />
        <MainContainer className="rounded-border-top background-white">
            <div className="display-flex flex-column justify-content-between deposit-filter-main" gapY="0" fullHeight>
                {children}
            </div>
        </MainContainer>
    </>
);

export default MobileFilterLayout;
