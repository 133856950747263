import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    SET_MESSAGE: "chatbot/SET_MESSAGE",
    START_SESSION: "chatbot/START_SESSION",
    SEND_MESSAGE_REQUEST: "chatbot/SEND_MESSAGE_REQUEST",
    SEND_MESSAGE_FAILURE: "chatbot/SEND_MESSAGE_FAILURE",
    SEND_MESSAGE_SUCCESS: "chatbot/SEND_MESSAGE_SUCCESS",
    END_SESSION: "chatbot/END_SESSION",
};

export const INITIAL_STATE = {
    fetching: false,
    messages: [],
    sessionId: null,
};

export default createReducer(INITIAL_STATE, {
    [types.SET_MESSAGE]: (state, action) => ({
        ...state,
        messages: [
            ...state.messages,
            {
                text: action.text,
                own: action.isOwn,
            },
        ],
        fetching: action.isOwn,
    }),
    [types.START_SESSION]: (state, action) => ({
        ...state,
        sessionId: action.sessionId,
    }),
    [types.SEND_MESSAGE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.SEND_MESSAGE_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.END_SESSION]: () => ({
        ...INITIAL_STATE,
    }),
});

export const actions = {
    setMessage: makeActionCreator(types.SET_MESSAGE, "text", "isOwn"),
    startSession: makeActionCreator(types.START_SESSION, "sessionId"),
    sendMessageRequest: makeActionCreator(types.SEND_MESSAGE_REQUEST, "text", "event"),
    sendMessageFailure: makeActionCreator(types.SEND_MESSAGE_FAILURE),
    endSession: makeActionCreator(types.END_SESSION),
};

export const selectors = {
    getMessages: ({ chatbot }) => chatbot.messages,
    isFetching: ({ chatbot }) => chatbot.fetching,
};
