import { Field, Form, withFormik } from "formik";
import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, shape } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions as unlockUserActions, selectors as unlockUserSelectors } from "reducers/unlockUser";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { selectors as sessionSelectors } from "reducers/session";
import TextField from "pages/_components/fields/TextField";
import LoginWrapper from "pages/login/_components/LoginWrapper";
import { push } from "react-router-redux";
import Captcha from "pages/_components/fields/credentials/Captcha";
import { selectors as settingsSelector } from "reducers/settings";
import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import Stepper from "pages/_layouts/DefaultStepperLayout/Stepper";
import { validationEmail } from "util/validationSchemaUtil";
import HeaderWithoutSession from "pages/_components/HeaderWithoutSession";
import PendingInvitationModal from "pages/login/_components/PendingInvitationModal";

const FORM_ID = "unlockUser.step1";

const UnlockUserStep1 = ({ isMobile, isSubmitting, isDesktop, values, dispatch }) => {
    useEffect(() => {
        dispatch(unlockUserActions.reset());
    }, []);

    const handleBack = () => {
        dispatch(push("/loginStep1"));
    };

    const renderDesktop = () => (
        <>
            <LoginWrapper>
                <>
                    {!isMobile && <BoxErrorNotification isMobile={isMobile} scopeToShow="unlockUser" />}
                    <HeaderWithoutSession labelKey="unlockUser.step1.header" handleOnBack={handleBack} showBack />
                    <MainContainer>
                        <Form
                            className={classNames({
                                "pointer-events-none": isSubmitting,
                            })}
                            noValidate="novalidate">
                            <Box display="flex" column fullWidth>
                                <Box display="flex" column className="mb-2 mt-5">
                                    <Field
                                        hideLabel={isDesktop}
                                        idForm={FORM_ID}
                                        name="username"
                                        component={TextField}
                                        autoFocus={isDesktop}
                                        placeholder={i18n.get("", "Ingrese su usuario")}
                                        noMarginBottom
                                        className="mb-4"
                                        idField="usernamefield"
                                        labelText={i18n.get("", "Usuario")}
                                        labelClassName="text-bold"
                                        spellCheck="false"
                                        maxLength={16}
                                        pattern="\S+"
                                    />
                                </Box>
                                <Box display="flex" column className="mb-2 mt-4">
                                    <Field
                                        hideLabel={isDesktop}
                                        idForm={FORM_ID}
                                        name="mail"
                                        type="email"
                                        component={TextField}
                                        autoFocus={isDesktop}
                                        placeholder={i18n.get("", "Ingrese su correo electrónico")}
                                        noMarginBottom
                                        className="mb-3"
                                        idField="usernamefield"
                                        labelText={i18n.get("", "Correo electrónico")}
                                        labelClassName="text-bold"
                                        spellCheck="false"
                                    />
                                </Box>
                                <Box display="flex" column className="mb-2 mt-4">
                                    <Field idForm={FORM_ID} name="captcha" component={Captcha} />
                                </Box>

                                <Button
                                    className="mt-7"
                                    type="submit"
                                    bsStyle="primary"
                                    label="global.continue"
                                    defaultLabelText={i18n.get("", "Continuar")}
                                    btnUppercase={false}
                                    loading={isSubmitting}
                                    disabled={!values.captcha || !values.username || !values.mail}
                                    block
                                />
                            </Box>
                        </Form>
                    </MainContainer>
                </>
            </LoginWrapper>
        </>
    );

    const renderMobile = () => (
        <LoginWrapper>
            <Form className="full-width">
                <Box position="relative" display="flex" column alignX="between" fullWidth heightAuto>
                    <MainContainer>
                        <Box display="flex" column className="mt-7">
                            <Box className="mb-5">
                                <HeaderWithoutSession labelKey="unlockUser.step1.header" />
                            </Box>
                            <Field
                                hideLabel={isDesktop}
                                idForm={FORM_ID}
                                name="username"
                                component={TextField}
                                autoFocus={isDesktop}
                                placeholder={i18n.get("", "Ingrese su usuario")}
                                noMarginBottom
                                className="mb-4"
                                idField="usernamefield"
                                labelText={i18n.get("", "Usuario")}
                                labelClassName="text-bold"
                                spellCheck="false"
                                maxLength={16}
                            />
                            <Box display="flex" column className="mb-2 mt-4">
                                <Field
                                    hideLabel={isDesktop}
                                    idForm={FORM_ID}
                                    name="mail"
                                    type="email"
                                    component={TextField}
                                    autoFocus={isDesktop}
                                    placeholder={i18n.get("", "Ingrese su correo electrónico")}
                                    noMarginBottom
                                    className="mb-3"
                                    idField="usernamefield"
                                    labelText={i18n.get("", "Correo electrónico")}
                                    labelClassName="text-bold"
                                    spellCheck="false"
                                />
                            </Box>
                            <Box display="flex" column className="mb-2 mt-4">
                                <Field idForm={FORM_ID} name="captcha" component={Captcha} />
                            </Box>
                        </Box>
                        <Box className="mb-8 mt-5">
                            <Button
                                type="submit"
                                bsStyle="primary"
                                label="global.continue"
                                block
                                disabled={!values.captcha || !values.username || !values.mail}
                            />
                        </Box>
                    </MainContainer>
                </Box>
            </Form>
        </LoginWrapper>
    );

    return (
        <Box
            display="flex"
            {...(!isDesktop && { alignX: "flex-end", component: "main" })}
            position="relative"
            zIndex="9"
            className={classNames("login-background", { "mt-auto": !isDesktop })}>
            {isDesktop ? renderDesktop() : renderMobile()}
            <PendingInvitationModal />
        </Box>
    );
};

UnlockUserStep1.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isMobile: bool.isRequired,
    fetching: bool.isRequired,
    preData: shape({}).isRequired,
    isActiveCorporate: bool.isRequired,
    showCaptcha: bool.isRequired,
    isSubmitting: bool.isRequired,
    values: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    fetching: unlockUserSelectors.getFetching(state),
    preData: unlockUserSelectors.getPreData(state),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
    showCaptcha: settingsSelector.getShowCaptcha(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            username: "",
            mail: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                username: Yup.string().required(i18n.get(`${FORM_ID}.username.required`)),
                mail: validationEmail(`${FORM_ID}.email.required`, `${FORM_ID}.email.invalid`),
            }),
        handleSubmit: ({ username, mail }, formikBag) => {
            formikBag.props.dispatch(unlockUserActions.saveForm(username, mail));
            formikBag.props.dispatch(unlockUserActions.unlockUserStep1(username, mail, formikBag));
        },
    }),
)(resizableRoute(UnlockUserStep1));
