import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LIST_REQUEST: "debitCards/LIST_REQUEST",
    LIST_FAILURE: "debitCards/LIST_FAILURE",
    LIST_SUCCESS: "debitCards/LIST_SUCCESS",
    REQUEST_PIN_REQUEST: "debitCards/REQUEST_PIN_REQUEST",
};

export const INITIAL_STATE = {
    fetching: false,
    list: [],
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LIST_SUCCESS]: (_, action) => ({
        fetching: false,
        list: action.list,
    }),
});

export const actions = {
    listRequest: makeActionCreator(types.LIST_REQUEST),
    listFailure: makeActionCreator(types.LIST_FAILURE),
    listSuccess: makeActionCreator(types.LIST_SUCCESS, "list"),
    requestPinRequest: makeActionCreator(types.REQUEST_PIN_REQUEST, "data"),
};

export const selectors = {
    isFetching: ({ debitCards }) => debitCards.fetching,
    getList: ({ debitCards }) => debitCards.list,
};
