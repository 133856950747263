import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { PDFTextField } from "pages/forms/customForms/PDFTicket";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import TicketRenderCustom from "../TicketRenderCustom";

const TicketHistoricTransaction = (props) => {
    const { transaction } = props;
    const {
        EffDt,
        EffHr,
        PmtDt,
        TrnDesc,
        Amt,
        CurCode,
        reference,
        BeneficiaryName,
        CreditAcctId,
        DebitAcctId,
        TargetBank,
    } = transaction?.data;

    const renderFields = () => (
        <Box className="pt-9 pt-md-12 mx-7">
            <FormFieldsComponents.ReadTextCustom
                value={reference}
                label="transactions.ticket.voucher.label"
                shouldRender={reference}
            />
            <FormFieldsComponents.ReadTextCustom
                value={`${EffDt} ${EffHr}`}
                label="transactions.ticket.date.label"
                shouldRender={EffDt}
            />
            <FormFieldsComponents.ReadTextCustom
                value={PmtDt}
                label="transactions.ticket.datePayment.label"
                shouldRender={PmtDt}
            />
            <FormFieldsComponents.ReadTextCustom
                value={TrnDesc}
                label="transactions.ticket.typeOperation.label"
                shouldRender={TrnDesc}
            />
            <FormFieldsComponents.ReadTextCustom
                value={DebitAcctId}
                label="transactions.ticket.debitAccount.label"
                shouldRender={DebitAcctId}
            />
            <FormFieldsComponents.ReadTextCustom
                value={CreditAcctId}
                label="transactions.ticket.creditAccount.label"
                shouldRender={CreditAcctId}
            />
            <FormFieldsComponents.ReadTextCustom
                value={`${CurCode} ${Amt}`}
                label="transactions.ticket.amount.label"
                shouldRender={!!CurCode && !!Amt}
            />
            <FormFieldsComponents.ReadTextCustom
                value={TargetBank}
                label="transactions.ticket.targetBank.label"
                shouldRender={TargetBank}
            />
            <FormFieldsComponents.ReadTextCustom
                value={BeneficiaryName}
                label="transactions.ticket.destinatary.label"
                shouldRender={BeneficiaryName}
            />
        </Box>
    );
    const renderTicket = () => (
        <>
            {reference ? (
                <PDFTextField value={reference} label={i18n.get("transactions.ticket.voucher.label")} />
            ) : null}
            {EffDt ? (
                <PDFTextField value={`${EffDt} ${EffHr}`} label={i18n.get("transactions.ticket.date.label")} />
            ) : null}
            {PmtDt ? <PDFTextField value={PmtDt} label={i18n.get("transactions.ticket.datePayment.label")} /> : null}
            {TrnDesc ? (
                <PDFTextField value={TrnDesc} label={i18n.get("transactions.ticket.typeOperation.label")} />
            ) : null}
            {DebitAcctId ? (
                <PDFTextField value={DebitAcctId} label={i18n.get("transactions.ticket.debitAccount.label")} />
            ) : null}
            {CreditAcctId ? (
                <PDFTextField value={CreditAcctId} label={i18n.get("transactions.ticket.creditAccount.label")} />
            ) : null}
            {CurCode && Amt ? (
                <PDFTextField value={`${CurCode} ${Amt}`} label={i18n.get("transactions.ticket.amount.label")} />
            ) : null}
            {TargetBank ? (
                <PDFTextField value={TargetBank} label={i18n.get("transactions.ticket.targetBank.label")} />
            ) : null}
            {BeneficiaryName ? (
                <PDFTextField value={BeneficiaryName} label={i18n.get("transactions.ticket.destinatary.label")} />
            ) : null}
        </>
    );

    const formProps = {
        title: TrnDesc,
        renderFields,
        renderTicket,
        idActivity: "historic.transaction",
    };
    return <TicketRenderCustom {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
});

TicketHistoricTransaction.propTypes = {
    dispatch: func.isRequired,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    fromTransaction: bool,
};
TicketHistoricTransaction.defaultProps = {
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    transaction: {},
    location: {},
    fromTransaction: false,
};
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(TicketHistoricTransaction));
