import React, { Component } from "react";
import { shape, node, string, func } from "prop-types";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

import Box from "pages/_components/Box";
import { selectors as sessionSelectors } from "reducers/session";
import { connect } from "react-redux";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";
import { Row } from "react-bootstrap";
import { selectors as depositActionsSelectors } from "reducers/deposits";
import Info from "pages/_components/Info";
import moment from "moment";
import classNames from "classnames";

class DepositDetail extends Component {
    render() {
        const { deposits, paymentMethod, isDesktop } = this.props;

        return (
            <>
                <Box className="pt-5">
                    <Box className="detail-head-info pt-0 pb-4">
                        <Container gridClassName="container-fluid">
                            <Row>
                                <Col className="col-12">
                                    <Box
                                        borderRadius="lg"
                                        className={classNames("box-shadow-small", {
                                            "p-7": isDesktop,
                                            "px-7 pt-1 pb-7": !isDesktop,
                                        })}
                                        fullHeight>
                                        <Row
                                            className={classNames({
                                                "row-template-1fr gap-0": !isDesktop,
                                            })}>
                                            <Col className="col-3">
                                                <Box
                                                    className={classNames({
                                                        "py-4": isDesktop,
                                                    })}>
                                                    <Info
                                                        labelKey="deposits.detail.term"
                                                        text={`${deposits.plazoTotal} ${i18nUtils.get(
                                                            "global.day.plural",
                                                        )}`}
                                                        classNameValue="size-6"
                                                        flex={!isDesktop}
                                                    />
                                                </Box>
                                            </Col>
                                            <Col className="col-3">
                                                <Box
                                                    className={classNames({
                                                        "py-4": isDesktop,
                                                    })}>
                                                    <Info
                                                        labelKey="deposits.detail.rate"
                                                        text={`${deposits.tasa}%`}
                                                        classNameValue="size-6"
                                                        flex={!isDesktop}
                                                    />
                                                </Box>
                                            </Col>
                                            <Col className="col-3">
                                                <Box
                                                    className={classNames({
                                                        "py-4": isDesktop,
                                                    })}>
                                                    <Info
                                                        labelKey="deposits.detail.currency"
                                                        text={deposits.descripcionMoneda}
                                                        classNameValue="size-6"
                                                        flex={!isDesktop}
                                                    />
                                                </Box>
                                            </Col>
                                            <Col className="col-3">
                                                <Box
                                                    className={classNames({
                                                        "py-4": isDesktop,
                                                    })}>
                                                    <Info
                                                        labelKey="deposits.detail.office"
                                                        text={deposits.nombreOficina}
                                                        classNameValue="size-6"
                                                        flex={!isDesktop}
                                                    />
                                                </Box>
                                            </Col>
                                        </Row>
                                        <Row
                                            className={classNames({
                                                "row-template-1fr gap-0": !isDesktop,
                                            })}>
                                            <Col className="col-3">
                                                <Box
                                                    className={classNames({
                                                        "py-4": isDesktop,
                                                    })}>
                                                    <Info
                                                        labelKey="deposits.detail.initdate"
                                                        text={moment(deposits.fechaApertura, "YYYY-MM-DD").format(
                                                            "DD/MM/YYYY",
                                                        )}
                                                        classNameValue="size-6"
                                                        flex={!isDesktop}
                                                    />
                                                </Box>
                                                
                                            </Col>
                                            <Col className="col-3">
                                                
                                                <Box
                                                    className={classNames({
                                                        "py-4": isDesktop,
                                                    })}>
                                                    <Info
                                                        labelKey="deposits.detail.expirationdate"
                                                        text={moment(deposits.fechaVencimiento, "YYYY-MM-DD").format(
                                                            "DD/MM/YYYY",
                                                        )}
                                                        classNameValue="size-6"
                                                        flex={!isDesktop}
                                                    />
                                                </Box>
                                                
                                            </Col>
                                            <Col className="col-3">
                                               
                                                <Box
                                                    className={classNames({
                                                        "py-4": isDesktop,
                                                    })}>
                                                    <Info
                                                        labelKey="deposits.detail.nextinterestpaymentdate"
                                                        text={moment(deposits.fechaPagoInteres, "YYYY-MM-DD").format(
                                                            "DD/MM/YYYY",
                                                        )}
                                                        classNameValue="size-6"
                                                        flex={!isDesktop}
                                                    />
                                                </Box>
                                            </Col>
                                            <Col className="col-3">
                                                
                                                <Box
                                                    className={classNames({
                                                        "py-4": isDesktop,
                                                    })}>
                                                    <Info
                                                        labelKey="deposits.detail.resetfrequency"
                                                        text={i18nUtils.get(
                                                            `deposits.payment.frequency.${deposits.frecuenciaPago}`,
                                                        )}
                                                        classNameValue="size-6"
                                                        flex={!isDesktop}
                                                    />
                                                </Box>
                                            </Col>
                                        </Row>
                                         <Row
                                            className={classNames({
                                                "row-template-1fr gap-0": !isDesktop,
                                            })}>
                                            <Col className="col-3">
                                              
                                                <Box
                                                    className={classNames({
                                                        "py-4": isDesktop,
                                                    })}>
                                                    <Info
                                                        labelKey="deposits.detail.qtyrenovation"
                                                        text={deposits.noVecesRenovacion}
                                                        classNameValue="size-6"
                                                        flex={!isDesktop}
                                                    />
                                                </Box>
                                            </Col>
                                            <Col className="col-3">
                                               
                                                <Box
                                                    className={classNames({
                                                        "py-4": isDesktop,
                                                    })}>
                                                    <Info
                                                        labelKey="deposits.detail.renovationautomatic"
                                                        text={deposits.renovacionAutomatica || "-"}
                                                        classNameValue="size-6"
                                                        flex={!isDesktop}
                                                    />
                                                </Box>
                                            </Col>
                                            <Col className="col-3">
                                                
                                                <Box
                                                    className={classNames({
                                                        "py-4": isDesktop,
                                                    })}>
                                                    <Info
                                                        labelKey="deposits.detail.cancellationdate"
                                                        text={
                                                            deposits.fechaCancelacion
                                                                ? moment(
                                                                      deposits.fechaCancelacion,
                                                                      "YYYY-MM-DD",
                                                                  ).format("DD/MM/YYYY")
                                                                : "-"
                                                        }
                                                        classNameValue="size-6"
                                                        flex={!isDesktop}
                                                    />
                                                </Box>
                                            </Col>
                                            <Col className="col-3">
                                               
                                            </Col>
                                        </Row>
                                    </Box>
                                </Col>
                            </Row>
                            {paymentMethod && paymentMethod[0] && (
                                <Row>
                                    <Box className="box-shadow-small background-white border-radius-lg  p-7" fullHeight>
                                        <Box>
                                            <Text labelKey="deposits.detail.accountdetails" bold />
                                        </Box>
                                        <Col className="col-12">
                                            <Row  className={classNames({
                                                "row-template-1fr gap-0": !isDesktop,
                                            })}>
                                                <Col className="col-3">
                                                    <Box
                                                        className={classNames({
                                                            "py-4": isDesktop,
                                                        })}>
                                                        <Info
                                                            labelKey="deposits.detail.beneficiaryname"
                                                            text={paymentMethod[0]?.nombreBeneficiario}
                                                            classNameValue="size-6"
                                                            flex={!isDesktop}
                                                        />
                                                    </Box>
                                                </Col>
                                                <Col className="col-3">
                                                    <Box
                                                        className={classNames({
                                                            "py-4": isDesktop,
                                                        })}>
                                                        <Info
                                                            labelKey="deposits.detail.waytopay"
                                                            text={paymentMethod[0]?.formaPago}
                                                            classNameValue="size-6"
                                                            flex={!isDesktop}
                                                        />
                                                    </Box>
                                                </Col>
                                                <Col className="col-3">
                                                    <Box
                                                        className={classNames({
                                                            "py-4": isDesktop,
                                                        })}>
                                                        <Info
                                                            labelKey="deposits.detail.account"
                                                            text={paymentMethod[0]?.cuenta}
                                                            classNameValue="size-6"
                                                            flex={!isDesktop}
                                                        />
                                                    </Box>
                                                </Col>
                                                <Col className="col-3">
                                                    <Box
                                                        className={classNames({
                                                            "py-4": isDesktop,
                                                        })}>
                                                        <Info
                                                            labelKey="deposits.detail.amount"
                                                            amount={paymentMethod[0]?.monto}
                                                            currency={configUtils.get("core.masterCurrency")}
                                                            size="6"
                                                            flex={!isDesktop}
                                                        />
                                                    </Box>
                                                </Col>
                                            </Row>  
                                        </Col>
                                    </Box>
                                </Row>
                            )}
                        </Container>
                    </Box>
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    deposits: depositActionsSelectors.getSelectedDeposit(state),
    paymentMethod: depositActionsSelectors.getPaymentMethod(state),
});

export default connect(mapStateToProps)(resizableRoute(DepositDetail));
