import React, { Component, Fragment } from "react";
import { push } from "react-router-redux/actions";
import { connect } from "react-redux";

import { shape, string, func, bool, arrayOf } from "prop-types";
import { Formik } from "formik";

import { actions } from "reducers/administration/common/group";
import { groupSelectors, permissionsSelectors } from "reducers/administration";
import { actions as groupsActions } from "reducers/administration/groups";
import * as administrationUtils from "util/administration";

import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import PageLoading from "pages/_components/PageLoading";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import MembersList from "pages/administration/_components/MembersList";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import Info from "pages/_components/Info";
import * as i18nUtils from "util/i18n";
import Button from "pages/_components/Button";
import classNames from "classnames";
import Permissions from "../_components/cashProductField/Permissions";
import { filterPermissionsSelected } from "../_components/cashProductField/permissionsUtils";

class Group extends Component {
    static propTypes = {
        match: shape({
            params: shape({
                id: string,
            }),
        }).isRequired,
        dispatch: func.isRequired,
        groupStatus: string.isRequired,
        name: string.isRequired,
        description: string,
        adminGroup: bool.isRequired,
        fetching: bool.isRequired,
        permissions: administrationUtils.permissionsPropType,
        groups: arrayOf(shape({})).isRequired,
        products: arrayOf(shape({})).isRequired,
    };

    static defaultProps = {
        description: "",
        permissions: {},
    };

    componentDidMount() {
        const { match, dispatch } = this.props;

        dispatch(actions.loadGroupRequest(match.params.id));
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/administration/groups"));
    };

    getStatusAction = (groupStatus) => (groupStatus === "active" ? "block" : "unblock");

    normalizedProducts = (items) => items.map((p) => ({ idProduct: p.idProduct, label: p.label, type: p.productType }));

    handleActionClick = (action) => {
        const { match, dispatch, name } = this.props;
        dispatch(groupsActions.changeGroupStatusPreview([match.params.id], [name], action));
    };

    renderContent = () => {
        const { name, description, groupStatus, adminGroup, match, permissions, groups, products } = this.props;

        return (
            <Fragment>
                <Head
                    onBack={this.handleBack}
                    textBack="administration.groups.returnToList"
                    hasCenterContent
                    {...(!adminGroup && {
                        rightChildren: (
                            <Box display="flex" gap="7">
                                <Button
                                    image="images/icons/deleteTrash.svg"
                                    bsStyle="outline"
                                    className="btn-width-lg"
                                    label="administration.delete"
                                    onClick={() => {
                                        this.handleActionClick("delete");
                                    }}
                                    btnUppercase={false}
                                />
                                {groupStatus === "blocked" ? (
                                    <Button
                                        image="images/icons/locked.svg"
                                        bsStyle="primary"
                                        className="btn-width-lg"
                                        key="unblock"
                                        label="administration.unblock"
                                        onClick={() => {
                                            this.handleActionClick("unblock");
                                        }}
                                        btnUppercase={false}
                                    />
                                ) : (
                                    <Button
                                        image="images/icons/locked.svg"
                                        bsStyle="primary"
                                        className="btn-width-lg"
                                        key="block"
                                        label="administration.block"
                                        onClick={() => {
                                            this.handleActionClick("block");
                                        }}
                                        btnUppercase={false}
                                    />
                                )}
                            </Box>
                        ),
                    })}>
                    <Text component="h2" size="1" color="heading-color" classNames="m-0" bold>
                        {name}
                    </Text>

                    <>
                        {adminGroup && (
                            <Box className="pl-9">
                                <Info
                                    className="info-user-list info-user-admin"
                                    text={`${i18nUtils.get("administration.administrator")} `}
                                />
                            </Box>
                        )}
                        <Box
                            className={classNames({
                                "ml-3": adminGroup,
                                "pl-9": !adminGroup,
                            })}>
                            <Info
                                // className={`info-user-list ${
                                //     groupStatus === "block" ? "info-user-blocked" : "info-user-active"
                                // }`}
                                className={classNames("info-user-list", {
                                    "info-user-blocked": groupStatus === "blocked",
                                    "info-user-active": groupStatus !== "blocked",
                                })}
                                text={i18nUtils.get(`user.status.${groupStatus}`)}
                            />
                        </Box>
                    </>
                </Head>

                <MainContainer>
                    <Box background="white" borderRadius="lg" className="view-app-content p-7 box-shadow-small">
                        <div className="above-the-fold">
                            <Box
                                fullWidth
                                display="flex"
                                background="white"
                                borderRadius="lg"
                                className="p-7 mb-5 box-shadow-small">
                                <Box fullWidth>
                                    <Box>
                                        <Text
                                            labelKey="administration.advanced.group.create.description.label"
                                            color="text-disabled-color"
                                        />
                                    </Box>
                                    <Box className="mt-5">
                                        <Text size="6" color="heading-color" classNames="m-0" bold>
                                            {description || "-"}
                                        </Text>
                                    </Box>
                                </Box>
                                {!adminGroup && (
                                    <Box display="flex" alignY="center" alignX="end" fullWidth>
                                        <Button
                                            href={`/administration/advanced/group/${match.params.id}/members`}
                                            // href={`/administration/advanced/group/members/${match.params.id}/step3`}
                                            bsStyle="outline"
                                            label="administration.advanced.group.button.users"
                                            btnUppercase={false}
                                            className="btn-small mr-6"
                                            image="images/user-plus.svg"
                                        />
                                        <Button
                                            href={`/administration/advanced/group/${match.params.id}/edit`}
                                            bsStyle="outline"
                                            label="global.modify"
                                            btnUppercase={false}
                                            className="btn-small"
                                            image="images/editPen.svg"
                                        />
                                    </Box>
                                )}
                            </Box>

                            <Container className="container--layout flex-grow-1">
                                <Box className="border-radius-lg p-7 mt-3 box-shadow-small background-white">
                                    <Box className="mt-5">
                                        <Text
                                            component="h3"
                                            labelKey="administration.advanced.group.create.description.text"
                                            bold
                                        />
                                    </Box>
                                    {/* 
                                    <Link
                                        className="btn-only-icon btn-circle btn"
                                        to={`/administration/advanced/group/${match.params.id}/edit`}
                                        aria-label={`${utilI18n.get("administration.edit")} ${utilI18n.get(
                                            "administration.permissions",
                                        )}`}>
                                        <Image src="images/editPen.svg" />
                                    </Link> */}

                                    <Container.ColumnBody columnClass="list-wrapper">
                                        <Formik
                                            initialValues={{ permissions: permissions || {} }}
                                            onSubmit={() => {}}
                                            enableReinitialize
                                            render={() => (
                                                <Permissions
                                                    fieldName="permissions"
                                                    administrationSchema="advanced"
                                                    data={{
                                                        groups: filterPermissionsSelected(groups, permissions || {}),
                                                        products: this.normalizedProducts(products),
                                                    }}
                                                    mode="view"
                                                />
                                            )}
                                        />
                                    </Container.ColumnBody>
                                </Box>
                                <Box className="border-radius-lg p-7 mt-3 box-shadow-small background-white">
                                    <Box className="mt-5">
                                        <Text component="h3" labelKey="administration.members" bold />
                                    </Box>
                                    <Container.ColumnBody>
                                        <MembersList />
                                    </Container.ColumnBody>
                                </Box>
                            </Container>
                        </div>
                    </Box>
                </MainContainer>
            </Fragment>
        );
    };

    render() {
        const { fetching, name } = this.props;
        const isLoading = fetching && name === "";

        return (
            <MainContainer className="mt-3 p-7" showLoader={isLoading}>
                <Notification scopeToShow="administration" />
                <PageLoading loading={isLoading}>{!isLoading && this.renderContent()}</PageLoading>
            </MainContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    name: groupSelectors.getName(state),
    description: groupSelectors.getDescription(state),
    adminGroup: groupSelectors.isAdminGroup(state),
    groupStatus: groupSelectors.getGroupStatus(state),
    fetching: groupSelectors.isFetching(state),
    permissions: permissionsSelectors.getPermissions(state),
    groups: permissionsSelectors.getGroups(state),
    products: permissionsSelectors.getProducts(state),
});

export default connect(mapStateToProps)(Group);
