import * as Yup from "yup";
import * as i18n from "util/i18n";

export const DEFAULT_REQUIRED = "program.date.blank";

// eslint-disable-next-line import/prefer-default-export
export const getMessageRequired = (message) => {
    const showMessage = message || DEFAULT_REQUIRED;
    return i18n.get(showMessage);
};
export const validationAmount = (messageRequired) =>
    Yup.object().test(
        "amount",
        getMessageRequired(messageRequired),
        (val) => !!val?.quantity && val?.quantity > 0 && !!val?.currency,
    );

export const validationEmail = (messageRequired, messageInvalid) =>
    Yup.string()
        .required(getMessageRequired(messageRequired))
        .test("mail", getMessageRequired(messageInvalid), (value) => {
            const defaultRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // Esta regex traida desde configurations se rompia por el escapeo
            // const emailPattern = configUtils.get("email.validationFormat.frontend", defaultRegex);
            return defaultRegex.test(String(value).toLowerCase());
        });

export const validationAmountCreditCard = (messageRequired) =>
    Yup.object().test(
        "amount",
        getMessageRequired(messageRequired),
        (val) => !!val?.quantity && val?.quantity > 0 && !!val?.currency,
    );

export const validationText = (message) => Yup.string().required(getMessageRequired(message));
export const validationBool = (message) => Yup.bool().oneOf([true], getMessageRequired(message));
export const validationLength = (messageMin, messageMax, min, max, isRequired, messageRequired) => {
    const validation = isRequired
        ? Yup.string()
              .required(getMessageRequired(messageRequired))
              .min(min, getMessageRequired(messageMin))
              .max(max, getMessageRequired(messageMax))
        : Yup.string()
              .min(min, getMessageRequired(messageMin))
              .max(max, getMessageRequired(messageMax));
    return validation;
};

export const validationDocument = (message, fieldName) =>
    Yup.object().test(
        fieldName,
        getMessageRequired(message),
        (val) => val.documentType && val.document && val.documentCountry,
    );
export const validationLengthMax = (message, max) => Yup.string().max(max, getMessageRequired(message));
export const validationAccount = (message, accList = []) =>
    Yup.string().test(
        "validate-account",
        getMessageRequired(message),
        (value) => accList.find((acc) => acc.idProduct === value) || value === "all",
    );
export const validationBankCode = (message, fieldName) =>
    Yup.object().test(fieldName, getMessageRequired(message), (value) => {
        if (!value) {
            return false;
        }
        return value.code && value.type;
    });
