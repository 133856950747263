import React from "react";
import { bool, func, shape, string } from "prop-types";

import Box from "pages/_components/Box";
import classNames from "classnames";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Text from "pages/_components/Text";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";

const ListMovementsDeposits = ({ data, isDesktop }) => {
    if (!data.length) {
        return null;
    }

    return (
        <Box>
            {isDesktop && (
                <Box display="flex" alignX="between" alignY="center">
                    <Box display="flex" alignY="center" className="p-3 mb-1" fullWidth>
                        <Box className="info-deposit-mov-list col-transaction">
                            <Text size="6" color="text-grey-color" labelKey="deposits.detail.transaccion" light />
                        </Box>
                        <Box className="info-deposit-mov-list">
                            <Text size="6" color="text-grey-color" labelKey="deposits.detail.waytopay" light />
                        </Box>
                        <Box className="info-deposit-mov-list">
                            <Text size="6" color="text-grey-color" labelKey="deposits.detail.imprent" light />
                        </Box>
                        <Box className="info-deposit-mov-list">
                            <Text size="6" color="text-grey-color" labelKey="tableHeader.USDValue" light />
                        </Box>
                        <Box className="info-deposit-mov-list">
                            <Text size="6" color="text-grey-color" labelKey="deposits.detail.netousd" light />
                        </Box>
                    </Box>
                </Box>
            )}
            <Box className="table-rows-color">
                {data.map((movement, index) => {
                    const { fecha, transaccion, formaPago, impuestoRenta, valor, valorNeto } = movement;
                    return (
                        <Box display="flex" alignX="between" alignY="center" borderRadius="md">
                            {isDesktop ? (
                                <Box
                                    display="flex"
                                    alignY="center"
                                    fullWidth
                                    className={classNames("p-3  justify-content-between ", {
                                        "background-table-odd": index % 2 === 0,
                                        "background-table-even": index % 2 === 1,
                                    })}>
                                    <Box className="info-deposit-mov-list col-transaction">
                                        <FormattedDate date={fecha} />
                                        <Box>
                                            <Text size="6" bold>
                                                {transaccion}
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box className="info-deposit-mov-list size-6">{formaPago}</Box>
                                    <Box className="info-deposit-mov-list">
                                        <FormattedAmount size="6" quantity={impuestoRenta ?? 0} noCurrency />
                                    </Box>
                                    <Box className="info-deposit-mov-list">
                                        <FormattedAmount size="6" quantity={valor ?? 0} noCurrency />
                                    </Box>
                                    <Box className="info-deposit-mov-list">
                                        <FormattedAmount size="6" quantity={valorNeto ?? 0} noCurrency />
                                    </Box>
                                </Box>
                            ) : (
                                <Row className="p-5">
                                    <Col xs="6">
                                        <Box>
                                            <FormattedDate size="6" color="text-grey-color" date={fecha} />
                                        </Box>
                                        <Box className="mt-3">
                                            <Text
                                                size="6"
                                                color="text-grey-color"
                                                labelKey="deposits.detail.transaccion"
                                                light
                                            />
                                        </Box>
                                        <Box>
                                            <Text size="6" bold>
                                                {transaccion}
                                            </Text>
                                        </Box>
                                        <Box className="mt-3">
                                            <Text
                                                size="6"
                                                color="text-grey-color"
                                                light
                                                labelKey="deposits.detail.waytopay"
                                            />
                                        </Box>
                                        <Box>
                                            <Text size="6">{formaPago || "-"}</Text>
                                        </Box>
                                    </Col>
                                    <Col xs="6">
                                        <Box className="text-right mt-3">
                                            <Text
                                                size="6"
                                                color="text-grey-color"
                                                light
                                                labelKey="deposits.detail.imprent"
                                            />
                                        </Box>
                                        <Box display="flex" alignX="flex-end">
                                            <FormattedAmount size="5" quantity={impuestoRenta ?? 0} noCurrency />
                                        </Box>
                                        <Box className="text-right mt-3">
                                            <Text
                                                size="6"
                                                color="text-grey-color"
                                                light
                                                labelKey="tableHeader.USDValue"
                                            />
                                        </Box>
                                        <Box display="flex" alignX="flex-end">
                                            <FormattedAmount size="5" quantity={valor ?? 0} noCurrency />
                                        </Box>
                                        <Box className="text-right mt-3">
                                            <Text
                                                size="6"
                                                color="text-grey-color"
                                                light
                                                labelKey="deposits.detail.netousd"
                                            />
                                        </Box>
                                        <Box display="flex" alignX="flex-end">
                                            <FormattedAmount size="5" quantity={valorNeto ?? 0} noCurrency />
                                        </Box>
                                    </Col>
                                </Row>
                            )}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

ListMovementsDeposits.propTypes = {
    administrationScheme: string,
    usersInfo: shape({
        signatureLevel: string,
        massiveEnabled: bool,
    }),
    showCheckBox: bool,
    handleCheckClick: func,
    selectedUsers: shape({}),
};

ListMovementsDeposits.defaultProps = {
    administrationScheme: "advanced",
    usersInfo: undefined,
    showCheckBox: false,
    handleCheckClick: () => {},
    selectedUsers: {},
};

export default ListMovementsDeposits;
