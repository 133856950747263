import EmptyTextBox from "pages/_components/EmptyTextBox";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import DepositsList from "pages/deposits/List";
import { arrayOf, bool, func, number, shape } from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import deposits, { actions as depositsActions, selectors as depositsSelectors } from "reducers/deposits";
import { actions as productsActions } from "reducers/products";
import { selectors as sessionSelectors } from "reducers/session";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import * as config from "util/config";
import Text from "pages/_components/Text";
import { Modal } from "react-bootstrap";
import Box from "pages/_components/Box";

import EquivalentTotalBalance from "pages/deposits/TotalBalanceHeader";
import Container from "pages/_components/Container";
import { isDesktop } from "react-device-detect";
import classNames from "classnames";
import moment from "moment";
import FormFilter from "./_components/FormFilter";
import NoProduct from "../desktop/widgets/_components/NoProduct.jsx";

class Deposits extends Component {
    static propTypes = {
        isRequestAvailable: bool,
        deposits: arrayOf({ length: number.isRequired }),
        dispatch: func.isRequired,
        isMobile: bool,
        fetching: bool,
        showProductIcon: bool,
        isDesktop: bool,
        completeFavorites: bool,
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        isRequestAvailable: false,
        isMobile: false,
        fetching: false,
        deposits: [],
        showProductIcon: true,
        isDesktop: false,
        completeFavorites: false,
        showModalFilter: false,
    };

    state = {
        rowsPerPage: config.getInteger("table.rowsPerPage", 10),
        searchWord: "",
        searchStatus: "",
        searchSubProductType: "",
        searchExpirationDate: "",
        initDateExpirationDate: "",
        endDateExpirationDate: "",
        showModalFilter: false,
        downloading: false,
        filteredDeposits: deposits,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(depositsActions.listDeposits());
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.push("/desktop"));

        // dispatch(routerActions.goBack());
    };

    setFavoriteDeposit = (idProduct, productType, favorite) => {
        const { dispatch } = this.props;
        dispatch(productsActions.toggleFavorite(idProduct, productType, favorite, null, true));
    };

    hasPermissionToCreate = () => {
        const { activeEnvironment } = this.props;
        const { permissions } = activeEnvironment;
        return permissions.depositCreate || false;
    };

    cancelModal = () => {
        this.setState({
            showModalFilter: false,
        });
    };

    ApplyModal = () => {
        this.setState({ showModalFilter: false });
    };

    showModal = () => {
        if (!isDesktop) {
            const { dispatch } = this.props;
            dispatch(routerActions.push("/deposits/filter"));
        } else {
            this.setState({ showModalFilter: true });
        }
    };

    onFinishDownload = () => {
        this.setState({
            downloading: false,
        });
    };

    handleClickDownload = (format) => {
        const { dispatch } = this.props;
        this.setState({
            downloading: true,
        });
        dispatch(depositsActions.downloadDeposits(format, this.onFinishDownload));
    };

    renderHeader = () => {
        const { downloading } = this.state;

        const documents =
            deposits.length > 0
                ? [
                      {
                          label: "global.pdfFile",
                          onClick: () => this.handleClickDownload("pdf"),
                      },
                      {
                          label: "global.xlsFile",
                          onClick: () => this.handleClickDownload("xls"),
                      },
                      {
                          label: "global.txtFile",
                          onClick: () => this.handleClickDownload("txt"),
                      },
                  ]
                : null;

        return (
            <Head
                {...(!isDesktop && { onBack: this.handleBack })}
                title="deposits.myDeposits.header.title"
                exportList={documents}
                exportListBsStyle={isDesktop ? "download" : "only-icon"}
                isFetchingExport={downloading}
            />
        );
    };

    renderDeposits = () => {
        const { showProductIcon, completeFavorites, isDesktop, dispatch, filteredDeposits } = this.props;
        return (
            <>
                {filteredDeposits && filteredDeposits.length > 0 ? (
                    <>
                        <DepositsList
                            deposits={filteredDeposits}
                            setFavoriteDeposit={this.setFavoriteDeposit}
                            showProductIcon={showProductIcon}
                            completeFavorites={completeFavorites}
                            isDesktop={isDesktop}
                            dispatch={dispatch}
                        />
                    </>
                ) : (
                    <NoProduct text="desktop.widgets.deposits.empty" />
                )}
            </>
        );
    };

    renderFilterModal = () => {
        const { showModalFilter } = this.state;
        return (
            <>
                <Modal show={showModalFilter} className="drawer">
                    <Box className="p-10">
                        <Container className="container--layout items-center flex-grow-1">
                            <Box display="flex" alignX="left" alignY="left">
                                <Text size={3} bold labelKey="deposits.detail.filters" />
                            </Box>
                            <FormFilter
                                handleFilter={this.handleFilterClick}
                                handleCancel={() => {
                                    this.cancelModal();
                                }}
                                isDesktop={isDesktop}
                                maxDate={moment().add(1, "year")}
                            />
                        </Container>
                    </Box>
                </Modal>
            </>
        );
    };

    // renderx() {
    //     const { deposits, fetching, isDesktop, showProductIcon, completeFavorites, dispatch } = this.props;
    //     const isLoading = fetching && !deposits.length;

    //     return (
    //         <Fragment>
    //             <Notification scopeToShow="deposits" />
    //             {!isLoading && this.renderHeader()}
    //             <MainContainer showLoader={isLoading || deposits.length === 1}>
    //                 <Row className="mt-5 mt-md-0">
    //                     <Col xs={12} md={8} mdOffset={2}>
    //                         {deposits && deposits.length > 0 ? (
    //                             <>
    //                                 <DepositsList
    //                                     deposits={deposits}
    //                                     setFavoriteDeposit={this.setFavoriteDeposit}
    //                                     showProductIcon={showProductIcon}
    //                                     completeFavorites={completeFavorites}
    //                                     isDesktop={isDesktop}
    //                                     dispatch={dispatch}
    //                                 />
    //                             </>
    //                         ) : (
    //                             <EmptyTextBox text="product.empty.text.deposits" footerLink />
    //                         )}
    //                     </Col>
    //                 </Row>
    //             </MainContainer>
    //         </Fragment>
    //     );
    // }
    handleFilterClick = () => {
        this.cancelModal();
    };

    render() {
        const { deposits, fetching, showProductIcon, filteredDeposits } = this.props;
        const { rowsPerPage } = this.state;
        const modalFilter = this.renderFilterModal();
        const totalBalanceSum = deposits.reduce((acc, currentValue) => acc + currentValue.totalBalance, 0);

        return (
            <Fragment>
                <Notification scopeToShow="deposits" />
                {!fetching && this.renderHeader()}
                <MainContainer
                    showLoader={fetching}
                    className={classNames("main-container rounded-border-top p-7 background-white texture-header", {
                        "box-shadow-small": isDesktop,
                    })}>
                    {deposits?.length ? <EquivalentTotalBalance totalBalance={totalBalanceSum} /> : <></>}
                    {modalFilter}
                    {deposits?.length ? (
                        <PaginatedDataTable
                            nameList="deposits"
                            data={filteredDeposits}
                            hasFilterApplied={filteredDeposits.length !== deposits.length}
                            showFilterModal
                            filterAction={this.showModal}
                            rowsPerPage={rowsPerPage}
                            component={this.renderDeposits}
                            componentProps={{
                                showProductIcon,
                            }}
                        />
                    ) : (
                        <NoProduct text="desktop.widgets.deposits.empty" />
                    )}
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    deposits: depositsSelectors.getDeposits(state),
    fetching: depositsSelectors.getFetching(state),
    completeFavorites: depositsSelectors.getCompleteFavorites(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    filteredDeposits: depositsSelectors.getFilteredDeposits(state),
});

export default connect(mapStateToProps)(Deposits);
