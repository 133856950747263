import React from "react";
import { node, string } from "prop-types";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import "./Collapsible.scss";

const Collapsible = ({ children, title, id }) => (
    <Box>
        <Box className="the-tabs" display="flex" column>
            <Box className="tab">
                <input type="checkbox" id={id} />
                <label className="tab-label" htmlFor={id}>
                    <Text>{title}</Text>
                </label>
                <Box className="tab-content">{children}</Box>
            </Box>
        </Box>
    </Box>
);

Collapsible.propTypes = {
    children: node,
    title: string,
    id: string.isRequired,
};

Collapsible.defaultProps = {
    children: null,
    title: null,
};

export default Collapsible;
