import * as softtokenApi from "middleware/softToken/softToken";
import {
    softTokenDisclaimerTypes,
    actions as softTokenActions,
} from "reducers/softTokenDisclaimer/softTokenDisclaimer.reducer";
import { types as enrollmentTypes } from "reducers/enrollment";
import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "react-router-redux";
import {
    USER_TOKEN_STATUS_AUTHENTICATE,
    USER_TOKEN_STATUS_INACTIVE,
    USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID,
    USER_TOKEN_STATUS_MIGRATE_ENTRUST,
    USER_TOKEN_STATUS_MIGRATE_LOCAL,
} from "util/userToken.util";
import { BIOMETRIC_CALLBACK_SAVE_TOKEN } from "util/biometric.util";
import { actions as notificationActions } from "reducers/notification";
import * as i18nUtils from "util/i18n";

function* validateStatusToken() {
    const deviceUuid = window?.app?.getDeviceUUID() || "";

    const response = yield call(softtokenApi.validateStatusTokenOnBase, deviceUuid);

    if (!response?.type || !response?.data?.data) {
        yield put({
            type: softTokenDisclaimerTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield;
        return;
    }

    const { type, data } = response;
    if (type === "W") {
        yield put({
            type: softTokenDisclaimerTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield;
        return;
    }

    const { tokenStatus } = data?.data;
    if (!tokenStatus) {
        yield put({
            type: softTokenDisclaimerTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield;
        return;
    }

    yield put(softTokenActions.validateTokenStatusSuccess(tokenStatus));
}

function* tokenStatusAction({ redirectSuccess, redirectError, scopeSuccess, scopeError, redirectAbort }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";

    const response = yield call(softtokenApi.validateStatusToken, deviceUuid);
    if (!response?.type || !response?.data?.data) {
        yield put({
            type: softTokenDisclaimerTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield put({
            type: softTokenDisclaimerTypes.TOKEN_STATUS_ACTION_FINISH,
        });
        yield;
        return;
    }

    const { type, data } = response;
    if (type === "W") {
        yield put({
            type: softTokenDisclaimerTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield put({
            type: softTokenDisclaimerTypes.TOKEN_STATUS_ACTION_FINISH,
        });
        yield;
        return;
    }

    const { tokenStatus } = data?.data;
    if (!tokenStatus) {
        yield put({
            type: softTokenDisclaimerTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield put({
            type: softTokenDisclaimerTypes.TOKEN_STATUS_ACTION_FINISH,
        });
        yield;
        return;
    }

    yield put({
        type: softTokenDisclaimerTypes.TOKEN_STATUS_ACTION_FINISH,
    });
    if (tokenStatus === USER_TOKEN_STATUS_AUTHENTICATE) {
        yield put(push("/authenticateSofttoken"));
        yield;
        return;
    }
    if (tokenStatus === USER_TOKEN_STATUS_INACTIVE) {
        yield call(setCallBackDataSelfie, redirectSuccess, redirectError, scopeSuccess, scopeError, redirectAbort);
        yield put(push("/auth/tokenActivationStep1"));
        yield;
        return;
    }
    if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_ENTRUST) {
        yield put(push("/migrateSoftTokenEntrust"));
        yield;
        return;
    }
    if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_LOCAL) {
        yield call(setCallBackDataSelfie, redirectSuccess, redirectError, scopeSuccess, scopeError, redirectAbort);
        yield put(push("/auth/tokenActivationStep1"));
        yield;
        return;
    }
    if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID) {
        yield put(
            notificationActions.showNotification(
                i18nUtils.get("token.entrust.migrateEntrust.validate.error.message"),
                "error",
                ["tokenActivation"],
                false,
            ),
        );
        yield call(setCallBackDataSelfie, redirectSuccess, redirectError, scopeSuccess, scopeError, redirectAbort);
        yield put(push("/auth/tokenActivationStep1"));
        yield;
        return;
    }
    /** if token status is undefined redirect to /settings/authenticatorHandler,
     * for example the user have a token with status INACTIVE but in entrust it is locked
     */

    yield put(push("/settings/authenticatorHandler"));
}

function* setCallBackDataSelfie(redirectSuccess, redirectError, scopeSuccess, scopeError, redirectAbort) {
    const callBackSelfie = {
        type: BIOMETRIC_CALLBACK_SAVE_TOKEN,
        data: {
            deviceUuid: window?.app?.getDeviceUUID() || "",
            deviceModel: window?.app?.model || "",
            deviceBrand: window?.app?.manufacturer || "",
        },
        redirectSuccess: redirectSuccess || "/desktop",
        redirectError: redirectError || "/desktop",
        scopeSuccess: scopeSuccess && scopeSuccess.length > 0 ? scopeSuccess : ["/desktop"],
        scopeError: scopeError && scopeError.length > 0 ? scopeError : ["/desktop"],
        redirectAbort: redirectAbort || "/desktop",
    };
    yield put({
        type: enrollmentTypes.SET_CALLBACK_DATA_SELFIE,
        callbackDataSelfie: callBackSelfie,
    });
}

const sagas = [
    takeLatest(softTokenDisclaimerTypes.VALIDATE_TOKEN_STATUS_REQUEST, validateStatusToken),
    takeLatest(softTokenDisclaimerTypes.TOKEN_STATUS_ACTION, tokenStatusAction),
];

export default sagas;
