import React, { useState } from "react";
import { withFormik, Form } from "formik";
import * as Yup from "yup";
import { bool, func, string } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { actions as recoveryPasswordActions, selectors } from "reducers/recoveryPassword";
import * as i18n from "util/i18n";

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import Stepper from "pages/_layouts/DefaultStepperLayout/Stepper";
import { routerActions } from "react-router-redux";
import classNames from "classnames";
import SecondFactor from "pages/secondFactor/SecondFactor";
import MainContainer from "pages/_components/MainContainer";
import Col from "react-bootstrap/lib/Col";
import Row from "pages/_components/Row";
import { steps } from "./utils";

const FORM_ID = "recoveryPassword.step2";

const Step2Content = (props) => {
    const { isSubmitting, isMobile, dispatch, setFieldValue, exchangeToken, submitForm } = props;

    const [canSubmit, setCanSubmit] = useState(false);

    const handleCancel = () => {
        dispatch(routerActions.push("/"));
    };

    const renderButton = () => (
        <Row className="mb-7">
            <Col xs={6}>
                <Button
                    label="global.continue"
                    type="submit"
                    bsStyle="primary"
                    className="mt-5"
                    loading={isSubmitting}
                    block
                    disabled={!canSubmit}
                />
            </Col>
            <Col xs={6}>
                <Button
                    label="global.cancel"
                    type="button"
                    bsStyle="outline"
                    className="mt-5"
                    onClick={handleCancel}
                    block
                />
            </Col>
        </Row>
    );

    if (!exchangeToken) {
        return <></>;
    }

    return (
        <MainContainer>
            <Box fullWidth>
                <Form>
                    <Box display="flex" column className={isMobile && "mt-7"}>
                        {isMobile && <Text labelKey="recoveryPassword.step1.header" size="2" bold />}
                        <Stepper
                            className={classNames("stepper-default mb-6", {
                                "mb-6 ": isMobile,
                                "mb-7": !isMobile,
                            })}
                            stepsList={steps}
                            currentStep={2}
                            completed={1}
                            showLabelMobile
                        />
                        <Box display="flex" column className="mb-2 mt-4">
                            <SecondFactor
                                onChangeToken={(tokenCode) => {
                                    if (setFieldValue) {
                                        setFieldValue("secondFactor", tokenCode);
                                        submitForm();
                                    }
                                }}
                                onValidSubmit={(validData) => {
                                    setCanSubmit(validData.submit);
                                }}
                                withEchangeToken={exchangeToken}
                                idActivity="session.recoverPassword.step2"
                                buttons={renderButton()}
                                isSubmitting={isSubmitting}
                            />
                        </Box>
                    </Box>
                </Form>
            </Box>
        </MainContainer>
    );
};

Step2Content.propTypes = {
    isSubmitting: bool,
    isMobile: bool.isRequired,
    dispatch: func.isRequired,
    exchangeToken: string.isRequired,
    setFieldValue: func.isRequired,
    submitForm: func.isRequired,
};

Step2Content.defaultProps = {
    isSubmitting: false,
};

const mapStateToProps = (state) => ({
    exchangeToken: selectors.getExchangeToken(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            secondFactor: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                secondFactor: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
            }),
        handleSubmit: ({ secondFactor }, formikBag) => {
            const {
                props: { exchangeToken },
            } = formikBag;
            formikBag.props.dispatch(recoveryPasswordActions.recoveryPassStep2(secondFactor, exchangeToken, formikBag));
        },
    }),
)(Step2Content);
