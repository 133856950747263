import React, { useEffect, useState } from "react";
import { arrayOf, bool, func, shape, string } from "prop-types";
import Head from "pages/_components/Head";
import { push } from "react-router-redux";
import MainContainer from "pages/_components/MainContainer";
import Box from "pages/_components/Box";
import * as i18n from "util/i18n";
import classNames from "classnames";
import { actions as checksActions, selectors as checksSelectors } from "reducers/checks";
import { selectors as accountsSelectors } from "reducers/accounts";
import { connect } from "react-redux";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import InfoCard from "pages/_components/InfoCard";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";

const Details = (props) => {
    const { dispatch, checks, match, isDesktop, accountNumber, documentImage, accounts, isFetching } = props;
    const PREFIX_KEY = "checks.image.detail";
    const [check, setCheck] = useState({});
    const [descriptionAccount, setDescriptionAccount] = useState("");

    useEffect(() => {
        const checkSelected = getCheck(match.params.id);
        setCheck(checkSelected);
        loadDataAccount();
        dispatch(
            checksActions.imageCheck(accountNumber, checkSelected?.numberOfCheck, checkSelected?.registrationDate),
        );
    }, [checks]);

    const getCheck = (id) => checks?.find((c) => c.numberOfCheck === id);
    const loadDataAccount = () => {
        const selectedAccount = accounts.find((account) => account.number === accountNumber);
        if (selectedAccount) {
            setDescriptionAccount(
                `${selectedAccount.numberMask} - ${selectedAccount.ownerName} - ${selectedAccount.productTypeBackend} - ${selectedAccount.currency}`,
            );
        }
    };

    const handleBack = () => {
        dispatch(push("/checks"));
    };

    const blueStatus = ["C", "G", "H"];
    const redStatus = ["A", "D", "E", "F", "O", "R", "S", "T", "V", "W", "Y"];
    const grayStatus = ["N", "X"];

    const renderEmptyImgs = () => (
        <Box className="m-8">
            <Image src="images/emptyFile.svg" />
            <Box className="text-center mt-5 mb-4">
                <Text labelKey={`${PREFIX_KEY}.noImage.title`} bold size="3" />
            </Box>
            <Box className="text-center">
                <Text labelKey={`${PREFIX_KEY}.noImage.text`} />
            </Box>
        </Box>
    );

    const renderImgsMobile = (image) => {
        if (!isDesktop) {
            const { frontImage, backImage } = image;
            return (
                <>
                    {(frontImage || backImage) && (
                        <>
                            <Box className="pt-5">
                                {frontImage && <img src={`data:image/jpg;base64, ${frontImage}`} alt="anverso" />}
                            </Box>
                            <Box className="pt-5">
                                {backImage && <img src={`data:image/jpg;base64, ${backImage}`} alt="reverso" />}
                            </Box>
                        </>
                    )}

                    {!frontImage && !backImage && <>{renderEmptyImgs()}</>}
                </>
            );
        }
        return null;
    };

    const renderImgs = (image) => {
        const { frontImage, backImage } = image;
        return (
            <>
                {(frontImage || backImage) && (
                    <Box
                        background="background-disabled"
                        borderRadius="lg"
                        className="p-5 box-detail-img mt-5"
                        display="flex"
                        gapX="5"
                        alignX="center">
                        {frontImage && <img src={`data:image/jpg;base64, ${frontImage}`} alt="anverso" />}
                        {backImage && <img src={`data:image/jpg;base64, ${backImage}`} alt="reverso" />}
                    </Box>
                )}

                {!frontImage && !backImage && <>{renderImgsMobile()}</>}
            </>
        );
    };

    const renderMobileContent = () => (
        <>
            <Box className="detail-head-info pt-0">
                <Row className="align-items-center">
                    <Col xs={2} md={2}>
                        <Box>
                            <Image src="images/money-circle.svg" wrapperClassName="item-info-icon" />
                        </Box>
                    </Col>
                    <Col xs={6} md={6}>
                        <Box className="ml-n-4">
                            <Text size="4" labelKey="checks.header.detail.data.label" semibold />
                        </Box>
                    </Col>
                    <Col xs={4} md={4}>
                        <Box>
                            <div
                                className={classNames(
                                    { "check-status-text": check?.status === "P" },
                                    { "check-status-info-text": blueStatus.includes(check?.status) },
                                    { "check-status-err-text": redStatus.includes(check?.status) },
                                    { "check-status-war-text": grayStatus.includes(check?.status) },
                                )}>
                                <span className="data-desc">
                                    {i18n.get(`checks.status.description.${check?.status}`)}
                                </span>
                            </div>
                        </Box>
                    </Col>
                </Row>
                <Box className="px-0 ml-n-4">
                    <InfoCard
                        className="mb-n-4"
                        title="checks.detail.account.label"
                        isDesktop={isDesktop}
                        message={descriptionAccount}
                    />
                    <InfoCard
                        className="mb-n-4"
                        title="checkbooks.filter.number.label"
                        isDesktop={isDesktop}
                        message={check?.numberOfCheck}
                    />

                    <InfoCard
                        className="mb-n-4"
                        title="checks.detail.date.label"
                        isDesktop={isDesktop}
                        date={check?.registrationDate}
                    />

                    <InfoCard
                        className="mb-n-4"
                        title="checks.detail.amount.label"
                        isDesktop={isDesktop}
                        quantity={check?.checkAmount}
                        currency="USD"
                        noCurrency
                        amountClassnames="info-card-message text-bold"
                    />

                    <InfoCard
                        className="mb-n-4"
                        title="checks.detail.branch.label"
                        isDesktop={isDesktop}
                        message={check?.branch || "-"}
                    />

                    <InfoCard
                        className="mb-n-4"
                        title="checks.detail.user.label"
                        isDesktop={isDesktop}
                        message={check?.user}
                    />
                </Box>
                <Box />
                <Box
                    className="mt-7"
                    component="article"
                    display="flex"
                    // borderRadius="default"
                    fullWidth
                    alignY="center">
                    <Box style={{ width: "38px" }}>
                        <Image src="images/photo-circle.svg" wrapperClassName="item-info-icon width-max-content" />
                    </Box>
                    <Box fullWidth className="mb-2 ml-4">
                        <Text size="4" labelKey="checks.detail.image.label" semibold />
                    </Box>
                </Box>
            </Box>
            {renderImgsMobile(documentImage || {})}
        </>
    );

    return (
        <>
            <Head
                onBack={handleBack}
                textBack="checks.header.detail.back"
                titleText={i18n.get("checks.header.detail.title")}>
                {isDesktop && (
                    <Box display="flex" className="ml-5">
                        <div
                            className={classNames(
                                { "check-status-text": check?.status === "P" },
                                { "check-status-info-text": blueStatus.includes(check?.status) },
                                { "check-status-err-text": redStatus.includes(check?.status) },
                                { "check-status-war-text": grayStatus.includes(check?.status) },
                            )}>
                            <span className="data-desc">{i18n.get(`checks.status.description.${check.status}`)}</span>
                        </div>
                    </Box>
                )}
            </Head>
            <MainContainer
                className="px-0 border-radius-lg p-7 box-shadow-small background-white"
                showLoader={isFetching}>
                {isDesktop ? (
                    <Box className="detail-head-info pt-0">
                        <Box
                            component="article"
                            display="flex"
                            // borderRadius="default"
                            fullWidth
                            alignY="center">
                            <Box>
                                <Image src="images/money-circle.svg" wrapperClassName="item-info-icon" />
                            </Box>
                            <Box fullWidth className="mb-2 ml-4">
                                <Text
                                    {...(isDesktop && { size: "3" })}
                                    {...(!isDesktop && { uppercase: false, size: "6" })}
                                    labelKey="checks.header.detail.data.label"
                                    semibold
                                />
                            </Box>
                        </Box>
                        <Row className="px-0 ml-n-4">
                            <Col xs={12} md={12} className="full-height">
                                <Row gapX="3" gapY="3" className="full-height">
                                    <Col xs={6} md={3}>
                                        <InfoCard
                                            title="checks.detail.account.label"
                                            isDesktop={isDesktop}
                                            message={descriptionAccount}
                                        />
                                    </Col>
                                    <Col xs={6} md={3}>
                                        <InfoCard
                                            title="checkbooks.filter.number.label"
                                            isDesktop={isDesktop}
                                            message={check?.numberOfCheck}
                                        />
                                    </Col>
                                    <Col xs={6} md={3}>
                                        <InfoCard
                                            title="checks.detail.date.label"
                                            isDesktop={isDesktop}
                                            date={check?.registrationDate}
                                        />
                                    </Col>
                                    <Col xs={6} md={3}>
                                        <InfoCard
                                            title="checks.detail.amount.label"
                                            isDesktop={isDesktop}
                                            quantity={check?.checkAmount}
                                            currency="USD"
                                            noCurrency
                                            amountClassnames="info-card-message text-bold"
                                        />
                                    </Col>
                                    <Col xs={6} md={3}>
                                        <InfoCard
                                            title="checks.detail.branch.label"
                                            isDesktop={isDesktop}
                                            message={check?.branch || "-"}
                                        />
                                    </Col>
                                    <Col xs={6} md={3}>
                                        <InfoCard
                                            title="checks.detail.user.label"
                                            isDesktop={isDesktop}
                                            message={check?.user}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Box />
                        <Box
                            component="article"
                            display="flex"
                            // borderRadius="default"
                            fullWidth
                            alignY="center">
                            <Box>
                                <Image src="images/photo-circle.svg" wrapperClassName="item-info-icon" />
                            </Box>
                            <Box fullWidth className="mb-2 ml-4">
                                <Text
                                    {...(isDesktop && { size: "3" })}
                                    {...(!isDesktop && { uppercase: false, size: "6" })}
                                    labelKey="checks.detail.image.label"
                                    semibold
                                />
                            </Box>
                        </Box>
                        {renderImgs(documentImage || {})}
                    </Box>
                ) : (
                    <>{renderMobileContent()}</>
                )}
            </MainContainer>
        </>
    );
};
Details.propTypes = {
    dispatch: func.isRequired,
    match: shape({}).isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    checks: arrayOf(shape({})).isRequired,
    accounts: arrayOf(shape({})).isRequired,
    accountNumber: string.isRequired,
    documentImage: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: checksSelectors.getFetching(state),
    checks: checksSelectors.checks(state),
    accountNumber: checksSelectors.accountNumber(state),
    documentImage: checksSelectors.getDocumentImage(state),
    accounts: accountsSelectors.getAccounts(state),
});

export default connect(mapStateToProps)(resizableRoute(Details));
