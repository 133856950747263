import { Field, Form, withFormik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, shape, string } from "prop-types";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import * as config from "util/config";
import { getPasswordHelp } from "pages/settings/ChangePassword";
import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";
import Button from "pages/_components/Button";
import Box from "pages/_components/Box";
import Credential from "pages/_components/fields/credentials/Credential";
import LabelWithIcon from "pages/_components/LabelWithIcon";
import Stepper from "pages/_layouts/DefaultStepperLayout/Stepper";
import { routerActions } from "react-router-redux";
import Text from "pages/_components/Text";
import classNames from "classnames";
import Col from "react-bootstrap/lib/Col";
import Row from "pages/_components/Row";
import MainContainer from "pages/_components/MainContainer";
import { validatePassword } from "util/credentials";
import { steps } from "./utils";

const FORM_ID = "recoveryPassword.step3";

class Step3Content extends Component {

    constructor(props){
        super(props);
        this.inputPassword = React.createRef();
        this.inputNewPasswordRepeat = React.createRef();
    }

    handleCancel = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.push("/"));
    };

    render() {
        const { isMobile, isSubmitting, values, isValid } = this.props;

        const {
            isErrorForm,
            hasSpecialChar,
            hasNumericChar,
            hasUppercase,
            hasLowercase,
            minLength,
            maxLength,
        } = validatePassword(values);

        const isValidForm = isErrorForm && isValid;

        const alertMessage = (
            <Box fullWidth className="mb-7 mb-md-7 ">
                <LabelWithIcon
                    type={
                        values.newPassword.length < minLength || values.newPassword.length > maxLength
                            ? "error"
                            : "alert"
                    }
                    text="recovery.password.step3.password.alert.1"
                    textParams={{ minLength, maxLength }}
                />
                <LabelWithIcon
                    type={!hasSpecialChar ? "error" : "alert"}
                    text="recovery.password.step3.password.alert.2"
                />
                <LabelWithIcon
                    type={!hasNumericChar ? "error" : "alert"}
                    text="recovery.password.step3.password.alert.3"
                />
                <LabelWithIcon
                    type={!hasUppercase ? "error" : "alert"}
                    text="recovery.password.step3.password.alert.4"
                />
                <LabelWithIcon
                    type={!hasLowercase ? "error" : "alert"}
                    text="recovery.password.step3.password.alert.5"
                />
            </Box>
        );

        const renderButton = () => (
            <Row className={isMobile ? "mt-7" : "mt-3"}>
                <Col xs={6}>
                    <Button
                        label="recoveryPassword.step3.confirm"
                        type="submit"
                        bsStyle="primary"
                        className="mt-5"
                        loading={isSubmitting}
                        disabled={!isValidForm}
                        block
                    />
                </Col>
                <Col xs={6}>
                    <Button
                        label="global.cancel"
                        type="button"
                        bsStyle="outline"
                        className="mt-5"
                        onClick={this.handleCancel}
                        block
                    />
                </Col>
            </Row>
        );

        return (
            <MainContainer>
                <Form autoComplete="off">
                    <Box
                        display="flex"
                        className={isMobile && "mt-7"}
                        column
                        fullWidth
                        {...(isMobile ? { fullHeight: true } : { fullHeight: false })}>
                        {isMobile && <Text labelKey="recoveryPassword.step1.header" size="2" bold />}
                        <Box display="flex" fullWidth className="pb-7" />
                        <Stepper
                            className={classNames("stepper-default", {
                                "mb-6": isMobile,
                                "mb-7": !isMobile,
                            })}
                            stepsList={steps}
                            currentStep={3}
                            completed={2}
                            showLabelMobile
                        />
                        <Box display="flex" column className="pb-7">
                            <Field
                                inputRef={this.inputPassword}
                                name="newPassword"
                                idForm={FORM_ID}
                                component={Credential}
                                maxLength={maxLength}
                                placeholder=""
                                tooltip={getPasswordHelp()}
                                className="mb-7"
                                tooltipPosition="bottom-right"
                            />
                            {alertMessage}
                            <Field
                                inputRef={this.inputNewPasswordRepeat}
                                name="newPasswordRepeat"
                                idForm={FORM_ID}
                                component={Credential}
                                maxLength={maxLength}
                                placeholder=""
                            />
                            {renderButton()}
                        </Box>
                    </Box>
                </Form>
            </MainContainer>
        );
    }
}

Step3Content.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    isSubmitting: bool.isRequired,
    values: shape({
        newUsername: string,
        newPassword: string,
        newPasswordRepeat: string,
    }).isRequired,
    isValid: bool.isRequired,
};

export default compose(
    connect(),
    withFormik({
        validateOnChange: true,
        validateOnBlur: false,
        mapPropsToValues: () => ({ newPassword: "", newPasswordRepeat: "" }),
        validationSchema: () =>
            Yup.object().shape({
                newPassword: Yup.string().required(i18n.get(`${FORM_ID}.newPassword.required`)),
                newPasswordRepeat: Yup.string()
                    .required(i18n.get(`${FORM_ID}.newPasswordRepeat.required`))
                    .oneOf([Yup.ref("newPassword")], i18n.get("password.newPasswordRepeat.passwordNotTheSame")),
            }),

        handleSubmit: (values, formikBag) => {
            const {
                props: { exchangeToken },
            } = formikBag;
            const { newPassword, newPasswordRepeat } = values;

            formikBag.props.dispatch(
                recoveryPasswordActions.recoveryPassStep3(newPassword, newPasswordRepeat, exchangeToken, formikBag),
            );
        },
    }),
)(Step3Content);
