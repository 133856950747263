/* eslint-disable*/
import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { compose } from "redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux/actions";
import { arrayOf, bool, func, shape } from "prop-types";
import * as i18n from "util/i18n";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import TextField from "pages/_components/fields/TextField";
import Head from "pages/_components/Head";
import ProductDetail from "pages/_components/ProductDetail";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { actions as wallyActions, selectors as wallySelectors } from "reducers/wally";
import { requestPermission, findContact, getContactDisplayName } from "util/contact.util";
import ListCommerce from "./WallyContactComponents/ListCommerce";
import ListContacts from "./WallyContactComponents/ListContacts";
import { formatAccount, formatPhone } from "./hooks/WallyHooks";
import { filterData } from "util/array";
import { routerActions } from "react-router-redux";
import { actions as notificationActions } from "reducers/notification";

const SendWally = (props) => {
    const {
        isDesktop,
        dispatch,
        isActiveWaly,
        beneficiariesCommercePA,
        beneficiariesCommerceOT,
        beneficiariesPeople,
        accountWally,
        isFetching,
        isFetchingPeople,
    } = props;

    const [listCommercePA, setListCommercePA] = useState([]);
    const [listCommerceOT, setListCommerceOT] = useState([]);
    const [listPeople, setListPeople] = useState([]);
    const [responseRequest, setResponseRequest] = useState(false);
    const [contactList, setContactList] = useState([]);
    const [loading, setLoading] = useState(true);
    const filters = {
        panama: "PA",
        others: "0T",
    };

    useEffect(() => {
        if (isActiveWaly) {
            dispatch(wallyActions.listBeneficiariesCommerceRequest(filters.panama, 1, 0));
            dispatch(wallyActions.listBeneficiariesCommerceRequest(filters.others, 1, 0));
            requestPhoneNumbers();
        } else {
            dispatch(push("/wally"));
        }
    }, []);

    useEffect(() => {
        if (!isFetching && beneficiariesCommercePA.length > 0) {
            setListCommercePA(beneficiariesCommercePA);
        }
        if (!isFetching && beneficiariesCommerceOT.length > 0) {
            setListCommerceOT(beneficiariesCommerceOT);
        }
        if (!isFetchingPeople && beneficiariesPeople.length > 0) {
            setListPeople(beneficiariesPeople);
        }
    }, [beneficiariesCommercePA, beneficiariesCommerceOT, beneficiariesPeople, isFetchingPeople]);

    useEffect(() => {
        if (contactList.length > 0 && responseRequest) {
            const arrPhone = [];
            const arrContacts = [];
            contactList.forEach((element) => {
                if (element?.phoneNumbers.length > 0) {
                    const number = formatPhone(element.phoneNumbers[0].value);
                    if (number) {
                        arrPhone.push(number);
                        arrContacts.push({ number, displayName: getContactDisplayName(element) });
                    }
                }
            });
            setLoading(true);
            dispatch(wallyActions.listBeneficiariesPeopleRequest(arrPhone, arrContacts, () => setLoading(false)));
        }
    }, [contactList]);

    const requestPhoneNumbers = async () => {
        const responseRequest = await requestPermission();
        if (responseRequest?.read === undefined || (responseRequest.read !== true && responseRequest !== "true")) {
            dispatch(
                notificationActions.showNotification(
                    i18n.get("wally.forms.transfers.contact.permission.denied"),
                    "error",
                    ["desktop"],
                    true,
                    null,
                    () => (
                        <Box alignX="center" display="flex">
                            <Button
                                label="wally.forms.transfers.contact.permission.open"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (!window?.cordova?.plugins?.settings) {
                                        return;
                                    }
                                    window.cordova.plugins.settings.open(
                                        "application_details",
                                        function() {},
                                        function() {},
                                    );
                                }}
                                bsStyle="link"
                            />
                        </Box>
                    ),
                ),
            );

            dispatch(routerActions.replace("/desktop"));
            return;
        }
        const responseContactList = await findContact({
            fields: {
                phoneNumbers: true,
            },
        });
        setResponseRequest(responseRequest);
        setContactList(responseContactList);
        if (!responseRequest) {
            setLoading(false);
        }
    };

    const filterBeneficiaries = (value, beneficiaries, keys, setBeneficiaries) => {
        if (value !== "") {
            const data = filterData(beneficiaries, keys, value);
            setBeneficiaries(data);
        } else {
            setBeneficiaries(beneficiaries);
        }
    };

    const handleBack = () => {
        dispatch(push("/wally"));
    };

    const onSelectBeneficiary = (beneficiary) => {
        dispatch(wallyActions.setSelectedBeneficiary(beneficiary));
        dispatch(push("/sendWallyTransaction"));
    };

    const handleFavorite = (beneficiary) => {
        dispatch(wallyActions.favoriteRequest(beneficiary));
    };

    const [isCommercePanama, setIsCommercePanama] = useState(true);

    return (
        <>
            <Head
                titleImage="wally-head.svg"
                subtitlePrefix={accountWally?.productAlias}
                subtitle={formatAccount(accountWally)}
                onBack={handleBack}
                title="wally.forms.transfers.title"
            />
            <ProductDetail>
                <ProductDetail.Body
                    fetching={loading}
                    id="productDetail.body"
                    isDesktop={isDesktop}
                    tabBackground="component-background"
                    tabBorderRadius="top-default"
                    background={null}
                    wally
                    scroll
                    beforeTabs={
                        <Box display="flex" column alignX="center" alignY="center" className="pt-7 pb-6">
                            <Text bold size="2" align="center" color="heading" labelKey="wally.forms.transfers.help" />
                        </Box>
                    }>
                    <Box
                        keyLabel="wally.forms.transfers.people"
                        keyLabelColor="wally"
                        iconLabel="images/contact-wally.svg"
                        display="flex"
                        column
                        alignX="center"
                        fullWidth
                        showIconAllways>
                        <Formik>
                            <Form className="full-width">
                                <Box
                                    display="flex"
                                    background="component-background"
                                    borderRadius="bottom-default"
                                    alignY="center"
                                    className="pt-7 pb-9 pl-5 pr-7 mb-3"
                                    fullWidth>
                                    <Field
                                        component={TextField}
                                        name="filter"
                                        type="text"
                                        placeholder={i18n.get("global.search")}
                                        showLabel={false}
                                        noLabel
                                        serarchStyle
                                        labelNoMarginTop
                                        handleOnChange={(event) =>
                                            filterBeneficiaries(
                                                event.target.value,
                                                beneficiariesPeople,
                                                ["phoneNumber", "fullName", "displayName"],
                                                setListPeople,
                                            )
                                        }
                                    />
                                    <Button
                                        className="ml-3"
                                        imageMd
                                        image="images/add-contact.svg"
                                        href="/sendWallyAddContact"
                                    />
                                </Box>
                            </Form>
                        </Formik>

                        <Box
                            display="flex"
                            background="component-background"
                            borderRadius="default"
                            alignY="center"
                            fullWidth>
                            <ListContacts
                                contacts={listPeople}
                                onSelectBeneficiary={onSelectBeneficiary}
                                updateFavorite={handleFavorite}
                            />
                        </Box>
                    </Box>
                    <Box
                        keyLabel="wally.forms.transfers.commerce"
                        keyLabelColor="wally"
                        colorIconLabel="wally"
                        iconLabel="images/commerce.svg"
                        showIconAllways>
                        <Box
                            keyLabel="wally.forms.transfers.people"
                            keyLabelColor="wally"
                            iconLabel="images/contact-wally.svg"
                            display="flex"
                            column
                            alignX="center"
                            fullWidth
                            showIconAllways>
                            <Formik>
                                <Box
                                    display="flex"
                                    column
                                    background="component-background"
                                    borderRadius="bottom-default"
                                    alignX="center"
                                    className="pt-7 pb-7 px-5 mb-3"
                                    fullWidth>
                                    <Row gapY="3" heightAuto className="mb-5">
                                        <Col xs={6}>
                                            <Button
                                                xs
                                                block
                                                onClick={() => setIsCommercePanama(true)}
                                                bsStyle="outline"
                                                btnUppercase={false}
                                                className={classNames("wally", { "is-active": isCommercePanama })}>
                                                {i18n.get("wally.forms.transfers.panama")}
                                            </Button>
                                        </Col>
                                        <Col xs={6}>
                                            <Button
                                                xs
                                                block
                                                onClick={() => setIsCommercePanama(false)}
                                                bsStyle="outline"
                                                btnUppercase={false}
                                                className={classNames("wally", { "is-active": !isCommercePanama })}>
                                                {i18n.get("wally.forms.transfers.otherCountries")}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Form className="full-width">
                                        <Field
                                            component={TextField}
                                            name="filter"
                                            type="text"
                                            placeholder={i18n.get("global.search")}
                                            showLabel={false}
                                            noLabel
                                            serarchStyle
                                            labelNoMarginTop
                                            handleOnChange={(event) => {
                                                if (isCommercePanama) {
                                                    filterBeneficiaries(
                                                        event.target.value,
                                                        beneficiariesCommercePA,
                                                        ["fullName"],
                                                        setListCommercePA,
                                                    );
                                                } else {
                                                    filterBeneficiaries(
                                                        event.target.value,
                                                        beneficiariesCommerceOT,
                                                        ["fullName"],
                                                        setListCommerceOT,
                                                    );
                                                }
                                            }}
                                        />
                                    </Form>
                                </Box>
                            </Formik>
                            <Box
                                display="flex"
                                background="component-background"
                                borderRadius="default"
                                alignY="center"
                                fullWidth>
                                <ListCommerce
                                    viewList={isCommercePanama}
                                    commercePanama={listCommercePA}
                                    commerceOtherCountry={listCommerceOT}
                                    onSelectBeneficiary={onSelectBeneficiary}
                                />
                            </Box>
                        </Box>
                    </Box>
                </ProductDetail.Body>
            </ProductDetail>
        </>
    );
};

const mapStateToProps = (state) => ({
    isActiveWaly: wallySelectors.getIsActiveWally(state),
    beneficiariesCommercePA: wallySelectors.getBeneficiariesCommercePA(state),
    beneficiariesCommerceOT: wallySelectors.getBeneficiariesCommerceOT(state),
    beneficiariesPeople: wallySelectors.getBeneficiariesPeople(state),
    accountWally: wallySelectors.getAccountWally(state),
    isFetching: wallySelectors.isFetching(state),
    isFetchingPeople: wallySelectors.isFetchingPeople(state),
});

SendWally.propTypes = {
    dispatch: func.isRequired,
    isActiveWaly: bool,
    isDesktop: bool.isRequired,
    beneficiariesCommercePA: arrayOf(shape({})),
    beneficiariesCommerceOT: arrayOf(shape({})),
    beneficiariesPeople: arrayOf(shape({})),
    accountWally: shape({}),
    isFetching: bool,
    isFetchingPeople: bool,
};

SendWally.defaultProps = {
    isActiveWaly: false,
    beneficiariesCommercePA: [],
    beneficiariesCommerceOT: [],
    beneficiariesPeople: [],
    accountWally: {},
    isFetching: false,
    isFetchingPeople: false,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(SendWally));
