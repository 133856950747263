import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import { bool, func, node, string } from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";

const HelpModal = (props) => {
    const { headingText, headingIcon, children, cancelFunction, modalShow } = props;

    return (
        <div className="modal-container modal-help">
            <Modal show={modalShow} dialogClassName="modal-help-dialog">
                <Button
                    onClick={cancelFunction}
                    image="images/lucideIcons/x.svg"
                    className="cross-close m-5"
                    bsStyle="only-icon"
                />
                <Box alignX="center" className="title-with-icon">
                    {headingIcon && <Image src={headingIcon} />}
                    <Text>{headingText}</Text>
                </Box>
                <Box className="modal-help-content">{children}</Box>
                <Button
                    onClick={cancelFunction}
                    label="global.close"
                    bsStyle="primary"
                    className="mt-8"
                    block
                    btnUppercase={false}
                />
            </Modal>
        </div>
    );
};

HelpModal.propTypes = {
    cancelFunction: func.isRequired,
    headingText: string.isRequired,
    headingIcon: string,
    modalShow: bool.isRequired,
    children: node.isRequired,
};
HelpModal.defaultProps = {
    headingIcon: null,
};

export default resizableRoute(HelpModal);
