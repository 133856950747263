import React from "react";
/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { func, number as numberType, oneOfType, shape, string } from "prop-types";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";

const AccountCardView = ({
    idProduct,
    number,
    name,
    title,
    reference,
    balance,
    currency,
    amountLabel,
    onClick,
    path,
    productType,
    productsMaskAmount,
    dispatch,
}) => {
    const urlLogo = `images/products/logo_${productType}.svg`;
    const urlIcon = `images/products/icon_${productType}.svg`;

    const maskAmount =
        !productsMaskAmount || productsMaskAmount[idProduct] === undefined
            ? true
            : productsMaskAmount[idProduct] || false;
    const updateMaskAmount = () => {
        dispatch(sessionActions.maskAmountUpdateRequest(idProduct, !maskAmount));
    };

    return (
        <Box display="flex" alignX="between" alignY="center" className="prod-item prod-accounts" noList>
            <Link
                aria-describedby={idProduct}
                className={classNames("prod-item-link full-width")}
                to={path}
                onClick={() => onClick()}>
                <Box fullWidth>
                    <Box className={classNames("prod-item-wrapper")} alignX="between" fullWidth gap="4" flex1>
                        <Box display="flex" alignY="flex-start" className="prod-item-info-wrapper mr-auto">
                            <Box className="item-info-wrapper">
                                <Image src={urlLogo} wrapperClassName="item-info-icon" />
                                <Text
                                    align="left"
                                    color="text"
                                    size="5"
                                    bold
                                    className="mt-1"
                                    ariaLabel={`${name} - ${title} - ${reference}`}
                                    ellipsis>
                                    {number}
                                </Text>
                            </Box>
                            <Box className="btn-hide-amount ml-auto">
                                <Button
                                    onClick={() => {
                                        updateMaskAmount();
                                    }}
                                    image={maskAmount ? "images/icons/showOff.svg" : "images/icons/show.svg"}
                                    imageMd
                                />
                            </Box>
                        </Box>
                        <Box align="left" className="mt-5">
                            <Text align="left" color="text" size="7">
                                <span className="prod-item-amount-label">
                                    <I18n id={amountLabel} /> {currency}
                                </span>
                            </Text>
                        </Box>
                        <Box id={idProduct} display="flex" className="prod-item-amount-wrapper mt-3" flex1>
                            <Box display="flex" alignY="center">
                                <FormattedAmount
                                    color="text"
                                    idProduct={idProduct}
                                    quantity={balance}
                                    noCurrency
                                    className="prod-item-amount-value"
                                />
                            </Box>
                            <Box display="flex" className="ml-auto">
                                <Image
                                    className="prod-item-icon"
                                    wrapperClassName="prod-item-icon-svg-wrapper"
                                    src={urlIcon}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Link>
        </Box>
    );
};

AccountCardView.propTypes = {
    idProduct: string.isRequired,
    number: string.isRequired,
    name: string,
    title: string,
    reference: string,
    balance: numberType.isRequired,
    currency: string.isRequired,
    amountLabel: string.isRequired,
    onClick: func,
    path: oneOfType([string, shape({ pathname: string })]).isRequired,
    productType: string,
    productsMaskAmount: shape({}),
    dispatch: func.isRequired,
};

AccountCardView.defaultProps = {
    name: "",
    title: "",
    reference: "",
    onClick: () => {},
    productType: null,
    productsMaskAmount: {},
};

const mapStateToProps = (state) => ({
    productsMaskAmount: sessionSelectors.getMaskAmountUpdate(state),
});

export default connect(mapStateToProps)(AccountCardView);
