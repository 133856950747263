import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LOAD_LIST_REQUEST: "administrationGroups/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "administrationGroups/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "administrationGroups/LOAD_LIST_SUCCESS",
    LOAD_MORE_REQUEST: "administrationGroups/LOAD_MORE_REQUEST",
    LOAD_MORE_SUCCESS: "administrationGroups/LOAD_MORE_SUCCESS",
    EXPORT_LIST_REQUEST: "administrationGroups/EXPORT_LIST_REQUEST",
    EXPORT_LIST_FAILURE: "administrationGroups/EXPORT_LIST_FAILURE",
    EXPORT_LIST_SUCCESS: "administrationGroups/EXPORT_LIST_SUCCESS",
    CHANGE_GROUP_STATUS_PREVIEW: "administrationGroups/CHANGE_GROUP_STATUS_PREVIEW",
    CHANGE_GROUP_STATUS_PREVIEW_SUCCESS: "administrationGroups/CHANGE_GROUP_STATUS_PREVIEW_SUCCESS",
    CHANGE_GROUP_STATUS_CONFIRMATION: "administrationGroups/CHANGE_GROUP_STATUS_CONFIRMATION",
    DELETE_SUCCESS: "administrationGroups/DELETE_SUCCESS",
};

export const INITIAL_STATE = {
    list: [],
    groupsExtendedInfo: {},
    groupAction: null,
    groupsToApplyAction: [],
    groupsNamesToApplyAction: [],
    currentPage: 1,
    totalPages: 0,
    hasMoreData: false,
    fetching: true,
    fetchingMoreGroups: false,
    fetchingExport: false,
    idTransaction: null,
    idActivity: null,
    credentialGroups: [],
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_LIST_REQUEST]: (state, { pageNumber }) => ({
        ...state,
        fetching: true,
        currentPage: pageNumber,
    }),
    [types.LOAD_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LOAD_LIST_SUCCESS]: (state, { data }) => ({
        list: data.groups,
        groupsExtendedInfo: data.groupsExtendedInfo,
        currentPage: data.currentPage,
        totalPages: data.totalPages,
        hasMoreData: data.currentPage < data.totalPages,
        fetching: false,
    }),
    [types.LOAD_MORE_REQUEST]: (state) => ({
        ...state,
        fetchingMoreGroups: true,
    }),
    [types.EXPORT_LIST_REQUEST]: (state) => ({
        ...state,
        fetchingExport: true,
    }),
    [types.EXPORT_LIST_FAILURE]: (state) => ({
        ...state,
        fetchingExport: false,
    }),
    [types.EXPORT_LIST_SUCCESS]: (state) => ({
        ...state,
        fetchingExport: false,
    }),
    [types.LOAD_MORE_SUCCESS]: (state, { data }) => ({
        ...state,
        fetchingMoreGroups: false,
        list: state.list ? state.list.concat(data.groups) : data.list,
        groupsExtendedInfo: state.groupsExtendedInfo
            ? { ...state.groupsExtendedInfo, ...data.groupsExtendedInfo }
            : data.groupsExtendedInfo,
        hasMoreData: data.currentPage < data.totalPages,
        currentPage: data.currentPage,
    }),
    [types.CHANGE_GROUP_STATUS_PREVIEW_SUCCESS]: (
        state,
        { credentialGroups, groupList, groupNameList, groupAction, idTransaction, idActivity },
    ) => ({
        ...state,
        credentialGroups,
        groupAction,
        groupsToApplyAction: groupList,
        groupsNamesToApplyAction: groupNameList,
        idTransaction,
        idActivity,
    }),
    [types.DELETE_SUCCESS]: (state) => ({
        ...state,
        list: [],
    }),
});

export const actions = {
    loadListRequest: (filters) => ({
        type: types.LOAD_LIST_REQUEST,
        filters,
    }),
    loadMoreRequest: (filters) => ({
        type: types.LOAD_MORE_REQUEST,
        filters,
    }),
    exportListRequest: (format) => ({
        type: types.EXPORT_LIST_REQUEST,
        format,
    }),
    loadListFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    loadListSuccess: makeActionCreator(types.LOAD_LIST_SUCCESS, "data"),
    changeGroupStatusPreview: (groupList, groupNameList, groupAction, idActivity) => ({
        type: types.CHANGE_GROUP_STATUS_PREVIEW,
        groupList,
        groupNameList,
        groupAction,
        idActivity,
    }),
    changeGroupsStatusConfirmation: (groupsToApplyAction, groupNameList, groupAction, secondFactor, formikBag) => ({
        type: types.CHANGE_GROUP_STATUS_CONFIRMATION,
        groupsToApplyAction,
        groupNameList,
        groupAction,
        secondFactor,
        formikBag,
    }),
};

export const selectors = {
    getGroups: ({ administrationGroups }) => administrationGroups.list,
    getGroupsExtendedInfo: ({ administrationGroups }) => administrationGroups.groupsExtendedInfo,
    getCurrentPage: ({ administrationGroups }) => administrationGroups.currentPage,
    getTotalPages: ({ administrationGroups }) => administrationGroups.totalPages,
    isFetching: ({ administrationGroups }) => administrationGroups.fetching,
    isFetchingExport: ({ administrationGroups }) => administrationGroups.fetchingExport,
    getHasMoreData: ({ administrationGroups }) => administrationGroups.hasMoreData,
    isFetchingMoreGroups: ({ administrationGroups }) => administrationGroups.fetchingMoreGroups,
    getGroupAction: ({ administrationGroups }) => administrationGroups.groupAction,
    getGroupsToApplyAction: ({ administrationGroups }) => administrationGroups.groupsToApplyAction,
    getGroupsNamesToApplyAction: ({ administrationGroups }) => administrationGroups.groupsNamesToApplyAction,
    getIdTransaction: ({ administrationGroups }) => administrationGroups.idTransaction,
    getIdActivity: ({ administrationGroups }) => administrationGroups.idActivity,
    getCredentialGroups: ({ administrationGroups }) => administrationGroups.credentialGroups,
};
