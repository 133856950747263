import * as API from "middleware/api";
import { credentialsToUnderscoreFormat } from "util/form";
import * as configUtils from "util/config";
import * as restAPI from "middleware/apiRESTful";
import { UNTIL, ONCE, TODAY, DAY, UP_TO } from "util/scheduler";

const getMSEndpointByForm = (idForm, type) => {
    if (idForm.includes("transfer")) {
        return `/api/transfers/v1/${idForm}/${type}`;
    }
    return "";
};

export const readForm = (idForm, data) =>
    API.executeWithAccessToken("/core.forms.read", {
        idForm,
        ...data,
    });

export const preview = (idForm, idActivity, idTransaction, data) => {
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        return restAPI.postWithAccessToken(getMSEndpointByForm(idForm, "preview"), {
            idForm,
            idTransaction,
            ...data,
        });
    }
    return API.executeWithAccessToken(`/${idActivity}`, {
        idForm,
        idTransaction,
        ...data,
    });
};

const schedule = (params, idForm, idTransaction) => {
    const { scheduler, ...restOfParams } = params;
    let scheduleParams = null;
    if (params.scheduler.program) {
        const { program } = scheduler;
        let lapseUntilDate;
        if (program.lapse === UNTIL) {
            lapseUntilDate = typeof program.date === "string" ? program.date : program.date.toISOString();
        }
        scheduleParams = {
            programRequest: {
                frequencyUnit: program.frequency,
                frequencyValue: program.frequencyValue,
                frequencyDayOfMonth: program.day,
                frequencyOccurrence: program.occurrence,
                frequencyDaysOfWeek: program.days,
                lapseType: program.lapse,
                lapseUntil: lapseUntilDate,
                lapseUpTo: program.number,
                startsOn: program.startsOn,
            },
        };
    } else if (params.scheduler.selectedOption === ONCE) {
        scheduleParams = {
            programRequest: {
                frequencyUnit: DAY,
                frequencyValue: "1",
                frequencyDayOfMonth: undefined,
                frequencyOccurrence: undefined,
                frequencyDaysOfWeek: undefined,
                lapseType: UP_TO,
                lapseUntil: undefined,
                lapseUpTo: 1,
                startsOn: params.scheduler.valueDate,
            },
        };
    }
    scheduleParams[`${idForm}Request`] = restOfParams;
    const result = restAPI.postWithAccessToken(getMSEndpointByForm(idForm, "schedule"), {
        idForm,
        idTransaction,
        ...scheduleParams,
    });
    return result;
};

export const send = (idForm, idActivity, idTransaction, data, credentials) => {
    let credentialsWithUnderscore;
    if (credentials) {
        credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    }
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        const params = {
            ...data,
            credentials: { ...credentialsWithUnderscore },
        };
        if (params.scheduler && params.scheduler.selectedOption !== TODAY) {
            return schedule(params, idForm, idTransaction);
        }

        return restAPI.postWithAccessToken(getMSEndpointByForm(idForm, ""), {
            idForm,
            idTransaction,
            ...params,
        });
    }
    const params = {
        ...data,
        ...credentialsWithUnderscore,
    };
    return API.executeWithAccessToken(`/${idActivity}`, {
        idForm,
        idTransaction,
        ...params,
    });
};

export const signPreview = (idForm, idActivity, idTransaction) => {
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        return restAPI.postWithAccessToken(getMSEndpointByForm(idForm, "preview"), {
            idForm,
            transactionId: idTransaction,
        });
    }
    return API.executeWithAccessToken(`/${idActivity}`, {
        idForm,
        idTransaction,
    });
};

export const sign = (idForm, idActivity, idTransaction, credentials) => {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        return restAPI.postWithAccessToken(getMSEndpointByForm(idForm, ""), {
            idForm,
            transactionId: idTransaction,
            credentials: { ...credentialsWithUnderscore },
        });
    }
    return API.executeWithAccessToken(`/${idActivity}`, {
        idForm,
        idTransaction,
        ...credentialsWithUnderscore,
    });
};

export const listCredentialsGroups = (idForm, idActivity) =>
    API.executeWithAccessToken("/core.listTransactionCredentialsGroups", {
        idActivityToRead: idActivity,
        idForm,
    });

export const readTransaction = (idTransaction) =>
    API.executeWithAccessToken("/core.readTransaction", {
        idTransactionToRead: idTransaction,
    });

export const readTransactionHistoric = (referenceNumber) =>
    API.executeWithAccessToken("/core.readHistoricTransactions", {
        referenceNumber,
    });

export const saveDraft = (idForm, transactionData, idActivityDraft, idTransactionToSave) =>
    API.executeWithAccessToken("/core.saveDraftTransaction", {
        idForm,
        idActivityDraft,
        idTransactionToSave,
        transactionData,
    });

export const saveTemplate = (idForm, data, templateName, idActivityTemplate) =>
    API.executeWithAccessToken("/core.createTransactionTemplate", {
        idForm,
        transactionData: data,
        idActivityTemplate,
        templateName,
    });

export const cancelTransaction = (idTransactionToCancel, credentials, idForm) => {
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        restAPI.postWithAccessToken(getMSEndpointByForm(idForm, "unSchedule"), {
            idForm,
            transactionId: idTransactionToCancel,
            credentials: { ...credentials },
        });
    }
    return API.executeWithAccessToken("/core.cancelTransaction", {
        idTransactionToCancel,
        ...credentials,
    });
};

export const moveToDraftTransaction = (idTransactionToMove) =>
    API.executeWithAccessToken("/core.moveToDraftTransaction", {
        idTransactionToMove,
    });

export const readTransactionFromBackoffice = (exchangeToken) =>
    API.executeAnonymous("/core.forms.backoffice.read", { _backofficeExchangeToken: exchangeToken });

export const downloadTicket = (idTicket, fileFormat, idForm) =>
    API.executeWithAccessToken("/transactions.downloadTicket", {
        idTicket,
        format: fileFormat,
        idForm,
    });
export const downloadImageTicket = (idTicket, imageTicket) =>
    API.executeWithAccessToken("/transactions.downloadTicket", {
        idTicket,
        imageTicket,
    });
export const pre = (idActivity, data) =>
    API.executeWithAccessToken(`/${idActivity}`, {
        ...data,
    });
export const getBankDescriptionData = (codeBank, codeBankType) =>
    API.executeWithAccessToken("/bank.description.search", {
        codeBank,
        codeBankType,
    });
