import React from "react";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { bool, string } from "prop-types";
import { selectors as unclockUsersSelectors } from "reducers/unlockUser";
import { connect } from "react-redux";
import { compose } from "redux";
import { resizableRoute } from "pages/_components/Resizable";

const ErrorContent = (props) => {
    const { message, title, isDesktop, buttonHref, buttonLabelKey } = props;
    return (
        <>
            <Box className="login-blocked-icon">
                <Image src="images/util/error.gif" />
            </Box>
            <Text size={isDesktop ? "3" : "1"} className="mt-8" bold labelKey={title} />
            <Text className="mt-8" size="6">
                {message}
            </Text>
            <Button
                className="mt-8"
                href={buttonHref}
                bsStyle="primary"
                label={buttonLabelKey}
                btnUppercase={false}
                block
            />
        </>
    );
};

ErrorContent.defaultProps = {
    buttonHref: "/",
    buttonLabelKey: "global.goToHome",
};

ErrorContent.propTypes = {
    title: string.isRequired,
    message: string.isRequired,
    isDesktop: bool.isRequired,
    buttonHref: string,
    buttonLabelKey: string,
};

const mapStateToProps = (state) => ({
    title: unclockUsersSelectors.getErrorTitle(state),
    message: unclockUsersSelectors.getErrorMessage(state),
});

export default compose(connect(mapStateToProps))(resizableRoute(ErrorContent));
