import React, { Component } from "react";
import FormRender from "pages/forms/_components/FormRender";
import { string, bool } from "prop-types";
import FormRenderCustom from "./FormRenderCustom";

class FormTransition extends Component {
    offsets = { edit: 0, preview: 1, view: 2 };

    parallax = null;

    static propTypes = {
        mode: string.isRequired,
        isMobile: bool.isRequired,
        isCustom: bool,
        fromBackoffice: bool,
    };

    static defaultProps = { isCustom: false, fromBackoffice: false };

    componentDidUpdate() {
        const { mode } = this.props;
        if (this.parallax) {
            this.parallax.scrollTo(this.offsets[mode]);
        }
    }

    getRenderFormStep = (mode, actualMode) => {
        const actualOffset = this.offsets[actualMode];
        const renderOffset = this.offsets[mode];
        let render = true;
        if (renderOffset > actualOffset) {
            render = false;
        } else if (actualOffset === 2 && renderOffset < actualOffset) {
            render = false;
        }
        return render;
    };

    renderMobile = (isCustom) => (isCustom && <FormRenderCustom {...this.props} />) || <FormRender {...this.props} />;

    renderDesktop = () => <FormRender {...this.props} />;

    renderDesktopCustom = () => <FormRenderCustom {...this.props} />;

    render() {
        const { isMobile, isCustom, fromBackoffice } = this.props;
        return isMobile && !fromBackoffice
            ? this.renderMobile(isCustom)
            : (isCustom && this.renderDesktopCustom()) || this.renderDesktop();
    }
}

export default FormTransition;
