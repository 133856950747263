import React, { useEffect } from "react";
import withRouter from "react-router-dom/withRouter";
import { compose } from "redux";
import { connect } from "react-redux";
import { bool, func, shape, string } from "prop-types";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as templateSelectors } from "reducers/template";
import { Field } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import * as i18nUtils from "util/i18n";
import { parse } from "query-string";
import * as Yup from "yup";
import { validationText, validationAmount } from "util/validationSchemaUtil";
import { numberFormat } from "util/number";
import FormTransition from "../_components/FormTransition";

const ID_FORM = "creditCard.cashAdvance";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

const CashAdvanceForm = (props) => {
    /**
     * Variables
     */

    const { mode, dispatch, location } = props;
    const { decimalSeparator, thousandSeparator } = numberFormat();

    const queryParamCreditCard = parse(location.search).creditCard;

    const validationSchema = () =>
        Yup.object().shape({
            creditCard: validationText(),
            amount: validationAmount(),
            amountFees: validationText(),
        });

    useEffect(() => {
        if (mode === "edit" || mode === "view") {
            const formData = { debitAccount: null };

            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
        }
        // eslint-disable-next-line
    }, [dispatch, location]);

    /**
     * Util functions
     */

    const generateAccountComponent = (debitAccountList) => ({
        options: debitAccountList
            ? debitAccountList.map((acc) => ({
                  ...acc,
                  balance: { currency: acc.currency, quantity: acc.balance },
                  id: acc.idProduct,
                  disabled: acc.isDisabled,
              }))
            : [],
    });

    const renderFields = () => {
        const { currentLang, preDataForm, transaction, fromBackoffice } = props;
        const idTransaction = transaction?.idTransaction;

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        const preData = preDataForm || {
            debitAccountList: [],
            creditCards: [],
            currencyList: [],
        };

        const dataAmount = {
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options: preData.currencyList.map((c) => ({
                id: c.backendId,
                label: c.code,
            })),
        };
        const defaultCreditCard = queryParamCreditCard || preData.creditCards[0]?.idProduct || "";

        const feesTypeOptions = preData.feesTypeArray?.map((item) => ({ ...item }));

        const accountOptions = generateAccountComponent(preData.debitAccountList);
        const creditCardOptions = {
            options: preData.creditCards?.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.totalAmount },
                id: acc.idProduct,
            })),
        };

        return (
            <React.Fragment>
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.ProductselectorCustom}
                    data={creditCardOptions}
                    key="creditCard"
                    name="creditCard"
                    renderAs="combo"
                    value={defaultCreditCard}
                    idField="creditCard"
                    /**
                     * Si se pide que no se pueda cambiar al recibir la cuenta por parametro
                     */
                    // mode={queryParamCreditCard && values?.amount?.quantity?"preview":"edit"}
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.ProductselectorCustom}
                    data={accountOptions}
                    key="creditAccount"
                    name="creditAccount"
                    renderAs="combo"
                    value={preData.debitAccountList[0]?.idProduct || ""}
                    idField="creditAccount"
                />
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Amount}
                    data={dataAmount}
                    key="amount"
                    name="amount"
                    value={
                        preData.currencyList && preData.currencyList[0]
                            ? { ...preData.currencyList[0], currency: preData.currencyList[0].backendId }
                            : {}
                    }
                    idField="amount"
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    key="amountFees"
                    name="amountFees"
                    idField="amountFees"
                    labelIdField="react-select-amountFees-input"
                    optionList={feesTypeOptions}
                    renderAs="combo"
                    customPlaceholder={i18nUtils.get(`${ID_FORM}.amountFees.placeholder`)}
                />
            </React.Fragment>
        );
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
        // eslint-disable-next-line react/prop-types
    } = props.preData || {};

    const formProps = {
        title: "forms.creditCard.cashAdvance.formName",
        metadata: {
            draftsEnabled: true,
            templatesEnabled: true,
            schedulable: true,
            programable: true,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },

        renderFields,
        idActivity: ID_ACTIVITY,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validationSchema,
    };
    return <FormTransition {...props} {...formProps} />;
};

CashAdvanceForm.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
    isDesktop: bool,
    postData: shape({}),
};
CashAdvanceForm.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: null,
    previewData: null,
    transaction: null,
    location: {},
    fromTransaction: false,
    isDesktop: false,
    postData: {},
};
const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    postData: formSelectors.getData(state),
});
export default compose(connect(mapStateToProps), withRouter)(CashAdvanceForm);
