import React, { useState } from "react";
import Button from "pages/_components/Button";
import Box from "pages/_components/Box";
import { format as formatDate } from "date-fns";
import Select from "pages/forms/_components/_fields/Select";
import InfoMessage from "pages/_components/InfoMessage";
import Dropdown from "pages/_components/Dropdown";
import * as i18n from "util/i18n";
import { actions as accountsActions, selectors } from "reducers/accounts";
import { bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import classNames from "classnames";

const MovementsCut = ({
    idAccount,
    openingDate,
    getMovementsCut,
    downloadMovementsCut,
    movementsCut,
    fetching,
    downloading,
    isDesktop,
}) => {
    const [selected, setSelected] = useState("");

    const generateOptions = () => {
        const result = [];

        const openingAccountDate = new Date(openingDate);
        openingAccountDate.setDate(1);

        const endDate = new Date();
        endDate.setFullYear(endDate.getFullYear() - 1);
        const startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

        while (endDate <= startDate && openingAccountDate <= startDate) {
            const op = formatDate(startDate, "MM/YYYY");

            result.push({
                label: op,
                value: op,
            });
            startDate.setMonth(startDate.getMonth() - 1);
        }

        return result;
    };

    const handleClickGetMovementCut = () => {
        getMovementsCut(idAccount, selected);
    };

    const handleClickDownload = (format) => {
        const { dateFrom, dateTo } = movementsCut;
        downloadMovementsCut(idAccount, selected, dateFrom, dateTo, format);
    };

    const renderEmptyResults = () => (
        <Box className="m-7">
            <Image src="images/empty.svg" />
            <Box className="text-center mt-5 mb-4">
                <Text labelKey="accounts.movementsCut.empty.title" bold size="3" />
            </Box>
            <Box className="text-center">
                <Text labelKey="accounts.movementsCut.empty.text" />
            </Box>
        </Box>
    );

    const documents = [
        {
            label: "global.pdfFile",
            onClick: () => handleClickDownload("pdf"),
        },
        {
            label: "global.xlsFile",
            onClick: () => handleClickDownload("xls"),
        },
        {
            label: "global.txtFile",
            onClick: () => handleClickDownload("txt"),
        },
    ];

    const renderMovementsCut = () => {
        const { dateFrom, dateTo } = movementsCut;
        return (
            <>
                <Box display="flex" className="py-5" fullWidth>
                    <b>1</b>&nbsp;Resultado
                </Box>

                <Box className="children-bg-color">
                    <div />
                    <Box display="flex" className={isDesktop ? "p-5" : "p-4"} borderRadius="md" fullWidth>
                        <Box display="flex" fullWidth alignY="center">
                            <Box>
                                <Text labelKey="accounts.movementCut" size="6" bold />
                                <Box className="mt-3">
                                    <Text
                                        labelKey="accounts.movementsCut.date"
                                        size="6"
                                        color="text-grey-color"
                                        dateFrom={dateFrom}
                                        dateTo={dateTo}
                                        light
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" fullWidth alignX="end">
                            <Dropdown
                                image="images/util/arrow-down.svg"
                                label="global.download"
                                className={classNames({ "display-flex content-center": !isDesktop })}
                                buttonClass={`btn-download background-white ${!isDesktop && "p-0"}`}
                                fetching={downloading}
                                imageRight
                                pullRight>
                                {documents.map((item) => (
                                    <Button
                                        key={item.label}
                                        label={item.label}
                                        onClick={() => {
                                            item.onClick();
                                        }}
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                    />
                                ))}
                            </Dropdown>
                        </Box>
                    </Box>
                </Box>
            </>
        );
    };

    return (
        <Box className="mt-3">
            <Box display={isDesktop && "flex"} className="py-5" gap="7" fullWidth>
                <Box display="flex" alignY="center" fullWidth>
                    <InfoMessage labelKey="accounts.movementsCut.info" />
                </Box>
                <Box fullWidth className={classNames({ "pl-7": isDesktop, "mt-6": !isDesktop })}>
                    <div className="input-group">
                        <Select
                            clearable={false}
                            searchable={false}
                            onChange={(select) => {
                                setSelected(select?.value);
                            }}
                            options={generateOptions()}
                            value={selected}
                            containerClassName="select-date-picker"
                            placeholder={i18n.get("accounts.movementsCut.select.placeholder")}
                        />
                    </div>
                </Box>
                <Box>
                    <Button
                        bsStyle="primary"
                        label="global.search"
                        btnUppercase={false}
                        className={classNames({ "px-11": isDesktop, "full-width mt-6": !isDesktop })}
                        onClick={handleClickGetMovementCut}
                        loading={fetching}
                        disabled={!selected}
                    />
                </Box>
            </Box>
            {movementsCut && movementsCut.hasMovements && renderMovementsCut()}
            {movementsCut && !movementsCut.hasMovements && renderEmptyResults()}
        </Box>
    );
};

MovementsCut.propTypes = {
    idAccount: string.isRequired,
    openingDate: string,
    getMovementsCut: func.isRequired,
    downloadMovementsCut: func.isRequired,
    fetching: bool,
    downloading: bool,
    movementsCut: shape({}),
    isDesktop: bool,
};

MovementsCut.defaultProps = {
    openingDate: null,
    fetching: false,
    downloading: false,
    movementsCut: undefined,
    isDesktop: true,
};

const mapStateToProps = (state) => ({
    fetching: selectors.getIsFetchingMovementsCut(state),
    downloading: selectors.getIsDownloadingMovementsCut(state),
    movementsCut: selectors.getMovementsCut(state),
});

const mapDispatchToProps = (dispatch) => ({
    getMovementsCut: (idAccount, date) => dispatch(accountsActions.getMovementCut(idAccount, date)),
    downloadMovementsCut: (idAccount, date, dateFrom, dateTo, format) =>
        dispatch(accountsActions.downloadMovementCut(idAccount, date, dateFrom, dateTo, format)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MovementsCut);
