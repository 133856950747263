import React, { Component } from "react";
import { oneOfType, node, string, bool, func } from "prop-types";
import classNames from "classnames";

import Image from "pages/_components/Image";
import Box from "../Box";
import Text from "../Text";

class RadioBox extends Component {
    static propTypes = {
        checked: bool.isRequired,
        label: oneOfType([string, node]).isRequired,
        value: string.isRequired,
        className: string,
        mode: string,
        inLineControl: bool,
        name: string.isRequired,
        onChange: func.isRequired,
        id: string.isRequired,
        radioLabelClassName: string,
        disabled: bool,
        icon: string,
        description: string,
    };

    static defaultProps = {
        inLineControl: false,
        className: "",
        mode: "edit",
        radioLabelClassName: null,
        disabled: false,
        icon: undefined,
        description: "",
    };

    render() {
        const {
            id,
            inLineControl,
            value,
            name,
            checked,
            onChange,
            className,
            label,
            mode,
            radioLabelClassName,
            disabled,
            icon,
            description
        } = this.props;

        if (mode !== "edit" && !checked) {
            return null;
        }

        if (mode !== "edit") {
            return <div>{label}</div>;
        }

        return (
            <Box borderRadius="lg" background={checked? "background-primary":"background-secondary"} className="p-7 mt-7">
                    <Box display="flex" column>
                        <Box display="flex">
                                <div className={classNames(
                                        "c-control c-control--has-icon c-control--radio display-flex gap-3 align-items-center position-relative",
                                        className,
                                        {
                                            "c-control-block": !inLineControl,
                                        },
                                    )}>
                                    <input
                                        className="c-control-input"
                                        checked={checked}
                                        id={id}
                                        type="radio"
                                        value={value}
                                        onChange={onChange}
                                        name={name}
                                        readOnly
                                        disabled={disabled}
                                    />
                                    <label htmlFor={id} className={classNames("c-control-label m-0", radioLabelClassName)}>
                                        <div className="c-control-icons">
                                            {icon ? (
                                                <div>
                                                    <Image src={`images/${icon}`} />
                                                </div>
                                            ) : (
                                                <div className="c-control-mark">
                                                    <Image src="images/radioButtonMark.svg" className="svg-icon svg-caret" />
                                                </div>
                                            )}
                                        </div>
                                        {label && (
                                            <Box className="form-group-text">
                                                <Text defaultValue={label} />
                                            </Box>
                                        )}
                                    </label>
                                </div>
                            </Box>
                            <Box className="form-group-text">
                                <Text defaultValue={description} />
                            </Box>
                    </Box>
            </Box>
        );
    }
}

export default RadioBox;
