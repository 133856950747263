import * as configUtils from "util/config";
import * as i18n from "util/i18n";

export const getAllQuestions = () => {
    const allQuestionsId = configUtils.getArray("security.questions.all");

    return allQuestionsId.map((questionId) => ({
        label: configUtils.get(`security.questions.${questionId}`),
        value: questionId,
    }));
};

export const validateSelectedQuestions = (questions, maxQuestions, formikBag) => {
    const qRequired = i18n.get("securityQuestions.validate.question.required");
    const aRequired = i18n.get("securityQuestions.validate.answer.required");
    const invalid = i18n.get("securityQuestions.validate.answer.invalid");
    const duplicated = i18n.get("securityQuestions.validate.answer.duplicated.invalid");

    let hasErrors = false;
    let i = 0;
    const answers = [];
    while (++i <= maxQuestions) {
        if (!questions[i]) {
            formikBag.setFieldError(`${i}.question`, qRequired);
            formikBag.setFieldError(`${i}.answer`, aRequired);
            hasErrors = true;
            continue;
        }
        if (questions[i].question === undefined) {
            formikBag.setFieldError(`${i}.question`, qRequired);
            hasErrors = true;
        }
        if (questions[i].answer === undefined) {
            formikBag.setFieldError(`${i}.answer`, aRequired);
            hasErrors = true;
        } else if (questions[i].answer.length < 2) {
            formikBag.setFieldError(`${i}.answer`, invalid);
            hasErrors = true;
        } else if (answers.includes(questions[i].answer)) {
            formikBag.setFieldError(`${i}.answer`, duplicated);
            hasErrors = true;
        } else {
            answers.push(questions[i].answer);
        }
    }
    return hasErrors;
};
