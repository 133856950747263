import React from "react";
import { func, number, shape, string } from "prop-types";
import Button from "pages/_components/Button";
import Col from "react-bootstrap/lib/Col";
import Row from "pages/_components/Row";
import RadioButton from "pages/_components/fields/Radio";
import { routerActions } from "react-router-redux/actions";
import DetailBox from "pages/_components/detailBox/DetailBox";
import I18n from "pages/_components/I18n";
import { actions as fileActions } from "reducers/files";

import FormattedAmount from "pages/_components/FormattedAmount";

const ShowInfoFile = ({
    uploadType,
    mode,
    onClickNewItem,
    dispatch,
    idTransactionTicket,
    optionCurrencyList,
    currencyCustom,
    handleCurrency,
    amount,
    lines,
    infoErrorFile,
}) => {
    const handleDownloadErrorsClick = () => {
        const { idRelatedFile, nameRelatedFile } = infoErrorFile;
        if (idRelatedFile && nameRelatedFile) {
            dispatch(fileActions.downloadFileRequest(idRelatedFile, nameRelatedFile));
        }
    };

    const handleClickDetail = () => {
        dispatch(routerActions.push(`/transaction/${idTransactionTicket}/processDetail`));
    };

    const renderCurrencyOptionsEdit = () => {
        if (!optionCurrencyList) {
            return <div />;
        }

        const handleCurrencyCustom = (event) => {
            if (handleCurrency) {
                handleCurrency(event);
            }
        };

        return (
            <>
                <Row className="form-content justify-content-center">
                    <Col sm={12 - optionCurrencyList.length} />
                    {optionCurrencyList.map((option) => (
                        <Col sm={1}>
                            <RadioButton
                                id={option.currency}
                                checked={currencyCustom ? option.currency === currencyCustom : false}
                                inLineControl={false}
                                key={option.currency}
                                label={option.label}
                                name={option.currency}
                                onChange={handleCurrencyCustom}
                                value={option.currency}
                            />
                        </Col>
                    ))}
                </Row>
            </>
        );
    };

    return (
        <>
            <DetailBox>
                {mode === "edit" && uploadType !== "file" && (
                    <div className="detailBox-row">
                        <div className="detailBox-data" />
                        <div className="text-right">{renderCurrencyOptionsEdit()}</div>
                    </div>
                )}

                <DetailBox.Data label="forms.inputFile.massivePayments.lines">
                    <div>
                        {lines} <I18n id="forms.inputFile.massivePayments.total.lines" />
                    </div>
                    {infoErrorFile && infoErrorFile.invalidLines > 0 && (
                        <>
                            <div>
                                <I18n id="forms.inputFile.massivePayments.invalid.lines" />
                                {` ${infoErrorFile.invalidLines} / ${infoErrorFile.validLines} `}
                                <I18n id="forms.inputFile.massivePayments.valid.lines" />
                            </div>
                            <Button
                                onClick={() => {
                                    handleDownloadErrorsClick();
                                }}
                                label="forms.inputFile.massivePayments.download.errors"
                                bsStyle="link"
                            />
                        </>
                    )}
                </DetailBox.Data>
                <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                    <FormattedAmount currency={currencyCustom} quantity={amount || 0} />
                </DetailBox.Data>

                {mode === "edit" && onClickNewItem && (
                    <div className="detailBox-row">
                        <Button
                            onClick={() => {
                                onClickNewItem();
                            }}
                            label="forms.inputFile.massivePayments.addPayments"
                            bsStyle="link"
                            block
                        />
                    </div>
                )}
                {mode === "view" && (
                    <div className="detailBox-row">
                        <Button
                            onClick={() => {
                                handleClickDetail();
                            }}
                            label="forms.inputFile.massivePayments.transaction.detail"
                            bsStyle="link"
                            block
                        />
                    </div>
                )}
            </DetailBox>
            <br />
        </>
    );
};

ShowInfoFile.propTypes = {
    onClickNewItem: func,
    amount: shape({}),
    mode: string,
    optionCurrencyList: shape([]),
    handleCurrency: func,
    uploadType: string,
    currencyCustom: string,
    dispatch: func,
    idTransactionTicket: string,
    lines: number,
    infoErrorFile: shape({}),
};
ShowInfoFile.defaultProps = {
    onClickNewItem: () => {},
    amount: {},
    mode: "",
    optionCurrencyList: [],
    handleCurrency: () => {},
    uploadType: "",
    currencyCustom: "",
    dispatch: () => {},
    idTransactionTicket: "",
    lines: 0,
    infoErrorFile: {},
};
export default ShowInfoFile;
