import * as i18nUtils from "util/i18n";
import moment from "moment";
import * as dateUtils from "util/date";

export const TODAY = "TODAY";
export const ONCE = "FUTURE";
export const DAY = "DAY";
export const WEEK = "WEEK";
export const MONTH = "MONTH";
export const YEAR = "YEAR";
export const CUSTOM = "CUSTOM";
export const NEVER = "UNLIMITED";
export const UP_TO = "UP_TO";
export const UNTIL = "UNTIL";

const FIRST = "first";
const SECOND = "second";
const THRID = "third";
const FOURTH = "fourth";
const LAST = "last";

export const listRecurrencies = () => [
    {
        value: ONCE,
        label: i18nUtils.get(`scheduler.${ONCE.toLowerCase()}`),
    },
    {
        value: DAY,
        label: i18nUtils.get(`scheduler.${DAY.toLowerCase()}`),
    },
    {
        value: WEEK,
        label: i18nUtils.get(`scheduler.${WEEK.toLowerCase()}`),
    },
    {
        value: MONTH,
        label: i18nUtils.get(`scheduler.${MONTH.toLowerCase()}`),
    },
    {
        value: YEAR,
        label: i18nUtils.get(`scheduler.${YEAR.toLowerCase()}`),
    },
    /* {
        value: CUSTOM,
        label: i18nUtils.get(`scheduler.${CUSTOM.toLowerCase()}`),
    }, */
];

export const listEvery = () => [
    {
        value: DAY,
        label: i18nUtils.get(`scheduler.${DAY.toLowerCase()}s`),
    },
    {
        value: WEEK,
        label: i18nUtils.get(`scheduler.${WEEK.toLowerCase()}s`),
    },
    {
        value: MONTH,
        label: i18nUtils.get(`scheduler.${MONTH.toLowerCase()}s`),
    },
    {
        value: YEAR,
        label: i18nUtils.get(`scheduler.${YEAR.toLowerCase()}s`),
    },
];

export const getOccurrenceWithWeekOfMonth = (weekOfMonth) => {
    switch (weekOfMonth) {
        case 1:
            return FIRST;
        case 2:
            return SECOND;
        case 3:
            return THRID;
        case 4:
            return FOURTH;
        default:
            return LAST;
    }
};

export const getMessagePay = (value) => {
    if (value) {
        if (value.selectedOption !== null && value.selectedOption !== undefined) {
            const formatDate = {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
            };

            let endDate = moment(new Date()).add(1, "days");

            if (value.selectedOption === ONCE) {
                endDate = value.valueDate._d;
            } else if (value.selectedOption !== TODAY && value.selectedOption !== ONCE) {
                endDate = value.program.date._d === undefined ? value.program.date : value.program.date._d;

                if (typeof value.program.date === "string") {
                    if (value.program.date.split(".")[0] !== null && value.program.date.split(".")[0] !== undefined) {
                        endDate = new Date(value.program.date.split(".")[0]);
                    }
                }
            }

            if (value.selectedOption !== TODAY) {
                endDate = dateUtils.DAY_MONTH_FORMAT(i18nUtils.getLang(), endDate, formatDate);

                const message = i18nUtils.get(`scheduler.messagePay.${value?.selectedOption.toLowerCase()}`, null, {
                    END_DATE: endDate,
                });

                return message;
            }
        }
    }

    return null;
};

export const getScheduleMessageStartDate = (value, titleKind) => {
    if (value) {
        const formatDate = {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
        };

        let startDate = moment(new Date()).add(1, "days");

        if (value.selectedOption === ONCE) {
            startDate = value.valueDate._d;
        } else if (value.selectedOption !== TODAY && value.selectedOption !== ONCE) {
            startDate = value.program.startsOn;
            if (typeof value.program.startsOn === "string") {
                if (
                    value.program.startsOn.split(".")[0] !== null &&
                    value.program.startsOn.split(".")[0] !== undefined
                ) {
                    startDate = new Date(value.program.startsOn.split(".")[0]);
                }
            }
        }

        if (value.selectedOption !== TODAY) {
            startDate = dateUtils.DAY_MONTH_FORMAT(i18nUtils.getLang(), startDate, formatDate);
            const message = i18nUtils.get(`scheduler.messageDate.${titleKind}`, null, {
                START_DATE: startDate,
            });
            return message;
        }
    }

    return null;
};
