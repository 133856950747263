import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Notification from "pages/_components/Notification";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { bool, func, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import Row from "pages/_components/Row";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import classNames from "classnames";
import SecondFactor from "pages/secondFactor/SecondFactor";

const FORM_ID = "securityQuestions.register";

const RegisterQuestionsConfirm = ({ isDesktop, action, dispatch, handleSubmit }) => {
    const [canSubmit, setCanSubmit] = useState(false);

    const handleBack = () => {
        dispatch(routerActions.push("/securityQuestions"));
    };

    const renderHeader = () => (
        <>
            <Notification scopeToShow="registerQuestions" />
            <Head
                onBack={handleBack}
                title={`securityQuestions.action.${action}.title`}
                textBack="securityQuestions.returnToList"
            />
        </>
    );

    const validationSchema = Yup.object().shape({
        secondFactor: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
    });

    const renderForm = (propsForm) => (
        <Form className="display-flex flex-column justify-content-between full-height">
            <Box
                background="white"
                borderRadius="lg"
                className={classNames("mt-3 ", {
                    "p-7 box-shadow-small": isDesktop,
                    "pt-6": !isDesktop,
                })}>
                <Row>
                    <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2}>
                        <SecondFactor
                            onChangeToken={(tokenCode) => {
                                if (propsForm?.setFieldValue) {
                                    propsForm.setFieldValue("secondFactor", tokenCode);
                                    propsForm.submitForm();
                                }
                            }}
                            onValidSubmit={(validData) => {
                                setCanSubmit(validData.submit);
                            }}
                            idActivity={
                                action === "register"
                                    ? "security.register.questions.send"
                                    : "security.update.questions.send"
                            }
                            isSubmitting={propsForm.isSubmitting}
                        />
                    </Col>
                </Row>
            </Box>
            <Row
                gapX="7"
                className={classNames("mt-7 mb-7", {
                    "d-flex flex-column-reverse": !isDesktop,
                })}>
                <Col xs={3} xsOffset={3}>
                    <Button
                        type="button"
                        bsStyle="outline"
                        label="global.cancel"
                        btnUppercase={false}
                        block
                        onClick={handleBack}
                    />
                </Col>
                <Col xs={3}>
                    <Button
                        type="submit"
                        bsStyle="primary"
                        label="global.continue"
                        btnUppercase={false}
                        disabled={!canSubmit}
                        loading={propsForm.isSubmitting}
                        block
                    />
                </Col>
            </Row>
        </Form>
    );
    return (
        <>
            {renderHeader()}
            <MainContainer
                className={classNames("main-container", {
                    "background-white": !isDesktop,
                })}>
                <Formik
                    initialValues={{ secondFactor: "" }}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
                    validationSchema={validationSchema}>
                    {renderForm}
                </Formik>
            </MainContainer>
        </>
    );
};

RegisterQuestionsConfirm.propTypes = {
    dispatch: func.isRequired,
    handleSubmit: func.isRequired,
    action: string,
    isDesktop: bool.isRequired,
};

RegisterQuestionsConfirm.defaultProps = {
    action: "register",
};

export default connect()(RegisterQuestionsConfirm);
