import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Info from "pages/_components/Info";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text";
import Selector from "pages/_components/fields/formik/Selector";
import { arrayOf, bool, func, objectOf, shape, string } from "prop-types";
import React, { Component } from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/lib/Col";
import { signatureLevelOptions } from "util/administration";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "administration.advanced.signature.modify";

class Signature extends Component {
    static propTypes = {
        user: shape({ firstName: string, lastName: string }).isRequired,
        routerActions: shape({
            goBack: func,
        }),
        idActivity: string,
        idTransaction: string,
        schemeName: string.isRequired,
        signatureLevels: arrayOf(shape({ id: string, label: string })),
        signatureLevel: string,
        actions: objectOf(func).isRequired,
        formActions: objectOf(func).isRequired,
        hasSignatureEnabled: bool,
        match: shape({
            params: shape({ id: string.isRequired }),
        }).isRequired,
        fetching: bool,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        userInfo: shape({ hasSignature: bool.isRequired }).isRequired,
        userEnvStatus: string,
    };

    static defaultProps = {
        routerActions: null,
        idActivity: null,
        idTransaction: null,
        fetching: false,
        signatureLevel: "",
        signatureLevels: [],
        hasSignatureEnabled: false,
        userEnvStatus: "",
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    renderFormContent = () => {
        const { signatureLevels, signatureLevel, idTransaction, schemeName } = this.props;

        if (schemeName === "advanced") {
            if (!idTransaction) {
                return (
                    <Field
                        component={Selector}
                        options={signatureLevels}
                        idForm={FORM_ID}
                        name="signatureLevel"
                        value={signatureLevel || "N"}
                        renderAs="radio"
                        inLineControl
                    />
                );
            }
            if (signatureLevel) {
                return <I18n id="administration.signature.ticket.configure" SIGNATURE_LEVEL={signatureLevel} />;
            }
            return <I18n id="administration.signature.ticket.change.true" />;
        }
        return null;
    };

    renderForm = () => (
        <Container className="container--layout items-center" gridClassName="form-content container--flex-middle">
            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                {this.renderFormContent()}
            </Col>
        </Container>
    );

    signaturesConfiguration = () => {
        const { signatureLevels } = this.props;

        return (
            <div className="form-group form-group--select">
                <Box className="mb-4">
                    <Text size="5" bold labelKey="administration.users.invite.signatureLevel.label" />
                </Box>
                <Box className="mt-4 mb-3">
                    <Text size="7" color="text" labelKey="administration.signatures.create.info" />
                </Box>
                <Field
                    component={Selector}
                    options={signatureLevelOptions(signatureLevels, i18n)}
                    idForm={FORM_ID}
                    name="signatureLevel"
                    renderAs="radio"
                    column
                    gap="1"
                    cControlClassName="mb-3"
                />
            </div>
        );
    };

    formatSubmitSignatureValue = (signatureLevel) => {
        const { hasSignatureEnabled, schemeName } = this.props;
        if (schemeName === "medium") {
            return hasSignatureEnabled ? null : "A";
        }
        return signatureLevel === "N" ? null : signatureLevel;
    };

    handleSubmit = (data, formikBag) => {
        const { routerActions, actions, user, schemeName } = this.props;
        actions.updateSignaturePreview(
            {
                signatureLevel: data.signatureLevel,
            },
            formikBag,
        );
        // const { dispatch } = this.props;
        // dispatch(push("/administration/groups"));
        routerActions.replace(`/administration/${schemeName}/signature/${user.idUser}/confirm`);
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    render() {
        const { fetching, signatureLevel, user, userEnvStatus } = this.props;

        return (
            <>
                <Head
                    title="administration.signature.configure.text"
                    onBack={this.handleBack}
                    textBack="administration.users.returnToDetail"
                />

                <MainContainer showLoader={fetching && !Object.keys(user).length}>
                    <Formik
                        initialValues={{ signatureLevel: signatureLevel || "N" }}
                        validationSchema={() => {
                            const requiredMessage = i18n.get("signature.empty");
                            return Yup.object().shape({
                                signatureLevel: Yup.string().required(requiredMessage),
                            });
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={this.handleSubmit}>
                        {(propsForm, isSubmitting) => (
                            <Form>
                                <Box background="white" borderRadius="lg" className="p-7 mt-5 box-shadow-small">
                                    <Box borderRadius="lg" className="p-7 box-shadow-small">
                                        <Box display="flex" className="mt-7" gapX="3">
                                            <Info
                                                className="info-user-list"
                                                labelKey="administration.users.list.header.name"
                                                text={user.fullName}
                                            />
                                            <Info
                                                className="info-user-list"
                                                labelKey="administration.users.list.header.identification"
                                                text={`${user.documentType} ${user.documentNumber}`}
                                            />
                                            <Info
                                                className="info-user-list"
                                                labelKey="administration.users.list.header.signature"
                                                text={
                                                    signatureLevel
                                                        ? i18n.get(
                                                              `administration.signatures.create.signatureLevelsCounts.${signatureLevel}.label`,
                                                          )
                                                        : i18n.get("global.no")
                                                }
                                            />
                                            <Box>
                                                <Info
                                                    className={`info-user-list info-user-${userEnvStatus}`}
                                                    text={i18n.get(`global.${userEnvStatus}`)}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box className="border-radius-lg p-7 mt-5 box-shadow-small background-white">
                                            <Row>
                                                <Col xs="6" xsOffset="3">
                                                    <Box className="mb-7">{this.signaturesConfiguration()}</Box>
                                                </Col>
                                            </Row>
                                        </Box>
                                    </Box>
                                </Box>
                                <Row gapY="7" className="mt-7">
                                    <Col lg={6} lgOffset={3}>
                                        <Row className="justify-content-center">
                                            <Col xs={6}>
                                                <Button
                                                    bsStyle="outline"
                                                    label="global.cancel"
                                                    onClick={this.handleBack}
                                                    btnUppercase={false}
                                                    block
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <Button
                                                    type="submit"
                                                    label="global.continue"
                                                    bsStyle="primary"
                                                    loading={propsForm?.isSubmitting || false || fetching}
                                                    btnUppercase={false}
                                                    block
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </MainContainer>
            </>
        );
    }
}

export default Signature;
