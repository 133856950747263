import React from "react";
import Notification from "pages/_components/Notification";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { bool, func, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import Button from "pages/_components/Button";
import { selectors as sessionSelector } from "reducers/session";
import { simpleMaskEmail } from "util/format";
import classNames from "classnames";
import { push } from "react-router-redux";
import { isMobile } from "react-device-detect";

const RegisterQuestionsSuccess = ({ action, email, isDesktop, dispatch }) => {
    const handleBack = () => {
        dispatch(routerActions.push("/securityQuestions"));
    };

    const goToHome = () => {
        dispatch(push("/desktop"));
    };

    const renderHeader = () => (
        <>
            <Notification scopeToShow="registerQuestions" />
            <Head onBack={handleBack} title={`securityQuestions.action.${action}.title`} />
        </>
    );

    return (
        <>
            {renderHeader()}
            <MainContainer
                className={classNames("main-container", {
                    "background-white": !isDesktop,
                })}>
                <Box className={isMobile && "display-flex flex-column justify-content-between full-height"}>
                    <Box
                        background="white"
                        borderRadius="lg"
                        className={classNames("mt-3 ", {
                            "p-7 box-shadow-small": isDesktop,
                            "pt-9": !isDesktop,
                        })}>
                        <Box display="flex" alignX="center" className="mb-8">
                            <Box className="box-image-success">
                                <Image src="images/util/success.gif" />
                            </Box>
                        </Box>
                        <Box className="text-center mb-7">
                            <Text
                                size={isDesktop ? "3" : "2"}
                                labelKey={`securityQuestions.action.${action}.success.title`}
                                bold
                                className={classNames({
                                    "line-height-125": isDesktop,
                                    "line-height-15": !isDesktop,
                                })}
                            />
                        </Box>
                        <Box className="text-center mb-7">
                            <Text
                                size={isDesktop ? "7" : "5"}
                                labelKey="securityQuestions.action.success.info"
                                light
                                className={classNames({
                                    "line-height-125": isDesktop,
                                    "line-height-15": !isDesktop,
                                })}
                            />
                            <Box className="mt-2">
                                <Text size={isDesktop ? "6" : "5"} bold>
                                    {simpleMaskEmail(email)}
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                    <Row
                        className={classNames("mt-7 mb-7 row-template-center", {
                            "d-flex": !isDesktop,
                        })}>
                        <Col xs={3} xsOffset={4}>
                            <Button
                                type="button"
                                bsStyle="primary"
                                label="global.goToHome"
                                btnUppercase={false}
                                onClick={goToHome}
                                block
                            />
                        </Col>
                    </Row>
                </Box>
            </MainContainer>
        </>
    );
};

RegisterQuestionsSuccess.propTypes = {
    dispatch: func.isRequired,
    action: string.isRequired,
    email: string.isRequired,
    isDesktop: bool.isRequired,
};

const mapStateToProps = (state) => ({
    email: sessionSelector.getEmail(state),
});

export default connect(mapStateToProps)(RegisterQuestionsSuccess);
