import React, { Component } from "react";
import { string } from "prop-types";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import Box from "../Box";

class ItemName extends Component {
    static propTypes = {
        name: string.isRequired,
        icon: string,
    };

    static defaultProps = {
        icon: null,
    };

    render() {
        const { name, icon } = this.props;

        return (
            <Box className="data-name">
                {icon && <Image src={`images/${icon}.svg`} className="svg-icon" />}
                <Text ellipsis>{name}</Text>
            </Box>
        );
    }
}

export default ItemName;
