/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState } from "react";
import { func, arrayOf, number, string, bool, oneOfType, shape } from "prop-types";

import { get as getMessage } from "util/i18n";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Scroll from "pages/_components/Scroll";
import I18n from "pages/_components/I18n";
import List from "pages/_components/List";
import { noop } from "pages/_components/utils";
import ListIndex from "./ListIndex";
import SelectListItem from "./SelectListItem";

function SelectList({
    listKey,
    title,
    items,
    itemLabel,
    selected,
    selectedFn,
    readonly,
    onItemChecked,
    onSelectAll,
    allSelected,
    onItemClick: handleItemClick,
    filter,
    showIndexes,
    showSelectAll,
    showTitle,
    sort,
    idSelector,
    selectedTitle,
    scrollable,
    itemsToShow,
    fetchMore,
    ...props
}) {
    const filteredItems = items ? items.filter(filter) : [];

    const [showMore, setShowMore] = useState(itemsToShow || 0);

    const onShowMoreItemsHandler = () => {
        setShowMore((prevState) => prevState + itemsToShow);
        if (fetchMore !== undefined) {
            fetchMore();
        }
    };
    const onShowLessItemsHandler = () => setShowMore(itemsToShow);

    const renderList = () => (
        <List className="list-group" {...props}>
            {filteredItems
                .map((item) => ({ ...item, label: itemLabel(item) }))
                .map((item, index, array) => {
                    let itemKey = `${listKey}-item-${index}`;
                    if (item.idItem) {
                        itemKey += `-${item.idItem}`;
                    }

                    return (
                        <Fragment key={`${listKey}-${index}`}>
                            {showIndexes && filteredItems.length > 10 && <ListIndex index={index} array={array} />}
                            <SelectListItem
                                className={item.className}
                                item={item}
                                itemKey={itemKey}
                                label={item.label}
                                readonly={readonly}
                                selected={selectedFn(selected, item, idSelector)}
                                onChange={() => onItemChecked(item[idSelector])}
                                onItemClick={handleItemClick}
                                selectedTitle={selectedTitle}
                            />
                        </Fragment>
                    );
                })
                .slice(0, itemsToShow ? showMore : filteredItems.length)}
        </List>
    );

    return (
        <Fragment key={listKey}>
            {((showTitle && title) || (!readonly && showSelectAll)) && (
                <>
                    {showTitle && title && (
                        <Box className="label">
                            <I18n id={title} />
                        </Box>
                    )}
                    {!readonly && showSelectAll && (
                        <SelectListItem
                            itemKey={`${listKey}-select-all`}
                            label={getMessage("cybo.general.tables.selectAll")}
                            onChange={() => onSelectAll()}
                            onItemClick={handleItemClick}
                            selected={allSelected}
                        />
                    )}
                </>
            )}
            {scrollable ? <Scroll>{renderList()}</Scroll> : renderList()}
            {showMore !== 0 && (showMore < filteredItems.length || fetchMore) && (
                <Button onClick={onShowMoreItemsHandler} btnLink>
                    <Image src="images/arrow.svg" />
                    <Box inline>
                        <I18n id="cybo.general.actions.showMore.items" />
                    </Box>
                </Button>
            )}
            {showMore !== 0 && showMore > filteredItems.length && filteredItems.length > itemsToShow && (
                <Button onClick={onShowLessItemsHandler} btnLink>
                    <Image src="images/arrow.svg" />
                    <Box inline>
                        <I18n id="cybo.general.actions.showLess.items" />
                    </Box>
                </Button>
            )}
        </Fragment>
    );
}

SelectList.propTypes = {
    listKey: string,
    allSelected: bool,
    filter: func,
    items: arrayOf(shape({})).isRequired,
    itemLabel: func.isRequired,
    onItemChecked: func,
    onSelectAll: func,
    readonly: bool,
    selected: arrayOf(oneOfType([number, string])),
    selectedFn: func,
    showTitle: bool,
    showSelectAll: bool,
    sort: func,
    showIndexes: bool,
    title: string,
    onItemClick: func,
    idSelector: string,
    scrollable: bool,
    selectedTitle: func,
    itemsToShow: number,
    fetchMore: func,
};

SelectList.defaultProps = {
    listKey: "",
    allSelected: false,
    filter: () => true,
    onItemChecked: noop,
    onSelectAll: noop,
    readonly: false,
    selected: [],
    selectedFn: (array, element, selector) => array.includes(element[selector]),
    showSelectAll: true,
    showTitle: true,
    sort: (a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1),
    showIndexes: false,
    title: undefined,
    onItemClick: noop,
    idSelector: "id",
    scrollable: true,
    selectedTitle: noop,
    itemsToShow: null,
    fetchMore: undefined,
};

export default SelectList;
