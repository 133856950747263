import React from "react";
import Box from "pages/_components/Box";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import Text from "pages/_components/Text";
import { bool, number, string } from "prop-types";
import classNames from "classnames";

const Info = ({
    labelKey,
    labelText,
    amount,
    currency,
    text,
    date,
    anotherFormatDate,
    className,
    classNameValue,
    fullWidth,
    flex,
    size,
}) => (
    <Box
        display={flex && "flex"}
        gap={flex && 3}
        className={classNames("info-data", className, {
            "mt-5": flex,
        })}
        fullWidth={fullWidth}>
        <Box className="info-data-label" fullWidth={flex}>
            {labelKey && <Text labelKey={labelKey} />}
            {labelText && <Text>{labelText}</Text>}
        </Box>
        <Box
            className={classNames("info-data-value", classNameValue, {
                "mt-3": !flex,
            })}
            fullWidth={flex}>
            {amount && <FormattedAmount noAmountMargin currency={currency} quantity={amount} noCurrency={!currency} size={size} />}
            {date && <FormattedDate bold color="heading" date={date} anotherFormat={anotherFormatDate} />}
            {text && <Text>{text}</Text>}
        </Box>
    </Box>
);

Info.propTypes = {
    labelKey: string,
    labelText: string,
    amount: number,
    currency: string,
    text: string,
    date: string,
    className: string,
    classNameValue: string,
    fullWidth: bool,
    flex: bool,
    anotherFormatDate: string,
    size: number,
};

Info.defaultProps = {
    labelKey: undefined,
    labelText: undefined,
    amount: undefined,
    currency: undefined,
    text: undefined,
    date: undefined,
    className: "",
    classNameValue: "",
    fullWidth: true,
    flex: false,
    anotherFormatDate: undefined,
    size: undefined,
};

export default Info;
