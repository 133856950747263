import classNames from "classnames";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, node, number, string } from "prop-types";
import React, { useEffect, useRef } from "react";

const GridTableData = ({
    ariaHiden,
    alignX,
    alignY,
    children,
    className,
    columnStart,
    columnWidth,
    flexDirection,
    inHeader,
    isDesktop,
    minHeight,
    overflow,
    rowStart,
    rowWidth,
    tabIndex,
    size,
    ...props
}) => {
    const ref = useRef(null);

    useEffect(() => {
        const focusableSelectors = "a, button, input, textarea, select, [tabindex]:not([tabindex='-1'])";
        const handleKeyDown = (event) => {
            if (!ref.current) {
                return;
            }

            let element;
            let firstElement = false;
            switch (event.key) {
                case "ArrowUp":
                    element = ref.current.parentElement.previousSibling?.children[ref.current.cellIndex];
                    while (element && element.tabIndex === -1 && !element.querySelector(focusableSelectors)) {
                        element = element.previousSibling;
                    }
                    break;
                case "ArrowDown":
                    element = ref.current.parentElement.nextSibling?.children[ref.current.cellIndex];
                    while (element && element.tabIndex === -1 && !element.querySelector(focusableSelectors)) {
                        element = element.nextSibling;
                    }
                    break;
                case "ArrowLeft":
                    element = ref.current;
                    if (element.tagName === "TD" && !element.previousSibling) {
                        firstElement = true;
                        element = element.parentNode;
                    } else {
                        element = ref.current.previousSibling;
                        while (element && element.tabIndex === -1 && !element.querySelector(focusableSelectors)) {
                            element = element.previousSibling;
                        }
                    }
                    break;
                case "ArrowRight":
                    element = ref.current.nextSibling;
                    while (element && element.tabIndex === -1 && !element.querySelector(focusableSelectors)) {
                        element = element.nextSibling;
                    }
                    break;
                default:
                    return;
            }

            if (element) {
                const focusable = element.querySelector(focusableSelectors);

                if (element.tabIndex !== -1 || (firstElement && element.tabIndex !== -1)) {
                    element.focus();
                } else if (focusable) {
                    focusable.focus();
                }
            }
        };

        if (ref.current) {
            ref.current.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, []);

    const Component = inHeader ? "th" : "td";

    return (
        <Component
            ref={ref}
            tabIndex={tabIndex}
            {...(inHeader && { scope: "col" })}
            {...(ariaHiden && { "aria-hiden": ariaHiden })}
            className={classNames("grid-table-data", className, {
                [`column-start-${columnStart}`]: columnStart,
                [`column-end-${columnWidth}`]: columnWidth,
                [`row-start-${rowStart}`]: rowStart,
                [`min-height-${minHeight}`]: minHeight && isDesktop,
                [`row-end-${rowWidth}`]: rowWidth,
                [`justify-content-${alignX}`]: alignX,
                [`align-items-${alignY}`]: alignY,
                [`flex-direction-${flexDirection}`]: flexDirection,
                [`table-data-fontsize-${size}`]: size,
                [`overflow-${overflow}`]: overflow,
            })}
            {...props}>
            {children}
        </Component>
    );
};

GridTableData.propTypes = {
    ariaHiden: bool,
    alignX: string,
    alignY: string,
    children: node,
    className: string,
    columnStart: number.isRequired,
    columnWidth: number,
    flexDirection: string,
    inHeader: bool,
    isDesktop: bool.isRequired,
    minHeight: string,
    overflow: string,
    rowStart: number,
    rowWidth: number,
    size: string,
    tabIndex: string,
};

GridTableData.defaultProps = {
    ariaHiden: null,
    alignX: "center",
    alignY: "center",
    children: null,
    className: null,
    columnWidth: null,
    flexDirection: null,
    inHeader: false,
    minHeight: "8",
    overflow: "hidden-x",
    rowStart: null,
    rowWidth: null,
    size: "6",
    tabIndex: "0",
};

export default resizableRoute(GridTableData);
