import { call, put, takeLatest } from "redux-saga/effects";
import { replace, push, routerActions } from "react-router-redux";

import { ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE } from "util/responses.js";
import * as form from "middleware/form";
import { types, actions } from "reducers/administration/users";
import { actions as notificationActions } from "reducers/notification";
import * as administrationUsers from "middleware/administration/users";
import * as administrationUsersAd from "middleware/administration/advanced";
import * as i18n from "util/i18n";
import * as utilDownload from "util/download";
import { credentialsToUnderscoreFormat } from "util/form.js";
import { types as secondFactorTypes } from "reducers/secondFactor";
import { actions as sessionActions } from "reducers/session";

const sagas = [
    takeLatest(types.LOAD_LIST_REQUEST, loadListRequest),
    takeLatest(types.LOAD_MORE_REQUEST, loadMoreRequest),
    takeLatest(types.EXPORT_LIST_REQUEST, exportListRequest),
    takeLatest(types.CHANGE_USER_STATUS_PREVIEW, changeUserStatusPreview),
    takeLatest(types.CHANGE_USER_STATUS_CONFIRMATION, changeUserStatusConfirmation),
    takeLatest(types.UPDATE_DISPATCHER_REQUEST, updateDispatcher),
    takeLatest(types.UPDATE_USER_CONTACTS_REQUEST, updateUserContacts),
];

export default sagas;

const actionToStatus = { block: "blocked", unblock: "active" };

function* loadListRequest(params) {
    const { filters } = params;
    const response = yield call(administrationUsers.loadListRequest, filters);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        yield put(actions.loadListSuccess(response.data.data));
    }
}

function* loadMoreRequest(params) {
    const { filters } = params;
    const response = yield call(administrationUsers.loadListRequest, filters);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        yield put({
            type: types.LOAD_MORE_SUCCESS,
            data: response.data.data,
        });
    }
}

function* exportListRequest(params) {
    const { type, data } = yield call(administrationUsers.exportListRequest, params);
    const { format } = params;

    if (type === "W") {
        yield put({ type: types.EXPORT_LIST_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        const { content, fileName } = data.data;
        if (format === "pdf") {
            utilDownload.downloadPdf(fileName, content);
        } else {
            utilDownload.downloadXls(fileName, content);
        }
        yield put({ type: types.EXPORT_LIST_SUCCESS });
    }
}

function* changeUserStatusPreview({ userList, userNameList, userAction, isFromList }) {
    const params = {
        userIdList: userList,
        userNameList,
        newStatus: actionToStatus[userAction],
    };
    let response = null;
    let idActivity;
    if (userAction === "block" || userAction === "unblock") {
        idActivity = "administration.users.blockunblock.send";
        response = yield call(administrationUsers.changeUsersStatusPreview, params);
    } else if (userAction === "delete") {
        idActivity = "administration.users.delete.send";
        response = yield call(administrationUsers.deleteUsersPreview, params);
    }
    if (response.type === "W") {
        yield put(push("/administration/users"));
        yield put(
            notificationActions.showNotification(response.data.data.NO_FIELD, "warning", ["administrationUsers"]),
        );
    } else {
        const responseCredentials = yield call(
            form.listCredentialsGroups,
            null,
            "administration.users.blockunblock.send",
        );
        const credentialGroups = responseCredentials.data.data.groups;
        yield put({
            type: types.CHANGE_USER_STATUS_PREVIEW_SUCCESS,
            userList,
            userNameList,
            userAction,
            credentialGroups,
            isFromList,
            idActivity,
        });
        yield put(push(`/administration/confirmUserAction`));
    }
}

function* changeUserStatusConfirmation({ usersToApplyAction, userNameList, userAction, secondFactor, formikBag }) {
    const params = {
        userIdList: usersToApplyAction,
        userNameList,
    };
    const massive = usersToApplyAction?.length === 1 ? "singular" : "massive";
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(secondFactor);
    let response = null;
    let successMessageSubKey = userAction;
    if (userAction === "block" || userAction === "unblock") {
        response = yield call(
            administrationUsers.changeUsersStatusConfirmation,
            { ...params, newStatus: actionToStatus[userAction] },
            credentialsWithUnderscore,
        );
        successMessageSubKey = actionToStatus[userAction];
    } else if (userAction === "delete") {
        response = yield call(administrationUsers.deleteUsersConfirmation, params, credentialsWithUnderscore);
    }

    formikBag.setSubmitting(false);
    if (response.type === "W") {
        const { code, data } = response.data;
        if (code === "COR020W" && data.secondFactor) {
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
        }
        if (code === "API707W") {
            yield put(sessionActions.logoutUserBlocked());
            return;
        }
        if (code === "API708W") {
            yield put(
                notificationActions.showNotification(i18n.get("secondFactor.credential.otp.expired", "Código OTP expirado, solicite un nuevo código OTP"), "warning", [
                    "administrationUsers",
                ]),
            );
            return;
        }
        if (response.data.data.NO_FIELD) {
            yield put(replace("/administration/users"));
            yield put(
                notificationActions.showNotification(response.data.data.NO_FIELD, "warning", ["administrationUsers"]),
            );
        } else {
            formikBag.setErrors(response.data.data);
            yield put(replace("/administration/confirmUserAction"));
        }
    } else {
        if (response.data.code && response.data.code === ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE) {
            const pendingMessage = i18n.get(
                `administration.user.modify.${successMessageSubKey}.${massive}.pending.messages`,
                response.data.message,
            );
            yield put(notificationActions.showNotification(pendingMessage, "warning", ["administrationUsers"]));
        } else {
            let successMessageKey = `administration.users.${successMessageSubKey}.success`;
            if (usersToApplyAction.length > 1) {
                successMessageKey = `${successMessageKey}.plural`;
            }
            yield put(
                notificationActions.showNotification(i18n.get(successMessageKey), "success", ["administrationUsers"]),
            );
        }
        yield put(replace("/administration/users"));
    }
}

function* updateDispatcher({ data, formikBag }) {
    const { dispatcher, userId, secondFactor, ...rest } = data;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(secondFactor);
    const response = yield call(administrationUsers.updateDispacther, {
        ...rest,
        ...credentialsWithUnderscore,
        userId,
        dispatcher,
    });
    const { setSubmitting, setErrors } = formikBag;
    setSubmitting(false);
    if (response.type === "W") {
        let showNotification = true;
        const { code, data } = response.data;
        if (code === "COR020W" && data.secondFactor) {
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            showNotification = false;
        }
        if (code === "API707W") {
            yield put(sessionActions.logoutUserBlocked());
            return;
        }
        if (code === "API708W") {
            yield put(
                notificationActions.showNotification(i18n.get("secondFactor.credential.otp.expired", "Código OTP expirado, solicite un nuevo código OTP"), "warning", [
                    "administration",
                ]),
            );
            return;
        }
        setErrors(response.data.data);
        const errorMessage = response.data.message || i18n.get("global.unexpectedError");
        if (showNotification) {
            yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
        }
        yield put(actions.updateDispatcherFailure());
    } else {
        yield put(notificationActions.showNotification(response.data.message, "success", ["administration"]));
        yield put(routerActions.goBack());
        yield put(actions.updateDispatcherSuccess(response.data.data.userExtendedInfo));
    }
}

function* updateUserContacts({ data, formikBag }) {
    const { idUser, emailUpdate, mobileNumberUpdate, ...secondFactor } = data;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(secondFactor);
    const response = yield call(administrationUsersAd.updateUserContactsRequest, {
        idUser,
        emailUpdate,
        mobileNumberUpdate,
        ...credentialsWithUnderscore,
    });

    formikBag.setSubmitting(false);

    if (response.type === "W") {
        let showNotification = true;
        const { code, data } = response.data;
        if (code === "COR020W" && data.secondFactor) {
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            showNotification = false;
        }
        if (code === "API707W") {
            yield put(sessionActions.logoutUserBlocked());
            return;
        }
        if (code === "API708W") {
            yield put(
                notificationActions.showNotification(i18n.get("secondFactor.credential.otp.expired", "Código OTP expirado, solicite un nuevo código OTP"), "warning", [
                    "administration",
                ]),
            );
            return;
        }
        const errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");

        formikBag.setErrors(response.data.data);
        if (showNotification) {
            yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
        }
    } else {
        const successMesage = response.data.message;
        if (response.data.code === ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE) {
            const message = i18n.get("administration.user.contacts.update.pending.messages", response.data.message);
            yield put(notificationActions.showNotification(message, "warning", ["administration"]));
        } else {
            yield put(notificationActions.showNotification(successMesage, "success", ["administration"]));
            yield put(actions.updateUserContactsSuccess());
        }
        if (idUser) {
            yield put(routerActions.replace(`/administration/advanced/details/${idUser}`));
        } else {
            yield put(routerActions.replace(`/administration/users`));
        }
    }
}
