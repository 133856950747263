import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { routerActions } from "react-router-redux/actions";

import { permissionsSelectors, permissionsTicketSelectors } from "reducers/administration";
import { permissionsTicketActions } from "reducers/administration/medium";

import PermissionsTicket from "pages/administration/_components/PermissionsTicket";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";

class PermissionsTicketContent extends Component {
    render() {
        return (
            <AdministrationTicket {...this.props}>
                <PermissionsTicket {...this.props} />
            </AdministrationTicket>
        );
    }
}

const mapStateToProps = (state) => ({
    user: permissionsSelectors.getUser(state),
    fetching: permissionsTicketSelectors.isFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(permissionsTicketActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsTicketContent);
