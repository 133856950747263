import { call, put, takeLatest, select } from "redux-saga/effects";
import * as envApi from "middleware/generalConditions";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { generalConditionsTypes } from "reducers/generalConditions";
import { downloadMobileFile, downloadPdf } from "util/download";
import { types as typesSession, selectors as sessionSelectors } from "reducers/session";
import { replace } from "react-router-redux";
import { isMobileNativeFunc } from "util/device";
import b64toBlob from "b64-to-blob";

import { selectors as loginSelectors } from "reducers/login";

const ID_ACTIVITY_DOWNLOAD_GENERAL_COND = "generalCondition.get.category";
const ID_ACTIVITY_DOWNLOAD_GENERAL_COND_PUBLIC = "generalCondition.public.get.category";

const ID_ACTIVITY_ACCEPT_GENERAL_COND = "environment.generalCondition.accept";

function* getGeneralConditions({ data }) {
    const { category } = data;
    const isLoggedIn = yield select(sessionSelectors.isLoggedIn);
    const exchangeToken = yield select(loginSelectors.getExchangeToken);
    const idActivity = isLoggedIn ? ID_ACTIVITY_DOWNLOAD_GENERAL_COND : ID_ACTIVITY_DOWNLOAD_GENERAL_COND_PUBLIC;
    const response = yield call(envApi.getGeneralConditions, idActivity, category, isLoggedIn, exchangeToken);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
            );
        } else if (!response.data || !response.data.data || Object.values(response.data.data).every((item) => !item)) {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
            );
        } else {
            const { generalConditionFileName, generalConditionContent } = response.data.data;
            if (isMobileNativeFunc()) {
                const fileBlob = b64toBlob(generalConditionContent, "application/pdf");
                downloadMobileFile(fileBlob, generalConditionFileName, "application/pdf");
            } else {
                downloadPdf(generalConditionFileName, generalConditionContent);
            }
            yield put({
                type: generalConditionsTypes.SUCCESS_DOWNLOAD_GENERAL_CONDITIONS,
            });
        }
    }
}

function* executeDispatchAcceptConditions({ data }) {
    const { idEnvironment, rememberEnvironment } = data;

    const response = yield call(envApi.acceptGeneralConditions, ID_ACTIVITY_ACCEPT_GENERAL_COND, idEnvironment, true);

    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put(replace("/desktop"));
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
            );
            yield put(replace("/desktop"));
        } else {
            yield put({
                type: typesSession.CHANGE_ENVIRONMENT_REQUEST,
                idEnvironment,
                rememberEnvironment,
            });
        }
    }
}

const sagas = [
    takeLatest(generalConditionsTypes.DOWNLOAD_GENERAL_CONDITIONS, getGeneralConditions),
    takeLatest(generalConditionsTypes.EXECUTE_ACTION_DISPATCH, executeDispatchAcceptConditions),
];

export default sagas;
