import formField from "pages/forms/_components/_fields/_commons/formField";
import { bool } from "prop-types";
import React, { Component } from "react";

class Horizontalrule extends Component {
    static propTypes = {
        editing: bool,
    };

    static defaultProps = {
        editing: false,
    };

    render() {
        if (this.props.editing) {
            return <hr />;
        }
        return null;
    }
}

export default formField({ pureRender: true })(Horizontalrule);
