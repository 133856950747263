import * as i18n from "util/i18n";
import { useEffect, useState } from "react";
import * as creditCardsUtil from "util/creditCards";
import { ouputFieldValues, inputFieldValues } from "./servicePaymentsHooks";

export const getOuputFieldValues = (billBalance, field) => {
    let ouputField = [];
    if (billBalance) {
        const fieldFiltered = field.filter((el) => el.fieldInq === false && el.serviceOper === "BillInqRs");
        ouputField = ouputFieldValues(fieldFiltered, billBalance);
    }
    return [ouputField];
};

export const getPmtUtilAddRqValues = (billBalance, field, queryBill) => {
    const ouputField = [];
    const arrayNoFields = ["SubTotal", "TotalTax", "TotalPmt", "TotalBalance"];
    // aumento noFields los valores ya ingresados por el usuario
    if (queryBill) {
        const tmp = queryBill?.paymentUser?.billInqRq;
        tmp.forEach((el) => {
            arrayNoFields.push(el.label);
        });
    }
    if (billBalance) {
        // Datos resultado de consulta
        const fieldFiltered = field.filter(
            (el) => el.serviceOper === "PmtUtilAddRq" && !arrayNoFields.some((str) => str === el.serviceField),
        );

        const tmp = ouputFieldValues(fieldFiltered, billBalance, true);
        tmp.forEach((el) => {
            ouputField.push({ label: el?.serviceField, value: `${el?.value}` });
        });
    }
    // Datos ingresados por usuario
    if (queryBill) {
        const fieldFiltered = field.filter(
            (el) =>
                el.serviceOper === "PmtUtilAddRq" &&
                !arrayNoFields.some((str) => str === el.serviceField && el.fieldInq),
        );
        const values = queryBill?.paymentUser?.pmtUtilAddRq;
        fieldFiltered.forEach((element) => {
            if (values.length > 0) {
                values.forEach((el) => {
                    if (
                        el?.label === element.serviceField &&
                        ouputField.filter((elem) => elem.label === el?.label).length === 0
                    ) {
                        ouputField.push({ label: element.serviceField, value: el?.value });
                    }
                });
            }
        });
    }
    return ouputField;
};

export const getInputFieldValues = (props) => {
    const { queryBill, field, transaction } = props;
    let inputField = [];
    const fieldFiltered = field.filter((el) => el.fieldInq === true);
    if (queryBill) {
        inputField = inputFieldValues(fieldFiltered, queryBill);
    } else {
        const tmpPaymentUser = {
            ...transaction?.data?.paymentUser,
            billInqRq: JSON.parse(transaction?.data?.paymentUser?.billInqRq),
            pmtUtilAddRq: JSON.parse(transaction?.data?.paymentUser?.pmtUtilAddRq),
        };
        const tmp = {
            paymentUser: tmpPaymentUser,
            biller: transaction?.data?.biller,
            savePayment: false,
        };
        inputField = inputFieldValues(fieldFiltered, tmp);
    }
    return [inputField];
};

export const validateDinamicForm = (values, props, typeAccount) => {
    const errors = {};

    // validacion cuenta|tarjeta seleccionada
    if (typeAccount === "debitAccount" && values.debitAccount === "") {
        errors.debitAccount = i18n.get(`servicePayment.dinamicForm.validate.required`);
    }
    if (typeAccount === "creditCard" && values.creditCard === "") {
        errors.creditCard = i18n.get(`servicePayment.dinamicForm.validate.required`);
    }

    // validacion dinamica minValue, maxValue

    const fieldSubTotal = props.field.filter((el) => el.serviceField === "SubTotal");
    if (fieldSubTotal.length > 0) {
        const { restriction } = fieldSubTotal[0];
        if (restriction.maxValue !== null && values.SubTotal.quantity > restriction.maxValue) {
            errors.SubTotal = i18n.get(`servicePayment.dinamicForm.validate.maxValue`, null, {
                NUM: restriction.maxValue,
            });
        }
        if (restriction.minValue !== null && values.SubTotal.quantity < restriction.minValue) {
            errors.SubTotal = i18n.get(`servicePayment.dinamicForm.validate.minValue`, null, {
                NUM: restriction.minValue,
            });
        }
    }

    // validacion monto requerido
    if (!values.SubTotal?.quantity) {
        errors.SubTotal = i18n.get(`servicePayment.dinamicForm.validate.required`);
    }

    // validacion al consultar valores
    // if (props.selectedBiller?.hasQuery && props.preDataForm?.billBalance == null) {
    //     errors.SubTotal = "Error al consultar valores";
    // }

    return errors;
};

export const initialValues = (props) => {
    let values = {};
    const data = props?.transaction?.data;
    if (!data) {
        let pmtUtilAddRq = getPmtUtilAddRqValues(props?.preDataForm?.billBalance, props.field, props.queryBill);
        const tmp = { ...props?.queryBill?.paymentUser };
        const billInqRq = props?.queryBill?.paymentUser?.billInqRq;
        // Caso billRef con datos
        const billRef = pmtUtilAddRq?.filter((el) => el.label === "BillRef");

        if (billRef && billInqRq && billRef.length > 0) {
            billInqRq.map((elem) => {
                const tempElem = elem;
                if (elem.label === "BillId") {
                    tempElem.label = "BillId";
                    tempElem.value = `${billRef[0].value}`;
                }
                return tempElem;
            });
        }

        pmtUtilAddRq = pmtUtilAddRq.filter((el) => el.label !== "BillRef");

        tmp.billInqRq = JSON.stringify(billInqRq);
        tmp.pmtUtilAddRq = JSON.stringify(props?.queryBill?.paymentUser?.pmtUtilAddRq);
        values = {
            SubTotal: { currency: "USD", quantity: "" },
            TotalTax: { currency: "USD", quantity: 0 },
            TotalPmt: { currency: "USD", quantity: 0 },
            TotalBalance: { currency: "USD", quantity: 0 },
            amount: { currency: "USD", quantity: 0 },
            debitAccount: "",
            creditCard: "",
            typeAccount: "debitAccount",
            paymentUser: tmp,
            biller: props?.queryBill?.biller,
            savePayment: props?.queryBill?.savePayment,
            isFastRecharge: props?.isFastRecharge,
            pmtUtilAddRq: JSON.stringify(pmtUtilAddRq),
        };

        if (props.selectedBiller?.hasQuery && props?.preDataForm?.billBalance !== null) {
            let total = 0;
            const listSummAmount = props.preDataForm?.billBalance?.listSummAmount;
            if (listSummAmount && props.selectedBiller.category !== "RECARGAS") {
                listSummAmount.forEach((el) => {
                    if (el.amount > 0) {
                        if (el.code === "CurrentBalance") {
                            total = el.amount;
                        }
                        if (el.code === "TotalBalance") {
                            total = el.amount;
                        }
                        if (el.code === "TotalPmt") {
                            total = el.amount;
                        }
                    }
                });
            }

            total = parseFloat(total).toFixed(2);
            values.SubTotal = { currency: "USD", quantity: total === 0 ? "" : total };
            values.TotalPmt = { currency: "USD", quantity: total };
            values.TotalBalance = { currency: "USD", quantity: total };
            values.amount = { currency: "USD", quantity: total };
        }
    } else {
        values = {
            SubTotal: data?.SubTotal,
            TotalTax: data?.TotalTax,
            TotalPmt: data?.TotalPmt,
            TotalBalance: data?.TotalBalance,
            amount: data?.TotalBalance,
            debitAccount: data?.debitAccount,
            creditCard: data?.creditCard,
            typeAccount: data?.typeAccount,
            paymentUser: data?.paymentUser,
            biller: data?.biller,
            savePayment: data?.savePayment,
            isFastRecharge: data?.isFastRecharge,
            scheduler: data?.scheduler,
            debitAccountData: data?.debitAccountData,
            creditCardData: data?.creditCardData,
        };
    }

    if (props.isFastRecharge) {
        const newPaymentUser = { ...values.paymentUser };
        newPaymentUser.fastRecharge = true;
        newPaymentUser.amountCurrency = "USD";
        newPaymentUser.amountQuantity = 0;
        newPaymentUser.productType = "debitAccount";
        newPaymentUser.idProduct = "";
        values.paymentUser = newPaymentUser;
    }

    return values;
};

export const useCreditCardPaySelectorOptions = (creditCardList) => {
    const [creditCardOptions, setCreditCardOptions] = useState([]);

    const createCreditCardList = (card) => ({
        ...card,
        balance: { currency: card.currency, quantity: card.totalAmount },
        id: card.idProduct,
        additionalCreditCardList: [],
    });

    useEffect(() => {
        const nonPrepaidWithAdditionals = creditCardsUtil.getCreditCardsWithAdditionals(creditCardList);
        setCreditCardOptions({
            options: nonPrepaidWithAdditionals
                .map(createCreditCardList)
                ?.filter((card) => card.statusCode === creditCardsUtil.CREDIT_CARD_STATUS.ACTIVA),
        });
    }, [creditCardList]);
    return creditCardOptions;
};

export const useAccountPaySelectorOptions = (debitAccountList) => {
    const [accountOptions, setAccountOptions] = useState([]);

    const createAccountList = (acc) => ({
        ...acc,
        balance: { currency: acc.currency, quantity: acc.balance },
        id: acc.idProduct,
        disabled: acc.isDisabled,
    });
    useEffect(() => {
        setAccountOptions({
            options: debitAccountList?.map(createAccountList),
        });
    }, [debitAccountList]);
    return accountOptions;
};
