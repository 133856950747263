import Box from 'pages/_components/Box/Box'
import Button from 'pages/_components/Button/Button'
import Image from 'pages/_components/Image/Image'
import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as i18n from "util/i18n";
import Text from "pages/_components/Text";

const LoginInfo = ({content, image, title, onReturn}) => {
  return (
    <Box className="login-info-page">
        <Box className="title-with-icon-info">
            <Image src="images/iconTitles/security_device.svg" />
            <Text component="h3" defaultValue={title} />
        </Box>
        <Image src={image} className="second-factor-img-width" />
        {content}
        <Button
            primaryLink
            bsStyle="link"
            defaultLabelText={i18n.get("global.return")}
            className="px-0"
            onClick={() => {
              if (onReturn) {
                onReturn()
              }
            }}
        />
    </Box>
  )
}

export default withRouter(connect()(LoginInfo));