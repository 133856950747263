import { Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { compose } from "redux";
import Box from "pages/_components/Box";
import classNames from "classnames";
import parse from "html-react-parser";
import EnrollmentWrapper from "../_components/EnrollmentWrapper";
import * as i18n from "../../../util/i18n";

class TermsAndConditions extends Component {
    cancel = () => {
        const { dispatch } = this.props;
        dispatch(enrollmentActions.showExitEnrollmentModal(true));
    };

    handleDeleteMessage = () => {
        const { dispatch } = this.props;

        dispatch(enrollmentActions.showExitEnrollmentModal(false));
        dispatch(push("/"));
    };

    onBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/enrollment/document"));
    };

    content = () => {
        const { isDesktop, isSubmitting } = this.props;
        return (
            <Form className={classNames("display-flex flex-column term-and-conditions")}>
                <Box display="flex" fullWidth className="login-title-container">
                    <Box fullWidth className="login-title-text">
                        <Text
                            size={isDesktop ? "3" : "2"}
                            className="btn-link-span"
                            bold
                            labelKey="enrollment.step5.title"
                        />
                    </Box>
                </Box>
                <div className="container-term-and-deposit custom-scrollbar line-height-15 mt-3">
                    <Text align="justify" className="mb-1 mb-md-3" color="text" breakWord>
                        {parse(`${i18n.get("enrollment.terms.conditions.text")}`, {})}
                    </Text>
                </div>
                <Row className="mt-9">
                    <Col xs={isDesktop ? 6 : 12}>
                        <Button
                            bsStyle="primary"
                            label="enrollment.term.accept"
                            loading={isSubmitting}
                            type="submit"
                            className="full-width"
                            block
                        />
                    </Col>
                    <Col xs={isDesktop ? 6 : 12}>
                        <Button bsStyle="outline" label="global.cancel" type="button" onClick={this.cancel} block />
                    </Col>
                </Row>
            </Form>
        );
    };

    render() {
        const { isDesktop } = this.props;

        return (
            <EnrollmentWrapper onBack={this.onBack} isDesktop={isDesktop}>
                <>{this.content()}</>
            </EnrollmentWrapper>
        );
    }
}

TermsAndConditions.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isSubmitting: bool,
};

TermsAndConditions.defaultProps = {
    isSubmitting: false,
};

const mapStateToProps = (state) => ({
    password: enrollmentSelectors.getNewPassword(state),
    username: enrollmentSelectors.getNewUsername(state),
    showExitModal: enrollmentSelectors.getShowEnrollmentModal(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({}),
        initialValues: { accept: false },
        handleSubmit: ({}, formikBag) => {
            const { dispatch, password, username } = formikBag.props;
            dispatch(enrollmentActions.finishCreate(username, password, password, formikBag));
        },
    }),
)(TermsAndConditions);
