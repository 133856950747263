import React from "react";
import { bool, func, node } from "prop-types";
import MainContainer from "pages/_components/MainContainer";
import Box from "pages/_components/Box";
import FooterDesktop from "pages/_components/FooterDesktop";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as i18n from "util/i18n";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import { actions, selectors } from "reducers/enrollment";
import SimpleHead from "./SimpleHead";

const EnrollmentWrapper = ({ children, isDesktop, onBack, showExitModal, dispatch }) => {
    const handleAcceptModal = () => {
        dispatch(actions.showExitEnrollmentModal(false));
        dispatch(push("/"));
    };

    const hideModal = () => {
        dispatch(actions.showExitEnrollmentModal(false));
    };

    const wrapperMobile = () => (
        <section className="container--layout">
            <Box className="login-desktop-wrapper panel">
                <MainContainer shouldHideOnLoad className="app-default-main full-height max-width-full">
                    <Box className="px-6 pt-8 pb-7" fullWidth>
                        <div>{children}</div>
                        <Box className="mt-7">
                            <FooterDesktop isLogin />
                        </Box>
                    </Box>
                </MainContainer>
            </Box>
        </section>
    );

    return (
        <>
            {!isDesktop && <SimpleHead {...(onBack && { onBack })} />}
            <TextAndButtonsModal
                modalShow={showExitModal}
                acceptFunction={handleAcceptModal}
                cancelFunction={hideModal}
                confirmLabel="enrollment.modal.exit"
                headingText={i18n.get("enrollment.modal.headingText")}
                text={i18n.get("enrollment.modal.text")}
            />
            {isDesktop ? <> {children}</> : <>{wrapperMobile()}</>}
        </>
    );
};

EnrollmentWrapper.propTypes = {
    children: node.isRequired,
    isDesktop: bool.isRequired,
    showExitModal: bool,
    dispatch: func.isRequired,
    onBack: func,
};

EnrollmentWrapper.defaultProps = {
    showExitModal: false,
    onBack: undefined,
};

const mapStateToProps = (state) => ({
    showExitModal: selectors.getShowEnrollmentModal(state),
});

export default connect(mapStateToProps)(EnrollmentWrapper);
