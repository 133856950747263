import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";

class FingerprintDeleteConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        device: shape({
            deviceId: string.isRequired,
            deviceModel: string.isRequired,
            idSession: string.isRequired,
        }).isRequired,
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
    };

    deleteDevice = (idSession) => () => {
        const { dispatch } = this.props;
        dispatch(fingerprintActions.fingerprintDeleteDevice(idSession));
    };

    getContent = () => {
        const {
            device: { deviceId, deviceModel, idSession },
        } = this.props;

        return (
            <form>
                <Row className="justify-content-center">
                    <Col xs={12} md={8} mdOffset={2}>
                        <Disclaimer
                            className="p-3 px-5 p-md-5 mt-5 mt-md-0 mb-8"
                            styled="info"
                            labelKey="settings.fingerprintConfiguration.deleteConfirmation.confirmWarning"
                        />
                    </Col>
                    <Col xs={12} md={8} mdOffset={2}>
                        <Text
                            size="4"
                            bold
                            color="heading"
                            labelKey="settings.fingerprintConfiguration.deleteConfirmation.action"
                        />
                    </Col>

                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                        <Box display="flex" alignX="between" background="white" borderRadius="md" className="p-5">
                            <Text
                                color="heading"
                                labelKey={`devices.apple.identifier.${deviceModel}`}
                                defaultValue={deviceModel}
                            />
                            <Text color="heading">{deviceId.substring(deviceId.length - 4, deviceId.length)}</Text>
                        </Box>
                    </Col>

                    <Row>
                        <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                            <Button
                                label="settings.fingerprintConfiguration.deleteConfirmation.confirm"
                                bsStyle="primary"
                                onClick={this.deleteDevice(idSession)}
                                block
                            />
                        </Col>
                    </Row>
                </Row>
            </form>
        );
    };

    render() {
        const { fetching } = this.props;

        return (
            <>
                <Head title="settings.biometrics" backLinkTo="/settings/fingerprintConfiguration" />
                <MainContainer showLoader={fetching}>
                    <Notification scopeToShow="fingerprintConfiguration" />
                    {this.getContent()}
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: fingerprintSelectors.getFetching(state),
    device: fingerprintSelectors.getDevice(state),
});

export default connect(mapStateToProps)(FingerprintDeleteConfirmation);
