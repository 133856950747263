import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool, string } from "prop-types";
import { actions as recoveryPasswordActions, selectors } from "reducers/recoveryPassword";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import { simpleMaskEmail } from "util/format";
import classNames from "classnames";

class Step4Content extends Component {
    goToLogin = () => {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.recoveryPassStep4());
    };

    render() {
        const { isMobile, email } = this.props;

        return (
            <Box display="flex" column fullWidth {...(isMobile ? { fullHeight: true } : { fullHeight: false })}>
                <Box display="flex" alignX="center" className="mb-8">
                    <Box className="login-blocked-icon">
                        <Image src="images/util/success.gif" />
                    </Box>
                </Box>
                <Box className="text-center mb-7">
                    <Text
                        size="3"
                        labelKey={
                            isMobile
                                ? "recoveryPassword.step4.mobile.success.title"
                                : "recoveryPassword.step4.web.success.title"
                        }
                        bold
                    />
                </Box>
                <Box display="flex" column className="my-auto pb-7">
                    <Text
                        labelKey="recoveryPassword.step4.confirmMessage"
                        textParams={{
                            EMAIL: email && simpleMaskEmail(email),
                        }}
                        align="center"
                        size={`${isMobile ? "5" : "6"}`}
                        className={classNames("my-0", {
                            "line-height-125": !isMobile,
                            "line-height-15": isMobile,
                        })}
                        component="p"
                        color="text"
                    />
                </Box>
                <Box display="flex" column className="mb-7 mb-md-0 mt-auto">
                    <Button label="recoveryPassword.step4.enter" bsStyle="primary" onClick={this.goToLogin} block />
                </Box>
            </Box>
        );
    }
}

Step4Content.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    email: string.isRequired,
};

const mapStateToProps = (state) => ({
    email: selectors.getEmail(state),
});

export default connect(mapStateToProps)(Step4Content);
