// eslint-disable-next-line import/prefer-default-export
export const isFirstTimeOpen = () =>
    new Promise((resolve) => {
        if (!window?.cordova?.plugins?.InitializationDevicePlugin?.isFirstTimeOpenCommand) {
            resolve(false);
            return;
        }

        window.cordova.plugins.InitializationDevicePlugin.isFirstTimeOpenCommand(
            (isFirstTimeOpenResponse) => {
                const isFirstTimeValue = isFirstTimeOpenResponse === true || isFirstTimeOpenResponse === "true";
                resolve(isFirstTimeValue);
            },
            () => {
                resolve(false);
            },
        );
    });
