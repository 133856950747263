import React from "react";
import { shape, string } from "prop-types";
import * as userUtil from "pages/administration/utils/users";
import Box from "pages/_components/Box";
import * as signaturesUtil from "../../utils/signaturesScheme";
import * as groupUtil from "../../utils/groups";

const MainDataTicket = ({ transactionData }) => {
    const renderBox = (renderMain) => (
        <Box background="background-disabled" className="p-5 my-5" borderRadius="lg">
            {renderMain}
        </Box>
    );

    const renderHeadTicket = () => {
        const { idActivity, data } = transactionData;
        switch (idActivity) {
            case "administration.signatures.create.send":
            case "administration.signatures.modify.send":
            case "administration.signatures.delete.send": {
                const { alias, signatureAlias } = data;
                return renderBox(signaturesUtil.renderAlias(alias || signatureAlias));
            }
            case "administration.advanced.group.create.send":
            case "administration.advanced.group.modify.send": {
                const { name } = data;
                return renderBox(groupUtil.renderMainData(name));
            }
            case "administration.groups.delete.send": {
                const { groupNameList: rolList } = data;
                if (rolList && rolList.length === 1) {
                    return renderBox(groupUtil.renderNameAction("delete", rolList[0]));
                }
                if (rolList && rolList.length > 1) {
                    return groupUtil.renderNameMassiveAction("delete", rolList);
                }
                return <></>;
            }
            case "administration.groups.blockunblock.send": {
                const { groupNameList, blocked } = data;
                const actionRol = blocked ? "block" : "unblock";
                if (groupNameList && groupNameList.length === 1) {
                    return renderBox(groupUtil.renderNameAction(actionRol, groupNameList[0]));
                }
                if (groupNameList && groupNameList.length > 1) {
                    return groupUtil.renderNameMassiveAction(actionRol, groupNameList);
                }
                return <></>;
            }
            case "administration.medium.modify.channels.send": {
                const { capsInfo } = data;
                return renderBox(userUtil.renderMainDataLimits(true, capsInfo || [], "corporate"));
            }
            case "administration.medium.modify.contacts.send": {
                const { _fullName } = data;
                return renderBox(userUtil.renderMainData(_fullName));
            }
            case "administration.users.invite.send": {
                const { firstName, lastName } = data;
                return renderBox(userUtil.renderName(`${firstName} ${lastName}`));
            }
            case "administration.medium.modify.signature.send": {
                const { fullName } = data;
                return renderBox(userUtil.renderMainData(fullName));
            }
            default:
                return <></>;
        }
    };

    return renderHeadTicket();
};

MainDataTicket.propTypes = {
    transactionData: shape({
        data: shape({}),
        idActivity: string,
        idTransaction: string,
    }).isRequired,
};

export default MainDataTicket;
